/* eslint-disable react/display-name */
import { groupBy, sortBy } from "lodash";
import React, { useMemo } from "react";

import useFieldData from "fields/hooks/useFieldData";

const useGroupedFields = (fieldsList) => {
  const skip = !!fieldsList?.length;
  const { fields } = useFieldData(skip);
  const fieldsData = skip ? fieldsList : fields;

  const groupedFields = useMemo(
    () => groupBy(fieldsData, ({ group }) => (group ? group.name : "Ungrouped")),
    [fields, fieldsList]
  );
  const groups = sortBy(Object.keys(groupedFields));

  return { groupedFields, groups };
};

export const withGroupedFieldsOptions = (Component) => (props) => {
  const { groupedFields, groups } = useGroupedFields();
  const chooserOptions = !groups.length
    ? []
    : groups.map((name) => ({
        name,
        options: groupedFields[name].map(({ id, name }) => ({ name, value: id })),
      }));

  return <Component {...props} chooserOptions={chooserOptions} />;
};

export default useGroupedFields;
