import ActivityDetail from "activity/pages/ActivityDetail";
import ActivityIndexPage from "activity/pages/ActivityIndexPage";

import App from "layout/app";
import SubRoute from "lib/SubRoute";

const ActivityRouter = SubRoute.extend({
  // Define routes
  routes: {
    "": "index",
    log: "log",
    ":id": "activity",
  },

  index() {
    App.display(ActivityIndexPage);
  },

  log() {
    // redirect to index
    window.location.href = "#activity";
  },

  activity(activityId, params = {}) {
    App.display(ActivityDetail, {
      action: params.action || undefined,
      activityId: +activityId,
    });
    App.setTitle("Activity Detail");
  },
});

export default () => new ActivityRouter("activity", { createTrailingSlashRoutes: true });
