const getBarStyles = (type) =>
  type === "thin"
    ? {
        height: 8,
        borderRadius: 5,
      }
    : {
        height: 24,
        borderRadius: 12,
      };

export default getBarStyles;
