import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import MaintenanceRecordFragment from "collection/graphql/equipment/fragments/MaintenanceRecordFragment";
import createMaintenanceRecordMutation from "collection/graphql/equipment/mutations/createMaintenanceRecord";
import deleteMaintenanceRecordMutation from "collection/graphql/equipment/mutations/deleteMaintenanceRecord";
import updateMaintenanceRecordMutation from "collection/graphql/equipment/mutations/updateMaintenanceRecord";

const useMaintenanceRecordMutations = () => {
  const create = useMutation(createMaintenanceRecordMutation)[0];
  const destroy = useMutation(deleteMaintenanceRecordMutation)[0];
  const update = useMutation(updateMaintenanceRecordMutation)[0];

  const createMaintenanceRecord = useCallback(async (input) => {
    await create({
      update(cache, { data }) {
        cache.modify({
          fields: {
            getAllMaintenanceRecords: () => {
              cache.writeFragment({
                data: data.maintenanceRecord,
                fragment: MaintenanceRecordFragment,
              });
            },
          },
        });
      },

      variables: {
        input,
      },
    });
  }, []);

  const deleteMaintenanceRecord = useCallback(async (id) => {
    await destroy({
      update(cache) {
        cache.evict({
          id: cache.identify({ id, __typename: "MaintenanceRecord" }),
        });
        cache.gc();
      },

      variables: {
        id,
      },
    });
  }, []);

  const saveMaintenanceRecord = useCallback(async (input) => {
    return input.id ? updateMaintenanceRecord(input) : createMaintenanceRecord(input);
  }, []);

  const updateMaintenanceRecord = useCallback(async (input) => {
    await update({
      optimisticResponse: ({ input }) => ({ maintenanceRecord: input }),
      variables: {
        input,
      },
    });
  }, []);

  return {
    createMaintenanceRecord,
    deleteMaintenanceRecord,
    saveMaintenanceRecord,
    updateMaintenanceRecord,
  };
};

export default useMaintenanceRecordMutations;
