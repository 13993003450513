import CheckedOption, { styles as optionStyles } from "activity/components/choosers/options/CheckedOption";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import ReactSelect, { components } from "react-select";
import styled from "styled-components";

import { useProductById } from "collection/graphql/products/hooks";

import { UIColors } from "components/fl-ui/colors";

const MenuTitle = styled.p`
  font-size: 0.8rem;
  margin: 0;
  padding: 8px;
  text-align: center;
  white-space: normal;
`;

const customComponents = {
  IndicatorSeparator: () => null,
  Placeholder: () => null,
  MenuList: (props) => {
    const unitType =
      props.selectProps.data.product?.commodity?.seedUnitType || props.selectProps.data.product?.unitType;
    const menuTitle =
      {
        COUNT: "This input is measured in units of count.",
        VOLUME: "This input is measured in units of volume.",
        WEIGHT: "This input is measured in units of weight.",
      }[unitType] ?? "";

    return (
      <components.MenuList {...props}>
        <MenuTitle>{menuTitle}</MenuTitle>
        {props.children}
      </components.MenuList>
    );
  },
  Option: (props) => (
    <CheckedOption {...props}>
      <span>
        {props.data.name}
        <small style={{ marginLeft: 10 }}>{props.data.abbr}</small>
      </span>
    </CheckedOption>
  ),
};

const styles = {
  control: (base, { selectProps }) => ({
    ...base,
    borderColor: selectProps.hasError ? UIColors.danger : base.borderColor,
    borderWidth: selectProps.hasError ? 2 : base.borderWidth,
    minHeight: 42,
  }),
  option: optionStyles,
};

const ProductUnitChooser = (props) => {
  const { hasError = false, onChange, onMenuClose = () => {}, options, productId, value } = props;
  const { product } = useProductById(productId);

  return (
    <ReactSelect
      components={customComponents}
      data={{ product }}
      hasError={hasError}
      isDisabled={!product}
      isSearchable={false}
      options={options}
      onMenuClose={onMenuClose}
      getOptionLabel={(option) => option.abbr || option.name}
      getOptionValue={({ value }) => value}
      onChange={({ value }) => onChange(value)}
      styles={styles}
      value={_.find(options, { value }) ?? null}
    />
  );
};

ProductUnitChooser.propTypes = {
  hasError: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onMenuClose: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      abbr: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  productId: PropTypes.number,
  value: PropTypes.string,
};

export default ProductUnitChooser;
