import createReactClass from "create-react-class";
import leaflet from "leaflet";
import Clus from "map/layer/clus";
import PropTypes from "prop-types";
import React from "react";

import MapControl from "components/map/control";
import Layer from "components/map/layer";
import FieldsLayer from "fields/add/FieldsLayer";
import { ControlButton } from "fields/add/helpers";
import { highlightTemporaryLayers } from "fields/utils";

const { object, func } = PropTypes;

const CLU_ZOOM_LEVEL = 14;

const getBounds = function (geometry) {
  const { clientWidth, clientHeight } = document.body;

  const bounds = leaflet.geoJson(geometry).getBounds();
  const paddingBottomRight = clientWidth < 800 ? [0, clientHeight / 2] : [clientWidth / 3, 0];

  return [bounds, { paddingBottomRight }];
};

export default createReactClass({
  displayName: "ClusLayer",

  propTypes: {
    geometry: object,
    onEdit: func,
    onSelect: func,
  },

  contextTypes: {
    map: object,
  },

  getInitialState() {
    return {
      hide: false,
      prevMinZoom: this.context.map.options.minZoom,
    };
  },

  componentDidMount() {
    window.addEventListener("orientationchange", this.updateMap);
    this.context.map
      .on("moveend", this.onMoveEnd)
      .on("clu:selected", this.handleSelect)
      .on("clu:cancelled", this.handleSelect);

    this.onMoveEnd();
  },

  componentDidUpdate(prevProps) {
    if (prevProps.geometry !== this.props.geometry) {
      this.updateMap();
    }
  },

  componentWillUnmount() {
    window.removeEventListener("orientationchange", this.updateMap);
    this.context.map
      .off("moveend", this.onMoveEnd)
      .off("clu:selected", this.handleSelect)
      .off("clu:cancelled", this.handleSelect).options.minZoom = this.state.prevMinZoom;

    this.resetClus();
  },

  updateMap() {
    const { map } = this.context;

    if (this.props.geometry) {
      return (map.fitBounds.apply(map, getBounds(this.props.geometry)).options.minZoom = CLU_ZOOM_LEVEL);
    } else {
      this.resetClus();
      return (map.options.minZoom = this.state.prevMinZoom);
    }
  },

  onMoveEnd() {
    const hide = this.context.map.getZoom() < CLU_ZOOM_LEVEL;

    if (!hide) {
      this.getClus();
    }
    this.setState({ hide });
  },

  getClus() {
    const bounds = this.context.map.getBounds();
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    Clus.load(ne, sw);
  },

  resetClus() {
    Clus.reset();
  },

  handleSelect(event) {
    const { geometry } = event;
    const sanitizedData = this.props.sanitizeLayer(geometry);
    const handleHighlightFinish = (data) => {
      this.props.onSelect(data.geometry || null);
    };

    if (sanitizedData.temporaryFeatures) {
      highlightTemporaryLayers(sanitizedData, this.context.map, handleHighlightFinish);
    } else {
      this.props.onSelect(geometry || null);
    }
  },

  render() {
    if (this.state.hide) {
      return <FieldsLayer />;
    }

    return (
      <span>
        <Layer layer={Clus} />
        <FieldsLayer />

        {!!this.props.geometry && (
          <MapControl position="top">
            <div className="col xs-12">
              <div className="float-right">
                <ControlButton onClick={this.props.onEdit} label="Edit Shape" />
              </div>
            </div>
          </MapControl>
        )}
      </span>
    );
  },
});
