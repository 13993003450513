import { css, StyleSheet } from "aphrodite/no-important";
import Cell, { CellRow } from "marketing/components/ui-elements/Cell";
import React from "react";

import { Card, CardHeader } from "components/fl-ui/Card";
import { Borders, Mixins, Typography } from "components/fl-ui/constants";

const styles = StyleSheet.create({
  detailCard_title: {
    margin: 0,
    lineHeight: 1,
  },
  detailCard_titleValue: {
    fontSize: Typography.sizes.rg,
    lineHeight: 1.142,
  },
  heading: {
    padding: `${Mixins.toRem(10)} 0 ${Mixins.toRem(16)}`,
    borderBottom: Borders.regular,
    marginBottom: Mixins.toRem(16),
  },
});

const DetailCard = ({ children, subtitle, title }) => {
  return (
    <Card>
      <CardHeader>
        <CellRow className={css(styles.heading)}>
          <Cell>
            <h3 className={css(styles.detailCard_title)}>{title}</h3>
          </Cell>
          <Cell className={css(styles.detailCard_titleValue)} right>
            {subtitle}
          </Cell>
        </CellRow>
      </CardHeader>

      {children}
    </Card>
  );
};

export default DetailCard;
