import getMobileForcedAppUpdateState from "mobile/getMobileForcedAppUpdateState";
import PropTypes from "prop-types";
import React, { createContext, useContext, useMemo } from "react";

import useAsync from "hooks/useAsync";
import useReleaseFeature from "hooks/useReleaseFeature";

const mobileForcedAppUpdateContext = createContext(undefined);

const useMobileForcedAppUpdate = () => {
  const context = useContext(mobileForcedAppUpdateContext);
  if (!context) {
    throw new Error("Mobile forced app update context required");
  }
  return context;
};

export default useMobileForcedAppUpdate;

export const MobileForcedAppUpdateContextProvider = ({ children }) => {
  const config = useReleaseFeature("mobile-forced-app-update-config");

  const asyncResult = useAsync(async () => {
    return await getMobileForcedAppUpdateState(config);
  }, [config]);

  const mobileForcedAppUpdateStateContext = useMemo(() => {
    return {
      isLoading: asyncResult.loading,
      state: asyncResult.value,
    };
  }, [asyncResult]);

  return (
    <mobileForcedAppUpdateContext.Provider value={mobileForcedAppUpdateStateContext}>
      {children}
    </mobileForcedAppUpdateContext.Provider>
  );
};

MobileForcedAppUpdateContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
