import context from "activity/components/choosers/CropSelectionMap/context";
import _ from "lodash";
import numeral from "numeral";
import React, { useContext } from "react";
import styled from "styled-components";

import { UIColors } from "components/fl-ui/colors";

const CustomControl = styled((props) => {
  const { acreage, className, onDoneClick, totalCrops } = props;
  const handleClick = (event) => {
    event.preventDefault();
    onDoneClick();
  };

  return (
    <div className={`mapboxgl-ctrl mapboxgl-ctrl-top-left ${className}`}>
      <span className="description">
        <span>
          <span className="label">Field crops:</span> {totalCrops}
        </span>
        <span className="separator">{"\u00b7"}</span>
        <span>
          <span className="label">Area:</span> {numeral(acreage).format("0.0")} ac
        </span>
      </span>
      <span className="button-separator" />
      <a href="#" type="link" onClick={handleClick}>
        Done
      </a>
    </div>
  );
})`
  background: #fff;
  border-radius: 4px;
  font-size: 13px;
  opacity: 0.85;
  padding: 5px 10px;
  transform: translate(10px, 10px) !important;

  > .description {
    .separator {
      margin: 0 4px;
    }
  }
  > .button-separator {
    border-left: 2px solid #ccc;
    margin: 0 5px;
  }
  > a {
    color: ${UIColors.blue};
    font-weight: bold;
  }

  @media only screen and (max-width: 48.75em) {
    align-items: stretch;
    display: flex;

    > .description {
      > span {
        display: block;
      }
      .label {
        font-size: 0.9em;
      }
      .separator {
        display: none;
      }
    }
    a {
      display: block;
      padding: 10px 0 0 3px;
    }
  }
`;

const CropSelectionSummary = (props) => {
  const { onComplete } = props;
  const { selectedCrops } = useContext(context);
  const acreage = _.sumBy(selectedCrops, "acreage");

  return (
    <CustomControl acreage={acreage} totalCrops={selectedCrops.length} onDoneClick={() => onComplete(selectedCrops)} />
  );
};

export default CropSelectionSummary;
