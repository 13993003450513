import { useEffect, useState } from "react";

/**
 * @typedef {Object} StatefulValue
 * @callback mergeValues
 * @property {Object} values
 */

/**
 * Provides a way of managing the state of an object with default values. The values will
 * be changed anytime a new `defaults` object is detected. Also provides a `mergeValues`
 * function that accepts and object to be merged into the current `values`.  Useful for
 * managing form state.
 *
 * @param {Object} [defaults={}]
 * @return {StatefulValue}
 */
const useStatefulValues = (defaults) => {
  const [values, setValues] = useState(defaults || {});
  useEffect(() => {
    if (defaults) {
      setValues(defaults);
    }
  }, [defaults]);

  return {
    mergeValues: (newValues) => setValues((existingValues) => ({ ...existingValues, ...newValues })),
    values,
  };
};

export default useStatefulValues;
