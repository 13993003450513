import styles from "marketing/cards/cardStyles";
import Cell, { CellRow } from "marketing/components/ui-elements/Cell";
import React from "react";
import router from "router";

import { useFarmAdvisorConfig } from "collection/graphql/advisor";

import { Link } from "components/fl-ui";
import { css } from "components/fl-ui/aphrodite";

const CargillCardBottomText = ({ boldText, hasContracts, recommendedAmountIncrease }) => {
  const { programName } = useFarmAdvisorConfig().data;

  return (
    <CellRow className={css(styles.dataRow, styles.noBorderBottom)}>
      <Cell center>
        {recommendedAmountIncrease > 0 ? (
          <span>
            Sell <b>{boldText}</b>
            {hasContracts && (
              <span>
                {" or assign this marketing crop to "}
                <Link className="blue-text" onClick={() => router.navigate("contracts", { trigger: true })}>
                  existing contract(s)
                </Link>
              </span>
            )}{" "}
            to reach your recommended sold level and decrease your risk.
          </span>
        ) : (
          <span>No action necessary to meet {programName} recommendations at this time.</span>
        )}
      </Cell>
    </CellRow>
  );
};

export { CargillCardBottomText };
