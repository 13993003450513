import styled from "styled-components";

import { Button } from "components/fl-ui";
import { UIColors } from "components/fl-ui/colors";
import { Spacing } from "components/fl-ui/constants";

export const HeadingText = styled.h3`
  line-height: 1.3;
`;

export const MapControlButton = styled(Button)`
  margin: ${Spacing.xxsmall} 0;
  padding: ${Spacing.xxsmall} !important;
  pointer-events: all;

  &:first-child {
    margin-top: 0;
  }
`;

export const MapControlLabel = styled.span`
  padding-right: ${Spacing.xsmall};
  ${(props) => !props.icon && `padding-left: ${Spacing.xsmall};`}
  ${(props) => props.color && `color: ${UIColors[props.color] ?? props.color};`}
`;
