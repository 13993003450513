import Backbone from "backbone";
import $ from "jquery";
import ReactDOM from "react-dom";
import _ from "underscore";

export default _.extend(
  {
    componentDidMount() {
      // stolen from Backbone ;-)
      this.$el = $(ReactDOM.findDOMNode(this));
      this.$ = function (selector) {
        return this.$el.find(selector);
      };
      if (this.events) {
        return this._delegateEvents();
      }
    },

    componentWillUnmount() {
      this.stopListening();
      if (this.events) {
        this._undelegateEvents();
      }
      return this.off();
    },

    _delegateEvents() {
      return (() => {
        const result = [];
        for (var selector in this.events) {
          var events = this.events[selector];
          result.push(
            (() => {
              const result1 = [];
              for (let e in events) {
                let cb = events[e];
                if (typeof cb === "string") {
                  cb = this[cb];
                } // handle object references
                result1.push(this.$el.on(e, selector, cb.bind(this)));
              }
              return result1;
            })()
          );
        }
        return result;
      })();
    },

    _undelegateEvents() {
      return this.$el.off();
    },
  },
  Backbone.Events
);
