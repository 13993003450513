import createReactClass from "create-react-class";
import $ from "jquery";
import PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";

export default createReactClass({
  displayName: "Collapsable",
  propTypes: {
    children: PropTypes.any,
    className: PropTypes.string,
    collapse: PropTypes.bool.isRequired,
    duration: PropTypes.number,
    easing: PropTypes.string,
  },

  getDefaultProps() {
    return {
      duration: 200,
      easing: "swing",
      className: "",
    };
  },
  
  UNSAFE_componentWillReceiveProps(newProps) {
    // handle slide up before removing content
    const { collapse, duration, easing } = newProps;
    if (collapse && !this.props.collapse) {
      return $(ReactDOM.findDOMNode(this)).slideUp(duration, easing);
    }
  },

  componentDidUpdate(prevProps) {
    // handle slide down after adding content
    const { collapse, duration, easing } = this.props;
    if (!collapse && prevProps.collapse) {
      return $(ReactDOM.findDOMNode(this)).slideDown(duration, easing);
    }
  },

  render() {
    return <div className={this.props.className}>{!this.props.collapse ? this.props.children : undefined}</div>;
  },
});
