import { sortBy, uniqBy } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import useYearFieldCrops from "modules/fields/hooks/useYearFieldCrops";

const CropSelector = ({ activeCommodity, onChange, year }) => {
  const { crops, loading } = useYearFieldCrops(year);
  const [collapsed, setCollapsed] = useState("collapsed");
  const getCommodities = () => {
    const uniqueYearCommodities = uniqBy(crops, "commodity.id").map(({ commodity }) => ({
      ...commodity,
      className: `crop-color-${commodity.color}`,
    }));

    return [{ className: "all-crops", id: null, name: "All Crops" }, ...sortBy(uniqueYearCommodities, "name")];
  };

  useEffect(() => {
    // It's possible for a commodity to no longer be available when changing
    // years, so this function makes a few checks. If you're not changing years,
    // nothing special is needed. If the year is changing, it sets the active
    // commodity to null (all) if the selected commodity isn't available.
    if (!loading) {
      const hasActiveCommodity = getCommodities().some(({ id }) => id === activeCommodity);

      if (!hasActiveCommodity) {
        setCollapsed("collapsed");
        onChange({ commodity_id: null });
      }
    }
  }, [loading]);

  const setActive = (commodity_id) => {
    // If the commodities are expanded, a click sets the selected commodity
    // via the onChange prop and collapses the list. If not expanded, a click just
    // expands the selector.
    if (collapsed) {
      setCollapsed("");
    } else {
      setCollapsed("collapsed");
      onChange({ commodity_id });
    }
  };

  return (
    <div className="js-commodity-filter">
      <h5 className="small-caps-heading">crop</h5>
      <ul className={`commodity-filter ${collapsed}`}>
        {getCommodities().map((commodity) => {
          const active = activeCommodity === commodity.id ? "active" : "inactive";
          const classes = `commodity-pill ${commodity.className} ${active}`;

          return (
            <li key={commodity.id || "all"} className={classes} onClick={() => setActive(commodity.id)}>
              <div className="text-container">{commodity.name}</div>
              <div className="change-crop">change</div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

CropSelector.defaultProps = {
  onChange: () => {},
};

CropSelector.propTypes = {
  onChange: PropTypes.func,
  year: PropTypes.number,
};

export default CropSelector;
