import { useQuery } from "@apollo/client";

import cache from "collection/graphql/cache";
import { DetailedProduct } from "collection/graphql/products/fragments";
import { getProduct } from "collection/graphql/products/queries";

export const getProductFromCache = (productId) => {
  return ["ChemicalProduct", "FertilizerProduct", "SeedProduct", "WaterProduct"]
    .map((__typename) => {
      if (productId > 0) {
        return cache.readFragment({
          fragment: DetailedProduct,
          id: cache.identify({ id: productId, __typename }),
        });
      }
    })
    .find((x) => x);
};

/**
 * Fetches a product by its id (optionally). If a productId is not provided, no query is issued.
 * Initially looks in the cache before issuing a query to the backend if no matching data is found.
 * @param {Number} [productId] optional product id
 */
const useProductById = (productId) => {
  const cachedProduct = productId > 0 ? getProductFromCache(productId) : null;
  const { data, loading } = useQuery(getProduct, { skip: !productId || !!cachedProduct, variables: { id: productId } });

  return {
    loading,
    product: cachedProduct || data?.product || null,
  };
};

export default useProductById;
