import { gql } from "@apollo/client";

export default gql`
  query GetIntegrations {
    integrations: getIntegrations {
      id
      enabled
      name
      provider
      status
      type
    }
  }
`;
