import commodityFormSchema from "../validation/CommodityFormSchema";
import useStatefulValues from "./useStatefulValues";
import { useQuery } from "@apollo/client";
import _ from "lodash";
import React, { createContext, useContext, useMemo, useState } from "react";

import { GET_COMMODITIES } from "collection/graphql/commodities/queries";
import useSchemaValidation from "hooks/useSchemaValidation";

import useCommoditiesUnitsOptions from "components/commodity/CommodityForm/hooks/useCommoditiesUnitsOptions";

const context = createContext(undefined);

/**
 * @typedef {Object} GenericOption
 * @property {String} label
 * @property {*} value
 */

/**
 * @typedef {Object} CommodityFormContext
 * @property {Function} errorFor returns a string error message if the given field is in error, null otherwise
 * @property {Boolean} isNew true if the current form is new (create)
 * @property {Boolean} isValid true if the entire form is valid
 * @property {Boolean} loading true if the commodities have loaded
 * @property {Function} mergeValues accepts an object with new form field values to be merged into the existing ones
 * @property {GenericOption[]} seedUnits
 * @property {GenericOption[]} seedUnitTypes count or weight
 * @property {Function} touch marks a field as touched (usually onBlur)
 * @property {Object} values the current values fo the form fields
 * @property {GenericOption[]} yieldUnitOptions
 */

/**
 * A hook returning the current {@link CommodityFormContext}.
 * @return {CommodityFormContext}
 */
const useCommodityForm = () => {
  const commodityFormContext = useContext(context);
  if (!commodityFormContext) {
    throw new Error("Invalid context: CommodityFormContext expected");
  }

  return commodityFormContext;
};

export default useCommodityForm;

/**
 * Context provider for {@link CommodityFormContext}.
 * @param {React.Children} children
 * @param {Number} [commodityId] the id of the commodity to edit
 */
export const Provider = ({ children, commodityId }) => {
  const commodities = useQuery(GET_COMMODITIES).data?.commodities;
  const loading = !commodities;
  const isNew = !commodityId;

  /*
   * Form functionality
   */
  const defaultValues = useMemo(() => _.find(commodities, { id: commodityId }), [!commodities]);
  const { mergeValues, values } = useStatefulValues(defaultValues);
  const [touched, setTouched] = useState({});
  const touch = (fieldName) => setTouched((touched) => ({ ...touched, [fieldName]: true }));

  /*
   * Dropdown options
   */
  const { seedUnits, seedUnitTypes, yieldUnitOptions } = useCommoditiesUnitsOptions(values.seedUnitType);

  /*
   * Validation context
   */
  const filteredCommodities = _.reject(commodities, { id: commodityId });
  const { errorsAtPath, isValid: isFormValid } = useSchemaValidation(commodityFormSchema, values, {
    context: {
      existingCommodityAbbrs: _.map(filteredCommodities, "abbr"),
      existingCommodityNames: _.map(filteredCommodities, "name"),
      seedUnits: _.map(seedUnits, "value"),
      yieldUnits: _.map(yieldUnitOptions, "value"),
    },
  });
  const errorFor = (fieldName) => {
    if (fieldName in touched) {
      return errorsAtPath(fieldName)?.[0] ?? null;
    }

    return null;
  };
  const isValid = loading ? false : isFormValid();

  const value = useMemo(
    () => ({
      errorFor,
      isNew,
      isValid,
      loading,
      mergeValues,
      seedUnits,
      seedUnitTypes,
      touch,
      values,
      yieldUnitOptions,
    }),
    [errorFor, isNew, isValid, loading, mergeValues, seedUnits, seedUnitTypes, touch, values, yieldUnitOptions]
  );

  return <context.Provider value={value}>{children}</context.Provider>;
};
