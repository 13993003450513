import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import React from "react";

const CardHeader = createReactClass({
  displayName: "CardHeader",

  propTypes: {
    children: PropTypes.node,
    className: PropTypes.string,
  },

  render() {
    return <div className={`card-title title top ${this.props.className || ""}`}>{this.props.children}</div>;
  },
});

const CardFooter = createReactClass({
  displayName: "CardFooter",

  propTypes: {
    children: PropTypes.node,
    className: PropTypes.string,
  },

  render() {
    return <div className={`card-bottom bottom ${this.props.className || ""}`}>{this.props.children}</div>;
  },
});

const Card = createReactClass({
  displayName: "Card",

  propTypes: {
    children: PropTypes.node,
    className: PropTypes.string,
  },

  statics: {
    Header: CardHeader,
    Footer: CardFooter,
  },

  render() {
    return (
      <div {...this.props} className={`card ${this.props.className || ""}`}>
        <div className="card-body clearfix">{this.props.children}</div>
      </div>
    );
  },
});

export default Card;
