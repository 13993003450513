import BasicSelect from "agreements/forms/components/BasicSelect";
import _ from "lodash";
import moment from "moment";
import React, { Fragment, useState } from "react";

const CompositeDate = (props) => {
  const { day = 1, month = 0, onChange } = props;

  const [date, setDate] = useState({ month: +month, day: +day });

  const days = _.range(1, moment().month(date.month).daysInMonth() + 1).map((key) => ({ key, label: key, value: key }));
  const months = _.range(0, 12).map((key) => ({
    key,
    label: moment().month(key).format("MMMM"),
    value: key,
  }));

  const handleChange = (payload) => {
    if ("month" in payload) {
      const targetMonth = moment().month(payload.month);
      if (date.day > targetMonth.daysInMonth()) {
        payload.day = 1;
      }
    }

    const newDate = {
      ...date,
      ...payload,
    };
    newDate.month = +newDate.month;
    newDate.day = +newDate.day;
    setDate(newDate);
    onChange(newDate);
  };

  return (
    <Fragment>
      <BasicSelect name="month" value={date.month} options={months} onChange={handleChange} minimal />
      <BasicSelect name="day" value={date.day} options={days} onChange={handleChange} minimal />
    </Fragment>
  );
};

export default CompositeDate;
