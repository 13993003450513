import { SupportEmailLink } from "billing/lib";
import { Container, Content, Header, MobileHeader, StyledIcon } from "imagery/styledComponents";
import React from "react";

const BlankSlate = () => (
  <Container>
    <MobileHeader>Coming soon</MobileHeader>
    <StyledIcon icon="camera" />

    <Content>
      <Header>Coming soon</Header>
      <p>
        {"Imagery is typically available within 24 hours of subscribing. Need help? Contact our support team at "}
        <SupportEmailLink />.
      </p>
    </Content>
  </Container>
);

export default BlankSlate;
