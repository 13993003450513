import findLatestActivity from "./findLatestActivity";
import getGDDDefaults from "./getGDDDefaults";
import moment from "moment";

const buildSummaryData = (rawSummaryData, cropYear) => {
  const today = moment();
  const startOfCropYear = moment([1, 1, cropYear].join("/")).format("YYYY-MM-DD");
  const endOfCropYear = moment([12, 31, cropYear].join("/")).format("YYYY-MM-DD");
  const plantingReferenceDate = today.year() === cropYear ? today : moment(endOfCropYear);

  const plantingDate = findLatestActivity(rawSummaryData.plantingActivities, plantingReferenceDate.toDate())?.date;
  const gddDefaults = getGDDDefaults(rawSummaryData);

  return {
    centroid: rawSummaryData.field?.centroid,
    cropYear,
    endOfCropYear,
    fieldId: rawSummaryData.field?.id,
    hasPlantedInYear: !!plantingDate,
    plantingDate: plantingDate ? moment(plantingDate).format("YYYY-MM-DD") : startOfCropYear,
    startOfCropYear,
    ...gddDefaults,
  };
};

export default buildSummaryData;
