import { useMutation } from "@apollo/client";
import ProductPurchaseFormSchema from "products/forms/validation/ProductPurchaseFormSchema";
import validateSchema from "products/forms/validation/validateSchema";
import useSavePurchase from "products/hooks/useSavePurchase";
import React, { useState } from "react";

import deleteProductPurchase from "collection/graphql/products/deleteProductPurchase";

const usePurchaseFormModal = ({ cropYear, onDeleteSuccess, onSaveSuccess, purchase = {} }) => {
  const isCreate = !purchase?.id;
  const executeSave = useSavePurchase(isCreate);
  const deletePurchase = useMutation(deleteProductPurchase)[0];

  const [formFields, setFormFields] = useState(() => {
    if (isCreate) {
      return {
        date: null,
        inputs: [
          {
            amount: undefined,
            amountUnit: purchase.product?.commodity?.defaultSeedPurchaseUnit ?? "",
            cost: "",
            costRate: "",
            product: purchase.product?.id ?? "",
            unitType: purchase.product?.commodity?.seedUnitType || purchase.product?.unitType || "",
          },
        ],
        vendor: "",
      };
    } else {
      return {
        date: purchase.date,
        id: purchase.id,
        vendor: purchase.vendor.name,
        inputs: [
          {
            amount: purchase.amountTotal,
            amountUnit: purchase.amountUnit,
            cost: purchase.costRate === "TOTAL" ? purchase.costTotal : purchase.costPerUnit,
            costRate: purchase.costRate,
            id: purchase.id,
            product: purchase.product?.id ?? "",
            unitType: purchase.product?.commodity?.seedUnitType || purchase.product?.unitType || "",
          },
        ],
      };
    }
  });

  return {
    formFields,
    isCreate,
    isValid: validateSchema(ProductPurchaseFormSchema, formFields).isValid,
    onChange: setFormFields,

    onDelete: async () => {
      await deletePurchase({
        variables: {
          productPurchaseId: purchase.id,
        },
      });
      onDeleteSuccess();
    },

    onSave: async () => {
      const { form, isValid } = validateSchema(ProductPurchaseFormSchema, formFields);
      if (isValid) {
        await executeSave({ cropYear, ...form });
        onSaveSuccess();
      }
    },
  };
};

export default usePurchaseFormModal;
