import styled from "styled-components";
import { flexbox } from "styled-system";

import { Icon } from "components/fl-ui/Icons";
import { Spacing } from "components/fl-ui/constants";

const logoStyles = `
  display: inline-block !important;
  height: ${Spacing.large} !important;
  overflow: visible;
  width: fit-content !important;

  @media only screen and (min-width: 30rem) {
    height: ${Spacing.xxlarge} !important;
  }
`;

export const StyledIcon = styled(Icon)`
  ${logoStyles}
`;

export const StyledIconsContainer = styled.div`
  ${flexbox}
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: ${Spacing.xsmall};
`;
