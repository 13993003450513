import * as Sentry from "@sentry/react";
import Backbone from "backbone";
import $ from "jquery";

export const API_VERSION = "v2.0";
const { CLIENT_VERSION } = process.env;

export const mergeHeaders = (headers = {}) => {
  headers = {
    ...headers,
    "X-Client-Name": "Web",
  };
  if (CLIENT_VERSION) {
    headers["X-Client-Version"] = CLIENT_VERSION;
  }

  return headers;
};

// Because IE9 is stupid, it caches ajax requests. jQuery will append
// a cache bust to the end of all ajax requests to fix this.
$.ajaxSetup({ cache: false });

/*
Ajax

Acts as a proxy to jQuery's ajax method for root application requests. This
allows us to provide some default parameters, and apply API versioning to
urls.
*/
const ajax = function (payload) {
  if (typeof payload === "string") {
    payload = { url: payload };
  }
  payload.headers = mergeHeaders(payload.headers);

  if (!payload.noVersion) {
    payload.url = `/${API_VERSION}${payload.url}`; // if payload.url.match('^/(?!/)')
  }

  return $.ajax(payload).fail(function (xhr) {
    if (xhr.status === 500) {
      Sentry.withScope((scope) => {
        scope.setExtra("payload", payload);
        scope.setExtra("status", `${xhr.status} ${xhr.statusText}`);
        scope.setExtra("response", xhr.responseText);
        Sentry.captureMessage(`Bad request to ${payload.url.split("?")[0]}`);
      });
    }

    return xhr;
  });
};

ajax.API_VERSION = API_VERSION;

Backbone.ajax = function () {
  return ajax.apply($, arguments);
};

export default ajax;
