import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

const SEND_NOTIFICATION = gql`
  mutation sendWorkOrderNotification($message: String, $users: [Int!]) {
    sendWorkOrderNotification(message: $message, users: $users) {
      ok
    }
  }
`;

const withWorkOrderNotification = graphql(SEND_NOTIFICATION, {
  props: ({ mutate }) => ({
    sendWorkOrderNotification: ({ message, users }) =>
      mutate({
        variables: { message, users },
      }),
  }),
});

export default withWorkOrderNotification;
