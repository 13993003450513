import {
  activityTypeFilter,
  commodityFilter,
  endDateFilter,
  equipmentFilter,
  fieldFilter,
  peopleFilter,
  productFilter,
  searchFilter,
  sourceFilter,
  startDateFilter,
  statusFilter,
} from "./filters";

const useFilteredActivities = (activities, filters) => {
  let appliedFilterCount = 0;
  const markFilterAsApplied = () => ++appliedFilterCount;

  activities = activityTypeFilter(activities ?? [], filters, markFilterAsApplied);
  activities = endDateFilter(activities, filters, markFilterAsApplied);
  activities = startDateFilter(activities, filters, markFilterAsApplied);
  activities = productFilter(activities, filters, markFilterAsApplied);
  activities = peopleFilter(activities, filters, markFilterAsApplied);
  activities = sourceFilter(activities, filters, markFilterAsApplied);
  activities = statusFilter(activities, filters, markFilterAsApplied);
  activities = commodityFilter(activities, filters, markFilterAsApplied);
  activities = fieldFilter(activities, filters, markFilterAsApplied);
  activities = equipmentFilter(activities, filters, markFilterAsApplied);
  activities = searchFilter(activities, filters);

  return { activities, appliedFilterCount };
};

export default useFilteredActivities;
