import { query as getWeatherForecast } from "./getWeatherForecast";
import React, { useEffect, useState } from "react";

import useForceUpdate from "hooks/useForceUpdate";
import useReleaseFeature from "hooks/useReleaseFeature";

// CORE-4368
export const cache = new Map();
const ttl = 10 * 60 * 1000; // 10 minutes

export const getForecast = async (fieldId, startDateTime) => {
  const response = await fetch("/v2.0/forecast/graphql", {
    body: JSON.stringify({
      query: getWeatherForecast,
      operationName: "getWeatherForecast",
      variables: { fieldId, startDateTime },
    }),
    method: "POST",
    headers: {
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "X-Client-Version": process.env.CLIENT_VERSION || "dev",
    },
  });

  const responseBody = await response.json();
  if (response.ok) {
    return responseBody.data.forecast;
  }
};

const useFieldForecast = (fieldId, skip, startDateTime) => {
  const [forecast, setForecast] = useState(null);
  const enabled = useReleaseFeature("forecast-api-access");
  const forceUpdate = useForceUpdate();
  const loading = enabled && !forecast;

  useEffect(() => {
    if (enabled && !skip) {
      if (!cache.has(`${fieldId}-${startDateTime}`)) {
        cache.set(
          `${fieldId}-${startDateTime}`,
          getForecast(fieldId, startDateTime).then((forecast) => {
            setTimeout(() => {
              cache.delete(`${fieldId}-${startDateTime}`);
              forceUpdate();
            }, ttl);

            return forecast;
          })
        );
      }

      cache.get(`${fieldId}-${startDateTime}`).then(setForecast);
    }
  }, [enabled, skip, cache.has(`${fieldId}-${startDateTime}`), loading]);

  return forecast ? { enabled, forecast, loading } : { enabled, loading };
};

export default useFieldForecast;
