import _ from "lodash";

import { createActivity } from "collection/graphql/activities";
import { marketingClient } from "collection/graphql/client";

const bulkActivityCreate = async (activityData, bulkAttributes) => {
  const clearInputArea = bulkAttributes.length > 1;
  const activities = bulkAttributes.map((attributes) => {
    const activity = {
      ...activityData,
      ...attributes,
    };

    // FARM-9179 inputs should not have ids on create
    activity.inputs = activityData.inputs.map(({ id, ...input }) => {
      input.area = _.isNil(input.area) || clearInputArea ? null : input.area;
      input.areaUnit = _.isNil(input.area) ? null : input.areaUnit;

      return input;
    });

    return activity;
  });

  const responses = await Promise.allSettled(
    activities.map((activity) => {
      return marketingClient.mutate({
        mutation: createActivity,
        refetchQueries: ["getMinimalActivityList", "getFieldCropsAndActivities"],
        variables: { activity },
      });
    })
  );

  return {
    activities: _.map(responses, "value.data.createActivity.activity"),
    errors: _.map(responses, "reason.message"),
  };
};

export default bulkActivityCreate;
