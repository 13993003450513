import Backbone from "backbone";
import $ from "jquery";
import render from "ui/templates";
import _ from "underscore";

let commodity_id = "all";

export default Backbone.View.extend({
  events: {
    "click .inactive.js-commodity": "changeCommodity",
    "click .active.js-commodity"() {
      return this.$(".commodity-filter").toggleClass("collapsed");
    },

    remove: "remove",
  },

  initialize() {
    return (this.data = { commodity_id });
  },

  serializeData() {
    return _.extend(this.data, {
      selected: this.getData("commodity_id") !== "all",
      commodities: this.getData("commodities").map(function (commodity) {
        return { ...commodity, active: commodity.id === this.getData("commodity_id") };
      }, this),
    });
  },

  render() {
    if (
      this.data.commodity_id !== "all" &&
      !_.any(this.getData("commodities"), (c) => this.data.commodity_id === c.id)
    ) {
      return this.resetCommodity();
    }
    this.$el.html(render("commodity_filter", this.serializeData()));

    return this;
  },

  selectCommodity() {
    return this.$(".js-commodity").each((i, el) => {
      const $el = $(el);
      if ($el.data("id") === this.getData("commodity_id")) {
        return $el.addClass("active").removeClass("inactive");
      } else {
        return $el.removeClass("active").addClass("inactive");
      }
    });
  },

  setData(key, value) {
    this.data[key] = value;

    return this;
  },

  getData(key) {
    return this.data[key];
  },

  resetCommodity() {
    this.setData("commodity_id", "all");
    _.defer(() => this.trigger("change", this.getData("commodity_id")));

    return this;
  },

  changeCommodity(e) {
    commodity_id = this.$(e.target).hasClass("js-close") ? "all" : this.$(e.currentTarget).data("id");
    this.setData("commodity_id", +commodity_id || commodity_id);
    this.selectCommodity();
    return this.trigger("change", this.getData("commodity_id"));
  },
});
