import { css, StyleSheet } from "aphrodite/no-important";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";

const modalRoot = document.getElementById("dialog");

// legacy classes that must be added to keep old page layouts from breaking
const FULLSCREEN_CLASS = "field-add-mode";
const BOTTOM_BAR_CLASS = "field-bottom-bar field-edit-mode";

const styles = StyleSheet.create({
  fullscreen: {
    position: "fixed !important",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 4000,
    backfaceVisibility: "hidden",
  },
});

class Fullscreen extends React.Component {
  constructor(props) {
    super(props);
    this.displayName = "FullScreen";
    this.el = document.createElement("div");
  }

  componentDidMount() {
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el);
  }

  render() {
    const { showBottomBar, className, children, ...otherProps } = this.props;
    return ReactDOM.createPortal(
      <div
        className={classNames(
          css(styles.fullscreen),
          FULLSCREEN_CLASS,
          this.props.showBottomBar && BOTTOM_BAR_CLASS,
          className
        )}
        {...otherProps}
      >
        {children}
      </div>,
      modalRoot
    );
  }
}

Fullscreen.propTypes = {
  showBottomBar: PropTypes.bool,
};

Fullscreen.defaultProps = {
  showBottomBar: false,
};

export default Fullscreen;
