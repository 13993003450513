import { gql } from "@apollo/client";

import { FINANCIAL_TRANSACTION_FRAGMENT } from "collection/graphql/profit_and_loss/fragments";

export default gql`
  mutation CreateTransaction($transaction: CreateFinancialAccountTransactionInput!) {
    createFinancialAccountTransaction(transaction: $transaction) {
      ok
      transaction {
        ...Transaction
      }
    }
  }
  ${FINANCIAL_TRANSACTION_FRAGMENT}
`;
