import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import ReactSelect from "react-select";

import useFormGroupContext from "components/fl-ui/Form/hooks/useFormGroupContext";
import { UIColors } from "components/fl-ui/colors";

const CommodityFormSelect = ({ options, value, ...props }) => {
  const { hasError } = useFormGroupContext();
  const styles = {
    control: (provided) => {
      const alteredStyles = { ...provided };
      if (hasError) {
        /**
         * These styles are copied from {@link BorderedInputContainer}
         */
        alteredStyles.borderColor = UIColors.danger;
        alteredStyles.borderWidth = 2;
      }

      return alteredStyles;
    },
  };

  return (
    <ReactSelect
      {...props}
      getOptionLabel={_.property("label")}
      getOptionValue={_.property("value")}
      options={options}
      styles={styles}
      value={_.find(options, { value }) || null}
    />
  );
};

CommodityFormSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })
  ),
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.any,
};

export default CommodityFormSelect;
