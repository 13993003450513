import styled from "styled-components";

import { Button } from "components/fl-ui";
import { Spacing, Typography } from "components/fl-ui/constants";

const MenuItem = styled(Button)`
  display: flex !important;
  font-size: ${Typography.sizes.rg};
  font-weight: ${Typography.weights.regular};
  justify-content: space-between;
  margin-bottom: ${Spacing.xsmall};
  min-height: ${Spacing.xxlarge};
  width: 100%;
  &:last-child {
    margin-bottom: 0;
  }
`;

export default MenuItem;
