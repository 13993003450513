import * as turf from "@turf/turf";
import leaflet from "leaflet";
import _ from "lodash";

import MapUtils from "lib/map/utils";

import { CropsColorPalette } from "components/fl-ui/colors/palette";

const { forceRHR, roundGeometry } = MapUtils;

class GeoJsonLayer extends leaflet.Layer {
  initialize(options) {
    if (options) {
      _.extend(this, options);
    }
    this.features = {};
    options?.feature
      ? this.addFeature(options.feature)
      : options?.geometry?.features?.forEach((feature) => this.addFeature(feature));
  }

  addEvents(events) {
    this.events = events;
  }

  addFeature(feature, options) {
    const id = this.getId(feature);
    const map = this._map;

    if (!options) {
      options = {};
    } else {
      _.extend(this, options);
    }

    options = _.defaults(options, { replace: true });
    feature.geometry = forceRHR(roundGeometry(feature.geometry));
    this.dismount(map);

    if (id && (options.replace || !this.features[id])) {
      this.features[id] = feature;
      const shadowCircleId = `${id}_shadowCircle`;

      if (feature.properties?.radius) {
        this.features[shadowCircleId] = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: feature.properties.centroid?.coordinates ?? feature.properties.centroid,
          },
          properties: { ...feature.properties, id: shadowCircleId },
        };
      } else if (this.features[shadowCircleId]) {
        this.removeFeature(shadowCircleId);
      }
    }

    // Add layer back after updating the feature
    map && this.addTo(map);

    return this;
  }

  dismount(map) {
    // removes layer from map if it has already been added so map can be visually updated
    if (map) {
      const features = this.features;
      this.removeFrom(map);
      this.features = features;
    }
  }

  getCenter(id) {
    const feature = this.features[id];
    if (feature) {
      return turf.center(feature).geometry.coordinates;
    }
  }

  getColor(properties) {
    return CropsColorPalette[properties?.color] || properties?.color || "gray";
  }

  getId({ properties }) {
    return properties.id || properties.field?.id;
  }

  onAdd(map) {
    // if only layer is required without map
    if (this.isLayerOnly) {
      map.getPane("tilePane").style.display = "none";
    }

    this.layers = leaflet
      .geoJSON(Object.values(this.features), {
        onEachFeature: this.onEachFeature,
        pointToLayer: ({ properties }, latLng) =>
          leaflet.circle(latLng, { radius: properties?.radius || 0, fill: false }),
        style: ({ properties }) => ({
          color: (this.hasOutline || this.isOutline) && this.getColor(properties),
          fillColor: this.getColor(properties),
          fillOpacity: this.isOutline ? 0 : 0.7,
          ...(this.style || {}),
          ...(properties?.style || {}),
        }),
      })
      .addTo(map);
  }

  onEachFeature = (feature, layer) => {
    if (this.events) {
      layer.on(this.events);
    }
  };

  onRemove(map) {
    map.removeLayer(this.layers);
    this.layers = null;
    this.features = {};
    this.events = null;
  }

  removeFeature(id) {
    // id can be field id or crop / other layer id
    const shadowCircleId = `${id}_shadowCircle`;
    const map = this._map;
    this.dismount(map);

    if (this.features[id]) {
      delete this.features[id];
      delete this.features[shadowCircleId];
    } else {
      for (const key in this.features) {
        const { properties } = this.features[key];
        if (properties.id === id || properties?.field?.id === id) {
          delete this.features[key];
          delete this.features[shadowCircleId];
        }
      }
    }

    map && this.addTo(map);
    return this;
  }
}

export default GeoJsonLayer;
