import { useQuery } from "@apollo/client";
import MapChooser from "activity/components/choosers/CropSelectionMap/MapChooser";
import FieldCropDropdownMenu from "activity/components/choosers/FieldCropChooser/FieldCropDropdownMenu";
import FieldCropSelection from "activity/components/choosers/FieldCropChooser/FieldCropSelection";
import useFieldCropOptions from "activity/components/choosers/FieldCropChooser/useFieldCropOptions";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { getFieldCropChooserData } from "collection/graphql/fields/queries";
import useCurrentCropYear from "hooks/useCurrentCropYear";

const useMenuControl = ({ onMenuClose, onMenuOpen }) => {
  const [menuState, setMenuState] = useState("closed");
  const controlContainerRef = useRef();
  const menuContainerRef = useRef();
  const mapContainerRef = useRef();

  const close = () => {
    setMenuState("closed");
    onMenuClose();
  };

  useEffect(() => {
    const onDocumentClick = (event) => {
      const isTargetInDocument = document.body.contains(event.target);
      if (isTargetInDocument && menuState !== "closed") {
        const isOutsideClick = [controlContainerRef, menuContainerRef, mapContainerRef].every(
          ({ current }) => !current?.contains(event.target)
        );
        if (isOutsideClick) {
          close();
        }
      }
    };

    document.addEventListener("click", onDocumentClick);
    return () => document.removeEventListener("click", onDocumentClick);
  }, [menuState]);

  return {
    close,
    controlContainerRef,
    mapContainerRef,
    menuContainerRef,
    isMapOpen: () => menuState === "map",
    isMenuOpen: () => menuState === "menu",
    showMap: () => setMenuState("map"),
    showMenu: () => {
      setMenuState("menu");
      onMenuOpen();
    },
  };
};

const FieldCropChooser = (props) => {
  const cropYear = useCurrentCropYear()[0];
  const { data } = useQuery(getFieldCropChooserData, { variables: { cropYear } });
  const fields = data?.fields ?? [];
  const fieldCrops = data?.fieldCrops ?? [];
  const { onAddCrop, onMenuClose, onMenuOpen, search, singleSelect } = props;
  const { addFieldCrops, handleChange, loading, options, optionType, removeFieldCrops, setOptionType, value } =
    useFieldCropOptions({ ...props, fields, fieldCrops });
  const menuControl = useMenuControl({ onMenuClose, onMenuOpen });

  return (
    <div>
      <FieldCropSelection
        menuControl={menuControl}
        onChange={handleChange}
        placeholder={loading ? "Loading crops..." : undefined}
        value={value}
      />

      {menuControl.isMenuOpen() && (
        <FieldCropDropdownMenu
          menuControl={menuControl}
          onAddFieldCrops={addFieldCrops}
          onClose={() => menuControl.close()}
          onCreateFieldCrop={onAddCrop}
          onRemoveFieldCrops={removeFieldCrops}
          options={options}
          optionType={optionType}
          setOptionType={setOptionType}
          search={search}
          singleSelect={singleSelect}
          value={value}
        />
      )}

      {menuControl.isMapOpen() && (
        <MapChooser
          fieldCrops={fieldCrops}
          fields={fields}
          fieldCropSelection={value}
          menuControl={menuControl}
          onChange={(selectedCrops) => handleChange(selectedCrops)}
        />
      )}
    </div>
  );
};

FieldCropChooser.propTypes = {
  onAddCrop: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onMenuOpen: PropTypes.func,
  search: PropTypes.object.isRequired,
  singleSelect: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.number).isRequired,
};

FieldCropChooser.defaultProps = {
  onMenuOpen: () => {},
  singleSelect: false,
};

export default FieldCropChooser;
