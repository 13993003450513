import { Label } from "agreements/forms/components";
import React from "react";

import { TextArea } from "components/fl-ui/Form";

const NotesFieldset = ({ notes = "", onChange }) => {
  return (
    <div>
      <Label>Notes (optional)</Label>
      <TextArea name="notes" value={notes || ""} onChange={(event, payload) => onChange(payload)} />
    </div>
  );
};

export default NotesFieldset;
