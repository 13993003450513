import MapUtils from "lib/map/utils";

import useFieldData from "fields/hooks/useFieldData";

export const getIconPath = (geometry, options = {}) => {
  const [lng, lat] = geometry.coordinates;
  const { height, width, zoom } = { height: 250, width: 450, zoom: 16, ...options };

  return MapUtils.getImageUrl({ height, lat, lng, width, zoom });
};

const useIconPath = (noteData) => {
  const noteGeometry = noteData?.geometry;
  const { fields } = useFieldData(!!noteGeometry);
  const fieldGeometry = fields?.find((field) => [noteData?.field_id, noteData?.fieldId].includes(field.id))?.geometry;
  const geometry = noteGeometry || fieldGeometry;

  return geometry ? getIconPath(geometry) : null;
};

export default useIconPath;
