import MaintenanceRecordForm from "equipment/forms/MaintenanceRecordForm";
import maintenanceRecordSchema from "equipment/forms/validation/maintenanceRecordSchema";
import validateSchema from "products/forms/validation/validateSchema";
import PropTypes from "prop-types";
import React from "react";

import usePermissions from "hooks/usePermissions";
import App from "layout/app";

import { Button } from "components/fl-ui";
import { CloseX } from "components/fl-ui/Icons";
import { Cluster } from "components/fl-ui/Layout";
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "components/fl-ui/Modal/Modal";

const MaintenanceRecordModal = (props) => {
  const { fields, onClose, onChange, onDelete, onSave } = props;
  const { id } = fields ?? {};
  const isCreate = !id;
  const canDelete = usePermissions().canDelete("equipment");

  const { form, isValid } = validateSchema(maintenanceRecordSchema, fields);

  const handleSubmit = (event) => {
    event.preventDefault();
    onSave(form);
  };

  const handleDelete = () => {
    App.confirm({
      confirm: "DELETE",
      message: "Delete this maintenance record and all of its associated data?",
      title: "Are you sure?",
    }).then(onDelete);
  };

  return (
    <Modal id="maintenanceRecordFormModal" width={500}>
      <ModalHeader>
        <ModalTitle>{isCreate ? "New maintenance record" : "Edit maintenance record"}</ModalTitle>
        <CloseX onClick={onClose} />
      </ModalHeader>

      <ModalBody>
        <MaintenanceRecordForm
          formFields={fields}
          formId="maintenanceRecordForm"
          onChange={(updatedFields) =>
            onChange((fields) => ({
              ...fields,
              ...updatedFields,
            }))
          }
          onSubmit={handleSubmit}
        />
      </ModalBody>

      <ModalFooter>
        <Cluster between={!isCreate} end={isCreate}>
          {!isCreate && canDelete && (
            <Button color="danger" link onClick={handleDelete} type="button">
              Delete
            </Button>
          )}

          <span>
            <Button color="default" onClick={onClose} type="button">
              Cancel
            </Button>{" "}
            <Button color="primary" disabled={!isValid} form="maintenanceRecordForm" type="submit">
              Save
            </Button>
          </span>
        </Cluster>
      </ModalFooter>
    </Modal>
  );
};

MaintenanceRecordModal.propTypes = {
  fields: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default MaintenanceRecordModal;
