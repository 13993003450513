import Big from "big.js";
import moment from "moment";
import _ from "underscore";

import Collection from "lib/collection";
import Convert from "lib/conversion";
import Model from "model/inventory_transaction";

const InventoryTransactionsCollection = Collection.extend({
  name: "inventory_transactions",
  model: Model,
  url: "/api/inventory_transactions",

  comparator(a, b) {
    // Sort by date
    if (a.get("date") > b.get("date")) {
      return -1;
    }
    if (a.get("date") < b.get("date")) {
      return 1;
    }
    // If same day, sort by id
    if (a.id > b.id) {
      return -1;
    }
    return 1;
  },

  // Input: id, inventory node id (int)
  // Output: all transactions that had id as the source or destination
  getNodeTransactions(id) {
    return this.filter((t) => t.get("source_id") === id || t.get("destination_id") === id);
  },

  getAvgMoisture(options, bushelSize) {
    const total_yield = this.getAmountLbs(options, bushelSize);

    return _.sum(this.where(options), (l) => l.get("moisture") * (l.getAmountLbs(bushelSize) / total_yield));
  },

  getAmountLbs(options, bushelSize) {
    return _.sum(this.where(options), (val) => Number(val.getAmountLbs(bushelSize)));
  },

  /**
   * Get current balance of a bin or buyer by node id
   *
   * @param {number} nodeId
   * @param {string|null} [unit=null]
   * @param {boolean} [useTrueBalance=false]
   * @param {function} [getBushelSizeByCommodityId=() => {}]
   * @return {*|number}
   */
  getNodeBalance(nodeId, unit = null, useTrueBalance = false, getBushelSizeByCommodityId = () => {}) {
    let balance;
    let last;
    for (const t of Array.from(this.getNodeTransactions(nodeId).reverse())) {
      // reverse the date's sort order
      // reset balance when commodity changes
      if (t.get("commodity_id") !== last?.get("commodity_id")) {
        balance = 0;
      }

      const bushelSize = getBushelSizeByCommodityId(t.get("commodity_id"));
      const amountInLbs = t.getAmountLbs(bushelSize) || 0;
      if (t.get("source_id") === nodeId) {
        balance = new Big(balance).minus(amountInLbs).toNumber();
      }
      if (t.get("destination_id") === nodeId) {
        balance = new Big(balance).plus(amountInLbs).toNumber();
      }
      // unless we want a "true balance", zero out balance if below 0
      if (!useTrueBalance && balance < 0) {
        balance = 0;
      }

      last = t;
    }
    balance = Math.floor(balance);

    if (unit && getBushelSizeByCommodityId) {
      return Convert.to(unit, balance, getBushelSizeByCommodityId(this.getNodeCommodityId(nodeId)));
    }
    return balance;
  },

  getTotalBalance(ids, unit, getBushelSize) {
    return _.sum(ids, (id) => {
      const nodeBalance = this.getNodeBalance(id, unit, false, getBushelSize);

      if (nodeBalance >= 0) {
        return nodeBalance;
      }
    });
  },

  getNodeCommodityId(id) {
    if (this.getNodeBalance(id) > 0) {
      return this.getNodeTransactions(id)[0]?.get("commodity_id");
    }
  },

  getTotalBalanceByCommodity(ids, unit, getBushelSize) {
    let nodes = _.filter(ids, (i) => this.getNodeBalance(i) > 0);
    const groups = _.groupBy(nodes, (n) => this.getNodeCommodityId(n));

    return (() => {
      const result = [];
      for (const id in groups) {
        nodes = groups[id];
        result.push({
          id,
          balance: this.getTotalBalance(nodes, unit, getBushelSize),
        });
      }
      return result;
    })();
  },

  // getDateRange: id (optional) -> object {endDate: , startDate:}
  // Input: id, an optional inventory node id (int)
  // Output: if there is an id, the start and end date of all the transactions
  //         with id as a source or dest. Otherwise, the start and end date of
  //         all transactions
  getDateRange(id) {
    let dates;
    if (id) {
      dates = this.getNodeTransactions(id)
        .map((t) => t.get("date"))
        .sort();
      if (dates.length) {
        return {
          endDate: moment(dates[dates.length - 1]),
          startDate: moment(dates[0]),
        };
      } else {
        return {
          endDate: moment(),
          startDate: moment().subtract(6, "months"),
        };
      }
    } else {
      dates = this.pluck("date").sort();
      return {
        endDate: moment(dates[dates.length - 1]),
        startDate: moment(dates[0]),
      };
    }
  },

  // Output: New object of data for deliveries tables
  getDeliveries(getCropByInventoryNode) {
    const transactions = this.models;
    const result = [];

    for (const t of Array.from(transactions)) {
      const destinationNodeDetails = t.getNodeDetails(t.get("destination_id")) ?? {};
      const { node: destination, path: destinationPath, type: destinationType } = destinationNodeDetails;
      const sourceNodeDetails = t.getNodeDetails(t.get("source_id"), getCropByInventoryNode) ?? {};
      const { node: source, path: sourcePath, type: sourceType } = sourceNodeDetails;
      const fieldId = sourceType === "crop" ? source.field.id : undefined;
      const isTransfer = () => {
        if (sourceType && destinationType) {
          return destinationType === "bin" && sourceType === "bin";
        }

        return destinationType !== "buyer";
      };

      const item = {
        incoming: destinationType === "bin" && sourceType === "crop",
        outgoing: destinationType === "buyer",
        transfer: isTransfer(),
        fieldId,
        source_name: fieldId ? source.field.name : source?.get("name"),
        source_id: fieldId ?? source?.get("id"),
        source_group_name: (fieldId ? source.field.group?.name : source?.get("group")) || "",
        source_path: fieldId ? `fields/${source.field.id}` : sourcePath,
        destination_id: destination?.get("local_bid_id") ?? destination?.get("id") ?? undefined,
        destination_name: destination?.get("name"),
        destination_group_name: destination?.get("city") ?? destination?.get("group") ?? "",
        dest_path: destinationPath || undefined,
        commodity_id: t.get("commodity_id"),
        amount: t.get("amount"),
        amount_formatted: _.numberFormat(Number(t.get("amount"))),
        amount_unit: t.get("amount_unit"),
        image: t.get("image"),
        id: t.get("id"),
        date: t.get("date"),
        date_formatted: t.formatDate(),
        delivery_month: destinationType === "buyer" ? moment(t.get("date")).format("M") : undefined,
        revenue: _.numberFormat(t.get("price") * t.get("amount"), 2),
      };

      result.push(item);
    }
    return result;
  },

  getLoadsData(crop, unit) {
    const node_id = crop.inventoryNodeId;
    const yieldUnit = crop.commodity?.defaultYieldUnit || "BUSHEL";
    const loads = this.where({ source_id: node_id });
    const bushelSize = crop.commodity?.bushelSize;
    /*
     * this function takes a commodityId argument to facilitate lookup but it's not needed here since we are already
     * pulling bushelSize from the commodity on the crop.
     */
    const getBushelSizeCallback = bushelSize > 0 ? () => bushelSize : undefined;

    if (loads.length) {
      // negate since they're outgoing transactions
      const amount = Convert.to(unit, -this.getNodeBalance(node_id, null, true, getBushelSizeCallback), bushelSize);

      return {
        avgMoisture: this.getAvgMoisture({ source_id: node_id }, bushelSize),
        yieldAmount: amount / crop.acreage,
        yieldRate: "PER_ACRE",
        yieldUnit,
        source: _.pluralize("harvest loads", loads.length, true),
      };
    }
  },
});
export default new InventoryTransactionsCollection();
