import { StyleSheet } from "aphrodite";

import { UIColors } from "components/fl-ui/colors/index";
import { Typography, Spacing, Mixins } from "components/fl-ui/constants/index";

const dashboardStyles = StyleSheet.create({
  addCropButton: {
    marginRight: "1em !important",
  },
  blankslateContainer: {
    alignItems: "center",
    display: "flex",
    flexFlow: "column",
    textAlign: "center",
    paddingTop: Spacing.large,
    paddingBottom: Spacing.xxxlarge,
    "@media only screen and (max-width: 30em)": {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  blankslateLink: {
    color: `${UIColors.blue}`,
    textDecoration: "none",
  },
  blankslateText: {
    maxWidth: Mixins.toRem(600),
    fontSize: Typography.sizes.lg,
    lineHeight: 1.375,
    marginBottom: Spacing.large,
  },
  blankslateTitle: {
    fontSize: Typography.sizes.xxl,
  },
  headerControlsWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    "@media only screen and (max-width: 30em)": {
      width: "100%",
      justifyContent: "space-between",
    },
  },
});

export default dashboardStyles;
