import PropTypes from "prop-types";
import React from "react";

import useEnterpriseFeature from "hooks/useEnterpriseFeature";

import PayGateAdBanner from "components/advertisements/banners/PayGateAdBanner";
import { Button } from "components/fl-ui";
import { Cluster } from "components/fl-ui/Layout";

const CommandButtonBar = ({ disableSave, onCancelClick, onDeleteClick, onSaveClick }) => {
  const showDeleteButton = typeof onDeleteClick === "function";

  return useEnterpriseFeature("land_costs") ? (
    <Cluster between={showDeleteButton} end={!showDeleteButton}>
      {showDeleteButton && (
        <a id="delete-contract-button" className="red-link float-left" onClick={onDeleteClick}>
          <b>Delete agreement</b>
        </a>
      )}

      <Cluster end>
        <Button color="primary" onClick={onCancelClick} link>
          Cancel
        </Button>
        <Button color="primary" disabled={disableSave} onClick={onSaveClick}>
          Save agreement
        </Button>
      </Cluster>
    </Cluster>
  ) : (
    <PayGateAdBanner />
  );
};

CommandButtonBar.propTypes = {
  disableSave: PropTypes.bool,
  onCancelClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func,
  onSaveClick: PropTypes.func.isRequired,
};

CommandButtonBar.defaultProps = {
  disableSave: false,
};

export default CommandButtonBar;
