import LeaseAgreementForm, { getLeaseAgreementDefaults } from "agreements/forms/LeaseAgreementForm";
import LoanAgreementForm, { getLoanAgreementDefaults } from "agreements/forms/LoanAgreementForm";
import RevenueAgreementForm, { getRevenueAgreementDefaults } from "agreements/forms/RevenueAgreementForm";
import _ from "lodash";
import React, { useState } from "react";
import router from "router";

import useEnterpriseFeature from "hooks/useEnterpriseFeature";

import PayGateAdBanner from "components/advertisements/banners/PayGateAdBanner";
import GroupedButtons from "components/fl-ui/Buttons/GroupedButtons";
import { Stack } from "components/fl-ui/Layout";

const LOAN = "LOAN";
const LEASE = "LEASE";
const REVENUE = "REVENUE";

const agreementTypes = [
  { id: LOAN, label: "Loan" },
  { id: LEASE, label: "Rent or lease" },
  { id: REVENUE, label: "Non-crop revenue" },
];

const CreateAgreementForm = () => {
  const [agreementType, setAgreementType] = useState(LOAN);
  const [allFields, setAllFields] = useState({
    [LOAN]: getLoanAgreementDefaults(),
    [LEASE]: getLeaseAgreementDefaults(),
    [REVENUE]: getRevenueAgreementDefaults(),
  });

  const formProps = {
    fields: allFields[agreementType],
    onCancel: () => router.navigate("land_costs", { trigger: true }),
    onSaveSuccess: () => router.navigate("land_costs", { trigger: true }),
    onChange: (changes) => {
      const updatedFields = _.defaultsDeep({}, changes, allFields[agreementType]);
      if ("paymentSchedules" in changes) {
        updatedFields.paymentSchedules = changes.paymentSchedules;
      }
      if ("terms" in changes) {
        updatedFields.terms = changes.terms;
      }
      if ("fields" in changes) {
        updatedFields.fields = _.cloneDeep(changes.fields);
      }

      setAllFields({
        ...allFields,
        [agreementType]: updatedFields,
      });
    },
  };

  return useEnterpriseFeature("land_costs") ? (
    <Stack>
      <GroupedButtons
        title="Agreement type"
        selected={{ id: agreementType }}
        options={agreementTypes}
        onClick={({ id }) => setAgreementType(id)}
        borderless
      />

      {agreementType === "LOAN" && <LoanAgreementForm {...formProps} />}
      {agreementType === "LEASE" && <LeaseAgreementForm {...formProps} />}
      {agreementType === "REVENUE" && <RevenueAgreementForm {...formProps} />}
    </Stack>
  ) : (
    <PayGateAdBanner />
  );
};

export default CreateAgreementForm;
