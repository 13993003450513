import PropTypes from "prop-types";
import React from "react";

import { Button } from "components/fl-ui";
import { CloseX } from "components/fl-ui/Icons";
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "components/fl-ui/Modal/Modal";
import ModalActionButtonGroup from "components/fl-ui/Modal/ModalActionButtonGroup";

const DataIntegrationsModal = ({ children, disabled, onClose, onReset, onSubmit, resetText, submitText, title }) => (
  <Modal>
    <ModalHeader>
      <ModalTitle>{title}</ModalTitle>
      <CloseX onClick={onClose} />
    </ModalHeader>

    <ModalBody>{children}</ModalBody>

    <ModalFooter>
      <ModalActionButtonGroup>
        {onSubmit && (
          <Button color="primary" disabled={disabled} onClick={onSubmit}>
            {submitText || "Done"}
          </Button>
        )}

        <Button color="primary" hollow onClick={onClose}>
          {onSubmit ? "Cancel" : "Close"}
        </Button>

        {onReset && (
          <Button color="danger" disabled={disabled} onClick={onReset}>
            {resetText || "Reset"}
          </Button>
        )}
      </ModalActionButtonGroup>
    </ModalFooter>
  </Modal>
);

DataIntegrationsModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  disabled: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  onSubmit: PropTypes.func,
  resetText: PropTypes.string,
  submitText: PropTypes.string,
  title: PropTypes.string.isRequired,
};

DataIntegrationsModal.defaultProps = {
  disabled: false,
};

export default DataIntegrationsModal;
