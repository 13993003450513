import chooserStyles from "activity/components/choosers/FieldCropChooser/chooserStyles";
import useActivitiesProvider from "activity/hooks/useActivitiesProvider";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import ReactSelect from "react-select";

const ActivityFieldChooser = (props) => {
  const { onChange, value } = props;

  const { allActivities } = useActivitiesProvider();
  const allFields = useMemo(() => {
    const allFields = _.map(allActivities, "field");
    const uniqueFields = _.uniqBy(allFields, "id");
    const sortedFields = _.sortBy(uniqueFields, ({ name }) => name.toLowerCase());

    return sortedFields;
  }, [allActivities]);
  const componentValue = value.map((id) => _.find(allFields, { id })).filter((x) => x);

  return (
    <ReactSelect
      getOptionLabel={_.property("name")}
      getOptionValue={_.property("id")}
      isMulti
      onChange={(selectedOptions) => onChange(_.map(selectedOptions, "id"))}
      options={allFields}
      styles={chooserStyles}
      value={componentValue}
    />
  );
};

ActivityFieldChooser.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default ActivityFieldChooser;
