import IndigoEnrollment from "carbon_enrollment/pages/IndigoEnrollment";
import NoriEnrollment from "carbon_enrollment/pages/NoriEnrollment";
import RegrowEnrollment from "carbon_enrollment/pages/RegrowEnrollment";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import router from "router";

import { withSuspenseWrapper } from "components/fl-ui/LoadingWrapper";

const CommonEnrollmentPage = withSuspenseWrapper(({ clientId }) => {
  const ClientComponent = useMemo(() => {
    const clients = {
      indigo: IndigoEnrollment,
      nori: NoriEnrollment,
      regrow: RegrowEnrollment,
    };
    return clients[clientId];
  }, [clientId]);

  if (!ClientComponent) {
    router.navigate("fields", { replace: true, trigger: true });
    return null;
  }

  return <ClientComponent />;
});

CommonEnrollmentPage.propTypes = {
  clientId: PropTypes.oneOf(["indigo", "nori", "regrow"]).isRequired,
};

export default CommonEnrollmentPage;
