import DesktopMenuItem from "./DesktopMenuItem";
import { MediaAware } from "@farmlogs/fl-ui";
import React from "react";
import styled from "styled-components";

import { Logo } from "components/fl-ui/Icons";
import DesktopMenuItemHeader from "components/fl-ui/Layout/Navigation/DesktopMenu/DesktopMenuItemHeader";
import DesktopMenuItems from "components/fl-ui/Layout/Navigation/DesktopMenu/DesktopMenuItems";
import DesktopNavigationMenuContainer from "components/fl-ui/Layout/Navigation/components/DesktopNavigationMenuContainer";
import useMenuItems from "components/fl-ui/Layout/Navigation/hooks/useMenuItems";
import { Borders, Spacing } from "components/fl-ui/constants";

const DesktopLogoContainer = styled.div`
  border-bottom: ${Borders.regular};
  margin-bottom: ${Spacing.xsmall};
  padding: ${[Spacing.medium, 0, Spacing.medium, Spacing.small].join(" ")};
`;

const DesktopMenu = () => {
  const { expenses, farm, revenue, activeMenuItemId } = useMenuItems();

  return (
    <MediaAware desktop>
      <DesktopNavigationMenuContainer>
        <DesktopLogoContainer>
          <Logo />
        </DesktopLogoContainer>

        <DesktopMenuItems>
          <DesktopMenuItemHeader>Farm</DesktopMenuItemHeader>
          {farm.map((menuItem) => (
            <DesktopMenuItem {...menuItem} active={activeMenuItemId === menuItem.id} key={`farm${menuItem.id}`} />
          ))}

          <DesktopMenuItemHeader>Revenue</DesktopMenuItemHeader>
          {revenue.map((menuItem) => (
            <DesktopMenuItem {...menuItem} active={activeMenuItemId === menuItem.id} key={`revenue${menuItem.id}`} />
          ))}

          <DesktopMenuItemHeader>Expenses</DesktopMenuItemHeader>
          {expenses.map((menuItem) => (
            <DesktopMenuItem {...menuItem} active={activeMenuItemId === menuItem.id} key={`expenses${menuItem.id}`} />
          ))}
        </DesktopMenuItems>
      </DesktopNavigationMenuContainer>
    </MediaAware>
  );
};

export default DesktopMenu;
