import { gql } from "@apollo/client";

export default gql`
  fragment FieldCrop on Crop {
    acreage
    cropYear
    geometry
    geometricCircumference
    id
    inventoryNodeId
    status
    commodity {
      ...Commodity
    }
    field {
      id
      name
      group {
        id
        name
      }
    }
  }
`;
