import styles from "./styles";
import { css } from "aphrodite/no-important";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";

import { Button } from "components/fl-ui";

const GroupedButtons = ({
  borderless,
  hasDescription,
  onClick,
  options,
  selected,
  style = {},
  title,
  titleStyle = {},
}) => (
  <div className={css(!borderless && styles.groupedButtonContainer)} style={style}>
    {(title || hasDescription) && (
      <div className={css(styles.groupedButtonHeader)}>
        {title && (
          <label className={css(styles.groupedButtonHeaderLeft)} style={titleStyle}>
            {title}
          </label>
        )}
        {hasDescription && selected && <p>{_.capitalize(selected.name)}</p>}
      </div>
    )}
    <div className={css(styles.buttonGroup)}>
      {options.map((option) => (
        <Button
          className={css(
            styles.groupedButtonMargin,
            option.id !== (selected && selected.id) && styles.regularTextColor
          )}
          color={option.id === (selected && selected.id) ? "primary" : "secondary"}
          key={option.id}
          onClick={() => onClick(option)}
        >
          {(!_.isNil(option.label) && `${option.label}`) || option.symbol}
        </Button>
      ))}
    </div>
  </div>
);

GroupedButtons.propTypes = {
  borderless: PropTypes.bool,
  hasDescription: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  selected: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  title: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default GroupedButtons;
