/* eslint-disable react/display-name */
import { CapsHeader } from "../Helpers";
import { ApolloProvider } from "@apollo/client";
import chooserStyles from "activity/components/choosers/FieldCropChooser/chooserStyles";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import ReactSelect from "react-select";

import { withAgreements } from "collection/graphql/agreements";
import marketingClient from "collection/graphql/client/marketingClient";

export const LandAgreementFilter = (props) => {
  const {
    data: { agreements },
    onParamChanged,
    param: { id, title },
  } = props;

  return (
    <div>
      <CapsHeader>{title}</CapsHeader>
      <ReactSelect
        getOptionLabel={(option) => option.id}
        getOptionValue={(option) => option.id}
        isMulti
        onChange={(agreements) =>
          onParamChanged({
            id,
            value: _.map(agreements, "id"),
          })
        }
        options={agreements}
        styles={chooserStyles}
      />
    </div>
  );
};

LandAgreementFilter.propTypes = {
  onParamChanged: PropTypes.func.isRequired,
  param: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

const WrappedLandAgreementsFilter = withAgreements(LandAgreementFilter);

export default (props) => (
  <ApolloProvider client={marketingClient}>
    <WrappedLandAgreementsFilter {...props} />
  </ApolloProvider>
);
