import MobilePaymentError from "billing/exceptions/MobilePaymentError";
import _ from "lodash";

import { restClient } from "collection/graphql/client";
import { getAllProducts } from "collection/graphql/subscription";

/**
 * Given an orderDescriptor of { productIds: ["premium"] }
 * returns "Complete"
 *
 * This distinction is pulled from mobilePlanId on SubscriptionProductTypePolicy
 *
 * @param {OrderDescriptor} orderDescriptor
 * @returns {String}
 * @throws {MobilePaymentError}
 */
export async function getMobilePlanId(orderDescriptor) {
  const productId = _.first(orderDescriptor.productIds);
  if (!productId) {
    throw new MobilePaymentError("Failed to find productId from orderDescriptor", {
      orderDescriptor,
    });
  }

  const { data } = await restClient.query({ query: getAllProducts });
  if (!data?.allProducts) {
    throw new MobilePaymentError("Failed to query all products for mobile plan id");
  }

  const matchingProduct = data.allProducts.find(({ id }) => id === productId);
  if (!matchingProduct) {
    throw new MobilePaymentError("Failed to find matching product for productId", {
      productId,
    });
  }

  const mobilePlanId = matchingProduct.mobilePlanId;
  if (!mobilePlanId) {
    throw new MobilePaymentError("Failed to find mobilePlanId on matching product", {
      matchingProduct,
    });
  }

  return mobilePlanId;
}
