import Index from "demo/routes/Index";
import React from "react";

import App from "layout/app";
import SubRoute from "lib/SubRoute";

const DemoRouter = SubRoute.extend({
  routes: {
    "": "index",
  },

  index() {
    const searchParams = new URLSearchParams(window.location.hash.split("?")[1]);
    const params = {};
    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }

    return App.display(Index, { screenName: "Demo Page", ...params });
  },
});

export default () => new DemoRouter("demo", { createTrailingSlashRoutes: false });
