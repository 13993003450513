import { CapsHeader, fullWidth, NEXT_YEAR } from "../Helpers";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { Select } from "components/fl-ui/Form/index";

/**
 * Month picker filter parameters.
 */
const MonthFilter = ({ param, onParamChanged }) => {
  const [selectedMonth, setSelectedMonth] = useState(param.default);

  const onMonthChanged = (event) => {
    setSelectedMonth(event.target.value);
    onParamChanged({ id: param.id, value: event.target.value });
  };

  const months = _.range(1, 13).map((val) => {
    const label = new Date(NEXT_YEAR, val - 1, 1).toLocaleString("default", {
      month: "long",
    });
    return {
      key: val,
      value: val,
      label: label,
    };
  });

  return (
    <div key={param.id}>
      <CapsHeader>{param.title}</CapsHeader>
      <Select
        defaultValue={selectedMonth}
        id={`report-filter-${param}`}
        name={param.id}
        style={fullWidth}
        onChange={onMonthChanged}
        options={months}
      />
    </div>
  );
};

MonthFilter.propTypes = {
  onParamChanged: PropTypes.func.isRequired,
  param: PropTypes.object.isRequired,
};

export default MonthFilter;
