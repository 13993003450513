import { graphql } from "@apollo/client/react/hoc";

import getActivityList from "collection/graphql/queries/getActivityList";

const withActivityList = graphql(getActivityList, {
  options: (props) => {
    const variables = {};
    if (props.cropYear) {
      variables.cropYear = props.cropYear;
    }

    if (props.fieldId) {
      variables.fields = [props.fieldId];
    }
    if (props.cropId) {
      variables.crops = [props.cropId];
    }

    return {
      fetchPolicy: "cache-and-network",
      variables,
    };
  },
});

export default withActivityList;
