import { useSuspenseQuery } from "@apollo/client";
import _ from "lodash";

import { getAllEquipment } from "collection/graphql/equipment/queries";
import getAllMaintenanceRecords from "collection/graphql/equipment/queries/getAllMaintenanceRecords";
import { getAllPeople } from "collection/graphql/people/queries";

const useRecords = (equipmentId = null) => {
  const { maintenanceRecords } = useSuspenseQuery(getAllMaintenanceRecords).data;
  const { data } = useSuspenseQuery(getAllEquipment);
  const equipmentById = _.keyBy(_.values(data).flat(Infinity), "id");
  const allPeople = useSuspenseQuery(getAllPeople).data.people;
  const peopleById = _.keyBy(allPeople, "id");

  let records = _.map(maintenanceRecords, (recordFields) => {
    const { equipmentId } = recordFields;
    const equipment = _.pick(equipmentById[equipmentId], ["id", "name"]);
    const people = _.map(recordFields.people, (personId) =>
      _.pick(peopleById[personId], ["firstName", "id", "lastName", "name"])
    );

    return {
      ..._.pick(recordFields, ["date", "engineHours", "id", "laborHours", "notes"]),
      equipment,
      people,
    };
  });

  records = _.sortBy(records, "date").reverse();
  if (equipmentId) {
    return _.filter(records, (record) => record.equipment.id === equipmentId);
  }

  return records;
};

export default useRecords;
