import moment from "moment";
import React, { useState } from "react";

import { trackTestEvent } from "lib/metrics/events/trackEvents";

import { Button } from "components/fl-ui";

const PendoTestButton = ({ text, ...rest }) => {
  const [state, setState] = useState("pending");

  const buttonProps = {
    color: state === "pending" ? "secondary" : "primary",
    children: state === "pending" ? text : "Triggered",
    onClick: () => {
      setState("triggering");
      setTimeout(() => setState("pending"), 2000);
      trackTestEvent(moment().format());
    },
    ...rest,
  };

  return <Button {...buttonProps} />;
};

const PendoDemo = () => {
  return (
    <div>
      <h2>Pendo Demo</h2>

      <PendoTestButton>Trigger Custom Track Event</PendoTestButton>
    </div>
  );
};

export default PendoDemo;
