import Big from "big.js";
import _ from "lodash";

const ProductPurchaseSummaryTypePolicy = {
  fields: {
    amountRemainingTotal: {
      read(__, { readField }) {
        const applied = readField("amountAppliedTotal") || 0;
        const purchased = readField("amountPurchasedTotal");

        return _.isNil(purchased) ? null : new Big(purchased).minus(applied).toNumber();
      },
    },

    amountRemainingUnit: {
      read(__, { readField }) {
        return readField("amountAppliedUnit") || readField("amountPurchasedUnit");
      },
    },
  },
};

export default ProductPurchaseSummaryTypePolicy;
