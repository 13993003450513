import { StyleSheet } from "aphrodite/no-important";

import styleVars from "components/fl-ui/Layout/Navigation/styleVars";

const screenStyles = StyleSheet.create({
  screen: {
    position: "fixed",
    zIndex: styleVars.screenOverlay_zIndex,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "none",
    "@media only screen and (max-width: 62.5em)": {
      display: "none",
      opacity: 0,
      zIndex: styleVars.screenOverlayMobile_zIndex,
      backgroundColor: styleVars.screenOverlay_color,
    },
  },
});

export default screenStyles;
