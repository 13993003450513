import { COMPLETED, DONE } from "lib/constants";

/**
 * Returns a boolean true if the activity is DONE, false otherwise.
 *
 * @param {Activity} activity
 * @param {String} activity.status
 * @returns {Boolean}
 */
export default ({ status }) => status === COMPLETED || status === DONE;
