import _ from "lodash";
import { useState } from "react";

const isCrop = (object) => object.__typename === "Crop";
const getFieldId = (object) => (isCrop(object) ? object.field.id : object.id);
const getFieldName = (object) => (isCrop(object) ? object.field.name : object.name);
const getGroup = (object) => (isCrop(object) ? object.field.group : object.group);
const getGroupId = (object) => getGroup(object)?.id;

const organizeByGroup = (list) => {
  const [grouped, ungrouped] = _.partition(list, getGroupId);
  return [_.groupBy(grouped, getGroupId), ungrouped];
};

const buildFieldGroupOptions = (fieldCrops, fields, excludedCropIds, filterText) => {
  // create a list containing selectable field crops and fields without crops
  const selectableFieldCrops = _.reject(fieldCrops, (crop) => excludedCropIds.includes(crop.id));
  const idsOfFieldsWithCrops = _.map(fieldCrops, "field.id");
  const fieldsWithoutCrops = _.reject(fields, ({ id }) => idsOfFieldsWithCrops.includes(id));

  filterText = _.trim(filterText).toLowerCase();
  const filteredCropsAndFields = [...selectableFieldCrops, ...fieldsWithoutCrops].filter((item) => {
    if (filterText === "") {
      return true;
    }

    const fieldNameMatches = getFieldName(item).toLowerCase().includes(filterText);
    const commodityMatches = (item.commodity?.name || "").toLowerCase().includes(filterText);

    return fieldNameMatches || commodityMatches;
  });

  // split into fields/fieldCrops with and without groups
  const [groupsById, ungrouped] = organizeByGroup(filteredCropsAndFields);

  // create an array of groups and their fields, sorted by group name.
  // fields/fieldCrops should be sorted by field name then commodity name.
  let fieldGroups = _.values(groupsById).map((fields) => {
    return {
      containsCrops: false, // placeholder
      fields: _.sortBy(fields, [getFieldName, "commodity.name"]),
      group: getGroup(fields[0]),
      totalFields: NaN, // placeholder
    };
  });
  fieldGroups = _.sortBy(fieldGroups, "group.name");

  // add an ungrouped field group.
  // fields should be sorted by field name then commodity name.
  if (ungrouped.length > 0) {
    fieldGroups.push({
      containsCrops: false, // placeholder
      fields: _.sortBy(ungrouped, [getFieldName, "commodity.name"]),
      group: { id: -1, name: "Ungrouped fields" },
      totalFields: NaN, // placeholder
    });
  }

  // count the number of unique fields in each group
  fieldGroups.forEach((item) => {
    item.containsCrops = _.some(item.fields, isCrop);
    item.totalFields = _.uniqBy(item.fields, getFieldId).length;
  });

  return fieldGroups;
};

const buildFieldCropGroupOptions = (fieldCrops, excludedCropIds, filterText) => {
  const groupedByCommodity = _.groupBy(fieldCrops, "commodity.id");
  const commodityCrops = _.keys(groupedByCommodity).map((commodityId) => {
    const fieldCrops = groupedByCommodity[commodityId];
    const acreage = _.sumBy(fieldCrops, ({ acreage }) => acreage * 1000) / 1000;
    const { commodity } = fieldCrops[0];
    const name = commodity.name;

    return { acreage, commodity, fieldCrops, name };
  });

  const nonExcludedCrops = _.filter(
    commodityCrops,
    ({ fieldCrops }) => _.difference(_.map(fieldCrops, "id"), excludedCropIds).length > 0
  );

  filterText = _.trim(filterText).toLowerCase();
  const filteredCrops = nonExcludedCrops.filter(({ commodity, name }) => {
    const nameMatches = name.toLowerCase().includes(filterText);
    const commodityMatches = commodity.name.toLowerCase().includes(filterText);

    return nameMatches || commodityMatches;
  });

  return _.sortBy(filteredCrops, ({ name }) => name.toLowerCase()).map((option) => ({ option }));
};

const useFieldCropOptions = ({ fields, fieldCrops, onChange, search, value }) => {
  const [optionType, setOptionType] = useState("fields");

  const addFieldCrops = (...fieldCrops) => {
    handleChange(_.uniq(value.concat(fieldCrops.flat(Infinity))));
  };

  const handleChange = (updatedValue) => {
    onChange(updatedValue);
  };

  const removeFieldCrops = (...fieldCrops) => {
    handleChange(_.difference(value, fieldCrops.flat(Infinity)));
  };

  const selectedCropIds = _.map(value, (cropIdOrObject) => cropIdOrObject.id || cropIdOrObject);
  const selectedFieldCrops = _.filter(fieldCrops, ({ id }) => selectedCropIds.includes(id));
  value = selectedFieldCrops;

  let options;
  switch (optionType) {
    case "fields":
      options = buildFieldGroupOptions(fieldCrops, fields, selectedCropIds, search.text);
      break;
    case "fieldCrops":
      options = buildFieldCropGroupOptions(fieldCrops, selectedCropIds, search.text);
  }

  return {
    addFieldCrops,
    removeFieldCrops,
    handleChange,
    options,
    optionType,
    setOptionType,
    value,
  };
};

export default useFieldCropOptions;
