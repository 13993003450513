import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import { Container, Content, DataRowContentContainer, StyledIcon, StyledText } from "settings/styledComponents";

const DataImportRow = ({ children, copy, icon, title }) => (
  <Row>
    <Col xs={12}>
      <Container>
        <Content>
          {icon && <StyledIcon icon={icon} />}
          <div>
            <StyledText>
              <strong>{title}</strong>
            </StyledText>
            <StyledText>{copy}</StyledText>
          </div>
        </Content>

        <DataRowContentContainer>{children}</DataRowContentContainer>
      </Container>
    </Col>
  </Row>
);

DataImportRow.propTypes = {
  children: PropTypes.any,
  copy: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default DataImportRow;
