import { isEmpty } from "lodash";
import React, { useState } from "react";

import CountySelectionInput from "modules/prices/components/CountySelectionInput";

import { Button } from "components/fl-ui";
import { FormGroup, Radio } from "components/fl-ui/Form";
import { Modal, ModalHeader, ModalTitle, ModalBody, ModalFooter } from "components/fl-ui/Modal/Modal";

const ConfigSelectionModal = ({ filters, onClose }) => {
  const { saveFilters } = filters;
  const [bidType, setBidType] = useState(filters.bidType);
  const [counties, setCounties] = useState(filters.counties);

  const handleBidTypeChange = (e, { bidType }) => setBidType(bidType);
  const handleCountySelect = (value) => setCounties(value);
  const handleConfigSave = () => {
    saveFilters({ bidType, counties });
    onClose();
  };

  return (
    <Modal>
      <ModalHeader>
        <ModalTitle>Edit pricing area</ModalTitle>
      </ModalHeader>

      <ModalBody>
        <FormGroup inputId="counties" label="Select counties in your pricing area">
          <CountySelectionInput onChange={handleCountySelect} selectedCounties={counties} />
        </FormGroup>

        <FormGroup inputId="bidType" label="Which bids do you want to see?">
          <Radio
            checked={bidType === "NEW_CROP"}
            label="Harvest Bids"
            name="bidType"
            onChange={handleBidTypeChange}
            value="NEW_CROP"
          />

          <Radio
            checked={bidType === "SPOT"}
            label="Spot Bids"
            name="bidType"
            onChange={handleBidTypeChange}
            value="SPOT"
          />
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button display="inline" onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary" disabled={isEmpty(counties)} onClick={handleConfigSave}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfigSelectionModal;
