import React, { useState } from "react";
import { ReportError } from "reports/components/ReportError";
import withDefinitions from "reports/components/withDefinitions";
import ReportSidebar from "reports/containers/ReportSidebar";
import ReportTable from "reports/containers/ReportTable";

import { useFeatureAndPermissionAccess } from "hooks/useFeatureAndPermissionAccess";
import { REPORT_DOWNLOAD } from "lib/metrics/events";
import { getFeatureNameFromReportId } from "modules/reports/utils/getFeatureNameFromReportId";

import ReportsBlankStatePage from "components/advertisements/pages/ReportsBlankStatePage";
import { Button } from "components/fl-ui";
import BlankSlate from "components/fl-ui/BlankSlate";
import Container from "components/fl-ui/Layout/Container";
import Content from "components/fl-ui/Layout/Content";
import ContentMain from "components/fl-ui/Layout/ContentMain";
import ContentSidebar from "components/fl-ui/Layout/ContentSidebar";
import Header from "components/fl-ui/Layout/Header";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import RestrictedAccessBlankslate from "components/fl-ui/RestrictedAccessBlankslate";
import Card from "components/ui/card";
import Loading from "components/ui/loading";

const ReadyState = ({ title }) => (
  <BlankSlate icon="reports" title={title}>
    Please set filters for your report and then click the load report button to generate a preview.
  </BlankSlate>
);

const dropdownStyle = {
  position: "absolute",
  zIndex: "10",
  marginTop: "2.75rem",
};

const Report = ({ definitions, error, id, links, loading, onChange, parameters }) => {
  const [linksShown, setLinksShown] = useState(false);
  const [reloadCount, setReloadCount] = useState(0);

  const { loadingAccess, showPaygate, showRestricted, showContent } = useFeatureAndPermissionAccess({
    featureName: getFeatureNameFromReportId(id),
    permissionName: "reports",
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ReportError />;
  }

  const { title, ...definition } = definitions;

  const handleReload = (parameters) => {
    setReloadCount(reloadCount + 1);
    onChange({ parameters });
  };

  const handleClick = (url) => {
    window.open(url, "_blank");
    handleTrack();
  };

  const handleTrack = () => {
    REPORT_DOWNLOAD.track({
      report_name: definitions.title,
      type: "pdf",
    });
  };

  const toggleShowLinks = (e) => {
    e.stopPropagation();
    setLinksShown(!linksShown);
  };

  const hideLinks = () => {
    setLinksShown(false);
  };

  return (
    <Container onClick={hideLinks}>
      <LoadingWrapper isLoading={loadingAccess}>
        <Header backLink="#reports" title={title}>
          {links.length > 0 && (
            <Button color="primary" icon="chevronDown" iconRight onClick={toggleShowLinks}>
              <span style={{ paddingRight: "1em" }}>Download</span>
            </Button>
          )}

          {linksShown && (
            <div style={dropdownStyle}>
              <Card className="border-caret-white caret-middle">
                <div style={{ padding: "0 1.5rem" }}>
                  {links.map((x) => (
                    <Button color="primary" display="block" key={x.format} link onClick={() => handleClick(x.href)}>
                      {x.format.toUpperCase()}
                    </Button>
                  ))}
                </div>
              </Card>
            </div>
          )}
        </Header>
        {showPaygate && <ReportsBlankStatePage />}
        {showRestricted && <RestrictedAccessBlankslate />}
        {showContent && (
          <Content>
            <ContentSidebar>
              <ReportSidebar definition={definition} onDataSourceChange={handleReload} />
            </ContentSidebar>

            <ContentMain>
              {reloadCount === 0 ? (
                <ReadyState title={title} />
              ) : (
                <ReportTable definition={definition} id={id} parameters={parameters} />
              )}
            </ContentMain>
          </Content>
        )}
      </LoadingWrapper>
    </Container>
  );
};

Report.defaultProps = {
  definitions: [],
  parameters: [],
  links: [],
};

export default withDefinitions(Report);
