import { useQuery } from "@apollo/client";
import useContractPermissions from "contracts/hooks/useContractPermissions";
import useContractsIndexQueryParams from "contracts/hooks/useContractsIndexQueryParams";
import getCurrentFilterCount from "contracts/utils/getCurrentFilterCount";
import _ from "lodash";
import React, { createContext, useContext, useEffect, useMemo, useRef } from "react";

import useDeleteContract from "collection/graphql/contracts/hooks/useDeleteContract";
import listCashContracts from "collection/graphql/contracts/queries/listCashContracts";
import useViewType from "hooks/useViewType";
import App from "layout/app";

const contractsIndexContext = createContext(undefined);

const useContractsIndexPage = () => {
  const context = useContext(contractsIndexContext);
  if (!context) {
    throw new Error("Contracts index page context required");
  }
  return context;
};

export default useContractsIndexPage;

export const ContractsIndexContextProvider = ({ children }) => {
  const { canRead } = useContractPermissions();
  const performDelete = useDeleteContract();
  const itemsPerPage = useViewType() === "desktop" ? 15 : 4;
  const { contractsQueryParams, resetContractsQueryParams, setContractsQueryParams } = useContractsIndexQueryParams();
  const page = useRef(1);

  const buildQueryVariables = () => ({
    ascending: contractsQueryParams.sortDirection === "asc",
    buyers: contractsQueryParams.buyers,
    commodities: contractsQueryParams.commodities,
    contractNumber: contractsQueryParams.contractNumber,
    contractStatus: contractsQueryParams.contractStatus,
    cropYears: contractsQueryParams.cropYears,
    endDate: contractsQueryParams.deliveryEndDate,
    itemsPerPage,
    marketedCrops: contractsQueryParams.marketedCrops,
    orderBy: contractsQueryParams.orderBy,
    page: page.current,
    startDate: contractsQueryParams.deliveryStartDate,
  });

  const { data, fetchMore, loading, refetch } = useQuery(listCashContracts, {
    skip: !canRead(),
    variables: buildQueryVariables(),
  });

  useEffect(() => {
    // TODO we need to revisit why this refetch is necessary. if it isn't required, we should remove it.
    if (canRead()) {
      refetch(buildQueryVariables());
    }
  }, [contractsQueryParams]);

  const { contracts = [], totalContracts = null, totalContractsFiltered = null } = data?.getCashContracts ?? {};
  const hasMore = contracts.length < totalContractsFiltered;

  const clearFilters = () => {
    page.current = 1;
    setContractsQueryParams({
      buyers: [],
      commodities: [],
      contractStatus: undefined,
      cropYears: [],
      deliveryEndDate: undefined,
      deliveryStartDate: undefined,
      marketedCrops: [],
    });
  };

  const clearSearch = () => {
    page.current = 1;
    setContractsQueryParams({
      contractNumber: "",
    });
  };

  const clearSort = () => {
    page.current = 1;
    setContractsQueryParams({
      orderBy: null,
      sortDirection: "",
    });
  };

  const deleteContract = async (contractId) => {
    let confirmed = false;
    try {
      await App.confirm({
        confirm: "Delete contract",
        message: "Are you sure you want to delete this contract?",
      });
      confirmed = true;
    } catch (error) {
      if (error) {
        throw error;
      }
    }

    if (confirmed) {
      const pageOfContract = findPageForContract(contractId);
      await performDelete(contractId);

      if (!isNaN(pageOfContract)) {
        if (pageOfContract > 1) {
          page.current = pageOfContract;
          fetchMore({
            variables: buildQueryVariables(),
          });
        } else {
          reloadAll();
        }
      }
    }
  };

  const fetchNextPage = () => {
    ++page.current;
    return fetchMore({
      variables: buildQueryVariables(),
    });
  };

  const findPageForContract = (id) => {
    const ordinal = _.findIndex(contracts, { id }) + 1;
    return ordinal > 0 ? Math.ceil(ordinal / itemsPerPage) : NaN;
  };

  const reloadAll = () => {
    page.current = 1;
    resetContractsQueryParams();
  };

  const setFilters = (updatedFilters) => {
    page.current = 1;
    setContractsQueryParams(updatedFilters);
  };

  const setSearch = (searchString) => {
    page.current = 1;
    setContractsQueryParams({
      buyers: [],
      commodities: [],
      contractNumber: searchString,
      contractStatus: undefined,
      cropYears: [],
      deliveryEndDate: undefined,
      deliveryStartDate: undefined,
      marketedCrops: [],
    });
  };

  const setSort = (orderBy, sortDirection) => {
    page.current = 1;
    setContractsQueryParams({
      orderBy,
      sortDirection,
    });
  };

  const context = useMemo(
    () => ({
      clearFilters,
      clearSearch,
      clearSort,
      contracts,
      currentFilterCount: getCurrentFilterCount(contractsQueryParams),
      currentFilters: {
        buyers: contractsQueryParams.buyers,
        commodities: contractsQueryParams.commodities,
        contractStatus: contractsQueryParams.contractStatus,
        cropYears: contractsQueryParams.cropYears,
        deliveryEndDate: contractsQueryParams.deliveryEndDate,
        deliveryStartDate: contractsQueryParams.deliveryStartDate,
        marketedCrops: contractsQueryParams.marketedCrops,
      },
      currentSortDirection: contractsQueryParams.sortDirection,
      currentSortColumn: contractsQueryParams.orderBy,
      currentSearchString: contractsQueryParams.contractNumber,
      deleteContract,
      fetchNextPage,
      hasMore,
      loading,
      reloadAll,
      setFilters,
      setSearch,
      setSort,
      sortIsActive: !!contractsQueryParams?.orderBy,
      totalContracts,
    }),
    [contracts, contractsQueryParams, hasMore, loading, page]
  );

  return <contractsIndexContext.Provider value={context}>{children}</contractsIndexContext.Provider>;
};
