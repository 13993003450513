import PropTypes from "prop-types";
import React from "react";

import useEnterpriseFeature from "hooks/useEnterpriseFeature";

import Attachments from "components/field/Attachments";
import FieldDetailsTable from "components/field/FieldDetailsTable";
import Loads from "components/field/Loads";
import SatelliteImagery from "components/field/SatelliteImagery";
import ScoutingNotes from "components/field/ScoutingNotes";
import Soil from "components/field/Soil";
import Yield from "components/field/Yield";
import Activities from "components/field/activities";
import CropStatusSummary from "components/field/weather/CropStatusSummary";
import DetailsWidget from "fields/components/DetailsWidget";
import { DetailsWidgetContainer } from "fields/components/styledComponents";

const FieldDetails = ({ field, fieldId, isMobile, toggleEditModal, year }) => {
  const hasLoads = useEnterpriseFeature("legacy_storage_and_loads");
  const showSatelliteImagery = useEnterpriseFeature("crop_health_enterprise");

  return (
    <>
      <DetailsWidgetContainer>
        <CropStatusSummary fieldId={fieldId} cropYear={year} />
      </DetailsWidgetContainer>

      {showSatelliteImagery && (
        <div className="field-detail-widget">
          <SatelliteImagery acreage={field.acreage} fieldId={fieldId} year={year} />
        </div>
      )}

      <DetailsWidget more="All activities" moreUrl={`#fields/${fieldId}/activities`} title="Activities">
        <Activities fieldId={fieldId} year={year} />
      </DetailsWidget>

      <DetailsWidget more="All notes" moreUrl={`#fields/${fieldId}/scouting`} title="Scouting">
        <ScoutingNotes fieldId={fieldId} fieldName={field.name} year={year} />
      </DetailsWidget>

      <DetailsWidget more="All yield" moreUrl={`#fields/${fieldId}/yield`} title="Yield">
        <Yield fieldId={fieldId} year={year} />
      </DetailsWidget>

      <DetailsWidget more="All attachments" moreUrl={`#fields/${fieldId}/attachments`} title="Attachments">
        <Attachments fieldId={fieldId} year={year} />
      </DetailsWidget>

      {hasLoads && (
        <DetailsWidget more="All loads" moreUrl={`#fields/${fieldId}/loads`} title="Loads">
          <Loads fieldId={fieldId} year={year} />
        </DetailsWidget>
      )}

      <Soil field={field} />

      {isMobile && (
        <DetailsWidget title="Field Details">
          <FieldDetailsTable fieldId={fieldId} onAddLandValue={toggleEditModal} />
        </DetailsWidget>
      )}
    </>
  );
};

FieldDetails.propTypes = {
  field: PropTypes.shape({
    acreage: PropTypes.number,
    id: PropTypes.number,
  }).isRequired,
  fieldId: PropTypes.number.isRequired,
  showAll: PropTypes.bool,
  year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default FieldDetails;
