import { StyleSheet } from "aphrodite/no-important";

import { UIColors } from "components/fl-ui/colors/index";
import { Typography, Borders, Spacing } from "components/fl-ui/constants/index";

const styles = StyleSheet.create({
  isSorted: {
    borderBottom: `1px solid ${UIColors.blue} !important`,
  },
  listingContainer: {
    marginBottom: "4em",
  },
  listingHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: Spacing.regular,
    borderBottom: Borders.regular,
    paddingBottom: Spacing.medium,
  },
  listingTitle: {
    display: "flex",
    alignItems: "baseline",
  },
  listing_h1: {
    fontSize: Typography.sizes.lg,
    lineHeight: 1.1875,
    margin: 0,
  },
  centerAligned: {
    textAlign: "center !important",
  },
  rightAligned: {
    textAlign: "right !important",
  },
});

export default styles;
