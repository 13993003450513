import { string } from "yup";

/**
 * These should be kept in sync with the `Enterprise.features` array (graphql). We should keep the typedef in sync as well
 * as it provides us with code completion when using the `useEnterpriseFeature` hook.
 *
 * @see EnterpriseFeature
 */

export default string().oneOf([
  "aar",
  "activities",
  "activity_cost_report",
  "activity_summary_report",
  "activity_types",
  "autohedge",
  "contracts",
  "crop_health_enterprise",
  "elevate_plus",
  "equipment",
  "field_season_report",
  "field_summary_report",
  "fields",
  "file_storage",
  "fsa_acreage_report",
  "grain_marketing",
  "ignore_acre_limit",
  "indigo_carbon",
  "integrations",
  "land_costs",
  "land_cost_report",
  "land_cost_crop_share_report",
  "land_cost_flex_rent_report",
  "legacy_storage_and_loads",
  "local_prices",
  "machine_data",
  "market_prices",
  "marketing_position_summary_report",
  "monthly_rainfall_summary_report",
  "products",
  "profit_and_loss",
  "rain_notification",
  "sbb",
  "scouting",
  "soil_maps",
  "spray_log_report",
  "storage",
  "weather_history",
  "work_orders",
]);
