import React from "react";
import styled from "styled-components";

import NotificationMenu from "modules/notifications/components/NotificationMenu";

import UserMenu from "components/fl-ui/Layout/Navigation/UserMenu";

const Toolbar = styled(({ className }) => {
  return (
    <div className={className}>
      <NotificationMenu />
      <UserMenu />
    </div>
  );
})`
  align-items: center;
  display: flex;
  gap: 5px;
  justify-content: flex-end;
  position: relative;

  @media print {
    display: none;
  }
`;

export default Toolbar;
