/* eslint-disable react/display-name */
import React, { useCallback } from "react";

import useCurrentEnterprise from "hooks/useCurrentEnterprise";

import LoadingWrapper from "components/fl-ui/LoadingWrapper";

/**
 * An HoC that provides the deprecated `hasEnterpriseFeature` function.
 * Use {@link useEnterpriseFeature} instead.
 *
 * @deprecated
 * @param {JSX} Component
 * @return {function(*)}
 */
const connectFeatures = (Component) => (props) => {
  const { currentEnterprise, loading } = useCurrentEnterprise();
  const hasEnterpriseFeature = useCallback(
    (featureId) => {
      /*
       * this logic is repeated in the useEnterpriseFeature hook. whenever possible we
       * should use that hook instead.
       */
      return currentEnterprise?.features?.includes(featureId);
    },
    [currentEnterprise?.features]
  );

  return loading ? <LoadingWrapper isLoading /> : <Component {...props} hasEnterpriseFeature={hasEnterpriseFeature} />;
};

export default connectFeatures;
