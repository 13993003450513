/* eslint-disable react/display-name */
import { css } from "aphrodite";
import EnrollmentForm from "carbon_enrollment/components/EnrollmentForm";
import { StyledIcon, StyledIconsContainer } from "carbon_enrollment/components/styledComponents";
import RegrowEnrollmentFormSchema from "carbon_enrollment/validation/RegrowEnrollmentFormSchema";
import { isNil, pick } from "lodash";
import React from "react";

import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import useSchemaValidation from "hooks/useSchemaValidation";
import useViewType from "hooks/useViewType";
import useCarbonEnrollment from "modules/carbon_enrollment/hooks/useCarbonEnrollment";
import styles from "modules/carbon_enrollment/styles";

import { FormGroup, Input } from "components/fl-ui/Form";
import { Logo } from "components/fl-ui/Icons";

const clientData = {};
clientData.clientName = "Cargill, Incorporated";
clientData.CustomFields = ({ onInputChange }) => {
  return (
    <FormGroup
      horizontal
      label="Please enter the email address you used to register on the Cargill RegenConnect sign up page:"
    >
      <Input
        name="contactEmail"
        onChange={(_e, data) => onInputChange(data)}
        placeholder="Enter your email address"
        style={{ maxWidth: "100%", width: "24rem" }}
        type="email"
      />
    </FormGroup>
  );
};

clientData.headerCopy = (
  <>
    {"Enrollment is open through September 16 for farmers interested in adopting new practices including cover crops, "}
    {"reduced tillage, and no-till for the 2022-23 crop season. You can "}
    <a
      className={css(styles.blueText)}
      href="https://www.cargillag.com/regen-ag?utm_source=farmlogs"
      rel="noopener noreferrer"
      target="_blank"
    >
      learn more here
    </a>
    .
  </>
);

clientData.headerTitle = "Get paid for environmental outcomes through our partnership with Cargill RegenConnect™!";
function LogoComponent() {
  const isMobile = useViewType() === "mobile";
  const vendorLogoStyles = {
    fontSize: isMobile ? "1.75rem" : "2rem",
    textAlign: isMobile ? "center" : "left",
    verticalAlign: "super",
  };

  return (
    <StyledIconsContainer flexDirection={isMobile ? "column" : "row"}>
      <Logo size={isMobile ? "sm" : "md"} />
      <StyledIcon icon="add" />
      <span style={vendorLogoStyles}>Cargill RegenConnect™</span>
    </StyledIconsContainer>
  );
}
clientData.LogoComponent = LogoComponent;

clientData.minimal = true;

clientData.nextSteps = (
  <>
    <p className={css(styles.copy)}>
      Once you authorize, Bushel Farm will securely send your information to Cargill RegenConnect. Here is what you can
      expect:
    </p>

    <ol className={css(styles.orderedList)}>
      <li className={css(styles.copy, styles.list)}>
        Your field boundaries will be available to select in the Cargill RegenConnect online platform.
      </li>
      <li className={css(styles.copy, styles.list)}>
        You will receive a confirmation email and be redirected back into the Cargill RegenConnect platform to complete
        program enrollment.
      </li>
      <li className={css(styles.copy, styles.list)}>
        Select fields to bring into the program to quantify carbon outcomes and estimate the impact of practices you
        choose. The following data points (as stored in Bushel Farm) will be available on Cargill RegenConnect for the
        field boundaries you select: field name, number of acres, field created and updated time stamps, commodity by
        year, yield per acre by year, activity history including inputs and equipment.
      </li>
      <li className={css(styles.copy, styles.list)}>
        Track activities through Bushel Farm to reduce the need to enter that information into the Cargill RegenConnect
        online platform.
      </li>
      <li className={css(styles.copy, styles.list)}>
        {"Reach out to "}
        <a
          className={css(styles.blueText)}
          href="mailto:RegenConnect@Cargilll.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          RegenConnect@Cargilll.com
        </a>
        {" if you have any questions regarding program eligibility or the enrollment process."}
      </li>
    </ol>
  </>
);

const RegrowEnrollment = () => {
  const enrollment = useCarbonEnrollment("regrow");
  const { data, isSaving, loading, selectedFields } = enrollment;
  const hasCarbonFeature = useEnterpriseFeature("regrow_carbon");
  const isLoading = loading || !data || isNil(hasCarbonFeature);
  const formData = pick(data, ["checked", "contactEmail"]);
  const validator = useSchemaValidation(RegrowEnrollmentFormSchema, formData, { context: { selectedFields } });
  const disabled = !validator.isValid() || isSaving;

  return (
    <EnrollmentForm
      clientData={clientData}
      disabled={disabled}
      enrollment={enrollment}
      isEligible={!hasCarbonFeature}
      isLoading={isLoading}
    />
  );
};

export default RegrowEnrollment;
