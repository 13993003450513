import { css } from "aphrodite";
import { SalesPhoneLink, styles, SupportEmailLink } from "billing/lib";
import PropTypes from "prop-types";
import React from "react";

import { Button } from "components/fl-ui";
import Dialog from "components/fl-ui/Dialog";

const CustomPlanDialog = (props) => {
  const { onClose } = props;
  const title = "Your subscription plan is no longer supported";
  const message = (
    <p className={css(styles.modal_text)}>
      For assistance with your plan, please contact our support team at <SupportEmailLink /> or <SalesPhoneLink />.
    </p>
  );

  return (
    <Dialog
      dialogBody={message}
      dialogControls={<Button onClick={onClose}>Close</Button>}
      dialogHeading={title}
      onClose={onClose}
    />
  );
};

CustomPlanDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default CustomPlanDialog;
