import { css, StyleSheet } from "aphrodite/no-important";
import React from "react";
import _ from "underscore";

import { Card } from "components/fl-ui/Card";

const infoStyle = {
  background: "white",
  border: "white",
};

const styles = StyleSheet.create({
  baseStyle: {
    pointerEvents: "all",
    width: "100%",
  },
  cardPadding: {
    padding: "1rem !important",
  },
});

const alertStyle = {
  background: "HSL(49, 100%, 68%)",
  border: "none",
};

const arrowPath = {
  down: "M0 10 L10 20 L20 10",
  up: "M0 20 L10 10 L20 20",
  left: "M15 0 L5 10 L15 20",
  right: "M10 0 L20 10 L10 20",
};

const cloudStyle = {
  display: "inline-block",
  height: "1rem",
};

export const CloudBadge = (props) => (
  <img {...props} style={{ ...props.style, ...cloudStyle }} src="/images/map/cloud-icon.svg" />
);

export const Arrow = ({ color, direction, style }) => (
  <svg
    viewBox="0 -2 22 24"
    style={_.assign(
      {
        // make sure its always square
        width: (style && style.height) || "1rem",
        height: (style && style.width) || "1rem",
      },
      style
    )}
  >
    <path d={arrowPath[direction || "left"]} fill="none" stroke={color || "black"} strokeWidth="3" />
  </svg>
);

export const ViewTypeIcon = ({ split }) => <img src={`/images/map/icon-${split ? "split" : "single"}-view.svg`} />;

export const Button = (props) => (
  <button {...props} style={props.style} className={`btn button-m ${css(styles.baseStyle)}`}>
    {props.children}
  </button>
);

export const FlatButton = (props) => (
  <Button
    {...props}
    style={_.assign(
      {
        background: "none",
        color: props.color,
        border: "none",
        boxShadow: "none",
      },
      props.style
    )}
  >
    {props.children}
  </Button>
);

export const TopBar = (props) => (
  <div
    className="row"
    style={{
      pointerEvents: "all",
      background: props.color,
      padding: ".5em 0",
      flex: "0 1 auto",
    }}
  >
    {props.children}
  </div>
);

export const InfoCard = ({ children, title, description, more, onToggle }) => (
  <div className={css(styles.baseStyle)}>
    <Card className={css(styles.cardPadding)}>
      {title && (
        <div id="info-card-toggle" className="row" onClick={children && onToggle} style={{ cursor: "pointer" }}>
          <div className="col xs-9 font-size-s">
            <h4 style={{ margin: "0 0 0.4em" }}>{title}</h4>
            {description}
          </div>
          {children && (
            <div className="col xs-3" style={{ textAlign: "right" }}>
              <Arrow color="#5c6370" direction={more ? "up" : "down"} style={{ marginTop: ".5em" }} />
            </div>
          )}
        </div>
      )}
      {more && children}
    </Card>
  </div>
);

export const AlertButton = ({ onClick, value, children }) => (
  <div className={css(styles.baseStyle)}>
    <FlatButton
      id="alert-button"
      onClick={onClick.bind(null, value)}
      style={_.assign({ padding: "1rem" }, children ? alertStyle : infoStyle)}
    >
      <span className="font-size-m">
        {children ? (
          <span>
            <div style={{ float: "left" }}>{children + " "}</div>
            <u style={{ float: "right" }}>View</u>
          </span>
        ) : (
          <u>Return to Crop Health Image</u>
        )}
      </span>
    </FlatButton>
  </div>
);
