import { css } from "aphrodite";
import styles from "profit_and_loss/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import Input from "components/fl-ui/Form/Input";
import Icon from "components/fl-ui/Icons/Icon";
import ToolTip from "components/fl-ui/Tooltip";

export const CancelButton = ({ message = "Cancel", onClick }) => (
  <ToolTip message={message}>
    <Icon className={css(styles.cancelIcon)} icon="close" onClick={onClick} />
  </ToolTip>
);

export const ConfirmButtonGroup = ({ onCancel, onConfirm }) => (
  <span className={css(styles.inputIconContainer)}>
    <ToolTip message="Save">
      <Icon className={css(styles.saveIcon)} icon="check" onClick={onConfirm} />
    </ToolTip>

    <CancelButton onClick={onCancel} />
  </span>
);

ConfirmButtonGroup.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export const EditButton = ({ onClick }) => (
  <ToolTip message="Edit">
    <Icon className={css(styles.editIcon)} icon="edit" onClick={onClick} />
  </ToolTip>
);

EditButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const InlineEditInput = ({ onSave, unit, value }) => {
  const [editValue, setEditValue] = useState(value);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setEditValue(value);
  }, [value]);

  const handleCancel = () => {
    setEditValue(value);
    setIsEditing(false);
  };

  const handleSave = async () => {
    if (!editValue) {
      return null;
    }

    setIsSaving(true);
    await onSave(editValue);
    setIsSaving(false);
    setIsEditing(false);
  };

  if (isEditing) {
    const suffix = () => (
      <>
        {unit}
        <ConfirmButtonGroup onCancel={handleCancel} onConfirm={handleSave} />
      </>
    );

    return (
      <Input
        disabled={isSaving}
        name="yield"
        onChange={({ target }) => setEditValue(target.value)}
        suffix={suffix()}
        type="number"
        value={editValue || ""}
      />
    );
  }

  return (
    <span className={css(styles.inlineEditContainer)}>
      {editValue ? `${editValue} ${unit} ` : "--"}
      <EditButton onClick={() => setIsEditing(true)} />
    </span>
  );
};

InlineEditInput.propTypes = {
  onSave: PropTypes.func.isRequired,
  unit: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default InlineEditInput;
