import { css } from "aphrodite/no-important";
import React from "react";

import PayGateCommonBlankSlateBanner from "components/advertisements/banners/PayGateCommonBlankSlateBanner";
import styles from "components/advertisements/styles";

const ReportsBlankSlateBanner = (props) => {
  props = {
    ...props,
    actionButtonText: "Try Reports for free",
    secondaryText: "Discover reporting capabilities tailored for each package. Start a free trial today.",
    imagePath: "images/pay_gates/banners/reports.png",
    title: "With Reports you can:",
  };

  return (
    <PayGateCommonBlankSlateBanner {...props}>
      <ul>
        <li className={css(styles.valueProp)}>
          Quickly generate reports using the information in your Bushel Farm account.
        </li>
        <li className={css(styles.valueProp)}>Use filters to easily customize report details.</li>
        <li className={css(styles.valueProp)}>
          Export your reports as a CSV or PDF file to easily share with partners.
        </li>
      </ul>
    </PayGateCommonBlankSlateBanner>
  );
};

export default ReportsBlankSlateBanner;
