import { captureException } from "@sentry/react";
import SubscriptionSaveError from "billing/exceptions/SubscriptionSaveError";
import { getPlatform, isNative } from "mobile/mobileManager";

import { getCurrentSubscription } from "collection/graphql/subscription";
import nativeOrderDescriptor from "collection/graphql/subscription/schemas/nativeOrderDescriptor";
import webDescriptorSchema from "collection/graphql/subscription/schemas/webDescriptorSchema";
import { saveNativeSubscription, saveWebSubscription } from "collection/graphql/subscription/utils";
import useCurrentEnterprise from "hooks/useCurrentEnterprise";
import useRestQuery from "hooks/useRestQuery";
import { SUBSCRIPTION_SAVE_START } from "lib/metrics/events";
import { STANDARD } from "model/Subscription/constants";

const useUpdateSubscription = () => {
  const { subscription } = useRestQuery(getCurrentSubscription).data ?? {};
  const { refetch: updateEnterpriseFeatures } = useCurrentEnterprise();
  const platform = getPlatform();

  return {
    /**
     * @return {Promise<void>}
     * @throws {SubscriptionSaveError}
     */
    cancelSubscription: async () => {
      if (!subscription.isCancelable) {
        throw new SubscriptionSaveError("Subscription is not cancelable", {
          currentPlan: subscription?.currentPlan,
          platform,
        });
      }

      try {
        const order = webDescriptorSchema.cast({
          cancelAtPeriodEnd: true,
          productIds: [STANDARD],
        });
        await saveWebSubscription(order);
      } catch (error) {
        const context = error.getContext?.() ?? {};
        const sentryEventId = captureException(error, context);
        throw new SubscriptionSaveError(error.message, {
          cause: error,
          sentryEventId,
        });
      }
    },

    /**
     * Builds an object that can be passed to the current platform's order processor.
     *
     * @param {OrderDescriptor} orderDescriptor
     * @return {Promise<NativeSaveResponse|WebSaveResponse>}
     * @throws {SubscriptionSaveError}
     */
    updateSubscription: async (orderDescriptor) => {
      try {
        const schema = isNative() ? nativeOrderDescriptor : webDescriptorSchema;
        /** @type {OrderDescriptor} */
        const descriptor = await schema.validate(orderDescriptor, {
          stripUnknown: true,
        });

        SUBSCRIPTION_SAVE_START.track({
          orderDescriptor: descriptor,
          platform,
        });

        const response = await (isNative() ? saveNativeSubscription(descriptor) : saveWebSubscription(descriptor));
        await updateEnterpriseFeatures();
        return response;
      } catch (error) {
        const context = error.getContext?.() ?? {};
        const sentryEventId = captureException(error, context);

        throw new SubscriptionSaveError(error.message, {
          cause: error,
          sentryEventId,
        });
      }
    },
  };
};

export default useUpdateSubscription;
