import Index from "loads/index";

import BinsCollection from "collection/bins";
import BuyersCollection from "collection/buyers";
import InventoryTransactionsCollection from "collection/inventory_transactions";
import App from "layout/app";
import SubRoute from "lib/SubRoute";

const primeAll = () =>
  Promise.allSettled([BinsCollection.prime(), BuyersCollection.prime(), InventoryTransactionsCollection.prime()]);

const LoadsRouter = SubRoute.extend({
  routes: {
    "": "index",
  },

  async index() {
    await primeAll();
    App.display(Index, { screenName: "Load List" });
  },
});

export default () => new LoadsRouter("loads", { creatTrailingSlashRoutes: true });
