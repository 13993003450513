import FieldCropChooser from "activity/components/choosers/FieldCropChooser/FieldCropChooser";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";

import withCropMutation from "hoc/withCropMutation";

const ActivityCropChooser = (props) => {
  const { createCrop, mode, onChange, onMenuOpen, selectedCrops } = props;
  const [searchText, setSearchText] = useState("");

  const handleCropChange = (crops) => {
    if (mode === "edit") {
      const newCrop = crops?.find(({ id }) => !selectedCrops.find((crop) => crop.id === id)) ?? selectedCrops[0];
      onChange([newCrop]);
    } else {
      onChange(crops);
    }
  };

  const handleOnAddCrop = async (field) => {
    const onUpdate = (createdCrop) => {
      if (createdCrop) {
        if (mode === "edit") {
          onChange([createdCrop]);
        } else {
          onChange([...selectedCrops, createdCrop]);
        }
      }
    };

    createCrop(field?.id ?? null, {}, { immutableCropYear: true, onUpdate });
  };

  const searchUtil = useMemo(
    () => ({
      placeholder: "Type to search",
      text: searchText,
      setText: setSearchText,
    }),
    [searchText]
  );

  return (
    <FieldCropChooser
      onAddCrop={handleOnAddCrop}
      onChange={handleCropChange}
      onMenuClose={() => setSearchText("")}
      onMenuOpen={onMenuOpen}
      search={searchUtil}
      singleSelect={mode === "edit"}
      value={selectedCrops.map(({ id }) => id)}
    />
  );
};

ActivityCropChooser.propTypes = {
  createCrop: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(["create", "edit"]).isRequired,
  onChange: PropTypes.func.isRequired,
  onMenuOpen: PropTypes.func,
  selectedCrops: PropTypes.arrayOf(PropTypes.object).isRequired,
};

ActivityCropChooser.defaultProps = {
  onMenuOpen: () => {},
};

export default withCropMutation(ActivityCropChooser);
