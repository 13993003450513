import _ from "lodash";
import React from "react";
import autoBind from "react-autobind";
import { get } from "underscore";

import naturalSort from "lib/naturalSort";

import { Body, Col, Footer, Header, HeaderCol, Row, Table } from "components/fl-ui/Table";

//TODO: This component needs to be refactored to a function component
class DataTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: null,
      sortAsc: false,
    };
    autoBind(this);
  }

  handleSort(e, { sortBy, sortAsc }) {
    if (!sortBy) return;
    this.setState({ sortBy, sortAsc: !sortAsc });
  }

  sortValue(value1, value2) {
    const { sortBy, sortAsc } = this.state;
    const a = sortAsc ? get(value1, sortBy) : get(value2, sortBy);
    const b = sortAsc ? get(value2, sortBy) : get(value1, sortBy);

    return naturalSort(a, b);
  }

  render() {
    const sortedValues = this.state.sortBy ? this.props.values.map(_.identity).sort(this.sortValue) : this.props.values;

    return (
      <Table style={this.props.style.table}>
        <Header style={this.props.style.header}>
          <Row style={{ border: "none" }}>
            {this.props.columns.map((label) => (
              <HeaderCol
                {...label}
                onClick={this.handleSort}
                sortAsc={this.state.sortBy === label.sortBy ? this.state.sortAsc : undefined}
                key={label.label}
                style={label.style}
              >
                {label.label}
              </HeaderCol>
            ))}
          </Row>
        </Header>
        <Body>
          {sortedValues.slice(0, this.props.limit ? this.props.limit : undefined).map((...args) => {
            const [value] = args;

            return this.props.children ? (
              this.props.children.apply(this.props.children, args)
            ) : (
              <Row>
                {this.props.columns.map((label) => (
                  <Col {...label} key={label}>
                    {value[label.sortBy]}
                  </Col>
                ))}
              </Row>
            );
          })}
        </Body>
        <Footer style={this.props.style.footer}>{this.props.footer}</Footer>
      </Table>
    );
  }
}

DataTable.defaultProps = {
  style: {},
  columns: [],
  values: [],
};

export default DataTable;
