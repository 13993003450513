import { ADD_SCALE_TICKET, EDIT_SCALE_TICKET } from "marketing/utils/contractEnums";
import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";

import { Button } from "components/fl-ui";

const ScaleFooter = ({
  action,
  contractId,
  cropId,
  handleAddTicket,
  handleDeleteTicket,
  handleEditTicket,
  hasErrors,
  isLoading,
  scaleTicket,
  year,
}) => {
  const handleSave = () => {
    const handler = action === ADD_SCALE_TICKET ? handleAddTicket : handleEditTicket;

    handler(scaleTicket);
  };

  return (
    <Row>
      <Col xs={12} md={10} lg={8}>
        <hr
          style={{
            margin: "3em auto 2em auto",
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "0 auto",
            maxWidth: "900px",
          }}
        >
          {action === EDIT_SCALE_TICKET ? (
            <a
              className="red-link float-left"
              disabled={isLoading}
              id="delete-contract-button"
              onClick={() => handleDeleteTicket()}
            >
              <b>Delete ticket</b>
            </a>
          ) : (
            <div />
          )}
          <div className="footer-actions-wrapper">
            <Button
              href={`#marketing/${year}/${cropId}/contracts/cash/${contractId}`}
              id="cancel-button"
              size="md"
              style={{ marginRight: "0.75em" }}
            >
              Cancel
            </Button>
            <Button color="primary" disabled={hasErrors() || isLoading} id="done-button" onClick={handleSave} size="md">
              Save
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ScaleFooter;
