import * as Sentry from "@sentry/react";
import _ from "lodash";
import { clearFileCache } from "mobile";

import {
  advisorClient,
  forecastClient,
  marketingClient,
  notificationsClient,
  restClient,
} from "collection/graphql/client";
import storage from "lib/storage";

async function clearData() {
  localStorage.clear();
  sessionStorage.clear();

  Sentry.configureScope((scope) => scope.setUser(null));

  const clients = [advisorClient, forecastClient, marketingClient, notificationsClient, restClient];
  const uniqueClients = _.uniqBy(clients, "cache");

  const actions = [];
  actions.push(storage.clear());
  actions.push(clearFileCache());
  actions.push(...uniqueClients.map((client) => client.clearStore()));
  await Promise.allSettled(actions);
}

export { clearData };
