import PropTypes from "prop-types";
import React from "react";

const altMap = {
  amex: "American Express",
  diners: "Diners club",
  discover: "Discover",
  jcb: "JCB",
  mastercard: "MasterCard",
  visa: "Visa",
};

const logoMap = {
  amex: {
    flat: "images/cc_icons/flat/amex.svg",
    "flat-rounded": "images/cc_icons/flat-rounded/amex.svg",
    logo: "images/cc_icons/logo/amex.svg",
    "logo-border": "images/cc_icons/logo-border/amex.svg",
    mono: "images/cc_icons/mono/amex.svg",
    "mono-border": "images/cc_icons/mono-border/amex.svg",
  },
  diners: {
    flat: "images/cc_icons/flat/diners.svg",
    "flat-rounded": "images/cc_icons/flat-rounded/diners.svg",
    logo: "images/cc_icons/logo/diners.svg",
    "logo-border": "images/cc_icons/logo-border/diners.svg",
    mono: "images/cc_icons/mono/diners.svg",
    "mono-border": "images/cc_icons/mono-border/diners.svg",
  },
  discover: {
    flat: "images/cc_icons/flat/discover.svg",
    "flat-rounded": "images/cc_icons/flat-rounded/discover.svg",
    logo: "images/cc_icons/logo/discover.svg",
    "logo-border": "images/cc_icons/logo-border/discover.svg",
    mono: "images/cc_icons/mono/discover.svg",
    "mono-border": "images/cc_icons/mono-border/discover.svg",
  },
  jcb: {
    flat: "images/cc_icons/flat/jcb.svg",
    "flat-rounded": "images/cc_icons/flat-rounded/jcb.svg",
    logo: "images/cc_icons/logo/jcb.svg",
    "logo-border": "images/cc_icons/logo-border/jcb.svg",
    mono: "images/cc_icons/mono/jcb.svg",
    "mono-border": "images/cc_icons/mono-border/jcb.svg",
  },
  mastercard: {
    flat: "images/cc_icons/flat/mastercard.svg",
    "flat-rounded": "images/cc_icons/flat-rounded/mastercard.svg",
    logo: "images/cc_icons/logo/mastercard.svg",
    "logo-border": "images/cc_icons/logo-border/mastercard.svg",
    mono: "images/cc_icons/mono/mastercard.svg",
    "mono-border": "images/cc_icons/mono-border/mastercard.svg",
  },
  visa: {
    flat: "images/cc_icons/flat/visa.svg",
    "flat-rounded": "images/cc_icons/flat-rounded/visa.svg",
    logo: "images/cc_icons/logo/visa.svg",
    "logo-border": "images/cc_icons/logo-border/visa.svg",
    mono: "images/cc_icons/mono/visa.svg",
    "mono-border": "images/cc_icons/mono-border/visa.svg",
  },
};

const sizeMap = {
  sm: { height: 22 },
  md: { height: 30 },
  lg: { height: 38 },
};

const CreditCardIcon = ({ cardType, size, variation, ...rest }) => {
  if (!(cardType in logoMap)) {
    return null;
  }

  return (
    <img
      {...{
        alt: altMap[cardType],
        ...(sizeMap[size] || sizeMap.sm),
        src: logoMap[cardType][variation],
        ...rest,
      }}
    />
  );
};

CreditCardIcon.propTypes = {
  cardType: PropTypes.oneOf(["amex", "diners", "discover", "jcb", "mastercard", "visa"]).isRequired,
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  variation: PropTypes.oneOf(["flat", "flat-rounded", "logo", "logo-border", "mono", "mono-outline"]),
};

CreditCardIcon.defaultProps = {
  size: "sm",
  variation: "flat-rounded",
};

export default CreditCardIcon;
