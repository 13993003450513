import systemUnitToClientUnit from "./systemUnitToClientUnit";
import { keyBy } from "lodash";
import { useMemo } from "react";

import useUnits from "hooks/useUnits";

const useUnitOfMeasure = (unitOfMeasure) => {
  const { data } = useUnits();
  const unitsById = useMemo(() => {
    const allUnits = [data?.seedCountUnits, data?.volumeUnits, data?.weightUnits].flat(Infinity).filter((x) => x);
    const convertedUnits = allUnits.map(systemUnitToClientUnit);
    return keyBy(convertedUnits, "value");
  }, [data]);

  return unitsById[unitOfMeasure];
};

export default useUnitOfMeasure;
