import BillingPage from "billing/pages/BillingPage";

import App from "layout/app";
import SubRoute from "lib/SubRoute";

const BillingRouter = SubRoute.extend({
  routes: {
    "": "index",
  },

  index() {
    return App.display(BillingPage);
  },
});

export default () => new BillingRouter("billing", { createTrailingSlashRoutes: false });
