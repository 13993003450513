const titleize = (str) => {
  if (typeof str !== "string") {
    return "";
  }

  return str
    .toLowerCase()
    .replace(/-|_/g, " ")
    .replace(/(?:^|\s|-)\S/g, (m) => {
      return m.toUpperCase();
    });
};

export default titleize;
