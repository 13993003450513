import Dashboard from "./pages/Dashboard";

import { wrapWithProvider, marketingClient } from "collection/graphql/client";
import App from "layout/app";
import SubRoute from "lib/SubRoute";

const ImageryRouter = SubRoute.extend({
  routes: {
    "": "index",
  },

  display(page, props) {
    App.display(wrapWithProvider(page, marketingClient), props);
  },

  index() {
    return this.display(Dashboard, { screenName: "Satellite Imagery" });
  },
});

export default () => new ImageryRouter("imagery", { createTrailingSlashRoutes: true });
