import { FERTILIZER, HARVESTER, PLANTER, SPRAYER, TILLER, WAGON } from "../constants";

export default [
  {
    name: "Generic Fertilizer",
    type: FERTILIZER,
    filename: "fertilizer/generic-fertilizer.png",
  },
  {
    name: "Generic Beet Harvester Blue",
    type: HARVESTER,
    filename: "harvester/generic-beet-harvester-blue.png",
  },
  {
    name: "Generic Beet Harvester Green",
    type: HARVESTER,
    filename: "harvester/generic-beet-harvester-green.png",
  },
  {
    name: "Generic Beet Harvester Red",
    type: HARVESTER,
    filename: "harvester/generic-beet-harvester-red.png",
  },
  {
    name: "Generic Planter",
    type: PLANTER,
    filename: "planter/generic-planter.png",
  },
  {
    name: "Generic Sprayer",
    type: SPRAYER,
    filename: "sprayer/generic-sprayer.png",
  },
  {
    name: "Generic Disk Ripper Blue",
    type: TILLER,
    filename: "tiller/generic-disk-ripper-blue.png",
  },
  {
    name: "Generic Plow",
    type: TILLER,
    filename: "tiller/generic-plow.png",
  },
  {
    name: "Generic Rotary Hoe",
    type: TILLER,
    filename: "tiller/generic-rotary-hoe.png",
  },
  {
    name: "Generic Row Cultivator",
    type: TILLER,
    filename: "tiller/generic-row-cultivator.png",
  },
  {
    name: "Generic Tiller",
    type: TILLER,
    filename: "tiller/generic-tiller.png",
  },
  { name: "Grain Cart", type: WAGON, filename: "wagon/grain-cart.png" },
];
