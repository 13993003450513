import themeGet from "@styled-system/theme-get";
import React from "react";
import styled from "styled-components";

import { BorderRadius, Spacing } from "components/fl-ui/constants";

const ResponsiveMenuContainer = styled.div`
  background-color: ${themeGet("colors.white")};
  border-radius: ${BorderRadius.medium};
  bottom: ${Spacing.small};
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  left: 0.5rem;
  padding: ${Spacing.xxsmall};
  position: fixed;
  transition: height 400ms cubic-bezier(0.37, 0, 0.63, 1);
  width: calc(100% - 1rem);
  z-index: 3000;
`;

ResponsiveMenuContainer.displayName = "ResponsiveMenuContainer";

export default ResponsiveMenuContainer;
