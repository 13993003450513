import PropTypes from "prop-types";
import React from "react";

import { FormGroup, Input } from "components/fl-ui/Form";
import CloseX from "components/fl-ui/Icons/CloseX";
import { Modal, ModalHeader, ModalTitle, ModalBody, ModalFooter } from "components/fl-ui/Modal/Modal";
import ModalActionButtonGroup from "components/fl-ui/Modal/ModalActionButtonGroup";

const FinancialAccountAddEditModal = ({ accountData, disabled, onArchive, onClose, onInputChange, onSave }) => {
  const { display, id, isArchived, name } = accountData;

  return (
    <Modal>
      <ModalHeader>
        <ModalTitle>{`${id ? "Edit" : "Create"} ${display} account`}</ModalTitle>
        <CloseX onClick={onClose} />
      </ModalHeader>

      <ModalBody>
        <FormGroup label="Name">
          <Input defaultValue={name || ""} name="name" onChange={onInputChange} />
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <ModalActionButtonGroup
          apply={{ action: onSave, buttonText: "Save", disabled: disabled || !name?.trim() }}
          cancel={{ action: onClose, buttonText: "Cancel" }}
          danger={
            id && {
              action: onArchive,
              buttonText: `${isArchived ? "Unarchive" : "Archive"} account`,
              disabled: disabled || !name?.trim(),
            }
          }
        />
      </ModalFooter>
    </Modal>
  );
};

FinancialAccountAddEditModal.propTypes = {
  accountData: PropTypes.shape({
    display: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isArchived: PropTypes.bool,
    name: PropTypes.string,
  }).isRequired,
  disabled: PropTypes.bool,
  onArchive: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default FinancialAccountAddEditModal;
