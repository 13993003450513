import createReactClass from "create-react-class";
import d3 from "d3";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";
import _ from "underscore";

import useActivityRecording from "hooks/useActivityRecording";
import useCropLayer from "hooks/useCropLayer";
import useYearFieldCrops from "modules/fields/hooks/useYearFieldCrops";

import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import events from "components/mixins/events";

const withActivityRecording = (Component) => (props) => {
  const { field, recordingId, recording: recordingProps } = props;
  const { loading, recording } = useActivityRecording(recordingId, recordingProps);
  const { getYearCropsForField } = useYearFieldCrops();
  const crops = getYearCropsForField(field?.id);
  const { layer } = useCropLayer({ crops, field });

  return (
    <LoadingWrapper isLoading={loading && !recording}>
      <Component {...props} layer={layer} recording={recording} />
    </LoadingWrapper>
  );
};

const MAPBOX_ZOOM_OFFSET = -1;

const getBounds = function (url) {
  // This takes the thumbnail address returned from leaflet and parses out
  // positional and dimensional information that is used to set up the svg.
  const split = url.split("/");
  const [lat, lng, zoom] = Array.from(split[split.length - 2].split(",").map((param) => Number(param)));
  const viewBox = split[split.length - 1].match(/(\d+)x(\d+)/);
  // Scale of the map tile
  const scale = (256 / Math.PI / 2) * Math.pow(2, zoom - MAPBOX_ZOOM_OFFSET);
  // Radius of the earth in feet: 20925524.9
  const feetPerPixel = 20925524.9 / scale;
  // This is our line width until we decide on something different
  // lineWidth = Math.ceil(implementWidth / feetPerPixel)
  const lineWidth = 3;

  return {
    center: [lat, lng],
    scale,
    viewBox: {
      width: viewBox[1],
      height: viewBox[2],
    },
    feetPerPixel,
    lineWidth,
  };
};

export default withActivityRecording(
  createReactClass({
    displayName: "RecordingPlayback",

    propTypes: {
      cropId: PropTypes.number.isRequired,
      field: PropTypes.shape({ id: PropTypes.number }).isRequired,
      previewUrl: PropTypes.string.isRequired,
      size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    },

    getDefaultProps() {
      return { size: 520 };
    },

    mixins: [events],

    componentDidMount() {
      this.d3 = d3.select(ReactDOM.findDOMNode(this));
      this.recording = this.props.recording.frames;
      this._setupComponent();
    },

    select(sel) {
      return this.d3.select(sel);
    },

    selectAll(sel) {
      return this.d3.selectAll(sel);
    },

    _controlPlayback(event) {
      const state = this.$(".js-playback-window").attr("data-playback-state");
      switch (state) {
        case "initial":
          if (!this.recording.length) {
            return;
          }
          this.animation.push(this.recording[0]);
          this.$(".js-playback-window").attr("data-playback-state", "playing");
          this._animate();
          break;
        case "paused":
          this._animate();
          this.$(".js-playback-window").attr("data-playback-state", "playing");
          break;
        case "playing":
          // Stop the transition
          this.rpath.transition().duration(0);
          this.$(".js-playback-window").attr("data-playback-state", "paused");
          break;
        case "finished":
          // To reset the animation, we set its value to the first frame of
          // the recording. Otherwise, calling @_animate() will resume.
          this.animation = [this.recording[0]];
          this._animate();
          this.$(".js-playback-window").attr("data-playback-state", "playing");
          break;
      }
      return event.stopPropagation();
    },

    //Takes a duration in milliseconds and returns a string
    _formatDuration(t, short) {
      t = moment.duration(t * 1000);
      if (Math.floor(Math.floor(t.asHours())) === 0 || short) {
        // We cast this duration as a time in UTC -- durations beyond 24 hours
        // won't be supported.
        return moment.utc(t._milliseconds).format("m:ss");
      } else {
        return moment.utc(t._milliseconds).format("H:mm:ss");
      }
    },

    _animate() {
      // Check to make sure this isn't the last frame
      if (this.animation.length === this.recording.length) {
        this.$(".js-playback-window").attr("data-playback-state", "finished");
        return;
      }
      // This is the base multiplier in ms
      let interval = 50;
      // The current frame
      const cur = _.last(this.animation);
      // The frame to be rendered
      const next = this.recording[this.animation.length];
      // Set the interval according to the multiplier if we're on at least the
      // second frame. Otherwise, leave it alone.
      if (this.animation.length > 1) {
        interval = 50 * (next.time - cur.time);
      }
      // Prepare the path
      return this.rpath
        .data([this.animation])
        .style("stroke-width", `${this.lineWidth}px`)
        .attr("d", this.line)
        .transition()
        .duration(interval) // Change the playback speed here
        .ease("linear") // Linear easing so the rendering doesn't slow on each point
        .attrTween("d", () => {
          // Set up an interpolator for the tween
          const interp = d3.interpolate([cur.lng, cur.lat], [next.lng, next.lat]);
          return (t) => {
            // Fetch the interpolation of the tween position
            const n = interp(t);
            const bearing = (Math.atan2(next.lng - cur.lng, next.lat - cur.lat) * 180) / Math.PI - 90;
            const marker = this.selectAll("#activity-marker");
            marker.transition().duration(75).attr("orient", bearing);
            // Whip up a temporary array that includes the coords of the interpolation
            const nextline = this.animation.concat({ lng: n[0], lat: n[1] });
            // Update the slider position and the brush extent
            // The position in the slider domain should be equal to the timestamp
            // of the current frame plus the difference between the next frame and
            // the current multiplied by t, where t is between 0 and 1 and represents
            // the tween progress between the two data points.
            const pos = cur.time + t * (next.time - cur.time);
            this.ctrl.brush.extent([pos, pos]);
            this.ctrl.handle.attr("cx", this.ctrl.scale(pos));
            this.ctrl.progressPath.attr(
              "d",
              this.ctrl.progressLine([
                { x: 0, y: 0 },
                { x: this.ctrl.scale(pos), y: 0 },
              ])
            );
            // Update the position/duration text
            this.ctrl.time
              .text((d) => `${this._formatDuration(d)}/${this._formatDuration(this.props.recording.duration)}`)
              .datum(pos - moment(this.props.recording.start).format("X"));
            // Update the timestamp
            this.timestamp.datum(moment(pos * 1000)).text((d) => d.format("h:mmA"));
            // Provide the path string for the current interpolation position
            return this.line(nextline);
          };
        })
        .each("end", () => {
          // Now we need to push the next recording frame onto the animation array
          this.animation.push(next);

          if (this.$(".js-playback-window").attr("data-playback-state") === "playing") {
            // Call to render the next frame
            return this._animate();
          }
        });
    },

    _brushed() {
      // Grab the first value from the brush extent and set it to our value.
      let value = this.ctrl.brush.extent()[0];

      if (d3.event.sourceEvent) {
        // Scale.invert() takes the coordinates of the mouse and provides a value
        // within the domain.
        // We have to pass the actual node of the input slider to d3.mouse and then
        // grab the x coordinate of the mouse position.
        let bearing;
        value = this.ctrl.scale.invert(d3.mouse(this.ctrl.slider.node())[0]);
        // Set both the left and right bounds of the extent to the value returned
        // above. We don't want the brush to cover a range, but rather a point
        // that roughly represents an index in the recording array.
        this.ctrl.brush.extent([value, value]);
        // Stop any current transitions -- this halts animation
        this.rpath.transition().duration(0);
        // Set the state of the playback to paused. This ensures that clicking play
        // will resume animation from the last frame provided by this brush slider.
        this.$(".js-playback-window").attr("data-playback-state", "paused");
        // Set the animation array to the slice of the array extending to the
        // current value
        this.animation = this.recording.filter((t) => t.time <= value);
        // Update the currently displayed line -- but DON'T resume animation
        this.rpath.data([this.animation]).attr("d", this.line).style("stroke-width", `${this.lineWidth}px`);
        const last = this.animation.slice(this.animation.length - 2);
        // Update the time display
        this.ctrl.time
          .datum(value - moment(this.props.recording.start).format("X"))
          .text((d) => `${this._formatDuration(d)}/${this._formatDuration(this.props.recording.duration)}`);
        // Update the timestamp
        this.timestamp.datum(moment(value * 1000)).text((d) => d.format("h:mmA"));
        // We need to get our bearing, but if we don't have at least two frames,
        // then just set it to 0
        if (last.length < 2) {
          bearing = 0;
        } else {
          // Grab the angle between the two points. This isn't super accurate
          // over long distances (it's not great circle math) but we don't need
          // it to be. The 90 degree subtraction at the end is to correct for
          // how the marker itself is drawn. We could change the marker and omit
          // the subtraction if necessary.
          bearing = (Math.atan2(last[1].lng - last[0].lng, last[1].lat - last[0].lat) * 180) / Math.PI - 90;
        }

        const marker = this.selectAll("#activity-marker");
        marker.transition().duration(75).attr("orient", bearing);
      }
      // Set the position of the handle to the position of the brush
      this.ctrl.handle.attr("cx", this.ctrl.scale(value));
      this.ctrl.progressPath.attr(
        "d",
        this.ctrl.progressLine([
          { x: 0, y: 0 },
          { x: this.ctrl.scale(value), y: 0 },
        ])
      );
      // Make sure to set the finished state if we drag to the end of the bar
      if (this.animation.length > this.recording.length - 2) {
        return this.$(".js-playback-window").attr("data-playback-state", "finished");
      }
    },

    _setupComponent() {
      if (!this.props.recording?.frames?.length) {
        return;
      }
      // Grab the field icon
      this.thumbnail = this.props.previewUrl;
      // Standard projection setup
      this.projection = d3.geo.mercator();
      // Get the bounds for the svg based on the tile returned from Leaflet
      ({
        center: this.center,
        scale: this.scale,
        viewBox: this.viewBox,
        feetPerPixel: this.feetPerPixel,
        lineWidth: this.lineWidth,
      } = getBounds(this.thumbnail));
      // Set up a blank object so keys can be added quickly
      this.ctrl = {};
      // Set up the projection with info we fetched from getBounds
      this.projection
        .center(this.center)
        .translate([this.viewBox.width / 2, this.viewBox.height / 2])
        .scale(this.scale);
      // Scope hack for line setup
      const prj = this.projection;
      // Set up our line generatorand specify how we should determine our x
      // and y positions. Use cardinal interpolation for smooth corners.
      // Monotone interpolation might work, too.
      // See: http://bl.ocks.org/mbostock/4342190
      this.line = d3.svg
        .line()
        .x((d) => prj([d.lng, d.lat])[0])
        .y((d) => prj([d.lng, d.lat])[1]);
      // Empty animation array. This will hold the current set of frames passed
      // to the line generator
      this.animation = [];
      // Load up the field thumbnail and embed it into the playback svg
      this.svg = this.select(".js-playback-svg");
      // Set up our marker def
      const marker = this.svg
        .select("defs")
        .append("svg:marker")
        .attr("class", "activity-marker activity-playback-arrow js-activity-playback-arrow")
        .attr("id", "activity-marker")
        .attr("markerHeight", 22)
        .attr("markerWidth", 22)
        .attr("refX", 10)
        .attr("refY", 10)
        .attr("viewBox", "0 0 100 100");

      marker
        .append("svg:circle")
        .style("fill", "#267fd9")
        .attr("cx", 10)
        .attr("cy", 10)
        .attr("r", 8)
        .attr("stroke", "#ffffff")
        .attr("stroke-width", "4");

      this.svg.attr("width", this.props.size).attr("height", this.props.size);
      this.svg
        .insert("svg:image", ".js-crop-path")
        .attr("xlink:href", this.thumbnail)
        .attr("width", this.props.size)
        .attr("height", this.props.size);
      // this.props.layer
      //   .setContainer(this.svg.node())
      //   .setProjection(this.projection)
      //   .render();
      // Give our viewbox the attributes we fetched earlier
      this.svg.attr("viewBox", `0 0 ${this.viewBox.width} ${this.viewBox.height}`);
      // The ghost path -- this shows the completed activity in full behind
      // the animated path.
      this.gpath = this.svg
        .append("svg:path")
        .datum(this.recording)
        .attr("d", this.line)
        .attr("class", "js-activity-ghost")
        .attr("class", "activity-ghost")
        .attr("stroke-linecap", "round")
        .style("stroke-width", `${this.lineWidth}px`);
      // This is our animated recording path. It
      this.rpath = this.svg
        .append("svg:path")
        .attr("class", "js-activity-path")
        .attr("class", "activity-path")
        .attr("stroke-linecap", "round")
        .attr("marker-end", "url(#activity-marker)");
      // Set up the slider control. Consider breaking this out into its own view.
      this.ctrl.sel = this.select(".js-playback-controls");
      // Set up a linear scale that covers the length of the array. This will
      // enable panning through the frames.
      // The range here is basically the width of the slider element that will
      // be displayed.
      this.ctrl.scale = d3.scale
        .linear()
        .domain([_.first(this.recording).time, _.last(this.recording).time])
        .range([0, 288])
        .clamp(true);

      this.ctrl.progressLine = d3.svg
        .line()
        .x((d) => d.x)
        .y((d) => d.y);
      // Set up the d3 brush that will handle grabbing the input from mouse events
      // Make sure extent is [0,0] since we're interested in a point and not a range.
      this.ctrl.brush = d3.svg.brush().x(this.ctrl.scale).extent([0, 0]).on("brush", this._brushed);
      // Styling
      this.ctrl.sel.attr("width", this.props.size * 0.9).attr("height", 20);
      // Append the axis and remove the text -- probably move the text removal
      // to the styles with a display:none
      // The translate ensures that the entire slider is visible and not cut off
      // at the bounds of the svg.
      // The tick modifications get rid of the default d3 tick styling that
      // make this look like an actual axis rather than a progress bar
      this.ctrl.sel
        .append("g")
        .attr("class", "js-slider-axis")
        .attr("transform", "translate(10,5)")
        .call(d3.svg.axis().scale(this.ctrl.scale).tickSize(0).tickPadding(1))
        .selectAll(".tick")
        .remove();
      // Call the brush here to enable clicking along the brush path
      this.select(".js-slider-axis").call(this.ctrl.brush);
      // Class for styling
      this.ctrl.sel.select(".domain").attr("class", "playback-control-track");
      // Add a path that will shade in the played portion of the control track
      this.ctrl.progressPath = this.select(".js-slider-axis")
        .insert("svg:path", "rect")
        .attr("class", "js-progress-path playback-control-progress")
        .attr("stroke-linecap", "round");
      // Add a slider. This is just a display object that is passed to brush so
      // that the brush knows its display element.
      this.ctrl.slider = this.ctrl.sel
        .append("g")
        .attr("transform", "translate(10,6)")
        .attr("class", "js-slider")
        .call(this.ctrl.brush);
      // Remove the extent and resize elements from the brush. These are a pain.
      this.ctrl.slider.selectAll(".extent,.resize").remove();
      // A display element that is 'used' to move the brush.
      this.ctrl.handle = this.ctrl.slider
        .append("circle")
        .attr("class", "js-slider-handle")
        .attr("class", "playback-slider-handle")
        .attr("r", 6);
      // Playback duration and progress display element
      this.ctrl.time = this.ctrl.sel
        .append("svg:text")
        .datum(0)
        .attr("class", "js-recording-duration recording-duration")
        .style("fill", "#fff")
        .style("font-weight", "500")
        .text((d) => `${this._formatDuration(d)}/${this._formatDuration(this.props.recording.duration)}`)
        .attr("transform", `translate(${0.67 * this.props.size},20)`);

      // Current frame's timestamp
      this.timestamp = this.select(".js-recording-timestamp")
        .datum(moment(this.props.recording.start))
        .style("fill", "#fff")
        .style("right", "0")
        .style("font-weight", "500")
        .text((d) => d.format("h:mmA"))
        .attr("transform", "translate(440,20)");
      // Average speed
      this.speed = this.select(".js-recording-speed")
        .datum(Math.round(this.props.recording.averageSpeed))
        .style("fill", "#fff")
        .style("left", "0")
        .style("font-weight", "500")
        .text((d) => `AVG SPEED: ${d} MPH`)
        .attr("transform", "translate(440,20)");
    },

    render() {
      if (this.props.recording?.frames?.length) {
        return (
          <div className="js-activity-playback-container">
            <div
              className="js-playback-window activity-recording"
              data-playback-state="initial"
              style={this.props.size ? { height: this.props.size, width: this.props.size } : {}}
            >
              <div className="js-recording-timestamp recording-timestamp" />
              <div className="js-recording-speed recording-timestamp" />
              <svg className="js-playback-svg">
                <defs />
                <path className="js-crop-path" />
              </svg>
              <div className="playback-backdrop" onClick={this._controlPlayback} />
              <div className="js-playback-control-container playback-control-container">
                <svg
                  className="playback-indicator pause"
                  onClick={this._controlPlayback}
                  x="0px"
                  y="0px"
                  width="75px"
                  height="100px"
                  viewBox="0 0 75 100"
                >
                  <rect width="25" height="100" />
                  <rect x="50" width="25" height="100" />
                </svg>
                <svg
                  className="playback-indicator play"
                  onClick={this._controlPlayback}
                  x="0px"
                  y="0px"
                  width="100px"
                  height="100px"
                  viewBox="0 0 100 100"
                  enableBackground="new 0 0 100 100"
                >
                  <polygon points="14.019,14.02 85.981,50.004 14.019,85.98  " />
                </svg>
                <svg
                  className="playback-indicator refresh"
                  onClick={this._controlPlayback}
                  x="0px"
                  y="0px"
                  width="96px"
                  height="100px"
                  viewBox="0 0 96 100"
                >
                  <path
                    d={`M83.803,13.197C74.896,5.009,63.023,0,50,0C22.43,0,0,22.43,0,50s22.43,50,50,50c13.763,0,26.243-5.59,35.293-14.618 \
    l-9.895-9.895C68.883,81.979,59.902,86,50,86c-19.851,0-36-16.149-36-36s16.149-36,36-36c9.164,0,17.533,3.447,23.895,9.105L62,35 \
    h20.713H96v-4.586V1L83.803,13.197z`}
                  />
                </svg>
                <svg className="js-playback-controls playback-track" />
              </div>
            </div>
          </div>
        );
      } else {
        return <div />;
      }
    },
  })
);
