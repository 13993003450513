import PropTypes from "prop-types";
import React from "react";

import { Button } from "components/fl-ui";
import ModalActionButtonGroup from "components/fl-ui/Modal/ModalActionButtonGroup";

const FilterActionButtonGroup = ({ apply, cancel, reset }) => (
  <ModalActionButtonGroup>
    <Button color="primary" onClick={apply.action}>
      {apply?.buttonText || "Apply filters"}
    </Button>
    <Button color="secondary" onClick={cancel.action}>
      {cancel?.buttonText || "Cancel"}
    </Button>
    <Button color="danger" hollow onClick={reset.action}>
      {reset?.buttonText || "Clear all filters"}
    </Button>
  </ModalActionButtonGroup>
);

const buttonShape = PropTypes.shape({
  action: PropTypes.func,
  buttonText: PropTypes.string,
});

FilterActionButtonGroup.propTypes = {
  apply: buttonShape.isRequired,
  cancel: buttonShape.isRequired,
  reset: buttonShape.isRequired,
};

export default FilterActionButtonGroup;
