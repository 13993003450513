import { gql } from "@apollo/client";

export default gql`
  mutation createTractor($input: CreateTractorInput!) {
    createTractor(tractor: $input) {
      ok
      tractor {
        ...TractorDetail
      }
    }
  }
`;
