import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import { string } from "yup";

import useUnits from "hooks/useUnits";
import AuthForm from "layout/components/AuthForm";
import FooterMessage from "layout/components/FooterMessage";

import { Checkbox, FormGroup, Input, Select } from "components/fl-ui/Form";
import { Stack } from "components/fl-ui/Layout";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import { Spacing } from "components/fl-ui/constants";
import { CheckboxLabel, CheckboxText, StyledRow, StyledHeader } from "fields/components/styledComponents";

const PLACEHOLDER = "Select...";
const copy = (
  <StyledHeader>
    Welcome to Bushel Farm! Let us know what brings you to Bushel Farm and we’ll help you get the most value from our
    service
  </StyledHeader>
);

const useCases = [
  { id: "monitor_weather", label: "Monitoring weather and/or crop conditions at specific locations" },
  { id: "track_activities", label: "Tracking everything that was done to my fields and the resulting yield" },
  { id: "see_profit", label: "Seeing the profit or loss for all my fields and crops" },
  { id: "market_grain", label: "Marketing my grain strategically and/or at the location with the best bid" },
  { id: "provide_reports", label: "Providing reports to third party stakeholders" },
  { id: "track_inputs", label: "Tracking my input purchases and their average cost" },
  { id: "assign_work", label: "Assigning work orders to people on my farm or for custom hire" },
];

const SurveyForm = ({ formData: { acreage, useType }, handleSubmit, isUpdating, options, setFormData, setOptions }) => {
  const { findEnumByName } = useUnits();
  const enterpriseUseTypes = findEnumByName("EnterpriseUseType")?.map(({ display, value }) => ({
    label: display,
    value,
  }));
  const isFarm = useType === "FARMER";

  const hasValidUseType = string().required().inEnum("EnterpriseUseType");
  const hasNoSelectedOptions = Object.values(options).filter((option) => !!option).length === 0;
  const conditionsForSubmitButtonDisablement = [
    isUpdating,
    isFarm && !acreage,
    !hasValidUseType || hasNoSelectedOptions,
  ];
  const isSubmitButtonDisabled = conditionsForSubmitButtonDisablement.some((condition) => condition);

  return (
    <AuthForm
      action="Next"
      copy={copy}
      disabled={isSubmitButtonDisabled}
      footer={<FooterMessage />}
      onSubmit={handleSubmit}
    >
      <Row>
        <Col xs={12}>
          <LoadingWrapper isLoading={!enterpriseUseTypes?.length} loadingText="Loading options...">
            <FormGroup label="I want to use Bushel Farm for my">
              <Select
                autoFocus
                disabled={isUpdating}
                name="useType"
                onChange={({ target }) => {
                  if (target.value !== PLACEHOLDER) {
                    const payload = isFarm ? { acreage: "", useType: target.value } : { useType: target.value };
                    setFormData(payload);
                  }
                }}
                options={enterpriseUseTypes}
                placeholder={PLACEHOLDER}
                value={useType}
              />
            </FormGroup>
          </LoadingWrapper>
        </Col>
      </Row>

      {isFarm && (
        <Row>
          <Col xs={12}>
            <FormGroup label="The number of acres I farm is:">
              <Input
                autoFocus
                defaultValue=""
                disabled={isUpdating}
                hasError={!acreage}
                name="acreage"
                onChange={({ target }) => setFormData({ acreage: +target.value })}
                placeholder="Enter farm acreage"
                suffix="ac"
                type="number"
              />
            </FormGroup>
          </Col>
        </Row>
      )}

      <div>
        {useType && (
          <Stack space={Spacing.regular}>
            <StyledRow>
              <h4>The main use cases I’m interested in are:</h4>
            </StyledRow>

            {useCases.map(({ id, label }) => (
              <StyledRow key={id}>
                <CheckboxLabel htmlFor={id}>
                  <Checkbox
                    checked={!!options[id]}
                    disabled={isUpdating}
                    id={id}
                    onChange={() => setOptions({ ...options, [id]: !options[id] })}
                  />
                  <CheckboxText>{label}</CheckboxText>
                </CheckboxLabel>
              </StyledRow>
            ))}
          </Stack>
        )}
      </div>
    </AuthForm>
  );
};

SurveyForm.propTypes = {
  formData: PropTypes.shape({
    acreage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    useType: PropTypes.string,
  }),
  handleSubmit: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool,
  options: PropTypes.object,
  setFormData: PropTypes.func.isRequired,
  setOptions: PropTypes.func.isRequired,
};

export default SurveyForm;
