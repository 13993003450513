import Mixins from "components/fl-ui/constants/mixins";

const Breakpoints = {
  screenXS_min: Mixins.toRem(1),
  screenSM_min: Mixins.toRem(480),
  screenMD_min: Mixins.toRem(800),
  screenLG_min: Mixins.toRem(1200),
};

export default Breakpoints;
