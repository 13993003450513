import { gql } from "@apollo/client";

import { COMMODITY } from "collection/graphql/commodities/fragments";
import { OTHER_EXPENSE_REVENUE_FRAGMENT } from "collection/graphql/profit_and_loss/fragments";

export default gql`
  query GetProfitAndLossForYear($cropYear: Int!) {
    profitAndLossData: getProfitAndLossForYear(cropYear: $cropYear) {
      acreage
      costPerAcre
      costTotal
      cropYear
      profitPerAcre
      profitTotal
      revenuePerAcre
      revenueTotal
      commodityCrops {
        # List of the year's commodity crops
        acreage
        costPerAcre
        costPerUnit
        costTotal
        cropYear
        growerYieldPerAcre
        growerYieldTotal
        name
        profitPerAcre
        profitPerUnit
        profitTotal
        revenuePerAcre
        revenuePerUnit
        revenueTotal
        yieldPerAcre
        yieldTotal
        commodity {
          ...Commodity
        }
        expenses {
          costPerAcre
          costPerUnit
          costTotal
          categories {
            id
            costPerAcre
            costPercentOfTotal
            costPerUnit
            costTotal
            name
          }
        }
        fieldCrops {
          # A list of field crops recorded for the year
          id
          acreage
          costPerAcre
          costPerUnit
          costTotal
          growerYieldPerAcre
          growerYieldTotal
          profitPerAcre
          profitPerUnit
          profitTotal
          revenuePerAcre
          revenuePerUnit
          revenueTotal
          yieldPerAcre
          yieldStoredAmount
          yieldStoredRate
          yieldStoredUnit
          yieldTotal
          yieldUnit
          field {
            id
            group {
              id
              name
            }
            name
          }
        }
        marketedCrop {
          id
        }
        revenue {
          revenuePerAcre
          revenuePerUnit
          revenueTotal
          categories {
            id
            name
            revenuePerAcre
            revenuePercentOfTotal
            revenuePerUnit
            revenueTotal
          }
        }
      }
      otherExpenses {
        ...OtherExpenseOrRevenue
      }
      otherRevenue {
        ...OtherExpenseOrRevenue
      }
    }
  }
  ${COMMODITY}
  ${OTHER_EXPENSE_REVENUE_FRAGMENT}
`;
