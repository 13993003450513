import { datadogRum } from "@datadog/browser-rum";
import { fileClient } from "@farmlogs/fl-ui";
import Backbone from "backbone";
// mixins + polyfills
import "backbone.queryparams";
import "backbone.validation";
import "core-js";
import $ from "jquery";
import leaflet from "leaflet";
import { mobileAppConfigureOnLaunch } from "mobile/mobileManager";
import { mobilePatchCordova } from "mobile/mobilePatchCordova";
import moment from "moment";
import _ from "underscore";
import "underscore.inflection";
import _s from "underscore.string";

import ajax from "lib/ajax";

mobilePatchCordova();
mobileAppConfigureOnLaunch();

// initialize filestack
fileClient.init(process.env.FILEPICKER_IO_API_KEY);

moment.updateLocale("en", {
  calendar: {
    lastDay: "[Yesterday]",
    sameDay: "[Today]",
    nextDay: "[Tomorrow]",
    thisweek: "dddd",
    lastWeek: "dddd",
    nextWeek: "dddd",
    sameElse: "L",
  },
});

// Console methods for browsers that don't expose them immediately
(function () {
  const methods = [
    "assert",
    "clear",
    "count",
    "debug",
    "dir",
    "dirxml",
    "error",
    "exception",
    "group",
    "groupCollapsed",
    "groupEnd",
    "info",
    "log",
    "markTimeline",
    "profile",
    "profileEnd",
    "table",
    "time",
    "timeEnd",
    "timeline",
    "timelineEnd",
    "timeStamp",
    "trace",
    "warn",
  ];

  const console = (window.console = window.console || {});

  return methods.forEach((method) => console[method] || (console[method] = () => {}));
})();

// Add leaflet default image path
leaflet.Icon.Default.imagePath = "/images/leaflet/";

// Mix underscore.string methods into underscore
_.mixin(_s.exports());
_.plural("people", "people");
_.singular("access", "access");

// Bring backbone validations library into backbone model
_.extend(Backbone.Model.prototype, Backbone.Validation.mixin);

// Extend default validation messages for all types
_.extend(Backbone.Validation.messages, {
  acceptance: "This field must be accepted",
  date: "This field must be a valid date",
  digits: "This field must be a positive whole number",
  email: "This field must be a valid email",
  phone: "This field must be a valid phone number e.g. (123) 456-7890",
  equalTo: "This field must be the same as {1}",
  inlinePattern: "This field is invalid",
  integers: "This field must be an integer",
  length: "This field must be {1} characters",
  max: "This field must be less than or equal to {1}",
  maxLength: "This field must be at most {1} characters",
  min: "This field must be greater than or equal to {1}",
  minLength: "This field must be at least {1} characters",
  number: "This field must be a number",
  oneOf: "This field must be one of: {1}",
  positiveNumbers: "This field must be a positive number",
  range: "This field must be between {1} and {2}",
  rangeLength: "This field must be between {1} and {2} characters",
  required: "This field is required",
  url: "This field must be a valid url",
});

_.extend(Backbone.Validation.patterns, {
  number: /^[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?$/,
  integers: /^(-)?\d+$/,
  positiveNumbers: /^(?:[1-9]\d*(?:\.\d+)?|0\.0*[1-9]\d*)$/,
  password: /.(?:[A-z]).*(?:\d)|.(?:\d).*(?:[A-z])/,
  phone: /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
});

_.extend(Backbone.Validation.validators, {
  date(val) {
    const date = moment(val, "YYYY-MM-DD");
    if (!date.isValid()) {
      return Backbone.Validation.messages.date;
    }
  },
  /* Custom Backbone Methods */
});

Backbone.Collection.prototype.post = function () {
  const models = this.slice().filter(function (model) {
    if (model.isNew() && model.isValid(true)) {
      model.saving = true;
      return model;
    }
  });

  if (!models.length) {
    return;
  }

  return ajax({
    type: "POST",
    contentType: "application/json",
    url: this.url,
    data: JSON.stringify(models),
  }).then((result) =>
    models.map(function (model, i) {
      model.saving = false;
      model.hasChangedSinceSync = false;
      return model.set(result[i]);
    })
  );
};

// Exact copy of original _valdate method except that it doesn't set
// "attrs" to extend a models current values

Backbone.Model.prototype._validate = function (attrs, options) {
  if (!options.validate || !this.validate) {
    return true;
  }

  const error = (this.validationError = this.validate(attrs, options) || null);
  if (!error) {
    return true;
  }

  this?.trigger?.("invalid", this, error, _.extend(options, { validationError: error }));
  return false;
};

Backbone.Model.prototype.set = _.wrap(Backbone.Model.prototype.set, function () {
  const args = Array.prototype.slice.call(arguments);
  const set = args.shift();
  this.hasChangedSinceSync = true;
  return set.apply(this, args);
});

Backbone.sync = _.wrap(Backbone.sync, function (sync, method, model, options) {
  options = options ? _.clone(options) : {};
  options.context = this;
  this.saving = true;

  if (this.request) {
    if (typeof this.request.reject === "function") {
      this.request.reject();
    }
    this.request = null;
  }

  return (this.request = sync(method, model, options).done(function () {
    this.request = null;
    this.saving = false;
    this.hasChangedSinceSync = false;
    return this?.trigger?.("complete");
  }));
});

Backbone.View.prototype.remove = function () {
  this.stopListening();
  this?.trigger?.("removed");
  return this.off();
};

$(document).on("click", ".print", () => window.print());

if (process.env.RUM_APPLICATION_ID !== undefined && process.env.RUM_CLIENT_TOKEN !== undefined) {
  datadogRum.init({
    applicationId: process.env.RUM_APPLICATION_ID,
    clientToken: process.env.RUM_CLIENT_TOKEN,
    site: "datadoghq.com",
    service: "farm-web-app",
    env: process.env.NODE_ENV,
    version: process.env.CLIENT_VERSION,
    sessionSampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    allowedTracingUrls: [/https:\/\/(.*\.)?bushel(farm|ops)\.com/],

    // turn off session replay
    sessionReplaySampleRate: 0,
    startSessionReplayRecordingManually: true,
  });
}
