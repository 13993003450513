import CommodityColorPickerOption from "./CommodityColorPickerOption";
import CommodityColorPickerSingleValue from "./CommodityColorPickerSingleValue";
import useColorOptions from "./useColorOptions";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import ReactSelect from "react-select";

import useViewType from "hooks/useViewType";

import useFormGroupContext from "components/fl-ui/Form/hooks/useFormGroupContext";
import { UIColors } from "components/fl-ui/colors";

/**
 * This number represents roughly the column size of each color cell. (36 pixel width + 2
 * pixel border + gaps of 10px.
 */
const COLUMN_WIDTH_MULTIPLIER = 48;

const CommodityColorPicker = ({ onBlur, onChange, value }) => {
  const options = useColorOptions();
  const columnCount = useViewType() === "mobile" ? 4 : 7;
  const { hasError } = useFormGroupContext();

  return (
    <ReactSelect
      components={{
        Option: CommodityColorPickerOption,
        SingleValue: CommodityColorPickerSingleValue,
      }}
      name="color"
      isSearchable={false}
      onBlur={onBlur}
      onChange={onChange}
      options={options}
      placeholder="Choose color"
      styles={{
        control: (provided) => {
          const alteredStyles = { ...provided };
          if (hasError) {
            /**
             * These styles are copied from {@link BorderedInputContainer}
             */
            alteredStyles.borderColor = UIColors.danger;
            alteredStyles.borderWidth = 2;
          }

          return alteredStyles;
        },

        menu: (provided) => ({
          ...provided,
          padding: "10px",
          width: columnCount * COLUMN_WIDTH_MULTIPLIER,
          zIndex: 8999,
        }),

        menuList: (provided) => ({
          ...provided,
          display: "grid",
          gap: "10px",
          gridTemplateColumns: `repeat(${columnCount}, 35px)`,
        }),

        singleValue: (provided) => ({
          ...provided,
          alignItems: "center",
          display: "flex",
          gap: "4px",
        }),
      }}
      value={_.find(options, { value })}
    />
  );
};

CommodityColorPicker.propTypes = {
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
};

export default CommodityColorPicker;
