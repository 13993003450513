import PropTypes from "prop-types";
import React, { useState } from "react";
import SelectionList from "settings/components/SelectionList";
import { SectionContainer } from "settings/styles";

import withViewType from "hoc/withViewType";

import { Button } from "components/fl-ui";

const ExpenseAndRevenueList = ({ copy, data, handleCreateData, handleEditData, type, viewType }) => {
  const { active, archived } = data;
  const [showArchived, setShowArchived] = useState(false);

  return (
    <SectionContainer>
      <SelectionList
        buttonText={`Add ${viewType === "desktop" ? type : ""} account`}
        copy={copy}
        handleCreateData={handleCreateData}
        handleEditData={handleEditData}
        header={`${type} accounts`}
        list={active}
        placeholder={archived.length ? `No active ${type} accounts` : `You haven’t created any ${type} accounts yet`}
      />

      {!!archived.length && (
        <>
          <Button
            color="primary"
            icon={showArchived ? "chevronDown" : "chevronRight"}
            link
            onClick={() => setShowArchived(!showArchived)}
          >
            {`${showArchived ? "Hide" : "Show"} archived ${type} accounts`}
          </Button>

          {showArchived && (
            <SelectionList
              copy={`Archived ${type} accounts`}
              handleEditData={handleEditData}
              list={archived}
              placeholder={`You haven’t archived any ${type} accounts yet`}
              secondary
            />
          )}
        </>
      )}
    </SectionContainer>
  );
};

ExpenseAndRevenueList.propTypes = {
  copy: PropTypes.any.isRequired,
  data: PropTypes.shape({
    active: PropTypes.array,
    archived: PropTypes.array,
  }).isRequired,
  handleCreateData: PropTypes.func,
  handleEditData: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  viewType: PropTypes.oneOf(["desktop", "mobile"]).isRequired,
};

export default withViewType(ExpenseAndRevenueList);
