import { useMutation } from "@apollo/client";

import buildFuturesContractInput from "collection/graphql/contracts/hooks/buildFuturesContractInput";
import { EDIT_FUTURES_CONTRACT } from "collection/graphql/marketing";

const useEditFuturesContract = (options) => {
  const save = useMutation(EDIT_FUTURES_CONTRACT, options)[0];

  return (contract, options = {}) => {
    const input = buildFuturesContractInput(contract);
    return save({
      variables: {
        id: contract.id,
        input,
      },
      ...options,
    });
  };
};

export default useEditFuturesContract;
