import d3 from "d3";

export default function dollarFormat(amount, options = {}) {
  const { precision = 2, returnZero = false } = options;

  const format = d3.format(`$,.${precision}f`);

  const num = Number(amount || 0);
  if (!(num || returnZero)) return "--";

  return format(amount);
}
