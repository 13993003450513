import { gql } from "@apollo/client";

export default gql`
  query getHeatHistory($geometry: String!, $tbase: Int, $tceil: Int, $startDate: Date!, $endDate: Date!) {
    daily: getFieldHeatHistory(start: $startDate, end: $endDate, geometry: $geometry, tbase: $tbase, tceil: $tceil)
      @rest(type: "FieldHeatHistory", path: "/heat/gdd?{args}") {
      amount
      date
      day @client
      lookup @client
      month @client
    }

    averages: getHistoricalHeatAverages(geometry: $geometry, tbase: $tbase, tceil: $tceil)
      @rest(type: "HistoricalGDDAverage", path: "/heat/gdd/averages?{args}") {
      amount
      day
      lookup @client
      month
    }
  }
`;
