import moment from "moment";

import { PLANNED } from "lib/constants";

/**
 * Returns a boolean true if the activity is OVERDUE, false otherwise.
 *
 * @param {Activity} activity
 * @param {Date|String} activity.date
 * @param {String} activity.status
 * @returns {Boolean}
 */
export default ({ date, status }) => status === PLANNED && moment().isAfter(date, "day");
