import { StyleSheet, css } from "aphrodite/no-important";
import React from "react";

import { useMarketingPageContext } from "context/MarketingPageContext";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";

import MarketingBlankStatePage from "components/advertisements/pages/MarketingBlankStatePage";
import { Button } from "components/fl-ui";
import BlankSlate from "components/fl-ui/BlankSlate";
import Icon from "components/fl-ui/Icons/Icon";
import { Typography } from "components/fl-ui/constants";

const AddCropBlankSlate = () => {
  const { sizes } = Typography;
  const styles = StyleSheet.create({
    blankSlateHeaderText: {
      fontSize: sizes.xl,
      fontWeight: 500,
      margin: "1em 0 0",
    },
    blankSlateBodyText: {
      lineHeight: "1.5em",
      margin: "1em 0",
      fontSize: sizes.lg,
    },
    blankSlateimage: {
      maxWidth: sizes.xxxl,
      margin: "auto",
    },
  });

  const { openCropEditModal } = useMarketingPageContext();

  return !useEnterpriseFeature("grain_marketing") ? (
    <MarketingBlankStatePage />
  ) : (
    <BlankSlate>
      <div>
        <Icon className={css(styles.blankSlateimage)} icon="dollar" />

        <p className={css(styles.blankSlateHeaderText)}>{`You haven't added any crops yet`}</p>

        <p className={css(styles.blankSlateBodyText)}>
          {`You haven't added any crops yet. As you add crops, they'll appear here.
          Add a new crop to get started.`}
        </p>
      </div>

      <Button color="primary" id="add-new-crop-button" onClick={openCropEditModal} size="lg">
        Add a Crop
      </Button>
    </BlankSlate>
  );
};

export default AddCropBlankSlate;
