import _ from "lodash";

/**
 * @typedef {Object} UnitOfMeasure
 * @property {String} abbr corresponds to `Unit.abbr` in grain marketing schema.
 * @property {String} legacyValue a unit identifier used by the REST services
 * @property {String} name corresponds to `Unit.display` in grain marketing schema.
 * @property {String} type corresponds to `Unit.type` in grain marketing schema.
 * @property {String} value corresponds to `Unit.data` in grain marketing schema. is the unique identifier.
 */

/**
 * Retrieves a unit by its legacy value.
 * @param {string} legacyValue
 * @return {UnitOfMeasure|null}
 */
export const unitByLegacyValue = (legacyValue) => {
  return _.find(Object.values(unitsOfMeasure), ({ legacyValue: pattern }) => pattern.test(legacyValue)) || null;
};

/**
 * @param {'COUNT'|'SEED_COUNT'|'VOLUME'|'WEIGHT'} unitType
 * @return {Object[]}
 */
export const unitsByType = (unitType) => {
  switch (unitType) {
    case "COUNT":
    case "SEED_COUNT":
      return Object.values(unitsOfMeasure).filter(({ type }) => type === "SEED_COUNT");

    case "VOLUME":
      return Object.values(unitsOfMeasure).filter(({ type }) => type === "VOLUME");

    case "WEIGHT":
      return Object.values(unitsOfMeasure).filter(({ type }) => type === "WEIGHT");

    default:
      return [];
  }
};

/**
 * Units of measure
 */
const unitsOfMeasure = {
  // Volume units
  ACRE_FOOT: {
    abbr: "ac-ft",
    legacyValue: /^(acre-foot|ac-ft)$/,
    name: "acre foot",
    type: "VOLUME",
    value: "ACRE_FOOT",
  },
  ACRE_INCH: {
    abbr: "ac-in",
    legacyValue: /(^acre-inch|ac-in)$/,
    name: "acre inch",
    type: "VOLUME",
    value: "ACRE_INCH",
  },
  CUBIC_FOOT: {
    abbr: "ft^3",
    legacyValue: /^(cubic-foot|ft\^3)$/,
    name: "cubic foot",
    type: "VOLUME",
    value: "CUBIC_FOOT",
  },
  CUBIC_YARD: {
    abbr: "yd^3",
    legacyValue: /^yd\^3$/,
    name: "cubic yard",
    type: "VOLUME",
    value: "CUBIC_YARD",
  },
  FLUID_OUNCE: {
    abbr: "fl oz",
    legacyValue: /^fl oz$/,
    name: "ounce",
    type: "VOLUME",
    value: "FLUID_OUNCE",
  },
  GALLON: {
    abbr: "gal",
    legacyValue: /^gal$/,
    name: "gallon",
    type: "VOLUME",
    value: "GALLON",
  },
  LITER: {
    abbr: "L",
    legacyValue: /^L$/,
    name: "liter",
    type: "VOLUME",
    value: "LITER",
  },
  MILLILITER: {
    abbr: "mL",
    legacyValue: /^mL$/,
    name: "milliliter",
    type: "VOLUME",
    value: "MILLILITER",
  },
  PINT: {
    abbr: "pt",
    legacyValue: /^pt$/,
    name: "pint",
    type: "VOLUME",
    value: "PINT",
  },
  QUART: {
    abbr: "qt",
    legacyValue: /^qt$/,
    name: "quart",
    type: "VOLUME",
    value: "QUART",
  },

  // Weight units
  GRAM: {
    abbr: "g",
    legacyValue: /^g$/,
    name: "gram",
    type: "WEIGHT",
    value: "GRAM",
  },
  HUNDREDWEIGHT: {
    abbr: "cwt",
    legacyValue: /^cwt$/,
    name: "hundredweight",
    type: "WEIGHT",
    value: "HUNDREDWEIGHT",
  },
  KILOGRAM: {
    abbr: "kg",
    legacyValue: /^kg$/,
    name: "kilogram",
    type: "WEIGHT",
    value: "KILOGRAM",
  },
  METRIC_TON: {
    abbr: "t",
    legacyValue: /^t$/,
    name: "tonne",
    type: "WEIGHT",
    value: "METRIC_TON",
  },
  POUND: {
    abbr: "lb",
    legacyValue: /^lb$/,
    name: "pound",
    type: "WEIGHT",
    value: "POUND",
  },
  OUNCE: {
    abbr: "oz",
    legacyValue: /^oz$/,
    name: "ounce",
    type: "WEIGHT",
    value: "OUNCE",
  },
  TON: {
    abbr: "ton",
    legacyValue: /^ton$/,
    name: "ton",
    type: "WEIGHT",
    value: "TON",
  },

  // Seed units
  SEED: {
    abbr: "seed",
    legacyValue: /^seed$/,
    name: "seed",
    type: "SEED_COUNT",
    value: "SEED",
  },
  SEED_1K: {
    abbr: "1k seed",
    legacyValue: /^1k seed$/,
    name: "1k seed",
    type: "SEED_COUNT",
    value: "SEED_1K",
  },
  SEED_10K: {
    abbr: "10k seed",
    legacyValue: /^10k seed$/,
    name: "10k seed",
    type: "SEED_COUNT",
    value: "SEED_10K",
  },
  SEED_80K: {
    abbr: "80k seed",
    legacyValue: /^80k seed$/,
    name: "80k seed",
    type: "SEED_COUNT",
    value: "SEED_80K",
  },
  SEED_100K: {
    abbr: "100k seed",
    legacyValue: /^100k seed$/,
    name: "100k seed",
    type: "SEED_COUNT",
    value: "SEED_100K",
  },
  SEED_140K: {
    abbr: "140k seed",
    legacyValue: /^140k seed$/,
    name: "140k seed",
    type: "SEED_COUNT",
    value: "SEED_140K",
  },
  SEED_200K: {
    abbr: "200k seed",
    legacyValue: /^200k seed$/,
    name: "200k seed",
    type: "SEED_COUNT",
    value: "SEED_200K",
  },
  SEED_220K: {
    abbr: "220k seed",
    legacyValue: /^220k seed$/,
    name: "220k seed",
    type: "SEED_COUNT",
    value: "SEED_220K",
  },
  SEED_230K: {
    abbr: "230k seed",
    legacyValue: /^230k seed$/,
    name: "230k seed",
    type: "SEED_COUNT",
    value: "SEED_230K",
  },
  SEED_600K: {
    abbr: "600k seed",
    legacyValue: /^600k seed$/,
    name: "600k seed",
    type: "SEED_COUNT",
    value: "SEED_600K",
  },
  SEED_750K: {
    abbr: "750k seed",
    legacyValue: /^750k seed$/,
    name: "750k seed",
    type: "SEED_COUNT",
    value: "SEED_750K",
  },
  SEED_1M: {
    abbr: "1M seed",
    legacyValue: /^1M seed$/,
    name: "1M seed",
    type: "SEED_COUNT",
    value: "SEED_1M",
  },
};

export default unitsOfMeasure;
