import { css, StyleSheet } from "aphrodite";
import { SupportEmailLink } from "billing/lib";
import React from "react";

import BlankSlate from "components/fl-ui/BlankSlate";
import { Spacing } from "components/fl-ui/constants/index";

const styles = StyleSheet.create({
  icon: {
    paddingBottom: Spacing.Small,
    height: "2em",
  },
  message: {
    lineHeight: Spacing.large,
  },
  title: {
    paddingBottom: Spacing.Small,
  },
});

const ReportError = () => (
  <BlankSlate
    title={
      <span>
        <img className={css(styles.icon)} src="/images/icons/error.svg" />
      </span>
    }
  >
    <h2 className={css(styles.title)}>Error Loading Report</h2>
    <p className={css(styles.message)}>
      This is often because it is taking too long to load the results. Please try adjusting your filters to request less
      data and try again. Please email us at <SupportEmailLink /> if you continue to have issues loading your report.
    </p>
  </BlankSlate>
);

const NoReportData = () => (
  <BlankSlate title="No Report Data Available">
    <p>
      No data exists for the requested filters.
      <br />
      Refine the report filters and try again.
    </p>
  </BlankSlate>
);

export { ReportError, NoReportData };
