import styled from "styled-components";

import { Input } from "components/fl-ui/Form/index";
import Icon from "components/fl-ui/Icons/Icon";
import { ColorPalette, GreyColors } from "components/fl-ui/colors";
import { BorderRadius, Borders, Spacing, Typography } from "components/fl-ui/constants";

export const DataRowContentContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;

  @media only screen and (max-width: 62.5rem) {
    width: 100%;
  }
`;

export const BorderedContainer = styled.div`
  border: ${Borders.regular};
  border-radius: ${BorderRadius.medium};
  padding: ${Spacing.small};
  margin-bottom: ${Spacing.regular};

  & div {
    border: none;
    margin-bottom: 0;
  }
`;

export const TopErrorText = styled.div`
  background-color: ${ColorPalette["medium-red"]};
  border-radius: ${BorderRadius.medium};
  color: ${GreyColors.smoke20};
  display: inline-flex;
  font-size: ${Typography.sizes.sm};
  justify-content: space-between;
  margin-bottom: ${Spacing.minor};
  padding: ${Spacing.xsmall} ${Spacing.regular};
  width: 100%;

  & > svg {
    width: 6%;
  }
  & > div {
    width: 88%;
    display: flex;
    align-items: center;
  }

  a,
  button {
    color: ${GreyColors.smoke20};
    font-weight: normal;
    font-size: ${Typography.sizes.sm};
    text-decoration: underline;
  }
`;

export const CheckboxLabel = styled.label`
  align-items: end;
  display: flex;
  font-size: ${Typography.sizes.rg}
  line-height: 1.3;
  margin-bottom: ${Spacing.xsmall};
`;

export const Container = styled.div`
  align-items: center;
  border-bottom: ${Borders.regular};
  display: flex;
  justify-content: space-between;
  margin-bottom: ${Spacing.regular};
  padding-bottom: ${Spacing.regular};
  ${(props) =>
    props.bordered &&
    `
    border: ${Borders.regular};
    border-radius: ${BorderRadius.medium};
    padding: ${Spacing.regular};
  `}

  @media only screen and (max-width: 62.5rem) {
    align-items: flex-start;
    flex-flow: column;
    justify-content: center;
  }
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  margin-right: ${Spacing.regular};

  @media only screen and (max-width: 62.5rem) {
    margin-bottom: ${Spacing.xsmall};
    margin-right: ${Spacing.xxsmall};
  } ;
`;

export const DropdownContainer = styled.div`
  border-bottom: ${Borders.regular};
  margin-bottom: ${Spacing.small};
  padding-bottom: ${Spacing.large};
`;

export const ImportDescription = styled.p`
  line-height: 1.3;
  margin-bottom: 1rem;
  margin-left: 1.5rem;
`;

export const InlineInput = styled(Input)`
  margin-right: ${Spacing.small};
`;

export const IntegrationImage = styled.img`
  filter: grayscale(${(props) => (props.disabled ? 1 : 0)});
  max-height: 40px;
  max-width: 100px;
`;

export const List = styled.li`
  margin-bottom: ${Spacing.regular};
  padding-left: ${Spacing.xsmall};
`;

export const OrderedList = styled.ol`
  list-style: decimal;
  margin: ${Spacing.regular};
`;

export const MatchGrid = styled.div`
  display: grid;
  grid-gap: ${Spacing.medium};
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
`;

export const ResponsiveHeading = styled.h2`
  font-size: ${Typography.sizes.lg};
  text-transform: capitalize;
  @media only screen and (min-width: 62.5rem) {
    font-size: ${Typography.sizes.xl};
  } ;
`;

export const StyledIcon = styled(Icon)`
  height: ${Spacing.large} !important;
  margin-right: ${Spacing.regular};
  min-width: ${Spacing.large} !important;
  width: ${Spacing.large} !important;

  @media only screen and (max-width: 62.5rem) {
    margin-right: ${Spacing.small};
  }
`;

export const StyledLogo = styled.div`
  border: ${Borders.regular};
  display: grid;
  margin-right: ${Spacing.regular};
  height: 50px;
  min-width: 110px;
  place-items: center;
  width: 110px;

  @media only screen and (max-width: 62.5rem) {
    margin-right: ${Spacing.small};
  }
`;

export const StyledText = styled.p`
  margin-bottom: ${Spacing.xxsmall};
  line-height: 1.5;

  @media only screen and (max-width: 62.5rem) {
    font-size: ${Typography.sizes.sm};
  }
`;

export const TextBox = styled.div`
  background: ${GreyColors.smoke96};
  border: 1px solid ${GreyColors.smoke96};
  margin: ${Spacing.small} 0 ${Spacing.regular};
  padding: ${Spacing.small};
`;

export const UnorderedList = styled.ul`
  list-style: disc;
  margin: ${Spacing.regular};
`;
