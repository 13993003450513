import BlankSlate from "imagery/components/BlankSlate";
import FilterModal from "imagery/components/FilterModal";
import GroupedLayers from "imagery/components/GroupedLayers";
import ImageryList from "imagery/components/ImageryList";
import { BlankSlateContainer, ControlsContainer, HideOnMobile } from "imagery/styledComponents";
import { getFilteredLayers, getSortedLayers, imageTypes, sortOptions } from "imagery/utils";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";

import { useFeatureAndPermissionAccess } from "hooks/useFeatureAndPermissionAccess";
import useLatestImagery from "lib/map/imagery/leaf/useLatestImagery";

import ImageryBlankSlateBanner from "components/advertisements/banners/ImageryBlankSlateBanner";
import PayGateCommonBlankStatePage from "components/advertisements/pages/PayGateCommonBlankStatePage";
import { GroupedButtons } from "components/fl-ui/Buttons";
import Filters from "components/fl-ui/Filters/Filters";
import RefineFilters from "components/fl-ui/Filters/RefineFilters";
import Container from "components/fl-ui/Layout/Container";
import Header from "components/fl-ui/Layout/Header";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import RestrictedAccessBlankslate from "components/fl-ui/RestrictedAccessBlankslate";

const Dashboard = ({ screenName }) => {
  const { loadingAccess, showPaygate, showRestricted, showContent } = useFeatureAndPermissionAccess({
    featureName: "crop_health_enterprise",
    permissionName: "imagery",
  });
  const { layers, loading } = useLatestImagery();
  const [filters, setFilters] = useState({ commodities: [], groups: [], irrigation: [] });
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [sortCriteria, setSortCriteria] = useState("group");
  const [type, setType] = useState("NDVI");
  const filteredLayers = useMemo(() => getFilteredLayers(layers, filters), [layers, filters]);
  const sortedLayers = useMemo(() => getSortedLayers(filteredLayers, sortCriteria), [filteredLayers, sortCriteria]);
  const fields = layers.map(({ field }) => field);
  const currentYear = new Date().getFullYear();
  const cropYears = [currentYear, currentYear + 1];

  return (
    <Container>
      <LoadingWrapper isLoading={loadingAccess || (loading && !layers.length)}>
        <Header title={screenName} />

        {showPaygate && <PayGateCommonBlankStatePage banner={ImageryBlankSlateBanner} />}
        {showRestricted && <RestrictedAccessBlankslate />}
        {showContent && (
          <>
            {!loading && !layers.length ? (
              <BlankSlateContainer>
                <BlankSlate />
              </BlankSlateContainer>
            ) : (
              <>
                <ControlsContainer>
                  <HideOnMobile>
                    <GroupedButtons
                      borderless
                      onClick={({ value }) => setType(value)}
                      options={imageTypes}
                      selected={imageTypes.find(({ value }) => value === type)}
                      style={{ width: "18rem" }}
                    />
                  </HideOnMobile>

                  <Filters
                    filters={filters}
                    setShowFilterModal={setShowFilterModal}
                    setSortCriteria={setSortCriteria}
                    sortCriteria={sortCriteria}
                    sortOptions={sortOptions}
                  />
                </ControlsContainer>

                {!sortedLayers?.length && <RefineFilters filterType="Satellite Imagery" icon="imagery" />}

                {sortCriteria === "group" &&
                  sortedLayers.map(({ group, layers }) => (
                    <GroupedLayers key={group} group={group} layers={layers} type={type} />
                  ))}

                {sortCriteria !== "group" && <ImageryList layers={sortedLayers} type={type} />}
              </>
            )}
          </>
        )}
      </LoadingWrapper>

      {showFilterModal && (
        <FilterModal
          fields={fields}
          filters={filters}
          imageType={type}
          onClose={() => setShowFilterModal(false)}
          setFilters={setFilters}
          setImageType={setType}
          setSortCriteria={setSortCriteria}
          sortCriteria={sortCriteria}
          years={cropYears}
        />
      )}
    </Container>
  );
};

Dashboard.propTypes = {
  screenName: PropTypes.string,
};

export default Dashboard;
