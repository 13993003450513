import eventFactory from "lib/metrics/event_factory";

export const ALERTS_DISMISS_ALL_CLICKED = eventFactory("Alerts: Dismiss All Clicked", {});
export const ALERTS_ALERT_DISMISSED = eventFactory("Alerts: Alert Dismissed", {
  dynamic: ["alertId"],
});
export const ALERTS_VIEW_ALL_CLICKED = eventFactory("Alerts: View All Clicked", {});
export const ALERTS_SETTINGS_ICON_CLICKED = eventFactory("Alerts: Settings Icon Clicked", {});
export const ALERTS_ACTION_VIEW_CROP = eventFactory("Alerts: View Crop", {});
export const ALERTS_ACTION_VIEW_MARKETING = eventFactory("Alerts: View Marketing", {});
export const ALERTS_ACTION_VIEW_RAINFALL = eventFactory("Alerts: View Rainfall", {});
export const ALERTS_ACTION_VIEW_WORKORDER = eventFactory("Alerts: View Work Order", {});
