import React from "react";

import withActivityTypes from "collection/graphql/activities/withActivityTypes";

export default (WrappedComponent) =>
  withActivityTypes((props) => {
    const { data, ...rest } = props;
    if (!data.activityTypes) {
      return null;
    }

    return <WrappedComponent {...rest} activityTypes={data.activityTypes} />;
  });
