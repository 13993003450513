import React from "react";
import { useSubscriptionPlatform } from "collection/graphql/subscription";
import { Button } from "components/fl-ui";
import { isNative } from "mobile/mobileManager";

const ManageElsewhere = () => {
  const platform = useSubscriptionPlatform();
  if (isNative() && platform?.isWebPaymentProvider) {
    return "Please log in to your Bushel Farm account from a web browser to manage your subscription.";
  }

  if (platform?.isMobilePaymentProvider) {
    return (
      <Button color="primary" href={platform.storeUrl} rel="noopener noreferrer" target="_blank">
        Manage subscription through {platform.storeName}
      </Button>
    );
  }

  return null;
};

export default ManageElsewhere;
