import { StyleSheet, css } from "aphrodite";
import React from "react";

import { ColorPalette } from "components/fl-ui/colors";

const { red, green } = ColorPalette;

const styles = StyleSheet.create({
  positive: {
    color: green,
  },
  negative: {
    color: red,
  },
});

const RedGreenText = ({ value, children }) => {
  return <span className={css(value > 0 ? styles.positive : styles.negative)}>{children || value}</span>;
};

export default RedGreenText;
