import { sumBy } from "lodash";

export const MIN_ACREAGE = 0;
export const isValid = (formData, selectedFields, minAcreage = MIN_ACREAGE) => {
  const totalAcreage = sumBy([...selectedFields], "acreage");

  for (const key in formData) {
    if (!formData[key] && key !== "address2") {
      return false;
    }
  }

  return selectedFields.size > 0 && totalAcreage >= minAcreage;
};

export const TOOLTIP_MESSAGE = `
  Qualifying practice changes are those that increase your soil organic matter levels or
  decrease other greenhouse emissions, e.g. adding cover crops, reducing tillage, reducing
  fertilizer use, diversifying your rotation or integrating livestock.
`;
