import { StyleSheet, css } from "aphrodite/no-important";
import classnames from "classnames";
import React from "react";

import { Mixins } from "components/fl-ui/constants";

const styles = StyleSheet.create({
  svg: {
    fill: "currentColor",
    width: Mixins.toRem(18),
    height: Mixins.toRem(24),
    display: "inline-block",
  },
});

const DarkSkyLogo = ({ className }) => (
  <svg
    width="12"
    height="16"
    viewBox="0 0 12 16"
    preserveAspectRatio="xMidYMid meet"
    className={classnames(css(styles.svg), className)}
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>darksky</title>
    <path
      d="M5.556.251c-.138.533-.819 1.688-1.55 2.626-.191.245-.348.452-.348.455 0 .006.217-.085.48-.201a8.266 8.266 0 0 1 1.6-.546c.402-.09 1.158-.207 1.352-.207.094 0 .116-.01.1-.05C7.14 2.199 5.905.37 5.692.11L5.616.019l-.06.232zM7.14 2.996c-.627.75-1.088 1.44-1.402 2.099-.16.342-.354.806-.339.822.003.006.273-.07.596-.166a7.33 7.33 0 0 1 .596-.167c.007.007-.097.23-.232.5-.436.88-.721 1.69-.847 2.421-.069.392-.154 1.421-.12 1.453.013.012.202-.063.421-.167.223-.106.405-.188.411-.182.007.007-.031.204-.084.446a12.521 12.521 0 0 0-.295 2.817c0 .819.025 1.13.15 1.917.12.734.23 1.173.295 1.173.16 0 .806-.14 1.136-.25a5.7 5.7 0 0 0 3.746-4.054 5.594 5.594 0 0 0-.066-2.968C10.78 7.62 10.3 6.776 8.9 4.81a97.826 97.826 0 0 1-1.013-1.46 12.923 12.923 0 0 0-.461-.652c-.016-.006-.144.129-.286.298zM2.952 4.16C1.167 6.271.376 7.677.075 9.28c-.1.527-.1 1.522 0 2.045a5.664 5.664 0 0 0 1.553 2.971A5.628 5.628 0 0 0 4.631 15.9c.354.065 1.352.125 1.455.084.038-.015-.003-.106-.204-.436-.542-.89-.894-1.688-1.095-2.481-.116-.462-.25-1.18-.25-1.34 0-.172-.038-.172-.34 0-.156.088-.297.154-.316.144-.035-.022-.23-.818-.301-1.226-.142-.803-.201-1.807-.151-2.488.016-.198.019-.36.01-.364-.01-.006-.192.063-.408.157l-.393.163-.025-.097c-.028-.113-.003-.989.044-1.528.063-.74.236-1.556.483-2.303.063-.185.11-.345.104-.348-.006-.006-.138.138-.292.323z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default DarkSkyLogo;
