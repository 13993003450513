import { useQuery } from "@apollo/client";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import CreatableReactSelect from "react-select/creatable";

import getContractBuyerNames from "collection/graphql/contracts/queries/getContractBuyerNames";

import { chooserStyles } from "components/fl-ui/Chooser";

const ContractBuyerDropdown = ({ disabled = false, id, onBlur, onChange, placeholder = "", value }) => {
  const { data, loading } = useQuery(getContractBuyerNames, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const options = _.map(data?.buyerNames, ({ name }) => ({
    label: name,
    value: name,
  }));

  let selectedOption = _.find(options, { value }) ?? null;
  if (!selectedOption && value) {
    selectedOption = { label: value, value };
    options.push(selectedOption);
  }

  return (
    <CreatableReactSelect
      getNewOptionData={(value) => ({ label: `Create ${value.trim()}`, value: value.trim() })}
      inputId={id}
      isClearable
      isDisabled={disabled || loading}
      isValidNewOption={(value) => !!value.trim()}
      onBlur={onBlur}
      onChange={(option) => onChange(option?.value ?? null)}
      options={options}
      placeholder={placeholder}
      styles={chooserStyles}
      value={selectedOption}
    />
  );
};

ContractBuyerDropdown.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

export default ContractBuyerDropdown;
