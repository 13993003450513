import { useQuery } from "@apollo/client";

import { getProductPurchaseDetail } from "collection/graphql/products/queries";
import useCurrentCropYear from "hooks/useCurrentCropYear";

/**
 * @param {Number} productId
 */
const useProductDetailData = (productId) => {
  const cropYear = useCurrentCropYear()[0];
  const { data, loading, refetch } = useQuery(getProductPurchaseDetail, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: {
      products: [productId],
      year: cropYear,
    },
  });
  const summary = data?.purchaseSummaries?.[0];

  return {
    cropYear,
    loading,
    refreshQuery: refetch,
    summary,
  };
};

export default useProductDetailData;
