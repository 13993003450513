import { useQuery } from "@apollo/client";
import { keyBy, sortBy } from "lodash";
import { useMemo } from "react";

import { marketingClient } from "collection/graphql/client";
import { GET_COMMODITIES } from "collection/graphql/commodities/queries";

const useCommodities = () => {
  const { data, loading } = useQuery(GET_COMMODITIES, { client: marketingClient });
  const commodities = useMemo(
    () => sortBy(data?.commodities, [({ isUsed }) => !isUsed, ({ name }) => name.toLowerCase()]),
    [data?.commodities]
  );

  const commoditiesById = useMemo(() => keyBy(data?.commodities, "id"), [data?.commodities]);
  const getCommodityById = (commodityId) => commoditiesById[commodityId];
  return { commodities, getCommodityById, loading };
};

export default useCommodities;
