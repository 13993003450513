import * as yup from "yup";

import { nanTransform } from "lib/validation/yupPolyfill";

const maxDecimalOf4Rule = (number) => {
  return String(number).indexOf(".") === -1 || String(number).split(".")[1].length <= 4;
};

const additionalAdjustments = yup
  .number()
  .label("Additional Adjustments")
  .transform(nanTransform)
  .default(null)
  .nullable()
  .currency();

const adjustments = yup.number().label("Adjustments").transform(nanTransform).default(null).nullable().currency();

const basis = yup
  .number()
  .label("Basis")
  .transform(nanTransform)
  .typeError("${path} is invalid")
  .default(null)
  .nullable()
  .currency();

const cashPrice = yup
  .number()
  .label("Cash Price")
  .transform(nanTransform)
  .default(null)
  .nullable()
  .max(1000000000)
  .min(0)
  .test("maxDecimals", "Cash Price must have 4 or less decimal places", (number) => maxDecimalOf4Rule(number));

const deliveryEndDate = yup
  .date()
  .label("Delivery End Date")
  .default(null)
  .required()
  .min(yup.ref("deliveryStartDate"), "Delivery End Date must be on or after Delivery Start Date");

const deliveryStartDate = yup
  .date()
  .label("Delivery Start Date")
  .default(null)
  .required()
  .min(new Date(new Date().getFullYear() - 20, 0, 1))
  .max(yup.ref("deliveryEndDate"), "Delivery Start Date must be on or before Delivery End Date");

const futuresPrice = yup
  .number()
  .label("Futures Price")
  .transform(nanTransform)
  .default(null)
  .nullable()
  .max(1000000000)
  .min(0)
  .test("maxDecimals", "Futures Price must have 4 or less decimal places", (number) => maxDecimalOf4Rule(number));

const id = yup.number().label("ID").integer().positive().nullable();

const notes = yup.string().label("Notes").default(null).trim().nullable();

const deliveryLocationId = yup.string().label("Location").nullable();

const quantityDelivered = yup.number().label("Quantity delivered").default(null).min(0).max(1000000000).nullable();

const quantityPriced = yup
  .number()
  .label("Quantity Priced")
  .transform(nanTransform)
  .default(null)
  .min(0)
  .max(1000000000)
  .required();

const quantityUnit = yup.string().label("Quantity Unit").required();

const underlying = yup.string().label("Futures Month").default(null).nullable();

const PriceLinesFormSchema = yup.object().shape({
  additionalAdjustments,
  adjustments,
  basis,
  cashPrice,
  deliveryEndDate,
  deliveryLocationId,
  deliveryStartDate,
  futuresPrice,
  id,
  notes,
  quantityDelivered,
  quantityPriced,
  quantityUnit,
  underlying,
});

export default PriceLinesFormSchema;
