import React from "react";

const legendStyle = {
  width: "100%",
  height: "10px",
};

const Legend = ({ layer, selectedType, showVariability, src, ticks }) => {
  return (
    <div className="layer-info layer-legend" style={{ background: "none", marginBottom: "0" }}>
      <div className="row">
        <div className="col xs-12">
          {selectedType === "TIRS" ? (
            <img src={layer.legend.thermalSrc} style={legendStyle} />
          ) : (
            <img src={src} style={legendStyle} />
          )}
        </div>
      </div>
      <div className="row">
        {selectedType === "TIRS" ? (
          <div className="col xs-12 layer-legend-ticks">
            <div className="layer-legend-tick">
              {showVariability ? layer.legend.thermalLegends.lowVarString : layer.legend.thermalLegends.lowString}
            </div>
            <div className="layer-legend-tick">
              {showVariability ? layer.legend.thermalLegends.highVarString : layer.legend.thermalLegends.highString}
            </div>
          </div>
        ) : (
          <div className="col xs-12 layer-legend-ticks">
            {ticks.map((tick) => (
              <div key={tick} className="layer-legend-tick">
                {tick}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Legend;
