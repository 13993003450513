import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";

import { Button } from "components/fl-ui";
import { Logo } from "components/fl-ui/Icons";
import { FullScreenContainer, FullScreenHeader } from "components/fl-ui/Layout/styles/styledComponents";

const FullscreenContainer = ({ children, handle }) => (
  <FullScreenContainer handle={handle}>
    {handle.active && (
      <>
        <Row>
          <Col xs={12}>
            <FullScreenHeader>
              <Logo size="sm" />
              <Button color="primary" icon="close" link onClick={handle.exit}>
                Close
              </Button>
            </FullScreenHeader>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>{children}</Col>
        </Row>
      </>
    )}
  </FullScreenContainer>
);

FullscreenContainer.propTypes = {
  children: PropTypes.any.isRequired,
  handle: PropTypes.shape({
    active: PropTypes.bool,
    exit: PropTypes.func,
  }).isRequired,
};

export default FullscreenContainer;
