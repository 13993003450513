import d3 from "d3";
import $ from "jquery";
import leaflet from "leaflet";
import _ from "underscore";

import MapUtils from "lib/map/utils";

const disableSmoothing = function (ctx) {
  ctx.mozImageSmoothingEnabled = false;
  ctx.webkitImageSmoothingEnabled = false;
  ctx.msImageSmoothingEnabled = false;
  ctx.imageSmoothingEnabled = false;
};

class CanvasLayer extends leaflet.Layer {
  initialize(bounds) {
    this.bounds = bounds;
    this.topLeft = this.bounds.top_left;
    this.bottomRight = this.bounds.bottom_right;
    this.composites = {};
  }

  getColorizer() {
    return {
      render(data) {
        return data;
      },
    };
  }

  getImageBounds() {
    if (this.bounds) {
      return [
        [this.bounds.bottom_right.lat, this.bounds.top_left.lng],
        [this.bounds.top_left.lat, this.bounds.bottom_right.lng],
      ];
    }
  }

  render(src) {
    this.img = new Image();
    if (!src.match(/data:image/)) {
      this.img.crossOrigin = "anonymous";
    }
    this.img.onload = this._onImageLoad.bind(this);
    return (this.img.src = src);
  }

  setContainer(canvas) {
    this.canvas = canvas;
    this.input = this.canvas.cloneNode(false).getContext("2d");
    this.output = this.canvas.getContext("2d");

    return this;
  }

  onAdd(map) {
    this.map = map;
    this.map.on("viewreset", this._resize, this);
    this.map.once("imageLoaded", ({ imageData }) => {
      this.mapLayer = new leaflet.imageOverlay(imageData, this.getImageBounds());
      map.addLayer(this.mapLayer);
    });

    const canvas = document.createElement("canvas");
    canvas.style.transformOrigin = "0 0";
    canvas.style.position = "absolute";
    canvas.width = 512;
    canvas.height = 512;

    this.setContainer(canvas);
    this._resize();
  }

  onRemove(map) {
    map.off("viewreset", this._resize, this);

    if (this.mapLayer) {
      map.removeLayer(this.mapLayer);
    }
  }

  renderComposite({ url, background, width, height }) {
    // Check to see if we've already generated this composite, and return that
    // if so.
    let deferred;
    const hash = JSON.stringify(arguments[0]);
    if (this.composites[hash]) {
      return this.composites[hash];
    }

    // Store deferred in memory for caching.
    this.composites[hash] = deferred = $.Deferred();
    // Canvas to store composited image
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    // 2D context for above canvas
    const context = canvas.getContext("2d");
    // Projection to use for translating coords between layer and thumbnail
    const projection = d3.geo.mercator();
    // Set our composite operation method here. This MUST occur after altering
    // the canvas dimensions. Modifying the canvas resets the context's composite
    // operation.
    context.globalCompositeOperation = "destination-over";
    // Image.crossOrigin needs to be set to 'anonymous' due to how Amazon
    // handles cross-origin requests.
    const layerImg = new Image();
    if (!url.match(/data:image/)) {
      layerImg.crossOrigin = "anonymous";
    }
    layerImg.src = url;
    // The in-memory element for the field icon image.
    const tImg = new Image();
    tImg.crossOrigin = "anonymous";
    tImg.src = background;
    // Grab the center point, scale, and viewbox dimensions from the field
    // icon image.
    const { center, scale, viewBox } = MapUtils.parseMapboxUrlForMetadata(background);
    // Set our projection to match the field icon image
    projection
      .scale(scale)
      .center(center)
      .translate([viewBox.width / 2, viewBox.height / 2]);
    const tl = projection([this.bounds.top_left.lng, this.bounds.top_left.lat]);
    const br = projection([this.bounds.bottom_right.lng, this.bounds.bottom_right.lat]);

    // Callback for image.onloads
    const _render = () => {
      // Calculate the positioning of the data layer on top of the field icon
      const offset = {
        x: (width - (br[0] - tl[0])) / 2,
        y: (height - (br[1] - tl[1])) / 2,
        w: br[0] - tl[0],
        h: br[1] - tl[1],
      };

      disableSmoothing(context);
      // Draw the layer data first
      context.drawImage(layerImg, offset.x, offset.y, offset.w, offset.h);
      // Color the layer data
      const rawImageData = context.getImageData(0, 0, width, height);
      const coloredData = this.getColorizer().render(rawImageData);
      context.putImageData(coloredData, 0, 0);
      // Add grey mask
      context.fillStyle = "rgba(46, 49, 56, 0.7)";
      context.fillRect(0, 0, width, height);
      // Place the field icon beneath the layer data
      context.drawImage(tImg, 0, 0);
      // Ship it off

      return deferred.resolve(canvas.toDataURL("image/png"));
    };

    // Set the image load callbacks
    const afterLoad = _.after(2, _render);
    layerImg.onload = afterLoad;
    tImg.onload = afterLoad;

    return deferred;
  }

  _getImageDimensions() {
    const topLeftPx = this.map.latLngToContainerPoint(this.topLeft);
    const bottomRightPx = this.map.latLngToContainerPoint(this.bottomRight);

    return {
      width: bottomRightPx.x - topLeftPx.x,
      height: bottomRightPx.y - topLeftPx.y,
    };
  }

  _onImageLoad() {
    this.canvas.height = this.img.naturalHeight;
    this.canvas.width = this.img.naturalWidth;
    this.setContainer(this.canvas);
    this.input.drawImage(this.img, 0, 0, this.canvas.width, this.canvas.height);
    const data = this.getColorizer().render(this.input.getImageData(0, 0, this.canvas.width, this.canvas.height));
    this.output.putImageData(data, 0, 0);
    this.map.fire("imageLoaded", { imageData: this.canvas.toDataURL() });
    return disableSmoothing(this.output);
  }

  _resize() {
    leaflet.DomUtil.setPosition(this.canvas, this.map.latLngToLayerPoint(this.topLeft).round());

    const { width, height } = this._getImageDimensions();

    this.canvas.style.width = `${width}px`;
    return (this.canvas.style.height = `${height}px`);
  }
}

export default CanvasLayer;
