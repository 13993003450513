import { BaseElement } from "@farmlogs/fl-ui";
import themeGet from "@styled-system/theme-get";
import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";

import { Icon } from "components/fl-ui/Icons";
import { Spacing } from "components/fl-ui/constants";

const labelMixin = css`
  &:after {
    border: 4px solid ${themeGet("colors.orange")};
    border-radius: 100%;
    content: "";
    position: absolute;
    left: calc(50% + 5px);
    top: 3px;
  }
`;

const ResponsiveMenuItemContainer = styled(BaseElement)`
  align-items: center;
  background-color: ${({ active, theme }) => (active ? theme.colors.lightestBlue : "transparent")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  outline: none;
  position: relative;
  text-decoration: none !important;

  > :nth-child(1) {
    color: ${themeGet("colors.blue")};
    height: ${Spacing.large};
    width: ${Spacing.large};
  }

  > :nth-child(2) {
    color: ${({ active, theme }) => (active ? theme.colors.blue : theme.colors.darkBlue)};
    font-size: ${themeGet("fontSizes.sm")};
    font-weight: ${({ boldTitles, theme }) => (boldTitles ? theme.fontWeights.bold : theme.fontWeights.normal)};
    line-height: 1.125rem;
    white-space: nowrap;
  }

  ${({ label }) => label && labelMixin}
`;

const ResponsiveMenuItem = ({ icon, title, titleAbbr, ...rest }) => (
  <ResponsiveMenuItemContainer as="a" {...rest}>
    <Icon icon={icon} />
    <span>{titleAbbr || title}</span>
  </ResponsiveMenuItemContainer>
);

ResponsiveMenuItem.propTypes = {
  active: PropTypes.bool,
  boldTitles: PropTypes.bool,
};

ResponsiveMenuItem.defaultProps = {
  active: false,
  boldTitles: false,
};

export default ResponsiveMenuItem;
