import { StyleSheet, css } from "aphrodite/no-important";
import React, { useState } from "react";
import { Transition, TransitionGroup } from "react-transition-group";

import styleVars from "components/fl-ui/Layout/Navigation/styleVars";
import navStyles from "components/fl-ui/Layout/styles/navStyles";

const DropDown = ({ target, width, height, offset, children }) => {
  const [showDropDown, setShowDropDown] = useState(false);

  const show = () => {
    document.addEventListener("click", hide);
    setShowDropDown(true);
  };

  const hide = (e) => {
    if (!e.defaultPrevented) {
      document.removeEventListener("click", hide);
      setShowDropDown(false);
    }
  };

  const handleClick = (event) => {
    if (event.target.tagName !== "A") {
      event.preventDefault();
    }
  };

  const transitionStyles = {
    entering: { height: 0 },
    entered: { height: `${height}px` },
  };

  const styles = StyleSheet.create({
    container: {
      position: "relative",
    },
    menuContainerOuter: {
      width: `${width || 300}px !important`,
      top: "2.55rem",
      right: `-${offset || 16}px`,
    },
    targetContainer: {
      cursor: "pointer",
    },
  });

  return (
    <div className={css(styles.container)} onClick={handleClick} onKeyDown={() => null}>
      <div className={css(styles.targetContainer)} onClick={show} onKeyDown={() => null}>
        {target}
      </div>
      <TransitionGroup>
        {showDropDown && (
          <Transition
            transitionName="dropDownTransition"
            key="dropDownTransition"
            timeout={styleVars.menuTransitionDuration}
          >
            {(status) => (
              <div
                key="menu"
                style={{
                  ...styleVars.userMenuDefaultStyle,
                  ...transitionStyles[status],
                }}
                className={css(navStyles.menuContainerOuter, styles.menuContainerOuter)}
              >
                <div className={css(navStyles.menuContainer)}>{children}</div>
              </div>
            )}
          </Transition>
        )}
      </TransitionGroup>
    </div>
  );
};

export default DropDown;
