import { StyleSheet, css } from "aphrodite/no-important";
import classNames from "classnames";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { isValidElement } from "react";

import { Mixins } from "components/fl-ui/constants";

const styles = StyleSheet.create({
  radioGroup_container: {
    marginBottom: Mixins.toRem(40),
  },
  radioGroup_label: {
    marginBottom: Mixins.toRem(24),
  },
});

class RadioGroup extends React.Component {
  handleChange = (e) => {
    this.props.onChange(_.pick(e.target, ["name", "value"]));
  };

  createRadios() {
    const radios = React.Children.map(this.props.children, (child, index) => {
      const radioId = `radioGroup_${this.props.name}__radio_${index}`;

      return isValidElement(child)
        ? React.cloneElement(child, {
            key: radioId,
            id: radioId,
            name: this.props.name,
            onChange: this.handleChange,
          })
        : null;
    });
    return radios;
  }

  render() {
    return (
      <div className={classNames(css(styles.radioGroup_container), this.props.className)}>
        <h3 className={css(styles.radioGroup_label)}>{this.props.label}</h3>
        {this.createRadios()}
      </div>
    );
  }
}

RadioGroup.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

RadioGroup.defaultProps = {
  label: "",
  onChange: () => {},
};

export default RadioGroup;
