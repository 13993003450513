import { BaseElement } from "@farmlogs/fl-ui";
import ProductUnitChooser from "activity/components/choosers/ProductUnitChooser";
import { MicroLabel } from "agreements/forms/components";
import React, { useState } from "react";

import { useProductById } from "collection/graphql/products/hooks";
import useUnitsOfMeasure from "collection/graphql/units/hooks/useUnitsOfMeasure";
import useCommodities from "hooks/useCommodities";

import { Button } from "components/fl-ui";
import { Stack } from "components/fl-ui/Layout";

const CHEMICAL = 469451;
const FERTILIZER = 1282;
const SEED = 17415;

const UnitChooserDemo = () => {
  const [productId, setProductId] = useState(CHEMICAL);
  const [unit, setUnit] = useState(null);
  const { product } = useProductById(productId);
  const commodity = useCommodities().getCommodityById(product?.commodity?.id);
  const options = useUnitsOfMeasure(commodity?.seedUnitType ?? product?.unitType);

  const handleUnitChange = (unit) => {
    console.info(unit);
    setUnit(unit);
  };

  const updateProductId = (newProductId) => {
    if (newProductId !== productId) {
      console.info(newProductId);
      setProductId(newProductId);
      setUnit(null);
    }
  };

  return (
    <div>
      <h4>ProductUnitChooser</h4>

      <Stack>
        <BaseElement alignItems="center" display="flex" gridGap="10px">
          <MicroLabel label="Unit">
            <ProductUnitChooser onChange={handleUnitChange} options={options} productId={productId} value={unit} />
          </MicroLabel>

          <Button color={productId === CHEMICAL ? "primary" : "default"} onClick={() => updateProductId(CHEMICAL)}>
            Chemical
          </Button>

          <Button color={productId === FERTILIZER ? "primary" : "default"} onClick={() => updateProductId(FERTILIZER)}>
            Fertilizer
          </Button>

          <Button color={productId === SEED ? "primary" : "default"} onClick={() => updateProductId(SEED)}>
            Seed
          </Button>

          <Button disabled={!productId} onClick={() => updateProductId(null)}>
            Clear
          </Button>
        </BaseElement>

        <div>
          <small>Component value:</small> <strong>{unit || "(null)"}</strong>
        </div>
      </Stack>
    </div>
  );
};

export default UnitChooserDemo;
