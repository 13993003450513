import { BaseElement } from "@farmlogs/fl-ui";
import PropTypes from "prop-types";
import React from "react";

const Section = ({ children, title, ...rest }) => (
  <BaseElement mb={30} {...rest}>
    {title && <h3>{title}</h3>}
    <div>{children}</div>

    <hr />
  </BaseElement>
);

Section.propTypes = {
  children: PropTypes.any.isRequired,
  title: PropTypes.string,
};

export default Section;
