import { BASE_RENT, CROP_SHARE, EXPENSE_SHARE, PER_ACRE, TOTAL, YIELD_ADJUSTMENT } from "agreements/constants";
import _ from "lodash";

import utils from "lib/validation/utils";

const isValid = (fields, errors = {}) => _.isEmpty(validate(fields, { errors }));
const isPositiveNumber = (value) => value > 0 && utils.isDecimal(value, { decimal_digits: "0,2" });
const isPercentage = (value) => value >= 0 && utils.isDecimal(value, { decimal_digits: "0,2" }) && value <= 100;

const validate = (fields, options = {}) => {
  const errors = options.errors || {};
  const { amount, tenantRevenueAllocation, termType } = fields;

  switch (termType) {
    case BASE_RENT:
      // amount
      if (!isPositiveNumber(amount)) {
        errors.amount = { value: amount };
      }

      // rate
      if (![PER_ACRE, TOTAL].includes(fields.baseRentRate)) {
        errors.baseRentRate = { value: fields.baseRentRate };
      }
      break;

    case CROP_SHARE:
      // tenantRevenueAllocation
      if (!isPercentage(tenantRevenueAllocation)) {
        errors.tenantRevenueAllocation = { value: tenantRevenueAllocation };
      }
      break;

    case EXPENSE_SHARE:
      const { expenseCategory, tenantCostAllocation } = fields;

      // amount
      if (!isPositiveNumber(tenantCostAllocation)) {
        errors.tenantCostAllocation = { value: tenantCostAllocation };
      }

      // category
      if (!expenseCategory) {
        errors.expenseCategory = { value: expenseCategory };
      }

      break;

    case YIELD_ADJUSTMENT:
      const { commodity, yieldTrigger } = fields;

      // amount
      if (!isPositiveNumber(amount)) {
        errors.amount = { value: amount };
      }

      // commodity
      if (!commodity) {
        errors.commodity = { value: commodity };
      }

      // yieldTrigger
      if (!utils.isInt(yieldTrigger, { min: 1 })) {
        errors.yieldTrigger = { value: yieldTrigger };
      }

      break;

    default:
      errors.termType = { value: termType };
      break;
  }

  return errors;
};

export default {
  isValid,
  validate,
};
