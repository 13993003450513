import { css } from "aphrodite";
import { isEmpty, orderBy, sumBy } from "lodash";
import numeral from "numeral";
import React from "react";
import { Row, Col } from "react-styled-flexboxgrid";

import useYearFieldCrops from "modules/fields/hooks/useYearFieldCrops";
import { BaseProgressBar, IndependentProgressBar } from "modules/marketing/components/ui-elements/progress-bar";

import { Button } from "components/fl-ui";
import { FilterButton } from "components/fl-ui/Buttons";
import DropdownMenu from "components/fl-ui/DropdownMenu";
import { Icon } from "components/fl-ui/Icons";
import { Cluster } from "components/fl-ui/Layout";
import Search from "components/fl-ui/Search";
import Tooltip from "components/fl-ui/Tooltip";
import { UIColors } from "components/fl-ui/colors";
import { getCropStylesFromColor } from "components/fl-ui/colors/crops";
import styles from "fields/common/styles";

const FilterBar = ({
  fields,
  filteredSize,
  handleFilterButtonClick,
  handleSort,
  handleViewType,
  onSearchUpdate,
  options,
  selected,
  selectedFilters,
  sortCriteria,
  totalSize,
  year,
}) => {
  const baseColor = "rgb(230, 232, 235)";

  const { crops } = useYearFieldCrops(year);
  const filterCount = sumBy(
    ["commodities", "groups", "irrigation", "providers"].map((f) => selectedFilters[f]?.length && 1)
  );

  const fieldAcreage = sumBy(fields, (f) => f.acreage);
  const consolidate = (commodities) => {
    if (commodities.length > 4) {
      const cutOffIndex = commodities.length - 3;
      const consolidated = [{ acreage: 0, color: UIColors.purple, name: "Other" }];

      commodities.forEach((c, i) => {
        if (i < cutOffIndex) {
          consolidated[0].acreage += c.acreage;
        } else {
          consolidated.push(c);
        }
      });

      return consolidated;
    }

    return commodities;
  };

  const getAcrePercentage = (acreage) => {
    const percentageAcres = (100 * acreage) / totalAcres;

    if (percentageAcres < 1) {
      return +percentageAcres.toFixed(2);
    }

    return percentageAcres < 10 ? percentageAcres.toPrecision(2) : percentageAcres.toPrecision(3);
  };

  const getCropData = () => {
    const commodities = {};
    const defaultCropColor = "light-green";
    let plantedAcres = 0;
    crops.forEach((c) => {
      if (fields.find(({ id }) => id === c.field?.id)) {
        const name = c.commodity.name;

        if (name.toLowerCase() !== "fallow") {
          const acreage = c.acreage;
          plantedAcres += acreage;

          if (!commodities[name]) {
            const color = getCropStylesFromColor(c.commodity.color || defaultCropColor).fill;
            commodities[name] = { acreage, color, name };
          } else {
            commodities[name].acreage += acreage;
          }
        }
      }
    });

    return { commodities: orderBy(Object.values(commodities), ["acreage", "name"], ["asc", "desc"]), plantedAcres };
  };

  const getNoCropAcres = () =>
    fields.reduce((acc, f) => {
      let fallowAcreage = 0;
      let fieldCropAcreage = 0;
      crops.forEach((c) => {
        if (c.field?.id === f.id) {
          if (c.commodity.name.toLowerCase() === "fallow") {
            fallowAcreage += c.acreage;
          } else {
            fieldCropAcreage += c.acreage;
          }
        }
      });

      const unUsedAcres = f.acreage - fieldCropAcreage > 0 ? f.acreage - fieldCropAcreage : 0;
      return acc + unUsedAcres + fallowAcreage;
    }, 0);

  const { commodities, plantedAcres } = getCropData();
  const noCropAcres = parseFloat(getNoCropAcres().toFixed(2));
  const consolidatedCommodities = consolidate(commodities);
  const totalAcres = plantedAcres + noCropAcres;
  let cumulativeAcres = noCropAcres;

  return (
    <>
      <Cluster between>
        <div className={css(styles.filterBar_controls)}>
          <div className={css(styles.filterBar_controls)}>
            <Search onChange={onSearchUpdate} style={{ marginLeft: 0, marginRight: 10 }} />
            <FilterButton filterCount={filterCount} onClick={handleFilterButtonClick} />
          </div>
          <div className={css(styles.filterBar_controls, styles.hide_on_mobile)}>
            <Tooltip message="Standard card view">
              <Button
                className={css(
                  styles.iconButton,
                  styles.filterBar_viewButton,
                  selected === "cards" && styles.iconButton_selected
                )}
                color={selected === "cards" ? "primary" : "default"}
                hollow
                icon="cardsLayout"
                onClick={() => handleViewType("cards")}
              />
            </Tooltip>
            <Tooltip message="Compact card view">
              <Button
                className={css(
                  styles.iconButton,
                  styles.filterBar_viewButton,
                  selected === "list" && styles.iconButton_selected
                )}
                color={selected === "list" ? "primary" : "default"}
                hollow
                icon="listLayout"
                onClick={() => handleViewType("list")}
              />
            </Tooltip>
          </div>
        </div>
        <div className={css(styles.filterBar_controls, styles.hide_on_mobile)}>
          <p className={css(styles.filterBar_text)}>Sort by</p>
          <DropdownMenu onChange={handleSort} options={options} value={sortCriteria} />
        </div>
      </Cluster>

      <Row className={css(styles.fieldsSummary)}>
        <Col xs={3} md={2} className={css(styles.fieldsSummary_columns)}>
          <p className={css(styles.fieldsSummary_bold, styles.no_marginBottom)}>{`${filteredSize} of ${totalSize}`}</p>
          <p className={css(styles.no_marginBottom)}>{`${totalSize === 1 ? "Field" : "Fields"}`}</p>
        </Col>
        <Col xs md={2} className={css(styles.fieldsSummary_columns)}>
          <p className={css(styles.fieldsSummary_bold, styles.no_marginBottom)}>
            {`${numeral(plantedAcres).format("0,0")} ac`}
          </p>
          <p className={css(styles.no_marginBottom)}>Crop acreage</p>
        </Col>
        <Col xs={false} md={6} className={css(styles.progress_bar)}>
          <Tooltip
            message={
              (!isEmpty(commodities) || !!noCropAcres) && (
                <div className={css(styles.progress_popover_container)}>
                  {!!noCropAcres && (
                    <div className={css(styles.progress_legend)}>
                      <p className={css(styles.progress_popover_text, styles.no_marginBottom)}>
                        <span className={css(styles.progress_legend_indicator)} style={{ background: baseColor }} />
                        No crop
                      </p>
                      <p className={css(styles.progress_popover_text, styles.no_marginBottom)}>
                        {`${numeral(noCropAcres).format("0,0")}ac \u00b7 ${getAcrePercentage(noCropAcres)}%`}
                      </p>
                    </div>
                  )}

                  {commodities.map((c, i) => (
                    <div className={css(styles.progress_legend)} key={`popover-legend-indicator-${i}`}>
                      <p className={css(styles.progress_popover_text, styles.no_marginBottom)}>
                        <span className={css(styles.progress_legend_indicator)} style={{ background: c.color }} />
                        {c.name}
                      </p>
                      <p className={css(styles.progress_popover_text, styles.no_marginBottom)}>
                        {`${numeral(c.acreage).format("0,0")}ac \u00b7 ${getAcrePercentage(c.acreage)}%`}
                      </p>
                    </div>
                  ))}
                </div>
              )
            }
            placement="top"
            style={{ maxWidth: "none" }}
          >
            <BaseProgressBar baseColor={baseColor} type="thin">
              {consolidatedCommodities.map((c, i) => {
                // Doing this because the bars draw over each other
                const cropBar = (
                  <IndependentProgressBar
                    color={c.color}
                    key={`cropbar-${i}`}
                    type="thin"
                    width={(100 * (totalAcres - cumulativeAcres)) / totalAcres}
                  />
                );
                cumulativeAcres += c.acreage;
                return cropBar;
              })}
            </BaseProgressBar>
          </Tooltip>
          <div className={css(styles.progress_legend_container)}>
            {isEmpty(consolidatedCommodities) ? (
              <div className={css(styles.blank_content)}>
                <Icon className={css(styles.helpIcon)} icon="help" />
                Add crops to your fields to see crop acreage
              </div>
            ) : (
              <>
                {consolidatedCommodities.length < 4 && !!noCropAcres && (
                  <div className={css(styles.progress_legend)}>
                    <span className={css(styles.progress_legend_indicator)} style={{ background: baseColor }} />
                    {`No crop \u00b7 ${getAcrePercentage(noCropAcres)}%`}
                  </div>
                )}

                {consolidatedCommodities.map((c, i) => (
                  <div className={css(styles.progress_legend)} key={`progress-legend-indicator-${i}`}>
                    <span className={css(styles.progress_legend_indicator)} style={{ background: c.color }} />
                    {`${c.name} \u00b7 ${getAcrePercentage(c.acreage)}%`}
                  </div>
                ))}
              </>
            )}
          </div>
        </Col>
        <Col xs md={2} className={css(styles.fieldsSummary_columns)}>
          <p className={css(styles.fieldsSummary_bold, styles.no_marginBottom)}>
            {`${numeral(fieldAcreage).format("0,0")} ac`}
          </p>
          <p className={css(styles.no_marginBottom)}>Field acreage</p>
        </Col>
      </Row>
    </>
  );
};

export default FilterBar;
