import { Model } from "backbone";
import _ from "lodash";
import { useEffect, useState } from "react";

const getModelData = (model) => {
  if (!(model instanceof Model)) {
    throw new Error("Instance of Backbone.Model expected");
  }

  return _.transform(
    model.toJSON(),
    (output, value, key) => {
      output[_.camelCase(key)] = value;
    },
    {}
  );
};

/**
 * Listens to a given model for changes and updates accordingly. Returns
 * the camelcased results of the model's `toJSON` method.
 * @param {Backbone.Model} model
 * @return {Object}
 */
const useBackboneModel = (model) => {
  const [data, setData] = useState(getModelData(model));

  useEffect(() => {
    const checkForUpdates = _.debounce(
      () => {
        setData((existingData) => {
          const newData = getModelData(model);
          return _.isEqual(newData, existingData) ? existingData : newData;
        });
      },
      50,
      { trailing: true }
    );

    model.on("change reset sync", checkForUpdates);
    return () => model.off(null, checkForUpdates);
  }, []);

  return data;
};

export default useBackboneModel;
