import { gql } from "@apollo/client";

import { FIELD_WITH_ATTACHMENTS } from "collection/graphql/fields/fragments";

export default gql`
  query getFieldAttachments($fieldId: Int!) {
    field: getField(fieldId: $fieldId) {
      ...FieldWithAttachments
    }
  }
  ${FIELD_WITH_ATTACHMENTS}
`;
