import _ from "lodash";

import { COMMODITY } from "collection/graphql/commodities/fragments";

const LegacyProductTypePolicy = {
  fields: {
    commodity: {
      read(value, { cache, readField }) {
        const commodityId = readField("commodityId");
        if (commodityId) {
          return (
            cache.readFragment({
              fragment: COMMODITY,
              id: cache.identify({ id: commodityId, __typename: "Commodity" }),
            }) ?? null
          );
        }

        return null;
      },
    },

    unitType: {
      read(value) {
        switch (_.toUpper(value)) {
          case "COUNT":
          case "SEED_COUNT":
            return "SEED_COUNT";

          case "VOLUME":
            return "VOLUME";

          case "WEIGHT":
            return "WEIGHT";

          default:
            // this can occur for water (product id: 1) or if there is incomplete data
            return null;
        }
      },
    },
  },
};

export default LegacyProductTypePolicy;
