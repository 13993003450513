import Backbone from "backbone";
import React from "react";
import { createRoot } from "react-dom/client";

import Modal from "components/ui/modal";

export default Backbone.View.extend({
  // This will be extended as needed

  events: {
    "click .js-close-modal": "close",
    "click .js-modal-backdrop": "close",
    "click .js-modal-next"() {
      return this.trigger("next");
    },
    "click .js-modal-prev"() {
      return this.trigger("previous");
    },
    "click .js-modal-delete"() {
      this.$(".js-modal-delete").hide();
      return this.$(".js-modal-delete-confirm").show();
    },

    "click .js-modal-delete-yes"(e) {
      return this.trigger("delete", e);
    },

    "click .js-modal-delete-no"() {
      this.$(".js-modal-delete").show();
      return this.$(".js-modal-delete-confirm").hide();
    },

    remove: "remove",
  },

  initialize(opts) {
    this.modalElement = <Modal {...opts} />;
    this.delegateEvents();
    return this;
  },

  setSafeClose(opts) {
    this.safeclose = opts != null ? opts.safeclose : undefined;
    this.safeclose_msg = opts != null ? opts.safeclose_msg : undefined;
    return (this.safecloseStrong = opts != null ? opts.strong : undefined);
  },

  render() {
    this.root = createRoot(this.el);
    this.root.render(this.modalElement);
    this.$el.appendTo("body");
    return this;
  },

  close() {
    if (this.safeclose) {
      if (this.safecloseStrong) {
        return this;
      }
      const res = confirm(this.safeclose_msg);
      if (res === false) {
        return this;
      }
    }
    this.trigger("closed");
    this.remove();
    return this;
  },

  // Make sure this view is fully destroyed and its events are unbound
  remove() {
    this.undelegateEvents();
    this.root.unmount();
    this.$el.remove();
    return Backbone.View.prototype.remove.call(this);
  },
});
