import { useQuery } from "@apollo/client";
import _ from "lodash";
import { useMemo } from "react";

import { GET_MACHINE_DATA_PRODUCT_MATCHES } from "collection/graphql/integrations/queries";

const useSortedProductMatches = () => {
  const { data, loading } = useQuery(GET_MACHINE_DATA_PRODUCT_MATCHES);
  const matches = useMemo(() => {
    const sortedMatches = _.sortBy(data?.allMatches, "search");
    const [withMatches, withoutMatches] = _.partition(
      sortedMatches,
      (match) => match.expectedProduct || match.matchedProduct
    );

    return withoutMatches.concat(withMatches);
  }, [data?.allMatches]);

  return {
    loading,
    matches,
  };
};

export default useSortedProductMatches;
