import _ from "lodash";
import * as yup from "yup";

const schema = yup.object().shape({
  fuelCapacity: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .label("Fuel capacity")
    .nullable()
    .default(null)
    .moreThan(0),
  horsepower: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .label("Horsepower")
    .nullable()
    .default(null)
    .moreThan(0),
  icon: yup.string().label("Icon").default(null).nullable(),
  isRented: yup.mixed().oneOf([true, false]).default(null).nullable(),
  make: yup
    .string()
    .label("Make")
    .transform((value) => _.trim(value))
    .default(""),
  model: yup
    .string()
    .label("Model")
    .transform((value) => _.trim(value))
    .default(""),
  name: yup.string().label("Name").trim().required("Tractor name is required").default(""),
  notes: yup
    .string()
    .label("Notes")
    .transform((value) => _.trim(value))
    .default(""),
  year: yup
    .number()
    .label("Year")
    .nullable()
    .integer()
    .min(1900)
    .max(new Date().getFullYear() + 2)
    .default(null),
});

export default schema;
