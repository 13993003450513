import { gql } from "@apollo/client";

/**
 * @typedef {Object} CreateProductPurchaseInput
 * @property {Number} amount (Float!)
 * @property {String} amountUnit
 * @property {Number} cost (Float!)
 * @property {'PER_UNIT'|'TOTAL'} costRate
 * @property {Number} cropYear (Int!)
 * @property {Date} date (Date!)
 * @property {Number} product (Int!)
 * @property {String} vendor
 */
export default gql`
  mutation createProductPurchase($productPurchase: CreateProductPurchaseInput!) {
    createProductPurchase(productPurchase: $productPurchase) {
      ok
      productPurchase {
        ...InputPurchase
      }
    }
  }
`;
