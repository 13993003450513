export default [
  { name: "Blue Tractor", filename: "blue-tractor.png" },
  { name: "Green Combine", filename: "green-combine.png" },
  { name: "Green Quad Tractor", filename: "green-quad-tractor.png" },
  { name: "Green Track Tractor", filename: "green-track-tractor.png" },
  { name: "Green Tractor", filename: "green-tractor.png" },
  { name: "Red Combine", filename: "red-combine.png" },
  { name: "Red Quad Tractor", filename: "red-quad-tractor.png" },
  { name: "Red Tractor", filename: "red-tractor.png" },
  { name: "White Truck", filename: "white-truck.png" },
  { name: "Yellow Combine", filename: "yellow-combine.png" },
  { name: "Yellow Track Tractor", filename: "yellow-track-tractor.png" },
  { name: "Yellow Tractor", filename: "yellow-tractor.png" },
];
