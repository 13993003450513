import ContractsModalClearButton from "contracts/components/specs/ContractsModalClearButton";
import useContractsIndexPage from "contracts/hooks/useContractsIndexPage";
import ContractBuyerFilter from "contracts/modals/ContractFilterModal/ContractBuyerFilter";
import ContractCommodityFilter from "contracts/modals/ContractFilterModal/ContractCommodityFilter";
import ContractCropYearFilter from "contracts/modals/ContractFilterModal/ContractCropYearFilter";
import ContractDeliveryDateFilter from "contracts/modals/ContractFilterModal/ContractDeliveryDateFilter";
import ContractMarketedCropFilter from "contracts/modals/ContractFilterModal/ContractMarketedCropFilter";
import ContractStatusFilter from "contracts/modals/ContractFilterModal/ContractStatusFilter";
import getCurrentFilterCount from "contracts/utils/getCurrentFilterCount";
import PropTypes from "prop-types";
import React, { useReducer } from "react";

import { Button } from "components/fl-ui";
import { CloseX } from "components/fl-ui/Icons";
import { Cluster } from "components/fl-ui/Layout";
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "components/fl-ui/Modal/Modal";

const ContractFilterModal = ({ onClose }) => {
  const { currentFilters, clearFilters, setFilters } = useContractsIndexPage();

  const [modalFilters, setModalFilters] = useReducer(
    (oldState, newState) => ({
      ...oldState,
      ...newState,
    }),
    currentFilters
  );

  const currentFiltersCount = getCurrentFilterCount(modalFilters);

  return (
    <Modal width={530}>
      <ModalHeader>
        <ModalTitle>Filters</ModalTitle>
        <CloseX onClick={onClose} />
      </ModalHeader>

      <ModalBody>
        <ContractStatusFilter
          onChange={(contractStatus) => setModalFilters({ contractStatus })}
          value={modalFilters.contractStatus}
        />

        <ContractCropYearFilter
          onChange={(cropYears) => setModalFilters({ cropYears })}
          value={modalFilters.cropYears}
        />

        <ContractCommodityFilter
          onChange={(commodities) => setModalFilters({ commodities })}
          value={modalFilters.commodities}
        />

        <ContractBuyerFilter onChange={(buyers) => setModalFilters({ buyers })} value={modalFilters.buyers} />

        <ContractDeliveryDateFilter
          deliveryEndDate={modalFilters.deliveryEndDate}
          deliveryStartDate={modalFilters.deliveryStartDate}
          onChange={(dates) => setModalFilters(dates)}
        />

        <ContractMarketedCropFilter
          onChange={(marketedCrops) => setModalFilters({ marketedCrops })}
          value={modalFilters.marketedCrops}
        />
      </ModalBody>

      <ModalFooter>
        <Cluster between>
          <ContractsModalClearButton
            text="Clear filters"
            onClick={() => {
              clearFilters();
              onClose();
            }}
          />

          <span>
            <Button color="default" onClick={onClose} type="button">
              Cancel
            </Button>{" "}
            <Button
              color="primary"
              onClick={() => {
                setFilters(modalFilters);
                onClose();
              }}
              type="button"
            >
              Apply {currentFiltersCount ? `(${currentFiltersCount})` : ""} Filters
            </Button>
          </span>
        </Cluster>
      </ModalFooter>
    </Modal>
  );
};

ContractFilterModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ContractFilterModal;
