import { PickerDropPane, PickerInline } from "filestack-react";
import PropTypes from "prop-types";
import React from "react";

import withViewType from "hoc/withViewType";

const InlineFilePicker = ({ apiKey, onError, onSuccess, pickerOptions, viewType }) => {
  const FilePicker = viewType === "mobile" ? PickerDropPane : PickerInline;

  return (
    <FilePicker
      apikey={apiKey || process.env.FILEPICKER_IO_API_KEY}
      onError={onError}
      onSuccess={onSuccess}
      pickerOptions={{
        customText: {
          "Drag and Drop, Copy and Paste Files": " ",
          "or Drag and Drop, Copy and Paste Files": "or browse to choose a file",
          "Select Files to Upload": "Drag & drop to upload",
        },
        fromSources: ["local_file_system", "url"],
        ...pickerOptions,
      }}
    >
      {viewType !== "mobile" && <div style={{ height: "300px" }} />}
    </FilePicker>
  );
};

InlineFilePicker.propTypes = {
  onError: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
  pickerOptions: PropTypes.object,
  viewType: PropTypes.oneOf(["desktop", "mobile"]),
};

export default withViewType(InlineFilePicker);
