import { gql } from "@apollo/client";

export default gql`
  fragment Account on FinancialAccount {
    id
    accountType
    isArchived
    name
  }
`;
