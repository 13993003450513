import { css } from "aphrodite";
import styles from "notifications/styles";
import PropTypes from "prop-types";
import React from "react";

import Icon from "components/fl-ui/Icons/Icon";

const NotificationIcon = ({ count }) => (
  <Icon
    className={css(styles.alertIcon, count && styles.alertIconActive)}
    icon={count ? "alertActive" : "alertInactive"}
  />
);

NotificationIcon.propTypes = {
  count: PropTypes.number,
};

export default NotificationIcon;
