/* eslint-disable react/display-name */
import cx from "classnames";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import useFormGroupContext from "components/fl-ui/Form/hooks/useFormGroupContext";
import { GreyColors, UIColors } from "components/fl-ui/colors";
import { BorderRadius, Mixins, Spacing } from "components/fl-ui/constants";

const Container = styled.span`
  align-items: center;
  height: 100%;
  display: flex;
  flex-direction: row;
  max-height: ${Mixins.toRem(40)};
  overflow: hidden;
  position: relative;
  width: 100%;

  /* alter padding when prefix/suffix is used */
  > input:nth-child(2) {
    padding-left: 0 !important;
  }
  > input:nth-last-child(n-1) {
    padding-right: 0 !important;
  }
`;
export { Container };

const Affix = styled.span`
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  white-space: nowrap;

  ${(props) => props.disabled && `color: ${UIColors.regular} !important;`}
  ${(props) => props.disabled && "opacity: .65;"}
`;
const Prefix = styled(Affix)`
  padding-left: ${Spacing.small};
  padding-right: ${Spacing.xxsmall};
`;
const Suffix = styled(Affix)`
  padding-left: ${Spacing.xxsmall};
  padding-right: ${Spacing.small};
`;
export { Prefix, Suffix };

/**
 * @typedef {BorderedInputContainer}
 */
const BorderedInputContainer = styled.span`
  align-items: center;
  background: ${UIColors.white};
  border: 1px solid ${UIColors.lightest};
  border-radius: ${BorderRadius.small};
  display: inline-flex;
  padding: 1px 0.01em;
  transition: border-color 0.2s;
  :focus-within {
    border-color: ${UIColors.primary};
  }

  ${(props) =>
    props.display === "block" &&
    `
    display: block;
    width: 100%;
  `}
  ${(props) =>
    props.disabled &&
    `
    background-color: ${GreyColors.smoke96};
    border: 1px solid ${GreyColors.smoke96};
  `}
  ${(props) => props.hasError && `border: 2px solid ${UIColors.danger};`}
  ${(props) =>
    props.size === "large" &&
    `
    height: ${Mixins.toRem(40)};
    > input {
      height: ${Mixins.toRem(36)}
    }
  `}

  > input {
    padding-left: ${Spacing.regular};
    padding-right: ${Spacing.regular};
  }
`;
BorderedInputContainer.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  display: PropTypes.oneOf(["block"]),
  hasError: PropTypes.bool,
  size: PropTypes.oneOf(["large"]),
  style: PropTypes.any,
};
BorderedInputContainer.defaultProps = {
  disabled: false,
  hasError: false,
};

export const withBorder = (WrappedComponent) => (props) => {
  const containerPropNames = Object.keys(BorderedInputContainer.propTypes);
  const childProps = _.omit(props, containerPropNames);
  const containerProps = _.pick(props, containerPropNames);
  containerProps.className = cx("flui-input-compat", containerProps.className);
  const { hasError } = useFormGroupContext();
  containerProps.hasError = containerProps.hasError ?? hasError;

  return (
    <BorderedInputContainer {...containerProps}>
      <WrappedComponent {...childProps} disabled={props.disabled} />
    </BorderedInputContainer>
  );
};
