import { useMutation } from "@apollo/client";

import { advisorClient as client } from "collection/graphql/client";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";

const useAdvisorMutation = (mutation, options = {}) => {
  const hasElevatePlus = useEnterpriseFeature("elevate_plus");
  const skip = options.skip || !hasElevatePlus;

  return useMutation(mutation, {
    ...options,
    client,
    skip,
  })[0];
};

export default useAdvisorMutation;
