import { useMediaQuery } from "beautiful-react-hooks";
import _ from "lodash";
import numeral from "numeral";
import PropTypes from "prop-types";
import React from "react";

import PageSummary, { ResponsiveFigure } from "components/fl-ui/PageSummary";

const useProductPurchasesSummary = (summaries) => {
  const calculateTotal = (filter) => {
    const subset = _.filter(summaries, filter);
    const times100 = ({ costTotal }) => costTotal * 100;

    return _.sumBy(subset, times100) / 100;
  };

  return {
    chemTotal: calculateTotal(({ product }) => product.__typename === "ChemicalProduct"),
    fertTotal: calculateTotal(({ product }) => product.__typename === "FertilizerProduct"),
    seedTotal: calculateTotal(({ product }) => product.__typename === "SeedProduct"),
    totalCost: calculateTotal(() => true),
  };
};

const ProductPurchasesSummary = ({ purchaseSummaries, totalPurchases }) => {
  const { chemTotal, fertTotal, seedTotal, totalCost } = useProductPurchasesSummary(purchaseSummaries);

  const format = (value) => numeral(value).format("$0,0");
  const showTotal = useMediaQuery("screen and (min-width: 22em)"); // 22em targets the smallest viewport size (iPhone 5)

  return (
    <PageSummary>
      <ResponsiveFigure
        label="Products"
        value={
          <span>
            {_.size(purchaseSummaries)} <small>of</small> {totalPurchases}
          </span>
        }
        isPrimary
      />

      {showTotal && <ResponsiveFigure label="Purchased year-to-date" value={format(totalCost)} />}
      <ResponsiveFigure label="Chemicals" value={format(chemTotal)} />
      <ResponsiveFigure label="Fertilizer" value={format(fertTotal)} />
      <ResponsiveFigure label="Seed" value={format(seedTotal)} />
    </PageSummary>
  );
};

ProductPurchasesSummary.propTypes = {
  purchaseSummaries: PropTypes.array.isRequired,
  totalPurchases: PropTypes.number.isRequired,
};

export default ProductPurchasesSummary;
