import React, { useEffect, useState } from "react";

import { Button } from "components/fl-ui";

const SentryErrorTest = (props) => {
  const [shouldTest, setShouldTest] = useState(false);
  useEffect(() => {
    if (shouldTest) {
      sentryErrorTest(); // eslint-disable-line
    }
  }, [shouldTest]);

  return (
    <Button color="secondary" icon="error" onClick={() => setShouldTest(true)}>
      Trigger Sentry error
    </Button>
  );
};

export default SentryErrorTest;
