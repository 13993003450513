const OptionsContractTypePolicy = {
  fields: {
    contractSubtype: {
      read(__, { readField }) {
        return readField("contractType");
      },
    },
  },
};

export default OptionsContractTypePolicy;
