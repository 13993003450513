import PropTypes from "prop-types";
import React from "react";
import router from "router";

const CashContractRedirect = ({ cropId, year }) => {
  const baseUrl = `marketing/${year}/${cropId}`;
  router.navigate(baseUrl, { replace: true, trigger: true });
  return null;
};

CashContractRedirect.propTypes = {
  year: PropTypes.number,
  cropId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CashContractRedirect;
