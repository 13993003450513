import NewPurchaseButton from "products/containers/NewPurchaseButton";
import PropTypes from "prop-types";
import React from "react";

import BlankSlate from "components/fl-ui/BlankSlate";

const ProductIndexBlankSlate = (props) => {
  const { onCreatePurchase } = props;

  return (
    <BlankSlate icon="inputs" title="You haven't used any products yet">
      <p>{`As you use products, they'll appear here. Log a new purchase to get started.`}</p>

      <NewPurchaseButton onSuccessfulClick={() => onCreatePurchase()} large primary />
    </BlankSlate>
  );
};

ProductIndexBlankSlate.propTypes = {
  onCreatePurchase: PropTypes.func.isRequired,
};

export default ProductIndexBlankSlate;
