import PropTypes from "prop-types";
import React from "react";
import autoBind from "react-autobind";
import { identity } from "underscore";

const cancelEvent = (e) => {
  e.stopPropagation();
  e.preventDefault();
};

class Checkbox extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  handleChange(e) {
    if (this.props.readOnly) {
      cancelEvent(e);
    }

    if (this.props.onChange) {
      const { name, checked } = e.target;
      this.props.onChange(e, { [name]: checked });
    }
  }

  handleClick(...args) {
    if (this.props.readOnly) {
      cancelEvent(...args);
      return;
    }

    this.props.onClick(...args);
  }

  render() {
    const input = (
      <input
        {...this.props}
        type="checkbox"
        onClick={this.handleClick}
        onChange={this.handleChange}
        style={{ marginRight: ".5em" }}
      >
        {this.props.children}
      </input>
    );

    if (this.props.label) {
      return (
        <label className="checkbox" style={{ verticalAlign: "top" }}>
          {input}
          {this.props.label}
        </label>
      );
    }

    return input;
  }
}

Checkbox.propTypes = {
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  onChange: identity,
  onClick: identity,
};

export default Checkbox;
