import React from "react";

import { useFarmAdvisorConfig } from "collection/graphql/advisor";
import { getCurrentSubscription } from "collection/graphql/subscription";
import useRestQuery from "hooks/useRestQuery";
import { ELEVATE_PLUS } from "model/Subscription/constants";

const CurrentSubscriptionCopy = () => {
  const { data, loading } = useRestQuery(getCurrentSubscription);
  const { isEnabled, programName } = useFarmAdvisorConfig().data;
  if (loading) {
    return null;
  }

  const descriptors = [];
  const { addons, currentPlan, isSubscribedToPlan } = data.subscription;
  if (isSubscribedToPlan) {
    if (isEnabled && currentPlan.id === ELEVATE_PLUS) {
      descriptors.push(programName);
    } else {
      descriptors.push(currentPlan.name);
    }
  }

  if (addons.length > 0) {
    descriptors.push("Add ons");
  }

  return <>Bushel Farm {descriptors.join(" + ")}</>;
};

export default CurrentSubscriptionCopy;
