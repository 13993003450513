import { gql } from "@apollo/client";

import {
  EditableFuturesContract,
  EditableOptionsContract,
  LegacyEditableCashContract,
} from "collection/graphql/contracts/fragments";

/**
 * Fetches the editable data for a single contract by id.
 * @type {DocumentNode}
 */
export default gql`
  query getEditableContract($id: Int!) {
    contract: getContract(id: $id) {
      ... on CashContract {
        ...LegacyEditableCashContract
      }
      ... on FuturesContract {
        ...EditableFuturesContract
      }
      ... on OptionsContract {
        ...EditableOptionsContract
      }
    }
  }

  ${EditableFuturesContract}
  ${EditableOptionsContract}
  ${LegacyEditableCashContract}
`;
