import styled from "styled-components";

import { Icon } from "components/fl-ui/Icons";
import { GreyColors } from "components/fl-ui/colors";
import { BorderRadius, Borders, Spacing, Typography } from "components/fl-ui/constants";

export const BlankSlateContainer = styled.div`
  > div {
    border: ${Borders.regular};
    margin: auto;
    padding: ${Spacing.xxlarge};
  }
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  max-width: 60rem;
  text-align: center;

  @media only screen and (max-width: 30rem) {
    flex-flow: column;
  } ;
`;

export const Content = styled.div`
  line-height: 1.5;
  margin-left: ${Spacing.regular};
  max-width: 75%;
  @media only screen and (max-width: 30rem) {
    margin-left: 0;
    margin-top: ${Spacing.large};
    max-width: 100%;
  } ;
`;

export const ControlsContainer = styled.div`
  align-items: center;
  border-bottom: ${Borders.regular};
  display: flex;
  margin-bottom: ${Spacing.regular};
  padding-bottom: ${Spacing.large};
`;

export const Header = styled.h3`
  margin: ${Spacing.small} 0;

  @media only screen and (max-width: 30rem) {
    display: none;
  }
`;

export const HideOnDesktop = styled.div`
  display: none;
  @media only screen and (max-width: 60rem) {
    display: unset;
  } ;
`;

export const HideOnMobile = styled.div`
  @media only screen and (max-width: 60rem) {
    display: none;
  } ;
`;

export const ImageryContainer = styled.div`
  border: ${Borders.regular};
  border-radius: ${BorderRadius.medium};
  margin-bottom: ${Spacing.large};
  margin-right: ${Spacing.large};
  padding: ${Spacing.small};

  @media only screen and (max-width: 30rem) {
    margin-left: auto;
    margin-right: auto;
  } ;
`;

export const MainText = styled.p`
  font-size: ${Typography.sizes.lg};
  font-weight: ${Typography.weights.medium};
  margin-bottom: ${Spacing.xxsmall};
`;

export const MobileHeader = styled.h3`
  display: none;
  margin: ${Spacing.small} 0;

  @media only screen and (max-width: 30rem) {
    display: block;
    margin-bottom: ${Spacing.large};
  }
`;

export const StyledIcon = styled(Icon)`
  background: ${GreyColors.smoke99};
  border: 1px solid ${GreyColors.smoke90};
  margin: auto;
  max-width: 25%;
  min-width: 5rem;
  padding: ${Spacing.large};
  width: 5rem !important;

  @media only screen and (max-width: 30rem) {
    max-width: 100%;
  } ;
`;

export const Subtext = styled.p`
  font-size: ${Typography.sizes.sm};
`;

export const WrappedRow = styled.div`
  align-items: flex-end;
  display: flex;
  flex-flow: row wrap;
`;
