import DesktopMenu from "./DesktopMenu";
import ResponsiveMenu from "./ResponsiveMenu";
import React from "react";

import { useAuth } from "collection/graphql/auth";
import useViewType from "hooks/useViewType";

const Navigation = () => {
  const { isLoggedIn } = useAuth();
  const isDesktop = useViewType() === "desktop";

  return !isLoggedIn ? null : (
    <>
      {isDesktop && <DesktopMenu />}
      {!isDesktop && <ResponsiveMenu />}
    </>
  );
};

export default Navigation;
