import * as yup from "yup";

import ActivityInputInputSchema from "collection/graphql/activities/schemas/ActivityInputInputSchema";

const IngredientSchema = yup.object().shape({
  amount: ActivityInputInputSchema.fields.amount.clone(),
  amountUnit: ActivityInputInputSchema.fields.amountUnit.clone(),
  enterpriseId: yup.number().label("Enterprise id").optional().min(0),
  product: ActivityInputInputSchema.fields.product.clone(),
  productRecipeId: yup.number().label("Product recipe id").optional().min(0),
  rateUnit: yup
    .string()
    .label("Rate")
    .typeError("${path} is invalid")
    .required()
    .oneOf(["PER_ACRE", "TOTAL"], "${path} is invalid"),
});

export default IngredientSchema;
