import * as yup from "yup";

const SbbEnrollmentFormSchema = yup.object().shape({
  name: yup.string().required("Please supply a Farm Name"),
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  postalCode: yup.string().min(5, "Farm zip code must be at least 5 characters").required("Farm zip code is required"),
  advisor_email: yup.string().required("Confirm Your SB&B advisor"),
  checked: yup.bool().oneOf([true], "Permission must be checked"),
});

export default SbbEnrollmentFormSchema;
