import CommonEnrollmentPage from "carbon_enrollment/pages/CommonEnrollmentPage";

import { marketingClient, wrapWithProvider } from "collection/graphql/client";
import App from "layout/app";
import SubRoute from "lib/SubRoute";

const CarbonEnrollmentRouter = SubRoute.extend({
  routes: {
    "": "index",
    ":clientId": "index",
  },

  display(page, props) {
    App.display(wrapWithProvider(page, marketingClient), props);
  },

  index(clientId) {
    this.display(CommonEnrollmentPage, { clientId, screenName: "Carbon enrollment" });
  },
});

export default () => new CarbonEnrollmentRouter("carbon_enrollment", { createTrailingSlashRoutes: true });
