import leaseTermsValidator from "agreements/validators/AgreementsForm/leaseTermsValidator";
import paymentScheduleValidator from "agreements/validators/AgreementsForm/paymentScheduleValidator";
import _ from "lodash";
import moment from "moment";

import utils from "lib/validation/utils";

const isValid = (fields, errors = {}) => _.isEmpty(validate(fields, { errors }));

const validate = (formFields, options = {}) => {
  const currentYear = +moment().format("YYYY");
  const MIN_YEAR = currentYear - 100;
  const MAX_YEAR = currentYear + 100;
  const errors = options.errors || {};
  const { fields, paymentSchedules, startYear, endYear, terms } = formFields;

  // startYear
  if (!utils.isInt(startYear, { min: MIN_YEAR, max: MAX_YEAR })) {
    errors.startYear = { value: startYear };
  }

  // endYear
  if (!_.has(errors, "startYear") && !utils.isInt(endYear, { min: startYear, max: MAX_YEAR })) {
    errors.endYear = { value: endYear };
  }

  // fields
  if (_.size(fields) === 0 || !_.every(fields, "id")) {
    errors.fields = { value: fields };
  }

  // terms
  const allLeaseTermsValid = _.every(terms, (term) => leaseTermsValidator.isValid(term));
  if (_.size(terms) === 0 || !allLeaseTermsValid) {
    errors.terms = { value: terms };
  }

  // paymentSchedules
  const allSchedulesValid = _.every(paymentSchedules, (schedule) =>
    paymentScheduleValidator.isValid(schedule, {
      context: {
        ...formFields,
        agreementType: "LEASE",
      },
    })
  );
  if (!allSchedulesValid) {
    errors.paymentSchedules = { value: paymentSchedules };
  }

  return errors;
};

export default {
  isValid,
  validate,
};
