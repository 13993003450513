import _ from "lodash";
import { useMemo } from "react";

import useUnits from "hooks/useUnits";

const useCommodityCustomaryUnits = () => {
  const customaryUnitEnumValues = useUnits().findEnumByName("CommodityCustomaryUnit");

  return useMemo(() => {
    const nameMap = {
      BUSHEL: "Bushel",
      HUNDREDWEIGHT: "Hundredweight",
      KILOGRAM: "Kilogram",
      POUND: "Pound",
      TON: "Ton",
    };

    return _.sortBy(customaryUnitEnumValues, "value").map(({ display, value }) => ({
      abbr: display,
      name: nameMap[value],
      type: "CommodityCustomaryUnit",
      value,
    }));
  }, [customaryUnitEnumValues]);
};

export default useCommodityCustomaryUnits;
