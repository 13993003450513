import UIColors from "components/fl-ui/colors/ui";
import Mixins from "components/fl-ui/constants/mixins";
import Variables from "components/fl-ui/constants/variables";

const Sizes = {
  xxs: Mixins.toRem(8),
  xs: Mixins.toRem(10),
  sm: Mixins.toRem(12),
  mn: Mixins.toRem(13),
  rg: Mixins.toRem(14),
  md: Mixins.toRem(14),
  lg: Mixins.toRem(16),
  xl: Mixins.toRem(20),
  xxl: Mixins.toRem(24),
  xxxl: Mixins.toRem(28),
  xxxxl: Mixins.toRem(32),
};

const Weights = {
  bold: 700,
  medium: 500,
  regular: 400,
  light: 300,
};

const Colors = {
  primary: UIColors.regular,
  secondary: UIColors.medium,
  tertiary: UIColors.lighter,
};

const Base = {
  fontFamily: `"Helvetica Neue", Helvetica, Arial, sans-serif`,
  fontSize: Sizes.rg,
  color: UIColors.regular,
  lineHeight: "1.2",
};

const InfoText = {
  color: UIColors.light,
  fontSize: ".7em",
  fontWeight: Weights.medium,
  display: "inline-block",
  margin: "0 0 0 .5em",
  lineHeight: 1,
};

const HelpText = {
  fontSize: Sizes.mn,
  color: UIColors.regular,
  lineHeight: 1.38,
  letterSpacing: "-0.2px",
};

const Typography = {
  baseRem: Variables.baseRem,
  base: Base,
  sizes: Sizes,
  weights: Weights,
  colors: Colors,
  infoText: InfoText,
  helpText: HelpText,
};

export default Typography;
