import Backbone from "backbone";
import _ from "lodash";

/**
 * @class Collection
 * @extends Backbone.Collection
 * @event sync:start
 * @event sync:change
 * @event sync:complete
 */
export default Backbone.Collection.extend({
  constructor() {
    Backbone.Collection.apply(this, arguments);

    this.prime = _.once(() => this.fetch());
  },

  /**
   * @abstract
   */
  name: "",
});
