import { gql } from "@apollo/client";

const deleteActivitiesMutation = gql`
  mutation deleteActivitiesById($ids: [Int]!) {
    deleteActivities(ids: $ids) {
      ok
    }
  }
`;

export default deleteActivitiesMutation;
