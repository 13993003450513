import _ from "lodash";

import convertKeysToLegacy from "collection/graphql/recipes/hooks/lib/convertKeysToLegacy";

import ProductRecipeFormSchema from "components/product_recipe/schemas/ProductRecipeFormSchema";

/**
 * Accepts a {@link ProductRecipe} and transforms it into an object consumable by the
 * product recipe REST endpoints.
 *
 * @param {ProductRecipe} recipe
 * @return {Object}
 */
function convertRecipeToLegacy(recipe) {
  const { ingredients } = recipe;
  const legacyRecipe = convertKeysToLegacy(ProductRecipeFormSchema.cast(recipe, { assert: false, stripUnknown: true }));
  legacyRecipe.ingredients = ingredients.map((ingredient) => {
    return convertKeysToLegacy(_.omit(ingredient, ["created", "updated"]));
  });

  return legacyRecipe;
}

export default convertRecipeToLegacy;
