/* eslint-disable react/display-name */
import { sortBy } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";

import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import useFieldLayers from "lib/map/layers/useFieldLayers";

const useImageryLayers = ({ endDate, fieldId, imageType, sanitize, startDate }) => {
  const skipAll = !useEnterpriseFeature("crop_health_enterprise");
  const type = "satellite.leaf";
  const { layers: leafLayers, loading } = useFieldLayers({ endDate, fieldId, skip: skipAll, startDate, type });
  const [data, setData] = useState({ imageryLayers: [], loading });

  useEffect(() => {
    if (!loading) {
      const updatedLeafLayers = sanitize ? sanitize(leafLayers, imageType) : leafLayers;
      const imageryLayers = sortBy(updatedLeafLayers, ({ date }) => moment(date).unix());

      setData({ imageryLayers, loading });
    }
  }, [endDate, fieldId, imageType, leafLayers, loading, sanitize, startDate]);

  return data;
};

export const withImagery = (Component) => (props) => <Component {...props} {...useImageryLayers(props)} />;

export default useImageryLayers;
