import { BaseElement } from "@farmlogs/fl-ui";
import EditNotesModal from "contracts/components/EditNotesModal";
import useContractPermissions from "contracts/hooks/useContractPermissions";
import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";

import { Button } from "components/fl-ui";
import { GreyColors } from "components/fl-ui/colors";
import { BorderRadius, Spacing } from "components/fl-ui/constants";

const NotesArea = styled(({ className, contract }) => {
  const [isEditing, setIsEditing] = useState(false);
  const { canWrite } = useContractPermissions();

  return (
    <div className={className}>
      <h3>Comments</h3>
      <p>{contract?.notes ?? "--"}</p>

      {canWrite && (
        <BaseElement display="flex" justifyContent="flex-end">
          <Button color="primary" hollow onClick={() => setIsEditing((previousValue) => !previousValue)}>
            Edit
          </Button>
        </BaseElement>
      )}

      {isEditing && <EditNotesModal contract={contract} onClose={() => setIsEditing(false)} />}
    </div>
  );
})`
  p {
    background-color: ${GreyColors.smoke97};
    margin: ${Spacing.xlarge} ${Spacing.medium};
    padding: ${Spacing.small} ${Spacing.large};
    border-radius: ${BorderRadius.xlarge};
    white-space: pre-wrap;
  }

  button {
    margin-right: ${Spacing.medium};
  }
`;

export default NotesArea;

NotesArea.propTypes = {
  notes: PropTypes.string,
};
