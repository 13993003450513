import { makeVar, useReactiveVar } from "@apollo/client";
import { useOnlineState } from "beautiful-react-hooks";
import React, { useEffect, useRef } from "react";

import useWindow from "hooks/useWindow";

/**
 * A hook that monitors network connectivity and updates when network status changes.
 * By default, there is a 5-second delay between when an offline event is detected
 * and when the hook changes its status. The when the online event is detected the hook
 * updates immediately.
 *
 * @param {Number} [delayMs=5000] the delay between detecting an offline event and notification
 * @return {Boolean}
 */
const useConnectivityChange = (delayMs = 5000) => {
  const { navigator } = useWindow();
  const timeout = useRef(undefined);
  const onlineState = useRef(makeVar(navigator.onLine));
  useOnlineState();
  useReactiveVar(onlineState.current);

  useEffect(() => {
    if (navigator.onLine !== onlineState.current()) {
      clearTimeout(timeout.current);
      if (delayMs > 0 && !navigator.onLine) {
        timeout.current = setTimeout(() => {
          if (navigator.onLine !== onlineState.current()) {
            onlineState.current(navigator.onLine);
          }
        }, delayMs);
      } else {
        onlineState.current(navigator.onLine);
      }
    }

    return () => clearTimeout(timeout.current);
  }, [navigator.onLine]);

  return onlineState.current();
};

export default useConnectivityChange;
