import { useQuery } from "@apollo/client";

import { useAuth } from "collection/graphql/auth";
import { marketingClient } from "collection/graphql/client";
import { GET_CURRENT_ENTERPRISE } from "collection/graphql/enterprise/queries";

const useCurrentEnterprise = () => {
  const { isLoggedIn } = useAuth();
  const { data, loading, refetch } = useQuery(GET_CURRENT_ENTERPRISE, {
    client: marketingClient,
    skip: !isLoggedIn,
  });

  return {
    currentEnterprise: data?.currentEnterprise,
    loading,
    refetch,
  };
};

export default useCurrentEnterprise;
