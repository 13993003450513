import { withWriteActivityWorkflow } from "activity/components/WriteActivityWorkflow";
import useActivitiesProvider from "activity/hooks/useActivitiesProvider";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import withViewType from "hoc/withViewType";
import useConnectivityChange from "hooks/useConnectivityChange";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import usePermissions from "hooks/usePermissions";

import { Button } from "components/fl-ui";
import DropdownButton from "components/fl-ui/Buttons/DropdownButton";
import CropYearPicker from "components/fl-ui/CropYearPicker";
import Header from "components/fl-ui/Layout/Header";
import { Spacing } from "components/fl-ui/constants";

const ActionButtons = withViewType(styled(
  ({ className, onCreateActivity, onCreateWorkOrder, showWorkOrderAction, viewType }) => {
    const isOnline = useConnectivityChange();

    if (viewType === "mobile" && showWorkOrderAction) {
      return (
        <div style={{ marginRight: "5px" }}>
          <DropdownButton
            buttonText="Log activity"
            disabled={!isOnline}
            onClick={() => onCreateActivity()}
            onSelect={() => onCreateWorkOrder()}
            options={[{ text: "Assign work" }]}
          />
        </div>
      );
    }

    return (
      <div className={className}>
        {showWorkOrderAction && (
          <Button color="primary" disabled={!isOnline} onClick={onCreateWorkOrder} size="mn">
            Assign work
          </Button>
        )}

        <Button color="primary" disabled={!isOnline} onClick={onCreateActivity} size="mn">
          Log activity
        </Button>
      </div>
    );
  }
)`
  ${(props) =>
    props.viewType === "desktop" &&
    `
    > button {
      margin: 0 ${Spacing.xsmall};
    }
  `}
`);

ActionButtons.propTypes = {
  onCreateActivity: PropTypes.func.isRequired,
  onCreateWorkOrder: PropTypes.func,
  showWorkOrderAction: PropTypes.bool,
};

ActionButtons.defaultProps = {
  showWorkOrderAction: false,
};

const ActivityPageHeader = ({ startWorkflow, showActivityCount }) => {
  const { totalFilteredActivities } = useActivitiesProvider();
  const { canWrite } = usePermissions();
  const hasWorkOrdersFeature = useEnterpriseFeature("work_orders");
  const showWorkOrderAction = !hasWorkOrdersFeature || canWrite("work_order_admin");

  const headerProps = { title: "Activities" };
  if (showActivityCount && totalFilteredActivities >= 0) {
    headerProps.description = `${totalFilteredActivities} ${totalFilteredActivities === 1 ? "activity" : "activities"}`;
  }

  const handleCreateActivity = () => {
    startWorkflow({ isWorkOrder: false });
  };

  const handleCreateWorkOrder = () => {
    startWorkflow({ isWorkOrder: true });
  };

  return (
    <Header {...headerProps}>
      <ActionButtons
        onCreateActivity={handleCreateActivity}
        onCreateWorkOrder={handleCreateWorkOrder}
        showWorkOrderAction={showWorkOrderAction}
      />

      <div>
        <CropYearPicker />
      </div>
    </Header>
  );
};

ActivityPageHeader.propTypes = {
  showActivityCount: PropTypes.bool,
};

ActivityPageHeader.defaultProps = {
  showActivityCount: false,
};

export default withWriteActivityWorkflow(ActivityPageHeader);
