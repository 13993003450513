import { gql } from "@apollo/client";

import { ENTERPRISE_FRAGMENT } from "collection/graphql/enterprise/fragments";

export default gql`
  mutation ($enterprise: UpdateEnterpriseInput!) {
    updateEnterprise(enterprise: $enterprise) {
      ok
      enterprise {
        ...Enterprise
      }
    }
  }
  ${ENTERPRISE_FRAGMENT}
`;
