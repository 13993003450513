import Dashboard from "./routes/Dashboard";
import Report from "./routes/Report";

import App from "layout/app";
import SubRoute from "lib/SubRoute";

const ReportsRouter = SubRoute.extend({
  routes: {
    "": "index",
    ":report": "report",
  },

  index() {
    App.display(Dashboard, { screenName: "Reports" });
  },

  report(id) {
    App.display(Report, { screenName: "Report", id });
  },
});

export default () => new ReportsRouter("reports", { createTrailingSlashRoutes: true });
