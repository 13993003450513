import { StyleSheet, css } from "aphrodite/no-important";
import React from "react";

import { BorderRadius, Borders, Spacing } from "components/fl-ui/constants";

const styles = StyleSheet.create({
  well: {
    padding: Spacing.large,
    borderRadius: BorderRadius.medium,
    border: Borders.regular,
  },
  divider: {
    borderBottom: Borders.regular,
    borderTop: "none",
    height: 0,
  },
});

const Well = ({ children, className, ...props }) => (
  <div {...props} className={css(styles.well)}>
    {children}
  </div>
);

const WellDivider = () => <hr className={css(styles.divider)} />;

export { Well, WellDivider };
