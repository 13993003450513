import CashContractRow from "./CashContractRow";
import CashContractsHeader from "./CashContractsHeader";
import NoContractsResults from "contracts/components/NoContractsResults";
import { ConnectToBushelCard } from "marketing/cards";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import LoadingSpinner from "components/ui/loading";

const CashContractsTableView = styled(({ contracts, className, loading }) => (
  <div className={className}>
    <CashContractsHeader />
    <br />

    <ConnectToBushelCard />

    {!loading && contracts.length === 0 && <NoContractsResults />}

    {loading && <LoadingSpinner loading />}

    {!loading && contracts.map((contract) => <CashContractRow contract={contract} key={contract.id} />)}
  </div>
))`
  & > div {
    align-items: center;
  }

  .expand {
    width: 3%;
  }
  .contract {
    width: 11%;
    word-wrap: break-word;
  }
  .buyer {
    width: 17%;
  }
  .buyer-icon {
    width: 4%;
    text-align: center;
  }
  .commodity {
    width: 12%;
  }
  .delivery {
    width: 20%;
  }
  .qty-contracted {
    width: 12%;
  }
  .qty-priced {
    width: 10%;
  }
  .pricing {
    width: 7%;
  }
  .dots {
    width: 4%;
  }
`;

export default CashContractsTableView;

CashContractsTableView.propTypes = {
  contracts: PropTypes.array,
};
