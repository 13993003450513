import { useSuspenseQuery } from "@apollo/client";
import Big from "big.js";
import { useMemo } from "react";

import getFieldStats from "collection/graphql/fields/queries/getFieldStats";

/**
 * @typedef {object} FieldStats
 * @property {number} totalFieldAcreage the sum of the acreages of all fields on the current enterprise.
 * @property {number} totalFields the total number of fields on the current enterprise.
 */

/**
 * Executes a suspendable query to fetch the minimum amount of data required to calculate
 * basic statistics on all fields on the enterprise.
 *
 * @return {FieldStats}
 */
const useFieldStats = () => {
  const fields = useSuspenseQuery(getFieldStats).data?.fields ?? [];
  const totalFieldAcreage = useMemo(() => {
    const total = fields.reduce((total, field) => total.add(field.acreage), new Big(0));
    return total.toNumber();
  }, [fields]);

  return {
    totalFieldAcreage,
    totalFields: fields.length,
  };
};

export default useFieldStats;
