import { gql } from "@apollo/client";

import { AARRecording } from "collection/graphql/activities/fragments";

export default gql`
  query GetActivityRecording($id: Int!) {
    recording: getActivityRecording(id: $id) {
      ...AARRecording
    }
  }
  ${AARRecording}
`;
