import PropTypes from "prop-types";
import React from "react";

import { Button } from "components/fl-ui";
import FormGroup from "components/fl-ui/Form/FormGroup";

const Label = ({ label, onClear }) => (
  <>
    {`${label} · `}
    <Button color="primary" link onClick={onClear} size="mn">
      Clear
    </Button>
  </>
);

const ClearAllFormGroup = ({ label, onClear, ...props }) => (
  <FormGroup label={<Label label={label} onClear={onClear} />} {...props} />
);

ClearAllFormGroup.propTypes = {
  label: PropTypes.string.isRequired,
  onClear: PropTypes.func.isRequired,
};

export default ClearAllFormGroup;
