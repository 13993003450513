import _ from "lodash";

/**
 * Groups and sorts activities.
 *
 * @param activities
 * @param {Object} filters
 * @param {ActivitySort} filters.sortCriteria
 * @return {Map[]}
 */
const useGroupedActivities = (activities, { sortCriteria }) => {
  let groupedActivities;

  const createGroupMap = (activities, comparator) => {
    const groups = _.groupBy(activities, comparator);
    const sortedKeys = _.sortBy(_.keys(groups), (key) => key.toLowerCase());
    return sortedKeys.reduce((map, key) => map.set(key, groups[key]), new Map());
  };

  switch (sortCriteria) {
    case "cropName":
      activities = _.sortBy(activities, ({ crop: { commodity } }) => _.lowerCase(commodity.name));
      groupedActivities = createGroupMap(activities, "crop.commodity.name");
      break;

    case "dateAsc":
    case "date":
      const order = sortCriteria === "dateAsc" ? "asc" : "desc";
      activities = _.orderBy(activities, "date", order);
      groupedActivities = createGroupMap(activities, () => "Date");
      break;

    case "fieldName":
      activities = _.sortBy(activities, ({ field: { name } }) => _.lowerCase(name));
      groupedActivities = createGroupMap(activities, ({ field }) => field.name);
      break;

    case "status":
      activities = _.sortBy(activities, "date").reverse();
      groupedActivities = createGroupMap(activities, ({ statusText }) => statusText);
      break;

    case "category":
    default:
      let [withCategories, withoutCategories] = _.partition(activities, "type");
      withCategories = _.sortBy(withCategories, "date").reverse();
      withoutCategories = _.sortBy(withoutCategories, "date").reverse();
      groupedActivities = createGroupMap(withCategories, (activity) => activity.type.category);
      if (withoutCategories.length > 0) {
        groupedActivities.set("Unknown", withoutCategories);
      }
      break;
  }

  return groupedActivities;
};

export default useGroupedActivities;
