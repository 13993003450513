/* eslint-disable react/display-name */
import { css } from "aphrodite";
import EnrollmentForm from "carbon_enrollment/components/EnrollmentForm";
import { StyledIconsContainer } from "carbon_enrollment/components/styledComponents";
import { isNil, sumBy } from "lodash";
import React from "react";

import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import useCarbonEnrollment from "modules/carbon_enrollment/hooks/useCarbonEnrollment";
import styles from "modules/carbon_enrollment/styles";
import { isValid, TOOLTIP_MESSAGE } from "modules/carbon_enrollment/utils";

import { Icon, Logo } from "components/fl-ui/Icons";
import Tooltip from "components/fl-ui/Tooltip";

const MIN_ACREAGE = 300;
const clientData = {};
clientData.clientName = "Indigo Ag";
clientData.confirmationCopy = (
  <>
    {"For the fields you are choosing to send to Indigo Carbon, please indicate if you have already made a "}
    <span className={css(styles.blueText)}>
      qualifying practice change
      <Tooltip message={TOOLTIP_MESSAGE}>
        <Icon icon="help" className={css(styles.helpIcon)} />
      </Tooltip>
    </span>
    this past season (since harvest of 2018) or intend to make one in the coming season(s).
  </>
);

clientData.headerCopy = (
  <>
    {"The 2020 program is open for enrollment to growers who have added carbon farming practices between "}
    {"harvest 2018 and harvest 2020. If you haven’t yet tried but are considering one of these practices for "}
    {"after harvest 2020, you can enroll in the 2021 program, and we’ll be glad to help you "}
    <a
      className={css(styles.blueText)}
      href="https://www.indigoag.com/indigo-carbon-farmlogs?utm_source=farmlogs"
      rel="noopener noreferrer"
      target="_blank"
    >
      learn more
    </a>
    {" for the next season."}
  </>
);

clientData.headerTitle = "Sign up to generate carbon credits through our partnership with Indigo Carbon!";
clientData.LogoComponent = () => (
  <StyledIconsContainer justifyContent="center">
    <Logo size="sm" />
  </StyledIconsContainer>
);

clientData.nextSteps = (
  <>
    <p className={css(styles.copy)}>
      Once you complete this page, Bushel Farm will securely send your information to Indigo and here is what you can
      expect:
    </p>

    <ol className={css(styles.orderedList)}>
      <li className={css(styles.copy, styles.list)}>
        A member of the Indigo team will reach out to you with a link to set up your Indigo Carbon account - please do
        this promptly.
      </li>
      <li className={css(styles.copy, styles.list)}>
        Once you have created an account, Indigo will upload the boundaries for your selected field(s) on your behalf
        and then let you know.
      </li>
      <li className={css(styles.copy, styles.list)}>
        You will log back into Indigo Carbon to confirm which field(s) you are enrolling in the program and to sign a
        contract. The deadline to complete this final step is 7/31 for field(s) with practice changes already made.
      </li>
    </ol>
  </>
);

clientData.radios = [
  {
    label: "I have already made a practice change on one or more of the selected field(s) since harvest of 2018",
    value: "true",
  },
  { label: "I look forward to making a first practice change after harvest 2020", value: "false" },
];

const IndigoEnrollment = () => {
  const enrollment = useCarbonEnrollment("indigo");
  const { data, fields, isSaving, loading, selectedFields } = enrollment;
  const disabled = !isValid(data, selectedFields, MIN_ACREAGE) || isSaving;
  const hasCarbonFeature = useEnterpriseFeature("indigo_carbon");
  const isEligible = !hasCarbonFeature && sumBy(fields, "acreage") >= MIN_ACREAGE;
  const isLoading = loading || !data || isNil(hasCarbonFeature);

  return (
    <EnrollmentForm
      clientData={clientData}
      disabled={disabled}
      enrollment={enrollment}
      isEligible={isEligible}
      isLoading={isLoading}
    />
  );
};

export default IndigoEnrollment;
