import PropTypes from "prop-types";
import React, { useMemo } from "react";
import ReactSelect from "react-select";

const UnderlyingDropdown = ({ id, onBlur, onChange, placeholder, underlyings, value }) => {
  const options = useMemo(
    () =>
      underlyings.map(({ shortName, symbol }) => ({
        label: shortName,
        value: symbol,
      })),
    []
  );
  const selectedOption = options.find((option) => option.value === value);

  return (
    <ReactSelect
      inputId={id}
      options={options}
      onBlur={onBlur}
      onChange={({ value }) => onChange(value)}
      placeholder={placeholder}
      value={selectedOption}
    />
  );
};

UnderlyingDropdown.propTypes = {
  id: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  underlyings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  value: PropTypes.string,
};

export default UnderlyingDropdown;
