import { useQuery } from "@apollo/client";
import _ from "lodash";
import React from "react";

import { GET_COUNTIES } from "collection/graphql/prices";

import Chooser from "components/fl-ui/Chooser";

const CountySelectionInput = ({ onChange, selectedCounties }) => {
  const { data } = useQuery(GET_COUNTIES);

  return (
    <Chooser
      hideIndicator
      getOptionLabel={({ name, stateAbbr }) => `${name}, ${stateAbbr}`}
      getOptionValue={({ id }) => id}
      isClearable={false}
      isMulti
      onChange={onChange}
      options={data?.getCountiesNearby ?? []}
      placeholder="Select counties..."
      value={selectedCounties}
    />
  );
};

export default CountySelectionInput;
