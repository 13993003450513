import _ from "lodash";
import * as yup from "yup";

import { CropsColorPalette } from "components/fl-ui/colors/palette";

/**
 * Validation schema for the commodity form.
 * @see https://farmlogs.atlassian.net/wiki/spaces/BS/pages/2420310029/Commodities#Validation
 */
const CommodityFormSchema = yup.object().shape(
  {
    id: yup.number().integer(),

    name: yup
      .string()
      .trim()
      .required("Commodity name is required")
      .test("isInUse", "Commodity name is already in use", (value, { resolve }) => {
        const names = resolve(yup.ref("$existingCommodityNames")).map(_.toLower);
        return !names.includes(_.toLower(value));
      }),

    abbr: yup
      .string()
      .trim()
      .required("Abbreviation is required")
      .min(1, "Abbreviations must be 1 to 3 characters")
      .max(3, "Abbreviations must be 1 to 3 characters")
      .matches(/^\S*$/, "Abbreviations can not contain spaces"),

    color: yup.string().required("Color is required").oneOf(_.keys(CropsColorPalette), "Color is invalid"),

    gddTbase: yup
      .number()
      .nullable()
      .when("gddTceil", (gddTceil) => {
        const rules = yup
          .number()
          .nullable()
          .integer("GDD floor must be a whole number")
          .moreThan(0, "GDD floor must be greater than 0");

        return _.isNil(gddTceil)
          ? rules
          : rules
              .required("GDD floor is required when GDD ceiling is provided")
              .lessThan(gddTceil, "GDD floor must be less than GDD ceiling");
      }),

    gddTceil: yup
      .number()
      .nullable()
      .when("gddTbase", (gddTbase) => {
        const rules = yup
          .number()
          .nullable()
          .integer("GDD ceiling must be a whole number")
          .moreThan(0, "GDD ceiling must be greater than 0");

        return _.isNil(gddTbase) ? rules : rules.moreThan(gddTbase, "GDD ceiling must be more than GDD floor");
      }),

    isArchived: yup.boolean().default(false),

    seedUnitType: yup
      .string()
      .required("Measure type is required")
      .oneOf(["COUNT", "WEIGHT"], "Measure type must be either Count or Weight"),

    defaultSeedApplyUnit: yup
      .string()
      .nullable()
      .test("isValidValue", "Default seed use unit is invalid", (value, { resolve }) => {
        return !value || resolve(yup.ref("$seedUnits")).includes(value);
      }),

    defaultSeedPurchaseUnit: yup
      .string()
      .nullable(true)
      .test("isValidValue", "Default seed purchase unit is invalid", (value, { resolve }) => {
        return !value || resolve(yup.ref("$seedUnits")).includes(value);
      }),

    defaultYieldUnit: yup
      .string()
      .required("Yield unit is required")
      .test("isValidValue", "Default yield unit is invalid", (value, { resolve }) => {
        return !value || resolve(yup.ref("$yieldUnits")).includes(value);
      }),

    bushelSize: yup.number().label("Bushel weight").typeError("${path} is required").required().moreThan(0).integer(),

    dryMoisture: yup
      .number()
      .label("Dry moisture")
      .nullable()
      .min(0)
      .max(100)
      .test({
        name: "singlePrecision",
        message: "${path} can only have a maximum of one decimal place",
        test: (number) => {
          return !number || /^\d+(\.\d{1})?$/.test(number);
        },
      }),
  },
  [["gddTbase", "gddTceil"]]
);

export default CommodityFormSchema;
