import { css } from "aphrodite/no-important";
import { SalesPhoneLink, styles, SupportLink } from "billing/lib";
import PropTypes from "prop-types";
import React from "react";

import { getCurrentSubscription } from "collection/graphql/subscription";
import useRestQuery from "hooks/useRestQuery";

import CloseX from "components/fl-ui/Icons/CloseX";
import { Modal, ModalBody, ModalSection } from "components/fl-ui/Modal/Modal";

const SuccessModal = ({ onClose }) => {
  const { data, loading } = useRestQuery(getCurrentSubscription);

  return loading ? null : (
    <Modal className={css(styles.success_modal)} width={530}>
      <ModalSection className={css(styles.success_modalSection)}>
        <CloseX className={css(styles.success_closeX)} color="white" onClick={onClose} />
      </ModalSection>
      <ModalBody className={css(styles.success_modalBody)}>
        <h2 className={css(styles.success_h2, styles.text_center)}>Thanks for choosing Bushel Farm!</h2>
        <p className={css(styles.modal_text, styles.text_center)}>
          {`You are now subscribed to ${data.subscription.currentPlan.name}. If you need help or have questions, give us a call at `}
          <SalesPhoneLink />
          {`or visit our `}
          <SupportLink>support page</SupportLink>
          {`. We'll be with you every step of the way!`}
        </p>
      </ModalBody>
    </Modal>
  );
};

SuccessModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default SuccessModal;
