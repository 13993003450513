/* eslint-disable react/display-name */
import AgreementRowSubtype from "agreements/components/AgreementRowSubtype";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";

import { withAgreements } from "collection/graphql/agreements";
import usePermissions from "hooks/usePermissions";

import { Button } from "components/fl-ui";

const FieldAgreements = ({ data: { agreements } }) =>
  !!agreements?.length && (
    <>
      <h4>AGREEMENTS</h4>

      {agreements?.map(({ agreementSummary, agreementType, id, maturityDate, subType, terms }) => (
        <p key={id}>
          <Button color="primary" href={`#land_costs/${id}`} lightWeight link size="lg">
            <AgreementRowSubtype agreementType={agreementType} subType={subType} terms={terms} />
          </Button>
          <span>{` - ${agreementSummary} \u00b7 Expires on ${moment(maturityDate).format("MM/DD/YYYY")}`}</span>
        </p>
      ))}
    </>
  );

const withPermissionProtection =
  (WrappedComponent) =>
  ({ fieldId, ...props }) => {
    const { canRead } = usePermissions();
    return canRead("agreements") ? <WrappedComponent {...props} fields={[fieldId]} /> : null;
  };

withPermissionProtection.propTypes = {
  fieldId: PropTypes.number.isRequired,
};

export default withPermissionProtection(withAgreements(FieldAgreements));
