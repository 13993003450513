import numeral from "numeral";
import * as yup from "yup";

import "lib/validation/yupPolyfill";

/**
 * A schema matching `CreateProductPurchaseInput` and `UpdateProductPurchaseInput` types in
 * the grain marketing graphql schema.
 */
const ProductPurchaseInputSchema = yup.object().shape({
  amount: yup
    .number()
    .label("Amount")
    .required()
    .test("isNonZero", "${path} can not be 0", (value) => {
      return value !== 0;
    }),

  amountUnit: yup.string().label("Unit").required().inEnum("ProductPurchaseAmountUnit"),

  cropYear: yup.number().label("Crop year").required().moreThan(0),
  date: yup.date().label("Purchase date").required(),
  id: yup.number().optional().moreThan(0).integer(),
  product: yup.number().label("Input").typeError("${path} is required").required().moreThan(0).integer(),

  vendor: yup.string().label("Vendor").trim().nullable(),

  cost: yup
    .number()
    .label("Cost")
    .typeError("Cost is a required field")
    .required("Cost is a required field")
    .moreThan(0, "Cost must be more than 0")
    .test("doublePrecision", "${path} is an invalid currency amount", (value) => {
      return +value === +numeral(value).format("0.00");
    }),

  costRate: yup.string().label("Rate").required().inEnum("ProductPurchaseCostRate"),
});

export default ProductPurchaseInputSchema;
