import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import React from "react";
import _ from "underscore";

export default createReactClass({
  displayName: "AutoComplete",

  propTypes: {
    className: PropTypes.string,
    name: PropTypes.string,
    onCreate: PropTypes.func,
    onSelect: PropTypes.func,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.any,
        name: PropTypes.string,
      })
    ).isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  },

  getDefaultProps() {
    return {
      name: "autocomplete-input",
      value: "",
      className: "",
      onCreate: false,
      onSelect() {},
    };
  },

  getInitialState() {
    return {
      open: false,
      selectedIndex: 0,
      filter: null,
      filteredOptions: this.props.options,
      showNew: false,
    };
  },
  
  //TODO: Refactor this component completely
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      return this.setState({ filter: null });
    }
  },

  _clear() {
    if (this.state.filter === "") {
      this._selectEmpty();
    }

    this.setState({
      open: false,
      filter: null,
      filteredOptions: this.props.options,
    });
    return this.refs.scroller != null ? (this.refs.scroller.scrollTop = 0) : undefined;
  },

  _open() {
    return this.setState({
      open: true,
      selectedIndex: _.findIndex(this.props.options, (val) => val.name === this.props.value),
    });
  },

  _create(name) {
    this.props.onCreate(name);
    return this._clear();
  },

  _select(value) {
    this.props.onSelect(value);
    // If the filter is empty, null it so _clear() doesn't select empty.
    if (this.state.filter === "") {
      return this.setState({ filter: null }, this._clear);
    } else {
      return this._clear();
    }
  },

  // When the text input is clicked, highlight all text.
  _handleInputClick(event) {
    event.stopPropagation();
    return this.refs.autocompleteInput.select();
  },

  _handleOptionClick(option) {
    return (event) => {
      event.stopPropagation();
      return this._select(option);
    };
  },

  _handleCreateClick() {
    return this._create(this.state.filter);
  },

  _selectEmpty() {
    return this.props.onSelect("");
  },

  _textChange(event) {
    event.stopPropagation();
    return this._filter(event.target.value);
  },

  _handleKeyDown(event) {
    if (!this.state.filter && !this.state.open) {
      this._open();
    }
    switch (event.keyCode) {
      // Up arrow
      case 38:
        return this._upPressed(event);
      // Down arrow
      case 40:
        return this._downPressed(event);
      // Enter
      case 13:
        return this._enterPressed(event);
      // Esc, tab
      case 27:
      case 9:
        return this._clear();
    }
  },

  _enterPressed(event) {
    if (this.state.open) {
      event.preventDefault();
      // If the selected index is the length of the options array, we're at the
      // 'create new' option.
      // If the value highlighted via keyboard is in the list of filtered options
      // when enter is pressed, select it.
      const index = this.state.selectedIndex;
      const selected = this.state.filteredOptions[index];
      if (index === this.state.filteredOptions.length && this.state.filter) {
        return this._create(this.state.filter);
      } else if (this.state.filteredOptions.indexOf(selected) !== -1) {
        return this._select(selected);
      }
    }
  },

  _downPressed(event) {
    if (this.state.open) {
      const optionCount = this.state.filteredOptions.length;
      event.preventDefault();
      if (
        this.state.selectedIndex < optionCount - 1 ||
        (this.state.selectedIndex === optionCount - 1 && this._isNewOptionShown())
      ) {
        const newIndex = this.state.selectedIndex + 1;
        const nextNode = this.refs[`option${newIndex}`];
        const scrollTop = this._getScrollPosition(nextNode);

        this.setState({ selectedIndex: newIndex });
        return (this.refs.scroller.scrollTop = scrollTop);
      }
    } else {
      return this._open();
    }
  },

  _upPressed(event) {
    if (this.state.open) {
      event.preventDefault();
      if (this.state.selectedIndex > 0) {
        const newIndex = this.state.selectedIndex - 1;
        const nextNode = this.refs[`option${newIndex}`];
        const scrollTop = this._getScrollPosition(nextNode);

        this.setState({ selectedIndex: newIndex });
        return (this.refs.scroller.scrollTop = scrollTop);
      }
    } else {
      return this._open();
    }
  },

  // Filter the results. Perform a lower case comparison between each option
  // and the filter text. If there's an exact match, hide the user input option.
  _filter(filter) {
    const lower = filter.toLowerCase();
    let exact = false;
    const regex = new RegExp(lower.split("").join(".*"));
    const options = _.filter(this.props.options, function (option) {
      const name = option.name.toLowerCase();
      exact = exact || name === lower;
      return regex.test(name);
    });
    // Only show the option to add a new entry if there isn't an exact match.
    const showNew = !exact;

    return this.setState({
      filter,
      filteredOptions: options,
      selectedIndex: filter === "" ? -1 : 0,
      open: true,
      showNew,
    });
  },

  // Get what scrolltop value needed to show `target`. If target is in view,
  // this returns the current scrolltop value.
  _getScrollPosition(target) {
    const { scroller } = this.refs;
    const bounds = scroller.getBoundingClientRect();
    const targetBounds = target.getBoundingClientRect();
    const pos = scroller.scrollTop;

    if (bounds.top > targetBounds.top) {
      return pos - (bounds.top - targetBounds.top);
    }
    if (bounds.bottom < targetBounds.bottom) {
      return pos + (targetBounds.bottom - bounds.bottom);
    } else {
      return pos;
    }
  },

  // Check if an option is the currently selected one.
  _isSelected(option) {
    if (_.isArray(this.props.value)) {
      return this.props.value.indexOf(option) !== -1;
    }

    return option === this.props.value;
  },

  _isNewOptionShown() {
    return !!(this.state.filter && this.state.showNew && this.props.onCreate);
  },

  // Return an element for each list item.
  _renderOption(value, index) {
    let className = "result";
    if (this._isSelected(value)) {
      className += " selected";
    }
    if (value === this.state.filteredOptions[this.state.selectedIndex]) {
      className += " highlighted";
    }

    return (
      <li key={value.id} onMouseDown={this._handleOptionClick(value)} className={className} ref={`option${index}`}>
        {value.name}
      </li>
    );
  },

  // Return an element for the user input list item.
  _renderCreateOption() {
    let className = "result add-new";
    if (this.state.selectedIndex === this.state.filteredOptions.length) {
      className += " highlighted";
    }

    return (
      <li
        key="new"
        className={className}
        onMouseDown={this._handleCreateClick}
        ref={`option${this.state.filteredOptions.length}`}
      >
        {`+ "${this.state.filter || " "}"`}
      </li>
    );
  },

  _renderList() {
    const showNew = this._isNewOptionShown();
    if (this.state.filteredOptions.length === 0 && !showNew) {
      return null;
    } else {
      let list;
      if (showNew) {
        list = _.map(this.state.filteredOptions, this._renderOption);
        list.push(this._renderCreateOption());
      } else {
        list = _.map(this.state.filteredOptions, this._renderOption);
      }
      return (
        <div className={`results${!this.state.open ? " hide" : ""}`}>
          <ul ref="scroller" className="result-list">
            {list}
          </ul>
        </div>
      );
    }
  },

  render() {
    // Some weird logic is needed to get the right value to display. state.filter
    // can be null or a string, including emptystring; if it's null, we're not
    // filtering and thus want to show the currently selected value. If it's
    // any string, we want to show that string.
    const value = this.state.filter === null ? this.props.value : this.state.filter;

    return (
      <div className="fl-autocomplete" style={{ position: "relative" }}>
        <input
          ref="autocompleteInput"
          name={this.props.name}
          type="text"
          value={value}
          className={this.props.className}
          onChange={this._textChange}
          onKeyDown={this._handleKeyDown}
          onBlur={this._clear}
          onClick={this._handleInputClick}
          placeholder={this.props.placeholder}
          autoComplete="off"
        />
        {this._renderList()}
      </div>
    );
  },
});
