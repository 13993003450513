import React from "react";

import { Stack } from "components/fl-ui/Layout";

const ScratchPage = () => {
  return (
    <Stack space="2rem">
      <h2>Scratch page</h2>
    </Stack>
  );
};

export default ScratchPage;
