import { gql } from "@apollo/client";

export default gql`
  fragment ProductionHistory on ProductionHistory {
    id
    commodity {
      id
      name
    }
    yieldPerAcre
  }
`;
