/* eslint-disable react/jsx-key */
//TODO: I had to add this to allow for the funky array syntax below
// this should be refactored as soon as it makes sense to
import { css } from "aphrodite";
import { upperFirst } from "lodash";
import dollarFormat from "marketing/utils/dollarFormat";
import InlineEditInput from "profit_and_loss/components/InlineEditInput";
import OrganizeCropModal from "profit_and_loss/components/OrganizeCropModal";
import SummaryTable from "profit_and_loss/components/SummaryTable";
import withEditCropYield from "profit_and_loss/hoc/withEditCropYield";
import styles from "profit_and_loss/styles";
import { getFinanceNotation, getSortedFieldCrops } from "profit_and_loss/utils";
import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";

import withViewType from "hoc/withViewType";
import useUnits from "hooks/useUnits";

import { Button } from "components/fl-ui";

const FieldsSummaryTable = ({ commodity, cropYear, editCropYield, fieldCrops, hasMarketedCrop, viewType }) => {
  const { defaultYieldUnit, id: commodityId } = commodity;

  const { findEnumByName } = useUnits();
  const enums = findEnumByName("CropYieldUnit");
  const getYieldUnitDisplay = (yieldUnit) =>
    enums?.find(({ value }) => value === yieldUnit)?.display || defaultYieldUnit?.toLowerCase();
  const [criteria, setCriteria] = useState("field");
  const [selectedField, setSelectedField] = useState(null);
  const [sortedFieldCrops, setSortedFieldCrops] = useState(getSortedFieldCrops(fieldCrops));
  const handleSort = (value) => {
    if (value === criteria) {
      setSortedFieldCrops([...sortedFieldCrops].reverse());
    } else {
      setCriteria(value);
      setSortedFieldCrops(getSortedFieldCrops(fieldCrops, value));
    }
  };

  const data = sortedFieldCrops.map(
    ({
      acreage,
      costPerAcre,
      field,
      id,
      growerYieldPerAcre,
      profitPerAcre,
      revenuePerAcre,
      yieldPerAcre,
      yieldUnit,
    }) => ({
      id,
      label: (
        <a className={css(styles.link)} href={`/#fields/${field.id}`}>
          {upperFirst(field.name)}
        </a>
      ),
      values:
        viewType === "mobile"
          ? [
              growerYieldPerAcre && `${growerYieldPerAcre.toFixed(2)} ${getYieldUnitDisplay(yieldUnit)} / ac`,
              profitPerAcre && `${getFinanceNotation(profitPerAcre)} / ac`,
            ]
          : [
              acreage && `${acreage.toFixed(2)} ac`,
              <InlineEditInput
                onSave={(value) =>
                  editCropYield({
                    crop: { id, yield: value, yieldRate: "PER_ACRE", yieldUnit: yieldUnit || defaultYieldUnit },
                  })
                }
                unit={`${getYieldUnitDisplay(yieldUnit)} / ac`}
                value={yieldPerAcre && yieldPerAcre.toFixed(2)}
              />,
              growerYieldPerAcre && `${growerYieldPerAcre.toFixed(2)} ${getYieldUnitDisplay(yieldUnit)} / ac`,
              costPerAcre && `${dollarFormat(costPerAcre)} / ac`,
              hasMarketedCrop ? (
                revenuePerAcre && `${dollarFormat(revenuePerAcre)} / ac`
              ) : (
                <Button className={css(styles.normalFont)} color="primary" link onClick={() => setSelectedField(field)}>
                  Organize crop
                </Button>
              ),
              profitPerAcre && `${getFinanceNotation(profitPerAcre)} / ac`,
            ],
    })
  );

  const header = {
    id: "fieldsHeader",
    label: "field",
    values:
      viewType === "mobile"
        ? ["grower yield", "p / l"]
        : ["crop area", "total yield", "grower yield", "cost", "revenue", "profit / loss"],
  };

  const tableAction = (
    <Button className={css(styles.normalFont)} color="primary" href="#marketing" link>
      {viewType === "mobile" ? "Organize crops" : "Organize your crops in Marketing"}
    </Button>
  );

  return (
    <Fragment>
      <SummaryTable
        action={tableAction}
        data={data}
        header={header}
        onSort={handleSort}
        sortCriteria={criteria}
        title="Fields summary"
      />
      {selectedField && (
        <OrganizeCropModal
          commodityId={commodityId}
          field={selectedField}
          onClose={() => setSelectedField(null)}
          year={cropYear}
        />
      )}
    </Fragment>
  );
};

FieldsSummaryTable.propTypes = {
  commodity: PropTypes.shape({
    customaryUnit: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  cropYear: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  fieldCrops: PropTypes.arrayOf(
    PropTypes.shape({
      acreage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      costPerAcre: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      field: PropTypes.object,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      profitPerAcre: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      revenuePerAcre: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      yieldPerAcre: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      yieldUnit: PropTypes.string,
    })
  ),
  hasMarketedCrop: PropTypes.bool,
  viewType: PropTypes.oneOf(["desktop", "mobile"]),
};

FieldsSummaryTable.defaultProps = {
  fieldCrops: [],
};

export default withViewType(withEditCropYield(FieldsSummaryTable));
