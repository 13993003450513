import { useCashContractForm } from "contracts/form/CashContractForm";
import useContractPermissions from "contracts/hooks/useContractPermissions";
import useContractsIndexPage from "contracts/hooks/useContractsIndexPage";
import PropTypes from "prop-types";
import React from "react";

import { Button } from "components/fl-ui";
import ModalActionButtonGroup from "components/fl-ui/Modal/ModalActionButtonGroup";

const CashContractFormActions = ({ clusterActions = false, formId, onCancel, onDelete = () => {} }) => {
  const { contract, isEdit, isValid } = useCashContractForm();
  const { canDelete, canWrite } = useContractPermissions();
  const isDeletable = canDelete(contract || {}) && isEdit;
  const { deleteContract } = useContractsIndexPage();

  const handleDelete = async () => {
    return deleteContract(contract.id).then(() => onDelete());
  };

  return !canWrite() ? null : (
    <ModalActionButtonGroup variant={clusterActions ? "clustered" : "spread"}>
      <Button color="primary" disabled={!isValid()} form={formId} type="submit">
        Save {!isEdit && "& Add Pricing"}
      </Button>

      <Button color="default" onClick={onCancel} type="button">
        Cancel
      </Button>

      {isDeletable && (
        <Button color="danger" link onClick={handleDelete} type="button">
          Delete contract
        </Button>
      )}
    </ModalActionButtonGroup>
  );
};

CashContractFormActions.propTypes = {
  clusterActions: PropTypes.bool,
  formId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
};

export default CashContractFormActions;
