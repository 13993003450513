import buildCashContractInput from "./buildCashContractInput";
import { useMutation } from "@apollo/client";
import React from "react";

import { EDIT_CASH_CONTRACT } from "collection/graphql/marketing";

const useEditCashContract = (options = {}) => {
  const save = useMutation(EDIT_CASH_CONTRACT, options)[0];

  return (contract, options = {}) => {
    const input = buildCashContractInput(contract);
    return save({
      variables: {
        id: contract.id,
        input,
      },
      ...options,
    });
  };
};

export default useEditCashContract;
