import webDescriptorSchema, {
  cancellationWebDescriptorSchema,
} from "collection/graphql/subscription/schemas/webDescriptorSchema";

/**
 * Converts an `OrderDescriptor` into a `SubscriptionInput` object.
 * @param {OrderDescriptor} orderDescriptor
 * @return {SubscriptionInput}
 */
export default (orderDescriptor) => {
  const { billingInterval, cancelAtPeriodEnd, coupon, productIds, stripeToken } =
    webDescriptorSchema.cast(orderDescriptor);

  if (cancellationWebDescriptorSchema.isValidSync(orderDescriptor)) {
    /** @type {SubscriptionInput} */
    return {
      cancelAtPeriodEnd,
      products: productIds.map((id) => ({ id })),
    };
  }

  /** @type {SubscriptionInput} */
  const webSubscriptionOrder = {
    products: productIds.map((id) => ({ id })),
  };

  webSubscriptionOrder.billingInterval = billingInterval;
  if (coupon) {
    webSubscriptionOrder.coupon = coupon.coupon_id;
  }
  if (stripeToken) {
    webSubscriptionOrder.stripeToken = stripeToken.id;
  }

  return webSubscriptionOrder;
};
