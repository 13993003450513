import ReportParams from "./ReportParams";
import _ from "lodash";
import React, { useState } from "react";

import { Button } from "components/fl-ui";
import { Form } from "components/fl-ui/Form";

/**
 * The report sidebar is responsible for displaying and storing all the current
 * parameters (aka filters) that are present on the report and passing
 * the up to the parent to fetch data based on the current values.
 */

const formatParamValues = (parameters) => {
  const formattedParamValues = {};
  parameters.forEach((param) => {
    formattedParamValues[param.id] = param.default;
  });
  return formattedParamValues;
};

const ReportSidebar = ({ definition: { columns, parameters }, onDataSourceChange }) => {
  const [highlightReload, setHighlightReload] = useState(false);
  const [paramValues, setParamValues] = useState(() => formatParamValues(parameters));

  const handleChange = () => {
    setHighlightReload(true);
  };

  const handleSubmit = () => {
    const nonEmptyParams = {};
    Object.keys(paramValues).forEach((key) => {
      const paramValue = paramValues[key];
      if (paramValue) {
        nonEmptyParams[key] = _.isArray(paramValue) ? paramValue.join(",") : paramValue;
      }
    });

    onDataSourceChange({ ...nonEmptyParams });

    setHighlightReload(false);
  };

  const handleParamChange = ({ id, value }) => {
    setParamValues((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
    handleChange();
  };

  return (
    <div>
      <Form id="report-parameters" onSubmit={handleSubmit}>
        <ReportParams columns={columns} onParamChanged={handleParamChange} params={parameters} />

        <Button
          color={highlightReload ? "primary" : undefined}
          display="block"
          onClick={handleSubmit}
          style={{ marginTop: "2rem" }}
        >
          Load report
        </Button>
      </Form>
    </div>
  );
};

export default ReportSidebar;
