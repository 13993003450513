import PropTypes from "prop-types";
import React from "react";

import { GreyColors } from "components/fl-ui/colors";

const CloseX = (props) => {
  const { color, ...rest } = props;
  const svgAttrs = {
    ...rest,
    "data-cy": "closeX",
    style: {
      cursor: "pointer",
      height: "24px",
      width: "24px",
    },
    viewBox: "0 0 24 24",
  };

  return (
    <svg {...svgAttrs}>
      <g fill="none" fillRule="evenodd" stroke={color} strokeLinecap="square" strokeWidth="2">
        <path d="M6 6L18 18M18 6L6 18" />
      </g>
    </svg>
  );
};

CloseX.propTypes = {
  className: PropTypes.any,
  color: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

CloseX.defaultProps = {
  color: GreyColors.smoke40,
};

export default CloseX;
