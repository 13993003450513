import { calculateDateRange, calculateDateRangeForYears } from "agreements/utils";
import _ from "lodash";
import moment from "moment";

import utils from "lib/validation/utils";

const isValid = (fields, options = {}) => {
  return _.isEmpty(
    validate(fields, {
      errors: {},
      context: {},
      ...options,
    })
  );
};

const isPositiveNumber = (value) => value > 0 && utils.isDecimal(value, { decimal_digits: "1,2" });

const validate = (fields, options = {}) => {
  const errors = options.errors || {};
  const context = options.context || {};
  const { amount, amountRate, endDate, startDate } = fields;
  const { paymentDay, paymentInterval, paymentMonth, paymentPeriod } = fields;

  // amount
  if (!isPositiveNumber(amount)) {
    errors.amount = { value: amount };
  }

  // amountRate
  if (!["PER_ACRE", "TOTAL"].includes(amountRate)) {
    errors.amountRate = { value: amountRate };
  }

  let range;
  try {
    if (context.agreementType === "LEASE") {
      range = calculateDateRangeForYears(context.startYear, context.endYear);
    } else {
      range = calculateDateRange(context.startDate, context.termLength, context.termUnit);
    }

    // startDate
    if (!startDate || !moment(startDate).isValid()) {
      errors.startDate = { value: startDate };
    } else if (range) {
      if (!moment(startDate).isSameOrAfter(range.startDate, "day")) {
        errors.startDate = { value: startDate, range };
      } else if (moment(startDate).isAfter(range.endDate, "day")) {
        errors.startDate = { value: startDate, range };
      }
    }

    // endDate
    if (!endDate || !moment(endDate).isValid()) {
      errors.endDate = { value: endDate };
    } else if (range) {
      if (!moment(endDate).isSameOrBefore(range.endDate, "day")) {
        errors.endDate = { value: endDate, range };
      } else if (!errors.startDate && !moment(endDate).isAfter(startDate, "day")) {
        errors.endDate = { value: endDate, range };
      }
    }
  } catch (error) {}

  // paymentInterval
  if (!utils.isInt(paymentInterval, { min: 1 })) {
    errors.paymentInterval = { value: paymentInterval };
  }

  // paymentPeriod
  if (!["MONTH", "YEAR"].includes(paymentPeriod)) {
    errors.paymentPeriod = { value: paymentPeriod };
  }

  if (paymentPeriod === "YEAR") {
    // paymentMonth
    if (!_.range(1, 13).includes(paymentMonth)) {
      errors.paymentMonth = { value: paymentMonth };
    }
  }

  // paymentDay
  if (!errors.paymentPeriod && !errors.paymentMonth) {
    if (paymentPeriod === "MONTH") {
      if (!_.range(1, 32).includes(paymentDay)) {
        errors.paymentDay = { value: paymentDay };
      }
    } else {
      const date = moment("2019-01", "YYYY-MM").month(paymentMonth - 1);
      if (!utils.isInt(paymentDay, { min: 1, max: date.daysInMonth() })) {
        errors.paymentDay = { value: paymentDay };
      }
    }
  }

  return errors;
};

export default {
  isValid,
  validate,
};
