import PropTypes from "prop-types";
import React from "react";

import Search from "components/fl-ui/Search";

const ContractNumberSearch = ({ isMobile, onChange, value }) => (
  <Search
    autofocus={!isMobile}
    onChange={onChange}
    placeholder="Search by Contract #"
    tooltip="Please use search to find contracts and filter to refine the search results."
    value={value}
  />
);

ContractNumberSearch.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default ContractNumberSearch;
