import { restClient } from "collection/graphql/client";
import { getCurrentSubscription } from "collection/graphql/subscription/index";

export const getNotificationTags = async (currentUser) => {
  const subscriptionTags = await getSubscriptionTags();

  return {
    email: currentUser.email,
    external_user_id: currentUser.id,
    fl_user_id: currentUser.id,
    user_enterprise_id: currentUser.enterpriseId,
    ...subscriptionTags,
  };
};

const getSubscriptionTags = async () => {
  const { data } = await restClient.query({ query: getCurrentSubscription });
  const subscription = data?.subscription;

  // If user is not subscribed in any way, do not return subscription tags
  // This also persists cancelled users tags indefinitely so we can identify/re-engage them
  if (!subscription?.isSubscribed) {
    return null;
  }

  return {
    subscription_type: subscription?.currentPlan?.name,
    subscription_is_trialing: subscription?.isTrialing,
    subscription_is_cancelled: subscription?.cancelAtPeriodEnd,
    subscription_date_start: subscription?.currentPlan?.periodStart,
    subscription_date_end: subscription?.currentPlan?.periodEnd,
    subscription_source: subscription?.currentPlan?.source,
  };
};
