import ContractFormContext from "./context/ContractFormContext";
import { DatePicker } from "@farmlogs/fl-ui";
import { css } from "aphrodite/no-important";
import ContractStatusDropdown from "contracts/form/components/ContractStatusDropdown";
import ContractSubtypeDropdown from "contracts/form/components/ContractSubtypeDropdown";
import UnderlyingDropdown from "contracts/form/components/UnderlyingDropdown";
import _ from "lodash";
import { AUTOMATIC, BASIS, MIN_MAX, MIN_MAX_AUTOMATIC } from "marketing/constants";
import ContractDetailsDeliveryOrExpirationField from "marketing/forms/ContractDetails/ContractDetailsDeliveryOrExpirationField";
import ContractDetailsExtraFields from "marketing/forms/ContractDetails/ContractDetailsExtraFields";
import ContractDetailsExtraFieldsNewRow from "marketing/forms/ContractDetails/ContractDetailsExtraFieldsNewRow";
import ContractDetailsSidePanel from "marketing/forms/ContractDetails/ContractDetailsSidePanel";
import { contractsStyles } from "marketing/styles";
import { CASH_CONTRACT, FUTURES_CONTRACT, OPTIONS_CONTRACT, OPEN, CLOSED } from "marketing/utils/contractEnums";
import { exists, isNumber, isInteger, isPositive } from "marketing/utils/validators";
import moment from "moment";
import React from "react";
import autoBind from "react-autobind";
import { Col, Row } from "react-styled-flexboxgrid";

import { FormGroup, Input } from "components/fl-ui/Form";
import ContractUnit from "components/units/ContractUnit";

class ContractDetailsForm extends React.Component {
  static contextType = ContractFormContext;

  constructor(props) {
    super(props);

    this.state = {
      contractStatus: OPEN,
      ...props.contract,
    };

    autoBind(this);
  }

  componentDidMount() {
    !this.state.contractDate && this.handleContractDateChange(moment().format("YYYY-MM-DD"));
  }

  handleContractDateChange(date) {
    this.props.handleContractChange({
      contractDate: date,
    });
  }

  handleContractPricingWindowStartChange(date) {
    this.props.handleContractChange({
      pricingWindowStart: date,
    });
  }

  handleContractPricingWindowEndChange(date) {
    this.props.handleContractChange({
      pricingWindowEnd: date,
    });
  }

  handleContractSubtypeChange(contractSubtype) {
    this.props.handleContractChange({
      contractSubtype,
    });
  }

  handleDeliveryStartDateChange(date) {
    this.props.handleContractChange({
      deliveryStartDate: date,
    });
  }

  handleContractStatusChange(contractStatus) {
    this.props.handleContractChange({
      contractStatus,
    });
  }

  handleQuantityChange(e) {
    const val = e.target.value;
    switch (this.props.getContractTypeEnum()) {
      case CASH_CONTRACT:
        if ((isNumber(val) && isPositive(val)) || !exists(val)) {
          this.props.handleContractChange({
            quantity: val,
          });
        }
        break;

      case FUTURES_CONTRACT:
      case OPTIONS_CONTRACT:
        if ((isInteger(val) && isPositive(val)) || !exists(val)) {
          this.props.handleContractChange({
            quantity: val,
          });
        }
        break;

      default:
        return this.props.handleContractChange({
          quantity: val,
        });
    }
  }

  handlePriceChange(e) {
    const val = e.target.value;
    if (isPositive(val) || !exists(val)) {
      let priceProperty = "contractPrice";

      switch (this.props.getContractTypeEnum()) {
        case OPTIONS_CONTRACT:
          priceProperty = "strikePrice";
          break;
        case CASH_CONTRACT:
          priceProperty = "cashContractPrice";
          break;
      }

      this.props.handleContractChange({
        [priceProperty]: val,
      });
    }
  }

  handleMinPriceChange(e) {
    const val = e.target.value;
    if (isPositive(val) || !exists(val)) {
      this.props.handleContractChange({
        ["minPrice"]: val,
      });
    }
  }

  handleMaxPriceChange(e) {
    const val = e.target.value;
    if (isPositive(val) || !exists(val)) {
      this.props.handleContractChange({
        ["maxPrice"]: val,
      });
    }
  }

  handleReferenceContractChange(underlying) {
    const extra = {};

    if (underlying) {
      switch (this.props.getContractTypeEnum()) {
        case FUTURES_CONTRACT:
          extra.expirationDate = underlying.futuresExpirationDate;
          break;

        case OPTIONS_CONTRACT:
          extra.expirationDate = underlying.optionsExpirationDate;
          break;

        default:
          extra.deliveryEndDate = underlying.expirationDate;
          extra.expirationDate = underlying.expirationDate;
      }
    }

    this.props.handleContractChange({
      underlying,
      ...extra,
    });
  }

  handleNotesChange(e) {
    this.props.handleContractChange({
      notes: e.target.value,
    });
  }

  getContractPriceLabel() {
    const { contract, marketedCrop } = this.props;
    const { contractSubtype } = contract;
    const { contractStatus } = this.state;
    if (this.props.getContractTypeEnum() === OPTIONS_CONTRACT) {
      return "Strike price";
    }

    if (contractSubtype === MIN_MAX || contractSubtype === MIN_MAX_AUTOMATIC || contractSubtype === AUTOMATIC) {
      return `Final avg. futures price ${contractStatus !== CLOSED ? "(optional)" : ""}`;
    }

    if (contractSubtype === BASIS) {
      return `Futures price ${contractStatus !== CLOSED ? "(optional)" : ""}`;
    }

    if (!marketedCrop.commodity.marketSymbol) {
      return "Price";
    }

    return "Futures price";
  }

  render() {
    const { contract, getContractTypeEnum, marketedCrop } = this.props;
    const { contractSubtype } = contract;
    const { commodity, underlyings } = marketedCrop;
    const hideReferenceContract = this.context.disableExchangePricing;
    const isCashContract = CASH_CONTRACT === getContractTypeEnum();
    const contractType = getContractTypeEnum();

    return (
      <form>
        <Row>
          <Col xs={12} md={7} lg={8}>
            <Row>
              <Col xs={12} sm={!hideReferenceContract ? 6 : 12}>
                <FormGroup label="Contract type" inputId="contract-type-selector">
                  <ContractSubtypeDropdown
                    id="contract-type-selector"
                    contractType={contractType}
                    onChange={this.handleContractSubtypeChange}
                    placeholder="Choose Type..."
                    value={contract.contractSubtype}
                  />
                </FormGroup>
              </Col>

              {!hideReferenceContract && (
                <Col xs={12} sm={6}>
                  <FormGroup label="Reference contract" inputId="ref-contract-selector">
                    <UnderlyingDropdown
                      id="ref-contract-selector"
                      onChange={(symbol) => this.handleReferenceContractChange(_.find(underlyings, { symbol }))}
                      placeholder="Choose Contract..."
                      underlyings={underlyings}
                      value={contract.underlying?.symbol}
                    />
                  </FormGroup>
                </Col>
              )}
            </Row>

            <Row>
              <Col xs={12} sm={6}>
                <FormGroup label="Status" inputId="contract-status">
                  <ContractStatusDropdown
                    id="contract-status"
                    onChange={this.handleContractStatusChange}
                    value={contract.contractStatus}
                  />
                </FormGroup>
              </Col>

              {!isCashContract && (
                <Col xs={12} sm={6}>
                  <ContractDetailsDeliveryOrExpirationField
                    contract={contract}
                    getContractTypeEnum={this.props.getContractTypeEnum}
                    handleContractChange={this.props.handleContractChange}
                  />
                </Col>
              )}
            </Row>

            {isCashContract && (
              <Row>
                {contractSubtype !== "SPOT" && (
                  <Col xs={12} sm={6}>
                    <FormGroup label="Delivery Start Date (optional)" inputId="delivery-start-date">
                      <DatePicker
                        inputId="delivery-start-date"
                        onChange={this.handleDeliveryStartDateChange}
                        value={contract.deliveryStartDate}
                      />
                      <div className={css(contractsStyles.helpText)}>
                        The first date that you can deliver the commodity.
                      </div>
                    </FormGroup>
                  </Col>
                )}

                <Col xs={12} sm={6}>
                  <ContractDetailsDeliveryOrExpirationField
                    contract={contract}
                    getContractTypeEnum={this.props.getContractTypeEnum}
                    handleContractChange={this.props.handleContractChange}
                  />
                </Col>
              </Row>
            )}

            {(contractSubtype === MIN_MAX_AUTOMATIC || contractSubtype === AUTOMATIC) && (
              <Row>
                <Col xs>
                  <FormGroup label="Pricing window">
                    <Row>
                      <Col xs={12} sm={5}>
                        <DatePicker
                          inputId="pricing-window-start"
                          onChange={this.handleContractPricingWindowStartChange}
                          value={contract.pricingWindowStart}
                        />
                      </Col>

                      <Col xs={12} sm={2} className={css(contractsStyles.centerAlign)}>
                        <p className={css(contractsStyles.formText)}>through</p>
                      </Col>

                      <Col xs={12} sm={5}>
                        <DatePicker
                          inputId="pricing-window-end"
                          min={contract.pricingWindowStart}
                          max={contract.expirationDate}
                          onChange={this.handleContractPricingWindowEndChange}
                          value={contract.pricingWindowEnd}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
              </Row>
            )}

            <Row>
              <Col xs={12} sm={6}>
                <FormGroup label="Booked date" inputId="contract-date">
                  <DatePicker
                    inputId="contract-date"
                    onChange={this.handleContractDateChange}
                    value={contract.contractDate}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={6}>
                <FormGroup label="Quantity" inputId="quantity-field">
                  <Input
                    id="quantity-field"
                    onChange={this.handleQuantityChange}
                    type="text"
                    display="block"
                    size="large"
                    value={contract.quantity || ""}
                    placeholder="0"
                    suffix={<ContractUnit contract={contract} />}
                  />
                </FormGroup>
              </Col>
            </Row>
            {(contractSubtype === MIN_MAX || contractSubtype === MIN_MAX_AUTOMATIC) && (
              <Row>
                <Col xs={12} sm={6}>
                  <FormGroup label="Min price" inputId="contract-min-price-field">
                    <Input
                      id="contract-min-price-field"
                      onChange={this.handleMinPriceChange}
                      type="text"
                      display="block"
                      size="large"
                      name="yield_target"
                      value={contract.minPrice || ""}
                      placeholder="0.00"
                      prefix="$"
                      suffix={<ContractUnit contract={contract} per="unit" />}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6}>
                  <FormGroup label="Max price" inputId="contract-max-price-field">
                    <Input
                      id="contract-max-price-field"
                      onChange={this.handleMaxPriceChange}
                      type="text"
                      display="block"
                      size="large"
                      name="yield_target"
                      value={contract.maxPrice || ""}
                      placeholder="0.00"
                      prefix="$"
                      suffix={<ContractUnit contract={contract} per="unit" />}
                    />
                  </FormGroup>
                </Col>
              </Row>
            )}
            <Row>
              <Col xs={12} sm={!hideReferenceContract ? 6 : 12}>
                <FormGroup label={this.getContractPriceLabel()} inputId="contract-price-field">
                  <Input
                    id="contract-price-field"
                    onChange={this.handlePriceChange}
                    type="text"
                    display="block"
                    size="large"
                    name="yield_target"
                    value={contract.contractPrice || contract.strikePrice || ""}
                    placeholder="0.00"
                    prefix="$"
                    suffix={<ContractUnit contract={contract} per="unit" />}
                  />
                  {(contractSubtype === MIN_MAX ||
                    contractSubtype === MIN_MAX_AUTOMATIC ||
                    contractSubtype === AUTOMATIC) && (
                    <div className={css(contractsStyles.helpText)}>The futures price at contract close.</div>
                  )}
                </FormGroup>
              </Col>
              <Col xs={12} sm={6}>
                <ContractDetailsExtraFields
                  data={contract}
                  contract={contract}
                  getContractTypeEnum={this.props.getContractTypeEnum}
                  handleContractChange={this.props.handleContractChange}
                  marketedCrop={marketedCrop}
                />
              </Col>
            </Row>
            <ContractDetailsExtraFieldsNewRow
              commodity={commodity}
              contract={contract}
              handleContractChange={this.props.handleContractChange}
              getContractTypeEnum={this.props.getContractTypeEnum}
            />
            <Row>
              <Col xs={12}>
                <FormGroup label="Your notes (optional)" inputId="notes-field">
                  <textarea
                    id="notes-field"
                    onChange={this.handleNotesChange}
                    value={contract.notes || ""}
                    className={css(contractsStyles.textArea)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={5} lg={4}>
            <ContractDetailsSidePanel
              data={contract}
              contract={contract}
              getContractTypeEnum={this.props.getContractTypeEnum}
              marketedCrop={marketedCrop}
            />
          </Col>
        </Row>
      </form>
    );
  }
}

export default ContractDetailsForm;
