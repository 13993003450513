import { ApolloClient, ApolloLink, createHttpLink } from "@apollo/client";

import cache from "collection/graphql/cache";
import { trackingCallback } from "collection/graphql/client/common";
import { authLink } from "collection/graphql/client/links/authLink";

const headers = {};
headers["X-Client-Version"] = process.env.CLIENT_VERSION || "dev";

export default new ApolloClient({
  cache,
  link: ApolloLink.from([
    trackingCallback,
    authLink,
    createHttpLink({
      credentials: "same-origin", // allow auth cookies to pass through
      headers,
      uri: "/v2.0/elevate-plus/graphql",
    }),
  ]),
});
