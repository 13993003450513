import ContractFormContext from "./context/ContractFormContext";
import contractFormValidator from "marketing/forms/ContractDetails/validation/contractFormValidator";
import contractEnums from "marketing/utils/contractEnums";
import PropTypes from "prop-types";
import React from "react";
import router from "router";

import buildCashContractInput from "collection/graphql/contracts/hooks/buildCashContractInput";
import buildFuturesContractInput from "collection/graphql/contracts/hooks/buildFuturesContractInput";
import buildOptionsContractInput from "collection/graphql/contracts/hooks/buildOptionsContractInput";
import useDeleteContract from "collection/graphql/contracts/hooks/useDeleteContract";
import {
  withCreateCashContract,
  withCreateFuturesContract,
  withCreateOptionsContract,
  withEditCashContract,
  withEditFuturesContract,
  withEditOptionsContract,
} from "collection/graphql/marketing";
import App from "layout/app";
import {
  MARKETING_MODAL_CASH_SAVE,
  MARKETING_MODAL_CASH_DELETE,
  MARKETING_MODAL_CASH_CANCEL,
  MARKETING_MODAL_FUTURES_SAVE,
  MARKETING_MODAL_FUTURES_DELETE,
  MARKETING_MODAL_FUTURES_CANCEL,
  MARKETING_MODAL_OPTIONS_SAVE,
  MARKETING_MODAL_OPTIONS_DELETE,
  MARKETING_MODAL_OPTIONS_CANCEL,
} from "lib/metrics/events";

import { Button } from "components/fl-ui";

const { ADD_CONTRACT, EDIT_CONTRACT, CASH_CONTRACT, FUTURES_CONTRACT, OPTIONS_CONTRACT } = contractEnums;

class ContractDetailsFooter extends React.Component {
  static contextType = ContractFormContext;

  getContractTypeEnum = () => {
    switch (this.props.contractType) {
      case "cash":
        return CASH_CONTRACT;
      case "futures":
        return FUTURES_CONTRACT;
      case "options":
        return OPTIONS_CONTRACT;
      default:
        return CASH_CONTRACT;
    }
  };

  prepareForSave = () => {
    const { contract, contractType, cropId, year } = this.props;
    const contractData = { ...contract, marketedCropId: cropId };
    const trackingData = this.getTrackingData(contract);
    let input, trackingCallback;

    switch (this.getContractTypeEnum()) {
      case CASH_CONTRACT:
        if (!isNaN(parseFloat(contract.basis))) {
          contractData.basis = contract.basis;
        }
        input = buildCashContractInput(contractData);
        trackingCallback = () => MARKETING_MODAL_CASH_SAVE.track(trackingData);
        break;

      case FUTURES_CONTRACT:
        input = buildFuturesContractInput(contractData);
        trackingCallback = () => MARKETING_MODAL_FUTURES_SAVE.track(trackingData);
        break;

      case OPTIONS_CONTRACT:
        input = buildOptionsContractInput(contractData);
        trackingCallback = () => MARKETING_MODAL_OPTIONS_SAVE.track(trackingData);
        break;
    }

    let successUrl = `marketing/${year}/${cropId}/contracts`;
    if (contract.id) {
      successUrl += `/${contractType}/${contract.id}`;
    }

    return {
      input,
      successUrl,
      trackingCallback,
    };
  };

  handleAddContract = () => {
    const { createCashContract, createFuturesContract, createOptionsContract, handleIsLoading } = this.props;
    const { input, successUrl, trackingCallback } = this.prepareForSave();

    handleIsLoading(true);

    const createAction = {
      [CASH_CONTRACT]: createCashContract,
      [FUTURES_CONTRACT]: createFuturesContract,
      [OPTIONS_CONTRACT]: createOptionsContract,
    }[this.getContractTypeEnum()];

    trackingCallback();
    return createAction(input)
      .then((res) => {
        handleIsLoading(false);
        if (res && res.data) {
          router.navigate(successUrl, { trigger: true });
        }
      })
      .catch(() => handleIsLoading(false));
  };

  handleEditContract = () => {
    const { contract, editCashContract, editFuturesContract, editOptionsContract, handleIsLoading } = this.props;
    const { input, successUrl, trackingCallback } = this.prepareForSave();
    const contractType = this.getContractTypeEnum();

    handleIsLoading(true);

    const editAction = {
      [CASH_CONTRACT]: editCashContract,
      [FUTURES_CONTRACT]: editFuturesContract,
      [OPTIONS_CONTRACT]: editOptionsContract,
    }[contractType];

    trackingCallback();
    return editAction({ id: contract.id, input })
      .then((res) => {
        handleIsLoading(false);
        if (res && res.data) {
          router.navigate(successUrl, { trigger: true });
        }
      })
      .catch(() => handleIsLoading(false));
  };

  handleDeleteContract = () => {
    const { contract, cropId, deleteContract, handleIsLoading, year } = this.props;

    return App.confirm({
      message: "This will delete this contract and all of its associated data.",
      cancel: "Cancel",
      confirm: "Delete",
      warning: true,
    }).then(async () => {
      const data = {
        contract_id: this.props.contract.id,
        commodity_id: this.props.commodityId,
        commodity_year: this.props.year,
      };
      switch (this.getContractTypeEnum()) {
        case CASH_CONTRACT:
          MARKETING_MODAL_CASH_DELETE.track(data);
          break;
        case FUTURES_CONTRACT:
          MARKETING_MODAL_FUTURES_DELETE.track(data);
          break;
        case OPTIONS_CONTRACT:
          MARKETING_MODAL_OPTIONS_DELETE.track(data);
          break;
      }

      return deleteContract(contract.id)
        .then((res) => {
          handleIsLoading(false);
          router.navigate(`marketing/${year}/${cropId}/contracts`, { trigger: true });
        })
        .catch(() => handleIsLoading(false));
    });
  };

  handleCancel = () => {
    const { year, cropId, contract, contractType } = this.props;
    const contractRoute = contract.id ? `/${contractType}/${contract.id}` : "";

    switch (this.getContractTypeEnum()) {
      case CASH_CONTRACT:
        MARKETING_MODAL_CASH_CANCEL.track();
        break;
      case FUTURES_CONTRACT:
        MARKETING_MODAL_FUTURES_CANCEL.track();
        break;
      case OPTIONS_CONTRACT:
        MARKETING_MODAL_OPTIONS_CANCEL.track();
        break;
    }
    router.navigate(`marketing/${year}/${cropId}/contracts${contractRoute}`, {
      trigger: true,
    });
  };

  getTrackingData = (input) => {
    const basicAttrs = {
      commodity_year: this.props.year,
    };

    const basicContractAttrs = input
      ? {
          contract_id: input.id,
          contract_status: input.contractStatus,
          contract_date: input.contractDate,
          contract_quantity: input.quantity,
          contract_basis: input.basis,
          reference_contract: input.underlying ? input.underlying.symbol : null,
        }
      : {};

    return {
      ...basicAttrs,
      ...basicContractAttrs,
    };
  };

  getErrors = () => {
    const { errors } = contractFormValidator.validateSync(this.props.contract, {
      contractType: this.getContractTypeEnum(this.props.contractType),
      disableExchangePricing: this.context.disableExchangePricing,
    });

    return errors;
  };

  hasErrors = () => {
    return !!Object.values(this.getErrors()).filter((e) => e).length;
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "0 auto",
        }}
      >
        {this.props.action === EDIT_CONTRACT ? (
          <a
            className="red-link float-left"
            disabled={this.props.contract.isLoading}
            id="delete-contract-button"
            onClick={() => this.handleDeleteContract()}
          >
            <b>Delete contract</b>
          </a>
        ) : (
          <div />
        )}
        <span className="gray-text" style={{ padding: "7px", display: "block" }}>
          <div className="footer-actions-wrapper">
            <Button
              className="cancel-button"
              id="cancel-button"
              onClick={this.handleCancel}
              style={{ marginRight: "0.75em" }}
            >
              Cancel
            </Button>
            {this.props.action === ADD_CONTRACT ? (
              <Button
                color="primary"
                disabled={this.hasErrors() || this.props.contract.isLoading}
                id="done-button"
                onClick={() => this.handleAddContract()}
              >
                Save
              </Button>
            ) : (
              <Button
                color="primary"
                disabled={this.hasErrors() || this.props.contract.isLoading}
                id="done-button"
                onClick={() => this.handleEditContract()}
              >
                Save
              </Button>
            )}
          </div>
        </span>
      </div>
    );
  }
}

ContractDetailsFooter.propTypes = {
  cropId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  action: PropTypes.string.isRequired,
  contractType: PropTypes.string.isRequired,
  contract: PropTypes.object,
  handleIsLoading: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default withEditCashContract(
  withEditFuturesContract(
    withEditOptionsContract(
      withCreateOptionsContract(
        withCreateFuturesContract(
          withCreateCashContract((props) => {
            return <ContractDetailsFooter {...props} deleteContract={useDeleteContract()} />;
          })
        )
      )
    )
  )
);
