import { TRACTOR } from "../constants";
import isLocalIcon from "../lib/isLocalIcon";
import { fileClient } from "@farmlogs/fl-ui";
import React, { useEffect, useMemo, useState } from "react";

const DEFAULT_IMAGE = "unknown.png";
const getSystemImagePath = (fileName, type) =>
  type === TRACTOR ? `/images/resources/tractors/${fileName}` : `/images/resources/implements/${fileName}`;

const useEquipmentImage = (type, imageIdentifier, size) => {
  const [path, setPath] = useState(() => getSystemImagePath(DEFAULT_IMAGE, type));
  const pathMap = useMemo(() => new Map(), []);
  const mapKey = [type, imageIdentifier].join("-");

  useEffect(() => {
    if (isLocalIcon(imageIdentifier)) {
      setPath(getSystemImagePath(imageIdentifier, type));
    } else if (pathMap.has(mapKey)) {
      return pathMap[mapKey];
    } else {
      try {
        fileClient.getMetadata(imageIdentifier).then(
          ({ mimeType }) => {
            if (mimeType.startsWith("image/")) {
              setPath(
                fileClient.getImagePreviewUrl(imageIdentifier, {
                  height: size,
                  width: size,
                })
              );
            } else {
              setPath(getSystemImagePath(DEFAULT_IMAGE, type));
            }
          },
          () => setPath(getSystemImagePath(DEFAULT_IMAGE, type))
        );
      } catch (error) {
        setPath(getSystemImagePath(DEFAULT_IMAGE, type));
      }
    }
  }, [imageIdentifier]);

  return path;
};

export default useEquipmentImage;
