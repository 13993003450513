import { useDemoContext } from "demo/demos/MobileSubscriptionsDemo/MobileSubscriptionsDemoContext";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useLayoutEffect, useMemo, useRef } from "react";

const LogViewer = ({ autoScroll, className }) => {
  const { log } = useDemoContext();
  const ref = useRef();
  useLayoutEffect(() => {
    if (autoScroll) {
      ref.current.scrollTop = 0;
    }
  }, [log]);

  const logValue = useMemo(() => {
    return log
      .map(({ logLevel, message, timestamp }) => {
        return `${moment(timestamp).format("YYYY-MM-DD hh:mm:ss.SS")} – ${logLevel}: ${message}`;
      })
      .join("\n\n");
  }, [log]);

  return <textarea className={className} placeholder="RevenueCat logs" ref={ref} value={logValue} />;
};

LogViewer.propTypes = {
  autoScroll: PropTypes.bool,
  className: PropTypes.string,
};

export default LogViewer;
