import eventFactory from "lib/metrics/event_factory";

export const BIN_DETAIL_DELETE = eventFactory("Delete", {
  context: "Bin Detail",
  entity: "Bin",
});

export const BIN_DETAIL_MODAL_CANCEL = eventFactory("Modal Cancel", {
  context: "Bin Detail",
  modal_name: "Bin Edit",
});

export const BIN_DETAIL_MODAL_OPEN_BIN_EDIT = eventFactory("Bin: Edit Modal Open", {
  context: "Bin Detail",
  modal_name: "Bin Edit",
});

export const BIN_DETAIL_MODAL_OPEN_LOAD_EDIT = eventFactory("Bin: Load Modal Open", {
  context: "Bin Detail",
  modal_name: "Load Edit",
});

export const BIN_DETAIL_UPDATE_SAVE = eventFactory("Update Save", {
  context: "Bin Detail",
  entity: "Bin",
});

export const BIN_DETAIL_VIEW_FILTER_CHANGE = eventFactory("Bin: View Filter Change", {
  context: "Bin Detail",
  type: "Date",
  dynamic: ["current_begin_date", "current_end_date", "previous_begin_date", "previous_end_date"],
});
