import Dashboard from "./routes/Dashboard";

import App from "layout/app";
import SubRoute from "lib/SubRoute";

const NotificationsRouter = SubRoute.extend({
  routes: {
    "": "index",
  },

  index() {
    App.display(Dashboard, {
      screenName: "Alerts",
    });
  },
});

export default () => new NotificationsRouter("alerts", { createTrailingSlashRoutes: true });
