import { gql } from "@apollo/client";

import { FIELD_LAYER } from "collection/graphql/fields/fragments";

export default gql`
  query GetLatestSatelliteImages($fields: [Int], $imageCount: Int) {
    layers: getLatestSatelliteImages(fields: $fields, imageCount: $imageCount)
      @requiresPermission(feature: "imagery", actions: ["read"]) {
      ...FieldLayer
    }
  }
  ${FIELD_LAYER}
`;
