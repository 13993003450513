import _ from "lodash";

const HistoricalGDDAverageTypePolicy = {
  fields: {
    lookup: {
      read(__, { readField }) {
        const pad = (field) => _.padStart(readField(field), 2, "0");
        return [pad("month"), pad("day")].join("-");
      },
    },
  },
};

export default HistoricalGDDAverageTypePolicy;
