import ContractsModalClearButton from "contracts/components/specs/ContractsModalClearButton";
import { CONTRACTS_SORT_COLUMNS } from "contracts/constants/contractsSortColumns";
import useContractsIndexPage from "contracts/hooks/useContractsIndexPage";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";

import { Button } from "components/fl-ui";
import { Radio, RadioGroup } from "components/fl-ui/Form";
import { CloseX } from "components/fl-ui/Icons";
import { Cluster } from "components/fl-ui/Layout";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "components/fl-ui/Modal/Modal";

const radioOptions = [
  { label: "Buyer (A to Z)", orderBy: CONTRACTS_SORT_COLUMNS.buyer, sortDirection: "asc", value: "1" },
  { label: "Buyer (Z to A)", orderBy: CONTRACTS_SORT_COLUMNS.buyer, sortDirection: "desc", value: "2" },
  { label: "Commodity (A to Z)", orderBy: CONTRACTS_SORT_COLUMNS.commodity, sortDirection: "asc", value: "3" },
  { label: "Commodity (Z to A)", orderBy: CONTRACTS_SORT_COLUMNS.commodity, sortDirection: "desc", value: "4" },
  { label: "Delivery Date (New to Old)", orderBy: CONTRACTS_SORT_COLUMNS.delivery, sortDirection: "asc", value: "5" },
  { label: "Delivery Date (Old to New)", orderBy: CONTRACTS_SORT_COLUMNS.delivery, sortDirection: "desc", value: "6" },
];

const ContractSortModal = ({ onClose, sortIsActive }) => {
  const [selectedOptionID, setSelectedOptionID] = useState(1);
  const { currentSortColumn, currentSortDirection, clearSort, setSort } = useContractsIndexPage();

  useMemo(() => {
    if (currentSortColumn) {
      const currentlyOrderedBy = radioOptions.find((radioOption) => {
        return (
          radioOption.orderBy === currentSortColumn && radioOption.sortDirection === (currentSortDirection || "asc")
        );
      });
      setSelectedOptionID(currentlyOrderedBy.value);
    }
  }, [currentSortColumn, currentSortDirection]);

  return (
    <Modal width={530}>
      <ModalHeader>
        <CloseX onClick={onClose} />
      </ModalHeader>

      <ModalBody>
        <h3>SORT BY</h3>

        <RadioGroup
          name="sort_options"
          onChange={({ name, value }) => {
            setSelectedOptionID(value);
          }}
        >
          {radioOptions.map(({ label, value }) => (
            <Radio checked={value === selectedOptionID} key={value} label={label} value={value} />
          ))}
        </RadioGroup>
      </ModalBody>

      <ModalFooter>
        <Cluster between>
          <div>
            {sortIsActive && (
              <ContractsModalClearButton
                text="Clear sort"
                onClick={() => {
                  clearSort();
                  onClose();
                }}
              />
            )}
          </div>

          <span>
            <Button color="default" onClick={onClose} type="button">
              Cancel
            </Button>{" "}
            <Button
              color="primary"
              onClick={() => {
                const selectedOption = radioOptions.find((radioOption) => radioOption.value === selectedOptionID);
                setSort(selectedOption.orderBy, selectedOption.sortDirection);
                onClose();
              }}
              type="button"
            >
              Apply
            </Button>
          </span>
        </Cluster>
      </ModalFooter>
    </Modal>
  );
};

ContractSortModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ContractSortModal;
