import themeGet from "@styled-system/theme-get";
import styled from "styled-components";

const DesktopMenuLabel = styled.div`
  background-color: #b8d7f9; // design-system: blue.100
  border-radius: 3px;
  color: #0b3c70; // design-system: blue.700
  font-size: ${themeGet("fontSizes.md")};
  font-weight: ${themeGet("fontWeights.bold")};
  margin-right: 4px;
  padding: 4px;
  text-transform: uppercase;
  transform: translateY(-1px);
`;

export default DesktopMenuLabel;
