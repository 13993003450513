import { gql } from "@apollo/client";

export default gql`
  mutation updateUserConfig($input: UserConfigInput!) {
    userConfig: updateUserConfig(input: $input) @rest(type: "UserConfig", path: "/config", method: "POST") {
      activitySortCriteria
      didOnboarding
      fieldSortCriteria
      fieldViewType
      mapBounds {
        center
        zoom
      }
    }
  }
`;
