import asAgreementForm from "agreements/forms/asAgreementForm";
import CommandButtonBar from "agreements/forms/components/CommandButtonBar";
import YearInput from "agreements/forms/components/YearInput";
import {
  AttachmentsFieldset,
  FieldsFieldset,
  LandlordInformationFieldset,
  LeaseTermsFieldset,
  NotesFieldset,
  PaymentScheduleFieldset,
} from "agreements/forms/fieldsets";
import { createLeaseTerm } from "agreements/forms/fieldsets/LeaseTermsFieldset/LeaseTermsFieldset";
import { Container, DoubleMicroLabel, LightText, StyledFormGroup, StyledMicroLabel } from "agreements/styledComponents";
import { prepareAgreementForSave } from "agreements/utils";
import leaseAgreementValidator from "agreements/validators/AgreementsForm/leaseAgreementValidator";
import PropTypes from "prop-types";
import React from "react";

import { withCreateLeaseAgreement, withUpdateLeaseAgreement } from "collection/graphql/agreements";

import { Cluster, Stack } from "components/fl-ui/Layout";

const LeaseAgreementForm = ({
  createLeaseAgreement,
  updateLeaseAgreement,
  fields,
  isValid,
  onChange,
  onCancel,
  onSaveSuccess,
  performDelete,
}) => {
  const cropYears = fields.endYear - fields.startYear;

  const handleLandlordChange = (changes) => {
    const landlord = {
      ...fields.landlord,
      ...changes,
    };
    onChange({ landlord });
  };

  const handleScheduleChange = ({ paymentSchedules }) => onChange({ paymentSchedules });

  const onSave = async () => {
    const agreement = prepareAgreementForSave(fields, "LEASE");
    if (agreement.id > 0) {
      await updateLeaseAgreement(agreement);
    } else {
      await createLeaseAgreement(agreement);
    }

    onSaveSuccess();
  };

  return (
    <Stack>
      <Container>
        <StyledFormGroup
          label={
            <Cluster between>
              <span>Lease duration</span>
              <LightText>
                {cropYears} crop {cropYears === 1 ? "year" : "years"}
              </LightText>
            </Cluster>
          }
        >
          <DoubleMicroLabel>
            <Cluster>
              <StyledMicroLabel label="Starting Year">
                <YearInput
                  name="startYear"
                  value={fields.startYear}
                  onChange={({ startYear }) => onChange({ startYear })}
                />
              </StyledMicroLabel>

              <StyledMicroLabel label="Ending Year">
                <YearInput name="endYear" value={fields.endYear} onChange={({ endYear }) => onChange({ endYear })} />
              </StyledMicroLabel>
            </Cluster>
          </DoubleMicroLabel>
        </StyledFormGroup>
      </Container>

      <FieldsFieldset onChange={onChange} fields={fields.fields} />
      <LandlordInformationFieldset onChange={handleLandlordChange} landlord={fields.landlord} />
      <LeaseTermsFieldset initialTerms={fields.terms} onChange={onChange} />
      <PaymentScheduleFieldset agreementType="LEASE" fields={fields} onChange={handleScheduleChange} />
      <NotesFieldset notes={fields.notes} onChange={onChange} />
      <AttachmentsFieldset attachments={fields.attachments} onChange={onChange} />
      <CommandButtonBar
        onSaveClick={onSave}
        disableSave={!isValid}
        onCancelClick={onCancel}
        onDeleteClick={fields.id ? () => performDelete(fields.id) : null}
      />
    </Stack>
  );
};

LeaseAgreementForm.propTypes = {
  agreement: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onSaveSuccess: PropTypes.func.isRequired,
};

LeaseAgreementForm.defaultProps = {
  agreement: {},
};

const getLeaseAgreementDefaults = () => ({
  fields: [],
  startYear: new Date().getFullYear(),
  endYear: new Date().getFullYear() + 1,
  landlord: {
    addressLine1: "",
    addressLine2: "",
    city: "",
    email: "",
    firstName: "",
    lastName: "",
    organization: "",
    postalCode: "",
    phone: "",
    state: "",
  },
  paymentSchedules: [],
  terms: [createLeaseTerm()],
});
export { getLeaseAgreementDefaults };

export default asAgreementForm(withCreateLeaseAgreement(withUpdateLeaseAgreement(LeaseAgreementForm)), {
  validator: leaseAgreementValidator,
});
