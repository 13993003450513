import { useMutation } from "@apollo/client";
import withActivityTypes from "activity/hoc/withActivityTypes";
import withPersonList from "activity/hoc/withPersonList";
import {
  formatActivityData,
  getCategoryOptions,
  prepareActivityForSave,
  prepareCropData,
  prepareForUpdate,
} from "activity/utils";
import activityFormValidator from "activity/validators/activityFormValidator";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { updateActivity as updateActivityQuery } from "collection/graphql/activities";
import { bulkActivityCreate } from "collection/graphql/activities/utils";
import withCropMutation from "hoc/withCropMutation";
import useCurrentCropYear from "hooks/useCurrentCropYear";
import App from "layout/app";
import { PLANNED } from "lib/constants";
import AddEditModal from "modules/activity/components/ActivityAddEditModal/AddEditModal";

const ActivityAddEditModal = (props) => {
  const [state, setState] = useState({
    bulkCreateAttributes: [],
    form: {},
    isSaving: false,
  });
  const updateState = (newState) =>
    setState({
      ...state,
      ...newState,
    });
  const { onClose, onSaveSuccess } = props;
  const { bulkCreateAttributes, form, isSaving } = state;

  const cropYear = useCurrentCropYear()[0];

  useEffect(() => {
    updateState(formatActivityData(props.activity, props.activityTypes, props.defaults));
  }, []);

  const { id, isWorkOrder, status, type } = form;
  const [updateActivity] = useMutation(updateActivityQuery);
  const updateFormValues = (fields) => updateState({ form: { ...form, ...fields } });

  const onSave = () => {
    return new Promise((resolve, reject) => {
      updateState({ isSaving: true });

      const activity = prepareActivityForSave(form);
      if (activity.id) {
        updateActivity({
          variables: { activity: prepareForUpdate(activity, bulkCreateAttributes[0]) },
          refetchQueries: ["getActivityById", "getFieldCropsAndActivities"],
        }).then((response) => {
          resolve({
            activity: response.data.updateActivity.activity,
            type: "update",
          });
        }, reject);
      } else {
        const cropData = prepareCropData(bulkCreateAttributes);
        bulkActivityCreate(activity, cropData).then((result) => {
          resolve({
            ...result,
            type: "create",
          });
        }, reject);
      }
    });
  };

  const onUpdateWaterInput = (input) => {
    const irrigation = { ...state.form.irrigation, ...input };
    updateFormValues({ irrigation });
  };

  const mode = id ? "edit" : "create";
  const category = type
    ? getCategoryOptions(props.activityTypes, props.activity?.type?.id).find(({ id }) => id === type)?.category
    : undefined;
  const isValid = activityFormValidator(form, bulkCreateAttributes);
  const disabled = isSaving || !isValid;

  const getButtonText = () => {
    if (isSaving) {
      return "Saving...";
    } else if (mode === "edit") {
      return "Update";
    }

    return isWorkOrder ? "Assign" : "Log activity";
  };

  const getModalTitle = () => {
    const action = getAction();
    const entity = isWorkOrder ? "Work" : "Activity";
    return `${action} ${cropYear} ${entity}`;
  };

  const getAction = () => {
    if (mode === "edit") {
      return "Edit";
    }
    return isWorkOrder ? "Assign" : "Log";
  };

  const getPeopleFieldLabel = () => {
    if (isWorkOrder) {
      return status === PLANNED ? "Assigned to" : "Completed by";
    }

    return status === PLANNED ? "Assigned to (optional)" : "Completed by (optional)";
  };

  const handleInputsChange = (inputs) => {
    updateState({
      form: {
        ...form,
        inputs,
      },
    });
  };

  const handleSave = async (shouldNotify) => {
    updateState({ isSaving: true });
    try {
      const result = await onSave();
      const { activities, errors, type } = result;
      if (type === "create") {
        if (activities.some(_.isNil)) {
          App.notify("An error occurred: one or more activities were not created successfully");

          if (activities.every(_.isNil)) {
            updateState({ isSaving: false });
            throw new Error(errors);
          }
        }
      }

      onSaveSuccess({
        ...result,
        shouldNotify,
      });
      onClose();
    } catch (error) {
      console.error(error);
      updateState({ isSaving: false });
    }
  };

  /* I think I can dramatically reduce the props here */

  return (
    <AddEditModal
      {...props}
      {...state}
      category={category}
      disabled={disabled}
      handleInputsChange={handleInputsChange}
      handleSave={handleSave}
      modalTitle={getModalTitle()}
      mode={mode}
      onUpdateWaterInput={onUpdateWaterInput}
      peopleFieldLabel={getPeopleFieldLabel()}
      primaryButtonText={getButtonText()}
      updateFormValues={updateFormValues}
      updateState={updateState}
    />
  );
};

ActivityAddEditModal.propTypes = {
  activity: PropTypes.object,
  defaults: PropTypes.object,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  onSaveSuccess: PropTypes.func,
};

ActivityAddEditModal.defaultProps = {
  onClose: () => {},
  onDelete: () => {},
  onSaveSuccess: () => {},
};

const withComposite = _.flowRight(withPersonList, withActivityTypes, withCropMutation);

export default withComposite(ActivityAddEditModal);
