import CheckedOption from "activity/components/choosers/options/CheckedOption";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import ReactSelect from "react-select";

import { UIColors } from "components/fl-ui/colors";

const customComponents = {
  IndicatorSeparator: () => null,
  Placeholder: () => null,
  Option: (props) => <CheckedOption {...props}>{props.data.label}</CheckedOption>,
};

const RateChooser = (props) => {
  const { hasError = false, onChange, onMenuClose = () => {}, options, value } = props;
  const selectedOption = _.find(options, { value }) ?? null;

  return (
    <ReactSelect
      components={customComponents}
      isSearchable={false}
      options={options}
      onMenuClose={onMenuClose}
      getOptionLabel={_.property("label")}
      getOptionValue={_.property("value")}
      onChange={({ value }) => onChange(value)}
      styles={{
        control: (base) => ({
          ...base,
          borderColor: hasError ? UIColors.danger : base.borderColor,
          borderWidth: hasError ? 2 : base.borderWidth,
          minHeight: 42,
        }),
      }}
      value={selectedOption}
    />
  );
};

RateChooser.propTypes = {
  hasError: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onMenuClose: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  value: PropTypes.string,
};

export default RateChooser;
