import { gql } from "@apollo/client";

export default gql`
  query getPurchaseIdsForProduct($products: [Int!], $year: Int!) {
    purchaseSummaries: getProductPurchaseSummary(products: $products, year: $year)
      @requiresPermission(feature: "inputs", actions: ["read"]) {
      product {
        ... on BaseProduct {
          id
        }
      }
      purchases {
        id
        vendor {
          name
        }
      }
    }
  }
`;
