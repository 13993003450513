import PropTypes from "prop-types";
import React from "react";

import useCropLayer from "hooks/useCropLayer";

import FieldMapImage, { withLazyRender } from "fields/components/FieldMapImage";

const CropMapImage = (props) => {
  const { className, commodity, crops, field, height, isLayerOnly, width } = props;
  const { layer, pattern } = useCropLayer({ commodity, crops, field, isLayerOnly });

  return (
    <FieldMapImage className={className} field={field} height={height} layer={layer} pattern={pattern} width={width} />
  );
};

CropMapImage.propTypes = {
  className: PropTypes.string,
  commodity: PropTypes.shape({
    color: PropTypes.string,
    id: PropTypes.number,
  }),
  crops: PropTypes.arrayOf(PropTypes.object).isRequired,
  field: PropTypes.shape({
    geometry: PropTypes.object,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  height: PropTypes.number,
  isLayerOnly: PropTypes.bool,
  width: PropTypes.number,
};

CropMapImage.defaultProps = {
  height: 48,
  isLayerOnly: true,
  width: 48,
};

export default withLazyRender(CropMapImage);
