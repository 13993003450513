import _ from "lodash";

import costsValidator from "modules/marketing/validators/CropForm/costsValidator";
import productionCostValidator from "modules/marketing/validators/CropForm/productionCostValidator";
import productionDetailsValidator from "modules/marketing/validators/CropForm/productionDetailsValidator";

const isValid = (fields, errors = {}) => _.isEmpty(validate(fields, { errors }));
const validate = (fields, options = {}) => {
  const errors = options.errors || {};
  const marketedCrops = options.marketedCrops || [];

  costsValidator.validate(fields, { errors });
  productionCostValidator.validate(fields, { errors });
  productionDetailsValidator.validate(fields, { errors, marketedCrops });
  return errors;
};

export default {
  isValid,
  validate,
};
