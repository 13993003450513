import moment from "moment";
import _ from "underscore";

const getTimeData = function (start, end) {
  const startDay = start.clone().startOf("day");
  const endDay = end.clone().startOf("day");

  return {
    start: {
      hour: start.format("ha"),
      day: start.format("ddd"),
      dayLong: start.format("dddd"),
      month: start.format("MMMM D"),
      daysAgo: moment().startOf("day").diff(startDay, "days"),
      monthsAgo: moment().startOf("day").diff(startDay, "months"),
    },
    end: {
      hour: end.format("ha"),
      day: end.format("ddd"),
      month: end.format("MMMM D"),
      monthLong: end.format("dddd, MMMM D"),
      daysAgo: moment().startOf("day").diff(endDay, "days"),
      monthsAgo: moment().startOf("day").diff(endDay, "months"),
    },
    duration: endDay.diff(startDay, "days"),
  };
};

const rainfallEvents = {
  badge({ end_time, start_time, state }) {
    const startDate = moment(start_time);
    const endDate = moment(end_time);
    if (!startDate.isValid() || !endDate.isValid()) {
      return "";
    }

    let day;
    const { start, end, duration } = getTimeData(startDate, endDate);
    const startHour = start.hour.replace("m", "");
    const endHour = end.hour.replace("m", "");

    switch (state) {
      // Events that may be continued or have ended within the past 6 hours
      case "recent":
      case "completed":
        if (duration < 2) {
          // Rainfall event started and ended today. Ex: "9a-9p"
          if (start.daysAgo === 0 && end.daysAgo === 0) {
            return `${startHour}-${endHour}`;
            // Event started yesterday and ended today. Ex: "Tues 9a-Today 9p"
          } else if (start.daysAgo > 0 && end.daysAgo === 0) {
            return `${start.day} ${startHour}-Today ${endHour}`;
            // Event started and ended yesterday. Ex: "Tues 9a-10p"
          } else if (start.daysAgo === 0 && end.daysAgo > 0) {
            return `${start.day} ${startHour}-${endHour}`;
            // Started the day before yesterday and ended yesterday. This is an edge case that
            // can be encountered when a rainfall event ends late at night and is checked early
            // in the morning before its duration is extended to 2 or it turns into a past event.
          } else if (start.daysAgo > 0 && end.daysAgo > 0) {
            day = start.daysAgo === end.daysAgo ? "" : `${end.day} `;
            return `${start.day} ${startHour}-${day}${endHour}`;
          }

          // For durations between 2 and 8 days, we only need to check the end date, as all start
          // dates will be formatted the same: "Mon 9a"
        } else if (duration < 8) {
          // Event ended today. Ex: "Mon 9a-Today 9a"
          if (end.daysAgo === 0) {
            return `${start.day} ${startHour}-Today ${endHour}`;
            // Event ended yesterday. Ex: "Mon 9a-Wed 9p"
          } else {
            return `${start.day} ${startHour}-${end.day} ${endHour}`;
          }
        } else {
          // Event ended today. Ex: "July 27-Today"
          if (end.daysAgo === 0) {
            return `${start.month}-Today`;
            // Event ended yesterday. Ex: "July 27-Aug 5"
          } else {
            return `${start.month}-${end.month}`;
          }
        }
        break;
      // Past events
      case "past":
        if (end.daysAgo > 365) {
          return "Last rain more than a year ago";
        } else if (end.daysAgo > 30) {
          return end.month;
        } else {
          return `${end.daysAgo}d ago`;
        }
      // Events with the 'none' state
      default:
        return "Last rain at least a month ago";
    }
  },

  // Generate the text to be displayed in the tooltip.
  // Similar to the time string below the badge, this is dependent
  // on both the state of the rainfall event and the times at which
  // the event occurred.
  tooltip({ amount, end_time, start_time, state }) {
    const startDate = moment(start_time);
    const endDate = moment(end_time);
    if (!startDate.isValid() || !endDate.isValid()) {
      return "";
    }

    const { start, end, duration } = getTimeData(startDate, endDate);

    switch (state) {
      case "recent":
      case "completed":
        // Format the starting time
        const begin =
          duration === 0
            ? // If duration is zero, then both start and end fall on the same day.
              // Considering the range of the recent/completed events, this limits
              // it to entirely today or entirely yesterday. In either case, we only
              // want the time of day for the start time.
              `${start.hour}`
            : start.daysAgo === 1
            ? `${start.hour} yesterday`
            : start.daysAgo < 8
            ? `${start.hour} ${start.dayLong}`
            : start.month;

        // Format the end time
        const stop =
          end.daysAgo === 0
            ? `${end.hour} today`
            : end.daysAgo === 1
            ? `${end.hour} yesterday`
            : `${end.hour} ${end.monthLong}`;

        // Plug those times into the return string
        return `Your field received about ${amount} ${_.pluralize("inch", amount)} of rain from ${begin} to ${stop}.`;

      case "past":
        // Past events
        if (end.daysAgo > 365) {
          return "It hasn't rained on your field for over a year.";
        } else if (end.daysAgo > 30) {
          return `It hasn't rained on your field since ${end.month}. \
The last time it rained, your field received about ${amount} ${_.pluralize("inch", amount)}.`;
        } else {
          return `It hasn't rained on your field for ${end.daysAgo} ${_.pluralize("day", end.daysAgo)}. \
The last time it rained, your field received about ${amount} ${_.pluralize("inch", amount)}.`;
        }

      default:
        return `It hasn't rained on your field for at least ${end.monthsAgo < 2 ? "a" : end.monthsAgo} ${_.pluralize(
          "month",
          end.monthsAgo
        )}.`;
    }
  },
};

export default rainfallEvents;
