import { css } from "aphrodite";
import PropTypes from "prop-types";
import React from "react";
import { Row, Col } from "react-styled-flexboxgrid";
import { ResponsiveHeading } from "settings/styledComponents";
import styles, { PlaceholderText } from "settings/styles";

import { Button } from "components/fl-ui";
import { Card } from "components/fl-ui/Card";

const SelectionCard = ({ onClick, type }) => (
  <Col className={css(styles.cardColumn)} md={6} xs={12}>
    <Card>
      <div className={css(styles.cardBody)}>
        <div>
          <span className={css(styles.cardHeader)}>
            {type.isArchived && <span className={css(styles.lightText)}>{"(Archived) "}</span>}
            {type.operationalCost
              ? `${type.name} · $${type.operationalCost} ${type.operationalCostRate === "PER_ACRE" ? "/ ac" : ""}`
              : type.name}
          </span>
        </div>
        <Button color="secondary" onClick={onClick}>
          Edit
        </Button>
      </div>
    </Card>
  </Col>
);

const SelectionList = (props) => {
  const { buttonText, copy, handleCreateData, handleEditData, header, list, placeholder, secondary } = props;
  const Header = secondary ? "h3" : ResponsiveHeading;

  return (
    <>
      <Row className={css(buttonText && styles.rowBottomMargin)}>
        <Col xs={buttonText ? 6 : 12}>
          <Header>{header}</Header>
        </Col>

        {buttonText && (
          <Col xs={6} className={css(styles.rightCol)}>
            <Button color="secondary" onClick={handleCreateData}>
              {buttonText}
            </Button>
          </Col>
        )}
      </Row>

      <Row>
        <Col xs={12}>
          <p className={css(styles.copyText, styles.rowBorderBottom)}>{copy}</p>
        </Col>
      </Row>

      <Row>
        {list?.length ? (
          list.map((type) => <SelectionCard key={type.id} onClick={() => handleEditData(type)} type={type} />)
        ) : (
          <Col xs={12}>
            <PlaceholderText>{placeholder}</PlaceholderText>
          </Col>
        )}
      </Row>
    </>
  );
};

SelectionList.propTypes = {
  buttonText: PropTypes.string,
  copy: PropTypes.any.isRequired,
  handleCreateData: PropTypes.func,
  handleEditData: PropTypes.func.isRequired,
  header: PropTypes.string,
  list: PropTypes.array,
  placeholder: PropTypes.string,
  secondary: PropTypes.bool,
};

export default SelectionList;
