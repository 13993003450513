import ConsentPage from "./pages/ConsentPage";

import App from "layout/app";
import SubRoute from "lib/SubRoute";

const ElevatePlusRouter = SubRoute.extend({
  routes: {
    consent: "consent",
  },

  consent(params) {
    App.display(ConsentPage, {
      token: params?.token || "",
    });
    return App.setTitle("Consent");
  },
});

export default () => new ElevatePlusRouter("elevate_plus", { createTrailingSlashRoutes: true });
