import { isNative, directoryCache, fileRmdir } from "mobile/mobileManager";

/**
 * Clears out any files stored in the device cache directory on mobile platforms.
 */
async function clearFileCache() {
  if (isNative()) {
    await fileRmdir({
      directory: directoryCache(),
      path: "com.farmlogs.app",
      recursive: true,
    });
  }
}

export default clearFileCache;
