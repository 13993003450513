import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { Button } from "components/fl-ui";
import { UIColors } from "components/fl-ui/colors";
import { Spacing, Typography } from "components/fl-ui/constants";

const Stat = styled.div`
  ${(props) =>
    props.highlight &&
    `
    color: ${props.highlightColor};
  `}
  > big {
    font-size: ${Typography.sizes.xxl};
    font-weight: ${Typography.weights.regular};
  }
  > .label {
    color: ${UIColors.light};
    font-size: ${Typography.sizes.sm};
    margin-bottom: 1em;
    text-transform: uppercase;
  }
`;

export const RainfallStat = (props) => {
  const { children, highlight, label } = props;

  return (
    <Stat highlight={highlight} highlightColor={UIColors.blue}>
      {children}
      <div className="label">{label}</div>
    </Stat>
  );
};

export const HeatStat = (props) => {
  const { children, highlight, label } = props;

  return (
    <Stat highlight={highlight} highlightColor={UIColors.red}>
      {children}
      <div className="label">{label}</div>
    </Stat>
  );
};

const ButtonContainer = styled.div`
  @media only screen and (max-width: 62.5rem) {
    margin-bottom: ${Spacing.regular};
  } ;
`;

const StatsContainer = styled.div`
  align-items: flex-end;
  color: ${UIColors.light};
  display: flex;
  font-weight: ${Typography.weights.medium};
  line-height: 1.3em;

  > :first-child {
    width: 60%;
  }
`;

const AspectSummary = (props) => {
  const { children, linkHref, linkText, title } = props;

  return (
    <a href={linkHref}>
      <div className="row-fluid">
        <div className="col xs-12">
          <h2>{title}</h2>
        </div>
      </div>

      <StatsContainer>{children}</StatsContainer>

      <div className="row-fluid">
        <ButtonContainer className="col xs-12">
          <Button color="primary" lightWeight link>
            {linkText}
          </Button>
        </ButtonContainer>
      </div>
    </a>
  );
};

AspectSummary.propTypes = {
  title: PropTypes.string.isRequired,
};

export default AspectSummary;
