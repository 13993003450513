import numeral from "numeral";

const ActivityInputTypePolicy = {
  fields: {
    area(area) {
      // limit area to double precision
      return +numeral(area).format("0.00");
    },
  },
};

export default ActivityInputTypePolicy;
