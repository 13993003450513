import React from "react";

import FileAttachmentsBlankSlateBanner from "components/advertisements/banners/FileAttachmentsBlankSlateBanner";
import PayGateCommonBlankStateModal from "components/advertisements/modals/PayGateCommonBlankStateModal";

const FileAttachmentsBlankStateModal = (props) => (
  <PayGateCommonBlankStateModal {...props} banner={FileAttachmentsBlankSlateBanner} />
);

export default FileAttachmentsBlankStateModal;
