import AgreementRowSubtype from "agreements/components/AgreementRowSubtype";
import { css } from "aphrodite";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import router from "router";

import { isPlural } from "modules/agreements/utils";

import { Card } from "components/fl-ui/Card";
import styles from "fields/common/styles";

const AgreementCard = (props) => {
  const { agreementSummary, agreementType, fields, id, maturityDate, subType, terms } = props;

  const fieldCount = fields ? fields.length : 0;

  const onClick = (event) => {
    if (!event.isDefaultPrevented()) {
      event.preventDefault();
      router.navigate(`land_costs/${id}`, { trigger: true });
    }
  };

  return (
    <Card className={css(styles.cardStyle)} onClick={onClick}>
      <div className={css(styles.spacedBetweenContainer)}>
        <div>
          <p className={css(styles.statusText)}>
            <AgreementRowSubtype {...{ agreementType, subType, terms }} />
          </p>
          <p className={css(styles.cardText, styles.blueText)}>{`${id} \u00b7 ${agreementSummary}`}</p>
          <p className={css(styles.inputText)}>
            {`${moment(maturityDate).format("MM/DD/YYYY")} \u00b7 ${fieldCount} ${
              isPlural(fields) ? "fields" : "field"
            }`}
          </p>
        </div>
      </div>
    </Card>
  );
};

AgreementCard.propTypes = {
  agreementType: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.object),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  maturityDate: PropTypes.string,
  subType: PropTypes.string,
  terms: PropTypes.arrayOf(PropTypes.object),
};

export default AgreementCard;
