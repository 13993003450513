import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

const ARCHIVE_ACTIVITY_TYPE = gql`
  mutation archiveActivityType($id: Int!) {
    archiveActivityType(id: $id) {
      ok
      activityType {
        id
        isArchived
        name
      }
    }
  }
`;

const UNARCHIVE_ACTIVITY_TYPE = gql`
  mutation unarchiveActivityType($id: Int!) {
    unarchiveActivityType(id: $id) {
      ok
      activityType {
        id
        isArchived
        name
      }
    }
  }
`;

export const withActivityTypeArchive = graphql(ARCHIVE_ACTIVITY_TYPE, {
  props: ({ mutate }) => ({
    archiveActivityType: (id) => {
      mutate({
        variables: { id },
      });
    },
  }),
  options: { refetchQueries: ["getActivityTypes"] },
});

export const withActivityTypeUnarchive = graphql(UNARCHIVE_ACTIVITY_TYPE, {
  props: ({ mutate }) => ({
    unarchiveActivityType: (id) => {
      mutate({
        variables: { id },
      });
    },
  }),
  options: { refetchQueries: ["getActivityTypes"] },
});
