import HeaderCell from "./HeaderCell";
import { CONTRACTS_SORT_COLUMNS } from "contracts/constants/contractsSortColumns";
import React from "react";
import styled from "styled-components";

const CashContractsHeader = styled(({ className }) => (
  <div className={className}>
    <HeaderCell columnClass="expand" />
    <HeaderCell columnClass="contract">CONTRACT</HeaderCell>
    <HeaderCell columnClass="buyer" sortSlug={CONTRACTS_SORT_COLUMNS.buyer}>
      BUYER
    </HeaderCell>
    <HeaderCell columnClass="buyer-icon" />
    <HeaderCell columnClass="commodity" sortSlug={CONTRACTS_SORT_COLUMNS.commodity}>
      COMMODITY
    </HeaderCell>
    <HeaderCell columnClass="delivery" sortSlug={CONTRACTS_SORT_COLUMNS.delivery}>
      DELIVERY
    </HeaderCell>
    <HeaderCell columnClass="qty-contracted">QTY CONTRACTED</HeaderCell>
    <HeaderCell columnClass="qty-priced">QTY PRICED</HeaderCell>
    <HeaderCell columnClass="pricing">PRICING</HeaderCell>
    <HeaderCell columnClass="dots" />
  </div>
))`
  display: flex;
`;

export default CashContractsHeader;
