import _ from "lodash";
import { useCallback, useMemo } from "react";

import useQueryParams from "hooks/useQueryParams";

const getDefaults = () => ({
  crops: [],
  fields: [],
  implements: [],
  people: [],
  products: [],
  sources: [],
  status: [],
  tractors: [],
  type: [],
});
/**
 * Ingests raw query params for the activity index page and normalizes them.  Exposes
 * a setter for updating the activity index query params.
 *
 * @return {[ActivityFilters|*, Function]}
 */
const useActivityQueryParams = () => {
  const [unprocessedQueryParams, setPersistedQueryParams] = useQueryParams();

  const queryParams = useMemo(
    () => ({
      ...getDefaults(),
      ...unprocessedQueryParams,
      sources: _.map(unprocessedQueryParams.sources, _.toUpper),
      status: _.map(unprocessedQueryParams.status, _.toUpper),
    }),
    [unprocessedQueryParams]
  );

  const setQueryParams = useCallback(
    ({ equipment = [], ...filters }) => {
      const updatedParams = { ...filters };
      if (equipment) {
        const [impls, tractors] = _.partition(equipment, { __typename: "Implement" });
        updatedParams["implements"] = _.map(impls, "id");
        updatedParams["tractors"] = _.map(tractors, "id");
      }

      setPersistedQueryParams(updatedParams);
    },
    [unprocessedQueryParams]
  );

  return [queryParams, setQueryParams];
};

export default useActivityQueryParams;
