import { CargillCropCardDetails, LegacyCropCardDetails, TitleArea } from "marketing/cards/components";
import formatAmount from "marketing/cards/utils/formatAmount";
import CropCardProgressBarArea from "marketing/components/CropCardProgressBarArea";
import React from "react";
import styled from "styled-components";

import { useMarketingPageContext } from "context/MarketingPageContext";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";

import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import { ColorPalette } from "components/fl-ui/colors";
import { getCropColors } from "components/fl-ui/colors/crops";
import CommodityYieldUnit from "components/units/CommodityYieldUnit";

const MarketedCropCardBody = styled(({ className, hasContracts, marketedCrop }) => {
  const elevatePlusEnterpriseEnabled = useEnterpriseFeature("elevate_plus");
  const { advisorRecommendations } = useMarketingPageContext();

  const advisorRecommendation = advisorRecommendations.find(
    (recommendation) =>
      marketedCrop?.year === recommendation.cropYear &&
      recommendation?.commodity?.specialtyCommodities?.includes(marketedCrop?.commodity?.id)
  );

  const Unit = () => <CommodityYieldUnit commodity={marketedCrop?.commodity} defaultUnit="bu" />;

  const marketedValue = marketedCrop?.progress?.marketed?.quantity;
  const unprotectedValue = marketedCrop?.progress?.total?.quantity;

  const progressBarMarketedValue = (
    <span>
      {formatAmount(marketedValue)} <Unit />
    </span>
  );

  const progressBarUnprotectedValue = (
    <span>
      {formatAmount(unprotectedValue)} <Unit />
    </span>
  );

  return (
    <div className={className}>
      {!!marketedCrop ? (
        <>
          <CropCardProgressBarArea
            barColor={marketedCrop?.commodity ? getCropColors(marketedCrop.commodity).hedged : ColorPalette.white}
            barBackgroundColor={
              marketedCrop?.commodity ? getCropColors(marketedCrop.commodity).unprotected : ColorPalette.white
            }
            marketedPercentage={marketedCrop?.progress?.marketed?.percentage}
            marketedText={progressBarMarketedValue}
            titleArea={<TitleArea hasContracts={hasContracts} />}
            unprotectedText={progressBarUnprotectedValue}
          />

          {elevatePlusEnterpriseEnabled && advisorRecommendation ? (
            <CargillCropCardDetails
              advisorRecommendation={advisorRecommendation}
              hasContracts={hasContracts}
              marketedCrop={marketedCrop}
            />
          ) : (
            <LegacyCropCardDetails marketedCrop={marketedCrop} />
          )}
        </>
      ) : (
        <LoadingWrapper isLoading />
      )}
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (min-width: 75rem) {
    min-height: 12rem;
  }
`;

export { MarketedCropCardBody };
