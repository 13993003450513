import { getCommodityOptions, getFieldOptions, getFieldGroupOptions } from "profit_and_loss/utils";
import PropTypes from "prop-types";
import React, { useState } from "react";

import Chooser from "components/fl-ui/Chooser";
import FilterActionButtonGroup from "components/fl-ui/Filters/FilterActionButtonGroup";
import { ClearAllFormGroup, TagSelectGroup } from "components/fl-ui/Form";
import CloseX from "components/fl-ui/Icons/CloseX";
import { Modal, ModalHeader, ModalTitle, ModalBody, ModalFooter } from "components/fl-ui/Modal/Modal";

const CropFilterModal = ({ commodityCrops, filters, onClose, onFilter, onReset }) => {
  const commodityOptions = getCommodityOptions(commodityCrops);
  const fieldOptions = getFieldOptions(commodityCrops);
  const fieldGroupOptions = getFieldGroupOptions(fieldOptions);
  const overheadOptions = [
    { label: "Expense", value: "EXPENSE" },
    { label: "Revenue", value: "REVENUE" },
  ];

  const [selected, setSelected] = useState(filters);
  const handleFilter = () => {
    onFilter(selected);
    onClose();
  };

  const handleReset = () => {
    onReset();
    onClose();
  };

  const update = (newFilters) => {
    setSelected({
      ...selected,
      ...newFilters,
    });
  };

  return (
    <Modal>
      <ModalHeader>
        <ModalTitle>Filter Profit & Loss</ModalTitle>
        <CloseX onClick={onClose} />
      </ModalHeader>

      <ModalBody>
        <TagSelectGroup
          id="commodity"
          isMulti
          label="Commodity"
          onChange={(commodities) => update({ commodities })}
          options={commodityOptions}
          showBulkSelect
          value={selected.commodities}
        />

        <TagSelectGroup
          id="overhead"
          isMulti
          label="Overhead"
          onChange={(overhead) => update({ overhead })}
          options={overheadOptions}
          showBulkSelect
          value={selected.overhead}
        />

        <ClearAllFormGroup label="Fields" onClear={() => setSelected({ ...selected, fields: [] })}>
          <Chooser
            getOptionLabel={({ name }) => name}
            isClearable
            isMulti
            onChange={(fields) => setSelected({ ...selected, fields: fields || [] })}
            options={fieldOptions}
            placeholder="Type to search..."
            value={selected.fields}
          />
        </ClearAllFormGroup>

        <TagSelectGroup
          id="fieldGroup"
          isMulti
          label="Field group"
          onChange={(fieldGroups) => update({ fieldGroups })}
          options={fieldGroupOptions}
          showBulkSelect
          value={selected.fieldGroups}
        />
      </ModalBody>

      <ModalFooter>
        <FilterActionButtonGroup
          apply={{ action: handleFilter }}
          cancel={{ action: onClose }}
          reset={{ action: handleReset }}
        />
      </ModalFooter>
    </Modal>
  );
};

CropFilterModal.propTypes = {
  commodityCrops: PropTypes.arrayOf(PropTypes.object).isRequired,
  filters: PropTypes.shape({
    commodities: PropTypes.array,
    fields: PropTypes.array,
    overhead: PropTypes.array,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
};

export default CropFilterModal;
