import React from "react";
import router from "router";

import BinsCollection from "collection/bins";
import BuyersCollection from "collection/buyers";
import InventoryTransactionsCollection from "collection/inventory_transactions";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import App from "layout/app";
import SubRoute from "lib/SubRoute";
import queryParams from "lib/queryParams";
import Index from "modules/fields/Index";

import RainAndHeatBlankStatePage from "components/advertisements/pages/RainAndHeatBlankStatePage";
import Container from "components/fl-ui/Layout/Container";
import Content from "components/fl-ui/Layout/Content";
import Header from "components/fl-ui/Layout/Header";
import FieldAdd from "fields/components/FieldAdd";
import FieldAspect from "fields/components/FieldAspect";
import FieldDetail from "fields/components/FieldDetail";
import Survey from "fields/components/Survey";

const primeAll = () =>
  Promise.allSettled([BinsCollection.prime(), BuyersCollection.prime(), InventoryTransactionsCollection.prime()]);

const RainHeatAspectContainer = (props) => {
  if (!useEnterpriseFeature("weather_history")) {
    return (
      <Container>
        <Header title="Rainfall" />
        <Content>
          <RainAndHeatBlankStatePage {...props} />
        </Content>
      </Container>
    );
  }

  return <FieldAspect {...props} />;
};

const Router = SubRoute.extend({
  routes: {
    "": "index",
    add: "add",
    survey: "survey",
    ":id": "field",
    ":id/edit": "edit",
    ":id/:aspect": "aspect",
  },

  index(urlParams) {
    urlParams = queryParams.parse(urlParams || "");
    App.display(Index, {
      screenName: "Field List",
      isNew: urlParams.new,
      urlParams,
    });
    return App.setTitle("Fields");
  },

  async field(id) {
    await primeAll();
    App.display(FieldDetail, { id: +id, screenName: "Field Detail" });
  },

  add() {
    App.display(FieldAdd);
    App.setTitle("Add Field");
  },

  survey() {
    App.display(Survey);
  },

  edit(id) {
    App.display(FieldAdd, { id });
    return App.setTitle("Edit Field");
  },

  async aspect(id, aspect, params) {
    await primeAll();
    // hide params from url
    router.navigate(`fields/${id}/${aspect}`, { trigger: true });

    const PageComponent = ["gdd", "rainfall"].includes(aspect) ? RainHeatAspectContainer : FieldAspect;

    for (const key of Object.keys(params || {})) {
      const value = params[key];
      params[key] = +value || value;
    }

    App.display(PageComponent, {
      aspect,
      fieldId: +id,
      where: params,
    });
  },
});

export default () => new Router("fields", { createTrailingSlashRoutes: true });
