import { gql } from "@apollo/client";

export default gql`
  query getProductPurchaseDetail(
    $year: Int!
    $purchaseStartDate: Date
    $purchaseEndDate: Date
    $commodities: [Int]
    $products: [Int!]
    $productTypes: [ProductType!]
    $restrictedUse: Boolean
  ) {
    purchaseSummaries: getProductPurchaseSummary(
      products: $products
      productTypes: $productTypes
      commodities: $commodities
      purchaseStartDate: $purchaseStartDate
      purchaseEndDate: $purchaseEndDate
      restrictedUse: $restrictedUse
      year: $year
    ) @requiresPermission(feature: "inputs", actions: ["read"]) {
      ...DetailedProductPurchaseSummary
    }
  }
`;
