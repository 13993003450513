import React from "react";
import SettingsPage from "settings/pages/SettingsPage";

import App from "layout/app";
import SubRoute from "lib/SubRoute";

const SettingsRouter = SubRoute.extend({
  routes: {
    data_integrations: function () {
      this.navigate("connections", { replace: true, trigger: false });
      this.display("connections");
    },
    "import/yield": function () {
      this.navigate("data", { replace: true, trigger: false });
      this.display("data", "yieldData");
    },
    integrations: function () {
      this.navigate("connections", { replace: true, trigger: false });
      this.display("connections");
    },

    /*
     * CORE-5851: emails contained the incorrect unsubscribe route. this has been fixed, but
     * we need to support this incorrect route for a while until we're confident that all
     * traces of it have been lost to time.
     */
    notifications: function () {
      this.navigate("alerts", { replace: true, trigger: false });
      this.display("alerts");
    },

    subscriptions: function () {
      this.navigate("billing", { replace: true, trigger: false });
      this.display("billing");
    },
    ":page(/*sub)": "display",
    "*": function () {
      this.navigate("personal", { replace: true, trigger: false });
      this.display("personal");
    },
  },

  display(routeId, subroute = "") {
    App.display(() => <SettingsPage activeRoute={routeId} subroute={subroute} />, { screenName: "Settings" });
  },
});

export default () => new SettingsRouter("settings", { createTrailingSlashRoutes: true });
