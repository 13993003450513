import { gql } from "@apollo/client";

export default gql`
  fragment BasicNote on Note {
    created
    cropId
    date
    enterpriseId
    fieldId
    geometry
    id
    images
    notes
    people
    updated
  }
`;
