import d3 from "d3";
import _ from "underscore";

class Colorizer {
  constructor({ domain, range, grayscale, interpolate, filter }) {
    this.domain = domain;
    this.filter = filter;
    this._scale = interpolate ? d3.scale.linear().clamp(true).interpolate(d3.interpolateHcl) : d3.scale.quantize();

    this._scale.domain(this.domain).range(range);

    this._grayscale = grayscale;

    if (grayscale) {
      this.MAX = 255;
      const colorMap = __range__(0, this.MAX, true).map((i) => d3.rgb(this._scale(i)));
      this._colors = (r) => colorMap[r];
    } else {
      this.MAX = Math.pow(256, 3);
      this._colors = function (r, g, b) {
        return d3.rgb(this._scale(this.getRawValue({ r, g, b })));
      };
    }

    this._colors = _.memoize(this._colors);
  }

  // Computes the raw value of a color between 0 and MAX
  // Accepts an object with the r, g, b attributes or a hex color string
  getRawValue(color) {
    let b, g, r;
    if (typeof color === "string") {
      ({ r, g, b } = d3.rgb(color));
    } else {
      ({ r, g, b } = color);
    }
    return r * 256 * 256 + b * 256 + g;
  }

  getLegend(width) {
    if (width == null) {
      width = 500;
    }
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = 1;
    const context = canvas.getContext("2d");
    const px = context.createImageData(1, 1);
    const min = d3.min(this.domain);
    const max = d3.max(this.domain);
    const mapScale = d3.scale.linear().domain([0, width]).rangeRound([0, this.MAX]);

    d3.range(0, width).forEach((i) => {
      const v = mapScale(i);
      const { r, g, b } = d3.rgb(this._scale(v));
      px.data[0] = r;
      px.data[1] = g;
      px.data[2] = b;
      px.data[3] = 255;
      return context.putImageData(px, i, 0);
    });

    return canvas.toDataURL("image/png");
  }

  render(imageData) {
    const { data } = imageData;

    for (let i = 0, end = data.length; i < end; i += 4) {
      if (!data[i + 3]) {
        continue;
      } // check alpha flag
      let r = data[i];
      let g = data[i + 1];
      let b = data[i + 2];
      let a = data[i + 3];

      if (this.filter && typeof this.filter === "function") {
        if (!this.filter({ r, g, b })) {
          // If the filter returns false, hide that pixel
          // and continue. The filter function may also be
          // used to manipulate the raw pixel data before
          // it is passed to the coloring function as long
          // as the filter returns true.
          data[i + 3] = 0;
          continue;
        }
      }

      ({ r, g, b } = this._colors(r, g, b));

      if (this._grayscale) {
        a = data[i] ? 255 : 0;
      }

      data[i] = r;
      data[i + 1] = g;
      data[i + 2] = b;
      data[i + 3] = a;
    }

    return imageData;
  }
}

export default Colorizer;

function __range__(left, right, inclusive) {
  const range = [];
  const ascending = left < right;
  const end = !inclusive ? right : ascending ? right + 1 : right - 1;
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i);
  }
  return range;
}
