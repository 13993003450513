import eventFactory from "lib/metrics/event_factory";

export const BILLING_PAGE_ENTER = eventFactory("Billing page enter", {
  dynamic: ["currentPlanId"],
});

export const BILLING_PAGE_USER_CANCELLED = eventFactory("User cancelled", {
  dynamic: ["platform", "response"],
});

export const SUBSCRIPTION_SAVE_START = eventFactory("Subscription save start", {
  dynamic: ["orderDescriptor", "platform"],
});

export const SUBSCRIPTION_SAVE_END = eventFactory("Subscription save end");

export const SUBSCRIPTION_SAVE_ERROR = eventFactory("Subscription save error", {
  dynamic: ["error", "orderDescriptor"],
});

export const TIER_SELECTION_PAYMENT_MODAL_OPEN = eventFactory("Payment Modal Open", {
  modal_name: "Payment info",
});

export const TIER_SELECTION_PAYMENT_MODAL_CANCEL = eventFactory("Payment Modal Cancel", {
  modal_name: "Payment info",
});

export const TIER_SELECTION_PAYMENT_MODAL_ERROR = eventFactory("Payment Modal Card Error", {
  modal_name: "Payment info",
  dynamic: ["error"],
});

export const TIER_SELECTION_CONFIRM_ORDER = eventFactory("Confirm Order Press", {
  context: "Payment info modal",
  dynamic: ["zipcode"],
});

export const TIER_SELECTION_PLAN_SELECT = eventFactory("Plan Select Press", {
  dynamic: ["planId"],
});

export const TIER_SELECTION_DOWNGRADE_MODAL_OPEN = eventFactory("DownGrade Modal Open", {
  modal_name: "DownGrade modal",
});

export const TIER_SELECTION_DOWNGRADE_MODAL_CANCEL = eventFactory("DownGrade Modal Cancel", {
  modal_name: "DownGrade modal",
});

export const TIER_SELECTION_CONFIRM_DOWNGRADE = eventFactory("Confirm DownGrade Press", {
  modal_name: "DownGrade modal",
});

export const TIER_SELECTION_PLAN_WELCOME_MODAL_OPEN = eventFactory("Plan welcome Modal Open", {
  modal_name: "Plan welcome",
});

export const TIER_SELECTION_PLAN_WELCOME_START = eventFactory("Start Using Bushel Farm", {
  modal_name: "Plan welcome",
});
