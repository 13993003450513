/* eslint-disable react/display-name */
import { CapsHeader } from "../Helpers";
import { ApolloProvider } from "@apollo/client";
import chooserStyles from "activity/components/choosers/FieldCropChooser/chooserStyles";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import ReactSelect from "react-select";

import marketingClient from "collection/graphql/client/marketingClient";
import useFieldGroups from "hooks/useFieldGroups";

const FieldGroupFilter = (props) => {
  const {
    onParamChanged,
    param: { id, title },
  } = props;
  const { fieldGroups } = useFieldGroups();

  return (
    <div>
      <CapsHeader>{title}</CapsHeader>
      <ReactSelect
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        isMulti
        onChange={(fieldGroups) =>
          onParamChanged({
            id,
            value: _.map(fieldGroups, "id"),
          })
        }
        options={fieldGroups}
        styles={chooserStyles}
      />
    </div>
  );
};

FieldGroupFilter.propTypes = {
  onParamChanged: PropTypes.func.isRequired,
  param: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default (props) => (
  <ApolloProvider client={marketingClient}>
    <FieldGroupFilter {...props} />
  </ApolloProvider>
);
