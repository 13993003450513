import { fileClient } from "@farmlogs/fl-ui";
import React from "react";
import router from "router";
import styled from "styled-components";

import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import usePermissions from "hooks/usePermissions";

import { Button } from "components/fl-ui";
import FileAttachments from "components/fl-ui/Attachments/FileAttachments";
import { FormGroup } from "components/fl-ui/Form";
import { GreyColors } from "components/fl-ui/colors";

const { getMetadata } = fileClient;

const StyledContainer = styled.div`
  border: 1px solid ${GreyColors.smoke91};
  border-radius: 4px;
  padding: 1rem;
  text-align: center;
`;

const EmptyState = () => {
  const onClick = (event) => {
    event.preventDefault();
    router.navigate("billing", { trigger: true });
  };

  return (
    <StyledContainer>
      <h3>Document storage is limited to Business subscribers.</h3>
      <Button color="primary" link onClick={onClick}>
        Manage your subscription
      </Button>
    </StyledContainer>
  );
};

const AttachmentsFieldset = ({ attachments, onChange }) => {
  const hasAccess = useEnterpriseFeature("file_storage");
  const { canDelete, canWrite } = usePermissions();

  const handleChange = async (files) => {
    const fileIds = _.map(files, "filepickerId");
    const results = await Promise.allSettled(fileIds.map((x) => getMetadata(x)));
    onChange({
      attachments: results.map(({ value }) => ({
        filepickerId: value.filepickerId,
        name: value.fileName,
      })),
    });
  };

  return (
    <FormGroup label="Attachments (optional)">
      {!hasAccess ? (
        <EmptyState />
      ) : (
        <FileAttachments
          canAdd={canWrite("agreements")}
          canDelete={canDelete("agreements")}
          carousel
          files={attachments}
          onChange={handleChange}
        />
      )}
    </FormGroup>
  );
};

export default AttachmentsFieldset;
