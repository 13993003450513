import { ApolloProvider } from "@apollo/client";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import styled from "styled-components";

import marketingClient from "collection/graphql/client/marketingClient";
import useCurrentCropYear from "hooks/useCurrentCropYear";
import { RainfallHeatChart } from "modules/fields/components";
import ErrorBoundary, { ErrorComponent } from "components/fl-ui/Layout/ErrorBoundary";

import DataTable from "components/field/DataTable";
import useWeatherHistory from "components/field/weather/hooks/useWeatherHistory";
import useWeatherSummary from "components/field/weather/hooks/useWeatherSummary";
import { transformDailyWeatherReadings, transformHistoricalWeatherReadings } from "components/field/weather/lib";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import SegmentedControl from "components/fl-ui/SegmentedControl";
import PlantingBanner from "fields/components/PlantingBanner";
import FieldHeatContext from "fields/components/context/FieldHeatContext";

const Controls = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  > p {
    flex-grow: 2;
    margin: 0;
    text-align: center;
  }

  @media only screen and (max-width: 30em) {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;

    > p {
      margin: 1rem 0;
      text-align: unset;
    }
  }
`;

const useCropStatus = (props) => {
  const { aspect, fieldId } = props;
  const { state } = useContext(FieldHeatContext);
  const { tbase, tceil } = state;
  const cropYear = useCurrentCropYear()[0];
  const isGDD = aspect === "gdd";
  const precision = isGDD ? 1 : 3;
  const [chartPeriod, setChartPeriod] = useState("full");
  const [chartType, setChartType] = useState("cumulative");

  const { data: weatherSummary } = useWeatherSummary(fieldId, cropYear, "CropStatus");
  const { hasPlantedInYear, plantingDate } = weatherSummary || {};

  const extraQueryVars = {};
  if (isGDD) {
    extraQueryVars.tbase = tbase;
    extraQueryVars.tceil = tceil;
  }

  const { data: historicalData, loading } = useWeatherHistory(weatherSummary, aspect, false, extraQueryVars);

  return {
    chartData: {
      average: transformHistoricalWeatherReadings(historicalData?.averages, precision),
      daily: transformDailyWeatherReadings(historicalData?.daily, precision),
      plantingDate,
      year: cropYear,
    },
    chartPeriod,
    chartType,
    cropYear,
    hasPlantedInYear,
    loading,
    onChangePeriod: setChartPeriod,
    onChangeChartType: setChartType,
    plantingDate,
  };
};

const CropStatus = (props) => {
  const { aspect, fieldId, fieldName } = props;
  const {
    chartData,
    chartPeriod,
    chartType,
    cropYear,
    hasPlantedInYear,
    loading,
    onChangePeriod,
    onChangeChartType,
    plantingDate,
  } = useCropStatus(props);

  const showDailyChart = chartData.daily.length > 0;
  const showPlantingBanner = !hasPlantedInYear;
  const showPeriodToggle = hasPlantedInYear && plantingDate && showDailyChart;
  const showChartTitle = !_.last(chartData.daily)?.total && chartType === "daily";

  const chartTitle =
    aspect === "gdd"
      ? `No growing degree days yet on ${fieldName} for ${cropYear}`
      : `No rainfall yet on ${fieldName} for ${cropYear}`;
  const periodOptions = [
    { label: `Daily`, optionValue: "daily" },
    { label: `Cumulative`, optionValue: "cumulative" },
  ];
  const periodToggleOptions = [
    { label: "Whole year", optionValue: "full" },
    { label: "Since planting", optionValue: "planting" },
  ];

  return (
    <ApolloProvider client={marketingClient}>
      <div className="js-rain analytics-sidebar-content">
        <div className="js-data-container">
          <ErrorBoundary fallback={<ErrorComponent compact />}>
            {showPlantingBanner && <PlantingBanner fieldId={fieldId} year={cropYear} />}

            <Controls>
              {showDailyChart && (
                <div>
                  <SegmentedControl onChange={onChangeChartType} options={periodOptions} value={chartType} />
                </div>
              )}

              {showChartTitle && <p>{chartTitle}</p>}

              {showPeriodToggle && (
                <div>
                  <SegmentedControl value={chartPeriod} onChange={onChangePeriod} options={periodToggleOptions} />
                </div>
              )}
            </Controls>

            <LoadingWrapper isLoading={loading}>
              {showDailyChart && (
                <RainfallHeatChart
                  aspect={aspect}
                  type={chartType}
                  period={chartPeriod}
                  data={chartData}
                  year={cropYear}
                />
              )}
            </LoadingWrapper>
          </ErrorBoundary>

          {!loading && <DataTable type={aspect} daily={chartData.daily} />}
        </div>
      </div>
    </ApolloProvider>
  );
};

CropStatus.propTypes = {
  aspect: PropTypes.oneOf(["gdd", "rainfall"]).isRequired,
  fieldId: PropTypes.number.isRequired,
  fieldName: PropTypes.string.isRequired,
  tbase: PropTypes.number,
  tceil: PropTypes.number,
  year: PropTypes.number,
};

CropStatus.defaultProps = {
  year: new Date().getFullYear(),
};

export default CropStatus;
