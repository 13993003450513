import { yupResolver } from "@hookform/resolvers/yup";
import { EditNotesSchema } from "contracts/form/validation/CashContractFormSchema";
import _ from "lodash";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import styled from "styled-components";

import useEditCashContract from "collection/graphql/contracts/hooks/useEditCashContract";

import { Button } from "components/fl-ui";
import { ControlledFormGroup, Form, TextArea } from "components/fl-ui/Form";
import { CloseX } from "components/fl-ui/Icons";
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "components/fl-ui/Modal/Modal";
import { Spacing } from "components/fl-ui/constants";

const EditNotesModal = styled(({ className, contract, onClose }) => {
  const methods = useForm({
    defaultValues: {
      notes: contract?.notes,
    },
    mode: "onTouched",
    resolver: yupResolver(EditNotesSchema),
  });

  const saveContract = useEditCashContract();

  const onSubmit = async (values) =>
    saveContract({
      ...contract,
      notes: values.notes,
    }).then(onClose);

  return (
    <Modal className={className} width={600}>
      <ModalHeader>
        <ModalTitle>
          <span className="modal-header-text">Edit comments</span>
        </ModalTitle>
        <CloseX onClick={onClose} />
      </ModalHeader>

      <ModalBody>
        <FormProvider {...methods} schema={EditNotesSchema}>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <ControlledFormGroup
              name="notes"
              render={({ field }) => <TextArea {...field} placeHolder="Add your contract comments here" />}
            />

            <ModalFooter className="extra-class">
              <Button color="default" onClick={onClose} type="button">
                Cancel
              </Button>

              <Button
                className="save-button"
                color="primary"
                disabled={!_.isEmpty(methods.formState.errors)}
                type="submit"
              >
                Save
              </Button>
            </ModalFooter>
          </Form>
        </FormProvider>
      </ModalBody>
    </Modal>
  );
})`
  .extra-class {
    padding: 0;
  }

  .save-button {
    margin-left: ${Spacing.xsmall};
  }
`;

export default EditNotesModal;
