import MenuItem from "../components/MenuItem";
import getDefaultActivityFilters from "activity/hooks/lib/getDefaultActivityFilters";
import useActivitiesProvider from "activity/hooks/useActivitiesProvider";
import useActivityFilters from "activity/hooks/useActivityFilters";
import _ from "lodash";
import moment from "moment";
import React, { useState } from "react";

import { useAuth } from "collection/graphql/auth";
import { OVERDUE, PLANNED } from "lib/constants";
import ActivityFilterModal from "modules/activity/components/ActivityFilterModal";

import { FilterButton } from "components/fl-ui/Buttons";
import Popover from "components/fl-ui/Popover/Popover";

const ActivityFilterButton = () => {
  const { totalAppliedFilters } = useActivitiesProvider();
  const { filters, resetFilters, updateFilters } = useActivityFilters();
  const applyQuickFilter = (newFilters) => updateFilters({ ...getDefaultActivityFilters(), ...newFilters });

  const filterOptions = [];

  /*
   * Assigned to me quick filter
   */
  const currentUserId = useAuth().currentUser.id;
  const isAssignedToMe = _.isEqual(filters.people, [currentUserId]) && totalAppliedFilters === 1;
  filterOptions.push({
    children: "Assigned to me",
    selected: isAssignedToMe,
    onClick: () => applyQuickFilter({ people: [currentUserId] }),
  });

  /*
   * Overdue quick filter
   */
  const isOverdue = _.isEqual(filters.status, [OVERDUE]) && totalAppliedFilters === 1;
  filterOptions.push({
    children: "Overdue activities",
    selected: isOverdue,
    onClick: () => applyQuickFilter({ status: [OVERDUE] }),
  });

  /*
   * Due this week quick filter
   */
  const startOfWeek = moment().startOf("week").format("YYYY-MM-DD");
  const endOfWeek = moment().endOf("week").format("YYYY-MM-DD");

  const isDueThisWeek = _.every([
    totalAppliedFilters === 3,
    _.isEqual(_.sortBy(filters.status, _.toUpper), [OVERDUE, PLANNED]),
    filters.endDate === endOfWeek,
    filters.startDate === startOfWeek,
  ]);

  const applyDueThisWeek = () =>
    applyQuickFilter({
      endDate: endOfWeek,
      startDate: startOfWeek,
      status: [OVERDUE, PLANNED],
    });

  filterOptions.push({ children: "Due this week", onClick: applyDueThisWeek, selected: isDueThisWeek });

  /*
   * Custom filter
   */
  const [showFilterModal, setShowFilterModal] = useState(false);
  let isAdvanced = false;
  if (totalAppliedFilters === 1 && !isOverdue && !isAssignedToMe) {
    isAdvanced = true;
  } else if (totalAppliedFilters > 1 && !isDueThisWeek) {
    isAdvanced = true;
  }
  filterOptions.push({ children: "Custom filters…", selected: isAdvanced, onClick: () => setShowFilterModal(true) });

  /*
   * Clear all filters
   */
  filterOptions.push({ children: "Clear all filters", color: "danger", onClick: resetFilters });

  return (
    <>
      <Popover
        content={filterOptions.map(({ selected, ...buttonProps }, i) => (
          <MenuItem color="primary" icon={selected ? "check" : ""} iconRight={selected} key={i} link {...buttonProps} />
        ))}
        placement="bottom-end"
      >
        <FilterButton filterCount={totalAppliedFilters} />
      </Popover>

      {showFilterModal && <ActivityFilterModal onClose={() => setShowFilterModal(false)} />}
    </>
  );
};

export default ActivityFilterButton;
