import { BasicInput } from "agreements/forms/components";
import React, { Component } from "react";

import utils from "lib/validation/utils";

class InterestRateInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.isValid(props.value) ? props.value : "",
    };
  }

  isValid = (value) => {
    const isInRange = value > 0 && value <= 100;
    const isCorrectPrecision = utils.isDecimal(value, { decimal_digits: "0,3" });

    return isInRange && isCorrectPrecision;
  };

  render() {
    const { name, onChange } = this.props;
    const { value } = this.state;

    const handleChange = (event, payload) => {
      const value = payload[name];

      if (value === "" || this.isValid(value)) {
        this.setState({ value }, () => onChange({ [name]: +this.state.value }));
      }
    };

    const props = {
      type: "float",
      name,
      onChange: handleChange,
      value,
    };

    return <BasicInput {...props} />;
  }
}

export default InterestRateInput;
