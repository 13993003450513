import createReactClass from "create-react-class";
import leaflet from "leaflet";
import PropTypes from "prop-types";
import React from "react";

import Layer from "components/map/layer";

const ICON_SIZE = 40;

const getIcon = () =>
  leaflet.icon({
    iconUrl: "/images/map/icon-crosshair-40x40.svg",
    iconSize: [ICON_SIZE, ICON_SIZE],
    iconAnchor: [ICON_SIZE / 2, ICON_SIZE / 2],
  });

const CrossHairIcon = createReactClass({
  contextTypes: {
    map: PropTypes.object,
  },

  UNSAFE_componentWillMount() {
    this.marker = leaflet.marker([0, 0], {
      icon: getIcon(),
      clickable: false,
    });

    return this.marker.on("add", this.updateLatLng);
  },

  componentDidMount() {
    return this.context.map.on("move", this.updateLatLng);
  },

  componentWillUnmount() {
    return this.context.map.off("move", this.updateLatLng);
  },

  updateLatLng() {
    return this.marker.setLatLng(this.context.map.getCenter());
  },

  render() {
    return <Layer layer={this.marker} />;
  },
});

export default CrossHairIcon;
