import leaflet from "leaflet";

import Wdrvi from "lib/map/layers/wdrvi";

class LeafImageryLayer extends Wdrvi {
  constructor({ bounds, layer }) {
    super({ bounds, layer });
    this.bounds = bounds;
    this.layer = layer;
  }

  onAdd(map) {
    const imageUrl = this.layer?.details?.url || "";
    this.mapLayer = new leaflet.imageOverlay(imageUrl, this.getImageBounds());
    map.addLayer(this.mapLayer);
  }

  onRemove(map) {
    // adding removeLayer method
    map.off("zoomanim", this._animateZoom, this);
    map.off("viewreset", this._resize, this);
    if (this.mapLayer) {
      map.removeLayer(this.mapLayer);
    }
  }

  toggleLayer(map) {
    if (this.mapLayer) {
      map.removeLayer(this.mapLayer);
    }

    const imageUrl = this.layer?.details?.url || "";
    this.mapLayer = new leaflet.imageOverlay(imageUrl, this.getImageBounds());
    map.addLayer(this.mapLayer);
  }

  renderComposite() {
    // Simplified method for only showing imagery
    return $.Deferred().resolve(this.layer.details.url);
  }
}

export default LeafImageryLayer;
