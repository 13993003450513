/* eslint-disable react/display-name */
import { useMutation } from "@apollo/client";
import React from "react";

import { CREATE_COMMODITY, UPDATE_COMMODITY } from "collection/graphql/commodities/mutations";
import { CreateCommodityInputSchema, UpdateCommodityInputSchema } from "collection/graphql/commodities/schemas";

import CommodityForm from "components/commodity/CommodityForm";
import useCommodityForm, {
  Provider as CommodityFormProvider,
} from "components/commodity/CommodityForm/hooks/useCommodityForm";
import { Button } from "components/fl-ui";
import { CloseX } from "components/fl-ui/Icons";
import { Modal, ModalHeader, ModalTitle, ModalBody, ModalFooter } from "components/fl-ui/Modal/Modal";

const CommodityFormModal = ({ onClose, onSave }) => {
  const { isNew, isValid, values } = useCommodityForm();
  const saveCommodity = useMutation(isNew ? CREATE_COMMODITY : UPDATE_COMMODITY, {
    refetchQueries: ["getCommodities"],
  })[0];
  const schema = isNew ? CreateCommodityInputSchema : UpdateCommodityInputSchema;

  const handleSubmit = (event) => {
    event.preventDefault();
    performSave();
  };

  const performSave = async () => {
    const commodity = schema.cast(values, { strict: true });
    commodity.seedUnitType = commodity.seedUnitType.toUpperCase();
    const { data } = await saveCommodity({ variables: { commodity } });
    const updatedCommodity = data.result.commodity;
    onSave(updatedCommodity);
  };

  return (
    <Modal width={700} onClose={onClose}>
      <ModalHeader>
        <ModalTitle>{isNew ? "Add commodity" : "Edit commodity"}</ModalTitle>
        <CloseX onClick={onClose} />
      </ModalHeader>

      <ModalBody>
        <CommodityForm formId="commodityForm" onSubmit={handleSubmit} />
      </ModalBody>

      <ModalFooter>
        <Button type="button" color="default" onClick={() => onClose()}>
          Cancel
        </Button>{" "}
        <Button type="submit" color="primary" form="commodityForm" disabled={!isValid}>
          {isNew ? "Add commodity" : "Save"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ({ commodityId, onClose, onSave }) => {
  return (
    <CommodityFormProvider commodityId={commodityId}>
      <CommodityFormModal onClose={onClose} onSave={onSave} />
    </CommodityFormProvider>
  );
};
