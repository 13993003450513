import { groupBy, max, min, range } from "lodash";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

import withCropMutation from "hoc/withCropMutation";
import useFieldCropsByField from "modules/fields/hooks/useFieldCropsByField";

import CropRow from "components/field/CropRow";
import { Button } from "components/fl-ui";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";

const CropHistory = ({ createCrop, fieldId, showAll, year }) => {
  const isYearCrops = !showAll && !!year;
  const { fieldCrops, loading } = useFieldCropsByField({ fieldId, year: isYearCrops && year });
  const hasNoYearCrops = isYearCrops && !loading && !fieldCrops?.length; // no crops and in sidebar
  const isLoading = loading && !fieldCrops?.length;

  const cropsList = useMemo(() => {
    const cropsByYear = groupBy(fieldCrops, "cropYear");
    const getYears = () => {
      if (isYearCrops) {
        return [year];
      } else {
        const cropYears = Object.keys(cropsByYear);
        cropYears.push(new Date().getFullYear());

        if (year) {
          cropYears.push(year);
        }

        return range(max(cropYears), min(cropYears) - 1);
      }
    };

    const years = getYears();
    return years.map((year) => ({ crops: cropsByYear[year] ?? [], year }));
  }, [fieldCrops, fieldId, loading, showAll, year]);

  return (
    <LoadingWrapper isLoading={isLoading}>
      {hasNoYearCrops && (
        <div className="text-centered">
          <div className="no-crops">{`You haven't added any ${year} crops yet`}</div>
          <Button color="primary" hollow onClick={() => createCrop(fieldId)}>
            + Add crop
          </Button>
        </div>
      )}

      {cropsList.map(({ crops, year }) => (
        <CropRow cropYear={year} crops={crops} fieldId={fieldId} key={year} showAll={showAll} />
      ))}
    </LoadingWrapper>
  );
};

CropHistory.propTypes = {
  createCrop: PropTypes.func,
  fieldId: PropTypes.number.isRequired,
  showAll: PropTypes.bool,
  year: PropTypes.number,
};

export default withCropMutation(CropHistory);
