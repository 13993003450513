import useActivityBulkDelete from "./useActivityBulkDelete";

/**
 * Returns a function which performs a deletion on an activity. The query for the current
 * crop year will be updated.
 */
const useActivityDelete = () => {
  const callback = useActivityBulkDelete();

  /**
   * @param {Number} id the id of the activity to delete.
   * @returns {Promise}
   */
  return (id) => callback([id]);
};

export default useActivityDelete;
