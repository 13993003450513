import CheckedOption from "activity/components/choosers/options/CheckedOption";
import { ButtonSwitchContainer, FullWidthButton, HalfWidthButton, SubText } from "loads/components/styledComponents";
import { upperFirst } from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import ReactSelect, { components } from "react-select";

import { Borders } from "components/fl-ui/constants";

const CategorizedChooser = ({ categorizedOptions, isDisabled, onAddNewItem, onChange, placeholder, value }) => {
  const categories = Object.keys(categorizedOptions);
  const defaultCategory =
    categories?.find((item) => categorizedOptions[item]?.find((option) => option.value === value)) || categories[0];
  const [category, setCategory] = useState(defaultCategory);
  const options = categorizedOptions[category];

  const MenuList = (props) => (
    <components.MenuList {...props}>
      <ButtonSwitchContainer>
        {categories.map((item) => (
          <HalfWidthButton color="primary" key={item} link={category !== item} onClick={() => setCategory(item)}>
            {upperFirst(item)}
          </HalfWidthButton>
        ))}
      </ButtonSwitchContainer>

      {props.children}

      <FullWidthButton color="primary" onClick={() => onAddNewItem(category)}>
        Add New
      </FullWidthButton>
    </components.MenuList>
  );

  const Option = (props) => (
    <CheckedOption {...props}>
      <div>
        <span>{upperFirst(props.data.name)}</span>
        {props.data.description && <SubText>{upperFirst(props.data.description)}</SubText>}
      </div>
    </CheckedOption>
  );

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>{value && upperFirst(children)}</components.SingleValue>
  );

  return (
    <ReactSelect
      components={{ MenuList, Option, SingleValue }}
      getOptionLabel={({ name }) => name}
      getOptionValue={({ value }) => value}
      isDisabled={isDisabled}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      styles={{
        option: (base) => ({ ...base, border: Borders.light }),
      }}
      value={options.find((option) => option.value === value)}
    />
  );
};

CategorizedChooser.propTypes = {
  categorizedOptions: PropTypes.object,
  isDisabled: PropTypes.bool,
  onAddNewItem: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default CategorizedChooser;
