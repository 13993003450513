import { fileClient } from "@farmlogs/fl-ui";
import { css } from "aphrodite/no-important";
import CurrentSubscriptionCopy from "billing/components/CurrentSubscriptionCopy";
import { SupportEmailLink, SupportLink, SupportPhoneLink } from "billing/lib";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Transition, TransitionGroup } from "react-transition-group";
import styled from "styled-components";

import { useAuth } from "collection/graphql/auth";
import { getCurrentSubscription } from "collection/graphql/subscription";
import usePermissions from "hooks/usePermissions";
import useReleaseFeature from "hooks/useReleaseFeature";
import useRestQuery from "hooks/useRestQuery";
import useWindow from "hooks/useWindow";

import { Button } from "components/fl-ui";
import CloseX from "components/fl-ui/Icons/CloseX";
import { Cluster } from "components/fl-ui/Layout";
import Screen from "components/fl-ui/Layout/Navigation/Screen";
import styleVars from "components/fl-ui/Layout/Navigation/styleVars";
import styles from "components/fl-ui/Layout/Navigation/userMenuStyles";
import navStyles from "components/fl-ui/Layout/styles/navStyles";
import { UIColors } from "components/fl-ui/colors";
import { Spacing, Typography } from "components/fl-ui/constants";

const Version = styled.div`
  color: ${UIColors.lightest};
  font-size: ${Typography.sizes.sm};
  margin-top: ${Spacing.regular};
`;

/**
 * @param {Object} user
 * @param {String} [user.firstName='User']
 * @param {String} [user.lastName='Name']
 * @return {{firstName: String, lastName: String, lastInitial: String, firstInitial: String}}
 */
const getName = (user) => {
  const { firstName, lastName } = user;

  return {
    firstName: firstName || "User",
    lastName: lastName || "Name",
    firstInitial: firstName ? firstName.charAt(0) : "U",
    lastInitial: lastName ? lastName.charAt(0) : "N",
  };
};

const UserIcon = ({ isPressed, onClick }) => {
  const user = useAuth().currentUser ?? {};
  const { firstInitial, lastInitial } = getName(user);
  const initials = [firstInitial, lastInitial].join(" ");

  const iconUrl = user.icon ? fileClient.getUrl(user.icon) : null;
  const [avatarUrl, setAvatarUrl] = useState(iconUrl);

  const containerProps = {
    className: css(styles.userIcon),
    role: "button",
  };
  if (typeof onClick === "function") {
    Object.assign(containerProps, {
      "aria-pressed": isPressed.toString(),
      onClick,
    });
  }

  return (
    <div {...containerProps}>
      {avatarUrl && <img alt={initials} onError={() => setAvatarUrl(null)} src={avatarUrl} />}

      {!avatarUrl && <span>{initials}</span>}
    </div>
  );
};

UserIcon.propTypes = {
  onClick: PropTypes.func,
  isPressed: PropTypes.bool,
};

UserIcon.defaultProps = {
  isPressed: false,
};

const UserID = () => {
  const user = useAuth().currentUser ?? {};
  const { firstName, lastName } = getName(user);

  return (
    <div className={css(styles.userID)}>
      <div className={css(styles.navToggle)}>
        <UserIcon />
      </div>
      <div className={css(styles.userCopy)}>
        <h2 className={css(styles.userName)}>
          {firstName} {lastName}
        </h2>
        {user.email && <p className={css(styles.userEmail)}>{user.email}</p>}
      </div>
    </div>
  );
};

const MenuItem = ({ children, href }) => (
  <li>
    <a className={css(styles.menuLink)} href={href}>
      {children}
    </a>
  </li>
);

const Menu = (props) => {
  const { onClick, status } = props;
  const { cancelAtPeriodEnd, daysRemaining, isSubscribed, isTrialing } =
    useRestQuery(getCurrentSubscription).data?.subscription || {};
  const { canRead, canWrite } = usePermissions();
  const { logout } = useAuth();
  const style = {
    ...styleVars.userMenuDefaultStyle,
    ...styleVars.userMenuTransitionStyles[status],
  };

  const handleSignoutClick = () => {
    onClick();
    logout();
  };

  const canViewActivityPage = canWrite("activity_types");
  const canViewBillingPage = canRead("subscription");
  const canViewFarmPage = canRead("farm_info");
  const canViewPeoplePage = canRead("access_control");
  const canViewDemoPage = useReleaseFeature("debug").enabled;

  return (
    <div className={css(navStyles.menuContainerOuter, navStyles.menuContainerOuter_mobile)} style={style}>
      <div className={css(navStyles.menuContainer, navStyles.menuContainer_mobile, styles.menuContainer)}>
        <div className={css(styles.menuHeader)}>
          <UserID />
          <CloseX onClick={onClick} />
        </div>

        <div className={css(styles.menuSection)}>
          <div className={css(styles.promo)}>
            <p className={css(styles.promoText)}>
              {!isSubscribed ? "Get started with Bushel Farm today" : "Current Subscription"}
            </p>

            {isSubscribed && (
              <p className={css(styles.noMargin)}>
                <CurrentSubscriptionCopy />

                {(cancelAtPeriodEnd || isTrialing) && daysRemaining >= 0 && (
                  <span className={css(styles.lineBreak, isTrialing ? styles.greenText : styles.redText)}>
                    {daysRemaining === 1 ? "1 day remaining" : `${daysRemaining} days remaining`}
                  </span>
                )}
              </p>
            )}
          </div>
        </div>

        <div className={css(styles.menuSection)}>
          <ul data-cy="userMenuItemList">
            <MenuItem href="#settings">Account Settings</MenuItem>
            {canViewFarmPage && <MenuItem href="#settings/farm">Farm Settings</MenuItem>}
            {canViewBillingPage && (
              <MenuItem href={!isSubscribed ? "/#billing" : "/#settings/billing"}>Subscription Plan & Billing</MenuItem>
            )}
            {canViewPeoplePage && <MenuItem href="#settings/users">Manage Users</MenuItem>}
            {canViewActivityPage && <MenuItem href="#settings/activities">Manage Activity Types</MenuItem>}
            <MenuItem href="#settings/data">Data</MenuItem>

            <MenuItem href="#settings/connections">Connections</MenuItem>

            {canViewDemoPage && <MenuItem href="#demo">Debug Page</MenuItem>}
          </ul>
        </div>

        <div className={css(styles.menuSection)}>
          <Button className={css(styles.signOutButton)} color="primary" hollow onClick={handleSignoutClick}>
            Sign out
          </Button>

          <span className={css(styles.menuFooter)}>
            If you need support, please visit our <SupportLink>support site</SupportLink>, or contact us at{" "}
            <SupportEmailLink /> or <SupportPhoneLink />.
          </span>

          <Cluster end>
            <Version>version {process.env.CLIENT_VERSION}</Version>
          </Cluster>
        </div>
      </div>
    </div>
  );
};

Menu.propTypes = {
  onClick: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};

const UserMenu = () => {
  const [showUserMenu, setShowUserMenu] = useState(false);
  const hideMenu = () => setShowUserMenu(false);
  const handleMenuClick = () => setShowUserMenu(!showUserMenu);

  const win = useWindow();
  useEffect(() => {
    win.addEventListener("hashchange", hideMenu);
    return () => win.removeEventListener("hashchange", hideMenu);
  }, []);

  return (
    <div>
      <div className={css(styles.navToggle)} data-cy="userMenuTrigger">
        <UserIcon isPressed={showUserMenu} onClick={handleMenuClick} />
      </div>
      <TransitionGroup>
        {showUserMenu && (
          <Transition
            key="userMenuTransition"
            timeout={styleVars.menuTransitionDuration}
            transitionName="userMenuTransition"
          >
            {(status) => (
              <>
                <Menu onClick={handleMenuClick} status={status} />
                <Screen
                  onClick={handleMenuClick}
                  style={{
                    ...styleVars.screenDefaultStyle,
                    ...styleVars.screenTransitionStyles[status],
                  }}
                />
              </>
            )}
          </Transition>
        )}
      </TransitionGroup>
    </div>
  );
};

export default UserMenu;
