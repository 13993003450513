import { css } from "aphrodite";
import { upperFirst } from "lodash";
import styles from "profit_and_loss/styles";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Col, Row } from "react-styled-flexboxgrid";

import SortIndicator from "components/fl-ui/SortIndicator";

const SummaryTable = ({ action, data, footer, header, onSort, sortCriteria, title }) => {
  const [sortAsc, setSortAsc] = useState();
  const handleSort = (value) => {
    if (onSort) {
      if (sortCriteria === value) {
        setSortAsc(!sortAsc);
      } else {
        setSortAsc(true);
      }
      onSort(value);
    }
  };

  return (
    <div style={{ overflowX: "auto" }}>
      {(title || action) && (
        <Row className={css(styles.tableTitleRow)}>
          {title && (
            <Col className={css(styles.leftAlignedText, styles.centerAligned)}>
              {typeof title === "string" ? upperFirst(title) : title}
            </Col>
          )}

          {action && <Col className={css(styles.rightAlignedText, styles.centerAligned)}>{action}</Col>}
        </Row>
      )}

      <table className={css(styles.summaryTable)}>
        <thead>
          {header && (
            <tr>
              <th
                className={css(styles.tableHead, styles.leftAlignedText, onSort && styles.pointer)}
                onClick={() => handleSort(header.label)}
              >
                {header.label}
                {sortCriteria === header.label && <SortIndicator sortDirection={sortAsc ? "asc" : "desc"} />}
              </th>

              {header.values.map((value) => (
                <th
                  className={css(styles.tableHead, styles.rightAlignedText, onSort && styles.pointer)}
                  key={`${value}-${header.id}`}
                  onClick={() => handleSort(value)}
                >
                  {value}
                  {sortCriteria === value && <SortIndicator sortDirection={sortAsc ? "asc" : "desc"} />}
                </th>
              ))}
            </tr>
          )}
        </thead>

        <tbody>
          {data.map(({ id, label, values }) => (
            <tr key={`${label}-${id}`}>
              <td className={css(styles.tableData, styles.leftAlignedText)}>
                {typeof label === "string" ? upperFirst(label) : label}
              </td>

              {values.map((value, index) => (
                <td
                  className={css(styles.tableData, styles.rightAlignedText)}
                  key={`${["number", "string"].includes(typeof value) ? value : index}-${id}`}
                >
                  {value || "--"}
                </td>
              ))}
            </tr>
          ))}

          {footer && (
            <tr>
              <td className={css(styles.tableHead, styles.leftAlignedText)}>{footer.label}</td>

              {footer.values.map((value, index) => (
                <td className={css(styles.tableData, styles.rightAlignedText)} key={`${value || index}-${footer.id}`}>
                  {value || "--"}
                </td>
              ))}
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

SummaryTable.propTypes = {
  actions: PropTypes.any,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  footer: PropTypes.shape({
    label: PropTypes.string,
    values: PropTypes.array,
  }),
  header: PropTypes.shape({
    label: PropTypes.string,
    values: PropTypes.array,
  }),
  title: PropTypes.any,
};

export default SummaryTable;
