import { BasicInput, BasicSelect } from "agreements/forms/components";
import MicroLabel from "agreements/forms/components/MicroLabel";
import React from "react";
import styled from "styled-components";

import states from "lib/states";

import { Input } from "components/fl-ui/Form";
import { Cluster, Stack } from "components/fl-ui/Layout";
import { GreyColors } from "components/fl-ui/colors";

const FormCluster = styled(Cluster)`
  > :nth-child(1):nth-last-child(1) {
    width: 100%;
  }
  > :nth-child(1):nth-last-child(2),
  > :nth-child(2):nth-last-child(1) {
    width: 50%;
  }
  > :nth-child(1):nth-last-child(4),
  > :nth-child(2):nth-last-child(2),
  > :nth-child(3):nth-last-child(1) {
    width: 33.3333%;
  }
`;

const StyledContainer = styled.div`
  border: 1px solid ${GreyColors.smoke91};
  border-radius: 4px;
  padding: 1rem;
  display: table;
  > div + div {
    margin-top: 1rem;
  }
`;

const LandlordInformationFieldset = ({ landlord, onChange }) => {
  const handleChangeEvent = (event, field) => {
    onChange({
      ...landlord,
      ...field,
    });
  };

  const stateOptions = states.US.map(({ code, name }) => ({
    key: code,
    label: name,
    value: code,
  }));
  stateOptions.unshift({ key: -1, value: null, label: "Select state" });

  return (
    <Stack>
      <h4>Landlord information (optional)</h4>
      <StyledContainer>
        <FormCluster>
          <MicroLabel label="First Name">
            <BasicInput name="firstName" value={landlord.firstName} onChange={handleChangeEvent} />
          </MicroLabel>

          <MicroLabel label="Last Name">
            <BasicInput name="lastName" value={landlord.lastName} onChange={handleChangeEvent} />
          </MicroLabel>
        </FormCluster>

        <FormCluster>
          <MicroLabel label="Legal entity">
            <BasicInput name="organization" value={landlord.organization} onChange={handleChangeEvent} />
          </MicroLabel>
        </FormCluster>

        <FormCluster>
          <MicroLabel label="Street">
            <BasicInput name="addressLine1" value={landlord.addressLine1} onChange={handleChangeEvent} />
          </MicroLabel>
        </FormCluster>

        <FormCluster>
          <Input name="addressLine2" value={landlord.addressLine2} onChange={handleChangeEvent} />
        </FormCluster>

        <FormCluster>
          <MicroLabel label="City">
            <BasicInput name="city" value={landlord.city} onChange={handleChangeEvent} />
          </MicroLabel>

          <MicroLabel label="State">
            <BasicSelect
              name="state"
              value={landlord.state}
              onChange={(payload) => onChange({ ...landlord, ...payload })}
              options={stateOptions}
              style={{ width: "100%" }}
            />
          </MicroLabel>

          <MicroLabel label="Postal Code">
            <BasicInput name="postalCode" value={landlord.postalCode} onChange={handleChangeEvent} />
          </MicroLabel>
        </FormCluster>

        <FormCluster>
          <MicroLabel label="Phone Number">
            <BasicInput name="phone" value={landlord.phone} onChange={handleChangeEvent} />
          </MicroLabel>

          <MicroLabel label="Email">
            <BasicInput name="email" value={landlord.email} onChange={handleChangeEvent} />
          </MicroLabel>
        </FormCluster>
      </StyledContainer>
    </Stack>
  );
};

export default LandlordInformationFieldset;
