import CropHeadingIcon from "marketing/components/CropHeadingIcon";
import PropTypes from "prop-types";
import React from "react";

import { darkenBy, getCropColors } from "components/fl-ui/colors/crops";

const DetailCropHeader = ({ crop, type }) => {
  const colors = getCropColors(crop?.color);
  let iconOpts, text;

  const map = {
    hedged: {
      iconOpts: { borderColor: darkenBy(colors.hedged, 0.2), fillColors: [colors.hedged] },
      text: "Hedged",
    },
    priced: {
      iconOpts: { borderColor: darkenBy(colors.priced, 0.2), fillColors: [colors.priced] },
      text: "Priced",
    },
    unprotected: {
      iconOpts: { borderColor: darkenBy(colors.unprotected, 0.2), fillColors: [colors.unprotected] },
      text: "Unprotected",
    },
  };

  if (type in map) {
    iconOpts = map[type].iconOpts;
    text = map[type].text;
  } else {
    iconOpts = {
      borderColor: darkenBy(colors.hedged, 0.2),
      fillColors: [colors.priced, colors.hedged, colors.unprotected],
    };
    text = "Total production";
  }

  return (
    <span>
      <CropHeadingIcon {...iconOpts} style={{ marginRight: 7 }} />
      {text}
    </span>
  );
};
DetailCropHeader.propTypes = {
  crop: PropTypes.shape({ color: PropTypes.string }).isRequired,
  type: PropTypes.oneOf(["hedged", "priced", "total", "unprotected"]),
};

export default DetailCropHeader;
