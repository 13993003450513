import PropTypes from "prop-types";
import React from "react";

import FlatDropdown from "components/ui/dropdown/FlatDropdown";
import TypeOption from "components/ui/dropdown/TypeOption";

const iconMap = {
  crops: "fields",
  imagery: "camera",
  "soil.ssurgo": "soil",
  "yield.512": "harvesting",
};

const TypeDropdown = (props) => {
  const { children, layers, onChange, types, value } = props;
  const handleClick = ({ currentTarget }) => {
    const type = types.find((t) => t.value === currentTarget.dataset.value);
    onChange({ type });
  };

  return (
    <FlatDropdown
      hasMultiControl={children?.toLowerCase() === "imagery"}
      icon={iconMap[value]}
      id="fde-type-button"
      text={children}
    >
      {types.map((type, i) => {
        return (
          <TypeOption
            icon={iconMap[type.value]}
            key={`type-option-${i}`}
            {...type}
            onClick={handleClick}
            description={type.value === value && !layers.length && type.placeholder}
            selected={value}
          />
        );
      })}
    </FlatDropdown>
  );
};

TypeDropdown.propTypes = {
  children: PropTypes.node,
  onChange: PropTypes.func,
  types: PropTypes.array,
};

export default TypeDropdown;
