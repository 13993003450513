import { gql } from "@apollo/client";

export default gql`
  mutation createImplement($input: CreateImplementInput!) {
    createImplement(implement: $input) {
      ok
      implement {
        ...ImplementDetail
      }
    }
  }
`;
