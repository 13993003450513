import mapContext from "./context";
import * as turf from "@turf/turf";
import CropSelectionSummary from "activity/components/choosers/CropSelectionMap/components/CropSelectionSummary";
import FieldLayer from "activity/components/choosers/CropSelectionMap/components/FieldLayer";
import FullscreenControl from "activity/components/choosers/CropSelectionMap/components/FullscreenControl";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import MapboxGL, { ZoomControl } from "react-mapbox-gl";

import mapUtils from "lib/map/utils";

const Map = MapboxGL({
  accessToken: process.env.MAPBOX_PUBLIC_TOKEN,
});

export const defaultConfig = (height) => ({
  center: [-98.5795, 39.8283], // Lebanon, KS. Geographic center of the US.
  containerStyle: { height: `${height}px` },
  style: "mapbox://styles/mapbox/satellite-streets-v11?optimize=true",
  zoom: [12],
});

const getBoundsForFields = (fields) => {
  const geometryCollection = turf.geometryCollection(_.map(fields, (field) => field.geometry));
  const envelope = turf.envelope(geometryCollection);
  return mapUtils.fitBounds(envelope.geometry, 300, 300);
};

const CropSelectionMap = (props) => {
  const { fieldCrops, fields, height, menuControl, onCropChange, onComplete, selectedCrops } = props;
  const isControlled = "selectedCrops" in props;
  const [cropSelections, setCropSelections] = useState(props.selectedCrops || []);

  const handleContainerClick = (event) => event.nativeEvent.stopImmediatePropagation();

  const mapConfig = useMemo(() => {
    const config = defaultConfig(height);
    const initialFields = selectedCrops.length > 0 ? _.map(selectedCrops, "field") : fields;
    if (_.size(initialFields)) {
      const { center, zoom } = getBoundsForFields(initialFields);
      config.center = center;
      config.zoom = [Math.min(zoom, 13)];
    }

    return config;
  }, []);

  useEffect(() => {
    if (isControlled) {
      setCropSelections(selectedCrops);
    }
  }, [selectedCrops]);

  const context = useMemo(
    () => ({
      fields,
      selectedCrops: cropSelections,
      addCrop: (...cropIds) => {
        const updatedCropSelection = [...cropSelections];
        const selectedCropIds = _.map(cropSelections, "id");
        cropIds.forEach((id) => {
          if (!selectedCropIds.includes(id)) {
            updatedCropSelection.push(_.find(fieldCrops, { id }));
          }
        });

        if (!isControlled) {
          setCropSelections(updatedCropSelection);
        }
        onCropChange(updatedCropSelection, { fields });
      },
      removeCrop: (...cropIds) => {
        const updatedCropSelection = _.reject(cropSelections, ({ id }) => cropIds.includes(id));
        if (!isControlled) {
          setCropSelections(updatedCropSelection);
        }
        onCropChange(updatedCropSelection, { fields });
      },
    }),
    [fields, cropSelections, fieldCrops, isControlled, onCropChange, setCropSelections]
  );

  return (
    <mapContext.Provider value={context}>
      <div onClick={handleContainerClick} ref={menuControl.mapContainerRef}>
        <Map {...mapConfig}>
          <CropSelectionSummary
            onComplete={() => {
              onComplete(cropSelections, { fields });
            }}
          />
          <FullscreenControl />
          <ZoomControl position="bottom-right" />

          {fields.map((field, i) => (
            <FieldLayer key={i} field={field} />
          ))}
        </Map>
      </div>
    </mapContext.Provider>
  );
};

CropSelectionMap.propTypes = {
  fieldCrops: PropTypes.arrayOf(PropTypes.object).isRequired,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  height: PropTypes.number,
  menuControl: PropTypes.object.isRequired,
  onComplete: PropTypes.func,
  onCropChange: PropTypes.func,
  selectedCrops: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.object])).isRequired,
};

CropSelectionMap.defaultProps = {
  height: 500,
  onComplete: () => {},
  onCropChange: () => {},
};

export default CropSelectionMap;
