import { useQuery } from "@apollo/client";
import WriteActivityWorkflow from "activity/components/WriteActivityWorkflow";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import getFieldCropsAndActivities from "collection/graphql/activities/getFieldCropsAndActivities";
import marketingClient from "collection/graphql/client/marketingClient";
import withCropMutation from "hoc/withCropMutation";

import { Button } from "components/fl-ui";

const addPlantingText = "planting to this field to see more accurate rainfall and heat stress";
const addCropText = "crop to this field to see more accurate rainfall and heat stress";

const getActivityDateString = (year) => {
  const yearDiff = new Date().getFullYear() - year;
  return moment([]).subtract(yearDiff, "year").format("YYYY-MM-DD");
};

const PlantingBanner = (props) => {
  const { createCrop, fieldId, year } = props;
  const [showAddActivityModal, setShowAddActivityModal] = useState(false);
  const [crop, setCrop] = useState(null);
  const { data, loading, refetch } = useQuery(getFieldCropsAndActivities, {
    client: marketingClient,
    variables: {
      cropYear: year,
      fieldId,
    },
  });

  useEffect(() => {
    if (!loading) {
      setCrop(_.last(data?.crops) ?? null);
    }
  }, [data?.crops, loading]);

  return loading ? null : (
    <div className="callout callout-gray font-size-l text-centered">
      <p>{`Add a ${year} ${crop ? addPlantingText : addCropText}`}</p>

      <Button
        className="btn btn-blue btn-block-small"
        onClick={() => {
          if (crop) {
            setShowAddActivityModal(true);
          } else {
            const onUpdate = (newCrop) => {
              if (newCrop?.id) {
                setCrop(newCrop);
                refetch();
              }
            };

            createCrop(fieldId, { cropYear: year }, { onUpdate });
          }
        }}
      >
        {crop ? "Log planting" : "Add Crop"}
      </Button>

      {showAddActivityModal && (
        <WriteActivityWorkflow
          defaults={{ crop, date: getActivityDateString(year), type: "PLANTING" }}
          onAbort={() => setShowAddActivityModal(false)}
          onCompletion={() => {
            setShowAddActivityModal(false);
            refetch();
          }}
        />
      )}
    </div>
  );
};

PlantingBanner.propTypes = {
  fieldId: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
};

export default withCropMutation(PlantingBanner);
