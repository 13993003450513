/**
 * Filters an array of activities by startDate.
 *
 * @param {Activity[]} activities
 * @param {Object} filters
 * @param {Date} filters.startDate
 * @param {Function} markAsApplied
 * @return {Activity[]}
 */
const startDateFilter = (activities, { startDate }, markAsApplied) => {
  if (startDate) {
    activities = activities.filter(({ date }) => startDate <= date);
    markAsApplied();
  }

  return activities;
};

export default startDateFilter;
