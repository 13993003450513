import { gql } from "@apollo/client";

const deleteMaintenanceRecordMutation = gql`
  mutation deleteMaintenanceRecord($id: Int!) {
    maintenanceRecord: deleteMaintenanceRecord(id: $id)
      @rest(type: "MaintenanceRecord", path: "/api/maintenance_records/{args.id}", method: "DELETE") {
      ...MaintenanceRecord
    }
  }
`;

export default deleteMaintenanceRecordMutation;
