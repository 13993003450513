import PropTypes from "prop-types";
import styled from "styled-components";

import { Button } from "components/fl-ui";
import { Card } from "components/fl-ui/Card";
import { Icon } from "components/fl-ui/Icons";
import { UIColors } from "components/fl-ui/colors";
import { Spacing, Typography } from "components/fl-ui/constants";

export const CardItems = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 62.5rem) {
    align-items: start;
    flex-direction: column;

    > * {
      margin: ${Spacing.xxsmall} 0;
      width: 100%;
    }
  }

  > div {
    align-items: center;
    display: flex;
  }
`;

export const PrefixIcon = styled(Icon)`
  color: ${UIColors.green};
  height: ${Spacing.regular} !important;
  width: ${Spacing.regular} !important;
  ${(props) => props.isSelected && `color: ${UIColors.white};`}
`;

PrefixIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
};

export const RegularText = styled.p`
  color: ${UIColors.regular};
  font-weight: ${Typography.weights.regular};
`;

export const StyledCard = styled(Card)`
  margin: ${Spacing.xxsmall} 0;
  padding: ${Spacing.small} !important;
`;

export const ToggleButton = styled(Button)`
  padding: ${Spacing.xxsmall} !important;
`;

export const ToggleContainer = styled(StyledCard)`
  margin-top: -${Spacing.xxsmall};
  padding-top: ${Spacing.medium} !important;
`;
