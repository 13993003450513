import { gql } from "@apollo/client";

export default gql`
  fragment ProductActivity on Activity {
    id
    acreage
    date
    type {
      id
      category
      name
    }
    field {
      id
      name
    }
    inputs {
      ...ActivityInputDetail
    }
    __typename
  }
`;
