import FieldChooser from "activity/components/choosers/FieldChooser";
import React from "react";

import { FormGroup } from "components/fl-ui/Form";

const FieldsFieldset = ({ onChange, fields }) => (
  <FormGroup label="Fields">
    <FieldChooser value={fields} onChange={(newFields) => onChange({ fields: newFields })} />
  </FormGroup>
);

export default FieldsFieldset;
