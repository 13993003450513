import { omit } from "lodash";
import PropTypes from "prop-types";
import React from "react";

import useUnitOfMeasure from "collection/graphql/units/hooks/useUnitOfMeasure";

import Unit from "components/units/Unit";

const ProductAmountRemainingUnit = ({ attribute, source, ...rest }) => {
  rest.unit = useUnitOfMeasure(source?.amountRemainingUnit)?.[attribute] ?? "";
  return <Unit {...rest} />;
};

ProductAmountRemainingUnit.propTypes = {
  ...omit(Unit.propTypes, ["unit"]),
  attribute: PropTypes.oneOf(["abbr", "name"]),
  source: PropTypes.shape({
    amountRemainingUnit: PropTypes.string.isRequired,
  }).isRequired,
};

ProductAmountRemainingUnit.defaultProps = {
  ...Unit.defaultProps,
  attribute: "abbr",
};

export default ProductAmountRemainingUnit;
