import * as turf from "@turf/turf";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React from "react";

import useCropLayer from "hooks/useCropLayer";
import useYearFieldCrops from "modules/fields/hooks/useYearFieldCrops";

import FieldMapImage, { withLazyRender } from "fields/components/FieldMapImage";

const useActivityLayer = ({ crop, feature, field, fillPattern }) => {
  const { commodity, cropYear, geometry } = crop;
  const { getYearCropsForField } = useYearFieldCrops(cropYear);
  const crops = getYearCropsForField(field?.id);
  const { layer: collection } = useCropLayer({ commodity, crops, field, isLayerOnly: true });
  const mainFeature = !isEmpty(feature?.geometry) && geometry && turf.difference(geometry, feature.geometry);

  if (!isEmpty(feature?.geometry)) {
    feature.properties.id = "activityFeatureId";
    feature.properties.style = { fillPattern };
    collection.addFeature(feature);
  }

  if (mainFeature) {
    mainFeature.properties.id = "mainId";
    mainFeature.properties.color = commodity.color;
    collection.addFeature(mainFeature);
  } else {
    collection.removeFeature("mainId");
  }

  if (isEmpty(feature?.geometry)) {
    collection.removeFeature("activityFeatureId");
    collection.removeFeature("mainId");
  }

  return { layer: collection, pattern: fillPattern };
};

const ActivityMapImage = ({ className, crop, feature, field, fillPattern, height, width }) => {
  const { layer } = useActivityLayer({ crop, feature, field, fillPattern });

  return (
    <FieldMapImage
      className={className}
      field={field}
      height={height}
      layer={layer}
      pattern={fillPattern}
      width={width}
    />
  );
};

ActivityMapImage.propTypes = {
  className: PropTypes.string,
  crop: PropTypes.shape({
    commodity: PropTypes.object,
    cropYear: PropTypes.number,
    geometry: PropTypes.object,
    id: PropTypes.number,
  }).isRequired,
  feature: PropTypes.object,
  field: PropTypes.shape({
    geometry: PropTypes.object,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  fillPattern: PropTypes.object,
  height: PropTypes.number,
  width: PropTypes.number,
};

ActivityMapImage.defaultProps = {
  height: 48,
  width: 48,
};

export default withLazyRender(ActivityMapImage);
