import InventoryTransaction from "loads/components/InventoryTransaction";
import PropTypes from "prop-types";
import React, { useState } from "react";

import Transactions from "collection/inventory_transactions";
import useFieldCrops from "modules/fields/hooks/useFieldCrops";

import LoadRow from "components/load/LoadRow";
import BlankDetail from "fields/components/BlankDetail";

const CropLoads = ({ crop }) => {
  const { getCropByInventoryNode } = useFieldCrops();
  const { commodity, cropYear, id, inventoryNodeId } = crop;
  const [showModal, setShowModal] = useState(false);
  const loads = Transactions.filter((transaction) => getCropByInventoryNode(transaction.get("source_id"))?.id === id);
  const newLoadData = { commodity_id: commodity.id, source_id: inventoryNodeId };

  return (
    <div>
      <h3>{`${commodity.name} ${cropYear}`}</h3>

      {loads.length ? (
        <div className="load-row-list">
          {Array.from(loads).map((load) => (
            <LoadRow key={load.id} model={load} />
          ))}
        </div>
      ) : (
        <BlankDetail onClick={() => setShowModal(true)}>
          <p>No loads</p>
        </BlankDetail>
      )}

      {showModal && <InventoryTransaction load={newLoadData} onClose={() => setShowModal(false)} />}
    </div>
  );
};

CropLoads.propTypes = {
  crop: PropTypes.shape({
    commodity: PropTypes.object,
    cropYear: PropTypes.number,
    id: PropTypes.number,
    inventoryNodeId: PropTypes.number,
  }).isRequired,
};

export default CropLoads;
