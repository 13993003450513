import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import React, { useState } from "react";

import MapControl from "components/map/control";
import { ControlButton, DrawHints } from "fields/add/helpers";
import withMapDrawing from "fields/add/withMapDrawing";
import { getBounds } from "fields/utils";

const FieldMapControls = (props) => {
  const { feature, onBack, onCancelEdit, onClear, points } = props;
  const { geometry, properties } = feature || {};
  const [hideHints, setHideHints] = useState(!!geometry);

  return (
    <>
      <MapControl position="top">
        <div className="col xs-12">
          <ControlButton icon="chevronLeft" label="Back" onClick={properties?.id ? onCancelEdit : onBack} />
        </div>

        {!!(geometry || points) && (
          <div className="col xs-12">
            <ControlButton
              color={geometry && "red"}
              icon={geometry ? "clearMap" : "close"}
              label={geometry ? "Clear Map" : "Cancel"}
              onClick={onClear}
            />
          </div>
        )}
      </MapControl>

      {!geometry && !hideHints && (
        <MapControl position="bottom">
          <div className="col xs-12 sm-5 md-4 lg-3">
            <DrawHints onClick={() => setHideHints(true)} points={points} />
          </div>
        </MapControl>
      )}
    </>
  );
};

FieldMapControls.propTypes = {
  feature: PropTypes.shape({
    geometry: PropTypes.object,
    properties: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  }),
  onBack: PropTypes.func,
  onCancelEdit: PropTypes.func,
  onClear: PropTypes.func,
  points: PropTypes.number,
};

const FieldDrawHandler = withMapDrawing(FieldMapControls);
export default createReactClass({
  displayName: "DrawHandler",

  contextTypes: {
    map: PropTypes.object,
  },

  componentDidMount() {
    if (this.props.feature?.geometry?.type?.match(/Polygon/)) {
      this.positionMap();
    }

    window.addEventListener("orientationchange", this.positionMap);
  },

  componentDidUpdate(prevProps) {
    if (!prevProps.feature?.geometry && this.props.feature?.geometry) {
      this.positionMap();
    }
  },

  componentWillUnmount() {
    window.removeEventListener("orientationchange", this.positionMap);
  },

  positionMap() {
    const { map } = this.context;
    map.fitBounds.apply(map, getBounds(this.props.feature?.geometry));
    map.setMinZoom(map.getZoom() - 2);
  },

  render() {
    return <FieldDrawHandler map={this.context.map} {...this.props} />;
  },
});
