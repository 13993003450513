import { css } from "aphrodite/no-important";
import React from "react";

import PayGateCommonBlankSlateBanner from "components/advertisements/banners/PayGateCommonBlankSlateBanner";
import styles from "components/advertisements/styles";

const ProfitAndLossBlankSlateBanner = (props) => {
  const bannerProps = {
    ...props,
    actionButtonText: "Try Profit & Loss for free",
    imagePath: "images/pay_gates/banners/pandl.png",
    secondaryText: "Profit & Loss is included in the Business plan, and you can try it for free.",
    title: "With Profit & Loss you can:",
  };

  return (
    <PayGateCommonBlankSlateBanner {...bannerProps}>
      <ul>
        <li className={css(styles.valueProp)}>
          Aggregate data from your Bushel Farm account to get a complete picture of how much money is being spent and
          made across every dimension of your farm.
        </li>
        <li className={css(styles.valueProp)}>
          Quickly determine which fields contributed to more profit or loss for your farm.
        </li>
        <li className={css(styles.valueProp)}>Evaluate where to invest or cut back.</li>
        <li className={css(styles.valueProp)}>Streamline conversations with your financial partners</li>
      </ul>
    </PayGateCommonBlankSlateBanner>
  );
};

export default ProfitAndLossBlankSlateBanner;
