import { css } from "aphrodite/no-important";
import { TrialText } from "billing/components/ProductSelectionList/AmountPerUnit";
import CouponFieldset from "billing/components/payment/CouponFieldset";
import useSubscriptionPricing from "billing/hooks/useSubscriptionPricing";
import { styles } from "billing/lib";
import _ from "lodash";
import { currency } from "marketing/utils/format";
import numeral from "numeral";
import PropTypes from "prop-types";
import React from "react";
import { useFormContext } from "react-hook-form";
import { Button } from "components/fl-ui";
import { Cluster } from "components/fl-ui/Layout";
import { isNative } from "mobile/mobileManager";

const LineItem = ({ name, value }) => (
  <div className={css(styles.bottom_line)} data-cy="orderLineItem">
    <Cluster between>
      <span>{name}</span>
      <span>{_.isNaN(+value) ? value : `$${numeral(value).format("0,0.00")}`}</span>
    </Cluster>
  </div>
);

const OrderSummary = () => {
  const form = useFormContext();
  const [billingInterval, coupon, selectedPlanId] = form.getValues(["billingInterval", "coupon", "selectedPlanId"]);
  const { discountTotal, plan, price, subtotal, total } = useSubscriptionPricing(
    selectedPlanId,
    billingInterval,
    coupon
  );
  const trialText = plan?.trial && <TrialText>{`${plan.trial.length}-${plan.trial.interval} free trial`}</TrialText>;
  const subtotalLabel = billingInterval === "month" ? "Monthly total" : "Yearly total";

  return (
    <div className={css(styles.borderedContainer)} data-cy="orderSummary">
      <p className={css(styles.bottom_line, styles.product_title)}>Summary</p>

      {price > 0 && <LineItem name={`Bushel Farm ${plan.name}`} value={trialText || price} />}

      <LineItem name={subtotalLabel} value={subtotal} />

      {coupon && (
        <>
          <div>
            <Cluster between>
              <span>
                <span className={css(styles.pos_promo_codeText)}>Promo code</span>
                <code>{coupon.coupon_id}</code>
                <Button
                  className={css(styles.pos_promo_code_button)}
                  color="danger"
                  data-cy="removeCouponButton"
                  link
                  onClick={() => form.setValue("coupon", null)}
                >
                  (Remove)
                </Button>
              </span>
              <span data-cy="totalDiscount">-{currency(discountTotal, { double: true })}</span>
            </Cluster>
          </div>
          <hr />
        </>
      )}

      {!coupon && !isNative() && <CouponFieldset />}

      <strong data-cy="billedToday">
        <LineItem name="Billed today" value={plan?.trial ? 0 : total} />
      </strong>
    </div>
  );
};

OrderSummary.propTypes = {
  order: PropTypes.object.isRequired,
};

export default OrderSummary;
