import PropTypes from "prop-types";
import React from "react";

import useWindow from "hooks/useWindow";
import CommonIntegration from "modules/settings/components/Integrations/CommonIntegration";

const ClimateFieldViewIntegration = ({ integration, initialModalId }) => {
  const win = useWindow();
  return (
    <CommonIntegration
      automaticFieldImportText="All new field boundaries will be imported into Bushel Farm from Climate FieldView."
      automaticModalCopy="All new activities will be imported into Bushel Farm from Climate FieldView."
      connectionUrl={`https://${win.location.host}/v2.0/integrations/climate/connect`}
      initialModalId={initialModalId}
      integration={integration}
      integrationImgSrc="/images/integrations/climateFieldView.png"
      mainCopy="Automatically import data from Climate FieldView into Bushel Farm."
      manualFieldImportText="Fields with 10% or more overlapping boundaries with existing Bushel Farm fields will not be imported."
    />
  );
};

ClimateFieldViewIntegration.integrationId = "climate";

ClimateFieldViewIntegration.propTypes = {
  integration: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  initialModalId: PropTypes.string.isRequired,
};

export default ClimateFieldViewIntegration;
