import { useQuery } from "@apollo/client";
import _ from "lodash";
import getDefaultFilters from "products/hooks/getDefaultFilters";
import React, { useState } from "react";

import getFieldCropsQuery from "collection/graphql/fields/queries/getFieldCrops";

export const filterBySearchTerm = (purchaseSummaries, term) => {
  term = _.toUpper(term).trim();
  return _.filter(purchaseSummaries, ({ product }) => {
    return product.name.toUpperCase().includes(term);
  });
};

export const filterByVendor = (purchaseSummaries, vendorName) => {
  vendorName = _.trim(vendorName);
  if (vendorName) {
    purchaseSummaries = purchaseSummaries.filter(({ purchases }) => {
      return _.some(purchases, ({ vendor }) => (vendor?.name ?? "").includes(vendorName));
    });
  }

  return purchaseSummaries;
};

export const sortSummaries = (purchaseSummaries, filters) => {
  let [purchases, activities] = _.partition(purchaseSummaries, ({ lastPurchaseDate }) => !!lastPurchaseDate);
  activities = _.sortBy(activities, "product.name");

  switch (filters.sortCriteria) {
    case "alphabetical":
      purchases = _.sortBy(purchases, "product.name");
      break;

    case "dateAsc":
      purchases = _.sortBy(purchases, "lastPurchaseDate");
      break;

    case "dateDesc":
      purchases = _.sortBy(purchases, "lastPurchaseDate");
      purchases = purchases.reverse();
      break;

    case "perUnitCost":
      // 100 here to account for floats, to force numeric type. negative to force expected sort order
      purchases = _.sortBy(purchases, ({ costPerUnit }) => costPerUnit * -100);
      break;

    case "totalCost":
      purchases = _.sortBy(purchases, ({ costTotal }) => costTotal * -100);
      break;
  }

  return activities.concat(purchases);
};

const useProductIndexFilters = (props) => {
  const { data } = useQuery(getFieldCropsQuery, {
    variables: {
      years: [props.cropYear],
    },
  });
  let commodities = _.map(data?.fieldCrops, "commodity");
  commodities = _.sortBy(commodities, "name");
  commodities = _.uniqBy(commodities, "id");

  const [filters, setFilters] = useState(getDefaultFilters());

  return {
    commodities,
    filters,
    onFilterChange: setFilters,
    resetFilters: () => setFilters(getDefaultFilters()),

    applyFiltersAndSort: (purchaseSummaries) => {
      let filteredSummaries = filterBySearchTerm(purchaseSummaries, filters.search);
      filteredSummaries = filterByVendor(filteredSummaries, filters.vendor);
      const sortedSummaries = sortSummaries(filteredSummaries, filters);

      return sortedSummaries;
    },
  };
};

export default useProductIndexFilters;
