import SentryInfoError from "lib/errors/SentryInfoError";

/**
 * An error thrown during the elevate consent workflow.
 */
class ElevateConsentError extends SentryInfoError {
  name = "ElevateConsentError";
}

export default ElevateConsentError;
