import { COMMODITY } from "../fragments";
import { gql } from "@apollo/client";

const updateCommodity = gql`
  mutation updateCommodity($commodity: UpdateCommodityInput!) {
    result: updateCommodity(commodity: $commodity) {
      ok
      commodity {
        ...Commodity
      }
    }
  }

  ${COMMODITY}
`;

export default updateCommodity;
