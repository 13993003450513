import cx from "classnames";
import React from "react";

const Tab = ({ children, className, active, onClick, size, disabled }) => (
  <div
    className={cx("tab", { active, [size]: size, disabled }, className)}
    onClick={disabled ? null : onClick}
    onKeyDown={() => null}
  >
    {children}
  </div>
);

const Tabs = ({ className, onChange, children, value, size }) => (
  <div className={cx("tabs", "horizontal-tabs", className)}>
    {React.Children.map(children, (child) => {
      return !child
        ? null
        : React.cloneElement(child, {
            ...child.props,
            size,
            active: "active" in child.props ? child.props.active : value === child.props.value,
            onClick: () => onChange(child.props.value),
            onKeyDown: () => null,
          });
    })}
  </div>
);

Tabs.Tab = Tab;
export { Tab };
export default Tabs;
