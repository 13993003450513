import createReactClass from "create-react-class";
import $ from "jquery";
import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";

const $app = $("#layout");

const BOTTOM_BAR_CLASS = "field-bottom-bar field-edit-mode";
const ANIMATION_DURATION = 500; // milliseconds

export default createReactClass({
  displayName: "BottomBar",
  propTypes: {
    children: PropTypes.node,
  },

  getInitialState() {
    return { show: false };
  },

  componentDidMount() {
    return this.toggleBottomBar();
  },

  UNSAFE_componentWillReceiveProps({ children }) {
    // no op if component is already showing correct content
    if (this.isReady(children.key)) {
      return;
    }

    this.toggleBottomBar();
    return setTimeout(this.toggleBottomBar, ANIMATION_DURATION);
  },

  shouldComponentUpdate({ children }) {
    // updates content if component is ready
    if (this.isReady(children.key)) {
      return true;
    }

    return false;
  },

  componentWillUnmount() {
    return this.toggleBottomBar();
  },

  isReady(key) {
    return key === this.props.children.key;
  },

  toggleBottomBar() {
    const show = !this.state.show;
    this.toggleClass(show);
    return this.setState({ show });
  },

  toggleClass(show) {
    return $app.toggleClass(BOTTOM_BAR_CLASS, show);
  },

  render() {
    return (
      <div className="bottom-bar" {...this.props}>
        <div style={{ maxWidth: 1400, margin: "0 auto", width: "100%" }}>
          <Row>
            <Col xs>{this.props.children}</Col>
          </Row>
        </div>
      </div>
    );
  },
});
