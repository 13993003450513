import { useWindowResize } from "beautiful-react-hooks";
import React, { useState } from "react";

/**
 * @return {'desktop'|'mobile'}
 */
const useViewType = () => {
  const desktopBreakpoint = "1001px";
  /* 1001px is a magic number I am adding to prevent a crash at 1000px. */
  const getViewType = () => (matchMedia(`(min-width: ${desktopBreakpoint})`).matches ? "desktop" : "mobile");
  const [viewType, setViewType] = useState(getViewType());
  useWindowResize(() => setViewType(getViewType()));

  return viewType;
};

export default useViewType;
