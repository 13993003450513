import PropTypes from "prop-types";
import React, { useState } from "react";

import GeocoderSearch from "components/map/GeocoderSearch";
import Card from "components/ui/card";

const SearchControl = ({ onChange, showHelp }) => {
  const [showCard, setShowCard] = useState(showHelp);
  const hideCard = () => setShowCard(false);
  const handleChange = (data) => {
    if (showCard) {
      hideCard();
    }

    onChange(data);
  };

  return (
    <div>
      <GeocoderSearch onChange={handleChange} />

      {showCard && (
        <div style={{ marginTop: "1em", pointerEvents: "all" }}>
          <Card className="border-caret-white caret-middle">
            <button className="close-x" onClick={hideCard}>
              x
            </button>
            <div className="text-centered" style={{ padding: "1em 0" }}>
              <h3>{`Let's find your first field`}</h3>
              <p>Search for an address near the field you want to add</p>
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};

SearchControl.propTypes = {
  onChange: PropTypes.func.isRequired,
  showHelp: PropTypes.bool,
};

export default SearchControl;
