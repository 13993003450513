import { gql } from "@apollo/client";

export const getLastDaysRainfall = `
  query getLastDaysRainfall($fieldId: Int) {
    last24Rainfall: getFieldRain(fieldId: $fieldId) {
      fieldId: id
      last24 {
        hourly {
          amount
          endTime
          startTime
          __typename
        }
        total
        __typename
      }
    }
  }
`;

export default gql(getLastDaysRainfall);
