import { StartNavigation } from "@proteansoftware/capacitor-start-navigation";
import { upperFirst } from "lodash";
import React from "react";
import router from "router";

import useCropLayer from "hooks/useCropLayer";
import useYearFieldCrops from "modules/fields/hooks/useYearFieldCrops";

import BasicFieldMap from "components/field/BasicFieldMap";
import CropHistory from "components/field/CropHistory";
import FieldDetailsTable from "components/field/FieldDetailsTable";
import { Button } from "components/fl-ui";
import DetailsWidget from "fields/components/DetailsWidget";
import { ButtonContainer, SidebarHeader } from "fields/components/styledComponents";

const FieldSidebar = ({ description, field, fieldId, isMobile, toggleEditModal, year }) => {
  const { getYearCropsForField } = useYearFieldCrops(year);
  const crops = getYearCropsForField(field?.id);

  const isPoint = field.geometry.type.toLowerCase() === "point";
  const { layer, pattern } = useCropLayer({ crops, field });
  const { centroid, geometry, name } = field;
  const [longitude, latitude] = centroid.coordinates;

  const handleNavigate = () => {
    StartNavigation.launchMapsApp({ latitude, longitude, name });
  };

  return (
    <>
      {isMobile && (
        <SidebarHeader>
          <h1 className="sidebar-h1">{upperFirst(name)}</h1>
          <p>
            {" "}
            {Array.isArray(description) ? (
              <span>
                {" "}
                <span className="sidebar-p">{description[0]}</span> {description[1] && <span> - {description[1]}</span>}{" "}
              </span>
            ) : (
              description
            )}
          </p>
        </SidebarHeader>
      )}

      <div className="sidebar-map-container">
        <BasicFieldMap
          centroid={centroid}
          geometry={geometry}
          layer={layer}
          onClick={() => router.navigate(`map/${fieldId}`, { trigger: true })}
          pattern={pattern}
          style={{ touchAction: "auto" }}
        />

        {!isPoint && !isMobile && (
          <Button className="sidebar-map-edit-link" href={`#fields/${fieldId}/edit`}>
            Edit
          </Button>
        )}
      </div>

      {!isPoint && isMobile && (
        <ButtonContainer>
          <Button color="primary" hollow lightWeight onClick={handleNavigate}>
            Start Navigation
          </Button>
          <Button color="primary" hollow href={`#fields/${fieldId}/edit`} lightWeight>
            Edit Boundary
          </Button>
        </ButtonContainer>
      )}

      <DetailsWidget more="All crops" moreUrl={`#fields/${fieldId}/crop_history`} title="Crops">
        <CropHistory fieldId={fieldId} year={year} />
      </DetailsWidget>

      {!isMobile && (
        <DetailsWidget title="Field Details">
          <FieldDetailsTable fieldId={fieldId} onAddLandValue={toggleEditModal} />
        </DetailsWidget>
      )}
    </>
  );
};

export default FieldSidebar;
