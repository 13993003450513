import ContractBuyerDropdown from "contracts/form/components/ContractBuyerDropdown";
import { CASH_CONTRACT, CLOSED, FUTURES_CONTRACT, OPTIONS_CONTRACT } from "marketing/utils/contractEnums";
import { exists, isNumber, isPositive } from "marketing/utils/validators";
import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";

import { FormGroup, Input } from "components/fl-ui/Form";
import ContractUnit from "components/units/ContractUnit";

class ContractDetailsExtraFieldsNewRow extends React.Component {
  handlePremiumChange = (e) => {
    const val = e.target.value;
    if (isPositive(val) || !exists(val)) {
      this.props.handleContractChange({
        premium: val,
      });
    }
  };

  getPremiumSign = () => {
    switch (this.props.contract.optionsContractType) {
      case "SHORT_PUT":
      case "SHORT_CALL":
        return "-";
      default:
        return "";
    }
  };

  handleRevenueImpactChange = (e) => {
    const val = e.target.value;
    if (isNumber(val) || !exists(val)) {
      this.props.handleContractChange({
        revenueImpact: val,
      });
    }
  };

  render() {
    switch (this.props.getContractTypeEnum()) {
      case CASH_CONTRACT:
        return (
          <Row>
            <Col sm={12} md={6}>
              <FormGroup label="Buyer (optional)" inputId="buyer-field">
                <ContractBuyerDropdown
                  id="buyer-field"
                  onChange={(value) => this.props.handleContractChange({ buyer: value ?? "" })}
                  value={this.props.contract.buyer ?? ""}
                />
              </FormGroup>
            </Col>
            <Col sm={12} md={6}>
              <FormGroup label="Fees and adjustments (optional)" inputId="premium-field">
                <Input
                  id="premium-field"
                  onChange={this.handlePremiumChange}
                  type="text"
                  display="block"
                  size="large"
                  value={this.props.contract.premium || ""}
                  placeholder="0.00"
                  prefix={`${this.getPremiumSign()}$`}
                  suffix={<ContractUnit contract={this.props.contract} per="unit" />}
                />
              </FormGroup>
            </Col>
          </Row>
        );
      case FUTURES_CONTRACT:
        return null;
      case OPTIONS_CONTRACT:
        return this.props.contract.contractStatus === CLOSED ? (
          <Row>
            <Col sm={12} md={6}>
              <FormGroup label="Revenue impact" inputId="revenue-impact-field">
                <Input
                  id="revenue-impact-field"
                  onChange={this.handleRevenueImpactChange}
                  type="text"
                  display="block"
                  size="large"
                  value={this.props.contract.revenueImpact || ""}
                  placeholder="0.00"
                  prefix="$"
                />
              </FormGroup>
            </Col>
          </Row>
        ) : null;
    }
  }
}

ContractDetailsExtraFieldsNewRow.propTypes = {
  contract: PropTypes.shape({
    optionsContractType: PropTypes.string,
  }),
  commodity: PropTypes.object,
  getContractTypeEnum: PropTypes.func.isRequired,
  handleContractChange: PropTypes.func.isRequired,
};

export default ContractDetailsExtraFieldsNewRow;
