import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";

import useUnits from "hooks/useUnits";

const LandAreaUnit = ({ defaultUnit = "ACRE", value }) => {
  const enums = _.keyBy(useUnits().findEnumByName("LandAreaUnit"), "data");
  return <>{(enums[value] || enums[defaultUnit]).display}</>;
};

LandAreaUnit.propTypes = {
  defaultUnit: PropTypes.oneOf(["ACRE", "HECTARE", "SQUARE_METER"]),
  value: PropTypes.oneOf(["ACRE", "HECTARE", "SQUARE_METER"]).isRequired,
};

export default LandAreaUnit;
