import BinDetailWrapper from "inventory/BinDetailWrapper";
import InventoryWrapper from "inventory/InventoryWrapper";

import BinsCollection from "collection/bins";
import BuyersCollection from "collection/buyers";
import InventoryTransactionsCollection from "collection/inventory_transactions";
import App from "layout/app";
import SubRoute from "lib/SubRoute";
import History from "lib/history";

const primeAll = () =>
  Promise.allSettled([BinsCollection.prime(), BuyersCollection.prime(), InventoryTransactionsCollection.prime()]);

const InventoryRouter = SubRoute.extend({
  routes: {
    "": "index",
    "bin/:id": "bin",
  },

  index: async () => {
    await primeAll();
    App.setTitle("Inventory");
    App.display(InventoryWrapper, { screenName: "Storage" });
  },

  bin: async (id) => {
    await primeAll();
    const model = BinsCollection.get(id);
    if (!model) {
      return History.back();
    }

    App.setTitle(model.get("name"));
    App.display(BinDetailWrapper, { model, screenName: "Bin Detail" });
  },
});

export default () => new InventoryRouter("inventory", { createTrailingSlashRoutes: true });
