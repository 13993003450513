import { css } from "aphrodite";
import { upperFirst } from "lodash";
import PropTypes from "prop-types";
import React from "react";

import styles from "modules/carbon_enrollment/styles";
import useYearFieldCrops from "modules/fields/hooks/useYearFieldCrops";

import { Button } from "components/fl-ui";
import CropMapImage from "fields/components/CropMapImage";

const CURRENT_YEAR = new Date().getFullYear();

const GroupedFields = ({ fields, groupName, onSelect, selectedFields, year = CURRENT_YEAR }) => {
  const { getYearCropsForField } = useYearFieldCrops(year);
  const hasAllSelected = fields.every((field) => selectedFields.has(field));

  return (
    <>
      <div className={css(styles.subContainer)}>
        <p className={css(styles.upperCaseText)}>
          {`${groupName} · ${fields.length} ${fields.length === 1 ? "field" : "fields"}`}
        </p>

        <Button
          className={css(styles.upperCaseText)}
          color="primary"
          link
          onClick={() => onSelect(fields, hasAllSelected)}
        >
          {hasAllSelected ? "Deselect" : "Select"} all
        </Button>
      </div>

      {fields.map((field) => {
        const isSelected = selectedFields.has(field);
        const crops = getYearCropsForField(field?.id);

        return (
          <div className={css(styles.subContainer, isSelected && styles.selected)} key={field.id}>
            <div className={css(styles.fieldContainer)}>
              <div className={css(styles.mapContainer, isSelected && styles.selectedBorder)}>
                <CropMapImage className={css(styles.mapImage)} crops={crops} field={field} />
              </div>
              <div>
                <p className={css(styles.optionHeader)}>{upperFirst(field.name)}</p>
                <p className={css(styles.optionSubText)}>{field.acreage.toFixed(1)} ac</p>
              </div>
            </div>

            <Button
              className={css(styles.upperCaseText, isSelected && styles.selectedBorder)}
              color="primary"
              hollow
              onClick={() => onSelect(field)}
            >
              {isSelected ? "Selected" : "Select"}
            </Button>
          </div>
        );
      })}
    </>
  );
};

GroupedFields.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  groupName: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedFields: PropTypes.object,
};

export default GroupedFields;
