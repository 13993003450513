import { gql } from "@apollo/client";

const createMaintenanceRecordMutation = gql`
  mutation createMaintenanceRecord($input: CreateMaintenanceRecordInput!) {
    maintenanceRecord: createMaintenanceRecord(input: $input)
      @rest(type: "MaintenanceRecord", path: "/api/maintenance_records", method: "POST") {
      ...MaintenanceRecord
    }
  }
`;

export default createMaintenanceRecordMutation;
