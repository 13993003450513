import PropTypes from "prop-types";
import React, { useMemo } from "react";

import useUnits from "hooks/useUnits";

const EnumCopy = ({ data, enumName }) => {
  const { data: enumData, findEnumValue } = useUnits();
  const copy = useMemo(() => findEnumValue(enumName, data)?.display ?? "", [enumData]);

  return <>{copy}</>;
};
EnumCopy.propTypes = {
  data: PropTypes.string.isRequired,
  enumName: PropTypes.string.isRequired,
};

export const ContractStatusCopy = ({ status }) => <EnumCopy data={status} enumName="ContractStatus" />;
ContractStatusCopy.propTypes = {
  status: PropTypes.oneOf(["CLOSED", "OPEN", "PENDING", "UNFILLED", "UNPRICED"]).isRequired,
};

export const ContractSubtypeCopy = ({ subtype, product }) => {
  const { data, getEnumKeyValues } = useUnits();
  let displayCopy = useMemo(
    () =>
      ({
        ...getEnumKeyValues("CashContractType"),
        ...getEnumKeyValues("FuturesContractType"),
        ...getEnumKeyValues("OptionsContractType"),
      }[subtype] ?? ""),
    [data]
  );

  if (product) {
    displayCopy += " - " + product;
  }
  return displayCopy;
};

ContractSubtypeCopy.propTypes = {
  subtype: PropTypes.oneOf([
    // CashContractType
    "AUTOHEDGE",
    "AUTOMATIC",
    "BASIS",
    "CASH",
    "HEDGE_TO_ARRIVE",
    "MIN_MAX",
    "MIN_MAX_AUTOMATIC",
    "OTHER",
    "SPOT",
    // FuturesContractType
    "LONG_FUTURES",
    "SHORT_FUTURES",
    // OptionsContractType
    "LONG_CALL",
    "LONG_PUT",
    "SHORT_CALL",
    "SHORT_PUT",
  ]).isRequired,
};
