import LayersMap from "map/LayersMap";
import Nav from "map/layers/Nav";
import PropTypes from "prop-types";
import React from "react";

import MapUtils from "lib/map/utils";

import Fullscreen from "components/ui/fullscreen";

const fullscreenStyle = {
  background: "black",
  height: "100%",
};

class Layers extends React.Component {
  handleChange = ({ target }) => {
    const { maxBounds } = this.state.mapValue;
    const zoom = target.getZoom();
    const center = target.getCenter();

    return this.setMapValue({ center, zoom, maxBounds });
  };

  state = {
    splitMode: false,
    mapValue: {
      maxBounds: MapUtils.getMaxBounds(this.props.geometry).pad(0.6),
    },
    mapEvents: [["move", this.handleChange]],
  };

  componentDidUpdate(prevProps) {
    const { geometry } = this.props;
    if (prevProps.geometry !== geometry) {
      const maxBounds = MapUtils.getMaxBounds(geometry);
      const center = maxBounds.getCenter();
      // clear zoom and allow mapBounds to find proper zoom level
      return this.setMapValue({ maxBounds, center, zoom: null });
    }
  }

  setMapValue = (mapValue) => {
    return this.setState({ mapValue });
  };

  handleSplit = () => {
    return this.setState({ splitMode: !this.state.splitMode });
  };

  render() {
    const { allLayers, data, defaultLayer, error, id, layers, loading } = this.props;
    const { mapEvents, mapValue, splitMode } = this.state;
    const commonProps = {
      allLayers,
      crossHair: splitMode,
      data,
      error,
      id,
      layers,
      loading,
      on: mapEvents,
      value: mapValue,
    };

    return (
      <Fullscreen id="map-wrapper">
        <Nav value={id} onSplit={this.handleSplit} splitMode={splitMode} />
        <div id="map-container" style={{ marginTop: "50px" }}>
          <div style={fullscreenStyle}>
            <div style={fullscreenStyle}>
              <LayersMap {...commonProps} defaultLayer={defaultLayer} zoom={!splitMode} />
            </div>

            {splitMode && (
              <div className="col xs-6" style={fullscreenStyle}>
                <LayersMap {...commonProps} zoom={true} />
              </div>
            )}
          </div>
        </div>
      </Fullscreen>
    );
  }
}

Layers.propTypes = {
  data: PropTypes.object,
  defaultLayer: PropTypes.object,
};

Layers.defaultProps = {
  data: {},
  loading: true,
};

export default Layers;
