import { useQuery } from "@apollo/client";
import { FERTILIZER } from "equipment/constants";
import ImplementForm from "equipment/forms/ImplementForm";
import implementFormSchema from "equipment/forms/validation/implementFormSchema";
import _ from "lodash";
import validateSchema from "products/forms/validation/validateSchema";
import PropTypes from "prop-types";
import React, { useState } from "react";

import useImplementMutations from "collection/graphql/equipment/hooks/useImplementMutations";
import { getImplementTypes } from "collection/graphql/equipment/queries";
import usePermissions from "hooks/usePermissions";
import App from "layout/app";

import { Button } from "components/fl-ui";
import { CloseX } from "components/fl-ui/Icons";
import { Cluster } from "components/fl-ui/Layout";
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "components/fl-ui/Modal/Modal";

const implementToFormFields = (implement) => {
  if (implement) {
    const formFields = _.pick(implement, ["icon", "id", "isRented", "name", "notes"]);
    formFields.implementTypeId = implement.implementType.id;
    formFields.manufactureYear = implement.manufactureYear === null ? "" : implement.manufactureYear;

    return formFields;
  } else {
    return {
      icon: "",
      implementTypeId: FERTILIZER,
      isRented: null,
      manufactureYear: "",
      name: "",
      notes: "",
    };
  }
};

const ImplementModal = (props) => {
  const { onClose, onDelete, onSaveSuccess, implement } = props;
  const { id, name } = implement ?? {};
  const isCreate = !id;
  const canDelete = usePermissions().canDelete("equipment");

  const { data, loading } = useQuery(getImplementTypes);
  const implementTypes = data?.types || [];
  const { deleteImplement, saveImplement } = useImplementMutations();

  const [fields, setFields] = useState(implementToFormFields(implement));
  const { form, isValid } = validateSchema(implementFormSchema, fields);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (form.manufactureYear === "") {
      delete form.manufactureYear;
    }

    await saveImplement(form);
    onSaveSuccess();
  };

  const handleDelete = () => {
    App.confirm({
      confirm: "DELETE",
      message: `Delete "${name}" and all of its associated data?`,
      title: "Are you sure?",
    }).then(async () => {
      await deleteImplement(id);
      onDelete(id);
    });
  };

  return loading ? null : (
    <Modal id="implementFormModal" width={500}>
      <ModalHeader>
        <ModalTitle>{isCreate ? "Add a new implement" : `Edit ${name}`}</ModalTitle>
        <CloseX onClick={onClose} />
      </ModalHeader>

      <ModalBody>
        <ImplementForm
          formFields={fields}
          formId="implementForm"
          implementTypes={implementTypes}
          onChange={(updatedFields) =>
            setFields((fields) => ({
              ...fields,
              ...updatedFields,
            }))
          }
          onSubmit={handleSubmit}
        />
      </ModalBody>

      <ModalFooter>
        <Cluster between={!isCreate} end={isCreate}>
          {!isCreate && canDelete && (
            <Button color="danger" link onClick={handleDelete} type="button">
              Delete
            </Button>
          )}

          <span>
            <Button color="default" onClick={onClose} type="button">
              Cancel
            </Button>{" "}
            <Button color="primary" disabled={!isValid} form="implementForm" type="submit">
              {isCreate ? "Add implement" : "Update implement"}
            </Button>
          </span>
        </Cluster>
      </ModalFooter>
    </Modal>
  );
};

ImplementModal.propTypes = {
  implement: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSaveSuccess: PropTypes.func.isRequired,
};

export default ImplementModal;
