import { gql } from "@apollo/client";

import { SubscriptionProductFragment } from "collection/graphql/subscription/fragments";

export default gql`
  query getAllProducts {
    allProducts: getAllProducts @rest(type: "SubscriptionProduct", path: "/subscriptions/products") {
      ...SubscriptionProductFragment
    }
  }

  ${SubscriptionProductFragment}
`;
