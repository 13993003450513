import MobilePaymentError from "billing/exceptions/MobilePaymentError";
import SubscriptionPluginError from "billing/exceptions/SubscriptionPluginError";
import _ from "lodash";
import { Purchases } from "mobile/purchases/Purchases";
import { PurchasesPackagesSchema } from "mobile/purchases/schemas";

import { fetchCurrentEnterprise } from "lib/fetchCurrentEnterprise";
import { fetchCurrentUser } from "lib/fetchCurrentUser";

/**
 * Period is defined by Revenuecat, Interval is defined by "/v2.0/subscriptions/products" API
 * Only P1M and P1Y supported for now
 * @param {"P1M"|"P1Y"} period
 * @return {"month"|"year"}
 */
const convertPeriodToInterval = (period) => {
  const supportedPeriods = {
    P1M: "month",
    P1Y: "year",
  };

  if (period in supportedPeriods) {
    return supportedPeriods[period];
  } else {
    throw new Error("Period is not supported: " + period);
  }
};

/**
 * Accepts a list of web products and returns a new product array with the available plans
 * modified to reflect product data from the app stores via RevenueCat.
 *
 * @param {SubscriptionProduct[]} allWebProducts
 * @return {Promise<{SubscriptionProduct[]}>}
 */
async function getMobileProducts(allWebProducts) {
  const currentUser = await fetchCurrentUser();
  const userId = currentUser?.id;
  if (!userId) {
    throw new MobilePaymentError("Failed to fetch user ID");
  }

  const currentEnterprise = await fetchCurrentEnterprise();
  const enterpriseId = currentEnterprise?.id;
  if (!enterpriseId) {
    throw new MobilePaymentError("Failed to fetch enterprise ID");
  }

  await Purchases.ensureSupported();
  await Purchases.configure(userId, enterpriseId);
  const mobilePackages = await Purchases.getAvailablePackages();
  if (!PurchasesPackagesSchema.isValidSync(mobilePackages)) {
    throw new SubscriptionPluginError("Invalid mobile packages", { mobilePackages });
  }

  const webProducts = _.reject(allWebProducts, { display: "hidden" });
  // pair purchasable mobile packages to web products by mobileId
  const pairs = _.reduce(
    mobilePackages,
    (output, mobilePackage) => {
      const matchingWebProduct = webProducts.find(({ mobileIds }) =>
        mobileIds.includes(mobilePackage.product.identifier)
      );

      if (matchingWebProduct) {
        output.push({ mobilePackage, webProduct: matchingWebProduct });
      }

      return output;
    },
    []
  );
  // adjust web product plans to match mobile package (interval and price)
  const mobileProducts = pairs.map(({ mobilePackage, webProduct }) => {
    const plans = [
      {
        interval: convertPeriodToInterval(mobilePackage.product.subscriptionPeriod),
        price: mobilePackage.product.price,
      },
    ];

    return Object.assign(_.cloneDeep(webProduct), { plans });
  });
  return mobileProducts;
}

export default getMobileProducts;
