import { capitalize } from "lodash";
import { Table, TableBody } from "profit_and_loss/styles";
import React from "react";

import { withField } from "collection/graphql/agreements";
import { wrapWithProvider, marketingClient } from "collection/graphql/client";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import useReleaseFeature from "hooks/useReleaseFeature";
import useUnits from "hooks/useUnits";

import EstimatedLandValue from "components/field/EstimatedLandValue";
import FieldAgreements from "components/field/FieldAgreements";
import FieldDetailsTableRow from "components/field/FieldDetailsTableRow";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";

const FieldDetailsTable = ({ data, fieldId, onAddLandValue }) => {
  const { field, loading } = data || {};
  const sourceEnumValue = useUnits().findEnumValue("FieldSource", field?.source)?.display || "";
  const hasManualImportFeature = useReleaseFeature("manual-import");
  const hasMachineData = useEnterpriseFeature("machine_data");

  if (loading && !field) {
    return <LoadingWrapper />;
  }

  const [longitude, latitude] = field.centroid.coordinates;
  const latLng = `${latitude.toFixed(5)}, ${longitude.toFixed(5)}`;

  return (
    <>
      <Table>
        <TableBody bordered borderTop>
          <EstimatedLandValue field={field} onAddLandValue={onAddLandValue} />
          <FieldDetailsTableRow label="Lat/Lng" value={latLng} />
          {field.county && <FieldDetailsTableRow label="County" value={field.county.name} />}
          {field.township && <FieldDetailsTableRow label="Township Name" value={field.township.name} />}
          {!!field.section && <FieldDetailsTableRow label="Section" value={field.section.name} />}
          {!!field.township && <FieldDetailsTableRow label="Township/Range" value={field.township.range} />}
          {!!field.fsaFarm && <FieldDetailsTableRow label="FSA Farm" value={field.fsaFarm} />}
          {!!field.fsaTract && <FieldDetailsTableRow label="FSA Tract" value={field.fsaTract} />}
          <FieldDetailsTableRow label="Irrigated" value={field.isIrrigated ? "Yes" : "No"} />
          <FieldDetailsTableRow label="Ownership" value={field.isRented ? "Rented" : "Owned"} />
          {field.source && hasMachineData && hasManualImportFeature && (
            <FieldDetailsTableRow label="Source" value={capitalize(sourceEnumValue)} />
          )}
          {field.isRented && field.landlord && <FieldDetailsTableRow label="Landlord" value={field.landlord} />}
          {field.notes && <p>{field.notes}</p>}
        </TableBody>
      </Table>

      <FieldAgreements fieldId={fieldId} />
    </>
  );
};

export default wrapWithProvider(withField(FieldDetailsTable), marketingClient);
