import _ from "lodash";
import React from "react";

import { notificationsClient, wrapWithProvider } from "collection/graphql/client";
import { dismissAllNotifications, dismissNotification, pollUserNotifications } from "collection/graphql/notifications";

export default (wrapped) =>
  wrapWithProvider(
    _.flowRight(dismissAllNotifications, dismissNotification, pollUserNotifications)(wrapped),
    notificationsClient
  );
