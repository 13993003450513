import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";

import { marketingClient } from "collection/graphql/client";
import { getLatestSatelliteImages } from "collection/graphql/fields/queries";

const useLatestImagery = (fields = [], imageCount = 1) => {
  const [layers, setLayers] = useState([]);
  const { data, loading } = useQuery(getLatestSatelliteImages, {
    client: marketingClient,
    variables: { fields, imageCount },
  });

  useEffect(() => {
    if (!loading && data?.layers) {
      setLayers(data.layers);
    }
  }, [fields, imageCount]);

  return { layers, loading };
};

export default useLatestImagery;
