import React from "react";

import { useFarmAdvisorConfig } from "collection/graphql/advisor";

const AdvisorSponsorImage = () => {
  const { id, isEnabled, programName } = useFarmAdvisorConfig().data;
  if (!isEnabled) {
    return null;
  }

  switch (id) {
    case "CARGILL":
      return <img alt={programName} height={40} src="/images/advisor/providers/elevate_plus/logo.svg" />;

    case "FARMERSCO":
      return <img alt={programName} height={40} src="/images/advisor/providers/farmersco/logo.svg" />;

    default:
      return null;
  }
};

export default AdvisorSponsorImage;
