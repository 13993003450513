import { StyleSheet } from "aphrodite/no-important";

import { UIColors } from "components/fl-ui/colors/index";
import { Typography, Borders, Mixins, Spacing } from "components/fl-ui/constants/index";

// common styles used within the reports module
const reportStyleSheet = StyleSheet.create({
  cropPosition: {
    marginBottom: Spacing.large,
  },
  header_wCropBadge: {
    display: "flex",
    alignItems: "baseline",
    paddingBottom: Mixins.toRem(24),
    marginBottom: Mixins.toRem(24),
    borderBottom: Borders.regular,
  },
  summaryContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "stretch",
    flexWrap: "wrap",
    paddingBottom: Spacing.regular,
    borderBottom: Borders.light,
    marginBottom: Spacing.large,
  },
  summaryItem: {
    flexGrow: 2,
    textAlign: "left",
    marginTop: 0,
    display: "block",
  },
  summaryItemTitle: {
    fontSize: Typography.sizes.rg,
    lineHeight: 1.38,
    color: UIColors.medium,
    textTransform: "uppercase",
  },
  summaryItemValue: {
    display: "block",
    fontSize: Typography.sizes.xxl,
    fontWeight: Typography.weights.medium,
    lineHeight: 1.375,
  },
  reportTable: {
    marginBottom: Spacing.large,
  },
});

export default reportStyleSheet;
