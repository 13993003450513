import leaflet from "leaflet";
import { once } from "lodash";
import render from "map/templates";

import { MAP_FARM_BOUNDARIES_PRESS, MAP_USER_LOCATION_PRESS } from "lib/metrics/events";

export default new (leaflet.Control.extend({
  includes: L.evented,
  geolocationDisabled: !leaflet.Browser.mobile,

  requestPermission: once(async function () {
    const onPermissionChange = (eventOrPermission) => {
      const { state } = eventOrPermission instanceof Event ? eventOrPermission.target : eventOrPermission;
      this.geolocationDisabled = state !== "granted";
      this.render();
    };

    try {
      if (!leaflet.Browser.mobile && navigator.permissions) {
        const permission = await navigator.permissions.query({ name: "geolocation" });
        permission.onchange = onPermissionChange;
        if (permission.state === "granted") {
          onPermissionChange(permission);
        }
      }
    } catch (error) {
      console.error("Unable to query geolocation permission", error);
    }
  }),

  options: {
    position: "topleft",
  },

  el: leaflet.DomUtil.create("div", "map-notification-wrapper"),

  onAdd(map) {
    this.map = map;
    this.render();
    leaflet.DomEvent.addListener(this.el, "click", this.onClick, this);

    return this.el;
  },

  onClick(event) {
    event.stopPropagation();

    if (leaflet.DomUtil.hasClass(event.target, "js-show-fields")) {
      //geometry geoJson gets all the map coordinates of fields
      const myLayer = new leaflet.geoJson();
      myLayer.addData(this.fields.map(({ geometry }) => geometry));
      //getbounds and then fits the map
      this.map.fitBounds(myLayer.getBounds());
      MAP_FARM_BOUNDARIES_PRESS.track();
    }

    if (
      leaflet.DomUtil.hasClass(event.target, "js-my-location") &&
      !leaflet.DomUtil.hasClass(event.target, "disabled")
    ) {
      this.map.setView(this.map.getUserLocation(), 18);
      MAP_USER_LOCATION_PRESS.track();
    }

    leaflet.DomEvent.stopPropagation(event);
  },

  render() {
    this.requestPermission();
    this.el.innerHTML = render("control.toolbar", { geolocationDisabled: this.geolocationDisabled });

    return this;
  },

  onRemove() {
    this.map = null;
    leaflet.DomEvent.removeListener(this.el, "click", this.onClick);
  },
}))();
