const theme = {
  flexboxgrid: {
    // Defaults
    gridSize: 12, // rem
    gutterWidth: 1.25, // rem
    outerMargin: 1, // rem
    mediaQuery: "only screen",
    container: {
      // not really important since we're only using fluid containers
      sm: 39, // rem
      md: 59, // rem
      lg: 72.5, // rem
    },
    breakpoints: {
      xs: 0, // em
      sm: 40, // em - ~479.99px min-width
      md: 60, // em - ~799.99px min-width
      lg: 75, // em - ~1200px min-width
    },
  },
};

const Variables = {
  baseRem: 16,
  gridTheme: theme,
};

export default Variables;
