import React from "react";

import BlankSlate from "components/fl-ui/BlankSlate";

const ProductFilterBlankSlate = () => {
  return (
    <BlankSlate icon="inputs" title="No matching purchases">
      <p style={{ margin: "20px auto" }}>Refine your filters to see purchases.</p>
    </BlankSlate>
  );
};

export default ProductFilterBlankSlate;
