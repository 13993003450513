import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

export const GET_ACTIVITY_TYPES_AND_CATEGORIES = gql`
  query getActivityTypes {
    activityTypes: getActivityTypes {
      id
      category
      isArchived
      isDefault
      name
      operationalCost
      operationalCostRate
    }
    categories: getEnum(enum: "ActivityTypeCategory") {
      data
      display
    }
  }
`;

const withActivityTypes = graphql(GET_ACTIVITY_TYPES_AND_CATEGORIES);
export default withActivityTypes;
