import useActivitiesProvider from "activity/hooks/useActivitiesProvider";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

import { TagSelectGroup } from "components/fl-ui/Form";

const ActivityCommodityChooser = (props) => {
  const { onChange, value } = props;
  const { allActivities } = useActivitiesProvider();

  const allCommodities = useMemo(() => {
    const allCommodities = _.map(_.flatMapDeep(allActivities, "crop"), "commodity");
    const uniqueCommodities = _.uniqBy(allCommodities, "id");
    const sortedCommodities = _.sortBy(uniqueCommodities, ({ name }) => name.toLowerCase());

    return sortedCommodities;
  }, [allActivities]);

  const options = useMemo(
    () =>
      allCommodities.map(({ id, name }) => ({
        label: name,
        value: id,
      })),
    [allCommodities]
  );

  return (
    <TagSelectGroup
      id="crop"
      isMulti
      label="Commodity"
      onChange={(commodities) => onChange(commodities)}
      options={options}
      showBulkSelect
      value={value}
    />
  );
};

ActivityCommodityChooser.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default ActivityCommodityChooser;
