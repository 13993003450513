import { gql } from "@apollo/client";

export const query = `
  query getWeatherForecast($fieldId: Int!, $startDateTime: String) {
    forecast: getWeatherForecast(fieldId: $fieldId, startDateTime: $startDateTime) {
      dailyForecast {
        isBeforeSunset  
        rainStartIntensity
        rainStartProbability
        rainStartTime
        rainEndTime
        temperatureMaxF
        temperatureMinF
        windDirection
        windGust
        windGustTime
      }
      weeklyForecast {
        date
        windGust
        windGustTime
      }
    }
  }
`;

export default gql(query);
