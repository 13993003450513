import { css, StyleSheet } from "aphrodite";
import classNames from "classnames";
import React from "react";

import { GreyColors, UIColors } from "components/fl-ui/colors";
import { Spacing, BorderRadius, Typography } from "components/fl-ui/constants";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    border: `1px solid ${UIColors.primary}`,
    borderRadius: BorderRadius.medium,
    overflow: "hidden",
  },
  itemBase: {
    padding: `${Spacing.xsmall} ${Spacing.regular}`,
    margin: 0,
    color: UIColors.primary,
    fontSize: Typography.sizes.sm,
    fontWeight: Typography.weights.medium,
    lineHeight: 1,
    letterSpacing: "0.92px",
    ":hover": {
      backgroundColor: GreyColors.smoke95,
      cursor: "pointer",
    },
  },
  itemActive: {
    backgroundColor: UIColors.primary,
    color: "white",
    ":hover": {
      backgroundColor: UIColors.primary,
      cursor: "pointer",
    },
  },
});

const SegmentedControl = ({ className, onChange, options, value }) => (
  <div className={classNames(css(styles.container), className)}>
    {options.map(({ label, optionValue }, i) => (
      <span
        key={i}
        id={`segmented-control-${optionValue}`}
        onClick={() => {
          onChange(optionValue);
        }}
        onKeyDown={() => null}
        className={css(styles.itemBase, optionValue === value && styles.itemActive)}
      >
        {label}
      </span>
    ))}
  </div>
);

export default SegmentedControl;
