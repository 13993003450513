import getDefaultActivityFilters from "activity/hooks/lib/getDefaultActivityFilters";
import useActivityQueryParams from "activity/hooks/useActivityQueryParams";
import _ from "lodash";
import React, { createContext, useContext, useMemo, useState } from "react";

import { useUserConfig } from "collection/graphql/config";

import { withSuspenseWrapper } from "components/fl-ui/LoadingWrapper";

/**
 * @typedef {Object} ActivityFiltersContext
 * @property {Object} filters
 * @property {function} resetFilters
 * @property {function} updateFilters
 */

/**
 * A hook to manage the currently applied filters as well as several functions for updating these filters.
 *
 * @private
 * @return {ActivityFiltersContext}
 */
export const useActivityFilters = () => {
  const [queryParams, setQueryParams] = useActivityQueryParams();
  const [search, setSearch] = useState("");

  const filters = useMemo(() => {
    const implementList = queryParams.implements.map((id) => ({ id, __typename: "Implement" }));
    const tractors = queryParams.tractors.map((id) => ({ id, __typename: "Tractor" }));

    return {
      ...getDefaultActivityFilters(),
      ..._.omit(queryParams, ["implements", "tractors"]),
      equipment: implementList.concat(tractors),
      search,
    };
  }, [queryParams, search]);

  return useMemo(
    () => ({
      filters,
      resetFilters: () => {
        setSearch("");
        setQueryParams({});
      },
      updateFilters: (updatedFilters) => {
        const mergedFilters = { ...filters, ...updatedFilters };
        const { search, ...rest } = mergedFilters;
        if ("search" in mergedFilters) {
          setSearch(search);
        }

        setQueryParams(rest);
      },
    }),
    [filters]
  );
};

/**
 * @type {React.Context<ActivityFiltersContext>}
 */
export const context = createContext(undefined);

/**
 * @typedef {React.FC} ActivityFiltersContextProvider
 */
/**
 * Context provider for {@link ActivityFiltersContext}.
 *
 * @param {React.Props} props
 * @param {React.Children} props.children
 * @return {JSX.Element}
 * @type {ActivityFiltersContextProvider}
 */
export const Provider = withSuspenseWrapper(({ children }) => {
  /*
   * userConfig loads via Suspense. it's currently used by getDefaultActivityFilters to generate default filters. this
   * ensures that the required data is loaded before rendering the subtree.
   */
  useUserConfig();
  return <context.Provider value={useActivityFilters()}>{children}</context.Provider>;
});

/**
 * Requires context from {@link ActivityFiltersContextProvider}.
 * @return {ActivityFiltersContext}
 */
export default () => {
  const hookContext = useContext(context);
  if (!hookContext) {
    throw new Error("Invalid context: ActivityFiltersContext expected");
  }

  return hookContext;
};
