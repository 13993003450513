import { Table } from "profit_and_loss/styles";
import { Row } from "react-styled-flexboxgrid";
import styled from "styled-components";

import { Button } from "components/fl-ui";
import { UIColors } from "components/fl-ui/colors";
import { Borders, BorderRadius, Spacing, Typography } from "components/fl-ui/constants";

export const AttachmentContainer = styled(Row)`
  border: ${Borders.regular};
  margin: 0;
  padding: ${Spacing.large};

  @media only screen and (max-width: 62.5rem) {
    border: none;
    padding: 0;
  }
`;

export const AttachmentDetailContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-flow: column;
  font-size: ${Typography.sizes.mn};
  justify-content: center;
  line-height: 1.3;
  margin-right: ${Spacing.regular};
  overflow: hidden;
`;

export const AttachmentListItem = styled.div`
  border-bottom: ${Borders.regular};
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: ${Spacing.xsmall} 0;
  width: 100%;

  > div {
    display: flex;
    overflow: hidden;
    max-width: 100%;
  }

  @media only screen and (max-width: 62.5rem) {
    border: ${Borders.regular};
    border-radius: ${BorderRadius.medium};
    margin: ${Spacing.xsmall} 0;
    padding: ${Spacing.small};
  }
`;

export const AttachmentPreview = styled.img`
  height: 22.5rem;
  object-fit: contain;
  padding: ${Spacing.xsmall};
  width: 100%;
`;

export const AttachmentTable = styled(Table)`
  margin-top: ${Spacing.large};
`;

export const AttachmentThumbnail = styled.img`
  border: ${(props) => (props.isSelected ? `4px solid ${UIColors.primary}` : Borders.regular)};
  border-radius: ${BorderRadius.medium};
  height: 4rem;
  margin-right: ${Spacing.regular};
  min-width: 4rem;
  object-fit: contain;
  width: 4rem;

  :hover {
    cursor: ${(props) => (props.onClick ? "pointer" : "default")};
  }
`;

export const BlankDetailContent = styled.div`
  cursor: ${({ role }) => (["button", "link"].includes(role) ? "pointer" : "default !important")};
  display: flex;
  flex-direction: column;
  gap: ${Spacing.small};
  width: 100%;

  p {
    all: unset;
  }

  > span:last-child {
    color: ${UIColors.blue};
  }
`;

export const ButtonContainer = styled.div`
  border-bottom: ${Borders.regular};
  display: flex;
  justify-content: space-between;
  margin: ${Spacing.regular} 0;
  padding: ${Spacing.regular} 0;
`;

export const ButtonLink = styled(Button)`
  max-width: 20rem;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) => props.disabled && "opacity: unset !important"};
`;

export const CheckboxText = styled.div``;

export const CheckboxLabel = styled.label`
  align-items: center;
  display: flex;
  gap: ${Spacing.xsmall};
  line-height: 1.3;
`;

export const DetailsWidgetContainer = styled.div`
  padding-bottom: ${Spacing.regular};
  border-bottom: ${Borders.regular};
  &:last-child {
    border-bottom: none;
  }
  @media only screen and (max-width: 62.5rem) {
    padding: 0;
  }
`;

export const FieldMapButton = styled(Button)`
  display: flex !important;
  justify-content: center !important;
  white-space: initial !important;
  ${({ height, width }) => `height: ${height}px; width: ${width}px`}
`;

export const FooterButtons = styled.div`
  display: flex;
  flex-flow: row-reverse;
  justify-content: space-between;
  margin-bottom: ${Spacing.xxlarge};
  width: 100%;
  @media only screen and (min-width: 62.5rem) {
    margin-top: -${Spacing.xsmall};
  } ;
`;

export const HeaderContent = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: flex-end;
  width: 100%;

  @media only screen and (max-width: 62.5rem) {
    align-items: end;
    justify-content: space-between;
    margin-top: ${Spacing.xsmall};
  }
`;

export const LightHeader = styled.h5`
  border-bottom: ${Borders.regular};
  color: ${UIColors.light};
  margin: 0;
  padding: ${Spacing.xsmall} 0;
  text-transform: uppercase;

  @media only screen and (max-width: 62.5rem) {
    border: none;
    padding: ${Spacing.xxsmall} 0;
  }
`;

export const OverLimitContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  line-height: 1.5;
  padding: ${Spacing.regular};
  text-align: center;
`;

export const RightAlignedContainer = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
`;

export const RightContent = styled.div`
  margin-left: ${Spacing.regular};
`;

export const SidebarHeader = styled.div`
  margin-top: -${Spacing.large};
  text-align: center;
`;

export const StyledLogoContainer = styled.div`
  background: ${UIColors.bushelBlue};
  border-radius: 100%;
  height: ${Spacing.xxxlarge} !important;
  padding: ${Spacing.xsmall};
  width: ${Spacing.xxxlarge} !important;
`;

export const StyledRow = styled.div`
  border-bottom: ${Borders.regular};
  padding: 0 ${Spacing.xxsmall} ${Spacing.regular};

  h4 {
    line-height: 1.3;
  }
  &:first-child {
    padding-bottom: 0;
  }
  &:last-child {
    margin-bottom: ${Spacing.regular};
  }
`;

export const StyledHeader = styled.h3`
  line-height: 1.5;
`;

export const WidgetHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${Spacing.regular} 0 ${Spacing.xsmall};

  @media only screen and (max-width: 62.5rem) {
    margin-top: ${Spacing.regular};
  }
`;
