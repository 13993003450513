import Backbone from "backbone";
import EventEmitter from "events";

export class History extends EventEmitter {
  crumbs = [];
  persistStack;
  initialized = false;
  window;

  constructor(win) {
    super();
    this.window = win;
  }

  initialize() {
    if (!this.initialized) {
      this.initialized = true;
      this.persistStack = matchMedia("screen and (max-width: 62.5rem)").matches;

      const savedCrumbs = sessionStorage.getItem("historyStack");
      if (this.persistStack && savedCrumbs) {
        this.crumbs = JSON.parse(savedCrumbs);
        sessionStorage.removeItem("historyStack");
      } else if (location.hash) {
        this.crumbs.push(location.hash);
      }

      Backbone.history.start();
      this.listenForRouteChange();
    }
  }

  back = () => {
    if (this.crumbs.length > 1) {
      return this.window.history.back();
    }

    this.crumbs = [];
    this.navigate("", { trigger: true });
  };

  canGoBack() {
    return this.crumbs.length > 0;
  }

  get fragment() {
    return Backbone.history.fragment;
  }

  loadUrl(fragment) {
    Backbone.history.loadUrl(fragment);
  }

  navigate(fragment, options) {
    fragment = fragment.replace(/^(#|\/)+/, "");
    return Backbone.history.navigate(fragment, options);
  }

  handleRouteChange() {
    // Look back in the history stack (crumbs) to the page before the current
    // and compare to the page that is about to be loaded.
    if (this.crumbs.length > 1 && this.crumbs[this.crumbs.length - 2] === location.hash) {
      const route = this.crumbs.pop();
      this.emit("historyChange", route.replace(/^#/, ""));
      this.persist();
      return route;
    } else {
      this.crumbs.push(location.hash);
      if (!location.hash.match(/(#log)|(#resources)/)) {
        this.window.scrollTo(0, 0);
      }
      this.emit("historyChange", location.hash.replace(/^#/, ""));
      this.persist();
    }
  }

  get length() {
    return this.crumbs.length;
  }

  listenForRouteChange() {
    this.window.addEventListener(
      "hashchange",
      function (event) {
        this.handleRouteChange({
          newRoute: event.newURL.split("#")[1] || "",
          oldRoute: event.oldURL.split("#")[1] || "",
        });
      }.bind(this)
    );
  }

  persist() {
    if (this.persistStack) {
      sessionStorage.setItem("historyStack", JSON.stringify(this.crumbs));
    }
  }

  /**
   * Normalizes the path/hash routing implementation to return a {@link URL} representing the
   * current location.  To determine the current path for routing or page identification
   * purposes, read the `pathname` property of the returned {@link URL}.
   * @return {URL}
   *
   * @example
   * // if `window.location` = https://example.com/#some/path
   * const url = History.parseCurrentUrl();
   * log(url.pathname); // logs "/some/path"
   *
   * @example
   * // if `window.location` = https://example.com/another/path
   * const url = History.parseCurrentUrl();
   * log(url.pathname); // logs "/another/path"
   */
  parseCurrentUrl() {
    return new URL(this.window.location.toString().replace("#", ""));
  }
}

const instance = new History(window);
export default instance;
