import { gql } from "@apollo/client";

import { FINANCIAL_ACCOUNT_FRAGMENT } from "collection/graphql/profit_and_loss/fragments";

export default gql`
  fragment Transaction on FinancialAccountTransaction {
    id
    allocateType
    amount
    cropYear
    date
    notes
    account {
      ...Account
    }
    allocateCommodityCrops {
      id
      name
    }
    allocateFieldCrops {
      id
      commodity {
        id
        name
      }
      field {
        id
        name
      }
    }
  }
  ${FINANCIAL_ACCOUNT_FRAGMENT}
`;
