import _ from "lodash";

import Collection from "lib/collection";
import naturalSort from "lib/naturalSort";
import Model from "model/bin";

export const BinsCollection = Collection.extend({
  name: "bins",
  model: Model,
  url: "/api/bins",

  comparator(a, b) {
    return naturalSort(a.get("name"), b.get("name"));
  },

  getGroups() {
    return _.compact(
      _.keys(
        this.groupBy(function (bin) {
          let left;
          return (left = bin.get("group")) != null ? left : "";
        })
      )
    );
  },

  // get total capacity of ALL bins
  getTotalCapacity() {
    return +_.sum(this.pluck("capacity"));
  },
});

export default new BinsCollection();
