import ImageryList from "imagery/components/ImageryList";
import { upperFirst } from "lodash";
import PropTypes from "prop-types";
import React from "react";

import Pane from "components/fl-ui/Layout/Pane";

const GroupedLayers = ({ group, layers, type }) => {
  const count = layers.length;

  return (
    <Pane collapsible subtitle={`${count} ${count > 1 ? "fields" : "field"}`} title={upperFirst(group)}>
      <ImageryList layers={layers} type={type} />
    </Pane>
  );
};

GroupedLayers.propTypes = {
  group: PropTypes.string.isRequired,
  layers: PropTypes.arrayOf(PropTypes.object).isRequired,
  type: PropTypes.string.isRequired,
};

export default GroupedLayers;
