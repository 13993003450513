import createReactClass from "create-react-class";
import React from "react";

export default createReactClass({
  displayName: "TableIcon",

  render() {
    return (
      <td>
        {(() => {
          if (this.props.transfer) {
            return (
              <div className="delivery-icon transfer">
                <svg height="16px" width="16px" viewBox="0 0 24 24">
                  <path d="M16.5,7h-2.086l1.293-1.293c0.391-0.391,0.391-1.023,0-1.414s-1.023-0.391-1.414,0L10.586,8l3.707,3.707  C14.488,11.902,14.744,12,15,12s0.512-0.098,0.707-0.293c0.391-0.391,0.391-1.023,0-1.414L14.414,9H16.5c1.379,0,2.5,1.346,2.5,3  s-1.346,3-3,3l0,0c-0.553,0-1,0.448-1,1s0.447,1,1,1l0,0c2.757,0,5-2.243,5-5S18.981,7,16.5,7z" />
                  <path d="M8.293,12.293c-0.391,0.391-0.391,1.023,0,1.414L9.586,15H7.5C6.121,15,5,13.654,5,12s1.346-3,3-3l0,0c0.553,0,1-0.448,1-1  S8.553,7,8,7l0,0c-2.757,0-5,2.243-5,5s2.019,5,4.5,5h2.086l-1.293,1.293c-0.391,0.391-0.391,1.023,0,1.414  C8.488,19.902,8.744,20,9,20s0.512-0.098,0.707-0.293L13.414,16l-3.707-3.707C9.316,11.902,8.684,11.902,8.293,12.293z" />
                </svg>
              </div>
            );
          } else if (this.props.outgoing) {
            return (
              <div className="delivery-icon outgoing">
                <svg height="16px" width="16px" viewBox="0 0 512 512">
                  <path d="M383.6,322.7L278.6,423c-5.8,6-13.7,9-22.4,9c-8.7,0-16.5-3-22.4-9L128.4,322.7c-12.5-11.9-12.5-31.3,0-43.2  c12.5-11.9,32.7-11.9,45.2,0l50.4,48.2v-217c0-16.9,14.3-30.6,32-30.6c17.7,0,32,13.7,32,30.6v217l50.4-48.2  c12.5-11.9,32.7-11.9,45.2,0C396.1,291.4,396.1,310.7,383.6,322.7z" />
                </svg>
              </div>
            );
          } else if (this.props.incoming) {
            return (
              <div className="delivery-icon incoming">
                <svg height="16px" width="16px" viewBox="0 0 512 512">
                  <path d="M383.6,322.7L278.6,423c-5.8,6-13.7,9-22.4,9c-8.7,0-16.5-3-22.4-9L128.4,322.7c-12.5-11.9-12.5-31.3,0-43.2  c12.5-11.9,32.7-11.9,45.2,0l50.4,48.2v-217c0-16.9,14.3-30.6,32-30.6c17.7,0,32,13.7,32,30.6v217l50.4-48.2  c12.5-11.9,32.7-11.9,45.2,0C396.1,291.4,396.1,310.7,383.6,322.7z" />
                </svg>
              </div>
            );
          }
        })()}
      </td>
    );
  },
});
