import ExpenseRevenueDashboardContainer from "profit_and_loss/components/ExpenseRevenueDashboardContainer";

import { wrapWithProvider, marketingClient } from "collection/graphql/client";
import App from "layout/app";
import SubRoute from "lib/SubRoute";

const OtherExpensesRouter = SubRoute.extend({
  routes: {
    "": "index",
  },

  display(page, props) {
    App.display(wrapWithProvider(page, marketingClient), props);
  },

  index() {
    return this.display(ExpenseRevenueDashboardContainer, { screenName: "Other Expenses", type: "EXPENSE" });
  },
});

export default () => new OtherExpensesRouter("other_expenses", { createTrailingSlashRoutes: true });
