import { css } from "aphrodite/no-important";
import PropTypes from "prop-types";
import React, { isValidElement, useRef, useEffect } from "react";

import styles from "components/fl-ui/Form/Input/inputStyles";
import { Container, Prefix, Suffix, withBorder } from "components/fl-ui/Form/Input/ui";

const BasicInput = ({
  controlled,
  disabled,
  onChange,
  prefix,
  suffix,
  isSelected,
  autoSelect,
  value,
  ...additionalProps
}) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (!controlled && value) {
      inputRef.current.value = value;
    }
    if (autoSelect) {
      inputRef.current.select();
    }

    if (additionalProps.type === "number") {
      const onWheel = (event) => {
        event.preventDefault();
        additionalProps.onWheel?.(event);
      };

      inputRef.current?.addEventListener?.("wheel", onWheel, { passive: false });
      return () => inputRef.current?.removeEventListener?.("wheel", onWheel);
    }
  }, []);

  useEffect(() => {
    if (isSelected) {
      inputRef.current.select();
    }
  }, [isSelected]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    onChange(event, { [name]: value });
  };

  if (controlled) {
    additionalProps.value = value;
  }

  return (
    <Container>
      {prefix && !isValidElement(prefix) ? <Prefix disabled={disabled}>{prefix}</Prefix> : prefix}
      <input
        {...additionalProps}
        className={css(styles.input, disabled && styles.input_disabled)}
        disabled={disabled}
        onChange={handleChange}
        ref={inputRef}
      />

      {!!suffix && <Suffix disabled={disabled}>{suffix}</Suffix>}
    </Container>
  );
};

BasicInput.propTypes = {
  controlled: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  type: PropTypes.string,
};

BasicInput.defaultProps = {
  autoComplete: "off",
  autoSelect: false,
  controlled: false,
  onChange: () => {},
  prefix: null,
  suffix: null,
  type: "text",
};

export { BasicInput };
export default withBorder(BasicInput);
