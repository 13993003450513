import _ from "lodash";
import { useEffect, useRef, useState } from "react";

function* keyGenerator() {
  let inputKey = 0;
  while (true) {
    yield inputKey++;
  }
}

/**
 * Provides functionality for managing an array of items when displaying it in a list in the
 * UI.  It generates keys automatically for each array item, and provides methods for add to
 * and removing from the array.
 * @param {any[]} list
 * @param {function} onChange
 */
const useKeyedList = (list, onChange) => {
  const keyGeneratorRef = useRef(keyGenerator());
  const newKey = () => keyGeneratorRef.current.next().value;
  const [keys, setKeys] = useState(() => list.map(newKey));
  useEffect(() => {
    if (list.length !== keys.length) {
      setKeys(list.map(newKey));
    }
  }, [list.length, keys.length]);

  return {
    append: (newListItem) => {
      setKeys(keys.concat(newKey()));
      onChange(list.concat(newListItem));
    },

    map: (callback) => list.map((listItem, i) => callback(listItem, keys[i], i)),

    remove: (key) => {
      const index = keys.indexOf(key);
      setKeys(_.without(keys, keys[index]));
      onChange(_.without(list, list[index]));
    },

    update: (key, value) => {
      const updatedList = [...list];
      updatedList[keys.indexOf(key)] = value;
      onChange(updatedList);
    },
  };
};

export default useKeyedList;
