import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { ColorPalette } from "components/fl-ui/colors";
import useLinkClick from "components/fl-ui/hooks/useLinkClick";

const StyledLink = styled.a`
  color: ${ColorPalette.blue};
`;

const Link = ({ onClick, replace = false, route = null, trigger = true, ...props }) => {
  const { onClick: handleClick } = useLinkClick({
    href: props.href,
    onClick,
    replace,
    route,
    target: props.target,
    trigger,
  });

  return <StyledLink {...props} onClick={handleClick} />;
};

Link.propTypes = {
  href: PropTypes.string,
  onClick: PropTypes.func,
  replace: PropTypes.bool,
  route: PropTypes.string,
  target: PropTypes.string,
  trigger: PropTypes.bool,
};

export default Link;
