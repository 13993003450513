import { css } from "aphrodite";
import React, { useState } from "react";
import router from "router";
import styled from "styled-components";

import withCropMutation from "hoc/withCropMutation";
import useCurrentCropYear from "hooks/useCurrentCropYear";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import usePermissions from "hooks/usePermissions";

import PayGateAdBanner from "components/advertisements/banners/PayGateAdBanner";
import PayGateCommonBlankStateModal from "components/advertisements/modals/PayGateCommonBlankStateModal";
import { Button } from "components/fl-ui";
import CropYearPicker from "components/fl-ui/CropYearPicker";
import Container from "components/fl-ui/Layout/Container";
import Content from "components/fl-ui/Layout/Content";
import ContentMain from "components/fl-ui/Layout/ContentMain";
import Header from "components/fl-ui/Layout/Header";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import styles from "fields/common/styles";
import { FieldView } from "fields/components";
import ChooseMethodModal from "fields/components/add/ChooseMethodModal";
import BlankSlate from "fields/components/blank_slate";
import useFieldData from "fields/hooks/useFieldData";

const HeaderControls = styled.div`
  display: flex;

  > * {
    margin-right: 0.5rem;
  }

  @media only screen and (max-width: 30rem) {
    flex-flow: row wrap;
    > * {
      margin-bottom: 0.75rem;
    }
  }
`;

const FieldIndex = ({ createCrop, urlParams }) => {
  const [showPayGateModal, setShowPayGateModal] = useState(false);
  const [showModal, setShowModal] = useState(urlParams?.modalId === "acreLimit");
  const year = useCurrentCropYear()[0];
  const fieldData = useFieldData();
  const { fields, loading, totalFieldCount } = fieldData;
  const isLoadingFields = loading && !fields;
  const hasFields = totalFieldCount > 0;
  const hasFieldsFeature = useEnterpriseFeature("fields");
  const showPageLevelPayGate = !hasFieldsFeature && totalFieldCount !== 1;
  const showDataDownload = showPageLevelPayGate && totalFieldCount > 1;
  const canAddFields = hasFieldsFeature || totalFieldCount < 1;

  const hasWritePermissions = usePermissions().hasPermission("fields", ["write"]);

  const handlePaygate = () => (hasFields ? router.navigate("billing", { trigger: true }) : setShowModal(true));
  const headerDescription = `${totalFieldCount} ${totalFieldCount === 1 ? "field" : "fields"}, ${
    fieldData?.totalAcreage
  } acres`;

  const handleAddCropClick = () => {
    if (canAddFields) {
      createCrop();
    } else {
      setShowPayGateModal(true);
    }
  };

  const handleAddFieldClick = () => {
    if (canAddFields) {
      setShowModal(true);
    } else {
      setShowPayGateModal(true);
    }
  };

  return (
    <Container>
      <Header description={!isLoadingFields ? headerDescription : null} title="Fields">
        <HeaderControls>
          {hasFields && hasWritePermissions && (
            <Button color="primary" disabled={isLoadingFields} onClick={handleAddCropClick}>
              Add crop
            </Button>
          )}
          {hasWritePermissions && (
            <Button color="primary" disabled={isLoadingFields} onClick={handleAddFieldClick}>
              Add field
            </Button>
          )}
          <CropYearPicker />
        </HeaderControls>
      </Header>

      <LoadingWrapper isLoading={isLoadingFields} loadingText={"Loading fields\u2026"}>
        {showPageLevelPayGate && (
          <>
            <PayGateAdBanner onClick={handlePaygate} />
            {showDataDownload && (
              <div className={css(styles.alignCenter)}>
                <a className={css(styles.blueText)} href="#settings/data">
                  Click here
                </a>{" "}
                to download your account history.
              </div>
            )}
          </>
        )}

        {!showPageLevelPayGate && !hasFields && <BlankSlate />}

        {!showPageLevelPayGate && hasFields && (
          <Content>
            <ContentMain fullWidth>
              <FieldView urlParams={urlParams} year={year} {...fieldData} />
            </ContentMain>
          </Content>
        )}

        {showModal && <ChooseMethodModal onClose={() => setShowModal(false)} />}
        {showPayGateModal && (
          <PayGateCommonBlankStateModal banner={PayGateAdBanner} onClose={() => setShowPayGateModal(false)} />
        )}
      </LoadingWrapper>
    </Container>
  );
};

export default withCropMutation(FieldIndex);
