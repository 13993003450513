import { gql } from "@apollo/client";

import { PricelineLocation } from "collection/graphql/contracts/fragments";

export default gql`
  mutation CreatePricelineLocation($input: CreatePricelineLocationInput!) {
    createPricelineLocation(input: $input) {
      pricelineLocation {
        ...PricelineLocation
      }
    }
  }
  ${PricelineLocation}
`;
