import leaflet from "leaflet";

import MapUtils from "lib/map/utils";

const tileDefaults = {
  maxZoom: 19,
  reuseTiles: true,
  tileSize: 512,
  zoomOffset: -1,
};

export default leaflet.TileLayer.extend({
  /**
   * @param {Object} [options={}]
   * @param {Boolean} [options.isLayerOnly=false]
   * @returns {*}
   */
  initialize(options = {}) {
    /*
     * CORE-6095 by passing `layer_only` to `getImageUrl()` we prevent the loading of the
     * background satellite image without breaking Leaflet functionality. Unfortunately
     * an existing config option to prevent the addition of the img tag could not be found.
     */
    const url = MapUtils.getImageUrl({
      type: options.isLayerOnly ? "layer_only" : "sat_label",
    });

    return leaflet.TileLayer.prototype.initialize.call(this, url, {
      ...tileDefaults,
      ...options,
    });
  },
});
