import { css } from "aphrodite";
import CardInput from "billing/components/payment/CardInput";
import PointOfSale from "billing/containers/PointOfSale";
import { styles } from "billing/lib";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useReducer } from "react";
import { useFormContext } from "react-hook-form";
import { Col, Row } from "react-styled-flexboxgrid";

import { usePaymentMethod } from "collection/graphql/subscription";
import { TIER_SELECTION_PAYMENT_MODAL_ERROR } from "lib/metrics/events";

import { Button } from "components/fl-ui";
import CloseX from "components/fl-ui/Icons/CloseX";
import { Modal, ModalHeader, ModalTitle, ModalBody, ModalFooter } from "components/fl-ui/Modal/Modal";

const ErrorBanner = ({ className, error }) => {
  const { message } = error;
  useEffect(() => {
    TIER_SELECTION_PAYMENT_MODAL_ERROR.track({ error });
  }, []);

  return <div className={`${className} ${css(styles.pos_transactionError)}`}>{message}</div>;
};

/**
 * Returns a boolean indicating whether the submit button should be disabled.
 *
 * @param {Object} criteria
 * @param {Number|String} criteria.addressZip
 * @param {Boolean} criteria.agreedToTerms
 * @param {Boolean} criteria.hasValidCard
 * @param {Boolean} criteria.isLoading
 * @param {Boolean} criteria.paymentIsValid
 * @return {Boolean}
 */
export const isSubmitDisabled = (criteria) => {
  const { addressZip, agreedToTerms, hasValidCard, isLoading, paymentIsValid } = criteria;
  const zipLength = (addressZip + "").trim().length;
  if (isLoading || zipLength === 0) {
    return true;
  } else if (!hasValidCard && !agreedToTerms) {
    return true;
  }

  return !paymentIsValid;
};

const reducer = (state, action) => {
  switch (action.type) {
    case "beginSubmit":
      return {
        ...state,
        error: null,
        isLoading: true,
      };

    case "onError":
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };

    case "updateBillingInfo":
      return {
        ...state,
        ..._.pick(action, ["addressZip", "name"]),
      };

    case "updatePaymentValidity":
      return {
        ...state,
        paymentIsValid: action.paymentIsValid,
      };
  }
};

const PaymentModal = PointOfSale(
  ({ defaultZipCode, onCardUpdate, onClose, onTokenReceipt, stripe: { createToken } }) => {
    const {
      data: { hasValidCard },
      updatePaymentMethod,
    } = usePaymentMethod();

    const [state, dispatch] = useReducer(reducer, {
      addressZip: defaultZipCode,
      error: null,
      isLoading: false,
      name: "",
      paymentIsValid: false,
    });
    const { error, isLoading, paymentIsValid } = state;

    const form = useFormContext();
    const submitDisabled = isSubmitDisabled({
      addressZip: state.addressZip,
      agreedToTerms: form.watch("agreedToTerms"),
      hasValidCard,
      isLoading,
      paymentIsValid,
    });

    const handleValidationChange = useCallback((paymentIsValid) => {
      dispatch({ type: "updatePaymentValidity", paymentIsValid });
    }, []);

    const handleSubmit = async () => {
      dispatch({ type: "beginSubmit" });

      const { error, token } = await createToken({ address_zip: state.addressZip, name: state.name });
      if (error) {
        dispatch({ type: "onError", error });
        return Promise.reject(error);
      }

      if (hasValidCard) {
        await updatePaymentMethod(token).then(
          () => {
            onClose();
            onCardUpdate();
          },
          (error) => dispatch({ type: "onError", error })
        );
      } else {
        onTokenReceipt({
          stripeToken: token,
          zipcode: state.addressZip,
        });
      }
    };

    const handleBillingDataChange = (data) => {
      if ("address_zip" in data) {
        dispatch({ type: "updateBillingInfo", addressZip: data.address_zip });
      } else if ("name" in data) {
        dispatch({ type: "updateBillingInfo", name: data.name });
      }
    };

    return (
      <Modal id="paymentModal" width={450}>
        <ModalHeader>
          <ModalTitle>{hasValidCard ? "Change payment method" : "Enter payment method"}</ModalTitle>
          <CloseX onClick={onClose} />
        </ModalHeader>

        <ModalBody>
          {error && (
            <Row>
              <Col xs={12}>
                <ErrorBanner error={error} />
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={12}>
              <CardInput
                onBillingDataChange={handleBillingDataChange}
                onValidationChange={handleValidationChange}
                zipCode={state.addressZip}
              />
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter>
          <Button className={css(styles.right_margin)} onClick={() => onClose()}>
            Cancel
          </Button>{" "}
          <Button color="primary" disabled={submitDisabled} loading={isLoading} onClick={handleSubmit}>
            {hasValidCard ? "Update payment method" : "Submit payment"}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
);

PaymentModal.propTypes = {
  defaultZipCode: PropTypes.string.isRequired,
  onCardUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onTokenReceipt: PropTypes.func.isRequired,
};

export default PaymentModal;
