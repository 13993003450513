import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { compose, layout, space } from "styled-system";

const MapControl = styled((props) => {
  const { className, children } = props;

  return <div className={`${className} row`}>{children}</div>;
})`
  pointer-events: none;
  position: absolute;
  width: 100%;
  z-index: 1;

  ${({ position }) => {
    switch (position) {
      case "bottom":
        return `
          align-items: flex-end;
          bottom: 0;
        `;

      case "middle":
        return `
          align-items: center;
          bottom: 0;
          display: flex;
          top: 0;
        `;

      case "top":
        return `
          align-items: flex-start;
          top: 0;
        `;
    }
  }}

  ${compose(layout, space)}
`;

MapControl.propTypes = {
  children: PropTypes.node,
  p: PropTypes.any,
  position: PropTypes.oneOf(["top", "bottom", "middle"]).isRequired,
};

MapControl.defaultProps = {
  p: "1rem",
};

export default MapControl;
