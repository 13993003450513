import SbbEnrollmentPage from "program_enrollment/pages/SbbEnrollment";
import router from "router";

import { marketingClient, wrapWithProvider } from "collection/graphql/client";
import App from "layout/app";
import SubRoute from "lib/SubRoute";

const ProgramEnrollmentRouter = SubRoute.extend({
  routes: {
    "": "index",
    ":clientId": "index",
  },

  display(page, props) {
    App.display(wrapWithProvider(page, marketingClient), props);
  },

  index(clientId) {
    switch (clientId) {
      case "sbb":
        this.display(SbbEnrollmentPage, { screenName: "Program enrollment" });
        break;
      default:
        router.navigate("fields", { replace: true, trigger: true });
    }
  },
});

export default () => new ProgramEnrollmentRouter("program_enrollment", { createTrailingSlashRoutes: true });
