import { gql } from "@apollo/client";

import { FINANCIAL_ACCOUNT_FRAGMENT } from "collection/graphql/profit_and_loss/fragments";

export default gql`
  mutation UpdateAccount($account: UpdateFinancialAccountInput!) {
    updateFinancialAccount(account: $account) {
      ok
      account {
        ...Account
      }
    }
  }
  ${FINANCIAL_ACCOUNT_FRAGMENT}
`;
