import { gql } from "@apollo/client";

export default gql`
  query getContractCommodities {
    contractCommodities: getContractCommodities @requiresPermission(feature: "contracts", actions: ["read"]) {
      id
      name
    }
  }
`;
