import { useQuery } from "@apollo/client";
import moment from "moment";
import { useEffect, useState } from "react";

import { getCommodityQuotes } from "collection/graphql/prices/queries";

const useCommodityQuotes = ({ skipContracts, startDate = new Date(), endDate }) => {
  if (!endDate) {
    const nextYear = new Date().getFullYear() + 1;
    endDate = new Date(nextYear, 9, 31);
  }

  const queryPayload = useQuery(getCommodityQuotes, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    pollInterval: 1000 * 60, // refresh every minute
    variables: {
      skipContracts: !!skipContracts,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    },
  });

  const [lastUpdated, setLastUpdated] = useState(null);
  useEffect(() => {
    if (!queryPayload.loading) {
      setLastUpdated(new Date().toISOString());
    }
  }, [queryPayload.loading]);

  return {
    ...queryPayload,
    lastUpdated,
  };
};

export default useCommodityQuotes;
