import context from "activity/components/choosers/CropSelectionMap/context";
import React, { useContext } from "react";
import useOnClickOutside from "react-cool-onclickoutside";
import { Popup } from "react-mapbox-gl";
import styled from "styled-components";

import { Icon, IconContainer as IconContainerFLUI } from "components/fl-ui/Icons";

const Heading = styled.h4`
  margin: 5px 0 10px;
`;

const IconContainer = styled(IconContainerFLUI)`
  left: 6px;
  position: relative;
`;

const Option = (props) => {
  const { crop } = props;
  const { addCrop, removeCrop, selectedCrops } = useContext(context);
  const isSelected = selectedCrops.includes(crop.id);

  const handleClick = () => {
    if (!isSelected) {
      addCrop(crop.id);
    } else {
      removeCrop(crop.id);
    }
  };

  return (
    <OptionUI isSelected={isSelected} onClick={handleClick}>
      <span>
        <strong>
          {crop.commodity.name} {crop.cropYear}
        </strong>{" "}
        {crop.acreage} ac
      </span>

      {isSelected && (
        <IconContainer>
          <Icon icon="circleCheck" />
        </IconContainer>
      )}
    </OptionUI>
  );
};

const OptionUI = styled.li`
  align-items: center;
  border-top: 1px solid rgb(237, 239, 244);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  min-height: 41px;
  min-width: 240px;
  padding: 8px 12px;

  ${(props) =>
    props.isSelected
      ? `
    background-color: #2684ff;
    color: #fff;
  `
      : `
    &:hover {
      background-color: #deebff;
    }
  `}
`;

const CropSelectionPopup = styled((props) => {
  const { className, field, onClose } = props;
  const {
    centroid: { coordinates },
    crops,
  } = field;
  const ref = useOnClickOutside(onClose);

  return (
    <Popup anchor="top" coordinates={coordinates} offset={50}>
      <div className={className} ref={ref}>
        <Heading>Select Field Crops</Heading>
        <ul>
          {crops.map((crop, i) => (
            <Option key={i} crop={crop} />
          ))}
        </ul>
      </div>
    </Popup>
  );
})`
  font-size: 14px;
`;

export default CropSelectionPopup;
