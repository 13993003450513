import PropTypes from "prop-types";
import React from "react";

import AuthForm from "layout/components/AuthForm";
import FooterMessage from "layout/components/FooterMessage";

import { FormGroup, Input } from "components/fl-ui/Form";

const InviteLayoutForm = ({ formErrors, formValues, handleChange, handleSubmit }) => {
  const { confirm_password, error, loading, password } = formValues;

  return (
    <AuthForm
      action="Join"
      disabled={loading || !!error}
      error={error}
      footer={<FooterMessage link="https://support.bushelfarm.com/" message="Need some help?" />}
      label="Join"
      onChange={handleChange}
      onSubmit={handleSubmit}
    >
      <FormGroup label="Create Password">
        <Input
          autoFocus
          disabled={loading}
          hasError={!!formErrors.password}
          id="password"
          name="password"
          type="password"
          value={password}
        />

        <span className="help-inline">{formErrors.password}</span>
      </FormGroup>

      <FormGroup label="Retype Password">
        <Input
          disabled={loading}
          hasError={!!formErrors.confirm_password}
          id="confirm_password"
          name="confirm_password"
          type="password"
          value={confirm_password}
        />

        <span className="help-inline">{formErrors.confirm_password}</span>
      </FormGroup>
    </AuthForm>
  );
};

InviteLayoutForm.propTypes = {
  formErrors: PropTypes.object.isRequired,
  formValues: PropTypes.shape({
    confirm_password: PropTypes.string,
    error: PropTypes.string,
    loading: PropTypes.bool,
    password: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default InviteLayoutForm;
