const getCurrentFilterCount = (filters) => {
  let filterCount = 0;
  let hasDateFilters = false;

  Object.entries(filters).forEach((filter) => {
    const filterSlug = filter[0];
    const filterProperty = filter[1];

    //For filter arrays, count the number of items in the array
    if (
      filterSlug === "buyers" ||
      filterSlug === "commodities" ||
      filterSlug === "cropYears" ||
      filterSlug === "marketedCrops"
    ) {
      filterCount += filterProperty.length;
    }

    //For filter strings, each count as 1
    if (filterSlug === "contractStatus" && !!filterProperty) {
      filterCount += 1;
    }

    //For date filters, anything active at all counts as 1
    if ((filterSlug === "deliveryStartDate" || filterSlug === "deliveryEndDate") && !!filterProperty) {
      hasDateFilters = true;
    }
  });

  filterCount += hasDateFilters ? 1 : 0;
  return filterCount;
};

export default getCurrentFilterCount;
