import { gql } from "@apollo/client";

import { AARRecording } from "collection/graphql/activities/fragments";
import ActivityListFragment from "collection/graphql/activities/fragments/ActivityListFragment";

export default gql`
  fragment ActivityDetail on Activity {
    ...ActivityListFragment

    depth
    inputCostPerAcre
    inputCostTotal
    moistureLevel
    notes
    operationalCostPerAcre
    speed
    field {
      id
      centroid
      name
      geometry
      geometricCircumference
      group {
        id
        name
      }
    }
    images {
      filepickerId
      s3Id
    }
    inputs {
      ...ActivityInputDetail
    }
    recordings {
      ...AARRecording
    }
  }
  ${AARRecording}
  ${ActivityListFragment}
`;
