import useContractPermissions from "contracts/hooks/useContractPermissions";
import PricingAndDeliveryModal from "contracts/modals/PricingAndDeliveryModal";
import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";

import { Button } from "components/fl-ui";
import Icon from "components/fl-ui/Icons/Icon";
import IconContainer from "components/fl-ui/Icons/IconContainer";
import { ColorPalette } from "components/fl-ui/colors";
import { Spacing } from "components/fl-ui/constants";

const AddPricingAndDeliveryButton = styled(({ className, contract }) => {
  const [showPDModal, setShowPDModal] = useState(false);
  const canWrite = useContractPermissions().canWrite();

  return !canWrite ? null : (
    <>
      <Button className={className} color="primary" link onClick={() => setShowPDModal(true)}>
        <IconContainer className="add-icon-wrapper" size="minor">
          <Icon icon="add" />
        </IconContainer>
        <span>Add Pricing & Delivery</span>
      </Button>
      {showPDModal && <PricingAndDeliveryModal contract={contract} onClose={() => setShowPDModal(false)} />}
    </>
  );
})`
  margin-right: ${Spacing.medium};

  .add-icon-wrapper {
    border-radius: 100%;
    border: 1px solid ${ColorPalette.blue};
    margin-right: ${Spacing.xsmall};
    vertical-align: text-top;
  }
`;

export default AddPricingAndDeliveryButton;

AddPricingAndDeliveryButton.propTypes = {
  contract: PropTypes.object,
};
