import { StyleSheet } from "aphrodite/no-important";

import { UIColors } from "components/fl-ui/colors/index";
import { Spacing, Borders, Mixins } from "components/fl-ui/constants/index";

const headerStyles = StyleSheet.create({
  headerContainer: {
    position: "relative",
    marginBottom: Spacing.large,
    "@media only screen and (max-width: 30em)": {
      paddingBottom: Spacing.regular,
      marginBottom: Spacing.regular,
    },
    "@media print": {
      borderBottom: "0 none !important",
      paddingBottom: "0 !important",
      display: "block",
      width: "100%",
    },
  },
  headerBorder: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "15px", // specific px value aligns it with logo border on desktop widths
    borderBottom: Borders.regular,
    "@media print": {
      paddingBottom: Spacing.regular,
    },
  },
  noBorder: {
    paddingBottom: 0,
    borderBottom: "none",
  },
  noPaddingBottom: {
    paddingBottom: 0,
  },
  headerContent: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    flexGrow: 2,
    "@media only screen and (max-width: 30em)": {
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    "@media print": {
      minWidth: "100%",
    },
  },
  inlineChildren: {
    "@media only screen and (max-width: 62.5em)": {
      flexDirection: "row",
    },
  },
  toolbarContainer: {
    borderLeft: Borders.regular,
    marginLeft: Spacing.regular,
    "@media only screen and (max-width: 62.5em)": {
      display: "none",
    },
  },
  toolbarContainer_noChildren: {
    borderLeft: "none",
    marginLeft: 0,
  },
  h1: {
    lineHeight: Mixins.toRem(40), // hack to create consistency between headers with or without child element buttons
    marginTop: 0,
    marginBottom: 0,
    fontWeight: 500,
    display: "flex",
    alignItems: "baseline",
    whiteSpace: "nowrap",
    "@media only screen and (max-width: 30em)": {
      marginBottom: Spacing.large,
    },
  },
  h1_wBackLink: {
    paddingLeft: "1.25em",
    display: "flex",
    alignItems: "baseline",
    whiteSpace: "nowrap",
  },
  h1_fieldName: {
    maxWidth: "350px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  backLink: {
    ...Mixins.uiControl,
    top: "-0.25em",
    position: "absolute",
    left: 0,
    width: "1em",
    height: "2em",
    opacity: ".8",
    ":hover": {
      opacity: 1,
    },
    ":after": {
      ...Mixins.beforeAfter,
      position: "absolute",
      left: "9px",
      top: "50%",
      margin: "-10px 0 0 -5px",
      width: "18px",
      height: "18px",
      fontWeight: 300,
      borderWidth: "0 0 2px 2px",
      borderStyle: "solid",
      borderColor: UIColors.lighter,
      transform: "rotate(45deg)",
    },
  },
  headerChildren: {
    flexGrow: 2,
    display: "flex",
    justifyContent: "flex-end",
    maxHeight: Mixins.toRem(40),
    overflow: "visible",
    "@media only screen and (max-width: 62.5em)": {
      maxHeight: "unset",
    },
    "@media print": {
      display: "none !important",
    },
  },
  headerChildren_alignLeft: {
    justifyContent: "flex-start",
  },
  headerChildren_fullWidth: {
    "@media only screen and (max-width: 62.5em)": {
      width: "100%",
    },
  },
});

export default headerStyles;
