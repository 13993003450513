const eventFactory = function (name, options) {
  if (options == null) {
    options = {};
  }
  if (!(name && options)) {
    throw new Error("must pass name and options");
  }

  const props = {};

  for (const key in options) {
    if (key === "dynamic") {
      options.dynamic.forEach((prop) => (props[prop] = null));
    } else {
      props[key] = options[key];
    }
  }

  const updateDeltas = function (values) {
    const newProps = {};

    for (const key in values) {
      const value = values[key];
      newProps[`previous_${key}`] = props[`current_${key}`];
      newProps[`current_${key}`] = value;
    }

    return setProps(newProps);
  };

  const setProps = (newProps) =>
    (() => {
      const result = [];
      for (const key in props) {
        let item;
        if (newProps[key]) {
          item = props[key] = newProps[key];
        }
        result.push(item);
      }
      return result;
    })();

  return {
    options,

    set(value, use) {
      if (use == null) {
        use = {};
      }
      if (typeof value === "object") {
        if (use.deltas) {
          return updateDeltas(value);
        } else {
          return setProps(value);
        }
      } else {
        return setProps({
          previous: props.current,
          current: value,
        });
      }
    },

    track(value, use) {
      if (use == null) {
        use = {};
      }
      if (value) {
        this.set(value, use);
      }
      return window.analytics.track(name, props);
    },
  };
};

export default eventFactory;
