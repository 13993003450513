import { useMutation, useQuery } from "@apollo/client";
import { sortBy } from "lodash";

import { createFieldGroup, deleteFieldGroup, updateFieldGroup } from "collection/graphql/fieldGroups/mutations";
import { getFieldGroups } from "collection/graphql/fieldGroups/queries";

const useFieldGroups = () => {
  const { data, loading } = useQuery(getFieldGroups);
  const refetchQueries = ["getFieldGroups"];
  const [createGroup, { loading: isCreating }] = useMutation(createFieldGroup, { refetchQueries });
  const [deleteGroup, { loading: isDeleting }] = useMutation(deleteFieldGroup, { refetchQueries });
  const [updateGroup, { loading: isUpdating }] = useMutation(updateFieldGroup);
  const fieldGroups = sortBy(data?.fieldGroups, ({ name }) => name.toLowerCase());

  return {
    createGroup,
    deleteGroup,
    fieldGroups,
    loading: loading || isCreating || isDeleting || isUpdating,
    updateGroup,
  };
};

export default useFieldGroups;
