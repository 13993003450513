/* eslint-disable react/display-name */
import { useReactiveVar } from "@apollo/client";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import router from "router";

import { useUserConfig } from "collection/graphql/config";
import { historicalRainfall, last24Rainfall } from "collection/graphql/fields/rainfall";
import queryParams from "lib/queryParams";

const withPersistence = (WrappedComponent) => (props) => {
  const userConfig = useUserConfig();
  const getDefaultFilters = () => ({
    commodities: [],
    groups: [],
    irrigation: [],
    source: [],
    search: "",
    sortCriteria: userConfig("fieldSortCriteria"),
    viewType: userConfig("fieldViewType"),
  });

  const [selectedFilters, setSelectedFilters] = useState(() => {
    const selectedFilters = _.extend(getDefaultFilters(), props.urlParams);
    selectedFilters.commodities = _.map(selectedFilters.commodities, (id) => +id);
    selectedFilters.groups = _.map(selectedFilters.groups, (id) => +id);

    return selectedFilters;
  });

  const hasLast24Loaded = !!useReactiveVar(last24Rainfall);
  const hasRecentLoaded = !!useReactiveVar(historicalRainfall);
  const rainfallHasLoaded = hasLast24Loaded && hasRecentLoaded;

  useEffect(() => {
    const viewType = userConfig("fieldViewType");
    if (viewType !== selectedFilters.viewType) {
      setSelectedFilters({
        ...selectedFilters,
        viewType,
      });
    }
  }, [userConfig("fieldViewType")]);

  const { fields } = props;
  const onFilterReset = useCallback(() => {
    setSelectedFilters(getDefaultFilters());
    router.navigate("fields", { replace: true, trigger: true });
  }, []);

  const onFilterUpdate = useCallback(
    (updatedFilters) => {
      updatedFilters = {
        ...selectedFilters,
        ...updatedFilters,
      };

      setSelectedFilters(updatedFilters);
      const query = queryParams.stringify(_.omit(updatedFilters, ["search"]));
      router.navigate(`fields?${query}`, { replace: true, trigger: false });
    },
    [selectedFilters]
  );

  const onSearchUpdate = useCallback((search) => onFilterUpdate({ search }), []);

  const onSortUpdate = useCallback((sortCriteria) => {
    userConfig("fieldSortCriteria", sortCriteria);
    onFilterUpdate({ sortCriteria });
  }, []);

  const onViewTypeUpdate = useCallback((viewType) => {
    userConfig("fieldViewType", viewType);
    onFilterUpdate({ viewType });
  }, []);

  return (
    <WrappedComponent
      {...props}
      fields={fields}
      rainfallHasLoaded={rainfallHasLoaded}
      onFilterReset={onFilterReset}
      onFilterUpdate={onFilterUpdate}
      onSearchUpdate={onSearchUpdate}
      onSortUpdate={onSortUpdate}
      onViewTypeUpdate={onViewTypeUpdate}
      search={selectedFilters.search}
      selectedFilters={selectedFilters}
      sortCriteria={selectedFilters.sortCriteria}
      viewType={selectedFilters.viewType}
    />
  );
};

export default withPersistence;
