import { StyleSheet, css } from "aphrodite/no-important";
import classNames from "classnames";
import React from "react";

import { UIColors } from "components/fl-ui/colors/";
import { Spacing, Mixins } from "components/fl-ui/constants/";

const styles = StyleSheet.create({
  stickyContainer: {
    ...Mixins.clearfix,
  },
  stickyControls: {
    ...Mixins.clearfix,
    paddingTop: Spacing.regular,
    paddingBottom: Spacing.regular,
  },
  stickyControls_isSticky: {
    background: UIColors.white,
    boxShadow: `0px -2px 2px ${UIColors.shadowOne}`,
    borderTop: `1px solid ${UIColors.lightest}`,
    position: "fixed",
    bottom: 0,
    right: 0,
    left: Mixins.toRem(272),
    "@media only screen and (max-width: 62.5em)": {
      left: Mixins.toRem(82),
    },
    "@media only screen and (max-width: 30em)": {
      left: 0,
    },
  },
  stickyControlsContent: {
    maxWidth: Mixins.toRem(800),
    margin: "0 auto",
    width: "100%",
  },
  stickyControlsContent_isSticky: {
    width: "100%",
    "@media only screen and (max-width: 62.5em)": {
      padding: `0 ${Spacing.regular}`,
    },
    "@media only screen and (max-width: 30em)": {
      padding: `0 ${Spacing.regular}`,
    },
  },
});

// Refactor this component to be a functional component
class StickyControls extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSticky: false,
      offsetHeight: 0,
    };
    this.stickyControlsRef = React.createRef();
    this._handlePosition = this._handlePosition.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this._handlePosition);
    window.addEventListener("resize", this._handlePosition);
    return this._handlePosition();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this._handlePosition);
    return window.removeEventListener("resize", this._handlePosition);
  }

  _handlePosition() {
    const windowBottom = document.documentElement.clientHeight + window.scrollY;
    const { offsetHeight, offsetTop } = this.stickyControlsRef.current;

    const offsetBottom = this.state.isSticky ? this.state.offset : offsetTop + offsetHeight;

    const calculatedOffsetHeight = this.state.isSticky ? this.state.offsetHeight : offsetHeight;

    return this.setState({
      offset: offsetBottom,
      offsetHeight: calculatedOffsetHeight,
      isSticky: windowBottom < offsetBottom,
    });
  }

  render() {
    const style = this.state.isSticky
      ? {
          ...this.props.style,
          ...this.props.stickyStyle,
        }
      : this.props.style;

    return (
      <div
        className={classNames(css(styles.stickyContainer), this.props.className)}
        style={{ minHeight: this.state.offsetHeight }}
        ref={this.stickyControlsRef}
      >
        <div
          className={classNames(
            css(styles.stickyControls, this.state.isSticky && styles.stickyControls_isSticky),
            "sticky-control-bar"
          )}
          style={style}
        >
          <div
            className={classNames(
              css(styles.stickyControlsContent, this.state.isSticky && styles.stickyControlsContent_isSticky),
              `${this.props.contentClass}`
            )}
          >
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

StickyControls.displayName = "StickyControls";

StickyControls.defaultProps = {
  contentClass: "",
  style: {},
  stickyStyle: {},
};

export default StickyControls;
