import { COMMODITY } from "../fragments";
import { gql } from "@apollo/client";

const createCommodity = gql`
  mutation createCommodity($commodity: CreateCommodityInput!) {
    result: createCommodity(commodity: $commodity) {
      ok
      commodity {
        ...Commodity
      }
    }
  }

  ${COMMODITY}
`;

export default createCommodity;
