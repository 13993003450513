import { FERTILIZER, HARVESTER, OTHER, PLANTER, SPRAYER, TILLER, WAGON } from "equipment/constants";
import _ from "lodash";
import * as yup from "yup";

const schema = yup.object().shape({
  icon: yup.string().label("Icon").nullable(),
  implementTypeId: yup
    .number()
    .label("Type")
    .oneOf([FERTILIZER, HARVESTER, OTHER, PLANTER, SPRAYER, TILLER, WAGON])
    .required("Implement type is required"),
  isRented: yup.mixed().label("Ownership").oneOf([true, false]).nullable(),
  manufactureYear: yup
    .number()
    .label("Manufacture year")
    .nullable()
    .min(1900)
    .max(new Date().getFullYear() + 2),
  name: yup.string().label("Tractor name").trim().required(),
  notes: yup
    .string()
    .label("Notes")
    .transform((value) => _.trim(value)),
});

export default schema;
