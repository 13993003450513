import { gql } from "@apollo/client";

export default gql`
  mutation importMachineDataByRange(
    $provider: MachineDataProvider!
    $endDate: DateTime
    $startDate: DateTime
    $reprocess: Boolean!
  ) {
    importMachineDataOperationsWithinDateRange(
      provider: $provider
      endDate: $endDate
      startDate: $startDate
      reprocess: $reprocess
    ) {
      ok
    }
  }
`;
