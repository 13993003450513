import CropProgressBarHeader from "marketing/components/CropProgressBarHeader";
import { BaseProgressBar, IndependentProgressBar } from "marketing/components/ui-elements/progress-bar";
import { percentage } from "marketing/utils/format";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import Cluster from "components/fl-ui/Layout/Cluster";
import { ColorPalette, GreyColors } from "components/fl-ui/colors";

const CropCardProgressBarArea = styled(
  ({
    barColor = ColorPalette["recommendation-bar-blue"],
    barBackgroundColor = ColorPalette["default-bar-background"],
    className,
    marketedPercentage,
    marketedText,
    unprotectedText,
    titleArea,
  }) => (
    <div className={className}>
      {titleArea}

      <CropProgressBarHeader
        marketedPercentage={percentage(marketedPercentage)}
        unprotectedPercentage={percentage(100 - marketedPercentage)}
      />

      <BaseProgressBar baseColor={barBackgroundColor} type="thick">
        <IndependentProgressBar color={barColor} type="thick" width={marketedPercentage} />
      </BaseProgressBar>

      <Cluster between className="bottom-cluster">
        <span>{marketedText}</span>
        <span>{unprotectedText}</span>
      </Cluster>
    </div>
  )
)`
  padding: 0.5rem 0;

  .bottom-cluster {
    padding: 0.5rem 0;
  }

  h3 {
    color: ${GreyColors.smoke50};
    font-weight: 400;
    font-size: 1rem;
  }
`;

CropCardProgressBarArea.propTypes = {
  barColor: PropTypes.string,
  barBackgroundColor: PropTypes.string,
  title: PropTypes.string,
};

export default CropCardProgressBarArea;
