import React from "react";
import styled from "styled-components";

import { UIColors } from "components/fl-ui/colors";
import { Spacing, Typography } from "components/fl-ui/constants";

export const SortIndicator = styled(({ className, sortDirection, style }) => (
  <div className={className} style={style}>
    <span className={`sort-arrow ${sortDirection === "asc" && "active-sort-arrow"}`}>▲</span>
    <span className={`sort-arrow ${sortDirection === "desc" && "active-sort-arrow"}`}>▼</span>
  </div>
))`
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  margin-left: ${Spacing.xsmall};
  position: relative;
  right: ${Spacing.xsmall};
  padding-left: ${Spacing.xsmall};
  vertical-align: middle;

  .sort-arrow {
    display: block;
    font-size: ${Typography.sizes.xxs};
    line-height: 1.2;
    color: ${UIColors.lightest};

    &.active-sort-arrow {
      color: ${UIColors.primary};
    }
  }
`;

export default SortIndicator;
