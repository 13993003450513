import { useMutation } from "@apollo/client";
import { pick } from "lodash";
import React, { useState } from "react";
import { InlineInput } from "settings/styledComponents";

import { UPDATE_ENTERPRISE } from "collection/graphql/enterprise/mutations";
import UpdateEnterpriseInputSchema from "collection/graphql/enterprise/schemas/UpdateEnterpriseInputSchema";
import useCurrentEnterprise from "hooks/useCurrentEnterprise";
import App from "layout/app";
import countryList from "lib/countries";
import statesList from "lib/states";

import { Button } from "components/fl-ui";
import { Form, Select } from "components/fl-ui/Form";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";

const ENTERPRISE_FIELDS = ["acreage", "address", "address2", "city", "country", "name", "phone", "postalCode", "state"];
const getListOptions = (list) =>
  [{ label: "-- Select One --", value: "" }].concat(list.map((m) => ({ key: m.code, label: m.name, value: m.code })));
const countryOptions = getListOptions(countryList["Countries"]);
const hasStates = (country) => ["US", "CA"].includes(country);

const FarmInput = ({ error, id, label, last = "", options, value }) => {
  const hasError = error?.path === id;

  return (
    <div className={`control-group${last && " last-sub-item"}${hasError ? " error" : ""}`}>
      <label className="control-label" htmlFor={id}>
        {label}
      </label>
      <div className="controls">
        {options && <Select defaultValue={value} hasError={hasError} id={id} legacy name={id} options={options} />}
        {!options && <InlineInput defaultValue={value} hasError={hasError} id={id} name={id} type="text" />}
        {hasError && <span className="help-inline">{error.message}</span>}
      </div>
    </div>
  );
};

const Farm = () => {
  const { currentEnterprise, loading } = useCurrentEnterprise();
  const [enterpriseData, setEnterpriseData] = useState(pick(currentEnterprise, ENTERPRISE_FIELDS));
  const [error, setError] = useState(null);
  const [updateEnterprise] = useMutation(UPDATE_ENTERPRISE);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleFormChange = (_event, formData) => {
    if (error && formData[error.path]) {
      setError(null);
    }

    setEnterpriseData((enterpriseData) => ({ ...enterpriseData, ...formData }));
  };

  const handleSave = async () => {
    setButtonDisabled(true);
    try {
      const enterprise = await UpdateEnterpriseInputSchema.validate(enterpriseData);

      if (!hasStates(enterprise.country)) {
        enterprise.state = null;
      }

      await updateEnterprise({ variables: { enterprise } });
      App.notify("Changes saved.");
    } catch (error) {
      setError(error);
      App.notify("Update failed, please try again");
    } finally {
      setButtonDisabled(false);
    }
  };

  return (
    <LoadingWrapper isLoading={loading}>
      <Form className="form-horizontal" onChange={handleFormChange} onSubmit={handleSave}>
        <h2 className="heading-underline">Farm Information</h2>
        <FarmInput error={error} id="name" label="Farm name" value={enterpriseData.name} />
        <FarmInput error={error} id="phone" label="Phone number" value={enterpriseData.phone} />
        <FarmInput error={error} id="acreage" label="Harvested acres" last value={enterpriseData.acreage} />

        <h2 className="heading-underline">Farm Address</h2>
        <FarmInput error={error} id="country" label="Country" options={countryOptions} value={enterpriseData.country} />
        <FarmInput error={error} id="address" label="Street" value={enterpriseData.address} />
        <FarmInput error={error} id="address2" label="" value={enterpriseData.address2} />
        <FarmInput error={error} id="city" label="City" value={enterpriseData.city} />

        {hasStates(enterpriseData.country) && (
          <FarmInput
            error={error}
            id="state"
            label={enterpriseData.country === "CA" ? "Province" : "State"}
            options={getListOptions(statesList[enterpriseData.country])}
            value={enterpriseData.state}
          />
        )}

        <FarmInput error={error} id="postalCode" label="ZIP/Postal code" value={enterpriseData.postalCode} />

        <div className="form-actions">
          <Button color="primary" disabled={buttonDisabled} type="submit">
            Save Changes
          </Button>
        </div>
      </Form>
    </LoadingWrapper>
  );
};

Farm.tabId = "farm";
Farm.title = "Farm Settings";

export default Farm;
