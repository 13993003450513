import useMobileInvoiceDownload from "./hooks/useMobileInvoiceDownload";
import React from "react";
import { Link } from "settings/styles";
import { Button } from "components/fl-ui";
import { isNative } from "mobile/mobileManager";

const DownloadInvoiceLink = ({ invoice }) => {
  const { downloadFile, hasDownloaded, loading, shareFile } = useMobileInvoiceDownload(invoice);

  if (!isNative()) {
    return (
      <Link href={invoice.hostedInvoiceUrl} noopener noreferrer target="_blank">
        View
      </Link>
    );
  }

  const handleClick = hasDownloaded ? shareFile : downloadFile;
  let linkText = "Save invoice";
  if (!hasDownloaded) {
    linkText = loading ? "Downloading" : "Download";
  }

  return (
    <Button color="primary" disabled={loading} link onClick={handleClick}>
      {linkText}
    </Button>
  );
};

export default DownloadInvoiceLink;
