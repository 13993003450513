import { useMemo } from "react";

import FarmAdvisorConfigSchema from "collection/graphql/advisor/schemas/FarmAdvisorConfigSchema";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import useReleaseFeature from "hooks/useReleaseFeature";

/**
 * A hook for stubbing out the farm advisor config.
 * @see {FarmAdvisorConfigSchema}
 * @return {{ data: FarmAdvisorConfig, loading: boolean }}
 */
const useFarmAdvisorConfig = (isFeatureFlagOverwrite = false) => {
  const hasFeatureFlag = useEnterpriseFeature("elevate_plus");
  const config = useReleaseFeature("farm-advisor-config");

  return useMemo(() => {
    if ((hasFeatureFlag || isFeatureFlagOverwrite) && FarmAdvisorConfigSchema.isValidSync(config)) {
      return {
        data: FarmAdvisorConfigSchema.cast(config, { assert: true, stripUnknown: true }),
        loading: false,
      };
    }

    return {
      data: FarmAdvisorConfigSchema.cast({ id: "DEFAULT" }),
      loading: false,
    };
  }, [config, hasFeatureFlag]);
};

export default useFarmAdvisorConfig;
