import { useMutation } from "@apollo/client";
import _ from "lodash";
import { useCallback } from "react";

import TractorFragment from "collection/graphql/equipment/fragments/TractorDetailFragment";
import createTractorMutation from "collection/graphql/equipment/mutations/createTractor";
import deleteTractorMutation from "collection/graphql/equipment/mutations/deleteTractor";
import updateTractorMutation from "collection/graphql/equipment/mutations/updateTractor";
import getAllMaintenanceRecords from "collection/graphql/equipment/queries/getAllMaintenanceRecords";

const useTractorMutations = () => {
  const create = useMutation(createTractorMutation)[0];
  const [
    destroy,
    {
      client: { cache },
    },
  ] = useMutation(deleteTractorMutation);
  const update = useMutation(updateTractorMutation)[0];

  const createTractor = useCallback(async (input) => {
    const result = await create({
      update(cache, { data }) {
        const { tractor } = data.createTractor;

        cache.modify({
          fields: {
            getTractors: (existingTractors = []) => {
              const newTractor = cache.writeFragment({
                id: cache.identify(tractor),
                data: tractor,
                fragment: TractorFragment,
              });

              return [...existingTractors, newTractor];
            },
          },
        });
      },

      variables: {
        input,
      },
    });

    return result.data.createTractor.tractor;
  }, []);

  const deleteTractor = useCallback(async (id) => {
    await destroy({
      onCompleted() {
        // any relevant maintenance records need to be manually deleted
        const cachedRecords = cache.readQuery({
          query: getAllMaintenanceRecords,
        })?.maintenanceRecords;

        const recordsForDeletedTractor = _.filter(cachedRecords, {
          equipmentId: id,
        });

        recordsForDeletedTractor.forEach(({ id }) => {
          cache.evict({
            id: cache.identify({ id, __typename: "MaintenanceRecord" }),
          });
        });

        if (recordsForDeletedTractor.length > 0) {
          cache.gc();
        }
      },

      refetchQueries: ["getAllMaintenanceRecords"],

      update(cache) {
        cache.evict({
          id: cache.identify({ id, __typename: "Tractor" }),
        });
        cache.gc();
      },

      variables: {
        id,
      },
    });
  }, []);

  const saveTractor = useCallback(async (input) => {
    return input.id ? updateTractor(input) : createTractor(input);
  }, []);

  const updateTractor = useCallback(async (input) => {
    await update({
      optimisticResponse: ({ input }) => ({
        updateTractor: {
          ok: true,
          tractor: input,
        },
      }),
      variables: {
        input,
      },
    });
  }, []);

  return {
    createTractor,
    deleteTractor,
    saveTractor,
    updateTractor,
  };
};

export default useTractorMutations;
