import { css } from "aphrodite/no-important";
import classNames from "classnames";
import React from "react";
import ReactDOM from "react-dom";

import styles from "components/fl-ui/util/styles";

const Fullscreen = ({ id, screenName, className, innerRef, ...otherProps }) =>
  ReactDOM.createPortal(
    <div className={classNames(css(styles.container), className)} id={id} ref={innerRef} {...otherProps} />,
    document.getElementById("dialog")
  );

export default Fullscreen;
