import React from "react";

import { FormGroup, Select } from "components/fl-ui/Form";

const RevenueSourceFieldset = ({ onChange, revenueSource }) => {
  const revenueSources = [
    { key: "HUNTING", label: "Hunting", value: "HUNTING" },
    { key: "MINING", label: "Mining", value: "MINING" },
    { key: "OIL", label: "Oil", value: "OIL" },
    { key: "WIND", label: "Wind", value: "WIND" },
    { key: "EASEMENT", label: "Easement", value: "EASEMENT" },
    { key: "CONSERVATION", label: "Conservation", value: "CONSERVATION" },
    { key: "OTHER", label: "Other", value: "OTHER" },
  ];

  return (
    <FormGroup label="Revenue source">
      <Select
        name="revenueSource"
        value={revenueSource}
        options={revenueSources}
        onChange={(e, payload) => onChange(payload)}
      />
    </FormGroup>
  );
};

export default RevenueSourceFieldset;
