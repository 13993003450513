import { css } from "aphrodite/no-important";
import PropTypes from "prop-types";
import React from "react";

import reportStyleSheet from "modules/reports/components/reportStyleSheet";

const SummaryItem = ({ title, value }) => {
  return (
    <h3 className={css(reportStyleSheet.summaryItem)}>
      <span className={css(reportStyleSheet.summaryItemTitle)}>{title}</span>
      <span className={css(reportStyleSheet.summaryItemValue)}>{value}</span>
    </h3>
  );
};

SummaryItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default SummaryItem;
