import { gql } from "@apollo/client";

const FieldDetailFragment = gql`
  fragment FieldDetail on Field {
    acreage
    centroid
    county {
      name
    }
    crops {
      id
      acreage
      cropYear
      commodity {
        id
        name
      }
    }
    enterprise {
      id
      name
    }
    group {
      id
      name
    }
    id
    name
    eligibleCarbonClients
    fsaFarm
    fsaTract
    geometry
    geometricCircumference
    isIrrigated
    isRented
    isTiled
    landlord
    landValuePerAcre
    landValueRate
    landValueTotal
    leafFieldId
    notes
    productionHistory {
      ...ProductionHistory
    }
    provider
    section {
      name
    }
    source
    township {
      name
      range
    }
  }
`;

export default FieldDetailFragment;
