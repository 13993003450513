const BorderRadius = {
  xsmall: "0.125rem",
  small: "0.188rem",
  medium: "0.25rem",
  large: "0.375rem",
  xlarge: "0.5rem",
  xxlarge: "1rem",
  xxxlarge: "1.5rem",
};

export default BorderRadius;
