import { FERTILIZER, HARVESTER, OTHER, PLANTER, SPRAYER, TILLER, TRACTOR, WAGON } from "../constants";
import { fileClient } from "@farmlogs/fl-ui";
import themeGet from "@styled-system/theme-get";
import cx from "classnames";
import useEquipmentImage from "equipment/hooks/useEquipmentImage";
import iconsByType from "equipment/lib/iconsByType";
import isLocalIcon from "equipment/lib/isLocalIcon";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import App from "layout/app";

import { Button } from "components/fl-ui";
import { Stack } from "components/fl-ui/Layout";

const IconPickerContainer = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(auto-fit, minmax(145px, 1fr));
`;

const ImageContainer = styled.div`
  border: 1px solid ${themeGet("colors.smoke80")};
  cursor: pointer;
  display: grid;
  height: 145px;
  place-content: center;
  width: 145px;

  &.selected {
    border-color: ${themeGet("colors.black")};
  }
`;

const IconPickerImg = ({ alt, fileName, size, type }) => {
  const src = useEquipmentImage(type, fileName, size);
  return <img src={src} alt={alt} />;
};

const EquipmentIconPicker = (props) => {
  const { iconGroup, onChange, value } = props;
  const icons = iconsByType(iconGroup);

  const handleRemove = () => {
    App.confirm({
      cancel: "No",
      confirm: "Yes",
      message: "Are you sure you want to remove this image?",
      title: "Remove image",
    }).then(() => onChange(""));
  };

  const handleUpload = () => {
    fileClient
      .selectFiles({
        accept: ["image/*"],
        fromSources: ["local_file_system", "url", "webcam"],
        maxFiles: 1,
      })
      .then(
        (files) => onChange(files[0].handle),
        (error) => console.error(error)
      );
  };

  return (
    <Stack>
      <IconPickerContainer>
        {!value || isLocalIcon(value)
          ? icons.map(({ filename, name }, i) => {
              return (
                <ImageContainer
                  key={[iconGroup, i].join("-")}
                  className={cx({ selected: filename === value })}
                  onClick={() => onChange(filename)}
                >
                  <IconPickerImg
                    alt={name}
                    fileName={filename}
                    size={80}
                    type={iconGroup === TRACTOR ? "tractor" : "implement"}
                  />
                </ImageContainer>
              );
            })
          : null}

        {!isLocalIcon(value) && value && (
          <IconPickerImg fileName={value} size={80} type={iconGroup === TRACTOR ? "tractor" : "implement"} />
        )}
      </IconPickerContainer>

      {(!value || isLocalIcon(value)) && <Button onClick={handleUpload}>Upload photo</Button>}
      {value && !isLocalIcon(value) && <Button onClick={handleRemove}>Remove</Button>}
    </Stack>
  );
};

EquipmentIconPicker.propTypes = {
  iconGroup: PropTypes.oneOf([FERTILIZER, HARVESTER, OTHER, PLANTER, SPRAYER, TILLER, TRACTOR, WAGON]).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

EquipmentIconPicker.defaultProps = {};

export default EquipmentIconPicker;
