import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import React from "react";
import _ from "underscore";

import Events from "components/mixins/events";
import ResponsiveHOC from "components/mixins/responsive";
import CropSelector from "components/ui/CropSelector";
import Collapsable from "components/ui/collapsable";
import FilterIcon from "components/ui/icons/filter";

export default ResponsiveHOC(
  createReactClass({
    displayName: "Sidebar",
    propTypes: {
      filterState: PropTypes.object,
      onChange: PropTypes.func,
      viewportSize: PropTypes.string.isRequired,
      year: PropTypes.number,
    },

    mixins: [Events],

    getDefaultProps() {
      return {
        onChange() {
          return null;
        },
      };
    },

    getInitialState() {
      return {
        collapse: true,
      };
    },

    _handleChange(data) {
      const filter = _.extend({}, this.props.filterState, data);
      return this.props.onChange(filter);
    },

    _handleHeaderClick() {
      return this.setState({ collapse: !this.state.collapse });
    },

    _renderMobile() {
      let classes = {
        body: "card-body",
        title: "card-title clickable",
      };

      if (this.state.collapse) {
        classes = _.mapValues(classes, (x) => x + " collapsed");
      }

      return (
        <div className="js-completesidebar card sidebar-card">
          <div className={classes.title} onClick={this._handleHeaderClick}>
            {this.state.collapse ? "Filter..." : "Hide Filters..."}
            <FilterIcon className="float-right" />
          </div>
          <Collapsable collapse={this.state.collapse} className={classes.body}>
            {this._renderSidebarFilters()}
          </Collapsable>
        </div>
      );
    },

    _renderSidebarFilters() {
      const filters = [];
      filters.push(
        <CropSelector
          key="sidebar-crops"
          activeCommodity={this.props.filterState.commodity_id}
          year={this.props.year}
          onChange={this._handleChange}
        />
      );

      filters.push(<span key="sidebar-children">{this.props.children}</span>);

      return filters;
    },

    render() {
      if (["xs", "sm"].includes(this.props.viewportSize)) {
        return this._renderMobile();
      }

      return <div className="js-completesidebar">{this._renderSidebarFilters()}</div>;
    },
  })
);
