import { DatePicker } from "@farmlogs/fl-ui";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

import { getAllPeople } from "collection/graphql/people/queries";
import useRestSuspenseQuery from "hooks/useRestSuspenseQuery";
import useFieldCropsByField from "modules/fields/hooks/useFieldCropsByField";

import Chooser from "components/fl-ui/Chooser";
import { withSuspenseWrapper } from "components/fl-ui/LoadingWrapper";
import ControlGroup from "components/ui/form/control_group";
import { withGroupedFieldsOptions } from "fields/hooks/useGroupedFields";

const NotePrimaryAttributes = withSuspenseWrapper(({ children, chooserOptions, data, errors, handleChange }) => {
  const { fieldCrops } = useFieldCropsByField({ fieldId: data.fieldId });
  const { people } = useRestSuspenseQuery(getAllPeople).data;

  const groupedCropOptions = useMemo(() => {
    // generate grouped fieldCrop options
    const cropYears = new Set(_.map(fieldCrops, "cropYear").sort().reverse());
    const cropsByYear = _.groupBy(fieldCrops, "cropYear");
    return Array.from(cropYears).map((cropYear) => {
      return {
        name: cropYear,
        options: cropsByYear[cropYear].map(({ commodity: { name }, id }) => ({
          name,
          value: id,
        })),
      };
    });
  }, [fieldCrops]);

  const onChange = (event) => {
    const { name, value } = event.target || event; // checks for DOM or synthetic event
    const note = _.defaults({ [name]: value }, data);
    handleChange(note);
  };

  const handleDateChange = (date) => {
    const note = _.defaults({ date: date || "" }, data);
    handleChange(note);
  };

  return (
    <div className="note-section">
      <ControlGroup label="date">
        <DatePicker onChange={handleDateChange} outputFormat="yyyy-MM-dd" value={data.date} />
        <span className="js-help-inline help-inline">{errors.date}</span>
      </ControlGroup>

      <ControlGroup label="field">
        <Chooser
          formatGroupLabel={_.property("name")}
          getOptionValue={_.property("value")}
          getOptionLabel={_.property("name")}
          onChange={({ value }) => {
            onChange({
              name: "fieldId",
              value,
            });
          }}
          options={chooserOptions}
          placeholder={`Choose field\u2024`}
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              maxWidth: "400px",
            }),
          }}
          value={_.find(_.flatMap(chooserOptions, "options"), { value: data.fieldId })}
        />
        <span className="js-help-inline help-inline">{errors.fieldId}</span>
      </ControlGroup>

      {!!data.fieldId && (
        <ControlGroup label="crop">
          <Chooser
            formatGroupLabel={_.property("name")}
            getOptionValue={_.property("value")}
            getOptionLabel={_.property("name")}
            onChange={({ value }) => {
              onChange({
                name: "cropId",
                value,
              });
            }}
            options={groupedCropOptions}
            placeholder={`Choose crop\u2024`}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                maxWidth: "400px",
              }),
            }}
            value={_.find(_.flatMap(groupedCropOptions, "options"), { value: data.cropId })}
          />
          <span className="js-help-inline help-inline">{errors.cropId}</span>
        </ControlGroup>
      )}

      <ControlGroup label="people">
        <Chooser
          getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
          getOptionValue={(option) => option.id}
          isMulti
          onChange={(selectedOptions) => {
            onChange({
              name: "people",
              value: _.map(selectedOptions, "id"),
            });
          }}
          options={people}
          placeholder={`Choose people\u2026`}
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              maxWidth: "400px",
            }),
          }}
          value={_.map(data.people, (id) => _.find(people, { id }))}
        />
        <span className="js-help-inline help-inline">{errors.people}</span>
      </ControlGroup>

      {children}
      <span className="js-help-inline help-inline">{errors.images}</span>
    </div>
  );
});

NotePrimaryAttributes.propTypes = {
  children: PropTypes.element,
  data: PropTypes.object.isRequired,
  errors: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
};

export default withGroupedFieldsOptions(NotePrimaryAttributes);
