import { redirectToAlert } from "notifications/components/lib";
import PropTypes from "prop-types";
import React from "react";

import { Button } from "components/fl-ui";
import { Spacing } from "components/fl-ui/constants";

const Link = (props) => <Button color="primary" link style={{ marginRight: Spacing.xxlarge }} {...props} />;

const MarketPrices = (props) => {
  const { marketedCrops } = props.data;

  return (
    <Link onClick={() => redirectToAlert(props)}>
      {marketedCrops.length > 1 ? "View Marketing" : "View Crop Details"}
    </Link>
  );
};

const News = (props) => {
  const { data } = props;

  return !data.url ? null : (
    <Link href={data.url} rel="noopener" target="_blank">
      Learn More
    </Link>
  );
};

const Rainfall = (props) => {
  return <Link onClick={() => redirectToAlert(props)}>View Recent Rain</Link>;
};

const Rollup = () => {
  return window.location.hash === "/#alerts" ? null : <Link onClick={handleClick}>View all</Link>;
};

const WorkOrders = (props) => {
  return <Link onClick={() => redirectToAlert(props)}>View activities</Link>;
};

const NotificationActions = ({ notification }) => {
  const Actions = {
    "market-prices": MarketPrices,
    news: News,
    rainfall: Rainfall,
    rollup: Rollup,
    "work-orders": WorkOrders,
  }[notification.type];

  return Actions ? <Actions {...notification} /> : null;
};

NotificationActions.propTypes = {
  notification: PropTypes.object.isRequired,
};

export default NotificationActions;
