import useRecords from "./hooks/useRecords";
import MaintenanceRecords from "equipment/components/MaintenanceRecords";
import useRecordWrite from "equipment/containers/hooks/useRecordWrite";
import MaintenanceRecordModal from "equipment/forms/MaintenanceRecordModal";
import PropTypes from "prop-types";
import React from "react";

const MaintenanceRecordContainer = (props) => {
  const { equipment, record, setRecord } = props;
  const showNameColumn = !equipment;
  const records = useRecords(equipment?.id);
  const form = useRecordWrite(record);
  let type;
  if (equipment) {
    type = equipment.__typename === "Tractor" ? "tractor" : "implement";
  }

  return (
    <>
      <MaintenanceRecords
        onAddRecord={() => {
          const newRecord = {};
          if (equipment) {
            newRecord.equipment = {
              id: equipment.id,
              type,
            };
          }

          setRecord(newRecord);
        }}
        onEditRecord={(record) => setRecord(record)}
        records={records}
        showNameColumn={showNameColumn}
        type={type}
      />

      {form.showModal && (
        <MaintenanceRecordModal
          fields={form.fields}
          onClose={() => setRecord(null)}
          onChange={form.onChange}
          onDelete={() => {
            setRecord(null);
            return form.onDelete();
          }}
          onSave={(fields) => {
            setRecord(null);
            return form.onSave(fields);
          }}
        />
      )}
    </>
  );
};

MaintenanceRecordContainer.propTypes = {
  equipment: PropTypes.object,
  record: PropTypes.object,
  setRecord: PropTypes.func.isRequired,
};

export default MaintenanceRecordContainer;
