import { gql } from "@apollo/client";

export default gql`
  mutation editFieldCrop($crop: UpdateFieldCropInput!) {
    updateFieldCrop(crop: $crop) {
      ok
      crop {
        ...FieldCrop
      }
    }
  }
`;
