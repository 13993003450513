import _ from "lodash";
import PropTypes from "prop-types";
import React, { createContext, useContext, useEffect, useMemo } from "react";
import SeedCommodityChooser from "resources/containers/SeedCommodityChooser";

import { marketingClient } from "collection/graphql/client";
import { getProduct } from "collection/graphql/products/queries";
import Products from "collection/products";
import ConnectModel from "hoc/connect_model";
import useBackboneCollection from "hooks/useBackboneCollection";
import App from "layout/app";
import { CHEMICAL, COUNT, FERTILIZER, SEED, VOLUME, WEIGHT } from "lib/constants";

import { Button } from "components/fl-ui";
import CloseX from "components/fl-ui/Icons/CloseX";
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "components/fl-ui/Modal/Modal";

function connectValue(name, cb) {
  return (e) => cb({ [name]: e.target.value });
}

function connectBool(name, cb) {
  return (e) => cb({ [name]: e.target.value === "true" });
}

const ProductInputModalContext = createContext(null);
export const ProductInputModalContextProvider = ProductInputModalContext.Provider;
const useProductInputModal = () => useContext(ProductInputModalContext) || { getMessageForField: () => null };

const ControlGroup = ({ label, error, children }) => {
  return (
    <div className="control-group">
      <label className="control-label">{label}</label>
      <div className="controls">
        {children}
        {error && <div className="help-inline"> {error} </div>}
      </div>
    </div>
  );
};

const BasicDetails = (props) => {
  const { type, data, onChange, error } = props;

  return (
    <div>
      <ControlGroup error={error.name} label={`${type} Name`}>
        <input onChange={connectValue("name", onChange)} type="text" value={data.name} />
      </ControlGroup>
      <ControlGroup label="Brand">
        <input onChange={connectValue("brand", onChange)} type="text" value={data.brand} />
      </ControlGroup>
    </div>
  );
};

const SeedDetails = (props) => {
  const { data, onChange, error } = props;
  const { getMessageForField } = useProductInputModal();

  return (
    <div>
      <BasicDetails type="Seed" {...props} />
      <ControlGroup error={error.commodity_id} label="Commodity">
        <SeedCommodityChooser
          commodityId={data.commodity_id}
          onChange={({ id, seedUnitType }) =>
            onChange({
              commodity_id: id,
              unit_type: seedUnitType.replace(/_/gi, "-").toLowerCase(),
            })
          }
        />

        {getMessageForField("commodityId")}
      </ControlGroup>
      <ControlGroup label="Generic?">
        <div className="select-wrapper">
          <select onChange={connectBool("generic", onChange)} value={data.generic}>
            <option value>Yes, Generic</option>
            <option value={false}>No, Not Generic</option>
          </select>
        </div>
      </ControlGroup>
      <ControlGroup error={error.relative_maturity} label="Relative Maturity">
        <input onChange={connectValue("relative_maturity", onChange)} type="number" value={data.relative_maturity} />
      </ControlGroup>
      <ControlGroup label="Notes">
        <textarea onChange={connectValue("notes", onChange)} value={data.notes} />
      </ControlGroup>
    </div>
  );
};

const FertChemDetails = (props) => {
  const { constraints, data, onChange, error } = props;
  useEffect(() => {
    if (constraints.unitType) {
      const unit_type = _.toLower(constraints.unitType);
      onChange({ unit_type });
    }
  }, []);

  return (
    <div>
      <ControlGroup error={error.unit_type} label="Unit Type">
        <div className="select-wrapper">
          <select
            disabled={!!constraints.unitType}
            onChange={connectValue("unit_type", onChange)}
            value={data.unit_type}
          >
            <option>Choose Type...</option>
            <option value="volume">Volume</option>
            <option value="weight">Weight</option>
          </select>
        </div>
      </ControlGroup>
      <ControlGroup error={error.density} label="Density">
        <input onChange={connectValue("density", onChange)} type="number" value={data.density} />
      </ControlGroup>
    </div>
  );
};

const FertilizerDetails = (props) => {
  const { data, onChange, error } = props;

  return (
    <div>
      <BasicDetails type="Fertilizer" {...props} />
      <FertChemDetails {...props} />
      <ControlGroup error={error.percent_nitrogen} label="Percent Nitrogen (N)">
        <div className="input-append">
          <input
            className="input-70"
            onChange={connectValue("percent_nitrogen", onChange)}
            type="text"
            value={data.percent_nitrogen}
          />
          <span className="add-on">%</span>
        </div>
      </ControlGroup>
      <ControlGroup error={error.percent_phosphate} label="Percent Phosphate (P)">
        <div className="input-append">
          <input
            className="input-70"
            onChange={connectValue("percent_phosphate", onChange)}
            type="text"
            value={data.percent_phosphate}
          />
          <span className="add-on">%</span>
        </div>
      </ControlGroup>
      <ControlGroup error={error.percent_potash} label="Percent Potash/Potassium (K)">
        <div className="input-append">
          <input
            className="input-70"
            onChange={connectValue("percent_potash", onChange)}
            type="text"
            value={data.percent_potash}
          />
          <span className="add-on">%</span>
        </div>
      </ControlGroup>
      <ControlGroup error={error.percent_zinc} label="Percent Zinc (Zn)">
        <div className="input-append">
          <input
            className="input-70"
            onChange={connectValue("percent_zinc", onChange)}
            type="text"
            value={data.percent_zinc}
          />
          <span className="add-on">%</span>
        </div>
      </ControlGroup>
      <ControlGroup error={error.percent_sulphate} label="Percent Sulfate">
        <div className="input-append">
          <input
            className="input-70"
            onChange={connectValue("percent_sulphate", onChange)}
            type="text"
            value={data.percent_sulphate}
          />
          <span className="add-on">%</span>
        </div>
      </ControlGroup>
      <ControlGroup label="Notes">
        <textarea onChange={connectValue("notes", onChange)} value={data.notes} />
      </ControlGroup>
    </div>
  );
};

const ChemicalDetails = (props) => {
  const { data, onChange, error } = props;

  return (
    <div>
      <BasicDetails type="Chemical" {...props} />
      <FertChemDetails {...props} />
      <ControlGroup error={error.chemical_type} label="Chemical Type">
        <div className="select-wrapper">
          <select onChange={connectValue("chemical_type", onChange)} value={data.chemical_type}>
            <option>Choose Type...</option>
            <option value="adjuvant">Adjuvant</option>
            <option value="fungicide">Fungicide</option>
            <option value="herbicide">Herbicide</option>
            <option value="insecticide">Insecticide</option>
          </select>
        </div>
      </ControlGroup>
      <ControlGroup label="EPA Number">
        <input onChange={connectValue("epa_number", onChange)} type="text" value={data.epa_number} />
      </ControlGroup>
      <ControlGroup label="Restricted Use?">
        <div className="select-wrapper">
          <select onChange={connectBool("restricted_use", onChange)} value={data.restricted_use}>
            <option>Select Option...</option>
            <option value>Yes, Restricted</option>
            <option value={false}>No, Not Restricted</option>
          </select>
        </div>
      </ControlGroup>
      <ControlGroup label="Notes">
        <textarea onChange={connectValue("notes", onChange)} value={data.notes} />
      </ControlGroup>
    </div>
  );
};

const ProductDetails = (props) => {
  const {
    data: { type },
  } = props;

  switch (_.toUpper(type)) {
    case CHEMICAL:
      return <ChemicalDetails {...props} />;
    case FERTILIZER:
      return <FertilizerDetails {...props} />;
    case SEED:
      return <SeedDetails {...props} />;
    default:
      return <noscript />;
  }
};

const ProductTypeSelect = ({ unitTypeConstraint, ...props }) => {
  const options = [CHEMICAL, FERTILIZER, SEED];
  if (unitTypeConstraint === VOLUME) {
    options.pop();
  }

  return (
    <select {...props}>
      <option>Choose Type...</option>
      {options.map((type) => (
        <option key={type} value={type}>
          {_.startCase(_.toLower(type))}
        </option>
      ))}
    </select>
  );
};

ProductTypeSelect.propTypes = {
  unitTypeConstraint: PropTypes.oneOf([COUNT, VOLUME, WEIGHT]),
};

const ProductInputModal = ConnectModel(Products, (props) => {
  const { error, data, onChange, onClose, onDelete, onFinish, onSave } = props;
  const constraints = { ...props.constraints };
  const typeDisabled = useMemo(() => !!data.type, []);
  if (typeDisabled) {
    constraints.type = data.type;
  }
  useBackboneCollection(Products);

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      onFinish();
    }
  };

  const handleDelete = () => {
    App.confirm({
      message: `This will delete ${data.name} and all of its associated data, including usage records and purchase history.`,
      confirm: "DELETE",
    }).then(async () => {
      await onDelete();
      onFinish();
    });
  };

  const handleSave = async () => {
    const model = await onSave();
    if (model) {
      const { id } = model;
      // load the product into the cache if it's not already there
      const { data } = await marketingClient.query({ query: getProduct, variables: { id } });
      onFinish(data.product);
    }
  };

  const handleTypeChange = (event) => onChange({ type: _.toLower(event.target.value) });

  return (
    <Modal width={700}>
      <ModalHeader>
        <ModalTitle> {data.id ? "Edit" : "Add"} Product </ModalTitle>
        <CloseX onClick={handleClose} />
      </ModalHeader>

      <ModalBody>
        <form className="form-horizontal">
          <ControlGroup label="Type">
            <div className="select-wrapper">
              <ProductTypeSelect
                disabled={typeDisabled}
                name="type"
                onChange={handleTypeChange}
                unitTypeConstraint={constraints.unitType}
                value={_.toUpper(data.type)}
              />
            </div>
          </ControlGroup>

          <ProductDetails constraints={constraints} data={data} error={error} onChange={onChange} />
        </form>
      </ModalBody>

      <ModalFooter>
        <Button onClick={handleClose}>Cancel</Button>
        &nbsp;
        <Button color="primary" disabled={!_.isEmpty(error)} onClick={handleSave}>
          Save {_.startCase(data.type) || "Product"}
        </Button>
        &nbsp;
        {data.id && (
          <Button color="danger" onClick={handleDelete}>
            Delete
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
});

ProductInputModal.propTypes = {
  constraints: PropTypes.shape({
    type: PropTypes.oneOf([CHEMICAL, FERTILIZER, SEED]),
    unitType: PropTypes.oneOf([COUNT, VOLUME, WEIGHT]),
  }),
  data: PropTypes.shape,
};

ProductInputModal.defaultProps = {
  constraints: {},
};

export default ProductInputModal;
