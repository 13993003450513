import { gql } from "@apollo/client";

export default gql`
  query getFieldCropsWithYield(
    $activityTypeCategories: [ActivityTypeCategory]
    $commodities: [Int]
    $fields: [Int]
    $years: [Int]
  ) {
    fieldCrops: getFieldCrops(
      activityTypeCategories: $activityTypeCategories
      commodities: $commodities
      fields: $fields
      years: $years
    ) {
      ...FieldCrop
      commodity {
        ...Commodity
      }
      yield
      yieldPerAcre
      yieldStoredAmount
      yieldStoredRate
      yieldStoredUnit
      yieldTotal
      yieldUnit
    }
  }
`;
