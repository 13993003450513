import { css } from "aphrodite";
import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import styles from "settings/styles";

import { Icon } from "components/fl-ui/Icons";

const WarningBanner = ({ cssClassName, icon, message }) => {
  return (
    <Row className={css(styles.errorContainer, cssClassName)} data-cy="warningBanner">
      <Col xs className={css(styles.errorColumn)}>
        {icon && (
          <span style={{ height: "32px" }}>
            <Icon className={css(styles.icon)} icon={icon} />
          </span>
        )}

        <div className={css(styles.copyText)}>{message}</div>
      </Col>
    </Row>
  );
};

WarningBanner.propTypes = {
  icon: PropTypes.string,
};

export default WarningBanner;
