import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { UIColors } from "components/fl-ui/colors";
import { BorderRadius, Borders, Spacing } from "components/fl-ui/constants";

const Container = styled.label`
  border: ${(props) => (props.borderless ? "none" : Borders.dark)};
  ${(props) => props.hasError && `border-color: ${UIColors.red};`}
  border-radius: ${BorderRadius.medium};
  display: inline-flex;
  padding: ${({ compact }) => (compact ? ".5rem 0" : ".5rem")};
  position: relative;
  white-space: nowrap;

  input {
    padding: 0 !important;
  }

  select {
    padding-bottom: 3px;
    padding-top: 3px;
    width: auto !important;
  }

  @media (max-width: 62.5rem) {
    margin-bottom: ${Spacing.regular};
    margin-left: 0;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Text = styled.span`
  color: #666;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
`;
export { Text };

const LabelText = styled(Text)`
  background: #fff;
  display: inline-block;
  left: 4px;
  padding: 4px;
  position: absolute;
  top: ${(props) => (props.borderless ? "-2px" : "-10px")};
  z-index: ${(props) => props.borderless && 100};
`;

const MicroLabel = ({ children, label, ...props }) => {
  return (
    <Container {...props}>
      {label && <LabelText borderless={props.borderless}>{label}</LabelText>}
      {children}
    </Container>
  );
};

MicroLabel.propTypes = {
  as: PropTypes.string,
  children: PropTypes.any.isRequired,
  compact: PropTypes.bool,
  hasError: PropTypes.bool,
  label: PropTypes.any,
};

MicroLabel.defaultProps = {
  compact: false,
  hasError: false,
};

export default MicroLabel;
