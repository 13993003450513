import React from "react";
import router from "router";

import { withAgreement } from "collection/graphql/agreements";
import { CreateAgreementForm, EditAgreementForm } from "modules/agreements/forms";

import BreadCrumbHeader from "components/fl-ui/Layout/BreadCrumbHeader";
import Container from "components/fl-ui/Layout/Container";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";

const WrappedEditForm = withAgreement(({ data: { agreement, loading }, ...props }) => {
  if (!loading && !agreement) {
    router.navigate("land_costs", { replace: true, trigger: true });
    return null;
  }

  return loading ? <LoadingWrapper isLoading /> : <EditAgreementForm {...props} agreement={agreement} />;
});

const AgreementDetailPage = ({ agreementId }) => {
  const isEditing = !!agreementId;
  const links = !isEditing
    ? [{ enabled: false, label: "Add agreement" }]
    : [
        { enabled: true, label: "Land Costs", link: "#land_costs" },
        { enabled: false, label: `#${agreementId}` },
      ];

  return (
    <Container>
      <BreadCrumbHeader links={links} />

      {isEditing ? <WrappedEditForm agreementId={agreementId} /> : <CreateAgreementForm />}
    </Container>
  );
};

export default AgreementDetailPage;
