import { StyleSheet, css } from "aphrodite/no-important";
import React from "react";

import Icon from "components/fl-ui/Icons/Icon";
import { UIColors } from "components/fl-ui/colors/index";
import { Typography, Mixins } from "components/fl-ui/constants/index";

const styles = StyleSheet.create({
  approved: {
    color: UIColors.green,
    display: "inline-flex",
    alignItems: "center",
    verticalAlign: "text-bottom",
    margin: 0,
  },
  icon: {
    height: Mixins.toRem(24),
    width: Mixins.toRem(24),
  },
  iconText: {
    fontSize: Typography.sizes.xs,
    fontWeight: Typography.weights.medium,
    lineHeight: 1,
    letterSpacing: ".83px",
    textTransform: "uppercase",
  },
});

const ApprovalStatus = ({ status, wText, ...props }) => {
  if (status) {
    return (
      <span className={css(styles.approved)}>
        <Icon className={css(styles.icon)} icon="approved" />
        {wText && <span className={css(styles.iconText)}>Approved</span>}
      </span>
    );
  }

  return null;
};

export default ApprovalStatus;
