import styled from "styled-components";

import { Button } from "components/fl-ui";
import { Borders, Spacing } from "components/fl-ui/constants";

export const MapButton = styled(Button)`
  border-left: ${Borders.dark} !important;
  margin-left: ${Spacing.xsmall} !important;
  padding: 0 !important;
  padding-left: ${Spacing.xsmall} !important;
`;
