import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import React from "react";

import Map from "lib/map/map";

const { any, object, string } = PropTypes;

export default createReactClass({
  displayName: "Map",

  propTypes: {
    children: any,
    controls: any,
    defaults: object,
    id: string,
    layers: any,
    location: object,
    on: any,
  },

  childContextTypes: {
    map: PropTypes.any,
  },

  getDefaultProps() {
    return {
      id: "map_canvas",
      // controlled vs. uncontrolled
      value: null,
      defaultValue: {
        zoom: 5,
        center: [39.833333, -98.583333],
      },
      on: [["click", function () {}]],
    };
  },

  getInitialState() {
    return { map: null };
  },

  getChildContext() {
    return { map: this.state.map };
  },

  componentDidMount() {
    const map = new Map(this.refs.map, this.props.value || this.props.defaultValue);
    map.toggleBackdrop(this.props.backdrop);

    this.props.on.forEach((args) => map.on.apply(map, args));

    return this.setState({ map });
  },
  
  UNSAFE_componentWillReceiveProps({ value, on: events }) {
    // unbind/bind events when they change
    if (events !== this.props.on) {
      this.setEvents(events);
    }

    // handle controlled map state
    if (value && this.state.map) {
      return this.updateOptions(value);
    }
  },

  componentDidUpdate(prevProps) {
    const { backdrop, isSearchMode, location } = this.props;
    const { map } = this.state;

    if (map) {
      if (location !== prevProps.location) {
        map.fire("search:done", location);
      }

      if (!isSearchMode && isSearchMode !== prevProps.isSearchMode) {
        map.fire("search:clear");
      }

      map.toggleBackdrop(backdrop);
    }
  },

  componentWillUnmount() {
    const { layers } = this.props.value || this.props.defaultValue;
    this.props.on.forEach((args) => this.state.map?.off.apply(this.state.map, args));
    layers && layers.map((layer) => layer.remove());
  },

  addEvent(args) {
    return this.state.map.on.apply(this.state.map, args);
  },

  removeEvent(args) {
    return this.state.map.off.apply(this.state.map, args);
  },

  setEvents(newEvents) {
    this.props.on.forEach(this.removeEvent);
    return newEvents.forEach(this.addEvent);
  },

  updateOptions(options) {
    this.state.map.setOptions(options);
    return this.updatePosition(options);
  },

  updatePosition({ center, zoom }) {
    const { map } = this.state;
    const newView = (center != null ? center.toString() : undefined) + zoom;
    const currView = map.getCenter().toString() + map.getZoom();

    if (newView && newView !== currView) {
      // FIXME: disabling animations prevents state loops
      map.setView(center, zoom, { animate: false });
    }

    return setTimeout(() => map.resize(), 0);
  },

  render() {
    return (
      <div style={this.props.style || { height: "100%" }} className={this.props.className}>
        <div id={this.props.id} ref="map" />
        {this.state.map ? this.props.children : undefined}
      </div>
    );
  },
});
