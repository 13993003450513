import React, { Fragment } from "react";
import FieldSeasonReport from "reports/components/FieldSeasonReport";

import CropPositionReport from "modules/reports/components/CropPositionReport";
import { ReportError, NoReportData } from "modules/reports/components/ReportError";
import TabularReport from "modules/reports/components/TabularReport";
import withData from "modules/reports/components/withData";

import { Button } from "components/fl-ui";
import BlankSlate from "components/fl-ui/BlankSlate";
import Loading from "components/ui/loading";

const ReportTable = ({ loading, error, data, definition }) => {
  if (loading) {
    return (
      <Fragment>
        <Loading />
        <div style={{ textAlign: "center" }}>Your report is loading. This may take up to 30 seconds.</div>
      </Fragment>
    );
  }

  if (definition?.type === "marketing" && error) {
    return (
      <BlankSlate title="No crops available">
        <p style={{ fontSize: "1.142em", lineHeight: 1.25, marginBottom: "2em" }}>
          You do not have any marketing data for your crops. Once you add crops they will be available in this report.
          Use Marketing to track your breakeven price and evaluate profitability throughout the year.
        </p>
        <Button color="primary" href="/#marketing">
          Get Started With Marketing
        </Button>
      </BlankSlate>
    );
  }

  if (error) {
    return <ReportError />;
  }

  switch (definition.type) {
    case "field-season":
      return <FieldSeasonReport data={data} />;

    case "tabular":
      return <TabularReport columns={data.columns} rows={data.rows} showResultCount total={data.total} />;
    case "marketing":
      return <CropPositionReport data={data} />;
  }

  return <NoReportData />;
};

export default withData(ReportTable);
