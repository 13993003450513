/* eslint-disable react/display-name */
import CumulativeChart from "./CumulativeChart";
import DailyChart from "./DailyChart";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

const chartDataProvider = (WrappedComponent) => (props) => {
  const { data, period, year } = props;
  const { average, daily, plantingDate } = data;
  const dayZero =
    plantingDate && period === "planting" ? moment(plantingDate).startOf("day") : moment().year(year).startOf("year");

  const dailyData = useMemo(() => {
    const isLeapYear = moment().year(year).isLeapYear();
    const dailyFiltered = daily.filter(({ date }) => {
      date = moment(date);
      if (isLeapYear && date.isSame(`${year}-02-29`)) {
        return false;
      }

      return dayZero.isSameOrBefore(date, "day");
    });

    const keyedByDate = _.keyBy(dailyFiltered, "date");

    return new Map(Object.entries(keyedByDate));
  }, [daily, period, plantingDate, year]);

  const chartData = useMemo(() => {
    return average.reduce((chartData, { amount, day, month, total }) => {
      month = _.padStart(month, 2, "0");
      day = _.padStart(day, 2, "0");
      const date = moment([year, month, day].join("-"));
      const dailyAmount = dailyData.get(date.format("YYYY-MM-DD"));
      const isLeapDay = date.isLeapYear() && date.format("MM-DD") === "02-29";

      if ((period === "planting" && date.isBefore(dayZero, "day")) || isLeapDay) {
        return chartData;
      }

      return chartData.concat({
        amount: dailyAmount ? dailyAmount.amount : undefined,
        avgAmount: amount,
        cumAverage: total.toFixed(2),
        date: moment(date).format(),
        sum: dailyAmount ? dailyAmount.total : undefined,
      });
    }, []);
  }, [dailyData]);

  return <WrappedComponent {...props} chartData={chartData} />;
};

class RainfallHeatChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMouseDown: false,
      refAreaLeft: "",
      refAreaRight: "",
      refAreaSum: "",
      refAreaAvgSum: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data || this.props.period !== prevProps.period) {
      this.setState({
        refAreaLeft: "",
        refAreaRight: "",
      });
    }
  }

  handleMouseDown = (e) => {
    if (e && e.activeLabel) {
      this.setState({
        isMouseDown: true,
        refAreaLeft: e.activeLabel,
        refAreaRight: "",
        refAreaSum: "",
        refAreaAvgSum: "",
      });
    }
  };

  handleMouseMove = ({ activeLabel }) => {
    const { chartData } = this.props;
    const { isMouseDown, refAreaLeft } = this.state;

    if (isMouseDown && refAreaLeft) {
      const dateRange = [refAreaLeft, activeLabel].sort();
      const startOfRange = moment(dateRange[0]);
      const endOfRange = moment(dateRange[1]).endOf("day");

      let dailySum = 0;
      let averageSum = 0;
      chartData.forEach(({ amount, avgAmount, date }) => {
        if (moment(date).isBetween(startOfRange, endOfRange, undefined, "[]")) {
          dailySum += amount * 1000 || 0;
          averageSum += avgAmount * 1000 || 0;
        }
      });

      this.setState({
        refAreaRight: activeLabel,
        refAreaSum: moment().isSameOrAfter(dateRange[0], "day") ? (dailySum / 1000).toFixed(2) : undefined,
        refAreaAvgSum: (averageSum / 1000).toFixed(2),
      });
    }
  };

  handleMouseUp = () => {
    this.setState({ isMouseDown: false });
  };

  render() {
    const { refAreaAvgSum, refAreaLeft, refAreaRight, refAreaSum } = this.state;
    const {
      aspect,
      chartData,
      data: { plantingDate },
      period,
      year,
    } = this.props;

    const chartProps = {
      aspect,
      data: chartData,
      onMouseDown: this.handleMouseDown,
      onMouseMove: this.handleMouseMove,
      onMouseUp: this.handleMouseUp,
      period,
      plantingDate,
      refAreaAvgSum,
      refAreaLeft,
      refAreaRight,
      refAreaSum,
      year,
    };

    if (this.props.type === "cumulative") {
      return <CumulativeChart {...chartProps} />;
    }
    return <DailyChart {...chartProps} />;
  }
}

RainfallHeatChart.propTypes = {
  aspect: PropTypes.string,
  data: PropTypes.object,
  loading: PropTypes.bool,
  type: PropTypes.string,
};

export default chartDataProvider(RainfallHeatChart);
