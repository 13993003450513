import { ESSENTIALS, LITE, PREMIUM, RAINFALL } from "model/Subscription/constants";

const SubscriptionProductTypePolicy = {
  fields: {
    isPurchasable: {
      read(__, { readField }) {
        return ["primary", "secondary"].includes(readField("display"));
      },
    },

    mobilePlanId: {
      read(__, { readField }) {
        const planId = readField("id");

        const planAliases = {
          [LITE]: "Lite",
          [ESSENTIALS]: "Essentials",
          [PREMIUM]: "Complete",
          [RAINFALL]: "Basic Rainfall",
        };

        return planAliases[planId] ?? null;
      },
    },
  },
};

export default SubscriptionProductTypePolicy;
