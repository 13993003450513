import styles from "marketing/cards/cardStyles";
import { CargillCardBottomText } from "marketing/cards/components/CargillCardBottomText";
import { ExchangeTradedCellRow } from "marketing/cards/components/ExchangeTradedCellRow";
import formatAmount from "marketing/cards/utils/formatAmount";
import formatCurrency from "marketing/cards/utils/formatCurrency";
import CropCardProgressBarArea from "marketing/components/CropCardProgressBarArea";
import Cell, { CellRow } from "marketing/components/ui-elements/Cell";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { useFarmAdvisorConfig } from "collection/graphql/advisor";

import { css } from "components/fl-ui/aphrodite";
import { ColorPalette } from "components/fl-ui/colors";
import { Spacing } from "components/fl-ui/constants";
import CommodityYieldUnit from "components/units/CommodityYieldUnit";

const TitleHeading = styled.h3`
  text-transform: uppercase;
`;

const CargillCropCardDetails = styled(({ advisorRecommendation, className, hasContracts, marketedCrop }) => {
  const {
    data: { programName },
  } = useFarmAdvisorConfig();

  const totalUnprotectedAmount = marketedCrop?.progress?.total?.quantity;
  const recommendedMarketedAmount = totalUnprotectedAmount * advisorRecommendation.recommendedPercentage;
  const recommendedAmountIncrease = recommendedMarketedAmount - marketedCrop?.progress?.marketed?.quantity;

  const Unit = () => <CommodityYieldUnit commodity={marketedCrop?.commodity} defaultUnit="bu" />;

  const progressBarMarketedValue = (
    <span>
      {formatAmount(recommendedMarketedAmount)} <Unit />
    </span>
  );
  const progressBarUnprotectedValue = (
    <span>
      {formatAmount(totalUnprotectedAmount)} <Unit />
    </span>
  );

  return (
    <div className={className}>
      {advisorRecommendation ? (
        <CropCardProgressBarArea
          marketedPercentage={advisorRecommendation.recommendedPercentage * 100}
          marketedText={progressBarMarketedValue}
          unprotectedText={progressBarUnprotectedValue}
          titleArea={<TitleHeading>Recommended by {programName}</TitleHeading>}
        />
      ) : (
        <div className="no-recommendations-area">
          <TitleHeading>Recommended by {programName}</TitleHeading>
          <p>{programName} has not released recommendations for this crop</p>
        </div>
      )}

      <CellRow className={css(styles.dataRow, styles.borderTop)}>
        <Cell>Your Avg Sold Price</Cell>
        <Cell right>
          {formatCurrency(marketedCrop?.progress?.sold?.valuePerUnit)} <Unit />
        </Cell>
      </CellRow>

      <CellRow className={css(styles.dataRow)}>
        <Cell>{programName} Avg Sold Price</Cell>
        <Cell right>
          {formatCurrency(advisorRecommendation.averageSoldPrice)} <Unit />
        </Cell>
      </CellRow>

      {marketedCrop.commodity.isExchangeTraded ? (
        <ExchangeTradedCellRow marketedCrop={marketedCrop} />
      ) : (
        <CellRow className={css(styles.dataRow)}>
          <Cell>Budget Price</Cell>
          <Cell right>
            <span>
              {formatCurrency(marketedCrop.harvestPrice)} <Unit />
            </span>
          </Cell>
        </CellRow>
      )}

      {marketedCrop.__typename === "MarketedCrop" && (
        <CargillCardBottomText
          boldText={
            <>
              {formatAmount(recommendedAmountIncrease)} <Unit />
            </>
          }
          hasContracts={hasContracts}
          recommendedAmountIncrease={recommendedAmountIncrease}
        />
      )}
    </div>
  );
})`
  .blue-text {
    color: ${ColorPalette.blue};
    padding-right: ${Spacing.xxsmall};
    cursor: pointer;
  }

  .no-recommendations-area {
    p {
      padding: ${Spacing.medium};
      text-align: center;
    }
  }
`;

CargillCropCardDetails.propTypes = {
  marketedCrop: PropTypes.object.isRequired,
};

export { CargillCropCardDetails };
