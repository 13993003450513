import React from "react";

import ActivitiesBlankSlateBanner from "components/advertisements/banners/ActivitiesBlankSlateBanner";
import PayGateCommonBlankStatePage from "components/advertisements/pages/PayGateCommonBlankStatePage";

const ActivitiesBlankStatePage = () => {
  const steps = [
    "Log activities like planting, spraying, harvesting to multiple fields at once",
    "Connect to machine data provider to record activities for you while you’re in the field",
    "Export your records into a CSV file to simplify the process of reporting for insurance or farm programs",
  ];

  return <PayGateCommonBlankStatePage banner={ActivitiesBlankSlateBanner} steps={steps} />;
};

export default ActivitiesBlankStatePage;
