import { StyleSheet, css } from "aphrodite/no-important";
import classNames from "classnames";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { Row, Col } from "react-styled-flexboxgrid";

import { ALERTS_ALERT_DISMISSED } from "lib/metrics/events";
import NotificationActions from "modules/notifications/components/NotificationActions";
import NotificationItemIcon from "modules/notifications/components/NotificationItemIcon";

import { Button } from "components/fl-ui";
import { Cluster } from "components/fl-ui/Layout";
import { GreyColors, UIColors } from "components/fl-ui/colors";
import { Spacing, Typography, Mixins } from "components/fl-ui/constants";

const styles = StyleSheet.create({
  categoryIcon: {
    color: UIColors.blue,
    height: Spacing.regular,
    width: Spacing.regular,
  },
  container: {
    borderBottom: `1px solid ${GreyColors.smoke90}`,
    padding: Spacing.regular,
    ":last-child": {
      border: 0,
    },
  },
  header: {
    marginBottom: Spacing.xsmall,
  },
  metaData: {
    fontSize: Typography.sizes.sm,
    fontWeight: Typography.weights.medium,
    marginBottom: 0,
    marginLeft: Spacing.xxsmall,
  },
  metaType: {
    textTransform: "capitalize",
  },
  title: {
    color: Typography.colors.primary,
    fontSize: Typography.sizes.rg,
    fontWeight: Typography.weights.medium,
    lineHeight: 1.357,
    margin: 0,
  },
  body: {
    lineHeight: 1.357,
  },
  blue: {
    color: UIColors.blue,
  },
  medium: {
    color: UIColors.medium,
  },
  marginRight: {
    marginRight: Mixins.toRem(2),
  },
  lowerCase: {
    textTransform: "lowercase",
  },
});

class NotificationItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      created: moment(props.notification.created),
    };
  }

  updateTimeout = () => {
    clearTimeout(this.timeout);
    const now = moment();
    const nextMinute = moment(now).add(1, "minute").startOf("minute");
    this.timeout = setTimeout(() => {
      this.setState({ created: moment(this.props.notification.created) });
    }, nextMinute.format("x") - now.format("x"));
  };

  componentDidMount() {
    this.updateTimeout();
  }

  componentDidUpdate() {
    this.updateTimeout();
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  render() {
    const { dismissNotification, notification, className } = this.props;
    const { body, created, id, title, type } = notification;
    const itemType = {
      imagery: "Imagery",
      "market-prices": "Marketing",
      news: "Bushel Farm",
      rainfall: "Rainfall",
      "work-orders": "Work Orders",
    }[type];
    const handleDismissClick = () => {
      ALERTS_ALERT_DISMISSED.track({
        alertId: id,
      });
      dismissNotification(id);
    };

    return (
      <div className={classNames(css(styles.container), className)}>
        <Cluster between className={css(styles.header)}>
          <Cluster>
            <NotificationItemIcon
              className={css(styles.categoryIcon, styles.marginRight)}
              notification={notification}
            />
            <p className={css(styles.metaData)}>
              <span className={css(styles.metaType, styles.marginRight, styles.blue)}>{itemType}</span>
              <span className={css(styles.medium, styles.marginRight)}>&middot;</span>
              <span className={css(styles.lowerCase, styles.medium)}>
                {moment(this.state.created || created).fromNow()}
              </span>
            </p>
          </Cluster>

          <Button color="primary" link onClick={handleDismissClick} size="sm">
            Dismiss
          </Button>
        </Cluster>

        <Row>
          <Col xs>
            <h3 className={css(styles.title)}>{title}</h3>
            <p className={css(styles.medium, styles.body)}>{body}</p>
          </Col>
        </Row>

        <Row>
          <Col xs>
            <NotificationActions notification={notification} />
          </Col>
        </Row>
      </div>
    );
  }
}

NotificationItem.propTypes = {
  dismissNotification: PropTypes.func,
  notification: PropTypes.object,
};

NotificationItem.defaultProps = {
  dismissNotification: () => null,
};

export default NotificationItem;
