import { css } from "aphrodite/no-important";
import DetailsItem from "marketing/components/format/DetailsItem";
import { MIN_MAX, MIN_MAX_AUTOMATIC } from "marketing/constants";
import { contractsStyles } from "marketing/styles";
import { CASH_CONTRACT, FUTURES_CONTRACT, OPTIONS_CONTRACT, OPEN } from "marketing/utils/contractEnums";
import { doubleCurrency } from "marketing/utils/format";
import formatCommas from "marketing/utils/formatCommas";
import React from "react";

import { Well, WellDivider } from "components/fl-ui/Well";
import ContractUnit from "components/units/ContractUnit";

function cleanMoneyInput(input) {
  return parseFloat(input) || 0;
}

const ContractDetailsSidePanel = ({ contract, getContractTypeEnum, marketedCrop }) => {
  const { basis, cashContractType } = contract;
  const minMaxContracts = [MIN_MAX, MIN_MAX_AUTOMATIC];
  const ct = getContractTypeEnum();

  switch (ct) {
    case CASH_CONTRACT:
      const contractPrice = cleanMoneyInput(contract.contractPrice);
      const estimatedBasis = cleanMoneyInput(isNaN(parseFloat(basis)) ? marketedCrop.harvestBasis : basis);
      const premium = cleanMoneyInput(contract.premium);

      const basisSign = estimatedBasis < 0 ? "-" : "+";
      const cleanedBasis = Math.abs(estimatedBasis);
      const basisValue =
        cleanedBasis !== 0 ? `${basisSign}${doubleCurrency(cleanedBasis)}` : `${doubleCurrency(cleanedBasis)}`;

      let minPrice, maxPrice, minCash, maxCash;
      let netPrice = contractPrice + estimatedBasis - premium;
      let netPriceColor = netPrice >= 0 ? "green" : "red";
      netPrice = doubleCurrency(netPrice);
      if (minMaxContracts.includes(cashContractType)) {
        minPrice = cleanMoneyInput(contract.minPrice);
        maxPrice = cleanMoneyInput(contract.maxPrice);
        minCash = minPrice + estimatedBasis - premium;
        maxCash = maxPrice + estimatedBasis - premium;

        netPrice = doubleCurrency(minCash) + " – " + doubleCurrency(maxCash);
        netPriceColor = minCash >= 0 ? "green" : "red";
      }

      return (
        <Well>
          <h3 className={css(contractsStyles.marginTopZero)}>Contract Summary</h3>
          <WellDivider />
          {/* MinMax prices */}
          {minMaxContracts.includes(cashContractType) && (
            <DetailsItem label="Min price" value={doubleCurrency(minPrice)} valueBold />
          )}
          {minMaxContracts.includes(cashContractType) && (
            <DetailsItem label="Max price" value={doubleCurrency(maxPrice)} valueBold />
          )}
          {/* Non MinMax price */}
          {!minMaxContracts.includes(cashContractType) && (
            <DetailsItem label="Contract price" value={doubleCurrency(contractPrice)} valueBold />
          )}
          <DetailsItem label="Basis" value={basisValue} valueBold />
          <DetailsItem label="Fees and adjustments" value={`-${doubleCurrency(premium)}`} valueBold />
          <WellDivider />
          <DetailsItem label="Estimated net price" value={netPrice} valueBold valueColor={netPriceColor} />
        </Well>
      );
    case FUTURES_CONTRACT:
      let priceLabel = "";
      let priceValue = 0;
      let difference = 0;

      const { underlying } = contract;
      const hasMarketPrice = underlying && underlying.currentMarketPrice;

      if (contract.contractStatus === OPEN) {
        priceLabel = "Current";

        priceValue = hasMarketPrice ? underlying.currentMarketPrice : 0;

        difference =
          contract.contractPrice && hasMarketPrice ? underlying.currentMarketPrice - contract.contractPrice : 0;
      } else {
        priceLabel = "Settlement";
        priceValue = contract.settlementPrice ? contract.settlementPrice : "TBD";
        difference =
          contract.contractPrice && contract.settlementPrice
            ? contract.settlementPrice - contract.contractPrice
            : "TBD";
      }

      return (
        <Well>
          <h3 className={css(contractsStyles.marginTopZero)}>Contract Summary</h3>
          <WellDivider />
          <DetailsItem
            label={priceLabel}
            value={typeof priceValue === "string" || Number.isNaN(priceValue) ? priceValue : doubleCurrency(priceValue)}
            valueBold
          />
          <DetailsItem label="Price" value={doubleCurrency(contract.contractPrice || 0)} valueBold />
          <WellDivider />

          <DetailsItem
            label="Difference"
            value={typeof difference === "string" || Number.isNaN(difference) ? difference : doubleCurrency(difference)}
            valueBold
          />
          <DetailsItem
            label="Quantity"
            value={<ContractUnit contract={contract} amount={formatCommas(contract.quantity, { returnZero: true })} />}
            valueBold
          />
          <WellDivider />

          <DetailsItem
            label="Total gain/loss"
            value={contract.netGain ? doubleCurrency(contract.netGain) : "TBD"}
            valueBold
            valueColor={contract.netGain > 0 ? "green" : "red"}
          />
        </Well>
      );
    case OPTIONS_CONTRACT:
      return (
        <Well>
          <h3 className={css(contractsStyles.marginTopZero)}>Contract Summary</h3>
          <DetailsItem
            label="Strike price"
            value={contract.strikePrice ? doubleCurrency(contract.strikePrice) : "TBD"}
            valueBold
          />
          <DetailsItem label="Fees and adjustments" value={doubleCurrency(contract.premium || 0)} valueBold />
          <DetailsItem
            label="Quantity"
            value={
              !contract.quantity ? "TBD" : <ContractUnit contract={contract} amount={formatCommas(contract.quantity)} />
            }
            valueBold
          />
          <WellDivider />
          <DetailsItem
            label="Revenue impact"
            value={contract.revenueImpact ? doubleCurrency(contract.revenueImpact) : "TBD"}
            valueBold
            valueColor={contract.revenueImpact > 0 ? "green" : "red"}
          />
        </Well>
      );
  }
};

export default ContractDetailsSidePanel;
