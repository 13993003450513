import { useQuery } from "@apollo/client";
import _ from "lodash";
import useProductIndexFilters from "products/hooks/useProductIndexFilters";

import { getProductPurchaseSummaries } from "collection/graphql/products/queries";
import useCurrentCropYear from "hooks/useCurrentCropYear";

const buildFilteredQuery = (filters) => {
  const { commodities, endDate, product, productTypes, restrictedUse, startDate, year } = filters;
  const vars = {
    commodities: commodities.length > 0 ? commodities : null,
    products: product ? [product.id] : null,
    productTypes: _.size(productTypes) > 0 ? productTypes : null,
    purchaseEndDate: endDate,
    purchaseStartDate: startDate,
    year,
  };
  if (restrictedUse) {
    vars.restrictedUse = true;
  }

  return vars;
};

const useProductIndexData = () => {
  const cropYear = useCurrentCropYear()[0];

  const { applyFiltersAndSort, ...filterProps } = useProductIndexFilters({ cropYear });
  const { data, refetch } = useQuery(getProductPurchaseSummaries, {
    errorPolicy: "ignore",
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: buildFilteredQuery({ ...filterProps.filters, year: cropYear }),
  });

  const purchaseSummaries = applyFiltersAndSort(data?.purchaseSummaries ?? []);

  return {
    ...filterProps,
    cropYear,
    hasDataLoaded: !!data?.purchaseSummaries,
    purchaseSummaries,
    refreshQuery: refetch,
    totalPurchases: _.size(data?.totalPurchaseCount),
  };
};

export default useProductIndexData;
