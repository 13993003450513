import PropTypes from "prop-types";
import React from "react";
import AsyncSelect from "react-select/async";
import styled from "styled-components";

import MapUtils from "lib/map/utils";
import { MAP_LOCATION_SEARCH } from "lib/metrics/events";

const SearchIcon = styled.img`
  height: 1.5rem;
  margin: 0.25rem;
  opacity: 0.6;
  width: 1.5rem;
`;

const DropdownIndicator = () => <SearchIcon src="/images/icons/search.svg" />;
const LoadingIndicator = () => null;

const formatLocation = (data) => {
  const { center, geometry } = data;
  const location = { lat: center[1], lng: center[0] };
  const bounds = MapUtils.getBoundsFromGeometry(geometry);
  return { bounds, location };
};

const geocoderSearch = async (query) => {
  const url = [
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${process.env.MAPBOX_PUBLIC_TOKEN}`,
    "country=us,ca",
    "language=en",
    "proximity=ip",
    "types=place,postcode,address",
  ].join("&");

  const response = await fetch(url);
  const data = await response.json();
  return data.features.map((feature) => ({ ...feature, query }));
};

const handleChange = (data, onChange) => {
  if (data) {
    const { query, ...locationData } = data;
    MAP_LOCATION_SEARCH.track({ query });
    onChange(formatLocation(locationData));
  }
};

const noOptionsMessage = "Sorry, we couldn't find any results.";
const placeholderText = 'Type an address or "lng, lat" to search';

const GeocoderSearch = ({ onChange }) => {
  return (
    <div id="map-control-search" style={{ pointerEvents: "all" }}>
      <AsyncSelect
        cacheOptions
        components={{ DropdownIndicator, LoadingIndicator }}
        defaultOptions
        getOptionLabel={({ place_name }) => place_name}
        getOptionValue={({ id }) => id}
        isClearable
        loadOptions={(text) => geocoderSearch(text)}
        noOptionsMessage={({ inputValue }) => (inputValue ? noOptionsMessage : placeholderText)}
        onChange={(data) => handleChange(data, onChange)}
        placeholder={placeholderText}
        styles={{ valueContainer: (base) => ({ ...base, whiteSpace: "nowrap", width: "17rem" }) }}
      />
    </div>
  );
};

GeocoderSearch.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default GeocoderSearch;
