import leaflet from "leaflet";

import App from "layout/app";
import GeoJsonLayer from "lib/map/layers/GeoJsonLayer";
import { FIELD_DRAW_POINT_DELETE } from "lib/metrics/events";

export const getBounds = (geometry) => {
  const { clientWidth, clientHeight } = document.body;
  const bounds = leaflet.geoJson(geometry).getBounds();
  const paddingBottomRight = clientWidth < 800 ? [0, clientHeight / 2] : [clientWidth / 3, 0];

  return [bounds, { paddingBottomRight }];
};

export const getDefaultOptions = (options) => ({
  drawCircleMarker: false,
  drawMarker: false,
  drawPolyline: false,
  editMode: false,
  oneBlock: true,
  position: "topright",
  ...(options || {}),
});

export const getDrawToolsOptions = (options) => ({
  drawControls: true,
  editControls: false,
  drawCircleMarker: false,
  drawMarker: false,
  drawPolyline: false,
  editMode: false,
  oneBlock: true,
  position: "topright",
  ...(options || {}),
});

export const getEditToolsOptions = (options) => ({
  drawControls: false,
  editControls: true,
  editMode: false,
  oneBlock: true,
  position: "topright",
  rotateMode: false,
  ...(options || {}),
});

export const getPoints = (eventType, points) => {
  if (eventType === "pm:vertexremoved") {
    FIELD_DRAW_POINT_DELETE.track();
    return points < 4 ? 0 : points - 1;
  }

  return points + 1;
};

export const getLayerEditSettings = (removeVertexValidation) => ({
  allowSelfIntersection: false,
  removeLayerBelowMinVertexCount: false,
  removeVertexOn: "click",
  removeVertexValidation,
});

export const style = { color: "white", fillColor: "black", fillOpacity: 0.3 };
export const intersectStyle = { color: "red", fillColor: "red", fillOpacity: 0.3 };

export const highlightTemporaryLayers = (data, map, onFinish) => {
  const { message, temporaryFeatures } = data;
  const temporaryLayers = new GeoJsonLayer();
  App.notify(message);
  temporaryFeatures.forEach((feature) => temporaryLayers.addFeature(feature, { style: intersectStyle }));
  temporaryLayers.addTo(map);

  setTimeout(() => {
    temporaryLayers.remove();
    onFinish(data);
  }, 2000);
};
