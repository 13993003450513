import { useMutation, useQuery } from "@apollo/client";
import { pick, sortBy } from "lodash";
import moment from "moment";
import { useMemo } from "react";

import { UPDATE_FIELD_ATTACHMENTS } from "collection/graphql/fields/mutations";
import { getFieldAttachments } from "collection/graphql/fields/queries";

// exports for unit testing
export const getFormatedAttachments = (attachments) =>
  attachments.map((file) => ({
    ...file,
    date: moment(file.created).format("MMM DD, YYYY"),
    size: file.size && `${(file.size / 1024).toFixed(0)}kb`,
    uploadedBy: `${file.user.firstName} ${file.user.lastName}`,
  }));

export const getUpdatedAttachmentsData = (fieldAttachments, newData) => {
  const attachmentsData = fieldAttachments.map((file) => pick(file, ["filepickerId", "id", "name"]));

  if (typeof newData === "object") {
    if (Array.isArray(newData)) {
      // create new attachments
      return attachmentsData.concat(newData.map((file) => pick(file, ["filepickerId", "name", "size"])));
    } else {
      // update attachment name
      attachmentsData.find(({ id }) => id === newData.id).name = newData.name;
      return attachmentsData;
    }
  } else if (typeof newData === "number") {
    // delete attachment by id
    return attachmentsData.filter(({ id }) => id !== newData);
  }

  return attachmentsData;
};

const useFieldAttachments = (fieldId) => {
  const { data, loading } = useQuery(getFieldAttachments, { variables: { fieldId } });
  const [updateFieldWithAttachment, { loading: isUpdating }] = useMutation(UPDATE_FIELD_ATTACHMENTS);
  const fieldAttachments = data?.field?.attachments ?? [];

  const attachments = useMemo(() => {
    const nameSortedAttachments = sortBy(fieldAttachments, ({ name }) => name.toLowerCase());
    return sortBy(getFormatedAttachments(nameSortedAttachments), ({ created }) => -moment(created).unix());
  }, [fieldAttachments]);

  const updateAttachments = async (newData) => {
    const field = { attachments: getUpdatedAttachmentsData(fieldAttachments, newData), id: fieldId };
    await updateFieldWithAttachment({ variables: { field } });
  };

  return { attachments, isUpdating, loading, updateAttachments };
};

export default useFieldAttachments;
