import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";

import useCropYears from "collection/graphql/fields/hooks/useCropYears";

import YearPicker from "components/fl-ui/YearPicker";

const CropYearPicker = () => {
  const cropYears = useCropYears();
  const disabledYears = new Set();
  const years = _.map(cropYears, ({ used, year }) => {
    if (!used) {
      disabledYears.add(year);
    }

    return year;
  })
    .sort()
    .reverse();

  return <YearPicker disabledYears={disabledYears} years={years} />;
};

CropYearPicker.propTypes = {
  onChange: PropTypes.func,
  year: PropTypes.number,
};

CropYearPicker.defaultProps = {
  onChange: () => {},
};

export default CropYearPicker;
