import { StyleSheet } from "aphrodite/no-important";

import { UIColors } from "components/fl-ui/colors";
import { Borders, Spacing, Typography } from "components/fl-ui/constants";

const cardStyles = StyleSheet.create({
  cardHeader: {
    borderBottom: Borders.regular,
    height: "auto",
    paddingBottom: Spacing.medium,
  },
  cardHeader_marketedCrop: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
  },
  cropBadgeContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: Spacing.minor,
  },
  cardTitle: {
    fontSize: Typography.sizes.xxl,
    margin: 0,
    "@media only screen and (max-width: 85.714rem)": {
      fontSize: Typography.sizes.xl,
    },
    "@media only screen and (max-width: 34.285rem)": {
      fontSize: Typography.sizes.lg,
    },
  },
  dataRow: {
    borderBottom: Borders.regular,
    paddingTop: Spacing.regular,
    paddingBottom: Spacing.regular,
  },
  dataRowCol_right: {
    display: "flex",
    justifyContent: "flex-end",
  },
  dataPointLabel_large: {
    fontWeight: Typography.weights.medium,
  },
  irrigationContainer: {
    alignItems: "center",
    display: "flex",
  },
  irrigationIcon: {
    color: UIColors.blue,
    marginRight: Spacing.xsmall,
  },
  irrigationText: {
    color: UIColors.medium,
    fontSize: Typography.sizes.rg,
    textTransform: "uppercase",
    letterSpacing: "0.5px",
    margin: 0,
  },
  modalBodyText: {
    display: "block",
    fontSize: Typography.sizes.rg,
    fontWeight: Typography.weights.regular,
    lineHeight: 1.357,
    marginBottom: Spacing.xxsmall,
  },
  menuIcon: {
    height: 24,
    width: 24,
  },
  rightMargin: {
    marginRight: `${Spacing.small} !important`,
  },
  underlinedText: {
    textDecoration: "underline",
  },
  marginTop_16: {
    marginTop: Spacing.regular,
  },
  infoIcon: {
    height: 16,
    width: 16,
    display: "inline-block",
    marginLeft: 6,
    verticalAlign: "middle",
  },
  noBorderBottom: {
    borderBottom: "none",
    paddingBottom: 0,
  },
});

export default cardStyles;
