import PropTypes from "prop-types";
import React, { useState } from "react";

import FilePopoverContainer from "components/field/FilePopoverContainer";
import { getSrcURL } from "components/field/utils";
import {
  AttachmentDetailContainer,
  AttachmentListItem,
  AttachmentThumbnail,
  ButtonLink,
} from "fields/components/styledComponents";

const AttachmentItem = ({ file, isUpdating, selectedFile, selectFile, showPreview, updateAttachments }) => {
  const { filepickerId, name } = file;
  const [hasPreview, setHasPreview] = useState(true);
  const handleError = ({ currentTarget }) => {
    currentTarget.onerror = null; // prevents looping
    currentTarget.src = "/images/app_icons/bushel-farm-icon-alt.svg";
    setHasPreview(false);
  };

  return (
    <AttachmentListItem>
      <div>
        <AttachmentThumbnail
          isSelected={filepickerId === selectedFile?.filepickerId}
          onClick={selectFile}
          onError={handleError}
          src={getSrcURL(filepickerId)}
        />
        <AttachmentDetailContainer>
          <ButtonLink color="primary" link onClick={selectFile}>
            {name}
          </ButtonLink>
          <span>{file.date}</span>
          <span>{file.size ?? "--"}</span>
        </AttachmentDetailContainer>
      </div>
      <FilePopoverContainer
        file={file}
        handlePreview={() => showPreview(file)}
        hasPreview={hasPreview}
        isUpdating={isUpdating}
        updateAttachments={updateAttachments}
      />
    </AttachmentListItem>
  );
};

const AttachmentList = ({ fileList, isUpdating, selectedFile, setSelectedFile, showPreview, updateAttachments }) => {
  const handlePreview = (file) => {
    setSelectedFile(file);
    showPreview();
  };

  return fileList.map((file) => (
    <AttachmentItem
      file={file}
      isUpdating={isUpdating}
      key={file.filepickerId}
      selectedFile={selectedFile}
      selectFile={() => setSelectedFile(file)}
      showPreview={handlePreview}
      updateAttachments={updateAttachments}
    />
  ));
};

AttachmentList.propTypes = {
  fileList: PropTypes.arrayOf(PropTypes.object).isRequired,
  isUpdating: PropTypes.bool,
  selectedFile: PropTypes.shape({
    filepickerId: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  setSelectedFile: PropTypes.func,
  showPreview: PropTypes.func,
  updateAttachments: PropTypes.func,
};

export default AttachmentList;
