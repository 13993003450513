import _ from "lodash";
import {
  MIN_COST_CORN,
  MAX_COST_CORN,
  MIN_YIELD_CORN,
  MAX_YIELD_CORN,
  MIN_COST_SOYBEANS,
  MAX_COST_SOYBEANS,
  MIN_YIELD_SOYBEANS,
  MAX_YIELD_SOYBEANS,
} from "marketing/constants";

import { CORN, SOYBEANS } from "lib/constants";

const exists = (value) => {
  return _.isNumber(value) || !_.isEmpty(value);
};

const isNumber = (val) => {
  return /^[+-]{0,1}?\d*(\.\d{0,2})?$/.test(val);
};

const isDecimal = (val) => {
  return /^[+-]{0,1}?\d*(\.\d{0,2})$/.test(val);
};

const isInteger = (val) => {
  return /^[+-]{0,1}?\d*$/.test(val);
};

const isPositive = (val) => {
  return /^\d*(\.\d{0,2})?$/.test(val);
};

const isNegative = (val) => {
  return /^[-]\d*(\.\d{0,2})?$/.test(val);
};

const minMax = (val, min, max) => {
  return val >= min && val <= max;
};

/**
 * Checks a MarketedCrop to see if it has properties that could potentially
 * be outside of typical ranges (CORE-1753). Returns true if the crop looks
 * ok, false otherwise.
 *
 * @param {Object} marketedCrop
 * @param {Object} marketedCrop.commodity
 * @param {Number} marketedCrop.commodity.id
 * @param {Number} marketedCrop.averageYield
 * @param {Number} marketedCrop.productionCostPerAcre
 * @return {Boolean} returns true if crop is within tolerances
 */
const isCropWithinTolerances = ({ averageYield, commodity, productionCostPerAcre }) => {
  let isInRange = true;

  if (commodity.id === CORN) {
    isInRange = minMax(+averageYield, MIN_YIELD_CORN, MAX_YIELD_CORN);
    isInRange = isInRange && minMax(+productionCostPerAcre, MIN_COST_CORN, MAX_COST_CORN);
  } else if (commodity.id === SOYBEANS) {
    isInRange = minMax(+averageYield, MIN_YIELD_SOYBEANS, MAX_YIELD_SOYBEANS);
    isInRange = isInRange && minMax(+productionCostPerAcre, MIN_COST_SOYBEANS, MAX_COST_SOYBEANS);
  }

  return isInRange;
};

export { exists, isCropWithinTolerances, isNumber, isDecimal, isInteger, isPositive, isNegative, minMax };
