import { css } from "aphrodite/no-important";
import PercentProduction from "marketing/components/format/PercentProduction";
import OpenClosedTab from "marketing/components/ui-elements/OpenClosedTab";
import tableStyleSheet from "marketing/tables/styles";
import useContractTables from "marketing/tables/useContractTables";
import PropTypes from "prop-types";
import React from "react";

import { Button } from "components/fl-ui";

const ContractsTableHeader = ({ category = "hedged", productionStats, title }) => {
  const { filters, onAdd, updateFilters } = useContractTables();
  const showOpenClosedToggle = !!filters;

  return (
    <>
      <div className={css(tableStyleSheet.header)}>
        <h3>
          <span className={css(tableStyleSheet.title)}>{title}</span>
          <span className={css(tableStyleSheet.infoText)}>
            <PercentProduction
              category={category}
              percentage={productionStats.percentage}
              quantity={productionStats.quantity}
            />
          </span>
        </h3>

        {!showOpenClosedToggle && (
          <Button onClick={onAdd} link color="primary">
            + Add new
          </Button>
        )}
      </div>

      {showOpenClosedToggle && (
        <div className={css(tableStyleSheet.actionWrapper)}>
          <OpenClosedTab
            onChange={(contractStatus) => updateFilters({ contractStatus })}
            value={filters.contractStatus}
          />

          <Button onClick={onAdd} link color="primary">
            + Add new
          </Button>
        </div>
      )}
    </>
  );
};

ContractsTableHeader.propTypes = {
  category: PropTypes.oneOf(["hedged", "sold"]),
  productionStats: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

export default ContractsTableHeader;
