import { gql } from "@apollo/client";

export default gql`
  fragment Config on MachineDataImportConfig {
    id
    enableAutomaticImport
    enableAutomaticFieldImport
    activityTypeCategories {
      category
      enabled
    }
    fields {
      enabled
      field {
        id
        name
      }
    }
  }
`;
