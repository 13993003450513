import { useState } from "react";

import { getCurrentSubscription } from "collection/graphql/subscription";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import useRestQuery from "hooks/useRestQuery";
import { ACREAGE_PAYGATE, INTEGRATIONS_PAYGATE } from "modules/settings/components/Integrations/constants";

import useFieldData from "fields/hooks/useFieldData";

const useModalState = () => {
  const [activeModal, setActiveModal] = useState();
  const acreLimit = useRestQuery(getCurrentSubscription).data?.subscription?.acreLimit ?? NaN;
  const { totalAcreage } = useFieldData();
  const exceedsAcreageLimit = totalAcreage >= acreLimit;
  const hasIntegrationsFeature = useEnterpriseFeature("integrations");

  return {
    activeModal,
    setActiveModal,
    protectCallback: (callback) => (event) => {
      if (!hasIntegrationsFeature) {
        setActiveModal(INTEGRATIONS_PAYGATE);
      } else if (exceedsAcreageLimit) {
        setActiveModal(ACREAGE_PAYGATE);
      } else {
        callback(event);
      }
    },
  };
};

export default useModalState;
