import useEquipmentImage from "../hooks/useEquipmentImage";
import isLocalIcon from "../lib/isLocalIcon";
import themeGet from "@styled-system/theme-get";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const EquipmentImageContainer = styled.div`
  border: 2px solid ${themeGet("colors.smoke80")};
  display: grid;
  height: ${({ size }) => size}px;
  place-content: center;
  width: ${({ size }) => size}px;
`;

const EquipmentImage = ({ icon, name, size, type }) => {
  const alt = isLocalIcon(icon) ? icon : name;
  const src = useEquipmentImage(type, icon, size);

  return (
    <EquipmentImageContainer size={size}>
      <img alt={alt} src={src} />
    </EquipmentImageContainer>
  );
};

EquipmentImage.propTypes = {
  icon: PropTypes.string,
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
  type: PropTypes.oneOf(["implement", "tractor"]).isRequired,
};

EquipmentImage.defaultProps = {
  size: 150,
};

export default EquipmentImage;
