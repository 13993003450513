import PropTypes from "prop-types";
import React, { useEffect, useMemo, useRef, useState } from "react";

import useViewType from "hooks/useViewType";
import Map from "lib/map/map";
import MapUtils from "lib/map/utils";

import { getCropGeoJSON, getLayerCollection, getSnappedLayerData } from "components/field/utils";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import ModalActionButtonGroup from "components/fl-ui/Modal/ModalActionButtonGroup";
import withMapDrawing from "fields/add/withMapDrawing";

const ActionButtons = ({ onCancel, onClear, onUpdate }) => (
  <div style={{ marginTop: "1rem" }}>
    <ModalActionButtonGroup
      apply={{ action: onUpdate, buttonText: "Done" }}
      cancel={{ action: onCancel, buttonText: "Cancel" }}
      danger={{ action: onClear, buttonText: "Clear map" }}
    />
  </div>
);

const DrawHandler = withMapDrawing(ActionButtons);

const ActivityAreaDrawHandler = ({ crop, feature, field, fillPattern, onCancel, onChange, onUpdate }) => {
  const [map, setMap] = useState(null);
  const [boundaryLayerCollection, setBoundaryLayerCollection] = useState(null);
  const mapRef = useRef();
  const cropGeometry = crop?.geometry;
  const fieldGeometry = field?.geometry;
  const noAttribution = useViewType() === "mobile";

  const addBoundaries = (map) => {
    const fieldFeature = getCropGeoJSON({ field });
    const cropFeature = getCropGeoJSON({ crop });
    const layerCollection = getLayerCollection(boundaryLayerCollection, [fieldFeature, cropFeature], map);
    setBoundaryLayerCollection(layerCollection);
  };

  const composeFeature = (feature) => {
    return {
      ...feature,
      properties: {
        ...feature.properties,
        style: { ...feature.properties.style, fillPattern },
      },
    };
  };

  const sanitizeLayer = (layer) => {
    const message = `Activity geometry is restricted to the crop area`;
    return getSnappedLayerData(crop.id, layer, message, cropGeometry, fillPattern);
  };

  const setUpMap = () => {
    const maxBounds = MapUtils.getMaxBounds(fieldGeometry).pad(0.2);

    if (map) {
      map.setMaxBounds(maxBounds);
      addBoundaries(map);
      fillPattern && fillPattern.addTo(map);
    } else {
      const newMap = new Map(mapRef.current, { ignoreLocation: true, maxBounds, noAttribution });
      setMap(newMap);
      addBoundaries(newMap);
      fillPattern && fillPattern.addTo(newMap);
    }
  };

  useEffect(() => {
    if (fieldGeometry) {
      setUpMap();
    }
  }, [cropGeometry, fieldGeometry]);

  useEffect(() => {
    return () => {
      map?.remove();
    };
  }, []);

  return (
    <>
      {!map && <LoadingWrapper loadingText="Loading map..." />}
      <div ref={mapRef} style={{ minHeight: "50vh", width: "100%", zIndex: 0 }} />

      {!!map && feature && (
        <DrawHandler
          feature={composeFeature(feature)}
          map={map}
          onCancel={onCancel}
          onChange={onChange}
          onUpdate={onUpdate}
          sanitizeLayer={sanitizeLayer}
          pattern={fillPattern}
        />
      )}
    </>
  );
};

ActivityAreaDrawHandler.propTypes = {
  crop: PropTypes.shape({ geometry: PropTypes.object, id: PropTypes.number }).isRequired,
  feature: PropTypes.shape({
    geometry: PropTypes.object,
    properties: PropTypes.object,
    type: PropTypes.string,
  }),
  field: PropTypes.shape({ geometry: PropTypes.object, id: PropTypes.number }).isRequired,
  fillPattern: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default ActivityAreaDrawHandler;
