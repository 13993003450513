import _ from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";

import useProductRecipeMutations from "collection/graphql/recipes/hooks/useProductRecipeMutations";
import useCurrentEnterprise from "hooks/useCurrentEnterprise";
import useSchemaValidation from "hooks/useSchemaValidation";
import App from "layout/app";

import { Form, FormGroup, Input } from "components/fl-ui/Form";
import CloseX from "components/fl-ui/Icons/CloseX";
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "components/fl-ui/Modal/Modal";
import ModalActionButtonGroup from "components/fl-ui/Modal/ModalActionButtonGroup";
import RecipeIngredientsList from "components/product_recipe/RecipeIngredientsList";
import ProductRecipeFormSchema from "components/product_recipe/schemas/ProductRecipeFormSchema";

const useRecipeForm = (initialValues) => {
  const isEdit = !!initialValues?.id;
  const [create, update] = useProductRecipeMutations();
  const [fields, setFields] = useState(() => {
    if (initialValues) {
      const fields = { ...initialValues };
      fields.ingredients = fields.ingredients.map(({ product, ...rest }) => ({
        ...rest,
        product: product.id,
        unitType: product.commodity?.seedUnitType ?? product.unitType,
      }));

      return fields;
    } else {
      return {
        name: "",
        ingredients: [
          {
            amount: undefined,
            amountUnit: "",
            product: undefined,
            rateUnit: "",
            unitType: "",
          },
        ],
      };
    }
  });

  const { errorsAtPath, isValid } = useSchemaValidation(ProductRecipeFormSchema, fields);
  const setField = (name, value) => setFields((fields) => ({ ...fields, [name]: value }));

  const { currentEnterprise } = useCurrentEnterprise();
  const saveRecipe = async () => {
    const recipeInput = _.pick(fields, ["created", "enterpriseId", "name", "id", "updated"]);

    recipeInput.enterpriseId = currentEnterprise.id;
    recipeInput.ingredients = fields.ingredients.map((ingredient) => {
      const input = _.pick(ingredient, [
        "amount",
        "amountUnit",
        "created",
        "enterpriseId",
        "id",
        "productRecipeId",
        "rateUnit",
        "updated",
      ]);
      input.productId = ingredient.product;
      return input;
    });

    return isEdit ? update(recipeInput) : create(recipeInput);
  };

  return {
    errorsAtPath,
    isEdit,
    isValid,
    fields,
    saveRecipe,
    setField,
  };
};

const RecipeCrud = (props) => {
  const { onFinish, recipe } = props;
  const destroy = useProductRecipeMutations()[2];
  const { errorsAtPath, fields, isEdit, isValid, saveRecipe, setField } = useRecipeForm(recipe);
  const [hasNameInputBlurred, setHasNameInputBlurred] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleDelete = () => {
    const message = `This will delete "${fields.name}" and all of its associated data.`;
    App.confirm({ confirm: "DELETE", message }).then(async () => {
      await destroy(recipe.id);
      App.notify("Mix deleted.");
      onFinish();
    });
  };

  const handleSave = async () => {
    setIsSaving(true);
    const savedRecipe = await saveRecipe();
    setIsSaving(false);
    onFinish(savedRecipe);
  };

  return (
    <Modal width={700}>
      <ModalHeader>
        <ModalTitle>{isEdit ? "Edit Mix" : "Add Mix"}</ModalTitle>
        <CloseX onClick={() => onFinish()} />
      </ModalHeader>

      <ModalBody>
        <Form noValidate>
          <FormGroup label="Name" error={hasNameInputBlurred && errorsAtPath("name")}>
            <Input
              onBlur={() => setHasNameInputBlurred(true)}
              type="text"
              value={fields.name}
              onChange={(event) => setField("name", event.target.value)}
            />
          </FormGroup>

          <FormGroup label="Ingredients">
            <RecipeIngredientsList
              ingredients={fields.ingredients}
              onChange={(ingredients) => setField("ingredients", ingredients)}
            />
          </FormGroup>
        </Form>
      </ModalBody>

      <ModalFooter>
        <ModalActionButtonGroup
          apply={{ action: handleSave, buttonText: "Save Mix", disabled: !isValid() || isSaving }}
          cancel={{ action: () => onFinish(), buttonText: "Cancel" }}
          danger={isEdit ? { action: handleDelete, buttonText: "Delete" } : null}
        />
      </ModalFooter>
    </Modal>
  );
};

RecipeCrud.propTypes = {
  onFinish: PropTypes.func.isRequired,
  recipe: PropTypes.object,
};

export default RecipeCrud;
