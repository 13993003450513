import leaflet from "leaflet";
import _ from "lodash";
import React from "react";
import { createRoot } from "react-dom/client";

import CropYearPicker from "components/fl-ui/CropYearPicker";

const YearControl = leaflet.Control.extend({
  includes: L.evented,
  el: leaflet.DomUtil.create("div", "year-picker"),

  options: {
    position: "topright",
  },

  onAdd() {
    leaflet.DomEvent.disableClickPropagation(this.el);
    //deferred to make sure the element is in the dom before rendering
    _.defer(() => {
      const root = createRoot(this.el); // createRoot(container!) if you use TypeScript
      root.render(<CropYearPicker />);
    });

    return this.el;
  },

  onRemove() {
    this.map = null;
  },
});

export default new YearControl();
