import { css } from "aphrodite";
import { SupportEmailLink, SalesPhoneLink } from "billing/lib";
import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";

import styles from "modules/carbon_enrollment/styles";

import { Button } from "components/fl-ui";
import Icon from "components/fl-ui/Icons/Icon";

const CarbonIneligible = ({ onCancel }) => (
  <Row className={css(styles.ineligibleContainer)}>
    <Col xs className={css(styles.centerText)}>
      <Icon className={css(styles.warningIcon)} icon="warning" />
      <h2>Your account doesn’t qualify for the carbon credit program at this time</h2>
      <p className={css(styles.copy)}>
        {"Your fields are either out of the service area, already enrolled, or don’t qualify for other reasons. "}
        If you have questions, you can reach us at <SalesPhoneLink /> or <SupportEmailLink />.
      </p>
      <Button className={css(styles.ineligibleButton)} color="primary" hollow onClick={onCancel} size="lg">
        Close
      </Button>
    </Col>
  </Row>
);

CarbonIneligible.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default CarbonIneligible;
