import { DatePicker } from "@farmlogs/fl-ui";
import EquipmentChooser from "activity/components/choosers/EquipmentChooser";
import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";

import { getAllPeople } from "collection/graphql/people/queries";
import useRestSuspenseQuery from "hooks/useRestSuspenseQuery";

import { FormGroup, NumericInput, Select, TextArea } from "components/fl-ui/Form";

const MaintenanceRecordForm = (props) => {
  const { formId, onChange, onSubmit } = props;
  const { date, engineHours, equipment, laborHours, people, notes } = props.formFields;
  const personId = people[0];
  const handleChange = (event, payload) => onChange(payload);
  const handleNumericChange = (event) => {
    const { name, value } = event.target;
    onChange({ [name]: value });
  };
  const { people: allPeople } = useRestSuspenseQuery(getAllPeople).data;
  const peopleOptions = allPeople.map(({ id, name }) => ({ label: name, value: id }));

  return (
    <form id={formId} onSubmit={onSubmit}>
      <FormGroup label="Equipment">
        <EquipmentChooser onChange={(equipment) => onChange({ equipment })} value={equipment} />
      </FormGroup>

      <Row>
        <Col md={6} sm>
          <FormGroup inputId="date" label="Date">
            <DatePicker onChange={(date) => onChange({ date })} value={date} />
          </FormGroup>
        </Col>

        <Col md={6} sm>
          <FormGroup inputId="person" label="Person">
            <Select
              id="person"
              name="people"
              onChange={(event) => onChange({ people: [+event.target.value] })}
              options={peopleOptions}
              value={personId}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6} sm>
          <FormGroup inputId="engineHours" label="Engine Hours">
            <NumericInput
              id="engineHours"
              min={0}
              name="engineHours"
              onChange={handleNumericChange}
              step={1}
              suffix="hours"
              type="integer"
              value={engineHours}
            />
          </FormGroup>
        </Col>

        <Col md={6} sm>
          <FormGroup inputId="laborHours" label="Time Spent">
            <NumericInput
              id="laborHours"
              min={0}
              name="laborHours"
              onChange={handleNumericChange}
              step={1}
              suffix="hours"
              type="integer"
              value={laborHours}
            />
          </FormGroup>
        </Col>
      </Row>

      <FormGroup inputId="notes" label="Notes">
        <TextArea id="notes" name="notes" onChange={handleChange} style={{ minHeight: "200px" }} value={notes} />
      </FormGroup>
    </form>
  );
};

MaintenanceRecordForm.propTypes = {
  formId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default MaintenanceRecordForm;
