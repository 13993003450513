import { gql } from "@apollo/client";

export default gql`
  fragment File on MachineDataFile {
    id
    status
    originalFile
    name
  }
`;
