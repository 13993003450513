import { gql } from "@apollo/client";

export default gql`
  query GetFieldCrops(
    $activityTypeCategories: [ActivityTypeCategory]
    $commodities: [Int]
    $fields: [Int]
    $years: [Int]
  ) {
    fieldCrops: getFieldCrops(
      activityTypeCategories: $activityTypeCategories
      commodities: $commodities
      fields: $fields
      years: $years
    ) {
      ...FieldCrop
    }
  }
`;
