import React from "react";
import styled from "styled-components";

import Cluster from "components/fl-ui/Layout/Cluster";

const CropProgressBarHeader = styled(({ className, marketedPercentage, unprotectedPercentage }) => (
  <Cluster between className={className}>
    <span>
      <b>{marketedPercentage}</b>MARKETED
    </span>
    <span>
      UNPROTECTED<b>{unprotectedPercentage}</b>
    </span>
  </Cluster>
))`
  span {
    font-size: 0.875rem;
    padding: 0.5rem 0.25rem;

    b {
      color: black;
      padding: 0 5px;
      font-size: 1rem;
    }
  }
`;

export default CropProgressBarHeader;
