import { gql } from "@apollo/client";

export default gql`
  mutation updateField($field: UpdateFieldInput!) {
    updateField(field: $field) {
      ok
      field {
        ...FieldDetail
      }
    }
  }
`;
