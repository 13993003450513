import { gql } from "@apollo/client";

const createFieldCrop = gql`
  mutation createFieldCrop($input: CreateFieldCropInput!) {
    createFieldCrop(crop: $input) {
      fieldCrop: crop {
        ...FieldCrop
      }
      ok
    }
  }
`;

export default createFieldCrop;
