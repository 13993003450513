/* eslint-disable react/display-name */
import { useViewportSpy } from "beautiful-react-hooks";
import PropTypes from "prop-types";
import React, { createRef, useRef } from "react";

import Map from "lib/map/map";
import MapUtils from "lib/map/utils";

const withLazyRender = (Component) => (props) => {
  const ref = useRef();
  const isVisible = useViewportSpy(ref, { rootMargin: "10px" });

  return (
    <div ref={ref}>
      <Component isVisible={!!isVisible} {...props} />
    </div>
  );
};

class BasicFieldMap extends React.Component {
  ref = createRef();
  state = {
    currentLayer: null,
    map: null,
  };

  setUpMap = (computedLayer) => {
    const { backdrop, centroid, geometry, pattern, zoom } = this.props;
    const newMap = new Map(this.ref.current, {
      center: centroid && [...centroid.coordinates].reverse(),
      ignoreLocation: true,
      isLayerOnly: computedLayer?.isLayerOnly ?? false,
      maxBounds: geometry && MapUtils.getMaxBounds(geometry),
      noAttribution: true,
      zoom,
      zoomControl: false,
    });

    // Disable map interactions
    newMap.boxZoom?.disable();
    newMap.doubleClickZoom?.disable();
    newMap.dragging?.disable();
    newMap.keyboard?.disable();
    newMap.scrollWheelZoom?.disable();
    newMap?.tap?.disable();
    newMap.touchZoom?.disable();

    if (computedLayer && !newMap.hasLayer(computedLayer)) {
      newMap.addLayer(computedLayer);

      if (pattern) {
        pattern.addTo(newMap);
      }
    }

    newMap.toggleBackdrop(backdrop);
    this.setState({
      currentLayer: computedLayer,
      currentPattern: pattern,
      map: newMap,
    });
  };

  componentDidMount() {
    const { computeLayer, isVisible, layer, type } = this.props;
    const computedLayer = computeLayer ? computeLayer(isVisible, layer, type) : layer;

    if (computedLayer) {
      this.setUpMap(computedLayer);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { shouldLayerChange } = this.props;
    return (
      nextProps.isVisible &&
      (shouldLayerChange ? shouldLayerChange(nextProps, nextState) : nextProps.layer !== nextState.currentLayer)
    );
  }

  componentDidUpdate() {
    const { computeLayer, isVisible, layer, pattern, type } = this.props;
    const { currentLayer, currentPattern, map } = this.state;
    const computedLayer = computeLayer && layer ? computeLayer(isVisible, layer, type) : layer;

    if (map) {
      if (currentLayer) {
        map.removeLayer(currentLayer);
        computedLayer && map.addLayer(computedLayer);

        currentPattern && currentPattern.removeFrom(map);
        pattern && pattern.addTo(map);
      } else if (computedLayer) {
        map.addLayer(computedLayer);
        pattern && pattern.addTo(map);
      }

      this.setState({ currentLayer: computedLayer, currentPattern: pattern });
    } else {
      this.setUpMap(computedLayer);
    }
  }

  componentWillUnmount() {
    this.state.map?.remove();
  }

  render() {
    const { className, onClick, style } = this.props;

    return (
      <div className={className} onClick={onClick} ref={this.ref} style={{ zIndex: 0, height: "20rem", ...style }} />
    );
  }
}

BasicFieldMap.propTypes = {
  backdrop: PropTypes.bool,
  centroid: PropTypes.object,
  computeLayer: PropTypes.func,
  geometry: PropTypes.object.isRequired,
  layer: PropTypes.object,
  onClick: PropTypes.func,
  pattern: PropTypes.object,
  shouldLayerChange: PropTypes.func,
  style: PropTypes.object,
  type: PropTypes.string,
  zoom: PropTypes.number,
};

BasicFieldMap.defaultProps = {
  onClick: () => null,
  style: {},
};

export default withLazyRender(BasicFieldMap);
