import BinView from "inventory/bin";
import InventoryTransaction from "loads/components/InventoryTransaction";
import React, { useEffect, useRef, useState } from "react";
import router from "router";

import inventory_transactions from "collection/inventory_transactions";
import useCommodities from "hooks/useCommodities";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import { BIN_DETAIL_MODAL_OPEN_LOAD_EDIT } from "lib/metrics/events";
import useFieldCrops from "modules/fields/hooks/useFieldCrops";

import { Button } from "components/fl-ui";
import Container from "components/fl-ui/Layout/Container";
import Header from "components/fl-ui/Layout/Header";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";

const BinDetailWrapper = ({ model }) => {
  const { group, inventory_node_id, name } = model.toJSON();
  const [load, setLoad] = useState(null);
  const binRef = useRef();
  const { getCommodityById, loading: commoditiesLoading } = useCommodities();
  const { getCropByInventoryNode, loading: cropsLoading } = useFieldCrops();
  const loading = commoditiesLoading || cropsLoading;

  const handleEdit = (load) => {
    setLoad(load);
  };

  const handleAdd = () => {
    BIN_DETAIL_MODAL_OPEN_LOAD_EDIT.track();
    const newLoad = {
      source_id: inventory_node_id,
      destination_id: inventory_node_id,
      commodity_id: inventory_transactions.getNodeTransactions(inventory_node_id)[0]?.get("commodity_id"),
    };

    handleEdit(newLoad);
  };

  useEffect(() => {
    if (!loading) {
      const attributes = { el: binRef.current, getCommodityById, getCropByInventoryNode, handleAdd, handleEdit, model };
      new BinView(attributes).render();
    }
  }, [loading]);

  if (!useEnterpriseFeature("legacy_storage_and_loads")) {
    router.navigate("fields", { replace: true, trigger: true });
    return null;
  }

  return (
    <Container>
      <Header description={group} title={name}>
        <Button className="btn btn-smoke" onClick={handleAdd}>
          Log Load
        </Button>
      </Header>

      <div ref={binRef} style={{ margin: "0.5rem" }}>
        {loading && <LoadingWrapper />}
      </div>

      {load && <InventoryTransaction load={load} onClose={() => setLoad(null)} />}
    </Container>
  );
};

export default BinDetailWrapper;
