import { graphql } from "@apollo/client/react/hoc";

import { GET_PROFIT_AND_LOSS } from "collection/graphql/profit_and_loss/queries";

export const withProfitAndLossData = graphql(GET_PROFIT_AND_LOSS, {
  options: (props) => ({
    fetchPolicy: "cache-and-network",
    pollInterval: 5 * 60 * 1000, // 5 minutes
    variables: {
      cropYear: props.cropYear,
    },
  }),
});
