import { gql } from "@apollo/client";

export default gql`
  mutation CreateFieldCrop($crops: [CreateFieldCropInput]!) {
    bulkCreateFieldCrops(crops: $crops) {
      ok
      crops {
        ...FieldCrop
      }
    }
  }
`;
