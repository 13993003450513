import PropTypes from "prop-types";
import React from "react";
import router from "router";

import { BlankDetailContent } from "fields/components/styledComponents";

const BlankDetail = ({ href, onClick, ...props }) => {
  const role = href || onClick ? "button" : undefined;
  const clickAction = (event) => {
    if (typeof onClick === "function") {
      onClick(event);
    } else if (href && href !== "#") {
      event.preventDefault();
      router.navigate(href, { trigger: true });
    }
    return undefined;
  };

  return <BlankDetailContent {...props} className="blank-slate blank-slate-dotted" onClick={clickAction} role={role} />;
};

BlankDetail.propTypes = {
  href: PropTypes.string,
  onClick: PropTypes.func,
};

export default BlankDetail;
