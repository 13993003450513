import { css } from "aphrodite";
import SummaryTable from "profit_and_loss/components/SummaryTable";
import styles from "profit_and_loss/styles";
import { getFinanceNotation, getProfitLossLabel } from "profit_and_loss/utils";
import PropTypes from "prop-types";
import React from "react";

import { Card } from "components/fl-ui/Card";
import CommodityYieldUnit from "components/units/CommodityYieldUnit";

const formatMetrics = ({ commodity, costPerAcre, growerYieldPerAcre, profitPerAcre, revenuePerAcre, yieldPerAcre }) => [
  {
    label: "total yield",
    values: [yieldPerAcre && <CommodityYieldUnit commodity={commodity} amount={yieldPerAcre.toFixed(2)} per="acre" />],
  },
  {
    label: "grower yield",
    values: [
      growerYieldPerAcre && (
        <CommodityYieldUnit commodity={commodity} amount={growerYieldPerAcre.toFixed(2)} per="acre" />
      ),
    ],
  },
  { label: "cost", values: [costPerAcre && `${getFinanceNotation(costPerAcre)} / ac`] },
  { label: "revenue", values: [revenuePerAcre && `${getFinanceNotation(revenuePerAcre)} / ac`] },
  { label: getProfitLossLabel(profitPerAcre), values: [profitPerAcre && `${getFinanceNotation(profitPerAcre)} / ac`] },
];

const CropSummaryMetricsMobile = (props) => {
  const metrics = formatMetrics(props);

  return (
    <Card className={css(styles.cropSummaryCard)}>
      <SummaryTable className={css(styles.summaryTable)} data={metrics} />
    </Card>
  );
};

CropSummaryMetricsMobile.propTypes = {
  commodity: PropTypes.object.isRequired,
  costPerAcre: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  growerYieldPerAcre: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  profitPerAcre: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  revenuePerAcre: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  yieldPerAcre: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default CropSummaryMetricsMobile;
