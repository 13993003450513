import { gql } from "@apollo/client";

import { MachineDataProductMatch } from "collection/graphql/integrations/fragments";

export default gql`
  mutation setMachineDataProductMatch($matchId: Int!, $productId: Int, $rewriteHistory: Boolean!) {
    setMachineDataProductMatch(matchId: $matchId, productId: $productId, rewriteHistory: $rewriteHistory) {
      ok
      match {
        ...MachineDataProductMatch
      }
    }
  }
  ${MachineDataProductMatch}
`;
