import _ from "lodash";

import { AAR_SOURCE, MANUAL_SOURCE } from "lib/constants";

/**
 * Filters an array of activities by source.
 *
 * @param {Activity[]} activities
 * @param {Object} filters
 * @param {String[]} filters.sources
 * @param {Function} markAsApplied
 * @return {Activity[]}
 */
const sourceFilter = (activities, { sources }, markAsApplied) => {
  if (sources.length > 0) {
    const sourceMap = _.keyBy(sources, _.toUpper);
    activities = activities.filter(({ isAAR, isMachineData, machineDataProviders }) => {
      if (AAR_SOURCE in sourceMap && isAAR) {
        return true;
      } else if (MANUAL_SOURCE in sourceMap && !isAAR && !isMachineData) {
        return true;
      } else if (machineDataProviders?.some((provider) => provider in sourceMap)) {
        return true;
      }

      return false;
    });

    markAsApplied();
  }

  return activities;
};

export default sourceFilter;
