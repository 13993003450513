import createReactClass from "create-react-class";
import { Arrow, FlatButton, TopBar, ViewTypeIcon } from "map/layers/helpers";
import React from "react";

import History from "lib/history";
import { EXPLORER_SELECTION_FIELD, EXPLORER_VIEW_FILTER_CHANGE } from "lib/metrics/events";

import Chooser from "components/ui/chooser";
import { withGroupedFieldsOptions } from "fields/hooks/useGroupedFields";

const { Option, Group } = Chooser;

const chooserStyle = {
  color: "white",
  fontWeight: "500",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  width: "100%",
};

const backStyle = {
  display: "flex",
  fontWeight: "500",
  justifyContent: "flex-start",
  textAlign: "left",
};

const viewStyle = {
  fontWeight: "500",
  textAlign: "right",
  display: "flex",
  justifyContent: "flex-end",
};

const Nav = createReactClass({
  displayName: "LayerNav",

  getDefaultProps() {
    return {
      onSplit() {},
      splitMode: false,
      value: null,
    };
  },

  handleBack() {
    return History.back();
  },

  handleSplit() {
    this.props.onSplit();

    return EXPLORER_VIEW_FILTER_CHANGE.track({
      previous_mode: this.props.splitMode ? "double" : "single",
      current_mode: this.props.splitMode ? "single" : "double",
    });
  },

  handleFieldChange({ value }) {
    if (value === null) {
      return;
    }

    EXPLORER_SELECTION_FIELD.track({
      entity_id: value,
    });

    return History.navigate(`map/${value}/layers`, {
      trigger: true,
      replace: true,
    });
  },

  render() {
    return (
      <TopBar color="black">
        <div className="col xs-4 md-2">
          <FlatButton id="fde-back-button" color="white" onClick={this.handleBack} style={backStyle}>
            <Arrow color="white" direction="left" style={{ width: "1.3em" }} />
            <span style={{ padding: ".1em .5em 0" }}>{" Back"}</span>
          </FlatButton>
        </div>
        <div className="col xs-8 md-2 md-offset-3">
          <div className="select-wrapper" style={{ display: "inherit", margin: 0, color: "white" }}>
            <Chooser
              name="field_id"
              className="btn button-m btn-transparent"
              label="field"
              onChange={this.handleFieldChange}
              placeholder="Choose a field"
              style={chooserStyle}
              value={this.props.value}
            >
              {this.props.chooserOptions.map(({ name, options }) => (
                <Group key={name} label={name}>
                  {options.map(({ value, name }) => (
                    <Option key={value} value={value}>
                      {name}
                    </Option>
                  ))}
                </Group>
              ))}
            </Chooser>
          </div>
        </div>
        {/* <div className="col xs-hide sm-hide md-2 md-offset-3">
            <FlatButton
              id="fde-view-button"
              color="white"
              onClick={this.handleSplit}
              style={viewStyle}
            >
              <ViewTypeIcon split={!this.props.splitMode} />
              <span style={{ paddingLeft: '.5em' }}>
                {this.props.splitMode ? 'Single' : 'Split'}
                {` View`}
              </span>
            </FlatButton>
          </div> */}
      </TopBar>
    );
  },
});

export default withGroupedFieldsOptions(Nav);
