import { useQuery } from "@apollo/client";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

import getContractBuyerNames from "collection/graphql/contracts/queries/getContractBuyerNames";

import { TagSelectGroup } from "components/fl-ui/Form";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";

const ContractBuyerFilter = ({ onChange, value }) => {
  const { data, loading } = useQuery(getContractBuyerNames);
  const options = useMemo(() => {
    return _.sortBy(data?.buyerNames, "name").map(({ name }) => ({
      label: name,
      value: name,
    }));
  }, [data?.buyerNames]);

  return (
    <LoadingWrapper isLoading={loading}>
      <TagSelectGroup
        id="buyersFilter"
        isMulti
        label="Buyer"
        onChange={(buyers) => onChange(buyers)}
        options={options}
        showBulkSelect
        value={value}
      />
    </LoadingWrapper>
  );
};

ContractBuyerFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired,
};

export default ContractBuyerFilter;
