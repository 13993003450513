import { css, StyleSheet } from "aphrodite/no-important";
import React, { useState } from "react";

import { ONBOARDING_FIRST_FIELD_ADD } from "lib/metrics/events";

import { Button } from "components/fl-ui";
import BlankSlate from "components/fl-ui/BlankSlate";
import { Icon } from "components/fl-ui/Icons";
import { GreyColors } from "components/fl-ui/colors";
import { Typography } from "components/fl-ui/constants";
import ChooseMethodModal from "fields/components/add/ChooseMethodModal";

const { sizes, weights } = Typography;

const styles = StyleSheet.create({
  blankSlateButton: {
    paddingLeft: "3em !important",
    paddingRight: "3em !important",
  },
  blankSlateHeaderText: {
    fontSize: sizes.xl,
    fontWeight: weights.medium,
    margin: "1em 0 0",
  },
  blankSlateBodyText: {
    lineHeight: "1.5em",
    margin: "1em 0",
    fontSize: sizes.lg,
  },
  blankSlateimage: {
    color: GreyColors.smoke90,
    margin: "auto",
    maxWidth: "7em",
  },
});

const FieldsBlankSlate = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <BlankSlate>
      <div>
        <Icon className={css(styles.blankSlateimage)} icon="fields" />

        <p className={css(styles.blankSlateHeaderText)}>Add a field to get started</p>

        <p className={css(styles.blankSlateBodyText)}>
          Add your first field to get access to daily rainfall, soil maps, crop history and more.
        </p>
      </div>

      <Button
        className={css(styles.blankSlateButton)}
        color="primary"
        id="add-first-field"
        onClick={() => {
          ONBOARDING_FIRST_FIELD_ADD.track({});
          setShowModal(true);
        }}
        size="lg"
      >
        Add field
      </Button>

      {showModal && <ChooseMethodModal onClose={() => setShowModal(false)} />}
    </BlankSlate>
  );
};

export default FieldsBlankSlate;
