import TabularReport from "./TabularReport";
import _ from "lodash";
import React from "react";

const SummaryTable = ({ crop }) => {
  const columns = [
    { id: "crop_acres", type: "number", title: "Crop Acres" },
    { id: "crop", type: "string", title: "Crop" },
    { id: "yield", type: "string", title: "Yield" },
    { id: "production_cost", type: "string", title: "Production Cost" },
  ];
  const rows = [
    {
      crop_acres: crop.acreage,
      crop: crop.commodity.name,
      yield: crop.yield_per_acre,
      production_cost: crop.total_cost_total,
    },
  ];

  return <TabularReport columns={columns} rows={rows} />;
};

const FieldSeasonReport = ({ data: { fields } }) => {
  return (
    <div>
      {fields.map(({ crops, field }) => {
        const title = _.compact([field.group?.name, field.name]).join(" - ");

        return (
          <div key={field.id}>
            <h2>{title}</h2>

            {crops.map(({ activities, crop, ...rest }) => {
              return (
                <>
                  <SummaryTable {...rest} crop={crop} />

                  {activities.map(({ activities, inputs = {}, type }, i) => {
                    return (
                      <section key={`cropActivity-${crop.id}${i}`} style={{ margin: "2em auto" }}>
                        <h3>{type.name}</h3>
                        <TabularReport {...activities} />

                        {inputs.rows.length > 0 && (
                          <>
                            <h4>Inputs</h4>
                            <TabularReport {...inputs} />
                          </>
                        )}
                      </section>
                    );
                  })}
                </>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default FieldSeasonReport;
