import chooserStyles from "activity/components/choosers/FieldCropChooser/chooserStyles";
import useActivitiesProvider from "activity/hooks/useActivitiesProvider";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import ReactSelect from "react-select";

const ActivityEquipmentChooser = (props) => {
  const { onChange, value } = props;
  const toIdentifier = ({ name, __typename }) => [__typename, name].join("");
  const toValue = (equipment) => _.pick(equipment, ["id", "__typename"]);

  const { allActivities } = useActivitiesProvider();
  const allUsedEquipment = useMemo(() => {
    const allEquipment = _.flatMapDeep(allActivities, (activity) => activity.implements.concat(activity.tractors));
    const uniqueEquipment = _.uniqBy(allEquipment, toIdentifier);
    const sortedEquipment = _.sortBy(uniqueEquipment, ({ name }) => name.toLowerCase());

    return sortedEquipment;
  }, [allActivities]);

  const componentValue = value.map((singleValue) => _.find(allUsedEquipment, toValue(singleValue))).filter((x) => x);

  return (
    <ReactSelect
      getOptionLabel={_.property("name")}
      getOptionValue={toIdentifier}
      isMulti
      onChange={(selectedOptions) => {
        const newValue = (selectedOptions || []).map(toValue);
        onChange(newValue);
      }}
      options={allUsedEquipment}
      styles={chooserStyles}
      value={componentValue}
    />
  );
};

ActivityEquipmentChooser.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      __typename: PropTypes.oneOf(["Implement", "Tractor"]),
    })
  ).isRequired,
};

export default ActivityEquipmentChooser;
