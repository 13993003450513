import { gql } from "@apollo/client";

export default gql`
  query getImplementById($id: Int!) {
    implement: getImplementById(id: $id)
      @requiresFeature(feature: "equipment")
      @requiresPermission(feature: "equipment", actions: ["read"]) {
      ...ImplementDetail
    }
  }
`;
