import FarmDashboard from "profit_and_loss/components/FarmDashboard";
import ProfitAndLossPermissionWrapper from "profit_and_loss/components/ProfitAndLossPermissionWrapper";
import ResponsiveBlankSlate from "profit_and_loss/components/ResponsiveBlankSlate";
import { withProfitAndLossData } from "profit_and_loss/hoc/withProfitAndLossData";
import PropTypes from "prop-types";
import React from "react";

import App from "layout/app";

import LoadingWrapper from "components/fl-ui/LoadingWrapper";

const Dashboard = ({ data }) => {
  const { error, loading, profitAndLossData } = data;

  if (!loading && error) {
    App.notify("An unexpected error occurred");
  }

  return (
    <LoadingWrapper isLoading={loading && !profitAndLossData}>
      {profitAndLossData && <FarmDashboard data={profitAndLossData} />}
      {!profitAndLossData && !error && (
        <ResponsiveBlankSlate
          copy="Once you create your crops, you will see a detailed summary of your expenses, revenue, and profit / loss."
          header="Get started by creating crops, logging activities, and logging contracts"
        />
      )}
    </LoadingWrapper>
  );
};

const DashboardContainer = (props) => (
  <ProfitAndLossPermissionWrapper {...props}>{withProfitAndLossData(Dashboard)}</ProfitAndLossPermissionWrapper>
);

DashboardContainer.propTypes = {
  data: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    profitAndLossData: PropTypes.object,
  }),
};

export default DashboardContainer;
