import { sortBy } from "lodash";
import { useEffect, useState } from "react";

import useFieldLayers from "lib/map/layers/useFieldLayers";

const useYieldLayers = ({ fieldId, skip, sanitize }) => {
  const { layers, loading } = useFieldLayers({ fieldId, skip, type: "yield.512" });
  const [data, setData] = useState({ loading, yieldLayers: [] });

  useEffect(() => {
    if (!loading) {
      const sortedLayers = sortBy(layers, ({ crop }) => crop?.cropYear);
      const yieldLayers = sanitize ? sanitize(sortedLayers) : sortedLayers;
      setData({ loading, yieldLayers });
    }
  }, [fieldId, layers, loading, skip, sanitize]);

  return data;
};

export default useYieldLayers;
