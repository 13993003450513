import Big from "big.js";
import { useBillingProducts } from "billing/providers/BillingProductsProvider";
import _ from "lodash";
import { useMemo } from "react";
import { number, object, ref, string } from "yup";

const schema = object().shape({
  effectiveBillingInterval: string().oneOf(["month", "year"]).required(),
  discountTotal: number().required().min(0).max(ref("subtotal")),
  subtotal: number().required().moreThan(0),
  total: number().required().min(0),
});

/**
 * @typedef {object} SubscriptionPricingDescriptor
 * @property {number} discountTotal the total amount to be discounted from the subtotal for a given coupon
 * @property {"month"|"year"} effectiveBillingInterval the billing interval used to perform calculations
 * @property {object} plan
 * @property {number} subtotal the plan price for the specified billing interval before discounts are applied
 * @property {number} total the total plan price with discounts applied
 */

/**
 * Derives numeric values from a given plan for a given billing interval and coupon. If
 * the specified billing interval is not available on the given plan, an alternate billing
 * interval is selected and the calculations are made against it.
 *
 * @param {"essentials"|"lite"|"premium"|"rainfall"|null} subscriptionProductId
 * @param {"month"|"year"} billingInterval
 * @param {{ amount_off: number, percent_off: number}} [coupon=null]
 * @return {SubscriptionPricingDescriptor}
 */
const useSubscriptionPricing = (subscriptionProductId, billingInterval, coupon = null) => {
  const { allPlans } = useBillingProducts();
  const product = useMemo(() => {
    return _.find(allPlans, { id: subscriptionProductId });
  }, [subscriptionProductId]);

  const plan =
    _.find(product?.plans, { interval: billingInterval }) ||
    _.reject(product?.plans, { interval: billingInterval }).shift();

  const discountTotal = useMemo(() => {
    return coupon?.percent_off
      ? Big(plan?.price || 0)
          .times(coupon.percent_off)
          .times(0.01)
          .toNumber()
      : Big(coupon?.amount_off ?? 0).toNumber();
  }, [coupon, plan]);

  if (!product?.isPurchasable || !plan) {
    return {
      discountTotal: 0,
      effectiveBillingInterval: billingInterval,
      plan: null,
      subtotal: 0,
      total: 0,
    };
  }

  const pricing = {
    discountTotal,
    effectiveBillingInterval: plan.interval,
    plan,
    subtotal: plan.price,
    total: Math.max(0, Big(plan.price).minus(discountTotal).toNumber()),
  };

  try {
    schema.validateSync(pricing);
  } catch (error) {
    console.info(error);
    console.info(pricing);
  }
  return pricing;
};

export default useSubscriptionPricing;
