import { css } from "aphrodite";
import PropTypes from "prop-types";
import React from "react";

import { Button } from "components/fl-ui";
import styles from "components/fl-ui/Form/Input/inputStyles";
import Popover from "components/fl-ui/Popover/Popover";

const defaultRates = [
  { name: "per acre", value: "PER_ACRE" },
  { name: "total", value: "TOTAL" },
];

const RateSelector = ({ onClick, rates, selected }) => (
  <Popover
    content={rates.map(({ name, value }) => (
      <Button
        className={css(styles.suffixDropdownList, selected === value && styles.blueText)}
        key={name}
        link
        onClick={() => onClick(value)}
      >
        {name}
      </Button>
    ))}
  >
    <Button className={css(styles.suffixDropdownButton)} icon="buttonChevronDown" iconRight>
      {(rates.find(({ value }) => value === selected) || {}).name}
    </Button>
  </Popover>
);

RateSelector.propTypes = {
  onClick: PropTypes.func.isRequired,
  rates: PropTypes.arrayOf(PropTypes.object),
  selected: PropTypes.string,
};

RateSelector.defaultProps = {
  rates: defaultRates,
};

export default RateSelector;
