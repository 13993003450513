import { gql } from "@apollo/client";

import { FieldGroup } from "collection/graphql/fieldGroups/fragments";

export default gql`
  mutation updateFieldGroup($group: UpdateFieldGroupInput!) {
    updateFieldGroup(group: $group) {
      ok
      group {
        ...FieldGroup
      }
    }
  }
  ${FieldGroup}
`;
