import FieldMapLayers from "map/layer/fields";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import Layer from "components/map/layer";
import withYearFieldCrops from "fields/hoc/withYearFieldCrops";

const FieldsLayer = ({ crops, fields }) => {
  const [fieldLayers, setFieldLayers] = useState(null);

  useEffect(() => {
    if (fieldLayers) {
      fieldLayers.updateCrops({ crops, fields });
    } else {
      const layers = new FieldMapLayers({ crops, fields });
      setFieldLayers(layers);
    }
  }, [crops, fields]);

  return fieldLayers && <Layer layer={fieldLayers} />;
};

FieldsLayer.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withYearFieldCrops(FieldsLayer);
