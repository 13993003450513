import _ from "lodash";
import * as yup from "yup";

const optionalNumber = () =>
  yup
    .mixed()
    .test((value) => value === "" || yup.number().isValidSync(value))
    .transform((value) => (value === "" ? value : +value));

const schema = yup.object().shape({
  date: yup.date().label("Date").required(),
  engineHours: yup.number().label("Engine hours").nullable().moreThan(0),
  equipment: yup.object().label("Equipment").required(),
  laborHours: yup.number().label("Labor hours").nullable().moreThan(0),
  notes: yup
    .string()
    .label("Notes")
    .transform((value) => _.trim(value)),
  people: yup
    .array()
    .label("Person")
    .min(1, "At least 1 person is required")
    .required()
    .default(() => []),
});

export default schema;
