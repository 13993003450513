import { gql } from "@apollo/client";

const getFieldCropChooserData = gql`
  query getFieldCropChooserData($cropYear: Int!) {
    fieldCrops: getFieldCrops(years: [$cropYear]) {
      ...ChooserFieldCrop
    }

    fields: getFields {
      ...BasicField
      crops(cropYear: $cropYear) {
        commodity {
          ...ChooserCommodity
        }
        id
      }
    }
  }

  fragment ChooserCommodity on Commodity {
    color
    id
    name
  }

  fragment ChooserFieldCrop on Crop {
    id
    acreage
    commodity {
      ...ChooserCommodity
    }
    cropYear
    field {
      ...BasicField
    }
    marketedCrop {
      id
      name
    }
  }
`;

export default getFieldCropChooserData;
