import _ from "lodash";
import moment from "moment";
import validator from "validator";

/**
 * @deprecated
 * Use yup validation instead.
 */

const excludedMethods = [
  "blacklist",
  "escape",
  "unescape",
  "normalizeEmail",
  "trim",
  "rtrim",
  "ltrim",
  "stripLow",
  "toBoolean",
  "toInt",
  "toDate",
  "toFLoat",
  "whitelist",
];

const utils = _.functions(validator).reduce((result, fn) => {
  if (excludedMethods.includes(fn)) {
    result[fn] = (...args) => validator[fn](...args);
  } else {
    result[fn] = (input, ...args) => validator[fn](input + "", ...args);
  }

  return result;
}, {});

/**
 * Tests to see if a value is an acceptable date. If a Moment or Date instance
 * is passed, the object is tested to ensure it is a valid date object. If a string
 * is passed, it is expected to be in one of the following formats:
 *  YYYY-MM-DD
 *  MM/DD/YYYY
 *  MM/DD/YY
 *  M/D/YYYY
 *  M/D/YY
 *
 * If a two digit year is passed, a post-2000 year is assumed.
 *
 * @param {Moment|Date|String} value
 * @return {Boolean} returns true if the supplied value is a valid date
 */
export const isValidDate = (value) => {
  if (moment.isMoment(value) || value instanceof Date) {
    return moment(value).isValid();
  } else if (!_.isString(value)) {
    return false;
  }

  let month, day, year;
  if (/^\d{1,2}\/\d{1,2}\/\d{2,4}$/.test(value)) {
    [month, day, year] = value.split("/");
  } else if (/^\d{4}-\d{1,2}-\d{1,2}$/.test(value)) {
    [year, month, day] = value.split("-");
  }

  year = _.size(year) === 2 ? "20" + year : year;
  if (utils.isInt(month, { allow_leading_zeroes: true, min: 1, max: 12 })) {
    month = _.padStart(month, 2, "0");
  }
  if (utils.isInt(day, { allow_leading_zeroes: true, min: 1, max: 31 })) {
    day = _.padStart(day, 2, "0");
  }

  return moment([year, month, day].join("-")).isValid();
};
utils.isValidDate = isValidDate;

export default utils;
