import Backbone from "backbone";
import render from "inventory/templates";
import $ from "jquery";
import BinView from "resources/bin";
import Sidebar from "ui/sidebar";
import renderUI from "ui/templates";
import _ from "underscore";

import bins from "collection/bins";
import inventory_transactions from "collection/inventory_transactions";
import App from "layout/app";
import naturalSort from "lib/naturalSort";

let sidebar;

export default Backbone.View.extend({
  bins,
  transactions: inventory_transactions,
  className: "analytics-page container-fluid",

  events: {
    "click h4": "toggleGroup",
    "click .js-edit": "toggleEdit",
    "click .js-save": "saveGroupName",
    "click .js-rename": "toggleRename",
    "click .js-cancel": "cancelGroupEdit",
    "click .js-delete": "deleteGroup",
    "keyup .js-group-input": "saveGroupName",
    "click .js-add-storage": "addStorage",
    click: "closeDropDown",
    remove: "remove",
  },

  initialize({ getCommodityById }) {
    sidebar = new Sidebar();
    this.getCommodityById = getCommodityById;
    this.listenTo(this.bins, "add reset sync", this.render);
    this.listenTo(inventory_transactions, "add reset sync", this.render);
  },

  render() {
    const data = {
      groups: this.bins.length ? this.getBins() : undefined,
      show_groups: this.bins.length ? this.getBins().length > 1 : undefined,
      details: renderUI("big_stats", this.getDetails()),
      info: this.getInfo(),
    };

    //renders it to inventory.mustache using the data, el is a backboneview
    this.$el.html(render("inventory", data));
    //renders it to js-commodity-filter divelement in
    this.renderSidebar();
    return this;
  },

  getBushelSize(commodityId) {
    return this.getCommodityById(commodityId)?.bushelSize;
  },

  getNodeCommodity(nodeId) {
    return this.getCommodityById(inventory_transactions.getNodeCommodityId(nodeId));
  },

  renderSidebar() {
    const comList = this.bins.reduce((list, bin) => {
      const commodity = this.getNodeCommodity(bin.get("inventory_node_id"));
      if (commodity) {
        list.push(commodity);
        const uniquelist = _.unique(list, (c) => c.id);
        list = _.sortBy(uniquelist, (t) => t.name);
      }
      return list;
    }, []);
    sidebar.setData("commodities", comList).setElement(this.$(".js-commodity-filter")).render();

    return this.listenTo(sidebar, "changeCommodity", function () {
      return this.render();
    });
  },

  getBins() {
    const groups = this.bins.groupBy((b) => b.get("group") || "");
    const map = (a) =>
      a.reduce((group, b) => {
        if (sidebar.getCommodity() !== "all") {
          if (inventory_transactions.getNodeCommodityId(b.get("inventory_node_id")) !== sidebar.getCommodity()) {
            return group;
          }
        }

        const bin = b.toJSON();
        const balance = inventory_transactions.getNodeBalance(
          bin.inventory_node_id,
          "bu",
          false,
          this?.getBushelSize.bind(this)
        );
        const amount = bin.capacity ? Math.min(bin.capacity, Math.max(0, balance)) : Math.max(0, balance);
        const percent = b.getUIPercentage(balance);

        group.push({
          id: bin.id,
          name: bin.name,
          amount: _.numberFormat(amount || "Empty", 0),
          unit: amount ? "bu" : undefined,
          capacity: bin.capacity,
          commodity: this.getNodeCommodity(bin.inventory_node_id)?.name,
          icon: renderUI("storage", {
            percent,
            color: this.getNodeCommodity(bin.inventory_node_id)?.color,
          }),
        });

        return group;
      }, []);

    // work around to have the ungrouped at the bottom
    const grouped = [];
    const ungrouped = [];

    for (const group_name in groups) {
      const bins = groups[group_name];
      const target = group_name !== "" ? grouped : ungrouped;
      target.push({
        name: group_name === "" ? null : group_name,
        bins: map(bins),
        isbin: map(bins).length !== 0,
        bushels() {
          const capacity = _.sum(this.bins, "capacity");
          if (capacity > 0) {
            return _.numberFormat(capacity);
          } else {
            return capacity;
          }
        },
        oneBin: bins.length === 1,
      });
    }

    return grouped.sort((a, b) => naturalSort(a.name, b.name)).concat(ungrouped);
  },

  getDetails() {
    const filterlist = inventory_transactions.getTotalBalanceByCommodity(
      this.bins.pluck("inventory_node_id"),
      "bu",
      this?.getBushelSize.bind(this)
    );

    return {
      stats: filterlist
        .filter(({ id }) => sidebar.getCommodity() === "all" || +id === sidebar.getCommodity())
        .map(({ balance, id }) => ({
          value: _.numberFormat(balance, 0),
          unit: "bu",
          description: this.getCommodityById(+id)?.name,
        })),
    };
  },

  getInfo() {
    const f = (n) => _.numberFormat(n, 0);
    const capacity = this.bins.getTotalCapacity();
    const nodeids = this.bins.pluck("inventory_node_id");
    const nodecap = this.bins.pluck("capacity");
    let emptybincap = 0;
    nodeids.forEach(function (id) {
      if (!inventory_transactions.getNodeCommodityId(id)) {
        return (emptybincap += nodecap[nodeids.indexOf(id)]);
      }
    });

    if (sidebar.getCommodity() === "all") {
      const balance = Math.max(
        0,
        inventory_transactions.getTotalBalance(
          this.bins.pluck("inventory_node_id"),
          "bu",
          this?.getBushelSize.bind(this)
        )
      );
      return {
        available: f(Math.max(0, capacity - balance)),
        capacity: f(capacity),
      };
    }

    const availablecapacity = _.sum(this.bins?.models, function (bin) {
      const commodityId = inventory_transactions.getNodeCommodityId(bin.get("inventory_node_id"));

      if (commodityId === sidebar.getCommodity()) {
        return (
          bin.get("capacity") -
          inventory_transactions.getNodeBalance(
            bin.get("inventory_node_id"),
            "bu",
            false,
            this?.getBushelSize(commodityId)
          )
        );
      }
    });

    return {
      available: f(Math.max(0, emptybincap + availablecapacity)),
      capacity: f(capacity),
    };
  },

  // shows the dropdown menu
  toggleEdit(e) {
    e.stopPropagation();
    return this.$(e.currentTarget).prev().toggle();
  },

  // if user chooses to save the group
  saveGroupName(e) {
    if (e.type === "keyup" && e.keyCode !== 13) {
      return false;
    }
    const $container = $(e.currentTarget).parent();
    const oldGroup = $(e.currentTarget).attr("data-name") || "";
    const newGroup = $("input", $container).val();
    const groups = this.bins.groupBy((b) => b.get("group") || "");
    //  Save group name to bins
    groups[oldGroup].map((bin) => bin.save({ group: newGroup }));

    $(".js-group", $container).text($("input", $container).val());
    $(".js-edit-hide", $container).toggle();
    return this.render();
  },

  // adds new storage
  addStorage(e) {
    const model = new this.bins.model({
      group: this.$(e.currentTarget).siblings("input").val(),
    });
    return new BinView({ collection: this.bins, model: model }).render().setUI();
  },

  // closes the dropdown
  closeDropDown() {
    return $(".js-dropdown").hide();
  },

  // allows the user the edit
  toggleRename(e) {
    e.stopPropagation();
    const $container = $(e.currentTarget).closest("h4");
    $(".js-edit-hide", $container).toggle();
    $("input", $container).focus().select();
    return $(".js-dropdown", $container).hide();
  },

  // cancels editing the group, everything should go back to normal
  cancelGroupEdit(e) {
    e.stopPropagation();
    const $container = $(e.currentTarget).closest("h4");
    return $(".js-edit-hide", $container).toggle();
  },

  // if user chooses to delete the groups
  deleteGroup(e) {
    e.stopPropagation();
    const $container = $(e.currentTarget).parent();

    return App.confirm({
      message: "This will remove this field group",
      confirm: "DELETE",
    }).then(() => {
      const oldGroup = $(e.currentTarget).attr("data-name");
      if (!oldGroup) {
        return;
      }
      const newGroup = "";
      const groups = this.bins.groupBy((b) => b.get("group") || "");
      //  Save group name to bins
      groups[oldGroup].map((bin) => bin.save({ group: newGroup }));

      $(".js-group", $container).text("Ungrouped");
      $(".js-edit-hide", $container).toggle();
      return this.render();
    });
  },

  // Toggles group views on clicking the header
  toggleGroup(e) {
    return this.$(e.currentTarget).toggleClass("collapsed").next(".js-field-group").slideToggle("fast");
  },
});
