import { css } from "aphrodite";
import CropSummaryMetricsDesktop from "profit_and_loss/components/CropSummaryMetricsDesktop";
import styles from "profit_and_loss/styles";
import PropTypes from "prop-types";
import React from "react";

import { Button } from "components/fl-ui";
import CropBadge from "components/fl-ui/CropBadge";
import Icon from "components/fl-ui/Icons/Icon";
import { Cluster } from "components/fl-ui/Layout";

const CropSummaryHeader = ({ data, expand, setExpand, viewType }) => {
  const { acreage, commodity, cropYear, name, ...metrics } = data;

  return (
    <Cluster between>
      <Cluster between>
        <CropBadge color={commodity.color} stripe />

        <div className={css(styles.boldVerticalStack)}>
          <span className={css(styles.cropSummaryHeaderSubtext)}>{`${cropYear} \u00b7 ${acreage.toFixed(2)} ac`}</span>
          <span className={css(styles.cropSummaryHeaderName)}>{name}</span>
        </div>
      </Cluster>

      <Cluster between>
        {viewType === "desktop" && <CropSummaryMetricsDesktop {...metrics} commodity={commodity} />}

        <Button className={css(styles.toggleButton)} color="white" onClick={() => setExpand(!expand)}>
          <Icon className={css(styles.toggleButtonIcon)} icon={expand ? "collapse" : "expand"} />
        </Button>
      </Cluster>
    </Cluster>
  );
};

CropSummaryHeader.propTypes = {
  data: PropTypes.shape({
    commodity: PropTypes.shape({
      color: PropTypes.string,
    }).isRequired,
    cropYear: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    name: PropTypes.string.isRequired,
  }),
  expand: PropTypes.bool.isRequired,
  setExpand: PropTypes.func.isRequired,
  viewType: PropTypes.string.isRequired,
};

export default CropSummaryHeader;
