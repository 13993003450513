import _ from "lodash";

import cache from "collection/graphql/cache";
import convertRecipeToLegacy from "collection/graphql/recipes/hooks/lib/convertRecipeToLegacy";
import saveRecipeIngredient from "collection/graphql/recipes/hooks/lib/saveRecipeIngredient";
import { GET_PRODUCT_RECIPES } from "collection/graphql/recipes/queries";

/**
 * Persists a new or existing recipe. Returns the id of the recipe upon successful completion.
 * @param {Object} recipe
 * @return {Promise<Number>}
 */
async function saveRecipe(recipe) {
  const legacyRecipe = convertRecipeToLegacy(recipe);

  // delete abandoned ingredients
  if (recipe.id) {
    const { allIngredients } = cache.readQuery({ query: GET_PRODUCT_RECIPES }) ?? {};
    const previousIds = _.map(_.filter(allIngredients, { productRecipeId: recipe.id }), "id");
    const currentIds = _.map(recipe.ingredients, "id").filter((x) => x);
    const ingredientIdsToDelete = _.difference(previousIds, currentIds);
    await Promise.allSettled(
      ingredientIdsToDelete.map((ingredientId) => {
        return fetch(`/v2.0/api/product_recipe_components/${ingredientId}`, {
          headers: { "Content-Type": "application/json" },
          method: "DELETE",
        });
      })
    );
  }

  // create/update the recipe
  let method = "POST";
  let url = "/v2.0/api/product_recipes";
  if (legacyRecipe.id > 0) {
    method = "PUT";
    url += `/${legacyRecipe.id}`;
  }
  const response = await fetch(url, {
    body: JSON.stringify(legacyRecipe),
    headers: { "Content-Type": "application/json" },
    method,
  });

  if (response.ok) {
    // create/update the ingredients
    const savedRecipe = await response.json();
    const ingredientRequestResults = await Promise.allSettled(
      legacyRecipe.ingredients.map((ingredient) => {
        return saveRecipeIngredient({
          ...ingredient,
          enterpriseId: recipe.enterpriseId,
          productRecipeId: savedRecipe.id,
        });
      })
    );

    if (!_.every(ingredientRequestResults, ({ status }) => status === "fulfilled")) {
      throw new Error("An error occurred persisting an ingredient");
    }

    // return the normalized saved recipe
    const camelize = (source) =>
      _.transform(source, (result, value, key) => Object.assign(result, { [_.camelCase(key)]: value }), {});
    const ingredients = await Promise.all(_.invokeMap(ingredientRequestResults, "value.json"));

    return {
      ...camelize(savedRecipe),
      ingredients: ingredients.map(camelize),
    };
  } else {
    throw new Error("Unable to save product recipe");
  }
}

export default saveRecipe;
