import moment from "moment";
import { useEffect, useState } from "react";

import { useAuth } from "collection/graphql/auth";
import useMaintenanceRecordMutations from "collection/graphql/equipment/hooks/useMaintenanceRecordMutations";

const recordToFormFields = (record, currentUser) => {
  if (record.id) {
    return {
      ...record,
      people: [record.people[0].id],
    };
  } else {
    return {
      date: moment().format("YYYY-MM-DD"),
      engineHours: "",
      equipment: record.equipment,
      laborHours: "",
      notes: "",
      parts: [],
      people: [currentUser.id],
    };
  }
};

const useRecordWrite = (record) => {
  const [fields, setFields] = useState(null);
  const { currentUser } = useAuth();
  const { deleteMaintenanceRecord, saveMaintenanceRecord } = useMaintenanceRecordMutations();

  useEffect(() => {
    if (record === null && fields) {
      setFields(null);
    } else if (record !== null && !fields) {
      setFields(recordToFormFields(record, currentUser || {}));
    }
  }, [record === null]);

  return {
    fields,
    onChange: (formFields) => setFields(formFields),
    onDelete: async () => {
      await deleteMaintenanceRecord(record.id);
    },

    onSave: async (formFields) => {
      const asJSON = {
        date: moment(formFields.date).format("YYYY-MM-DD"),
        engine_hours: formFields.engineHours,
        equipment_id: formFields.equipment.id,
        equipment_type: formFields.equipment.__typename === "Tractor" ? "tractor" : "implement",
        labor_hours: formFields.laborHours,
        notes: formFields.notes,
        people: [formFields.people[0]],
      };
      if (formFields.id) {
        asJSON.id = formFields.id;
      }

      await saveMaintenanceRecord(asJSON);
    },

    showModal: fields !== null,
  };
};

export default useRecordWrite;
