import Big from "big.js";
import * as yup from "yup";

import { nanTransform } from "lib/validation/yupPolyfill";

const customQuantityValidationTest = {
  test: (value, { createError, from, parent }) => {
    const formattedQuantityDelivered = Big(parent.quantityDelivered ?? 0).toNumber();
    const formattedQuantityCanceled = Big(parent.quantityCanceled ?? 0).toNumber();
    const formattedQuantity = Big(parent.quantity ?? 0).toNumber();
    const sum = Big(formattedQuantityDelivered).add(formattedQuantityCanceled);
    const isValid = sum <= (parent.quantity ?? 0);

    if (isValid) {
      return true;
    }

    const { quantity, quantityCanceled, quantityDelivered } = from[0].schema.describe().fields;

    return createError({
      message:
        "${canceledLabel} (${formattedQuantityCanceled}) and ${deliveredLabel} (${formattedQuantityDelivered}) must be test less than or equal to ${quantityLabel} (${formattedQuantity})",
      params: {
        canceledLabel: quantityCanceled.label,
        deliveredLabel: quantityDelivered.label,
        formattedQuantity: formattedQuantity,
        formattedQuantityCanceled: formattedQuantityCanceled,
        formattedQuantityDelivered: formattedQuantityDelivered,
        quantityLabel: quantity.label,
      },
    });
  },
};

const customContractTypeValidationTest = {
  test: (value, testContext) => {
    const isCommodityExchangeTraded = testContext.options.context?.commodities.filter(
      (commodity) => commodity.id === testContext.parent.commodityId
    )[0]?.isExchangeTraded;
    const nonExchangeTradedContractTypes = ["CASH", "SPOT", "OTHER"];
    const exchangeTradedContractTypes = ["CASH", "BASIS", "HEDGE_TO_ARRIVE", "OTHER", "SPOT"];

    const validContractTypes = isCommodityExchangeTraded ? exchangeTradedContractTypes : nonExchangeTradedContractTypes;

    const isValid =
      testContext.parent.contractType === null || validContractTypes.includes(testContext.parent.contractType);

    if (isValid) {
      return true;
    }
    const message =
      testContext.path === "contractType"
        ? "Contract Type can only be Cash, Spot, or Other for non-exchange traded commodities"
        : "Non-exchange traded commodities can only use Cash, Spot, or Other contract types";

    return testContext.createError({ message });
  },
};

const buyer = yup.string().label("Buyer").trim().required();

const commodityId = yup.number().label("Commodity").moreThan(0).integer().test(customContractTypeValidationTest);

const contractDate = yup.date().label("Booked Date");

const contractNumber = yup.string().label("Contract Number").trim();

const contractStatus = yup.string().label("Status").oneOf(["CLOSED", "OPEN", "UNFILLED", "UNPRICED"]).required();

const contractType = yup.string().label("Contract Type").test(customContractTypeValidationTest);

const cropYear = yup.date().required().label("Crop Year");

const marketedCropId = yup.number().label("Commodity").integer().moreThan(0);

const notes = yup.string().label("Notes").trim();

const quantity = yup
  .number()
  .transform(nanTransform)
  .label("Quantity Contracted")
  .required()
  .moreThan(0)
  .test(customQuantityValidationTest);

const quantityCanceled = yup
  .number()
  .transform(nanTransform)
  .label("Quantity Canceled")
  .nullable()
  .min(0)
  .test(customQuantityValidationTest);

const quantityDelivered = yup
  .number()
  .transform(nanTransform)
  .label("Quantity Delivered")
  .nullable()
  .min(0)
  .test(customQuantityValidationTest);

export const ManualCashContractSchema = yup.object().shape({
  buyer: buyer.nullable(),
  commodityId: commodityId.required(),
  contractDate: contractDate.required(),
  contractNumber: contractNumber.nullable(),
  contractStatus: contractStatus.required(),
  contractType: contractType.required(),
  cropYear: cropYear.required(),
  marketedCropId: marketedCropId.nullable(),
  notes: notes.nullable(),
  quantity: quantity.required(),
  quantityCanceled: quantityCanceled.nullable(),
  quantityDelivered: quantityDelivered.nullable(),
});

export const ImportedCashContractSchema = yup.object().shape({
  buyer: buyer.required(),
  commodityId: commodityId.required(),
  contractDate: contractDate.required(),
  contractNumber: contractNumber.required(),
  contractStatus: contractStatus.required(),
  contractType: contractType.required(),
  cropYear: cropYear.required(),
  marketedCropId: marketedCropId.nullable(),
  notes: notes.nullable(),
  quantity: quantity.required(),
  quantityCanceled: quantityCanceled.nullable(),
  quantityDelivered: quantityDelivered.required(),
});

export const EditNotesSchema = yup.object().shape({
  notes: notes.nullable(),
});
