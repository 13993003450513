import { useMutation, useSuspenseQuery } from "@apollo/client";
import _ from "lodash";
import { useMemo, useState } from "react";

import { useAuth } from "collection/graphql/auth";
import { restClient } from "collection/graphql/client";
import { UPDATE_ENTERPRISE } from "collection/graphql/enterprise/mutations";
import { GET_CURRENT_ENTERPRISE } from "collection/graphql/enterprise/queries";
import { getBasicFieldsData } from "collection/graphql/fields/queries";
import { updatePerson } from "collection/graphql/people/mutations";
import { LegacyPersonSchema } from "collection/graphql/people/schemas/LegacyPersonSchema";
import App from "layout/app";
import History from "lib/history";
import { CARBON_ENROLLMENT_SUCCESS } from "lib/metrics/events";
import { ENROLL_ENTERPRISE_FIELDS } from "modules/carbon_enrollment/queries/carbonEnrollmentQueries";

const ENTERPRISE_FIELDS = ["address", "address2", "city", "country", "name", "postalCode", "state"];
const USER_FIELDS = ["email", "firstName", "lastName", "phone"];
const getDetails = (data) =>
  _.omit(data, [...ENTERPRISE_FIELDS, ...USER_FIELDS, "checked", "has_made_practice_change"]);
const getEnterpriseData = (data) => _.pick(data, ENTERPRISE_FIELDS);

const useCarbonEnrollment = (client) => {
  const {
    data: { currentEnterprise },
  } = useSuspenseQuery(GET_CURRENT_ENTERPRISE);
  const [updateEnterprise] = useMutation(UPDATE_ENTERPRISE);

  const { fields } = useSuspenseQuery(getBasicFieldsData).data;
  const eligibleFields = useMemo(
    () => _.filter(fields, ({ eligibleCarbonClients }) => eligibleCarbonClients?.includes(client)),
    [fields]
  );
  const [selectedFields, setSelectedFields] = useState(new Set());

  const { currentUser, refetch: refetchCurrentUser } = useAuth();
  const [updateUser] = useMutation(updatePerson, { client: restClient });
  const [isSaving, setIsSaving] = useState(false);
  const [enrollEnterpriseFields] = useMutation(ENROLL_ENTERPRISE_FIELDS);

  const [formData, setFormData] = useState({
    ...getEnterpriseData(currentEnterprise),
    checked: false,
    country: currentEnterprise?.country || "US",
    has_made_practice_change: false,
    // current user fields
    email: currentUser.email,
    firstName: currentUser.firstName,
    lastName: currentUser.lastName,
    phone: currentUser.phone,
  });

  const onCancel = () => History.navigate("fields", { replace: true, trigger: true });
  const onFieldSelect = (selection, hasAllSelected) => {
    if (Array.isArray(selection)) {
      if (hasAllSelected) {
        const currentSelection = new Set(selectedFields);
        selection.forEach((field) => currentSelection.delete(field));
        setSelectedFields(currentSelection);
      } else {
        setSelectedFields(new Set([...selectedFields, ...selection]));
      }
    } else {
      const currentSelection = new Set(selectedFields);
      currentSelection.has(selection) ? currentSelection.delete(selection) : currentSelection.add(selection);
      setSelectedFields(currentSelection);
    }
  };
  const saveUserFields = async () => {
    const input = LegacyPersonSchema.cast(
      {
        ...currentUser,
        ...formData,
      },
      { assert: false, stripUnknown: true }
    );

    return updateUser({
      variables: { input },
    });
  };
  const onInputChange = (changes) => setFormData({ ...formData, ...changes });
  const onSave = async () => {
    const has_made_practice_change = formData.has_made_practice_change === "true";
    const fields = [...selectedFields].map(({ id }) => id);
    const details = { has_made_practice_change, ...getDetails(formData) };
    setIsSaving(true);

    try {
      await Promise.all([
        saveUserFields().then(refetchCurrentUser),
        updateEnterprise({
          variables: { enterprise: getEnterpriseData(formData) },
        }),
      ]);

      await enrollEnterpriseFields({ variables: { client, details, fields } });
      CARBON_ENROLLMENT_SUCCESS.track({});
      App.notify("Fields successfully enrolled");
      onCancel();
    } catch (err) {
      App.notify("An unexpected error occurred");
      setIsSaving(false);
    }
  };

  return {
    data: formData,
    fields: eligibleFields,
    isSaving,
    loading: false,
    onCancel,
    onSelect: onFieldSelect,
    onInputChange,
    onSave,
    testSave: saveUserFields,
    selectedFields,
  };
};

export default useCarbonEnrollment;
