import { BaseElement } from "@farmlogs/fl-ui";
import ProductInputChooser from "activity/components/choosers/ProductInputChooser";
import { MicroLabel } from "agreements/forms/components";
import React, { useState } from "react";

import cache from "collection/graphql/cache";

import { Button } from "components/fl-ui";
import { Stack } from "components/fl-ui/Layout";

const CHEMICAL = 469451;
const FERTILIZER = 1282;
const SEED = 17415;

const InputChooserDemo = () => {
  const [productId, setProductId] = useState(null);
  const handleChange = (newProductId) => {
    // ensure this object is not in the cache so a refetch is necessary
    let __typename;
    switch (newProductId) {
      case CHEMICAL:
        __typename = "ChemicalProduct";
        break;

      case FERTILIZER:
        __typename = "FertilizerProduct";
        break;

      case SEED:
        __typename = "SeedProduct";
        break;
    }

    if (__typename && cache.evict({ id: cache.identify({ id: newProductId, __typename }) })) {
      console.info(`${__typename} successfully removed from the cache`);
    }
    cache.gc();

    if (newProductId !== productId) {
      console.info("new product id: ", newProductId);
      setProductId(newProductId);
    }
  };

  return (
    <div>
      <h4>ProductInputChooser</h4>

      <Stack>
        <BaseElement alignItems="center" display="flex" gridGap="10px">
          <MicroLabel label="Input">
            <ProductInputChooser onChange={handleChange} value={productId} />
          </MicroLabel>

          <Button color={productId === CHEMICAL ? "primary" : "default"} onClick={() => handleChange(CHEMICAL)}>
            Chemical
          </Button>

          <Button color={productId === FERTILIZER ? "primary" : "default"} onClick={() => handleChange(FERTILIZER)}>
            Fertilizer
          </Button>

          <Button color={productId === SEED ? "primary" : "default"} onClick={() => handleChange(SEED)}>
            Seed
          </Button>

          <Button disabled={!productId} onClick={() => handleChange(null)}>
            Clear
          </Button>
        </BaseElement>

        <div>
          <small>Component value (product id):</small> <strong>{productId || "(null)"}</strong>
        </div>
      </Stack>
    </div>
  );
};

export default InputChooserDemo;
