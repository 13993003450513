import { BasicInput } from "agreements/forms/components";
import React, { Component } from "react";

import utils from "lib/validation/utils";

class YearInput extends Component {
  state = {
    value: this.props.value + "",
  };

  render() {
    const { name, onChange } = this.props;
    const { value } = this.state;

    const onBlur = (event) => {
      const value = event.target.value;
      if (utils.isInt(value) && /^\d{2}$/.test(value)) {
        this.setState(
          {
            value: 2000 + +value + "",
          },
          () => onChange({ [name]: +this.state.value })
        );
      }
    };

    const props = {
      maxLength: 4,
      type: "integer",
      name,
      onBlur,
      onChange: ({ target: { value } }) => onChange({ [name]: +value }),
      value,
    };

    return <BasicInput {...props} />;
  }
}

export default YearInput;
