import { CapsHeader } from "../Helpers";
import { DatePicker } from "@farmlogs/fl-ui";
import PropTypes from "prop-types";
import React from "react";
import autoBind from "react-autobind";

const FilterDatePicker = ({ label, onChange, value }) => (
  <label>
    {label}
    <DatePicker onChange={onChange} value={value} />
  </label>
);

/**
 * Date range filter parameter.  Returns a (start date, end date) tuple.
 */
class DateRangeFilter extends React.Component {
  constructor(props) {
    super(props);

    const dates = (this.props.param.default || ",").split(",");
    this.state = {
      startDate: dates[0],
      endDate: dates[1],
    };

    autoBind(this);
  }

  handleChange() {
    const value = [this.state.startDate, this.state.endDate].join(",");
    this.props.onParamChanged({ id: this.props.param.id, value });
  }

  handleStartDateChange(startDate) {
    this.setState({ startDate }, this.handleChange);
  }

  handleEndDateChange(endDate) {
    this.setState({ endDate }, this.handleChange);
  }

  render() {
    const p = this.props.param;
    return (
      <div key={p.id}>
        <CapsHeader>{p.title}</CapsHeader>
        <span>
          <FilterDatePicker
            label={<h3>Start Date</h3>}
            onChange={this.handleStartDateChange}
            value={this.state.startDate}
          />

          <FilterDatePicker label={<h3>End Date</h3>} onChange={this.handleEndDateChange} value={this.state.endDate} />
        </span>
      </div>
    );
  }
}

DateRangeFilter.propTypes = {
  param: PropTypes.object.isRequired,
  onParamChanged: PropTypes.func.isRequired,
};

export default DateRangeFilter;
