import _ from "lodash";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

import useCommodityCustomaryUnits from "collection/graphql/commodities/hooks/useCommodityCustomaryUnits";

import CommodityYieldUnit from "components/units/CommodityYieldUnit";
import Unit from "components/units/Unit";

const ContractUnit = ({ contract, defaultUnit, ...rest }) => {
  const customaryUnits = useCommodityCustomaryUnits();

  rest.unit = useMemo(() => {
    if (!contract) {
      return defaultUnit;
    }

    const quantityUnit = _.find(customaryUnits, { value: contract.quantityUnit });
    const commodity =
      contract.__typename === "UnderlyingContract" ? contract?.commodity : contract?.marketedCrop?.commodity;
    if (!quantityUnit) {
      return commodity ? <CommodityYieldUnit commodity={commodity} defaultUnit={defaultUnit} /> : defaultUnit;
    }

    return quantityUnit.abbr;
  }, [contract, customaryUnits]);

  return <Unit {...rest} />;
};

ContractUnit.propTypes = {
  amount: PropTypes.any,
  as: PropTypes.string,
  components: PropTypes.shape({
    Acre: PropTypes.func,
    Amount: PropTypes.func,
    Separator: PropTypes.func,
    Unit: PropTypes.func,
  }),
  contract: PropTypes.shape({
    commodity: PropTypes.object,
    marketedCrop: PropTypes.shape({
      commodity: PropTypes.object,
    }),
    quantityUnit: PropTypes.oneOf(["BUSHEL", "HUNDREDWEIGHT", "KILOGRAM", "POUND", "TON"]),
    __typename: PropTypes.oneOf(["CashContract", "FuturesContract", "OptionsContract", "UnderlyingContract"]),
  }),
  defaultUnit: PropTypes.string,
  per: PropTypes.oneOf(["acre", "unit"]),
};

ContractUnit.defaultProps = {
  as: "span",
  defaultUnit: "bu",
};

export default ContractUnit;
