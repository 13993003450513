import PropTypes from "prop-types";
import React from "react";

import CommodityColorPicker from "components/commodity/CommodityColorPicker";
import CommodityFormSelect from "components/commodity/CommodityForm/components/CommodityFormSelect";
import useCommodityForm from "components/commodity/CommodityForm/hooks/useCommodityForm";
import { Form, FormGroup, Input, NumericInput } from "components/fl-ui/Form";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";

const CommodityForm = (props) => {
  const { formId, onSubmit } = props;
  const { errorFor, isNew, loading, mergeValues, seedUnits, seedUnitTypes, touch, values, yieldUnitOptions } =
    useCommodityForm();
  const gddBaseRequired = values.gddTceil > 0;

  const blurHandlerFor = (fieldName) => () => touch(fieldName);
  const inputChangeHandler = (event, payload) => mergeValues(payload);
  const selectChangeHandlerFor =
    (fieldName) =>
    ({ value }) =>
      mergeValues({ [fieldName]: value });

  return loading ? (
    <LoadingWrapper isLoading />
  ) : (
    <Form id={formId} layout="flow-horizontal" onSubmit={onSubmit}>
      <FormGroup autoFocus error={errorFor("name")} gridColumn="1 / -1" label="Commodity name">
        <Input name="name" onBlur={blurHandlerFor("name")} onChange={inputChangeHandler} value={values.name || ""} />
      </FormGroup>

      <FormGroup error={errorFor("abbr")} label="Abbreviation (1-3 characters)">
        <Input
          maxLength={3}
          name="abbr"
          onBlur={blurHandlerFor("abbr")}
          onChange={inputChangeHandler}
          value={values.abbr || ""}
        />
      </FormGroup>

      <FormGroup error={errorFor("color")} gridColumn="1 / -1" label="Commodity color">
        <CommodityColorPicker
          onBlur={blurHandlerFor("color")}
          onChange={selectChangeHandlerFor("color")}
          value={values.color}
        />
      </FormGroup>

      <FormGroup error={errorFor("gddTbase")} label={gddBaseRequired ? "GDD floor" : "GDD floor (optional)"}>
        <NumericInput
          name="gddTbase"
          onBlur={blurHandlerFor("gddTbase")}
          onChange={({ target: { value } }) => mergeValues({ gddTbase: value !== "" ? value : undefined })}
          suffix={<small>°F</small>}
          value={values.gddTbase || ""}
        />
      </FormGroup>

      <FormGroup error={errorFor("gddTceil")} label="GDD ceiling (optional)">
        <NumericInput
          name="gddTceil"
          onBlur={blurHandlerFor("gddTceil")}
          onChange={({ target: { value } }) => mergeValues({ gddTceil: value !== "" ? value : undefined })}
          suffix={<small>°F</small>}
          value={values.gddTceil || ""}
        />
      </FormGroup>

      <FormGroup error={errorFor("seedUnitType")} label="Measure type">
        <CommodityFormSelect
          isDisabled={!isNew}
          onBlur={blurHandlerFor("seedUnitType")}
          onChange={(option) => {
            const payload = { seedUnitType: option.value };
            if (payload.seedUnitType !== values.seedUnitType) {
              payload.defaultSeedApplyUnit = null;
              payload.defaultSeedPurchaseUnit = null;
            }

            mergeValues(payload);
          }}
          options={seedUnitTypes}
          placeholder="Choose type"
          value={values.seedUnitType}
        />
      </FormGroup>

      <FormGroup
        error={errorFor("defaultSeedPurchaseUnit")}
        gridColumn="1/2"
        label="Default seed purchase unit (optional)"
      >
        <CommodityFormSelect
          isDisabled={!values.seedUnitType}
          onChange={selectChangeHandlerFor("defaultSeedPurchaseUnit")}
          options={seedUnits}
          placeholder="Choose seed unit"
          value={values.defaultSeedPurchaseUnit}
        />
      </FormGroup>

      <FormGroup error={errorFor("defaultSeedApplyUnit")} label="Default seed use unit (optional)">
        <CommodityFormSelect
          isDisabled={!values.seedUnitType}
          name="defaultSeedApplyUnit"
          onChange={selectChangeHandlerFor("defaultSeedApplyUnit")}
          options={seedUnits}
          placeholder="Choose seed unit"
          value={values.defaultSeedApplyUnit}
        />
      </FormGroup>

      <FormGroup error={errorFor("defaultYieldUnit")} gridColumn="1 / -1" label="Default yield unit">
        <CommodityFormSelect
          name="defaultYieldUnit"
          onChange={selectChangeHandlerFor("defaultYieldUnit")}
          options={yieldUnitOptions}
          placeholder="Choose yield unit"
          value={values.defaultYieldUnit}
        />
      </FormGroup>

      <FormGroup error={errorFor("bushelSize")} label="Bushel weight">
        <NumericInput
          name="bushelSize"
          onBlur={blurHandlerFor("bushelSize")}
          onChange={({ target: { value } }) => mergeValues({ bushelSize: value !== "" ? value : null })}
          suffix={<small>lbs</small>}
          type="integer"
          value={values.bushelSize || ""}
        />
      </FormGroup>

      <FormGroup error={errorFor("dryMoisture")} label="Standard dry moisture (optional)">
        <NumericInput
          maxPrecision={1}
          name="dryMoisture"
          onBlur={blurHandlerFor("dryMoisture")}
          onChange={({ target: { value } }) => mergeValues({ dryMoisture: value !== "" ? value : null })}
          suffix={<small>%</small>}
          type="float"
          value={values.dryMoisture || ""}
        />
      </FormGroup>
    </Form>
  );
};

CommodityForm.propTypes = {
  formId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CommodityForm;
