import dollarFormat from "marketing/utils/dollarFormat";
import SummaryTable from "profit_and_loss/components/SummaryTable";
import TextHelpTooltip from "profit_and_loss/components/TextHelpTooltip";
import PropTypes from "prop-types";
import React, { useState } from "react";

import withViewType from "hoc/withViewType";

import { GroupedButtons } from "components/fl-ui/Buttons";
import CommodityYieldUnit from "components/units/CommodityYieldUnit";

const activityCostMessage =
  "Activity costs cover operational expenses (fuel, labor, etc). Input costs are automatically calculated based on Product prices and application rates.";
const expenseSummaryMessage =
  "Expenses are calculated net of crop share agreements. Production is based on grower yield, not total production.";

const selectMetricButtonOptions = [
  { id: "growerOnly", label: "GROWER ONLY" },
  { id: "total", label: "TOTAL PRODUCTION" },
];

const ExpenseSummaryTable = ({ categories = [], commodity, costPerAcre, costPerUnit, costTotal, viewType }) => {
  const [selectedMetrics, setSelectedMetrics] = useState(selectMetricButtonOptions.find(({ id }) => id === "total"));
  const data = categories.map(({ costPerAcre, costPerUnit, costPercentOfTotal, costTotal, id, name }) => ({
    id,
    label: id === "operational_costs" ? <TextHelpTooltip message={activityCostMessage} text={name} /> : name,
    values:
      viewType === "mobile"
        ? [costPerAcre && `${dollarFormat(costPerAcre)} / ac`, costPercentOfTotal && `${costPercentOfTotal}%`]
        : [
            costPerAcre && `${dollarFormat(costPerAcre)} / ac`,
            costPerUnit && <CommodityYieldUnit commodity={commodity} amount={dollarFormat(costPerUnit)} per="unit" />,
            costTotal && dollarFormat(costTotal),
            costPercentOfTotal && `${costPercentOfTotal}%`,
          ],
  }));

  const footer = {
    id: "expenseFooter",
    label: "total",
    values:
      viewType === "mobile"
        ? [costPerAcre && `${dollarFormat(costPerAcre)} / ac`, "100%"]
        : [
            costPerAcre && `${dollarFormat(costPerAcre)} / ac`,
            costPerUnit && <CommodityYieldUnit commodity={commodity} amount={dollarFormat(costPerUnit)} per="unit" />,
            costTotal && dollarFormat(costTotal),
            "100%",
          ],
  };

  const header = {
    id: "expenseHeader",
    label: "category",
    values:
      viewType === "mobile" ? ["cost", "% of all"] : ["Cost per acre", "Cost per unit", "total Cost", "% of all Costs"],
  };

  const tableAction = viewType !== "mobile" && (
    <GroupedButtons onClick={setSelectedMetrics} options={selectMetricButtonOptions} selected={selectedMetrics} />
  );

  return (
    <SummaryTable
      // action={tableAction} Commenting out till P& L 1.1
      data={data}
      footer={footer}
      header={header}
      title={<TextHelpTooltip message={expenseSummaryMessage} text="Expense summary" />}
    />
  );
};

ExpenseSummaryTable.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object),
  commodity: PropTypes.object.isRequired,
  costPerAcre: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  costPerUnit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  costTotal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  viewType: PropTypes.oneOf(["desktop", "mobile"]),
};

export default withViewType(ExpenseSummaryTable);
