import { fileClient } from "@farmlogs/fl-ui";
import PropTypes from "prop-types";
import React from "react";

import { Button } from "components/fl-ui";
import { Cluster } from "components/fl-ui/Layout";

const UserSettingsItem = ({ addButton, blankSlateText, canEdit, description, onEditUser, title, users }) => {
  const getUserIconUrl = (user) => {
    if (user.icon) {
      return fileClient.getImagePreviewUrl(user.icon, { width: 200, height: 200 });
    }

    return "/images/resources/people/unknown.png";
  };

  return (
    <div className="settings-inputs-wrapper">
      <h2 className="heading-underline">{title}</h2>
      <p>{description}</p>

      <div className="settings-item-wrapper">
        {users.length === 0 && <div className="blank-slate">{blankSlateText}</div>}

        {users.map((user) => (
          <Cluster between className="settings-item settings-item-hoverable" key={user.id}>
            <Cluster start>
              <img className="icon" src={getUserIconUrl(user)} style={{ float: "none" }} />

              <div>
                <div>{user.name}</div>
                <div className="sub">{user.email || "No email"}</div>
              </div>
            </Cluster>
            <Button disabled={!canEdit} onClick={() => onEditUser(user.id)}>
              Edit
            </Button>
          </Cluster>
        ))}
      </div>

      {addButton}
    </div>
  );
};

UserSettingsItem.propTypes = {
  addButton: PropTypes.node,
  blankSlateText: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  role: PropTypes.oneOf(["admin", "contributor", "manager", "no_access", "service_provider"]).isRequired,
  title: PropTypes.string.isRequired,
  users: PropTypes.array.isRequired,
};

UserSettingsItem.defaultProps = {};

export default UserSettingsItem;
