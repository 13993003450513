import React from "react";
import router from "router";

import { Button } from "components/fl-ui";

const CreateAgreementButton = (props) => (
  <Button color="primary" onClick={() => router.navigate("land_costs/add", { trigger: true })} style={props.style}>
    Add agreement
  </Button>
);

export default CreateAgreementButton;
