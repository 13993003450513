import { useMutation } from "@apollo/client";

import buildCashContractInput from "collection/graphql/contracts/hooks/buildCashContractInput";
import { CREATE_CASH_CONTRACT } from "collection/graphql/marketing";

const useCreateCashContract = (options = {}) => {
  const save = useMutation(CREATE_CASH_CONTRACT, options)[0];

  return async (contract, options = {}) => {
    const input = buildCashContractInput(contract);
    return save({
      variables: {
        input,
      },
      ...options,
    });
  };
};

export default useCreateCashContract;
