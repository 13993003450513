import { InMemoryCache } from "@apollo/client";

import createFragmentRegistry from "collection/graphql/cache/createFragmentRegistry";
import * as typePolicies from "collection/graphql/cache/typePolicies";

export const cacheConfig = {
  fragments: createFragmentRegistry(),

  possibleTypes: {
    AgreementFields: ["LeaseAgreement", "LoanAgreement", "RevenueAgreement"],
    BaseContract: ["CashContract", "FuturesContract", "OptionsContract"],
    BaseProduct: ["ChemicalProduct", "FertilizerProduct", "SeedProduct", "WaterProduct"],
    LeaseTermFields: ["BaseRentLeaseTerm", "ExpenseShareLeaseTerm", "YieldAdjustmentLeaseTerm", "YieldShareLeaseTerm"],
  },

  typePolicies: {
    ...typePolicies,
  },
};

export default new InMemoryCache(cacheConfig);
