import { PACKAGE_TYPE, PRODUCT_CATEGORY, PRODUCT_TYPE } from "@revenuecat/purchases-capacitor";
import { array, number, object, string } from "yup";

const packageTypeSchema = string().oneOf(Object.values(PACKAGE_TYPE));
const productCategorySchema = string().oneOf(Object.values(PRODUCT_CATEGORY));
const productTypeSchema = string().oneOf(Object.values(PRODUCT_TYPE));

const purchasesStoreProductSchema = object({
  currencyCode: string().required(),
  description: string().optional(),
  discounts: array().of(string()).nullable(),
  identifier: string().required(),
  price: number().required(),
  priceString: string().required(),
  productCategory: productCategorySchema.nullable(),
  productType: productTypeSchema.required(),
  subscriptionPeriod: string().optional().nullable(),
  title: string().optional(),
});

const purchasesPackageSchema = object().shape({
  identifier: string().required(),
  offeringIdentifier: string().required(),
  packageType: packageTypeSchema.required(),
  product: purchasesStoreProductSchema.required(),
});

export const AppUserIdSchema = number().required().integer().moreThan(0);

export const PurchasesPackagesSchema = array().of(purchasesPackageSchema);
