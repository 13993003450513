import { useMutation, useQuery } from "@apollo/client";
import _ from "lodash";
import { useEffect, useState } from "react";

import { GET_COMMODITIES } from "collection/graphql/commodities/queries";
import { GET_MARKETING_CONFIG, SET_MARKETING_CONFIG } from "collection/graphql/marketing";
import { GET_BIDS_BY_COUNTY } from "collection/graphql/prices";
import useCurrentEnterprise from "hooks/useCurrentEnterprise";
import { CORN } from "lib/constants";

const useLocalPricesFilters = () => {
  const [bidType, setBidType] = useState(null);
  const [commodity, setCommodity] = useState(null);
  const [counties, setCounties] = useState(null);
  const [saveFilters] = useMutation(SET_MARKETING_CONFIG);

  const commodityQuery = useQuery(GET_COMMODITIES);
  const configQuery = useQuery(GET_MARKETING_CONFIG);
  const enterpriseQuery = useCurrentEnterprise();

  const loading = _.some([commodityQuery, configQuery, enterpriseQuery], "loading");
  useEffect(() => {
    if (!loading) {
      const { bidType, commodity, counties } = configQuery.data.marketingConfig.localPrices ?? {};
      const usedCommodities = _.filter(commodityQuery.data.commodities, { isExchangeTraded: true });
      const targetCommodity = commodity ?? _.find(usedCommodities, { id: CORN });

      setBidType(bidType ?? "SPOT");
      setCommodity(targetCommodity);
      setCounties(counties ?? enterpriseQuery.currentEnterprise.county);
    }
  }, [loading, configQuery.data?.marketingConfig]);

  const handleFilterSave = (updatedFilters) => {
    const { id: commodityId } = updatedFilters.commodity ?? commodity;
    const normalizedFilters = {
      bidType: updatedFilters.bidType ?? bidType,
      counties: _.map(updatedFilters.counties || counties, "fips"),
    };

    saveFilters({
      refetchQueries: [
        {
          query: GET_BIDS_BY_COUNTY,
          variables: {
            ...normalizedFilters,
            commodity: commodityId,
          },
        },
      ],

      variables: {
        config: {
          localPrices: {
            ...normalizedFilters,
            commodityId,
          },
        },
      },
    });
  };

  const updateCommodity = (commodity) => {
    setCommodity(commodity);
    handleFilterSave({ commodity });
  };

  return {
    bidType,
    commodity,
    counties,
    loaded: !!bidType,
    saveFilters: handleFilterSave,
    updateCommodity,
  };
};

export default useLocalPricesFilters;
