import { useQuery } from "@apollo/client";
import chooserStyles from "activity/components/choosers/FieldCropChooser/chooserStyles";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import ReactSelect from "react-select";

import { GET_ACTIVITY_TYPES_AND_CATEGORIES } from "collection/graphql/activities/withActivityTypes";

const ActivityTypeChooser = (props) => {
  const { excludeArchived, onChange, value } = props;

  const { data } = useQuery(GET_ACTIVITY_TYPES_AND_CATEGORIES);
  const allActivityTypes = useMemo(() => {
    let allTypes = data?.activityTypes ?? [];
    if (excludeArchived) {
      allTypes = _.reject(allTypes, { isArchived: true });
    }
    const sortedActivityTypes = _.sortBy(allTypes, ({ name }) => name.toLowerCase());

    return sortedActivityTypes;
  }, [data?.activityTypes]);

  const componentValue = value.map((id) => _.find(allActivityTypes, { id }));

  return (
    <ReactSelect
      getOptionLabel={_.property("name")}
      getOptionValue={_.property("id")}
      isMulti
      onChange={(selectedOptions) => onChange(_.map(selectedOptions, "id"))}
      options={allActivityTypes}
      styles={chooserStyles}
      value={componentValue}
    />
  );
};

ActivityTypeChooser.propTypes = {
  excludeArchived: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.number).isRequired,
};

ActivityTypeChooser.defaultProps = {
  excludeArchived: false,
};

export default ActivityTypeChooser;
