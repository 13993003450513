import { useQuery } from "@apollo/client";

import { getBasicField } from "collection/graphql/fields/queries";

const useBasicField = ({ fieldId }) => {
  const { data, loading } = useQuery(getBasicField, { variables: { fieldId } });
  return { ...(data?.field ?? {}), loading };
};

export default useBasicField;
