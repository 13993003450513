import { css } from "aphrodite";
import styles from "map/layers/styles";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { Button } from "components/fl-ui";
import Icon from "components/fl-ui/Icons/Icon";
import Card from "components/ui/card";

const FlatDropdown = ({ children, hasMultiControl, icon, openDefault, text }) => {
  const [open, setOpen] = useState(openDefault);
  const ref = useRef(null);

  const handleBlur = ({ target }) => {
    if (!ref.current.contains(target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleBlur);
    return () => document.removeEventListener("click", handleBlur);
  }, []);

  return (
    <div className={css(styles.flatButton)} onClick={() => setOpen(!open)} ref={ref}>
      <Button
        className={css(styles.mapControlButton, hasMultiControl && styles.unsetWidth)}
        color="white"
        icon={icon}
        iconClassName={css(styles.buttonIconStyle)}
        onBlur={handleBlur}
        size="lg"
      >
        <div className={css(styles.buttonContent)}>
          <span className={css(hasMultiControl && styles.buttonText)}>{text}</span>
          <Icon className={css(styles.popoverTargetIcon)} icon="chevronDown" />
        </div>
      </Button>
      {open && (
        <div className={css(styles.containerStyle)}>
          <Card className={`border-caret-white caret-middle ${css(styles.cardStyle)}`}>{children}</Card>
        </div>
      )}
    </div>
  );
};

FlatDropdown.propTypes = {
  onClick: PropTypes.func,
};

FlatDropdown.defaultProps = {
  openDefault: false,
};

export default FlatDropdown;
