import styled from "styled-components";
import { border, color } from "styled-system";

import { GreyColors } from "components/fl-ui/colors";
import { BorderRadius, Borders, Spacing } from "components/fl-ui/constants";

export const Card = styled.div`
  border: ${Borders.regular};
  border-radius: ${BorderRadius.large};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${border}
`;

export const CardBody = styled.div`
  padding: ${Spacing.regular};
`;

export const CardHeader = styled.header`
  background-color: ${GreyColors.smoke90};
  padding: 0 ${Spacing.regular};
  ${color}
`;
