import { useQuery } from "@apollo/client";
import { partition, orderBy } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { getFieldCropsWithYield } from "collection/graphql/fields/queries";
import withCropMutation from "hoc/withCropMutation";

import CropYield from "components/crop/yield";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import BlankDetail from "fields/components/BlankDetail";
import ListWrapper from "fields/components/ListWrapper";

const getDisplayedCrops = (crops, showAll, year) => {
  if (!showAll) {
    // - If there is a crop for the current year, show the yield (or blank slate) for that crop.
    // - If there's no crop for the current year, check for a crop from the
    //   previous year. If there's a crop, show it's yield information (or blank slate)
    // - If we haven't found a crop yet, set the state to an empty array. Render
    //   will display the crop blank slate that prompts users to add a crop to view
    //   yield.
    const [current, previous] = partition(crops, ({ cropYear }) => cropYear === year);
    return current.length ? current : previous;
  }

  return crops;
};

const Yield = ({ createCrop, fieldId, showAll, year }) => {
  const [fieldCrops, setFieldCrops] = useState(null);
  const variables = { fields: [fieldId] };
  if (!showAll) {
    variables.years = [year, year - 1];
  }

  // Commodities with no bushelSize set throw an error. Setting errorPolicy to retain useful data
  const { data, loading } = useQuery(getFieldCropsWithYield, { errorPolicy: "all", variables });

  useEffect(() => {
    if (!loading && data?.fieldCrops) {
      const crops = getDisplayedCrops(data.fieldCrops, showAll, year);
      const sortedCrops = orderBy(crops, ["cropYear", "commodity.name"], ["desc", "asc"]);
      setFieldCrops(sortedCrops);
    }
  }, [data?.fieldCrops, loading]);

  if (loading && !fieldCrops) {
    return <LoadingWrapper />;
  }

  return (
    <div>
      {fieldCrops?.length ? (
        <ListWrapper buttonAction={() => null} buttonLabel="" limit={showAll ? Infinity : 3} list={[...fieldCrops]}>
          {({ displayed }) => displayed.map((crop) => <CropYield crop={crop} fieldId={fieldId} key={crop.id} />)}
        </ListWrapper>
      ) : (
        <BlankDetail onClick={() => createCrop(fieldId)}>
          <p>Add a crop to record yield</p>
        </BlankDetail>
      )}
    </div>
  );
};

Yield.propTypes = {
  createCrop: PropTypes.func.isRequired,
  fieldId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  showAll: PropTypes.bool,
  year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default withCropMutation(Yield);
