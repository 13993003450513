import Tippy from "@tippyjs/react";
import { StyleSheet, css } from "aphrodite/no-important";
import PropTypes from "prop-types";
import React from "react";
import "tippy.js/dist/tippy.css";

const styles = StyleSheet.create({
  target: {
    display: "inline",
    ":hover": {
      cursor: "pointer",
    },
  },
});

const Tooltip = ({ children, message, placement }) => (
  <Tippy content={message} placement={placement}>
    <span className={css(styles.target)}>{children}</span>
  </Tippy>
);

Tooltip.propTypes = {
  children: PropTypes.any,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  placement: PropTypes.oneOf(["top", "bottom", "left", "right"]),
};

Tooltip.defaultProps = { placement: "top" };

export default Tooltip;
