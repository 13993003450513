import { css } from "aphrodite/no-important";
import classNames from "classnames";
import React from "react";

import styles from "components/fl-ui/Layout/styles/headerStyles";

const BackLink = ({ backLink }) =>
  typeof backLink === "string" ? (
    <a href={backLink} className={classNames(css(styles.backLink), "heading-back-link")} data-qa-id="back-arrow" />
  ) : (
    <a onClick={backLink} className={classNames(css(styles.backLink), "heading-back-link")} data-qa-id="back-arrow" />
  );

export default BackLink;
