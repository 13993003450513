import { gql } from "@apollo/client";

import { Priceline } from "collection/graphql/contracts/fragments";

export default gql`
  mutation CreatePriceline($input: CreatePricelineInput!) {
    createPriceline(input: $input) {
      priceline {
        ...Priceline
      }
    }
  }
  ${Priceline}
`;
