import { gql } from "@apollo/client";

const MaintenanceRecordFragment = gql`
  fragment MaintenanceRecord on MaintenanceRecord {
    created
    date
    engineHours
    equipmentId
    equipmentType
    id
    laborHours
    notes
    parts
    people
    updated
  }
`;

export default MaintenanceRecordFragment;
