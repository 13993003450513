import { useQuery } from "@apollo/client";
import _ from "lodash";
import { useMemo } from "react";

import { getProducts } from "collection/graphql/products/queries";

const useProductsById = (productIds) => {
  const uniqueProductIds = useMemo(() => _.uniq(productIds).sort(), [productIds]);
  const { data, loading } = useQuery(getProducts, {
    skip: uniqueProductIds.length === 0,
    variables: {
      productIds: uniqueProductIds,
    },
  });

  return {
    products: data?.products ?? [],
    loading,
  };
};

export default useProductsById;
