const FieldRainfallHistoryTypePolicy = {
  fields: {
    day: {
      read: (_, { readField }) => (readField("date") || "").split("-")[2],
    },

    lookup: {
      read: (_, { readField }) => (readField("date") || "").replace(/^\d{4}-/, ""),
    },

    month: {
      read: (_, { readField }) => (readField("date") || "").split("-")[1],
    },
  },
};

export default FieldRainfallHistoryTypePolicy;
