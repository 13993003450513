import { TRACTOR } from "../constants";
import implementIcons from "./implementIcons";
import tractorIcons from "./tractorIcons";
import _ from "lodash";

const iconsByType = (type) => {
  return type === TRACTOR ? tractorIcons : _.filter(implementIcons, { type });
};

export default iconsByType;
