import useActivitiesProvider from "activity/hooks/useActivitiesProvider";
import useActivityBulkSelection from "activity/hooks/useActivityBulkSelection";
import { isCancelledActivity, isDoneActivity, isOverdueActivity, isPlannedActivity } from "activity/lib";
import { activityBulkDeletionPrompt } from "activity/utils";
import _ from "lodash";
import React, { useMemo } from "react";

import useActivityBulkDelete from "collection/graphql/activities/hooks/useActivityBulkDelete";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";

import BulkActions from "components/fl-ui/Buttons/BulkActions";

const useBulkOptions = (bulkSelection, activities) => {
  return useMemo(() => {
    let hasCancelledActivities = false;
    let hasDoneActivities = false;
    let hasOverdueActivities = false;
    let hasPlannedActivities = false;
    let hasAAR = false;
    let hasMachineData = false;

    _.each(activities, (activity) => {
      if (isCancelledActivity(activity)) {
        hasCancelledActivities = true;
      }
      if (isDoneActivity(activity)) {
        hasDoneActivities = true;
      }
      if (isOverdueActivity(activity)) {
        hasOverdueActivities = true;
      }
      if (isPlannedActivity(activity)) {
        hasPlannedActivities = true;
      }
      if (activity.isAAR) {
        hasAAR = true;
      }
      if (activity.isMachineData) {
        hasMachineData = true;
      }
    });

    const reconstitute = (activities) => bulkSelection.reconstitute(_.map(activities, "id"));

    const options = [];
    options.push({ label: "All", onClick: () => reconstitute(activities) });
    options.push({ label: "None", onClick: () => bulkSelection.clear() });

    // status toggles
    hasDoneActivities &&
      options.push({ label: "Done", onClick: () => reconstitute(activities.filter(isDoneActivity)) });
    hasPlannedActivities &&
      options.push({ label: "Planned", onClick: () => reconstitute(activities.filter(isPlannedActivity)) });
    hasOverdueActivities &&
      options.push({ label: "Overdue", onClick: () => reconstitute(activities.filter(isOverdueActivity)) });
    hasCancelledActivities &&
      options.push({ label: "Cancelled", onClick: () => reconstitute(activities.filter(isCancelledActivity)) });

    // aar/machine data
    hasAAR && options.push({ label: "AAR", icon: "aar", onClick: () => reconstitute(_.filter(activities, "isAAR")) });
    hasMachineData &&
      options.push({
        label: "Integrations",
        icon: "integrations",
        onClick: () => reconstitute(_.filter(activities, "isMachineData")),
      });

    return options;
  }, [activities]);
};

const ActivityBulkActions = () => {
  const bulkSelection = useActivityBulkSelection();
  const options = useBulkOptions(bulkSelection, useActivitiesProvider().filteredActivities);
  const selectionStatus = [bulkSelection.size, bulkSelection.size === 1 ? "activity" : "activities"].join(" ");
  const performBulkDelete = useActivityBulkDelete();
  const hasWorkOrderFeature = useEnterpriseFeature("work_orders");

  const showPrompt = () => {
    activityBulkDeletionPrompt(bulkSelection.size, hasWorkOrderFeature).then(
      async () => {
        await performBulkDelete(bulkSelection.items());
        bulkSelection.clear();
      },
      () => {}
    );
  };

  return (
    <BulkActions
      danger={{ action: showPrompt }}
      options={options}
      selection={bulkSelection.items()}
      selectionStatus={selectionStatus}
    />
  );
};

export default ActivityBulkActions;
