import { gql } from "@apollo/client";

import { FIELD_LAYER } from "collection/graphql/fields/fragments";

export default gql`
  query GetFieldLayers($field: Int, $type: String, $startDate: Date, $endDate: Date) {
    layers: getFieldLayers(field: $field, type: $type, startDate: $startDate, endDate: $endDate) {
      ...FieldLayer
    }
  }
  ${FIELD_LAYER}
`;
