import { CapsHeader, fullWidth } from "../Helpers";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { Checkbox } from "components/fl-ui/Form/index";

/**
 * Renders the toggleable columns for a report.
 */

const ColumnFilter = ({ columns, onParamChanged }) => {
  const [reportFields, setReportFields] = useState({});

  useEffect(() => {
    if (columns) {
      const fields = {};

      columns.forEach((column) => {
        fields[column.id] = true;
      });

      setReportFields(fields);
    }
  }, []);

  const handleFieldToggled = (event) => {
    setReportFields((prevFields) => ({
      ...prevFields,
      [event.target.value]: event.target.checked,
    }));
  };

  useEffect(() => {
    const columns = Object.keys(reportFields)
      .filter((k) => !!reportFields[k])
      .join();
    onParamChanged({ id: "columns", value: columns });
  }, [reportFields]);

  const filteredColumns = columns.filter((c) => !c.required);

  return (
    <>
      {filteredColumns.length > 0 && (
        <>
          <CapsHeader>Report Fields</CapsHeader>
          {filteredColumns.map((c) => (
            <p key={c.id} style={{ opacity: c.required ? "0.5" : undefined }}>
              <Checkbox
                name="columns"
                style={fullWidth}
                defaultChecked={!!c.id}
                readOnly={c.required}
                label={c.title}
                value={c.id}
                onChange={handleFieldToggled}
              />
            </p>
          ))}
        </>
      )}
    </>
  );
};

ColumnFilter.propTypes = {
  columns: PropTypes.array,
  onParamChanged: PropTypes.func.isRequired,
};

export default ColumnFilter;
