import { css } from "aphrodite/no-important";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import React from "react";
import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { GreyColors, UIColors } from "components/fl-ui/colors";
import ReferenceAreaLabel from "fields/components/RainfallHeatChart/ReferenceAreaLabel";
import {
  chartMargins,
  chartStyles,
  inlineStyles,
  getColor,
  getColorClass,
  getUnit,
  getTicks,
  xAxisProps,
  yAxisProps,
} from "fields/components/RainfallHeatChart/common";
import styles from "fields/onboarding/onboardingStyles";

const formatSum = (sum, aspect, data, period, plantingDate) => {
  let formattedSum = sum;

  if (sum && aspect === "gdd") {
    formattedSum = Math.round(sum);
  }

  if (period === "planting") {
    const { amount, sum: total } = data.find(({ date }) => moment(date).format("YYYY-MM-DD") === plantingDate) || {};
    formattedSum = Math.round(formattedSum - total + amount);
  }

  return formattedSum;
};

const CumulativeToolTipContent = ({ aspect, data, payload, period, plantingDate, year }) => {
  const { cumAverage, date, sum } = _.isEmpty(payload) ? {} : payload[0];
  const formattedCumulativeAverage = cumAverage && aspect === "gdd" ? Math.round(cumAverage) : cumAverage;
  const formattedSum = formatSum(sum, aspect, data, period, plantingDate);
  const since = moment((_.minBy(data, "date") || {}).date || moment().startOf("year"));
  const shouldRenderContainer = date || formattedSum || formattedCumulativeAverage;

  return (
    <>
      {shouldRenderContainer && (
        <div className={css(styles.tooltipContainer)}>
          {!!date && (
            <p className={css(styles.tooltipTitle)}>
              {formattedSum ? moment(date).year(year).format("MMMM Do YYYY") : moment(date).format("MMMM Do")}
            </p>
          )}
          {!!formattedSum && (
            <div>
              <p className={css(getColorClass(aspect), styles.tooltipValues)}>{`${formattedSum}${getUnit(aspect)}`}</p>
              <p className={css(styles.tooltipDescription, getColorClass(aspect))}>Since {since.format("MMM D")}</p>
            </div>
          )}
          {!!formattedCumulativeAverage && (
            <div>
              <p className={css(styles.tooltipValues)}>{`${formattedCumulativeAverage}${getUnit(aspect)}`}</p>
              <p className={css(styles.tooltipDescription)}>10 year YTD average</p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

const CumulativeChart = (props) => {
  const { data, refAreaLeft, refAreaRight, refAreaSum, refAreaAvgSum, aspect, period, plantingDate, year } = props;
  const [leftDate, rightDate] = [refAreaLeft, refAreaRight].sort();
  const cumYAxisProps = {
    ...yAxisProps,
    domain: [
      "dataMin",
      (dataMax) => {
        const maxCumAverage = data.map((x) => parseFloat(x.cumAverage))[data.length - 1];
        return numeral(Math.ceil(Math.max(maxCumAverage, dataMax))).format("0.0");
      },
    ],
  };

  return (
    <ResponsiveContainer minHeight={400} className={css(chartStyles.chartContainer)}>
      <LineChart
        data={data}
        margin={chartMargins}
        onMouseDown={props.onMouseDown}
        onMouseMove={props.onMouseMove}
        onMouseUp={props.onMouseUp}
        cursor="crosshair"
      >
        <CartesianGrid isAnimationActive={false} stroke={GreyColors.smoke91} strokeDasharray="3 3" vertical={false} />
        <Line
          dataKey="sum"
          dot={false}
          isAnimationActive={false}
          stroke={getColor(aspect)}
          strokeWidth={2}
          yAxisId={1}
        />
        <Line
          dataKey="cumAverage"
          dot={false}
          isAnimationActive={false}
          opacity={0.5}
          stroke={UIColors.medium}
          strokeDasharray="4 4"
          strokeWidth={2}
          yAxisId={1}
        />

        {plantingDate && (
          <ReferenceLine yAxisId={1} x={moment(plantingDate).format()} strokeDasharray="3 3">
            <Label fill={UIColors.light} value="Planting" style={inlineStyles.refLineLabel} position="insideTopLeft" />
          </ReferenceLine>
        )}
        {leftDate && rightDate && (
          <ReferenceArea
            opacity={0.25}
            fill={UIColors.lightest}
            yAxisId="1"
            x1={leftDate}
            x2={rightDate}
            strokeOpacity={0.3}
          >
            <Label
              content={
                <ReferenceAreaLabel
                  aspect={aspect}
                  start={leftDate}
                  end={rightDate}
                  refAreaSum={refAreaSum}
                  refAreaAvgSum={refAreaAvgSum}
                  unit={getUnit(aspect)}
                />
              }
            />
          </ReferenceArea>
        )}
        <Tooltip
          position={{ y: 0 }}
          content={
            <CumulativeToolTipContent
              aspect={aspect}
              data={data}
              offset={24}
              period={period}
              plantingDate={plantingDate}
              year={year}
            />
          }
        />

        <Legend
          payload={[
            { value: `${year} cumulative`, type: "line", dataKey: "sum", color: getColor(aspect) },
            { value: "10-year average", type: "line", dataKey: "cumAverage", color: UIColors.medium },
          ]}
          wrapperStyle={inlineStyles.legend}
        />
        <XAxis {...xAxisProps} ticks={getTicks(data)} />
        <YAxis {...cumYAxisProps} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default CumulativeChart;
