import Collection from "lib/collection";
import Model from "model/buyer";

const BuyersCollection = Collection.extend({
  name: "buyers",
  model: Model,
  url: "/api/buyers",

  comparator(model) {
    return model.get("name").toLowerCase();
  },
});
export default new BuyersCollection();
