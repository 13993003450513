import { css } from "aphrodite/no-important";
import React from "react";

import PayGateCommonBlankSlateBanner from "components/advertisements/banners/PayGateCommonBlankSlateBanner";
import styles from "components/advertisements/styles";

const FileAttachmentsBlankSlateBanner = (props) => {
  props = {
    ...props,
    actionButtonText: "Try Field Attachments for free",
    secondaryText: "Field Attachments is included in the Essentials plan, and you can try it for free.",
    imagePath: "images/pay_gates/banners/fileAttachments.png",
    title: "With Field Attachments you can:",
  };

  return (
    <PayGateCommonBlankSlateBanner {...props}>
      <ul>
        <li className={css(styles.valueProp)}>
          Attach and manage digital files that you want to have at your fingertips when viewing your field records in
          Bushel Farm.
        </li>
        <li className={css(styles.valueProp)}>
          PDFs, images, and text files as well as your favorite Word, Excel, and PowerPoint files can be uploaded so
          that you have access to your digital field records in one place!
        </li>
      </ul>
    </PayGateCommonBlankSlateBanner>
  );
};

export default FileAttachmentsBlankSlateBanner;
