import { gql } from "@apollo/client";

import { FINANCIAL_ACCOUNT_FRAGMENT } from "collection/graphql/profit_and_loss/fragments";

export default gql`
  fragment OtherExpenseOrRevenue on ProfitAndLossYearOther {
    accountType
    amountTotal
    cropYear
    name
    accounts {
      account {
        ...Account
      }
      amountTotal
    }
  }
  ${FINANCIAL_ACCOUNT_FRAGMENT}
`;
