import { css } from "aphrodite/no-important";
import PropTypes from "prop-types";
import React from "react";

import styleVars from "components/fl-ui/Layout/Navigation/styleVars";
import styles from "components/fl-ui/Layout/styles/screenStyles";

export const NavigationScreen = ({ state, ...rest }) => (
  <Screen
    {...rest}
    style={{
      ...styleVars.screenDefaultStyle,
      ...styleVars.screenTransitionStyles[state],
    }}
  />
);

const Screen = (props) => <div className={css(styles.screen)} onClick={props.onClick} style={props.style} />;

Screen.propTypes = {
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object.isRequired,
};

export default Screen;
