import { useEffect } from "react";
import router from "router";

import useFeatureRedirect from "hooks/useFeatureRedirect";
import App from "layout/app";
import History from "lib/history";

import useFieldData from "fields/hooks/useFieldData";

const noGeo = (id) => {
  App.confirm({
    title: "Sorry!",
    message:
      "We don't have any boundaries for this field. Click 'Add Boundaries' to be taken to the map where you can add them.",
    confirm: "Add Boundaries",
    warning: false,
  })
    .then(() => router.navigate(`fields/${id}/edit`, { trigger: true }))
    .fail(() => router.navigate("fields", { trigger: true }));
};

const useFieldAccess = (fieldId, url = "fields") => {
  const pathname = History.parseCurrentUrl()?.pathname;
  const { fields, loading } = useFieldData();
  const isOnboarding = fields?.length === 1 && pathname === "/fields/add";
  const skip = !fields?.length || isOnboarding;
  useFeatureRedirect({ feature: "fields", skip });
  const field = fields?.find(({ id }) => id === fieldId);

  useEffect(() => {
    if (fieldId) {
      if (!loading && !field) {
        router.navigate(url, { trigger: true });
      } else if (field && !field.geometry) {
        noGeo(fieldId);
      }
    }
  }, [field, fieldId, loading]);

  return { field, loading };
};

export default useFieldAccess;
