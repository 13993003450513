import { isNative } from "mobile/mobileManager";

/**
 * Logan Johnson pulled this from a Capacitor Issue:
 * https://github.com/ionic-team/capacitor/issues/4178
 */

/**
 *
 * This patches the global document and window event listener functions which are added by Capacitor. The Capacitor
 * functions throw an error if their first argument is `undefined` or `null`.
 *
 * I filed a bug report here: https://github.com/ionic-team/capacitor/issues/4178. When this is addressed, this code can
 * be deleted.
 *
 * The Capacitor (or Cordova) code with the bug in it is here:
 * https://github.com/ionic-team/capacitor/blob/main/core/cordova.js#L122-L158
 *
 * The Capacitor code which copies the `cordova.js` file into our application during build is here:
 * https://github.com/ionic-team/capacitor/blob/f44d51694fdb637904933888837693e5f81716b1/cli/src/cordova.ts#L176
 */
export function mobilePatchCordova() {
  if (!isNative()) {
    return;
  }

  const cordovaDocumentAddEventListener = document.addEventListener;
  const cordovaDocumentRemoveEventListener = document.removeEventListener;
  const cordovaWindowAddEventListener = window.addEventListener;
  const cordovaWindowRemoveEventListener = window.removeEventListener;

  document.addEventListener = function (evt, handler, capture) {
    if (!evt) return;
    cordovaDocumentAddEventListener.call(document, evt, handler, capture);
  };

  window.addEventListener = function (evt, handler, capture) {
    if (!evt) return;
    cordovaWindowAddEventListener.call(window, evt, handler, capture);
  };

  document.removeEventListener = function (evt, handler, capture) {
    if (!evt) return;
    cordovaDocumentRemoveEventListener.call(document, evt, handler, capture);
  };

  window.removeEventListener = function (evt, handler, capture) {
    if (!evt) return;
    cordovaWindowRemoveEventListener.call(window, evt, handler, capture);
  };
}
