import Layers from "map/Layers";
import { getFieldLayers, sanitizeSoilLayers, sanitizeYieldLayers } from "map/layers/utils";
import PropTypes from "prop-types";
import React, { useEffect } from "react";

import History from "lib/history";
import useImageryLayers from "lib/map/imagery/useImageryLayers";
import useSoilLayers from "lib/map/layers/useSoilLayers";
import useYieldLayers from "lib/map/layers/useYieldLayers";
import useYearFieldCrops from "modules/fields/hooks/useYearFieldCrops";

import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import useFieldAccess from "fields/hooks/useFieldAccess";

const CURRENT_YEAR = new Date().getFullYear();

const LayersContainer = (props) => {
  const { crops } = useYearFieldCrops(CURRENT_YEAR);
  const { id: fieldId } = props;
  const { field } = useFieldAccess(fieldId, "map");
  const fieldGeometry = field?.geometry;
  const { imageryLayers, loading } = useImageryLayers({ fieldId, imageType: "NDVI" });
  const { soilLayers } = useSoilLayers({ fieldId, sanitize: sanitizeSoilLayers });
  const { yieldLayers } = useYieldLayers({ fieldId, sanitize: sanitizeYieldLayers });
  const fieldCrops = crops.filter(({ field }) => field.id === fieldId);
  const allLayers = { fieldLayers: getFieldLayers(field, fieldCrops), imageryLayers, soilLayers, yieldLayers };

  useEffect(() => {
    if (fieldGeometry) {
      const url = window.location.hash.split("?")[0];
      History.navigate(url, { replace: true });
    }
  }, [fieldGeometry]);

  return fieldGeometry ? (
    <Layers id={fieldId} geometry={fieldGeometry} allLayers={allLayers} loading={loading} {...props} />
  ) : (
    <LoadingWrapper />
  );
};

LayersContainer.propTypes = {
  id: PropTypes.number.isRequired,
};

export default LayersContainer;
