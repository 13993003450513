import { css } from "aphrodite";
import { isEmpty, upperFirst } from "lodash";
import dollarFormat from "marketing/utils/dollarFormat";
import SummaryTable from "profit_and_loss/components/SummaryTable";
import styles from "profit_and_loss/styles";
import PropTypes from "prop-types";
import React from "react";

import withViewType from "hoc/withViewType";

import CommodityYieldUnit from "components/units/CommodityYieldUnit";

const getLink = (id, { cropYear, marketedCrop }) => {
  const linkMap = {
    crop_sales: isEmpty(marketedCrop)
      ? `/#marketing/${cropYear}`
      : `/#marketing/${cropYear}/${marketedCrop.id}/contracts`,
    other_revenue: "/#other_revenue",
  };

  return linkMap[id];
};

const RevenueSummaryTable = (props) => {
  const { categories = [], commodity, revenuePerAcre, revenuePerUnit, revenueTotal, viewType } = props;

  const data = categories.map(({ id, name, revenuePerAcre, revenuePerUnit, revenuePercentOfTotal, revenueTotal }) => ({
    id,
    label: (
      <a className={css(styles.link)} href={getLink(id, props)}>
        {upperFirst(name)}
      </a>
    ),
    values:
      viewType === "mobile"
        ? [
            revenuePerAcre && `${dollarFormat(revenuePerAcre)} / ac`,
            revenuePercentOfTotal && `${revenuePercentOfTotal}%`,
          ]
        : [
            revenuePerAcre && `${dollarFormat(revenuePerAcre)} / ac`,
            revenuePerUnit && (
              <CommodityYieldUnit commodity={commodity} amount={dollarFormat(revenuePerUnit)} per="unit" />
            ),
            revenueTotal && dollarFormat(revenueTotal),
            revenuePercentOfTotal && `${revenuePercentOfTotal}%`,
          ],
  }));

  const footer = {
    id: "revenueFooter",
    label: "total",
    values:
      viewType === "mobile"
        ? [revenuePerAcre && `${dollarFormat(revenuePerAcre)} / ac`, "100%"]
        : [
            revenuePerAcre && `${dollarFormat(revenuePerAcre)} / ac`,
            revenuePerUnit && (
              <CommodityYieldUnit commodity={commodity} amount={dollarFormat(revenuePerUnit)} per="unit" />
            ),
            revenueTotal && dollarFormat(revenueTotal),
            "100%",
          ],
  };

  const header = {
    id: "revenueHeader",
    label: "category",
    values:
      viewType === "mobile"
        ? ["revenue", "% of all"]
        : ["Revenue per acre", "Revenue per unit", "total revenue", "% of all revenue"],
  };

  return <SummaryTable data={data} footer={footer} header={header} title="Revenue summary" />;
};

RevenueSummaryTable.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object),
  cropYear: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  marketedCrop: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]) }),
  revenuePerAcre: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  revenuePerUnit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  revenueTotal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  commodity: PropTypes.object.isRequired,
  viewType: PropTypes.oneOf(["desktop", "mobile"]),
};

export default withViewType(RevenueSummaryTable);
