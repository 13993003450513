import withAgreementEnums from "agreements/hoc/withAgreementEnums";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";

import { withAgreements, withDeleteAgreements } from "collection/graphql/agreements";
import App from "layout/app";
import AgreementFilterModal from "modules/agreements/components/AgreementFilterModal";
import Dashboard from "modules/agreements/components/Dashboard";
import * as constants from "modules/agreements/constants";
import { getFilteredAgreements, getSearchAgreements, getSortedAgreements } from "modules/agreements/utils";

import LoadingWrapper from "components/fl-ui/LoadingWrapper";

class Index extends Component {
  defaultList = [];

  state = {
    agreements: this.defaultList,
    bulkSelection: [],
    groupedAgreements: null,
    selectedFilters: { fields: [] },
    showFilterModal: false,
    sort: "creationDate",
  };

  componentDidMount() {
    const { agreements, loading } = this.props.data;
    if (!loading && !_.isEmpty(agreements)) {
      this.setDefaultList(agreements);
    }
  }

  componentDidUpdate({ data }) {
    const { agreements, loading } = this.props.data;
    if (!loading && !_.isEqual(data.agreements, agreements)) {
      this.setDefaultList(agreements);
    }
  }

  setDefaultList = (agreements) => {
    this.defaultList = _.sortBy(
      agreements.filter(({ maturityDate }) => moment().diff(moment(maturityDate), "days") < 0),
      [this.state.sort, "id"]
    );

    this.setState({ agreements: this.defaultList });
  };

  getFilteredAgreements = () => {
    if (this.filteredAgreements) return this.filteredAgreements;

    const { selectedFilters } = this.state;
    this.filteredAgreements = getFilteredAgreements(this.getViewableAgreements(), selectedFilters);
    return this.filteredAgreements;
  };

  getFilteredIds = (hasCriteria) =>
    this.setState(({ agreements }) => ({
      bulkSelection: agreements.reduce((acc, agreement) => {
        hasCriteria(agreement) && acc.push(agreement.id);
        return acc;
      }, []),
    }));

  getViewableAgreements = () =>
    this.state.selectedFilters.includeExpired ? this.props.data.agreements : this.defaultList;

  handleFilterUpdate = () => {
    this.filteredAgreements = null;
    const data = getSortedAgreements(this.getFilteredAgreements(), this.state.sort);
    data.showFilterModal = false;

    this.setState(() => data);
  };

  handleSearch = (search) => {
    const data = getSortedAgreements(getSearchAgreements(this.getFilteredAgreements, search), this.state.sort);
    this.setState(() => data);
  };

  handleSort = (sort) => this.setState(({ agreements }) => getSortedAgreements(agreements, sort));

  onBulkDelete = () => {
    const bulkSelection = [...this.state.bulkSelection];
    App.confirm({
      message: "This will delete all selected agreements and all their associated data. This action cannot be undone.",
      title: `Are you sure you want to delete ${bulkSelection.length} agreements?`,
      confirm: "Delete agreements",
    }).then(
      async () => {
        await this.props.deleteAgreements(bulkSelection);
        this.setState({ bulkSelection: [] });
      },
      (error) => console.error(error)
    );
  };

  onBulkSelect = (id) => {
    const { bulkSelection } = this.state;

    if (bulkSelection.includes(id)) {
      this.setState({ bulkSelection: [...bulkSelection].filter((value) => value !== id) });
    } else {
      this.setState({ bulkSelection: [...bulkSelection, id] });
    }
  };

  onFilterChange = (filter) =>
    this.setState(({ selectedFilters }) => ({
      selectedFilters: { ...selectedFilters, ...filter },
    }));

  toggleModal = () => this.setState(({ showFilterModal }) => ({ showFilterModal: !showFilterModal }));

  render() {
    const { agreements, selectedFilters, showFilterModal } = this.state;
    const { agreements: allAgreements, loading } = this.props.data;
    const { includeExpired } = selectedFilters;
    const isLease = ({ agreementType }) => agreementType === constants.LEASE;
    const isLoan = ({ agreementType }) => agreementType === constants.LOAN;
    const isRevenue = ({ agreementType }) => agreementType === constants.REVENUE;

    const bulkSelectionOptions = _.compact([
      { label: "All", onClick: () => this.setState({ bulkSelection: agreements.map((agreement) => agreement.id) }) },
      { label: "None", onClick: () => this.setState({ bulkSelection: [] }) },
      agreements.some(isLoan) && { label: "Loans", onClick: () => this.getFilteredIds(isLoan) },
      agreements.some(isLease) && { label: "Leases", onClick: () => this.getFilteredIds(isLease) },
      agreements.some(isRevenue) && { label: "Non-crop revenue", onClick: () => this.getFilteredIds(isRevenue) },
    ]);

    const hasLeases = _.some(includeExpired ? allAgreements : this.defaultList, { agreementType: constants.LEASE });

    if (loading && !allAgreements) {
      return <LoadingWrapper />;
    }

    return (
      <Fragment>
        <Dashboard
          {...this.state}
          bulkSelectionOptions={bulkSelectionOptions}
          handleFilterUpdate={this.handleFilterUpdate}
          handleSearch={this.handleSearch}
          handleSort={this.handleSort}
          onBulkDelete={this.onBulkDelete}
          onBulkSelect={this.onBulkSelect}
          toggleModal={this.toggleModal}
          totalAgreements={_.size(allAgreements)}
        />

        {showFilterModal && (
          <AgreementFilterModal
            applyFilters={this.handleFilterUpdate}
            fields={_.compact(
              _.uniqBy(
                _.flatMap(this.getViewableAgreements(), ({ fields }) => fields),
                "id"
              )
            )}
            hasLeases={hasLeases}
            onClose={() => this.setState({ selectedFilters: { fields: [] }, showFilterModal: false })}
            onFilterChange={this.onFilterChange}
            resetFilters={() => this.setState({ selectedFilters: { fields: [] } }, this.handleFilterUpdate)}
            selectedFilters={selectedFilters}
          />
        )}
      </Fragment>
    );
  }
}

Index.propTypes = {
  data: PropTypes.shape({
    agreements: PropTypes.arrayOf(PropTypes.object),
    loading: PropTypes.bool,
  }),
};

export default withAgreementEnums(withAgreements(withDeleteAgreements(Index)));
