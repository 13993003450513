import { BaseElement } from "@farmlogs/fl-ui";
import { useDemoContext } from "demo/demos/MobileSubscriptionsDemo/MobileSubscriptionsDemoContext";
import React from "react";

import { Button } from "components/fl-ui";

const DataViewer = () => {
  const { purchases, updateState } = useDemoContext();
  return (
    <BaseElement display="flex" flexWrap="wrap" gap="5px">
      <Button onClick={async () => updateState({ jsonOutput: await purchases.getCustomerInfo() })} size="sm">
        <code>getCustomerInfo()</code>
      </Button>

      <Button onClick={async () => updateState({ jsonOutput: await purchases.getActiveEntitlements() })} size="sm">
        <code>getActiveEntitlements()</code>
      </Button>

      <Button
        onClick={async () => updateState({ jsonOutput: await purchases.syncAttributesAndOfferingsIfNeeded() })}
        size="sm"
      >
        <code>syncAttributesAndOfferingsIfNeeded()</code>
      </Button>
    </BaseElement>
  );
};

export default DataViewer;
