import { gql } from "@apollo/client";

export default gql`
  fragment MachineDataProductMatch on MachineDataProductMatch {
    id
    expectedProduct {
      ...DetailedProduct
    }
    search
    unitType
    matchedProduct {
      ...DetailedProduct
    }
  }
`;
