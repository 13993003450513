import { getCurrentSubscription } from "collection/graphql/subscription";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import usePermissions from "hooks/usePermissions";
import useRestQuery from "hooks/useRestQuery";
import { LITE } from "model/Subscription/constants";

/**
 * Contains the logic to determine whether a contract can be deleted by the current user.
 * @return {boolean}
 */
const useContractPermissions = () => {
  const isLiteSubscription = useRestQuery(getCurrentSubscription).data?.subscription?.currentPlan?.id === LITE;
  const hasContractsFeature = useEnterpriseFeature("contracts");
  const hasMarketingFeature = useEnterpriseFeature("grain_marketing");
  const featurePermissions = usePermissions();

  const canDelete = (contract = null) => {
    return hasContractsFeature && featurePermissions.canDelete("contracts") && contract?.dataSource !== "PARTNER";
  };

  const canRead = () => {
    return hasContractsFeature && featurePermissions.canRead("contracts");
  };

  const canWrite = (contract = {}) => {
    if (!hasContractsFeature || !featurePermissions.canWrite("contracts")) {
      return false;
    } else if (contract && contract.dataSource === "PARTNER" && isLiteSubscription) {
      return false;
    }

    return true;
  };

  const requiresMarketingUpgrade = () => {
    return !hasMarketingFeature;
  };

  return {
    canDelete,
    canRead,
    canWrite,
    requiresMarketingUpgrade,
  };
};

export default useContractPermissions;
