import PropTypes from "prop-types";
import React, { createContext } from "react";

import useAuthContext from "collection/graphql/auth/hooks/useAuthContext";

export const authContext = createContext(undefined);

const AuthContextProvider = ({ children }) => (
  <authContext.Provider value={useAuthContext()}>{children}</authContext.Provider>
);

AuthContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default AuthContextProvider;
