import { gql } from "@apollo/client";

export default gql`
  fragment BasicField on Field {
    acreage
    centroid
    eligibleCarbonClients
    geometry
    geometricCircumference
    group {
      id
      name
      __typename
    }
    id
    isIrrigated
    name
    provider
    source
    __typename
  }
`;
