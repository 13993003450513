import CropSelectionPopup from "activity/components/choosers/CropSelectionMap/components/CropSelectionPopup";
import Pin from "activity/components/choosers/CropSelectionMap/components/Pin";
import context from "activity/components/choosers/CropSelectionMap/context";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { GeoJSONLayer } from "react-mapbox-gl";

import cropColors from "components/fl-ui/colors/crops";

const getCropColor = (field) => {
  const color = field.crops[0]?.commodity?.color ?? "black";
  return cropColors.styles[color];
};

const FieldLayer = (props) => {
  const { addCrop, removeCrop, selectedCrops } = useContext(context);
  const { field } = props;
  const { centroid, crops, geometry } = field;
  const [showCropMenu, setShowCropMenu] = useState(false);
  const cropIds = _.map(crops, "id");
  const isSelected = showCropMenu || _.some(selectedCrops, ({ id }) => cropIds.includes(id));

  const { fill, stroke } = getCropColor(field);
  const paint = {
    "fill-color": fill,
    "fill-opacity": 0.8,
    "fill-outline-color": stroke,
  };
  const outlinePaint = {
    "line-color": "#fff",
    "line-opacity": 0.8,
    "line-width": 4,
  };

  const setCursorTo =
    (value) =>
    ({ target }) =>
      (target.getCanvas().style.cursor = value);

  const handleFieldClick = () => {
    if (crops.length > 1) {
      /*
       * There is a bug here where clicking on the field while the CropSelectionPopup
       * is open does not close the popup as expected due to the onClose callback calling
       * setShowCropMenu(false).
       */
      setShowCropMenu(!showCropMenu);
    } else if (isSelected) {
      removeCrop(..._.map(crops, "id"));
      setShowCropMenu(false);
    } else if (crops.length === 1) {
      addCrop(crops[0].id);
    }
  };

  return (
    <>
      <GeoJSONLayer
        data={geometry}
        fillPaint={paint}
        fillOnMouseEnter={setCursorTo("pointer")}
        fillOnMouseLeave={setCursorTo("")}
        fillOnClick={handleFieldClick}
      />

      {isSelected && (
        <>
          <GeoJSONLayer data={geometry} linePaint={outlinePaint} />
          <Pin coordinates={centroid.coordinates} />

          {showCropMenu && <CropSelectionPopup field={field} onClose={() => setShowCropMenu(false)} />}
        </>
      )}
    </>
  );
};

FieldLayer.propTypes = {
  field: PropTypes.object.isRequired,
};

export default FieldLayer;
