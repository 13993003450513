import eventFactory from "lib/metrics/event_factory";

export const LOGIN_FORGOT_PASSWORD_PRESS = eventFactory("Forgot Password Press", {
  context: "Login",
});

export const LOGIN_LOGIN = eventFactory("Login", {
  context: "Login",
  type: "Email",
  dynamic: ["email", "outcome"],
});

export const LOGIN_SIGNUP = eventFactory("Signed Up", {
  context: "Login",
  type: "Email",
  dynamic: ["email", "outcome"],
});
