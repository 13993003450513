import d3 from "d3";
import leaflet from "leaflet";

import CanvasLayer from "lib/map/canvas_layer";
import Colorizer from "lib/workers/colorizer";

import { getIconPath } from "components/field/utils";

const colorizer = new Colorizer({
  domain: [0, 1, 43, 85, 127, 169, 211, 255],
  range: ["#CC4033", "#CC4033", "#F56B3D", "#F5993D", "#FEE067", "#70C285", "#45A15C", "#2E6B3D"],
  grayscale: true,
  interpolate: true,
});

class Yield extends CanvasLayer {
  constructor(layer) {
    const [lng1, lat1] = Array.from(layer.details.top_left);
    const [lng2, lat2] = Array.from(layer.details.bottom_right);

    const bounds = {
      top_left: leaflet.latLng(lat1, lng1),
      bottom_right: leaflet.latLng(lat2, lng2),
    };

    super(bounds);

    this.layer = layer;
    this.min = layer?.details?.min_interpolated_yield;
    this.max = layer?.details?.max_interpolated_yield;
    this.scaleBounds = leaflet.latLngBounds(leaflet.latLng(lat2, lng1), leaflet.latLng(lat1, lng2));
    this.setLayer(layer);
  }

  getColorizer() {
    return colorizer;
  }

  getLegend() {
    const format = (value) => d3.format("g")(d3.round(value));
    const interval = (this.max - this.min) / 5;
    const ticks = d3.range(6).map((i) => format(this.min + interval * i));
    const width = 100 / 5.25;

    return {
      src: colorizer.getLegend(width),
      ticks,
    };
  }

  getURL() {
    const url = this.layer.details?.url;
    return url ? new URL(this.layer.details?.url).pathname : "";
  }

  setZoom(zoom) {
    this.zoom = zoom;
    return this;
  }

  setLayer(layer) {
    this.layer = layer;
    return this;
  }

  onAdd() {
    super.onAdd(...arguments);
    this.render();
  }

  render() {
    if (this.layer) {
      return super.render(this.getURL());
    }
  }

  renderComposite(options) {
    options.url = this.getURL();
    options.background = getIconPath(this.layer.field, options);
    return super.renderComposite(options);
  }
}

export default Yield;
