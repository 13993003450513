import chooserStyles from "activity/components/choosers/FieldCropChooser/chooserStyles";
import useActivitiesProvider from "activity/hooks/useActivitiesProvider";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import ReactSelect from "react-select";

const ActivityProductChooser = (props) => {
  const { onChange, value } = props;

  const { allActivities } = useActivitiesProvider();
  const allUsedProducts = useMemo(() => {
    const allProducts = _.map(_.flatMapDeep(allActivities, "inputs"), "product");
    const uniqueProducts = _.uniqBy(allProducts, "id");
    const sortedProducts = _.sortBy(uniqueProducts, ({ name }) => name.toLowerCase());

    return sortedProducts;
  }, [allActivities]);

  const componentValue = value.map((id) => _.find(allUsedProducts, { id })).filter((x) => x);

  return (
    <ReactSelect
      getOptionLabel={_.property("name")}
      getOptionValue={_.property("id")}
      isMulti
      onChange={(selectedOptions) => {
        onChange(selectedOptions ? _.map(selectedOptions, "id") : []);
      }}
      options={allUsedProducts}
      styles={chooserStyles}
      value={componentValue}
    />
  );
};

ActivityProductChooser.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default ActivityProductChooser;
