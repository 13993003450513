import CropSelectionMap from "activity/components/choosers/CropSelectionMap";
import PropTypes from "prop-types";
import React from "react";

const MapChooser = (props) => {
  const { fieldCrops, fields, fieldCropSelection, menuControl, onChange } = props;

  return (
    <CropSelectionMap
      fieldCrops={fieldCrops}
      fields={fields}
      menuControl={menuControl}
      onCropChange={onChange}
      onComplete={() => menuControl.close()}
      selectedCrops={fieldCropSelection}
    />
  );
};

MapChooser.propTypes = {
  fieldCrops: PropTypes.arrayOf(PropTypes.object).isRequired,
  fieldCropSelection: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.object])).isRequired,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  menuControl: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MapChooser;
