import _ from "lodash";
import { useCallback } from "react";

import updateUserConfig from "collection/graphql/config/mutations/updateUserConfig";
import getUserConfig from "collection/graphql/config/queries/getUserConfig";
import UserConfigSchema from "collection/graphql/config/schemas/UserConfigSchema";
import useRestMutation from "hooks/useRestMutation";
import useRestSuspenseQuery from "hooks/useRestSuspenseQuery";

const useUserConfig = () => {
  const { userConfig } = useRestSuspenseQuery(getUserConfig).data;
  const updateConfig = useRestMutation(updateUserConfig)[0];

  const save = async (newConfig) => {
    const input = UserConfigSchema.cast(newConfig, { stripUnknown: true });

    return updateConfig({
      optimisticResponse: { userConfig: { ...input, __typename: "UserConfig" } },
      variables: { input },

      update(cache, { data }) {
        cache.writeQuery({ data, query: getUserConfig });
      },
    });
  };

  /**
   * Manages configuration settings by getting, setting, or merging values.
   * - If no arguments are provided the userConfig object is returned.
   * - If a single string argument is provided, returns the value for the given key.
   * - If an object is provided as the first argument, it merges this object with the configuration.
   * - If a key-value pair is provided, it sets or updates the configuration with this pair.
   * - If a key and a null value are provided, it deletes the entry for the key.
   *
   * @param {keyof typeof UserConfig|UserConfig} key - The configuration key to get/set, or an object to merge.
   * @param {*} [value] - The value to set for the key. If `null`, the key is deleted.
   * @returns {UserConfig|undefined} The {UserConfig} if no arguments are passed
   *
   * @example
   * const userConfig = useUserConfig();
   *
   * const activitySort = userConfig("activitySortCriteria");   // read
   * userConfig("activitySortCriteria", "date");    // sets userConfig.activitySortCriteria equal to "date" and saves it
   * userConfig({ activitySortCriteria: "date" });  // sets userConfig.activitySortCriteria equal to "date" and saves it
   * userConfig(activitySortCriteria, null);        // unsets userConfig.activitySortCriteria and returns it to the default value
   */
  const manageConfig = useCallback(
    function (key, value) {
      if (arguments.length === 0) {
        return userConfig;
      }

      // Directly return the value for a single string argument
      if (arguments.length === 1 && typeof key === "string") {
        return userConfig[key];
      }

      /** @type {UserConfig} */
      const newConfig = _.cloneDeep(userConfig);
      if (_.isPlainObject(key)) {
        // Merge object with the configuration
        Object.assign(newConfig, key);
      } else if (arguments.length === 2) {
        if (_.isNil(value)) {
          // Delete the configuration entry for the key
          delete newConfig[key];
        } else {
          // Set or update the configuration
          newConfig[key] = value;
        }
      }

      save(newConfig);
    },
    [userConfig]
  );

  return manageConfig;
};

export default useUserConfig;
