import { gql } from "@apollo/client";

import { MachineDataProductMatch } from "collection/graphql/integrations/fragments";

export default gql`
  query getMachineDataProductMatches {
    allMatches: getMachineDataProductMatches {
      ...MachineDataProductMatch
    }
    providers: getIntegrations {
      id
      enabled
      name
      provider
      type
    }
  }
  ${MachineDataProductMatch}
`;
