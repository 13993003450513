import Index from "./routes/index";
import { FUTURES_CONTRACT, FUTURES_PRICES, LOCAL_BIDS } from "prices/constants";

import App from "layout/app";
import SubRoute from "lib/SubRoute";

const PricesRouter = SubRoute.extend({
  routes: {
    "": "index",
    futures: "futures",
    "futures/contract/:symbol": "futuresContract",
    local: "local",
    "local/buyers/:id": "buyer",
    commentary: "commentary",
  },

  display(screenName, view, props = {}) {
    App.display(Index, {
      router: this,
      screenName,
      view,
      year: new Date().getFullYear(),
      ...props,
    });
  },

  buyer(buyerId) {
    return this.display("Local Prices", LOCAL_BIDS, { buyerId: +buyerId });
  },

  index() {
    return this.display("Market Prices", FUTURES_PRICES);
  },

  futuresContract(symbol) {
    return this.display("Market Prices", FUTURES_CONTRACT, { symbol });
  },

  local() {
    return this.display("Local Prices", LOCAL_BIDS);
  },

  futures() {
    return this.display("Market Prices", FUTURES_PRICES);
  },

  commentary() {
    return this.navigate("", { replace: true, trigger: true });
  },
});

export default () => new PricesRouter("prices", { createTrailingSlashRoutes: true });
