// plans
export const STANDARD = "standard";
export const ESSENTIALS = "essentials";
export const PREMIUM = "premium";
export const BASIC_ACTIVITIES = "basic_activities";
export const CUSTOM = "custom";
export const ELEVATE_PLUS = "elevate_plus";
export const ELEVATE_PLUS_BUSINESS = "elevate_plus_business";
export const ENTERPRISE = "enterprise";
export const LEGACY = "legacy";
export const LITE = "lite";
export const RAINFALL = "rainfall";
export const PLANS = [
  BASIC_ACTIVITIES,
  CUSTOM,
  ELEVATE_PLUS,
  ELEVATE_PLUS_BUSINESS,
  ENTERPRISE,
  ESSENTIALS,
  LEGACY,
  LITE,
  PREMIUM,
  RAINFALL,
  STANDARD,
];

// addons
export const ACTIVITIES = "activities";
export const BASIC_IMAGERY = "imagery_tier1";
export const ELEVATE_PLUS_ADDON = "elevate_plus_add_on";
export const MARKETING = "marketing";
export const REPORTING = "reports";
export const WEATHER = "weather";
export const WORK_ORDERS = "work_orders";
export const ADDONS = [ACTIVITIES, BASIC_IMAGERY, ELEVATE_PLUS_ADDON, MARKETING, REPORTING, WEATHER, WORK_ORDERS];
