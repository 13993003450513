/* eslint-disable react/display-name */
import { css } from "aphrodite";
import EnrollmentForm from "carbon_enrollment/components/EnrollmentForm";
import { StyledIcon, StyledIconsContainer } from "carbon_enrollment/components/styledComponents";
import { isNil } from "lodash";
import React from "react";

import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import useCarbonEnrollment from "modules/carbon_enrollment/hooks/useCarbonEnrollment";
import styles from "modules/carbon_enrollment/styles";
import { isValid, TOOLTIP_MESSAGE } from "modules/carbon_enrollment/utils";

import { Icon, Logo } from "components/fl-ui/Icons";
import Tooltip from "components/fl-ui/Tooltip";

const clientData = {};
clientData.clientName = "Nori";
clientData.confirmationCopy = (
  <>
    {"For the fields you are choosing to send to Nori, please indicate if you have already made a "}
    <span className={css(styles.blueText)}>
      qualifying practice change
      <Tooltip message={TOOLTIP_MESSAGE}>
        <Icon icon="help" className={css(styles.helpIcon)} />
      </Tooltip>
    </span>
    in the last 10 years or intend to make one in the coming season(s).
  </>
);

clientData.headerCopy = (
  <>
    {"The Nori program is open for enrollment to growers who have added carbon farming practices "}
    {"in the last 10 years. If you haven’t but are considering a qualifying practice "}
    {"change, we’d be glad to help you "}
    <a
      className={css(styles.blueText)}
      href="https://nori.com/integrations/farmlogs?utm_source=farmlogs"
      rel="noopener noreferrer"
      target="_blank"
    >
      learn more
    </a>
    {" for next season."}
  </>
);

clientData.headerTitle = "Sign up to generate carbon credits through our partnership with Nori!";

clientData.LogoComponent = () => (
  <StyledIconsContainer>
    <Logo size="sm" />
    <StyledIcon icon="add" />
    <StyledIcon icon="noriLogo" />
  </StyledIconsContainer>
);

clientData.nextSteps = (
  <>
    <p className={css(styles.copy)}>
      Once you complete this page, Bushel Farm will securely send your information to Nori. Here is what you can expect:
    </p>

    <ol className={css(styles.orderedList)}>
      <li className={css(styles.copy, styles.list)}>
        A member of the Nori team will create an account on your behalf with your Bushel Farm data. Expect to receive an
        email with a scheduling link to set up a call with a Nori Supply Account Manager within a week.
      </li>
      <li className={css(styles.copy, styles.list)}>
        Once your Bushel Farm records are in your account, the Nori team will reach out to you to discuss how your
        operation fits in the Nori carbon market, answer questions, and what to expect with the enrollment process. Nori
        will work with you to complete your records for the program, run carbon credit projections, and make a decision
        on verification.
      </li>
      <li className={css(styles.copy, styles.list)}>
        After your project is verified, Nori will provide a contract for you to review and sign. You will be issued
        credits on the fields where practice changes have been made in the last 10 years.
      </li>
    </ol>
  </>
);

clientData.radios = [
  {
    label: "", // needs to be replaced dynamically in the NoriEnrollment component
    value: "true",
  },
  { label: "I look forward to making a first practice change in the upcoming growing season", value: "false" },
];

const NoriEnrollment = () => {
  const enrollment = useCarbonEnrollment("nori");
  const { data, isSaving, loading, selectedFields } = enrollment;
  const disabled = !isValid(data, selectedFields) || isSaving;
  const hasCarbonFeature = useEnterpriseFeature("nori_carbon");
  const isLoading = loading || !data || isNil(hasCarbonFeature);

  // This needs to be done here for unit testing purposes
  const tenYearsAgo = new Date().getFullYear() - 10;
  clientData.radios[0].label = `I have already made a qualifying practice change on one or more of the selected field(s) since ${tenYearsAgo}`;

  return (
    <EnrollmentForm
      clientData={clientData}
      disabled={disabled}
      enrollment={enrollment}
      isEligible={!hasCarbonFeature}
      isLoading={isLoading}
    />
  );
};

export default NoriEnrollment;
