import PrimaryPlan from "billing/components/PlanLists/PrimaryPlan";
import { useBillingProducts } from "billing/providers/BillingProductsProvider";
import React from "react";
import styled from "styled-components";

const PrimaryPlanList = styled(({ className }) => (
  <div className={className}>
    {useBillingProducts().primaryPlans.map((plan) => {
      return <PrimaryPlan key={plan.id} plan={plan} />;
    })}
  </div>
))`
  align-items: stretch;
  display: flex;
  gap: 32px;
  justify-content: space-between;

  > * {
    flex: 1;
  }

  @media only screen and (max-width: 62.5rem) {
    overflow-x: scroll;
  }
`;

export default PrimaryPlanList;
