import { useMutation } from "@apollo/client";
import _ from "lodash";
import { useCallback } from "react";

import ImplementFragment from "collection/graphql/equipment/fragments/ImplementDetailFragment";
import createImplementMutation from "collection/graphql/equipment/mutations/createImplement";
import deleteImplementMutation from "collection/graphql/equipment/mutations/deleteImplement";
import updateImplementMutation from "collection/graphql/equipment/mutations/updateImplement";
import getAllMaintenanceRecords from "collection/graphql/equipment/queries/getAllMaintenanceRecords";

const useImplementMutations = () => {
  const create = useMutation(createImplementMutation)[0];
  const [
    destroy,
    {
      client: { cache },
    },
  ] = useMutation(deleteImplementMutation);
  const update = useMutation(updateImplementMutation)[0];

  const createImplement = useCallback(async (input) => {
    const result = await create({
      update(cache, { data }) {
        const { implement } = data.createImplement;

        cache.modify({
          fields: {
            getImplements: (existingTractors = []) => {
              const newImplement = cache.writeFragment({
                id: cache.identify(implement),
                data: implement,
                fragment: ImplementFragment,
              });

              return [...existingTractors, newImplement];
            },
          },
        });
      },

      variables: {
        input,
      },
    });

    return result.data.createImplement.implement;
  }, []);

  const deleteImplement = useCallback(async (id) => {
    await destroy({
      onCompleted() {
        // any relevant maintenance records need to be manually deleted
        const cachedRecords = cache.readQuery({
          query: getAllMaintenanceRecords,
        })?.maintenanceRecords;

        const recordsForDeletedImplement = _.filter(cachedRecords, {
          equipmentId: id,
        });

        recordsForDeletedImplement.forEach(({ id }) => {
          cache.evict({
            id: cache.identify({ id, __typename: "MaintenanceRecord" }),
          });
        });

        if (recordsForDeletedImplement.length > 0) {
          cache.gc();
        }
      },

      refetchQueries: ["getAllMaintenanceRecords"],

      update(cache) {
        cache.evict({
          id: cache.identify({ id, __typename: "Implement" }),
        });
        cache.gc();
      },

      variables: {
        id,
      },
    });
  }, []);

  const saveImplement = useCallback(async (input) => {
    return input.id ? updateImplement(input) : createImplement(input);
  }, []);

  const updateImplement = useCallback(async (input) => {
    await update({
      optimisticResponse: ({ input }) => ({
        updateImplement: {
          ok: true,
          implement: input,
        },
      }),
      variables: {
        input,
      },
    });
  }, []);

  return {
    createImplement,
    deleteImplement,
    saveImplement,
    updateImplement,
  };
};

export default useImplementMutations;
