import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import ReactMarkdown from "react-markdown";
import remarkDirective from "remark-directive";
import remarkDirectiveRehype from "remark-directive-rehype";
import styled from "styled-components";

import useLinkClick from "components/fl-ui/hooks/useLinkClick";

const Anchor = ({ children, href }) => {
  const { onClick } = useLinkClick({ href });
  return (
    <a href={href} onClick={onClick}>
      {children}
    </a>
  );
};

/**
 * Builds a props object to be passed to a react-markdown instance.
 *
 * @param {Object} props
 * @param {Object} [props.config={}] an optional config object
 * @param {String} props.message a markdown message to be parsed
 * @see https://github.com/remarkjs/react-markdown
 * @see https://github.com/remarkjs/remark-directive
 * @see https://github.com/IGassmann/remark-directive-rehype
 */
export const useBannerMarkdownProps = (props) => {
  const { config, message } = props;

  return {
    allowedElements: ["a", "em", "p", "strong", "time-until"],
    children: message,
    components: {
      a: Anchor,

      /**
       * Outputs a formatted datetime for a timestamp. If the time is less than one
       * day in the future just the time is returned.  If it's in more than one day or
       * in the past the full date and time are returned.
       *
       * @param {Object} syntaxTree
       * @return {String}
       */
      "time-until": ({ configKey }) => {
        if (configKey in config) {
          const date = moment(config[configKey]);
          if (date.isValid()) {
            const daysUntil = moment.duration(date.diff(moment())).asDays();
            const timestampFormat = daysUntil >= 0 && daysUntil <= 1 ? "h:mma" : "h:mma on MMMM Do";
            return <span>{date.local().format(timestampFormat)}</span>;
          }
        }

        return "";
      },
    },
    remarkPlugins: [remarkDirective, remarkDirectiveRehype],
    skipHtml: true,
  };
};

const PrimaryBannerMessage = styled((props) => {
  const { className, id } = props;
  const markdownProps = useBannerMarkdownProps(props);

  return !id ? null : (
    <div className={className}>
      <ReactMarkdown {...markdownProps} />
    </div>
  );
})`
  ${(props) => props.bannerRouteId && "cursor: pointer;"}
`;

PrimaryBannerMessage.propTypes = {
  bannerRouteId: PropTypes.string,
  config: PropTypes.object,
  id: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onLinkClick: PropTypes.func,
};

PrimaryBannerMessage.defaultProps = {
  config: {},
  onLinkClick: () => {},
};

export default PrimaryBannerMessage;
