import unitsOfMeasure, { unitByLegacyValue } from "collection/graphql/units/unitsOfMeasure";

/**
 * Normalizes an ingredient's amount unit.
 */
function normalizeRecipeUnit(unitToConvert) {
  if (unitToConvert in unitsOfMeasure) {
    return unitsOfMeasure[unitToConvert].value;
  } else {
    const legacyUnit = unitByLegacyValue(unitToConvert);
    if (legacyUnit) {
      return legacyUnit.value;
    }
  }

  throw new Error(`Unexpected product recipe unit "${unitToConvert}"`);
}

export default normalizeRecipeUnit;
