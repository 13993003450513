import React from "react";

import LocalPricesBlankSlateBanner from "components/advertisements/banners/LocalPricesBlankSlateBanner";
import PayGateCommonBlankStatePage from "components/advertisements/pages/PayGateCommonBlankStatePage";

const LocalPricesBlankStatePage = () => {
  const steps = [
    "Select counties and delivery windows",
    "See sorted bids based on price & distance",
    "Click any location to see all posted bids",
  ];

  return (
    <div>
      <PayGateCommonBlankStatePage banner={LocalPricesBlankSlateBanner} steps={steps} />
    </div>
  );
};

export default LocalPricesBlankStatePage;
