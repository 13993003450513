import React, { useState } from "react";

import { useAuth } from "collection/graphql/auth";
import LoginForm from "layout/components/LoginForm";
import { getEmailErrors, hasErrors } from "layout/utils";
import { LOGIN_FORGOT_PASSWORD_PRESS, LOGIN_LOGIN } from "lib/metrics/events";

const trackReset = () => LOGIN_FORGOT_PASSWORD_PRESS.track();

const defaultValues = {
  email: "",
  error: "",
  loading: false,
  password: "",
};

const Login = () => {
  const { login } = useAuth();
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState(defaultValues);
  const [obfuscatePassword, setObfuscatePassword] = useState(true);

  const handleChange = (e, formData) => setFormValues({ ...formValues, ...formData });

  const handleLogin = ({ email, password }) => {
    const formValues = { email, password };
    const formErrors = getEmailErrors(formValues);
    setFormErrors(formErrors);

    if (hasErrors(formErrors)) {
      return null;
    }

    setFormValues({ ...formValues, error: "", loading: true });

    return login({ email, password }).catch((error) => {
      const errorMessage = "Sorry, we couldn't log you in. Please check your login credentials and try again.";

      LOGIN_LOGIN.track({ email, outcome: "failure" });
      setFormValues({ ...formValues, error: errorMessage, loading: false });
      return Promise.reject(error);
    });
  };

  const handlePasswordFieldToggle = () => setObfuscatePassword(!obfuscatePassword);

  return (
    <LoginForm
      formErrors={formErrors}
      formValues={formValues}
      handleChange={handleChange}
      handleLogin={handleLogin}
      handlePasswordFieldToggle={handlePasswordFieldToggle}
      obfuscatePassword={obfuscatePassword}
      trackReset={trackReset}
    />
  );
};

export default Login;
