import { css, StyleSheet } from "aphrodite/no-important";
import { CellRow } from "marketing/components/ui-elements/Cell";
import React from "react";

import { Mixins, Typography } from "components/fl-ui/constants";

const styles = StyleSheet.create({
  detailCard_cellRow: {
    margin: `${Mixins.toRem(8)} 0`,
    fontSize: Typography.sizes.rg,
  },
  detailCard_valueCellRow: {
    fontSize: Typography.sizes.rg,
    lineHeight: "2em",
  },
});

const DetailedCellRow = (props) => <CellRow className={css(styles.detailCard_cellRow)} {...props} />;
const DetailedRow = (props) => <div className={css(styles.detailCard_valueCellRow)}>{props.children}</div>;
const DetailedRowBody = (props) => (
  <div>
    <strong>{props.children}</strong>
  </div>
);

export { DetailedCellRow, DetailedRow, DetailedRowBody };
