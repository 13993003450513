import { BaseElement } from "@farmlogs/fl-ui";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";

import { Spacing } from "components/fl-ui/constants";

/*
 * YieldUnit child components
 */
const DefaultAcre = () => <span className="acre">ac</span>;
const DefaultAmount = ({ amount }) => <span className="amount">{amount}</span>;
const DefaultSeparator = ({ separatorType }) => <span className={`separator separator-${separatorType}`}>/</span>;
DefaultSeparator.propTypes = {
  separatorType: PropTypes.oneOf(["acre", "unit"]),
};
const DefaultUnit = ({ unit }) => <span className="unit">{unit}</span>;

const Unit = ({ amount, commodity, components = {}, defaultUnit, per, unit, ...rest }) => {
  /*
   * Determine the child components
   */
  const Acre = components.Acre ?? DefaultAcre;
  const Amount = components.Amount ?? DefaultAmount;
  const Separator = components.Separator ?? DefaultSeparator;
  const Unit = components.Unit ?? DefaultUnit;

  /*
   * BaseElement props
   */
  const styledProps = {
    display: "inline-flex",
    gridGap: Spacing.xxsmall,
    ...rest,
  };

  return (
    <BaseElement {...styledProps}>
      {!_.isNil(amount) && <Amount amount={amount} />}
      {per === "unit" && <Separator separatorType="unit" />}
      <Unit unit={unit} />
      {per === "acre" && (
        <>
          <Separator separatorType="acre" />
          <Acre />
        </>
      )}
    </BaseElement>
  );
};

Unit.propTypes = {
  amount: PropTypes.any,
  as: PropTypes.string,
  components: PropTypes.shape({
    Acre: PropTypes.func,
    Amount: PropTypes.func,
    Separator: PropTypes.func,
    Unit: PropTypes.func,
  }),
  per: PropTypes.oneOf(["acre", "unit"]),
  unit: PropTypes.any,
};

Unit.defaultProps = {
  as: "span",
};

export default Unit;
