import eventFactory from "lib/metrics/event_factory";

export const MARKETING_TOTAL_FARM_VIEW = eventFactory("Total Farm Overview", {
  context: "Total Farm Overview",
  entity: "Total Farm Overview",
});

export const MARKETING_COMMODITY_PRICES_VIEW = eventFactory("Commodity Prices", {
  context: "Commodity Prices",
  entity: "Commodity Prices",
});

export const MARKETING_ONBOARDING_ADD_CROP = eventFactory("Marketing: Create Marketing Crop", {
  context: "Onboarding Info",
  entity: "Add Crop",
  dynamic: ["modal_name", "crop_year"],
});

export const MARKETING_MODAL_CASH_OPEN = eventFactory("Modal Open - Cash Contract", {
  context: "Commodity Details",
  entity: "Cash Contract",
  dynamic: ["commodity_id", "commodity_year"],
});

export const MARKETING_MODAL_CASH_SAVE = eventFactory("Modal Save - Cash Contract", {
  context: "Commodity Details",
  entity: "Cash Contract",
  dynamic: [
    "contract_id",
    "commodity_id",
    "commodity_year",
    "contract_type",
    "contract_status",
    "contract_date",
    "contract_quantity",
    "futures_price",
    "contract_basis",
    "contract_type",
    "reference_contract",
    "contract_delivery",
  ],
});

export const MARKETING_MODAL_CASH_DELETE = eventFactory("Modal Delete - Cash Contract", {
  context: "Commodity Details",
  entity: "Cash Contract",
  dynamic: ["contract_id", "commodity_id", "commodity_year"],
});

export const MARKETING_MODAL_CASH_CANCEL = eventFactory("Modal Cancel - Cash Contract", {
  context: "Commodity Details",
  entity: "Cash Contract",
  dynamic: ["commodity_id", "commodity_year"],
});

export const MARKETING_MODAL_FUTURES_OPEN = eventFactory("Modal Open - Futures Contract", {
  context: "Commodity Details",
  entity: "Futures Contract",
  dynamic: ["commodity_id", "commodity_year"],
});

export const MARKETING_MODAL_FUTURES_SAVE = eventFactory("Modal Save - Futures Contract", {
  context: "Commodity Details",
  entity: "Futures Contract",
  dynamic: [
    "contract_id",
    "commodity_id",
    "commodity_year",
    "contract_type",
    "contract_status",
    "contract_date",
    "contract_quantity",
    "futures_price",
    "contract_basis",
    "contract_type",
    "reference_contract",
    "contract_delivery",
  ],
});

export const MARKETING_MODAL_FUTURES_DELETE = eventFactory("Modal Delete - Futures Contract", {
  context: "Commodity Details",
  entity: "Futures Contract",
  dynamic: ["contract_id", "commodity_id", "commodity_year"],
});

export const MARKETING_MODAL_FUTURES_CANCEL = eventFactory("Modal Cancel - Futures Contract", {
  context: "Commodity Details",
  entity: "Futures Contract",
  dynamic: ["contract_id", "commodity_id", "commodity_year"],
});

export const MARKETING_MODAL_OPTIONS_OPEN = eventFactory("Modal Open - Options Contract", {
  context: "Commodity Details",
  entity: "Options Contract",
  dynamic: ["commodity_id", "commodity_year"],
});

export const MARKETING_MODAL_OPTIONS_SAVE = eventFactory("Modal Save - Options Contract", {
  context: "Commodity Details",
  entity: "Options Contract",
  dynamic: [
    "contract_id",
    "commodity_id",
    "commodity_year",
    "contract_type",
    "contract_status",
    "contract_date",
    "contract_quantity",
    "futures_price",
    "contract_basis",
    "contract_type",
    "reference_contract",
    "contract_delivery",
  ],
});

export const MARKETING_MODAL_OPTIONS_DELETE = eventFactory("Modal Delete - Options Contract", {
  context: "Commodity Details",
  entity: "Options Contract",
  dynamic: ["contract_id", "commodity_id", "commodity_year"],
});

export const MARKETING_MODAL_OPTIONS_CANCEL = eventFactory("Modal Cancel - Options Contract", {
  context: "Commodity Details",
  entity: "Options Contract",
  dynamic: ["contract_id", "commodity_id", "commodity_year"],
});
