import React from "react";
import styled from "styled-components";

import { Button } from "components/fl-ui";
import { GreyColors } from "components/fl-ui/colors";

const ContractsModalClearButton = styled(({ text, className, onClick }) => (
  <Button className={className} color="danger" onClick={onClick} hollow type="button">
    {text}
  </Button>
))`
  border: 1px solid ${GreyColors.smoke90} !important;
`;

export default ContractsModalClearButton;
