import { CardHeading, StyledCard } from "profit_and_loss/styles";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { Cluster, Stack } from "components/fl-ui/Layout";
import { Spacing, Typography } from "components/fl-ui/constants";

export const ProductCardBody = styled.div`
  font-size: ${Typography.sizes.lg};
  font-weight: ${Typography.weights.medium};
`;

export const ProductCardHeading = ({ children }) => <CardHeading as="h3">{children}</CardHeading>;

export const ProductCardFooter = styled(Cluster)`
  font-size: ${Typography.sizes.rg};
`;

const ProductCard = (props) => {
  const { body, children, footer, heading, onCardClick } = props;

  return (
    <StyledCard onClick={onCardClick}>
      <Stack space={Spacing.xsmall}>
        {children || (
          <>
            <ProductCardHeading>{heading}</ProductCardHeading>
            <ProductCardBody>{body}</ProductCardBody>
            <ProductCardFooter between>{footer}</ProductCardFooter>
          </>
        )}
      </Stack>
    </StyledCard>
  );
};

ProductCard.propTypes = {
  body: PropTypes.any,
  children: PropTypes.any,
  footer: PropTypes.any,
  heading: PropTypes.any,
  onCardClick: PropTypes.func,
};

ProductCard.defaultProps = {
  onCardClick: () => {},
};

export default ProductCard;
