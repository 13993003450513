import { isNil } from "lodash";
import moment from "moment";
import numeral from "numeral";
import ProductActivityCard from "products/containers/ProductActivityCard";
import ProductPurchaseCard from "products/containers/ProductPurchaseCard";
import { Table } from "profit_and_loss/styles";
import PropTypes from "prop-types";
import React from "react";
import router from "router";
import styled from "styled-components";

import useViewType from "hooks/useViewType";

import { UIColors } from "components/fl-ui/colors";
import { Typography } from "components/fl-ui/constants";
import ProductAmountRemainingUnit from "components/product/ProductAmountRemainingUnit";
import ProductApplyUnit from "components/product/ProductApplyUnit";
import ProductPurchaseUnit from "components/product/ProductPurchaseUnit";

const typeCopyMap = {
  ChemicalProduct: "Chem",
  FertilizerProduct: "Fert",
  SeedProduct: "Seed",
  WaterProduct: "Water",
};

const ClickableCell = styled.td`
  cursor: pointer;

  ${(props) =>
    props.primary &&
    `
    color: ${UIColors.blue};
    font-weight: ${Typography.weights.bold} !important;
    text-align: left !important;
  `}

  a {
    color: ${UIColors.blue};
  }
`;

const PurchaseSummaryRow = ({ summary, onCreatePurchase }) => {
  const formatAmount = (amount) => (!Math.abs(amount) > 0 ? "--" : numeral(amount).format("0,0[.]0"));
  const cost = (value) => (value ? numeral(value).format("$0,0.00") : "--");
  const viewProduct = ({ id }) => router.navigate(`products/${id}`, { trigger: true });

  const remainingAmount =
    isNil(summary.amountRemainingTotal) || summary.amountRemainingTotal === 0
      ? null
      : numeral(summary.amountRemainingTotal).format("0,0[.]0");

  const handleRowClick = (event) => {
    if (!event.isDefaultPrevented()) {
      if (summary.purchases.length > 0) {
        viewProduct(summary.product);
      } else {
        const activityId = summary.activityInputs[0].activity.id;
        router.navigate(`activity/${activityId}`, { trigger: true });
      }
    }
  };

  const handlePurchaseCreateClick = (event) => {
    event.preventDefault();
    onCreatePurchase({ product: summary.product });
  };

  return (
    <tr onClick={handleRowClick}>
      <ClickableCell primary>{summary.product.name}</ClickableCell>
      <ClickableCell>{typeCopyMap[summary.product.__typename]}</ClickableCell>
      <ClickableCell>
        {summary.lastPurchaseDate && moment(summary.lastPurchaseDate).format("MM/DD/YYYY")}
        {!summary.lastPurchaseDate && (
          <a href="#" onClick={handlePurchaseCreateClick}>
            Log purchase
          </a>
        )}
      </ClickableCell>
      <ClickableCell>
        <ProductPurchaseUnit source={summary} amount={formatAmount(summary.amountPurchasedTotal)} />
      </ClickableCell>
      <ClickableCell>
        <ProductApplyUnit source={summary} amount={formatAmount(Math.ceil(summary.amountAppliedTotal))} />
      </ClickableCell>
      <ClickableCell>
        {remainingAmount ? <ProductAmountRemainingUnit source={summary} amount={remainingAmount} /> : "--"}
      </ClickableCell>
      <ClickableCell>{cost(summary.costPerUnit)}</ClickableCell>
      <ClickableCell>{cost(summary.costTotal)}</ClickableCell>
    </tr>
  );
};

const ProductPurchaseSummariesMobile = ({ purchaseSummaries, onCreatePurchase }) => (
  <>
    {purchaseSummaries.map((summary) => {
      const key = summary?.product?.id + "_" + summary.lastPurchaseDate + "_" + summary.costTotal;
      return summary.lastPurchaseDate ? (
        <ProductPurchaseCard
          key={key}
          purchaseSummary={summary}
          onLogPurchase={(product) => onCreatePurchase({ product })}
        />
      ) : (
        <ProductActivityCard
          key={key}
          purchaseSummary={summary}
          onLogPurchase={(product) => onCreatePurchase({ product })}
        />
      );
    })}
  </>
);

const ProductPurchaseSummariesTable = ({ purchaseSummaries, onCreatePurchase }) => (
  <Table>
    <thead>
      <tr>
        <th>Product</th>
        <th>Type</th>
        <th>Last Purchase</th>
        <th>Purchased</th>
        <th>Applied</th>
        <th>Remaining</th>
        <th>Avg Unit Price</th>
        <th>Cost</th>
      </tr>
    </thead>

    <tbody>
      {purchaseSummaries.map((summary) => (
        <PurchaseSummaryRow key={summary.product.id} onCreatePurchase={onCreatePurchase} summary={summary} />
      ))}
    </tbody>
  </Table>
);

const ProductPurchaseSummaries = (props) =>
  useViewType() === "mobile" ? (
    <ProductPurchaseSummariesMobile {...props} />
  ) : (
    <ProductPurchaseSummariesTable {...props} />
  );

ProductPurchaseSummaries.propTypes = {
  onCreatePurchase: PropTypes.func.isRequired,
  purchaseSummaries: PropTypes.arrayOf(
    PropTypes.shape({
      activityInputs: PropTypes.array,
      amountAppliedTotal: PropTypes.number,
      amountAppliedUnit: PropTypes.string,
      amountPurchasedTotal: PropTypes.number,
      costPerUnit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      costTotal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      lastPurchaseDate: PropTypes.string,
      product: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        __typename: PropTypes.oneOf(["ChemicalProduct", "FertilizerProduct", "SeedProduct", "WaterProduct"]).isRequired,
      }).isRequired,
    })
  ).isRequired,
};

export default ProductPurchaseSummaries;
