import { groupBy, sortBy, uniqBy, upperFirst } from "lodash";
import moment from "moment";

export const imageTypes = [
  { id: "trueColor", label: "True Color", value: "True Color" },
  { id: "ndvi", label: "NDVI", value: "NDVI" },
  { id: "maxNdvi", label: "Max NDVI", value: "NDVI_relative" },
];

export const sortOptions = [
  { key: 1, label: "Field Group (alphabetical)", value: "group" },
  { key: 2, label: "Field Name (alphabetical)", value: "fieldName" },
  { key: 3, label: "Cloud % (Low to high)", value: "cloud" },
  { key: 4, label: "Recency (New to Old)", value: "date" },
];

export const getCommodityOptions = (fieldCrops) => {
  const sortedCrops = sortBy(fieldCrops, ({ cropYear }) => -cropYear);
  const { commodities } = sortedCrops.reduce(
    (acc, { commodity, cropYear, field }) => {
      const storedCrop = acc[field.id];
      const currentCommodity = acc.commodities[commodity.id];
      const addFieldId = () => {
        if (currentCommodity) {
          currentCommodity.fields.push(field.id);
        } else {
          acc.commodities[commodity.id] = {
            fields: [field.id],
            id: commodity.id,
            label: commodity.name,
            value: commodity.id,
          };
        }
      };

      if (!storedCrop) {
        acc[field.id] = { cropYear, commodity };
        addFieldId();
      } else if (cropYear === storedCrop.cropYear && commodity.id !== storedCrop.commodity.id) {
        addFieldId();
      }

      return acc;
    },
    { commodities: {} }
  );

  return sortBy(Object.values(commodities), ["label"]);
};

export const getGroupOptions = (fields) => {
  const groups = fields?.map(({ group }) => ({
    label: upperFirst(group?.name) || "Ungrouped",
    value: group?.id || -1,
  }));

  return uniqBy(sortBy(groups, "label"), "value");
};

export const getFilteredLayers = (layers, filters) => {
  const { commodities, groups, irrigation } = filters;
  const fieldsWithSelectedCommodities = commodities.flatMap(({ fields }) => fields);
  const irrigated = irrigation[0] === "irrigation_true";
  const matchesCommodity = ({ field }) => !commodities.length || fieldsWithSelectedCommodities.includes(field.id);
  const matchesFieldGroup = ({ field }) =>
    !groups.length || groups.some((value) => (value === -1 ? !field.group : value === field.group?.id));
  const matchesIrrigation = ({ field }) => irrigation.length !== 1 || !!field.isIrrigated === irrigated;

  return layers.filter((layer) => matchesCommodity(layer) && matchesFieldGroup(layer) && matchesIrrigation(layer));
};

export const getSortedLayers = (layers, sortCriteria) => {
  const sortedLayers = sortBy(layers, ({ field }) => field.name.toLowerCase());

  switch (sortCriteria) {
    case "cloud":
      return sortBy(sortedLayers, ({ details }) => details.clouds);
    case "date":
      return sortBy(sortedLayers, ({ date }) => -moment(date).unix());
    case "fieldName":
      return sortedLayers;
    case "group":
    default:
      const groupedLayers = groupBy(sortedLayers, ({ field }) => field.group?.name);
      const groupNames = Object.keys(groupedLayers).filter((name) => name !== "undefined");
      const sortedGroupNames = sortBy(groupNames, (name) => name.toLowerCase());
      const sortedGroupedLayers = sortedGroupNames.map((group) => ({ group, layers: groupedLayers[group] }));
      groupedLayers[undefined] && sortedGroupedLayers.push({ group: "Ungrouped", layers: groupedLayers[undefined] });

      return sortedGroupedLayers;
  }
};
