import { StyleSheet as Aphrodite } from "aphrodite/no-important";

/*
 * Customizes aphrodite to add support for the child combinator
 * See https://bit.ly/2NZCUs1 and https://developer.mozilla.org/en-US/docs/Web/CSS/Child_combinator
 */
const { css, StyleSheet } = Aphrodite.extend([
  {
    selectorHandler: (selector, baseSelector, generateSubtreeStyles) => {
      if (selector[0] === ">") {
        return generateSubtreeStyles(`${baseSelector} > ${selector.slice(1)}`);
      }

      return null;
    },
  },
]);

export { css, StyleSheet };
