import { DatePicker } from "@farmlogs/fl-ui";
import { css } from "aphrodite/no-important";
import { contractsStyles } from "marketing/styles";
import contractEnums from "marketing/utils/contractEnums";
import PropTypes from "prop-types";
import React from "react";

import { FormGroup } from "components/fl-ui/Form";

const { CASH_CONTRACT, FUTURES_CONTRACT, OPTIONS_CONTRACT } = contractEnums;

const ContractDetailsDeliveryOrExpirationField = ({ contract, getContractTypeEnum, handleContractChange }) => {
  const { deliveryEndDate, expirationDate } = contract;

  const handleDeliveryDateChange = (date) => {
    handleContractChange({
      deliveryEndDate: date,
    });
  };

  const handleExpirationDateChange = (date) => {
    handleContractChange({
      expirationDate: date,
    });
  };

  switch (getContractTypeEnum()) {
    case CASH_CONTRACT:
      return (
        <FormGroup label="Delivery" inputId="delivery-end-date">
          <DatePicker inputId="delivery-end-date" onChange={handleDeliveryDateChange} value={deliveryEndDate} />
          <div className={css(contractsStyles.helpText)}>The last date that you can deliver the commodity.</div>
        </FormGroup>
      );
    case FUTURES_CONTRACT:
    case OPTIONS_CONTRACT:
      return (
        <FormGroup label="Expiration date" inputId="expiration-date">
          <DatePicker inputId="expiration-date" onChange={handleExpirationDateChange} value={expirationDate} />
        </FormGroup>
      );
    default:
      return (
        <FormGroup label="Delivery" inputId="delivery-end-date">
          <DatePicker inputId="delivery-end-date" onChange={handleDeliveryDateChange} value={deliveryEndDate} />
          <div className={css(contractsStyles.helpText)}>The last date that you can deliver the commodity.</div>
        </FormGroup>
      );
  }
};

ContractDetailsDeliveryOrExpirationField.propTypes = {
  contract: PropTypes.shape({
    deliveryDate: PropTypes.string,
    expirationEndDate: PropTypes.string,
  }).isRequired,
  getContractTypeEnum: PropTypes.func.isRequired,
  handleContractChange: PropTypes.func.isRequired,
};

export default ContractDetailsDeliveryOrExpirationField;
