import { useState } from "react";

/**
 * A hook which returns a React-aware [Set](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set).
 * Only a subset of methods are provided.
 *
 * @param {any[]} [initialItems=[]] an array containing the items used to populate the Set initially.
 * @return ImmutableSet
 */
const useImmutableSet = (initialItems = []) => {
  const [items, setItems] = useState(new Set(initialItems));

  return {
    clear: () => setItems(new Set()),
    has: (key) => items.has(key),
    items: () => Array.from(items.values()),
    reconstitute: (values) => setItems(new Set(values)),
    size: items.size,
    toggle: (key) => {
      const newItems = new Set(items);
      newItems.has(key) ? newItems.delete(key) : newItems.add(key);

      setItems(newItems);
    },
  };
};

export default useImmutableSet;

/**
 * @typedef {object} ImmutableSet
 * A React-aware [Set](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set) with
 * a minimal interface.
 *
 * @property {Function} clear empties the Set
 * @property {Function} has returns true if the Set contains the item, false otherwise
 * @property {Function} items returns an array containing the items in the set
 * @property {Function} reconstitute empties the Set and repopulates it with the provided item array
 * @property {Number} size the number of items in the Set
 * @property {Function} toggle adds/removes an item from the selected items
 */
