import { useMutation } from "@apollo/client";
import ProductInputChooser from "activity/components/choosers/ProductInputChooser";
import PropTypes from "prop-types";
import React, { useState } from "react";
import usePossibleMatches from "settings/pages/ProductAndMachineMatching/hooks/usePossibleMatches";
import styled from "styled-components";

import { SET_MACHINE_DATA_PRODUCT_MATCH } from "collection/graphql/integrations/mutations";
import App from "layout/app";
import { SETTINGS_PRODUCT_MATCH } from "lib/metrics/events";

import { Button } from "components/fl-ui";
import { Checkbox, FormGroup } from "components/fl-ui/Form";
import { CloseX } from "components/fl-ui/Icons";
import { Cluster } from "components/fl-ui/Layout";
import { Modal, ModalHeader, ModalTitle, ModalBody, ModalFooter } from "components/fl-ui/Modal/Modal";
import { GreyColors } from "components/fl-ui/colors";
import { Spacing, Typography } from "components/fl-ui/constants";

const ButtonContainer = styled.div`
  display: flex;
  gap: ${Spacing.xxsmall};

  @media only screen and (min-width: 60rem) {
    justify-content: end;
    > :nth-child(1) {
      order: 3;
    }
    > :nth-child(2) {
      order: 2;
    }
    > :nth-child(3) {
      margin-right: auto;
      order: 1;
    }
  }
  @media only screen and (max-width: 60rem) {
    flex-direction: column;
  }
`;

const CheckboxLabel = styled.span`
  color: ${GreyColors.smoke30};
  font-size: ${Typography.sizes.mn};
`;

const ProductMatchModal = ({ onClose, onSave, productMatch }) => {
  const { id, matchedProduct, unitType } = productMatch;
  const [selection, setSelection] = useState(matchedProduct ?? null);
  const [rewriteHistory, setRewriteHistory] = useState(true);
  const hasSavedMatch = !!matchedProduct?.id;
  const setProductMatch = useMutation(SET_MACHINE_DATA_PRODUCT_MATCH)[0];
  const possibleMatches = usePossibleMatches(id);
  const suggestedMatch = possibleMatches[0];
  const suggestedOptions = possibleMatches;

  const handleProductChange = (input) => setSelection(input);

  const handleSubmit = (event) => {
    event.preventDefault();
    save(selection ?? null);
  };

  const save = async (product) => {
    try {
      const payload = {
        matchId: id,
        productId: product?.id ?? null,
        rewriteHistory,
      };
      await setProductMatch({ variables: payload });
      SETTINGS_PRODUCT_MATCH.track(payload);

      onSave();
    } catch (error) {
      App.notify("An error occurred, please try again later");
    }
  };

  return (
    <Modal width={500}>
      <ModalHeader>
        <ModalTitle>Set Product Match</ModalTitle>
        <CloseX onClick={onClose} />
      </ModalHeader>

      <ModalBody>
        <form id="productMatchForm" onSubmit={handleSubmit}>
          <FormGroup label="Unidentified Input">
            <Cluster between>{suggestedMatch?.name ?? "--"}</Cluster>
          </FormGroup>

          <FormGroup label="Select Matching Product">
            <ProductInputChooser
              onChange={handleProductChange}
              productMatch={productMatch}
              suggestedOptions={suggestedOptions}
              unitType={unitType}
              value={selection?.id}
              variant="search"
            />
          </FormGroup>

          <FormGroup>
            <Checkbox
              checked={rewriteHistory}
              label={<CheckboxLabel>Rewrite previous instances with the match selected above.</CheckboxLabel>}
              name="rewriteHistory"
              onChange={() => setRewriteHistory((rewriteHistory) => !rewriteHistory)}
            />
          </FormGroup>
        </form>
      </ModalBody>

      <ModalFooter>
        <ButtonContainer>
          <Button color="primary" disabled={!selection} form="productMatchForm" type="submit">
            Save Match
          </Button>
          <Button onClick={onClose}>Cancel</Button>
          {hasSavedMatch && (
            <Button color="danger" hollow onClick={() => save(null)}>
              Delete Match
            </Button>
          )}
        </ButtonContainer>
      </ModalFooter>
    </Modal>
  );
};

ProductMatchModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  productMatch: PropTypes.object.isRequired,
};

export default ProductMatchModal;
