import themeGet from "@styled-system/theme-get";
import React from "react";
import styled from "styled-components";

import { BorderRadius, Spacing } from "components/fl-ui/constants";

const ResponsiveMenuDrawer = styled.div`
  background-color: ${themeGet("colors.smoke99")};
  border-radius: ${BorderRadius.medium};
  bottom: 4.25rem;
  display: ${({ state }) => (state === "exited" ? "none" : "grid")};
  grid-gap: ${Spacing.xxsmall};
  grid-template-columns: repeat(3, 1fr);
  left: 0.5rem;
  opacity: ${({ state }) => (state === "entered" ? 1 : 0)};
  padding: ${Spacing.regular} ${Spacing.xxsmall};
  position: fixed;
  width: calc(100% - 1rem);
  z-index: 3000;
`;

ResponsiveMenuDrawer.displayName = "ResponsiveMenuDrawer";

export default ResponsiveMenuDrawer;
