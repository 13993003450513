import { makeVar } from "@apollo/client";
import _ from "lodash";

import { last24RainfallClient, historicalRainfallClient } from "collection/graphql/client/rainfallClient";
import { getHistoricalRainfall, getLastDaysRainfall } from "collection/graphql/fields/queries";

export const last24Rainfall = makeVar();
export const historicalRainfall = makeVar();

export const refreshLast24Rainfall = async () => {
  const { data } = await last24RainfallClient.query({ query: getLastDaysRainfall, fetchPolicy: "network-only" });
  const sortedLeastToMost = _.sortBy(data?.last24Rainfall, ({ last24 }) => last24.total);
  last24Rainfall(_.reduce(sortedLeastToMost, (map, { fieldId, last24 }) => map.set(fieldId, last24), new Map()));
};

export const refreshHistoricalRainfall = async () => {
  const { data } = await historicalRainfallClient.query({ query: getHistoricalRainfall, fetchPolicy: "network-only" });
  const sortedLeastToMost = _.sortBy(data?.historicalRainfall, [
    ({ recent }) => (recent.state === "recent" ? -1 : 1),
    ({ recent }) => recent.amount,
  ]);
  historicalRainfall(_.reduce(sortedLeastToMost, (map, { fieldId, recent }) => map.set(fieldId, recent), new Map()));
};
