import { CapsHeader } from "../Helpers";
import PropTypes from "prop-types";
import React from "react";
import autoBind from "react-autobind";

import { Checkbox } from "components/fl-ui/Form/index";

/**
 * Boolean (checkbox) parameter.
 */
class BooleanFilter extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  handleValueChange(event) {
    this.props.onParamChanged({ id: this.props.param.id, value: event.target.checked.toString() });
  }

  render() {
    return (
      <div key={this.props.param.id}>
        <CapsHeader>{this.props.param.title}</CapsHeader>
        <Checkbox name={this.props.param.id} onChange={this.handleValueChange} />
      </div>
    );
  }
}

BooleanFilter.propTypes = {
  param: PropTypes.object.isRequired,
  onParamChanged: PropTypes.func.isRequired,
};

export default BooleanFilter;
