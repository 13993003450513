import { useMutation, useSuspenseQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import router from "router";

import { useAuth } from "collection/graphql/auth";
import { useUserConfig } from "collection/graphql/config";
import { UPDATE_ENTERPRISE } from "collection/graphql/enterprise/mutations";
import { GET_CURRENT_ENTERPRISE } from "collection/graphql/enterprise/queries";
import { getCurrentSubscription } from "collection/graphql/subscription";
import usePermissions from "hooks/usePermissions";
import useRestSuspenseQuery from "hooks/useRestSuspenseQuery";
import { ONBOARDING_SURVEY_SUBMIT } from "lib/metrics/events";

import { withSuspenseWrapper } from "components/fl-ui/LoadingWrapper";
import SurveyForm from "fields/components/SurveyForm";

const Survey = withSuspenseWrapper(() => {
  const [formData, setFormData] = useState({});
  const [options, setOptions] = useState({});
  const { currentUser } = useAuth();
  const [updateEnterprise, { loading: isUpdating }] = useMutation(UPDATE_ENTERPRISE);
  const hasCompletedOnboarding = useUserConfig()("didOnboarding");

  const navigateToNextPage = () => {
    const redirectionPath = hasCompletedOnboarding ? "fields" : "fields/add";
    router.navigate(redirectionPath, { replace: true, trigger: true });
  };

  const handleFormData = (data) => {
    setFormData({ ...formData, ...data });
  };

  const handleSubmit = async () => {
    const { acreage, useType } = formData;

    try {
      if (useType) {
        const enterprise = useType === "FARMER" ? { ...formData, options } : { options, useType };
        await updateEnterprise({ variables: { enterprise } });
        ONBOARDING_SURVEY_SUBMIT.track({
          context: {
            traits: {
              use_type: useType,
              ...options,
            },
          },
          properties: {
            acreage_claimed: acreage,
            email: currentUser?.email,
          },
        });
      }
    } catch (err) {
      throw err;
    } finally {
      const redirectionPath = hasCompletedOnboarding ? "fields" : "fields/add";
      router.navigate(redirectionPath, { replace: true, trigger: true });
    }
  };

  /*
   * The user can only view the survey if all of the following conditions are true:
   *  1. They have READ permissions on farm_info.
   *  2. They are not on a paid subscription.
   *  3. Their enterprise does not already have a `useType`.
   */
  const canReadFarmInfo = usePermissions().canRead("farm_info");
  const isNotSubscribed = !useRestSuspenseQuery(getCurrentSubscription).data.subscription.isSubscribedToPlan;
  const hasNotSetUseType = !useSuspenseQuery(GET_CURRENT_ENTERPRISE).data.currentEnterprise.useType;
  useEffect(() => {
    const requirements = [canReadFarmInfo, isNotSubscribed, hasNotSetUseType];
    if (!requirements.every((condition) => condition)) {
      navigateToNextPage();
    }
  }, []);

  return (
    <SurveyForm
      formData={formData}
      handleSubmit={handleSubmit}
      isUpdating={isUpdating}
      options={options}
      setFormData={handleFormData}
      setOptions={setOptions}
    />
  );
});

Survey.title = "Survey";

export default Survey;
