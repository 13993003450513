import React from "react";

const CapsHeader = (props) => <div {...props} className="small-caps-heading" />;

const NEXT_YEAR = new Date().getFullYear() + 1;

const fullWidth = { width: "100%" };

const toOption = ({ name, id, color }) => ({
  label: name,
  value: id,
  color,
});

export { CapsHeader, NEXT_YEAR, fullWidth, toOption };
