import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { Borders, Spacing, Typography } from "components/fl-ui/constants";

const CardRow = styled(({ children, className, title }) => (
  <div className={className}>
    <div>{title}</div>
    <div>{children}</div>
  </div>
))`
  display: flex;
  flex-direction: row;
  gap: ${Spacing.small};
  justify-content: space-between;
  padding: ${Spacing.xsmall} 0;

  :not(:last-child) {
    border-bottom: ${Borders.regular};
  }

  > * {
    white-space: nowrap;
  }

  > :last-child {
    font-weight: ${Typography.weights.medium};
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
`;

export default CardRow;

CardRow.propTypes = {
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};
