import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import React, { useState } from "react";
import router from "router";
import styled from "styled-components";

import { GET_MARKETED_CROP } from "collection/graphql/marketing";
import { useMarketingPageContext } from "context/MarketingPageContext";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import usePermissions from "hooks/usePermissions";

import MarketingBlankStateModal from "components/advertisements/modals/MarketingBlankStateModal";
import PopoverButton from "components/fl-ui/Buttons/PopoverButton";
import { ColorPalette } from "components/fl-ui/colors";

const CardHeaderPopover = styled(({ className, marketedCropId: id, onDeleteRequest, year }) => {
  const { canDelete } = usePermissions();
  const hasMarketing = useEnterpriseFeature("grain_marketing");
  const [paygate, setPaygate] = useState(false);
  const { openCropEditModal } = useMarketingPageContext();

  const [isPrimed, setIsPrimed] = useState(false);
  const { data } = useQuery(GET_MARKETED_CROP, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    skip: !isPrimed,
    variables: { id },
  });

  const { marketedCrop } = data ?? {};

  const navigateTo = (path = "") => {
    if (hasMarketing || !path) {
      router.navigate(`marketing/${year}/${id}${path}`, { trigger: true });
    } else {
      setPaygate(true);
    }
  };

  /*
   * build menu items array (popover content)
   */
  const menuItems = [
    marketedCrop?.allowsSpeculativeContracts && {
      label: "Log futures contract",
      onSelect: () => navigateTo("/contracts/futures/new"),
    },
    marketedCrop?.allowsSpeculativeContracts && {
      label: "Log options contract",
      onSelect: () => navigateTo("/contracts/options/new"),
    },
    marketedCrop && { label: "Edit crop", onSelect: () => openCropEditModal(marketedCrop) },
    { label: "View details", onSelect: () => navigateTo("") },
    canDelete("marketing") && { color: "danger", label: "Delete crop", onSelect: onDeleteRequest },
  ].filter((x) => x);

  const handleClick = (event) => {
    event.preventDefault();
    setIsPrimed(true);
  };

  return (
    <span className={className} onMouseEnter={() => setIsPrimed(true)}>
      <PopoverButton onClick={handleClick} options={menuItems} primary />
      {paygate && (
        <div onClick={(event) => event.preventDefault()}>
          <MarketingBlankStateModal onClose={() => setPaygate(false)} />
        </div>
      )}
    </span>
  );
})`
  span button {
    height: 2.5rem;

    svg {
      margin-top: 0.3rem;
      color: ${ColorPalette["blue"]};
    }
  }
`;

CardHeaderPopover.propTypes = {
  marketedCropId: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
};

export { CardHeaderPopover };
