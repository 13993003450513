import { StyleSheet, css } from "aphrodite/no-important";
import PropTypes from "prop-types";
import React from "react";

import NotificationActions from "modules/notifications/components/NotificationActions";
import NotificationItemIcon from "modules/notifications/components/NotificationItemIcon";

import Icon from "components/fl-ui/Icons/Icon";
import { popoverStyles } from "components/fl-ui/Popover/popoverStyles";
import { UIColors } from "components/fl-ui/colors";
import { Mixins, Spacing, Typography } from "components/fl-ui/constants";

const styleSheet = StyleSheet.create({
  container: {
    position: "fixed",
    bottom: Mixins.toRem(40),
    right: Mixins.toRem(40),
    zIndex: 3000,
    "@media only screen and (max-width: 30em)": {
      display: "none",
    },
  },
  popoverContainer: {
    position: "relative",
    height: "auto",
    marginBottom: 4,
    opacity: 1,
    paddingTop: 16,
    paddingRight: 68,
    paddingBottom: 16,
    paddingLeft: 16,
    borderWidth: 1,
    maxWidth: Mixins.toRem(340),
    transition: "box-shadow 0.1s ease-in-out",
    overflow: "hidden",
    ":hover": {
      boxShadow: `0 4px 6px 0 rgba(0,0,0,0.15)`,
    },
  },
  closeX: {
    color: UIColors.regular,
    top: Spacing.regular,
    right: Spacing.regular,
  },
  content: {
    marginBottom: Spacing.regular,
    display: "flex",
    justifyContent: "flex-start",
  },
  icon: {
    color: UIColors.regular,
    minWidth: Spacing.large,
    width: Spacing.large,
    height: Spacing.large,
    marginRight: Spacing.medium,
    marginTop: Spacing.xsmall,
  },
  title: {
    marginTop: 0,
    marginBottom: 0,
    fontWeight: Typography.weights.medium,
    lineHeight: 1.4285,
    letterSpacing: "0.25px",
  },
  body: {
    marginBottom: 0,
    lineHeight: 1.357,
  },
});

const NotificationPopovers = ({ count, notifications, onDismiss }) => {
  return (
    <div className={css(styleSheet.container)}>
      {notifications.map((n) => (
        <div className={css(popoverStyles.popover, popoverStyles.withCloseX, styleSheet.popoverContainer)} key={n.id}>
          <div className={css(popoverStyles.closeX, styleSheet.closeX)} onClick={onDismiss.bind(this, n.id)}>
            <Icon icon="close" />
          </div>
          <div className={css(styleSheet.content)}>
            <div className={css(styleSheet.icon)}>
              <NotificationItemIcon notification={n} count={count} />
            </div>
            <div className={css(styleSheet.text)}>
              <h4 className={css(styleSheet.title)}>{n.title}</h4>
              <p className={css(styleSheet.body)}>{n.body}</p>
            </div>
          </div>
          <div className={css(styleSheet.actions)}>
            <NotificationActions notification={n} />
          </div>
        </div>
      ))}
    </div>
  );
};

NotificationPopovers.propTypes = {
  count: PropTypes.number.isRequired,
  onDismiss: PropTypes.func.isRequired,
  notifications: PropTypes.array.isRequired,
};

export default NotificationPopovers;
