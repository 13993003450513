import _ from "lodash";

/**
 * Returns a version of a source object with the keys in [snake-case]{@link https://lodash.com/docs/4.17.15#snakeCase}.
 *
 * @param {Object} source
 * @return {Object}
 *
 * @example
 * convertKeysToLegacy({ varName: 'test' })
 * // returns { var_name: 'test' }
 */
function convertKeysToLegacy(source) {
  return _.mapKeys(source, (value, key) => _.snakeCase(key));
}

export default convertKeysToLegacy;
