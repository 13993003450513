import { gql } from "@apollo/client";

import { MachineDataProductMatch } from "collection/graphql/integrations/fragments";

export default gql`
  query getMachineDataProductMatch($id: Int!) {
    productMatch: getMachineDataProductMatch(id: $id) {
      ...MachineDataProductMatch
      possibleMatches {
        matchScore
        product {
          ...Product
        }
      }
    }
  }
  ${MachineDataProductMatch}
`;
