import React from "react";

const PercentProduction = ({ percentage, quantity, category }) => {
  if (+percentage == 0 && +quantity > 0) return <span>Less than 1% total production {category}</span>;

  return (
    <span>
      {percentage}% of total production {category}
    </span>
  );
};

export default PercentProduction;
