import _ from "lodash";

/**
 * A base class for any errors being sent to Sentry. Provides a `getContext()` method
 * for manually adding additional structured context to the exception report.
 *
 * @see https://docs.sentry.io/platforms/javascript/guides/react/enriching-events/context/#passing-context-directly
 */
class SentryInfoError extends Error {
  constructor(message, options = {}) {
    super(message, _.pick(options, ["cause"]));
    this.options = _.omit(options, ["cause"]);
  }

  getContext() {
    return {
      extra: this.options,
    };
  }
}

export default SentryInfoError;
