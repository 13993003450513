import EditableBaseContract from "./EditableBaseContract";
import { gql } from "@apollo/client";

/**
 * Represents the fields necessary to edit a FuturesContract.
 * @type {DocumentNode}
 */
export default gql`
  fragment EditableFuturesContract on FuturesContract {
    ...EditableBaseContract
    contractPrice
    contractSubtypeFutures: contractType
    expirationDate
    netPrice
    quantity
  }

  ${EditableBaseContract}
`;
