import useCommodityCustomaryUnits from "collection/graphql/commodities/hooks/useCommodityCustomaryUnits";
import useUnits from "hooks/useUnits";

const COUNT = "COUNT";
const WEIGHT = "WEIGHT";

/**
 * @param {'COUNT'|'WEIGHT'} seedUnitType
 */
const useCommoditiesUnitsOptions = (seedUnitType) => {
  const { seedCountUnits = [], weightUnits = [] } = useUnits().data ?? {};
  let seedUnits = [];
  if (seedUnitType === COUNT) {
    seedUnits = seedCountUnits;
  } else if (seedUnitType === WEIGHT) {
    seedUnits = weightUnits;
  }
  seedUnits = seedUnits.map(({ data, display }) => ({ label: display, value: data }));

  const seedUnitTypes = [
    { label: "Count", value: COUNT },
    { label: "Weight", value: WEIGHT },
  ];

  const yieldUnitOptions = useCommodityCustomaryUnits().map(({ abbr, name, value }) => ({
    label: `${name} (${abbr})`,
    value,
  }));

  return { seedUnits, seedUnitTypes, yieldUnitOptions };
};

export default useCommoditiesUnitsOptions;
