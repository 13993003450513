import * as Sentry from "@sentry/react";
import { SupportEmailLink } from "billing/lib";
import { isNative } from "mobile/mobileManager";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { Button } from "components/fl-ui";
import { Icon, IconContainer } from "components/fl-ui/Icons";
import styleVars from "components/fl-ui/Layout/Navigation/styleVars";
import { Spacing } from "components/fl-ui/constants";

export const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;

  ${(props) =>
    !props.compact &&
    `
    min-height: 100vh;
  `}

  ${(props) =>
    props.pad &&
    `
    padding: 0 0 0 ${styleVars.mainMenuWidth_desktop};
    @media only screen and (max-width: 62.5em) {
      padding: 0 0 0 ${styleVars.mainMenuWidth_tablet};
    }
    @media only screen and (max-width: 30em) {
      padding: ${styleVars.header_height} ${Spacing.medium} 0;
    }
  `}
`;

export const ErrorComponent = ({ compact = false, pad = false }) => (
  <ErrorContainer compact={compact} pad={pad}>
    <IconContainer size="xxxlarge">
      <Icon icon="error" />
    </IconContainer>

    <h1>Oops! Something went wrong.</h1>
    <p>
      Please try{" "}
      {isNative() ? (
        <Button color="primary" link onClick={() => location.reload()}>
          reloading
        </Button>
      ) : (
        "refreshing your browser"
      )}
      .
    </p>
    <p>
      If this error persists, please let us know by contacting our <SupportEmailLink>support team</SupportEmailLink> so
      we can work on a fix.
    </p>
    <p>In the meantime, you can navigate to other pages in the Bushel Farm app.</p>

    {isNative() && (
      <Button color="primary" link onClick={() => location.reload()}>
        Reload app
      </Button>
    )}
  </ErrorContainer>
);

ErrorComponent.propTypes = {
  compact: PropTypes.bool,
  pad: PropTypes.bool,
};

const ErrorBoundary = (props) => <Sentry.ErrorBoundary {...props}>{props.children}</Sentry.ErrorBoundary>;

ErrorBoundary.propTypes = {
  children: PropTypes.any.isRequired,
  fallback: PropTypes.func,
};

ErrorBoundary.defaultProps = {
  fallback: () => <ErrorComponent pad />,
};

export default ErrorBoundary;
