import DashboardContainer from "./pages/DashboardContainer";

import { wrapWithProvider, marketingClient } from "collection/graphql/client";
import App from "layout/app";
import SubRoute from "lib/SubRoute";

const PandLRouter = SubRoute.extend({
  routes: {
    "": "index",
  },

  display(page, props) {
    App.display(wrapWithProvider(page, marketingClient), props);
  },

  index() {
    return this.display(DashboardContainer, { screenName: "Profit & Loss" });
  },
});

export default () => new PandLRouter("profit_and_loss", { createTrailingSlashRoutes: true });
