import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { Button } from "components/fl-ui";
import Popover from "components/fl-ui/Popover/Popover";
import { Borders, Spacing, Typography } from "components/fl-ui/constants";

const DropdownMenuOption = styled(({ className, label, onSelect, selected, value }) => {
  const buttonProps = {
    children: label,
    className,
    color: selected ? "primary" : "default",
    icon: selected ? "check" : null,
    iconClassName: "sortOptionIcon",
    iconRight: selected,
    link: true,
    onClick: () => onSelect(value),
  };

  return <Button {...buttonProps} />;
})`
  display: flex !important;
  font-size: ${Typography.sizes.rg};
  font-weight: ${Typography.weights.regular} !important;
  justify-content: space-between;
  min-height: ${Spacing.xxlarge};
  width: 100%;
  * + * {
    margin-top: ${Spacing.xsmall};
  }
  .sortOptionIcon {
    height: ${Spacing.medium};
    margin-left: ${Spacing.xsmall};
    width: ${Spacing.medium};
  }
`;

const DropdownMenu = ({ labelKey, onChange, options, value, valueKey }) => {
  const labelText = _.find(options, { [valueKey]: value })?.[labelKey] ?? "";
  const handleSelect = (newValue) => {
    if (newValue !== value) {
      onChange(newValue);
    }
  };

  const menu = (
    <>
      {options.map((option, i) => (
        <DropdownMenuOption key={i} {...option} onSelect={handleSelect} selected={option[valueKey] === value} />
      ))}
    </>
  );

  return (
    <Popover content={menu} placement="bottom-end">
      <Button color="white" icon="chevronDown" iconRight style={{ border: Borders.regular }}>
        {labelText}
      </Button>
    </Popover>
  );
};

DropdownMenu.propTypes = {
  labelKey: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.string.isRequired,
  valueKey: PropTypes.string,
};

DropdownMenu.defaultProps = {
  labelKey: "label",
  valueKey: "value",
};

export default DropdownMenu;
