import Index from "inventory/index";
import React, { useEffect, useRef } from "react";

import useCommodities from "hooks/useCommodities";
import { useFeatureAndPermissionAccess } from "hooks/useFeatureAndPermissionAccess";

import PayGateAdBanner from "components/advertisements/banners/PayGateAdBanner";
import Container from "components/fl-ui/Layout/Container";
import Header from "components/fl-ui/Layout/Header";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import RestrictedAccessBlankslate from "components/fl-ui/RestrictedAccessBlankslate";

const InventoryWrapper = () => {
  const storageRef = useRef();
  const { getCommodityById, loading } = useCommodities();

  useEffect(() => {
    if (!loading) {
      new Index({ el: storageRef.current, getCommodityById }).render();
    }
  }, [loading]);

  const { loadingAccess, showPaygate, showRestricted, showContent } = useFeatureAndPermissionAccess({
    featureName: "legacy_storage_and_loads",
    permissionName: "storage",
  });

  return (
    <Container>
      <LoadingWrapper isLoading={loadingAccess}>
        <Header title="Storage" />
        {showPaygate && <PayGateAdBanner />}
        {showRestricted && <RestrictedAccessBlankslate />}
        {showContent && <div ref={storageRef} style={{ margin: "0.5rem" }} />}
      </LoadingWrapper>
    </Container>
  );
};

export default InventoryWrapper;
