/* istanbul ignore file */
const getDefaultFilters = () => ({
  commodities: [],
  endDate: null,
  product: null,
  productTypes: [],
  restrictedUse: false,
  search: "",
  sortCriteria: "dateDesc",
  startDate: null,
  vendor: null,
});

export default getDefaultFilters;
