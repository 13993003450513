import PropTypes from "prop-types";
import React from "react";

import { EmptyResultContainer, EmptyResultIcon } from "components/fl-ui/Filters/styledComponents";

const RefineFilters = ({ filterType, icon }) => (
  <EmptyResultContainer>
    {icon && <EmptyResultIcon icon={icon} />}
    <h2>No matching {filterType || "items"}</h2>
    <p>Please refine your filters{filterType ? ` to see ${filterType}` : ""}</p>
  </EmptyResultContainer>
);

RefineFilters.propTypes = {
  filterType: PropTypes.string,
  icon: PropTypes.string,
};

export default RefineFilters;
