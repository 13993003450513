import { omit } from "lodash";
import PropTypes from "prop-types";
import React from "react";

import useUnitOfMeasure from "collection/graphql/units/hooks/useUnitOfMeasure";

import Unit from "components/units/Unit";

const ProductApplyUnit = ({ attribute, source, ...rest }) => {
  let unitType;
  switch (source?.__typename) {
    case "ActivityInput":
      unitType = source.amountUnit;
      break;

    case "ProductPurchaseSummary":
      unitType = source.amountAppliedUnit;
      break;
  }
  rest.unit = useUnitOfMeasure(unitType)?.[attribute] ?? "";

  return <Unit {...rest} />;
};

ProductApplyUnit.propTypes = {
  ...omit(Unit.propTypes, ["unit"]),
  attribute: PropTypes.oneOf(["abbr", "name"]),
  source: PropTypes.oneOfType([
    // `ActivityInput`
    PropTypes.shape({
      amountUnit: PropTypes.string,
      __typename: PropTypes.string.isRequired, // `ActivityInput`
    }),

    PropTypes.shape({
      // `ProductPurchaseSummary`
      amountAppliedUnit: PropTypes.string,
      __typename: PropTypes.string.isRequired, // `ProductPurchaseSummary`
    }),
  ]),
};

ProductApplyUnit.defaultProps = {
  ...Unit.defaultProps,
  attribute: "abbr",
};

export default ProductApplyUnit;
