import moment from "moment";

import { getCurrentSubscription } from "collection/graphql/subscription";
import usePermissions from "hooks/usePermissions";
import useRestQuery from "hooks/useRestQuery";

/**
 * @typedef {object} PaymentMethodDetails
 * @property {string} brand lowercase brand identifier (ex: "visa")
 * @property {string} brandName brand name copy (ex: "Visa")
 * @property {string} cardholder the full name of the cardholder
 * @property {number} expirationMonth
 * @property {number} expirationYear
 * @property {string} last4 the last four digits of the credit card
 * @property {"card"|string} type
 */

/**
 * @typedef {object} UsePaymentMethodReturnType
 * @property {object} data
 * @property {string} [data.cardExpiryCopy=""] the expiry month and year (ex: "12/24" for December 2024)
 * @property {boolean} [data.hasValidCard=false]
 * @property {object} [data.paymentMethod=null]
 * @property {string} [error] error description of the payment method details can not be displayed
 * @property {boolean} loading true if payment method details are loading
 */

/**
 * Returns details regarding the current payment method if the user has the appropriate permissions.
 * @return {UsePaymentMethodReturnType}
 */
const usePaymentMethod = () => {
  const { canRead, canWrite } = usePermissions();
  const canReadSubscription = canRead("subscription");
  const canWriteSubscription = canWrite("subscription");

  const { data, loading, refetch } = useRestQuery(getCurrentSubscription, { skip: !canReadSubscription });
  const result = {
    data: {
      cardExpiryCopy: "",
      hasValidCard: false,
      paymentMethod: null,
    },
    loading,

    /**
     * @param {object} stripeToken
     * @param {string} stripeToken.id
     * @return {Promise<*|Response>}
     */
    updatePaymentMethod: async (stripeToken) => {
      if (!canWriteSubscription) {
        throw new Error("Unable to save payment method details");
      }

      const url = `/v2.0/subscriptions/subscription/payment_method`;

      const response = await fetch(url, {
        body: JSON.stringify({ stripe_token: stripeToken.id }),
        cache: "no-store",
        headers: { "Content-Type": "application/json" },
        method: "POST",
      });

      const responseBody = await response.json();
      if (response.ok) {
        refetch();
        return responseBody;
      }

      return Promise.reject(responseBody);
    },
  };

  const { paymentMethod } = data?.subscription || {};
  if (!canReadSubscription) {
    result.error = "Unable to read payment method details";
  } else if (paymentMethod) {
    const { expirationMonth, expirationYear } = paymentMethod;
    const cardExpiry = moment()
      .month(expirationMonth - 1)
      .year(expirationYear)
      .endOf("month");

    result.data.paymentMethod = paymentMethod;
    result.data.cardExpiryCopy = cardExpiry.format("MM/YY");
    result.data.hasValidCard = cardExpiry.isAfter(moment());
  }

  return result;
};

export default usePaymentMethod;
