import { LeftButton } from "profit_and_loss/styles";
import PropTypes from "prop-types";
import React from "react";

import useCurrentCropYear from "hooks/useCurrentCropYear";
import { useFeatureAndPermissionAccess } from "hooks/useFeatureAndPermissionAccess";

import ProfitAndLossBlankSlateBannerPage from "components/advertisements/pages/ProfitAndLossBlankSlateBannerPage";
import CropYearPicker from "components/fl-ui/CropYearPicker";
import Container from "components/fl-ui/Layout/Container";
import Header from "components/fl-ui/Layout/Header";
import RestrictedAccessBlankslate from "components/fl-ui/RestrictedAccessBlankslate";

const ProfitAndLossPermissionWrapper = ({ banner, children, pageButton, screenName, ...otherProps }) => {
  const cropYear = useCurrentCropYear()[0];
  const Dashboard = children;

  const { showPaygate, showRestricted, showContent } = useFeatureAndPermissionAccess({
    featureName: "profit_and_loss",
    permissionName: "profit_and_loss",
  });

  return (
    <Container>
      <Header title={screenName}>
        {showContent && pageButton && (
          <LeftButton color="primary" onClick={pageButton.action}>
            {pageButton.buttonText}
          </LeftButton>
        )}
        {showContent && <CropYearPicker />}
      </Header>

      {showPaygate && <ProfitAndLossBlankSlateBannerPage banner={banner} />}
      {showRestricted && <RestrictedAccessBlankslate />}
      {showContent && <Dashboard {...otherProps} cropYear={cropYear} hasAccess={showContent} />}
    </Container>
  );
};

ProfitAndLossPermissionWrapper.propTypes = {
  children: PropTypes.any.isRequired,
  pageButton: PropTypes.shape({
    action: PropTypes.func.isRequired,
    buttonText: PropTypes.string.isRequired,
  }),
  screenName: PropTypes.string.isRequired,
};

export default ProfitAndLossPermissionWrapper;
