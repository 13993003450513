import _ from "lodash";

import validationUtils from "lib/validation/utils";
import validator from "modules/marketing/validators/CropForm";

const buildDefaultMarketedCrop = (defaults) =>
  _.defaults({}, defaults, {
    acresPlanted: "",
    averageYield: "",
    disableExchangePricing: false,
    fields: [],
    harvestBasis: 0,
    itemizedProductionCosts: [],
    name: "",
    productionCost: null,
    productionCostUnit: "PER_ACRE",
    useItemizedProductionCosts: false,
    year: null,
  });

const getFieldsWithoutSelectedCommodity = (formData) => {
  if (formData.commodity) {
    return formData.fields.reduce((fieldsWithoutCommodity, field) => {
      const hasSelectedCommodity = _.some(field.crops, ({ commodity }) => commodity.id === formData.commodity?.id);
      const hasSelectedYear = _.some(field.crops, ({ cropYear }) => formData.year === cropYear);

      if (_.size(field.crops) === 0 || (!hasSelectedCommodity && hasSelectedYear)) {
        fieldsWithoutCommodity.push(field);
      }

      return fieldsWithoutCommodity;
    }, []);
  }

  return [];
};

const sanitizeMarketedCrop = (marketedCrop) => {
  if (!validator.isValid(marketedCrop)) {
    throw new Error("Invalid marketed crop", marketedCrop);
  }

  const sanitized = _.pick(marketedCrop, _.keys(buildDefaultMarketedCrop()));

  if ("id" in marketedCrop) {
    sanitized.id = marketedCrop.id;
  }

  sanitized.commodityId = marketedCrop.commodity.id;

  if (marketedCrop.commodity.marketSymbol && !marketedCrop.disableExchangePricing) {
    sanitized.harvestBasis = parseFloat(marketedCrop.harvestBasis);
  } else {
    sanitized.harvestPrice = parseFloat(marketedCrop.harvestPrice);
    sanitized.harvestBasis = 0;
  }

  sanitized.fields = _.map(sanitized.fields, ({ id, value }) => parseFloat(id || value));

  // removes incomplete itemized costs items
  sanitized.itemizedProductionCosts = sanitized.itemizedProductionCosts
    ? sanitized.itemizedProductionCosts.filter((item) => {
        return item.category && item.category !== "SELECT" && item.unit && validationUtils.isFloat(item.cost);
      })
    : [];

  // convert any string values in numeric fields to numbers
  sanitized.acresPlanted = parseFloat(sanitized.acresPlanted);
  sanitized.averageYield = parseFloat(sanitized.averageYield);
  sanitized.itemizedProductionCosts = sanitized.itemizedProductionCosts.map((item) => {
    return {
      ..._.omit(item, ["__typename"]),
      cost: parseFloat(item.cost),
    };
  });

  return sanitized;
};

export { buildDefaultMarketedCrop, getFieldsWithoutSelectedCommodity, sanitizeMarketedCrop };
