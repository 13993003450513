import useProductData from "activity/components/choosers/ProductInputChooser/hooks/useProductData";
import { defaultStyles, InputOption, searchStyles, TabbedMenuList } from "activity/utils/getInputChooserComponents";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import ReactSelect from "react-select";

import { useProductById } from "collection/graphql/products/hooks";
import useViewType from "hooks/useViewType";

import InputModal from "components/product/crud";

const tabs = [
  { label: "ALL", value: "ALL" },
  { label: "SEED", value: "SEED" },
  { label: "CHEM", value: "CHEMICAL" },
  { label: "FERT", value: "FERTILIZER" },
];

const ProductInputChooser = (props) => {
  const { hasError = false, onChange, openOnInitialize, unitType, value, variant } = props;
  const isMobile = useViewType() === "mobile";
  const [isMenuOpen, setIsMenuOpen] = useState(openOnInitialize);
  const [showInputModal, setShowInputModal] = useState(false);
  const [tabValue, setTabValue] = useState(tabs[0].value);
  const { loading, options, search, setProductType, setSearch } = useProductData(props);
  const { product } = useProductById(value);

  const filterOption = (option, searchString) => {
    const { name, shortDescription } = option.data;
    searchString = _.toLower(searchString);

    return _.toLower(name).includes(searchString) || _.toLower(shortDescription).includes(searchString);
  };

  const handleTabChange = (value) => {
    setTabValue(value);
    setProductType(value);
  };

  const handleCloseClick = (event) => {
    event?.preventDefault();
    handleTabChange(tabs[0].value);
    setIsMenuOpen(false);
  };

  const handleOnCreate = (event) => {
    event.preventDefault();
    handleCloseClick();
    setShowInputModal(true);
  };

  const handleOnClose = () => {
    setShowInputModal(false);
    if (openOnInitialize) {
      setIsMenuOpen(true);
    }
  };

  const handleOnFinish = (newProduct) => {
    setShowInputModal(false);
    if (newProduct) {
      onChange(newProduct);
    }
  };

  const handleOnChange = (product) => {
    onChange(product);
    handleCloseClick();
  };

  const variantProps = {
    onClose: handleCloseClick,
    styles: defaultStyles,
  };
  if (variant === "search") {
    variantProps.styles = searchStyles;
    if (!isMobile) {
      variantProps.onClose = null;
    }
  }

  return (
    <>
      <ReactSelect
        {...variantProps}
        autoFocus
        activeTab={tabValue}
        components={{ MenuList: TabbedMenuList, Option: InputOption }}
        createButtonText="Create a custom input"
        filterOption={filterOption}
        getOptionLabel={_.property("name")}
        getOptionValue={_.property("id")}
        hasError={hasError}
        isClearable
        isLoading={loading}
        menuIsOpen={isMenuOpen}
        onBlur={isMobile ? null : handleCloseClick}
        onChange={handleOnChange}
        onCreateStart={handleOnCreate}
        onInputChange={setSearch}
        onMenuOpen={() => setIsMenuOpen(true)}
        onTabChange={handleTabChange}
        options={options}
        placeholder={`Search for a product\u2026`}
        search={search}
        tabs={tabs}
        value={product || null}
      />

      {showInputModal && (
        <InputModal constraints={unitType ? { unitType } : {}} onClose={handleOnClose} onFinish={handleOnFinish} />
      )}
    </>
  );
};

ProductInputChooser.propTypes = {
  hasError: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  openOnInitialize: PropTypes.bool,
  suggestedOptions: PropTypes.array,
  unitType: PropTypes.oneOf(["COUNT", "VOLUME", "WEIGHT"]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  variant: PropTypes.oneOf(["default", "search"]),
};

ProductInputChooser.defaultProps = {
  filters: {},
  openOnInitialize: false,
  variant: "default",
};

export default ProductInputChooser;
