/* eslint-disable react/display-name */
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";

import useYearFieldCrops from "modules/fields/hooks/useYearFieldCrops";

import Chooser from "components/fl-ui/Chooser";
import wrapOccurrences from "components/fl-ui/util/wrapOccurrences";
import useFieldData from "fields/hooks/useFieldData";

const getLabel = ({ group, name }) => _.compact([name, group]).join(" · ");

const formatOptionLabel =
  (search, commodityId) =>
  (option, { context }) => {
    const fieldWithCrop = option.crops.filter((crop) => crop.commodity.id === commodityId);
    const fieldCropAcreage = fieldWithCrop.length ? fieldWithCrop[0].acreage : "--";
    return context == "menu" ? (
      <>
        <small>{commodityId ? fieldCropAcreage : option.acreage} acres</small>
        {` · `}
        <span>{wrapOccurrences(getLabel(option), search)}</span>
      </>
    ) : (
      option.name
    );
  };

const MarketedCropFieldInput = ({ commodity, disabled, onChange, value, year }) => {
  const [inputValue, setInputValue] = useState("");
  const { fields: fieldsData } = useFieldData();
  const { crops } = useYearFieldCrops(year);

  const fields = useMemo(() => {
    const fieldsList = fieldsData || [];

    return fieldsList.map((field) => ({
      acreage: field.acreage.toFixed(1),
      crops: crops.filter((crop) => crop.field.id === field.id),
      group: field.group ? field.group.name : "",
      id: field.id,
      name: field.name,
      value: field.id,
    }));
  }, [fieldsData]);

  return (
    <Chooser
      formatOptionLabel={formatOptionLabel(inputValue, commodity?.id)}
      getOptionLabel={getLabel}
      getOptionValue={({ id }) => id}
      hideIndicator
      isClearable={false}
      isDisabled={disabled}
      isMulti
      noOptionsMessage={() => "No matching fields. Refine your search or add crops to your fields to see them here"}
      onChange={(selected) => onChange(selected ?? [])}
      onInputChange={(inputValue) => setInputValue(inputValue)}
      options={fields}
      placeholder="Select fields..."
      value={value.map(({ id }) => fields.find((field) => field.id === id))}
    />
  );
};

MarketedCropFieldInput.propTypes = {
  commodity: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.object),
  year: PropTypes.any,
};

export default MarketedCropFieldInput;
