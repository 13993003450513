/**
 * Converts incoming urls to their equivalents that are compatible with the routing logic.
 * @param {String|URL} originalUrl
 * @return {URL}
 */
const translateUrl = (originalUrl) => {
  const url = new URL(originalUrl);
  url.pathname = url.pathname.replace(/^\/app\//, "");

  /*
   * Activity detail urls come in as /activity?id=[:id] and need to be converted to /activity/[:id]
   */
  if (url.pathname === "/activity" && url.searchParams.has("id")) {
    const activityId = url.searchParams.get("id");
    url.searchParams.delete("id");
    url.pathname += `/${activityId}`;
  }

  return url;
};

export default translateUrl;
