import { useQuery } from "@apollo/client";

import { advisorClient as client } from "collection/graphql/client";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";

const useAdvisorQuery = (query, options = {}) => {
  const hasElevatePlus = useEnterpriseFeature("elevate_plus");
  const skip = options.skip || !hasElevatePlus;

  return useQuery(query, {
    ...options,
    client,
    skip,
  });
};

export default useAdvisorQuery;
