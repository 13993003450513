/* eslint-disable react/display-name */
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import leaflet from "leaflet";
import PropTypes from "prop-types";
import React from "react";

import App from "layout/app";
import GeoJsonLayer from "lib/map/layers/GeoJsonLayer";

import {
  getDefaultOptions,
  getDrawToolsOptions,
  getEditToolsOptions,
  getLayerEditSettings,
  getPoints,
  highlightTemporaryLayers,
  style,
} from "fields/utils";

const getShadowCircle = (feature, map) => {
  const { centroid, radius } = feature.properties ?? {};
  if (radius) {
    const shadow = leaflet.circle([...centroid.coordinates].reverse(), { radius });
    shadow.setStyle({ ...style, fill: false }).addTo(map);
    return shadow;
  }

  return null;
};

const withMapDrawing = (WrappedComponent) => {
  class DrawOnMap extends React.Component {
    state = {
      allowVertexRemoval: false,
      defaultZoom: this.props.map.getZoom(),
      prevMinZoom: this.props.map.options.minZoom,
      layer: null,
      points: 0,
    };

    removeVertexValidation = () => this.state.allowVertexRemoval;

    applyMapSettings = (map, pattern) => {
      const toolButtonNames = ["Circle", "Cut", "Drag", "Polygon", "Rectangle", "Removal"];
      const customStyles = { ...style };

      if (pattern) {
        pattern.addTo(map);
        customStyles.fillPattern = pattern;
      }

      map.pm.setPathOptions(customStyles);
      toolButtonNames.forEach((name) => map.pm.Toolbar.changeActionsOfControl(name, []));

      if (map.pm.Toolbar?.buttons?.removalMode?._button) {
        // https://github.com/geoman-io/leaflet-geoman/issues/732#issuecomment-749095636
        map.pm.Toolbar.buttons.removalMode._button.className = "leaflet-pm-icon-trash";
        map.pm.Toolbar.buttons.removalMode._button.title = "Remove shape";
      }

      if (!map.pm.Toolbar.getControlOrder().includes("removePoint")) {
        map.pm.Toolbar.createCustomControl({
          block: "edit",
          className: "leaflet-pm-icon-delete",
          name: "removePoint",
          onClick: () => this.state.layer?.pm?.enable(this.removeVertexValidation),
          title: "Remove point",
        });
      }

      map.on("pm:buttonclick", ({ btnName }) => {
        if (btnName === "removePoint" || this.state.allowVertexRemoval) {
          this.setState(({ allowVertexRemoval }) => ({ allowVertexRemoval: !allowVertexRemoval }));
        }
      });
    };

    componentDidMount() {
      const { feature, map, pattern } = this.props;
      // Uncomment when multipolygon is supported
      // map.pm.addControls(getDefaultOptions());

      // force re-draw if anything other than a polygon (eg. point fields)
      if (feature?.geometry?.type?.match(/Polygon/)) {
        // strip out radius attribute to prevent automatically drawing the circumference
        const featureArea = { ...feature, properties: { ...feature.properties, radius: null } };
        const shape = new GeoJsonLayer({ feature: featureArea, isOutline: true, style }).addTo(map);
        const layer = shape.layers;
        this.setUpLayer(layer, getShadowCircle(feature, map));
      } else {
        this.setUpDrawMode();
      }

      this.applyMapSettings(map, pattern);
    }

    componentDidUpdate(prevProps) {
      const { drawTool = "Polygon", feature, map } = this.props;

      // when editing existing shapes
      if (prevProps.feature?.geometry && !feature?.geometry) {
        map.pm.enableDraw(drawTool, { snappable: true });
      }
    }

    componentWillUnmount() {
      const { map } = this.props;
      map.pm.removeControls();
      map.pm.disableDraw();
      this.state.layer && this.state.layer.remove();
      this.state.shadowCircle && this.state.shadowCircle.remove();
      map.off("pm:buttonclick");
      map.off("pm:create");
      map.off("pm:drawstart");
      map.options.minZoom = this.state.prevMinZoom;
      map.setZoom(this.state.defaultZoom);
    }

    addCommonLayerEvents = (layer, shadowCircle) => {
      layer.on("pm:cut", (e) => {
        shadowCircle && shadowCircle.remove();
        layer.off("pm:edit");
        layer.remove();
        this.setState({ layer: null }, () => {
          this.handleLayerEdit(e.layer);
          this.setUpLayer(e.layer);
        });
      });

      layer.on("pm:remove", () => {
        this.setUpDrawMode();
      });
    };

    clearSelfIntersection = (layer) => {
      App.notify("Self-intersecting shapes are invalid. Please try again.");
      layer.setStyle({ color: "red" });
      setTimeout(() => {
        layer.remove();
        this.setUpDrawMode();
      }, 2000);
    };

    handleLayerCreate = () => {
      const { map, onChange, sanitizeLayer } = this.props;

      map.on("pm:create", ({ layer }) => {
        if (layer.pm.hasSelfIntersection && layer.pm.hasSelfIntersection()) {
          // If user somehow manages to create a self intersecting layer despite the _removeLastVertex safeguard 🤷🏽‍♂️
          this.clearSelfIntersection(layer);
        } else {
          const isCircle = layer.pm.getShape()?.toLowerCase() === "circle";
          const newLayer = isCircle ? leaflet.PM.Utils.circleToPolygon(layer, 32) : layer;
          layer.remove();

          const sanitizedData = sanitizeLayer(newLayer);
          const { geometry, layer: validLayer, temporaryFeatures } = sanitizedData;
          geometry && validLayer.addTo(map);

          const updateLayer = () => {
            // if layer is circle and unmutated by intersection
            if (isCircle && validLayer === newLayer) {
              const circleGeometry = {
                ...validLayer.toGeoJSON(),
                properties: { centroid: layer.toGeoJSON().geometry, radius: layer.getRadius() },
              };
              layer.setStyle({ fill: false });
              layer.addTo(map);
              onChange(geometry, circleGeometry);
              this.setUpLayer(validLayer, layer);
            } else {
              onChange(geometry);
              this.setUpLayer(geometry && (validLayer?.layers ?? validLayer));
            }
          };

          if (temporaryFeatures) {
            highlightTemporaryLayers(sanitizedData, map, updateLayer);
          } else {
            updateLayer();
          }
        }
      });
    };

    handleLayerEdit = (newLayer, shadowCircle) => {
      const { map, onChange, sanitizeLayer } = this.props;
      const sanitizedData = sanitizeLayer(newLayer);
      const { geometry, layer, temporaryFeatures } = sanitizedData;
      const hasCoordinates = geometry?.coordinates.length > 1 || geometry?.coordinates[0][0];
      const newGeometry = hasCoordinates ? geometry : null;

      const updateLayer = () => {
        const geometryData = [newGeometry];
        shadowCircle &&
          layer === newLayer &&
          geometryData.push({
            ...newLayer.toGeoJSON(),
            properties: { centroid: shadowCircle.toGeoJSON().geometry, radius: shadowCircle.getRadius() },
          });
        onChange(...geometryData);
      };

      if (temporaryFeatures) {
        newLayer.remove();
        newGeometry && layer.addTo(map);

        this.setState({ layer: null }, () => {
          highlightTemporaryLayers(sanitizedData, map, updateLayer);
          this.setUpLayer(geometry && (layer?.layers ?? layer));
        });
      } else {
        updateLayer();
      }
    };

    setUpCircleLayer = (layer, shadowCircle) => {
      const { map, sanitizeLayer } = this.props;
      layer.pm.setOptions({ allowEditing: false, allowRotation: false, draggable: false });
      layer.pm.disableLayerDrag && layer.pm.disableLayerDrag();
      shadowCircle.pm.enable();
      shadowCircle.on("pm:edit", () => {
        layer.remove();
        const newLayer = leaflet.PM.Utils.circleToPolygon(shadowCircle, 32).setStyle({ fill: true });
        this.setState({ layer: null }, () => {
          newLayer.addTo(map);
          this.handleLayerEdit(newLayer, shadowCircle);

          if (sanitizeLayer(newLayer).layer !== newLayer) {
            // It is now a polygon after an overlap / intersection mutation, remove shadow
            shadowCircle.remove();
          } else {
            // Disable shadowCircle events and pass it to setUpLayer so that the right events are added to both layers
            shadowCircle.off("pm:edit pm:remove");
            this.setUpLayer(newLayer, shadowCircle);
          }
        });
      });

      shadowCircle.on("pm:remove", () => {
        layer?.remove();
        this.setUpDrawMode();
      });
    };

    setUpLayer = (layer, shadowCircle) => {
      const { map, options } = this.props;

      if (!layer) {
        this.setUpDrawMode();
      } else if (!this.state.layer) {
        map.pm.addControls(getEditToolsOptions(options.editOptions));
        this.setState({ layer, shadowCircle });

        if (shadowCircle) {
          this.setUpCircleLayer(layer, shadowCircle);
        } else {
          layer.pm.enable(getLayerEditSettings(this.removeVertexValidation));
          layer.pm.setOptions({ allowEditing: true, allowRotation: false, draggable: true });
          layer.on("pm:dragend pm:rotatedisable", () => {
            layer.pm.enable(getLayerEditSettings(this.removeVertexValidation));
          });

          layer.on("pm:vertexadded pm:vertexremoved", ({ type }) => {
            if (type === "pm:vertexadded" && this.state.allowVertexRemoval) {
              // This stops the click event from passing validation to remove newly added vertex.
              this.setState({ allowVertexRemoval: false }, () => {
                map.pm.Toolbar?.buttons?.removePoint?.toggle();
              });
            }

            this.setState(({ points }) => ({ points: getPoints(type, points) }));
          });

          layer.on("pm:edit", (e) => {
            if (e.layer.pm.hasSelfIntersection()) {
              this.clearSelfIntersection(e.layer);
            } else {
              this.handleLayerEdit(e.layer);
            }
          });
        }

        this.addCommonLayerEvents(layer, shadowCircle);
      }
    };

    setUpDrawMode = () => {
      const { drawTool = "Polygon", map, onChange, options } = this.props;
      onChange(null);
      map.off("pm:drawstart");
      map.off("pm:create");
      this.state.shadowCircle && this.state.shadowCircle.remove();
      this.state.layer && this.state.layer.remove();
      this.setState({ layer: null, points: 0, shadowCircle: null });
      map.getContainer().classList.add("crosshair-cursor");

      map.on("pm:drawstart", ({ workingLayer, shape }) => {
        if (shape === "Rectangle") {
          map.once("click", () => this.setState({ points: 4 }));
        }

        workingLayer.on("pm:vertexadded pm:centerplaced", ({ shape }) => {
          if (shape === "Polygon" && workingLayer.pm.hasSelfIntersection()) {
            map.pm.Draw.Polygon._removeLastVertex();
          } else {
            this.setState(({ points }) => ({ points: points + 1 }));
          }
        });
      });

      map.pm.enableDraw(drawTool, { snappable: true });
      this.handleLayerCreate();
      map.pm.addControls(getDrawToolsOptions(options.drawOptions));
    };

    render() {
      return WrappedComponent ? (
        <WrappedComponent {...this.props} onClear={this.setUpDrawMode} points={this.state.points} />
      ) : null;
    }
  }

  DrawOnMap.propTypes = {
    feature: PropTypes.shape({
      geometry: PropTypes.object,
      properties: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      }),
    }),
    map: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.object,
    sanitizeLayer: PropTypes.func,
  };

  DrawOnMap.defaultProps = {
    options: {},
    sanitizeLayer: (layer) => ({ geometry: layer.toGeoJSON().geometry, layer }),
  };

  return DrawOnMap;
};

export default withMapDrawing;
