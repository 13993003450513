import { useQuery } from "@apollo/client";
import { withCashContractFormContext } from "contracts/form/CashContractForm";
import PropTypes from "prop-types";
import React, { useState } from "react";
import ReactSelect from "react-select";

import useEditCashContract from "collection/graphql/contracts/hooks/useEditCashContract";
import { GET_ALL_MARKETED_CROPS } from "collection/graphql/marketing";
import EventChannel from "lib/events";

import { Button } from "components/fl-ui";
import { Form } from "components/fl-ui/Form";
import { CloseX } from "components/fl-ui/Icons";
import { Modal, ModalFooter, ModalHeader, ModalTitle } from "components/fl-ui/Modal/Modal";
import ModalActionButtonGroup from "components/fl-ui/Modal/ModalActionButtonGroup";

const toastChannel = EventChannel.getChannel("toast");

const AssignToCropModal = withCashContractFormContext(({ contract, onClose, onOpenCropEditModal }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const editContract = useEditCashContract();

  const handleSubmit = async () => {
    const fields = {
      buyer: contract?.buyer,
      commodityId: contract?.commodity.id,
      contractDate: contract.contractDate,
      contractNumber: contract?.contractNumber,
      contractPrice: contract.contractPrice,
      contractStatus: contract.contractStatus,
      contractType: contract.contractType,
      cropYear: contract.cropYear,
      dataSource: contract.dataSource,
      id: contract.id,
      marketedCropId: selectedOption,
      quantity: contract.quantity,
      quantityCanceled: contract?.quantityCanceled,
      quantityDelivered: contract?.quantityDelivered,
    };

    let success;
    try {
      await editContract(fields);
      success = true;
    } catch (error) {
      success = false;
    }

    const toastMessage = success
      ? "Success: Contract has been assigned to a marketing crop"
      : "ERROR: Marketing crop was not assigned, please try again.";
    const toastStatus = success ? "success" : "error";

    toastChannel.fire(toastStatus, { message: toastMessage });

    onClose();
  };

  const { data, loading, refetch } = useQuery(GET_ALL_MARKETED_CROPS);
  const marketedCrops = data?.marketedCrops || [];
  const commodityFilter = contract.commodity.id ? ({ commodity: { id } }) => id === contract.commodity.id : () => true;
  const yearFilter = contract.cropYear ? ({ year }) => year === contract.cropYear : () => true;

  const options = marketedCrops
    .filter(commodityFilter)
    .filter(yearFilter)
    .map((marketedCrop) => {
      return { label: marketedCrop.name, value: marketedCrop.id };
    });

  // need to refetch on every render/popup since the user could have just created a new crop
  refetch();

  return (
    <Modal width={500}>
      <ModalHeader style={{ borderBottom: "none" }}>
        <ModalTitle>Assign to Marketing Crop</ModalTitle>
        <CloseX onClick={onClose} />
      </ModalHeader>

      {!loading && (
        <div style={{ padding: "0 1.5rem" }}>
          {options.length > 0 ? (
            <Form id="assignCropForm" layout="flow-vertical" onSubmit={handleSubmit} preventDefault>
              <ReactSelect
                isClearable
                onChange={(option) => setSelectedOption(option?.value ?? null)}
                options={options}
                placeholder="Select Marketing Crop"
              />
            </Form>
          ) : (
            <div>
              You do not have any {contract.commodity.name.toLowerCase()} marketing crops set up for the{" "}
              {contract.cropYear} crop year.{" "}
              <Button color="primary" link onClick={onOpenCropEditModal}>
                Create a marketing crop{" "}
              </Button>{" "}
              or adjust the crop year on your contract.
            </div>
          )}
        </div>
      )}

      <ModalFooter>
        <ModalActionButtonGroup>
          {options.length > 0 && (
            <Button
              form="assignCropForm"
              className="save-button"
              color="primary"
              disabled={!selectedOption}
              type="submit"
            >
              Save
            </Button>
          )}
          <Button color="default" onClick={onClose} type="button">
            {options.length > 0 ? "Cancel" : "Close"}
          </Button>
        </ModalActionButtonGroup>
      </ModalFooter>
    </Modal>
  );
});

AssignToCropModal.propTypes = {
  contract: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onOpenCropEditModal: PropTypes.func.isRequired,
};

export default AssignToCropModal;
