import { gql } from "@apollo/client";

export default gql`
  fragment ImplementDetail on Implement {
    id
    equipmentType @client {
      name
    }
    icon
    implementType {
      id
      name
      activityTypes
    }
    isRented
    manufactureYear
    name
    notes
  }
`;
