import Backbone from "backbone";
import "backbone.validation";
import moment from "moment";

import bins from "collection/bins";
import buyers from "collection/buyers";
import { convert } from "lib/units";

const allNodes = { bin: bins, buyer: buyers };

export default Backbone.Model.extend({
  urlRoot: "/api/inventory_transactions",

  defaults() {
    return { date: moment().format("YYYY-MM-DD") };
  },

  validation: {
    amount: [
      {
        required: true,
        pattern: "positiveNumbers",
      },
    ],
    commodity_id: [
      {
        required: true,
        msg: "Commodity is required",
        pattern: "digits",
      },
    ],
    date: {
      required: true,
      fn(val) {
        if (!moment(val, "YYYY-MM-DD", true).isValid()) {
          return "Invalid Date (M/D/YYYY)";
        }
      },
    },

    moisture: { required: false, pattern: "number", range: [0, 100] },
    price: { required: false, pattern: "number" },
  },

  filter: {
    amount: "float",
    moisture: "float",
    price: "float",
  },

  getNodeDetails(inventory_node_id, getCropByInventoryNode) {
    const crop = getCropByInventoryNode && getCropByInventoryNode(inventory_node_id);

    if (crop) {
      return { node: crop, path: `resources/crops/${crop.id}`, type: "crop" };
    } else {
      for (const nodeType in allNodes) {
        const node = allNodes[nodeType].findWhere({ inventory_node_id });
        if (node) {
          return { node, path: nodeType === "bin" && `inventory/bin/${node.id}`, type: nodeType };
        }
      }
    }
  },

  getSource(getCropByInventoryNode) {
    return this.getNodeDetails(this.get("source_id"), getCropByInventoryNode)?.node;
  },

  getDestination() {
    return this.getNodeDetails(this.get("destination_id"))?.node;
  },

  getAmountLbs(bushelSize) {
    switch (this.get("amount_unit")) {
      case "bu":
        return this.get("amount") * (bushelSize || 60);
      case "lbs":
        return this.get("amount");
      default:
        return convert(this.get("amount"), this.get("amount_unit"), "lb");
    }
  },

  // Formats date to a user friendly format,
  // e.g. today, yesterday, last Tuesday for things in the last week
  //      Month Day, for things in the last year
  //      Month Day, Year for things in other years
  formatDate() {
    const date = moment(this.get("date")).format("YYYY-MM-DD");
    const now = moment().format("YYYY-MM-DD");

    if (moment().subtract(1, "days").format("YYYY-MM-DD") <= date && now >= date) {
      return moment(date).calendar();
    } else if (moment(date).format("YYYY") === moment().format("YYYY")) {
      return moment(date).format("MMM D");
    } else {
      return moment(date).format("MMM D, YYYY");
    }
  },
});
