import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";

import useKeyedList from "hooks/useKeyedList";

import { Button } from "components/fl-ui";
import { Cluster, Stack } from "components/fl-ui/Layout";
import ProductChooser from "components/product/ProductChooser";
import RecipeChooser from "components/product_recipe/chooser";

const ActivityInputChooser = ({ disableArea = false, inputs, onChange }) => {
  const inputList = useKeyedList(inputs, onChange);
  const defaultInput = () => ({
    area: null,
    areaUnit: "ACRE",
    amount: null,
    amountRate: "",
    amountUnit: "",
    product: undefined,
    unitType: "",
  });

  return (
    <Stack as="form" noValidate>
      {inputList.map((input, key) => {
        return (
          <ProductChooser
            disableArea={disableArea}
            key={key}
            input={input}
            inputType="ACTIVITY"
            onChange={(input) => inputList.update(key, input)}
            onRemove={() => inputList.remove(key)}
          />
        );
      })}

      <Cluster>
        <Button color="secondary" onClick={() => inputList.append(defaultInput())}>
          Add input
        </Button>

        <div style={{ display: "inline-block", verticalAlign: "top" }}>
          <RecipeChooser
            placeholder="Add mix"
            onSelect={(selectedRecipe) => {
              const inputs = inputList.map((input) => input);
              const ingredientInputs = selectedRecipe.ingredients.map((ingredient) => ({
                ..._.omit(ingredient, ["id"]),
                amountRate: ingredient.rateUnit,
                product: ingredient.productId,
                unitType: ingredient.product.commodity?.seedUnitType ?? ingredient.product.unitType,
              }));

              onChange(inputs.concat(ingredientInputs));
            }}
          />
        </div>
      </Cluster>
    </Stack>
  );
};

ActivityInputChooser.propTypes = {
  disableArea: PropTypes.bool,
  inputs: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ActivityInputChooser;
