import PropTypes from "prop-types";
import React, { Children } from "react";
import styled from "styled-components";

import { Button } from "components/fl-ui";
import { Spacing } from "components/fl-ui/constants";

/**
 * Expects 1 to 3 children
 */
const ModalActionButtonGroupUI = styled.div`
  display: flex;
  gap: ${Spacing.regular};
  flex-direction: row-reverse;

  ${({ variant }) =>
    variant === "spread" &&
    `
    > :nth-child(3) {
      margin-right: auto;
    }
  `}

  @media only screen and (max-width: 60rem) {
    display: flex;
    flex-direction: column;
    gap: ${Spacing.xsmall};
    > :nth-child(3) {
      margin-right: unset;
    }
  }
`;

ModalActionButtonGroupUI.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  variant: PropTypes.oneOf(["clustered", "spread"]).isRequired,
};

/**
 * ModalActionButtonGroup can be used one of two ways.
 *
 * If the apply, cancel, and danger props are used these buttons are automatically added
 * in the correct order to the containing element.
 *
 * If the children prop is passed buttons can be passed explicitly. One, two, or three children
 * can be provided. In this case child order is important:
 *    - Primary action button should be the first child
 *    - Secondary action button should be the second child
 *    - Delete action button should be the third child. The `variant` prop dictates this button's placement.
 */
const ModalActionButtonGroup = ({ apply, cancel, children, danger, variant = "spread", ...rest }) => {
  const totalChildren = Children.count(children);
  if (totalChildren >= 1 && totalChildren <= 3) {
    return (
      <ModalActionButtonGroupUI {...rest} variant={variant}>
        {children}
      </ModalActionButtonGroupUI>
    );
  }

  return (
    <ModalActionButtonGroupUI variant={variant}>
      <Button color="primary" disabled={apply.disabled} onClick={apply.action}>
        {apply.buttonText}
      </Button>

      {cancel && (
        <Button color="primary" disabled={cancel.disabled} hollow onClick={cancel.action}>
          {cancel.buttonText}
        </Button>
      )}

      {danger && (
        <Button color="danger" disabled={danger.disabled} hollow onClick={danger.action}>
          {danger.buttonText}
        </Button>
      )}
    </ModalActionButtonGroupUI>
  );
};

const buttonShape = PropTypes.shape({
  action: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
});

ModalActionButtonGroup.propTypes = {
  apply: buttonShape,
  cancel: buttonShape,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  danger: buttonShape,
  /**
   * Defaults to "spread" which left-aligns the delete button. If "clustered", the delete button aligns next to the cancel button.
   */
  variant: PropTypes.oneOf(["clustered", "spread"]),
};

export default ModalActionButtonGroup;
