import { useQuery } from "@apollo/client";
import React, { useMemo } from "react";

import getFieldCropsAndActivities from "collection/graphql/activities/getFieldCropsAndActivities";
import marketingClient from "collection/graphql/client/marketingClient";

import { buildSummaryData } from "components/field/weather/lib";

/**
 * @param {Number} fieldId
 * @param {Number} cropYear
 * @param {String} caller used for debugging network requests
 * @return {WeatherSummaryResponse}
 *
 * @typedef {Object} WeatherSummaryResponse
 * @property {WeatherSummary} [data]
 * @property {Boolean} loading
 *
 * @typedef {Object} WeatherSummary
 * @property {Object} centroid
 * @property {Number} cropYear
 * @property {String} endOfCropYear
 * @property {Number} fieldId
 * @property {Number} gddTbase
 * @property {Number} gddTceil
 * @property {Boolean} hasPlantedInYear
 * @property {String} plantingDate
 * @property {String} startOfCropYear
 */
const useWeatherSummary = (fieldId, cropYear, caller) => {
  const {
    data: summaryData,
    loading,
    ...rest
  } = useQuery(getFieldCropsAndActivities, {
    client: marketingClient,
    fetchPolicy: "cache-and-network",
    variables: { cropYear, fieldId, caller },
  });

  const data = useMemo(() => {
    return summaryData ? buildSummaryData(summaryData, cropYear) : {};
  }, [summaryData]);

  return { data, loading, ...rest };
};

export default useWeatherSummary;
