import { gql } from "@apollo/client";

import { FINANCIAL_ACCOUNT_FRAGMENT } from "collection/graphql/profit_and_loss/fragments";

export default gql`
  query GetFinancialAccountsData {
    accountList: getFinancialAccounts {
      ...Account
    }
  }
  ${FINANCIAL_ACCOUNT_FRAGMENT}
`;
