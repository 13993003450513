/* eslint-disable react/display-name */
import React from "react";

const asAgreementForm =
  (WrappedComponent, { validator }) =>
  (props) => {
    const errors = {};
    const componentProps = {
      ...props,
      isValid: validator.isValid(props.fields, errors),
    };
    if (!componentProps.isValid) {
      componentProps.errors = errors;
    }

    return <WrappedComponent {...componentProps} />;
  };

export default asAgreementForm;
