import styles from "./styles";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";

import { Button } from "components/fl-ui";
import Popover from "components/fl-ui/Popover/Popover";
import { css } from "components/fl-ui/aphrodite";

/**
 * @param {Object} props
 * @param {Boolean} [props.bypassEventHandling=false] Clicking on a popover option calls `preventDefault`
 *  on the event by default. Passing this flag disables this behavior.
 * @param {Function} [props.onSelect] `onSelect` callbacks can either be passed per option or a global
 *  one can be provided. If neither callback is present an error will be thrown when the option is selected.
 * @param {Function} [props.optionRenderer] A custom renderer for each popover option. If not provided
 *  the default one will be used.
 */
const PopoverButton = ({
  bypassEventHandling,
  children,
  onClick,
  onSelect: onGlobalSelect,
  options,
  optionRenderer,
  primary,
  size,
}) => {
  const handleClick = (event) => {
    if (onClick) {
      onClick(event);
    } else {
      event.preventDefault();
    }
  };

  const renderer =
    optionRenderer ||
    ((option, i) => {
      const buttonProps = {
        key: `menuItem${i}`,
        children: option.label,
        className: css(styles.popoverOption),
        color: option.color || "primary",
        icon: option.icon,
        iconRight: !!option.icon,
        link: true,
        onClick: (event) => {
          if (!bypassEventHandling) {
            event.preventDefault();
          }

          const selectedOption = options[i];
          if (_.isFunction(selectedOption.onSelect)) {
            selectedOption.onSelect(event);
          } else if (_.isFunction(onGlobalSelect)) {
            onGlobalSelect(selectedOption, event);
          } else {
            throw new Error("An onSelect prop is expected on either the option or the popover. Neither was provided.");
          }
        },
      };

      return <Button {...buttonProps} />;
    });

  return (
    <Popover content={options.map(renderer)}>
      {children || (
        <Button
          className={css(
            styles.popoverIconButton,
            size === "lg" && styles.popoverIconButtonLarge,
            primary && styles.popoverIconButtonPrimary
          )}
          hollow
          icon="overflowHorizontal"
          onClick={handleClick}
          type="button"
        />
      )}
    </Popover>
  );
};

const optionShape = PropTypes.shape({
  color: PropTypes.oneOf(["primary", "danger"]),
  label: PropTypes.string.isRequired,
  onSelect: PropTypes.func,
});

PopoverButton.propTypes = {
  bypassEventHandling: PropTypes.bool,
  children: PropTypes.any,
  onClick: PropTypes.func,
  onSelect: PropTypes.func,
  options: PropTypes.arrayOf(optionShape).isRequired,
  optionRenderer: PropTypes.func,
  primary: PropTypes.bool,
  size: PropTypes.oneOf(["sm", "md", "lg"]),
};

PopoverButton.defaultProps = {
  bypassEventHandling: false,
  primary: false,
  size: "md",
};

export { optionShape, styles };
export default PopoverButton;
