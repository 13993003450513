import _ from "lodash";

/**
 * @param {CashContract} contract
 * @private
 * @return {CashContractInput}
 */
const buildCashContractInput = (contract) => {
  const cashContractInput = _.pick(contract, [
    "basis",
    "buyer",
    "commodityId",
    "contractDate",
    "contractNumber",
    "contractPrice",
    "contractStatus",
    "contractType",
    "cropYear",
    "deliveryEndDate",
    "deliveryStartDate",
    "enabled",
    "marketedCropId",
    "maxPrice",
    "minPrice",
    "notes",
    "premium",
    "pricingWindowEnd",
    "pricingWindowStart",
    "quantity",
    "quantityCanceled",
    "quantityDelivered",
    "quantityString",
  ]);

  if (contract.contractSubtype && contract.contractSubtype !== cashContractInput.contractType) {
    cashContractInput.contractType = contract.contractSubtype;
  }

  if (contract.underlying?.symbol) {
    cashContractInput.underlying = contract.underlying.symbol;
  }

  //Contract forms should send marketedCropId or commodityId, never both
  if (cashContractInput.marketedCropId) {
    cashContractInput.commodityId = null;
  }

  return cashContractInput;
};

export default buildCashContractInput;
