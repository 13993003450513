/**
 * Filters an array of activities by field id.
 *
 * @param {Activity[]} activities
 * @param {Object} filters
 * @param {Object[]} filters.equipment
 * @param {"Implement"|"Tractor"} filters.equipment[].__typename
 * @param {Number} filters.equipment[].id
 * @param {Function} markAsApplied
 * @return {Activity[]}
 */
const equipmentFilter = (activities, { equipment }, markAsApplied) => {
  if (equipment.length > 0) {
    const implementIds = new Map();
    const tractorIds = new Map();
    equipment.forEach(({ id, __typename }) => {
      if (__typename === "Implement") {
        implementIds.set(id, true);
      } else {
        tractorIds.set(id, true);
      }
    });

    activities = activities.filter((activity) => {
      const hasMatchingImplement = activity.implements.some(({ id }) => implementIds.has(id));
      if (!hasMatchingImplement) {
        const hasMatchingTractor = activity.tractors.some(({ id }) => tractorIds.has(id));
        return hasMatchingTractor;
      }

      return hasMatchingImplement;
    });

    markAsApplied();
  }

  return activities;
};

export default equipmentFilter;
