import ProductFilterBar from "products/components/ProductFilterBar";
import ProductFilterBlankSlate from "products/components/ProductFilterBlankSlate";
import ProductFilterModal from "products/components/ProductFilterModal";
import ProductIndexBlankSlate from "products/components/ProductIndexBlankSlate";
import ProductPageHeader from "products/components/ProductPageHeader";
import ProductPurchaseSummaries from "products/components/ProductPurchaseSummaries";
import ProductPurchasesSummary from "products/components/ProductPurchasesSummary";
import PurchaseFormModal from "products/containers/PurchaseFormModal";
import useProductIndexData from "products/hooks/useProductIndexData";
import React, { useState } from "react";

import { useFeatureAndPermissionAccess } from "hooks/useFeatureAndPermissionAccess";

import PayGateAdBanner from "components/advertisements/banners/PayGateAdBanner";
import { Container, Stack } from "components/fl-ui/Layout";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import { RestrictedAccessWithHeader } from "components/fl-ui/RestrictedAccessWithHeader";

const NO_FILTER_MATCH = "NO_FILTER_MATCH";
const NO_PURCHASES = "NO_PURCHASES";

const ProductIndexPage = () => {
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [purchaseToEdit, setPurchaseToEdit] = useState(null);

  const { loadingAccess, showPaygate, showRestricted, showContent } = useFeatureAndPermissionAccess({
    featureName: "products",
    permissionName: "inputs",
  });

  const handlePaygate = () => router.navigate("billing", { trigger: true });

  const {
    cropYear,
    commodities,
    filters,
    hasDataLoaded,
    onFilterChange,
    refreshQuery,
    resetFilters,
    purchaseSummaries,
    totalPurchases,
  } = useProductIndexData();
  let shownBlankSlate = "";
  if (hasDataLoaded) {
    if (totalPurchases === 0) {
      shownBlankSlate = NO_PURCHASES;
    } else if (purchaseSummaries.length === 0) {
      shownBlankSlate = NO_FILTER_MATCH;
    }
  }

  const handleCreate = (defaults = {}) => {
    setPurchaseToEdit(defaults);
  };

  const handleFilterChange = (updatedFilters) => {
    setShowFilterModal(false);
    onFilterChange(updatedFilters);
  };

  const handleFilterReset = () => {
    setShowFilterModal(false);
    resetFilters();
  };

  if (showRestricted) {
    return <RestrictedAccessWithHeader title="Inputs" />;
  }

  return (
    <Container>
      <LoadingWrapper isLoading={loadingAccess}>
        <Stack>
          {showPaygate && (
            <>
              <ProductPageHeader onCreatePurchase={handleCreate} />
              <PayGateAdBanner onClick={handlePaygate} />
            </>
          )}
          {showContent && (
            <>
              <ProductPageHeader onCreatePurchase={handleCreate} />
              {shownBlankSlate === NO_PURCHASES && <ProductIndexBlankSlate onCreatePurchase={handleCreate} />}

              {shownBlankSlate !== NO_PURCHASES && (
                <>
                  <ProductFilterBar
                    onChange={onFilterChange}
                    onFilterButtonClick={() => setShowFilterModal(true)}
                    filters={filters}
                  />

                  <LoadingWrapper isLoading={!hasDataLoaded}>
                    <ProductPurchasesSummary purchaseSummaries={purchaseSummaries} totalPurchases={totalPurchases} />

                    {shownBlankSlate === NO_FILTER_MATCH ? (
                      <ProductFilterBlankSlate />
                    ) : (
                      <ProductPurchaseSummaries purchaseSummaries={purchaseSummaries} onCreatePurchase={handleCreate} />
                    )}
                  </LoadingWrapper>
                </>
              )}
            </>
          )}
        </Stack>
      </LoadingWrapper>

      {showFilterModal && (
        <ProductFilterModal
          commodities={commodities}
          filters={filters}
          onChange={handleFilterChange}
          onClear={handleFilterReset}
          onClose={() => setShowFilterModal(false)}
          purchaseSummaries={purchaseSummaries}
        />
      )}

      {purchaseToEdit && (
        <PurchaseFormModal
          cropYear={cropYear}
          onClose={() => setPurchaseToEdit(null)}
          onSaveSuccess={() => {
            refreshQuery();
            setPurchaseToEdit(null);
          }}
          purchase={purchaseToEdit}
        />
      )}
    </Container>
  );
};

export default ProductIndexPage;
