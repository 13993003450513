import PropTypes from "prop-types";
import React from "react";

import Container from "components/fl-ui/Layout/Container";
import Content from "components/fl-ui/Layout/Content";
import ContentMain from "components/fl-ui/Layout/ContentMain";
import Header from "components/fl-ui/Layout/Header";
import RestrictedAccessBlankslate from "components/fl-ui/RestrictedAccessBlankslate";

const RestrictedAccessWithHeader = ({ title }) => (
  <Container>
    <Header title={title} />
    <Content>
      <ContentMain fullWidth>
        <RestrictedAccessBlankslate />
      </ContentMain>
    </Content>
  </Container>
);

RestrictedAccessWithHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export { RestrictedAccessWithHeader };
