import moment from "moment";
import numeral from "numeral";
import ProductCard from "products/components/ProductCard";
import PropTypes from "prop-types";
import React from "react";
import router from "router";

import ProductPurchaseUnit from "components/product/ProductPurchaseUnit";

const PurchaseFooter = ({ purchase, purchaseSummary }) => {
  const source = purchase || purchaseSummary;
  const { costPerUnit } = source;
  const amount = purchase?.amountTotal || purchaseSummary.amountPurchasedTotal;
  const date = purchase?.date;

  return (
    <span>
      <ProductPurchaseUnit source={source} amount={amount} />
      {" @ "}
      <ProductPurchaseUnit source={source} amount={numeral(costPerUnit).format("$0,0.00")} per="unit" />
      {date && ` on ${moment(date).format("MMM D, YY")}`}
    </span>
  );
};

const ProductPurchaseCard = (props) => {
  const { onEdit, purchase, purchaseSummary } = props;
  const { product } = purchase || purchaseSummary;

  return (
    <ProductCard
      body={product.name}
      heading={product.type}
      footer={<PurchaseFooter purchase={purchase} purchaseSummary={purchaseSummary} />}
      onCardClick={() => {
        if (onEdit) {
          onEdit(product);
        } else {
          router.navigate(`products/${product.id}`, { trigger: true });
        }
      }}
    />
  );
};

ProductPurchaseCard.propTypes = {
  onEdit: PropTypes.func,
  purchase: PropTypes.object,
  purchaseSummary: PropTypes.object,
};

export default ProductPurchaseCard;
