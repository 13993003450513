import FieldMap from "map/FieldMap";
import LayersContainer from "map/layers/LayersContainer";

import App from "layout/app";
import SubRoute from "lib/SubRoute";

const MapRouter = SubRoute.extend({
  routes: {
    "": "display",
    ":id": "display",
    ":id/layers": "showLayers",
  },

  async display(id) {
    App.display(FieldMap, { id: +id, screenName: "Map" });
    App.setTitle(id ? "View Field" : "Map");
  },

  async showLayers(id, params) {
    App.display(LayersContainer, {
      id: +id,
      key: "layers",
      defaultLayer: params,
      screenName: "Explorer",
    });
  },
});

export default () => new MapRouter("map", { createTrailingSlashRoutes: true });
