import { isEmpty, sortBy } from "lodash";
import CropFilterModal from "profit_and_loss/components/CropFilterModal";
import CropSummary from "profit_and_loss/components/CropSummary";
import ExpenseRevenueSummary from "profit_and_loss/components/ExpenseRevenueSummary";
import FarmSummary from "profit_and_loss/components/FarmSummary";
import { getFilteredItems, getSearchResults } from "profit_and_loss/utils";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";

import Filters from "components/fl-ui/Filters/Filters";
import RefineFilters from "components/fl-ui/Filters/RefineFilters";

const defaultFilters = { commodities: [], fieldGroups: [], fields: [], overhead: [] };

const FarmDashboard = ({ data }) => {
  const { commodityCrops, costTotal, otherExpenses, otherRevenue, profitTotal, revenueTotal } = data;
  const [filters, setFilters] = useState(defaultFilters);
  const [search, setSearch] = useState("");
  const [showFilterModal, setShowFilterModal] = useState(false);

  const filteredData = useMemo(
    () => getFilteredItems([...commodityCrops, otherExpenses, otherRevenue], filters),
    [commodityCrops, filters]
  );
  const searchedData = useMemo(() => getSearchResults(filteredData, search), [filteredData, search]);
  const sortedData = sortBy(searchedData, ["commodity.name", "name"]);

  return (
    <>
      <Filters filters={filters} search={search} setSearch={setSearch} setShowFilterModal={setShowFilterModal} />
      <FarmSummary costTotal={costTotal} profitTotal={profitTotal} revenueTotal={revenueTotal} />
      {isEmpty(sortedData) && <RefineFilters icon="profitAndLoss" />}

      {sortedData.map((data, index) =>
        data.commodity ? (
          <CropSummary data={data} key={`${data.name}-${index}`} />
        ) : (
          <ExpenseRevenueSummary data={data} key={`${data.name}-${index}`} />
        )
      )}

      {showFilterModal && (
        <CropFilterModal
          commodityCrops={commodityCrops}
          filters={filters}
          onClose={() => setShowFilterModal(false)}
          onFilter={(filter) => setFilters({ ...filters, ...filter })}
          onReset={() => setFilters(defaultFilters)}
        />
      )}
    </>
  );
};

FarmDashboard.propTypes = {
  data: PropTypes.shape({
    commodityCrops: PropTypes.arrayOf(PropTypes.object).isRequired,
    costTotal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    profitTotal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    revenueTotal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }),
};

export default FarmDashboard;
