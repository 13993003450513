import { gql } from "@apollo/client";

export default gql`
  query searchProducts(
    $cropYear: Int
    $popular: Boolean
    $search: String
    $sortCropYear: Int
    $types: [ProductType]
    $unitType: ProductUnitType
  ) {
    popularProducts: getProducts(
      cropYear: $cropYear
      itemsPerPage: 100
      page: 1
      popular: $popular
      search: $search
      sortCropYear: $sortCropYear
      types: $types
      unitType: $unitType
      used: false
    ) {
      ...DetailedProduct
    }

    usedProducts: getProducts(
      cropYear: $cropYear
      itemsPerPage: 100
      page: 1
      search: $search
      sortCropYear: $sortCropYear
      types: $types
      unitType: $unitType
      used: true
    ) {
      ...DetailedProduct
    }
  }
`;
