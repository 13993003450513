import _ from "lodash";
import { isNative } from "mobile/mobileManager";
import ProductPurchaseForm from "products/forms/ProductPurchaseForm";
import usePurchaseFormModal from "products/forms/usePurchaseFormModal";
import PropTypes from "prop-types";
import React, { useEffect } from "react";

import usePermissions from "hooks/usePermissions";
import useViewType from "hooks/useViewType";
import useWindow from "hooks/useWindow";
import App from "layout/app";
import { PRODUCT_LOG_PURCHASE_MODAL_OPEN } from "lib/metrics/events";

import { Button } from "components/fl-ui";
import { CloseX } from "components/fl-ui/Icons";
import { Cluster } from "components/fl-ui/Layout";
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "components/fl-ui/Modal/Modal";

const PurchaseFormModal = ({ cropYear, onClose, onDeleteSuccess, onSaveSuccess, purchase }) => {
  const { formFields, isCreate, isValid, onChange, onDelete, onSave } = usePurchaseFormModal({
    cropYear,
    onDeleteSuccess,
    onSaveSuccess,
    purchase,
  });
  const formId = "productPurchaseForm";
  const canDelete = usePermissions().canDelete("inputs");
  const win = useWindow();
  const scrollOnFocus = useViewType() === "mobile";

  const handleFocus = (event) => {
    if (scrollOnFocus) {
      const scrollTarget = isNative() ? win : win.document.getElementById("purchaseFormModal");
      const top = event.target.getBoundingClientRect().y;
      scrollTarget.scrollTo({ top });
    }
  };

  const handleClose = () => {
    onClose();
    if (isNative()) {
      win.scrollTo({ top: 0 });
    }
  };

  let primaryButtonText = "Update purchase";
  if (isCreate) {
    primaryButtonText = _.size(formFields.purchases) > 1 ? "Log purchases" : "Log purchase";
  }

  const onDeleteClick = () => {
    App.confirm({
      confirm: "Delete purchase",
      title: "Are you sure you want to delete this purchase?",
    }).then(onDelete);
  };

  useEffect(() => {
    PRODUCT_LOG_PURCHASE_MODAL_OPEN.track();
  }, []);

  return (
    <Modal id="purchaseFormModal" width={800}>
      <ModalHeader>
        <ModalTitle>{isCreate ? `Log ${cropYear} Input Purchase` : `Edit ${cropYear} Input Purchase`}</ModalTitle>
        <CloseX onClick={handleClose} />
      </ModalHeader>

      <ModalBody>
        <ProductPurchaseForm
          formFields={formFields}
          formId={formId}
          onChange={onChange}
          onFocus={handleFocus}
          onSubmit={onSave}
        />
      </ModalBody>

      <ModalFooter>
        <Cluster between={!isCreate} end={isCreate}>
          {!isCreate && canDelete && (
            <Button color="danger" link onClick={onDeleteClick} type="button">
              Delete
            </Button>
          )}

          <span>
            <Button color="default" onClick={handleClose} type="button">
              Cancel
            </Button>{" "}
            <Button color="primary" disabled={!isValid} form={formId} type="submit">
              {primaryButtonText}
            </Button>
          </span>
        </Cluster>
      </ModalFooter>
    </Modal>
  );
};

PurchaseFormModal.propTypes = {
  cropYear: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onDeleteSuccess: PropTypes.func,
  onSaveSuccess: PropTypes.func.isRequired,
  purchase: PropTypes.object,
};

PurchaseFormModal.defaultProps = {
  purchase: {},
};

export default PurchaseFormModal;
