import { gql } from "@apollo/client";

const updateMaintenanceRecordMutation = gql`
  mutation updateMaintenanceRecord($input: UpdateMaintenanceRecordInput!) {
    maintenanceRecord: updateMaintenanceRecord(input: $input)
      @rest(type: "MaintenanceRecord", path: "/api/maintenance_records/{args.input.id}", method: "PUT") {
      ...MaintenanceRecord
    }
  }
`;

export default updateMaintenanceRecordMutation;
