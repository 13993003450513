import useContractPermissions from "contracts/hooks/useContractPermissions";
import useContractsIndexPage from "contracts/hooks/useContractsIndexPage";
import AssignToCropModal from "contracts/modals/AssignToCropModal";
import ContractFormModal from "contracts/modals/ContractFormModal";
import PricingAndDeliveryModal from "contracts/modals/PricingAndDeliveryModal";
import CropEditModal from "marketing/components/CropEditModal";
import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";

import PopoverButton from "components/fl-ui/Buttons/PopoverButton";
import { Icon, IconContainer } from "components/fl-ui/Icons";
import { Spacing } from "components/fl-ui/constants";

const Dots = styled(({ className, contract, size }) => {
  const [showCropModal, setShowCropModal] = useState(false);
  const [showEditContractModal, setShowEditContractModal] = useState(false);
  const [showPricingAndDeliveryModal, setShowPricingAndDeliveryModal] = useState(false);
  const [showAssignToCropModal, setShowAssignToCropModal] = useState(false);
  const { deleteContract } = useContractsIndexPage();

  const { canDelete, canWrite } = useContractPermissions();
  const isEditable = canWrite(contract);
  const isDeletable = canDelete(contract);

  if (!isEditable) {
    return null;
  }

  const options = [
    isEditable && {
      label: "Edit",
      onSelect: () => setShowEditContractModal(true),
    },

    contract.marketedCrop === null && {
      label: "Assign to marketing crop",
      onSelect: () => setShowAssignToCropModal(true),
    },

    contract.dataSource === "USER" && {
      label: "Add Pricing & Delivery",
      onSelect: () => setShowPricingAndDeliveryModal(true),
    },

    isDeletable && {
      label: "Delete",
      onSelect: async () => deleteContract(contract.id),
      color: "danger",
    },
  ].filter((x) => x);

  return (
    <div className={className}>
      <PopoverButton options={options}>
        <IconContainer size={size}>
          <Icon className="ellipses" icon="ellipses" />
        </IconContainer>
      </PopoverButton>

      {showEditContractModal && (
        <ContractFormModal
          contractId={contract.id}
          onClose={() => setShowEditContractModal(false)}
          onSave={() => setShowEditContractModal(false)}
        />
      )}

      {showAssignToCropModal && (
        <AssignToCropModal
          contract={contract}
          onClose={() => setShowAssignToCropModal(false)}
          onOpenCropEditModal={() => {
            setShowAssignToCropModal(false);
            setShowCropModal(true);
          }}
        />
      )}

      {showCropModal && (
        <CropEditModal
          marketedCrops={[{ year: contract.cropYear, commodity: contract.commodity }]}
          onClose={() => setShowCropModal(false)}
          onSaveSuccess={() => {
            setShowCropModal(false);
            setShowAssignToCropModal(true);
          }}
        />
      )}

      {showPricingAndDeliveryModal && (
        <PricingAndDeliveryModal contract={contract} onClose={() => setShowPricingAndDeliveryModal(false)} />
      )}
    </div>
  );
})`
  .ellipses {
    cursor: pointer;
    padding: ${Spacing.small} 0;
  }
`;

Dots.propTypes = {
  contract: PropTypes.object.isRequired,
  size: PropTypes.string,
};

export default Dots;
