import _ from "lodash";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import styled from "styled-components";

import useCommodityCustomaryUnits from "collection/graphql/commodities/hooks/useCommodityCustomaryUnits";
import usePermissions from "hooks/usePermissions";

import CommodityFormModal from "components/commodity/CommodityFormModal";
import { Button } from "components/fl-ui";
import CropBadge from "components/fl-ui/CropBadge";
import { BorderRadius, Borders, Spacing } from "components/fl-ui/constants";

const Card = styled.div`
  align-items: center;
  border: ${Borders.regular};
  border-radius: ${BorderRadius.large};
  display: flex;
  gap: 10px;
  padding: ${Spacing.regular};
  > :nth-child(2) {
    flex-grow: 2;
    > * {
      display: block;
    }
  }
`;

const CommodityCard = ({ commodity }) => {
  const [showModal, setShowModal] = useState(false);
  const canWriteCommodities = usePermissions().canWrite("commodities");

  const yieldUnits = useCommodityCustomaryUnits();
  const unit = useMemo(
    () => _.find(yieldUnits, { value: commodity.defaultYieldUnit }),
    [commodity?.defaultYieldUnit, yieldUnits]
  );

  return (
    <Card>
      <CropBadge abbr={commodity.abbr} color={commodity.color} />
      <div>
        <strong>
          {commodity.name} ({commodity.abbr})
        </strong>
        {unit && (
          <small>
            {unit.name} ({unit.abbr})
          </small>
        )}
      </div>

      {canWriteCommodities && (
        <Button color="secondary" onClick={() => setShowModal(true)}>
          Edit
        </Button>
      )}
      {showModal && (
        <CommodityFormModal
          commodityId={commodity.id}
          onClose={() => setShowModal(false)}
          onSave={() => setShowModal(false)}
        />
      )}
    </Card>
  );
};

CommodityCard.propTypes = {
  commodity: PropTypes.shape({
    abbr: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    defaultYieldUnit: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default CommodityCard;
