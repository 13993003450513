import { StyleSheet } from "aphrodite/no-important";

import styleVars from "components/fl-ui/Layout/Navigation/styleVars";
import { GreyColors, UIColors } from "components/fl-ui/colors";
import { Mixins, BorderRadius, Spacing } from "components/fl-ui/constants";

const ARROW_SIZE = 8;
const ARROW_OFFSET = 28;
const arrow = {
  width: 0,
  height: 0,
};

const navStyles = StyleSheet.create({
  // Dropdown/Slide Out Menu
  menuContainerOuter: {
    position: "absolute",
    top: Spacing.xxxlarge,
    right: `-${ARROW_OFFSET - ARROW_SIZE * 1.5}px`,
    zIndex: styleVars.userMenu_zIndex,
    width: "auto",
    height: 0,
    overflow: "hidden",
    padding: `0 ${Spacing.xsmall}`,
    backgroundColor: "transparent",
    "@media only screen and (max-width: 62.5em)": {
      display: "none",
      padding: 0,
    },
  },
  menuContainerOuter_mobile: {
    "@media only screen and (max-width: 62.5em)": {
      display: "block",
    },
  },
  menuContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "auto",
    minWidth: Mixins.toRem(280),
    marginTop: "10px",
    background: styleVars.userMenu_backgroundColor,
    borderRadius: BorderRadius.medium,
    boxShadow: styleVars.userMenu_boxShadow,
    // menu dropdown arrow
    ":before": {
      ...Mixins.beforeAfter,
      ...arrow,
      borderLeft: `${ARROW_SIZE + 1}px solid transparent`,
      borderRight: `${ARROW_SIZE + 1}px solid transparent`,
      borderBottom: `${(ARROW_SIZE + 1) * 1.25}px solid ${GreyColors.smoke90}`,
      top: 0,
      right: `${ARROW_OFFSET - 1}px`,
    },
    ":after": {
      ...Mixins.beforeAfter,
      ...arrow,
      borderLeft: `${ARROW_SIZE}px solid transparent`,
      borderRight: `${ARROW_SIZE}px solid transparent`,
      borderBottom: `${ARROW_SIZE * 1.25}px solid ${styleVars.userMenu_backgroundColor}`,
      top: "2px",
      right: `${ARROW_OFFSET}px`,
    },
    "@media only screen and (max-width: 62.5em)": {
      display: "none",
      marginTop: 0,
    },
  },
  menuContainer_mobile: {
    "@media only screen and (max-width: 62.5em)": {
      display: "block",
      marginTop: "10px",
      width: "100%",
      height: "100%",
      background: styleVars.userMenuMobile_backgroundColor,
      borderRadius: 0,
    },
  },
});

export default navStyles;
