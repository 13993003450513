import _ from "lodash";

const BaseProductTypePolicy = {
  fields: {
    shortDescription: {
      read(__, { readField }) {
        const brand = readField("brand") || readField("manufacturer") || "Generic";
        const output = [brand];

        switch (readField("__typename")) {
          case "ChemicalProduct":
            output.push(_.capitalize(readField("chemicalType")));
            if (readField("restrictedUse")) {
              output.push("(Restricted)");
            }
            break;

          case "FertilizerProduct":
            const npk = [readField("percentNitrogen"), readField("percentPhosphate"), readField("percentPotash")];
            const suffix = npk.includes(null) ? "" : `, ${npk.join("-")}`;
            output.push(_.capitalize(readField("type")) + suffix);
            break;

          case "SeedProduct":
            output.push(_.startCase(readField("commodity")?.name), _.capitalize(readField("type")));
            break;

          case "WaterProduct":
            output.push("Water");
            break;
        }

        return output.filter((x) => x).join(" ");
      },
    },
  },
};

export default BaseProductTypePolicy;
