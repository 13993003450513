import { StyleSheet, css } from "aphrodite/no-important";
import classNames from "classnames";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import React from "react";

import "components/fl-ui/Carousel/carousel.css";
import Icon from "components/fl-ui/Icons/Icon";
import { Mixins } from "components/fl-ui/constants";

const styles = StyleSheet.create({
  icon: {
    width: Mixins.toRem(24),
    height: Mixins.toRem(24),
    maxWidth: Mixins.toRem(24),
    maxHeight: Mixins.toRem(24),
  },
});

class Carousel extends React.Component {
  render() {
    const preventDefault = (e) => e.preventDefault();
    const {
      children,
      totalSlides,
      naturalSlideWidth,
      naturalSlideHeight,
      currentSlide,
      visibleSlides,
      withDots,
      className,
      onScrollClick,
    } = this.props;
    const slideCount = children.length;

    const handleScrollClick = (e, id) => {
      e.preventDefault();
      onScrollClick && onScrollClick(id);
    };

    return (
      <CarouselProvider
        naturalSlideWidth={naturalSlideWidth || 100}
        naturalSlideHeight={naturalSlideHeight || 125}
        totalSlides={totalSlides}
        currentSlide={currentSlide}
        visibleSlides={visibleSlides || 1}
        className={classNames(className, !withDots && "no-dots")}
      >
        {slideCount > 1 && (
          <ButtonBack onClick={(e) => handleScrollClick(e, "back")}>
            <div className={css(styles.icon)}>
              <Icon icon="chevronLeft" />
            </div>
          </ButtonBack>
        )}
        <Slider>{children}</Slider>
        {slideCount > 1 && (
          <ButtonNext onClick={(e) => handleScrollClick(e, "next")}>
            <div className={css(styles.icon)}>
              <Icon icon="chevronRight" />
            </div>
          </ButtonNext>
        )}
        {withDots && slideCount > 1 && <DotGroup onClick={preventDefault} />}
      </CarouselProvider>
    );
  }
}

export { Carousel, Slide };
