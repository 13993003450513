import { Definition, DefinitionList } from "equipment/components/EquipmentDetailSummary";
import PropTypes from "prop-types";
import React from "react";
import { Card, CardBody, CardHeader } from "settings/pages/ProductAndMachineMatching/components/styledComponents";
import styled from "styled-components";

import { Button } from "components/fl-ui";
import { ColorPalette } from "components/fl-ui/colors";

const ProductMatchDefinitionList = styled(DefinitionList)`
  > dt {
    font-size: 11px;
  }
`;

const ProductMatchCard = ({ match, onEdit }) => {
  const { expectedProduct, matchedProduct, search, unitType } = match;
  const hasExpectedMatch = !!expectedProduct;
  const hasSavedMatch = !!matchedProduct;
  const hasNoMatch = !hasExpectedMatch && !hasSavedMatch;

  const buttonProps = {
    children: hasSavedMatch ? "Edit Bushel Farm Match" : "Set Match",
    color: hasSavedMatch ? "primary" : "danger",
    hollow: true,
    onClick: () => onEdit(match),
    style: { width: "100%" },
  };

  const headerHighlightColor = hasNoMatch ? ColorPalette["dark-red"] : null;
  const termTextColor = hasNoMatch ? ColorPalette.white : null;
  if (!hasNoMatch) {
    buttonProps.children = "Edit Match";
    buttonProps.color = "primary";
  }

  return (
    <Card borderColor={headerHighlightColor}>
      <CardHeader bg={headerHighlightColor} color={termTextColor}>
        <ProductMatchDefinitionList>
          <Definition term={<span style={{ color: termTextColor }}>Source Name</span>}>{search || "--"}</Definition>
        </ProductMatchDefinitionList>
      </CardHeader>

      <CardBody>
        <ProductMatchDefinitionList>
          <Definition term="Set Match">{matchedProduct?.name || "--"}</Definition>
          <Definition term="Auto Match">{expectedProduct?.name || "--"}</Definition>
          <Definition term="Product Type">{matchedProduct?.type || "--"}</Definition>
          <Definition term="Measurement Type">{unitType || "--"}</Definition>
        </ProductMatchDefinitionList>

        <Button {...buttonProps} />
      </CardBody>
    </Card>
  );
};

ProductMatchCard.propTypes = {
  match: PropTypes.shape({
    matchedProduct: PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
    }),
    search: PropTypes.string,
    unitType: PropTypes.string,
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default ProductMatchCard;
