import { useMutation } from "@apollo/client";
import InventoryTransaction from "loads/components/InventoryTransaction";
import moment from "moment";
import numeral from "numeral";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { EDIT_FIELD_CROP } from "collection/graphql/fields/mutations";
import Transactions from "collection/inventory_transactions";
import useBackboneCollection from "hooks/useBackboneCollection";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import useUnits from "hooks/useUnits";
import App from "layout/app";

import EditYieldForm from "components/crop/EditYieldForm";

/*
 * This should be integrated with the units being returned from the getUnits graphql call. But
 * there are a few problems:
 *  - bu (bushels) is not contained in that data set
 *  - the "lb" identifier in getUnits does not map to the "lbs" identifier used here in DEFAULT_UNITS
 *  - getUnits (and getEnum) do not provide plural values.  this would need to be handled by an i18n library when we get to that point.
 */
const getUnitCopy = (unit, amount) => {
  switch (unit) {
    case "bu":
    case "BUSHEL":
      return amount === 1 ? "bushel" : "bushels";

    case "cwt":
    case "HUNDREDWEIGHT":
      return amount === 1 ? "hundredweight" : "hundredweights";

    case "kg":
    case "KILOGRAM":
      return amount === 1 ? "kilogram" : "kilograms";

    case "lb":
    case "lbs":
    case "POUND":
      return amount === 1 ? "pound" : "pounds";

    case "ton":
    case "TON":
      return amount === 1 ? "ton" : "tons";

    default:
      throw new Error(`Unrecognized unit: ${unit}`);
  }
};

const getDefaultYieldData = (layer, loadsData) => {
  if (loadsData) {
    return loadsData;
  }

  return {
    avgMoisture: layer?.details?.avg_moisture,
    source: "yield data",
    uploadDate: layer?.created,
    yieldAmount: layer?.details?.avg_yield,
    yieldRate: "PER_ACRE",
    yieldUnit: "BUSHEL",
  };
};

const getAverageYieldData = (crop, layer, loadsData) => {
  const { yieldPerAcre, yieldStoredAmount, yieldStoredUnit, yieldUnit } = crop;

  // Yield was manually input by user
  if (yieldStoredAmount) {
    return {
      source: "declared yield",
      yieldAmount: yieldPerAcre,
      yieldRate: "PER_ACRE",
      yieldUnit: yieldStoredUnit ?? yieldUnit ?? "BUSHEL",
    };
  }

  return getDefaultYieldData(layer, loadsData);
};

const getTotalAmount = (acreage, yieldAmount, yieldTotal) => {
  if (yieldTotal) {
    return numeral(yieldTotal).format("0.0");
  }

  return yieldAmount && acreage ? numeral(+yieldAmount * +acreage).format("0.0") : "";
};

const getUnits = (hasBushelSize, units) => {
  if (hasBushelSize) {
    return units;
  }

  return units?.filter(({ value }) => value !== "BUSHEL");
};

const getYieldUnitDisplay = (unit, units) => {
  const { display } = units?.find(({ display, value }) => display === unit || value === unit) ?? {};
  return display || unit;
};

const YieldInfo = ({ crop, layer }) => {
  const { acreage, commodity, id, inventoryNodeId, yieldStoredAmount, yieldStoredRate, yieldStoredUnit, yieldTotal } =
    crop;
  const { defaultYieldUnit } = commodity;
  const [editFieldCrop, { loading: isUpdating }] = useMutation(EDIT_FIELD_CROP, {
    refetchQueries: ["getFieldCropsWithYield"],
  });
  const { findEnumByName } = useUnits();
  const units = findEnumByName("CropYieldUnit");
  const legacyDefaultYieldUnit = units?.find(({ value }) => value === defaultYieldUnit)?.display ?? "bu";
  const loadsData = Transactions.getLoadsData(crop, legacyDefaultYieldUnit);
  const newLoadData = { commodity_id: commodity.id, source_id: inventoryNodeId };
  const data = getAverageYieldData(crop, layer, loadsData);
  const { avgMoisture, source, yieldAmount } = data;
  const hasBushelSize = !!commodity.bushelSize;
  const [addLoad, setAddLoad] = useState(false);
  const [editYield, setEditYield] = useState(false);
  const hasLoads = useEnterpriseFeature("legacy_storage_and_loads");
  useBackboneCollection(Transactions);
  const totalAmount = getTotalAmount(acreage, yieldAmount, yieldTotal);
  const unitCopy = defaultYieldUnit && totalAmount > 0 ? getUnitCopy(defaultYieldUnit, totalAmount) : "";

  const setYield = () => {
    setAddLoad(false);
    setEditYield(true);
  };

  const resetEdit = () => {
    setAddLoad(false);
    setEditYield(false);
  };

  const updateYield = async (variables) => {
    try {
      await editFieldCrop({ variables });
      resetEdit();
    } catch (error) {
      App.notify("An error occurred");
      throw error;
    }
  };

  const saveYield = async ({ yield: yieldAmount, yieldRate, yieldUnit }) => {
    const variables = { crop: { id, yield: +yieldAmount, yieldRate: yieldRate, yieldUnit: yieldUnit } };
    updateYield(variables);
  };

  const removeYield = async () => {
    const variables = { crop: { id, yield: null, yieldRate: null, yieldUnit: null } };
    updateYield(variables);
  };

  return (
    <div>
      {!editYield && yieldAmount && (
        <div>
          <div className="yield-amount">
            <span className="amount">{numeral(yieldAmount).format("0.0")}</span>
            <span className="unit">
              {getYieldUnitDisplay(defaultYieldUnit, units)}
              /ac
              {unitCopy && ` (${totalAmount} ${unitCopy})`}
            </span>
          </div>

          <div>
            <div>{avgMoisture ? `${numeral(avgMoisture).format("0.0")}% avg moisture` : ""}</div>
            <div className="sub">
              <span>based on {source} </span>
              <span>
                <span>
                  (<a onClick={setYield}>{source !== "declared yield" ? "set manually" : "edit"}</a>
                  {source === "declared yield" && (
                    <>
                      {" \x2F "}
                      <a onClick={removeYield}>remove</a>
                    </>
                  )}
                  )
                </span>
              </span>
            </div>
          </div>
          <br />

          {!!data.uploadDate && <div className="sub">{`uploaded ${moment(data.uploadDate).format("MMM D YYYY")}`}</div>}
        </div>
      )}

      {!editYield && !yieldAmount && (
        <div>
          <div className="subtitle">No yield for this crop</div>
          <div className="sub">
            <a href="#settings/data/yieldData">upload yield file</a>
            {hasLoads && (
              <span>
                , <a onClick={() => setAddLoad(true)}>log load</a>,{" "}
              </span>
            )}
            {` or `}
            <a onClick={setYield}>set yield manually</a>
          </div>
        </div>
      )}

      {addLoad && <InventoryTransaction load={newLoadData} onClose={() => setAddLoad(false)} />}

      {editYield && (
        <EditYieldForm
          defaultYield={numeral(yieldStoredAmount || yieldAmount).format("0.0")}
          defaultYieldRate={yieldStoredRate || "PER_ACRE"}
          defaultYieldUnit={yieldStoredUnit || defaultYieldUnit}
          isUpdating={isUpdating}
          onCancel={() => setEditYield(false)}
          onSubmit={saveYield}
          units={getUnits(hasBushelSize, units)}
        />
      )}
    </div>
  );
};

YieldInfo.propTypes = {
  crop: PropTypes.object.isRequired,
  layer: PropTypes.object,
};

export default YieldInfo;
