import { isNative } from "mobile/mobileManager";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";

import { BorderRadius, Spacing, Typography } from "components/fl-ui/constants";

const CursorTrackingContainer = styled.div`
  align-items: center;
  background: black;
  border: 1px solid black;
  border-radius: ${BorderRadius.small};
  color: white;
  display: flex;
  font-size: ${Typography.sizes.sm};
  font-weight: ${Typography.weights.medium};
  justify-content: center;
  padding: ${Spacing.xsmall};
  position: absolute;
`;

const CursorTracker = ({ children }) => {
  const ref = useRef();

  useEffect(() => {
    const handleCursorTrack = ({ pageX, pageY }) => {
      if (ref.current) {
        ref.current.style.left = `${pageX + 48}px`;
        ref.current.style.top = `${pageY + 48}px`;
      }
    };

    if (!isNative()) {
      document.addEventListener("mousemove", handleCursorTrack);
    }

    return () => {
      if (!isNative()) {
        document.removeEventListener("mousemove", handleCursorTrack);
      }
    };
  }, []);

  return children ? <CursorTrackingContainer ref={ref}>{children}</CursorTrackingContainer> : null;
};

export default CursorTracker;
