import paymentScheduleValidator from "agreements/validators/AgreementsForm/paymentScheduleValidator";
import _ from "lodash";
import moment from "moment";

import utils from "lib/validation/utils";

const isValid = (fields, errors = {}) => _.isEmpty(validate(fields, { errors }));

const isPositiveNumber = (value) => value > 0 && utils.isDecimal(value, { decimal_digits: "1,2" });

const validate = (formFields, options = {}) => {
  const errors = options.errors || {};
  const { fields, lenderName, loanType, interestRate, originalBalance } = formFields;
  const { paymentSchedules, startDate, termLength, termUnit } = formFields;

  // startDate
  if (!/^\d{4}-\d{2}-\d{2}$/.test(startDate) || !moment(startDate).isValid()) {
    errors.startDate = { value: startDate };
  }

  // fields
  if (_.size(fields) === 0 || !_.every(fields, "id")) {
    errors.fields = { value: fields };
  }

  // termLength
  if (!utils.isInt(termLength, { gt: 0 })) {
    errors.termLength = { value: termLength };
  }

  // termUnit
  if (!termUnit) {
    errors.termUnit = { value: termUnit };
  }

  // lenderName
  if (!utils.isLength(lenderName || "", { min: 1 })) {
    errors.lenderName = { value: lenderName };
  }

  // loanType
  if (!loanType) {
    errors.loanType = { value: loanType };
  }

  // originalBalance
  if (!isPositiveNumber(originalBalance)) {
    errors.originalBalance = { value: originalBalance };
  }

  // interestRate
  if (!utils.isDecimal(interestRate, { decimal_digits: "0,3" }) || !(interestRate > 0 && interestRate <= 100)) {
    errors.interestRate = { value: interestRate };
  }

  // paymentSchedules
  const allSchedulesValid = _.every(paymentSchedules, (schedule) =>
    paymentScheduleValidator.isValid(schedule, { context: formFields })
  );
  if (!allSchedulesValid) {
    errors.paymentSchedules = { value: paymentSchedules };
  }

  return errors;
};

export default {
  isValid,
  validate,
};
