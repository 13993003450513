import PropTypes from "prop-types";
import React from "react";
import ReactSelect, { components } from "react-select";

import { GreyColors, UIColors } from "components/fl-ui/colors";

export { components };

export const chooserStyles = {
  control: (base, { isDisabled }) => {
    const styles = { ...base };
    if (isDisabled) {
      styles.backgroundColor = GreyColors.smoke96;
    }

    return styles;
  },
  menuList: (base) => ({
    ...base,
    boxShadow: "0px 10px 10px 0px rgba(0, 0, 0, 0.25)",
  }),
  multiValue: (base) => ({
    ...base,
    border: `1px solid ${UIColors.blue}`,
  }),
  multiValueLabel: (base) => ({
    ...base,
    backgroundColor: UIColors.blue,
    borderRadius: 0,
    color: "white",
  }),
  multiValueRemove: (base) => ({
    ...base,
    backgroundColor: UIColors.blue,
    border: `1px solid ${UIColors.blue}`,
    borderRadius: 0,
    color: "white",
    cursor: "pointer",
  }),
};

const DropdownIndicator = (props) =>
  props.selectProps.hideIndicator ? null : <components.DropdownIndicator {...props} />;

const Chooser = ({ components, styles, ...rest }) => (
  <ReactSelect components={{ DropdownIndicator, ...components }} {...rest} styles={{ ...chooserStyles, ...styles }} />
);

Chooser.propTypes = {
  components: PropTypes.object,
  hideIndicator: PropTypes.bool,
  styles: PropTypes.objectOf(PropTypes.func),
};

Chooser.defaultProps = {
  hideIndicator: false,
};

export default Chooser;
