import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import React from "react";

const AnomalyBadge = createReactClass({
  displayName: "AnomalyBadge",

  propTypes: {
    tooltip: PropTypes.string,
    style: PropTypes.object,
  },

  getDefaultProps() {
    return { tooltip: "Crop Health Alert" };
  },

  render() {
    return (
      <div style={this.props.style} className="anomaly-badge">
        <div style={{ position: "relative" }} className="fl-tooltip" data-tooltip-text={this.props.tooltip}>
          <svg viewBox="0 0 30 26" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" strokeWidth="1" fill="none" fill-rule="evenodd">
              <g>
                <path
                  d="M29.2329231,21.2703636 L17.679,1.68007273 C17.0494615,0.612418182 16.0735385,0 15.0025385,0 C13.9315385,0 12.9558462,0.612181818 12.3258462,1.67936364 L0.767076923,21.2703636 C0.120923077,22.3654364 0.0537692308,23.5439455 0.582461538,24.5042909 C1.10584615,25.4544727 2.10784615,26 3.33184615,26 L26.6683846,26 C27.8928462,26 28.8943846,25.4542364 29.4182308,24.5042909 C29.9469231,23.5439455 29.8793077,22.3654364 29.2329231,21.2703636 L29.2329231,21.2703636 Z"
                  fill="#454A54"
                />
                <path
                  d="M26.667,24.1090909 L3.33184615,24.1090909 C2.18723077,24.1090909 1.743,23.2723636 2.34715385,22.2484364 L13.9056923,2.65790909 C14.2070769,2.14665455 14.6049231,1.89090909 15.0025385,1.89090909 C15.4001538,1.89090909 15.7973077,2.14665455 16.0986923,2.65790909 L27.6519231,22.2484364 C28.2560769,23.2723636 27.8125385,24.1090909 26.667,24.1090909 L26.667,24.1090909 Z"
                  fill="#FFE15C"
                />
              </g>
              <g transform="translate(13.615385, 7.563636)" fill="#454A54">
                <path
                  d="M1.38461538,9.00450909 C1.89438462,9.00450909 2.30769231,8.58118182 2.30769231,8.05905455 L2.30769231,0.967436364 C2.30769231,0.445309091 1.89438462,0.0219818182 1.38461538,0.0219818182 C0.874846154,0.0219818182 0.461538462,0.445309091 0.461538462,0.967436364 L0.461538462,8.05905455 C0.461538462,8.58118182 0.874846154,9.00450909 1.38461538,9.00450909 L1.38461538,9.00450909 Z"
                  id="Shape"
                />
                <ellipse id="Oval" cx="1.38461538" cy="12.7636364" rx="1.38461538" ry="1.41818182" />
              </g>
            </g>
          </svg>
        </div>
      </div>
    );
  },
});

export default AnomalyBadge;
