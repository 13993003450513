import _ from "lodash";

export const activityTypeFilter = ({ type }, pattern) => pattern.test(type?.name ?? "");
export const fieldNameFilter = ({ field: { name } }, pattern) => pattern.test(name);
export const cropNameFilter = ({ crop }, pattern) => pattern.test(crop.commodity.name);
export const productNameFilter = ({ inputs }, pattern) => inputs.some(({ product: { name } }) => pattern.test(name));

export const createPattern = (search) => new RegExp(search, "i");

/**
 * Filters an array of activities by the following attributes: activity type, field name,
 * commodity name, and product name.
 *
 * @param {Activity[]} activities
 * @param {Object} filters
 * @param {String} filters.search
 * @return {Activity[]}
 */
const searchFilter = (activities, { search }) => {
  search = _.trim(search);
  if (search.length > 0) {
    const pattern = createPattern(search);
    activities = activities.filter(
      (activity) =>
        activityTypeFilter(activity, pattern) ||
        fieldNameFilter(activity, pattern) ||
        cropNameFilter(activity, pattern) ||
        productNameFilter(activity, pattern)
    );
  }

  return activities;
};

export default searchFilter;
