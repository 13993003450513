import { getNotificationTags } from "mobile/getNotificationTags";

export const oneSignalConfigure = () => {
  window.plugins.OneSignal.initialize(process.env.ONESIGNAL_APP_ID);
};

export const oneSignalInfo = () => {
  const id = window.plugins.OneSignal.User.pushSubscription.id;
  const token = window.plugins.OneSignal.User.pushSubscription.token;
  return {
    id: id,
    token: token,
  };
};

export const oneSignalLogIn = async (currentUser) => {
  window.plugins.OneSignal.login(currentUser.id.toString());
  window.plugins.OneSignal.User.addEmail(currentUser.email);

  const tags = await getNotificationTags(currentUser);
  window.plugins.OneSignal.User.addTags(tags);
};

export const oneSignalLogOut = () => {
  window.plugins.OneSignal.User.pushSubscription.optOut();
  window.plugins.OneSignal.logout();
};

export const oneSignalOptIn = () => {
  window.plugins.OneSignal.User.pushSubscription.optIn();
};
