/* eslint-disable react/display-name */
import React from "react";

import usePermissions from "hooks/usePermissions";
import App from "layout/app";

import RestrictedAccessBlankslate from "components/fl-ui/RestrictedAccessBlankslate";

const withMarketingCheck = (Component) => (props) => {
  const { canRead } = usePermissions();
  if (canRead("marketing")) {
    return <Component {...props} />;
  }

  App.setTitle("Restricted");
  return <RestrictedAccessBlankslate />;
};

export { withMarketingCheck };
