import { useQuery } from "@apollo/client";
import _ from "lodash";
import React, { useCallback } from "react";

import client from "collection/graphql/client/marketingClient";
import { getAllUnits } from "collection/graphql/queries";

/**
 * @typedef {String} EnumName see enums returned from `getEnums` query in graphql schema
 */
export default () => {
  const { data, loading } = useQuery(getAllUnits, { client });

  /**
   * Returns the given enum as an object mapping the identifier (data) to its string
   * value (value).
   *
   * @param {string} enumName
   * @type {function}
   * @return {{ [key]: value }[]}
   */
  const getEnumKeyValues = useCallback(
    _.memoize((enumName) =>
      _.reduce(findEnumByName(enumName), (obj, { display, value }) => Object.assign(obj, { [value]: display }), {})
    ),
    [data?.allEnums]
  );

  /**
   * Returns the key/value pairs for a given enum as objects with a label and a value.
   * This is typically used in dropdowns.
   *
   * @param {string} enumName
   * @return {{ label: string, value: string }[]}
   */
  const getEnumAsOptions = (enumName) =>
    _.map(findEnumByName(enumName), ({ display, value }) => ({
      label: display,
      value,
    }));

  /**
   * @param {EnumName} name
   */
  const findEnumByName = useCallback((name) => _.find(data?.allEnums, { name })?.values || null, [data]);

  /**
   * @param {EnumName} enumName
   * @param {String} data value of the enum's `data` property
   */
  const findEnumValue = useCallback((enumName, data) => _.find(findEnumByName(enumName), { data }), [data]);

  return {
    data: data || null,
    getEnumKeyValues,
    getEnumAsOptions,
    findEnumByName,
    findEnumValue,
    loading,
  };
};
