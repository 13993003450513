import NoteContainer from "notes/components/NoteContainer";
import Index from "notes/index";

import { marketingClient } from "collection/graphql/client";
import { GET_CURRENT_ENTERPRISE } from "collection/graphql/enterprise/queries";
import App from "layout/app";
import SubRoute from "lib/SubRoute";

const ScoutingRouter = SubRoute.extend({
  routes: {
    "": "index",
    add: "add",
    ":id": "detail",
  },

  async ensurePageReady() {
    await marketingClient.query({ query: GET_CURRENT_ENTERPRISE });
  },

  async index() {
    await this.ensurePageReady();
    App.display(Index, { screenName: "Scouting List" });
    return App.setTitle("Scouting");
  },

  async add(params) {
    await this.ensurePageReady();
    if (params == null) {
      params = {};
    }

    App.display(NoteContainer, {
      fieldId: +params.field_id,
      screenName: "Scouting Detail",
    });
    return App.setTitle("Add Scouting");
  },

  async detail(id) {
    await this.ensurePageReady();
    App.display(NoteContainer, {
      noteId: +id,
      screenName: "Scouting Detail",
    });
    return App.setTitle("Scouting");
  },
});

export default () => new ScoutingRouter("scouting", { createTrailingSlashRoutes: true });
