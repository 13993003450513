import chooserStyles from "activity/components/choosers/FieldCropChooser/chooserStyles";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import ReactSelect, { components } from "react-select";
import styled from "styled-components";

import { Button } from "components/fl-ui";

const SelectInputHousing = styled.div`
  ${(props) =>
    props.enableMap &&
    `
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    > :first-child {
      flex-grow: 2;
    }
  `}
`;

const IndicatorsContainer = (props) => {
  const { getStyles, selectProps } = props;
  const { menuControl } = selectProps;
  const mapButtonContainer = useRef();

  const handleTouchEvent = (event) => {
    if (mapButtonContainer.current.contains(event.target)) {
      menuControl.showMap();
    } else {
      menuControl.showMenu();
    }
  };

  const containerProps = {
    onClick: handleTouchEvent,
    onTouchStart: handleTouchEvent,
    style: {
      ...getStyles("indicatorsContainer"),
      cursor: "pointer",
    },
  };

  const chevronStyle = {
    cursor: "pointer",
    marginRight: "14px",
  };

  return (
    <div {...containerProps}>
      <components.DownChevron style={chevronStyle} />
      <components.IndicatorSeparator {...props} />
      <span ref={mapButtonContainer}>
        <Button color="white" icon="searchMap" style={{ marginLeft: "1px" }} />
      </span>
    </div>
  );
};

const ValueContainer = (props) => {
  const { children, getStyles } = props;
  const { menuControl, value } = props.selectProps;
  const style = getStyles("valueContainer", props);
  style.padding = "11px 8px";

  const handleClick = (event) => {
    if (_.size(value) === 0) {
      menuControl.showMenu();
    } else if (event.currentTarget === event.target) {
      menuControl.showMenu();
    }
  };

  return (
    <div onClick={handleClick} onTouchStart={handleClick} style={style}>
      {children}
    </div>
  );
};

const FieldCropSelection = (props) => {
  const { enableMap, menuControl, onChange, placeholder, value } = props;

  const renderLabel = ({ commodity, cropYear, field }) => `${field.name} \u00b7 ${cropYear} ${commodity.name}`;
  const renderValue = _.property("id");

  return (
    <SelectInputHousing enableMap={enableMap} ref={menuControl.controlContainerRef}>
      <ReactSelect
        components={{ IndicatorsContainer, ValueContainer }}
        getOptionLabel={renderLabel}
        getOptionValue={renderValue}
        isClearable={false}
        isMulti
        isSearchable={false}
        menuControl={menuControl}
        menuIsOpen={false}
        onChange={(newValue) => onChange(newValue || [])}
        openMenuOnClick={false}
        placeholder={placeholder}
        styles={chooserStyles}
        value={_.sortBy(value, ["field.name", "commodity.name"])}
      />
    </SelectInputHousing>
  );
};

FieldCropSelection.propTypes = {
  enableMap: PropTypes.bool,
  menuControl: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.array.isRequired,
};

FieldCropSelection.defaultProps = {
  enableMap: true,
  placeholder: "Select field crops",
};

export default FieldCropSelection;
