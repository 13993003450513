import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import styled from "styled-components";

import { Icon, IconContainer as IconContainerFLUI } from "components/fl-ui/Icons";
import { GreyColors } from "components/fl-ui/colors";
import { Spacing } from "components/fl-ui/constants";

const BlankSlateContainer = styled(({ children, className }) => (
  <Row center="xs" middle="xs" className={className}>
    <Col xs={12} md={8}>
      {children}
    </Col>
  </Row>
))`
  padding: ${Spacing.xxxlarge} 0 ${Spacing.xxxlarge};
  text-align: center;

  h2 {
    line-height: 3em;
  }
`;

const IconContainer = styled(IconContainerFLUI)`
  color: ${GreyColors.smoke91};
  text-align: center;
`;

const BlankSlate = ({ children, icon, title }) => (
  <BlankSlateContainer>
    {icon && (
      <IconContainer size="xxxlarge">
        <Icon icon={icon} />
      </IconContainer>
    )}
    {title && <h2>{title}</h2>}
    {children}
  </BlankSlateContainer>
);

BlankSlate.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  icon: PropTypes.string,
  title: PropTypes.string,
};

export default BlankSlate;
