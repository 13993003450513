import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import React from "react";

import { MAP_FARM_BOUNDARIES_PRESS } from "lib/metrics/events";

export default createReactClass({
  displayName: "Bounds",

  propTypes: {
    bounds: PropTypes.any,
  },

  contextTypes: {
    map: PropTypes.object,
  },

  setBounds() {
    MAP_FARM_BOUNDARIES_PRESS.track();
    return this.context.map.fitBounds(this.props.bounds);
  },

  render() {
    return (
      <span className="leaflet-control map-notification-wrapper">
        <a className="btn farm-bounds-icon" onClick={this.setBounds}>
          <img src="/images/map/icon-farm-bounds.png" alt="Zoom to farm bounds" />
        </a>
      </span>
    );
  },
});
