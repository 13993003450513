import * as yup from "yup";

import { IngredientSchema } from "components/product/schemas";

const ProductRecipeFormSchema = yup.object().shape({
  enterpriseId: yup.number().label("Enterprise id").optional().min(0),
  id: yup.number().optional().moreThan(0).integer(),
  ingredients: yup.array().label("Ingredients").required().of(IngredientSchema),
  name: yup.string().label("Name").trim().required(),
});

export default ProductRecipeFormSchema;
