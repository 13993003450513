import { isNative, mobileNotificationLogIn, requestNotificationPermission } from "mobile/mobileManager";
import { useEffect } from "react";

import { useAuth } from "collection/graphql/auth";
import useAsyncFn from "hooks/useAsyncFn";

const useMobileListeners = () => {
  const { currentUser, isLoggedIn } = useAuth();

  const [{ loading: permissionRequestIsLoading }, makePermissionRequest] = useAsyncFn(async () => {
    await mobileNotificationLogIn(currentUser);
    try {
      await requestNotificationPermission();
    } catch (error) {
      console.error(error);
    }
  });

  useEffect(() => {
    if (isLoggedIn && isNative()) {
      if (!permissionRequestIsLoading) {
        makePermissionRequest();
      }

      return () => {};
    }
  }, [isLoggedIn]);
};

export default useMobileListeners;
