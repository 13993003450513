import _ from "lodash";
import * as yup from "yup";

import cache from "collection/graphql/cache";
import { getProductFromCache } from "collection/graphql/products/hooks/useProductById";
import { getAllUnits } from "collection/graphql/queries";
import "lib/validation/yupPolyfill";

/**
 * A schema matching `ActivityInputInput` and `UpdateActivityInputInput` types in
 * the grain marketing graphql schema.
 */
const ActivityInputInputSchema = yup.object().shape({
  area: yup.number().optional().nullable().min(0).precision({ max: 2 }),

  areaUnit: yup.string().optional().nullable().inEnum("LandAreaUnit"),

  amount: yup
    .number()
    .label("Amount")
    .required()
    .min(0, "${path} can not be below zero")
    .max(9999999, "${path} is too large"),

  amountRate: yup.string().label("Rate").required().inEnum("ActivityInputAmountRate"),

  amountUnit: yup
    .string()
    .label("Unit")
    .when("product", {
      is: (product) => product,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional().nullable(),
    })

    .test({
      name: "validActivityInputUnit",
      test: (value, { createError, parent: { product: productId } }) => {
        if (!_.isNil(value)) {
          const unitType = getProductFromCache(productId)?.unitType;
          const { seedCountUnits, volumeUnits, weightUnits } = cache.readQuery({
            query: getAllUnits,
          });

          const units = {
            COUNT: seedCountUnits,
            SEED_COUNT: seedCountUnits,
            VOLUME: volumeUnits,
            WEIGHT: weightUnits,
          }[unitType];

          const validUnits = _.map(units, "data");
          if (validUnits.length === 0) {
            return createError("${path} is invalid");
          }

          if (!validUnits.includes(value)) {
            const validUnitCopy = _.map(units, "display");
            return createError({
              message: `\${path} must be one of the following values: ${validUnitCopy.sort().join(", ")}`,
            });
          }
        }

        return true;
      },
    }),

  id: yup.number().optional().moreThan(0).integer(),

  product: yup.number().label("Input product").typeError("${path} is required").required().moreThan(0).integer(),
});

export default ActivityInputInputSchema;
