export const ADD_CONTRACT = "AddContract";
export const ADD_MARKETED_CROP = "AddMarketedCrop";
export const ADD_SCALE_TICKET = "AddScaleTicket";
export const CASH_CONTRACT = "CashContract";
export const CLOSED = "CLOSED";
export const EDIT_CONTRACT = "EditContract";
export const EDIT_MARKETED_CROP = "EditMarketedCrop";
export const EDIT_SCALE_TICKET = "EditScaleTicket";
export const FUTURES_CONTRACT = "FuturesContract";
export const PENDING = "PENDING";
export const OPEN = "OPEN";
export const OPTIONS_CONTRACT = "OptionsContract";
export const UNFILLED = "UNFILLED";
export const UNPRICED = "UNPRICED";

export default {
  ADD_CONTRACT,
  ADD_MARKETED_CROP,
  ADD_SCALE_TICKET,
  CASH_CONTRACT,
  CLOSED,
  EDIT_CONTRACT,
  EDIT_MARKETED_CROP,
  EDIT_SCALE_TICKET,
  FUTURES_CONTRACT,
  PENDING,
  OPEN,
  OPTIONS_CONTRACT,
  UNFILLED,
  UNPRICED,
};
