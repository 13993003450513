import d3 from "d3";
import leaflet from "leaflet";
import _ from "underscore";

import GeoJsonLayer from "lib/map/layers/GeoJsonLayer";

const mouseover = (geoJSONLayer, soilLayer) => {
  if (!soilLayer.selectedId) {
    const { edge, ie, opera } = leaflet.Browser;
    geoJSONLayer.setStyle({
      color: "#fff",
      opacity: 1,
      transition: "all .2s ease",
    });

    if (!ie && !opera && !edge) {
      geoJSONLayer.bringToFront();
    }
  }
};

const mouseout = (geoJSONLayer, soilLayer) => {
  if (!soilLayer.selectedId) {
    soilLayer.layers.resetStyle(geoJSONLayer);
  }
};

const select = (geoJSONLayer, soilLayer) => {
  const selectedLayerId = soilLayer.getId(geoJSONLayer.feature);

  if (soilLayer.selectedId === selectedLayerId) {
    soilLayer.layers.resetStyle();
    soilLayer.setSelected(null);
  } else {
    soilLayer.layers.setStyle({ fillOpacity: 0.5, opacity: 0.5, transition: "all .2s ease" });
    soilLayer.layers.resetStyle(geoJSONLayer);
    soilLayer.setSelected(selectedLayerId);
  }
};

// Colors for soil types
const colors = {
  HC: "#e251b7",
  C: "#f85757",
  SiC: "#a591c0",
  SiCL: "#91a8c0",
  CL: "#da9660",
  SC: "#f87d57",
  SiL: "#91c096",
  L: "#afda5c",
  SCL: "#91a8c0",
  SL: "#f8d013",
  Si: "#48c8ed",
  LS: "#ffe270",
  S: "#f8f013",
  default: "#999",
};

const getAcreage = (feature) => {
  const EQUATORIAL_RADIUS = 6378137;
  const SQUARE_METERS_PER_ACRE = 4046.86;
  return (d3.geo.area(feature.geometry) * Math.pow(EQUATORIAL_RADIUS, 2)) / SQUARE_METERS_PER_ACRE;
};

const groupSoil = (type) => {
  const left = type?.split(",")[0].match(/(silt|loam|sand)y?|clay/gi) || [];
  return _(left)
    .unique((type) => type.match(/silt|sand|loam|clay/i)[0])
    .value();
};

const getKey = (type) =>
  groupSoil(type)
    .map(function (item) {
      switch (item.toLowerCase()) {
        case "clay":
          return "C";
        case "silt":
        case "silty":
          return "Si";
        case "sand":
        case "sandy":
          return "S";
        case "loam":
        case "loamy":
          return "L";
      }
    })
    .join("");

class Soil extends GeoJsonLayer {
  _totalAcreage = 0;
  style = { fillOpacity: 1, weight: 1 };
  selectedId = null;

  addFeature(feature) {
    const acreage = getAcreage(feature);
    const newFeature = {
      ...feature,
      properties: {
        ...feature.properties,
        acres: _.numberFormat(acreage, 1),
        acreage: +acreage.toFixed(1),
        key: getKey(feature.properties.name),
      },
    };
    this._totalAcreage += newFeature.properties.acreage;
    return GeoJsonLayer.prototype.addFeature.call(this, newFeature);
  }

  getColor(properties) {
    const slope = properties?.slope?.match(/(\d+)\s*to\s*(\d+)/i);
    const color = colors[getKey(properties.name) || "default"];

    if (slope) {
      const average = (Number(slope[1]) + Number(slope[2])) / 2;
      const delta = (average - (average % 2) - 5) / 16;

      return delta > 0
        ? d3.rgb(color).darker(Math.abs(delta)).toString()
        : d3.rgb(color).brighter(Math.abs(delta)).toString();
    } else {
      return d3
        .rgb(color)
        .brighter(5 / 16)
        .toString();
    }
  }

  getCoverage(feature) {
    return feature.properties.acreage / this._totalAcreage;
  }

  group() {
    return _(this.features)
      .values()
      .groupBy((d) => groupSoil(d.properties.name).join(" ") || "other")
      .map((d, i) => {
        return {
          name: _.titleize(i),
          acreage: +_.sum(d, (s) => s.properties.acreage).toFixed(1),
          coverage: _.sum(d, (s) => this.getCoverage(s)) * 100,
          data: d,
          color: colors[getKey(i) || "default"],
          key: getKey(i),
        };
      })
      .sortBy((d) => d.key || "z")
      .value();
  }

  getProperties() {
    return _(this.features)
      .map((feature) => ({
        ...feature.properties,
        color: this.getColor(feature.properties),
        coverage: this.getCoverage(feature),
        description: feature.properties.slope,
        id: feature.properties.id,
        name: feature.properties.name,
      }))
      .sortBy(({ acreage }) => -acreage)
      .value();
  }

  highlightFeature(id) {
    if (this.selectedId === id) {
      this.layers.resetStyle();
      this.setSelected(null);
    } else {
      const selectedLayer = this.layers.getLayers().find(({ feature }) => this.getId(feature) === id);
      this.layers.setStyle({ fillOpacity: 0.5, opacity: 0.5, transition: "all .2s ease" });
      this.layers.resetStyle(selectedLayer);
      this.setSelected(id);
    }
  }

  onEachFeature = (feature, layer) => {
    layer.on({
      click: () => select(layer, this),
      mouseout: () => mouseout(layer, this),
      mouseover: () => mouseover(layer, this),
    });
  };

  setSelected(id) {
    this.selectedId = id;
    this.fire("layer:changed");
  }
}

export default Soil;
