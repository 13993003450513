/* eslint-disable */
import createReactClass from "create-react-class";
import $ from "jquery";
import PropTypes from "prop-types";
import React from "react";

const { string, node, func, object } = PropTypes;

const ModalSection = createReactClass({
  displayName: "ModalSection",
  propTypes: {
    className: string,
  },
  getDefaultProps() {
    return { className: "" };
  },

  render() {
    return <div className={`fl-modal-section ${this.props.className}`}>{this.props.children}</div>;
  },
});

const ModalScroll = createReactClass({
  propTypes: {
    className: string,
  },
  getDefaultProps() {
    return { className: "" };
  },

  render() {
    return <div className={`fl-modal-scroll ${this.props.className}`}>{this.props.children}</div>;
  },
});

// Convenience components for modal contents. Instead of remembering that the
// modal body needs these X divs, just use Modal.body.
const ModalBody = createReactClass({
  displayName: "ModalBody",
  propTypes: {
    className: string,
  },
  getDefaultProps() {
    return { className: "" };
  },

  render() {
    return (
      <div className="fl-modal-body" style={this.props.style}>
        <div className="container-fluid">
          <div className={`row-fluid ${this.props.className}`}>{this.props.children}</div>
        </div>
      </div>
    );
  },
});

const ModalTitle = createReactClass({
  displayName: "ModalTitle",
  propTypes: {
    className: string,
    style: object,
  },
  getDefaultProps() {
    return { className: "" };
  },

  render() {
    return (
      <div className={`fl-modal-header ${this.props.className}`} style={this.props.style}>
        {this.props.children}
      </div>
    );
  },
});

const ModalRibbon = createReactClass({
  displayName: "ModalRibbon",
  render() {
    return <div className="modal-ribbon">{this.props.children}</div>;
  },
});

const ModalFooter = createReactClass({
  displayName: "ModalFooter",
  propTypes: {
    style: object,
  },
  render() {
    return (
      <div className="fl-modal-footer" style={this.props.style}>
        {this.props.children}
      </div>
    );
  },
});

const ModalFooterButton = createReactClass({
  displayName: "ModalFooterButton",
  render() {
    return (
      <div className="fl-modal-footer-button">
        <a {...this.props} />
      </div>
    );
  },
});

const Modal = createReactClass({
  displayName: "Modal",
  statics: {
    body: ModalBody,
    title: ModalTitle,
    footer: ModalFooter,
    footerButton: ModalFooterButton,
    ribbon: ModalRibbon,
    section: ModalSection,
    scroll: ModalScroll,
  },
  propTypes: {
    children: node,
    onClose: func,
    type: string,
    windowClass: string,
  },

  getDefaultProps() {
    return {
      type: "",
      windowClass: "",
    };
  },

  // Set the content of the modal. This is only used
  // when creating the modal via a Backbone View.
  // React-based parents will set content via `@props.children`.
  updateNode(node) {
    const contentNode = this.refs.content;
    for (let child of Array.from(contentNode.children)) {
      contentNode.removeChild(child);
    }
    if (node) {
      return contentNode.appendChild(node);
    }
  },

  componentDidMount() {
    if (this.props.content != null) {
      this.updateNode(this.props.content);
    }

    window.addEventListener("hashchange", this.componentWillUnmount.bind(this));
    return $("body").addClass("fl-modal-present");
  },

  componentWillUnmount() {
    window.removeEventListener("hashchange", this.componentWillUnmount.bind(this));
    return $("body").removeClass("fl-modal-present");
  },

  _handleOverlayClick(e) {
    if (e.target === this.refs.container) {
      return typeof this.props.onClose === "function" ? this.props.onClose(e) : undefined;
    }
  },

  render() {
    return (
      <div
        id={this.props.id}
        ref="container"
        className={`js-modal-wrapper fl-modal-wrapper ${this.props.className || ""}`}
        onClick={this._handleOverlayClick}
      >
        <div className={`fl-modal-window ${this.props.windowClass || ""}`}>
          <div ref="content" className="js-modal-content">
            {this.props.children}
          </div>
        </div>
      </div>
    );
  },
});

export default Modal;
