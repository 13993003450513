import { css } from "aphrodite";
import { upperFirst } from "lodash";
import styles from "profit_and_loss/styles";
import PropTypes from "prop-types";
import React from "react";

import Icon from "components/fl-ui/Icons/Icon";
import ToolTip from "components/fl-ui/Tooltip";

const TextHelpTooltip = ({ message, text }) => (
  <span style={{ display: "flex" }}>
    {upperFirst(text)}
    <ToolTip message={message}>
      <Icon className={css(styles.helpIcon)} icon="help" />
    </ToolTip>
  </span>
);

TextHelpTooltip.propTypes = {
  message: PropTypes.string,
  text: PropTypes.string,
};

export default TextHelpTooltip;
