import themeGet from "@styled-system/theme-get";
import React from "react";
import styled from "styled-components";

import { Mixins, Spacing } from "components/fl-ui/constants";

const DesktopNavigationMenuContainer = styled.div`
  background-color: ${themeGet("colors.smoke98")};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  padding: 0 ${Spacing.regular};
  position: fixed;
  width: ${Mixins.toRem(272)};
`;

export default DesktopNavigationMenuContainer;
