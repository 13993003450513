/**
 * Filters an array of activities by field id.
 *
 * @param {Activity[]} activities
 * @param {Object} filters
 * @param {Number[]} filters.fields
 * @param {Function} markAsApplied
 * @return {Activity[]}
 */
const fieldFilter = (activities, { fields }, markAsApplied) => {
  if (fields.length > 0) {
    activities = activities.filter(({ field }) => fields.includes(field.id));
    markAsApplied();
  }

  return activities;
};

export default fieldFilter;
