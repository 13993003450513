/**
 * Performs a DELETE operation for a given recipe.
 *
 * @param {ProductRecipe|Number} recipeId
 * @return {Promise<Response>}
 */
function deleteRecipe(recipeId) {
  return fetch(`/v2.0/api/product_recipes/${recipeId}`, {
    headers: { "Content-Type": "application/json" },
    method: "DELETE",
  });
}

export default deleteRecipe;
