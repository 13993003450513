import EventChannel from "./EventChannel";
import { useRef } from "react";

const useEventChannel = (channelName) => {
  const channel = useRef(undefined);
  if (!channel.current) {
    channel.current = EventChannel.getChannel(channelName);
  }

  return channel.current;
};

export default useEventChannel;
