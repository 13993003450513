import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

const CREATE_ACTIVITY_TYPE = gql`
  mutation createActivityType($activityType: CreateActivityTypeInput!) {
    createActivityType(activityType: $activityType) {
      ok
      activityType {
        id
      }
    }
  }
`;

export default graphql(CREATE_ACTIVITY_TYPE, {
  props: ({ mutate }) => ({
    createActivityType: (activityType) => {
      return mutate({
        variables: { activityType },
      });
    },
  }),
  options: { refetchQueries: ["getActivityTypes"] },
});
