import * as Sentry from "@sentry/react";
import { retry, retryable, series } from "async";
import _ from "lodash";

import { useAuth } from "collection/graphql/auth";
import { logout } from "collection/graphql/auth/lib";
import { getAllPeople } from "collection/graphql/people/queries";
import { useSubscriptionPlatform, useUpdateSubscription } from "collection/graphql/subscription";
import useRestSuspenseQuery from "hooks/useRestSuspenseQuery";

const deactivateUser = async (userId) => {
  const response = await fetch(`/v2.0/users/${userId}/delete`, {
    cache: "no-store",
    headers: { "Content-Type": "application/json" },
    method: "DELETE",
  });

  if (!response.ok) {
    throw new Error(`Unable to delete user ${userId}`);
  }

  return response;
};

const useAccountDeactivation = () => {
  const { currentUser } = useAuth();
  const isAdmin = currentUser.role === "admin";

  const { cancelSubscription } = useUpdateSubscription();
  const { isWebPaymentProvider } = useSubscriptionPlatform() ?? {};
  const shouldCancelSubscription = isAdmin && isWebPaymentProvider;

  const { people } = useRestSuspenseQuery(getAllPeople).data;
  const otherUserIds = _.reject(people, { id: currentUser.id }).map(({ id }) => id);

  const handleError = (error) => {
    Sentry.withScope((scope) => {
      scope.setExtra("logger", "AccountDeactivationLogger");
      Sentry.captureException(error);
    });

    throw error;
  };

  const deleteSelf = async () => deactivateUser(currentUser.id);

  const deleteOthers = async (retries) => {
    const deletionTransactions = otherUserIds.map((id) => retry(retries, async () => deactivateUser(id)));
    const results = await Promise.allSettled(deletionTransactions);
    const errors = _.map(_.filter(results, { status: "rejected" }), "reason");
    if (errors.length > 0) {
      throw new AggregateError(errors, "Unable to delete all users");
    }
  };

  return async ({ retries = 5 } = {}) => {
    const tasks = [retryable(retries, deleteSelf)];
    if (shouldCancelSubscription) {
      tasks.unshift(retryable(retries, cancelSubscription));
    }
    if (isAdmin) {
      tasks.unshift(async () => deleteOthers(retries));
    }

    await series(tasks).catch(handleError).finally(logout);
  };
};

export default useAccountDeactivation;
