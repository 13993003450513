import { array, boolean, lazy, mixed, object, string } from "yup";

import { ESSENTIALS, LITE, PREMIUM, RAINFALL, STANDARD } from "model/Subscription/constants";

const validProductIds = [ESSENTIALS, LITE, PREMIUM, RAINFALL];

export const productIdSchema = array()
  .compact((id) => !validProductIds.includes(id))
  .length(1)
  .of(string().oneOf(validProductIds))
  .required();

/**
 * @typedef {Object} OrderDescriptor
 * @property {boolean} [cancelAtPeriodEnd] required for cancellation orders, set to `true`
 * @property {String[]} productIds an array of plan product ids
 * @property {"month"|"year"} [billingInterval] does not apply to mobile subscriptions
 * @property {Object} [coupon] does not apply to mobile subscriptions
 * @property {String} [coupon.coupon_id] does not apply to mobile subscriptions
 * @property {Object} [stripeToken] does not apply to mobile subscriptions
 * @property {String} [stripeToken.id] does not apply to mobile subscriptions
 */
const purchasableWebDescriptorSchema = object({
  billingInterval: string().required().oneOf(["month", "year"]),

  coupon: lazy((coupon) => {
    return !coupon
      ? mixed().strip()
      : object().required().shape({
          coupon_id: string().trim().required(),
        });
  }),

  productIds: productIdSchema,

  stripeToken: lazy((stripeToken) => {
    if (!stripeToken) {
      return mixed().strip();
    }

    return object().required().shape({
      id: string().trim().required(),
    });
  }),
}).required();

export const cancellationWebDescriptorSchema = object({
  cancelAtPeriodEnd: boolean().label("Cancel at period end").oneOf([true], "${path} must be true").required(),
  productIds: array()
    .length(1)
    .of(string().oneOf([STANDARD], "Must subscribe to standard plan to cancel")),
}).required();

const webDescriptorSchema = lazy((orderDescriptor) => {
  return "cancelAtPeriodEnd" in orderDescriptor ? cancellationWebDescriptorSchema : purchasableWebDescriptorSchema;
});

export default webDescriptorSchema;
