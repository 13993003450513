/* eslint-disable react/display-name */
import CustomPlanDialog from "billing/components/dialogs/CustomPlanDialog";
import NonSelfServiceDialog from "billing/components/dialogs/NonSelfServiceDialog";
import MobilePaymentError from "billing/exceptions/MobilePaymentError";
import _ from "lodash";
import React, { createContext, useContext, useMemo } from "react";
import router from "router";

import { getCurrentSubscription } from "collection/graphql/subscription";
import useSubscriptionProducts from "collection/graphql/subscription/hooks/useSubscriptionProducts";
import usePermissions from "hooks/usePermissions";
import useRestQuery from "hooks/useRestQuery";

import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import RestrictedAccessBlankslate from "components/fl-ui/RestrictedAccessBlankslate";

const BillingProductsProvider = createContext(undefined);

/**
 * @return {object}
 * @return {object} return.allPlans
 * @return {object} return.currentSubscription
 * @return {object[]} return.primaryPlans
 * @return {object[]} return.secondaryPlans
 */
export const useBillingProducts = () => useContext(BillingProductsProvider);

/**
 * Context provider which allows/denies access to the billing page
 * @param children
 * @return {*}
 */
export default ({ children }) => {
  const { data: allPlans, error, loading: loadingAllPlans } = useSubscriptionProducts();

  if (error) {
    throw new MobilePaymentError("Failed to get Subscription Products", {
      cause: error,
    });
  }

  const { data, loading: loadingSubscription } = useRestQuery(getCurrentSubscription, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const { subscription } = data ?? {};
  const loading = loadingSubscription || loadingAllPlans;

  const canWriteSubscription = usePermissions().hasPermission("subscription", ["read", "write"]);

  const primaryPlans = useMemo(() => {
    return _.filter(allPlans, { display: "primary" }).sort((a, b) => {
      const getMonthlyPrice = ({ plans }) => +_.find(plans, { interval: "month" }).price;
      return getMonthlyPrice(a) - getMonthlyPrice(b);
    });
  }, [allPlans]);
  const secondaryPlans = useMemo(() => {
    return _.sortBy(_.filter(allPlans, { display: "secondary" }), "name");
  }, [allPlans]);
  const isCurrentPlanPurchasable = !subscription?.currentPlan?.isCustomPlan;

  const context = useMemo(
    () => ({
      currentSubscription: subscription,
      allPlans: allPlans,
      primaryPlans,
      secondaryPlans,
    }),
    [allPlans, primaryPlans, secondaryPlans, subscription]
  );

  /*
   * Perform some checks to ensure the user has the ability to view the billing page
   */
  const onClose = () => router.navigate("fields", { replace: true, trigger: true });
  if (loading) {
    return <LoadingWrapper isLoading />;
  } else if (!canWriteSubscription) {
    return <RestrictedAccessBlankslate />;
  } else if (!subscription.selfService) {
    return <NonSelfServiceDialog onClose={onClose} />;
  } else if (!isCurrentPlanPurchasable) {
    return <CustomPlanDialog onClose={onClose} />;
  } else if (!subscription.canManageSubscription || subscription.cancelAtPeriodEnd) {
    router.navigate("settings/billing", { replace: true, trigger: true });
    return null;
  }

  return <BillingProductsProvider.Provider value={context}>{children}</BillingProductsProvider.Provider>;
};
