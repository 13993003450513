import { useEffect } from "react";

import useWindow from "hooks/useWindow";
import { useEventChannel } from "lib/events";

const useDocumentTitle = () => {
  const { document } = useWindow();
  const channel = useEventChannel("documentTitle");

  useEffect(
    () =>
      channel.on("update", (titleFragment) => {
        const updatedTitle = ["Bushel Farm", titleFragment || ""].filter((x) => x).join(" - ");
        if (document.title !== updatedTitle) {
          document.title = updatedTitle;
        }
      }),
    [channel]
  );
};

export default useDocumentTitle;
