import { useQuery } from "@apollo/client";
import { useViewportSpy } from "beautiful-react-hooks";
import MarketingCropCardWrap from "marketing/cards/MarketingCropCardWrap";
import React, { useRef } from "react";
import styled from "styled-components";

import { CASH_CONTRACTS_SUMMARY, GET_MARKETED_CROP } from "collection/graphql/marketing";
import { CardHeader, MarketedCropCardBody } from "modules/marketing/cards/components";

const MarketedCropCard = styled(({ abbreviatedMarketedCrop, className }) => {
  const containerRef = useRef();
  const hasBeenVisible = useRef(false);
  const isInViewport = useViewportSpy(containerRef);
  const isStandardMarketedCrop = abbreviatedMarketedCrop.__typename === "MarketedCrop";

  const { data: marketedCropData } = useQuery(GET_MARKETED_CROP, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    skip: !isInViewport || !isStandardMarketedCrop || !abbreviatedMarketedCrop?.id,
    variables: {
      id: abbreviatedMarketedCrop?.id,
    },
  });

  const { data: contractsData } = useQuery(CASH_CONTRACTS_SUMMARY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    skip: !isInViewport || !abbreviatedMarketedCrop?.id,
    variables: { marketedCropId: abbreviatedMarketedCrop?.id },
  });

  if (isInViewport && !hasBeenVisible.current) {
    hasBeenVisible.current = true;
  }

  const marketedCrop = isStandardMarketedCrop ? marketedCropData?.marketedCrop : abbreviatedMarketedCrop;
  const hasContracts = contractsData?.contractsList?.contracts?.length > 0;

  return (
    <div
      className={className}
      data-cy="marketedCropCard"
      id={`marketed-crop-${abbreviatedMarketedCrop?.id}-row`}
      ref={containerRef}
    >
      <MarketingCropCardWrap marketedCrop={marketedCrop}>
        <CardHeader marketedCrop={marketedCrop} />
        <MarketedCropCardBody hasContracts={hasContracts} marketedCrop={marketedCrop} />
      </MarketingCropCardWrap>
    </div>
  );
})`
  height: 100%;
  position: relative;
`;

export { MarketedCropCard };
