import usePlan from "billing/components/PlanLists/usePlan";
import _ from "lodash";
import numeral from "numeral";
import PropTypes from "prop-types";
import React from "react";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";

import { Button } from "components/fl-ui";
import { IconContainer } from "components/fl-ui/Icons";
import { UIColors } from "components/fl-ui/colors";
import { Typography } from "components/fl-ui/constants";

// TODO source the color values from the palette
const StyledPrimaryPlan = styled.div`
  --bgColor: #fafafb;
  --black: #000;
  --bodyTextColor: #5c6370;
  --darkBlue: #0b3c70;
  --lightBlue: #b8d7f9;
  --selectedBorderColor: #1e7ddc;

  background: var(--bgColor);
  border: 3px solid transparent;
  ${({ selected }) => selected && "border-color: var(--selectedBorderColor);"}
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  font-size: ${Typography.sizes.md};
  justify-content: space-between;
  padding: 40px 16px;
  text-align: center;
  transition: border 200ms ease-in;

  header {
    display: flex;
    flex-direction: column;
    gap: 16px;

    > * {
      margin: 0;
    }
  }

  .features {
    margin-top: 32px;

    .inclusionHeading {
      margin-top: 8px;
    }

    ul {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      gap: 32px;
      margin: 32px auto;
      text-align: left;
      width: 160px;

      > * {
        align-items: center;
        display: flex;
        gap: 5px;
        width: 100%;

        > :first-child {
          color: ${UIColors.green};
          width: 30px;
        }
      }
    }
  }

  .planName {
    color: var(--bodyTextColor);
    font-size: 18px;
    font-style: normal;
    margin-top: 0;
  }

  .pricing {
    color: var(--black);
    font-size: 40px;
    font-style: normal;

    > .currency {
      font-weight: ${Typography.weights.regular};
    }

    > .billingInterval {
      color: var(--bodyTextColor);
      font-size: ${Typography.sizes.lg};
    }
  }

  .inclusionHeading {
    color: var(--bodyTextColor);
    font-size: ${Typography.sizes.mn};
    text-transform: uppercase;
  }

  .limits {
    background: var(--lightBlue);
    color: var(--darkBlue);
    font-size: ${Typography.sizes.rg};
    font-weight: ${Typography.weights.bold};
    text-transform: uppercase;
  }

  .description {
    color: var(--bodyTextColor);
    text-align: center;
    font-style: normal;
    line-height: 1.4;
  }

  .trial {
    color: var(--black);
    font-weight: ${Typography.weights.medium};
    margin-top: 10px;
  }
`;

/**
 * @package
 */
const PrimaryPlan = ({ plan }) => {
  const { description, features, inclusionHeading, price, selected, setSelected } = usePlan(plan);
  const form = useFormContext();
  const billingInterval = form.watch("billingInterval");

  const acres = numeral(plan.acreLimit.max).format("0,0");
  const userLimit = plan.userLimits.users?.max ?? Infinity;
  const userLimitCopy = `${_.isFinite(userLimit) ? userLimit : "Unlimited"} ${userLimit === 1 ? "User" : "Users"}`;

  return (
    <StyledPrimaryPlan selected={selected} data-cy="plan" data-cy-plan={plan.id} data-cy-plan-display="primary">
      <section>
        <header>
          <h2 className="planName">{plan.name}</h2>
          <h3 className="pricing">
            <span className="currency">$</span>
            <span className="amount">{price}</span>
            <div className="billingInterval">per {billingInterval}</div>
          </h3>

          <div className="limits">
            {acres} acres • {userLimitCopy}
          </div>

          <p className="description">{description}</p>
        </header>

        <div className="features">
          {inclusionHeading && <h4 className="inclusionHeading">{inclusionHeading}</h4>}

          <ul>
            {features.map((feature, i) => (
              <li key={[plan.id, i].join("-")}>
                <IconContainer icon="circleCheck" />
                <span>{feature}</span>
              </li>
            ))}
          </ul>
        </div>
      </section>

      <footer>
        <Button color={selected ? "primary" : "secondary"} onClick={setSelected}>
          {selected ? "Selected" : "Select Plan"}
        </Button>

        {plan.trial?.length > 0 && (
          <div className="trial">
            Includes a {plan.trial.length}-{plan.trial.interval} free trial
          </div>
        )}
      </footer>
    </StyledPrimaryPlan>
  );
};

PrimaryPlan.propTypes = {
  plan: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default PrimaryPlan;
