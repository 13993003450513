import { css } from "aphrodite/no-important";
import moment from "moment";
import React, { Fragment } from "react";

import { UIColors } from "components/fl-ui/colors";
import { chartStyles } from "fields/components/RainfallHeatChart/common";

const ReferenceAreaLabel = (props) => {
  const { viewBox, start, end, refAreaSum, refAreaAvgSum, unit } = props;
  const { x, y, height, width } = viewBox;
  const labelX = width / 3.333 + x;
  const labelY = height / 2 + y;
  return (
    <Fragment>
      <text x={labelX} y={labelY} fill={UIColors.medium} fontSize={14} dy={-46} className={css(chartStyles.noSelect)}>
        {`${moment(start).format("MMM Do")} - ${moment(end).format("MMM Do")}`}
      </text>
      {!isNaN(refAreaSum) && (
        <Fragment>
          <text
            dy={-16}
            x={labelX}
            y={labelY}
            fill={UIColors.darker}
            fontSize={20}
            className={css(chartStyles.noSelect, chartStyles.refAreaLabel)}
          >
            {`${refAreaSum}${unit}`}
          </text>
          <text
            dy={0}
            x={labelX}
            y={labelY}
            fill={UIColors.darker}
            fontSize={12}
            className={css(chartStyles.noSelect, chartStyles.refAreaLabel)}
          >
            THIS YEAR
          </text>
        </Fragment>
      )}
      {!isNaN(refAreaAvgSum) && (
        <Fragment>
          <text
            dy={!isNaN(refAreaSum) ? 30 : -16}
            x={labelX}
            y={labelY}
            fill={UIColors.medium}
            fontSize={20}
            className={css(chartStyles.noSelect, chartStyles.refAreaLabel)}
          >
            {`${refAreaAvgSum}${unit}`}
          </text>
          <text
            dy={!isNaN(refAreaSum) ? 46 : 0}
            x={labelX}
            y={labelY}
            fill={UIColors.medium}
            fontSize={12}
            className={css(chartStyles.noSelect, chartStyles.refAreaLabel)}
          >
            10 YEAR AVERAGE
          </text>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ReferenceAreaLabel;
