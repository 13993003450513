import { gql } from "@apollo/client";

export default gql`
  fragment FieldWithAttachments on Field {
    id
    name
    attachments {
      id
      size
      created
      filepickerId
      name
      user {
        firstName
        id
        lastName
      }
    }
    __typename
  }
`;
