import { StyleSheet, css } from "aphrodite/no-important";
import classnames from "classnames";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

const Icon = (props) => {
  const [svgContent, setSvgContent] = useState(null);

  useEffect(() => {
    import(`./svg/${props.icon}.svg`)
      .then((module) => setSvgContent(module.default))
      .catch((err) => console.error(`Error loading SVG: ${err}`));
  }, [props.icon]);

  const styles = StyleSheet.create({
    svg: {
      display: "block",
      width: "100%",
      height: "auto",
      fill: "currentColor",
    },
  });

  const defaultAttributes = {
    className: classnames(css(styles.svg), props.className),
    disabled: props.disabled,
    height: "24",
    onClick: props.onClick,
    preserveAspectRatio: "xMidYMid meet",
    title: props.title || props.icon,
    viewBox: "0 0 24 24",
    width: "24",
    xmlns: "http://www.w3.org/2000/svg",
  };

  if (!svgContent) {
    return null;
  }

  return <svg {...defaultAttributes} dangerouslySetInnerHTML={{ __html: svgContent }} />;
};

Icon.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  title: PropTypes.string,
};

Icon.defaultProps = {
  className: "",
  disabled: false,
  onClick: null,
  title: "",
};

export default Icon;
