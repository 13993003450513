import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";

import useYearFieldCrops from "modules/fields/hooks/useYearFieldCrops";

import CropForm from "components/crop/CropForm";
import { CloseX } from "components/fl-ui/Icons";
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "components/fl-ui/Modal/Modal";
import ModalActionButtonGroup from "components/fl-ui/Modal/ModalActionButtonGroup";

const getSoleField = (fields) => fields?.length === 1 && fields[0];
const validate = ({ fields, ...cropData }) => {
  const soleField = getSoleField(fields);
  const hasValidCropData = !!fields.length && ["commodityId", "cropYear"].every((fieldName) => cropData[fieldName]);
  return hasValidCropData && (!soleField || cropData.acreage > 0);
};

const CropFormModal = ({ allYears, crop = {}, disabled, fields, immutableCropYear, onClose, onDelete, onSave }) => {
  const defaults = _.pick(crop, ["cropYear", "geometry", "geometricCircumference", "id"]);
  const isEdit = crop.id;
  defaults.fields = fields;
  defaults.commodityId = crop?.commodity?.id || crop?.commodityId;
  const soleField = getSoleField(fields);

  if (isEdit) {
    defaults.acreage = crop.acreage;
  } else if (soleField) {
    defaults.acreage = crop.acreage ?? soleField.acreage;
  }

  defaults.acreage = defaults.acreage && +defaults.acreage.toFixed(2);

  const [form, setForm] = useState(defaults);
  const { crops, getYearCropsForField, loading } = useYearFieldCrops(form.cropYear);
  const initialCommodityId = useMemo(() => defaults.commodityId, []);
  const onChange = (changes) => setForm({ ...form, ...changes });
  const disableSave = !validate(form) || disabled;
  const fieldId = getSoleField(form.fields)?.id;
  const fieldCrops = getYearCropsForField(fieldId);

  const hasConflictingCrop = (commodityId) =>
    fieldCrops.some(({ commodity }) => commodity.id === commodityId && (!isEdit || initialCommodityId !== commodityId));

  useEffect(() => {
    if (hasConflictingCrop(form.commodityId)) {
      onChange({ commodityId: null });
    }
  }, [fieldCrops, form.commodityId, loading]);

  const handleSave = () => {
    const matchingCommodityCrops = crops.filter(({ commodity }) => commodity.id === form.commodityId);
    const isValidCrop = ({ id }) => matchingCommodityCrops.every(({ field }) => id !== field.id);
    onSave({ ...form, fields: form.fields.filter(isValidCrop) });
  };

  return (
    <Modal width={700}>
      <ModalHeader>
        <ModalTitle>{isEdit ? "Edit crop" : `Add ${form.cropYear} crop`}</ModalTitle>
        <CloseX onClick={onClose} />
      </ModalHeader>

      <ModalBody>
        <CropForm
          fieldCrops={fieldCrops}
          fieldId={fieldId}
          hasConflictingCrop={hasConflictingCrop}
          immutableCropYear={immutableCropYear}
          isSingle={!!soleField}
          onChange={onChange}
          years={allYears}
          {...form}
        />
      </ModalBody>

      <ModalFooter>
        <ModalActionButtonGroup
          apply={{ action: handleSave, buttonText: "Save crop", disabled: disableSave }}
          cancel={{ action: onClose, buttonText: "Cancel", disabled }}
          danger={crop.id && { action: () => onDelete(crop.id), buttonText: "Delete crop", disabled }}
        />
      </ModalFooter>
    </Modal>
  );
};

CropFormModal.propTypes = {
  allYears: PropTypes.arrayOf(PropTypes.object).isRequired,
  crop: PropTypes.shape({
    acreage: PropTypes.number,
    commodity: PropTypes.object,
    cropYear: PropTypes.number,
    id: PropTypes.number,
  }),
  disabled: PropTypes.bool,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      acreage: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
    })
  ),
  immutableCropYear: PropTypes.bool,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
};

CropFormModal.defaultProps = {
  immutableCropYear: false,
  onClose: () => {},
};

export default CropFormModal;
