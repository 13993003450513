import styles from "activity/common/styles";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import React from "react";

import { useFieldForecast } from "collection/graphql/weather";

const Copy = (props) => <p className={css(styles.copyText)} {...props} />;

const formattedWeatherData = (value) => (value ? value : "--");
const WeatherSummary = ({ field, startDateTime }) => {
  const { forecast, loading } = useFieldForecast(field.id, false, startDateTime);
  const { dailyForecast } = forecast || {};

  if (loading) {
    return "Loading";
  }

  if (!forecast) {
    return <Copy>No weather data found for this date and location.</Copy>;
  }

  return (
    <>
      <Copy>{`Temperature: ${formattedWeatherData(dailyForecast?.temperatureMinF)}º F – ${formattedWeatherData(
        dailyForecast?.temperatureMaxF
      )}º F`}</Copy>
      <Copy>{`Wind: ${formattedWeatherData(dailyForecast?.windGust)}mph (${formattedWeatherData(
        dailyForecast?.windDirection
      )})`}</Copy>
    </>
  );
};

WeatherSummary.propTypes = {
  field: PropTypes.shape({
    centroid: PropTypes.shape({
      coordinates: PropTypes.array,
    }),
  }).isRequired,
};

export default WeatherSummary;
