import _ from "lodash";

import { GET_CURRENT_USER } from "collection/graphql/auth/queries";
import { marketingClient, restClient } from "collection/graphql/client";
import { GET_COMMODITIES } from "collection/graphql/commodities/queries";
import { getUserConfig } from "collection/graphql/config";
import { GET_CURRENT_ENTERPRISE } from "collection/graphql/enterprise/queries";
import { GET_FIELD_CROPS } from "collection/graphql/fields/queries";
import { refreshHistoricalRainfall, refreshLast24Rainfall } from "collection/graphql/fields/rainfall";
import { getAllUnits } from "collection/graphql/queries";
import { getCurrentSubscription } from "collection/graphql/subscription";
import { getCurrentCropYear } from "hooks/useCurrentCropYear";

const callAtInterval = (queryFn, interval) => queryFn().then(() => setInterval(queryFn, interval));

export const loadFieldsIndexQueries = _.once(async () => {
  callAtInterval(refreshLast24Rainfall, 10 * 60 * 1000); // call every 10 minutes
  callAtInterval(refreshHistoricalRainfall, 24 * 60 * 60 * 1000); // call every 24 hours
});

/*
 * This contains queries that we'd like to prefetch for performance purposes. This function
 * will only be called once. Generally we'll use the standard HoC way of making queries,
 * so putting queries here is a special case.
 */
export default _.once(() => {
  const subscription = restClient
    .watchQuery({
      query: GET_CURRENT_USER,
      fetchPolicy: "cache-only",
    })
    .subscribe(({ data }) => {
      if (data.currentUser) {
        subscription.unsubscribe();

        // This prefetches the current enterprise data which is used to check enterprise flags
        marketingClient.query({ query: GET_CURRENT_ENTERPRISE });

        restClient.query({ query: getUserConfig });

        marketingClient.query({ query: GET_FIELD_CROPS, variables: { years: [getCurrentCropYear()] } });
        marketingClient.query({ query: GET_COMMODITIES });
        marketingClient.query({ query: getAllUnits });
        restClient.query({ query: getCurrentSubscription });
      }
    });
});
