import React, { useReducer } from "react";
import { object, ref, string } from "yup";

import { updatePassword } from "collection/graphql/auth/mutations";
import useRestMutation from "hooks/useRestMutation";
import App from "layout/app";

import { Form, Input } from "components/fl-ui/Form";

/**
 * @typedef {"currentPassword"|"newPassword"|"passwordConfirm"} PasswordChangeFormField
 */

const PasswordChangeFormSchema = object({
  currentPassword: string().label("Current Password").required(),
  newPassword: string()
    .label("New Password")
    .required()
    .min(8)
    .notOneOf([ref("currentPassword")], ({ path }) => `${path} can not be the same as the old password.`)
    .matches(/^(?=.*[a-z])(?=.*\d).+$/i, ({ path }) => `${path} must contain at least 1 letter and 1 number`),
  passwordConfirm: string()
    .label("Retype Password")
    .oneOf([ref("newPassword")], "Passwords must match.")
    .required(),
});

const reducer = (state, newState) => ({
  ...state,
  ...newState,
});
const defaultState = () => ({
  currentPassword: "",
  errors: {},
  isSubmitting: false,
  newPassword: "",
  passwordConfirm: "",
  touched: {},
});

const PasswordChangeForm = () => {
  const [state, updateState] = useReducer(reducer, defaultState());
  const formIsValid = PasswordChangeFormSchema.isValidSync(state);
  const savePassword = useRestMutation(updatePassword)[0];

  /**
   * @param {PasswordChangeFormField} fieldName
   * @return {string?}
   */
  const getError = (fieldName) => {
    if (state.errors[fieldName]) {
      return state.errors[fieldName];
    }

    if (fieldName in state.touched) {
      try {
        PasswordChangeFormSchema.validateSyncAt(fieldName, state);
      } catch (error) {
        return error.message;
      }
    }

    return null;
  };

  /**
   * @param {PasswordChangeFormField} fieldName
   */
  const onTouched = (fieldName) => {
    if (!state.touched[fieldName]) {
      updateState({
        touched: {
          ...state.touched,
          [fieldName]: true,
        },
      });
    }
  };

  const handleFormChange = (event, formData) => {
    return updateState(formData);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { currentPassword, newPassword } = PasswordChangeFormSchema.cast(state);
    updateState({
      errors: {},
      isSubmitting: true,
    });

    try {
      await savePassword({
        variables: {
          input: {
            currentPassword,
            newPassword,
          },
        },
      });
      updateState(defaultState());
      App.notify("Changes saved.");
    } catch (error) {
      const { fields } = error.networkError.result;
      const newState = { errors: {}, isSubmitting: false };
      if ("currentPassword" in fields) {
        newState.errors.currentPassword = "Current Password is incorrect.";
      }
      if ("newPassword" in fields) {
        if (fields.newPassword.includes("min-length")) {
          newState.errors.newPassword = "New Password must be at least 8 characters.";
        } else if (fields.newPassword.includes("unchanged")) {
          newState.errors.newPassword = "New Password can not be the same as the old password.";
        }
      }

      updateState(newState);
    }
  };

  /**
   * @param {PasswordChangeFormField} fieldName
   * @return {string}
   */
  const errorClass = (fieldName) => {
    return getError(fieldName) ? "error" : "";
  };

  const baseFieldClass = "js-control-group control-group js-password password";

  return (
    <Form className="form-horizontal" onChange={handleFormChange} onSubmit={handleSubmit}>
      <h2 className="heading-underline">Change Password</h2>
      <div>
        <div className={`${baseFieldClass} ${errorClass("currentPassword")}`}>
          <label className="control-label" htmlFor="currentPassword">
            Current Password
          </label>
          <div className="js-controls controls">
            <Input
              controlled
              id="currentPassword"
              name="currentPassword"
              onBlur={() => onTouched("currentPassword")}
              type="password"
              value={state.currentPassword}
            />
            <span className="js-help-inline help-inline">{getError("currentPassword")}</span>
          </div>
        </div>

        <div className={`${baseFieldClass} ${errorClass("newPassword")}`}>
          <label className="control-label" htmlFor="newPassword">
            New Password
          </label>
          <div className="js-controls controls">
            <Input
              controlled
              id="newPassword"
              name="newPassword"
              onBlur={() => onTouched("newPassword")}
              type="password"
              value={state.newPassword}
            />
            <span className="js-help-inline help-inline">{getError("newPassword")}</span>
          </div>
        </div>

        <div className={`${baseFieldClass} ${errorClass("passwordConfirm")}`}>
          <label className="control-label" htmlFor="passwordConfirm">
            Retype Password
          </label>
          <div className="js-controls controls">
            <Input
              controlled
              id="passwordConfirm"
              name="passwordConfirm"
              onBlur={() => onTouched("passwordConfirm")}
              type="password"
              value={state.passwordConfirm}
            />
            <span className="js-help-inline help-inline">{getError("passwordConfirm")}</span>
          </div>
        </div>
      </div>
      <div className="form-actions">
        <button className="btn btn-blue save_person" disabled={state.isSubmitting || !formIsValid} type="submit">
          Change Password
        </button>
      </div>
    </Form>
  );
};

export default PasswordChangeForm;
