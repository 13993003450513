import _ from "lodash";
import React, { useState } from "react";
import CommoditySection from "settings/pages/Commodities/containers/CommoditySection";
import styled from "styled-components";

import useCommodities from "hooks/useCommodities";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import usePermissions from "hooks/usePermissions";

import { Stack } from "components/fl-ui/Layout";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import Search from "components/fl-ui/Search";

const Container = styled(Stack)`
  margin-top: 1rem;
`;

const CommoditiesSettingsPage = () => {
  const { commodities, loading } = useCommodities();
  const [search, setSearch] = useState("");
  const hasFieldsFeature = useEnterpriseFeature("fields");
  const canWriteCommodities = usePermissions().canWrite("commodities") && hasFieldsFeature;

  let sortedCommodities = _.sortBy(commodities, "name");
  if (search) {
    sortedCommodities = sortedCommodities.filter(({ name }) => name.toLowerCase().includes(search.toLowerCase()));
  }
  const [used, unused] = _.partition(sortedCommodities, "isUsed");

  return loading ? (
    <LoadingWrapper isLoading />
  ) : (
    <Container>
      <Search onChange={(search) => setSearch(_.trim(search))} placeholder={`Type to search \u2026`} autoFocus />

      <CommoditySection
        abridge={!search}
        commodities={used}
        description="Create custom commodities or manage a customized commodity's unit or use."
        showAddButton={canWriteCommodities}
        title="Custom Commodities"
      />

      <CommoditySection
        abridge={!search}
        commodities={unused}
        description="Edit unit or use for any standard commodity. Customized commodities will appear under Custom Commodities."
        title="Standard Commodities"
      />
    </Container>
  );
};

CommoditiesSettingsPage.tabId = "commodities";
CommoditiesSettingsPage.title = "Commodities";

export default CommoditiesSettingsPage;
