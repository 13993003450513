import useMenuItems from "../hooks/useMenuItems";
import ResponsiveMenuContainer from "./ResponsiveMenuContainer";
import ResponsiveMenuDrawer from "./ResponsiveMenuDrawer";
import ResponsiveMenuItem from "./ResponsiveMenuItem";
import { MediaAware } from "@farmlogs/fl-ui";
import React, { useState } from "react";
import { Transition, TransitionGroup } from "react-transition-group";

import History from "lib/history";

import { Logo } from "components/fl-ui/Icons";
import { NavigationScreen } from "components/fl-ui/Layout/Navigation/Screen";
import Toolbar from "components/fl-ui/Layout/Navigation/Toolbar";
import NavigationHeader from "components/fl-ui/Layout/Navigation/components/NavigationHeader";

const ResponsiveMenu = () => {
  const [showDrawer, setShowDrawer] = useState(false);
  const { drawerItems, mainMenuItems, activeMenuItemId } = useMenuItems();

  const handleClick = (event, { id, onClick }) => {
    if (typeof onClick === "function") {
      onClick(event);
    }

    if (id === "more") {
      event.preventDefault();
      setShowDrawer((showDrawer) => !showDrawer);
    } else if (id === "back") {
      event.preventDefault();
      setShowDrawer(false);
      History.back();
    } else {
      setShowDrawer(false);
    }
  };

  return (
    <MediaAware mobile>
      <NavigationHeader>
        <Logo size="sm" />
        <Toolbar />
      </NavigationHeader>

      <ResponsiveMenuContainer data-cy="mobileMenu" id="mobileMenuContainer">
        {mainMenuItems.map((menuItem) => (
          <ResponsiveMenuItem
            {...menuItem}
            active={activeMenuItemId === menuItem.id || menuItem.id === "back"}
            boldTitles
            height="3.5rem"
            key={menuItem.id}
            onClick={(event) => handleClick(event, menuItem)}
            p=".5rem"
            width="20%"
          />
        ))}
      </ResponsiveMenuContainer>

      <TransitionGroup>
        {showDrawer && (
          <Transition key="mainMenuTransition" timeout={250} transitionName="mainMenuTransition">
            {(state) => (
              <>
                <ResponsiveMenuDrawer state={state}>
                  {drawerItems.map((menuItem) => (
                    <ResponsiveMenuItem
                      {...menuItem}
                      active={activeMenuItemId === menuItem.id}
                      key={menuItem.id}
                      onClick={(event) => handleClick(event, menuItem)}
                      py=".3rem"
                    />
                  ))}
                </ResponsiveMenuDrawer>

                <NavigationScreen key="screen" onClick={() => setShowDrawer(false)} state={state} />
              </>
            )}
          </Transition>
        )}
      </TransitionGroup>
    </MediaAware>
  );
};

export default ResponsiveMenu;
