import { css } from "aphrodite/no-important";
import React from "react";

import PayGateCommonBlankSlateBanner from "components/advertisements/banners/PayGateCommonBlankSlateBanner";
import styles from "components/advertisements/styles";

const MarketingBlankSlateBanner = (props) => {
  props = {
    ...props,
    actionButtonText: "Try Marketing for free",
    secondaryText: "Marketing is included in the Essentials and Business plan, and you can try it for free.",
    imagePath: "images/pay_gates/banners/marketing.svg",
    title: "With Marketing you can:",
  };

  return (
    <PayGateCommonBlankSlateBanner {...props}>
      <ul>
        <li className={css(styles.valueProp)}>Calculate breakeven for your farm.</li>
        <li className={css(styles.valueProp)}>Track grain sales and hedges as you make them.</li>
        <li className={css(styles.valueProp)}>See the value of your unsold bushels as market prices move.</li>
        <li className={css(styles.valueProp)}>
          Make decisions on the go with a complete view of your current marketing position.
        </li>
      </ul>
    </PayGateCommonBlankSlateBanner>
  );
};

export default MarketingBlankSlateBanner;
