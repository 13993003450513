import _ from "lodash";

import utils from "lib/validation/utils";

export const isValid = (formData, errors = {}) => _.isEmpty(validate(formData, { errors }));

export const validate = (formData, options = {}) => {
  const { commodity, harvestBasis, harvestPrice } = formData;
  const errors = options.errors || {};

  // harvestBasis
  if (commodity && commodity.marketSymbol) {
    if (harvestBasis?.length > 0 && !utils.isFloat(harvestBasis)) {
      errors.harvestBasis = { value: harvestBasis, msg: "Harvest basis is invalid" };
    }
  } else {
    // harvestPrice
    if (!utils.isFloat(harvestPrice, { min: 0.01 })) {
      errors.harvestPrice = { value: harvestPrice, msg: "Budget price must be greater than zero" };
    }
  }

  return errors;
};

export default {
  isValid,
  validate,
};
