import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import React from "react";

export default createReactClass({
  displayName: "Locate",

  propTypes: {
    onChange: PropTypes.func,
  },

  contextTypes: {
    map: PropTypes.object.isRequired,
  },

  getDefaultProps() {
    return { onChange() {} };
  },

  getLocation() {
    this.context.map.setView(this.context.map.getUserLocation(), 18);
    this.props.onChange();
  },

  render() {
    return (
      <span className="leaflet-control map-notification-wrapper">
        <a className="btn locate-me-icon" onClick={this.getLocation}>
          <img src="/images/map/icon-locate-me.png" alt="Find my current location" />
        </a>
      </span>
    );
  },
});
