import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";

import FormGroup from "components/fl-ui/Form/FormGroup";

const ControlledFormGroup = ({ name, render, ...props }) => {
  const form = useFormContext();
  const { schema } = form;

  const descriptor = useMemo(() => {
    return {
      label: null,
      nullable: true,
      optional: true,
      ...(schema?.describe?.().fields?.[name] || {}),
    };
  }, [schema]);

  const label = useMemo(() => {
    return "label" in props ? props.label : descriptor.label;
  }, [props.label, descriptor]);

  const optional = useMemo(() => {
    if ("optional" in props) {
      return props.optional;
    }

    return descriptor.nullable || descriptor.optional;
  }, [props.optional, descriptor]);

  return (
    <Controller
      control={form.control}
      name={name}
      render={({ field, fieldState, formState }) => {
        return (
          <FormGroup {...props} error={formState.errors[name]?.message} label={label} optional={optional}>
            {render({ field, fieldState, formState })}
          </FormGroup>
        );
      }}
    />
  );
};

ControlledFormGroup.propTypes = {
  ...FormGroup.propTypes,
  name: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired,
};

export default ControlledFormGroup;
