import _ from "lodash";
import moment from "moment";

/**
 * Returns the activity before or on a given date.
 *
 * @param {Activity[]} activities
 * @param {Date} referenceDate
 * @return {Activity|undefined}
 */
const findLatestActivity = (activities, referenceDate) => {
  referenceDate = moment(referenceDate).format("YYYY-MM-DD");
  const beforeDate = _.reject(activities, ({ date }) => date > referenceDate);
  return _.maxBy(beforeDate, "date");
};

export default findLatestActivity;
