import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";

import NotificationPopovers from "modules/notifications/components/NotificationPopovers";
import { withNotifications } from "modules/notifications/hoc";

const buildRollupNotification = (items) => {
  return {
    body: "", // TODO need feedback from product on how to proceed here
    created: moment(),
    id: -1,
    title: `${items.length} new notifications`,
    type: "rollup",
  };
};

class NotificationPopoverContainer extends Component {
  static propTypes = {
    itemTTL: PropTypes.number,
    rollupThreshold: PropTypes.number,
  };

  static defaultProps = {
    itemTTL: 10000,
    rollupThreshold: 5,
  };

  constructor(props) {
    super(props);

    this.state = {
      dismissedIds: [],
      onDismiss: ((...ids) => {
        this.setState({
          dismissedIds: this.state.dismissedIds.concat(ids),
        });
      }).bind(this),
      totalNotifications: 0,
    };
  }

  static getDerivedStateFromProps(props, state) {
    let { notifications = [] } = props.data;
    const now = moment();
    const allNotifications = notifications;
    state.totalNotifications = allNotifications.length;

    // filter out any notifications that have been dismissed on the client, but the data hasn't fully synced up yet
    const notificationIds = _.map(notifications, "id");
    state.dismissedIds = state.dismissedIds.filter((id) => notificationIds.includes(id));
    notifications = _.reject(notifications, ({ id }) => state.dismissedIds.includes(id));

    // filter out any notifications that are older than the item time-to-live
    notifications = notifications.filter(({ created }) =>
      moment(created).add(props.itemTTL, "milliseconds").isAfter(now)
    );

    if (notifications.length > props.rollupThreshold) {
      notifications = _.dropRight(notifications, notifications.length - props.rollupThreshold);
      notifications.unshift(buildRollupNotification(allNotifications));
    }

    state.notifications = notifications;

    return state;
  }

  componentDidUpdate() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState(_.clone(this.state));
    }, this.props.itemTTL);
  }

  render() {
    return (
      <NotificationPopovers
        notifications={this.state.notifications}
        onDismiss={this.state.onDismiss}
        count={this.state.totalNotifications}
      />
    );
  }
}

export default withNotifications(NotificationPopoverContainer);
