import CanvasLayer from "lib/map/canvas_layer";
import Colorizer from "lib/workers/colorizer";

import { getIconPath } from "components/field/utils";

const MAX = Math.pow(255, 3);

class Wdrvi extends CanvasLayer {
  constructor({ bounds, layer }) {
    super(bounds);
    this.bounds = bounds;
    this.layer = layer;

    const min = this.layer?.details?.adjusted_min || 0;
    const max = this.layer?.details?.adjusted_max || MAX;
    const mean = min + (max - min) / 2;
    const layerColors = ["#e3694c", "#ffec95", "#41a259"];

    this.colorizer = new Colorizer({
      domain: [min, mean, max],
      range: layerColors,
      interpolate: true,
    });

    this.legendColors = new Colorizer({
      domain: [0, MAX / 2, MAX],
      range: layerColors,
      interpolate: true,
    });
  }

  onAdd() {
    super.onAdd(...arguments);
    return this.render(this.layer.details.url);
  }

  getColorizer() {
    return this.colorizer;
  }

  getLegend(width) {
    return {
      src: this.legendColors.getLegend(width),
      ticks: ["WORSE", "BETTER"],
    };
  }

  _onImageLoad() {
    return super._onImageLoad(...arguments);
  }

  renderComposite(options) {
    options.url = this.layer.details.url;
    options.background = getIconPath(this.layer.field, options);
    return super.renderComposite(options);
  }
}

export default Wdrvi;
