import { css } from "aphrodite";
import PropTypes from "prop-types";
import React from "react";
import { Row, Col } from "react-styled-flexboxgrid";
import WarningBanner from "settings/components/WarningBanner";

import AuthFormBanner from "layout/components/AuthFormBanner";
import styles from "layout/styles";

import { Button } from "components/fl-ui";
import { Form } from "components/fl-ui/Form";
import { Logo } from "components/fl-ui/Icons";
import Fullscreen from "components/fl-ui/util/Fullscreen";

const AuthForm = ({ action, children, copy, disabled, error, footer, heading, label, onChange, onSubmit }) => {
  return (
    <Fullscreen>
      <Row className={css(styles.fullscreen)}>
        <Col xs={12} md={4} className={css(styles.authContainer)}>
          {heading || <Logo size="sm" />}
          <AuthFormBanner mt="10px" />

          <Form onChange={onChange} onSubmit={onSubmit}>
            {label && <h1>{label}</h1>}
            {copy}
            {error && <WarningBanner message={error} />}

            <div className={css(styles.formContainer)}>{children}</div>
            {action && (
              <Button color="primary" disabled={disabled} display="block" size="lg" type="submit">
                {action}
              </Button>
            )}
            {footer}
          </Form>
        </Col>

        <Col xs={false} md={8}>
          <div className={css(styles.sideBackground)} />
        </Col>
      </Row>
    </Fullscreen>
  );
};

AuthForm.propTypes = {
  action: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  copy: PropTypes.node,
  disabled: PropTypes.bool,
  error: PropTypes.node,
  footer: PropTypes.node,
  heading: PropTypes.node,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
};

export default AuthForm;
