/* eslint-disable react/display-name */
import $ from "jquery";
import moment from "moment";
import React from "react";
import autoBind from "react-autobind";

import ajax from "lib/ajax";

const DEFINITIONS_URL = "/planner/reports/definitions";
const DOWNLOAD_URL = "/planner/reports/download";
const getDefinitions = (id) => ajax(`${DEFINITIONS_URL}${id ? `/${id}` : ""}`);

const DATE_FORMAT = "YYYY-MM-DD";

const getDefaultForType = (type) => {
  // hook to set a default value if one is not supplied by the backend
  if (type == "date_range") {
    const endDate = moment().format(DATE_FORMAT);
    const startDate = moment().subtract(1, "year").format(DATE_FORMAT);
    return [startDate, endDate].join();
  }
  return undefined;
};

const getDefaultParameters = ({ parameters }) => {
  return parameters.reduce((defaults, p) => {
    if (p.default == undefined && p.required) {
      p.default = getDefaultForType(p.type);
    }
    defaults[p.id] = p.default || undefined;
    return defaults;
  }, {});
};

const getLink = (params) => ({
  format: params.format,
  href: `/${ajax.API_VERSION}${DOWNLOAD_URL}?${$.param(params)}`,
});

const withDefinitions = (Component) => {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        definitions: [],
        loading: true,
        links: [],
        parameters: {},
        filter: {},
      };
      this.displayName = `withDefinitions(${Component.displayName})`;
      autoBind(this);
    }

    componentDidMount() {
      this.getDefinitions();
    }

    getDefinitions() {
      this.setState({ loading: true });

      return getDefinitions(this.props.id).fail(this.handleError).then(this.setDefinitions).then(this.toggleLoading);
    }

    setDefinitions(definitions) {
      const { id } = this.props;
      const parameters = id
        ? getDefaultParameters(definitions)
        : definitions.filter((x) => x.id === id).map(getDefaultParameters)[0];

      this.setState({ definitions });

      if (id) {
        this.handleChange({ parameters });
      }
    }

    handleError({ responseJSON = {} }) {
      this.setState({ error: responseJSON, loading: false });
    }

    toggleLoading() {
      this.setState({ loading: !this.state.loading });
    }

    handleChange({ parameters }) {
      const links = this.state.definitions.formats
        ?.filter((format) => format !== "json")
        ?.map((format) =>
          getLink({
            id: this.props.id,
            format,
            ...parameters,
          })
        );

      this.setState({ parameters, links });
    }

    render() {
      return <Component {...this.props} {...this.state} onChange={this.handleChange} />;
    }
  };
};

export default withDefinitions;
