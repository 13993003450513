import { StyleSheet } from "aphrodite/no-important";

import { UIColors, GreyColors } from "components/fl-ui/colors";
import { Mixins, BorderRadius, Spacing } from "components/fl-ui/constants";

const ARROW_SIZE = 8;
const BACKGROUND_COLOR = UIColors.white;
const BORDER_COLOR = GreyColors.smoke90;

const popoverStyles = StyleSheet.create({
  popover: {
    background: BACKGROUND_COLOR,
    border: `1px solid ${BORDER_COLOR}`,
    borderRadius: BorderRadius.medium,
    padding: Spacing.regular,
    maxWidth: Mixins.toRem(450),
    boxShadow: `0 2px 4px 0 rgba(0,0,0,0.15)`,
    zIndex: 5000,
  },
  withCloseX: {
    paddingRight: Mixins.toRem(68),
  },
  closeX: {
    width: Spacing.medium,
    height: Spacing.medium,
    position: "absolute",
    cursor: "pointer",
    top: Spacing.large,
    right: Spacing.large,
    color: UIColors.primary,
  },
  arrow: {
    ":after": {
      ...Mixins.beforeAfter,
      border: `${ARROW_SIZE}px solid transparent`,
    },
    ":before": {
      ...Mixins.beforeAfter,
      border: `${ARROW_SIZE + 1}px solid transparent`,
    },
  },
});

export { popoverStyles, ARROW_SIZE, BORDER_COLOR, BACKGROUND_COLOR };
