import { gql } from "@apollo/client";

export default gql`
  query getProductPurchaseSummaries(
    $year: Int!
    $purchaseStartDate: Date
    $purchaseEndDate: Date
    $commodities: [Int]
    $products: [Int!]
    $productTypes: [ProductType!]
    $restrictedUse: Boolean
  ) {
    purchaseSummaries: getProductPurchaseSummary(
      products: $products
      productTypes: $productTypes
      commodities: $commodities
      purchaseStartDate: $purchaseStartDate
      purchaseEndDate: $purchaseEndDate
      restrictedUse: $restrictedUse
      year: $year
    ) @requiresPermission(feature: "inputs", actions: ["read"]) {
      amountAppliedTotal
      amountAppliedUnit
      amountRemainingTotal @client
      amountRemainingUnit @client
      amountPurchasedTotal
      amountPurchasedUnit
      costPerUnit
      costTotal
      lastPurchaseDate
      activityInputs {
        id
        activity {
          id
        }
      }
      product {
        ...MinimalPurchasedProduct
      }
      purchases {
        id
        vendor {
          name
        }
      }
    }

    totalPurchaseCount: getProductPurchaseSummary(year: $year)
      @requiresPermission(feature: "inputs", actions: ["read"]) {
      # This query exists only to get a count of the total purchases
      __typename
    }
  }

  fragment MinimalPurchasedProduct on BaseProduct {
    id
    name
    type
    unitType
    ... on SeedProduct {
      commodity {
        ...Commodity
      }
    }
  }
`;
