import { css } from "aphrodite";
import _ from "lodash";
import React from "react";

import styles from "modules/activity/common/styles";

const AttributeListing = ({ attribute, emptyMessage }) =>
  _.isEmpty(attribute) ? (
    <p className={css(styles.listSpacing)}>{emptyMessage}</p>
  ) : (
    <ul className={`unordered-list ${css(styles.listSpacing)}`}>
      {attribute.map((item, i) => (
        <li key={`item${i}`}>{_.upperFirst(item.name)}</li>
      ))}
    </ul>
  );

export default AttributeListing;
