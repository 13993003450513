import _ from "lodash";

/**
 * @param {FuturesContract} contract
 * @private
 * @return {FuturesContractInput}
 */
const buildFuturesContractInput = (contract) => {
  const futuresContractInput = _.pick(contract, [
    "contractDate",
    "contractPrice",
    "contractStatus",
    "enabled",
    "expirationDate",
    "marketedCropId",
    "notes",
    "quantity",
    "quantityString",
    "settlementPrice",
  ]);

  futuresContractInput.contractType = contract.contractSubtype;
  futuresContractInput.underlying = contract.underlying.symbol;

  return futuresContractInput;
};

export default buildFuturesContractInput;
