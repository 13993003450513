import PropTypes from "prop-types";
import React from "react";
import { Marker } from "react-mapbox-gl";

const Pin = (props) => {
  const { circleFill, fill, style, ...rest } = props;
  const markerStyle = {
    ...style,
    color: fill,
  };

  return (
    <Marker {...rest} style={markerStyle}>
      <svg display="block" height={41} width={27} viewBox="0 0 27 41">
        <g>
          <ellipse cx={13.5} cy={34.80029} opacity={0.04} rx={10.5} ry={5.250023} />
          <ellipse cx={13.5} cy={34.80029} opacity={0.04} rx={10.5} ry={5.250023} />
          <ellipse cx={13.5} cy={34.80029} opacity={0.04} rx={9.5} ry={4.77275} />
          <ellipse cx={13.5} cy={34.80029} opacity={0.04} rx={8.5} ry={4.295499} />
          <ellipse cx={13.5} cy={34.80029} opacity={0.04} rx={7.5} ry={3.818223} />
          <ellipse cx={13.5} cy={34.80029} opacity={0.04} rx={6.5} ry={3.340947} />
          <ellipse cx={13.5} cy={34.80029} opacity={0.04} rx={5.5} ry={2.863671} />
          <ellipse cx={13.5} cy={34.80029} opacity={0.04} rx={4.5} ry={2.386369} />
        </g>

        <path
          d="m27 13.5c0 5.574644-6.749999 13.500002-12.25 21.000002-.733335 1.000002-1.766665 1.000002-2.5 0-5.5000007-7.5-12.25-15.27744-12.25-21.000002 0-7.4558441 6.0441559-13.5 13.5-13.5 7.455844 0 13.5 6.0441559 13.5 13.5z"
          fill="currentColor"
        />

        <path
          d="m13.5 0c-7.4558441 0-13.5 6.0441559-13.5 13.5 0 5.722562 6.7499993 13.5 12.25 21 .75 1.022727 1.766664 1.000004 2.5 0 5.500001-7.5 12.25-15.425356 12.25-21 0-7.4558441-6.044156-13.5-13.5-13.5zm0 1c6.915404 0 12.5 5.584596 12.5 12.5 0 2.398657-1.504416 5.681431-3.779297 9.238281-2.27488 3.556851-5.515584 7.403886-8.277344 11.169922-.199914.272611-.330644.414535-.443359.533203-.112715-.118668-.243445-.260592-.443359-.533203-2.77216-3.780218-5.6417726-7.594044-8.041016-11.134765-2.3992434-3.540723-4.015625-6.8199-4.015625-9.273438 0-6.915404 5.584596-12.5 12.5-12.5z"
          opacity=".25"
        />

        <circle cx={13.5} cy={13.5} opacity={0.25} r={5.499996} />
        <circle cx={13.5} cy={13.5} fill={circleFill} r={5.499996} />
      </svg>
    </Marker>
  );
};

Pin.propTypes = {
  anchor: PropTypes.string,
  circleFill: PropTypes.string,
  coordinates: PropTypes.arrayOf(PropTypes.number).isRequired,
  fill: PropTypes.string,
  style: PropTypes.object,
};

Pin.defaultProps = {
  anchor: "bottom",
  circleFill: "#fff",
  fill: "#454545",
  style: {},
};

export default Pin;
