import leaflet from "leaflet";
import FieldPopup from "map/field/view";
import badges from "map/layer/badges";
import _ from "underscore";

import { getCropLayer } from "hooks/useCropLayer";
import { MAP_SELECTION } from "lib/metrics/events";

const fieldLayerGroup = leaflet.layerGroup();
const popup = new FieldPopup();
const getFieldId = ({ feature }) => feature?.properties?.field?.id;

const showPopup = ({ field, fieldCrops, layer }) => {
  const map = layer._map;
  const fieldId = getFieldId(layer);

  if (!map.hasLayer(popup) || popup?.model?.id !== fieldId) {
    map.closePopup(popup);
    popup.field = field;
    popup.fieldCrops = fieldCrops;
    popup.setLatLng(layer.getCenter(fieldId).reverse());
    popup.openOn(map);
  }
};

const hidePopup = (layer) => {
  const map = layer.target._map;
  const nextTarget = layer.originalEvent?.relatedTarget;

  if (!nextTarget || nextTarget === map.getContainer()) {
    map.closePopup(popup);
  }
};

const fieldDetail = ({ layer }) => {
  window.location.href = `#fields/${getFieldId(layer)}`;
  MAP_SELECTION.track();
};

const addField = ({ crops, enablePopup, field, map }) => {
  const fieldCrops = crops.filter((crop) => crop.field.id === field.id);
  const { layer, pattern } = getCropLayer({ field, fieldCrops });
  layer && fieldLayerGroup.addLayer(layer);
  pattern && pattern.addTo(map);

  badges.addData({
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: layer.getCenter(field.id),
    },

    properties: {
      field: { id: field.id },
      abbr: field.name[0].toUpperCase(),
      color: "gray",
      centroid: field.centroid,
    },
  });

  if (enablePopup) {
    layer.addEvents({
      click: () => fieldDetail({ layer }),
      mouseout: hidePopup,
      mouseover: () => showPopup({ field, fieldCrops, layer }),
    });
  }
};

const addFields = ({ crops, enablePopup, fields }, map) => {
  fields.map((field) => addField({ crops, enablePopup, field, map }));
  map.addLayer(fieldLayerGroup).addLayer(badges);
};

export default leaflet.Layer.extend({
  initialize(options) {
    this.options = { ...this.options, ...options };
    return _.bindAll(this);
  },

  dismount() {
    const map = this._map ?? options.map;
    fieldLayerGroup.clearLayers();
    fieldLayerGroup.removeFrom(map);
    badges.clearLayers();
    badges.removeFrom(map);
    map.closePopup(popup);
  },

  onAdd(_map) {
    this._map = _map;
    addFields(this.options, this._map);
    popup.createCrop = this.options.createCrop;
  },

  onRemove() {
    this.off("mouseout");
    this.dismount();
  },

  updateCrops(options) {
    const map = this._map ?? options.map;
    this.dismount();
    this.options = { ...this.options, ...options };
    addFields(this.options, map);
  },
});
