import { NEXT_YEAR, fullWidth, CapsHeader } from "../Helpers";
import PropTypes from "prop-types";
import React from "react";
import autoBind from "react-autobind";
import { times } from "underscore";

import { Select } from "components/fl-ui/Form/index";

/**
 * Year picker filter parameters.
 */
class YearFilter extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  onYearChanged(event) {
    this.props.onParamChanged({ id: this.props.param.id, value: event.target.value });
  }

  render() {
    const years = times(10, (n) => {
      const val = NEXT_YEAR - n;
      return {
        key: val,
        value: val,
        label: val,
      };
    });

    return (
      <div key={this.props.param.id}>
        <CapsHeader>{this.props.param.title}</CapsHeader>
        <Select
          defaultValue={this.props.param.default}
          id={`report-filter-${this.props.param}`}
          name={this.props.param.id}
          style={fullWidth}
          onChange={this.onYearChanged}
          options={years}
        />
      </div>
    );
  }
}

YearFilter.propTypes = {
  param: PropTypes.object.isRequired,
  onParamChanged: PropTypes.func.isRequired,
};

export default YearFilter;
