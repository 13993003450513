import { css } from "aphrodite/no-important";
import classNames from "classnames";
import moment from "moment";
import React, { Component } from "react";
import _ from "underscore";

import SegmentedControl from "components/fl-ui/SegmentedControl";
import { Body, Col, Header, HeaderCol, Row, Table } from "components/fl-ui/Table";
import { chartStyles } from "fields/components/RainfallHeatChart/common";

const getValueString = (val, type) => {
  if (type === "rainfall") {
    return `${_.numberFormat(val, 2)}”`;
  } else {
    return _.numberFormat(val, 0);
  }
};

const DataTableWeeklyBody = ({ sums, type }) => {
  return (
    <Body>
      {sums.map(({ date, weekly, ytd }, i) => {
        return (
          <Row key={i}>
            <Col>{moment(date).format("MMM D")}</Col>
            <Col align="right">{getValueString(weekly, type)}</Col>
            <Col align="right">{getValueString(ytd, type)}</Col>
          </Row>
        );
      })}
    </Body>
  );
};

const DataTableDailyBody = ({ data, type }) => {
  const rows = data.filter((day) => day.amount > 0).reverse();

  return (
    <Body>
      {rows.map(({ amount, date, total }, i) => {
        return (
          <Row key={i}>
            <Col>{moment(date).format("MMM D")}</Col>
            <Col align="right">{getValueString(amount, type)}</Col>
            <Col align="right">{getValueString(total, type)}</Col>
          </Row>
        );
      })}
    </Body>
  );
};

class DataTable extends Component {
  state = {
    isWeekly: true,
  };

  _setWeeklySums = (year, day, amount) => {
    if (year == null || year.length === 0) {
      return [];
    }
    const weeklySums = [];
    let currentWeek = 1;
    let tempSum = 0;
    let ytdSum = 0;
    let startingDate = moment(year[0][day], "YYYY-MM-DD").startOf("year");

    year.forEach(function (elem) {
      if (moment(elem[day], "YYYY-MM-DD").dayOfYear() > moment(startingDate).add(7, "days").dayOfYear()) {
        if (tempSum > 0) {
          ytdSum += tempSum;
          weeklySums.push({
            weekly: Number(tempSum),
            ytd: Number(ytdSum),
            date: startingDate,
          });
        }
        tempSum = elem[amount];
        const missingWeeks = moment(elem[day], "YYYY-MM-DD").week() - currentWeek;
        for (let i = 1, end = missingWeeks - 2; i <= end; i++) {
          if (missingWeeks > 1) {
            startingDate = moment(startingDate).add(7, "days").format("YYYY-MM-DD");
          }
        }
        startingDate = moment(startingDate).add(7, "days").format("YYYY-MM-DD");
        return (currentWeek = moment(startingDate).week());
      } else {
        return (tempSum += elem[amount]);
      }
    });

    if (tempSum > 0) {
      ytdSum += tempSum;
      weeklySums.push({
        weekly: Number(tempSum),
        ytd: Number(ytdSum),
        date: startingDate,
      });
    }

    return weeklySums.reverse();
  };

  prepareData = () => {
    const { daily, type } = this.props;
    let weeklySums;
    const data = {};
    const year = daily;

    if (type === "rainfall") {
      weeklySums = this._setWeeklySums(year, "date", "amount", 2);
      data.rainfall = { weeklySums };
      data.precision = 2;
    } else if (type === "gdd") {
      weeklySums = this._setWeeklySums(year, "date", "amount", 0);
      data.gdd = { weeklySums };
      data.precision = 0;
    }

    return data;
  };

  changeTablePeriod = (value) => {
    return this.setState({
      isWeekly: value === "weekly",
    });
  };

  render() {
    const { daily, type } = this.props;
    const { isWeekly } = this.state;
    const title = type === "rainfall" ? "Rainfall" : "Growing Degree Days";
    const data = this.prepareData();

    return (
      <div className="js-data-table">
        {data != null &&
        ((data.rainfall != null && data.rainfall.weeklySums.length) ||
          (data.gdd != null && data.gdd.weeklySums.length)) ? (
          <div>
            <h2
              className={classNames(css(chartStyles.headerContainer), "heading-underline")}
              style={{ paddingBottom: "16px", minHeight: "48px" }}
            >
              {title}
              <div
                className={classNames(css(chartStyles.controlContainer), "chart-toggle js-table-period")}
                data-toggle="buttons-radio"
              >
                <SegmentedControl
                  value={isWeekly ? "weekly" : "daily"}
                  onChange={this.changeTablePeriod}
                  options={[
                    {
                      label: "Weekly",
                      optionValue: "weekly",
                    },
                    {
                      label: "Daily",
                      optionValue: "daily",
                    },
                  ]}
                />
              </div>
            </h2>
            <Table>
              <Header>
                <Row>
                  <HeaderCol>
                    <span>{isWeekly ? "Week of" : "Date"}</span>
                  </HeaderCol>
                  <HeaderCol align="right">{title}</HeaderCol>
                  <HeaderCol align="right">Year-to-date</HeaderCol>
                </Row>
              </Header>

              {isWeekly && <DataTableWeeklyBody sums={data[type].weeklySums} type={type} />}
              {!isWeekly && <DataTableDailyBody data={daily} type={type} />}
            </Table>
          </div>
        ) : undefined}
      </div>
    );
  }
}

export default DataTable;
