import Big from "big.js";
import _ from "lodash";

/**
 *
 * @param dailyReadings
 * @param historicalAverages
 * @return {{percentChange: (*|number), dailyTotal: *, historicalTotal: *}}
 */
const calculateHeatAndRainfallTotals = (dailyReadings, historicalAverages) => {
  const averagesMap = _.keyBy(historicalAverages, "lookup");
  let dailyTotal = Big(0);
  let historicalTotal = Big(0);
  _.each(dailyReadings, ({ amount, lookup }) => {
    dailyTotal = dailyTotal.add(amount);
    historicalTotal = historicalTotal.add(averagesMap[lookup]?.amount ?? 0);
  });
  const percentChange = historicalTotal.gt(0) ? dailyTotal.minus(historicalTotal).div(historicalTotal).toNumber() : NaN;

  return {
    dailyTotal: dailyTotal.toNumber(),
    historicalTotal: historicalTotal.toNumber(),
    percentChange,
  };
};

export default calculateHeatAndRainfallTotals;
