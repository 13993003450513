import React from "react";

import { useFarmAdvisorConfig } from "collection/graphql/advisor";

const AdvisorDisclaimer = ({ className }) => {
  const { id, isEnabled } = useFarmAdvisorConfig().data;
  if (!isEnabled || id !== "CARGILL") {
    return null;
  }

  return (
    <p className={className}>
      This information, including any news or commentary, is provided for informational purposes, and is subject to
      change without notice. Information may be derived from sources that are believed to be reliable, but we cannot
      give any assurance or guarantee as to its accuracy or completeness. All information is provided on an “as is”
      basis. No warranty is made with regard to the information or results obtained by its use. You assume all risks of
      its use. Neither Cargill, Incorporated nor any of its affiliates shall be liable for any damages or loss in
      connection with your use of, or reliance upon, the information. Past performance is no guarantee of future
      results. This message was sent to you by Cargill, Incorporated, 15407 McGinty Road West, Wayzata, MN 55391.
      Copyright © Cargill, Incorporated 2023. All Rights Reserved.
    </p>
  );
};

export default AdvisorDisclaimer;
