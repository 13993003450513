import { ApolloProvider } from "@apollo/client";
import { ConfigProvider, theme } from "@farmlogs/fl-ui";
import { isNative } from "mobile/mobileManager";
import { MobileForcedAppUpdateContextProvider } from "mobile/useMobileForcedAppUpdate";
import PropTypes from "prop-types";
import React from "react";

import { AuthContextProvider } from "collection/graphql/auth";
import { marketingClient } from "collection/graphql/client";
import LaunchDarklyProvider from "context/LaunchDarklyProvider";

import ErrorBoundary from "components/fl-ui/Layout/ErrorBoundary";

const GlobalContextProvider = ({ children }) => {
  const uiConfig = {
    datePicker: {
      forceNative: isNative(),
      outputFormat: "yyyy-MM-dd",
    },
  };

  return (
    <ConfigProvider config={uiConfig} theme={theme}>
      <AuthContextProvider>
        <LaunchDarklyProvider>
          <ErrorBoundary>
            <MobileForcedAppUpdateContextProvider>
              <ApolloProvider client={marketingClient}>{children}</ApolloProvider>
            </MobileForcedAppUpdateContextProvider>
          </ErrorBoundary>
        </LaunchDarklyProvider>
      </AuthContextProvider>
    </ConfigProvider>
  );
};

GlobalContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default GlobalContextProvider;
