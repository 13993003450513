import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import ReactSelect from "react-select";

const ProductFilterChooser = (props) => {
  const { onChange, purchaseSummaries, value } = props;
  const products = _.map(purchaseSummaries, "product");

  return (
    <ReactSelect
      getOptionLabel={({ name }) => name}
      getOptionValue={({ id }) => id}
      onChange={(product) => onChange(product)}
      options={products}
      placeholder=""
      value={value}
    />
  );
};

ProductFilterChooser.propTypes = {
  onChange: PropTypes.func.isRequired,
  purchaseSummaries: PropTypes.arrayOf(
    PropTypes.shape({
      product: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  value: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
};

export default ProductFilterChooser;
