import { useQuery } from "@apollo/client";
import { debounce } from "lodash";
import { useCallback, useState } from "react";

import { searchProducts } from "collection/graphql/products/queries";
import useCurrentCropYear from "hooks/useCurrentCropYear";

const useProductData = ({ suggestedOptions, unitType }) => {
  const [productType, setProductType] = useState("ALL");
  const [search, setSearch] = useState(null);
  const variables = {
    popular: !search,
    search: search || null,
    sortCropYear: useCurrentCropYear()[0],
    types: productType !== "ALL" ? [productType] : [],
    unitType,
  };

  const { data } = useQuery(searchProducts, { fetchPolicy: "network-only", variables });
  const { popularProducts = [], usedProducts = [] } = data ?? {};
  const loading = !usedProducts || !popularProducts;

  const options = [
    { label: "suggested products", options: suggestedOptions ?? [] },
    { label: "products used by you", options: usedProducts },
    { label: "popular products", options: popularProducts },
  ];

  return {
    loading,
    options,
    search,
    setProductType,
    setSearch: useCallback(debounce(setSearch, 500), []),
  };
};

export default useProductData;
