import { gql } from "@apollo/client";

import { COMMODITY } from "collection/graphql/commodities/fragments";
import { BasicUnderlying } from "collection/graphql/marketing/fragments";

/**
 * The common subset of fields needed by all contract types for editing a contract.
 * @type {DocumentNode}
 */
export default gql`
  fragment EditableBaseContract on BaseContract {
    commodity {
      ...Commodity
    }
    contractDate
    contractSource
    contractStatus
    contractSubtype @client
    enabled
    id
    marketedCrop {
      allowsSpeculativeContracts @client
      id
      name
      commodity {
        ...Commodity
      }
      underlyings {
        ...BasicUnderlying
      }
    }
    notes
    quantityUnit
    underlying {
      ...BasicUnderlying
    }
  }

  ${BasicUnderlying}
  ${COMMODITY}
`;
