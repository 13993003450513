/**
 * Sums the total acreage given a list of activities and a property. Only adds
 * the acreage for a given property id once.
 *
 * If a property of "crop" is provided, each activity is expected to have a "crop"
 * property which has "id" and "acreage" properties.  Likewise when a value of "field"
 * is passed for property.
 *
 * @param {Activity[]} activities
 * @param {"crop"|"field"} property
 * @return {Number}
 */
const sumActivityAcreageBy = (activities, property) => {
  const ids = {};

  return activities.reduce((sum, activity) => {
    const { acreage, id } = activity[property];
    if (!ids[id]) {
      sum += acreage;
      ids[id] = id;
    }

    return sum;
  }, 0);
};

export default sumActivityAcreageBy;
