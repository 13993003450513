import createReactClass from "create-react-class";
import React from "react";
import _ from "underscore";

// Connect a backbone data to a component and handle any updates
// 1. Add new data
// 2. Update existing data
// 3. Delete data

export default function (Collection, Component) {
  return createReactClass({
    displayName: `ConnectModel(${Component.displayName})`,

    statics: {
      collection: Collection,
    },

    getDefaultProps() {
      return { id: null };
    },

    getInitialState() {
      const model = Collection.get(this.props.id) || new Collection.model();

      return {
        data: Object.assign({}, model.toJSON(), this.props.data), // cache attributes
        error: this.props.id ? { dataNeedsToChange: true } : {}, // validation errors
        model, // PRIVATE reference to backbone model
        status: {},
      };
    }, // update status (changed/saving)

    isValid() {
      return Object.keys(this.state.error).length === 0;
    },

    async handleSave() {
      const { model, data } = this.state;

      model.set(data);
      const error = model.validate() || {};
      if (Object.keys(error).length) {
        return this.setState({ error });
      }

      this.setState({ status: { saving: true } });

      await model.save().then(
        (res) => {
          Collection.add(model, { merge: true });
          this.setState({ data: { ...data, ...res } });
        },
        () => {
          if (!model.id) {
            return Collection.remove(model);
          }
        }
      );

      return model;
    },

    handleChange(newData) {
      if (newData == null) {
        newData = {};
      }
      const error = Object.assign({}, this.state.error);

      // Anytime the data changes, we can safely remove this.
      delete error["dataNeedsToChange"];

      Object.keys(error).forEach(function (prop) {
        if (newData[prop]) {
          return delete error[prop];
        }
      });

      Object.assign(error, this.state.model.preValidate(newData));

      const data = Object.assign({}, this.state.data, newData);
      return this.setState({ data, error, status: { changed: true } });
    },

    handleReset() {
      this.state.model.clear();
      return this.setState({
        data: {},
        error: {},
        status: {},
      });
    },

    async handleDelete() {
      await this.state.model.destroy();
      this.setState({ status: { saving: true } });
    },

    render() {
      return (
        <Component
          {...this.props}
          {...this.state}
          model={this.state.model}
          data={this.state.data}
          error={this.state.error}
          status={this.state.status}
          onReset={this.handleReset}
          onChange={this.handleChange}
          onSave={this.handleSave}
          onDelete={this.handleDelete}
        />
      );
    },
  });
}
