import { Section } from "demo/components";
import SessionInfo from "demo/components/SessionInfo";
import SentryErrorTest from "demo/demos/SentryErrorTest";
import React from "react";

const DebugInfo = () => {
  return (
    <Section title="Debug Info">
      <SessionInfo />

      <SentryErrorTest />
    </Section>
  );
};

export default DebugInfo;
