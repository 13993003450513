import React, { useState } from "react";
import ProductMatchBlankSlate from "settings/pages/ProductAndMachineMatching/components/ProductMatchBlankSlate";
import ProductMatchCard from "settings/pages/ProductAndMachineMatching/components/ProductMatchCard";
import ProductMatchModal from "settings/pages/ProductAndMachineMatching/components/ProductMatchModal";
import useSortedProductMatches from "settings/pages/ProductAndMachineMatching/hooks/useSortedProductMatches";
import { MatchGrid, ResponsiveHeading, StyledText } from "settings/styledComponents";

import { Stack } from "components/fl-ui/Layout";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";

const ProductAndMachineMatching = () => {
  const { loading, matches } = useSortedProductMatches();
  const [matchToEdit, setMatchToEdit] = useState(null);
  const showBlankSlate = !loading && matches.length === 0;
  const close = () => setMatchToEdit(null);

  return (
    <Stack>
      <ResponsiveHeading>Input Matching</ResponsiveHeading>
      <StyledText>To finalize activities, please find a match for unidentified inputs.</StyledText>

      <LoadingWrapper isLoading={loading}>
        {showBlankSlate && <ProductMatchBlankSlate />}

        {!showBlankSlate && (
          <MatchGrid>
            {matches.map((match) => (
              <ProductMatchCard key={match.id} onEdit={() => setMatchToEdit(match)} match={match} />
            ))}
          </MatchGrid>
        )}

        {matchToEdit && <ProductMatchModal onClose={close} onSave={close} productMatch={matchToEdit} />}
      </LoadingWrapper>
    </Stack>
  );
};

export default ProductAndMachineMatching;
