import Backbone from "backbone";

export default Backbone.Model.extend({
  urlRoot: "/api/bins",

  validation: {
    capacity: {
      required: false,
      range: [0, Math.pow(10, 11)],
      pattern: "digits",
    },
    name: { required: true, maxLength: 45 },
  },

  filter: {
    capacity: "integer",
  },

  getIconPath() {
    return "/images/inventory/bin.png";
  },

  // returns the % as a number for use in CSS
  getUIPercentage(balance) {
    let percent = (balance / this.get("capacity")) * 100;
    if (percent > 100) {
      percent = 100;
    }
    if (percent < 0 || isNaN(percent)) {
      percent = 0;
    }

    return percent * 0.76;
  },
});
