import { gql } from "@apollo/client";

import { MinimalListedActivity } from "collection/graphql/activities/fragments";

const getMinimalActivityList = gql`
  query getMinimalActivityList($cropYear: Int!) {
    activities: getActivities(cropYear: $cropYear) @requiresPermission(feature: "activities", actions: ["read"]) {
      ...MinimalListedActivity
    }
  }

  ${MinimalListedActivity}
`;

export default getMinimalActivityList;
