import { DatePicker } from "@farmlogs/fl-ui";
import chooserStyles from "activity/components/choosers/FieldCropChooser/chooserStyles";
import { debounce, upperFirst } from "lodash";
import { Copy, Link, StyledFormGroup, StyledLastFormGroup, StyledRadioGroup } from "profit_and_loss/styles";
import PropTypes from "prop-types";
import React from "react";
import ReactSelect from "react-select";
import { Col, Row } from "react-styled-flexboxgrid";

import { Input, Radio, TextArea } from "components/fl-ui/Form";
import CloseX from "components/fl-ui/Icons/CloseX";
import { Cluster } from "components/fl-ui/Layout";
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "components/fl-ui/Modal/Modal";
import ModalActionButtonGroup from "components/fl-ui/Modal/ModalActionButtonGroup";

const copyMap = {
  EXPENSE: (
    <span>
      {"Use other expenses to track costs not captured by "}
      <Link href="/#activity">activities</Link>, <Link href="/#land_costs">land costs</Link>,{" or "}
      <Link href="/#products">input purchases</Link>
    </span>
  ),
  REVENUE: "Use revenue accounts to track revenue not associated with your crops",
};

const LogExpenseRevenueModal = (props) => {
  const {
    accounts,
    accountType,
    cropYear,
    data,
    fieldCrops,
    isSaving,
    loading,
    marketedCrops,
    onClose,
    onDelete,
    onSave,
    type,
    updateData,
  } = props;
  const { account, allocateType, amount, crops, date, disabled, id, notes } = data;
  const action = id ? "Edit" : "Log";

  return (
    <Modal>
      <ModalHeader>
        <ModalTitle>{`${action} ${cropYear} ${accountType}`}</ModalTitle>
        <CloseX onClick={onClose} />
      </ModalHeader>

      <ModalBody>
        <Row>
          <Col xs={12}>
            <Copy>{copyMap[type]}</Copy>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <StyledFormGroup
              label={
                <Cluster between>
                  <span>Account</span>
                  <Link href="/#settings/expense_and_revenue">Manage accounts</Link>
                </Cluster>
              }
            >
              <ReactSelect
                getOptionLabel={({ name }) => name}
                getOptionValue={({ id }) => id}
                isLoading={loading}
                onChange={(account) => updateData({ account })}
                options={accounts}
                placeholder="Type to search..."
                value={account}
              />
            </StyledFormGroup>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={6}>
            <StyledFormGroup label="Date">
              <DatePicker onChange={(date) => updateData({ date })} value={date} />
            </StyledFormGroup>
          </Col>

          <Col xs={12} md={6}>
            <StyledFormGroup label="Amount">
              <Input
                defaultValue={amount}
                name="amount"
                onChange={debounce((e, amount) => updateData(amount), 500)}
                prefix="$"
                type="number"
              />
            </StyledFormGroup>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <StyledRadioGroup
              label="Allocate to"
              name="allocateType"
              onChange={({ name, value }) => updateData({ [name]: value, crops: null })}
            >
              <Radio checked={allocateType === "UNALLOCATED"} label="Entire business (overhead)" value="UNALLOCATED" />

              <Radio checked={allocateType === "COMMODITY_CROP"} label="Marketing crops" value="COMMODITY_CROP" />
              {allocateType === "COMMODITY_CROP" && (
                <Col xs>
                  <StyledFormGroup>
                    <ReactSelect
                      getOptionLabel={({ name }) => upperFirst(name)}
                      getOptionValue={({ id }) => id}
                      isMulti
                      onChange={(crops) => updateData({ crops })}
                      options={marketedCrops}
                      placeholder="Type to search..."
                      styles={chooserStyles}
                      value={crops}
                    />
                  </StyledFormGroup>
                </Col>
              )}

              <Radio checked={allocateType === "FIELD_CROP"} label="Field crops" value="FIELD_CROP" />
              {allocateType === "FIELD_CROP" && (
                <Col xs>
                  <StyledFormGroup>
                    <ReactSelect
                      getOptionLabel={({ commodity, field }) =>
                        `${upperFirst(field.name)} · ${commodity.name} ${cropYear}`
                      }
                      getOptionValue={({ id }) => id}
                      isMulti
                      onChange={(crops) => updateData({ crops })}
                      options={fieldCrops}
                      placeholder="Type to search..."
                      styles={chooserStyles}
                      value={crops}
                    />
                  </StyledFormGroup>
                </Col>
              )}
            </StyledRadioGroup>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <StyledLastFormGroup label="Notes (optional)">
              <TextArea
                defaultValue={notes || ""}
                name="notes"
                onChange={debounce((e, notes) => updateData(notes), 500)}
              />
            </StyledLastFormGroup>
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter>
        <ModalActionButtonGroup
          apply={{ action: onSave, buttonText: `${action} ${accountType}`, disabled: disabled || isSaving }}
          cancel={{ action: onClose, buttonText: "Cancel" }}
          danger={id && { action: onDelete, buttonText: "Delete", disabled: isSaving }}
        />
      </ModalFooter>
    </Modal>
  );
};

LogExpenseRevenueModal.propTypes = {
  accounts: PropTypes.array,
  accountType: PropTypes.string.isRequired,
  cropYear: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  data: PropTypes.object.isRequired,
  fieldCrops: PropTypes.arrayOf(PropTypes.object),
  marketedCrops: PropTypes.arrayOf(PropTypes.object),
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  updateData: PropTypes.func.isRequired,
};

export default LogExpenseRevenueModal;
