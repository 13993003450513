import { GET_CURRENT_USER } from "collection/graphql/auth/queries";
import { restClient } from "collection/graphql/client";

/**
 * Queries for the current user authentication
 * @param {Object} [requestOptions={}]
 */
export default function authentication(requestOptions = {}) {
  return restClient.query({ query: GET_CURRENT_USER, ...requestOptions });
}
