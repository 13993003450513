import { gql } from "@apollo/client";

export default gql`
  query getAllEquipment {
    allImplements: getImplements
      @requiresFeature(feature: "equipment")
      @requiresPermission(feature: "equipment", actions: ["read"]) {
      ...ImplementDetail
    }

    allTractors: getTractors
      @requiresFeature(feature: "equipment")
      @requiresPermission(feature: "equipment", actions: ["read"]) {
      ...TractorDetail
    }
  }
`;
