import TableCell from "contracts/components/TableCell";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { Icon, IconContainer } from "components/fl-ui/Icons";
import { Spacing } from "components/fl-ui/constants";

const ExpansionCell = styled(({ className, toggleRow, expanded }) => (
  <TableCell columnClass={`${className} expand`}>
    <IconContainer size="xlarge" onClick={toggleRow}>
      <Icon className={expanded ? "up" : "down"} icon="chevronDown" />
    </IconContainer>
  </TableCell>
))`
  margin-left: ${Spacing.minor};
  cursor: pointer;

  .down {
    transition: 200ms ease-in;
    transform: rotate(0deg);
  }

  .up {
    transition: 200ms ease-in;
    transform: rotate(-180deg);
  }
`;

export default ExpansionCell;

ExpansionCell.propTypes = {
  expanded: PropTypes.bool,
  toggleRow: PropTypes.func,
};
