import { gql } from "@apollo/client";

/**
 * The `description` and `ingredients` fields on a {@link ProductRecipe} require a separate network call to
 * the product_recipe_components REST endpoint. Since the product_recipe_components endpoint does not accept
 * a recipe id param it returns all {@link ProductRecipeIngredient}s for all recipes saved on the current enterprise.
 * In order to perform this filtering we are fetching all ingredients along all recipes in this call, and then
 * merging them together in the {@link useProductRecipes} hook.
 */
const getProductRecipes = gql`
  query getProductRecipes {
    allIngredients: getProductRecipeIngredients
      @rest(path: "/api/product_recipe_components", type: "ProductRecipeIngredient") {
      amount
      amountUnit
      created
      enterpriseId
      id
      productId @export(as: "productId")
      productRecipeId
      rateUnit
      updated
    }

    recipes: getProductRecipes @rest(path: "/api/product_recipes", type: "ProductRecipe") {
      created
      enterpriseId
      id
      name
      updated

      #description
      #ingredients
    }
  }
`;

export default getProductRecipes;
