import { gql } from "@apollo/client";

export default gql`
  mutation createField($field: CreateFieldInput!) {
    createField(field: $field) {
      ok
      field {
        ...BasicField
      }
    }
  }
`;
