import { css } from "aphrodite";
import styles from "profit_and_loss/styles";
import { getFinanceNotation, getProfitLossLabel } from "profit_and_loss/utils";
import PropTypes from "prop-types";
import React from "react";

import { Cluster } from "components/fl-ui/Layout";
import CommodityYieldUnit from "components/units/CommodityYieldUnit";

const CropSummaryMetricsDesktop = (props) => {
  const { commodity, costPerAcre, growerYieldPerAcre, profitPerAcre, revenuePerAcre, yieldPerAcre } = props;
  const metrics = [
    {
      label: "total yield",
      unit: <CommodityYieldUnit commodity={commodity} per="acre" ml={4} />,
      value: yieldPerAcre && yieldPerAcre.toFixed(2),
    },
    {
      label: "grower yield",
      unit: <CommodityYieldUnit commodity={commodity} per="acre" ml={4} />,
      value: growerYieldPerAcre && growerYieldPerAcre.toFixed(2),
    },
    { label: "cost", unit: " / ac", value: getFinanceNotation(costPerAcre) },
    { label: "revenue", unit: " / ac", value: getFinanceNotation(revenuePerAcre) },
    { label: getProfitLossLabel(profitPerAcre), unit: " / ac", value: getFinanceNotation(profitPerAcre) },
  ];

  return (
    <Cluster className={css(styles.metricContainer)} between>
      {metrics.map(({ label, unit, value }) => (
        <div className={css(styles.boldVerticalStack, styles.rightAlignedContainer)} key={label}>
          <span className={css(styles.cropSummaryHeaderNumber)}>
            {value || "--"}
            <span className={css(styles.lightText)}>{value ? unit : ""}</span>
          </span>
          <span className={css(styles.cropSummaryHeaderSubtext)}>{label}</span>
        </div>
      ))}
    </Cluster>
  );
};

CropSummaryMetricsDesktop.propTypes = {
  commodity: PropTypes.object.isRequired,
  costPerAcre: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  growerYieldPerAcre: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  profitPerAcre: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  revenuePerAcre: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  yieldPerAcre: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default CropSummaryMetricsDesktop;
