import { logoutUser } from "mobile/mobileManager";

/**
 * Attempts to log out the mobile user if on a mobile platform. No-op if on web.
 * @return {Promise}
 */
const logoutMobileUser = () => {
  return new Promise(async (resolve) => {
    try {
      await logoutUser();
    } catch (error) {
      // expected if on web platform
    } finally {
      resolve();
    }
  });
};

export default logoutMobileUser;
