import { css } from "aphrodite/no-important";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { ColorSpinner, Icon } from "components/fl-ui/Icons";
import styles, { getBtnClasses, getSpinnerColor, iconSizes, SIZE } from "components/fl-ui/buttonStyles";
import useLinkClick from "components/fl-ui/hooks/useLinkClick";

const StyledButton = styled.button``;

const Button = (props) => {
  const buttonProps = {
    as: props.href ? "a" : "button",
    ..._.omit(props, [
      "children",
      "color",
      "display",
      "hollow",
      "icon",
      "iconClassName",
      "iconRight",
      "lightWeight",
      "link",
      "loading",
      "replace",
      "route",
      "size",
      "trigger",
    ]),
    className: getBtnClasses(props),
    type: !props.href ? props.type : undefined,
  };

  const {
    children,
    color,
    hollow,
    href,
    icon,
    iconClassName,
    iconRight,
    link,
    loading,
    onClick,
    replace,
    route,
    target,
    trigger,
    size,
  } = props;
  const handleClick = useLinkClick({ href, onClick, replace, route, target, trigger }).onClick;
  if (onClick) {
    buttonProps.onClick = handleClick;
  }

  return (
    <StyledButton {...buttonProps}>
      {loading && (
        <ColorSpinner
          className={css(styles.spinner)}
          color={getSpinnerColor(color, link, hollow)}
          size={SIZE[size].fontSize}
        />
      )}
      {icon && (
        <div
          className={css(
            styles.buttonIcon,
            size && iconSizes[size],
            iconRight && styles.buttonIcon_right,
            !children && styles.buttonIcon_noChildren
          )}
        >
          <Icon
            className={`${css(["xs", "sm", "mn"].includes(size) && styles.buttonIcon_bold)} ${iconClassName}`}
            icon={icon}
          />
        </div>
      )}
      {children}
    </StyledButton>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(["default", "primary", "secondary", "danger", "white"]),
  children: PropTypes.any,
  disabled: PropTypes.bool,
  display: PropTypes.string,
  form: PropTypes.string,
  hollow: PropTypes.bool,
  href: PropTypes.string,
  lightWeight: PropTypes.bool,
  link: PropTypes.bool,
  icon: PropTypes.string,
  iconRight: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  raised: PropTypes.bool,
  replace: PropTypes.bool,
  route: PropTypes.string,
  size: PropTypes.oneOf(["xl", "lg", "md", "rg", "mn", "sm", "xs"]),
  trigger: PropTypes.bool,
  type: PropTypes.oneOf(["button", "reset", "submit"]),
};

Button.defaultProps = {
  color: "default",
  replace: false,
  size: "rg",
  trigger: false,
  type: "button",
};

export default Button;
