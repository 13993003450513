import { BASE_RENT, CROP_SHARE, EXPENSE_SHARE, YIELD_ADJUSTMENT } from "agreements/constants";
import { Label } from "agreements/forms/components";
import LeaseTerm from "agreements/forms/fieldsets/LeaseTermsFieldset/LeaseTerm";
import _ from "lodash";
import React, { useEffect, useState } from "react";

import { Button } from "components/fl-ui";
import { Stack } from "components/fl-ui/Layout";

const createLeaseTerm = (existingTerms) => {
  existingTerms = existingTerms || new Set();
  const types = [BASE_RENT, CROP_SHARE, EXPENSE_SHARE, YIELD_ADJUSTMENT];
  return types.reduce((nextType, type) => {
    return !nextType && !existingTerms.has(type) ? getDefaultsForType(type) : nextType;
  }, null);
};
export { createLeaseTerm };

const getDefaultsForType = (termType) => {
  const defaults = {
    BASE_RENT: {
      termType: BASE_RENT,
      amount: null,
      baseRentRate: "PER_ACRE",
    },
    CROP_SHARE: {
      termType: CROP_SHARE,
      tenantRevenueAllocation: null,
    },
    YIELD_ADJUSTMENT: {
      termType: YIELD_ADJUSTMENT,
      amount: null,
      yieldTrigger: null,
    },
    EXPENSE_SHARE: {
      amount: null,
      expenseCategory: "ALL",
      termType: EXPENSE_SHARE,
      tenantCostAllocation: null,
    },
  };

  return defaults[termType];
};

const LeaseTermsFieldset = ({ initialTerms, onChange }) => {
  const [leaseTerms, setLeaseTerms] = useState(new Map());

  useEffect(() => {
    const leaseTerms = new Map();
    _.each(initialTerms, (term) => leaseTerms.set(_.uniqueId("term"), term));
    setLeaseTerms(leaseTerms);
  }, []);

  const terms = Array.from(leaseTerms, (term) => term);
  const termTypeBlacklist = terms.reduce((resultSet, [key, { termType }]) => {
    if (termType !== "YIELD_ADJUSTMENT") {
      resultSet.add(termType);
    }

    return resultSet;
  }, new Set());

  const addListItem = () => {
    const newLeaseTerms = new Map(leaseTerms);
    newLeaseTerms.set(_.uniqueId("term"), createLeaseTerm(termTypeBlacklist));
    updateLeaseTerms(newLeaseTerms);
  };

  const removeListItem = (key) => {
    const newLeaseTerms = new Map(leaseTerms);
    newLeaseTerms.delete(key);
    updateLeaseTerms(newLeaseTerms);
  };

  const updateListItem = (key, term) => {
    const newLeaseTerms = new Map(leaseTerms);
    newLeaseTerms.set(key, term);
    updateLeaseTerms(newLeaseTerms);
  };

  const updateLeaseTerms = (newLeaseTerms) => {
    setLeaseTerms(newLeaseTerms);
    onChange({ terms: Array.from(newLeaseTerms, ([key, term]) => term) });
  };

  return (
    <Stack>
      <Label>Lease terms</Label>

      {terms.map(([key, term], index) => {
        return (
          <LeaseTerm
            getDefaultsForType={getDefaultsForType}
            key={key}
            onChange={(updatedTerm) => updateListItem(key, updatedTerm)}
            onRemove={() => (terms.length > 1 ? removeListItem(key) : null)}
            ordinal={index + 1}
            term={term}
            terms={terms}
            total={terms.length}
            typeBlacklist={termTypeBlacklist}
          />
        );
      })}

      <Button onClick={addListItem}>Add lease term</Button>
    </Stack>
  );
};

export default LeaseTermsFieldset;
