import { StyleSheet } from "aphrodite";

import { UIColors } from "components/fl-ui/colors";
import { Borders, BorderRadius, Spacing, Typography } from "components/fl-ui/constants";

export default StyleSheet.create({
  blueText: {
    color: UIColors.primary,
    fontWeight: Typography.weights.medium,
  },
  booleanSelectContainer: {
    border: Borders.regular,
    borderTop: "none",
    marginBottom: 0,
    padding: `${Spacing.large}`,
    ":first-of-type": {
      borderTop: Borders.regular,
      borderTopLeftRadius: BorderRadius.medium,
      borderTopRightRadius: BorderRadius.medium,
    },
    ":last-of-type": {
      borderBottomLeftRadius: BorderRadius.medium,
      borderBottomRightRadius: BorderRadius.medium,
    },
  },
  button: {
    margin: `0 auto ${Spacing.minor} `,
    padding: `${Spacing.small} 10rem`,
  },
  buttonGroup: {
    borderTop: Borders.regular,
    display: "flex",
    flexFlow: "column",
    paddingTop: Spacing.xxlarge,
  },
  centerText: {
    textAlign: "center",
  },
  checkboxRow: {
    borderTop: Borders.regular,
    paddingTop: Spacing.xxlarge,
  },
  checkboxRowMinimal: {
    marginBottom: Spacing.xxlarge,
  },
  copy: {
    fontSize: Typography.sizes.lg,
    lineHeight: Spacing.large,
    marginBottom: 0,
  },
  fieldContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  form: {
    padding: Spacing.large,
  },
  heading: {
    fontSize: Typography.sizes.xl,
    fontWeight: Typography.weights.medium,
    marginTop: Spacing.regular,
  },
  helpIcon: {
    display: "inline-block",
    height: Spacing.regular,
    marginLeft: Spacing.xxsmall,
    marginRight: Spacing.xxsmall,
    verticalAlign: "middle",
    width: Spacing.regular,
  },
  ineligibleButton: {
    marginTop: Spacing.large,
    width: "11rem",
  },
  ineligibleContainer: {
    border: Borders.regular,
    borderRadius: BorderRadius.medium,
    marginTop: Spacing.xxlarge,
    padding: "2.5rem 3.5rem",
  },
  list: {
    padding: `${Spacing.xsmall} ${Spacing.regular} ${Spacing.regular}`,
  },
  mainColumn: {
    paddingBottom: Spacing.regular,
    paddingTop: Spacing.regular,
    "@media only screen and (min-width: 768px)": {
      ":first-child": {
        paddingRight: Spacing.xlarge,
      },
      ":last-child": {
        paddingLeft: Spacing.xlarge,
      },
    },
  },
  mainContainer: {
    border: Borders.regular,
    borderRadius: BorderRadius.medium,
    marginTop: Spacing.large,
    maxHeight: "38.5rem",
    minHeight: "38.5rem",
  },
  mapContainer: {
    border: Borders.regular,
    borderRadius: BorderRadius.small,
    marginRight: Spacing.regular,
  },
  mapImage: {
    background: "white",
    height: Spacing.xxxlarge,
    width: Spacing.xxxlarge,
  },
  optionHeader: {
    fontSize: Typography.sizes.rg,
    fontWeight: Typography.weights.medium,
    marginBottom: Spacing.xxsmall,
  },
  optionSubText: {
    fontSize: Typography.sizes.mn,
    marginBottom: Spacing.xxsmall,
  },
  orderedList: {
    listStyle: "decimal",
    margin: Spacing.large,
  },
  secondaryInput: {
    marginTop: Spacing.xxsmall,
  },
  section: {
    borderBottom: Borders.regular,
    marginTop: Spacing.regular,
    marginBottom: Spacing.xxlarge,
    paddingBottom: Spacing.xxlarge,
  },
  selected: {
    background: "rgb(249, 251, 254)",
    color: UIColors.darkBlue,
    fontWeight: Typography.weights.medium,
  },
  selectedBorder: {
    border: `1px solid ${UIColors.darkBlue}`,
    color: UIColors.darkBlue,
    fontWeight: Typography.weights.medium,
  },
  steps: {
    fontSize: Typography.sizes.mn,
    fontWeight: Typography.weights.medium,
    textTransform: "uppercase",
  },
  subContainer: {
    alignItems: "center",
    borderBottom: Borders.regular,
    display: "flex",
    justifyContent: "space-between",
    padding: Spacing.regular,
    ":last-child": {
      borderBottom: "none",
    },
  },
  upperCaseText: {
    fontSize: Typography.sizes.sm,
    fontWeight: Typography.weights.medium,
    margin: 0,
    textTransform: "uppercase",
  },
  verticalScroll: {
    overflowY: "scroll",
  },
  warningIcon: {
    color: UIColors.red,
    height: Spacing.xxxlarge,
    margin: "auto",
    marginBottom: Spacing.xxlarge,
    width: Spacing.xxxlarge,
  },
});
