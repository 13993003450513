import { StyleSheet } from "aphrodite/no-important";

import { UIColors } from "components/fl-ui/colors";
import { Borders, Mixins, Spacing, Typography } from "components/fl-ui/constants";

const formStyles = StyleSheet.create({
  width100: {
    width: "100%",
  },
  flexAlignCenter: {
    display: "flex",
    alignItems: "center",
  },
  marginRight5: {
    marginRight: Mixins.toRem(5),
  },
  infoText: {
    ...Typography.infoText,
    fontSize: Typography.sizes.rg,
  },
  breadCrumbs: {
    marginBottom: `${Mixins.toRem(56)} !important`,
  },
  fieldsSummary: {
    color: UIColors.medium,
    fontSize: Typography.sizes.rg,
    letterSpacing: "-0.22px",
    lineHeight: 1.5,
    marginBottom: 0,
    marginTop: Spacing.xsmall,
    minHeight: Mixins.toRem(21),
  },
  formButtonContainer: {
    borderTop: Borders.regular,
    display: "flex",
    justifyContent: "flex-end",
    marginTop: Spacing.large,
    paddingTop: Spacing.large,
  },
  helpIcon: {
    display: "inline-block",
    height: Spacing.regular,
    marginLeft: Spacing.xsmall,
    verticalAlign: "middle",
    width: Spacing.regular,
  },
  secondaryButton: {
    marginRight: Spacing.regular,
  },
  regularMarginTop: {
    marginTop: Spacing.regular,
  },
});

export const optionStyles = {
  option: (base, { data, isSelected }) => {
    const secondaryStyles =
      !data.isUsed && !isSelected
        ? {
            background: "#f0f0f0",
            borderTop: "2px solid #ddd",
            color: "inherit",
            "&:hover": {
              background: "#ddd",
            },
          }
        : {};

    return {
      ...base,
      borderTop: "2px solid #eef0f2",
      ...secondaryStyles,
    };
  },
};

export default formStyles;
