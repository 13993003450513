import { dataIdFromObject, trackingCallback } from "./common";
import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from "@apollo/client";

import { authLink } from "collection/graphql/client/links/authLink";

const cache = new InMemoryCache({
  dataIdFromObject,
});

const headers = {};
headers["X-Client-Version"] = process.env.CLIENT_VERSION || "dev";

export default new ApolloClient({
  assumeImmutableResults: true,
  cache,
  link: ApolloLink.from([
    trackingCallback,
    authLink,
    createHttpLink({
      credentials: "same-origin", // allow auth cookies to pass through
      headers,
      uri: "/v2.0/forecast/graphql",
    }),
  ]),
});
