import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import useViewType from "hooks/useViewType";
import Map from "lib/map/map";
import MapUtils from "lib/map/utils";

import { getCropGeoJSON, getLayerCollection, getSnappedLayerData } from "components/field/utils";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import withMapDrawing from "fields/add/withMapDrawing";
import useBasicField from "fields/hooks/useBasicField";

const FeatureWatcher = ({ feature, onClear }) => {
  useEffect(() => {
    if (feature.geometry === null) {
      onClear();
    }
  }, [feature?.geometry]);

  return null;
};

const DrawHandler = withMapDrawing(FeatureWatcher);

const CropDrawHandler = ({ cropId, feature, fieldCrops, fieldId, onChange }) => {
  const [map, setMap] = useState(null);
  const [cropLayerCollection, setCropLayerCollection] = useState(null);
  const [fieldLayerCollection, setFieldLayerCollection] = useState(null);
  const mapRef = useRef();
  const { loading, ...field } = useBasicField({ fieldId });
  const fieldGeometry = field?.geometry;
  const noAttribution = useViewType() === "mobile";

  const addFieldBoundary = (map) => {
    const fieldFeature = [getCropGeoJSON({ color: "white", field })];
    const layerCollection = getLayerCollection(fieldLayerCollection, fieldFeature, map, { isOutline: true });
    setFieldLayerCollection(layerCollection);
  };

  const sanitizeLayer = (layer) => {
    const id = cropId || fieldId;
    const message = `Crop area is restricted to the crop's field`;
    return getSnappedLayerData(id, layer, message, fieldGeometry);
  };

  const setUpLayers = (map) => {
    if (fieldCrops) {
      const otherCrops = fieldCrops.filter(({ id }) => id !== cropId);
      const nonEditableFeatures = otherCrops.map((crop) => getCropGeoJSON({ crop }));
      const layerCollection = getLayerCollection(cropLayerCollection, nonEditableFeatures, map);
      setCropLayerCollection(layerCollection);
    }
  };

  const applyMapConfig = (map) => {
    addFieldBoundary(map);
    setUpLayers(map);
  };

  const setUpMap = () => {
    const maxBounds = MapUtils.getMaxBounds(fieldGeometry).pad(0.2);

    if (map) {
      map.setMaxBounds(maxBounds);
      applyMapConfig(map);
    } else {
      const newMap = new Map(mapRef.current, { ignoreLocation: true, maxBounds, noAttribution });
      setMap(newMap);
      applyMapConfig(newMap);
    }
  };

  useEffect(() => {
    if (!loading && fieldGeometry) {
      setUpMap();
    }
  }, [fieldId, loading, fieldCrops]);

  useEffect(() => {
    return () => {
      map?.remove();
    };
  }, []);

  return (
    <>
      {!map && <LoadingWrapper loadingText="Loading map..." />}
      <div ref={mapRef} style={{ minHeight: "50vh", width: "100%", zIndex: 0 }} />

      {!!map && (!cropId || feature) && (
        <DrawHandler feature={feature} map={map} onChange={onChange} sanitizeLayer={sanitizeLayer} />
      )}
    </>
  );
};

CropDrawHandler.propTypes = {
  cropId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  feature: PropTypes.shape({
    geometry: PropTypes.object,
    properties: PropTypes.object,
    type: PropTypes.string,
  }),
  fieldCrops: PropTypes.arrayOf(PropTypes.object).isRequired,
  fieldId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CropDrawHandler;
