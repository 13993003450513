import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import Fullscreen from "components/fl-ui/util/Fullscreen";

const ContentContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 10px;
  justify-content: center;
  text-align: center;
`;

const AccountDeactivationConfirmation = ({ platform, role }) => {
  const isAdmin = role === "admin";
  const showMobileStoreLink = isAdmin && platform?.isMobilePaymentProvider;

  return (
    <Fullscreen>
      <ContentContainer>
        <img height="200px" src="/images/app_icons/bushel-farm-icon-alt.svg" />

        <h2>
          {isAdmin &&
            platform?.isWebPaymentProvider &&
            `
            Your subscription has been canceled and all accounts have been deleted.
          `}

          {isAdmin &&
            (!platform || platform.isMobilePaymentProvider) &&
            `
            All accounts have been deleted.
          `}

          {!isAdmin &&
            `
            Your account has been deleted.
          `}
        </h2>

        {showMobileStoreLink && (
          <div>
            <p>
              In order to complete this process,{" "}
              <a href={platform.storeUrl} target="_blank" rel="noopener noreferrer">
                click here
              </a>{" "}
              to go through {platform.storeName} and cancel your subscription.
            </p>
          </div>
        )}
      </ContentContainer>
    </Fullscreen>
  );
};

AccountDeactivationConfirmation.propTypes = {
  platform: PropTypes.shape({
    isMobilePaymentProvider: PropTypes.bool.isRequired,
    isWebPaymentProvider: PropTypes.bool.isRequired,
    storeName: PropTypes.string.isRequired,
    storeUrl: PropTypes.string,
  }),
  role: PropTypes.oneOf(["admin", "manager", "contributor"]).isRequired,
};

AccountDeactivationConfirmation.defaultProps = {};

export default AccountDeactivationConfirmation;
