import { css, StyleSheet } from "aphrodite/no-important";
import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";

import useReleaseFeature from "hooks/useReleaseFeature";

import { DarkSkyLogo, WeatherKitLogo } from "components/fl-ui/Icons";
import { Spacing } from "components/fl-ui/constants";

const styles = StyleSheet.create({
  container: {
    marginTop: Spacing.regular,
  },
  p: {
    display: "inline-block",
    verticalAlign: "middle",
    marginLeft: Spacing.small,
  },
});

const WeatherAttributionFooter = () => {
  const weatherKitEnabled = useReleaseFeature("weather-kit-attribution");
  return (
    <Row>
      <Col xs className={css(styles.container)}>
        <hr />
        {weatherKitEnabled ? <WeatherKitLogo /> : <DarkSkyLogo />}
        <p className={css(styles.p)}>Forecasts provided by {weatherKitEnabled ? "WeatherKit" : "DarkSky"}</p>
      </Col>
    </Row>
  );
};

export default WeatherAttributionFooter;
