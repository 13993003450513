import AddonPrompt from "billing/components/AddonPrompt";
import CustomPlanPrompt from "billing/components/CustomPlanPrompt";
import PrimaryPlanList from "billing/components/PlanLists/PrimaryPlanList";
import SecondaryPlanList from "billing/components/PlanLists/SecondaryPlanList";
import React from "react";
import styled from "styled-components";

import { Stack } from "components/fl-ui/Layout";

const ProductSelectionList = styled(({ className }) => (
  <div id="productSelectionList" className={className}>
    <Stack space="32px">
      <PrimaryPlanList />
      <SecondaryPlanList />
      <CustomPlanPrompt />
      <AddonPrompt />
    </Stack>
  </div>
))`
  margin-bottom: 32px;
`;

export default ProductSelectionList;
