import _ from "lodash";

import ActivityInputInputSchema from "collection/graphql/activities/schemas/ActivityInputInputSchema";

const sanitizeBulkAttributes = ({ acreage, crop, geometry, geometricCircumference }) => ({
  acreage,
  crop: crop.id,
  geometry,
  geometricCircumference,
});

export const prepareCropData = (bulkCreateAttributes) => bulkCreateAttributes.map(sanitizeBulkAttributes);

export const prepareForUpdate = (activity, bulkAttributes) => {
  const updatedActivity = {
    ...activity,
    ...sanitizeBulkAttributes(bulkAttributes),
  };

  if (updatedActivity.irrigation) {
    delete updatedActivity.irrigation.__typename;
    delete updatedActivity.irrigation.id;
    delete updatedActivity.irrigation.product;
  }

  return updatedActivity;
};

const prepareActivityForSave = (activityFields) => {
  const activity = _.pick(activityFields, [
    "date",
    "id",
    "irrigation",
    "isWorkOrder",
    "notes",
    "people",
    "status",
    "type",
  ]);

  activity.images = _.map(activityFields.images, (image) => _.pick(image, ["filepickerId", "name", "s3Id", "url"]));
  activity.status = activity.status === "COMPLETED" ? "DONE" : activity.status;

  const equipment = _.partition(activityFields.equipment, { __typename: "Implement" });
  activity.implements = _.map(equipment[0], "id");
  activity.tractors = _.map(equipment[1], "id");

  activity.inputs = _.map(activityFields.inputs, (item) => {
    const input = ActivityInputInputSchema.cast(item, { assert: true, stripUnknown: true });
    /*
     * FARM-9229 area and areaUnit must either both be set or they should both be null.
     */
    if (_.isNil(input.area)) {
      input.areaUnit = null;
    } else if (_.isNil(input.areaUnit)) {
      input.areaUnit = "ACRE";
    }

    return input;
  });

  return activity;
};

export default prepareActivityForSave;
