import React from "react";

import RainAndHeatBlankSlateBanner from "components/advertisements/banners/RainAndHeatBlankSlateBanner";
import PayGateCommonBlankStatePage from "components/advertisements/pages/PayGateCommonBlankStatePage";

const RainAndHeatBlankStatePage = () => {
  const steps = [
    "Get daily or weekly look at how much rain and heat units are accumulating on your fields",
    "Use charts to take a deep dive into the rain or heat history over any custom time period",
    "Ditch the rain gauge and weather station",
  ];

  return (
    <div>
      <PayGateCommonBlankStatePage banner={RainAndHeatBlankSlateBanner} steps={steps} />
    </div>
  );
};

export default RainAndHeatBlankStatePage;
