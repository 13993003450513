import CashContractCard from "contracts/components/CashContractCard";
import NoContractsResults from "contracts/components/NoContractsResults";
import ContractFormModal from "contracts/modals/ContractFormModal";
import { ConnectToBushelCard } from "marketing/cards";
import React, { useState } from "react";

import LoadingSpinner from "components/ui/loading";

const CashContractsMobileView = ({ contracts, loading }) => {
  const [editingContractId, setEditingContractId] = useState(null);

  return (
    <>
      {!loading && contracts.length === 0 && (
        <>
          <ConnectToBushelCard />
          <NoContractsResults />
        </>
      )}

      {loading && <LoadingSpinner loading />}

      {!loading &&
        contracts.map((contract) => (
          <CashContractCard
            contract={contract}
            key={contract.id}
            onEdit={(contractId) => setEditingContractId(contractId)}
          />
        ))}

      {editingContractId && (
        <ContractFormModal
          contractId={editingContractId}
          onClose={() => setEditingContractId(null)}
          onSave={() => setEditingContractId(null)}
        />
      )}
    </>
  );
};

export default CashContractsMobileView;
