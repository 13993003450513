/**
 * Filters an array of activities by person id.
 *
 * @param {Activity[]} activities
 * @param {Object} filters
 * @param {Number[]} filters.people
 * @param {Function} markAsApplied
 * @return {Activity[]}
 */
const peopleFilter = (activities, { people }, markAsApplied) => {
  if (people.length > 0) {
    const personIdMap = people.reduce((map, id) => map.set(id, true), new Map());
    activities = activities.filter((activity) => activity.people.some((person) => personIdMap.has(person.id)));

    markAsApplied();
  }

  return activities;
};

export default peopleFilter;
