import { useLocalStorage, useSessionStorage } from "beautiful-react-hooks";
import { Section } from "demo/components";
import React, { useEffect, useState } from "react";

import useWindow from "hooks/useWindow";
import storage from "lib/storage";

import { Button } from "components/fl-ui";
import { Stack } from "components/fl-ui/Layout";

const SessionTestDemo = (props) => {
  const win = useWindow();
  const loadingDelay = 500;

  const key = "FARM-8493-testing";
  const [local, setLocal] = useLocalStorage(key);
  const [session, setSession] = useSessionStorage(key);

  const [idb, setIdb] = useState("loading");

  /*
   * .getItem()/.setItem() execute very quickly. the timeouts give the user a visual indication that the action is async.
   */
  useEffect(() => {
    storage.getItem(key).then((value) => {
      win.setTimeout(() => {
        setIdb(value);
      }, loadingDelay);
    });
  }, []);

  const updateIdb = (newValue) => {
    setIdb("loading");
    // this executes very quickly. adding a delay to give a visual indication that it's async.
    storage.setItem(key, newValue).then(() => {
      win.setTimeout(() => {
        setIdb(newValue);
      }, loadingDelay);
    });
  };

  return (
    <div>
      <Section title="FARM-8493 session test">
        <Stack space="1.8rem">
          <Stack space=".8rem">
            Test value in localStorage? <strong>{local ? "Yes" : "No"}</strong>
            <br />
            {local ? (
              <Button onClick={() => setLocal(null)} size="sm">
                Clear
              </Button>
            ) : (
              <Button onClick={() => setLocal("true")} size="sm">
                Add to storage
              </Button>
            )}
          </Stack>

          <Stack space=".8rem">
            Test value in sessionStorage? <strong>{session ? "Yes" : "No"}</strong>
            <br />
            {session ? (
              <Button onClick={() => setSession(null)} size="sm">
                Clear
              </Button>
            ) : (
              <Button onClick={() => setSession("true")} size="sm">
                Add to storage
              </Button>
            )}
          </Stack>

          <Stack space=".8rem">
            {idb === "loading" && (
              <>
                Test value in indexedDB? <strong>Loading...</strong>
                <br />
                <Button disabled onClick={() => {}}>
                  Loading...
                </Button>
              </>
            )}

            {idb !== "loading" && idb && (
              <>
                Test value in indexedDB? <strong>Yes</strong>
                <br />
                <Button onClick={() => updateIdb(null)} size="sm">
                  Clear
                </Button>
              </>
            )}

            {idb !== "loading" && !idb && (
              <>
                Test value in indexedDB? <strong>No</strong>
                <br />
                <Button onClick={() => updateIdb("true")} size="sm">
                  Add to storage
                </Button>
              </>
            )}
          </Stack>
        </Stack>
      </Section>
    </div>
  );
};

export default SessionTestDemo;
