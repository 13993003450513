import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

import { UPDATE_FIELD } from "collection/graphql/fields/mutations";
import { GET_FIELD } from "collection/graphql/fields/queries";

// FRAGMENTS
const ATTACHMENT_FRAGMENT = `
  fragment Attachment on FileAttachment {
    id
    filepickerId
    name
    url
  }
`;

const BASE_RENT_LEASE_TERM_FRAGMENT = `
  fragment BaseRentLeaseTerm on BaseRentLeaseTerm {
    id
    baseRentRate
    amount
    termType
    __typename
  }
`;

const CROP_SHARE_FRAGMENT = `
  fragment CropShare on YieldShareLeaseTerm {
    id
    termType
    tenantRevenueAllocation
    __typename
  }
`;

const ENUM_FRAGMENT = `
  fragment EnumFragment on EnumPair {
    data
    display
  }
`;

const EXPENSE_SHARE_FRAGMENT = `
  fragment ExpenseShareLeaseTerm on ExpenseShareLeaseTerm {
    id
    expenseCategory
    tenantCostAllocation
    termType
    __typename
  }
`;

const YIELD_ADJUSTMENT_LEASE_TERM_FRAGMENT = `
  fragment YieldAdjustmentLeaseTerm on YieldAdjustmentLeaseTerm {
    id
    amount
    commodity {
      id
      defaultYieldUnit
    }
    termType
    yieldTrigger
    __typename
  }
`;

const PAYMENT_SCHEDULE_FRAGMENT = `
  fragment PaymentSchedule on PaymentSchedule {
    id
    amount
    amountRate
    endDate
    paymentDay
    paymentInterval
    paymentMonth
    paymentPeriod
    startDate
  }
`;

const LEASE_FRAGMENT = `
  fragment Lease on LeaseAgreement {
    id
    startDate
    termUnit
    termLength
    agreementType
    notes
    endYear
    startYear
    fields {
      id
      name
    }
    landlord {
      firstName
      lastName
      addressLine1
      addressLine2
      email
      organization
      phone
      postalCode
      state
      city
    }
    terms {
      ...BaseRentLeaseTerm
      ...CropShare
      ...ExpenseShareLeaseTerm
      ...YieldAdjustmentLeaseTerm
    }
  }
  ${BASE_RENT_LEASE_TERM_FRAGMENT}
  ${CROP_SHARE_FRAGMENT}
  ${EXPENSE_SHARE_FRAGMENT}
  ${YIELD_ADJUSTMENT_LEASE_TERM_FRAGMENT}
`;

const LOAN_FRAGMENT = `
  fragment Loan on LoanAgreement {
    id
    interestRate
    lenderName
    loanType
    originalBalance
    startDate
    subType
  }
`;

const REVENUE_FRAGMENT = `
  fragment Revenue on RevenueAgreement {
    id
    revenueSource
    subType
  }
`;

const AGREEMENT_FRAGMENT = `
  fragment Agreement on AgreementFields {
    agreementSummary
    agreementType
    attachments {
      ...Attachment
    }
    creationDate
    displayId
    enterprise {
      name
    }
    fields {
      acreage
      id
      landValuePerAcre
      landValueRate
      landValueTotal
      name
    }
    id
    maturityDate
    notes
    paymentSchedules {
      ...PaymentSchedule
    }
    startDate
    termLength
    termUnit
    ...Lease
    ...Loan
    ...Revenue
  }
  ${ATTACHMENT_FRAGMENT}
  ${LEASE_FRAGMENT}
  ${LOAN_FRAGMENT}
  ${PAYMENT_SCHEDULE_FRAGMENT}
  ${REVENUE_FRAGMENT}
`;

// QUERIES
const GET_AGREEMENTS = gql([
  `
  query getAgreements($fields: [Int]) {
    agreements: getAgreements(fields: $fields) {
      ...Agreement
    }
  }
  ${AGREEMENT_FRAGMENT}
`,
]);

const GET_AGREEMENT = gql`
  query getAgreement($id: Int!) {
    agreement: getAgreement(agreementId: $id) {
      ...Agreement
    }
  }
  ${AGREEMENT_FRAGMENT}
`;

export const GET_AGREEMENT_ENUMS = gql`
  query getAgreementEnums {
    agreementTypes: getEnum(enum: "AgreementType") {
      ...EnumFragment
    }
    leaseTermTypes: getEnum(enum: "AgreementLeaseTermType") {
      ...EnumFragment
    }
    loanTypes: getEnum(enum: "AgreementLoanType") {
      ...EnumFragment
    }
    revenueSourceTypes: getEnum(enum: "AgreementRevenueSource") {
      ...EnumFragment
    }
  }
  ${ENUM_FRAGMENT}
`;

// MUTATIONS
const CREATE_LOAN = gql`
  mutation createLoanAgreement($agreement: LoanAgreementFieldInput!) {
    createLoanAgreement(agreement: $agreement) {
      ok
      loanAgreement {
        id
      }
    }
  }
`;
const withCreateLoanAgreement = graphql(CREATE_LOAN, {
  props: ({ mutate }) => ({
    createLoanAgreement: (agreement) => {
      mutate({
        variables: { agreement },
      });
    },
  }),
  options: {
    refetchQueries: [{ query: GET_AGREEMENTS }],
  },
});
export { withCreateLoanAgreement };

const UPDATE_LOAN = gql`
  mutation updateLoanAgreement($agreement: UpdateLoanAgreementFieldInput!) {
    updateLoanAgreement(agreement: $agreement) {
      ok
      loanAgreement {
        id
      }
    }
  }
`;
const withUpdateLoanAgreement = graphql(UPDATE_LOAN, {
  props: ({ mutate }) => ({
    updateLoanAgreement: (agreement) => {
      mutate({
        variables: { agreement },
      });
    },
  }),
  options: {
    refetchQueries: [{ query: GET_AGREEMENTS }],
  },
});
export { withUpdateLoanAgreement };

const CREATE_LEASE = gql`
  mutation createLeaseAgreement($agreement: LeaseAgreementFieldInput!) {
    createLeaseAgreement(agreement: $agreement) {
      ok
      leaseAgreement {
        id
      }
    }
  }
`;
const withCreateLeaseAgreement = graphql(CREATE_LEASE, {
  props: ({ mutate }) => ({
    createLeaseAgreement: (agreement) => {
      return mutate({
        variables: { agreement },
      });
    },
  }),
  options: {
    refetchQueries: [{ query: GET_AGREEMENTS }],
  },
});
export { withCreateLeaseAgreement };

const UPDATE_LEASE = gql`
  mutation updateLeaseAgreement($agreement: UpdateLeaseAgreementFieldInput!) {
    updateLeaseAgreement(agreement: $agreement) {
      ok
      leaseAgreement {
        id
      }
    }
  }
`;
const withUpdateLeaseAgreement = graphql(UPDATE_LEASE, {
  props: ({ mutate }) => ({
    updateLeaseAgreement: (agreement) => {
      return mutate({
        variables: { agreement },
      });
    },
  }),
  options: {
    refetchQueries: [{ query: GET_AGREEMENTS }],
  },
});
export { withUpdateLeaseAgreement };

const CREATE_REVENUE = gql`
  mutation createRevenueAgreement($agreement: RevenueAgreementFieldInput!) {
    createRevenueAgreement(agreement: $agreement) {
      ok
      revenueAgreement {
        id
      }
    }
  }
`;
const withCreateRevenueAgreement = graphql(CREATE_REVENUE, {
  props: ({ mutate }) => ({
    createRevenueAgreement: (agreement) => {
      mutate({
        variables: { agreement },
      });
    },
  }),
  options: {
    refetchQueries: [{ query: GET_AGREEMENTS }],
  },
});
export { withCreateRevenueAgreement };

const UPDATE_REVENUE = gql`
  mutation updateRevenueAgreement($agreement: UpdateRevenueAgreementFieldInput!) {
    updateRevenueAgreement(agreement: $agreement) {
      ok
      revenueAgreement {
        id
      }
    }
  }
`;
const withUpdateRevenueAgreement = graphql(UPDATE_REVENUE, {
  props: ({ mutate }) => ({
    updateRevenueAgreement: (agreement) => {
      mutate({
        variables: { agreement },
      });
    },
  }),
  options: {
    refetchQueries: [{ query: GET_AGREEMENTS }],
  },
});
export { withUpdateRevenueAgreement };

const DELETE_AGREEMENT = gql`
  mutation deleteAgreement($id: Int!) {
    deleteAgreement(id: $id) {
      ok
    }
  }
`;
const withDeleteAgreement = graphql(DELETE_AGREEMENT, {
  props: ({ mutate }) => ({
    deleteAgreement: (id) => {
      mutate({
        variables: { id },
      });
    },
  }),
  options: {
    refetchQueries: [{ query: GET_AGREEMENTS }],
  },
});
export { withDeleteAgreement };

const DELETE_AGREEMENTS = gql`
  mutation deleteAgreements($ids: [Int]!) {
    deleteAgreements(ids: $ids) {
      ok
    }
  }
`;
const withDeleteAgreements = graphql(DELETE_AGREEMENTS, {
  props: ({ mutate }) => ({
    deleteAgreements: (ids) => {
      mutate({
        variables: { ids },
      });
    },
  }),
  options: {
    refetchQueries: [{ query: GET_AGREEMENTS }],
  },
});
export { withDeleteAgreements };

// HOCs
export const withAgreement = graphql(GET_AGREEMENT, {
  options: (props) => ({
    errorPolicy: "all",
    fetchPolicy: "network-only",
    variables: {
      id: props.agreementId,
    },
  }),
});

export const withAgreements = graphql(GET_AGREEMENTS, {
  options: (props) => ({
    errorPolicy: "all",
    variables: {
      fields: props.fields,
    },
  }),
});

export const withField = graphql(GET_FIELD, {
  options: (props) => ({
    fetchPolicy: "cache-and-network",
    variables: {
      fieldId: props.fieldId,
    },
  }),
});

export const withUpdateField = graphql(UPDATE_FIELD, {
  props: ({ mutate }) => ({
    updateField: (field) =>
      mutate({
        variables: { field },
      }),
  }),
  options: {
    refetchQueries: ["getField"],
  },
});
