import ActivityFilterButton from "activity/containers/ActivityFilterButton";
import ActivitySortControl from "activity/containers/ActivitySortControl";
import useActivityFilters from "activity/hooks/useActivityFilters";
import _ from "lodash";
import React from "react";

import useViewType from "hooks/useViewType";

import { FilterControlsContainer, FiltersContainer, SortByLabel } from "components/fl-ui/Filters/styledComponents";
import { Cluster } from "components/fl-ui/Layout";
import Search from "components/fl-ui/Search";

const ActivityFilterBar = () => {
  const { filters, updateFilters } = useActivityFilters();
  const onSearchChange = _.debounce((search) => updateFilters({ search: search.trim() }));
  const isDesktop = useViewType() === "desktop";

  return (
    <FiltersContainer>
      <FilterControlsContainer>
        <Search onChange={onSearchChange} />
        <ActivityFilterButton />
      </FilterControlsContainer>

      {isDesktop && (
        <Cluster align="center" start>
          <SortByLabel>Sort by</SortByLabel>
          <ActivitySortControl
            variant="buttons"
            onChange={(sortCriteria) => updateFilters({ sortCriteria })}
            value={filters.sortCriteria}
          />
        </Cluster>
      )}
    </FiltersContainer>
  );
};

export default ActivityFilterBar;
