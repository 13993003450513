import { css } from "aphrodite";
import CropSummaryHeader from "profit_and_loss/components/CropSummaryHeader";
import CropSummaryMetricsMobile from "profit_and_loss/components/CropSummaryMetricsMobile";
import ExpenseSummaryTable from "profit_and_loss/components/ExpenseSummaryTable";
import FieldsSummaryTable from "profit_and_loss/components/FieldsSummaryTable";
import RevenueSummaryTable from "profit_and_loss/components/RevenueSummaryTable";
import styles from "profit_and_loss/styles";
import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";

import withViewType from "hoc/withViewType";

import { Card } from "components/fl-ui/Card";

const CropSummary = ({ data, showDetails, viewType }) => {
  const { commodity, cropYear, expenses, fieldCrops, marketedCrop, revenue } = data;
  const [expand, setExpand] = useState(showDetails);

  return (
    <div className={css(styles.cropSummaryContainer)}>
      <Card className={css(styles.cropSummaryCard)}>
        <CropSummaryHeader data={data} expand={expand} setExpand={setExpand} viewType={viewType} />
      </Card>

      {viewType === "mobile" && <CropSummaryMetricsMobile {...data} />}

      {expand && (
        <Fragment>
          <Card className={css(styles.cropSummaryCard)}>
            <ExpenseSummaryTable {...expenses} commodity={commodity} />
          </Card>

          <Card className={css(styles.cropSummaryCard)}>
            <RevenueSummaryTable {...revenue} cropYear={cropYear} commodity={commodity} marketedCrop={marketedCrop} />
          </Card>

          <Card className={css(styles.cropSummaryCard)}>
            <FieldsSummaryTable
              commodity={commodity}
              cropYear={cropYear}
              fieldCrops={fieldCrops}
              hasMarketedCrop={!!marketedCrop}
            />
          </Card>
        </Fragment>
      )}
    </div>
  );
};

CropSummary.propTypes = {
  data: PropTypes.object.isRequired,
  showDetails: PropTypes.bool,
};

CropSummary.defaultProps = {
  showDetails: true,
};

export default withViewType(CropSummary);
