import _ from "lodash";
import { CLOSED, OPEN, PENDING } from "marketing/utils/contractEnums";
import PropTypes from "prop-types";
import React from "react";
import { useFormContext } from "react-hook-form";
import ReactSelect from "react-select";

import useUnits from "hooks/useUnits";

import { chooserStyles } from "components/fl-ui/Chooser";

const ContractStatusDropdown = ({ disabled = false, id, onBlur, onChange, placeholder, value }) => {
  const enumOptions = _.reject(useUnits().getEnumAsOptions("ContractStatus"), { value: PENDING });
  const { isPartner } = useFormContext() || { isPartner: false };
  const options = isPartner
    ? enumOptions
    : [
        { label: "Open", value: OPEN },
        { label: "Closed", value: CLOSED },
      ];
  const selectedOption = options.find((option) => option.value === value);

  return (
    <ReactSelect
      inputId={id}
      isDisabled={disabled}
      onBlur={onBlur}
      onChange={({ value }) => onChange(value)}
      options={options}
      placeholder={placeholder}
      styles={chooserStyles}
      value={selectedOption}
    />
  );
};

ContractStatusDropdown.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

export default ContractStatusDropdown;
