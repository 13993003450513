import { makeVar, useReactiveVar } from "@apollo/client";
import { number } from "yup";

export const isValidYear = (value) => {
  /*
   * We need to add upper/lower bounds to the crop year to ensure validity.
   * See discussion here for the reasoning behind these particular magic numbers: https://fl.slack.com/archives/C5MGA151P/p1662068051360559
   */
  const MAX_YEAR = new Date().getFullYear() + 2;
  const MIN_YEAR = 1970;

  return number().min(MIN_YEAR).max(MAX_YEAR).isValidSync(+value);
};

const persist = (value) => sessionStorage.setItem("currentCropYear", value + "");

export const getCurrentCropYear = () => {
  let cropYear = sessionStorage.getItem("currentCropYear");
  if (!isValidYear(cropYear)) {
    cropYear = new Date().getFullYear();
    persist(cropYear);
  }

  return +cropYear;
};

const currentCropYear = makeVar();
export const readCurrentCropYear = () => currentCropYear();

const useCurrentCropYear = () => {
  useReactiveVar(currentCropYear);
  if (!currentCropYear()) {
    currentCropYear(getCurrentCropYear());
  }

  return [
    currentCropYear(),
    (newCropYear) => {
      if (isValidYear(newCropYear)) {
        persist(newCropYear);
        currentCropYear(+newCropYear);
      }
    },
  ];
};

export default useCurrentCropYear;
