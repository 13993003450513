import { useFlags } from "launchdarkly-react-client-sdk";

/**
 * A hook that returns the value of a launch darkly flag identified by featureId.
 *
 * @param {String} featureId - a launch darkly flag
 * @return {*}
 * @see https://docs.launchdarkly.com/sdk/client-side/react/react-web
 */
const useReleaseFeature = (featureId) => useFlags()[featureId];

export default useReleaseFeature;
