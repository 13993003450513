import PropTypes from "prop-types";
import React from "react";

import { css, StyleSheet } from "components/fl-ui/aphrodite";
import { Borders, Mixins, Spacing } from "components/fl-ui/constants";

const styles = StyleSheet.create({
  defaultContainer: {
    borderLeft: Borders.regular,
    marginLeft: Mixins.toRem(16),
    paddingLeft: Mixins.toRem(16),
    "@media only screen and (max-width: 30em)": {
      borderLeft: 0,
      marginLeft: 0,
      paddingLeft: 0,
    },
    "@media print": {
      borderLeft: 0,
      marginLeft: 0,
      paddingLeft: 0,
    },
  },
  unIndentedContainer: {
    borderLeft: 0,
    marginLeft: 0,
    marginTop: Spacing.regular,
    paddingLeft: 0,
  },
});

const PaneContents = (props) => {
  const { children, noIndent } = props;

  return <div className={css(styles.defaultContainer, noIndent && styles.unIndentedContainer)}>{children}</div>;
};

PaneContents.propTypes = {
  noIndent: PropTypes.bool,
};

PaneContents.defaultProps = {
  noIndent: false,
};

export { styles };
export default PaneContents;
