import React from "react";
import { components } from "react-select";
import styled from "styled-components";

import { Icon, IconContainer as IconContainerFLUI } from "components/fl-ui/Icons";
import { Cluster } from "components/fl-ui/Layout";

const IconContainer = styled(IconContainerFLUI)`
  left: 6px;
  position: relative;
`;

export const styles = (provided, state) => {
  const styles = {
    ...provided,
    borderTop: "1px solid rgb(237, 239, 244)",
  };
  if (state.isSelected) {
    styles.backgroundColor = "rgb(51, 127, 220)";
    styles.color = "#fff";
  } else if (state.isFocused) {
    styles.backgroundColor = "rgb(239, 245, 252)";
    styles.color = "rgb(18, 57, 114)";
  }

  return styles;
};

const CheckedOption = (props) => {
  return (
    <components.Option {...props}>
      <Cluster between>
        {props.children}

        {props.isSelected && (
          <IconContainer>
            <Icon icon="circleCheck" />
          </IconContainer>
        )}
      </Cluster>
    </components.Option>
  );
};

export default CheckedOption;
