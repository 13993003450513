import React from "react";

import SegmentedControl from "components/fl-ui/SegmentedControl";

const OpenClosedTab = (props) => {
  return (
    <SegmentedControl
      value={props.value}
      onChange={props.onChange}
      options={[
        {
          label: "Open",
          optionValue: "OPEN",
        },
        {
          label: "Closed",
          optionValue: "CLOSED",
        },
      ]}
    />
  );
};

export default OpenClosedTab;
