/* eslint-disable react/display-name */
import { useQuery } from "@apollo/client";
import { css, StyleSheet } from "aphrodite/no-important";
import Disclaimer from "marketing/components/Disclaimer";
import { VIEW } from "marketing/constants";
import MarketedCropFuturesAndOptions from "marketing/containers/MarketedCropFuturesAndOptions";
import CropDetailsDashboard from "marketing/dashboards/CropDetailsDashboard";
import withZipCodeProtection from "marketing/hoc/withZipCodeProtection";
import { getBreadCrumbLinks } from "marketing/utils/getBreadCrumbLinks";
import PropTypes from "prop-types";
import React from "react";
import router from "router";

import { GET_MARKETED_CROP } from "collection/graphql/marketing";
import { useFeatureAndPermissionAccess } from "hooks/useFeatureAndPermissionAccess";

import PayGateAdBanner from "components/advertisements/banners/PayGateAdBanner";
import BreadCrumbHeader from "components/fl-ui/Layout/BreadCrumbHeader";
import Container from "components/fl-ui/Layout/Container";
import Content from "components/fl-ui/Layout/Content";
import ErrorBoundary, { ErrorComponent } from "components/fl-ui/Layout/ErrorBoundary";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import RestrictedAccessBlankslate from "components/fl-ui/RestrictedAccessBlankslate";
import Tabs, { Tab } from "components/fl-ui/Tabs";

const styles = StyleSheet.create({
  container: {
    minHeight: "100%",
  },
  contractsContainer_temp: {
    width: "100%",
    height: "100%",
    minHeight: "100%",
    padding: "1rem",
  },
  floatRight: {
    float: "right",
  },
  marginRight: {
    marginRight: "1rem !important",
  },
  headerControlsWrapper: {
    display: "flex",
  },
});

const MarketedCropDetail = withZipCodeProtection(({ marketedCropId, view, year }) => {
  const { data, loading } = useQuery(GET_MARKETED_CROP, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: {
      id: marketedCropId,
    },
  });

  const { loadingAccess, showPaygate, showRestricted, showContent } = useFeatureAndPermissionAccess({
    featureName: "grain_marketing",
    permissionName: "marketing",
  });

  const onTabChange = (currentTab) => {
    router.navigate(`marketing/${year}/${marketedCropId}/${currentTab.toLowerCase()}`, { trigger: true });
  };

  const { marketedCrop } = data ?? {};
  if (loading && !marketedCrop) {
    return <LoadingWrapper isLoading loadingText="Loading..." />;
  }

  const { allowsSpeculativeContracts, commodity, name, productionStats } = marketedCrop;
  if (marketedCrop.year !== year || (view === MarketedCropDetail.CONTRACTS && !allowsSpeculativeContracts)) {
    router.navigate(`marketing/${marketedCrop.year}/${marketedCrop.id}`, { replace: true, trigger: true });
    return null;
  }

  const breadCrumbProps = {
    action: VIEW,
    commodityName: name || commodity?.name,
    contentType: view !== "DASHBOARD" && view,
    cropId: marketedCropId,
    year,
  };

  return (
    <Container>
      <LoadingWrapper isLoading={loadingAccess}>
        <BreadCrumbHeader links={getBreadCrumbLinks(breadCrumbProps)} noBorder />

        {showPaygate && <PayGateAdBanner />}
        {showRestricted && <RestrictedAccessBlankslate />}
        {showContent && (
          <>
            <div style={{ borderBottom: "1px solid #ddd" }}>
              <Tabs onChange={onTabChange} size="large" value={view}>
                <Tab value={MarketedCropDetail.DASHBOARD}>Overview</Tab>
                {allowsSpeculativeContracts && <Tab value={MarketedCropDetail.CONTRACTS}>Futures & Options</Tab>}
              </Tabs>
            </div>

            <ErrorBoundary fallback={() => <ErrorComponent />}>
              <Content style={{ marginTop: "2em" }}>
                {view === MarketedCropDetail.DASHBOARD && <CropDetailsDashboard marketedCrop={marketedCrop} />}

                {view === MarketedCropDetail.CONTRACTS && (
                  <div className={css(styles.contractsContainer_temp)}>
                    <MarketedCropFuturesAndOptions
                      commodity={commodity}
                      marketedCropId={marketedCropId}
                      productionStats={productionStats}
                      year={year}
                    />
                  </div>
                )}
              </Content>

              {commodity?.marketSymbol && <Disclaimer />}
            </ErrorBoundary>
          </>
        )}
      </LoadingWrapper>
    </Container>
  );
});

MarketedCropDetail.propTypes = {
  marketedCropId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  view: PropTypes.oneOf(["CONTRACTS", "DASHBOARD"]).isRequired,
  year: PropTypes.number.isRequired,
};

MarketedCropDetail.CONTRACTS = "CONTRACTS";
MarketedCropDetail.DASHBOARD = "DASHBOARD";

export default MarketedCropDetail;
