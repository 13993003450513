import { gql } from "@apollo/client";

export default gql`
  fragment FieldLayer on FieldLayer {
    created
    crop {
      id
      cropYear
    }
    date
    details
    field {
      centroid
      geometry
      group {
        id
        name
      }
      isIrrigated
      id
      name
    }
    geometry
    id
    type
    updated
  }
`;
