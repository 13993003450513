import createReactClass from "create-react-class";
import PropTypes from "prop-types";

const { object } = PropTypes;

export default createReactClass({
  displayName: "LeafletLayer",

  propTypes: {
    layer: object,
  },

  contextTypes: {
    map: object,
  },

  componentDidMount() {
    this.context.map.addLayer(this.props.layer);
  },

  componentDidUpdate({ layer }) {
    if (layer !== this.props.layer) {
      this.context.map.removeLayer(layer);
      this.context.map.addLayer(this.props.layer);
    }
  },

  componentWillUnmount() {
    try {
      this.context.map.removeLayer(this.props.layer);
    } catch (error) {
      // this can happen when the layer node is no longer in the DOM.
    }
  },

  render() {
    return null;
  },
});
