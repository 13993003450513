import { StyleSheet } from "aphrodite/no-important";

import { GreyColors } from "components/fl-ui/colors";
import { Borders, Mixins, Typography, Spacing } from "components/fl-ui/constants";

const styles = StyleSheet.create({
  // Detail Page Styles
  detailHeaderTitle: {
    flexGrow: 1,
  },
  listingTitle: {
    display: "flex",
    alignItems: "baseline",
  },
  contractSelectionFormGroup: {
    minHeight: Mixins.toRem(40),
    minWidth: Mixins.toRem(240),
  },
  movingAverage: {
    fontWeight: Typography.weights.medium,
    fontSize: Typography.sizes.lg,
    marginBottom: Spacing.large,
    lineHeight: 1.4,
  },
  movingAverage_timestamp: {
    fontSize: Typography.sizes.rg,
    lineHeight: 1.64,
    marginTop: `-${Spacing.large}`,
    marginBottom: Spacing.medium,
  },

  // Price Stats Snapshot
  statsTitle: {
    borderBottom: `1px solid ${GreyColors.smoke90}`,
    paddingBottom: Spacing.regular,
    marginTop: Spacing.large,
    marginBottom: 0,
  },
  container: {
    display: "grid",
    fontSize: Typography.sizes.rg,
    gridTemplateColumns: "1fr 1fr",
    gridColumnGap: Spacing.xxlarge,
    "@media only screen and (max-width: 30em)": {
      gridTemplateColumns: "1fr",
    },
  },
  cell: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    padding: `${Spacing.regular} 0`,
    borderBottom: Borders.regular,
    ":last-child": {
      borderBottom: "none",
    },
    "@media only screen and (max-width: 30em)": {
      ":last-child": {
        borderBottom: Borders.regular,
      },
    },
  },
  figureAmount: {
    textAlign: "right",
  },
  figureTitle: {
    fontWeight: Typography.weights.medium,
  },

  // Chart
  chartControlsContainer: {
    borderBottom: Borders.regular,
    margin: `0 0 ${Spacing.medium} 0`,
  },
  chartSelectorContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  chartSelectorIcons: {
    width: 26,
    height: 30,
  },

  // Chart Tooltip
  tooltipContainer: {
    background: "rgba(255,255,255,0.95)",
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.07)",
    border: `1px solid ${GreyColors.smoke90}`,
    borderRadius: "5px",
    padding: Spacing.regular,
  },
  tooltipLabel: {
    color: GreyColors.smoke50,
    fontSize: Typography.sizes.sm,
    lineHeight: Typography.sizes.sm,
  },
  tooltipLastPrice: {
    marginRight: Mixins.toRem(40),
  },
  tooltipTitle: {
    fontSize: Typography.sizes.sm,
    margin: `0 0 ${Spacing.regular}`,
  },
  tooltipValue: {
    fontSize: Typography.sizes.lg,
    fontWeight: Typography.weights.medium,
  },
});

export default styles;
