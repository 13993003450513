const DEFAULT_BUSHEL_SIZE = 60; // lbs
const UNITS = { cwt: 100, ton: 2000, kg: 2.20462262185 }; // factor constant from lbs

export default {
  to(unit, lbs, bushelSize) {
    if (["lbs", "lb"].includes(unit)) {
      return lbs;
    }
    UNITS.bu = bushelSize || DEFAULT_BUSHEL_SIZE;
    return lbs / UNITS[unit];
  },

  toLbs(amount, unit, commodity) {
    if (unit === "lbs") {
      return amount;
    }
    UNITS.bu = (commodity != null ? commodity.get("bushel_size") : undefined) || DEFAULT_BUSHEL_SIZE;
    return amount * UNITS[unit];
  },

  getDryWeight(weight, moisture) {
    // uses 86 because our dry standard is 14%
    if (moisture > 14) {
      return ((100 - moisture) / 86) * weight;
    } else {
      return weight;
    }
  },
};
