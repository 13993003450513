import ContractNumberSearch from "contracts/components/ContractNumberSearch";
import useContractsIndexPage from "contracts/hooks/useContractsIndexPage";
import ContractFilterModal from "contracts/modals/ContractFilterModal";
import ContractSortModal from "contracts/modals/ContractSortModal/ContractSortModal";
import React, { useState } from "react";
import styled from "styled-components";

import useViewType from "hooks/useViewType";

import { ClearAllButton, FilterButton, SortButton } from "components/fl-ui/Buttons";
import { Cluster } from "components/fl-ui/Layout";
import { Spacing } from "components/fl-ui/constants";

const ContractsFilterBar = styled(({ className }) => {
  const { currentFilterCount, currentSearchString, reloadAll, sortIsActive, setSearch } = useContractsIndexPage();
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showSortModal, setShowSortModal] = useState(false);
  const isMobile = useViewType() !== "desktop";

  return (
    <div className={className}>
      <Cluster className="contracts-filters-cluster">
        {isMobile && <SortButton sortIsActive={sortIsActive} onClick={() => setShowSortModal(true)} />}
        <FilterButton filterCount={currentFilterCount} onClick={() => setShowFilterModal(true)} />
        {sortIsActive || currentFilterCount || currentSearchString ? <ClearAllButton onClick={reloadAll} /> : null}
      </Cluster>

      {showFilterModal && <ContractFilterModal onClose={() => setShowFilterModal(false)} />}
      {showSortModal && <ContractSortModal onClose={() => setShowSortModal(false)} sortIsActive={sortIsActive} />}

      <ContractNumberSearch isMobile={isMobile} onChange={setSearch} value={(currentSearchString || "").toString()} />
    </div>
  );
})`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .contracts-filters-cluster {
    width: 24rem;
    padding: ${Spacing.xxsmall} 0;
  }

  form {
    display: flex;
    padding: ${Spacing.xxsmall} 0;
  }
`;

export default ContractsFilterBar;
