/* eslint-disable react/display-name */
import _ from "lodash";
import React, { Component } from "react";

import { GET_AGREEMENT_ENUMS } from "collection/graphql/agreements";
import client from "collection/graphql/client/marketingClient";

const createEnumCopyMap = _.memoize((key, data) => {
  return _.reduce(
    data,
    (copyMap, { data, display }) => ({
      ...copyMap,
      [data]: display,
    }),
    {}
  );
});

const withAgreementEnums = (WrappedComponent) =>
  class extends Component {
    state = { agreementEnums: null };

    async componentDidMount() {
      const { data } = await client.query({
        query: GET_AGREEMENT_ENUMS,
        fetchPolicy: "cache-first",
      });

      const agreementEnums = {};
      _.keys(data).forEach((key) => {
        agreementEnums[key] = createEnumCopyMap(key, data[key]);
      });
      this.setState({ agreementEnums });
    }

    render() {
      const { agreementEnums } = this.state;

      return agreementEnums ? <WrappedComponent {...this.props} agreementEnums={agreementEnums} /> : null;
    }
  };

export default withAgreementEnums;
