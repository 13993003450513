import MenuListFooter from "activity/components/choosers/MenuListFooter";
import PropTypes from "prop-types";
import React from "react";

import { Button } from "components/fl-ui";

const FieldCropChooserFooter = (props) => {
  const { onClose, onCreateCrop } = props;

  const handleClick = (event) => {
    event.preventDefault();
    onCreateCrop();
  };

  return (
    <MenuListFooter>
      <div>
        {`Can't find your crop? `}
        <Button color="primary" link onClick={handleClick} size="lg">
          Add a crop
        </Button>
      </div>

      <Button color="primary" link onClick={onClose} size="lg">
        Close
      </Button>
    </MenuListFooter>
  );
};

FieldCropChooserFooter.propTypes = {
  onClose: PropTypes.func,
  onCreateCrop: PropTypes.func,
};

export default FieldCropChooserFooter;
