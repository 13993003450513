import { css, StyleSheet } from "aphrodite/no-important";
import LayerImg from "map/layers/LayerImg";
import { CloudBadge } from "map/layers/helpers";
import { getCloudCoverage } from "map/layers/utils";
import { imageryTypeMap } from "map/layers/utils";
import React, { forwardRef } from "react";

import { EXPLORER_SELECTION_FIELD_LAYER } from "lib/metrics/events";

import AnomalyBadge from "components/field/anomaly_badge";

const styles = StyleSheet.create({
  badgeStyle: {
    position: "relative",
    bottom: "20px",
    textAlign: "right",
  },
  buttonStyle: {
    background: "transparent",
    border: "none",
    outline: "none",
    fontSize: "12px",
    padding: "0 0 .5em",
    margin: "0 .25em",
  },
  containerStyle: {
    width: "64px",
    height: "64px",
    border: "solid 1px #8f96a4",
    padding: "0.25em",
    marginBottom: "0.25em",
    "@media only screen and (max-height: 720px)": {
      width: "3em",
      height: "3em",
    },
  },
  dateStyle: {
    color: "white",
    fontWeight: 500,
    "@media only screen and (max-height: 720px)": {
      fontSize: "0.625rem",
    },
  },
  selected: {
    border: "0.25em solid white",
    fontWeight: 500,
  },
});

const LayerButton = forwardRef((props, ref) => {
  const {
    alert,
    children,
    imageryType,
    onClick,
    selected,
    value,
    value: { details, id, layer },
  } = props;

  const handleClick = (e) => {
    onClick(e, value);

    return EXPLORER_SELECTION_FIELD_LAYER.track({
      entity_id: id,
      field_id: value.field_id || value.field?.id,
      layer_type: value.type,
    });
  };

  return (
    <button className={css(styles.buttonStyle)} id={`layer-button-${id}`} onClick={handleClick} ref={ref}>
      <div className={css(styles.containerStyle, selected && styles.selected)}>
        <LayerImg layer={layer} src={details.images && details.images[imageryTypeMap[imageryType]].url} />

        <div className={css(styles.badgeStyle)}>
          {!!alert && <AnomalyBadge style={{ display: "inline-block" }} />}
          {!!getCloudCoverage(details) && <CloudBadge />}
        </div>
      </div>
      <div className={css(styles.dateStyle)}>{children}</div>
    </button>
  );
});

LayerButton.defaultProps = {
  value: {},
  onClick: () => {},
};

export default LayerButton;
