import CashContractForm, { useCashContractForm, withCashContractFormContext } from "contracts/form/CashContractForm";
import CashContractFormActions from "contracts/form/CashContractForm/CashContractFormActions";
import useContractsIndexPage from "contracts/hooks/useContractsIndexPage";
import PropTypes from "prop-types";
import React from "react";

import { CloseX } from "components/fl-ui/Icons";
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "components/fl-ui/Modal/Modal";

const ContractFormModal = withCashContractFormContext(({ onClose, onSave }) => {
  const { isEdit } = useCashContractForm();
  const { reloadAll } = useContractsIndexPage();
  const handleSave = (contract = {}) => {
    onSave(contract);
    // reload happens in pricing modal if new contract (not edit)
    if (isEdit) {
      reloadAll();
    }
  };

  return (
    <Modal width={500}>
      <ModalHeader>
        <ModalTitle>{isEdit ? "Edit cash contract" : "Add cash contract"}</ModalTitle>
        <CloseX onClick={onClose} />
      </ModalHeader>

      <ModalBody>
        <CashContractForm formId="contractForm" layout="columnar" onCancel={onClose} onSave={handleSave} />
      </ModalBody>

      <ModalFooter>
        <CashContractFormActions formId="contractForm" onCancel={onClose} onDelete={onClose} />
      </ModalFooter>
    </Modal>
  );
});

ContractFormModal.propTypes = {
  contractId: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default ContractFormModal;
