export const BASIS = "BASIS";
export const MIN_MAX = "MIN_MAX";
export const MIN_MAX_AUTOMATIC = "MIN_MAX_AUTOMATIC";
export const AUTOHEDGE = "AUTOHEDGE";
export const AUTOMATIC = "AUTOMATIC";
export const EDIT = "Edit";
export const VIEW = "View";
export const MIN_COST_CORN = 475;
export const MAX_COST_CORN = 800;
export const MIN_YIELD_CORN = 100;
export const MAX_YIELD_CORN = 275;

export const MIN_COST_SOYBEANS = 400;
export const MAX_COST_SOYBEANS = 625;
export const MIN_YIELD_SOYBEANS = 25;
export const MAX_YIELD_SOYBEANS = 90;
export const CARGILL_ELEVATE = "CargillElevate";
export const SUMMARY = "Summary";

export const MARKETING_PAGE_POLL_INTERVAL = 5 * 60 * 1000;
