import { array, bool, object, string } from "yup";


const requiredIfEnabled = {
  is: (isEnabled) => isEnabled,
  then: (schema) => schema.required(),
  otherwise: (schema) => schema.strip(),
};

/**
 * @typedef {Object} FarmAdvisorConfig
 * @property {CommentaryType[]} [disallowedCommentaryTypes]
 * @property {"CARGILL"|"CARGILL_DEV"|"DEFAULT"|"FARMERSCO"} id
 * @property {boolean} isEnabled
 * @property {string} [name]
 * @property {object} [nav]
 * @property {string} [nav.tabTitle]
 * @property {string} [programName]
 * @property {object} [urls]
 * @property {string} [urls.customerManagement]
 * @property {"FarmAdvisorConfig"} __typename
 */

/**
 * Maps to an `AdvisorCommentary` `__typename`.
 * @typedef {"ActiveRecommendationCommentary"|"DailyFlashCommentary"|"OtherCommentary"|"RSSEntry"|"USDAMarketUpdateCommentary"|"WeeklyReportCommentary"} CommentaryType
 */

/**
 * The data for this is currently sourced from the [farm-advisor-config
 * flag](https://app.launchdarkly.com/default/live/features/farm-advisor-config) in LaunchDarkly. The plan is that it
 * will eventually come from a graphql endpoint (`getAdvisorConfig`?) which is why we're including a __typename.
 */
const FarmAdvisorConfigSchema = object()
  .default({ id: "DEFAULT" })
  .required()

  .transform((value, originalValue) => {
    if (originalValue?.id === "DEFAULT") {
      return {
        ...originalValue,
        isEnabled: false,
      };
    }

    return originalValue;
  })

  .shape({
    disallowedCommentaryTypes: array()
      .default([])
      .when("isEnabled", requiredIfEnabled)
      .of(string().oneOf([
        "ActiveRecommendationCommentary",
        "DailyFlashCommentary",
        "OtherCommentary",
        "RSSEntry",
        "USDAMarketUpdateCommentary",
        "WeeklyReportCommentary"
      ], "${value} is not a valid commentary type")),

    id: string().default("DEFAULT").trim().required(),
    isEnabled: bool()
      .default(false)
      .when("id", {
        is: (id) => id === "DEFAULT",
        then: (schema) => schema.oneOf([false]),
        otherwise: (schema) => schema.required(),
      }),
    name: string().trim().when("isEnabled", requiredIfEnabled),
    nav: object().when("isEnabled", requiredIfEnabled).shape({
      tabTitle: string().trim().required(),
    }),
    programName: string().trim().when("isEnabled", requiredIfEnabled),

    urls: object().when("isEnabled", requiredIfEnabled).shape({
      customerManagement: string().url().required(),
    }),

    __typename: string().default("FarmAdvisorConfig").required(),
  });

export default FarmAdvisorConfigSchema;
