import PropTypes from "prop-types";
import React from "react";

const FieldDetailsTableRow = ({ label, value }) => (
  <tr>
    <td>{label.toUpperCase()}</td>
    <td>{value}</td>
  </tr>
);

FieldDetailsTableRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
};

export default FieldDetailsTableRow;
