import { css } from "aphrodite/no-important";
import StripeInputWrapper from "billing/components/payment/StripeInputWrapper";
import { cardElementStyles, styles } from "billing/lib/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { CardCVCElement, CardExpiryElement, CardNumberElement } from "react-stripe-elements";

import CreditCardIcon from "components/fl-ui/CreditCardIcon";
import { FormGroup, Input } from "components/fl-ui/Form";
import { Icon } from "components/fl-ui/Icons";
import Tooltip from "components/fl-ui/Tooltip";

const CardNumberTooltip = () => {
  return (
    <Tooltip
      message="Bushel Farm safeguards your information by encrypting your payment details with SSL.
        Bushel Farm does not store your credit card information."
    >
      <Icon className={css(styles.pos_inputIcon)} icon="lock" />
    </Tooltip>
  );
};

const CVCTooltip = () => {
  return (
    <Tooltip
      message="The Card Verification Value (CVV) on your credit card or debit card is a 3 digit number on VISA®,
        MasterCard® and Discover® branded credit and debit cards.
        On your American Express® branded credit or debit card it is a 4 digit numeric code."
    >
      <Icon className={css(styles.pos_inputIcon)} icon="help" />
    </Tooltip>
  );
};

class CardInput extends React.Component {
  static propTypes = {
    onBillingDataChange: PropTypes.func.isRequired,
    onValidationChange: PropTypes.func.isRequired,
    zipCode: PropTypes.any.isRequired,
  };

  state = {
    card: {
      address_zip: this.props.zipCode,
      cardCVC: {
        complete: false,
        empty: true,
      },
      cardExpiry: {
        complete: false,
        empty: true,
      },
      cardNumber: {
        complete: false,
        empty: true,
      },
      name: "",
    },
    error: {},
  };

  handleCardChange = (input, key) => {
    if (key === "address_zip" || key === "name") {
      this.props.onBillingDataChange(input);
    }

    this.setState(({ card, error }, { onValidationChange }) => {
      const resultingState = {
        card: { ...card, ...input },
        error: { ...error, [key]: key && input[key].error },
      };

      const hasErrors = _.compact(_.values(resultingState.error)).length > 0;
      const ccFields = ["cardNumber", "cardExpiry", "cardCVC"];
      const ccFieldsAreComplete = ccFields.every((key) => resultingState.card[key].complete);
      onValidationChange(ccFieldsAreComplete && !hasErrors);

      return resultingState;
    });
  };

  validateEmptyField = (key, value) => {
    this.setState(({ error }) => ({ error: { ...error, [key]: !value } }));
  };

  render() {
    const { card, error } = this.state;
    const { cardNumber, cardExpiry, cardCVC } = card;
    const brand = (cardNumber.brand || "").toLowerCase();

    return (
      <>
        <FormGroup label="Card number" className={css(styles.no_marginBottom)}>
          <StripeInputWrapper data-cy="cardNumber" tooltip={<CardNumberTooltip />} hasError={cardNumber.error}>
            {brand && brand !== "unknown" ? (
              <CreditCardIcon cardType={brand} />
            ) : (
              <span style={{ display: "inline-block", width: "40px" }}> </span>
            )}
            <CardNumberElement
              onChange={(cardNumber) => this.handleCardChange({ cardNumber }, "cardNumber")}
              style={cardElementStyles}
            />
          </StripeInputWrapper>
          {error.cardNumber && <p className={css(styles.pos_formInputError)}>{error.cardNumber.message}</p>}
        </FormGroup>

        <FormGroup label="Cardholder name" data-cy="cardFullName">
          <Input
            autoComplete="cc-name"
            hasError={error.name}
            name="name"
            onBlur={({ target }) => this.validateEmptyField(target.name, target.value)}
            onChange={_.debounce((e, data) => this.handleCardChange(data, "name"), 500)}
            placeholder="Name on card"
            size="large"
            type="text"
          />
          {error.name && <p className={css(styles.pos_formInputError)}>Name required</p>}
        </FormGroup>

        <div className={css(styles.pos_formRow)}>
          <FormGroup label="Expiration date" className={css(styles.pos_formGroupSplit, styles.no_marginBottom)}>
            <StripeInputWrapper data-cy="cardExp" hasError={cardExpiry.error}>
              <CardExpiryElement
                onChange={(cardExpiry) => this.handleCardChange({ cardExpiry }, "cardExpiry")}
                style={cardElementStyles}
              />
            </StripeInputWrapper>
            {error.cardExpiry && <p className={css(styles.pos_formInputError)}>{error.cardExpiry.message}</p>}
          </FormGroup>

          <FormGroup label="CVC" className={css(styles.pos_formGroupSplit, styles.no_marginBottom)}>
            <StripeInputWrapper data-cy="cardCVC" tooltip={<CVCTooltip />} hasError={cardCVC.error}>
              <CardCVCElement
                onChange={(cardCVC) => this.handleCardChange({ cardCVC }, "cardCVC")}
                placeholder="123"
                style={cardElementStyles}
              />
            </StripeInputWrapper>
            {error.cardCVC && <p className={css(styles.pos_formInputError)}>{error.cardCVC.message}</p>}
          </FormGroup>
        </div>

        <FormGroup label="Billing postal code" data-cy="cardZip">
          <Input
            autoComplete="postal-code"
            defaultValue={this.props.zipCode}
            hasError={error.address_zip}
            name="address_zip"
            onBlur={({ target }) => this.validateEmptyField(target.name, target.value)}
            onChange={_.debounce((e, data) => this.handleCardChange(data, "address_zip"), 500)}
            placeholder="99577"
            size="large"
            type="text"
          />
          {error.address_zip && <p className={css(styles.pos_formInputError)}>Postal code required</p>}
        </FormGroup>
      </>
    );
  }
}

export default CardInput;
