/* eslint-disable react/display-name */
import { AgreementDetailPage, Dashboard } from "./pages";
import React from "react";

import { marketingClient, wrapWithProvider } from "collection/graphql/client";
import usePermissions from "hooks/usePermissions";
import App from "layout/app";
import SubRoute from "lib/SubRoute";

import { RestrictedAccessWithHeader } from "components/fl-ui/RestrictedAccessWithHeader";

const withPageProtections = (WrappedComponent) => (props) => {
  const { canRead } = usePermissions();
  return canRead("agreements") ? <WrappedComponent {...props} /> : <RestrictedAccessWithHeader title="Land Costs" />;
};

const AgreementsRouter = SubRoute.extend({
  routes: {
    "": "index",
    add: "add",
    ":id": "edit",
  },

  async display(page, props) {
    App.display(wrapWithProvider(withPageProtections(page), marketingClient), {
      year: new Date().getFullYear(),
      ...props,
    });
  },

  index() {
    return this.display(Dashboard, { screenName: "Land Costs" });
  },

  add() {
    return this.display(AgreementDetailPage, { screenName: "Add agreement" });
  },

  edit(agreementId) {
    return this.display(AgreementDetailPage, { screenName: "Edit Land Cost", agreementId });
  },
});

export default () => new AgreementsRouter("land_costs", { createTrailingSlashRoutes: true });
