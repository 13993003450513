import { useLazyQuery, useQuery } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { ImportedCashContractSchema, ManualCashContractSchema } from "contracts/form/validation/CashContractFormSchema";
import _ from "lodash";
import React, { createContext, useContext, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { GET_COMMODITIES } from "collection/graphql/commodities/queries";
import getCashContractDetails from "collection/graphql/contracts/queries/getCashContractDetails";
import { GET_ALL_MARKETED_CROPS } from "collection/graphql/marketing";
import useSchemaValidation from "hooks/useSchemaValidation";

export const cashContractFormContext = createContext(null);

const defaultFormFields = () => ({
  buyer: null,
  commodityId: null,
  contractDate: null,
  contractNumber: null,
  contractPrice: null,
  contractType: null,
  contractStatus: "OPEN",
  cropYear: null,
  dataSource: "USER",
  id: null,
  marketedCropId: null,
  quantity: null,
  quantityCanceled: null,
  quantityDelivered: null,
});

const useContractForm = ({ contractId }) => {
  const isEdit = !!contractId;
  const [contract, setContract] = useState(null);
  const isPartner = contract?.dataSource === "PARTNER";

  const [getContract] = useLazyQuery(getCashContractDetails, {
    errorPolicy: "ignore",
    variables: {
      id: contractId,
    },
  });

  /*
   * Set up validation schema, context, and resolver
   */
  const commodityQuery = useQuery(GET_COMMODITIES);
  const marketedCropsQuery = useQuery(GET_ALL_MARKETED_CROPS, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const queries = [commodityQuery, marketedCropsQuery];

  const context = useMemo(() => {
    return {
      commodities: queries[0].data?.commodities ?? [],
      isEdit,
      isPartner,
      marketedCrops: queries[1].data?.marketedCrops ?? [],
    };
  }, _.map(queries, "data"));

  const schema = useMemo(() => {
    return isPartner ? ImportedCashContractSchema : ManualCashContractSchema;
  }, [isPartner]);

  const resolver = useMemo(() => yupResolver(schema), [schema]);

  /*
   * Initialize the form
   */
  const form = useForm({
    context,
    defaultValues: async () => {
      const defaults = defaultFormFields();

      if (contractId) {
        const { contract } = (await getContract()).data;
        Object.assign(defaults, _.pick(contract, _.keys(defaultFormFields())));
        defaults.commodityId = contract.commodity.id;
        defaults.marketedCropId = contract.marketedCrop?.id ?? null;
        setContract(contract);
      }

      return defaults;
    },
    mode: "onTouched",
    resolver,
  });

  const { isValid } = useSchemaValidation(schema, form.watch(), { context });

  return useMemo(() => {
    return {
      ...form,
      commodities: context.commodities,
      contract,
      isEdit,
      isPartner,
      isValid,
      schema,
    };
  }, [context, contract, form, isEdit, isValid, isPartner, schema]);
};

const CashContractFormProvider = ({ children, contractId }) => {
  const context = useContractForm({ contractId });
  return (
    <cashContractFormContext.Provider value={context}>
      <FormProvider {...context}>{children}</FormProvider>
    </cashContractFormContext.Provider>
  );
};

export const withCashContractFormContext = (Component) => {
  return ({ contractId, ...props }) => {
    // ensure that only one context is provided
    return useContext(cashContractFormContext) ? (
      <Component {...props} />
    ) : (
      <CashContractFormProvider contractId={contractId}>
        <Component {...props} />
      </CashContractFormProvider>
    );
  };
};

export default withCashContractFormContext;
