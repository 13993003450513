import DebugInfo from "demo/demos/DebugInfo";
import EditProductDemo from "demo/demos/EditProductDemo";
import Flags from "demo/demos/Flags";
import IconDemo from "demo/demos/IconDemo";
import MobilePluginsDemo from "demo/demos/MobilePluginsDemo";
import MobileSubscriptionsDemo from "demo/demos/MobileSubscriptionsDemo";
import PayGateDemos from "demo/demos/PayGateDemos";
import PendoDemo from "demo/demos/PendoDemo";
import PurchaseFormsDemo from "demo/demos/PurchaseFormsDemo";
import ScratchPage from "demo/demos/ScratchPage";
import SentryTestDemo from "demo/demos/SentryTestDemo";
import SessionTestDemo from "demo/demos/SessionTestDemo";
import React from "react";
import ReactSelect from "react-select";
import router from "router";

import useReleaseFeature from "hooks/useReleaseFeature";

import { Container, Content, ContentMain, Header } from "components/fl-ui/Layout";

const Index = ({ tab = "scratch" }) => {
  const updateTab = (tab) => {
    router.navigate(`demo?tab=${tab}`, { trigger: true });
  };

  const hasAccess = useReleaseFeature("debug").enabled;
  if (!hasAccess) {
    if (hasAccess === false) {
      window.location.href = "#fields";
    }
    return null;
  }

  const options = [
    { label: "Scratch page", value: "scratch" },
    { label: "FARM-8493 session test", value: "session" },
    { label: "Debug info", value: "debugInfo" },
    { label: "Sentry", value: "sentry" },
    { label: "Mobile Plugins", value: "mobilePlugins" },
    { label: "Pendo", value: "pendo" },
    { label: "RevenueCat", value: "mobileSubscriptions" },
    { label: "Flags", value: "flags" },
    { label: "Icons", value: "icons" },
    { label: "Pay gates", value: "payGates" },
    { label: "Purchase forms", value: "purchaseForms" },
    { label: "Product chooser", value: "editProduct" },
  ];
  const selectedOption = _.find(options, { value: tab });

  return (
    <Container>
      <Header title="Demo Page" />
      <Content>
        <ContentMain fullWidth>
          <ReactSelect onChange={({ value }) => updateTab(value)} options={options} value={selectedOption} />

          <div style={{ marginTop: "20px" }}>
            {tab === "scratch" && <ScratchPage />}
            {tab === "session" && <SessionTestDemo />}
            {tab === "debugInfo" && <DebugInfo />}
            {tab === "sentry" && <SentryTestDemo />}
            {tab === "mobilePlugins" && <MobilePluginsDemo />}
            {tab === "mobileSubscriptions" && <MobileSubscriptionsDemo />}
            {tab === "pendo" && <PendoDemo />}
            {tab === "flags" && <Flags />}
            {tab === "icons" && <IconDemo />}
            {tab === "payGates" && <PayGateDemos />}
            {tab === "purchaseForms" && <PurchaseFormsDemo />}
            {tab === "editProduct" && <EditProductDemo />}
          </div>
        </ContentMain>
      </Content>
    </Container>
  );
};

export default Index;
