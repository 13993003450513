import { useEffect } from "react";
import router from "router";

import useEnterpriseFeature from "hooks/useEnterpriseFeature";

const useFeatureRedirect = ({ feature, skip = false, url = "fields" }) => {
  const hasFeature = useEnterpriseFeature(feature);

  useEffect(() => {
    if (!skip && !hasFeature && hasFeature === false) {
      router.navigate(url, { trigger: true });
    }
  }, [hasFeature, skip]);
};

export default useFeatureRedirect;
