import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import useOnClickOutside from "react-cool-onclickoutside";
import styled from "styled-components";

import useCurrentCropYear from "hooks/useCurrentCropYear";

import { Button } from "components/fl-ui";

const PickerButtons = styled(({ className, onNextClick, onPreviousClick, onYearClick, year }) => (
  <div className={className}>
    <Button onClick={() => onPreviousClick()}>‹</Button>
    <Button data-cy="currentPickerYear" onClick={onYearClick}>
      {year}
    </Button>
    <Button onClick={() => onNextClick()}>›</Button>
  </div>
))`
  display: flex;
  max-height: 40px;

  > button {
    border-radius: 0;
    color: #5c6370;
    font-weight: 300;
    font-size: 20px;
    line-height: 1;
    padding: 0.5em;
  }
  > :first-child {
    border-bottom-left-radius: 3px;
    border-right: none;
    border-top-left-radius: 3px;
  }
  > :last-child {
    border-bottom-right-radius: 3px;
    border-left: none;
    border-top-right-radius: 3px;
  }
`;

const YearPicker = ({ disabledYears, onChange, year, years }) => {
  const formattedDisabledYears = useMemo(() => disabledYears || new Set(), [disabledYears, years]);
  const [showDropDown, setShowDropDown] = useState(false);
  const [cropYear, setCropYear] = useCurrentCropYear();
  const yearOrCropYear = year || cropYear;
  const dropdownRef = useOnClickOutside(() => setShowDropDown(false));

  const updateYear = (newYear) => {
    setCropYear(newYear);
    onChange(newYear);
  };

  const shiftYear = (increment) => {
    const shiftedYear = yearOrCropYear + increment;
    if (years.includes(shiftedYear)) {
      updateYear(shiftedYear);
    }
  };

  return (
    <div className="year-picker">
      <PickerButtons
        onNextClick={() => shiftYear(1)}
        onPreviousClick={() => shiftYear(-1)}
        onYearClick={() => setShowDropDown(!showDropDown)}
        year={yearOrCropYear}
      />

      {showDropDown && (
        <div className="year-picker-dropdown" ref={dropdownRef} style={{ display: "block" }}>
          {years.map((dropdownYear) => (
            <a
              className={`year ${formattedDisabledYears.has(dropdownYear) ? "disabled" : ""}`}
              data-cy="pickerYear"
              key={dropdownYear}
              onClick={() => updateYear(dropdownYear)}
              onKeyDown={() => null}
            >
              {dropdownYear}
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

YearPicker.propTypes = {
  onChange: PropTypes.func,
  disabledYears: PropTypes.instanceOf(Set),
  year: PropTypes.number,
  years: PropTypes.array,
};

YearPicker.defaultProps = {
  onChange: () => {},
  years: [new Date().getFullYear()],
};

export default YearPicker;
