import { clearData } from "./clearData";
import { logoutMobileUser } from "mobile";

export default async function logout() {
  const actions = [];
  actions.push(clearData());
  actions.push(logoutMobileUser());
  actions.push(fetch("/v2.0/users/logout").catch(() => {}));

  await Promise.allSettled(actions);
}
