import ActivityTypeFilter from "../components/filters/ActivityTypeFilter";
import BooleanFilter from "../components/filters/BooleanFilter";
import ColumnFilter from "../components/filters/ColumnFilter";
import CommodityFilter from "../components/filters/CommodityFilter";
import DateRangeFilter from "../components/filters/DateRangeFilter";
import DropdownFilter from "../components/filters/DropdownFilter";
import FieldFilter from "../components/filters/FieldFilter";
import FieldGroupFilter from "../components/filters/FieldGroupFilter";
import IntegerFilter from "../components/filters/IntegerFilter";
import LandAgreementFilter from "../components/filters/LandAgreementFilter";
import MonthFilter from "../components/filters/MonthFilter";
import YearFilter from "../components/filters/YearFilter";
import PropTypes from "prop-types";
import React from "react";

/**
 * Container around the params that knows how to dispatch each indiviual param
 * type to the appropriate componenet, else skipping it.
 *
 * This also adds the special "toggleable columns" aspect to the sidebar.
 */
const ReportParams = (props) => {
  const { columns, onParamChanged, params } = props;

  if (params.length === 0) {
    return null;
  }

  const handleParamChange = ({ id, value }) => onParamChanged({ id, value });

  const renderedParams = params
    .map((param) => {
      switch (param.type) {
        case "activity_types":
          return <ActivityTypeFilter key={param.id} param={param} onParamChanged={handleParamChange} />;
        case "year":
          return <YearFilter key={param.id} param={param} onParamChanged={handleParamChange} />;
        case "month":
          return <MonthFilter key={param.id} param={param} onParamChanged={handleParamChange} />;
        case "fields":
          return <FieldFilter key={param.id} param={param} onParamChanged={handleParamChange} />;
        case "field_groups":
          return <FieldGroupFilter key={param.id} param={param} onParamChanged={handleParamChange} />;
        case "commodities":
          return <CommodityFilter key={param.id} param={param} onParamChanged={handleParamChange} />;
        case "land_agreements":
          return <LandAgreementFilter key={param.id} param={param} onParamChanged={handleParamChange} />;
        case "date_range":
          return <DateRangeFilter key={param.id} param={param} onParamChanged={handleParamChange} />;
        case "boolean":
          return <BooleanFilter key={param.id} param={param} onParamChanged={handleParamChange} />;
        case "int":
          return <IntegerFilter key={param.id} param={param} onParamChanged={handleParamChange} />;
        case "dropdown":
          return <DropdownFilter key={param.id} param={param} onParamChanged={handleParamChange} />;
      }
      return null;
    })
    .filter((param) => param != null);

  if (columns.length > 0) {
    renderedParams.push(<ColumnFilter key="reports-columns" columns={columns} onParamChanged={handleParamChange} />);
  }

  return <div>{renderedParams}</div>;
};

ReportParams.propTypes = {
  columns: PropTypes.array,
  onParamChanged: PropTypes.func.isRequired,
  params: PropTypes.array,
};

ReportParams.defaultProps = {
  columns: [],
  params: [],
};

export default ReportParams;
