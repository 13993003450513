import { useQuery } from "@apollo/client";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

import getContractCommodities from "collection/graphql/contracts/queries/getContractCommodities";

import { TagSelectGroup } from "components/fl-ui/Form";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";

const ContractCommodityFilter = ({ onChange, value }) => {
  const { data, loading } = useQuery(getContractCommodities);
  const options = useMemo(() => {
    return _.sortBy(data?.contractCommodities, ["name"]).map((commodity) => ({
      label: commodity.name,
      value: commodity.id,
    }));
  }, [data?.contractCommodities]);

  return (
    <LoadingWrapper isLoading={loading}>
      <TagSelectGroup
        id="commoditiesFilter"
        isMulti
        label="Commodities"
        onChange={(commodities) => onChange(commodities)}
        options={options}
        showBulkSelect
        value={value}
      />
    </LoadingWrapper>
  );
};

ContractCommodityFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired,
};

export default ContractCommodityFilter;
