/* eslint-disable react/display-name */
import React, { createContext, useContext, useState, useMemo } from "react";

/**
 * @typedef {Object} SettingsPageContext
 * @property {Boolean} mainNavIsVisible
 * @property {Function} setMainNavVisibility
 */

/**
 * @return {SettingsPageContext}
 */
export const useSettingsPageContext = () => useContext(settingsPageContext);
export const withSettingsPageContext = (Component) => (props) =>
  (
    <Provider>
      <Component {...props} />
    </Provider>
  );

export const Provider = ({ children }) => {
  const [mainNavIsVisible, setMainNavVisibility] = useState(true);
  const context = useMemo(
    () => ({
      mainNavIsVisible,
      setMainNavVisibility,
    }),
    [mainNavIsVisible, setMainNavVisibility]
  );

  return <settingsPageContext.Provider value={context}>{children}</settingsPageContext.Provider>;
};

const settingsPageContext = createContext(undefined);
export default settingsPageContext;
