import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { Modal } from "components/fl-ui/Modal/Modal";

const ImageCarousel = (props) => {
  const { onDownload, onHide, readOnly } = props;
  const [state, setState] = useState({
    confirmDelete: false,
    idx: props.idx,
    images: props.images,
  });
  const { idx, images } = state;
  const previewUrl = images[idx]?.previewUrl ?? "";

  const _shouldShowArrows = () => {
    return (images != null ? images.length : undefined) > 1;
  };

  const _handlePrevious = (event) => {
    event.stopPropagation();
    return setState({
      ...state,
      idx: idx === 0 ? images.length - 1 : idx - 1,
    });
  };

  const _handleNext = (event) => {
    event.stopPropagation();
    return setState({
      ...state,
      idx: (idx + 1) % images.length,
    });
  };

  const _handleClose = (event) => {
    event.stopPropagation();
    onHide();
  };

  const _handleKeydown = (event) => {
    const { keyCode } = event;

    if (keyCode === 37) {
      // left key
      _handlePrevious(event);
    } else if (keyCode === 39) {
      // right key
      _handleNext(event);
    } else if (keyCode === 27) {
      // escape key
      _handleClose(event);
    }
  };

  const handleDownloadClick = (event) => {
    event.preventDefault();
    onDownload(images[idx]);
  };

  useEffect(() => {
    document.addEventListener("keydown", _handleKeydown);
    setState({
      ...state,
      idx: props.idx,
      images: props.images,
    });

    return () => {
      document.removeEventListener("keydown", _handleKeydown);
    };
  }, [props.idx, props.images]);

  return (
    <Modal>
      <div className="fl-modal-body fl-modal-body-no-padding">
        <div className="close-x close-x-l close-x-outside" onClick={_handleClose}>
          ×
        </div>
        <img className="fl-modal-lightbox-media" src={previewUrl} />
        {!readOnly && onDownload && (
          <div className="fl-modal-lightbox-action">
            <a className="inline-confirm-action" href="#" style={{ marginRight: "5px" }} onClick={handleDownloadClick}>
              Download
            </a>
          </div>
        )}
      </div>

      {_shouldShowArrows() && (
        <div>
          <div className="fl-modal-nav-item prev" onClick={_handlePrevious}>
            {" ‹ "}
          </div>
          <div className="fl-modal-nav-item next" onClick={_handleNext}>
            {" › "}
          </div>
        </div>
      )}
    </Modal>
  );
};

ImageCarousel.propTypes = {
  idx: PropTypes.number.isRequired,
  images: PropTypes.array.isRequired,
  onDelete: PropTypes.func,
  onDownload: PropTypes.func,
  onHide: PropTypes.func,
  readOnly: PropTypes.bool,
};

ImageCarousel.defaultProps = {
  onHide: () => {},
  readOnly: false,
};

export default ImageCarousel;
