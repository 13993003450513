import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { Button } from "components/fl-ui";
import { CloseX } from "components/fl-ui/Icons";
import { Modal, ModalBody, ModalHeader } from "components/fl-ui/Modal/Modal";
import { Spacing, Typography } from "components/fl-ui/constants";

const InviteSuccessBody = styled(ModalBody)`
  font-size: ${Typography.sizes.md};

  .main-copy {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    gap: ${Spacing.small};
  }

  .call-to-action {
    font-size: ${Typography.sizes.lg};
    font-weight: ${Typography.weights.bold};
    padding: ${Spacing.medium} 0;
    text-align: center;
  }

  .button-group {
    display: flex;
    gap: ${Spacing.small};
    justify-content: flex-end;
  }
`;

const InviteSuccess = ({ handleDoneClick, handleYesClick, newEmail }) => (
  <Modal>
    <ModalHeader>
      <CloseX className="close-x" onClick={handleDoneClick} />
    </ModalHeader>

    <InviteSuccessBody>
      <div className="main-copy">
        <img alt="Invite successful" height="60" src="/images/invite/email-illustration.svg" width="83" />

        <div>
          An invitation was sent to <span className="blue-text">{newEmail}</span>
        </div>
      </div>

      <p className="call-to-action">Add another person to your farm?</p>

      <div className="button-group">
        <Button onClick={handleDoneClick}>Not now</Button>
        <Button color="primary" onClick={handleYesClick}>
          Yes
        </Button>
      </div>
    </InviteSuccessBody>
  </Modal>
);

InviteSuccess.propTypes = {
  newEmail: PropTypes.string.isRequired,
  handleDoneClick: PropTypes.func.isRequired,
  handleYesClick: PropTypes.func.isRequired,
};

export default InviteSuccess;
