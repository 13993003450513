import { css } from "aphrodite/no-important";
import PropTypes from "prop-types";
import React from "react";

import styles from "components/advertisements/styles";
import { CloseX } from "components/fl-ui/Icons";
import { Modal, ModalBody } from "components/fl-ui/Modal/Modal";

const PayGateCommonBlankStateModal = ({ banner: Banner, onClose, width = 800 }) => (
  <Modal width={width}>
    <ModalBody className={css(styles.modal_body)}>
      <CloseX className={css(styles.modal_closeX)} onClick={onClose} />
      <Banner size="sm" />
    </ModalBody>
  </Modal>
);

PayGateCommonBlankStateModal.propTypes = {
  banner: PropTypes.any.isRequired,
  onClose: PropTypes.func,
};

PayGateCommonBlankStateModal.defaultProps = {
  onClose: () => {},
};

export default PayGateCommonBlankStateModal;
