import { useMutation } from "@apollo/client";

import buildOptionsContractInput from "collection/graphql/contracts/hooks/buildOptionsContractInput";
import { EDIT_OPTIONS_CONTRACT } from "collection/graphql/marketing";

const useEditOptionsContract = (options) => {
  const save = useMutation(EDIT_OPTIONS_CONTRACT, options)[0];

  return (contract, options = {}) => {
    const input = buildOptionsContractInput(contract);
    return save({
      variables: {
        id: contract.id,
        input,
      },
      ...options,
    });
  };
};

export default useEditOptionsContract;
