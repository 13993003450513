import { gql } from "@apollo/client";

import BasicNoteFragment from "collection/graphql/notes/fragments/BasicNote";

export default gql`
  query getAllNotes {
    notes: getAllNotes @rest(path: "/api/notes", type: "Note") {
      ...BasicNote
    }
  }

  ${BasicNoteFragment}
`;
