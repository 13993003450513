import { GreyColors } from "components/fl-ui/colors";

const Borders = {
  light: `1px solid ${GreyColors.smoke95}`,
  regular: `1px solid ${GreyColors.smoke90}`,
  dark: `1px solid ${GreyColors.smoke80}`,
  darker: `1px solid ${GreyColors.smoke60}`,
  dashed: `1px dashed ${GreyColors.smoke60}`,
  dashedDarker: `1px dashed ${GreyColors.smoke40}`,
  tableRow: `0px 2px 3px ${GreyColors.smoke80}, 0px 0px 2px ${GreyColors.smoke80}`,
};

export default Borders;
