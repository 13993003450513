import { gql } from "@apollo/client";

export default gql`
  query getRainfallHistory($geometry: String!, $startDate: String!, $endDate: String!) {
    daily: getFieldRainHistory(geometry: $geometry, start: $startDate, end: $endDate)
      @rest(type: "FieldRainfallHistory", path: "/rain/observations?{args}") {
      amount
      date
      day @client
      lookup @client
      month @client
    }

    averages: getHistoricalRainfallAverages(geometry: $geometry)
      @rest(type: "HistoricalRainfallAverage", path: "/rain/averages?{args}") {
      amount
      day
      lookup @client
      month
    }
  }
`;
