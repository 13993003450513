import _ from "underscore";

import ajax from "lib/ajax";

let UNITS = [
  {
    id: "lb",
    name: "pound",
    symbol: "lb",
    type: "weight",
    conversion_rate: 1,
    name_plural: "pounds",
    symbol_plural: "lbs",
    legacy: "pounds",
  },
  {
    id: "cwt",
    name: "hundredweight",
    symbol: "cwt",
    type: "weight",
    conversion_rate: 100,
    name_plural: "hundredweight",
    symbol_plural: "cwt",
  },
  {
    id: "ton",
    name: "ton",
    symbol: "ton",
    type: "weight",
    conversion_rate: 2000,
    name_plural: "tons",
    symbol_plural: "tons",
    legacy: "tons",
  },
  {
    id: "g",
    name: "gram",
    symbol: "g",
    type: "weight",
    conversion_rate: 0.00220462262185,
    name_plural: "grams",
    symbol_plural: "g",
    legacy: "g",
  },
  {
    id: "kg",
    name: "kilogram",
    symbol: "kg",
    type: "weight",
    conversion_rate: 2.20462262185,
    name_plural: "kilograms",
    symbol_plural: "kg",
    legacy: "kg",
  },
  {
    id: "t",
    name: "metric ton",
    symbol: "t",
    type: "weight",
    conversion_rate: 2204.62,
    name_plural: "metric tons",
    symbol_plural: "t",
    legacy: "t",
  },
  {
    id: "oz",
    name: "fluid ounce",
    symbol: "oz",
    type: "volume",
    conversion_rate: 1,
    name_plural: "fluid ounces",
    symbol_plural: "oz",
    legacy: "ounces",
  },
  {
    id: "pt",
    name: "pint",
    symbol: "pt",
    type: "volume",
    conversion_rate: 16,
    name_plural: "pints",
    symbol_plural: "pt",
  },
  {
    id: "qt",
    name: "quart",
    symbol: "qt",
    type: "volume",
    conversion_rate: 32,
    name_plural: "quarts",
    symbol_plural: "qt",
  },
  {
    id: "gal",
    name: "gallon",
    symbol: "gal",
    type: "volume",
    conversion_rate: 128,
    name_plural: "gallons",
    symbol_plural: "gal",
    legacy: "gallons",
  },
  {
    id: "mL",
    name: "milliliter",
    symbol: "mL",
    type: "volume",
    conversion_rate: 0.033814,
    name_plural: "milliliters",
    symbol_plural: "mL",
    legacy: "milliliters",
  },
  {
    id: "L",
    name: "liter",
    symbol: "L",
    type: "volume",
    conversion_rate: 33.814,
    name_plural: "liters",
    symbol_plural: "L",
    legacy: "liters",
  },
  {
    id: "yd^3",
    name: "cubic yard",
    symbol: "yd^3",
    type: "volume",
    conversion_rate: 25852.6753,
    name_plural: "cubic yards",
    symbol_plural: "yd^3",
    legacy: "cubic yards",
  },
  {
    id: "acre-inch",
    name: "acre-inch",
    symbol: "acre-inch",
    type: "volume",
    conversion_rate: 3475748.57,
    name_plural: "acre-inches",
    symbol_plural: "acre-inches",
  },
  {
    id: "acre-foot",
    name: "acre-foot",
    symbol: "acre-foot",
    type: "volume",
    conversion_rate: 4.17089829e7,
    name_plural: "acre-feet",
    symbol_plural: "acre-feet",
  },
  {
    id: "cubic-foot",
    name: "cubic foot",
    symbol: "cubic-foot",
    type: "volume",
    conversion_rate: 957.506,
    name_plural: "cubic feet",
    symbol_plural: "cubic-feet",
  },
  {
    id: "seed",
    name: "seed",
    symbol: "seed",
    type: "count",
    conversion_rate: 1,
    name_plural: "seeds",
    symbol_plural: "seeds",
    for: "seed",
  },
  {
    id: "1k seed",
    name: "1,000 seed",
    symbol: "1k seed",
    type: "count",
    conversion_rate: 1000,
    name_plural: "1,000 seed",
    symbol_plural: "1k seed",
    for: "seed",
  },
  {
    id: "10k seed",
    name: "10,000 seed",
    symbol: "10k seed",
    type: "count",
    conversion_rate: 10000,
    name_plural: "10,000 seed",
    symbol_plural: "10k seed",
    for: "seed",
  },
  {
    id: "100k seed",
    name: "100,000 seed",
    symbol: "100k seed",
    type: "count",
    conversion_rate: 100000,
    name_plural: "100,000 seed",
    symbol_plural: "100k seed",
    for: "seed",
  },
  {
    id: "1M seed",
    name: "1,000,000 seed",
    symbol: "1M seed",
    type: "count",
    conversion_rate: 1000000,
    name_plural: "1,000,000 seed",
    symbol_plural: "1M seed",
    for: "seed",
  },
  {
    id: "80k seed",
    name: "(80k) bag",
    symbol: "80k seed",
    type: "count",
    conversion_rate: 80000,
    name_plural: "(80k) bag",
    symbol_plural: "80k seed",
    for: "seed",
  },
  {
    id: "140k seed",
    name: "(140k) bag",
    symbol: "140k seed",
    type: "count",
    conversion_rate: 140000,
    name_plural: "(140k) bag",
    symbol_plural: "140k seed",
    for: "seed",
  },
  {
    id: "200k seed",
    name: "(200k) bag",
    symbol: "200k seed",
    type: "count",
    conversion_rate: 200000,
    name_plural: "(200k) bag",
    symbol_plural: "200k seed",
    for: "seed",
  },
  {
    id: "220k seed",
    name: "(220k) bag",
    symbol: "220k seed",
    type: "count",
    conversion_rate: 220000,
    name_plural: "(220k) bag",
    symbol_plural: "220k seed",
    for: "seed",
  },
  {
    id: "230k seed",
    name: "(230k) bag",
    symbol: "230k seed",
    type: "count",
    conversion_rate: 230000,
    name_plural: "(230k) bag",
    symbol_plural: "230k seed",
    for: "seed",
  },
  {
    id: "600k seed",
    name: "(600k) bag",
    symbol: "600k seed",
    type: "count",
    conversion_rate: 600000,
    name_plural: "(600k) bag",
    symbol_plural: "600k seed",
    for: "seed",
  },
  {
    id: "750k seed",
    name: "(750k) bag",
    symbol: "750k seed",
    type: "count",
    conversion_rate: 750000,
    name_plural: "(750k) bag",
    symbol_plural: "750k seed",
    for: "seed",
  },
  {
    id: "per-second",
    name: "per second",
    symbol: "per-second",
    type: "rate-time",
    conversion_rate: 1,
    name_plural: "per second",
    symbol_plural: "per-second",
  },
  {
    id: "per-minute",
    name: "per minute",
    symbol: "per-minute",
    type: "rate-time",
    conversion_rate: 60,
    name_plural: "per minute",
    symbol_plural: "per-minute",
  },
  {
    id: "per-hour",
    name: "per hour",
    symbol: "per-hour",
    type: "rate-time",
    conversion_rate: 3600,
    name_plural: "per hour",
    symbol_plural: "per-hour",
  },
  {
    id: "per-day",
    name: "per day",
    symbol: "per-day",
    type: "rate-time",
    conversion_rate: 86400,
    name_plural: "per day",
    symbol_plural: "per-day",
  },
  {
    id: "total",
    name: "total",
    symbol: "total",
    type: "rate",
    conversion_rate: null,
    name_plural: "total",
    symbol_plural: "total",
  },
  {
    id: "per-acre",
    name: "per-acre",
    symbol: "per-acre",
    type: "rate-area",
    conversion_rate: null,
    name_plural: "per-acre",
    symbol_plural: "per-acre",
  },
  {
    id: "second",
    name: "second",
    symbol: "second",
    type: "duration",
    conversion_rate: 1,
    name_plural: "seconds",
    symbol_plural: "seconds",
  },
  {
    id: "minute",
    name: "minute",
    symbol: "minute",
    type: "duration",
    conversion_rate: 60,
    name_plural: "minutes",
    symbol_plural: "minutes",
  },
  {
    id: "hour",
    name: "hour",
    symbol: "hour",
    type: "duration",
    conversion_rate: 3600,
    name_plural: "hours",
    symbol_plural: "hours",
  },
  {
    id: "day",
    name: "day",
    symbol: "day",
    type: "duration",
    conversion_rate: 86400,
    name_plural: "days",
    symbol_plural: "days",
  },
];

let INPUTS = {
  seed: [
    "seed",
    "1k seed",
    "10k seed",
    "100k seed",
    "80k seed",
    "140k seed",
    "200k seed",
    "220k seed",
    "230k seed",
    "600k seed",
    "750k seed",
    "1M seed",
    "lb",
    "kg",
    "cwt",
    "ton",
    "t",
    "total",
    "per-acre",
  ],
  chemical: ["lb", "cwt", "ton", "g", "kg", "t", "oz", "pt", "qt", "gal", "mL", "L", "yd^3", "total", "per-acre"],
  fertilizer: ["lb", "cwt", "ton", "g", "kg", "t", "oz", "pt", "qt", "gal", "mL", "L", "yd^3", "total", "per-acre"],
  water: [
    "acre-inch",
    "acre-foot",
    "cubic-foot",
    "gal",
    "L",
    "total",
    "per-second",
    "per-hour",
    "per-minute",
    "per-day",
    "second",
    "minute",
    "hour",
    "day",
  ],
};

let LOOKUP = {
  ounces: "oz",
  "fl oz": "oz",
  gallons: "gal",
  liters: "L",
  "cubic yards": "yd^3",
  pounds: "lb",
};

let RATES = { "per-acre": "per acre", total: "total" };

const setUnits = (data) => {
  UNITS = data.units;
  INPUTS = data.inputs;
  LOOKUP = data.lookup;
  RATES = data.rates;

  _.each(UNITS, function (u) {
    _.irregular(u.name, u.name_plural);
    return _.irregular(u.symbol, u.symbol_plural);
  });
};

ajax({
  url: `/list/units`,
  type: "GET",
}).then(setUnits);

// Conversion rates from:
// http://en.wikipedia.org/wiki/United_States_customary_units
export const getUnitTypes = () => {
  return _(UNITS).map("type").uniq().value();
};

export const get = (symbol) => {
  const unit =
    symbol &&
    _.find(
      UNITS,
      (unit) =>
        unit.symbol === _.singularize(symbol) ||
        unit.legacy === symbol ||
        unit.legacy === _.singularize(symbol.toLowerCase()) ||
        unit.legacy === symbol.toLowerCase()
    );

  if (!unit) {
    throw new Error(`Unit '${symbol}' not found.`);
  }
  return unit;
};

export const convert = (amount, amount_unit, unit) => {
  if (!amount || !amount_unit || !unit) {
    return 0;
  }
  amount_unit = get(amount_unit);
  unit = get(unit);
  if ((unit != null ? unit.type : undefined) !== (amount_unit != null ? amount_unit.type : undefined)) {
    return 0;
  }

  const base = amount * amount_unit["conversion_rate"]; // convert to base
  return base / unit["conversion_rate"];
};
