/**
 * Filters an array of activities by product id.
 *
 * @param {Activity[]} activities
 * @param {Object} filters
 * @param {Number[]} filters.products
 * @param {Function} markAsApplied
 * @return {Activity[]}
 */
const productFilter = (activities, { products }, markAsApplied) => {
  if (products.length > 0) {
    const productIdFilterMap = products.reduce((map, productId) => map.set(productId, true), new Map());
    const filteredActivityMap = new Map();

    activities.forEach((activity) => {
      activity.inputs.forEach((input) => {
        if (productIdFilterMap.has(input.product.id)) {
          filteredActivityMap.set(activity.id, activity);
        }
      });
    });

    activities = Array.from(filteredActivityMap.values());
    markAsApplied();
  }

  return activities;
};

export default productFilter;
