import ContractsPage from "./pages/ContractsPage";
import React from "react";

import App from "layout/app";
import SubRoute from "lib/SubRoute";

const ContractsRouter = SubRoute.extend({
  routes: {
    "": "index",
    "*notFound": "redirect",
  },

  index() {
    return App.display(ContractsPage, {
      screenName: "Contracts Page",
    });
  },

  redirect() {
    window.location.href = "#contracts";
  },
});

export default () => new ContractsRouter("contracts", { createTrailingSlashRoutes: false });
