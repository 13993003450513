import { gql } from "@apollo/client";

import { COMMODITY } from "collection/graphql/commodities/fragments";
import { BID_FRAGMENT } from "collection/graphql/marketing";

export const COUNTY_FRAGMENT = gql`
  fragment County on County {
    id
    name
    fips
    stateName
    stateFips
    stateAbbr
  }
`;

const GET_BIDS_BY_BUYER = gql`
  query getBidsByBuyer($buyers: [Int]!) {
    bids: getBidsByBuyer(buyers: $buyers) {
      basis
      bidType
      buyer {
        acceptsOfferDelivery
        address
        city
        distance
        id
        latitude
        longitude
        name
        phone
        state
        zip
      }
      delivery
      price
      underlying {
        commodity {
          ...Commodity
        }
        shortName
      }
    }
  }

  ${COMMODITY}
`;

const GET_COUNTIES = gql`
  query GetCountiesNearby {
    getCountiesNearby {
      ...County
    }
  }
  ${COUNTY_FRAGMENT}
`;

const GET_BIDS_BY_COUNTY = gql`
  query getBidsByCounty($commodity: Int!, $counties: [String]!, $bidType: BidType) {
    bidsByCounty: getBidsByCounty(commodity: $commodity, counties: $counties, bidType: $bidType) {
      ...Bid
    }
  }
  ${BID_FRAGMENT}
`;

export { GET_BIDS_BY_BUYER, GET_COUNTIES, GET_BIDS_BY_COUNTY };
