import ActivityCropChooser from "activity/components/choosers/ActivityCropChooser";
import FieldCropAcreageList from "activity/components/choosers/FieldCropChooser/FieldCropAcreageList";
import { RegularText } from "activity/components/styledComponents";
import numeral from "numeral";
import PropTypes from "prop-types";
import React, { useContext, useRef } from "react";

import usePermissions from "hooks/usePermissions";
import useViewType from "hooks/useViewType";

import { Button } from "components/fl-ui";
import { FormGroup } from "components/fl-ui/Form";
import { Cluster } from "components/fl-ui/Layout";
import { modalContext } from "components/fl-ui/Modal/Modal";

const getCropBulkAttributes = (crop) => ({ crop, acreage: crop.acreage });

const FieldCropFormGroup = (props) => {
  const { activityType, bulkAttributes, mode, onChange, showClearButton } = props;
  const labelRef = useRef();
  const { container } = useContext(modalContext);
  const shouldScrollOnMenuOpen = useViewType() === "mobile";
  const canReadActivityTypeCosts = usePermissions().canRead("activity_type_costs");
  const { operationalCost, operationalCostRate } = activityType || {};
  const showOperationalCost = !showClearButton && canReadActivityTypeCosts && operationalCost;
  const isTotalCost = operationalCostRate === "TOTAL";

  const handleClear = (event) => {
    event.preventDefault();
    onChange({ bulkCreateAttributes: [] });
  };

  const onMenuOpen = () => {
    if (shouldScrollOnMenuOpen) {
      container.scrollTop = labelRef.current.getBoundingClientRect().y;
    }
  };

  const label = (
    <Cluster between>
      <span ref={labelRef}>Field Crops</span>

      {showClearButton && (
        <Button color="primary" link onClick={handleClear}>
          Clear selection
        </Button>
      )}

      {showOperationalCost && (
        <RegularText>
          Operational cost: ${numeral(operationalCost).format(isTotalCost ? "0,0" : "0,0.00")}
          {!isTotalCost && " / ac"}
        </RegularText>
      )}
    </Cluster>
  );

  return (
    <FormGroup label={label}>
      <ActivityCropChooser
        mode={mode}
        onChange={(cropsSelection) => {
          const bulkCreateAttributes = cropsSelection.map(
            (crop) => bulkAttributes.find(({ crop: { id } }) => id === crop.id) ?? getCropBulkAttributes(crop)
          );
          onChange({ bulkCreateAttributes });
        }}
        onMenuOpen={onMenuOpen}
        selectedCrops={bulkAttributes.map(({ crop }) => crop)}
      />

      <FieldCropAcreageList
        activityType={activityType}
        bulkAttributes={bulkAttributes}
        onChange={(bulkCreateAttributes) => onChange({ bulkCreateAttributes })}
      />
    </FormGroup>
  );
};

FieldCropFormGroup.propTypes = {
  activityType: PropTypes.shape({
    operationalCost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    operationalCostRate: PropTypes.oneOf(["PER_ACRE", "TOTAL"]),
  }),
  bulkAttributes: PropTypes.arrayOf(
    PropTypes.shape({
      acreage: PropTypes.number,
      crop: PropTypes.object,
      geometry: PropTypes.object,
      geometricCircumference: PropTypes.object,
    })
  ).isRequired,
  mode: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  showClearButton: PropTypes.bool.isRequired,
};

export default FieldCropFormGroup;
