import { saveAs } from "file-saver";
import { compact } from "lodash";
import { isNative, share } from "mobile/mobileManager";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { getSrcURL } from "components/field/utils";
import { Button } from "components/fl-ui";
import { LeftButton } from "components/fl-ui/Buttons";
import PopoverButton from "components/fl-ui/Buttons/PopoverButton";
import Dialog from "components/fl-ui/Dialog";
import { FormGroup, Input } from "components/fl-ui/Form";
import { CloseX } from "components/fl-ui/Icons";
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "components/fl-ui/Modal/Modal";

const DeleteFileDialog = ({ file, isUpdating, onCancel, updateAttachments }) => {
  return (
    <Dialog
      dialogBody="Deleting of attachments cannot be undone."
      dialogControls={
        <>
          <LeftButton onClick={onCancel}>Cancel</LeftButton>
          <Button color="danger" disabled={isUpdating} onClick={() => updateAttachments(file.id)}>
            Delete Attachment
          </Button>
        </>
      }
      dialogHeading="Are you sure you want to delete this attachment?"
    />
  );
};

const RenameFileModal = ({ file, isUpdating, onCancel, updateAttachments }) => {
  const name = file.name.substring(0, file.name.lastIndexOf(".")) || file.name;
  const extension = file.name.substring(file.name.lastIndexOf("."));
  const [filename, setFilename] = useState(name);
  const handleUpdate = async () => {
    await updateAttachments({ ...file, name: `${filename}${extension}` });
    onCancel();
  };

  return (
    <Modal>
      <ModalHeader>
        <ModalTitle>Rename attachment</ModalTitle>
        <CloseX onClick={onCancel} />
      </ModalHeader>

      <ModalBody>
        <FormGroup label="File Name">
          <Input onChange={({ target }) => setFilename(target.value)} value={filename} />
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <LeftButton disabled={isUpdating} onClick={onCancel}>
          Cancel
        </LeftButton>
        <Button color="primary" disabled={isUpdating} onClick={handleUpdate}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const FilePopoverContainer = ({ file, handlePreview, hasPreview, isUpdating, updateAttachments }) => {
  const { filepickerId, name } = file;
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const fileURL = getSrcURL(filepickerId).split("/convert")[0];

  const handleDownload = () => saveAs(fileURL, name);
  const handleShare = async () => {
    await share({
      title: name,
      text: name,
      url: fileURL,
      dialogTitle: `Share ${name}`,
    });
  };

  const options = compact([
    hasPreview && { icon: "newWindow", label: "Preview", onSelect: handlePreview },
    isNative() && { label: "Share", onSelect: handleShare },
    !isNative() && { label: "Download", onSelect: handleDownload },
    { label: "Rename", onSelect: () => setShowModal(true) },
    { label: "Delete", onSelect: () => setDeleteDialog(true), color: "danger" },
  ]);

  return (
    <>
      <PopoverButton options={options} />
      {deleteDialog && (
        <DeleteFileDialog
          file={file}
          isUpdating={isUpdating}
          onCancel={() => setDeleteDialog(false)}
          updateAttachments={updateAttachments}
        />
      )}
      {showModal && (
        <RenameFileModal
          file={file}
          isUpdating={isUpdating}
          onCancel={() => setShowModal(false)}
          updateAttachments={updateAttachments}
        />
      )}
    </>
  );
};

FilePopoverContainer.propTypes = {
  file: PropTypes.shape({
    filepickerId: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  handlePreview: PropTypes.func.isRequired,
  hasPreview: PropTypes.bool,
  isUpdating: PropTypes.bool,
  updateAttachments: PropTypes.func.isRequired,
};

export default FilePopoverContainer;
