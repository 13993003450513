import createReactClass from "create-react-class";
import $ from "jquery";
import PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";

export default createReactClass({
  displayName: "BackboneView",

  propTypes: {
    // instance of a pre-render()ed Backbone view
    view: PropTypes.object.isRequired,
  },

  getDefaultProps() {
    return { view: null };
  },

  componentDidMount() {
    this.$el = $(ReactDOM.findDOMNode(this));
    return this.renderView();
  },

  componentDidUpdate() {
    return this.renderView();
  },

  componentWillUnmount() {
    // trigger "remove" event for views
    return this.$el.empty();
  },

  renderView() {
    return this.$el.html(this.props.view.el);
  },

  render() {
    return <span />;
  },
});
