import { gql } from "@apollo/client";

const getFieldStats = gql`
  query getFieldStats {
    fields: getFields {
      ...FieldStatsFragment
    }
  }
`;

export default getFieldStats;
