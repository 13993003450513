import { gql } from "@apollo/client";

import { FieldGroup } from "collection/graphql/fieldGroups/fragments";

const getFieldGroups = gql`
  query getFieldGroups {
    fieldGroups: getFieldGroups {
      ...FieldGroup
    }
  }
  ${FieldGroup}
`;

export default getFieldGroups;
