import { DatePicker } from "@farmlogs/fl-ui";
import CheckedOption from "activity/components/choosers/options/CheckedOption";
import withPersonList from "activity/hoc/withPersonList";
import CategorizedChooser from "loads/components/CategorizedChooser";
import { debounce, pick } from "lodash";
import React from "react";
import ReactSelect from "react-select";
import { Col, Row } from "react-styled-flexboxgrid";
import WarningBanner from "settings/components/WarningBanner";

import GlobalContextProvider from "context/GlobalContextProvider";

import CommodityChooser from "components/commodity/CommodityChooser";
import { FormGroup, Input, TextArea } from "components/fl-ui/Form";
import { CloseX } from "components/fl-ui/Icons";
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "components/fl-ui/Modal/Modal";
import ModalActionButtonGroup from "components/fl-ui/Modal/ModalActionButtonGroup";

const customComponents = {
  IndicatorSeparator: () => null,
  Placeholder: () => null,
  Option: (props) => <CheckedOption {...props}>{props.data.name}</CheckedOption>,
};

const formatNumericValue = (value) => Math.abs(parseFloat(value)) || null;

const InventoryTransactionModal = ({
  data: {
    amount,
    amount_unit,
    commodity_id,
    date,
    destination_id,
    id,
    moisture,
    notes,
    person_id,
    price,
    source_id,
    ticket_number,
  },
  categorizedOptions,
  error,
  hasBuyer,
  hideModal,
  isSaving,
  onClose,
  onDelete,
  onSave,
  personList,
  setSubModal,
  units,
  updateData,
}) => {
  const disabled = !commodity_id || !amount || !amount_unit || !date;

  /*
   * GlobalContextProvider is only needed while this modal is being spawned from a page
   * outside of the typical tree in app.jsx; namely on the storage > bin detail page. Once
   * we migrate away from Backbone views we no longer need to wrap this modal in
   * the GlobalContextProvider
   */
  return (
    <GlobalContextProvider>
      <Modal hideModal={hideModal}>
        <ModalHeader>
          <ModalTitle>{id ? "Edit" : "Log"} Load</ModalTitle>
          <CloseX onClick={onClose} />
        </ModalHeader>

        <ModalBody>
          {error && <WarningBanner message={error} />}

          <FormGroup label="Commodity">
            <CommodityChooser
              commodityId={commodity_id}
              onChange={({ id }) => {
                const payload = { commodity_id: id };

                if (source_id && categorizedOptions?.crops?.some(({ value }) => value === source_id)) {
                  payload.source_id = null;
                }

                updateData(payload);
              }}
            />
          </FormGroup>

          <Row>
            <Col xs={12} sm={6}>
              <FormGroup label="Amount">
                <Input
                  defaultValue={amount}
                  name="amount"
                  onChange={debounce((__, { amount }) => updateData({ amount: formatNumericValue(amount) }), 500)}
                  size="large"
                  type="number"
                  min="0"
                />
              </FormGroup>
            </Col>

            <Col xs={12} sm={6}>
              <FormGroup label="Unit">
                <ReactSelect
                  components={customComponents}
                  isSearchable={false}
                  options={units}
                  getOptionLabel={({ value }) => value}
                  getOptionValue={({ value }) => value}
                  onChange={({ value }) => updateData({ amount_unit: value })}
                  value={units.find(({ value }) => value === amount_unit)}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={6}>
              <FormGroup label="Source">
                <CategorizedChooser
                  categorizedOptions={pick(categorizedOptions, ["storage", "crops"])}
                  isDisabled={!commodity_id}
                  placeholder="Choose source"
                  onAddNewItem={(category) => setSubModal(category)}
                  onChange={({ value }) => updateData({ source_id: value })}
                  value={source_id}
                />
              </FormGroup>
            </Col>

            <Col xs={12} sm={6}>
              <FormGroup label="Destination">
                <CategorizedChooser
                  categorizedOptions={pick(categorizedOptions, ["storage", "buyers"])}
                  onAddNewItem={(category) => setSubModal(category)}
                  onChange={({ value }) => {
                    const payload = { destination_id: value };
                    if (!categorizedOptions.buyers?.some((buyer) => buyer.value === value)) {
                      payload.price = null;
                    }

                    updateData(payload);
                  }}
                  placeholder="Choose destination"
                  value={destination_id}
                />
              </FormGroup>
            </Col>
          </Row>

          <FormGroup label="Person">
            <ReactSelect
              components={customComponents}
              isSearchable={false}
              options={personList}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              onChange={({ id }) => updateData({ person_id: id })}
              value={personList.find(({ id }) => id === person_id)}
            />
          </FormGroup>

          <Row>
            {hasBuyer && (
              <Col xs={12} sm={6}>
                <FormGroup label="Price (optional)">
                  <Input
                    defaultValue={price}
                    name="price"
                    onChange={debounce((__, { price }) => updateData({ price: formatNumericValue(price) }), 500)}
                    prefix="$"
                    size="large"
                    suffix={amount_unit ? `/ ${amount_unit}` : ""}
                    type="number"
                    min="0"
                  />
                </FormGroup>
              </Col>
            )}

            <Col xs={12} sm={hasBuyer ? 6 : 12}>
              <FormGroup label="Ticket # (optional)">
                <Input
                  defaultValue={ticket_number}
                  name="ticket_number"
                  onChange={debounce((__, ticketNumber) => updateData(ticketNumber), 500)}
                  size="large"
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={6}>
              <FormGroup label="Moisture content (optional)">
                <Input
                  defaultValue={moisture}
                  name="moisture"
                  onChange={debounce((__, { moisture }) => updateData({ moisture: formatNumericValue(moisture) }), 500)}
                  size="large"
                  suffix="%"
                  type="number"
                  min="0"
                />
              </FormGroup>
            </Col>

            <Col xs={12} sm={6}>
              <FormGroup label="Date">
                <DatePicker onChange={(date) => updateData({ date })} value={date} />
              </FormGroup>
            </Col>
          </Row>

          <FormGroup label="Notes (optional)">
            <TextArea defaultValue={notes} name="notes" onChange={debounce((__, notes) => updateData(notes), 500)} />
          </FormGroup>
        </ModalBody>

        <ModalFooter>
          <ModalActionButtonGroup
            apply={{ action: onSave, buttonText: "Save", disabled: disabled || isSaving }}
            cancel={{ action: onClose, buttonText: "Cancel", disabled: isSaving }}
            danger={id && { action: onDelete, buttonText: "Delete", disabled: isSaving }}
          />
        </ModalFooter>
      </Modal>
    </GlobalContextProvider>
  );
};

export default withPersonList(InventoryTransactionModal);
