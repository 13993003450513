import * as turf from "@turf/turf";
import d3 from "d3";
import leaflet from "leaflet";
import _ from "lodash";

/**
 * @typedef {Object} Geometry
 * @property {String} type
 * @property {Number[]} coordinates
 * @see {@link https://geojson.org/}
 */
/**
 * @typedef {Object} LeafletLatLong
 * @property {Number} lat
 * @property {Number} long
 * @see https://leafletjs.com/reference-1.6.0.html#latlng
 */
/**
 * @typedef GeocoderSearchResultLatLong
 * @property {Number} lat
 * @property {Number} long
 */
/**
 * @typedef GeocoderSearchResult
 * @property {GeocoderSearchResultLatLong[]} bounds
 * @property {GeocoderSearchResultLatLong} location
 */

const MAPBOX_SATELLITE = "mapbox/satellite-v9";
const MAPBOX_SATELLITE_LABELS = "mapbox/satellite-streets-v11";
const MAPBOX_STATIC_URL = "api.mapbox.com/styles/v1";
const MAPBOX_ZOOM_OFFSET = -1;
const MIN_ZOOM = 17;

/**
 * Apply a method to all coordinates in a geometry (regardless of type)
 * Creates a function that will run a given transformation on a geometry object.
 *
 * @param {Function} transformation
 * @return {Function} a function that accepts a geometry object and
 * will apply the given transformation to it.
 */
const createGeometryTransform = (transformation) => {
  /**
   * Transforms the given geometry by type. Supported types are: Feature, FeatureColletion,
   * Point, and Polygon.
   *
   * @param {Geometry} geometry
   */
  return function (geometry) {
    const { type } = geometry || {};

    switch (type) {
      case "Feature":
        return { geometry: createGeometryTransform(transformation)(geometry.geometry) };

      case "FeatureCollection":
        return {
          features: geometry.features.map(createGeometryTransform.bind(null, transformation)),
        };

      case "Point":
        return geometry;

      case "Polygon":
        return { coordinates: transformation(geometry.coordinates) };

      case "MultiPolygon":
        return { coordinates: geometry.coordinates.map(transformation) };
    }
  };
};

const roundCoordinates = function (coordinates) {
  if (coordinates.length) {
    return coordinates.map(roundCoordinates);
  }

  return Number(coordinates.toFixed(8));
};

const area = function (path) {
  let sum = 0;

  // leveraging a reduce here to shorten notation of needing previous/next values
  // this function is impure to mutate our `sum` accumulator, but shortens the method instruction
  path.reduce(function (previous, next) {
    sum += (next[0] + previous[0]) * (previous[1] - next[1]);
    return next;
  });

  return sum / 2;
};

const mapUtils = {
  calculateAcreage(polygon) {
    const raw = turf.area(polygon) * 0.000247105; // m^2 to acres
    return +raw.toFixed(1);
  },

  /**
   * Generate an object that represents the bounds for a given geometry constrained
   * by a given width and height.
   *
   * @param {Geometry} geometry
   * @param {Number} width
   * @param {Number} height
   * @return {{
   *    center: LeafletLatLong,
   *    bounds: {
   *      top_left: LeafletLatLong,
   *      bottom_right: LeafletLatLong
   *    },
   *    zoom: Number
   *  }}
   * @throws {TypeError} throws a TypeError if height or width are not numeric
   */
  fitBounds(geometry, width, height) {
    geometry = this.forceRHR(geometry);
    if (isNaN(height)) {
      throw new TypeError(`Invalid height: ${height}`);
    }
    if (isNaN(width)) {
      throw new TypeError(`Invalid width: ${width}`);
    }

    const projection = d3.geo.mercator().scale(1).translate([0, 0]);
    const bounds = d3.geo.path().projection(projection).bounds(geometry);
    const x = Math.abs(bounds[1][0] - bounds[0][0]) / width;
    const y = Math.abs(bounds[1][1] - bounds[0][1]) / height;

    const scale = 1 / Math.max(x, y);
    const zoom = Math.min(Math.floor(Math.log((scale / 256) * Math.PI * 2) / Math.LN2), MIN_ZOOM);

    const latLngBounds = d3.geo.bounds(geometry);

    const [lng, lat] = Array.from(latLngBounds[0]);
    const [lng2, lat2] = Array.from(latLngBounds[1]);

    return {
      zoom,
      center: leaflet.latLng((lat + lat2) / 2, (lng + lng2) / 2),
      bounds: {
        top_left: leaflet.latLng(lat2, lng),
        bottom_right: leaflet.latLng(lat, lng2),
      },
    };
  },

  d3BoundsToLeafletBounds(d3Bounds) {
    return leaflet.latLngBounds(d3Bounds.map((a) => a.reverse()));
  },

  getBoundsFromGeometry(geometry) {
    if (_.isArray(geometry)) {
      geometry = geometry[0];
    }
    geometry = this.forceRHR(geometry);
    return this.d3BoundsToLeafletBounds(d3.geo.bounds(geometry));
  },

  /**
   * Force right-hand rule for a given geometry.
   *
   * @param {Geometry} geometry
   * @return {Object}
   * @see https://en.wikipedia.org/wiki/Right-hand_rule#Coordinates
   */
  forceRHR(geometry) {
    const transform = createGeometryTransform((coordinates) => {
      return coordinates.map((ring) => {
        return area(ring) < 0 ? ring.slice().reverse() : ring;
      });
    });

    return _.defaultsDeep(transform(geometry), geometry);
  },

  roundGeometry(geojson) {
    return _.defaultsDeep(createGeometryTransform(roundCoordinates)(geojson), geojson);
  },

  /**
   * Parses a MapBox url to extract map metadata.
   *
   * @param {String} url A MapBox url
   * @return {Object}
   * @return {Number[]} return.center An array containing the [x, y] coordinates of the map's center.
   * @return {Number} return.scale
   * @return {Object} return.viewBox
   * @return {Number} return.viewBox.height
   * @return {Number} return.viewBox.width
   */
  parseMapboxUrlForMetadata(url) {
    const split = url.split("/");
    const [lng, lat, zoom] = Array.from(split[split.length - 2].split(",").map((param) => Number(param)));
    const viewBox = split[split.length - 1].match(/(\d+)x(\d+)/) || [0, 0, 0];

    return {
      center: [lng, lat],
      scale: (256 / Math.PI / 2) * Math.pow(2, zoom - MAPBOX_ZOOM_OFFSET),
      viewBox: {
        width: viewBox[1],
        height: viewBox[2],
      },
    };
  },

  /**
   * @param {Object} options
   * @param {Number} options.lat
   * @param {Number} options.lng
   * @param {Number} options.zoom
   * @param {Number} options.width
   * @param {Number} options.height
   * @param {'layer_only'|'sat_label'} [options.type]
   * @returns {String}
   */
  getImageUrl({ lat, lng, zoom, width, height, type }) {
    if (type === "layer_only") {
      return "";
    }

    const token = `access_token=${process.env.MAPBOX_PUBLIC_TOKEN}`;
    const retina = leaflet.Browser.retina ? "@2x" : "";
    const resource = type === "sat_label" ? MAPBOX_SATELLITE_LABELS : MAPBOX_SATELLITE;

    if (lat && lng && zoom) {
      // Compensate for mapbox zoom offset
      const route = `/${resource}/static/${lng},${lat},${zoom}/${width}x${height}`;
      return `//${MAPBOX_STATIC_URL}${route}?${token}`;
    }

    return `https://api.mapbox.com/styles/v1/${resource}/tiles/{z}/{x}/{y}${retina}?${token}`;
  },

  getThumbnail(geometry, options) {
    const { width, height } = _.defaults(options, {
      width: 150,
      height: 150,
    });

    const {
      zoom,
      center: { lat, lng },
    } = this.fitBounds(geometry, width, height);

    let formattedZoom = zoom;

    if (!_.isFinite(formattedZoom)) {
      formattedZoom = 14;
    }

    formattedZoom = Math.max(0, Math.min(formattedZoom, 19)) + MAPBOX_ZOOM_OFFSET;

    return this.getImageUrl({
      lat,
      lng,
      zoom: formattedZoom,
      width,
      height,
    });
  },

  doesNotIntersect(feature) {
    if (typeof feature !== "object") {
      throw "Feature must be an object";
    }

    let intersections = turf.kinks(feature).features;

    // clean out false positives due to rounding
    intersections = intersections.filter(
      (i1) =>
        intersections.filter((i2) => i1.geometry.coordinates.toString() === i2.geometry.coordinates.toString())
          .length === 2
    ); // 2 points constitutes an intersection

    return !intersections.length;
  },

  getCenter(feature) {
    return turf.centroid(feature);
  },

  getMaxBounds(geometry) {
    const [swLng, swLat, neLng, neLat] = Array.from(
      leaflet
        .geoJson(geometry)
        .getBounds()
        .toBBoxString()
        .split(",")
        .map((position) => Number(position).toFixed(4))
    );

    return leaflet.latLngBounds([swLat, swLng], [neLat, neLng]);
  },
};

export default mapUtils;
