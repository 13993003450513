import { useMutation } from "@apollo/client";

import { UPDATE_ACTIVITY_STATUSES } from "collection/graphql/activities/mutations";

/**
 * Returns a function which performs a bulk status update for the given activity ids.
 * @return {Function}
 */
const useBulkActivityStatusUpdate = () => {
  const mutation = useMutation(UPDATE_ACTIVITY_STATUSES)[0];

  /**
   * @param {Number[]} ids the activity ids on which to update the status.
   * @param {ActivityStatus} status the desired status value for the given activity ids.
   * @returns {Promise}
   */
  return (ids, status) =>
    mutation({
      variables: { activityIds: ids, status },
    });
};

export default useBulkActivityStatusUpdate;
