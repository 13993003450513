import React from "react";

import { Modal, ModalBody } from "components/fl-ui/Modal/Modal";

const Divider = ({ style, children }) => (
  <div>
    <div style={style}>
      <div className="divider" />
    </div>
    {children ? (
      <span>
        {children}
        <div style={style}>
          <div className="divider" />
        </div>
      </span>
    ) : undefined}
  </div>
);

const GetStartedModal = ({ onClose }) => (
  <Modal width={600}>
    <ModalBody>
      <button className="close-x" onClick={onClose}>
        {`\
  ×\
  `}
      </button>
      <div className="text-centered">
        <h2>Two Ways to Add Your Field</h2>
        <div style={{ padding: "2rem 0" }}>
          <div className="row">
            <div className="col xs-5">
              <p className="small-caps">Select A Shape</p>
              <img style={{ width: "130px" }} src="/images/map/select-shape@2x.png" />
            </div>
            <div className="col xs-2">
              <div style={{ margin: "2em 0" }}>
                <Divider style={{ marginLeft: "50%", height: "40px" }}>
                  {`\
  or\
  `}
                </Divider>
              </div>
            </div>
            <div className="col xs-5">
              <p className="small-caps">Draw Your Own</p>
              <img style={{ width: "130px" }} src="/images/map/draw-shape@2x.png" />
            </div>
          </div>
          <button onClick={onClose} className="btn btn-blue button-m">
            {`\
  Get Started\
  `}
          </button>
        </div>
      </div>
    </ModalBody>
  </Modal>
);

GetStartedModal.displayName = "GetStartedModal";

export default GetStartedModal;
