/* eslint-disable react/display-name */
import React from "react";

import useYearFieldCrops from "modules/fields/hooks/useYearFieldCrops";

import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import useFieldData from "fields/hooks/useFieldData";

const withYearFieldCrops = (Component) => (props) => {
  const { crops, loading } = useYearFieldCrops();
  const { fields } = useFieldData();

  return fields ? <Component {...props} crops={crops} fields={fields} loading={loading} /> : <LoadingWrapper />;
};

export default withYearFieldCrops;
