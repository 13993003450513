import { gql } from "@apollo/client";

export default gql`
  query getAdvisorRecommendationCommoditiesByYear($year: Int!) {
    getAdvisorRecommendationCommoditiesByYear(year: $year) {
      id
      commodityId
      name
      ordinal
    }
  }
`;
