import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import FieldCropFragment from "collection/graphql/fieldCrops/fragments/FieldCropFragment";
import createFieldCrop from "collection/graphql/fieldCrops/mutations/createFieldCrop";

const useFieldCropMutations = () => {
  const create = useMutation(createFieldCrop)[0];

  return {
    createFieldCrop: useCallback(async (input) => {
      await create({
        update(
          cache,
          {
            data: {
              createFieldCrop: { fieldCrop },
            },
          }
        ) {
          cache.modify({
            fields: {
              getFieldCrops: () => {
                cache.writeFragment({
                  data: fieldCrop,
                  fragment: FieldCropFragment,
                });
              },
            },
          });
        },

        variables: {
          input,
        },
      });
    }, []),
  };
};

export default useFieldCropMutations;
