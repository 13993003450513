import { StyleSheet, css } from "aphrodite/no-important";
import PropTypes from "prop-types";
import React from "react";

import { Mixins, Spacing, Typography } from "components/fl-ui/constants/index";

const styles = StyleSheet.create({
  radio_container: {
    marginBottom: Spacing.regular,
  },
  radio_container_inline: {
    display: "inline",
    marginBottom: 0,
  },
  flex: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  inline_flex: {
    display: "inline-flex",
  },
  radio_label: {
    marginLeft: Spacing.xsmall,
    fontSize: Typography.sizes.rg,
    lineHeight: 1.375,
  },
});

class Radio extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    if (this.props.onChange) {
      const { name, value } = e.target;
      this.props.onChange(e, { [name]: value });
    }
  }

  render() {
    const style = {
      opacity: this.props.disabled ? 0.5 : 1,
    };
    const { className, label, inline, id, ...props } = this.props;

    const input = <input {...props} type="radio" onChange={this.handleChange} style={style} id={id} />;
    return (
      <div
        className={`${css(styles.radio_container, this.props.inline && styles.radio_container_inline)} ${className}`}
      >
        {label ? (
          <label className={css(styles.flex, this.props.inline && styles.inline_flex)} htmlFor={id}>
            {input}
            <span className={css(styles.radio_label)}>{label}</span>
          </label>
        ) : (
          <span>{input}</span>
        )}
      </div>
    );
  }
}

Radio.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
};

Radio.defaultProps = {
  label: "",
  disabled: false,
  inline: false,
};

export default Radio;
