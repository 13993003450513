import moment from "moment";
import React from "react";

import { Cluster } from "components/fl-ui/Layout";
import { css, StyleSheet } from "components/fl-ui/aphrodite";
import { GreyColors } from "components/fl-ui/colors";
import { UIColors } from "components/fl-ui/colors";
import { Spacing, Typography } from "components/fl-ui/constants";
import { styles as commonStyles } from "fields/common";

const styles = StyleSheet.create({
  container: {
    fontSize: Typography.sizes.mn,
    lineHeight: "1.5em",
  },
  divider: {
    height: 35,
    width: 3,
    "> line": {
      stroke: GreyColors.smoke91,
      strokeWidth: 3,
    },
  },
  extra: {
    color: UIColors.light,
  },
  content: {
    lineHeight: 1.5,
    color: UIColors.regular,
    marginBottom: Spacing.xsmall,
  },
  value: {
    color: UIColors.dark,
  },
  noMargin: {
    marginBottom: 0,
  },
});

const RainfallForecast = ({
  dailyForecast: { rainStartTime, rainEndTime, rainStartIntensity, rainStartProbability },
}) => {
  const currentTime = moment();
  const isRainingNow = currentTime.isAfter(rainStartTime) && currentTime.isBefore(rainEndTime);
  const isRainingLater = currentTime.isBefore(rainStartTime);
  const label = isRainingNow ? "Raining now" : "Rainfall";

  const getIntensity = (amount) => {
    if (amount <= 0.098) {
      return "Light";
    } else if (amount >= 0.3) {
      return "Heavy";
    } else {
      return "Moderate";
    }
  };
  const getValue = () => {
    if (isRainingNow) {
      return `${getIntensity(rainStartIntensity)} rain until ${moment(rainEndTime).format(
        "h a"
      )} (${rainStartProbability}%)`;
    } else if (isRainingLater) {
      return `${getIntensity(rainStartIntensity)} rain at ${moment(rainStartTime).format(
        "h a"
      )} (${rainStartProbability}%)`;
    } else {
      return "0%";
    }
  };

  return (
    <Cluster between>
      <p className={css(styles.content)}>{label}</p>
      <p className={css(styles.content, styles.value)}>{getValue()}</p>
    </Cluster>
  );
};

const WindForecast = ({ weeklyForecast, dailyForecast }) => {
  const { isBeforeSunset, windGust, windGustTime } = dailyForecast;

  const windSpeed = !isBeforeSunset ? windGust : weeklyForecast[1].windGust;
  const windTime = !isBeforeSunset
    ? moment(windGustTime).format("h a")
    : moment(weeklyForecast[1].windGustTime).format("h a");

  return (
    <Cluster between>
      <p className={css(styles.content, styles.noMargin)}>Wind gusts</p>
      <p className={css(styles.content, styles.noMargin, styles.value)}>{`${windSpeed} mph at ${windTime}`}</p>
    </Cluster>
  );
};

const ForecastSummary = (props) => (
  <div>
    <h4 className={css(commonStyles.fieldCard_subHeader)}>Forecast for the next 24 hours</h4>
    <RainfallForecast {...props} />
    <WindForecast {...props} />
  </div>
);

export default ForecastSummary;
