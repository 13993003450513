import { gql } from "@apollo/client";

export default gql`
  query GetUnderlyingForCropYear($commodityId: Int!, $cropYear: Int!) {
    getUnderlyingForCropYear(commodityId: $commodityId, cropYear: $cropYear) {
      commodity {
        id
        name
      }
      symbol
      month
      year
      shortName
      fullName
      isHarvestMonth
      isFrontMonth
      isDefault
      futuresExpirationDate
      currentMarketPrice
    }
  }
`;
