import { AUTOMATIC, BASIS, MIN_MAX, MIN_MAX_AUTOMATIC } from "marketing/constants";
import contractEnums from "marketing/utils/contractEnums";
import { exists } from "marketing/utils/validators";

const { CASH_CONTRACT, CLOSED, FUTURES_CONTRACT, OPEN, OPTIONS_CONTRACT } = contractEnums;

const validateContractForm = (fields, context) => {
  const { contractType, disableExchangePricing } = context;
  const {
    basis,
    contractDate,
    contractPrice,
    contractStatus,
    contractSubtype,
    deliveryStartDate,
    deliveryEndDate,
    expirationDate,
    maxPrice,
    minPrice,
    premium,
    pricingWindowEnd,
    pricingWindowStart,
    quantity,
    revenueImpact,
    settlementPrice,
    strikePrice,
    underlying,
  } = fields;

  const isContractStatusValid = (value) => [OPEN, CLOSED].includes(value);
  const isQuantityValid = (value) => /^(0|[1-9]\d{0,9})$/.test(value);
  const isDeliveryStartDateValid = (value) => !exists(value) || new Date(value) < new Date(deliveryEndDate);
  switch (contractType) {
    case CASH_CONTRACT:
      const cashErrs = {
        contractDate: !exists(contractDate) && "Please select a date",
        contractStatus: !isContractStatusValid(contractStatus) && "required",
        contractSubtype: !exists(contractSubtype) && "Please select a contract type",
        quantity: !isQuantityValid(quantity) && "required and must be less than 1000000000",
        underlying: !disableExchangePricing && !exists(underlying) && "Please select a reference contract",
        deliveryStartDate: !isDeliveryStartDateValid(deliveryStartDate) && "must be before delivery end date",
        deliveryEndDate: !exists(deliveryEndDate) && "required",
        minPrice:
          contractSubtype === MIN_MAX || contractSubtype === MIN_MAX_AUTOMATIC
            ? !exists(minPrice) && "required"
            : false,
        maxPrice:
          contractSubtype === MIN_MAX || contractSubtype === MIN_MAX_AUTOMATIC
            ? !exists(maxPrice) && "required"
            : false,
        pricingWindowEnd:
          contractSubtype === MIN_MAX_AUTOMATIC || contractSubtype === AUTOMATIC
            ? !exists(pricingWindowEnd) && "required"
            : false,
        pricingWindowStart:
          contractSubtype === MIN_MAX_AUTOMATIC || contractSubtype === AUTOMATIC
            ? !exists(pricingWindowStart) && "required"
            : false,
        contractPrice:
          contractSubtype === BASIS ||
          contractSubtype === MIN_MAX ||
          contractSubtype === MIN_MAX_AUTOMATIC ||
          contractSubtype === AUTOMATIC
            ? false
            : !exists(contractPrice) && "required",
      };

      // the basis field is only required on BASIS contracts that are not CLOSED
      if (contractStatus !== CLOSED && contractSubtype === BASIS && !exists(basis)) {
        cashErrs.basis = "required";
      } else {
        cashErrs.basis = false;
      }

      if (contractStatus === CLOSED) {
        return {
          errors: {
            ...cashErrs,
            contractPrice: !exists(contractPrice) && "required",
          },
        };
      }
      return { errors: cashErrs };

    case FUTURES_CONTRACT:
      const futuresErrs = {
        contractDate: !exists(contractDate) && "Please select a date",
        contractStatus: !isContractStatusValid(contractStatus) && "required",
        contractSubtype: !exists(contractSubtype) && "Please select a contract type",
        quantity: !exists(quantity) && "required",
        underlying: !exists(underlying) && "Please select a reference contract",
        expirationDate: !exists(expirationDate) && "required",
        contractPrice: !exists(contractPrice) && "required",
      };

      if (contractStatus === CLOSED) {
        return {
          errors: {
            ...futuresErrs,
            settlementPrice: !exists(settlementPrice) && "required",
          },
        };
      }
      return { errors: futuresErrs };

    case OPTIONS_CONTRACT:
      const optionsErrs = {
        contractDate: !exists(contractDate) && "Please select a date",
        contractStatus: !isContractStatusValid(contractStatus) && "required",
        contractSubtype: !exists(contractSubtype) && "Please select a contract type",
        quantity: !exists(quantity) && "required",
        underlying: !exists(underlying) && "Please select a reference contract",
        expirationDate: !exists(expirationDate) && "required",
        strikePrice: !exists(strikePrice) && "required",
        premium: !exists(premium) && "required",
      };
      if (contractStatus === CLOSED) {
        return {
          errors: {
            ...optionsErrs,
            revenueImpact: !exists(revenueImpact) && "required",
          },
        };
      }
      return { errors: optionsErrs };
  }
};

export default {
  validateSync: validateContractForm,
};
