import { gql } from "@apollo/client";

import { FIELD_WITH_ATTACHMENTS } from "collection/graphql/fields/fragments";

export default gql`
  mutation updateFieldWithAttachment($field: UpdateFieldInput!) {
    updateField(field: $field) {
      ok
      field {
        ...FieldWithAttachments
      }
    }
  }
  ${FIELD_WITH_ATTACHMENTS}
`;
