import _ from "lodash";
import moment from "moment";
import numeral from "numeral";

export const allocateTypeMap = {
  COMMODITY_CROP: "Specific crop(s)",
  FIELD_CROP: "Specific field(s)",
  UNALLOCATED: "Overhead",
};

export const getCommodityOptions = (crops) => {
  return _.uniqBy(_.map(crops, "commodity"), "id").map((commodity) => ({
    ...commodity,
    label: commodity.name,
    value: commodity.id,
  }));
};

export const getFieldOptions = (crops) => {
  const ids = {};

  return crops.reduce((acc, { fieldCrops = [] }) => {
    fieldCrops.forEach(({ field }) => {
      if (!ids[field.id]) {
        ids[field.id] = true;
        acc.push({ ...field, value: field.id });
      }
    });

    return acc;
  }, []);
};

export const getFieldGroupOptions = (fields) => {
  const ids = {};

  const groups = fields.reduce((acc, { group }) => {
    if (!ids[group?.id]) {
      ids[group?.id] = true;
      acc.push({ label: group?.name || "Ungrouped", value: group?.id || "ungrouped" });
    }

    return acc;
  }, []);

  return _.sortBy(groups, ({ label }) => label.toLowerCase());
};

export const getFilteredItems = (items, { commodities, fieldGroups, fields, overhead }) => {
  const matchesCommodity = ({ commodity }) =>
    (_.isEmpty(commodities) && _.isEmpty(overhead)) || commodities.some((id) => id === commodity?.id);

  const matchesFieldGroup = ({ fieldCrops = [] }) =>
    _.isEmpty(fieldGroups) || fieldGroups.some((id) => fieldCrops.some(({ field }) => field?.group?.id === id));

  const matchesField = ({ fieldCrops = [] }) =>
    _.isEmpty(fields) || fields.some(({ id }) => fieldCrops.some(({ field }) => field.id === id));

  const matchesOverhead = ({ accountType }) =>
    (_.isEmpty(commodities) && _.isEmpty(fields) && _.isEmpty(fieldGroups) && _.isEmpty(overhead)) ||
    overhead.some((value) => value === accountType);

  return items.filter(
    (item) => matchesOverhead(item) || (matchesCommodity(item) && matchesField(item) && matchesFieldGroup(item))
  );
};

export const getFinanceNotation = (amount) => numeral(amount).format("$ (0,0.00)");

export const getProfitLossLabel = (value) => (value < 0 ? "loss" : "profit");

export const getSearchResults = (items, search) => {
  const matchesName = ({ name }) => name.toLowerCase().includes(search.toLowerCase());
  const matchesCommodityName = ({ commodity }) => commodity?.name.toLowerCase().includes(search.toLowerCase());

  return items.filter((item) => !search || matchesName(item) || matchesCommodityName(item));
};

export const getFilterCrops = (transactions) => {
  const { fieldCrops, marketedCrops } = transactions.reduce(
    (acc, { allocateCommodityCrops, allocateFieldCrops }) => {
      acc.fieldCrops = acc.fieldCrops.concat(allocateFieldCrops);
      acc.marketedCrops = acc.marketedCrops.concat(allocateCommodityCrops);

      return acc;
    },
    { fieldCrops: [], marketedCrops: [] }
  );

  return {
    fieldCrops: [...new Set(fieldCrops)],
    marketedCrops: [...new Set(marketedCrops)],
  };
};

export const getFilteredTransactions = (transactions, filters) => {
  const { accounts, commodities, endDate, excludeOverhead, fieldCrops, startDate } = filters;
  const matchesAccount = ({ account }) => _.isEmpty(accounts) || accounts.some(({ id }) => id === account.id);
  const matchesCommodity = ({ allocateCommodityCrops }) =>
    _.isEmpty(commodities) ||
    _.isEmpty(allocateCommodityCrops) ||
    commodities.some(({ id }) => allocateCommodityCrops.some((commodity) => commodity.id === id));
  const matchesEndDate = ({ date }) => !endDate || moment(date).isSameOrBefore(endDate);
  const matchesFieldCrops = ({ allocateFieldCrops }) =>
    _.isEmpty(fieldCrops) ||
    _.isEmpty(allocateFieldCrops) ||
    fieldCrops.some(({ id }) => allocateFieldCrops.some((crop) => crop.id === id));
  const matchesOverhead = ({ allocateType }) => allocateType !== "UNALLOCATED" || !excludeOverhead;
  const matchesStartDate = ({ date }) => !startDate || moment(date).isSameOrAfter(startDate);

  return transactions.filter(
    (transaction) =>
      matchesAccount(transaction) &&
      matchesCommodity(transaction) &&
      matchesEndDate(transaction) &&
      matchesFieldCrops(transaction) &&
      matchesOverhead(transaction) &&
      matchesStartDate(transaction)
  );
};

export const getSortedFieldCrops = (fieldCrops, value) =>
  _.sortBy(fieldCrops, (crop) => {
    switch (value) {
      case "crop area":
        return crop.acreage;
      case "total yield":
        return crop.yieldPerAcre;
      case "grower yield":
        return crop.growerYieldPerAcre;
      case "cost":
        return crop.costPerAcre;
      case "revenue":
        return crop.revenuePerAcre;
      case "profit / loss":
      case "p / l":
        return crop.profitPerAcre;
      case "field":
      default:
        return crop.field.name.toLowerCase();
    }
  });

export const getSortedTransactions = (transactions, sortCriteria) => {
  switch (sortCriteria) {
    case "date":
      return _.sortBy(transactions, [sortCriteria]).reverse();
    case "amountAsc":
      return _.sortBy(transactions, ({ amount }) => +amount);
    case "amountDesc":
      return _.sortBy(transactions, ({ amount }) => -amount);
  }
};

export const getTransactionsSearchResults = (transactions, search) => {
  const matchesAccountName = ({ account }) => account.name.toLowerCase().includes(search.toLowerCase());
  const matchesAllocation = ({ allocateType }) =>
    allocateTypeMap[allocateType].toLowerCase().includes(search.toLowerCase());
  const matchesAmount = ({ amount }) => parseFloat(amount).toFixed(2).includes(search);
  const matchesNotes = ({ notes }) => notes?.toLowerCase().includes(search.toLowerCase());
  const hasMatch = (data) =>
    matchesAccountName(data) || matchesAllocation(data) || matchesAmount(data) || matchesNotes(data);

  return transactions.filter((transaction) => !search || hasMatch(transaction));
};
