const validateSchema = (schema, fields) => {
  const validationResult = {
    isValid: false,
    form: fields,
    errors: {},
    errorMessages: [],
  };

  try {
    validationResult.form = schema.validateSync(fields, { abortEarly: false });
    validationResult.isValid = true;
  } catch ({ errors, inner }) {
    validationResult.errorMessages = errors;
    validationResult.errors = inner.reduce((errorsByField, { message, path }) => {
      errorsByField[path] = message;
      return errorsByField;
    }, {});
  }

  return validationResult;
};

export default validateSchema;
