import { css } from "aphrodite/no-important";
import React from "react";

import PayGateCommonBlankSlateBanner from "components/advertisements/banners/PayGateCommonBlankSlateBanner";
import styles from "components/advertisements/styles";

const RainAndHeatBlankSlateBanner = (props) => {
  props = {
    ...props,
    actionButtonText: "Try Rain & Heat History for free",
    imagePath: "images/pay_gates/banners/rain_and_heat.png",
    title: "With Rain & Heat History you can:",
  };

  return (
    <PayGateCommonBlankSlateBanner {...props}>
      <ul>
        <li className={css(styles.valueProp)}>
          See rainfall history and heat unit accumulation for each field and compare it to prior seasons and the 10-year
          average.
        </li>
        <li className={css(styles.valueProp)}>
          Receive a detailed email with each rain event showing you the fields that received the most and least
          rainfall.
        </li>
        <li className={css(styles.valueProp)}>
          Use charts to take a deep dive into the rain or heat history over any custom time period over the past 20
          years.
        </li>
        <li className={css(styles.valueProp)}>
          Rain and Heat history is available in the Lite, Essentials, and Business plans
        </li>
      </ul>
    </PayGateCommonBlankSlateBanner>
  );
};

export default RainAndHeatBlankSlateBanner;
