import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { GreyColors } from "components/fl-ui/colors";
import { BorderRadius, Spacing } from "components/fl-ui/constants";

const ContractStatus = styled(({ className, children }) => <span className={className}>{children}</span>)`
  background-color: ${GreyColors.smoke97};
  padding: ${Spacing.xxsmall};
  border-radius: ${BorderRadius.xsmall};
`;

export default ContractStatus;

ContractStatus.propTypes = {
  children: PropTypes.node,
};
