import { gql } from "@apollo/client";

import { MinimalCashContract } from "collection/graphql/contracts/fragments";

export default gql`
  query getCashContractDetails($id: Int!) {
    contract: getContract(id: $id) {
      ... on CashContract {
        ...MinimalCashContract
      }
    }
  }

  ${MinimalCashContract}
`;
