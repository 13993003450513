import translateUrl from "lib/translateUrl";

/**
 * Parses a url and calls replaceFn() if the provided url pathname starts with /app/.
 * @param {String|URL} originalUrl
 * @param {Function} replaceFn
 */
const optionallyReplaceState = (originalUrl, replaceFn) => {
  const url = new URL(originalUrl);
  if (url.pathname.match(/^\/app\//)) {
    const translatedUrl = translateUrl(url);
    const newRoute = translatedUrl.pathname.replace(/^\//, "/#") + translatedUrl.search;
    replaceFn({}, null, newRoute);
  }
};

export default optionallyReplaceState;
