import { useQuery } from "@apollo/client";
import { DatePicker } from "@farmlogs/fl-ui";
import { css } from "aphrodite/no-important";
import _ from "lodash";
import ScaleFooter from "marketing/components/ScaleFooter";
import { formStyles } from "marketing/styles";
import { EDIT_SCALE_TICKET } from "marketing/utils/contractEnums";
import titleize from "marketing/utils/titleize";
import { exists, isNumber, isInteger, isPositive } from "marketing/utils/validators";
import PropTypes from "prop-types";
import React, { useMemo, useReducer, useState } from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import router from "router";
import * as yup from "yup";

import getScaleTicket from "collection/graphql/contracts/queries/getScaleTicket";
import {
  withCreateScaleTicket,
  withEditScaleTicket,
  withDeleteScaleTicket,
  CASH_CONTRACT,
} from "collection/graphql/marketing";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import App from "layout/app";

import MarketingBlankStatePage from "components/advertisements/pages/MarketingBlankStatePage";
import Input from "components/fl-ui/Form/Input";
import { BreadCrumbHeader, Container, Content, ContentMain } from "components/fl-ui/Layout";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import ContractUnit from "components/units/ContractUnit";

const ticketIdValidation = yup
  .string()
  .matches(/^[a-zA-Z0-9]*$/)
  .max(50)
  .label("Scale ticket ID");

const scaleTicketFormReducer = (state, action) => ({
  ...state,
  ...action,
});

const withScaleTicketMutations = _.flowRight([withCreateScaleTicket, withDeleteScaleTicket, withEditScaleTicket]);

const ScaleTicketForm = withScaleTicketMutations(
  ({ action, contract, createScaleTicket, deleteScaleTicket, editScaleTicket, scaleTicket, year }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [formValues, updateForm] = useReducer(scaleTicketFormReducer, {}, () => ({
      ...(scaleTicket || {}),
    }));

    const { marketedCrop } = contract;
    const route = `marketing/${year}/${marketedCrop.id}/contracts/cash/${contract.id}`;

    const breadcrumbLinks = useMemo(() => {
      const name = marketedCrop?.name ?? marketedCrop?.commodity?.name;
      const commodityName = (name ?? "").toLowerCase();

      const breadCrumbs = [
        {
          label: `Marketing ${year}`,
          link: `#marketing/${year}`,
          enabled: true,
        },
        {
          label: titleize(commodityName ? `${commodityName} ` : " "),
          link: `#marketing/${year}/${marketedCrop.id}`,
          enabled: true,
        },
        {
          label: "Contracts",
          link: `#marketing/${year}/${marketedCrop.id}/contracts`,
          enabled: true,
        },
        {
          label: `#${contract.id}`,
          link: `#marketing/${year}/${marketedCrop.id}/contracts/cash/${contract.id}`,
          enabled: true,
        },
      ];

      if (action === EDIT_SCALE_TICKET) {
        const { ticketId } = formValues;
        breadCrumbs.push({
          label: `Scale Ticket #${ticketId || scaleTicket.id}`,
          link: "",
          enabled: false,
        });
      } else {
        breadCrumbs.push({
          label: "Log Scale Ticket",
          link: "",
          enabled: false,
        });
      }

      return breadCrumbs;
    }, [marketedCrop]);

    if (isLoading) {
      return <LoadingWrapper isLoading loadingText="Loading..." />;
    }

    const getErrors = () => {
      return {
        date: !exists(formValues.date) && "Please select a date",
        netAmount: !exists(formValues.netAmount) && "required",
      };
    };

    const hasErrors = () => {
      return !!Object.values(getErrors()).filter((e) => e).length;
    };

    const handleTicketIdChange = (e) => {
      const ticketId = e.target.value;

      try {
        ticketIdValidation.validateSync(ticketId);
        updateForm({ ticketId });
      } catch (error) {
        App.notify("Ticket ID: alphanumeric, max 50 characters");
      }
    };

    const handleMoistureChange = (e) => {
      const moisturePercentage = e.target.value;
      if (
        (isNumber(moisturePercentage) && isPositive(moisturePercentage) && parseFloat(moisturePercentage) <= 100) ||
        !exists(moisturePercentage)
      ) {
        updateForm({ moisturePercentage });
      }
    };

    const handleDockAmountChange = (e) => {
      const dockAmount = e.target.value;
      if ((isNumber(dockAmount) && isPositive(dockAmount)) || !exists(dockAmount)) {
        updateForm({ dockAmount });
      }
    };

    const handleGrossAmountChange = (e) => {
      const grossAmount = e.target.value;
      if ((isNumber(grossAmount) && isPositive(grossAmount)) || !exists(grossAmount)) {
        updateForm({ grossAmount });
      }
    };

    const handleNetAmountChange = (e) => {
      const netAmount = e.target.value;
      if ((isNumber(netAmount) && isPositive(netAmount)) || !exists(netAmount)) {
        updateForm({ netAmount });
      }
    };

    const handleNetWeightChange = (e) => {
      const netWeight = e.target.value;
      if ((isInteger(netWeight) && isPositive(netWeight)) || !exists(netWeight)) {
        updateForm({ netWeight });
      }
    };

    const handleAddTicket = async (input) => {
      try {
        setIsLoading(true);

        await createScaleTicket({
          input: {
            contractId: contract.id,
            date: input.date,
            ticketId: input.ticketId,
            moisturePercentage: input.moisturePercentage,
            grossAmount: input.grossAmount,
            dockAmount: input.dockAmount,
            netAmount: input.netAmount,
            netWeight: input.netWeight,
          },
        });

        router.navigate(route, { trigger: true });
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    const handleEditTicket = async (input) => {
      try {
        setIsLoading(true);

        await editScaleTicket({
          input: {
            id: input.id,
            date: input.date,
            ticketId: input.ticketId,
            moisturePercentage: input.moisturePercentage,
            grossAmount: input.grossAmount,
            dockAmount: input.dockAmount,
            netAmount: input.netAmount,
            netWeight: input.netWeight,
          },
        });

        router.navigate(route, { trigger: true });
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    const handleDeleteTicket = () => {
      return App.confirm({
        title: "Are you sure you want to delete the ticket?",
        message: "This will delete this ticket and all of its associated data.",
        cancel: "Cancel",
        confirm: "Delete",
        warning: true,
      }).then(async () => {
        setIsLoading(true);
        await deleteScaleTicket({ id: formValues.id });
        setIsLoading(false);
        router.navigate(route, { trigger: true });
      });
    };

    return (
      <Container>
        <BreadCrumbHeader links={breadcrumbLinks} />
        <Content>
          <ContentMain fullWidth>
            <h2>{`${action === EDIT_SCALE_TICKET ? "Edit" : "Log"} scale ticket for contract #${contract.id}`}</h2>
            <form id={contract.id}>
              <Row>
                <Col xs={12} sm={6} md={5} lg={4}>
                  <div className={css(formStyles.width100)}>
                    <label htmlFor="contract-date">
                      <div className={css(formStyles.flexAlignCenter)}>
                        <h3 className={css(formStyles.marginRight5)}>
                          Date
                          <span className={css(formStyles.infoText)}>(required)</span>
                        </h3>
                      </div>
                      <DatePicker
                        inputId="contract-date"
                        onChange={(date) => updateForm({ date })}
                        value={formValues.date}
                      />
                    </label>
                  </div>
                </Col>
                <Col xs={12} sm={6} md={5} lg={4}>
                  <div>
                    <label htmlFor="gross-amount">
                      <h3>Gross amount</h3>
                      <Input
                        id="gross-amount-field"
                        onChange={handleGrossAmountChange}
                        type="text"
                        display="block"
                        size="large"
                        value={formValues.grossAmount || ""}
                        placeholder="0"
                        suffix={<ContractUnit contract={contract} />}
                      />
                    </label>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6} md={5} lg={4}>
                  <div>
                    <label htmlFor="ticket-id">
                      <h3>Ticket ID</h3>
                      <Input
                        id="ticket-id-field"
                        onChange={handleTicketIdChange}
                        type="text"
                        display="block"
                        size="large"
                        value={formValues.ticketId || ""}
                        placeholder="0"
                        prefix="#"
                      />
                    </label>
                  </div>
                </Col>
                <Col xs={12} sm={6} md={5} lg={4}>
                  <div>
                    <label htmlFor="dock-amount">
                      <h3>Dock amount</h3>
                      <Input
                        id="dock-amount-field"
                        onChange={handleDockAmountChange}
                        type="text"
                        display="block"
                        size="large"
                        value={formValues.dockAmount || ""}
                        placeholder="0"
                        suffix={<ContractUnit contract={contract} />}
                      />
                    </label>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6} md={5} lg={4}>
                  <div>
                    <label htmlFor="moisture">
                      <h3>Moisture</h3>
                      <Input
                        id="moisture-field"
                        onChange={handleMoistureChange}
                        type="text"
                        display="block"
                        size="large"
                        value={formValues.moisturePercentage || ""}
                        placeholder="0"
                        suffix="%"
                      />
                    </label>
                  </div>
                </Col>
                <Col xs={12} sm={6} md={5} lg={4}>
                  <div>
                    <label htmlFor="net-amount">
                      <div className={css(formStyles.flexAlignCenter)}>
                        <h3 className={css(formStyles.marginRight5)}>
                          Net amount
                          <span className={css(formStyles.infoText)}>(required)</span>
                        </h3>
                      </div>
                      <Input
                        id="net-amount-field"
                        onChange={handleNetAmountChange}
                        type="text"
                        display="block"
                        size="large"
                        value={formValues.netAmount || ""}
                        placeholder="0"
                        suffix={<ContractUnit contract={contract} />}
                      />
                    </label>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6} md={5} lg={4}>
                  <div>
                    <label htmlFor="net-weight">
                      <h3>Net weight</h3>
                      <Input
                        id="net-weight-field"
                        onChange={handleNetWeightChange}
                        type="text"
                        display="block"
                        size="large"
                        value={formValues.netWeight || ""}
                        placeholder="0"
                        suffix="lbs"
                      />
                    </label>
                  </div>
                </Col>
              </Row>
            </form>
            <ScaleFooter
              year={year}
              cropId={marketedCrop.id}
              contractId={contract.id}
              action={action}
              isLoading={isLoading}
              handleDeleteTicket={handleDeleteTicket}
              handleAddTicket={handleAddTicket}
              hasErrors={hasErrors}
              handleEditTicket={handleEditTicket}
              scaleTicket={formValues}
            />
          </ContentMain>
        </Content>
      </Container>
    );
  }
);

const ScaleDetails = ({ ...props }) => {
  const hasGrainMarketing = useEnterpriseFeature("grain_marketing");
  const { action, contractId, cropId, scaleTicketId, year } = props;

  const contractQuery = useQuery(CASH_CONTRACT, {
    skip: !hasGrainMarketing,
    variables: {
      id: contractId,
    },
  });
  const contract = contractQuery.data?.contract;

  const scaleTicketQuery = useQuery(getScaleTicket, {
    skip: !hasGrainMarketing || action !== EDIT_SCALE_TICKET,
    variables: {
      id: scaleTicketId,
    },
  });

  const contractsUrl = `marketing/${year}/${cropId}/contracts`;
  if (!hasGrainMarketing) {
    return <MarketingBlankStatePage />;
  } else if (contractQuery.error) {
    App.notify("Unable to load contract data");
    router.navigate(contractsUrl, {
      replace: true,
      trigger: true,
    });
    return null;
  } else if (contract && scaleTicketQuery.error) {
    App.notify("The requested scale ticket does not exist on your account");
    const route = `${contractsUrl}/cash/${contract.id}`;
    router.navigate(route, {
      replace: true,
      trigger: true,
    });
    return null;
  }

  const loading = contractQuery.loading || scaleTicketQuery.loading;

  return (
    <LoadingWrapper isLoading={loading}>
      <ScaleTicketForm
        action={action}
        contract={contract}
        scaleTicket={scaleTicketQuery.data?.scaleTicket}
        year={year}
      />
      ;
    </LoadingWrapper>
  );
};

ScaleDetails.propTypes = {
  contractId: PropTypes.number.isRequired,
  cropId: PropTypes.number.isRequired,
  scaleTicketId: PropTypes.number,
  year: PropTypes.number.isRequired,
};

export default ScaleDetails;
