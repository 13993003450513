import { array, boolean, lazy, object, string } from "yup";

import { ESSENTIALS, LITE, PREMIUM, RAINFALL, STANDARD } from "model/Subscription/constants";

const productsAvailableMonthly = [ESSENTIALS, LITE, PREMIUM];
const productsAvailableYearly = [ESSENTIALS, LITE, PREMIUM, RAINFALL];

/**
 * @typedef CancelSubscriptionInput
 * @property {boolean} cancelAtPeriodEnd should always be true for cancellations
 * @property {{ id: string }[]} products a list of plan products
 */
const cancelSubscriptionInputSchema = object({
  cancelAtPeriodEnd: boolean().label("Cancel at period end").oneOf([true], "${path} must be true").required(),
  products: array()
    .required()
    .length(1)
    .of(
      object({
        id: string().oneOf([STANDARD], "Must subscribe to standard plan to cancel"),
      }).required()
    ),
}).required();

/**
 * @typedef PurchaseSubscriptionInput
 * @property {{ id: string }[]} products a list of plan products
 * @property {"month"|"year"} billingInterval
 * @property {string} [coupon]
 * @property {string} [stripeToken]
 */
const purchaseSubscriptionInputSchema = object({
  billingInterval: string().required().oneOf(["month", "year"]),
  coupon: string().optional(),

  products: array()
    .required()
    .length(1)
    .when("billingInterval", {
      is: (billingInterval) => billingInterval === "year",
      then: (schema) => {
        return schema.of(
          object({
            id: string().required().oneOf(productsAvailableYearly, "Product is not available yearly"),
          }).required()
        );
      },
      otherwise: (schema) => {
        return schema.of(
          object({
            id: string().required().oneOf(productsAvailableMonthly, "Product is not available monthly"),
          }).required()
        );
      },
    }),

  stripeToken: string().optional(),
}).required();

/**
 * @typedef {(CancelSubscriptionInput|PurchaseSubscriptionInput)} SubscriptionInput
 */
const subscriptionInputSchema = lazy((value) => {
  return "cancelAtPeriodEnd" in value ? cancelSubscriptionInputSchema : purchaseSubscriptionInputSchema;
});

export default subscriptionInputSchema;
