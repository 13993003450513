/**
 * Filters an array of activities by commodity id.
 *
 * @param {Activity[]} activities
 * @param {Object} filters
 * @param {Number[]} filters.crops an array of commodity ids
 * @param {Function} markAsApplied
 * @return {Activity[]}
 */
const commodityFilter = (activities, { crops }, markAsApplied) => {
  if (crops.length > 0) {
    activities = activities.filter(({ crop }) => crops.includes(crop.commodity.id));
    markAsApplied();
  }

  return activities;
};

export default commodityFilter;
