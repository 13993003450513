import ActivityCard from "activity/components/ActivityCard";
import ActivityListing from "activity/containers/ActivityListing";
import GroupedActivities from "activity/containers/GroupedActivities";
import useActivitiesProvider from "activity/hooks/useActivitiesProvider";
import useActivityFilters from "activity/hooks/useActivityFilters";
import React from "react";

import useViewType from "hooks/useViewType";
import { COMPLETED } from "lib/constants";

const ActivityIndexBody = () => {
  const { groupedActivities } = useActivitiesProvider();
  const isMobile = useViewType() === "mobile";
  const {
    filters: { sortCriteria },
  } = useActivityFilters();

  const activitiesByDate = groupedActivities.get("Date");
  const renderCards = () => activitiesByDate.map((activity) => <ActivityCard key={activity.id} activity={activity} />);
  const renderList = () => <ActivityListing activities={activitiesByDate} />;
  const renderGroups = () => (
    <>
      {Array.from(groupedActivities).map(([group, activities]) => (
        <GroupedActivities key={group} activities={activities} groupName={group === COMPLETED ? "Done" : group} />
      ))}
    </>
  );

  let viewRenderer;
  if (["date", "dateAsc"].includes(sortCriteria)) {
    viewRenderer = isMobile ? renderCards : renderList;
  } else {
    viewRenderer = renderGroups;
  }

  return <>{viewRenderer()}</>;
};

export default ActivityIndexBody;
