import { useQuery } from "@apollo/client";
import _ from "lodash";
import React from "react";

import { GET_MACHINE_DATA_PRODUCT_MATCH } from "collection/graphql/integrations/queries";

const usePossibleMatches = (matchId) => {
  const possibleMatches = useQuery(GET_MACHINE_DATA_PRODUCT_MATCH, { variables: { id: matchId } }).data?.productMatch
    .possibleMatches;
  return _.map(_.sortBy(possibleMatches, "matchScore").reverse(), "product");
};

export default usePossibleMatches;
