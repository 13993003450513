/**
 * Filters an array of activities by endDate.
 *
 * @param {Activity[]} activities
 * @param {Object} filters
 * @param {Date} filters.endDate
 * @param {Function} markAsApplied
 * @return {Activity[]}
 */
const activityTypeFilter = (activities, { endDate }, markAsApplied) => {
  if (endDate) {
    activities = activities.filter(({ date }) => endDate >= date);
    markAsApplied();
  }

  return activities;
};

export default activityTypeFilter;
