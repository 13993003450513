import ActivityCard from "activity/components/ActivityCard";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import React, { useEffect, useState } from "react";
import router from "router";

import withActivityList from "collection/graphql/activities/withActivityList";
import withViewType from "hoc/withViewType";
import useUnits from "hooks/useUnits";

import { Button } from "components/fl-ui";
import { IconContainer } from "components/fl-ui/Icons";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import { Table, Body, Row, Col } from "components/fl-ui/Table";
import Tooltip from "components/fl-ui/Tooltip";
import { UIColors } from "components/fl-ui/colors";
import BlankDetail from "fields/components/BlankDetail";
import ListWrapper from "fields/components/ListWrapper";

const ActivityRow = (props) => {
  const { activity } = props;
  const { date, id, inputs, isMachineData, statusText, type } = activity;
  const { findEnumValue } = useUnits();

  let productText = "";
  if (inputs.length > 0) {
    const filteredInputs = _.filter(inputs, ({ amount }) => !_.isNil(amount));
    productText = filteredInputs
      .map(({ amount, amountRate, amountUnit, product }) => {
        amount = numeral(amount).format("0,0");
        const unit = findEnumValue("ActivityInputAmountUnit", amountUnit);
        return `${product.name} (${amount}${amountRate === "PER_ACRE" && unit ? ` ${unit.display} / ac` : ""})`;
      })
      .join(" ");
  }

  const getStatusColor = () => {
    if (activity.isDone) {
      return { color: UIColors.green };
    } else if (activity.isOverdue || activity.isCancelled) {
      return { color: UIColors.red };
    }

    return {};
  };

  const handleClick = (event) => {
    event.preventDefault();
    router.navigate(`activity/${id}`, { trigger: true });
  };

  return (
    <Row onClick={handleClick} style={{ cursor: "pointer", borderTop: "solid 1px #eee" }}>
      <Col style={{ width: "100px" }}>{moment(date).format("MMM D, YYYY")}</Col>
      <Col style={{ width: "40px" }}>
        {isMachineData && (
          <Tooltip message="Logged from machine data">
            <IconContainer icon="integrations" size="medium" style={{ color: UIColors.green }} />
          </Tooltip>
        )}
      </Col>
      <Col style={{ width: "200px" }}>
        <div style={{ fontWeight: "500" }}>{type ? type.name : "--"}</div>
      </Col>
      <Col>{productText}</Col>
      <Col style={{ textAlign: "right", width: "100px" }}>
        <span style={getStatusColor()}>{statusText}</span>
      </Col>
    </Row>
  );
};

const ActivityList = ({ cropId, displayed, isMobile, setSelectedCropId }) => (
  <>
    {!displayed?.length && (
      <>
        <BlankDetail>
          <p>No activities yet this year</p>
        </BlankDetail>

        <div style={{ textAlign: "right" }}>
          <Button color="primary" lightWeight link onClick={() => setSelectedCropId(cropId)}>
            Log Activity
          </Button>
        </div>
      </>
    )}

    {!!displayed?.length &&
      isMobile &&
      displayed.map((activity) => <ActivityCard activity={activity} key={activity.id} />)}

    {!!displayed?.length && !isMobile && (
      <Table style={{ border: "none" }}>
        <Body>
          {displayed.map((activity, j) => (
            <ActivityRow activity={activity} key={`activity-row-${j}`} />
          ))}
        </Body>
      </Table>
    )}
  </>
);

const FieldActivitiesTable = (props) => {
  const {
    cropId,
    data: { activities, loading, refetch },
    refreshToken,
    setSelectedCropId,
    viewType,
  } = props;
  const { data: unitsData } = useUnits();
  const isMobile = viewType === "mobile";

  const [fullRefreshes, setFullRefreshes] = useState(0);
  useEffect(() => {
    if (fullRefreshes > 0) {
      refetch({
        variables: _.pick(props, ["fieldId", "cropId", "cropYear"]),
      });
    }
    setFullRefreshes(fullRefreshes + 1);
  }, [refreshToken]);

  return (
    <LoadingWrapper isLoading={loading && (!activities || !unitsData)}>
      <ListWrapper
        buttonAction={() => setSelectedCropId(cropId)}
        buttonLabel="Log Activity"
        cropId={cropId}
        isMobile={isMobile}
        list={activities || []}
        setSelectedCropId={setSelectedCropId}
      >
        {ActivityList}
      </ListWrapper>
    </LoadingWrapper>
  );
};

export default withActivityList(withViewType(FieldActivitiesTable));
