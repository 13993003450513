import { useQuery } from "@apollo/client";

import { GET_ACTIVITY_RECORDING } from "collection/graphql/activities/queries";
import { marketingClient } from "collection/graphql/client";

const convertToMPH = (speed) => speed * (3600 / 1609);

const useActivityRecording = (id, recordingData) => {
  const { data, loading } = useQuery(GET_ACTIVITY_RECORDING, {
    client: marketingClient,
    skip: !!recordingData,
    variables: { id },
  });

  const rawRecording = recordingData || data?.recording;
  const recording = rawRecording && { ...rawRecording, averageSpeed: convertToMPH(rawRecording.averageSpeed) };
  return { loading, recording };
};

export default useActivityRecording;
