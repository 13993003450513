import leaflet from "leaflet";
import _ from "lodash";
import render from "map/templates";
import moment from "moment";

import { marketingClient, restClient } from "collection/graphql/client";
import { GET_FIELD_CROPS } from "collection/graphql/fields/queries";
import { getAllNotes } from "collection/graphql/notes";
import { getAllPeople } from "collection/graphql/people/queries";
import { readCurrentCropYear } from "hooks/useCurrentCropYear";

let ZOOM = null;

const openPopup = function ({ layer }) {
  popup.setLatLng(layer.getLatLng()).setContent(render("layer.note.popup", layer.feature.properties)).openOn(this._map);
};

const closePopup = _.debounce(function (event) {
  let skip;
  let element = (event.originalEvent != null ? event.originalEvent.relatedTarget : undefined) || event.relatedTarget;

  while (element != null) {
    if (element.id === "map_canvas" || element === document) {
      break;
    } else if (/\bleaflet-(popup|marker-icon)\b/.test(element.className || element.tagName === "svg")) {
      skip = true;
      break;
    } else {
      element = element.parentNode;
    }
  }

  if (!skip) {
    this._map?.closePopup(popup);
  }
}, 500);

const popup = new (leaflet.Popup.extend({
  onAdd() {
    leaflet.Popup.prototype.onAdd.apply(this, arguments);
    return leaflet.DomEvent.on(this._container, "mouseout", closePopup, this);
  },
  onRemove() {
    leaflet.Popup.prototype.onRemove.apply(this, arguments);
    return leaflet.DomEvent.off(this._container, "mouseout", closePopup);
  },
}))({ autoClose: false });

export default new (leaflet.GeoJSON.extend({
  options: {
    pointToLayer(feature, latLng) {
      return new leaflet.Marker(latLng, {
        icon: new leaflet.DivIcon({
          iconSize: [30, 30],
          iconAnchor: [15, 5],
          className: "js-note-badge",
          html: render("layer.note.badge", _.defaults(feature.properties, { large: ZOOM })),
        }),
      });
    },
  },

  getLayerId(layer) {
    return layer.feature != null ? layer.feature.properties.id : undefined;
  },

  initialize() {
    leaflet.GeoJSON.prototype.initialize.apply(this, arguments);

    _.bindAll(this);
    this.on("mouseover", openPopup, this);
    this.on("mouseout", closePopup, this);
  },

  onAdd() {
    leaflet.GeoJSON.prototype.onAdd.apply(this, arguments);
    this._map.off("zoomend", this.onZoom);
    this._map.on("zoomend", this.onZoom);

    this.notesSubscription = restClient
      .watchQuery({
        fetchPolicy: "cache-only",
        query: getAllNotes,
      })
      .subscribe(() => {
        this.updateLayers();
      });
    this.updateLayers();

    _.once(this.onZoom)(); // Initialize the ZOOM level when added to the map
  },

  onRemove() {
    this.notesSubscription.unsubscribe();
    return leaflet.GeoJSON.prototype.onRemove.apply(this, arguments);
  },

  onZoom() {
    if (!this._map) {
      return;
    }

    if (ZOOM !== this._map.getZoom() > 13) {
      ZOOM = this._map.getZoom() > 13;
      this.updateLayers();
    }
  },

  /**
   * Notes appear on the map as a little binoculars icon. This method adds this icon
   * to the map for the current crop year if either condition is true:
   *  1. A note has a crop in the current crop year, OR
   *  2. A note has a date attribute in the current crop year.
   * @return {Promise<void>}
   */
  async updateLayers() {
    this.clearLayers();
    const year = +readCurrentCropYear();

    const {
      data: { fieldCrops },
    } = await marketingClient.query({
      query: GET_FIELD_CROPS,
      variables: { years: [new Date().getFullYear()] },
    });
    const cropIdsInYear = _.map(fieldCrops, "id");

    const {
      data: { notes },
    } = await restClient.query({
      query: getAllNotes,
    });

    const {
      data: { people },
    } = await restClient.query({ query: getAllPeople });
    notes.forEach((note) => {
      const hasCropInCurrentYear = note.cropId && cropIdsInYear.includes(note.cropId);
      const noteDateIsInCurrentYear = note.date && +note.date.substring(0, 4) === year;
      if (hasCropInCurrentYear || noteDateIsInCurrentYear) {
        this.addNote(note, people);
      }
    });
  },

  /**
   * Adds a note icon/tooltip to the map.
   * @param {Note} note
   * @param {LegacyPerson[]} people
   */
  addNote(note, people) {
    this.removeLayer(note.id);
    if (note.geometry) {
      const fullNames = note.people
        .map((id) => {
          const person = _.find(people, { id });
          return person?.fullName ?? "";
        })
        .filter((x) => x);

      this.addData({
        geometry: note.geometry,
        properties: {
          date: moment(note.date).format("L"),
          id: note.id,
          notes: note.notes,
          peopleString: fullNames.join(", "),
        },
        type: "Feature",
      });
    }
  },
}))();
