import { useMutation } from "@apollo/client";
import { capitalize } from "lodash";
import ExpenseRevenueDashboard from "profit_and_loss/components/ExpenseRevenueDashboard";
import ProfitAndLossPermissionWrapper from "profit_and_loss/components/ProfitAndLossPermissionWrapper";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { DELETE_FINANCIAL_TRANSACTIONS } from "collection/graphql/profit_and_loss/mutations";
import App from "layout/app";

import OtherExpensesBlankSlateBanner from "components/advertisements/banners/OtherExpensesBlankSlateBanner";
import OtherRevenueBlankSlateBanner from "components/advertisements/banners/OtherRevenueBlankSlateBanner";

const ExpenseRevenueDashboardContainer = ({ screenName, type }) => {
  const emptyModalData = { account: { accountType: type } };
  const [modalData, setModalData] = useState(null);
  const [selection, setSelection] = useState(new Set());
  const [deleteFinancialAccountTransactions] = useMutation(DELETE_FINANCIAL_TRANSACTIONS, {
    refetchQueries: ["GetTransactions"],
  });

  const onSelect = (selected) => {
    if (Array.isArray(selected)) {
      setSelection(new Set(selected));
    } else {
      const currentSelection = new Set(selection);
      currentSelection.has(selected) ? currentSelection.delete(selected) : currentSelection.add(selected);
      setSelection(currentSelection);
    }
  };

  const handleBulkDelete = async () => {
    const ids = [...selection].map(({ id }) => id);

    try {
      await deleteFinancialAccountTransactions({ variables: { ids } });
      onSelect([]);
    } catch (err) {
      App.notify("An error occurred, please try again later");
      throw err;
    }
  };

  const confirmDelete = () =>
    App.confirm({
      confirm: `Delete ${selection.size} ${type.toLowerCase()} ${selection.size === 1 ? "item" : "items"}`,
      message:
        "This will delete the records and remove them from any accounts they were associated with. This action cannot be undone.",
      title: `Are you sure you want to delete ${selection.size} ${type.toLowerCase()} ${
        selection.size === 1 ? "item" : "items"
      }`,
    }).then(handleBulkDelete);

  return (
    <ProfitAndLossPermissionWrapper
      banner={type === "REVENUE" ? OtherRevenueBlankSlateBanner : OtherExpensesBlankSlateBanner}
      emptyModalData={emptyModalData}
      modalData={modalData}
      onBulkDelete={confirmDelete}
      onSelect={onSelect}
      pageButton={{ action: () => setModalData(emptyModalData), buttonText: `Log ${capitalize(type)}` }}
      selection={selection}
      setModal={setModalData}
      screenName={screenName}
      type={type}
    >
      {ExpenseRevenueDashboard}
    </ProfitAndLossPermissionWrapper>
  );
};

ExpenseRevenueDashboardContainer.propTypes = {
  screenName: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default ExpenseRevenueDashboardContainer;
