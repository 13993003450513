import { gql } from "@apollo/client";

export default gql`
  fragment InputPurchase on ProductPurchase {
    id
    amountTotal
    amountUnit
    costPerUnit
    costRate
    costTotal
    cropYear
    date
    product {
      ...DetailedProduct
    }
    vendor {
      name
    }
    __typename
  }
`;
