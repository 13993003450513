/* eslint-disable react/display-name */
import Toast from "./Toast";
import _ from "lodash";
import React, { useEffect, useState } from "react";

import { useEventChannel } from "lib/events";

export default () => {
  const [toast, setToast] = useState(null);
  const channel = useEventChannel("toast");

  const remove = (identifier) => {
    setToast((toast) => (toast === identifier ? null : toast));
  };

  useEffect(() => {
    const defaultTimeout = 5000;

    const handler = _.throttle((payload) => {
      setToast(payload);
      setTimeout(() => remove(payload), defaultTimeout);
    }, defaultTimeout + 100);

    const subscriptions = [
      channel.on("error", (payload) => handler({ ...payload, status: "error" })),
      channel.on("success", (payload) => handler({ ...payload, status: "success" })),
      channel.on("warn", (payload) => handler({ ...payload, status: "warning" })),
    ];

    return () => {
      subscriptions.forEach((unsubscribe) => unsubscribe());
    };
  }, []);

  return toast ? <Toast {...toast} onDismiss={() => remove(toast)} /> : null;
};
