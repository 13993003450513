import { PrefixIcon } from "activity/components/styledComponents";
import useActivitiesProvider from "activity/hooks/useActivitiesProvider";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

import useUnits from "hooks/useUnits";

import { TagSelectGroup } from "components/fl-ui/Form";

const ActivitySourceButtons = (props) => {
  const { onChange, value } = props;
  const { allActivities } = useActivitiesProvider();

  const units = useUnits();
  const knownProviders = useMemo(() => _.keyBy(units.findEnumByName("MachineDataProvider"), "value"), [units.data]);

  const uniqueSources = useMemo(() => {
    const uniqueProviders = _.uniq(_.flatMap(allActivities, "machineDataProviders"));
    const providerWhitelist = uniqueProviders.filter((providerId) => providerId in knownProviders);
    const sortedProviders = _.sortBy(providerWhitelist, _.toUpper);

    return sortedProviders;
  }, [allActivities, knownProviders]);

  const options = useMemo(() => {
    const options = [];
    options.push({ id: "MANUAL", label: "Manually created", value: "MANUAL" });
    options.push({ id: "AAR", label: "AAR", prefix: (props) => <PrefixIcon icon="aar" {...props} />, value: "AAR" });

    uniqueSources.forEach((id) => {
      options.push({
        label: _.upperFirst(knownProviders[id].display),
        prefix: () => <PrefixIcon icon="integrations" isSelected={value.includes(id)} />,
        value: id,
      });
    });

    return options;
  }, [uniqueSources]);

  return (
    <TagSelectGroup
      id="sources"
      isMulti
      label="Activity source"
      onChange={(sources) => onChange(sources)}
      options={options}
      showBulkSelect
      value={value}
    />
  );
};

ActivitySourceButtons.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
};

ActivitySourceButtons.defaultProps = {};

export default ActivitySourceButtons;
