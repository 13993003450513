import { StyleSheet, css } from "aphrodite/no-important";
import classNames from "classnames";
import { isEmpty } from "lodash";
import React from "react";

import { UIColors } from "components/fl-ui/colors";
import { BorderRadius, Borders, Mixins, Typography, Spacing } from "components/fl-ui/constants";

const styles = StyleSheet.create({
  card: {
    borderRadius: BorderRadius.large,
    border: Borders.regular,
    background: UIColors.white,
    padding: Spacing.regular,
    "@media only screen and (min-width: 62.5em)": {
      padding: Spacing.large,
    },
    position: "relative",
  },
  card_labelled: {
    marginTop: `-${Spacing.minor}`,
  },
  cardBody: {
    ...Mixins.clearfix,
    backgroundColor: "transparent",
    position: "relative",
    height: "inherit",
    "&:not(:last-child)": {
      borderBottom: Borders.regular,
    },
    ":last-child": {
      borderBottom: "none",
    },
  },
  cardBody_padded: {
    margin: `0 ${Spacing.large}`,
    padding: `${Spacing.regular} ${Spacing.xsmall}`,
  },
  cardHeader: {
    fontSize: Typography.sizes.lg,
    fontWeight: Typography.weights.medium,
    color: Typography.colors.primary,
  },
  cardFooter: {
    marginTop: "-1px",
    padding: Spacing.xxlarge,
    backgroundColor: "transparent",
    borderTop: Borders.regular,
  },
  cardFooter_padded: {
    padding: `${Spacing.xxlarge} ${Spacing.large}`,
  },
  eCard_Label: {
    fontSize: `${Typography.sizes.xs} !important`,
    fontWeight: `${Typography.weights.medium} !important`,
    padding: `${Spacing.xsmall} ${Spacing.regular} !important`,
    textShadow: "0 1px 0 rgba(0,0,0,0.1)",
    color: `${UIColors.white} !important`,
    width: "max-content",
    height: "auto",
    lineHeight: 1,
  },
  eCard_LabelContainer: {
    display: "flex",
    justifyContent: "center",
  },
});

const Card = ({ status, ...props }) => (
  <div
    {...props}
    className={classNames(css(styles.card, !isEmpty(status) && styles.card_labelled), props.className)}
    style={props.styles}
  >
    <div className={css(styles.cardBody)}>{props.children}</div>
  </div>
);

const CardHeader = ({ className, children }) => (
  <div className={classNames(css(styles.cardHeader), className)}>{children}</div>
);

const CardFooter = ({ className, children }) => (
  <div className={classNames(`card-bottom bottom`, css(styles.cardFooter), className)}>{children}</div>
);

export { Card, CardFooter, CardHeader };
