import PropTypes from "prop-types";
import React from "react";

import { Button } from "components/fl-ui";
import { DetailsWidgetContainer, WidgetHeader } from "fields/components/styledComponents";

const DetailsWidget = ({ children, more, moreUrl, title }) => (
  <DetailsWidgetContainer>
    <WidgetHeader>
      <h2>{title}</h2>
      {more && (
        <Button color="primary" href={moreUrl} icon="chevronRight" iconRight lightWeight link size="lg">
          {more}
        </Button>
      )}
    </WidgetHeader>

    {children}
  </DetailsWidgetContainer>
);

DetailsWidget.propTypes = {
  children: PropTypes.node.isRequired,
  more: PropTypes.string,
  moreUrl: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default DetailsWidget;
