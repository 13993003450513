const useContactInfo = () => ({
  privacyUrl: "https://bushelpowered.com/policies/",
  salesEmail: "sales@bushelfarm.com",
  salesPhone: "734-431-6788",
  salesPhoneFormatted: "(734) 431-6788",
  supportEmail: "support@bushelfarm.com",
  supportPhone: "701-212-1422",
  supportPhoneFormatted: "(701) 212-1422",
  supportUrl: "https://support.bushelfarm.com/",
});

export default useContactInfo;
