import { useDemoContext } from "demo/demos/MobileSubscriptionsDemo/MobileSubscriptionsDemoContext";
import PropTypes from "prop-types";
import React from "react";

import { Button } from "components/fl-ui";

const SubscriptionPeriod = ({ period }) => {
  const map = {
    P1M: "every month",
    P2M: "every 2 months",
    P3M: "every 3 months",
    P6M: "every 6 months",
    P1W: "every week",
    P1Y: "every year",
  };

  return map[period] || period;
};

const AvailablePackages = ({ packages }) => {
  const { customerInfo, purchases, updateState } = useDemoContext();
  const purchase = async (aPackage) => {
    try {
      const result = await purchases.purchasePackage({ aPackage });
      updateState({ jsonOutput: result });
      await purchases.syncPurchases();
      refresh();
    } catch (error) {
      updateState({ jsonOutput: _.pick(error, ["code", "errorMessage", "message"]) });
    }
  };

  return (
    <table>
      <thead>
        <tr>
          <th>Package Identifier</th>
          <th>Product Identifier</th>
          <th>Price</th>
          <th>Period</th>
          <th> </th>
        </tr>
      </thead>

      <tbody>
        {_.sortBy(packages, "product.identifier").map((pkg) => {
          const { identifier, product } = pkg;
          const isActive = customerInfo?.activeSubscriptions?.includes(product.identifier);

          return (
            <tr key={product.identifier}>
              <td>{identifier}</td>
              <td>{product.identifier}</td>
              <td>
                {product.priceString} <SubscriptionPeriod period={product.subscriptionPeriod} />
              </td>
              <td>
                <Button color="primary" disabled={isActive} onClick={() => purchase(pkg)}>
                  {isActive ? "Purchased" : "Purchase"}
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

AvailablePackages.propTypes = {
  packages: PropTypes.arrayOf(PropTypes.object),
};

export default AvailablePackages;
