import { getFinanceNotation, getProfitLossLabel } from "profit_and_loss/utils";
import PropTypes from "prop-types";
import React from "react";

import PageSummary, { ResponsiveFigure } from "components/fl-ui/PageSummary";

const FarmSummary = ({ costTotal, profitTotal, revenueTotal }) => {
  const metrics = [
    { label: "Total revenue", value: getFinanceNotation(revenueTotal) },
    { label: "Total costs", value: getFinanceNotation(costTotal) },
    { label: `Total ${getProfitLossLabel(profitTotal)}`, value: getFinanceNotation(profitTotal) },
  ];

  return (
    <PageSummary>
      {metrics.map(({ label, value }) => (
        <ResponsiveFigure key={label} label={label} value={value} />
      ))}
    </PageSummary>
  );
};

FarmSummary.propTypes = {
  costTotal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  profitTotal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  revenueTotal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default FarmSummary;
