import React from "react";

import ReportsBlankSlateBanner from "components/advertisements/banners/ReportsBlankSlateBanner";
import PayGateCommonBlankStatePage from "components/advertisements/pages/PayGateCommonBlankStatePage";

const ReportsBlankStatePage = () => {
  const steps = [
    "Create reports for inputs, planted acres, field data, spraying records, and marketing data",
    "Use filters to easily customize report details",
    "Download your reports as a CSV or PDF file to easily share with partners",
  ];

  return (
    <div>
      <PayGateCommonBlankStatePage banner={ReportsBlankSlateBanner} steps={steps} />
    </div>
  );
};

export default ReportsBlankStatePage;
