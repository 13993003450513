import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { UIColors } from "components/fl-ui/colors";
import { Spacing, Typography } from "components/fl-ui/constants";

export const TrialText = styled.p`
  color: ${UIColors.green};
  display: block;
  font-size: ${Typography.sizes.rg};
  font-weight: ${Typography.weights.medium};
  margin-bottom: ${Spacing.xxsmall};
`;

const AmountPerUnit = ({ amount, className, unit }) => (
  <div className={className}>
    <strong>{amount}</strong>
    {amount !== "Free" && <small> / {unit}</small>}
  </div>
);

AmountPerUnit.propTypes = {
  amount: PropTypes.any.isRequired,
  trial: PropTypes.shape({ interval: PropTypes.string, length: PropTypes.number }),
  unit: PropTypes.string.isRequired,
};

export default AmountPerUnit;
