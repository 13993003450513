import Backbone from "backbone";
import "backbone.validation";
import _ from "underscore";

import { getUnitTypes } from "lib/units";

const validateNpk = {
  required(value, attr, computedState) {
    const { id, type, percent_nitrogen, percent_phosphate, percent_potash } = computedState;

    // We only care if its a fertilizer
    if (type !== "fertilizer") {
      return;
    }

    // we don't care about data that already exists
    if (id != null) {
      return;
    }

    // if the user has entered in one of the NPK values, then all the values should be present.
    if (_.isEmpty(value) && _.some([percent_nitrogen, percent_phosphate, percent_potash])) {
      return true;
    }
  },

  fn(value, attr, computedState) {
    const { percent_nitrogen, percent_phosphate, percent_potash } = computedState;

    if (0 > value && value > 100) {
      return "Must be between 0 and 100";
    }

    if (Number(percent_nitrogen) + Number(percent_phosphate) + Number(percent_potash) > 100) {
      return "Total percentage cannot exceed 100";
    }
  },
};

export default Backbone.Model.extend({
  urlRoot: "/api/products",

  defaults() {
    return {
      unit_type: null,
      name: null,
      type: null,
    };
  },

  validation: {
    name: {
      required(value, attr, { type }) {
        return type !== "water";
      },
    },
    type: {
      required: true,
    },
    brand: {
      required: false,
    },

    // Fertilizer and Chemicals
    density: {
      required: false,
      pattern: "number",
      min: 0,
    },

    unit_type: {
      required(value, attr, { type }) {
        return type === "chemical" || type === "fertilizer";
      },
      oneOf: getUnitTypes(),
    },

    // Fertilizers only
    percent_nitrogen: validateNpk,
    percent_phosphate: validateNpk,
    percent_potash: validateNpk,
    percent_zinc: {
      required: false,
      pattern: "number",
    },
    percent_sulfate: {
      required: false,
      pattern: "number",
    },

    // Chemicals only
    chemical_type: {
      required(value, attr, { type }) {
        return type === "chemical";
      },
      oneOf: ["adjuvant", "fungicide", "herbicide", "insecticide"],
    },

    restricted: {
      required: false,
    },

    // Seeds only
    commodity_id: {
      required(value, attr, { id, type }) {
        return !id && type === "seed";
      },
      pattern: "digits",
      range: [1, Math.pow(10, 11)],
    },

    relative_maturity: {
      required: false,
      pattern: "number",
      min: 0,
    },
  },
});
