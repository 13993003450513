import { gql } from "@apollo/client";

import { FINANCIAL_TRANSACTION_FRAGMENT } from "collection/graphql/profit_and_loss/fragments";

export default gql`
  query GetTransactions(
    $account: Int
    $accountType: FinancialAccountType
    $cropYear: Int
    $startDate: Date
    $endDate: Date
  ) {
    transactions: getFinancialAccountTransactions(
      account: $account
      accountType: $accountType
      cropYear: $cropYear
      startDate: $startDate
      endDate: $endDate
    ) {
      ...Transaction
    }
  }
  ${FINANCIAL_TRANSACTION_FRAGMENT}
`;
