import { getCurrentSubscription } from "collection/graphql/subscription/index";
import useRestQuery from "hooks/useRestQuery";

const useSubscriptionPlatform = () => {
  const sources = useRestQuery(getCurrentSubscription).data?.subscription?.sources ?? [];

  if (sources.includes("stripe")) {
    return {
      isMobilePaymentProvider: false,
      isWebPaymentProvider: true,
      storeName: "Bushel Farm",
    };
  }

  if (sources.includes("ios")) {
    return {
      isMobilePaymentProvider: true,
      isWebPaymentProvider: false,
      storeName: "Apple",
      storeUrl: "https://apps.apple.com/account/subscriptions",
    };
  }

  if (sources.includes("android")) {
    return {
      isMobilePaymentProvider: true,
      isWebPaymentProvider: false,
      storeName: "Google Play",
      storeUrl: "https://play.google.com/store/account/subscriptions",
    };
  }

  return null;
};

export default useSubscriptionPlatform;
