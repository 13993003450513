import deleteContract from "../mutations/deleteContract";
import { useMutation } from "@apollo/client";

const useDeleteContract = () => {
  const [
    performDelete,
    {
      client: { cache },
    },
  ] = useMutation(deleteContract);

  return async (contractId) => {
    const id = cache.identify({ __typename: "CashContract", id: contractId });
    cache.evict({ id });
    cache.gc();

    return performDelete({
      variables: {
        id: contractId,
      },
    });
  };
};

export default useDeleteContract;
