import { gql } from "@apollo/client";

import { MachineDataImportConfig } from "collection/graphql/integrations/fragments";

export default gql`
  mutation SetMachineDataImportConfig(
    $activityTypeCategories: [MachineDataImportActivityTypeCategoryConfigInput]
    $enableAutomaticFieldImport: Boolean
    $enableAutomaticImport: Boolean
    $fields: [MachineDataImportFieldConfigInput]
    $id: String!
  ) {
    setMachineDataImportConfig(
      activityTypeCategories: $activityTypeCategories
      enableAutomaticFieldImport: $enableAutomaticFieldImport
      enableAutomaticImport: $enableAutomaticImport
      fields: $fields
      id: $id
    ) {
      ok
      config {
        ...Config
      }
    }
  }
  ${MachineDataImportConfig}
`;
