import CardRow from "contracts/components/CardRow";
import ContractQuantity from "contracts/components/ContractQuantity";
import PriceWithSubtext from "contracts/components/PriceWithSubtext";
import PricingAndDeliveryModal from "contracts/modals/PricingAndDeliveryModal";
import moment from "moment/moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";

import useEnterpriseFeature from "hooks/useEnterpriseFeature";

import { Button } from "components/fl-ui";
import { BorderRadius, Borders, Spacing } from "components/fl-ui/constants";

const PricingAndDeliverySubCard = styled(({ className, contract, priceline, totalQuantityPriced }) => {
  const elevatePlusEnterpriseEnabled = useEnterpriseFeature("elevate_plus");

  const [showModal, setShowModal] = useState(false);
  return (
    <div className={className}>
      <div>
        <CardRow title="Number">{priceline.lineNumber ?? "--"}</CardRow>
        <CardRow title="Delivery">
          {contract.deliveryStartDate && moment(contract.deliveryStartDate).format("MM/DD/YYYY - ")}
          {moment(contract.deliveryEndDate).format("MM/DD/YYYY")}
        </CardRow>
        <CardRow title="Location">{priceline?.deliveryLocation?.publicName ?? "--"}</CardRow>
        <CardRow title="Amount">
          <ContractQuantity contract={contract} quantity={priceline.quantityPriced} />
        </CardRow>
        <CardRow title="Future Price">
          <PriceWithSubtext price={priceline.futuresPrice} status={priceline.futuresPriceStatus} />
        </CardRow>
        <CardRow title="Basis">{priceline.basis ?? "--"}</CardRow>
        <CardRow title="Adjustment">{contract.adjustments ?? "--"}</CardRow>
        {elevatePlusEnterpriseEnabled && (
          <CardRow title="Addtl Adjustment">
            <PriceWithSubtext price={priceline.additionalAdjustments} status={priceline?.additionalAdjustmentsStatus} />
          </CardRow>
        )}
        <CardRow title="Cash Price">
          <PriceWithSubtext price={priceline.cashPrice} status={priceline.cashPriceStatus} />
        </CardRow>
        <CardRow title="Futures Month">{priceline.underlying ?? "--"}</CardRow>
      </div>
      <div className="btn-container">
        <Button color="primary" hollow onClick={() => setShowModal(true)} size="sm">
          Edit
        </Button>
      </div>
      {showModal && (
        <PricingAndDeliveryModal
          contract={contract}
          onClose={() => setShowModal(false)}
          priceline={priceline}
          totalQuantityPriced={totalQuantityPriced}
        />
      )}
    </div>
  );
})`
  border: ${Borders.regular};
  border-radius: ${BorderRadius.xlarge};
  padding: ${Spacing.small};
  margin: ${Spacing.small} ${Spacing.xxxsmall};

  > .btn-container {
    display: flex;
    justify-content: center;
    margin-top: ${Spacing.small};
  }
`;

export default PricingAndDeliverySubCard;

PricingAndDeliverySubCard.propTypes = {
  contract: PropTypes.object,
};
