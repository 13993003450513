import { MinimalCashContract } from "../fragments";
import { gql } from "@apollo/client";

export default gql`
  query listCashContracts(
    $ascending: Boolean
    $buyers: [String]
    $commodities: [Int]
    $contractNumber: String
    $contractStatus: ContractStatus
    $cropYears: [Int]
    $endDate: Date
    $itemsPerPage: Int
    $marketedCrops: [Int]
    $orderBy: ContractOrderBy
    $page: Int
    $startDate: Date
  ) {
    getCashContracts(
      ascending: $ascending
      buyers: $buyers
      commodities: $commodities
      contractNumber: $contractNumber
      contractStatus: $contractStatus
      cropYears: $cropYears
      itemsPerPage: $itemsPerPage
      endDate: $endDate
      marketedCrops: $marketedCrops
      orderBy: $orderBy
      page: $page
      startDate: $startDate
    ) {
      contracts {
        ...MinimalCashContract
      }
      totalContracts
      totalContractsFiltered
    }
  }

  ${MinimalCashContract}
`;
