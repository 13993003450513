import { BaseElement } from "@farmlogs/fl-ui";
import AppUserButton from "demo/demos/MobileSubscriptionsDemo/AppUserButton";
import AvailablePackages from "demo/demos/MobileSubscriptionsDemo/AvailablePackages";
import DataViewer from "demo/demos/MobileSubscriptionsDemo/DataViewer";
import LogViewer from "demo/demos/MobileSubscriptionsDemo/LogViewer";
import { useDemoContext } from "demo/demos/MobileSubscriptionsDemo/MobileSubscriptionsDemoContext";
import React from "react";
import ReactJsonView from "react-json-view";
import styled from "styled-components";

const CurrentOffering = ({ offerings }) => {
  return (
    <div>
      <h2>{offerings.current.identifier}</h2>
      <AvailablePackages packages={offerings.current.availablePackages} />
    </div>
  );
};

const DebuggingOutput = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(2, 1fr);

  > :last-child {
    min-height: 65vh;
    overflow-y: scroll;
  }
`;

const MobileDemoPage = () => {
  const { isConfigured, jsonOutput, offerings } = useDemoContext();

  return (
    <BaseElement display="flex" flexDirection="column" gap="8px">
      <BaseElement alignItems="center" display="flex" justifyContent="space-between">
        <h2>RevenueCat test page</h2>

        <AppUserButton />
      </BaseElement>

      {isConfigured && (
        <>
          <hr />
          <DataViewer />
        </>
      )}

      {offerings && (
        <>
          <hr />
          <CurrentOffering offerings={offerings} />
        </>
      )}

      <hr />

      <DebuggingOutput>
        {!jsonOutput ? (
          <div>No JSON output</div>
        ) : (
          <ReactJsonView enableClipboard src={jsonOutput} theme="shapeshifter:inverted">
            {JSON.stringify(jsonOutput, null, 2)}
          </ReactJsonView>
        )}

        <LogViewer autoScroll />
      </DebuggingOutput>
    </BaseElement>
  );
};

export default MobileDemoPage;
