import SentryInfoError from "lib/errors/SentryInfoError";

/**
 * Extends {@link SentryInfoError} to provide context relevant to general subscription
 * save errors on native platforms.
 *
 * @extends SentryInfoError
 */
class MobilePaymentError extends SentryInfoError {
  name = "MobilePaymentError";
}

export default MobilePaymentError;
