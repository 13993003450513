import { gql } from "@apollo/client";

export default gql`
  fragment AbbreviatedCashContract on CashContract {
    buyer
    contractNumber
    contractSubtype @client
    contractType
    dataSource
    deliveryEndDate
    deliveryStartDate
    commodity {
      id
      name
    }
    id
    marketedCrop {
      id
    }
    netPrice
    quantity
    quantityPriced
    quantityUnit
  }
`;
