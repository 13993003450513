import _ from "lodash";
import React, { useContext } from "react";

import Select from "components/fl-ui/Form/Select";
import FieldHeatContext from "fields/components/context/FieldHeatContext";

const formulaOptions = Object.freeze([
  { id: 1, tbase: 60, tceil: undefined, label: "GDD 60" },
  { id: 2, tbase: 55, tceil: undefined, label: "GDD 55" },
  { id: 3, tbase: 50, tceil: 86, label: "GDD 50/86" },
  { id: 4, tbase: 45, tceil: undefined, label: "GDD 45" },
  { id: 5, tbase: 44, tceil: undefined, label: "GDD 44" },
  { id: 6, tbase: 41, tceil: undefined, label: "GDD 41" },
  { id: 7, tbase: 40, tceil: undefined, label: "GDD 40" },
  { id: 8, tbase: 35, tceil: undefined, label: "GDD 35" },
  { id: 9, tbase: 32, tceil: undefined, label: "GDD 32" },
]);

export const getDefaultFormula = () => _.find(formulaOptions, { id: 3 });

/**
 * @see https://farmlogs.atlassian.net/browse/CORE-4413
 * @see https://farmlogs.atlassian.net/wiki/spaces/WEBSPECS/pages/1580859393/Growing+Degree+Days
 */
const HeatDropdown = () => {
  const {
    dispatch,
    state: { tbase, tceil },
  } = useContext(FieldHeatContext);
  const { id } = _.find(formulaOptions, { tbase, tceil }) || getDefaultFormula();

  const options = formulaOptions.map(({ id, label }) => {
    return { value: id, label: label };
  });

  const handleChange = (event, { temperatureRange }) => {
    const id = +temperatureRange;
    const newValue = _.find(formulaOptions, { id });
    dispatch({
      type: "setHeatRange",
      tbase: newValue.tbase,
      tceil: newValue.tceil || undefined,
    });
  };

  return (
    <div>
      <Select name="temperatureRange" onChange={handleChange} options={options} value={id} />
    </div>
  );
};

export default HeatDropdown;
