import Backbone from "backbone";
import render from "ui/templates";
import _ from "underscore";

export default Backbone.View.extend({
  events: {
    remove: "remove",
  },

  render() {
    this.$el.html(render("stats", this.serializeData()));
    return this;
  },

  initialize() {
    return (this.data = {});
  },

  setData(key, value) {
    this.data[key] = value;

    return this;
  },

  serializeData() {
    _.extend(this.data);
    return {
      stats: this.getData("stats"),
      yield_stats: this.getData("yield_stats"),
      budget_stats: this.getData("budget_stats"),
    };
  },

  getData(key) {
    return this.data[key];
  },
});
