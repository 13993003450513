import { css } from "aphrodite";
import useSubscriptionPricing from "billing/hooks/useSubscriptionPricing";
import { styles } from "billing/lib";
import dollarFormat from "marketing/utils/dollarFormat";
import React from "react";
import { useFormContext } from "react-hook-form";

const OrderSummaryCopy = () => {
  const form = useFormContext();
  const [billingInterval, selectedPlanId] = form.watch(["billingInterval", "selectedPlanId"]);
  const { subtotal } = useSubscriptionPricing(selectedPlanId, billingInterval);
  const period = billingInterval === "month" ? "monthly" : "yearly";

  return (
    <div className={css(styles.orderSummaryCopy)} data-cy="orderSummaryCopy">
      <p>
        <strong>
          You will be billed {dollarFormat(subtotal)} {period} until you cancel your subscription.
        </strong>
        You will not be billed for a plan today unless you have already used a free trial. Subscriptions are
        non-refundable during your selected billing cycle. You may cancel or make changes to your subscription at any
        time. Changes will go into effect immediately.
      </p>

      <p>
        You will continue to have access to your data for any features not covered by your plan. You can change or
        cancel your subscription at any time.
      </p>
    </div>
  );
};

export default OrderSummaryCopy;
