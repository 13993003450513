import PropTypes from "prop-types";
import React, { useMemo } from "react";
import ReactSelect from "react-select";

import useCropYears from "collection/graphql/fields/hooks/useCropYears";

import { chooserStyles } from "components/fl-ui/Chooser";

const CropYearDropdown = ({ disabled = false, isOptionDisabled, id, onBlur, onChange, placeholder, value }) => {
  const cropYears = useCropYears();
  const options = useMemo(() => {
    return cropYears.map((cropYear) => ({
      label: cropYear.year,
      value: cropYear.year,
    }));
  }, [cropYears]);

  const selectedOption = options.find((option) => option.value === value);

  return (
    <ReactSelect
      inputId={id}
      isDisabled={disabled}
      isOptionDisabled={isOptionDisabled}
      onBlur={onBlur}
      onChange={({ value }) => onChange(value)}
      options={options}
      placeholder={placeholder}
      styles={chooserStyles}
      value={selectedOption}
    />
  );
};

CropYearDropdown.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.number,
};

export default CropYearDropdown;
