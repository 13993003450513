import { onError } from "@apollo/client/link/error";
import * as Sentry from "@sentry/react";

export class ApolloError extends Error {
  name = "ApolloError";
}

export default onError((error) => {
  const { graphQLErrors, operation } = error;

  if (graphQLErrors) {
    const { operationName, query, variables } = operation;
    const operationType = query.definitions.find(({ kind }) => kind === "OperationDefinition")?.operation ?? "query";

    graphQLErrors.map(({ message }) => {
      const apolloError = new ApolloError(`[GraphQL error]: Message: ${message}`);
      console.error(apolloError);

      Sentry.configureScope((scope) => {
        scope.setTransactionName(operationName);
        Sentry.addBreadcrumb({
          category: `graphql.${operationType}`,
          message,
          level: "error",
          type: "error",
          data: {
            [operationType]: operationName,
            variables,
          },
        });
        Sentry.captureException(apolloError);
      });
    });
  }
});
