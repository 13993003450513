import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { Button } from "components/fl-ui";
import { Spacing } from "components/fl-ui/constants";
import { BorderRadius } from "components/fl-ui/constants";

const FilterButton = styled(({ className, filterCount, onClick }) => (
  <Button className={className} color="primary" hollow={!filterCount} icon="tune" iconRight onClick={onClick}>
    <span className="filter-text-area">
      {filterCount > 0 && <span className="filter-count">({filterCount})</span>}
      <span className="filter-text">{filterCount === 1 ? "Filter" : "Filters"}</span>
    </span>
  </Button>
))`
  position: relative !important;
  border-radius: ${BorderRadius.xxxlarge} !important;
  padding: ${Spacing.minor} ${Spacing.medium};

  svg {
    padding: ${Spacing.xxxsmall};
  }

  .filter-text-area {
    .filter-count {
      padding-right: ${Spacing.xxsmall};
    }
  }
`;

FilterButton.propTypes = {
  filterCount: PropTypes.number,
  onClick: PropTypes.func,
};

FilterButton.defaultProps = {
  onClick: () => null,
};

export default FilterButton;
