import React from "react";
import styled from "styled-components";

const CommoditySectionBlankSlate = styled((props) => {
  return <div {...props}>No matching commodities where found.</div>;
})`
  margin: 20px 0;
`;

export default CommoditySectionBlankSlate;
