import { gql } from "@apollo/client";

export default gql`
  mutation updateTractor($input: UpdateTractorInput!) {
    updateTractor(tractor: $input) {
      ok
      tractor {
        ...TractorDetail
      }
    }
  }
`;
