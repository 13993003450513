import { css } from "aphrodite/no-important";
import styles from "prices/charts/styles";
import { number, oneOfType, shape, string } from "prop-types";
import React from "react";

import dollarFormat from "lib/utils/dollarFormat";

const CommodityContractSummary = (props) => {
  const { commodityName } = props;
  const { current, currentOverMovingAverage20Day } = props.priceSnapshot;

  return (
    <p className={css(styles.movingAverage)}>
      {commodityName} is at {dollarFormat(current)}, {dollarFormat(Math.abs(currentOverMovingAverage20Day))}{" "}
      {currentOverMovingAverage20Day >= 0 ? "above" : "below"} the 20-day moving average
    </p>
  );
};

CommodityContractSummary.propTypes = {
  commodityName: string.isRequired,
  priceSnapshot: shape({
    current: oneOfType([string, number]).isRequired,
    currentOverMovingAverage20Day: oneOfType([string, number]).isRequired,
  }).isRequired,
};

export default CommodityContractSummary;
