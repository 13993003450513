import React, { useState } from "react";

import { useAuth } from "collection/graphql/auth";
import PasswordResetForm from "layout/components/PasswordResetForm";
import { getEmailErrors, hasErrors } from "layout/utils";

const defaultValues = {
  done: false,
  email: "",
  error: "",
  loading: false,
};

const PasswordReset = () => {
  const { getPasswordResetToken } = useAuth();
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState(defaultValues);
  const handleChange = ({ target: { value } }) => setFormValues({ ...formValues, email: value });
  const handleError = () =>
    setFormValues({ ...formValues, error: "There was an issue processing the request.", loading: false });

  const handleSuccess = () => setFormValues({ ...formValues, done: true, error: false, loading: false });
  const handleReset = (event) => {
    event.preventDefault();
    const formErrors = getEmailErrors(formValues);
    setFormErrors(formErrors);

    if (hasErrors(formErrors)) {
      return null;
    }

    setFormValues({ ...formValues, loading: true });
    return getPasswordResetToken(formValues.email).then(handleSuccess).catch(handleError);
  };

  return (
    <PasswordResetForm
      formErrors={formErrors}
      formValues={formValues}
      handleChange={handleChange}
      handleReset={handleReset}
    />
  );
};

export default PasswordReset;
