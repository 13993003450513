import _ from "lodash";
import moment from "moment";

/**
 * Determines the default gddTbase and gddTceil for a given list of crops and planting
 * activities. Values default to null if no tbase/ceil default values exist.
 *
 * If multiple crops exist, the commodity should equal the commodity on the most
 * recent planting event. If that doesn't exist we'll just default to the last
 * commodity in the crops array.
 *
 * @see https://farmlogs.atlassian.net/browse/CORE-4612
 *
 * @param {Object[]} crops
 * @param {Object[]} plantingActivities
 * @return {HeatRangeDefaults}
 *
 * @typedef {Object} HeatRangeDefaults
 * @property {Number|null} gddTbase the default tbase value
 * @property {Number|null} gddTceil the default tceil value
 */
const getGDDDefaults = ({ crops, plantingActivities }) => {
  let commodity = _.last(crops)?.commodity ?? {};

  if (crops?.length > 1) {
    const today = moment().format("YYYY-MM-DD");
    const pastActivities = _.filter(plantingActivities, ({ date }) => date <= today);
    const mostRecentPlantingActivity = _.maxBy(pastActivities, "date");
    if (mostRecentPlantingActivity) {
      commodity = mostRecentPlantingActivity.crop.commodity;
    }
  }

  return _.pick(commodity, ["gddTbase", "gddTceil"]);
};

export default getGDDDefaults;
