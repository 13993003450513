import SentryInfoError from "lib/errors/SentryInfoError";

/**
 * An error instance that is thrown when there is a miscommunication with or invalid response
 * from the Capacitor subscriptions plugin.
 */
class SubscriptionPluginError extends SentryInfoError {
  name = "SubscriptionPluginError";
}

export default SubscriptionPluginError;
