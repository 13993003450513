import PropTypes from "prop-types";
import React from "react";

import { TagSelectGroup } from "components/fl-ui/Form";

const ContractCropYearFilter = ({ onChange, value }) => {
  const availableCropYearsList = Array.from({ length: 7 }, (_, i) => new Date().getFullYear() + 1 - i);
  return (
    <TagSelectGroup
      id="cropYearFilter"
      isMulti
      label="Crop Year"
      onChange={(cropYear) => onChange(cropYear)}
      options={availableCropYearsList.map((year) => ({
        label: year.toString(),
        value: year,
      }))}
      showBulkSelect
      value={value}
    />
  );
};

ContractCropYearFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired,
};

export default ContractCropYearFilter;
