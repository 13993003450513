import { css } from "aphrodite/no-important";
import React from "react";

import PayGateCommonBlankSlateBanner from "components/advertisements/banners/PayGateCommonBlankSlateBanner";
import styles from "components/advertisements/styles";

const SoilBlankSlateBanner = (props) => {
  props = {
    ...props,
    actionButtonText: "Try Soil Maps for free",
    imagePath: "images/pay_gates/banners/soil.png",
    title: "With Soil Maps you can:",
  };

  return (
    <PayGateCommonBlankSlateBanner {...props}>
      <ul>
        <li className={css(styles.valueProp)}>
          See each soil type area and elevation features so you can evaluate new ground and find opportunities to
          improve management on existing fields.
        </li>
        <li className={css(styles.valueProp)}>Compare soil maps side-by-side with other field layers.</li>
      </ul>
    </PayGateCommonBlankSlateBanner>
  );
};

export default SoilBlankSlateBanner;
