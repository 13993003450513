
import Hogan from 'hogan';

var templates = {};
  templates['control.toolbar'] = new Hogan.Template({code: function (c,p,i) { var t=this;t.b(i=i||"");t.b("<span style=\"margin-right: 1rem;\">");t.b("\n" + i);t.b("  <a  class=\"js-show-fields btn farm-bounds-icon\" style=\"padding: 3px 7px 6px 7px;\">");t.b("\n" + i);t.b("    <img src=\"images/map/icon-farm-bounds.png\" class=\"js-show-fields\" alt=\"Zoom to farm bounds\" style=\"width: 16px; height: 16px; position: relative; top: 3px;\" />");t.b("\n" + i);t.b("  </a>");t.b("\n" + i);t.b("  <a class=\"js-my-location btn locate-me-icon");if(t.s(t.f("geolocationDisabled",c,p,1),c,p,0,361,370,"{{ }}")){t.rs(c,p,function(c,p,t){t.b(" disabled");});c.pop();}t.b("\"");t.b("\n" + i);t.b("     style=\"padding: 3px 7px 6px 7px;\">");t.b("\n" + i);t.b("    <img src=\"images/map/icon-locate-me.png\" class=\"js-my-location\" alt=\"Find my current location\" style=\"width: 16px; height: 16px; position: relative; top: 3px;\" />");t.b("\n" + i);t.b("  </a>");t.b("\n" + i);t.b("</span>");t.b("\n");t.b("\n" + i);t.b("<span>");t.b("\n" + i);t.b("  <a href=\"#fields/add\" class=\"add-field btn\" style=\"padding: 8px 8px 7px;\">");t.b("\n" + i);t.b("    Add field");t.b("\n" + i);t.b("  </a>");t.b("\n" + i);t.b("</span>");t.b("\n");return t.fl(); },partials: {}, subs: {  }});
  templates['layer.field.popup'] = new Hogan.Template({code: function (c,p,i) { var t=this;t.b(i=i||"");if(t.s(t.f("field",c,p,1),c,p,0,12,557,"{{ }}")){t.rs(c,p,function(c,p,t){t.b("<div class=\"js-field-info field-info\">\r");t.b("\n" + i);t.b("    <h2>");t.b(t.v(t.f("name",c,p,0)));t.b("</h2>\r");t.b("\n" + i);t.b("    <span class=\"meta\">\r");t.b("\n" + i);t.b("        ");t.b(t.v(t.f("acreage",c,p,0)));t.b(" acres\r");t.b("\n" + i);t.b("        <br>\r");t.b("\n" + i);t.b("\r");t.b("\n" + i);if(t.s(t.f("hasCommodities",c,p,1),c,p,0,178,219,"{{ }}")){t.rs(c,p,function(c,p,t){t.b("            ");t.b(t.v(t.f("commodities",c,p,0)));t.b("\r");t.b("\n" + i);});c.pop();}if(!t.s(t.f("hasCommodities",c,p,1),c,p,1,0,0,"")){t.b("            <button class=\"js-add-crop btn btn-tiny btn-white\">Add Crop</button>\r");t.b("\n" + i);};t.b("\r");t.b("\n" + i);t.b("    </span>\r");t.b("\n" + i);t.b("    <span class=\"js-rainfall\"></span>\r");t.b("\n" + i);t.b("\r");t.b("\n" + i);t.b("    <a class=\"edit btn btn-tiny btn-white\" href=\"#map/");t.b(t.v(t.f("id",c,p,0)));t.b("/layers\">\r");t.b("\n" + i);t.b("        View<br />Layers\r");t.b("\n" + i);t.b("    </a>\r");t.b("\n" + i);t.b("</div>\r");t.b("\n" + i);});c.pop();}return t.fl(); },partials: {}, subs: {  }});
  templates['layer.note.badge'] = new Hogan.Template({code: function (c,p,i) { var t=this;t.b(i=i||"");if(t.s(t.f("large",c,p,1),c,p,0,12,1593,"{{ }}")){t.rs(c,p,function(c,p,t){if(!t.s(t.d("images.length",c,p,1),c,p,1,0,0,"")){t.b("		<img width=\"36\" src=\"/images/icons/map-note-icon.png\" alt=\"Note\" />");t.b("\n" + i);};if(t.s(t.d("images.length",c,p,1),c,p,0,148,1572,"{{ }}")){t.rs(c,p,function(c,p,t){t.b("    <img class=\"map-note-image\" width=\"30\" src=\"");t.b(t.v(t.f("firstImage",c,p,0)));t.b("\">");t.b("\n" + i);});c.pop();}});c.pop();}t.b("\n" + i);if(!t.s(t.f("large",c,p,1),c,p,1,0,0,"")){t.b("	<img src=\"images/map/scouting.png\" class=\"layer-info\" height=\"16px\" width=\"16px\" />");t.b("\n" + i);};return t.fl(); },partials: {}, subs: {  }});
  templates['layer.note.popup'] = new Hogan.Template({code: function (c,p,i) { var t=this;t.b(i=i||"");t.b("<div class=\"field-info\">");t.b("\n");t.b("\n" + i);t.b("  <p class=\"note-body\">");t.b(t.v(t.f("notes",c,p,0)));t.b("</p>");t.b("\n");t.b("\n" + i);t.b("  <span class=\"meta\">");t.b("\n" + i);t.b("  	");t.b(t.v(t.f("peopleString",c,p,0)));t.b(" -");t.b("\n" + i);t.b("  	");t.b(t.v(t.f("date",c,p,0)));t.b(" -");t.b("\n" + i);t.b("  	<a href=\"#scouting/");t.b(t.v(t.f("id",c,p,0)));t.b("\">edit</a>");t.b("\n" + i);t.b("  </span>");t.b("\n");t.b("\n" + i);t.b("</div>");t.b("\n");return t.fl(); },partials: {}, subs: {  }});

export default function(n,c,p,i){return templates[n].render(c,p||templates,i);};
