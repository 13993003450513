import systemUnitToClientUnit from "./systemUnitToClientUnit";
import { map } from "lodash";
import { useMemo } from "react";

import useUnits from "hooks/useUnits";

/**
 * Returns an array of {@link UnitOfMeasure}s for the given `unitType`.
 *
 * @param {'COUNT'|'SEED_COUNT'|'VOLUME'|'WEIGHT'} unitType
 * @return {UnitOfMeasure[]}
 */
const useUnitsOfMeasure = (unitType) => {
  const { data } = useUnits();

  return useMemo(() => {
    switch (unitType) {
      case "COUNT":
      case "SEED_COUNT":
        return map(data?.seedCountUnits, systemUnitToClientUnit);

      case "VOLUME":
        return map(data?.volumeUnits, systemUnitToClientUnit);

      case "WEIGHT":
        return map(data?.weightUnits, systemUnitToClientUnit);

      default:
        return [];
    }
  }, [data, unitType]);
};

export default useUnitsOfMeasure;
