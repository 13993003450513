import _ from "lodash";
import { useMemo } from "react";
import router from "router";

import useWindow from "hooks/useWindow";
import { parse, stringify } from "lib/queryParams";

/**
 * A hook that parses the query params of the current location into an object.
 *
 * @param {object} [options]
 * @param {boolean} [options.compact=true] removes empty arrays when setting the query string
 * @param {boolean} [options.convertTypes=true] converts numeric strings to numbers
 * @return {[Object, Function]}
 */
const useQueryParams = (options = { compact: true, convertTypes: true }) => {
  const win = useWindow();
  const pathChunks = win.location.hash.split("?");
  const path = pathChunks.shift().replace(/^#/, "");
  const search = pathChunks.join("?");

  const params = useMemo(() => {
    const params = parse(search);
    if (!options.convertTypes) {
      return params;
    }

    return _.transform(
      params,
      (output, value, key) => {
        if (_.isArray(value)) {
          output[key] = value.map((item) => (/^\d+$/.test(item) ? +item : item));
        } else {
          output[key] = /^\d+$/.test(value) ? +value : value;
        }

        return output;
      },
      {}
    );
  }, [path, search]);

  const updateQueryParams = (newParams, routerOptions = { replace: true, trigger: true }) => {
    const updatedParams = _.transform(
      newParams,
      (output, value, key) => {
        if (!options.compact) {
          output[key] = value;
        } else if (_.isArray(value) && value.length > 0) {
          output[key] = value;
        } else if (value !== "") {
          output[key] = value;
        }

        return output;
      },
      {}
    );

    if (!_.isEqual(params, updatedParams)) {
      const queryString = _.isEmpty(updatedParams) ? "" : `?${stringify(updatedParams)}`;
      router.navigate(path + queryString, routerOptions);
    }
  };

  return [params, updateQueryParams];
};

export default useQueryParams;
