import { isNative, browserOpenUrl } from "mobile/mobileManager";
import PropTypes from "prop-types";
import React from "react";

import useWindow from "hooks/useWindow";
import { CONTRACT_CONNECT_CLICK } from "lib/metrics/events";

import { Button } from "components/fl-ui";

const BushelConnectButton = ({ source, ...rest }) => {
  const win = useWindow();

  const getConnectionUrl = async () => {
    try {
      const data = await fetch("/v2.0/integrations/bushel/url", {
        headers: { "Content-Type": "application/json" },
        method: "GET",
      }).then((response) => response.json());

      return data.url;
    } catch (error) {
      console.error(error);
    }
  };

  const handleClick = async (event) => {
    event.preventDefault();

    CONTRACT_CONNECT_CLICK.track({ source });
    const url = await getConnectionUrl();

    if (isNative()) {
      browserOpenUrl(url);
    } else {
      win.location.href = url;
    }
  };

  return (
    <Button {...rest} onClick={handleClick}>
      {rest.children}
    </Button>
  );
};

export default BushelConnectButton;

BushelConnectButton.propTypes = {
  source: PropTypes.oneOf(["ContractsBanner", "Integrations", "MarketingCard"]).isRequired,
};
