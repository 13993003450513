import _ from "lodash";

export const getSortedTypes = (allActivityTypes) => _.partition(_.sortBy(allActivityTypes, ["name"]), "isArchived");

export const hasEmptyRequiredField = (data) => {
  const requiredFields = ["category", "name"];
  return requiredFields.some((field) => !data[field]);
};

export const isInvalidName = (allActivityTypes, data) =>
  allActivityTypes.some(({ id, name }) => name.toLowerCase() === data.name.toLowerCase() && id !== data.id);
