import { css } from "aphrodite/no-important";
import { styles } from "billing/lib";
import React from "react";

class StripeInputWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFocused: false,
    };
  }
  handleBlur = () => {
    this.setState({ isFocused: false });
  };
  handleFocus = () => {
    this.setState({ isFocused: true });
  };
  render() {
    const { hasError, tooltip, ...rest } = this.props;
    const children = React.Children.map(this.props.children, (child) => {
      return React.cloneElement(child, {
        onBlur: this.handleBlur,
        onFocus: this.handleFocus,
      });
    });

    return (
      <div
        {...rest}
        className={css(
          styles.pos_stripeInput_Wrapper,
          this.state.isFocused && styles.pos_stripeInput_Wrapper_focused,
          hasError && styles.pos_stripeInput_Wrapper_hasError
        )}
      >
        {children}
        <div className={css(styles.pos_stripeInput_tooltip)}>{tooltip}</div>
      </div>
    );
  }
}

export default StripeInputWrapper;
