import { css } from "aphrodite";
import PropTypes from "prop-types";
import React from "react";
import ReactSelect from "react-select";
import { Col, Row } from "react-styled-flexboxgrid";

import statesList from "lib/states";
import GroupedFields from "modules/carbon_enrollment/components/GroupedFields";
import styles from "modules/carbon_enrollment/styles";

import { Form, FormGroup, Input } from "components/fl-ui/Form";
import useGroupedFields from "fields/hooks/useGroupedFields";

const GenericFieldset = ({ data, fields, onInputChange, onSelect, selectedFields, stepOneHeading, stepTwoHeading }) => {
  const { groupedFields, groups } = useGroupedFields(fields);
  const handleChange = (e, change) => onInputChange(change);

  return (
    <Row className={css(styles.section)}>
      <Col xs={12} sm={6} className={css(styles.mainColumn)}>
        <p className={css(styles.steps)}>Step 1</p>
        <p className={css(styles.heading)}>{stepOneHeading || "Verify your account information"}</p>

        <Form className={css(styles.mainContainer, styles.form)}>
          <Row>
            <Col xs={12}>
              <FormGroup label="Legal farm name">
                <Input autoFocus defaultValue={data.name} id="farm_name" name="name" onChange={handleChange} />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col xs={6}>
              <FormGroup label="First name">
                <Input defaultValue={data.firstName} name="firstName" onChange={handleChange} />
              </FormGroup>
            </Col>

            <Col xs={6}>
              <FormGroup label="Last name">
                <Input defaultValue={data.lastName} name="lastName" onChange={handleChange} />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col xs={6}>
              <FormGroup label="Email">
                <Input defaultValue={data.email} disabled name="email" onChange={handleChange} />
              </FormGroup>
            </Col>

            <Col xs={6}>
              <FormGroup label="Phone">
                <Input defaultValue={data.phone} name="phone" onChange={handleChange} />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <FormGroup label="Street">
                <Input defaultValue={data.address} name="address" onChange={handleChange} />
                <Input
                  className={css(styles.secondaryInput)}
                  defaultValue={data.address2}
                  name="address2"
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <FormGroup label="City">
                <Input defaultValue={data.city} name="city" onChange={handleChange} />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col xs={6}>
              <FormGroup label="State">
                <ReactSelect
                  getOptionLabel={({ name }) => name}
                  getOptionValue={({ code }) => code}
                  onChange={({ code }) => onInputChange({ state: code })}
                  options={statesList[data.country]}
                  styles={{ control: (base) => ({ ...base, height: "2.5rem" }) }}
                  value={statesList[data.country].find(({ code }) => code === data.state)}
                />
              </FormGroup>
            </Col>

            <Col xs={6}>
              <FormGroup label="Postal code">
                <Input defaultValue={data.postalCode} name="postalCode" onChange={handleChange} />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </Col>

      <Col xs={12} sm={6} className={css(styles.mainColumn)}>
        <p className={css(styles.steps)}>Step 2</p>
        <p className={css(styles.heading)}>{stepTwoHeading || "Select the fields you’d like to enroll"}</p>

        <div className={css(styles.mainContainer, styles.verticalScroll)}>
          {groups.map((groupName) => (
            <GroupedFields
              fields={groupedFields[groupName]}
              groupName={groupName}
              key={groupName}
              onSelect={onSelect}
              selectedFields={selectedFields}
            />
          ))}
        </div>
      </Col>
    </Row>
  );
};

GenericFieldset.propTypes = {
  data: PropTypes.object.isRequired,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  onInputChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedFields: PropTypes.object,
  stepOneHeading: PropTypes.string,
  stepTwoHeading: PropTypes.string,
};

export default GenericFieldset;
