import { css } from "aphrodite/no-important";
import React from "react";

import PayGateCommonBlankSlateBanner from "components/advertisements/banners/PayGateCommonBlankSlateBanner";
import styles from "components/advertisements/styles";

const OtherExpensesBlankSlateBanner = (props) => {
  const bannerProps = {
    ...props,
    actionButtonText: "Try Other Expenses for free",
    imagePath: "images/pay_gates/banners/otherExpense.png",
    secondaryText:
      "Other Expenses is bundled with Profit & Loss and included in the Business plan. You can try it for free.",
    title: "With Other Expenses you can:",
  };

  return (
    <PayGateCommonBlankSlateBanner {...bannerProps}>
      <ul>
        <li className={css(styles.valueProp)}>
          Track expenses that are not related to fieldwork or inputs, such as crop insurance or drying fees.
        </li>
        <li className={css(styles.valueProp)}>
          Attribute expenses to your entire business as overhead, certain Marketing crops, or specific field crops.
        </li>
        <li className={css(styles.valueProp)}>Keep an accurate Profit & Loss statement.</li>
      </ul>
    </PayGateCommonBlankSlateBanner>
  );
};

export default OtherExpensesBlankSlateBanner;
