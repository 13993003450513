import PropTypes from "prop-types";
import React, { useContext } from "react";
import { TextArea } from "components/fl-ui/Form";
import { modalContext } from "components/fl-ui/Modal/Modal";
import { isNative } from "mobile/mobileManager";

const NotesField = ({ onChange, value }) => {
  const { container } = useContext(modalContext);
  return (
    <TextArea
      onFocus={({ target }) => {
        if (isNative()) {
          container.scrollTop = target.getBoundingClientRect().y;
        }
      }}
      name="notes"
      onChange={({ target }) => onChange(target.value)}
      placeholder="Type to add a note..."
      value={value || ""}
      legacy
    />
  );
};

NotesField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default NotesField;
