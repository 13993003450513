import ScaleTicket from "../fragments/ScaleTicket";
import { gql } from "@apollo/client";

export default gql`
  query getScaleTicket($id: Int!) {
    scaleTicket: getScaleTicket(id: $id) {
      ...ScaleTicket
    }
  }

  ${ScaleTicket}
`;
