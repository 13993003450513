/* eslint-disable react/display-name */
import React, { useMemo } from "react";

import { useAuth } from "collection/graphql/auth";
import { hasPolicyPermissions } from "collection/graphql/auth/lib/hasPolicyPermissions";

/**
 * A hook that returns an object whose methods can be used to determine whether or
 * not the current user has the permissions necessary to access a given featureId.
 *
 * @returns {PermissionUtil}
 */
const usePermissions = () => {
  // Permissions are sourced from the currentUser object. Using useAuth here re-renders should the permissions change.
  const { currentUser } = useAuth();

  /**
   * An object containing utility functions used to determine the current user's
   * ability to read/write/delete on a given featureId.
   *
   * @class PermissionUtil
   * @typedef {Object} PermissionUtil
   * @property {Function} hasPermission {@link PermissionUtil#hasPermission}
   * @property {Function} canRead {@link PermissionUtil#canRead}
   * @property {Function} canWrite {@link PermissionUtil#canWrite}
   * @property {Function} canDelete {@link PermissionUtil#canDelete}
   */
  return useMemo(
    () => ({
      /**
       * Given an array of permissions, returns true if the user has permission to
       * perform all actions for a specified feature.
       *
       * @return {Boolean}
       * @param {UserFeature} featureId The id of the feature to be checked.
       * @param {UserAccessType[]} permissions An array containing at least one of the following
       * values: 'read', 'write', or 'delete'.
       *
       *     @example
       *     // checks if the user can read and write to the marketing feature
       *     hasPermissions('marketing', ['read', 'write']);
       */
      hasPermission: (featureId, permissions) => hasPolicyPermissions(featureId, permissions),

      /**
       * Returns true if the current user has `read` permissions on a given feature id.
       *
       * @param {UserFeature} featureId
       * @return {Boolean}
       */
      canRead: (featureId) => hasPolicyPermissions(featureId, ["read"]),

      /**
       * Returns true if the current user has `write` permissions on a given feature id.
       *
       * @param {UserFeature} featureId
       * @return {Boolean}
       */
      canWrite: (featureId) => hasPolicyPermissions(featureId, ["write"]),

      /**
       * Returns true if the current user has `delete` permissions on a given feature id.
       *
       * @param {UserFeature} featureId
       * @return {Boolean}
       */
      canDelete: (featureId) => hasPolicyPermissions(featureId, ["delete"]),
    }),
    [currentUser]
  );
};

const withPermissions = (WrappedComponent) => (props) => <WrappedComponent {...props} {...usePermissions()} />;

export { withPermissions };
export default usePermissions;
