import { StyleSheet, css } from "aphrodite/no-important";
import PropTypes from "prop-types";
import React from "react";
import { Row, Col } from "react-styled-flexboxgrid";

import BreadCrumbs, { BreadCrumbLinkShape } from "components/fl-ui/BreadCrumbs";
import Toolbar from "components/fl-ui/Layout/Navigation/Toolbar";
import headerStyles from "components/fl-ui/Layout/styles/headerStyles";
import { Mixins } from "components/fl-ui/constants/index";

const styles = StyleSheet.create({
  h1: {
    lineHeight: Mixins.toRem(40),
    marginTop: 0,
    marginBottom: 0,
  },
  breadCrumbs: {
    flexWrap: "wrap",
  },
});

const BreadCrumbHeader = (props) => {
  return (
    <Row className={css(headerStyles.headerContainer)}>
      <Col xs>
        <div className={css(headerStyles.headerBorder, props.noBorder && headerStyles.noBorder)}>
          <div className={css(headerStyles.headerContent)}>
            <h1 className={css(styles.h1)}>
              <BreadCrumbs className={css(styles.breadCrumbs)} links={props.links} />
            </h1>
            <div
              className={css(
                headerStyles.headerChildren,
                props.alignChildrenLeft && headerStyles.headerChildren_alignLeft
              )}
            >
              {props.children}
            </div>
          </div>

          <div
            className={css(
              headerStyles.toolbarContainer,
              (!props.children || props.alignChildrenLeft) && headerStyles.toolbarContainer_noChildren
            )}
          >
            <Toolbar />
          </div>
        </div>
      </Col>
    </Row>
  );
};

BreadCrumbHeader.propTypes = {
  alignChildrenLeft: PropTypes.bool,
  children: PropTypes.any,
  links: PropTypes.arrayOf(BreadCrumbLinkShape),
  noBorder: PropTypes.bool,
};

BreadCrumbHeader.defaultProps = {
  alignChildrenLeft: false,
  noBorder: false,
};

export default BreadCrumbHeader;
