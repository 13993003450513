import styled from "styled-components";

import { Button } from "components/fl-ui";
import { UIColors } from "components/fl-ui/colors";
import { Spacing } from "components/fl-ui/constants";

export const ButtonSwitchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: ${Spacing.xxsmall};
`;

export const FullWidthButton = styled(Button)`
  margin-top: ${Spacing.xsmall};
  width: 100%;
`;

export const HalfWidthButton = styled(Button)`
  width: 50%;
`;

export const SubText = styled.sub`
  color: ${UIColors.lighter};
  display: block;
  padding: ${Spacing.xsmall} 0;
`;
