import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { GreyColors } from "components/fl-ui/colors";
import { Spacing, Typography } from "components/fl-ui/constants";

const Subtitle = styled.small`
  color: ${GreyColors.smoke70};
  font-size: ${Typography.sizes.md};
  margin-left: ${Spacing.small};
`;

const TableHeading = styled((props) => {
  const { subtitle, title } = props;

  return (
    <h2>
      {title}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </h2>
  );
})``;

TableHeading.propTypes = {
  subtitle: PropTypes.any,
  title: PropTypes.any.isRequired,
};

export default TableHeading;
