import { normalizeRecipeRate, normalizeRecipeUnit } from "collection/graphql/recipes/lib";

const ProductRecipeIngredientTypePolicy = {
  fields: {
    amountUnit: {
      read: (value) => normalizeRecipeUnit(value),
    },

    rateUnit: {
      read: (value) => normalizeRecipeRate(value),
    },
  },
};

export default ProductRecipeIngredientTypePolicy;
