import Unit from "./Unit";
import { find, omit } from "lodash";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

import useCommodityCustomaryUnits from "collection/graphql/commodities/hooks/useCommodityCustomaryUnits";

const CommodityYieldUnit = ({ commodity, defaultUnit, ...rest }) => {
  const customaryUnits = useCommodityCustomaryUnits();
  rest.unit = useMemo(
    () => find(customaryUnits, { value: commodity?.defaultYieldUnit })?.abbr ?? defaultUnit,
    [commodity?.defaultYieldUnit, customaryUnits]
  );

  return <Unit {...rest} />;
};

CommodityYieldUnit.propTypes = {
  ...omit(Unit.propTypes, ["unit"]),
  commodity: PropTypes.object,
  defaultUnit: PropTypes.string,
};

CommodityYieldUnit.defaultProps = {
  ...Unit.defaultProps,
  defaultUnit: "bu",
};

export default CommodityYieldUnit;
