import { withMarketingCheck } from "marketing/hoc/withMarketingCheck";
import withZipCodeProtection from "marketing/hoc/withZipCodeProtection";
import CashContractRedirect from "marketing/routes/CashContract";
import ContractDetails from "marketing/routes/ContractDetails";
import ScaleDetails from "marketing/routes/ScaleDetails";
import { ADD_CONTRACT, ADD_SCALE_TICKET, EDIT_CONTRACT, EDIT_SCALE_TICKET } from "marketing/utils/contractEnums";

import App from "layout/app";
import SubRoute from "lib/SubRoute";

const isValidContractType = (type) => _.includes(["cash", "futures", "options"], type.toLowerCase());

const validateContractType = (year, cropId, contractType) => {
  return (renderComponent) =>
    isValidContractType(contractType)
      ? renderComponent()
      : (window.location.href = `#marketing/${year}/${cropId}/contracts`);
};

const Router = SubRoute.extend({
  routes: {
    "": "listContracts",
    ":type/new": "addContract",
    ":type/:contract": "viewContract",
    ":type/:contract/edit": "editContract",
    "cash/:contract/scale/new": "addScale",
    "cash/:contractId/scale/:scaleTicketId": "editScale",
  },

  initialize(options) {
    this.year = parseInt(options.year, 10);
    this.cropId = parseInt(options.cropId, 10);
  },

  addContract(contractType) {
    const { year, cropId } = this;

    return validateContractType(
      year,
      cropId,
      contractType
    )(() => {
      // Render add contract component
      App.display(withZipCodeProtection(withMarketingCheck(ContractDetails)), {
        screenName: "Marketing",
        year: parseInt(year, 10),
        cropId,
        contractType,
        action: ADD_CONTRACT,
      });
      return App.setTitle("Marketing");
    });
  },

  editContract(contractType, contractId) {
    const { year, cropId } = this;

    return validateContractType(
      year,
      cropId,
      contractType
    )(() => {
      // Render edit contract component
      App.display(withZipCodeProtection(withMarketingCheck(ContractDetails)), {
        screenName: "Marketing",
        year: parseInt(year, 10),
        cropId,
        contractType,
        contractId: parseInt(contractId, 10),
        action: EDIT_CONTRACT,
      });
      return App.setTitle("Marketing");
    });
  },

  viewContract(contractType) {
    const { year, cropId } = this;

    return validateContractType(
      year,
      cropId,
      contractType
    )(() => {
      switch (contractType.toLowerCase()) {
        case "cash":
          // Cash contract redirect
          App.display(withZipCodeProtection(withMarketingCheck(CashContractRedirect)), {
            year: parseInt(year, 10),
            cropId,
          });
          return App.setTitle("Marketing");
        default:
          // Redirect to contracts page
          window.location.href = `#marketing/${year}/${cropId}/contracts`;
          break;
      }
    });
  },

  addScale(contractId) {
    const { year, cropId } = this;

    App.display(withZipCodeProtection(withMarketingCheck(ScaleDetails)), {
      screenName: "Marketing",
      year: parseInt(year, 10),
      cropId,
      contractId: parseInt(contractId, 10),
      action: ADD_SCALE_TICKET,
    });
    return App.setTitle("Marketing");
  },

  editScale(contractId, scaleTicketId) {
    const { year, cropId } = this;

    App.display(withZipCodeProtection(withMarketingCheck(ScaleDetails)), {
      screenName: "Marketing",
      year: parseInt(year, 10),
      cropId,
      contractId: parseInt(contractId, 10),
      scaleTicketId: parseInt(scaleTicketId, 10),
      action: EDIT_SCALE_TICKET,
    });
    return App.setTitle("Marketing");
  },
});

export default Router;
