import Legend from "map/layers/Legend";
import numeral from "numeral";
import PropTypes from "prop-types";
import React from "react";

import ImageryTour from "components/field/imagery_tour";

const LayerInfo = ({ layer, legendData, onClick, selectedType, showVariability }) => (
  <div className="row font-size-s" style={{ marginTop: "1em" }}>
    {layer.highlights != null
      ? layer.highlights.map(function (...args) {
          const [title, description] = Array.from(args[0]),
            index = args[1];
          const [primary, secondary] = Array.from(description.split(" "));

          return (
            <div className={`col xs-${index ? "5" : "7"}`} key={index}>
              <div style={{ lineHeight: "1.25em" }}>
                <span style={{ fontSize: "16px" }}>{primary} </span>
                {secondary}
              </div>
              <div>{title}</div>
            </div>
          );
        })
      : undefined}

    {layer.cloudCoverage !== undefined && (
      <div className="col xs-12" style={{ marginBottom: "1em" }}>
        This image contains approximately {numeral(layer.cloudCoverage).format("0")}% clouds.
      </div>
    )}

    {layer.learnMore ? (
      <div className="col xs-12">
        <ImageryTour>Learn more</ImageryTour>
      </div>
    ) : undefined}
    {layer.legend && legendData && (
      <div className="col xs-12">
        {legendData}
        <Legend {...layer.legend} selectedType={selectedType} showVariability={showVariability} layer={layer} />
      </div>
    )}
    <div className="col xs-12">
      {layer.info != null
        ? layer.info.map((info, i) => (
            <div
              id={`layer-info-item-${i + 1}`}
              key={`layer-info-item-${i + 1}`}
              className={`row layer-row ${info.selected === false ? "fl-faded" : ""}`}
              onClick={onClick.bind(null, info.id)}
              style={{ padding: ".5rem 0", cursor: "pointer" }}
            >
              {info.color ? (
                <div className="col xs-1" style={{ paddingRight: "0" }}>
                  <div
                    className={`${[info.color.className].join("")} soil-legend-swatch`}
                    style={{
                      background: info.color.code,
                      height: "16px",
                      width: "16px",
                    }}
                  />
                </div>
              ) : undefined}
              <div className="col xs-11">
                <div style={{ paddingLeft: "1em" }}>
                  <div style={{ fontWeight: "500" }}>{info.title}</div>
                  {info.description != null
                    ? info.description.map((d, i) => <div key={`description-${i}`}>{d}</div>)
                    : undefined}
                </div>
              </div>
            </div>
          ))
        : undefined}
    </div>
  </div>
);

LayerInfo.propTypes = {
  layer: PropTypes.shape({
    // emphasized layer info
    highlights: PropTypes.arrayOf(PropTypes.array),
    // shows "learn more" imagery tour link
    learnMore: PropTypes.bool,
    // legend values values
    legend: PropTypes.object,
    // "table" structure of vector information
    info: PropTypes.arrayOf(
      PropTypes.shape({
        selected: PropTypes.bool,
        color: PropTypes.shape({
          className: PropTypes.string,
          code: PropTypes.string,
        }),
        description: PropTypes.arrayOf(PropTypes.node),
      })
    ),
  }),
  // triggered when a vector detail is clicked
  onClick: PropTypes.func,
};

export default LayerInfo;
