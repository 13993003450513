import { gql } from "@apollo/client";

export default gql`
  query getFieldCropsAndActivities($fieldId: Int!, $cropYear: Int!) {
    crops: getFieldCrops(fields: [$fieldId], years: [$cropYear]) {
      ...Crop
    }
    field: getField(fieldId: $fieldId) {
      id
      centroid
    }
    plantingActivities: getActivities(fields: [$fieldId], cropYear: $cropYear, activityTypeCategories: [PLANTING]) {
      id
      crop {
        ...Crop
      }
      date
    }
  }

  fragment Crop on Crop {
    id
    acreage
    commodity {
      id
      gddTbase
      gddTceil
      name
    }
    cropYear
    field {
      id
      name
    }
  }
`;
