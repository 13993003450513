import { PLANNED } from "lib/constants";

/**
 * Returns a boolean true if the activity is PLANNED, false otherwise.
 *
 * @param {Activity} activity
 * @param {String} activity.status
 * @returns {Boolean}
 */
export default ({ status }) => status === PLANNED;
