import _ from "lodash";
import numeral from "numeral";

const normalize = (value) => {
  value = numeral(value).value();
  return _.isFinite(value) ? value : 0;
};

/**
 * Formats a currency.  Unless the double = true option is provided, whole numbers
 * are shown without their decimals.
 *
 * @param {Number|String} numericValue
 * @param {Object} [options={}]
 * @param {Boolean} [options.double=false] force double precision
 * @returns {String} currency formatted string, ex: $1,000, -$1,000, $100.50
 */
const currency = (numericValue, options = {}) => {
  const value = normalize(numericValue);

  const double = options.hasOwnProperty("double") ? options.double : Math.abs(value) < 100;

  const format = double ? "$0,0.00" : "$0,0";

  if (value < 0) {
    return "-" + numeral(Math.abs(value)).format(format);
  }

  return numeral(value).format(format);
};

/**
 * Formats a currency, forcing double precision.
 *
 * @param {Number|String} numericValue
 * @returns {String} currency formatted string, ex: $1,000, -$1,000, $100.50
 */
const doubleCurrency = (numericValue) => currency(numericValue, { double: true });

/**
 * Formats a currency to show a gain (ex: +$2.00) or a loss (ex: -$2.00). Forces
 * double precision.
 *
 * @param {Number|String} numericValue
 * @param {Boolean} [double=false]
 * @returns {String}
 */
const gainLoss = (numericValue, double = false) => {
  const sign = normalize(numericValue) > 0 ? "+" : "";
  return `${sign}${currency(numericValue, { double: double })}`;
};

/**
 * @param {Number|String} numericValue
 * @returns {String}
 */
const number = (numericValue) => {
  const value = normalize(numericValue);
  const double = Math.abs(value) < 100 && value % 1;
  const format = double ? "0,0.00" : "0,0";

  return numeral(value).format(format);
};

const percentage = (value) => numeral(value / 100).format("0%");

export { currency, doubleCurrency, gainLoss, normalize, number, percentage };
