import asAgreementForm from "agreements/forms/asAgreementForm";
import CommandButtonBar from "agreements/forms/components/CommandButtonBar";
import {
  AttachmentsFieldset,
  FieldsFieldset,
  NotesFieldset,
  PaymentScheduleFieldset,
  RevenueSourceFieldset,
  StartDateAndTermFieldset,
} from "agreements/forms/fieldsets";
import { prepareAgreementForSave } from "agreements/utils";
import revenueAgreementValidator from "agreements/validators/AgreementsForm/revenueAgreementValidator";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";

import { withCreateRevenueAgreement, withUpdateRevenueAgreement } from "collection/graphql/agreements";

import { Stack } from "components/fl-ui/Layout";

const RevenueAgreementForm = ({
  createRevenueAgreement,
  updateRevenueAgreement,
  fields,
  isValid,
  onChange,
  onCancel,
  onSaveSuccess,
  performDelete,
}) => {
  const handleScheduleChange = ({ paymentSchedules }) => onChange({ paymentSchedules });

  const onSave = async () => {
    const agreement = prepareAgreementForSave(fields, "REVENUE");
    if (agreement.id > 0) {
      await updateRevenueAgreement(agreement);
    } else {
      await createRevenueAgreement(agreement);
    }

    onSaveSuccess();
  };

  return (
    <Stack>
      <StartDateAndTermFieldset
        onChange={onChange}
        startDate={fields.startDate}
        termLength={fields.termLength}
        termUnit={fields.termUnit}
      />
      <FieldsFieldset onChange={onChange} fields={fields.fields} />
      <RevenueSourceFieldset onChange={onChange} revenueSource={fields.revenueSource} />
      <PaymentScheduleFieldset agreementType="REVENUE" fields={fields} onChange={handleScheduleChange} />
      <NotesFieldset notes={fields.notes} onChange={onChange} />
      <AttachmentsFieldset attachments={fields.attachments} onChange={onChange} />
      <CommandButtonBar
        onSaveClick={onSave}
        disableSave={!isValid}
        onCancelClick={onCancel}
        onDeleteClick={fields.id ? () => performDelete(fields.id) : null}
      />
    </Stack>
  );
};

RevenueAgreementForm.propTypes = {
  agreement: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onSaveSuccess: PropTypes.func.isRequired,
};

RevenueAgreementForm.defaultProps = {
  agreement: {},
};

const getRevenueAgreementDefaults = () => ({
  fields: [],
  paymentSchedules: [],
  revenueSource: "HUNTING",
  startDate: moment().format("YYYY-MM-YY"),
  termLength: 30,
  termUnit: "YEAR",
});
export { getRevenueAgreementDefaults };

export default asAgreementForm(withCreateRevenueAgreement(withUpdateRevenueAgreement(RevenueAgreementForm)), {
  validator: revenueAgreementValidator,
});
