import styles from "fields/common/styles";

const fieldCardGrid = {
  flexboxgrid: {
    // Defaults
    gutterWidth: 0.5, // rem
    outerMargin: 0.25, // rem
    mediaQuery: "only screen",
  },
};

export { styles, fieldCardGrid };
