import { gql } from "@apollo/client";

const getAllUnits = gql`
  query getAllUnits {
    # Enums
    allEnums: getEnums {
      name
      values {
        ...EnumPair
      }
      __typename
    }

    # Units
    seedCountUnits: getUnits(type: SEED_COUNT) {
      ...Unit
    }
    volumeUnits: getUnits(type: VOLUME) {
      ...Unit
    }
    weightUnits: getUnits(type: WEIGHT) {
      ...Unit
    }
  }

  fragment EnumPair on EnumPair {
    data
    display
    value: data
    __typename
  }

  fragment Unit on Unit {
    abbr
    data
    display
    type
    __typename
  }
`;

export default getAllUnits;
