import { useGlobalEvent } from "beautiful-react-hooks";
import _ from "lodash";
import { useMemo } from "react";

import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import useForceUpdate from "hooks/useForceUpdate";
import History from "lib/history";
import { CONTRACT_NAVIGATION_CLICK } from "lib/metrics/events";

const shouldShowBackButton = (route) => {
  const formattedRoute = route.replace(/^\//, "");

  // special cases
  const isMarketingPageTopLevel = /^marketing\/\d{4}$/.test(formattedRoute);
  const isPrices = /^prices\/(futures|local)$/i.test(formattedRoute);
  const isSettings = /^settings\//i.test(formattedRoute);

  if (isMarketingPageTopLevel || isPrices || isSettings) {
    return false;
  }

  return formattedRoute.split("/").length > 1;
};

const desktopGroups = {
  expenses: "expenses",
  farm: "farm",
  revenue: "revenue",
};

const mobileGroups = {
  drawer: "drawer",
  main: "main",
};

const useMenuItems = () => {
  const hasEquipment = useEnterpriseFeature("equipment");
  const hasLoads = useEnterpriseFeature("legacy_storage_and_loads");
  const hasStorage = useEnterpriseFeature("legacy_storage_and_loads");

  const { pathname, search } = History.parseCurrentUrl();
  const pathWithQuery = search ? [pathname, search].join("?") : pathname;
  const page = _.toLower(pathname.split("/")[1]);
  const update = useForceUpdate();
  useGlobalEvent("hashchange")(() => update());

  const allMenuItems = [
    {
      href: "#fields",
      icon: "fields",
      id: "fields",
      title: "Fields",
      enabled: true,
      mobileGroup: mobileGroups.main,
      mobileGroupOrder: 1,
      desktopGroup: desktopGroups.farm,
      desktopGroupOrder: 1,
      titleAbbr: null,
    },
    {
      href: "#map",
      icon: "map",
      id: "map",
      title: "Map",
      enabled: true,
      mobileGroup: mobileGroups.main,
      mobileGroupOrder: 2,
      desktopGroup: desktopGroups.farm,
      desktopGroupOrder: 2,
      titleAbbr: null,
    },
    {
      href: "#imagery",
      icon: "imagery",
      id: "imagery",
      title: "Satellite Imagery",
      enabled: true,
      mobileGroup: mobileGroups.drawer,
      mobileGroupOrder: 13,
      desktopGroup: desktopGroups.farm,
      desktopGroupOrder: 3,
      titleAbbr: null,
    },
    {
      href: "#scouting",
      icon: "scouting",
      id: "scouting",
      title: "Scouting",
      enabled: true,
      mobileGroup: mobileGroups.drawer,
      mobileGroupOrder: 5,
      desktopGroup: desktopGroups.farm,
      desktopGroupOrder: 4,
      titleAbbr: null,
    },
    {
      href: "#equipment",
      icon: "equipment",
      id: "equipment",
      title: "Equipment",
      enabled: hasEquipment,
      mobileGroup: mobileGroups.drawer,
      mobileGroupOrder: 8,
      desktopGroup: desktopGroups.farm,
      desktopGroupOrder: 5,
      titleAbbr: null,
    },
    {
      href: "#loads",
      icon: "loads",
      id: "loads",
      title: "Loads",
      enabled: hasLoads,
      mobileGroup: mobileGroups.drawer,
      mobileGroupOrder: 10,
      desktopGroup: desktopGroups.farm,
      desktopGroupOrder: 6,
      titleAbbr: null,
    },
    {
      href: "#inventory",
      icon: "storage",
      id: "storage",
      title: "Storage",
      enabled: hasStorage,
      mobileGroup: mobileGroups.drawer,
      mobileGroupOrder: 9,
      desktopGroup: desktopGroups.farm,
      desktopGroupOrder: 7,
      titleAbbr: null,
    },
    {
      href: "#reports",
      icon: "reports",
      id: "reports",
      title: "Reports",
      enabled: true,
      mobileGroup: mobileGroups.drawer,
      mobileGroupOrder: 2,
      desktopGroup: desktopGroups.farm,
      desktopGroupOrder: 8,
      titleAbbr: null,
    },
    {
      href: "#profit_and_loss",
      icon: "profitAndLoss",
      id: "profitAndLoss",
      title: "Profit & Loss",
      enabled: true,
      mobileGroup: mobileGroups.drawer,
      mobileGroupOrder: 1,
      desktopGroup: desktopGroups.farm,
      desktopGroupOrder: 9,
      titleAbbr: "P&L",
    },
    {
      href: "#activity",
      icon: "activities",
      id: "activities",
      title: "Activities",
      enabled: true,
      mobileGroup: mobileGroups.main,
      mobileGroupOrder: 4,
      desktopGroup: desktopGroups.expenses,
      desktopGroupOrder: 1,
      titleAbbr: null,
    },
    {
      href: "#products",
      icon: "inputs",
      id: "inputs",
      title: "Inputs",
      enabled: true,
      mobileGroup: mobileGroups.drawer,
      mobileGroupOrder: 7,
      desktopGroup: desktopGroups.expenses,
      desktopGroupOrder: 2,
      titleAbbr: null,
    },
    {
      href: "#land_costs",
      icon: "landCosts",
      id: "landCosts",
      title: "Land Costs",
      enabled: true,
      mobileGroup: mobileGroups.drawer,
      mobileGroupOrder: 3,
      desktopGroup: desktopGroups.expenses,
      desktopGroupOrder: 3,
      titleAbbr: null,
    },
    {
      href: "#other_expenses",
      icon: "expense",
      id: "expense",
      title: "Other Expenses",
      enabled: true,
      mobileGroup: mobileGroups.drawer,
      mobileGroupOrder: 11,
      desktopGroup: desktopGroups.expenses,
      desktopGroupOrder: 4,
      titleAbbr: null,
    },
    {
      href: "#marketing",
      icon: "marketing",
      id: "marketing",
      title: "Marketing",
      enabled: true,
      mobileGroup: mobileGroups.drawer,
      mobileGroupOrder: 4,
      desktopGroup: desktopGroups.revenue,
      desktopGroupOrder: 1,
      titleAbbr: null,
    },
    {
      href: "#contracts",
      icon: "contracts",
      id: "contracts",
      title: "Contracts",
      enabled: true,
      mobileGroup: mobileGroups.main,
      mobileGroupOrder: 3,
      desktopGroup: desktopGroups.revenue,
      desktopGroupOrder: 2,
      onClick: () => {
        CONTRACT_NAVIGATION_CLICK.track();
      },
      titleAbbr: null,
    },
    {
      href: "#prices",
      icon: "marketingPrices",
      id: "marketingPrices",
      title: "Market Prices",
      enabled: true,
      mobileGroup: mobileGroups.drawer,
      mobileGroupOrder: 6,
      desktopGroup: desktopGroups.revenue,
      desktopGroupOrder: 3,
      titleAbbr: "Prices",
    },
    {
      href: "#other_revenue",
      icon: "revenue",
      id: "revenue",
      title: "Other Revenue",
      enabled: true,
      mobileGroup: mobileGroups.drawer,
      mobileGroupOrder: 12,
      desktopGroup: desktopGroups.revenue,
      desktopGroupOrder: 4,
      titleAbbr: null,
    },
    {
      href: "#",
      icon: "more",
      id: "more",
      title: "More\u2026",
      enabled: true,
      mobileGroup: mobileGroups.main,
      mobileGroupOrder: 4,
      desktopGroup: "",
      desktopGroupOrder: 1,
      titleAbbr: null,
    },
  ];

  const backButtonMenuItem = {
    href: "#",
    icon: "chevronLeft",
    id: "back",
    title: "Back",
    enabled: true,
    mobileGroup: "",
    mobileGroupOrder: 1,
    desktopGroup: "",
    desktopGroupOrder: 1,
    titleAbbr: null,
  };

  const desktopGroupSort = (list, group) =>
    list
      .filter((item) => item.desktopGroup === desktopGroups[group] && item.enabled)
      .sort((a, b) => a.desktopGroupOrder - b.desktopGroupOrder);
  const mobileGroupSort = (list, group) =>
    list
      .filter((item) => item.mobileGroup === mobileGroups[group] && item.enabled)
      .sort((a, b) => a.mobileGroupOrder - b.mobileGroupOrder);

  const farm = desktopGroupSort(allMenuItems, desktopGroups.farm);
  const expenses = desktopGroupSort(allMenuItems, desktopGroups.expenses);
  const revenue = desktopGroupSort(allMenuItems, desktopGroups.revenue);
  const drawerItems = mobileGroupSort(allMenuItems, mobileGroups.drawer);
  const mainMobileMenuItems = mobileGroupSort(allMenuItems, mobileGroups.main);

  const activeMenuItemId = useMemo(
    () => allMenuItems.find((menuItem) => page === _.toLower(menuItem.href).replace(/^#/, ""))?.id,
    [page]
  );

  // conditionally display back button
  const mainMenuItems = _.cloneDeep(mainMobileMenuItems);

  if (History.canGoBack() && shouldShowBackButton(pathWithQuery)) {
    const indexOfActiveNav = _.findIndex(mainMenuItems, { id: activeMenuItemId });

    /*
     * Special case: navigating to an activity detail page from a field detail page or sub-page
     * should show the back button in the fields navigation slot.
     */
    const isActivityDetail = /^activity\/\d+/.test(History.fragment);
    const referrer = History.crumbs[History.crumbs.length - 2];
    const hasFieldDetailReferrer = /^#?fields\/\d+/.test(referrer);

    const indexToReplace =
      isActivityDetail && hasFieldDetailReferrer
        ? _.findIndex(mainMenuItems, { id: "fields" })
        : indexOfActiveNav === -1
        ? mainMenuItems.length - 1
        : indexOfActiveNav;

    mainMenuItems[indexToReplace] = backButtonMenuItem;
  }

  return {
    mainMenuItems,
    drawerItems,
    expenses,
    farm,
    revenue,
    activeMenuItemId,
  };
};

export default useMenuItems;
