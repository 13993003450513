/* istanbul ignore file */
import ProductDetailsPage from "products/pages/ProductDetailsPage";
import ProductIndexPage from "products/pages/ProductIndexPage";

import { marketingClient, wrapWithProvider } from "collection/graphql/client";
import App from "layout/app";
import SubRoute from "lib/SubRoute";

const ProductsRouter = SubRoute.extend({
  routes: {
    "": "index",
    ":productId": "details",
    ":redirect": "index",
  },

  index() {
    const PageComponent = wrapWithProvider(ProductIndexPage, marketingClient);
    App.display(PageComponent, { screenName: "Inputs" });
  },

  details(productId) {
    const PageComponent = wrapWithProvider(ProductDetailsPage, marketingClient);
    App.display(PageComponent, { productId: +productId, screenName: "Input Details" });
  },
});

export default () => new ProductsRouter("products", { createTrailingSlashRoutes: true });
