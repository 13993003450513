/* eslint-disable react/display-name */
import { FullscreenControl as MapboxFullscreenControl } from "mapbox-gl";
import React, { useEffect, useState } from "react";
import { MapContext } from "react-mapbox-gl";

const FullscreenControl = (props) => {
  const { map } = props;
  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    const control = new MapboxFullscreenControl({ container: map.getContainer() });
    map.addControl(control);

    // CORE-4302 this fullscreen fix targets a safari-only issue. see https://bit.ly/348diDt
    const onFullScreenChange = () => setIsFullScreen(window.innerHeight === screen.height);
    window.addEventListener("webkitfullscreenchange", onFullScreenChange);

    return () => {
      map.removeControl(control);
      window.removeEventListener("webkitfullscreenchange", onFullScreenChange);
    };
  }, [map]);

  useEffect(() => {
    if (isFullScreen) {
      map.resize();
    }
  }, [isFullScreen]);

  return null;
};

export default () => <MapContext.Consumer>{(map) => <FullscreenControl map={map} />}</MapContext.Consumer>;
