import { css } from "aphrodite";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import styled from "styled-components";

import AuthForm from "layout/components/AuthForm";
import FooterMessage from "layout/components/FooterMessage";
import styles from "layout/styles";

import { Button } from "components/fl-ui";
import { FormGroup } from "components/fl-ui/Form";
import { Container as InputContainer, Suffix, withBorder } from "components/fl-ui/Form/Input/ui";
import Link from "components/fl-ui/Link";
import { GreyColors } from "components/fl-ui/colors";

const copy = (
  <p className={css(styles.copyText)}>
    {`Don’t have an account? `}
    <a className={css(styles.blueText)} href="/#signup">
      Create an account to get a free trial
    </a>
  </p>
);

const PasswordContainer = withBorder(styled(({ children, className }) => {
  return (
    <span className={["flui-input-compat", className].join(" ")}>
      <InputContainer>{children}</InputContainer>
    </span>
  );
})`
  width: 100%;
  ${(props) => props.disabled && `background-color: ${GreyColors.smoke93};`}

  input {
    flex-grow: 2;
  }
`);

const LoginForm = ({
  formErrors,
  formValues,
  handleLogin,
  handlePasswordFieldToggle,
  obfuscatePassword,
  trackReset,
}) => {
  const { error, loading } = formValues;
  const disabled = loading;
  const passwordInputType = obfuscatePassword ? "password" : "text";
  const passwordToggleText = obfuscatePassword ? "SHOW" : "HIDE";
  const emailRef = useRef();
  const passwordRef = useRef();

  const handleSubmit = (event) => {
    event.preventDefault();
    handleLogin({
      email: emailRef.current.value.trim(),
      password: passwordRef.current.value,
    });
  };

  const footer = (
    <>
      <FooterMessage link="#password/reset" message="Did you forget your password?" onClick={trackReset} />
      <div className={css(styles.footerContainer)}>
        <Link
          className={css(styles.blueText)}
          href="https://bushelpowered.com/bushel-privacy-notice/"
          rel="noopener noreferrer"
          style={{ marginRight: "12px" }}
          target="_blank"
        >
          privacy policy
        </Link>

        <Link
          className={css(styles.blueText)}
          href="https://bushelpowered.com/policies"
          rel="noopener noreferrer"
          style={{ marginLeft: "12px" }}
          target="_blank"
        >
          terms of service
        </Link>
      </div>
    </>
  );

  return (
    <AuthForm
      action="Sign in"
      copy={copy}
      disabled={disabled}
      error={error}
      footer={footer}
      label="Sign in to Bushel Farm"
      onSubmit={handleSubmit}
    >
      <FormGroup inputId="email" label="Email">
        <input autoComplete="off" disabled={disabled} id="email" name="email" ref={emailRef} type="email" />

        {formErrors.email && <div className="help-inline">{formErrors.email}</div>}
      </FormGroup>

      <FormGroup inputId="password" label="Password">
        <PasswordContainer disabled={disabled}>
          <input disabled={disabled} id="password" name="password" ref={passwordRef} type={passwordInputType} />

          {!disabled && (
            <Suffix>
              <Button className={css(styles.toggleText)} link onClick={() => handlePasswordFieldToggle()}>
                {passwordToggleText}
              </Button>
            </Suffix>
          )}
        </PasswordContainer>
      </FormGroup>
    </AuthForm>
  );
};

LoginForm.propTypes = {
  formErrors: PropTypes.object.isRequired,
  formValues: PropTypes.shape({
    email: PropTypes.string,
    error: PropTypes.string,
    loading: PropTypes.bool,
    password: PropTypes.string,
    passwordType: PropTypes.string,
    passwordToggleText: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleLogin: PropTypes.func.isRequired,
  handlePasswordFieldToggle: PropTypes.func.isRequired,
  trackReset: PropTypes.func.isRequired,
};

export default LoginForm;
