import styles from "activity/common/styles";
import { css } from "aphrodite";
import _ from "lodash";
import numeral from "numeral";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import useYearFieldCrops from "modules/fields/hooks/useYearFieldCrops";

import { Button } from "components/fl-ui";
import CropBadge from "components/fl-ui/CropBadge";
import { Borders } from "components/fl-ui/constants";
import wrapOccurrences from "components/fl-ui/util/wrapOccurrences";
import CropMapImage from "fields/components/CropMapImage";

export const CropOption = (props) => {
  const { onSelect, option, search } = props;
  const { acreage, commodity, fieldCrops, name } = option;
  const totalFields = _.size(fieldCrops);
  const subtitle = `${numeral(acreage).format("0,0[.]00")} ac \u00b7 ${totalFields} ${
    totalFields === 1 ? "field" : "fields"
  }`;

  return (
    <FieldCropOptionBase
      badge={<CropBadge color={commodity.color} stripe />}
      buttonText="Select"
      onSelect={() => onSelect(fieldCrops)}
      subtitle={subtitle}
      title={wrapOccurrences(name, search.text)}
    />
  );
};

CropOption.propTypes = {
  onSelect: PropTypes.func.isRequired,
  option: PropTypes.shape({
    acreage: PropTypes.number.isRequired,
    commodity: PropTypes.shape({
      color: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    fieldCrops: PropTypes.array,
    name: PropTypes.string,
  }).isRequired,
  search: PropTypes.shape({
    text: PropTypes.string,
  }),
};

export const FieldOption = (props) => {
  const { isSelected, onSelect, option, search } = props;
  const isCrop = option.__typename === "Crop";

  let acreage, field, name;
  if (isCrop) {
    acreage = option.acreage;
    field = option.field;
    name = option.name;
  } else {
    field = option;
    acreage = field.acreage;
    name = field.name;
  }

  const cropMapField = field;
  const { commodity, cropYear } = option;
  const buttonText = isCrop ? "Select" : "Add crop";
  const { getYearCropsForField } = useYearFieldCrops(cropYear);
  const crops = getYearCropsForField(field?.id);

  const title = wrapOccurrences(name || field.name, search.text);
  const subtitle = (
    <>
      {_.round(acreage, 1)} ac {cropYear ? `\u00b7 ${cropYear}` : ""}{" "}
      {commodity ? wrapOccurrences(commodity.name, search.text) : ""}
    </>
  );

  return (
    <FieldCropOptionBase
      badge={<CropMapImage className={css(styles.mapImage)} crops={crops} commodity={commodity} field={cropMapField} />}
      buttonText={buttonText}
      isSelected={isSelected}
      onSelect={() => onSelect(option)}
      subtitle={subtitle}
      title={title}
    />
  );
};

FieldOption.propTypes = {
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  option: PropTypes.shape({
    __typename: PropTypes.oneOf(["Crop", "Field", "FieldOption"]),
    acreage: PropTypes.number.isRequired,
    commodity: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    cropYear: PropTypes.number,
    field: PropTypes.shape({
      acreage: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  search: PropTypes.shape({
    text: PropTypes.string,
  }),
};

const FieldCropOptionUI = styled.div`
  align-items: center;
  border-top: ${Borders.regular};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  min-height: 52px;
  padding: 12px;
  &:last-child {
    border-bottom: ${Borders.regular};
  }

  :hover {
    background-color: #deebff;
  }
  > :nth-child(2) {
    flex-grow: 2;
  }
  .resource-icon-wrapper {
    margin-bottom: 0;
    svg {
      position: static !important;
    }
  }

  ${({ isSelected }) =>
    isSelected &&
    `
    background: #edf6ff;
    border-top: 2px solid #eef0f2;
    color: inherit;
  `}
`;

const FieldCropOptionBase = (props) => {
  const { buttonText, badge, isSelected, onSelect, subtitle, title } = props;

  return (
    <FieldCropOptionUI onClick={onSelect}>
      {badge}

      <div>
        <p className={css(styles.optionHeader)}>{title}</p>
        <p className={css(styles.optionSubText)}>{subtitle}</p>
      </div>

      <div>
        <Button className={css(isSelected && styles.selectedBorder)} color="secondary">
          {buttonText}
        </Button>
      </div>
    </FieldCropOptionUI>
  );
};

FieldCropOptionBase.propTypes = {
  buttonText: PropTypes.string.isRequired,
  badge: PropTypes.element.isRequired,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
};
