export default `
  acreLimit
  addons @client {
    id
    name
    periodEnd
    source
  }
  billingInterval
  cancelAtPeriodEnd
  canManageSubscription @client
  currentPlan @client {
    id
    isPurchasable @client
    name
    periodEnd
    periodStart
    source
    totalPrice
  }
  daysRemaining @client
  enforceMaxAcres
  enforceMaxUsers
  hasNativeSubscription @client
  isCancelable @client
  isCustomPlan @client
  isSubscribed @client
  isSubscribedToPlan @client
  isTrialing @client
  paymentMethod
  products {
    id
    interval
    isPurchasable @client
    meta
    name
    periodEnd
    periodStart
    price
    quantity
    source
    totalPrice
    year @client
  }
  selfService
  sources @client
  status
  userLimits
`;
