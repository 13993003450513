import BasisInput from "marketing/components/BasisInput";
import { BASIS } from "marketing/constants";
import { CASH_CONTRACT, FUTURES_CONTRACT, OPTIONS_CONTRACT, CLOSED } from "marketing/utils/contractEnums";
import { currency } from "marketing/utils/format";
import { exists, isPositive } from "marketing/utils/validators";
import React from "react";

import { FormGroup, Input } from "components/fl-ui/Form";
import ContractUnit from "components/units/ContractUnit";

class ContractDetailsExtraFields extends React.Component {
  handleBasisChange = (event) => {
    this.props.handleContractChange({ basis: event.target.value });
  };

  handleSettlementPriceChange = (e) => {
    const val = e.target.value;
    if (isPositive(val) || !exists(val)) {
      this.props.handleContractChange({
        settlementPrice: val,
      });
    }
  };

  handlePremiumChange = (e) => {
    const val = e.target.value;
    if (isPositive(val) || !exists(val)) {
      const premiumProperty = this.props.getContractTypeEnum() === CASH_CONTRACT ? "cashPremium" : "premium";
      this.props.handleContractChange({
        [premiumProperty]: val,
      });
    }
  };

  render() {
    const { basis, contractStatus, contractSubtype, premium, settlementPrice } = this.props.contract;
    const { commodity, harvestBasis } = this.props.marketedCrop;
    const isExchangeTraded = commodity?.marketSymbol;
    const feesPrefix = ["SHORT_CALL", "SHORT_PUT"].includes(contractSubtype) ? "-" : "";

    switch (this.props.getContractTypeEnum()) {
      // TODO is this even used anymore?
      case CASH_CONTRACT:
        return (
          isExchangeTraded && (
            <FormGroup label={`Basis${contractStatus !== CLOSED && contractSubtype !== BASIS ? " (optional)" : ""}`}>
              <BasisInput
                helpText={`Your estimated basis is ${currency(
                  harvestBasis
                )}. This value is used when basis is not set.`}
                onChange={this.handleBasisChange}
                suffix={<ContractUnit contract={this.props.contract} per="unit" />}
                value={basis}
              />
            </FormGroup>
          )
        );
      case FUTURES_CONTRACT:
        if (contractStatus === CLOSED) {
          return (
            <div className="settlement-price">
              <FormGroup inputId="settlement-price-field" label="Settlement price">
                <Input
                  display="block"
                  id="settlement-price-field"
                  name="yield_target"
                  onChange={this.handleSettlementPriceChange}
                  placeholder="0.00"
                  prefix="$"
                  size="large"
                  type="text"
                  value={settlementPrice || ""}
                />
              </FormGroup>
            </div>
          );
        }
        return null;
      case OPTIONS_CONTRACT:
        return (
          <div className="premium">
            <FormGroup inputId="premium-field" label="Fees and Adjustments">
              <Input
                display="block"
                id="premium-field"
                onChange={this.handlePremiumChange}
                placeholder="0.00"
                prefix={`${feesPrefix}$`}
                size="large"
                suffix={<ContractUnit contract={this.props.contract} per="unit" />}
                type="text"
                value={premium || ""}
              />
            </FormGroup>
          </div>
        );
      default:
        return null;
    }
  }
}

export default ContractDetailsExtraFields;
