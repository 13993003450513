import moment from "moment";
import PropTypes from "prop-types";
import React from "react";

const ContractDeliveryDateRange = ({ endDate, startDate }) => {
  const dateRange = [startDate, endDate]
    .map((date) => moment(date ?? null))
    .filter((date) => date.isValid())
    .map((date) => date.format("MM/DD/YYYY"));

  return <>{dateRange.join(" - ") || "--"}</>;
};

ContractDeliveryDateRange.propTypes = {
  endDate: PropTypes.any,
  startDate: PropTypes.any,
};

export default ContractDeliveryDateRange;
