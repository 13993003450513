import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { components } from "react-select";

import Chooser from "components/fl-ui/Chooser";
import useFieldData from "fields/hooks/useFieldData";

const FieldChooser = (props) => {
  const { hasAcreage, isDisabled, onChange, value } = props;
  const { fields: fieldsData } = useFieldData();
  const fields = _.sortBy(fieldsData ?? [], ({ name }) => name.toLowerCase());

  const componentProps = {
    components: { MultiValueRemove: isDisabled ? () => null : components.MultiValueRemove },
    filterOption: ({ data }, input) => !input || data.name.toLowerCase().includes(input.toLowerCase()),
    formatOptionLabel: (option, { context }) => {
      return context === "menu" || !hasAcreage ? option.name : `${option.name} · ${(+option.acreage).toFixed(2)} ac`;
    },
    getOptionValue: _.property("id"),
    isClearable: false,
    isDisabled: isDisabled || !fieldsData,
    isLoading: !fieldsData,
    isMulti: true,
    onChange: (selected) => onChange(selected || []),
    options: fields,
    placeholder: "Select field",
    value,
  };

  return <Chooser {...componentProps} />;
};

FieldChooser.propTypes = {
  hasAcreage: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
};

FieldChooser.defaultProps = {
  hasAcreage: true,
  onChange: () => {},
};

export default FieldChooser;
