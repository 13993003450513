import utils from "lib/validation/utils";

const EMAIL_INVALID = "Invalid email";
const PASSWORD_INVALID = "This field must have at least 8 characters";
const POSTAL_CODE_INVALID = "This field must have no more than 10 characters";

export const isValidPassword = (password) => !!password && password.length > 7;
export const isValidPostalCode = (postal_code) => !!postal_code && postal_code.length <= 10;
export const getEmailErrors = ({ email }) => ({ email: !utils.isEmail(email) && EMAIL_INVALID });

export const getResetErrors = (formValues) => {
  const { password } = formValues;
  const errors = { password: !isValidPassword(password) && PASSWORD_INVALID };
  const confirm = formValues.hasOwnProperty("confirm_password") ? "confirm_password" : "password_confirm";
  errors[confirm] = !isConfirmedPassword(password, formValues[confirm]) && "Passwords do not match.";

  return errors;
};

export const getSignUpErrors = ({ email, first_name, last_name, password, phone, postal_code }) => ({
  email: !utils.isEmail(email) && EMAIL_INVALID,
  first_name: !first_name && "Required",
  last_name: !last_name && "Required",
  password: !isValidPassword(password) && PASSWORD_INVALID,
  phone: !phone && "Required",
  postal_code: (!postal_code && "Required") || (!isValidPostalCode(postal_code) && POSTAL_CODE_INVALID),
});

export const hasErrors = (errors) => !!Object.values(errors).some((e) => e);
export const isConfirmedPassword = (password1, password2) => password1 === password2;
