import eventFactory from "lib/metrics/event_factory";

export const PRODUCT_DETAIL_PAGE_VIEW = eventFactory("Viewed Input Detail Page", {
  entity: "Product",
  dynamic: ["productId"],
});

export const PRODUCT_LOG_PURCHASE_MODAL_OPEN = eventFactory("Log Input Purchase Modal Open");

export const PRODUCT_PURCHASE_CREATED = eventFactory("Input Purchase Logged", {
  entity: "Product",
  dynamic: ["productId", "purchaseId"],
});

export const PRODUCT_PURCHASE_EDITED = eventFactory("Input Purchase Edited", {
  entity: "Product",
  dynamic: ["productId", "purchaseId"],
});
