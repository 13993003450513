import { useQuery } from "@apollo/client";
import { isCancelledActivity, isDoneActivity, isOverdueActivity, isPlannedActivity } from "activity/lib";
import _ from "lodash";
import { useMemo } from "react";

import { GET_MINIMAL_ACTIVITY_LIST } from "collection/graphql/activities/queries";
import useCurrentCropYear from "hooks/useCurrentCropYear";

/**
 * Retrieves a list of activities for the current crop year and augments them with extra information.
 *
 * Normally this is done with local-only fields in the [Apollo cache](https://www.apollographql.com/docs/react/local-state/managing-state-with-field-policies/)
 * but that was causing a performance bug for large data sets ([CORE-5306](https://farmlogs.atlassian.net/browse/CORE-5306)).
 *
 * @return {Object}
 */
const useAugmentedActivities = () => {
  const { data, loading } = useQuery(GET_MINIMAL_ACTIVITY_LIST, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: { cropYear: useCurrentCropYear()[[0]] },
  });

  const allActivities = useMemo(() => {
    return !data?.activities
      ? undefined
      : data.activities.map((activity) => {
          const extraFields = {
            isDone: isDoneActivity(activity),
            isCancelled: isCancelledActivity(activity),
            isOverdue: isOverdueActivity(activity),
            isPlanned: isPlannedActivity(activity),
            statusText: "",
          };

          if (extraFields.isCancelled) {
            extraFields.statusText = "Cancelled";
          } else if (extraFields.isDone) {
            extraFields.statusText = "Done";
          } else if (extraFields.isOverdue) {
            extraFields.statusText = "Overdue";
          } else if (extraFields.isPlanned) {
            extraFields.statusText = "Planned";
          }

          return { ...activity, ...extraFields };
        });
  }, [data?.activities]);

  return {
    allActivities,
    loading,
    totalActivities: _.size(allActivities),
  };
};

export default useAugmentedActivities;
