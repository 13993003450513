import { css, StyleSheet } from "aphrodite/no-important";
import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import { ReportError } from "reports/components/ReportError";
import withDefinitions from "reports/components/withDefinitions";
import useReportRowViewModels from "reports/hooks/useReportRowViewModels";

import { useFeatureAndPermissionAccess } from "hooks/useFeatureAndPermissionAccess";

import ReportsBlankStatePage from "components/advertisements/pages/ReportsBlankStatePage";
import { Button } from "components/fl-ui";
import { Icon } from "components/fl-ui/Icons";
import Container from "components/fl-ui/Layout/Container";
import Content from "components/fl-ui/Layout/Content";
import ContentMain from "components/fl-ui/Layout/ContentMain";
import Header from "components/fl-ui/Layout/Header";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import RestrictedAccessBlankslate from "components/fl-ui/RestrictedAccessBlankslate";
import Tooltip from "components/fl-ui/Tooltip";
import { Spacing } from "components/fl-ui/constants";

const styles = StyleSheet.create({
  reportRowDisabled: {
    padding: "16px 0",
    opacity: 0.5,
  },
  reportRowEnabled: {
    padding: "16px 0",
    opacity: 1,
  },
  reportDescription: {
    fontSize: "16px",
    lineHeight: 1.375,
    marginBottom: 0,
  },
  divider: {
    margin: 0,
  },
  descriptionEmphasis: {
    display: "block",
    fontStyle: "italic",
    marginTop: "1em",
  },
  lockIcon: {
    color: "black",
    width: Spacing.medium,
    height: Spacing.medium,
  },
  flexRow: {
    display: "flex",
    alignItems: "center",
  },
});

const Dashboard = (props) => {
  const { error, definitions, loading } = props;
  const reportDefinitions = definitions.filter(({ beta }) => !beta);
  const reportRowViewModels = useReportRowViewModels(reportDefinitions);

  // lite and above users should see this screen. Standard users should still see the paygate
  // monthly_rainfall_summary_report is the lite report feature that essentials and business should also have
  const { loadingAccess, showPaygate, showRestricted, showContent } = useFeatureAndPermissionAccess({
    featureName: "monthly_rainfall_summary_report",
    permissionName: "reports",
  });

  return (
    <Container>
      <LoadingWrapper isLoading={loadingAccess || loading}>
        <Header title="Reports" />
        {showPaygate && <ReportsBlankStatePage />}
        {showRestricted && <RestrictedAccessBlankslate />}
        {showContent && (
          <Content>
            <ContentMain fullWidth>
              {reportRowViewModels.map((viewModel) => (
                <span key={viewModel.reportId}>
                  <Row className={css(viewModel.isDisabled ? styles.reportRowDisabled : styles.reportRowEnabled)}>
                    <Col xs={12} md={3} className={css(styles.flexRow)}>
                      <Button
                        disabled={viewModel.isDisabled}
                        color="primary"
                        display="inline"
                        href={`#reports/${viewModel.reportId}`}
                        link
                        style={{ marginBottom: 0, marginLeft: 0 }}
                      >
                        {viewModel.title}
                      </Button>
                      {viewModel.isDisabled && (
                        <Tooltip message={viewModel.tooltipMessage}>
                          <Icon className={css(styles.lockIcon)} icon="lock" />
                        </Tooltip>
                      )}
                    </Col>
                    <Col xs={12} md={9}>
                      <p className={css(styles.reportDescription)}>{viewModel.description}</p>
                    </Col>
                  </Row>
                  <hr className={css(styles.divider)} />
                </span>
              ))}
              {error && <ReportError />}
            </ContentMain>
          </Content>
        )}
      </LoadingWrapper>
    </Container>
  );
};

Dashboard.defaultProps = {
  data: [],
};

export default withDefinitions(Dashboard);
