import { css } from "aphrodite/no-important";
import _ from "lodash";
import Cell, { CellRow } from "marketing/components/ui-elements/Cell";
import { currency } from "marketing/utils/format";
import moment from "moment";
import FuturesContractHistoryCandlestickChart from "prices/charts/components/FuturesContractHistoryCandlestickChart";
import FuturesContractHistoryLineChart from "prices/charts/components/FuturesContractHistoryLineChart";
import styles from "prices/charts/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";

import { Icon } from "components/fl-ui/Icons";
import Tabs, { Tab } from "components/fl-ui/Tabs";
import { GreyColors } from "components/fl-ui/colors";
import { Typography } from "components/fl-ui/constants";

class FuturesContractHistoryChart extends Component {
  static propTypes = {
    history: PropTypes.array.isRequired,
  };

  state = {
    line: 30,
    candlestick: 30,
    view: "line",
  };

  componentDidMount() {
    const { candlestick, line, view } = sessionStorage;

    if (candlestick) {
      this.setState({ candlestick });
    }

    if (line) {
      this.setState({ line });
    }

    if (view) {
      this.setState({ view });
    }
  }

  render() {
    const { history } = this.props;
    const convertedHistory = history.map((item) => ({
      ...item,
      close: parseFloat(item.close),
    }));

    const { candlestick, line, view } = this.state;
    const chartDays = view === "line" ? line : candlestick;
    const graphData = _.slice(convertedHistory, chartDays * -1);
    const candlestickData = graphData.map((d) => ({
      lowHigh: [d.low, d.high],
      netChange: d.netChange,
      openClose: [d.open, d.close],
      priceTimestamp: d.priceTimestamp,
    }));

    const xAxisProps = {
      dataKey: "priceTimestamp",
      stroke: GreyColors.smoke91,
      tick: { fill: GreyColors.smoke50, fontSize: Typography.sizes.sm, lineHeight: Typography.sizes.xl },
      tickFormatter: (d) => moment(d).format("MMM DD").toUpperCase(),
      tickMargin: 10,
    };

    const yAxisProps = {
      axisLine: false,
      domain: ["dataMin", "dataMax"],
      orientation: "right",
      scale: "linear",
      tick: { fontSize: Typography.sizes.sm },
      tickFormatter: (d) => currency(d),
      tickLine: false,
      type: "number",
    };

    return (
      <div>
        <CellRow className={css(styles.chartControlsContainer)}>
          <Cell>
            <Tabs
              onChange={(days) => {
                sessionStorage[view] = days;
                return this.setState({ [view]: days });
              }}
              size="medium"
              value={chartDays}
            >
              <Tab value={7}>7 days</Tab>
              <Tab value={30}>30 days</Tab>
              <Tab value={90}>90 days</Tab>
              <Tab value={365}>1 year</Tab>
            </Tabs>
          </Cell>

          <Cell className={css(styles.chartSelectorContainer)}>
            <Tabs
              onChange={(view) => {
                sessionStorage.view = view;
                return this.setState({ view });
              }}
              size="medium"
              value={view}
            >
              <Tab value="line">
                <Icon className={css(styles.chartSelectorIcons)} icon="lineChart" />
              </Tab>
              <Tab value="candlestick">
                <Icon className={css(styles.chartSelectorIcons)} icon="candlestick" />
              </Tab>
            </Tabs>
          </Cell>
        </CellRow>

        {view === "line" && (
          <FuturesContractHistoryLineChart data={graphData} xAxisProps={xAxisProps} yAxisProps={yAxisProps} />
        )}

        {view === "candlestick" && (
          <FuturesContractHistoryCandlestickChart
            data={candlestickData}
            xAxisProps={xAxisProps}
            yAxisProps={yAxisProps}
          />
        )}
      </div>
    );
  }
}

export default FuturesContractHistoryChart;
