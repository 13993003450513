import { Section } from "demo/components";
import { useFlags } from "launchdarkly-react-client-sdk";
import _ from "lodash";
import React, { useMemo } from "react";
import styled from "styled-components";

import { useAuth } from "collection/graphql/auth";
import useCurrentEnterprise from "hooks/useCurrentEnterprise";

const Tags = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(${(props) => props.cellSize}, 1fr));

  > * {
    background: #fefefe;
    border: 1px solid #999;
    border-radius: 4px;
    font-size: 14px;
    padding: 5px;
  }
`;

const Flags = () => {
  const ldFlags = useFlags();
  const ldFlagList = useMemo(() => {
    const list = [];
    for (const i in ldFlags) {
      list.push({ label: i, value: ldFlags[i] });
    }

    return list;
  }, [ldFlags]);

  const { currentEnterprise } = useCurrentEnterprise();
  const enterpriseFeatures = _.sortBy(currentEnterprise?.features);

  const { currentUser } = useAuth();
  const userPermissions = _.sortBy(currentUser.policy.permissions, "feature");

  return (
    <div>
      <Section title="LaunchDarkly flags">
        <Tags cellSize="300px">
          {ldFlagList.map(({ label, value }) => (
            <div key={label}>
              <strong>{label}:</strong>
              <code>
                {typeof value === "boolean" && value.toString()}
                {typeof value === "object" && JSON.stringify(value)}
              </code>
            </div>
          ))}
        </Tags>
      </Section>

      <Section title="Enterprise available features">
        <Tags cellSize="200px">
          {enterpriseFeatures.map((feature) => (
            <div key={feature}>{feature}</div>
          ))}
        </Tags>
      </Section>

      <Section title="User permissions">
        <Tags cellSize="200px">
          {userPermissions.map(({ actions, feature }) => (
            <div key={feature}>
              <h4 style={{ marginTop: 0 }}>{feature}</h4>
              {_.sortBy(actions).join(", ")}
            </div>
          ))}
        </Tags>
      </Section>
    </div>
  );
};

export default Flags;
