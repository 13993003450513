import { gql } from "@apollo/client";

export default gql`
  mutation updateImplement($input: UpdateImplementInput!) {
    updateImplement(implement: $input) {
      ok
      implement {
        ...ImplementDetail
      }
    }
  }
`;
