import { gql } from "@apollo/client";

export default gql`
  fragment TractorDetail on Tractor {
    id
    equipmentType @client {
      name
    }
    fuelCapacity
    horsepower
    icon
    isRented
    make
    model
    name
    notes
    year
  }
`;
