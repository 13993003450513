import { StyleSheet, css } from "aphrodite/no-important";
import _ from "lodash";
import CommoditySelect from "prices/components/CommoditySelect";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";

import useWindow from "hooks/useWindow";
import { HARD_RED_SPRING_WHEAT, HARD_RED_WINTER_WHEAT, SOFT_RED_WINTER_WHEAT } from "lib/constants";
import { FUTURES_COMMODITY_SCROLL } from "lib/metrics/events";
import dollarFormat from "lib/utils/dollarFormat";

import { Carousel, Slide } from "components/fl-ui/Carousel/Carousel";
import CropBadge from "components/fl-ui/CropBadge";
import { UIColors } from "components/fl-ui/colors";
import { Borders, BorderRadius, Spacing, Mixins, Typography } from "components/fl-ui/constants";

const ARROW_SIZE = 6;

const styles = StyleSheet.create({
  carousel_container: {
    width: "100%",
    height: Mixins.toRem(200),
    marginBottom: Spacing.xlarge,
    "@media only screen and (max-width: 36.25rem)": {
      height: "auto",
    },
  },
  commodityTile: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: Borders.regular,
    borderRadius: BorderRadius.medium,
    cursor: "pointer",
    padding: `${Spacing.large}`,
    marginLeft: Mixins.toRem(4),
    marginRight: Mixins.toRem(4),
    textAlign: "center",
    minWidth: Mixins.toRem(100),
    height: Mixins.toRem(190),
  },
  commodityTile_selected: {
    borderColor: UIColors.primary,
    borderWidth: "3px",
    padding: `${Mixins.toRem(22)}`,
  },
  arrow_base: {
    position: "absolute",
    width: 0,
    height: 0,
  },
  arrow_outline: {
    bottom: `-${ARROW_SIZE + 4}px`,
    left: "50%",
    transform: "translateX(-50%)",
    borderTop: `${ARROW_SIZE + 2}px solid ${UIColors.primary}`,
    borderBottom: `0 solid transparent`,
    borderLeft: `${ARROW_SIZE + 2}px solid transparent`,
    borderRight: `${ARROW_SIZE + 2}px solid transparent`,
  },
  arrow_fill: {
    bottom: `-${ARROW_SIZE}px`,
    left: "50%",
    transform: "translateX(-50%)",
    borderTop: `${ARROW_SIZE}px solid ${UIColors.white}`,
    borderBottom: `0 solid transparent`,
    borderLeft: `${ARROW_SIZE}px solid transparent`,
    borderRight: `${ARROW_SIZE}px solid transparent`,
  },
  cropBadge: {
    margin: `0 !important`,
    flex: "none",
  },
  commodityName: {
    fontSize: Typography.sizes.sm,
    letterSpacing: "0.3px",
    lineHeight: 2,
    textTransform: "uppercase",
    marginTop: Spacing.xxsmall,
    marginBottom: Spacing.xxsmall,
    wordBreak: "break-word",
    wordWrap: "break-word",
    maxWidth: Mixins.toRem(100),
  },
  price_current: {
    fontSize: Typography.sizes.xxxl,
    fontWeight: Typography.weights.medium,
    lineHeight: 1,
    marginBottom: Spacing.xxsmall,
  },
  price_netChange: {
    position: "relative",
    fontSize: Typography.sizes.rg,
    fontWeight: Typography.weights.medium,
    lineHeight: 1.357,
    margin: "10px auto 0",
    color: UIColors.green,
    backgroundColor: UIColors.white,
    padding: `0 ${Spacing.small}`,
    ":after": {
      ...Mixins.beforeAfter,
      minWidth: Mixins.toRem(100),
      width: "100%",
      height: "1px",
      left: "50%",
      top: "50%",
      transform: "translateX(-50%)",
      borderBottom: Borders.regular,
      zIndex: "-10",
    },
  },
  price_netChange_neg: {
    color: UIColors.red,
  },
});

const abbreviatedCommodity = (commodity) => {
  // Adding a short name property
  commodity = {
    ...commodity,
    shortName: commodity.name,
  };

  // All varieties of wheat should have "W" for their abbreviation and an abbreviated name
  if (commodity.abbr === "RW") {
    const nameMap = {
      [HARD_RED_SPRING_WHEAT]: "HRS Wheat",
      [HARD_RED_WINTER_WHEAT]: "HRW Wheat",
      [SOFT_RED_WINTER_WHEAT]: "SRW Wheat",
    };

    commodity.abbr = "W";
    commodity.shortName = nameMap[commodity.id] || commodity.name;
  }

  return commodity;
};

const CommodityCarousel = ({ commodityQuotes, onCommodityChange, selectedCommodityId }) => {
  const carouselContainer = useRef();
  const [state, setState] = useState({
    showSelect: false,
    slideCount: null,
  });

  const calculateState = useCallback(() => {
    const getCount = (width) => {
      if (width <= 450) {
        return null;
      }
      if (width <= 650) {
        return 2;
      }
      if (width <= 750) {
        return 3;
      }
      if (width <= 860) {
        return 4;
      }
      if (width <= 990) {
        return 5;
      }
      if (width <= 1070) {
        return 6;
      }
      if (width <= 1170) {
        return 7;
      }
      return 8;
    };

    if (carouselContainer.current) {
      const containerWidth = carouselContainer.current.getBoundingClientRect().width;
      setState(() => ({
        showSelect: containerWidth <= 450,
        slideCount: getCount(containerWidth),
      }));
    }
  }, [commodityQuotes]);

  const win = useWindow();
  useEffect(() => {
    calculateState();
    win.addEventListener("resize", calculateState);
    return () => {
      win.removeEventListener("resize", calculateState);
    };
  }, [calculateState]);

  const getCommodityOptions = () => {
    return commodityQuotes.map(({ commodity, priceSnapshot }) => {
      commodity = abbreviatedCommodity(commodity);

      return {
        key: commodity.id,
        label: `${commodity.shortName} · ${dollarFormat(priceSnapshot.current)}`,
        value: commodity.id,
        id: commodity.id,
        color: commodity.color,
        abbr: commodity.abbr,
      };
    });
  };

  const handleCommodityChange = (commodity) => {
    onCommodityChange({ id: commodity.id });
  };

  const handleScrollClick = (id) => {
    FUTURES_COMMODITY_SCROLL.track({
      button_clicked: id,
    });
  };

  return (
    <div className={css(styles.carousel_container)} ref={carouselContainer}>
      {state.showSelect ? (
        <CommoditySelect
          id="commodity"
          name="commodity"
          options={getCommodityOptions()}
          value={selectedCommodityId}
          onChange={handleCommodityChange}
        />
      ) : (
        <Carousel
          totalSlides={commodityQuotes.length}
          naturalSlideHeight={200}
          visibleSlides={state.slideCount}
          className={css(styles.carousel)}
          onScrollClick={handleScrollClick}
        >
          {_.map(commodityQuotes, ({ commodity, priceSnapshot }, i) => {
            const isSelected = selectedCommodityId === commodity.id;
            commodity = abbreviatedCommodity(commodity);
            const title = commodity.name !== commodity.shortName ? commodity.name : null;

            return (
              <Slide
                index={i}
                key={i}
                className={css(styles.carousel_slide)}
                innerClassName={css(styles.carousel_slideInner)}
              >
                <div
                  onClick={() => handleCommodityChange(commodity)}
                  title={title}
                  className={css(styles.commodityTile, isSelected && styles.commodityTile_selected)}
                >
                  <CropBadge color={commodity.color} abbr={commodity.abbr} className={css(styles.cropBadge)} />

                  <h3 className={css(styles.commodityName)}>{commodity.shortName}</h3>
                  <p className={css(styles.price_current)}>{dollarFormat(priceSnapshot.current)}</p>
                  <p className={css(styles.price_netChange, priceSnapshot.netChange < 0 && styles.price_netChange_neg)}>
                    {dollarFormat(priceSnapshot.netChange)}
                  </p>
                  {isSelected && (
                    <>
                      <div className={css(styles.arrow_base, styles.arrow_outline)} />
                      <div className={css(styles.arrow_base, styles.arrow_fill)} />
                    </>
                  )}
                </div>
              </Slide>
            );
          })}
        </Carousel>
      )}
    </div>
  );
};

CommodityCarousel.propTypes = {
  commodityQuotes: PropTypes.array.isRequired,
  onCommodityChange: PropTypes.func.isRequired,
  selectedCommodityId: PropTypes.number,
};

export default CommodityCarousel;
