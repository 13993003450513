import React from "react";

import { getAllPeople } from "collection/graphql/people/queries";
import useRestSuspenseQuery from "hooks/useRestSuspenseQuery";

const withPersonList = (WrappedComponent) => (props) => {
  const isWorkOrder = !!props.defaults?.isWorkOrder;

  const personList = useRestSuspenseQuery(getAllPeople).data.people.reduce((list, person) => {
    if (!isWorkOrder || person.role !== "no_access") {
      list.push({
        id: person.id,
        name: person.name,
      });
    }

    return list;
  }, []);

  return <WrappedComponent {...props} personList={personList} />;
};

export default withPersonList;
