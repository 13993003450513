import _ from "lodash";
import { useFormContext } from "react-hook-form";

import { TIER_SELECTION_PLAN_SELECT } from "lib/metrics/events";
import { ESSENTIALS, LITE, PREMIUM, RAINFALL } from "model/Subscription/constants";

/**
 * @package
 */
const usePlan = (plan) => {
  const form = useFormContext();
  const interval = form.watch("billingInterval");
  const selectedPlan = _.find(plan.plans, { interval }) ?? plan.plans[0];

  const common = {
    price: selectedPlan?.price ?? NaN,
    selected: form.watch("selectedPlanId") === plan.id,
    setSelected: () => {
      TIER_SELECTION_PLAN_SELECT.track({
        planId: plan.id,
      });
      form.setValue("selectedPlanId", plan.id);
    },
  };

  switch (plan.id) {
    /*
     * Primary plans
     */
    case LITE:
      return {
        ...common,
        description: "Monitor your fields and keep detailed records.",
        features: [
          "Contracts with Automated Entry",
          "Field Mapping",
          "Scouting",
          "Soil Maps",
          "Recent Rainfall",
          "Rain & Heat History",
          "Inputs",
          "Futures Prices",
          "Monthly Rainfall Report",
        ],
      };

    case ESSENTIALS:
      return {
        ...common,
        description: "Get a detailed view of your fields, marketing position and local markets.",
        features: [
          "Marketing",
          "Activities",
          "Satellite Imagery",
          "Local Prices",
          "Field Attachments",
          "Field, Activity, and Marketing Reports",
        ],
        inclusionHeading: "Everything in Lite, plus:",
      };

    case PREMIUM:
      return {
        ...common,
        description: "Access everything you need to manage and monitor your entire operation.",
        features: [
          "Profit & Loss",
          "Machine Data Connections",
          "Land Costs",
          "Work Orders",
          "Custom Activities",
          "Land Cost Reports",
        ],
        inclusionHeading: "Everything in Essentials, plus:",
      };

    /*
     * Secondary plans
     */
    case RAINFALL:
      return {
        ...common,
        description: "Includes Field Mapping up to 10,000 acres, Recent Rainfall and Unlimited Users.",
        interval: "year",
        price: _.find(plan.plans, { interval: "year" })?.price ?? NaN,
      };
  }
};

export default usePlan;
