import React, { createContext, useContext, useMemo, useState } from "react";

import useCurrentCropYear from "hooks/useCurrentCropYear";
import { MARKETING_ONBOARDING_ADD_CROP } from "lib/metrics/events";

const MarketingPageContext = createContext({});

const MarketingPageContextProvider = ({ advisorCommodities, advisorRecommendations, children }) => {
  const [showCropModal, setShowCropModal] = useState(false);
  const [marketedCropModalData, setMarketedCropModalData] = useState({});
  const [cropYear] = useCurrentCropYear();

  const openCropEditModal = (marketedCrop) => {
    if (marketedCrop.id || marketedCrop.commodity) {
      setMarketedCropModalData(marketedCrop);
    } else {
      setMarketedCropModalData({ year: cropYear });
      MARKETING_ONBOARDING_ADD_CROP.track({
        modal_name: "onboarding_details",
        origin: "onboarding_details",
        crop_year: cropYear,
      });
    }
    setShowCropModal(true);
  };

  const closeCropEditModal = () => {
    setMarketedCropModalData({});
    setShowCropModal(false);
  };

  const context = useMemo(
    () => ({
      advisorCommodities,
      advisorRecommendations,
      closeCropEditModal,
      marketedCropModalData,
      openCropEditModal,
      setShowCropModal,
      showCropModal,
    }),
    [
      advisorCommodities,
      advisorRecommendations,
      closeCropEditModal,
      marketedCropModalData,
      openCropEditModal,
      setShowCropModal,
      showCropModal,
    ]
  );

  return <MarketingPageContext.Provider value={context}>{children}</MarketingPageContext.Provider>;
};

const useMarketingPageContext = () => useContext(MarketingPageContext);

export { MarketingPageContextProvider, MarketingPageContext, useMarketingPageContext };
