import BinView from "resources/bin";
import CropView from "resources/crop";
import _ from "underscore";

import App from "layout/app";
import SubRoute from "lib/SubRoute";
import History from "lib/history";

import ProductCrud from "components/product/crud";
import RecipeView from "components/product_recipe/crud";

const routeTo = (View) =>
  function (id, params) {
    let model, value;
    if (params == null) {
      params = {};
    }
    const { collection } = View;
    if (id != null && (model = collection.get(id)) == null) {
      return History.back();
    }
    if (params != null) {
      for (const key in params) {
        value = params[key];
        params[key] = +value || value;
      }
    }

    if (model == null) {
      model = new collection.model(params);
    }
    const view = new View({ model });

    view.render();
    if (typeof view.setUI === "function") {
      view.setUI();
    }

    view.once("finish", () => History.back());
    return App.setTitle((id != null ? "Edit " : "Add ") + _(collection.name).singularize().humanize().value());
  };

const routeToReact = (View) =>
  function (id, params) {
    if (params == null) {
      params = {};
    }
    const { collection } = View;

    if (id && collection && !collection.get(id)) {
      return History.back();
    }

    return App.display(
      View,
      Object.assign(
        {},
        {
          id: +id,
          data: params,
          onFinish() {
            return History.back();
          },
        }
      )
    );
  };

const ResourcesRouter = SubRoute.extend({
  routes: {
    "bins(/:id)": "bins",
    "crops(/:id)": "crops",
    "people(/:id)": "people",
    "products(/:id)": "products",
    "product_recipes(/:id)": "recipes",
  },

  bins: routeTo(BinView),
  crops: routeToReact(CropView),
  products: routeToReact(ProductCrud),
  recipes: routeToReact(RecipeView),
});

export default () => new ResourcesRouter("resources", { createTrailingSlashRoutes: true });
