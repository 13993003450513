import { gql } from "@apollo/client";

const updateActivityStatuses = gql`
  mutation updateActivityStatus($activityIds: [Int]!, $status: ActivityStatus!) {
    setActivityStatus(activities: $activityIds, status: $status) {
      ok
      activities {
        id
        status
      }
    }
  }
`;

export default updateActivityStatuses;
