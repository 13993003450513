import { css, StyleSheet } from "aphrodite/no-important";
import { isEmpty } from "lodash";
import Disclaimer from "marketing/components/Disclaimer";
import PropTypes from "prop-types";
import React from "react";

import { ALERTS_DISMISS_ALL_CLICKED, ALERTS_SETTINGS_ICON_CLICKED } from "lib/metrics/events";
import NotificationItem from "modules/notifications/components/NotificationItem";
import { withNotifications } from "modules/notifications/hoc/index";

import BlankSlate from "components/fl-ui/BlankSlate";
import Icon from "components/fl-ui/Icons/Icon";
import { Container, Header, Content, ContentMain } from "components/fl-ui/Layout";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import ToolTip from "components/fl-ui/Tooltip";
import { UIColors } from "components/fl-ui/colors";
import { Mixins, Spacing, Typography } from "components/fl-ui/constants";

const { sizes, weights } = Typography;

const styles = StyleSheet.create({
  blankSlateHeaderText: {
    fontSize: sizes.xl,
    fontWeight: 500,
    margin: "1em 0 0",
  },
  blankSlateBodyText: {
    lineHeight: "1.5em",
    margin: "1em 0",
    fontSize: sizes.lg,
  },
  blankSlateimage: {
    maxWidth: Mixins.toRem(40),
    margin: "auto",
  },
  errorText: {
    color: UIColors.red,
    marginBottom: Spacing.xxlarge,
    textAlign: "center",
  },
  header: {
    marginBottom: `0 !important`,
  },
  headerControlsWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    height: Mixins.toRem(40),
    "@media only screen and (max-width: 30em)": {
      position: "absolute",
      top: 0,
      right: 0,
    },
  },
  notificationIcon: {
    color: UIColors.medium,
    cursor: "pointer",
    display: "block",
    height: Spacing.xlarge,
    margin: `0 ${Spacing.xsmall}`,
    transition: "color 200ms ease-in-out",
    ":hover": {
      color: UIColors.dark,
    },
    width: Spacing.xlarge,
  },
  notificationItems: {
    marginTop: `-${Spacing.large}`,
  },
  notificationItem: {
    padding: `${Spacing.regular} 0 !important`,
    ":last-child": {
      border: 0,
    },
  },
});

const Dashboard = (props) => {
  const { data, dismissAllNotifications, dismissNotification } = props;
  const { error, loading, notifications } = data;
  const errorMessage = "An unexpected error occurred. Please try again later.";
  const handleDismissAllClick = () => {
    ALERTS_DISMISS_ALL_CLICKED.track({});
    dismissAllNotifications();
  };
  const handleSettingsClick = () => {
    ALERTS_SETTINGS_ICON_CLICKED.track({});
    window.location.href = "/#settings/alerts";
  };

  return (
    <Container>
      <Header title="Alerts" className={css(styles.header)}>
        <div className={css(styles.headerControlsWrapper)}>
          <ToolTip message="Dismiss All" placement="bottom">
            <Icon className={css(styles.notificationIcon)} icon="dismissAll" onClick={handleDismissAllClick} />
          </ToolTip>
          <ToolTip message="Settings" placement="bottom">
            <Icon className={css(styles.notificationIcon)} icon="settings" onClick={handleSettingsClick} />
          </ToolTip>
        </div>
      </Header>

      <Content>
        <ContentMain fullWidth>
          <LoadingWrapper isLoading={loading} loadingText="Loading your alerts...">
            {error && <p className={css(styles.errorText)}>{errorMessage}</p>}

            {isEmpty(notifications) ? (
              <BlankSlate>
                <Icon icon="alertInactive" className={css(styles.blankSlateimage)} />

                <p className={css(styles.blankSlateHeaderText)}>{`You're all caught up!`}</p>

                <p className={css(styles.blankSlateBodyText)}>{`When new alerts arrive they'll display here.`}</p>
              </BlankSlate>
            ) : (
              <div className={css(styles.notificationItems)}>
                {notifications.map((notification) => (
                  <NotificationItem
                    dismissNotification={dismissNotification}
                    key={notification.id}
                    notification={notification}
                    className={css(styles.notificationItem)}
                  />
                ))}
              </div>
            )}
          </LoadingWrapper>
          <Disclaimer />
        </ContentMain>
      </Content>
    </Container>
  );
};

Dashboard.propTypes = {
  error: PropTypes.any,
  loading: PropTypes.bool,
  notifications: PropTypes.arrayOf(PropTypes.object),
};

export default withNotifications(Dashboard);
