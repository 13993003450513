import { gql } from "@apollo/client";

export default gql`
  fragment MinimalCashContract on CashContract {
    buyer
    commodity {
      id
      name
    }
    contractDate
    contractFinalStatus
    contractNumber
    contractStatus
    contractType
    cropYear
    dataSource
    deliveryEndDate
    deliveryStartDate
    id
    marketedCrop {
      id
      name
      year
    }
    netPrice
    notes
    product
    quantity
    quantityCanceled
    quantityDelivered
    quantityPriced
    quantityUnit
  }
`;
