import _ from "lodash";

import utils from "lib/validation/utils";

export const isValid = (fields, errors = {}) => _.isEmpty(validate(fields, { errors }));

export const validate = (fields, options = {}) => {
  const errors = options.errors || {};
  const { itemizedProductionCosts, productionCost, productionCostUnit, useItemizedProductionCosts } = fields;

  if (useItemizedProductionCosts) {
    if (_.size(itemizedProductionCosts) === 0) {
      errors.itemizedProductionCosts = { value: itemizedProductionCosts };
    } else {
      const isValid = itemizedProductionCosts.every(({ category, cost }) => cost > 0 && category !== "SELECT");
      if (!isValid) {
        errors.itemizedProductionCosts = { value: itemizedProductionCosts, msg: "Amount > 0 and select category" };
      }
    }
  } else {
    if (!utils.isFloat(productionCost, { min: 0.01 })) {
      errors.productionCost = { value: productionCost, msg: "Production costs must be greater than 0.01" };
    }
    if (!["PER_ACRE", "TOTAL"].includes(productionCostUnit)) {
      errors.productionCostUnit = { value: productionCostUnit };
    }
  }

  return errors;
};

export default {
  isValid,
  validate,
};
