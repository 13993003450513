/* eslint-disable react/display-name */
import React from "react";
import router from "router";

import useCurrentEnterprise from "hooks/useCurrentEnterprise";

import LoadingWrapper from "components/fl-ui/LoadingWrapper";

const withZipCodeProtection = (Component) => (props) => {
  const { currentEnterprise, loading } = useCurrentEnterprise();
  if (loading) {
    return <LoadingWrapper isLoading />;
  } else if (!currentEnterprise.postalCode) {
    router.navigate("marketing", { replace: true, trigger: true });
  }

  return <Component {...props} />;
};

export default withZipCodeProtection;
