import createReactClass from "create-react-class";
import React from "react";

const imgStyle = { height: "100%", width: "100%" };
const iconPath = "/images/map";

const ICON = {
  crops: "/thumb-field-geometry@2x.png",
  imagery: "/thumb-crop-health@2x.png",
  "soil.ssurgo": "/thumb-soil@2x.png",
  "yield.512": "/thumb-yield@2x.png",
  seed: "/thumb-rx@2x.png",
  nitrogen: "/thumb-rx@2x.png",
};

const LayerImg = createReactClass({
  getDefaultProps() {
    return {
      style: imgStyle,
      size: 140,
    };
  },

  getInitialState() {
    return { src: "" };
  },

  componentDidMount() {
    return this.getSrc();
  },

  componentDidUpdate({ layer, src }) {
    if (layer !== this.props.layer || src !== this.props.src) {
      return this.getSrc();
    }
  },
  getSrc() {
    const { layer, src } = this.props;

    if (!layer && src) {
      return this.setSrc(src);
    }

    if (layer.layer?.source === "leaf") {
      return this.setSrc(layer.layer.details.url);
    }

    if (layer && layer.renderComposite) {
      return layer.renderComposite({ height: this.props.size, width: this.props.size }).then(
        (src) => {
          this.setSrc(src);
        },
        (error) => {
          console.error(error);
        }
      );
    }

    if (layer && layer._url) {
      return this.setSrc(layer._url);
    }

    return this.setSrc(iconPath + ICON[this.props.type]);
  },

  setSrc(src) {
    return this.setState({ src });
  },

  render() {
    return (
      <div
        style={{
          ...this.props.style,
          alignItems: "flex-end",
          backgroundImage: `url(${this.state.src})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          display: "flex",
        }}
      ></div>
    );
  },
});

export default LayerImg;
