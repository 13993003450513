/* eslint-disable react/display-name */
import { useMutation, useQuery } from "@apollo/client";
import _ from "lodash";
import React, { useCallback, useMemo } from "react";

import { marketingClient } from "collection/graphql/client";
import { CREATE_FIELD, DELETE_FIELDS, UPDATE_FIELD } from "collection/graphql/fields/mutations";
import { getBasicFieldsData } from "collection/graphql/fields/queries";
import useFieldGroups from "hooks/useFieldGroups";

import LoadingWrapper from "components/fl-ui/LoadingWrapper";

const useFieldData = (skip = false) => {
  const { createGroup, deleteGroup, loading: groupLoading, updateGroup } = useFieldGroups();
  const { data: fieldsData, loading, refetch } = useQuery(getBasicFieldsData, { client: marketingClient, skip });
  const fields = fieldsData?.fields;
  const mutationOptions = { client: marketingClient, refetchQueries: ["getBasicFieldsData", "GetFieldCrops"] };
  const [createField, { loading: isCreating }] = useMutation(CREATE_FIELD, mutationOptions);
  const [deleteFields, { loading: isDeleting }] = useMutation(DELETE_FIELDS, mutationOptions);
  const [updateField, { loading: isUpdating }] = useMutation(UPDATE_FIELD);

  const executeFieldDelete = useCallback(async (fieldIds) => {
    await deleteFields({ variables: { fieldIds } });
  }, []);

  const executeGroupCreate = async (name) => {
    const { data } = await createGroup({ variables: { group: { name } } });
    return data.createFieldGroup?.group;
  };

  const executeGroupDelete = useCallback(
    async (groupId, includeFields = false) => {
      if (includeFields) {
        const fieldIds = _.reduce(
          fields,
          (fieldIds, { group, id }) => {
            if (group?.id === groupId) {
              fieldIds.push(id);
            }

            return fieldIds;
          },
          []
        );

        await deleteFields({ variables: { fieldIds } });
      }

      await deleteGroup({ variables: { groupId } });
      await refetch();
    },
    [fields]
  );

  const executeGroupUpdate = useCallback(async (id, attributes) => {
    const group = { id, ...attributes };
    await updateGroup({ variables: { group } });
  }, []);

  const handleFieldCreate = async (field) => {
    try {
      const { data } = await createField({ variables: { field } });
      return data;
    } catch (error) {
      throw error;
    }
  };

  const handleFieldEdit = async (field) => {
    try {
      const response = await updateField({ variables: { field } });
      return response;
    } catch (error) {
      throw error;
    }
  };

  const isSaving = isCreating || isDeleting || isUpdating || groupLoading;
  const totalAcreage = useMemo(() => +_.sumBy(fields, "acreage").toFixed(), [fields || []]);
  const totalFieldCount = _.size(fields);

  return {
    createField: handleFieldCreate,
    editField: handleFieldEdit,
    executeFieldDelete,
    executeGroupCreate,
    executeGroupDelete,
    executeGroupUpdate,
    fields,
    isSaving,
    loading,
    totalAcreage,
    totalFieldCount,
  };
};

export const withBasicFields = (Component) => (props) => {
  const { fields, loading } = useFieldData();
  return !fields && loading ? <LoadingWrapper /> : <Component {...props} fields={fields} />;
};

export default useFieldData;
