import { useMutation } from "@apollo/client";

import { restClient } from "collection/graphql/client";

/**
 * Use `useMutation` instead.
 * @deprecated
 */
const useRestMutation = (mutation, options = {}) => {
  return useMutation(mutation, {
    ...options,
    client: restClient,
  });
};

export default useRestMutation;
