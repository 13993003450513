import leaflet from "leaflet";

const badges = new (leaflet.GeoJSON.extend({
  id: "badges",
  getLayerId(layer) {
    return layer?.feature?.properties?.field?.id;
  },

  options: {
    clickable: true,

    pointToLayer(feature, latLng) {
      const { abbr, color } = feature.properties;
      return new leaflet.Marker(latLng, {
        icon: new leaflet.DivIcon({
          iconSize: [30, 30],
          iconAnchor: [15, 15],
          className: `map-badge crop-color-${color}`,
          html: abbr,
        }),
      });
    },
  },

  addLayer(layer) {
    let id;
    if (this.hasLayer(layer)) {
      this.removeLayer(layer);
    }
    leaflet.GeoJSON.prototype.addLayer.call(this, layer);
    if ((id = this.getLayerId(layer))) {
      this._layers[id] = layer;
    }
  },

  removeLayer(layer) {
    if (!layer) {
      return;
    }
    try {
      leaflet.GeoJSON.prototype.removeLayer.call(this, layer);
    } catch (error) {
      // this can happen when removeLayer is called and the associated node is no longer in the DOM.
    } finally {
      delete this._layers[this.getLayerId(layer)];
    }
  },
}))();

export default badges;
