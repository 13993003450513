import _ from "lodash";

import { chooserStyles } from "components/fl-ui/Chooser";
import { UIColors } from "components/fl-ui/colors";
import { Spacing } from "components/fl-ui/constants";

export default {
  groupHeading: (base) => ({
    ...base,
    background: UIColors.secondary,
    padding: `${Spacing.small}`,
  }),
  menuList: (base) => ({
    ...base,
    boxShadow: "0px 10px 10px 0px rgba(0, 0, 0, 0.25)",
  }),
  option: (base, { isSelected }) => {
    const selectedStyles = isSelected
      ? {
          background: "#edf6ff",
          color: "inherit",
          borderTop: "2px solid #eef0f2",
        }
      : {};

    return {
      ...base,
      ...selectedStyles,
    };
  },
  ..._.pick(chooserStyles, ["multiValue", "multiValueLabel", "multiValueRemove"]),
};
