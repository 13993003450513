import { DocumentTransform } from "@apollo/client";
import { visit } from "graphql";
import { find, includes } from "lodash";

import cache from "collection/graphql/cache";
import { GET_CURRENT_ENTERPRISE } from "collection/graphql/enterprise/queries";
import EnterpriseFeaturesSchema from "collection/graphql/enterprise/schemas/EnterpriseFeaturesSchema";

/**
 * The `@requiresFeature` directive will only execute a query if the current enterprise
 * has the specified [feature]{@link EnterpriseFeature}. If the enterprise does not have
 * the feature a `@skip(if: true)` directive is added to the query.
 *
 * @type {DocumentTransform}
 * @example
 * query testQuery {
 *   getActivities @requiresFeature(feature: "activities") {
 *     id
 *   }
 * }
 * @see EnterpriseFeature
 */
export const requiresFeatureDirective = new DocumentTransform((document) => {
  return visit(document, {
    Directive(node) {
      if (node.name.value === "requiresFeature") {
        const feature = find(node.arguments, ["name.value", "feature"])?.value?.value;
        EnterpriseFeaturesSchema.validateSync(feature);

        const hasFeature = includes(
          cache.readQuery({ query: GET_CURRENT_ENTERPRISE }).currentEnterprise?.features,
          feature
        );
        if (!hasFeature) {
          // replace the @requiresFeature node with @skip(if: true)
          return {
            kind: "Directive",
            name: { kind: "Name", value: "skip" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "if" },
                value: { kind: "BooleanValue", value: true },
              },
            ],
          };
        } else {
          // remove the @requiresFeature node
          return null;
        }
      }
    },
  });
});
