import _ from "lodash";
import { useMemo } from "react";

import { useProductsById } from "collection/graphql/products/hooks";
import { GET_PRODUCT_RECIPES } from "collection/graphql/recipes/queries";
import useRestQuery from "hooks/useRestQuery";
import useUnits from "hooks/useUnits";

/**
 * A hook used for accessing product recipe data. Currently, that data is coming from a
 * legacy REST endpoint via apollo-link-rest.  Due to implementation limitations we need
 * to manually filter down the product recipe ingredients to their parent product
 */
const useProductRecipes = () => {
  const { loading: unitsLoading } = useUnits();
  const {
    data: recipeData,
    loading: recipesLoading,
    refetch,
  } = useRestQuery(GET_PRODUCT_RECIPES, { skip: unitsLoading });
  const { allIngredients, recipes } = recipeData || {};

  const productIds = useMemo(() => _.map(allIngredients, "productId"), [allIngredients]);
  const { products, loading: productsLoading } = useProductsById(productIds);
  const productMapById = useMemo(() => _.keyBy(products, "id"), [products]);

  const ingredients = useMemo(
    () =>
      _.map(allIngredients, (ingredient) => ({
        ...ingredient,
        product: productMapById[ingredient.productId] ?? null,
      })),
    [allIngredients, productMapById]
  );

  return {
    loading: productsLoading || recipesLoading || unitsLoading,
    refetch,

    recipes: useMemo(() => {
      if (allIngredients && recipes) {
        const ingredientMap = _.groupBy(ingredients, "productRecipeId");
        return _.sortBy(
          recipes.map((recipe) => ({
            ...recipe,
            description: _.map(ingredientMap[recipe.id], "product.name").join(", "),
            ingredients: ingredientMap[recipe.id] ?? [],
          })),
          ({ name }) => _.toLower(name)
        );
      }

      return [];
    }, [ingredients, recipes]),
  };
};

export default useProductRecipes;
