import { withWriteActivityWorkflow } from "activity/components/WriteActivityWorkflow";
import PropTypes from "prop-types";
import React from "react";

import useConnectivityChange from "hooks/useConnectivityChange";

import { Button } from "components/fl-ui";
import Icon from "components/fl-ui/Icons/Icon";
import { css, StyleSheet } from "components/fl-ui/aphrodite";
import { GreyColors } from "components/fl-ui/colors";

const styles = StyleSheet.create({
  container: {
    lineHeight: 1.5,
    margin: "auto",
    maxWidth: 560,
    paddingTop: 100,
    textAlign: "center",
  },
  icon: {
    color: GreyColors.smoke91,
    display: "inline-block",
    height: 68,
    textAlign: "center",
    width: 72,
  },
});

const BlankState = (props) => {
  const { startWorkflow, totalActivities } = props;
  const isOnline = useConnectivityChange();
  const title = totalActivities > 0 ? "No matching activities" : "Log an activity";
  const copy =
    totalActivities > 0
      ? `Refine your filters to see activities.`
      : `
    Easily log and review all your field work in one place to keep your records
    organized, secure, and accessible from any desktop or mobile device.
  `;

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.icon)}>
        <Icon icon="activities" />
      </div>

      <h2>{title}</h2>
      <p>{copy}</p>
      {totalActivities === 0 && (
        <Button color="primary" disabled={!isOnline} onClick={() => startWorkflow({ isWorkOrder: false })} size="mn">
          Log activity
        </Button>
      )}
    </div>
  );
};

BlankState.propTypes = {
  totalActivities: PropTypes.number.isRequired,
};

export default withWriteActivityWorkflow(BlankState);
