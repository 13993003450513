import AppComponent from "app";
import Backbone from "backbone";
import $ from "jquery";
import React from "react";
import { createRoot } from "react-dom/client";
import Modal from "ui/modal";
import _ from "underscore";

import render from "layout/templates";
import EventChannel from "lib/events";
import { page } from "lib/segment";

import BackboneView from "components/backbone";

const titleChannel = EventChannel.getChannel("documentTitle");
const toastChannel = EventChannel.getChannel("toast");

const AppInstance = new (Backbone.View.extend({
  el: "#layout",
  events: {
    "click .js-print": "print", // TODO not even sure this is used anywhere
    remove: "remove",
  },

  initialize() {
    this.root = createRoot(this.el);
  },

  render(children, props) {
    this.root.render(<AppComponent {...props}>{children}</AppComponent>);
  },

  display(Component, props = {}) {
    if (Component instanceof Backbone.View) {
      return this.displayBackboneView(Component, props);
    }

    const screenName = Component.screenName || props.screenName;
    if (screenName) {
      page(screenName);
    }

    this.setTitle(Component.title || screenName || "");
    this.render(<Component key={Date.now()} {...props} />);

    return Component;
  },

  displayBackboneView(view, props = {}) {
    const { screenName } = props;
    if (screenName) {
      page(screenName);
      this.setTitle(screenName);
    }

    // set "key" to ensure component refreshes when needed
    props = {
      key: Date.now(),
      ...props,
    };

    if (view.title != null) {
      this.setTitle(view.title);
    }
    props = { view };

    this.render(<BackboneView {...props} />);

    return view;
  },

  /**
   * @param {String} message
   */
  notify: (message) => {
    toastChannel.fire("warn", { message });
  },

  /**
   * Use the `documentTitle` event channel instead.
   * @deprecated
   * @param {string} title
   */
  setTitle(title) {
    titleChannel.fire("update", title);
  },

  print() {
    return window.print();
  },

  dialog(view, options) {
    if (options == null) {
      options = {};
    }
    if (options.screenName) {
      page(options.screenName);
    }

    options.content = view.el;
    const modal = new Modal(options);
    modal.render();

    // Forward the events
    view.listenTo(modal, "all", (type, options) => view.trigger(type, options));

    modal.listenTo(view, "safeclose", modal.setSafeClose);
    modal.listenTo(view, "complete", modal.close);
    view.remove = _.bind(view.remove, view);
    $("form input:text:first").focus();

    view.once("finish removed", function () {
      view.trigger("cancel finish");
      window.removeEventListener("hashchange", view.remove);
      return modal.remove();
    });

    _.defer(() => window.addEventListener("hashchange", view.remove));

    return view;
  },

  alert(data) {
    const action = $.Deferred();

    const $conf = $(
      render(
        "confirm",
        _.defaults(data, {
          title: "Hey there!",
          confirm: "OK",
          message: "",
          warning: false,
        })
      )
    );

    const modal = new Modal({
      content: $conf[0],
    });

    $conf.on("click", ".btn.js-ok", function () {
      action.resolve();
      return modal.remove();
    });

    $conf.on("click", ".btn.js-cancel", function () {
      action.reject();
      return modal.remove();
    });

    modal.render();
    return action.promise();
  },

  confirm(data) {
    return AppInstance.alert(
      _.defaults(data, {
        title: "Are you sure?",
        confirm: "OK",
        cancel: "Cancel",
        warning: true,
      })
    );
  },
}))();

export default AppInstance;
