/**
 * @description See the Activity object in grain marketing graphql schema; specifically the
 * isAAR, isMachineData, and machineDataProvider properties.
 * @typedef {'AAR'|'MANUAL'|'JOHN_DEERE'} ActivitySourceIdentifier
 */
export const AAR_SOURCE = "AAR";
export const MANUAL_SOURCE = "MANUAL";

/**
 * @description A superset of {@link ActivityStatus} objects from the grain marketing graphql schema.
 * @typedef {'CANCELLED'|'COMPLETED'|'DONE'|'OVERDUE'|'PLANNED'} ActivityStatusIdentifier
 */
export const CANCELLED = "CANCELLED";
export const COMPLETED = "COMPLETED";
export const DONE = "DONE";
export const OVERDUE = "OVERDUE";
export const PLANNED = "PLANNED";

/*
 * Activity types
 */
export const IRRIGATING = "IRRIGATING";
export const UNKNOWN = "UNKNOWN";

/*
 * Product Input types
 */
export const CHEMICAL = "CHEMICAL";
export const FERTILIZER = "FERTILIZER";
export const SEED = "SEED";
export const WATER = "WATER";

/*
 * Unit types
 */
export const COUNT = "COUNT";
export const VOLUME = "VOLUME";
export const WEIGHT = "WEIGHT";

// Crops (incomplete, add as needed)
export const CORN = 1;
export const FALLOW = -2;
export const SOYBEANS = 2;
export const RICE = 6101;
export const UNKNOWN_COMMODITY = -1;
// Wheat
export const HARD_RED_SPRING_WHEAT = 8367;
export const HARD_RED_WINTER_WHEAT = 8368;
export const SOFT_RED_WINTER_WHEAT = 7943;

/**
 * @description Can either be a Date instance or a date string, typically in the format YYYY-MM-DD.
 * @typedef {Date|String} DateLike
 */

/**
 * @description A sort identifier for activities
 * @typedef {'category'|'cropName'|'fieldName'|'dateAsc'|'date'|'status'} ActivitySort
 */

/**
 * @description Possible values for an activity's status. See `ActivityStatus` in grain-marketing graphql schema.
 * @typedef {'CANCELLED'|'DONE'|'PLANNED'} ActivityStatus
 */
