import { StyleSheet, css } from "aphrodite/no-important";
import classnames from "classnames";
import React from "react";

import { GreyColors } from "components/fl-ui/colors";

const styles = StyleSheet.create({
  svg: {
    display: "inline-block",
    width: "100%",
    height: "auto",
  },
});

const PlatformLogo = ({ store, ...props }) => {
  if (store === "ios") {
    return (
      <svg
        className={classnames(css(styles.svg), props.className)}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Apple Store</title>
        <g fill={GreyColors.smoke50} fillRule="nonzero">
          <path d="M17.23 12.615c0-2.384 2-3.538 2.078-3.615-1.154-1.615-2.846-1.846-3.462-1.923-1.461-.154-2.923.846-3.615.846-.77 0-1.923-.846-3.154-.846C7.462 7.077 6 8 5.154 9.462c-1.692 2.923-.462 7.23 1.154 9.615.846 1.154 1.769 2.461 3 2.385 1.23-.077 1.692-.77 3.154-.77 1.461 0 1.846.77 3.153.77 1.308 0 2.154-1.154 2.923-2.308.924-1.308 1.308-2.616 1.308-2.692-.077-.077-2.615-1-2.615-3.847zM14.846 5.538c.616-.846 1.077-1.923 1-3.076-.923.076-2.154.615-2.77 1.461-.614.692-1.153 1.846-1 2.923 1 .077 2.078-.538 2.77-1.308z" />
        </g>
      </svg>
    );
  }
  if (store === "android") {
    return (
      <svg
        className={classnames(css(styles.svg), props.className)}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Google Store</title>
        <defs>
          <linearGradient x1="32.072%" y1="-6.096%" x2="54.462%" y2="92.156%" id="a">
            <stop stopColor="#006884" offset="0%" />
            <stop stopColor="#8AD1D0" offset="100%" />
          </linearGradient>
          <linearGradient x1="-7.528%" y1="30.236%" x2="91.537%" y2="70.924%" id="b">
            <stop stopColor="#24BBB6" offset="0%" />
            <stop stopColor="#DBE692" offset="100%" />
          </linearGradient>
          <linearGradient x1="49.94%" y1="105.755%" x2="49.94%" y2="-3.127%" id="c">
            <stop stopColor="#FCC072" offset="0%" />
            <stop stopColor="#F58A5B" offset="100%" />
          </linearGradient>
          <linearGradient x1="11.149%" y1="89.509%" x2="90.437%" y2="27.928%" id="d">
            <stop stopColor="#712B8F" offset="0%" />
            <stop stopColor="#EA1D27" offset="100%" />
          </linearGradient>
        </defs>
        <g fill="none" fillRule="evenodd">
          <path
            d="M0 9.667V.917C0 .417.417 0 .833 0c.417 0 .584.083.834.25L17.75 9.083c.333.167.5.417.5.667 0 .25-.167.5-.5.667L1.667 19.25c-.167.083-.417.25-.834.25-.416 0-.833-.417-.833-.917V9.667z"
            fill="url(#a)"
            transform="translate(2 2)"
          />
          <path
            d="M10.417 9.75L.667 0h.166c.417 0 .584.083.834.25L13.5 6.75l-3.083 3z"
            fill="url(#b)"
            transform="translate(2 2)"
          />
          <path
            d="M13.417 12.667l-3-3L13.5 6.583l4.25 2.334c.333.166.5.416.5.666 0 .25-.167.5-.5.667l-4.333 2.417z"
            fill="url(#c)"
            transform="translate(2 2)"
          />
          <path
            d="M.75 19.333l9.667-9.666 3 3-11.75 6.416c-.25.167-.417.25-.917.25.083 0 .083 0 0 0z"
            fill="url(#d)"
            transform="translate(2 2)"
          />
        </g>
      </svg>
    );
  }
  return null;
};

export default PlatformLogo;
