const LegacyPersonTypePolicy = {
  fields: {
    fullName: {
      read(__, { readField }) {
        return [readField("firstName"), readField("lastName")].join(" ").trim();
      },
    },
    /**
     * isLegacyPaidSeat
     * users with a role of "service_providers" or "no_access" or "marketing_advisor" did not count toward paid seats
     * this no longer matters since all our plans are unlimited seats
     * but we limit the user count shown on the billing page still anyway
     */
    isLegacyPaidSeat: {
      read(__, { readField }) {
        const role = readField("role");
        const paidSeatRoles = ["admin", "manager", "contributor"];
        return paidSeatRoles.includes(role);
      },
    },
  },
};

export default LegacyPersonTypePolicy;
