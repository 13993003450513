import { css } from "aphrodite/no-important";
import React from "react";

import PayGateCommonBlankSlateBanner from "components/advertisements/banners/PayGateCommonBlankSlateBanner";
import styles from "components/advertisements/styles";

const OtherRevenueBlankSlateBanner = (props) => {
  const bannerProps = {
    ...props,
    actionButtonText: "Try Other Revenue for free",
    imagePath: "images/pay_gates/banners/otherRevenue.png",
    secondaryText:
      "Other Revenue is bundled with Profit & Loss and included in the Business plan. You can try it for free.",
    title: "With Other Revenue you can:",
  };

  return (
    <PayGateCommonBlankSlateBanner {...bannerProps}>
      <ul>
        <li className={css(styles.valueProp)}>Track revenue that is not related to crop sales, such as custom work.</li>
        <li className={css(styles.valueProp)}>
          Attribute revenue to your entire business as overhead, certain Marketing crops, or specific field crops.
        </li>
        <li className={css(styles.valueProp)}>Keep an accurate Profit & Loss statement.</li>
      </ul>
    </PayGateCommonBlankSlateBanner>
  );
};

export default OtherRevenueBlankSlateBanner;
