import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { Col, Row } from "react-styled-flexboxgrid";

import { Button } from "components/fl-ui";
import PopoverButton, { optionShape } from "components/fl-ui/Buttons/PopoverButton";
import PaneContents from "components/fl-ui/Layout/Pane/PaneContents";
import Tooltip from "components/fl-ui/Tooltip";
import { css, StyleSheet } from "components/fl-ui/aphrodite";
import { GreyColors, UIColors } from "components/fl-ui/colors";
import { Mixins, Spacing, Typography, Borders } from "components/fl-ui/constants";

const styles = StyleSheet.create({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: Spacing.regular,
    marginBottom: Spacing.regular,
    height: Mixins.toRem(40),
    "@media print": {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  iconButton: {
    display: "inline !important",
    width: Spacing.xxlarge,
    height: Spacing.xxlarge,
    padding: "0 !important",
    backgroundColor: UIColors.white,
    ":hover": {
      backgroundColor: GreyColors.smoke97,
      color: UIColors.primary,
    },
    "@media print": {
      display: "none !important",
      visibility: "hidden",
    },
  },
  h2: {
    display: "flex",
    alignItems: "center",
    fontSize: Typography.sizes.lg,
    fontWeight: Typography.weights.medium,
    flexGrow: 2,
    margin: 0,
    ":hover": {
      cursor: "pointer",
    },
    "@media print": {
      marginLeft: 0,
      alignItems: "left",
    },
  },
  h2Info: {
    marginLeft: Spacing.regular,
    fontSize: Typography.sizes.rg,
    fontWeight: Typography.weights.regular,
    color: Typography.colors.secondary,
    "@media only screen and (max-width: 47.9375em)": {
      display: "none",
    },
  },
  h2Text: {
    marginRight: Spacing.regular,
    marginLeft: Spacing.regular,
    lineHeight: 1.56,
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
    "@media print": {
      marginLeft: 0,
    },
  },
  h2TextTitle: {
    maxWidth: "200px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  rule: {
    flexGrow: 2,
    height: "1px",
    borderBottom: Borders.regular,
  },
});

class Pane extends Component {
  state = {
    collapsed: false,
  };

  render() {
    const { children, collapsible, menuOptions, noIndent, onToggle, subtitle } = this.props;
    const { collapsed } = this.state;
    const title = _.result(this.props, "title", "");

    let { tooltip } = this.props;
    if (collapsible && !tooltip) {
      tooltip = collapsed ? "Expand" : "Collapse";
    }

    const onClick = () => {
      this.setState({ collapsed: !collapsed }, onToggle.bind(this, !collapsed));
    };

    return (
      <Fragment>
        <Row>
          <Col className={css(styles.header)} xs>
            {collapsible && (
              <Tooltip message={tooltip}>
                <Button
                  className={css(styles.iconButton)}
                  hollow
                  icon={!collapsed ? "chevronDown" : "chevronRight"}
                  onClick={onClick}
                  size="rg"
                  type="button"
                />
              </Tooltip>
            )}

            <h2 className={css(styles.h2)}>
              <span className={css(styles.h2Text)} onClick={onClick}>
                <span className={css(styles.h2TextTitle)}>{title}</span>
                {subtitle && <span className={css(styles.h2Info)}>{subtitle}</span>}
              </span>
              <div className={css(styles.rule)} />
            </h2>

            {_.isArray(menuOptions) && (
              <div style={{ float: "right", marginLeft: "1rem" }}>
                <PopoverButton options={menuOptions} />
              </div>
            )}
          </Col>
        </Row>

        {!collapsed && <PaneContents noIndent={noIndent}>{children}</PaneContents>}
      </Fragment>
    );
  }
}

Pane.propTypes = {
  children: PropTypes.any.isRequired,
  collapsible: PropTypes.bool,
  menuOptions: PropTypes.arrayOf(optionShape),
  noIndent: PropTypes.bool,
  onToggle: PropTypes.func,
  subtitle: PropTypes.any,
  title: PropTypes.any,
  tooltip: PropTypes.string,
};

Pane.defaultProps = {
  collapsible: false,
  onToggle: () => {},
};

export default Pane;
