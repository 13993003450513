import { gql } from "@apollo/client";

import { MachineDataImportConfig } from "collection/graphql/integrations/fragments";

export default gql`
  query GetMachineDataImportConfig {
    config: getMachineDataImportConfig {
      ...Config
    }
  }
  ${MachineDataImportConfig}
`;
