import Color from "color";
import d3 from "d3";
import _ from "lodash";

import { CropsColorPalette } from "components/fl-ui/colors/palette";
import UIColors from "components/fl-ui/colors/ui";

/**
 * When compared to white using [color#contrast](https://github.com/Qix-/color/blob/1.0.3/index.js#L266), numbers
 * below this threshold are considered light in color; aka closer to white.
 *
 * @see https://www.w3.org/TR/WCAG20/#contrast-ratiodef
 */
const CONTRAST_THRESHOLD = 1.6;

export const isLight = (color) => Color(color).contrast(Color("#fff")) < CONTRAST_THRESHOLD;

export const darkenBy = (hex, percent) => {
  const { r, g, b } = hexToRgb(hex);
  const darken = (color) => color - color * percent;

  return d3.rgb(darken(r), darken(g), darken(b)).toString();
};

const lightenBy = (hex, percent) => {
  const { r, g, b } = hexToRgb(hex);
  const lighten = (color) => color + (255 - color) * percent;

  return d3.rgb(lighten(r), lighten(g), lighten(b)).toString();
};

/*
 * This should be handled by d3.color(), but it isn't working correctly in the version of
 * D3 we are using.  Once we have a working version we should refactor this to use D3.
 */
const hexToRgb = (hex) => {
  // normalize hex to an array of [rr, gg, bb]
  hex = hex.toLowerCase().replace("#", "").split("");
  if (hex.length === 3) {
    hex = hex.reduce((result, char) => result.concat(char, char), []);
  }
  hex = _.chunk(hex, 2).map((color) => color.join(""));

  return {
    r: parseInt(hex[0], 16),
    g: parseInt(hex[1], 16),
    b: parseInt(hex[2], 16),
  };
};

export const getCropColors = ({ color }) => {
  const cropColor = color || "grey";
  const hex = d3.hsl(CropsColorPalette[cropColor]).toString();
  return {
    priced: darkenBy(hex, 0.2),
    unprotected: lightenBy(hex, 0.6),
    hedged: hex,
  };
};

const getCropColorProps = (color) => ({
  // Background
  background: CropsColorPalette[color],
  fill: CropsColorPalette[color],
  stroke: CropsColorPalette[color],
  // Text
  color: isLight(CropsColorPalette[color]) ? "rgba(0,0,0,0.65)" : UIColors.white,
});

export const getCropStylesFromColor = (color = "none") => Crops.styles[color] || Crops.styles.none;

const Crops = {
  styles: {
    "almost-white": getCropColorProps("almost-white"),
    black: getCropColorProps("black"),
    blue: getCropColorProps("blue"),
    brown: getCropColorProps("brown"),
    "dark-blue": getCropColorProps("dark-blue"),
    "dark-brown": getCropColorProps("dark-brown"),
    "dark-green": getCropColorProps("dark-green"),
    "dark-purple": getCropColorProps("dark-purple"),
    "dark-red": getCropColorProps("dark-red"),
    "dark-tan": getCropColorProps("dark-tan"),
    "dark-yellow": getCropColorProps("dark-yellow"),
    green: getCropColorProps("green"),
    gray: getCropColorProps("black"),
    grey: getCropColorProps("black"),
    "light-blue": getCropColorProps("light-blue"),
    "light-brown": getCropColorProps("light-brown"),
    "light-green": getCropColorProps("light-green"),
    "light-purple": getCropColorProps("light-purple"),
    "light-red": getCropColorProps("light-red"),
    "light-tan": getCropColorProps("light-tan"),
    "light-yellow": getCropColorProps("light-yellow"),
    orange: getCropColorProps("orange"),
    purple: getCropColorProps("purple"),
    red: getCropColorProps("red"),
    "red-orange": getCropColorProps("red-orange"),
    "sky-blue": getCropColorProps("sky-blue"),
    tan: getCropColorProps("tan"),
    white: getCropColorProps("white"),
    yellow: getCropColorProps("yellow"),
    none: getCropColorProps("black"),
  },
};

export default Crops;
