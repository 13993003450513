import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import React from "react";

const Zoom = createReactClass({
  contextTypes: {
    map: PropTypes.object.isRequired,
  },

  onZoomIn() {
    return this.context.map.zoomIn();
  },

  onZoomOut() {
    return this.context.map.zoomOut();
  },

  render() {
    return (
      <div style={this.props.style} className="leaflet-control-zoom leaflet-bar leaflet-control">
        <a className="leaflet-control-zoom-in" title="Zoom in" onClick={this.onZoomIn}>
          {`\
    +\
    `}
        </a>
        <a className="leaflet-control-zoom-out" title="Zoom out" onClick={this.onZoomOut}>
          {`\
    -\
    `}
        </a>
      </div>
    );
  },
});

export default Zoom;
