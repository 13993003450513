import { hsl } from "d3";

const SMOKE_HUE = 220;
const SMOKE_SATURATION = 0.1;

const GreyColors = {
  /** RGB: #17191c */
  smoke10: hsl(SMOKE_HUE, SMOKE_SATURATION, 0.1).toString(),

  /** RGB: #2e3138 */
  smoke20: hsl(SMOKE_HUE, SMOKE_SATURATION, 0.2).toString(),

  /** RGB: #454a54 */
  smoke30: hsl(SMOKE_HUE, SMOKE_SATURATION, 0.3).toString(),

  /** RGB: #5c6370 */
  smoke40: hsl(SMOKE_HUE, SMOKE_SATURATION, 0.4).toString(),

  /** RGB: #737b8c */
  smoke50: hsl(SMOKE_HUE, SMOKE_SATURATION, 0.5).toString(),

  /** RGB: #8f96a3 */
  smoke60: hsl(SMOKE_HUE, SMOKE_SATURATION, 0.6).toString(),

  /** RGB: #abb0ba */
  smoke70: hsl(SMOKE_HUE, SMOKE_SATURATION, 0.7).toString(),

  /** RGB: #c7cad1 */
  smoke80: hsl(SMOKE_HUE, SMOKE_SATURATION, 0.8).toString(),

  /** RGB: #e3e5e8 */
  smoke90: hsl(SMOKE_HUE, SMOKE_SATURATION, 0.9).toString(),

  /** RGB: #e6e7ea */
  smoke91: hsl(SMOKE_HUE, SMOKE_SATURATION, 0.91).toString(),

  /** RGB: #e9eaed */
  smoke92: hsl(SMOKE_HUE, SMOKE_SATURATION, 0.92).toString(),

  /** RGB: #ebedef */
  smoke93: hsl(SMOKE_HUE, SMOKE_SATURATION, 0.93).toString(),

  /** RGB: #eeeff1 */
  smoke94: hsl(SMOKE_HUE, SMOKE_SATURATION, 0.94).toString(),

  /** RGB: #f1f2f4 */
  smoke95: hsl(SMOKE_HUE, SMOKE_SATURATION, 0.95).toString(),

  /** RGB: #f4f4f6 */
  smoke96: hsl(SMOKE_HUE, SMOKE_SATURATION, 0.96).toString(),

  /** RGB: #f7f7f8 */
  smoke97: hsl(SMOKE_HUE, SMOKE_SATURATION, 0.97).toString(),

  /** RGB: #f9fafa */
  smoke98: hsl(SMOKE_HUE, SMOKE_SATURATION, 0.98).toString(),

  /** RGB: #fcfcfd */
  smoke99: hsl(SMOKE_HUE, SMOKE_SATURATION, 0.99).toString(),
};

export default GreyColors;
