import { gql } from "@apollo/client";

import PartialProductFragment from "collection/graphql/fragments/PartialProductFragment";

export default gql`
  fragment ActivityListFragment on Activity {
    id
    created
    updated
    acreage
    date
    endTime
    geometry
    geometricCircumference
    isAAR
    isCancelled @client
    isDone @client
    isMachineData
    isOverdue @client
    isPlanned @client
    isWorkOrder
    startTime
    status
    statusText @client
    totalCostPerAcre
    totalCostTotal

    crop {
      id
      acreage
      commodity {
        id
        color
        name
      }
      cropYear
      field {
        id
        name
      }
    }
    field {
      id
      acreage
      name
    }
    images {
      filepickerId
      name
      s3Id
      url
    }
    implements {
      id
      name
    }
    inputs {
      id
      acreage
      amount
      amountRate
      amountUnit
      area
      areaUnit
      inputCostPerAcre
      inputCostPerUnit
      product {
        ...Product
      }
    }
    irrigation {
      id
      amount
      amountRate
      amountUnit
      duration
      durationUnit
      product {
        ...Product
      }
    }
    isMachineData
    machineDataProviders
    notes
    people {
      id
      name
    }
    tractors {
      id
      name
    }
    type {
      id
      category
      isArchived
      name
      operationalCost
      operationalCostRate
    }
  }

  ${PartialProductFragment}
`;
