import { gql } from "@apollo/client";

export default gql`
  fragment Priceline on Priceline {
    adjustments
    additionalAdjustments
    additionalAdjustmentsStatus
    adjustments
    basis
    basisStatus
    cashPrice
    cashPriceStatus
    currency
    deliveryEndDate
    deliveryLocation {
      id
      publicName
    }
    deliveryStartDate
    futuresPrice
    futuresPriceStatus
    id
    lineNumber
    notes
    priceDate
    quantityDelivered
    quantityPriced
    quantityUnit
    signatureStatus
    sequenceNumber
    underlying
  }
`;
