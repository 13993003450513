import { LEASE, LOAN, REVENUE } from "agreements/constants";
import withAgreementEnums from "agreements/hoc/withAgreementEnums";

const MAX_TERM_LENGTH = 2;

const AgreementRowSubtype = (props) => {
  const { agreementType, subType, terms } = props;
  const { leaseTermTypes, loanTypes, revenueSourceTypes } = props.agreementEnums;

  if (agreementType === LOAN) {
    return loanTypes[subType];
  }
  if (agreementType === REVENUE) {
    return revenueSourceTypes[subType];
  }
  if (agreementType !== LEASE) {
    return "Unknown";
  }

  const leaseTermMap = leaseTermTypes;
  let leaseTermCopy = terms.map(({ termType }) => leaseTermMap[termType]);
  leaseTermCopy = leaseTermCopy.filter((item) => item);
  const copySize = leaseTermCopy.length;
  if (copySize > MAX_TERM_LENGTH) {
    leaseTermCopy = leaseTermCopy.slice(0, MAX_TERM_LENGTH);
    leaseTermCopy.push(`+ ${copySize - leaseTermCopy.length} more`);
  }

  return leaseTermCopy.join(", ");
};

export default withAgreementEnums(AgreementRowSubtype);
