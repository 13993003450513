import getCategoryOptions from "activity/utils/getCategoryOptions";
import _ from "lodash";
import moment from "moment";

import ActivityInputInputSchema from "collection/graphql/activities/schemas/ActivityInputInputSchema";
import { COMPLETED, DONE, PLANNED } from "lib/constants";

export const formDefaults = {
  date: moment().format("YYYY-MM-DD"),
  equipment: [],
  images: [],
  inputs: [],
  irrigation: null,
  notes: "",
  people: [],
  status: COMPLETED,
};

const formatActivityEditData = (activity) => {
  const bulkAttributes = _.pick(activity, ["acreage", "crop", "geometry", "geometricCircumference"]);

  if (bulkAttributes.crop && !bulkAttributes.crop?.field && activity.field) {
    bulkAttributes.crop = { ...bulkAttributes.crop, field: activity.field };
  }

  const irrigation =
    activity.irrigation &&
    _.pick(activity.irrigation, ["amount", "amountRate", "amountUnit", "area", "areaUnit", "duration", "durationUnit"]);
  const status = activity.status === DONE ? COMPLETED : activity.status;

  const form = {
    ...formDefaults,
    ..._.pick(activity, ["id", "images", "isWorkOrder", "notes"]),
    date: moment(activity.date).format("YYYY-MM-DD"),
    equipment: activity.implements.concat(activity.tractors),
    // convert ActivityInput type to ActivityInputInput type
    inputs: _.map(activity.inputs, (input) => {
      return ActivityInputInputSchema.cast(
        {
          ...input,
          product: input.product.id,
          unitType: input.product.commodity?.seedUnitType || input.product.unitType,
        },
        { stripUnknown: true }
      );
    }),
    irrigation,
    people: _.map(activity.people, "id"),
    status,
    type: activity?.type?.id ?? null,
  };

  return { activityType: activity.type, bulkCreateAttributes: [bulkAttributes], form };
};

const formatActivityCreateData = (activityTypes, defaults) => {
  if (defaults) {
    const { crop, date, type } = defaults;
    const data = {};
    const form = { ...formDefaults };
    form.isWorkOrder = !!defaults.isWorkOrder;

    if (crop) {
      data.bulkCreateAttributes = [{ crop, acreage: crop.acreage }];
    }
    if (date) {
      form.date = date;
    }
    if (type) {
      const defaultType = getCategoryOptions(activityTypes, null).find(
        ({ category, id }) => id === type || category === type
      );

      form.type = defaultType?.id;
      data.activityType = defaultType;
    }
    if (form.isWorkOrder && !("status" in defaults)) {
      form.status = PLANNED;
    }

    data.form = form;
    return data;
  }

  return { form: formDefaults };
};

const formatActivityData = (activity, activityTypes, defaults) => {
  return !_.isNil(activity) ? formatActivityEditData(activity) : formatActivityCreateData(activityTypes, defaults);
};

export default formatActivityData;
