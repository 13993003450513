const ImplementTypePolicy = {
  fields: {
    equipmentType: {
      read() {
        return { name: "Implement" };
      },
    },
  },
};

export default ImplementTypePolicy;
