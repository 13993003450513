/* eslint-disable react/display-name */
import React from "react";

import useViewType from "hooks/useViewType";

const withViewType = (WrappedComponent) => (props) => {
  return <WrappedComponent {...props} viewType={useViewType()} />;
};

export default withViewType;
