import { useQuery } from "@apollo/client";

import { GET_INTEGRATIONS } from "collection/graphql/integrations/queries";
import usePermissions from "hooks/usePermissions";

const useIntegrations = () => {
  const { canRead } = usePermissions();
  const { data, loading } = useQuery(GET_INTEGRATIONS, {
    skip: !canRead("integrations"),
  });

  return {
    integrations: data?.integrations,
    loading,
  };
};

export default useIntegrations;
