import { useQuery } from "@apollo/client";
import moment from "moment";
import { useEffect, useState } from "react";

import { marketingClient } from "collection/graphql/client";
import { GET_FIELD_LAYERS } from "collection/graphql/fields/queries";

const useFieldLayers = ({ endDate, fieldId, skip, startDate, type }) => {
  const currentDate = moment().format("YYYY-MM-DD");
  const [layers, setLayers] = useState([]);
  const { data, loading } = useQuery(GET_FIELD_LAYERS, {
    client: marketingClient,
    skip,
    variables: { endDate: endDate || currentDate, field: fieldId, startDate, type },
  });

  useEffect(() => {
    if (!loading && data?.layers) {
      setLayers(data.layers);
    }
  }, [fieldId, data]);

  return { layers, loading };
};

export default useFieldLayers;
