import { gql } from "@apollo/client";

export default gql`
  query getStripeInvoiceHistory {
    invoices: getStripeInvoiceHistory @rest(type: "SubscriptionInvoice", path: "/subscriptions/invoices/13") {
      date
      hostedInvoiceUrl
      id
      paid
      periodEnd
      periodStart
      status
      total
      __typename
    }
  }
`;
