import React from "react";

import useCurrentCropYear from "hooks/useCurrentCropYear";
import useImmutableSet from "hooks/useImmutableSet";

/**
 * @typedef {ImmutableSet} ActivityBulkSelectionContext
 */

/**
 * @type {React.Context<ActivityBulkSelectionContext>}
 */
const context = React.createContext(undefined);

/**
 * Returns an {@link ImmutableSet} for working with activity bulk selection. Resets
 * the selection when the crop year changes.
 *
 * @private
 * @return {ActivityBulkSelectionContext}
 */
export const useActivityBulkSelection = () => {
  const bulkSelectionSet = useImmutableSet();
  React.useEffect(() => () => bulkSelectionSet.clear(), [useCurrentCropYear()[0]]);

  return bulkSelectionSet;
};

/**
 * @typedef {React.FC} ActivityBulkSelectionContextProvider
 */
/**
 * Context provider for {@link ActivityBulkSelectionContext}.
 *
 * @param {React.Props} props
 * @param {React.Children} props.children
 * @return {JSX.Element}
 * @type {ActivityBulkSelectionContextProvider}
 */
export const Provider = (props) => (
  <context.Provider value={useActivityBulkSelection()}>{props.children}</context.Provider>
);

/**
 * Provides bulk selection state and methods. Requires a {@link ActivityBulkSelectionContextProvider} context provider.
 * @returns {ImmutableSet}
 */
export default () => {
  const contextValue = React.useContext(context);
  if (!contextValue) {
    throw new Error("Invalid context: ActivityBulkSelectionContext expected");
  }

  return contextValue;
};
