import _ from "lodash";
import numeral from "numeral";

/**
 * Calculates the per acre cost for a set of activities. Total acreage is derived
 * from the activity set if one is not provided.
 *
 * @param {{ acreage, totalCostTotal }[]} activitySet
 * @param {Number} [totalAcreage=NaN]
 * @return {Number}
 */
const perAcreActivityCost = (activitySet, totalAcreage = NaN) => {
  if (_.isNaN(totalAcreage)) {
    totalAcreage = _.sumBy(activitySet, "acreage");
  }

  const total = _.sumBy(activitySet, ({ totalCostTotal }) => {
    return totalCostTotal / totalAcreage;
  });

  return +numeral(total).format("0.00");
};

export default perAcreActivityCost;
