import PropTypes from "prop-types";
import React from "react";
import CommonIntegrationDataRow from "settings/components/CommonIntegrationDataRow";

import usePermissions from "hooks/usePermissions";
import BushelConnectButton from "modules/settings/components/Integrations/components/BushelConnectButton";

import { LeftButton } from "components/fl-ui/Buttons";

const BushelIntegration = ({ integration }) => {
  const showButtons = usePermissions().canWrite("integrations");

  return (
    <CommonIntegrationDataRow
      bordered
      copy="Automatically import contract data from grain buyers in the Bushel network."
      imgSrc="/images/layout/bushel-logo.svg"
      integration={integration}
    >
      {showButtons && (
        <>
          <LeftButton color="primary" href="https://go.bushelfarm.com/connect-to-bushel-network" link target="_blank">
            Learn more
          </LeftButton>

          {integration.enabled && <BushelConnectButton color="primary" hollow icon="settings" source="Integrations" />}

          {!integration.enabled && (
            <BushelConnectButton color="primary" source="Integrations">
              Connect
            </BushelConnectButton>
          )}
        </>
      )}
    </CommonIntegrationDataRow>
  );
};

BushelIntegration.integrationId = "bushel";

BushelIntegration.propTypes = {
  integration: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default BushelIntegration;
