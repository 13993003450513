import { css } from "aphrodite";
import { capitalize } from "lodash";
import dollarFormat from "marketing/utils/dollarFormat";
import LogExpenseRevenueModalContainer from "profit_and_loss/components/LogExpenseRevenueModalContainer";
import SummaryTable from "profit_and_loss/components/SummaryTable";
import styles from "profit_and_loss/styles";
import { getFinanceNotation } from "profit_and_loss/utils";
import PropTypes from "prop-types";
import React, { useState } from "react";

import withViewType from "hoc/withViewType";

import { Button } from "components/fl-ui";
import { Card } from "components/fl-ui/Card";
import CropBadge from "components/fl-ui/CropBadge";
import Icon from "components/fl-ui/Icons/Icon";
import { Cluster } from "components/fl-ui/Layout";

const ExpenseRevenueSummary = ({ data, showDetails, viewType }) => {
  const { accountType, accounts, amountTotal, cropYear, name } = data;
  const [expand, setExpand] = useState(showDetails);
  const [modalData, setModalData] = useState(null);
  const emptyModalData = { account: { accountType } };

  const label = accountType === "EXPENSE" ? "Cost" : "Revenue";
  const amountTotalFormatted = getFinanceNotation(amountTotal) || "--";
  const accountTypeDisplay = accountType === "EXPENSE" ? `${accountType.toLowerCase()}s` : accountType.toLowerCase();
  const accountsData = accounts?.length
    ? accounts.map(({ account, amountTotal: amountTotalBreakdown }) => ({
        id: account.id,
        label: account.name,
        values: [dollarFormat(amountTotalBreakdown)],
      }))
    : [
        {
          label: (
            <span>
              <Button color="primary" link onClick={() => setModalData(emptyModalData)}>
                {`Log other ${accountTypeDisplay}`}
              </Button>
              {` to see a summary of your non-crop ${accountTypeDisplay} for the year`}
            </span>
          ),
          values: [""],
        },
      ];

  const colorMap = { EXPENSE: "dark-purple", REVENUE: "blue" };
  const header = { id: "expenseRevenueHeader", label: "Account", values: ["total cost"] };
  const tableAction = (
    <Button className={css(styles.normalFont)} color="primary" href={`#other_${accountTypeDisplay}`} link>
      {viewType === "desktop" ? `View other ${accountTypeDisplay}` : `Other ${accountTypeDisplay}`}
    </Button>
  );

  return (
    <div className={css(styles.cropSummaryContainer)}>
      <Card className={css(styles.cropSummaryCard)}>
        <Cluster between>
          <Cluster between>
            <CropBadge color={colorMap[accountType]} stripe />

            <div className={css(styles.boldVerticalStack)}>
              <span className={css(styles.cropSummaryHeaderSubtext)}>{cropYear}</span>
              <span className={css(styles.cropSummaryHeaderName)}>{name}</span>
            </div>
          </Cluster>

          <Cluster between>
            {viewType === "desktop" && (
              <Cluster between className={css(styles.metricContainer)}>
                <div className={css(styles.boldVerticalStack, styles.rightAlignedContainer)}>
                  <span className={css(styles.cropSummaryHeaderNumber)}>{amountTotalFormatted}</span>
                  <span className={css(styles.cropSummaryHeaderSubtext)}>{label}</span>
                </div>
              </Cluster>
            )}

            <Button className={css(styles.toggleButton)} color="white" onClick={() => setExpand(!expand)}>
              <Icon className={css(styles.toggleButtonIcon)} icon={expand ? "collapse" : "expand"} />
            </Button>
          </Cluster>
        </Cluster>
      </Card>

      {viewType === "mobile" && (
        <Card className={css(styles.cropSummaryCard)}>
          <SummaryTable className={css(styles.summaryTable)} data={[{ label, values: [amountTotalFormatted] }]} />
        </Card>
      )}

      {expand && (
        <Card className={css(styles.cropSummaryCard)}>
          <SummaryTable
            action={tableAction}
            data={accountsData}
            header={header}
            title={`${capitalize(accountType)} summary`}
          />
        </Card>
      )}

      {modalData && (
        <LogExpenseRevenueModalContainer cropYear={cropYear} data={modalData} onClose={() => setModalData(null)} />
      )}
    </div>
  );
};

ExpenseRevenueSummary.propTypes = {
  data: PropTypes.shape({
    accountType: PropTypes.string,
    accounts: PropTypes.arrayOf(PropTypes.object),
    amountTotal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    cropYear: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
  }).isRequired,
  showDetails: PropTypes.bool,
};

ExpenseRevenueSummary.defaultProps = {
  showDetails: true,
};

export default withViewType(ExpenseRevenueSummary);
