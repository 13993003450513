import React, { useState } from "react";
import AccountDeactivationConfirmation from "settings/components/AccountDeletion/AccountDeactivationConfirmation";
import AccountDeactivationModal from "settings/components/AccountDeletion/AccountDeactivationModal";

import { useAuth } from "collection/graphql/auth";
import { useAccountDeactivation } from "collection/graphql/people";
import useSubscriptionPlatform from "collection/graphql/subscription/useSubscriptionPlatform";

import { Button } from "components/fl-ui";
import LoadingDialog from "components/fl-ui/LoadingDialog";

const CONFIRM = "CONFIRM";
const COMPLETE = "COMPLETE";
const LOAD = "LOAD";

const DeactivateUserButton = () => {
  const deactivateAccount = useAccountDeactivation();
  const platform = useSubscriptionPlatform();
  const { currentUser } = useAuth();
  const [activeModal, setActiveModal] = useState(null);

  const onConfirm = async () => {
    try {
      await deactivateAccount();
    } catch (error) {
      // TODO handle any errors with error messaging
      console.info(error);
    }
  };

  const handleClose = () => setActiveModal(null);

  const handleConfirm = async () => {
    setActiveModal(LOAD);
    await onConfirm();
    setActiveModal(COMPLETE);
  };

  return (
    <>
      <Button color="danger" hollow onClick={() => setActiveModal(CONFIRM)}>
        Deactivate Account
      </Button>

      {activeModal === CONFIRM && (
        <AccountDeactivationModal
          onClose={handleClose}
          onConfirm={handleConfirm}
          platform={platform}
          role={currentUser.role}
        />
      )}

      {activeModal === LOAD && <LoadingDialog dialogMessage="Processing request" />}

      {activeModal === COMPLETE && <AccountDeactivationConfirmation platform={platform} role={currentUser.role} />}
    </>
  );
};

export default DeactivateUserButton;
