import { useFormContext } from "react-hook-form";

import { getCurrentSubscription } from "collection/graphql/subscription";
import useRestQuery from "hooks/useRestQuery";

const useSubmitButtonStatus = () => {
  const [agreedToTerms, billingInterval, selectedPlanId] = useFormContext().watch([
    "agreedToTerms",
    "billingInterval",
    "selectedPlanId",
  ]);
  const subscription = useRestQuery(getCurrentSubscription).data?.subscription;
  if (!subscription) {
    return false;
  }

  const { billingInterval: currentBillingInterval, currentPlan } = subscription;
  const containsPlanChange = selectedPlanId !== currentPlan.id;
  const containsIntervalChange = currentBillingInterval !== billingInterval;

  return [agreedToTerms, selectedPlanId, containsPlanChange || containsIntervalChange].every((x) => x);
};

export default useSubmitButtonStatus;
