import FieldChooser from "activity/components/choosers/FieldChooser";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Col, Row } from "react-styled-flexboxgrid";

import useViewType from "hooks/useViewType";
import MapUtils from "lib/map/utils";

import CommodityChooser from "components/commodity/CommodityChooser";
import CropDrawHandler from "components/crop/CropDrawHandler";
import { MapButton } from "components/crop/styledComponents";
import { getFeature } from "components/field/utils";
import { FormGroup, NumericInput, Select } from "components/fl-ui/Form";
import Tooltip from "components/fl-ui/Tooltip";

const CropAcreageSuffix = ({ handleClick, isDisabled }) => (
  <>
    ac
    <Tooltip message="Draw crop area on map">
      <MapButton color="white" disabled={isDisabled} icon="searchMap" onClick={handleClick} />
    </Tooltip>
  </>
);

CropAcreageSuffix.propTypes = {
  handleClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

const CropForm = ({
  acreage,
  commodityId,
  cropYear,
  fieldCrops,
  fieldId,
  fields,
  geometry,
  geometricCircumference,
  hasConflictingCrop,
  id,
  immutableCropYear,
  isSingle,
  onChange,
  years,
}) => {
  const [showMap, setShowMap] = useState(false);
  const isMobile = useViewType() === "mobile";
  const isBulkAdd = fields?.length > 1;
  const disableAcreageEdit = !fieldId;
  const chooserOptions = { isDisabled: isSingle, value: fields };

  if (!chooserOptions.isDisabled) {
    chooserOptions.onChange = (fields) => {
      const changes = { acreage: undefined, fields, geometry: null, geometricCircumference: null };
      const soleField = fields?.length === 1 && fields[0];

      if (soleField && soleField.acreage >= 0) {
        changes.acreage = +soleField.acreage;
      }

      onChange(changes);
    };
  } else {
    chooserOptions.hasAcreage = false;
  }

  const onGeometryChange = (geometry, geometricCircumference = null) => {
    onChange({
      acreage: geometry ? MapUtils.calculateAcreage(geometry) : acreage,
      geometry,
      geometricCircumference,
    });
  };

  return (
    <>
      <Row>
        <Col sm={6} xs={12}>
          <FormGroup label="Field">
            <FieldChooser {...chooserOptions} />
          </FormGroup>
        </Col>

        <Col sm={6} xs={12}>
          <FormGroup inputId="cropYear" label="Harvest year">
            <Select
              disabled={immutableCropYear}
              id="cropYear"
              name="cropYear"
              onChange={(event, { cropYear }) => {
                onChange({ cropYear: +cropYear });
              }}
              options={years}
              value={cropYear}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        {isBulkAdd && isMobile ? null : (
          <Col sm={6} xs={12}>
            <FormGroup inputId="acreage" label="Crop area">
              <NumericInput
                disabled={isBulkAdd}
                id="acreage"
                min={0}
                name="acreage"
                onChange={({ target: { value } }) => onChange({ acreage: value })}
                suffix={
                  <CropAcreageSuffix
                    handleClick={() => setShowMap((showMap) => !showMap)}
                    isDisabled={disableAcreageEdit}
                  />
                }
                value={acreage}
              />
            </FormGroup>
          </Col>
        )}

        <Col sm={6} xs={12}>
          <FormGroup inputId="cropCommodity" label="Commodity">
            <CommodityChooser
              commodityId={commodityId}
              onChange={({ id }) => onChange({ commodityId: id })}
              sanitizer={(item) => ({ ...item, isDisabled: hasConflictingCrop(item.id) })}
            />
          </FormGroup>
        </Col>
      </Row>

      {!disableAcreageEdit && showMap && (
        <Row>
          <Col xs={12}>
            <CropDrawHandler
              cropId={id}
              feature={getFeature({ geometry, geometricCircumference, id: id || fieldId })}
              fieldCrops={fieldCrops}
              fieldId={fieldId}
              onChange={onGeometryChange}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

CropForm.propTypes = {
  acreage: PropTypes.number,
  commodityId: PropTypes.number,
  cropYear: PropTypes.number,
  fieldCrops: PropTypes.arrayOf(PropTypes.object),
  fieldId: PropTypes.number,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      acreage: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
    })
  ),
  geometry: PropTypes.shape({
    coordinates: PropTypes.array,
    type: PropTypes.string,
  }),
  geometricCircumference: PropTypes.shape({
    geometry: PropTypes.object,
    properties: PropTypes.object,
    type: PropTypes.string,
  }),
  hasConflictingCrop: PropTypes.func.isRequired,
  id: PropTypes.number,
  immutableCropYear: PropTypes.bool,
  isSingle: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  years: PropTypes.arrayOf(PropTypes.object).isRequired,
};

CropForm.defaultProps = {
  immutableCropYear: false,
};

export default CropForm;
