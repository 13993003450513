import _ from "lodash";

import { getAllPeople } from "collection/graphql/people/queries";
import { getCurrentSubscription } from "collection/graphql/subscription";
import useRestQuery from "hooks/useRestQuery";
import useRestSuspenseQuery from "hooks/useRestSuspenseQuery";

/**
 * A hook that returns data used for managing limitations around adding/removing users.
 *
 * @return {UserLimits}
 * @typedef {Object} UserLimits
 * @property {Function} canAddRole - returns a Promise resolving with a boolean indicating whether or not a given role can be added to the current plan
 * @property {Boolean} enforce - a boolean indicating whether or not to enforce user limits
 */
const useUserLimits = () => {
  const { people } = useRestSuspenseQuery(getAllPeople).data;
  const totalServiceProviders = _.filter(people, { role: "service_provider" }).length;
  const subscription = useRestQuery(getCurrentSubscription).data?.subscription;
  const enforceUserLimit = subscription?.enforceMaxUsers ?? false;

  return {
    enforce: enforceUserLimit,

    /**
     * Returns true if a user of the given role can be added to the current subscription.
     * Most user limits removed in CORE-5545.
     * @param {UserRole} role
     * @return {Boolean}
     * @see https://farmlogs.atlassian.net/browse/CORE-5545
     */
    canAddRole: (role) => {
      if (!subscription?.currentPlan) {
        return false;
      } else if (!enforceUserLimit || role !== "service_provider") {
        return true;
      }

      const maxUsers = subscription.currentPlan.userLimits.serviceProviders?.max ?? Infinity;
      return totalServiceProviders < maxUsers;
    },
  };
};

export default useUserLimits;
