import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import React from "react";

import { Icon } from "components/fl-ui/Icons";

const ControlGroup = createReactClass({
  displayName: "ControlGroup",

  propTypes: {
    className: PropTypes.string,
  },

  getDefaultProps() {
    return { className: "" };
  },

  render() {
    const getIcon = (label) => {
      switch (label) {
        case "tractors":
          return "equipment";
        case "date":
          return "calendar";
        case "field":
        case "crop":
          return "fields";
        default:
          return label;
      }
    };
    return (
      <div className={`control-group icon-label ${this.props.className}`}>
        <label className={`control-label`} htmlFor={this.props.label}>
          <Icon icon={getIcon(this.props.label)} className={"control-label-icon"} />
        </label>
        <div className="js-controls controls">{this.props.children}</div>
      </div>
    );
  },
});

export default ControlGroup;
