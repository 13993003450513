import { useQuery } from "@apollo/client";

import { GET_FIELD_CROPS } from "collection/graphql/fields/queries";

const useFieldCropsByField = ({ fieldId, year }) => {
  const variables = year ? { fields: [fieldId], years: [year] } : { fields: [fieldId] };
  const { data, loading } = useQuery(GET_FIELD_CROPS, { variables });

  return { fieldCrops: data?.fieldCrops ?? [], loading };
};

export default useFieldCropsByField;
