/* eslint-disable react/display-name */
import _ from "lodash";
import React from "react";

import useYearFieldCrops from "modules/fields/hooks/useYearFieldCrops";

export default (WrappedComponent) => (props) => {
  let { fields } = props;
  const { onFilterUpdate, selectedFilters, year } = props;
  const { crops: fieldCrops } = useYearFieldCrops(year);

  /*
   * Filter by groups
   */
  const { groups } = selectedFilters;
  if (groups.length > 0) {
    fields = _.filter(fields, (field) => groups.includes(field.group?.id));
  }

  /*
   * Filter by crops/commodities
   */
  const { commodities } = selectedFilters;
  if (commodities.length) {
    const filteredFieldCrops = _.filter(fieldCrops, ({ commodity: { id } }) => commodities.includes(id));
    const matchingFieldIds = _.map(filteredFieldCrops, "field.id");
    fields = fields.filter((field) => matchingFieldIds.includes(field.id));
  }

  /*
   * Filter by irrigation
   */
  const { irrigation } = selectedFilters;
  if (irrigation.length === 1) {
    const irrigated = irrigation[0] === "irrigation_true";
    fields = _.filter(fields, ({ isIrrigated }) => !!isIrrigated === irrigated);
  }

  /*
   * Filter by provider
   */
  const { providers } = selectedFilters;
  if (providers?.length) {
    fields = _.filter(fields, ({ provider }) => providers.includes(provider));
  }

  /*
   * Filter by search text
   */
  const { search } = selectedFilters;
  if (search.length > 0) {
    const lowercaseSearch = search.toLowerCase();
    fields = _.filter(fields, (field) => field.name.toLowerCase().includes(lowercaseSearch));
  }

  return (
    <WrappedComponent
      {...props}
      fields={fields}
      filteredFieldCount={fields.length}
      onFilterUpdate={onFilterUpdate}
      selectedFilters={selectedFilters}
    />
  );
};
