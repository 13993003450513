import { CARGILL_ELEVATE } from "marketing/constants";
import ContractsRouter from "marketing/contracts/init";
import Dashboard from "marketing/routes/Dashboard";
import MarketedCropDetail from "marketing/routes/MarketedCropDetail";

import { isValidYear } from "hooks/useCurrentCropYear";
import App from "layout/app";
import SubRoute from "lib/SubRoute";

const ensureValidYear = (input) => {
  const year = parseInt(input, 10);
  return isValidYear(year) ? year : new Date().getFullYear();
};

const MarketingRouter = SubRoute.extend({
  routes: {
    "": "totalFarmOverview",
    advisor: "advisor",
    cargillelevate: function () {
      this.navigate("advisor", { replace: true, trigger: true });
    },
    ":year": "totalFarmOverview",
    ":year/:crop": "marketedCropDetail",
    ":year/:crop/dashboard": "marketedCropDetail",

    // Contracts
    ":year/:crop/contracts": "contractList",
    ":year/:crop/contracts(/*subroute)": "contracts",

    "*notFound": "notFound",
  },

  advisor: function () {
    // page title needs to be dynamically set given the payload of the `useFarmAdvisorConfig` hook.
    App.display(Dashboard, {
      requireValidCropYear: false,
      view: CARGILL_ELEVATE,
    });
  },

  display(view, year, cropId, title) {
    App.display(MarketedCropDetail, {
      marketedCropId: parseInt(cropId, 10),
      screenName: title,
      view: view,
      year,
    });

    App.setTitle(title);
  },

  totalFarmOverview: function (year) {
    App.display(Dashboard, {
      screenName: "Marketing",
      view: "Summary",
      year: ensureValidYear(year),
    });

    return App.setTitle("Marketing");
  },

  marketedCropDetail: function (year, cropId) {
    return this.display(MarketedCropDetail.DASHBOARD, ensureValidYear(year), cropId, "Marketing");
  },

  notFound: function () {
    this.navigate("marketing", { replace: true, trigger: true });
  },

  contractList: function (year, cropId) {
    return this.display(MarketedCropDetail.CONTRACTS, ensureValidYear(year), cropId, "Marketing");
  },

  contracts: function (year, cropId) {
    const validYear = ensureValidYear(year);
    const path = `marketing/${validYear}/${cropId}/contracts/`;

    if (!MarketingRouter.hasOwnProperty(path)) {
      // CORE-6250 without the setTimeout the contract routes do not work
      setTimeout(() => {
        const subrouter = new ContractsRouter(path, {
          year: validYear,
          cropId: cropId,
          createTrailingSlashRoutes: true,
        });
        MarketingRouter[path] = subrouter;
      }, 0);
    }
  },
});

export default () => new MarketingRouter("marketing", { createTrailingSlashRoutes: true });
