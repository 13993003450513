import { css } from "aphrodite/no-important";
import React from "react";

import PayGateCommonBlankSlateBanner from "components/advertisements/banners/PayGateCommonBlankSlateBanner";
import styles from "components/advertisements/styles";

const ConnectionsBlankSlateBanner = (props) => {
  props = {
    ...props,
    actionButtonText: "Try Connections for Free",
    imagePath: "images/pay_gates/banners/connections.svg",
    title: "Connections",
  };

  return (
    <PayGateCommonBlankSlateBanner {...props} size="lg">
      <p className={css(styles.secondaryText)}>
        With Connections you can link your accounts with other data providers to Bushel Farm, automatically and manually
        synchronize data, and get the full power of Bushel Farm with less data entry.
      </p>
    </PayGateCommonBlankSlateBanner>
  );
};

export default ConnectionsBlankSlateBanner;
