import { gql } from "@apollo/client";

import BasicNoteFragment from "collection/graphql/notes/fragments/BasicNote";

export default gql`
  mutation updateNote($input: UpdateNoteInput!) {
    note: updateNote(input: $input) @rest(type: "Note", path: "/api/notes/{args.input.id}", method: "PUT") {
      ...BasicNote
    }
  }

  ${BasicNoteFragment}
`;
