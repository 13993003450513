import PropTypes from "prop-types";
import React from "react";
import router from "router";
import DataImportExport from "settings/components/DataImportExport";

const Data = ({ subroute }) => {
  let initialModalId;
  if (["fieldBoundaries", "yieldData"].includes(subroute)) {
    initialModalId = subroute;
    router.navigate("settings/data", { replace: true, trigger: false });
  }

  return <DataImportExport initialModalId={initialModalId} />;
};

Data.propTypes = {
  subroute: PropTypes.string,
};

Data.defaultProps = {
  subroute: "",
};

Data.tabId = "data";
Data.title = "Data Settings";

export default Data;
