import { StyleSheet, css } from "aphrodite/no-important";
import React from "react";

import BlankSlate from "components/fl-ui/BlankSlate";
import Icon from "components/fl-ui/Icons/Icon";
import { GreyColors } from "components/fl-ui/colors";
import { Typography } from "components/fl-ui/constants";

const styles = StyleSheet.create({
  blankSlateHeaderText: {
    fontSize: Typography.sizes.xl,
    fontWeight: Typography.weights.medium,
    margin: "1em 0 0",
  },
  blankSlateBodyText: {
    lineHeight: "1.5em",
    margin: "1em 0",
    fontSize: Typography.sizes.lg,
  },
  blankSlateimage: {
    color: GreyColors.smoke90,
    margin: "auto",
    maxWidth: "7em",
  },
});

const RestrictedAccessBlankslate = () => (
  <BlankSlate>
    <div>
      <Icon icon="error" className={css(styles.blankSlateimage)} />

      <p className={css(styles.blankSlateHeaderText)}>You do not have access to this feature.</p>

      <p className={css(styles.blankSlateBodyText)}>Contact your farm administrator to request access.</p>
    </div>
  </BlankSlate>
);

export default RestrictedAccessBlankslate;
