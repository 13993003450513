import getBarStyles from "./getBarStyles";
import React from "react";

const IndependentProgressBar = ({ color, type, width = 0 }) => {
  const { borderRadius } = getBarStyles(type);
  return (
    <rect
      x="1"
      y="1"
      width={`${width}%`}
      height="100%"
      stroke="#ffffff"
      strokeWidth="1"
      rx={borderRadius}
      ry={borderRadius}
      fill={color}
    />
  );
};

export { IndependentProgressBar };
