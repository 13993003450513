import { useMemo, useState } from "react";

/**
 * A custom hook to manage boolean state. It provides methods to explicitly set the state to true or false, or to toggle the current state.
 *
 * @param {boolean} [initialState=false] - The initial state of the boolean value. Defaults to `false` if not provided.
 * @returns {[boolean, {on: Function, off: Function, toggle: Function}]} A tuple where the first element is the current state and the second element is an object with three functions to mutate the state: `on`, `off`, and `toggle`.
 * `on` sets the state to true, `off` sets the state to false, and `toggle` toggles the state between true and false.
 *
 * @example
 * // Example usage of the `useBoolean` hook
 * const [isToggled, { on, off, toggle }] = useBoolean();
 */
const useBoolean = (initialState = false) => {
  const [state, setState] = useState(!!initialState);

  return useMemo(() => {
    return [
      state,
      {
        off: () => setState(false),
        on: () => setState(true),
        toggle: () => setState((value) => !value),
      },
    ];
  }, [state]);
};

export default useBoolean;
