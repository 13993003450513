import { css } from "aphrodite/no-important";
import React from "react";

import PayGateCommonBlankSlateBanner from "components/advertisements/banners/PayGateCommonBlankSlateBanner";
import styles from "components/advertisements/styles";

const LocalPricesBlankSlateBanner = (props) => {
  props = {
    ...props,
    actionButtonText: "Try Local Prices for free",
    secondaryText: "Local Prices is included in the Business plan, and you can try it for free.",
    imagePath: "images/pay_gates/banners/local_prices.png",
    title: "With Local Prices you can:",
  };

  return (
    <PayGateCommonBlankSlateBanner {...props}>
      <ul>
        <li className={css(styles.valueProp)}>Find where you can get the best price for your crop.</li>
        <li className={css(styles.valueProp)}>Know the optimal time to lock in the basis with your buyers.</li>
        <li className={css(styles.valueProp)}>Sort bids based on price and distance.</li>
        <li className={css(styles.valueProp)}>Click any location to see all posted bids.</li>
      </ul>
    </PayGateCommonBlankSlateBanner>
  );
};

export default LocalPricesBlankSlateBanner;
