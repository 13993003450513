import { gql } from "@apollo/client";

export default gql`
  query getPricelineLocations {
    getPricelineLocations {
      id
      publicName
    }
  }
`;
