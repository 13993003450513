import { readCurrentCropYear } from "hooks/useCurrentCropYear";
import {
  ALERTS_ACTION_VIEW_CROP,
  ALERTS_ACTION_VIEW_MARKETING,
  ALERTS_ACTION_VIEW_RAINFALL,
  ALERTS_ACTION_VIEW_WORKORDER,
  ALERTS_VIEW_ALL_CLICKED,
} from "lib/metrics/events";

const redirect = (path) => (window.location.href = path);

export default function redirectToAlert(notification) {
  const { data = {}, user, type } = notification;
  switch (type) {
    case "market-prices":
      const { marketedCrops } = data;
      const marketing = `/#marketing/${readCurrentCropYear()}`;
      if (marketedCrops.length > 1) {
        ALERTS_ACTION_VIEW_MARKETING.track({});
        redirect(marketing);
      } else {
        ALERTS_ACTION_VIEW_CROP.track({});
        redirect(`${marketing}/${marketedCrops[0]}`);
      }
      break;

    case "rainfall":
      const fieldId = notification.data.fieldId || null;
      ALERTS_ACTION_VIEW_RAINFALL.track({});
      if (fieldId) {
        window.location.href = `/#fields/${fieldId}/rainfall`;
      } else {
        window.location.href = "/#fields";
      }
      break;

    case "rollup":
      ALERTS_VIEW_ALL_CLICKED.track({});
      window.location.href = "/#alerts";
      break;

    case "work-orders":
      ALERTS_ACTION_VIEW_WORKORDER.track({});
      window.location = `/#activity?people[0]=${user.id}&sortCriteria=status`;
      break;

    default:
      console.error(`Unrecognized notification type: ${type}`);
  }
}
