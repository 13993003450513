import { gql } from "@apollo/client";

import { ActivityListFragment } from "collection/graphql/activities/fragments";

export default gql`
  mutation createActivity($activity: CreateActivityInput!) {
    createActivity(activity: $activity) {
      ok
      activity {
        ...ActivityListFragment
      }
    }
  }

  ${ActivityListFragment}
`;
