import { gql } from "@apollo/client";

import { ActivityListFragment } from "collection/graphql/activities/fragments";

export default gql`
  mutation updateActivity($activity: UpdateActivityInput!) {
    updateActivity(activity: $activity) {
      ok
      activity {
        ...ActivityListFragment
      }
    }
  }

  ${ActivityListFragment}
`;
