import PropTypes from "prop-types";
import React from "react";

import useKeyedList from "hooks/useKeyedList";

import { Button } from "components/fl-ui";
import { Cluster, Stack } from "components/fl-ui/Layout";
import ProductChooser from "components/product/ProductChooser";

const PurchaseInputChooser = ({ inputs, isEdit = false, onChange }) => {
  const inputList = useKeyedList(inputs, onChange);
  const defaultInput = () => ({
    amount: undefined,
    amountUnit: "",
    cost: "",
    costRate: "",
    product: undefined,
    unitType: "",
  });

  return (
    <Stack>
      {inputList.map((input, key) => {
        return (
          <ProductChooser
            key={key}
            input={input}
            inputType="PURCHASE"
            onChange={(input) => inputList.update(key, input)}
            onRemove={!isEdit ? () => inputList.remove(key) : undefined}
          />
        );
      })}

      {!isEdit && (
        <Cluster>
          <Button color="secondary" onClick={() => inputList.append(defaultInput())}>
            Add input
          </Button>
        </Cluster>
      )}
    </Stack>
  );
};

PurchaseInputChooser.propTypes = {
  inputs: PropTypes.array.isRequired,
  isEdit: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default PurchaseInputChooser;
