import * as Sentry from "@sentry/react";
import localForage from "localforage";

export const createInstance = (config) => {
  try {
    return localForage.createInstance(config);
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setExtra("logger", "storage");
      scope.setExtra("phase", "initialization");
      Sentry.captureException(error);
    });

    return {
      clear: () => Promise.reject(new Error("Call to clear() failed due to initialization error")),
      getItem: () => Promise.reject(new Error("Call to getItem() failed due to initialization error")),
      removeItem: () => Promise.reject(new Error("Call to removeItem() failed due to initialization error")),
      setItem: () => Promise.reject(new Error("Call to setItem() failed due to initialization error")),
    };
  }
};

export const appDataDB = createInstance({
  name: "FarmLogs",
  storeName: "AppData",
});

const handledMethods = ["clear", "getItem", "removeItem", "setItem"];
/**
 * @typedef {Object} Storage
 * @property {Function<Promise>} clear
 * @property {Function<Promise>} getItem
 * @property {Function<Promise>} removeItem
 * @property {Function<Promise>} setItem
 */
export default new Proxy(appDataDB, {
  get(target, prop) {
    if (typeof target[prop] === "function") {
      return new Proxy(target[prop], {
        apply: (target, thisArg, argumentsList) => {
          if (!handledMethods.includes(prop)) {
            return Reflect.apply(target, thisArg, argumentsList);
          }

          return Reflect.apply(target, thisArg, argumentsList).catch((error) => {
            Sentry.withScope((scope) => {
              scope.setExtra("logger", "storage");
              scope.setExtra("phase", "default");
              scope.setExtra("method", prop);
              scope.setExtra("arguments", argumentsList);
              Sentry.captureException(error);
            });

            return Promise.reject(error);
          });
        },
      });
    } else {
      return Reflect.get(target, prop);
    }
  },
});
