import { gql } from "@apollo/client";

import tractorFragment from "collection/graphql/equipment/fragments/TractorDetailFragment";

export default gql`
  query getTractorById($id: Int!) {
    tractor: getTractorById(id: $id)
      @requiresFeature(feature: "equipment")
      @requiresPermission(feature: "equipment", actions: ["read"]) {
      ...TractorDetail
    }
  }

  ${tractorFragment}
`;
