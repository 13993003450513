import MobileForcedAppUpdateConfigSchema from "mobile/MobileForcedAppUpdateConfigSchema";
import { appVersion, getPlatform, isNative } from "mobile/mobileManager";
import satisfies from "semver/functions/satisfies";

const platformSpecificDetails = (config) => {
  switch (getPlatform()) {
    case "android":
      return config.android;
    case "ios":
      return config.ios;
    case "web":
      return undefined;
  }
};

const getDeprecatedRange = (config) => {
  if (MobileForcedAppUpdateConfigSchema.isValidSync(config)) {
    const details = platformSpecificDetails(config);
    return details.deprecatedRange;
  }

  return undefined;
};

/**
 * Takes in the launch darkly config and determines the current state app
 * @param {MobileForcedAppUpdateConfigSchema} config
 * @returns {"SUPPORTED"|"DEPRECATED"|undefined}
 */
const getMobileForcedAppUpdateState = async (config) => {
  if (!isNative() || !config) {
    return undefined;
  }

  const currentAppVersion = await appVersion();
  const deprecatedRange = getDeprecatedRange(config);

  if (!currentAppVersion) {
    return undefined;
  }

  const data = {
    appIsDeprecated: !!deprecatedRange && satisfies(currentAppVersion, deprecatedRange),
  };

  return data;
};

export default getMobileForcedAppUpdateState;
