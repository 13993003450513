import PropTypes from "prop-types";
import React from "react";

import { DONE, OVERDUE, PLANNED } from "lib/constants";

import { TagSelectGroup } from "components/fl-ui/Form";

const ActivityStatusButtons = (props) => {
  const { onChange, value } = props;

  const statusCopy = {
    [DONE]: "Done",
    [OVERDUE]: "Overdue",
    [PLANNED]: "Planned",
  };
  const options = [DONE, OVERDUE, PLANNED].map((value) => ({
    label: statusCopy[value],
    value,
  }));

  return (
    <TagSelectGroup
      id="status"
      isMulti
      label="Status"
      onChange={(status) => onChange(status)}
      options={options}
      showBulkSelect
      value={value}
    />
  );
};

ActivityStatusButtons.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.oneOf([DONE, OVERDUE, PLANNED])).isRequired,
};

export default ActivityStatusButtons;
