import PropTypes from "prop-types";
import React from "react";

import Select from "components/fl-ui/Form/Select";

const BasicSelect = ({ onChange, ...props }) => {
  props.minimal = true;
  props.onChange = (event, payload) => onChange(payload);

  return <Select {...props} />;
};

BasicSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.any,
};

export default BasicSelect;
