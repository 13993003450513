const TractorTypePolicy = {
  fields: {
    equipmentType: {
      read() {
        return { name: "Tractor" };
      },
    },
  },
};

export default TractorTypePolicy;
