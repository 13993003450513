import PropTypes from "prop-types";
import React from "react";

import withViewType from "hoc/withViewType";
import AgreementCard from "modules/agreements/components/AgreementCard";
import AgreementRow from "modules/agreements/components/AgreementRow";

import { Button } from "components/fl-ui";
import Pane from "components/fl-ui/Layout/Pane";
import { Typography } from "components/fl-ui/constants";

class GroupedAgreements extends React.Component {
  state = {
    showAllAgreements: false,
  };

  render() {
    const { agreements, groupName, viewType, ...props } = this.props;
    const { showAllAgreements } = this.state;
    const agreementCount = agreements.length;
    const displayedAgreements = !showAllAgreements && agreementCount > 5 ? agreements.slice(0, 5) : agreements;
    const remainingAgreements = agreementCount - 5;

    // TODO: mobile view
    return (
      <Pane
        collapsible
        subtitle={`${agreementCount} ${agreementCount > 1 ? "agreements" : "agreement"}`}
        title={_.capitalize(groupName)}
      >
        {viewType !== "mobile" &&
          displayedAgreements.map((agreement, index) => (
            <AgreementRow {...agreement} key={`${agreement.id}-${index}`} {...props} />
          ))}

        {viewType === "mobile" &&
          displayedAgreements.map((agreement, index) => (
            <AgreementCard {...agreement} key={`${agreement.id}-${index}`} {...props} />
          ))}

        {remainingAgreements > 0 && (
          <Button
            color="primary"
            link
            onClick={() => this.setState(() => ({ showAllAgreements: !showAllAgreements }))}
            style={{ fontWeight: Typography.weights.regular }}
          >
            {`${showAllAgreements ? "Hide" : "Show"} ${remainingAgreements} more...`}
          </Button>
        )}
      </Pane>
    );
  }
}

GroupedAgreements.propTypes = {
  agreements: PropTypes.arrayOf(PropTypes.object).isRequired,
  groupName: PropTypes.string,
};

export default withViewType(GroupedAgreements);
