import { gql } from "@apollo/client";

export default gql`
  query getFieldCrop($cropId: Int!) {
    fieldCrop: getFieldCrop(cropId: $cropId) {
      ...FieldCrop
      field {
        ...BasicField
      }
    }
  }
`;
