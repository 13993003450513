import { CapsHeader } from "../Helpers";
import FieldChooser from "activity/components/choosers/FieldChooser";
import PropTypes from "prop-types";
import React, { useState } from "react";

/**
 * Fields filter parameter, allows one or more fields to be selected.
 */

const FieldFilter = ({ onParamChanged, param }) => {
  const [selectedFields, setSelectedFields] = useState([]);
  const handleFieldsChange = (fields) => {
    setSelectedFields(fields);
    onParamChanged({ id: param.id, value: fields.map(({ id }) => id).join() });
  };

  return (
    <div key={param.id}>
      <CapsHeader>{param.title}</CapsHeader>
      <FieldChooser hasAcreage={false} onChange={handleFieldsChange} value={selectedFields} />
    </div>
  );
};

FieldFilter.propTypes = {
  param: PropTypes.object.isRequired,
  onParamChanged: PropTypes.func.isRequired,
};

export default FieldFilter;
