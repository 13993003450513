import { useQuery } from "@apollo/client";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import ReactSelect from "react-select";

import { GET_ALL_MARKETED_CROPS } from "collection/graphql/marketing";

import { chooserStyles } from "components/fl-ui/Chooser";
import { ClearAllFormGroup } from "components/fl-ui/Form";

const ContractMarketedCropFilter = ({ onChange, value }) => {
  const { data, loading } = useQuery(GET_ALL_MARKETED_CROPS);
  const options = useMemo(() => {
    return _.sortBy(data?.marketedCrops, ["year", "name"]).map((crop) => ({
      label: crop.name,
      value: crop.id,
    }));
  }, [data?.marketedCrops]);

  const selectedOptions = useMemo(() => {
    return _.filter(options, (option) => value.includes(option.value));
  }, [options, value]);

  return (
    <ClearAllFormGroup label="Marketing Crop" onClear={() => onChange([])}>
      <ReactSelect
        disabled={loading}
        isMulti
        placeholder="Select marketing crop"
        onChange={(selections) => onChange(_.map(selections, "value"))}
        options={options}
        styles={chooserStyles}
        value={selectedOptions}
      />
    </ClearAllFormGroup>
  );
};

ContractMarketedCropFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired,
};

export default ContractMarketedCropFilter;
