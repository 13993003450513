import { css } from "aphrodite";
import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";

import styles from "modules/carbon_enrollment/styles";

import { Checkbox, Radio, RadioGroup } from "components/fl-ui/Form";

const Confirmation = ({ copy, clientName, CustomFields, data, minimal, onInputChange, nextSteps, radios }) => (
  <>
    {!!(copy || radios) && (
      <Row>
        <Col xs={12}>
          <p className={css(styles.steps)}>Step 3</p>
          <p className={css(styles.heading)}>Confirm your first carbon credit crop season</p>
          <p className={css(styles.copy)}>{copy}</p>

          <RadioGroup name="has_made_practice_change" onChange={({ name, value }) => onInputChange({ [name]: value })}>
            {radios.map(({ label, value }) => (
              <Radio
                className={css(
                  styles.booleanSelectContainer,
                  data.has_made_practice_change === value && styles.selected
                )}
                key={value}
                label={label}
                value={value}
              />
            ))}
          </RadioGroup>
        </Col>
      </Row>
    )}

    <Row className={css(styles.checkboxRowMinimal, !minimal && styles.checkboxRow)}>
      <Col xs={12}>
        <p className={css(styles.heading)}>What to expect next</p>

        {nextSteps}

        {CustomFields && <CustomFields onInputChange={onInputChange} />}

        <div className={css(styles.booleanSelectContainer, data.checked && styles.selected, styles.checkboxRow)}>
          <label htmlFor="agree">
            <Checkbox
              checked={data.checked}
              id="agree"
              name="agree"
              onChange={({ target: { checked } }) => onInputChange({ checked })}
            />
            <span className={css(styles.copy, styles.list)}>
              {"By clicking Confirm below, I agree to give Bushel Farm permission to share my information, "}
              {`boundaries, and field records with ${clientName}.`}
            </span>
          </label>
        </div>
      </Col>
    </Row>
  </>
);

Confirmation.propTypes = {
  copy: PropTypes.node,
  clientName: PropTypes.string.isRequired,
  CustomFields: PropTypes.any,
  data: PropTypes.object.isRequired,
  minimal: PropTypes.bool,
  onInputChange: PropTypes.func.isRequired,
  nextSteps: PropTypes.node.isRequired,
  radios: PropTypes.arrayOf(PropTypes.object),
};

export default Confirmation;
