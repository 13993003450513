import { css } from "aphrodite";
import { MarketedCropCard } from "marketing/cards";
import AddCropBlankSlate from "marketing/components/AddCropBlankSlate";
import { dashboardStyles as styles } from "marketing/styles";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import useCurrentEnterprise from "hooks/useCurrentEnterprise";

import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import ZipCodeInput from "components/fl-ui/ZipCodeInput";
import { Spacing } from "components/fl-ui/constants";

const CardGrid = styled.div`
  display: grid;
  grid-gap: ${Spacing.medium};
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: ${Spacing.large};
  margin-top: ${Spacing.large};

  @media only screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;

const TotalFarm = ({ loading, marketedCrops }) => {
  const { currentEnterprise, loading: enterpriseLoading } = useCurrentEnterprise();
  const zipCode = currentEnterprise?.postalCode;

  if (enterpriseLoading || loading) {
    return <LoadingWrapper isLoading />;
  }

  if (!zipCode) {
    return (
      <div className={css(styles.blankslateContainer)}>
        <h2 className={css(styles.blankslateTitle)}>Where is your farm located?</h2>
        <p className={css(styles.blankslateText)}>
          {
            "Bushel Farm uses your postal code to calculate average basis and county yield averages for your crops. You can always change this later in your "
          }
          <a className={css(styles.blankslateLink)} href="/#settings/farm">
            Farm settings
          </a>
          .
        </p>
        <ZipCodeInput defaultValue={zipCode} />
      </div>
    );
  }

  if (marketedCrops.length > 0) {
    return (
      <CardGrid>
        {marketedCrops.map((marketedCrop) => (
          <MarketedCropCard
            key={`crop-${marketedCrop?.id || marketedCrop?.name}`}
            abbreviatedMarketedCrop={marketedCrop}
          />
        ))}
      </CardGrid>
    );
  }

  return <AddCropBlankSlate />;
};

TotalFarm.propTypes = {
  loading: PropTypes.bool.isRequired,
  marketedCrops: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default TotalFarm;
