import leaflet from "leaflet";
import React from "react";
import { createRoot } from "react-dom/client";

import GeocoderSearch from "components/map/GeocoderSearch";

const SearchControl = leaflet.Control.extend({
  includes: L.evented,
  el: leaflet.DomUtil.create("div", "search-container"),
  options: { position: "topright" },

  onAdd(map) {
    this.map = map;
    const onChange = (location) => this.map.fire("search:done", location);

    leaflet.DomEvent.disableClickPropagation(this.el);

    const root = createRoot(this.el);
    root.render(<GeocoderSearch onChange={onChange} />);

    return this.el;
  },

  onRemove() {
    leaflet.DomEvent.off(this.el, "mousedown", leaflet.DomEvent.stop);
    this.map.fire("search:clear");
    this.map = null;
  },
});

export default new SearchControl();
