import useActivityBulkSelection from "activity/hooks/useActivityBulkSelection";
import _ from "lodash";
import React from "react";
import styled from "styled-components";

import usePermissions from "hooks/usePermissions";

import { UIColors } from "components/fl-ui/colors";

const Status = styled.span`
  ${(props) => (props.isOverdue || props.isCancelled) && `color: ${UIColors.red};`}
  ${(props) => props.isDone && `color: ${UIColors.green};`}
`;

const useActivityListing = (activities) => {
  const hasIconColumn = _.some(activities, ({ isAAR, isMachineData }) => isAAR || isMachineData);
  const bulkSelection = useActivityBulkSelection();
  const isSelectedActivity = (activityId) => bulkSelection.has(activityId);
  const getProductNamesCopy = (inputs) => _.uniq(_.map(inputs, "product.name")).join(" ");
  const renderStatusText = (activity) => <Status {...activity}>{activity.statusText}</Status>;
  const showCostPerAcre = usePermissions().canRead("activity_type_costs");

  return {
    getProductNamesCopy,
    hasIconColumn,
    isSelectedActivity,
    renderStatusText,
    showCostPerAcre,
  };
};

export default useActivityListing;
