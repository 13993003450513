import TractorForm from "equipment/forms/TractorForm";
import tractorFormSchema from "equipment/forms/validation/tractorFormSchema";
import _ from "lodash";
import validateSchema from "products/forms/validation/validateSchema";
import PropTypes from "prop-types";
import React, { useState } from "react";

import useTractorMutations from "collection/graphql/equipment/hooks/useTractorMutations";
import usePermissions from "hooks/usePermissions";
import App from "layout/app";

import { Button } from "components/fl-ui";
import { CloseX } from "components/fl-ui/Icons";
import { Cluster } from "components/fl-ui/Layout";
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "components/fl-ui/Modal/Modal";

const tractorToFormFields = (tractor) => {
  if (tractor) {
    const formFields = _.pick(tractor, ["icon", "id", "isRented", "make", "model", "name", "notes", "year"]);
    formFields.fuelCapacity = tractor.fuelCapacity === null ? "" : tractor.fuelCapacity;
    formFields.horsepower = tractor.horsepower === null ? "" : tractor.horsepower;

    return formFields;
  } else {
    return tractorFormSchema.cast({}, { assert: false });
  }
};

const TractorModal = (props) => {
  const { onClose, onDelete, onSaveSuccess, tractor } = props;
  const { id, name } = tractor ?? {};
  const isCreate = !id;
  const canDelete = usePermissions().canDelete("equipment");
  const { deleteTractor, saveTractor } = useTractorMutations();

  const [fields, setFields] = useState(tractorToFormFields(tractor));
  const { form, isValid } = validateSchema(tractorFormSchema, fields);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (form.fuelCapacity === "") {
      delete form.fuelCapacity;
    }
    if (form.horsepower === "") {
      delete form.horsepower;
    }

    await saveTractor(form);
    onSaveSuccess();
  };

  const handleDelete = async () => {
    App.confirm({
      confirm: "DELETE",
      message: `Delete "${name}" and all of its associated data?`,
      title: "Are you sure?",
    }).then(async () => {
      await deleteTractor(id);
      onDelete(id);
    });
  };

  return (
    <Modal id="tractorFormModal" width={500}>
      <ModalHeader>
        <ModalTitle>{isCreate ? "Add a new tractor" : `Edit ${name}`}</ModalTitle>
        <CloseX onClick={onClose} />
      </ModalHeader>

      <ModalBody>
        <TractorForm
          formFields={fields}
          formId="tractorForm"
          onChange={(updatedFields) =>
            setFields((fields) => ({
              ...fields,
              ...updatedFields,
            }))
          }
          onSubmit={handleSubmit}
        />
      </ModalBody>

      <ModalFooter>
        <Cluster between={!isCreate} end={isCreate}>
          {!isCreate && canDelete && (
            <Button color="danger" link onClick={handleDelete} type="button">
              Delete
            </Button>
          )}

          <span>
            <Button color="default" onClick={onClose} type="button">
              Cancel
            </Button>{" "}
            <Button color="primary" disabled={!isValid} form="tractorForm" type="submit">
              {isCreate ? "Add tractor" : "Update tractor"}
            </Button>
          </span>
        </Cluster>
      </ModalFooter>
    </Modal>
  );
};

TractorModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSaveSuccess: PropTypes.func.isRequired,
};

export default TractorModal;
