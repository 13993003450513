import _ from "lodash";

import ActivityInputInputSchema from "collection/graphql/activities/schemas/ActivityInputInputSchema";
import utils from "lib/validation/utils";

export const validateInput = (input) => ActivityInputInputSchema.isValidSync(input);

export const validate = (form, bulkCreateAttributes) => {
  const { date, inputs, isWorkOrder, people, type } = form;
  let isValid = true;

  if (!utils.isValidDate(date)) {
    isValid = false;
  }

  if (_.isEmpty(bulkCreateAttributes)) {
    isValid = false;
  }

  if (!type) {
    isValid = false;
  }

  const allInputsValid = _.every(inputs, validateInput);
  if (!allInputsValid) {
    isValid = false;
  }

  if (isWorkOrder && _.size(people) !== 1) {
    isValid = false;
  }

  return isValid;
};

export default validate;
