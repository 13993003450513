import { useQuery } from "@apollo/client";
import { groupBy } from "lodash";
import { useMemo } from "react";

import { GET_FIELD_CROPS } from "collection/graphql/fields/queries";
import useCurrentCropYear from "hooks/useCurrentCropYear";

const groupByFieldId = (crops) => groupBy(crops, "field.id");

const useYearFieldCrops = (year) => {
  /**
   * Get all field crops
   * Group them by most common use cases for O(1) access
   */
  const cropYear = useCurrentCropYear()[0];
  const currentCropYear = year ?? cropYear;
  const { data, loading } = useQuery(GET_FIELD_CROPS, { variables: { years: [currentCropYear] } });
  const crops = data?.fieldCrops ?? [];
  const groupByField = useMemo(() => groupByFieldId(crops), [crops]);
  const groupById = useMemo(() => groupBy(crops, "id"), [crops]);

  return {
    crops,
    getCropById: (cropId) => groupById[cropId]?.[0],
    getYearCropsForField: (fieldId) => groupByField[fieldId] ?? [],
    loading,
  };
};

export default useYearFieldCrops;
