import { useQuery } from "@apollo/client";
import styles from "marketing/cards/cardStyles";
import formatCurrency from "marketing/cards/utils/formatCurrency";
import Cell, { CellRow } from "marketing/components/ui-elements/Cell";
import React from "react";
import router from "router";
import styled from "styled-components";

import { marketingClient } from "collection/graphql/client";
import getUnderlyingForCropYear from "collection/graphql/marketing/queries/getUnderlyingForCropYear";
import useCurrentCropYear from "hooks/useCurrentCropYear";

import { css } from "components/fl-ui/aphrodite";
import CommodityYieldUnit from "components/units/CommodityYieldUnit";

const ExchangeTradedCellRow = styled(({ className, marketedCrop }) => {
  const [cropYear] = useCurrentCropYear();

  const { data } = useQuery(getUnderlyingForCropYear, {
    client: marketingClient,
    variables: {
      commodityId: marketedCrop.commodity.id,
      cropYear: cropYear,
    },
  });

  const marketDisplayDate = data?.getUnderlyingForCropYear?.shortName.split(" ").slice(0, 2).join("") || "--";

  const navigate = () => {
    router.navigate("/#prices", { trigger: true });
  };

  return (
    <CellRow className={[css(styles.dataRow), className].join(" ")}>
      <Cell>Current Market Price</Cell>
      <Cell right>
        <span className="blue-text" onClick={navigate} onKeyDown={navigate}>
          {formatCurrency(data?.getUnderlyingForCropYear?.currentMarketPrice)}{" "}
          <CommodityYieldUnit commodity={marketedCrop?.commodity} defaultUnit="bu" />
        </span>
        <span className="date-text">{marketDisplayDate}</span>
      </Cell>
    </CellRow>
  );
})`
  &:last-child {
    border-bottom: none;
  }

  .date-text {
    text-transform: uppercase;
    font-weight: bold;
  }
`;

export { ExchangeTradedCellRow };
