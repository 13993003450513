import { useQuery } from "@apollo/client";
import { groupBy } from "lodash";
import { useMemo } from "react";

import { marketingClient } from "collection/graphql/client";
import { CROP_YEARS } from "collection/graphql/fields/hooks/useCropYears";
import { GET_FIELD_CROPS } from "collection/graphql/fields/queries";

const groupByFieldId = (crops) => groupBy(crops, "field.id");

/**
 * @deprecated
 * IMPORTANT! Use only with legacy storage and loads
 * Get all field crops
 * Group them by most common use cases for O(1) access
 */

const useFieldCrops = () => {
  const { data, loading } = useQuery(GET_FIELD_CROPS, { client: marketingClient, variables: { years: CROP_YEARS } });
  const crops = data?.fieldCrops ?? [];
  const groupByCommodity = useMemo(() => groupBy(crops, "commodity.id"), [crops]);
  const groupByField = useMemo(() => groupByFieldId(crops), [crops]);
  const groupByInventoryNodeId = useMemo(() => groupBy(crops, "inventoryNodeId"), [crops]);

  return {
    crops,
    getCropByInventoryNode: (inventoryNodeId) => groupByInventoryNodeId[inventoryNodeId]?.[0],
    getCropsForCommodity: (commodityId) => groupByCommodity[commodityId] ?? [],
    getCropsForField: (fieldId) => groupByField[fieldId] ?? [],
    loading,
  };
};

export default useFieldCrops;
