import PropTypes from "prop-types";
import React from "react";
import ReactSelect from "react-select";

import { useUserConfig } from "collection/graphql/config";

import { GroupedButtons } from "components/fl-ui/Buttons";

const BUTTON_VARIANT = "buttons";
const DROPDOWN_VARIANT = "dropdown";

export const useActivitySortControl = ({ onChange, value, variant }) => {
  const userConfig = useUserConfig();

  const options = [
    { id: "category", label: "Category" },
    { id: "fieldName", label: "Field name" },
    { id: "cropName", label: "Commodity" },
    { id: "status", label: "Status" },
    { id: "date", label: "Date" },
  ];

  /**
   * @param {Object} sortOption
   * @param {'category'|'cropName'|'date'|'dateAsc'|'fieldName'|'status'} sortOption.id
   * @private
   */
  const handleChange = ({ id }) => {
    const persistedSortId = id;
    if (variant === BUTTON_VARIANT && id === "date" && ["date", "dateAsc"].includes(value)) {
      id = value === "date" ? "dateAsc" : "date";
    }

    userConfig("activitySortCriteria", persistedSortId);
    onChange(id);
  };

  return { handleChange, options };
};

const ActivitySortControl = (props) => {
  const { value, variant } = props;
  const { handleChange, options } = useActivitySortControl(props);

  if (variant === DROPDOWN_VARIANT) {
    return (
      <ReactSelect
        getOptionLabel={_.property("label")}
        getOptionValue={_.property("id")}
        onChange={handleChange}
        options={options}
      />
    );
  }

  return (
    <GroupedButtons
      borderless
      onClick={handleChange}
      options={options}
      selected={{ id: value === "dateAsc" ? "date" : value }}
    />
  );
};

ActivitySortControl.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  variant: PropTypes.oneOf([BUTTON_VARIANT, DROPDOWN_VARIANT]).isRequired,
};

export default ActivitySortControl;
