import { useLocalStorage } from "beautiful-react-hooks";
import MobileDemoPage from "demo/demos/MobileSubscriptionsDemo/MobileDemoPage";
import { Provider } from "demo/demos/MobileSubscriptionsDemo/MobileSubscriptionsDemoContext";
import { isNative } from "mobile/mobileManager";
import { Purchases } from "mobile/purchases/Purchases";
import React, { useEffect, useMemo, useReducer } from "react";

import { useAuth } from "collection/graphql/auth";
import useAsyncFn from "hooks/useAsyncFn";

import LoadingWrapper from "components/fl-ui/LoadingWrapper";

const reducer = (state, newState) => {
  switch (newState.action) {
    case "addLogEntry": {
      return {
        ...state,
        log: [newState.entry].concat(state.log),
      };
    }

    case "addUser": {
      const users = new Set(
        Array.from(state.users)
          .concat(+newState.userId)
          .filter((x) => x)
      );
      localStorage.setItem("mobileTestUserIds", JSON.stringify(Array.from(users)));
      return {
        ...state,
        users,
      };
    }

    default: {
      return { ...state, ...newState };
    }
  }
};

const MobileSubscriptionsDemo = (props) => {
  const { currentUser } = useAuth();
  useEffect(() => {
    Purchases.setLogHandler(({ logLevel, message }) => {
      updateState({ action: "addLogEntry", entry: { logLevel, message, timestamp: Date.now() } });
    });
    return () => Purchases.setLogHandler(console.log);
  }, []);

  const [mobileTestUserIds] = useLocalStorage("mobileTestUserIds", [currentUser.id]);
  if (!isNative()) {
    return (
      <div>
        <h2>RevenueCat test page</h2>
        Not supported on web
      </div>
    );
  }

  const refreshInitialData = useAsyncFn(async () => {
    const newState = { isLoading: false };
    newState.isConfigured = (await Purchases.isConfigured()).isConfigured;
    try {
      if (newState.isConfigured) {
        newState.appUserId = await Purchases.getAppUserID();
        newState.customerInfo = (await Purchases.getCustomerInfo()).customerInfo;
        newState.offerings = await Purchases.syncAttributesAndOfferingsIfNeeded();
        newState.packages = await Purchases.getAvailablePackages();

        newState.jsonOutput = _.pick(newState, ["customerInfo"]);
      }
    } catch (error) {
      newState.jsonOutput = { error: _.pick(error, ["code", "errorMessage"]) };
    }

    updateState(newState);
  })[1];

  const [state, updateState] = useReducer(reducer, {}, () => ({
    appUserId: null,
    currentUser,
    customerInfo: null,
    isLoading: true,
    jsonOutput: null,
    log: [],
    packages: null,
    purchases: Purchases,
    refresh: refreshInitialData,
    users: new Set(mobileTestUserIds),
  }));

  useEffect(() => {
    refreshInitialData();
  }, []);

  const context = useMemo(
    () => ({
      ...state,
      updateState,
    }),
    [state]
  );

  if (state.isLoading) {
    return <LoadingWrapper isLoading />;
  }

  return (
    <Provider value={context}>
      <MobileDemoPage />
    </Provider>
  );
};

export default MobileSubscriptionsDemo;
