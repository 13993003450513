import useContractsIndexPage from "contracts/hooks/useContractsIndexPage";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import SortIndicator from "components/fl-ui/SortIndicator";
import ColorPalette from "components/fl-ui/colors/palette";
import { Typography } from "components/fl-ui/constants";

const HeaderCell = styled(({ className, columnClass, children, sortSlug }) => {
  const { currentSortColumn, currentSortDirection, setSort } = useContractsIndexPage();
  const currentlySortedByThisColumn = sortSlug === currentSortColumn;

  const incrementSortDirection = () => {
    switch (currentSortDirection) {
      case "asc":
        setSort(sortSlug, "desc");
        return;
      case "desc":
        setSort(null, "");
        return;
      default:
        setSort(sortSlug, "asc");
        return;
    }
  };

  return (
    <div
      className={`${className} ${columnClass}`}
      onClick={() => {
        if (currentlySortedByThisColumn) {
          incrementSortDirection();
        } else {
          setSort(sortSlug, "asc");
        }
      }}
      onKeyDown={() => null}
    >
      <span>{children}</span>
      {sortSlug && <SortIndicator sortDirection={currentlySortedByThisColumn ? currentSortDirection : ""} />}
    </div>
  );
})`
  cursor: ${(props) => (!!props.sortSlug ? "pointer" : "auto")};
  display: flex;
  position: relative;
  & > span {
    align-self: flex-end;
    color: ${ColorPalette["bushel-blue"]};
    font-weight: ${Typography.weights.medium}};
    font-size: ${Typography.sizes.mn};
   }
`;

export default HeaderCell;

HeaderCell.propTypes = {
  columnClass: PropTypes.string,
  children: PropTypes.any,
};
