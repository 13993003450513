import App from "layout/app";

/**
 * Shows a confirmation modal when bulk deleting activities.
 * @param {Number} numberOfActivities the number of activities being deleted
 * @param {Boolean} hasWorkOrderFeature
 * @return {Promise}
 */
export const activityBulkDeletionPrompt = (numberOfActivities, hasWorkOrderFeature) => {
  const messageChunks = [
    "This will delete all selected activities and all their associated data.",
    "This action cannot be undone.",
  ];
  if (hasWorkOrderFeature) {
    messageChunks.splice(1, 0, "Assignees aren't notified of activity deletions.");
  }

  return new Promise((resolve, reject) => {
    App.confirm({
      confirm: "Delete activities",
      message: messageChunks.join(" "),
      title: `Are you sure you want to delete ${numberOfActivities} ${
        numberOfActivities === 1 ? "activity" : "activities"
      }?`,
    }).then(resolve, reject);
  });
};

/**
 * Shows a confirmation modal when deleting a single activity.
 * @param {Boolean} hasWorkOrderFeature
 * @return {Promise}
 */
export const activityDeletionPrompt = (hasWorkOrderFeature) => {
  const messageChunks = [
    "This will delete this activity and all of its associated data.",
    "This action cannot be undone.",
  ];
  if (hasWorkOrderFeature) {
    messageChunks.splice(1, 0, "Assignees aren't notified of activity deletions.");
  }

  return new Promise((resolve, reject) => {
    App.confirm({
      confirm: "Delete activity",
      message: messageChunks.join(" "),
      title: "Are you sure you want to delete this activity?",
    }).then(resolve, reject);
  });
};
