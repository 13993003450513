import PropTypes from "prop-types";
import React, { useState } from "react";
import CommodityCard from "settings/pages/Commodities/containers/CommodityCard";
import CommoditySectionBlankSlate from "settings/pages/Commodities/containers/CommoditySectionBlankSlate";
import styled from "styled-components";
import { display, grid } from "styled-system";

import useViewType from "hooks/useViewType";

import CommodityFormModal from "components/commodity/CommodityFormModal";
import { Button } from "components/fl-ui";
import { Cluster, Stack } from "components/fl-ui/Layout";

const ABRIDGED_AMOUNT = 10;
const CardList = styled("div")(display, grid);

const CommoditySection = ({ abridge, commodities, description, showAddButton = false, title }) => {
  const [showAll, setShowAll] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const columnRepeat = useViewType() === "mobile" ? 1 : 2; // this has to be doable with css only somehow (without media queries)
  const showAbridgedList = !showAll && commodities.length > ABRIDGED_AMOUNT && abridge;
  const visibleCommodities = !showAbridgedList ? commodities : commodities.slice(0, ABRIDGED_AMOUNT);

  return (
    <Stack>
      <Cluster between>
        <div>
          <h2>{title}</h2>
          {description && <p>{description}</p>}
        </div>

        {showAddButton && (
          <Button onClick={() => setShowModal(true)} color="secondary">
            + Add custom commodity
          </Button>
        )}
        {showModal && <CommodityFormModal onClose={() => setShowModal(false)} onSave={() => setShowModal(false)} />}
      </Cluster>

      {visibleCommodities.length > 0 && (
        <CardList display="grid" gridGap={10} gridTemplateColumns={`repeat(${columnRepeat}, minmax(300px, 1fr))`}>
          {visibleCommodities.map((commodity) => (
            <CommodityCard key={commodity.id} commodity={commodity} />
          ))}
        </CardList>
      )}

      {visibleCommodities.length === 0 && <CommoditySectionBlankSlate />}

      {!showAll && commodities.length > ABRIDGED_AMOUNT && (
        <Cluster center>
          <Button link onClick={() => setShowAll(true)} color="primary">
            Show all {commodities.length}
          </Button>
        </Cluster>
      )}
    </Stack>
  );
};

CommoditySection.propTypes = {
  abridge: PropTypes.bool,
  commodities: PropTypes.array.isRequired,
  description: PropTypes.string,
  showAddButton: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

export default CommoditySection;
