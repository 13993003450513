import { useInterval } from "beautiful-react-hooks";
import { appVersion, isNative, getPlatform, deviceGetInfo } from "mobile/mobileManager";
import React, { useState } from "react";
import styled from "styled-components";

import { useAuth } from "collection/graphql/auth";
import useConnectivityChange from "hooks/useConnectivityChange";
import useWindow from "hooks/useWindow";
import { Section } from "modules/demo/components";

const SessionInfo = styled(({ className }) => {
  const clientVersion = process.env.CLIENT_VERSION;
  const mobileAppVersion = isNative() ? appVersion() : "--";
  const { currentUser } = useAuth();
  const win = useWindow();
  const platform = getPlatform();

  const [isOnline, setIsOnline] = useState(navigator.onLine);
  useInterval(() => {
    if (navigator.onLine !== isOnline) {
      setIsOnline(navigator.onLine);
    }
  }, 1000);

  const definition = (term, value) => (
    <>
      <dt>{term}</dt>
      <dd>{value?.toString() ?? "--"}</dd>
    </>
  );

  return (
    <div className={className}>
      <Section title="Status">
        <dl>
          {definition("origin", win.location.origin)}
          {definition("API", process.env.API)}
          {definition("navigator.onLine", isOnline)}
          {definition(`isOnline\u00A0(internal)`, useConnectivityChange())}
          {definition("clientVersion", clientVersion || "dev")}
          {definition("mobileAppVersion", mobileAppVersion || "dev")}
        </dl>
      </Section>

      <Section title="Platform info">
        <dl>
          {definition("Is Web", !isNative())}
          {definition("Is Mobile", isNative())}
          {definition("Is Android", platform === "android")}
          {definition("Is IOS", platform === "ios")}
          {definition("Is Native", isNative())}
          {definition("Platform", platform)}
          {isNative() && definition("OS Version", deviceGetInfo()?.osVersion)}
        </dl>
      </Section>

      <Section title="User info">
        <dl>
          {definition("Name", currentUser.name)}
          {definition("Email", currentUser.email)}
          {definition("id", currentUser.id)}
          {definition("enterpriseId", currentUser.enterpriseId)}
          {definition("landingPage", currentUser.landingPage ?? "--")}
        </dl>
      </Section>

      <Section title="Operating system">
        <dl>
          {definition("name", platform)}
          {isNative() && definition("os version", deviceGetInfo()?.osVersion)}
          {definition("userAgent", navigator.userAgent)}
        </dl>
      </Section>
    </div>
  );
})`
  font-size: 13px;
  line-height: 1.5em;

  dl {
    display: grid;
    grid-gap: 4px;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }
  dt {
    display: inline;
  }
  dt::after {
    content: ": ";
  }
  dd {
    display: inline;
    font-family: monospace;
    margin-left: 0;
    overflow: scroll;
    text-align: right;
  }
`;

export default SessionInfo;
