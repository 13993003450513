import React from "react";
import styled from "styled-components";

import { setElevatePlusRecommendationsPreference } from "collection/graphql/advisor";
import { withDismissPartialMarketedCrop } from "collection/graphql/marketing";
import { useMarketingPageContext } from "context/MarketingPageContext";
import useAdvisorMutation from "hooks/useAdvisorMutation";
import useCurrentCropYear from "hooks/useCurrentCropYear";
import usePermissions from "hooks/usePermissions";

import { Button } from "components/fl-ui";
import { ColorPalette, GreyColors } from "components/fl-ui/colors";

const MarketingCropCardButtons = styled(
  withDismissPartialMarketedCrop(({ className, dismissPartialMarketedCrop, marketedCrop }) => {
    const [cropYear] = useCurrentCropYear();
    const { advisorCommodities, openCropEditModal } = useMarketingPageContext();
    const advisorCommodityID = advisorCommodities?.find(
      (commodity) => commodity?.commodityId === marketedCrop?.commodity?.id
    )?.id;

    const setRecommendationPreference = useAdvisorMutation(setElevatePlusRecommendationsPreference, {
      refetchQueries: ["getAdvisorRecommendations", "getAdvisorRecommendationCommoditiesByYear"],
    });

    const canDelete = usePermissions().canDelete("marketing");

    return (
      <div className={className}>
        <div className="buttons">
          <Button
            className="edit"
            color="primary"
            display="block"
            onClick={() => openCropEditModal(marketedCrop)}
            size="sm"
            type="submit"
          >
            Plan {marketedCrop?.year} {marketedCrop?.commodity?.name} Crop
          </Button>

          {canDelete && (
            <Button
              className="remove"
              color="white"
              display="block"
              onClick={() => {
                if (marketedCrop?.__typename === "PartialMarketedCrop") {
                  dismissPartialMarketedCrop({ commodityId: marketedCrop?.commodity?.id, year: marketedCrop?.year });
                } else {
                  setRecommendationPreference({
                    variables: {
                      input: {
                        year: cropYear,
                        id: advisorCommodityID,
                        enabled: false,
                      },
                    },
                  });
                }
              }}
              size="sm"
            >
              Remove
            </Button>
          )}
        </div>
      </div>
    );
  })
)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 60%;
  }

  .edit {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .remove {
    border: 1px solid ${GreyColors.smoke90};
    color: ${ColorPalette.blue};
  }
`;

export { MarketingCropCardButtons };
