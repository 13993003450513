import { showAppDeprecated } from "mobile/mobileAppUpdate";
import {
  browserOpenUrl,
  deviceGetInfo,
  cookiesClearAll,
  directoryCache,
  fileDelete,
  fileStat,
  fileWrite,
  geolocationClearWatch,
  geolocationWatchPosition,
  requestNotificationPermission,
  share,
} from "mobile/mobileManager";
import { oneSignalInfo, oneSignalLogIn, oneSignalLogOut, oneSignalOptIn } from "mobile/mobileNotifications";
import React from "react";

import { useAuth } from "collection/graphql/auth";

const MobilePluginsDemo = () => {
  const { currentUser } = useAuth();

  // Mobile App Update
  const triggerShowAppDeprecated = async () => {
    await showAppDeprecated();
  };

  // OneSignal
  const triggerOneSignalInfo = () => {
    const result = oneSignalInfo();
    alert(JSON.stringify(result));
  };

  const triggerOneSignalLogIn = async () => {
    oneSignalLogIn(currentUser);
  };

  const triggerOneSignalLogOut = async () => {
    oneSignalLogOut();
  };

  const triggerOneSignalOptIn = async () => {
    oneSignalOptIn();
  };

  // Old Subscriptions
  const triggerDeviceGetInfo = async () => {
    const info = await deviceGetInfo();
    console.log(info);
  };

  const triggerBrowserOpenUrl = async () => {
    browserOpenUrl("https://www.bushelpowered.com");
  };

  const triggerFileDelete = async () => {
    await fileDelete({
      path: "text.txt",
      directory: directoryCache(),
    })
      .then(() => {
        alert("Deleted file");
      })
      .catch((error) => {
        console.log("Unable to delete file:", error);
      });
  };

  const triggerFileStat = async () => {
    try {
      const result = await fileStat({
        path: "text.txt",
        directory: directoryCache(),
      });
      alert("Stat file: ", result);
    } catch (e) {
      console.error("Unable to stat file:", e);
    }
  };

  const triggerFileWrite = async () => {
    try {
      const result = await fileWrite({
        path: "text.txt",
        data: "This is a test",
        directory: directoryCache(),
        encoding: "utf8",
        recursive: true,
      });
      alert("Wrote file:", result);
    } catch (e) {
      console.error("Unable to write file:", e);
    }
  };

  let watchPositionCallbackId = "";

  const triggerGeolocationClearWatch = async () => {
    try {
      await geolocationClearWatch(watchPositionCallbackId);
      alert("triggerGeolocationClearWatch");
    } catch (e) {
      console.error("Unable to clear watch:", e);
    }
  };

  const triggerGeolocationWatchPosition = async () => {
    const options = {
      enableHighAccuracy: true,
      maximumAge: 10000,
      timeout: 10000,
    };
    try {
      watchPositionCallbackId = await geolocationWatchPosition(options, (geoPosition) => {
        if (geoPosition?.coords) {
          console.log("COORDS: ", geoPosition.coords);
        }
      });
    } catch (e) {
      console.error("Unable to watch position:", e);
    }
  };

  const triggerShare = async () => {
    await share({ text: "Just Text 2" })
      .then((response) => {
        alert({ response });
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  const triggerRequestMobileNotificationPermissionMethod = () => {
    requestNotificationPermission().then((response) => {
      console.log({ response });
    });
  };

  // Cookies

  const triggerCookiesClearAll = async () => {
    await cookiesClearAll();
    alert("CLEARED COOKIES");
  };

  return (
    <div>
      <br />
      <br />
      <h2>App Update Plugin</h2>
      <button onClick={triggerShowAppDeprecated}>Trigger showAppDeprecated()</button>
      <br />
      <br />
      <h2>OneSignal Plugin</h2>
      <button onClick={triggerOneSignalInfo}>Trigger oneSignalInfo()</button>
      <br />
      <br />
      <button onClick={triggerOneSignalLogIn}>Trigger oneSignalLogIn()</button>
      <br />
      <br />
      <button onClick={triggerOneSignalLogOut}>Trigger oneSignalLogOut()</button>
      <br />
      <br />
      <button onClick={triggerOneSignalOptIn}>Trigger oneSignalOptIn()</button>
      <br />
      <br />
      <h2>Device Plugin</h2>
      <button onClick={triggerDeviceGetInfo}>GET INFO</button>
      <br />
      <br />
      <h2>Browser Plugin</h2>
      <button onClick={triggerBrowserOpenUrl}>OPEN URL</button>
      <br />
      <br />
      <h2>Filesystem Plugin</h2>
      <button onClick={triggerFileDelete}>Trigger fileDelete()</button>
      <br />
      <br />
      <button onClick={triggerFileStat}>Trigger fileStat()</button>
      <br />
      <br />
      <button onClick={triggerFileWrite}>Trigger fileWrite()</button>
      <br />
      <br />
      <h2>Geolocation Plugin</h2>
      <button onClick={triggerGeolocationClearWatch}>Trigger clearWatch()</button>
      <br />
      <br />
      <button onClick={triggerGeolocationWatchPosition}>Trigger watchPosition()</button>
      <br />
      <br />
      <h2>Share Plugin</h2>
      <button onClick={triggerShare}>SHARE</button>
      <br />
      <br />
      <h2>Mobile Notification Plugin Buttons</h2>
      <button onClick={triggerRequestMobileNotificationPermissionMethod}>
        Trigger requestNotificationPermission() Method
      </button>
      <br />
      <br />
      <h2>Cookies Plugin</h2>
      <button onClick={triggerCookiesClearAll}>Trigger cookiesClearAll()</button>
      <br />
      <br />
    </div>
  );
};

export default MobilePluginsDemo;
