import React from "react";

import useYieldLayers from "lib/map/layers/useYieldLayers";

import YieldInfo from "components/crop/YieldInfo";
import YieldMap from "components/crop/yield_map";

const CropYield = ({ crop, fieldId }) => {
  const { loading, yieldLayers } = useYieldLayers({ fieldId });
  const layer = yieldLayers.find((layer) => layer.crop?.id === crop.id);
  const { commodity, cropYear } = crop;

  return (
    <>
      <h3 className="small-caps-heading">{`${commodity.name} ${cropYear}`}</h3>
      <div className="row-fluid yield-info">
        <div className="col xs-6 md-3">
          <YieldMap fieldId={fieldId} layer={layer} loading={loading} />
        </div>
        <div className="col xs-hide sm-hide md-1"> </div>

        <div className="col xs-6 md-8">
          {loading ? <span>loading...</span> : <YieldInfo crop={crop} layer={layer} />}
        </div>
      </div>
    </>
  );
};

export default CropYield;
