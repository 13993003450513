const CashContractTypePolicy = {
  fields: {
    buyerName: {
      read: (__, { readField }) => {
        const buyer = readField("buyer");
        return buyer ? buyer.toLowerCase().trim() : "unassigned";
      },
    },

    contractStatus: {
      read: (contractStatus) => {
        if (contractStatus === "PENDING") {
          return "OPEN";
        }

        return contractStatus;
      },
    },

    contractSubtype: {
      read(__, { readField }) {
        return readField("contractType");
      },
    },
  },
};

export default CashContractTypePolicy;
