import React from "react";
import styled from "styled-components";

import Icon from "components/fl-ui/Icons/Icon";
import ToolTip from "components/fl-ui/Tooltip";
import { ColorPalette } from "components/fl-ui/colors";

const TitleArea = styled(({ className, hasContracts }) => (
  <h3 className={className}>
    CURRENT POSITION
    {hasContracts && (
      <ToolTip message="Assign contracts to this marketing crop to update your current position.">
        <Icon icon="help" />
      </ToolTip>
    )}
  </h3>
))`
  svg {
    height: 20px;
    width: 20px;
    display: inline-block;
    margin-left: 6px;
    vertical-align: middle;
    color: ${ColorPalette["blue"]};
  }
`;

export { TitleArea };
