import useContractsIndexPage from "contracts/hooks/useContractsIndexPage";
import PricingAndDeliveryModal from "contracts/modals/PricingAndDeliveryModal";
import React from "react";

const ContractsPricingAndDeliveryModal = ({ newContract, onClose, onSave }) => {
  const { reloadAll } = useContractsIndexPage();
  return (
    <PricingAndDeliveryModal
      contract={newContract}
      onClose={() => {
        reloadAll();
        onClose();
      }}
      onSave={() => {
        reloadAll();
        onSave();
      }}
    />
  );
};

export default ContractsPricingAndDeliveryModal;
