import createReactClass from "create-react-class";
import React from "react";

const getSize = () => window.getComputedStyle(document.body, ":after").content.replace(/"/g, "");

export default function (Component) {
  return createReactClass({
    displayName: `Responsive(${Component.displayName})`,
    getInitialState() {
      return { viewportSize: getSize() };
    },

    UNSAFE_componentWillMount() {
      return window.addEventListener("resize", this._handleResize);
    },

    componentWillUnmount() {
      return window.removeEventListener("resize", this._handleResize);
    },

    _handleResize() {
      return this.setState({ viewportSize: getSize() });
    },

    render() {
      return <Component {...this.props} viewportSize={this.state.viewportSize} />;
    },
  });
}
