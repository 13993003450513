import PropTypes from "prop-types";
import React, { useMemo } from "react";
import ReactSelect from "react-select";

import useUnits from "hooks/useUnits";

import { chooserStyles } from "components/fl-ui/Chooser";

const contractTypesEnum = Object.freeze({
  CashContract: "CashContractType",
  FuturesContract: "FuturesContractType",
  OptionsContract: "OptionsContractType",
});

const acceptedTypes = [
  "BASIS",
  "CASH",
  "HEDGE_TO_ARRIVE",
  "LONG_CALL",
  "LONG_FUTURES",
  "LONG_PUT",
  "OTHER",
  "SHORT_CALL",
  "SHORT_FUTURES",
  "SHORT_PUT",
  "SPOT",
];

const ContractSubtypeDropdown = ({
  contractType,
  disabled = false,
  isOptionDisabled,
  id,
  onBlur,
  onChange,
  placeholder,
  value,
}) => {
  const allOptions = useUnits().getEnumAsOptions(contractTypesEnum[contractType]);
  const options = useMemo(() => {
    return allOptions.filter((option) => acceptedTypes.includes(option.value));
  }, [allOptions]);
  const selectedOption = options.find((option) => option.value === value);

  return (
    <ReactSelect
      inputId={id}
      isDisabled={disabled}
      isOptionDisabled={isOptionDisabled}
      onBlur={onBlur}
      onChange={({ value }) => onChange(value)}
      options={options}
      placeholder={placeholder}
      styles={chooserStyles}
      value={selectedOption}
    />
  );
};

ContractSubtypeDropdown.propTypes = {
  contractType: PropTypes.oneOf(["CashContract", "FuturesContract", "OptionsContract"]).isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

export default ContractSubtypeDropdown;
