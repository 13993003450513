import MobilePaymentError from "billing/exceptions/MobilePaymentError";
import { Purchases } from "mobile/purchases/Purchases";

import nativeOrderDescriptor from "collection/graphql/subscription/schemas/nativeOrderDescriptor";
import { getMobilePlanId } from "collection/graphql/subscription/utils/getMobilePlanId";
import { fetchCurrentEnterprise } from "lib/fetchCurrentEnterprise";
import { fetchCurrentUser } from "lib/fetchCurrentUser";

/**
 * Accepts an order descriptor and performs a payment transaction through the native app.
 *
 * @param {OrderDescriptor} orderDescriptor
 * @return {Promise}
 * @throws {MobilePaymentError}
 */
async function saveNativeSubscription(orderDescriptor) {
  await nativeOrderDescriptor.validate(orderDescriptor);
  const mobilePlanId = await getMobilePlanId(orderDescriptor);

  const currentUser = await fetchCurrentUser();
  const userId = currentUser?.id;
  if (!userId) {
    throw new MobilePaymentError("Failed to fetch user ID");
  }

  const currentEnterprise = await fetchCurrentEnterprise();
  const enterpriseId = currentEnterprise?.id;
  if (!enterpriseId) {
    throw new MobilePaymentError("Failed to fetch enterprise ID");
  }

  await Purchases.ensureSupported();
  await Purchases.configure(userId, enterpriseId);
  await Purchases.payForMobilePlanId(mobilePlanId);
}

export default saveNativeSubscription;
