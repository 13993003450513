import { BasicInput, Label, MicroLabel } from "agreements/forms/components";
import BasicSelect from "agreements/forms/components/BasicSelect";
import InterestRateInput from "agreements/forms/components/InterestRateInput";
import React from "react";

import { FormGroup, Input } from "components/fl-ui/Form";
import { Cluster, Stack } from "components/fl-ui/Layout";

const loanTypeOptions = [
  { key: "FIXED_RATE_MORTGAGE", value: "FIXED_RATE_MORTGAGE", label: "Mortgage" },
  { key: "EQUITY_LINE", value: "EQUITY_LINE", label: "Equity Line" },
  { key: "LAND_CONTRACT", value: "LAND_CONTRACT", label: "Land Contract" },
  { key: "LINE_OF_CREDIT", value: "LINE_OF_CREDIT", label: "Line of Credit" },
  { key: "OPERATING", value: "OPERATING", label: "Operating" },
  { key: "FSA_DIRECT", value: "FSA_DIRECT", label: "FSA Direct" },
  { key: "FSA_GUARANTEED", value: "FSA_GUARANTEED", label: "FSA Guaranteed" },
  { key: "OTHER", value: "OTHER", label: "Other" },
];

const LoanDetailsFieldset = ({ interestRate, lenderName, loanType, onChange, originalBalance }) => (
  <Stack>
    <FormGroup label="Lender name">
      <Input name="lenderName" onChange={(e, payload) => onChange(payload)} value={lenderName} />
    </FormGroup>

    <Label>Loan terms</Label>

    <Cluster style={{ flexWrap: "wrap", justifyItems: "start" }}>
      <MicroLabel label="Type">
        <BasicSelect name="loanType" onChange={onChange} options={loanTypeOptions} value={loanType} />
      </MicroLabel>

      <MicroLabel label="Original Balance">
        <BasicInput
          name="originalBalance"
          onChange={({ target: { value } }) => onChange({ originalBalance: value })}
          type="currency"
          value={originalBalance}
        />
      </MicroLabel>

      <MicroLabel label="Interest Rate">
        <InterestRateInput
          name="interestRate"
          onChange={({ interestRate }) => onChange({ interestRate: +interestRate })}
          value={interestRate}
        />
      </MicroLabel>
    </Cluster>
  </Stack>
);

export default LoanDetailsFieldset;
