import { gql } from "@apollo/client";

import { ActivityListFragment } from "collection/graphql/activities/fragments";

export default gql`
  query getActivityList($cropYear: Int, $fields: [Int], $crops: [Int], $products: [Int]) {
    activities: getActivities(cropYear: $cropYear, fields: $fields, crops: $crops, products: $products) {
      ...ActivityListFragment
    }
  }

  ${ActivityListFragment}
`;
