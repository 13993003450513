import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import React from "react";

const { func, number, node } = PropTypes;

export default createReactClass({
  displayName: "Pager",

  propTypes: {
    children: node,
    initialPage: number,
    onCancel: func,
    onComplete: func,
  },

  getDefaultProps() {
    return {
      onComplete() {},
      onCancel() {},
    };
  },

  getInitialState() {
    return { currentPage: 0 };
  },

  _handleNext() {
    if (this.state.currentPage >= this.props.children.length - 1) {
      return this.props.onComplete();
    } else {
      return this.setState({ currentPage: this.state.currentPage + 1 });
    }
  },

  _handleBack() {
    if (this.state.currentPage === 0) {
      return this.props.onCancel();
    } else {
      if (this.props.onBack) this.props.onBack();
      return this.setState({ currentPage: this.state.currentPage - 1 });
    }
  },

  render() {
    return (
      <span className="pager">
        {React.Children.map(this.props.children, (child, i) => {
          if (i !== this.state.currentPage) {
            return null;
          }

          return React.cloneElement(child, {
            currentPage: i + 1,
            totalPages: this.props.children.length,
            onNext: this._handleNext,
            onBack: this._handleBack,
          });
        })}
      </span>
    );
  },
});
