import { useMutation } from "@apollo/client";
import _ from "lodash";

import createProductPurchase from "collection/graphql/products/createProductPurchase";
import ProductPurchaseInputSchema from "collection/graphql/products/schemas/ProductPurchaseInputSchema";
import updateProductPurchase from "collection/graphql/products/updateProductPurchase";
import { PRODUCT_PURCHASE_CREATED, PRODUCT_PURCHASE_EDITED } from "lib/metrics/events";

const create = (save) => async (form) => {
  const commonFields = _.pick(form, ["cropYear", "date", "vendor"]);
  const purchaseList = form.inputs.map((input) => ({ ...input, ...commonFields }));
  const results = await Promise.allSettled(
    purchaseList.map((rawPurchase) => {
      try {
        ProductPurchaseInputSchema.validateSync(rawPurchase);
        const createPurchaseInput = ProductPurchaseInputSchema.cast(rawPurchase, { stripUnknown: true });
        return save({ variables: { productPurchase: createPurchaseInput } });
      } catch (error) {
        return Promise.reject(error);
      }
    })
  );

  const succeeded = [];
  const failed = [];
  purchaseList.forEach((purchase, i) => {
    if (results[i].status === "fulfilled") {
      const { productPurchase } = results[i].value.data.createProductPurchase;
      succeeded.push(productPurchase);

      PRODUCT_PURCHASE_CREATED.track({
        productId: productPurchase.product.id,
        purchaseId: productPurchase.id,
      });
    } else {
      failed.push({
        input: purchase,
        reason: results[i].reason.message,
      });
    }
  });

  return { failed, succeeded };
};

const update = (save) => async (form) => {
  try {
    const rawInput = { ...form.inputs[0], ...form };
    ProductPurchaseInputSchema.validateSync(rawInput);
    const updatePurchaseInput = ProductPurchaseInputSchema.cast(rawInput, { stripUnknown: true });

    const result = await save({
      refetchQueries: ["getProductPurchaseSummaries"],
      variables: { productPurchase: updatePurchaseInput },
    });
    const { productPurchase } = result.data.updateProductPurchase;

    PRODUCT_PURCHASE_EDITED.track({
      productId: productPurchase.product.id,
      purchaseId: productPurchase.id,
    });

    return productPurchase;
  } catch (error) {
    return Promise.reject(error);
  }
};

const useSavePurchase = (isCreate) => {
  const doSave = useMutation(isCreate ? createProductPurchase : updateProductPurchase)[0];
  return isCreate ? create(doSave) : update(doSave);
};

export default useSavePurchase;
