import { DocumentTransform } from "@apollo/client";
import { visit } from "graphql";
import { find, map } from "lodash";

import { hasPolicyPermissions } from "collection/graphql/auth/lib/hasPolicyPermissions";

/**
 * The `@requiresPermission` directive will skip a field if the current user does not
 * have the specified permissions.
 *
 * @type {DocumentTransform}
 * @example
 * mutation updateCommodity($input: UpdateCommodityInput!) {
 *   result: updateCommodity(commodity: $input) @requiresPermission(feature: "commodities", actions: ["write"]) {
 *     ok
 *   }
 * }
 */
export const requiresPermissionDirective = new DocumentTransform((document) => {
  return visit(document, {
    Directive(node) {
      if (node.name.value === "requiresPermission") {
        const actions = map(find(node.arguments, ["name.value", "actions"])?.value?.values, "value");
        const feature = find(node.arguments, ["name.value", "feature"])?.value?.value;

        const isPermitted = hasPolicyPermissions(feature, actions);
        if (!isPermitted) {
          // replace the @requiresFeature node with @skip(if: true)
          return {
            kind: "Directive",
            name: { kind: "Name", value: "skip" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "if" },
                value: { kind: "BooleanValue", value: true },
              },
            ],
          };
        } else {
          // remove the @requiresFeature node
          return null;
        }
      }
    },
  });
});
