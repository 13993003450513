import { useQuery } from "@apollo/client";
import _ from "lodash";
import { useMemo } from "react";

import { marketingClient } from "collection/graphql/client";
import { getFieldCropYears } from "collection/graphql/fields/queries";

const CURRENT_YEAR = new Date().getFullYear();
const MAX_YEAR = CURRENT_YEAR + 2;
const MIN_YEAR = CURRENT_YEAR - 20;
const STEP = -1;

export const CROP_YEARS = _.range(MAX_YEAR, MIN_YEAR + STEP, STEP); // min and max cropYears inclusive

const getCropYears = (fieldCrops) => {
  return _.reduce(
    fieldCrops,
    (years, { cropYear }) => {
      years.add(cropYear);
      return years;
    },
    new Set()
  );
};

const useCropYears = (filterOptions = {}) => {
  const { fieldId } = filterOptions;
  const variables = fieldId ? { fields: [fieldId], years: CROP_YEARS } : { years: CROP_YEARS };
  const { data, loading } = useQuery(getFieldCropYears, {
    /*
     * CORE-6518 – excluding the context here caused an error on the map page since
     * the component being rendered was outside the typical context provided by
     * `ApolloProvider`. Removing the `client` option here would cause a breakage on the
     * maps page.
     */
    client: marketingClient,
    variables,
  });
  const crops = data?.fieldCrops ?? [];

  return useMemo(() => {
    const usedCropYears = getCropYears(crops);
    return CROP_YEARS.map((year) => ({ used: usedCropYears.has(year), year }));
  }, [crops, fieldId, loading]);
};

export default useCropYears;
