import moment from "moment";

/**
 * We should also be wary of using this due to performance concerns on large data sets. See
 * [CORE-5306]{@link https://farmlogs.atlassian.net/browse/CORE-5306} for more details.
 */
const ActivityTypePolicy = {
  fields: {
    isCancelled: {
      read: (__, { readField }) => readField("status") === "CANCELLED",
    },

    isOverdue: {
      read: (__, { readField }) => readField("status") === "PLANNED" && moment().isAfter(readField("date"), "day"),
    },

    isDone: {
      read: (__, { readField }) => readField("status") === "DONE",
    },

    isPlanned: {
      read: (__, { readField }) => readField("status") === "PLANNED",
    },

    statusText: (__, { readField }) => {
      switch (readField("status")) {
        case "CANCELLED":
          return "Cancelled";

        case "DONE":
          return "Done";

        case "PLANNED":
          return moment().isAfter(readField("date"), "day") ? "Overdue" : "Planned";
      }
    },
  },
};

export default ActivityTypePolicy;
