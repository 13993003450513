import { css, StyleSheet } from "aphrodite/no-important";
import BlankSlate from "imagery/components/BlankSlate";
import { isEmpty } from "lodash";
import { sanitizeImagery } from "map/layers/utils";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import router from "router";

import useImageryLayers from "lib/map/imagery/useImageryLayers";

import BasicFieldMap from "components/field/BasicFieldMap";
import { TOO_BIG_ACREAGE } from "components/field/utils";
import { Card } from "components/fl-ui/Card";
import { Icon } from "components/fl-ui/Icons";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import { GreyColors, UIColors } from "components/fl-ui/colors";
import { Spacing, Typography } from "components/fl-ui/constants";

const styles = StyleSheet.create({
  columnDivider: {
    borderLeft: `1px solid ${GreyColors.smoke90}`,
  },
  columnPadding: {
    padding: `${Spacing.xxsmall} ${Spacing.xxlarge}`,
  },
  header: {
    margin: `${Spacing.small} 0`,
  },
  imageryDate: {
    color: UIColors.blue,
    fontSize: Typography.sizes.lg,
    marginBottom: Spacing.xxsmall,
    marginTop: Spacing.xxsmall,
  },
  imageryDetails: {
    alignItems: "center",
    display: "flex",
    margin: `${Spacing.xsmall} 0`,
  },
  imageryIcon: {
    background: UIColors.white,
    border: `2px solid ${GreyColors.smoke90}`,
    borderRadius: "50%",
    color: UIColors.blue,
    height: "2.5rem",
    marginRight: Spacing.large,
    overflow: "visible",
    padding: Spacing.xxsmall,
    width: "2.5rem",
    zIndex: 100,
  },
  recentImageryContainer: {
    display: "flex",
    flexFlow: "column",
    marginTop: "-15rem",
  },
  text: {
    marginBottom: Spacing.xxsmall,
  },
  verticalLine: {
    height: "15rem",
    borderLeft: `2px solid ${GreyColors.smoke90}`,
    position: "relative",
    left: Spacing.medium,
    top: Spacing.xxlarge,
  },
});

const BlankContent = ({ isTooBig }) => (
  <div className="blank-slate">
    <span>
      {isTooBig ? `Satellite imagery is unavailable for fields larger than ${TOO_BIG_ACREAGE} acres.` : <BlankSlate />}
    </span>
  </div>
);

const SatelliteImagery = ({ acreage, fieldId, year }) => {
  const limit = 6;
  const endDate = (moment().year() === year ? moment() : moment().year(year).endOf("year")).format("YYYY-MM-DD");
  const startDate = moment(endDate).subtract(90, "days").format("YYYY-MM-DD");
  const imageryParams = { endDate, fieldId, startDate };
  const { imageryLayers, loading } = useImageryLayers(imageryParams);
  const imagery = !isEmpty(imageryLayers) && [...imageryLayers].reverse().slice(0, limit);
  const latestImagery = imagery && imagery[0];
  const colSize = imagery && imagery.length > 1 ? 6 : false;
  const imageSource = "Sentinel-2 Imagery";
  const imageType = "True Color";

  return (
    <>
      <Row>
        <Col xs>
          {!imagery ? (
            <h2>Imagery</h2>
          ) : (
            <a href={`#map/${fieldId}/layers?type=imagery`}>
              <h2>Imagery</h2>
            </a>
          )}
        </Col>
      </Row>

      <Row>
        <Col xs>
          <Card>
            {loading ? (
              <LoadingWrapper />
            ) : (
              <>
                {!imagery && <BlankContent isTooBig={+acreage > TOO_BIG_ACREAGE} />}

                {imagery && (
                  <Row>
                    <Col className={css(styles.columnPadding)} xs={12} sm={6} md={6}>
                      <BasicFieldMap
                        backdrop
                        centroid={latestImagery.field.centroid}
                        geometry={latestImagery.field.geometry}
                        layer={sanitizeImagery(latestImagery, imageType)?.layer}
                        onClick={() =>
                          router.navigate(`map/${fieldId}/layers?type=imagery&id=${latestImagery.id}`, {
                            trigger: true,
                          })
                        }
                        style={{ borderRadius: "4px", marginBottom: "1rem", maxWidth: "100%" }}
                      />

                      <div>
                        <h3 className={css(styles.text)}>{`Latest Image \u2022 ${imageType}`}</h3>
                        <p>{`${moment(latestImagery.details.date).format("MMM DD YYYY")} \u2022 ${imageSource}`}</p>
                      </div>
                    </Col>

                    <Col
                      className={css(styles.columnPadding, styles.columnDivider)}
                      xs={false}
                      sm={colSize}
                      md={colSize}
                    >
                      <h4 className={css(styles.header)}>RECENT IMAGES</h4>
                      <div>
                        <div className={css(styles.verticalLine)} />
                        <div className={css(styles.recentImageryContainer)}>
                          {imagery.slice(1).map((layer) => (
                            <div className={css(styles.imageryDetails)} key={layer.id}>
                              <Icon className={css(styles.imageryIcon)} icon="imagery" />
                              <div>
                                <a href={`#map/${fieldId}/layers?type=imagery&id=${layer.id}`}>
                                  <h4 className={css(styles.imageryDate)}>
                                    {moment(layer.details.date).format("MMM DD YYYY")}
                                  </h4>
                                </a>
                                <p className={css(styles.text)}>{imageSource}</p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
              </>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

SatelliteImagery.propTypes = {
  acreage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  fieldId: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
};

export default SatelliteImagery;
