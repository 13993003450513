import { NoReportData } from "../components/ReportError";
import React from "react";

import { HeaderCol, Col, Table, Header, Row, Body, Footer } from "components/fl-ui/Table";

/**
 * Represents a table of data, with a few knobs:
 *  - showing result count
 *  - an optional total row
 */
const TabularReport = ({ title, rows, columns, total, showResultCount, className }) => {
  const hasData = rows.length;

  if (!hasData) return <NoReportData />;

  if (hasData) {
    return (
      <div className={className}>
        {title ? <h3>{title}</h3> : ""}

        {showResultCount ? <p>Showing {rows.length.toLocaleString()} results</p> : ""}

        <Table>
          <Header>
            <Row>
              {columns.map(({ id, title, type }, index) => (
                <HeaderCol key={id} type={type}>
                  {title}
                </HeaderCol>
              ))}
            </Row>
          </Header>
          <Body>
            {rows.map((row) => (
              <Row key={Math.random()}>
                {columns.map(({ id, title, type }, index) => (
                  <Col key={id} type={type}>
                    {row[id]}
                  </Col>
                ))}
              </Row>
            ))}
          </Body>

          {total ? (
            <Footer>
              <Row>
                {columns.map(({ id, title, type }, index) => (
                  <Col key={id} type={type}>
                    {total[id]}
                  </Col>
                ))}
              </Row>
            </Footer>
          ) : (
            ""
          )}
        </Table>
      </div>
    );
  }
};

export default TabularReport;
