import ReferenceAreaLabel from "./ReferenceAreaLabel";
import {
  chartMargins,
  chartStyles,
  inlineStyles,
  getTicks,
  getColor,
  getColorClass,
  getUnit,
  xAxisProps,
  yAxisProps,
} from "./common";
import { css } from "aphrodite/no-important";
import _ from "lodash";
import moment from "moment";
import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Label,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { GreyColors, UIColors } from "components/fl-ui/colors";
import styles from "fields/onboarding/onboardingStyles";

const DailyToolTipContent = (props) => {
  const { aspect, data, period, plantingDate } = props;
  const { payload } = _.isEmpty(props.payload) ? {} : props.payload[0];
  const { date, sum, amount } = payload || {};

  let formattedSum = sum;
  let formattedAmount = amount;

  if (aspect === "gdd") {
    formattedSum = _.isUndefined(formattedSum) ? formattedSum : Math.round(formattedSum);
    formattedAmount = _.isUndefined(formattedAmount) ? formattedAmount : Math.round(formattedAmount);
  }

  if (period === "planting") {
    const { formattedAmount, formattedSum: total } =
      data.find(({ date }) => moment(date).format("YYYY-MM-DD") === plantingDate) || {};
    formattedSum = Math.round(formattedSum - total + formattedAmount);
  }

  const since = moment((_.minBy(data, "date") || {}).date || moment().startOf("year"));

  return (
    <div className={css(styles.tooltipContainer)}>
      <p className={css(styles.tooltipTitle)}>
        {formattedSum ? moment(date).format("MMMM Do YYYY") : moment(date).format("MMMM Do")}
      </p>
      <div>
        <h1 className={css(getColorClass(aspect))}>{`${formattedAmount}${getUnit(aspect)}`}</h1>
      </div>
      {!!formattedSum && (
        <div>
          <h1>{`${formattedSum}${getUnit(aspect)}`}</h1>
          <p className={css(styles.tooltipDescription)}>Since {since.format("MMM D")}</p>
        </div>
      )}
    </div>
  );
};

const DailyChart = (props) => {
  const { data, refAreaLeft, refAreaRight, refAreaSum, refAreaAvgSum, aspect, period, plantingDate } = props;
  const [leftDate, rightDate] = [refAreaLeft, refAreaRight].sort();
  const dailyYAxisProps = {
    ...yAxisProps,
    domain: ["dataMin", (dataMax) => Math.ceil(dataMax)],
  };
  return (
    <ResponsiveContainer minHeight={400} className={css(chartStyles.chartContainer)}>
      <BarChart
        data={data}
        margin={chartMargins}
        maxBarSize={14}
        minPointSize={4}
        barGap={0}
        onMouseDown={props.onMouseDown}
        onMouseMove={props.onMouseMove}
        onMouseUp={props.onMouseUp}
        cursor="crosshair"
      >
        <CartesianGrid isAnimationActive={false} stroke={GreyColors.smoke91} strokeDasharray="3 3" vertical={false} />
        <Bar
          dataKey="amount"
          name="Daily"
          yAxisId={1}
          data={data}
          margin={chartMargins}
          onMouseDown={props.handleMouseDown}
          onMouseMove={props.handleMouseMove}
          onMouseUp={props.handleMouseUp}
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={moment(entry.date).isAfter(plantingDate) ? getColor(aspect) : UIColors.medium}
            />
          ))}
        </Bar>

        {plantingDate && (
          <ReferenceLine yAxisId={1} x={moment(plantingDate).format()} strokeDasharray="3 3">
            <Label fill={UIColors.light} style={inlineStyles.refLineLabel} value="Planting" position="insideTopLeft" />
          </ReferenceLine>
        )}
        {leftDate && rightDate && (
          <ReferenceArea
            opacity={0.25}
            fill={UIColors.lightest}
            yAxisId="1"
            x1={leftDate}
            x2={rightDate}
            strokeOpacity={0.3}
          >
            <Label
              content={
                <ReferenceAreaLabel
                  aspect={aspect}
                  start={leftDate}
                  end={rightDate}
                  refAreaSum={refAreaSum}
                  refAreaAvgSum={refAreaAvgSum}
                  unit={getUnit(aspect)}
                />
              }
            />
          </ReferenceArea>
        )}
        <Tooltip
          content={
            <DailyToolTipContent aspect={aspect} data={data} offset={24} period={period} plantingDate={plantingDate} />
          }
        />
        <XAxis {...xAxisProps} ticks={getTicks(data)} />
        <YAxis {...dailyYAxisProps} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default DailyChart;
