import DownloadInvoiceLink from "./DownloadInvoiceLink";
import { css } from "aphrodite/no-important";
import _ from "lodash";
import moment from "moment";
import React from "react";
import styles, { HeaderText, TitleText } from "settings/styles";
import styled from "styled-components";

import { getStripeInvoiceHistory } from "collection/graphql/subscription";
import useRestQuery from "hooks/useRestQuery";

import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import { Spacing } from "components/fl-ui/constants";

const InvoiceList = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: ${Spacing.medium};

  > * {
    align-items: center;
    display: flex;
    gap: ${Spacing.medium};
    justify-content: flex-end;

    > :first-child {
      flex-grow: 2;
      margin-bottom: 0;
    }
    > button:last-child {
      min-width: 90px;
      text-align: right;
    }
  }
`;

const InvoiceHistory = () => {
  const { data, loading } = useRestQuery(getStripeInvoiceHistory);

  return (
    <>
      <div className={css(styles.rowBorderBottom)}>
        <HeaderText>Billing history</HeaderText>
      </div>

      <LoadingWrapper isLoading={loading} loadingText="Loading invoices...">
        <InvoiceList>
          {_.map(data?.invoices, (invoice) => (
            <div key={invoice.id}>
              <TitleText>{moment(invoice.date).format("MMMM D, YYYY")}</TitleText>

              <div>${invoice.total}</div>

              <DownloadInvoiceLink invoice={invoice} />
            </div>
          ))}
        </InvoiceList>
      </LoadingWrapper>
    </>
  );
};

export default InvoiceHistory;
