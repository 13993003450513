import { useQuery } from "@apollo/client";
import { partition, sortBy } from "lodash";

import { marketingClient } from "collection/graphql/client";
import { GET_FINANCIAL_ACCOUNTS } from "collection/graphql/profit_and_loss/queries";
import useUnits from "hooks/useUnits";

const getFormattedData = (list) => {
  const [archived, active] = partition(list, "isArchived");
  return { active, archived };
};

const useFinancialAccounts = () => {
  const { data, loading } = useQuery(GET_FINANCIAL_ACCOUNTS, { client: marketingClient });
  const { findEnumByName } = useUnits();
  const [expenseList, revenueList] = partition(sortBy(data?.accountList, ["name"]), { accountType: "EXPENSE" });

  return {
    accountTypes: findEnumByName("FinancialAccountType"),
    EXPENSE: getFormattedData(expenseList),
    loading,
    REVENUE: getFormattedData(revenueList),
  };
};

export default useFinancialAccounts;
