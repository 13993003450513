import { MicroLabel } from "agreements/forms/components";
import PropTypes from "prop-types";
import React from "react";

import { Button } from "components/fl-ui";
import { NumericInput } from "components/fl-ui/Form";
import { GreyColors } from "components/fl-ui/colors";

const AcreageMapControl = ({ id, onChange, onInputBlur, onInputFocus, onMapButtonClick, value }) => {
  const handleBlur = (event) => {
    if (onInputBlur) {
      onInputBlur(event);
    }
  };

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  const handleFocus = (event) => {
    if (onInputFocus) {
      onInputFocus(event);
    }
  };

  return (
    <MicroLabel inputId={id} label="Acreage">
      <NumericInput
        id={id}
        inputMode="decimal"
        maxPrecision={2}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        min={0}
        step={0.01}
        style={{ border: "none", width: "90px" }}
        suffix="ac"
        value={value}
      />

      <svg height="90%" viewBox="0 0 1 20" width="1px">
        <rect width="1" height="20" fill={GreyColors.smoke80} />
      </svg>

      <Button
        hollow
        icon="searchMap"
        onClick={onMapButtonClick}
        size="md"
        style={{
          border: "none",
          background: "transparent",
          paddingBottom: 0,
          paddingTop: 0,
        }}
      />
    </MicroLabel>
  );
};

AcreageMapControl.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onInputBlur: PropTypes.func,
  onInputFocus: PropTypes.func,
  onMapButtonClick: PropTypes.func.isRequired,
  value: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
};

export default AcreageMapControl;
