import { gql } from "@apollo/client";

import { MACHINE_DATA_FILE } from "collection/graphql/yield/fragments";

export default gql`
  mutation UploadMachineData($file: CreateMachineDataFileInput!) {
    createMachineDataFile(file: $file) {
      ok
      file {
        ...File
      }
    }
  }
  ${MACHINE_DATA_FILE}
`;
