import Backbone from "backbone";
import CommodityFilter from "ui/commodity_filter";
import Stats from "ui/stats";
import render from "ui/templates";

const commodityFilter = new CommodityFilter();
const stats = new Stats();

export default Backbone.View.extend({
  events: {
    remove: "remove",
  },

  initialize() {
    this.data = {};
  },

  render() {
    this.$el.html(render("sidebar"));
    this.renderStats();
    return this.renderCommodityFilter();
  },

  // renders the stats in the sidebar
  renderStats() {
    const filteredData = this.getData("stats");
    const yieldFilteredData = this.getData("statistics");
    const budgetFilteredData = this.getData("budget_stats");

    stats
      .setData("stats", filteredData)
      .setData("yield_stats", yieldFilteredData)
      .setData("budget_stats", budgetFilteredData)
      .setElement(this.$(".js-stats"))
      .render();

    return this;
  },

  // renders the commodity filter in the sidebar
  renderCommodityFilter() {
    commodityFilter
      .setData("commodities", this.getData("commodities"))
      .setElement(this.$(".js-commodity-filter"))
      .render();

    this.stopListening(commodityFilter);
    this.listenTo(commodityFilter, "change", function () {
      this.trigger("changeCommodity");
    });
    this.listenTo(commodityFilter, "change", this.handleStats);

    return this;
  },

  handleStats(event) {
    return this.renderStats();
  },

  // handles the states when filter is cleared
  resetStats() {
    return this.renderStats();
  },

  getCommodity() {
    return commodityFilter.getData("commodity_id");
  },

  setData(key, value) {
    this.data[key] = value;
    return this;
  },

  getData(key) {
    return this.data[key];
  },

  // checks if there are any filters in place
  hasFilters() {
    return this.hasGroupFilter() || this.getCommodity() !== "all";
  },

  // removes both the filters using their respective removefilter methods
  removeFilters() {
    return commodityFilter.resetCommodity();
  },
});
