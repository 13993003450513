import { ApolloClient } from "@apollo/client";

import defaultCache from "collection/graphql/cache";
import { restLink } from "collection/graphql/client/links/restLink";

/**
 * Use `marketingClient` instead.
 * @deprecated
 */
const client = new ApolloClient({
  cache: defaultCache,
  link: restLink,
});

export default client;
