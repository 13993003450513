import PropTypes from "prop-types";
import React, { useState } from "react";
import router from "router";

import { useAuth } from "collection/graphql/auth";
import InviteLayoutForm from "layout/components/InviteLayoutForm";
import { getResetErrors, hasErrors } from "layout/utils";
import { UnauthorizedError } from "lib/errors";

import ErrorBoundary from "components/fl-ui/Layout/ErrorBoundary";

const InviteLayout = ({ token = "" }) => {
  const { resetPassword } = useAuth();
  const defaultValues = {
    confirm_password: "",
    error: token ? "" : "No invite token detected. Please check your email and click on the link given.",
    loading: false,
    password: "",
  };

  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState(defaultValues);
  const { password } = formValues;

  const handleChange = ({ target: { name, value } }) => setFormValues({ ...formValues, [name]: value });
  const handleError = (errorMessage) => setFormValues({ ...formValues, error: errorMessage, loading: false });

  const handleSubmit = (event) => {
    event.preventDefault();
    const formErrors = getResetErrors(formValues);
    setFormErrors(formErrors);

    if (hasErrors(formErrors)) {
      return null;
    }

    setFormValues({ ...formValues, error: "", loading: true });
    return resetPassword({ token, password })
      .then(() => {
        router.navigate("fields?new=true", { replace: true, trigger: true });
      })
      .catch((error) => {
        let errorMessage =
          "Sorry, we couldn't reset the password. Please try again, or contact Customer Experience if the problem persists.";
        if (error instanceof UnauthorizedError) {
          errorMessage =
            "The password reset link has expired or is invalid. Please try requesting a new password reset email.";
        }

        handleError(errorMessage);
      });
  };

  return (
    <ErrorBoundary>
      <InviteLayoutForm
        formErrors={formErrors}
        formValues={formValues}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
    </ErrorBoundary>
  );
};

InviteLayout.propTypes = {
  token: PropTypes.string.isRequired,
};

export default InviteLayout;
