import { useMutation } from "@apollo/client";
import _ from "lodash";

import { DELETE_ACTIVITIES } from "collection/graphql/activities/mutations";
import { GET_MINIMAL_ACTIVITY_LIST } from "collection/graphql/activities/queries";
import useCurrentCropYear from "hooks/useCurrentCropYear";

/**
 * Returns a function which performs a bulk deletion on a set of given activity ids. The query for
 * the current crop year will be updated.
 * @return {Function}
 */
const useActivityBulkDelete = () => {
  const cropYear = useCurrentCropYear()[0];
  const mutation = useMutation(DELETE_ACTIVITIES)[0];

  /**
   * @param {Number[]} ids the activity ids to delete in bulk.
   * @returns {Promise}
   */
  return (ids) =>
    mutation({
      variables: { ids },

      update: (cache) => {
        const queryOptions = { query: GET_MINIMAL_ACTIVITY_LIST, variables: { cropYear } };
        const cachedActivities = cache.readQuery(queryOptions)?.activities;
        const updatedActivities = _.reject(cachedActivities, ({ id }) => ids.includes(id));

        cache.writeQuery({ ...queryOptions, data: { activities: updatedActivities } });
      },
    });
};

export default useActivityBulkDelete;
