import PropTypes from "prop-types";
import React from "react";

const FilterIcon = ({ width, height, className }) => (
  <svg className={`icon-root ${className}`} width={width} height={height} alt="filter" viewBox="0 0 18 12">
    <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
      <g fill="#000000" id="Core" transform="translate(-465.000000, -216.000000)">
        <g id="filter" transform="translate(465.000000, 216.000000)">
          <path
            className="icon"
            d="M7,12 L11,12 L11,10 L7,10 L7,12 L7,12 Z M0,0 L0,2 L18,2 L18,0 L0,0 L0,0 Z M3,7 L15,7 L15,5 L3,5 L3,7 L3,7 Z"
            id="Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);

FilterIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

FilterIcon.defaultProps = {
  className: "",
  height: 16,
  width: 16,
};

export default FilterIcon;
