import { useAuth } from "collection/graphql/auth";

const useMarketingAdvisor = () => {
  const { currentUser } = useAuth();
  const isMarketingAdvisor = ["marketing_advisor", "cargill_admin"].includes(currentUser?.role);

  return {
    isMarketingAdvisor,
  };
};

export default useMarketingAdvisor;
