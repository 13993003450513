import { Label } from "agreements/forms/components";
import Schedule from "agreements/forms/fieldsets/PaymentScheduleFieldset/Schedule";
import { calculateDateRange, calculateDateRangeForYears } from "agreements/utils";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";

import { Button } from "components/fl-ui";
import { Stack } from "components/fl-ui/Layout";

const createSchedule = (agreementType, { startYear, endYear, startDate, termLength, termUnit }) => {
  let newStartDate, newEndDate;
  if (agreementType === "LEASE") {
    const isValidYear = (year) => /^\d{4}$/.test(year);
    if (isValidYear(startYear) && isValidYear(endYear)) {
      const dateRange = calculateDateRangeForYears(startYear, endYear);
      newStartDate = dateRange.startDate;
      newEndDate = dateRange.endDate;
    }
  } else if (moment(startDate).isValid()) {
    const dateRange = calculateDateRange(startDate, termLength, termUnit);
    newStartDate = dateRange.startDate;
    newEndDate = dateRange.endDate;
  }

  return {
    paymentInterval: 6,
    paymentPeriod: "MONTH",
    paymentMonth: null,
    paymentDay: 1,
    amount: null,
    amountRate: "PER_ACRE",
    startDate: newStartDate && newStartDate.format("YYYY-MM-DD"),
    endDate: newEndDate && newEndDate.format("YYYY-MM-DD"),
  };
};

const PaymentScheduleFieldset = ({ agreementType, fields, onChange, showRevenueLabel }) => {
  const [paymentSchedules, setPaymentSchedules] = useState(new Map());

  useEffect(() => {
    const newPaymentSchedules = new Map(paymentSchedules);
    _.each(fields.paymentSchedules, (schedule) => newPaymentSchedules.set(_.uniqueId("schedule"), schedule));
    setPaymentSchedules(newPaymentSchedules);
  }, []);

  const addListItem = () => {
    const newPaymentSchedules = new Map(paymentSchedules);
    newPaymentSchedules.set(_.uniqueId("schedule"), createSchedule(agreementType, fields));
    updateSchedules(newPaymentSchedules);
  };

  const removeListItem = (key) => {
    const newPaymentSchedules = new Map(paymentSchedules);
    newPaymentSchedules.delete(key);
    updateSchedules(newPaymentSchedules);
  };

  const updateListItem = (key, schedule) => {
    const newPaymentSchedules = new Map(paymentSchedules);
    newPaymentSchedules.set(key, schedule);
    updateSchedules(newPaymentSchedules);
  };

  const updateSchedules = (newPaymentSchedules) => {
    setPaymentSchedules(newPaymentSchedules);
    onChange({
      paymentSchedules: Array.from(newPaymentSchedules, ([key, schedule]) => schedule),
    });
  };

  return (
    <Stack>
      <Label>{showRevenueLabel ? "Revenue schedule (optional)" : "Payment schedule (optional)"}</Label>
      {Array.from(paymentSchedules, (schedule) => schedule).map(([key, schedule], index) => (
        <Schedule
          agreementType={agreementType}
          fields={fields}
          key={key}
          onChange={updateListItem.bind(this, key)}
          onRemove={() => removeListItem(key)}
          ordinal={index + 1}
          schedule={schedule}
          total={paymentSchedules.size}
        />
      ))}
      <Button onClick={addListItem}>{showRevenueLabel ? "Add revenue schedule" : "Add payment schedule"}</Button>
    </Stack>
  );
};

export default PaymentScheduleFieldset;
