import { css } from "aphrodite/no-important";
import { last, max } from "lodash";
import LayerButton from "map/layers/LayerButton";
import { Arrow, FlatButton } from "map/layers/helpers";
import styles from "map/layers/styles";
import moment from "moment";
import React from "react";
import autoBind from "react-autobind";

import { EXPLORER_GALLERY_SCROLL } from "lib/metrics/events";

import { Button } from "components/fl-ui";
import Icon from "components/fl-ui/Icons/Icon";
import Popover from "components/fl-ui/Popover/Popover";

const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;
const KEY_OFFSET = {
  [LEFT_ARROW]: -1,
  [RIGHT_ARROW]: 1,
};

const gradient = {
  left: "right",
  right: "left",
};

const arrowButtonStyle = ({ direction, disabled }) => ({
  backgroundImage: disabled
    ? "none"
    : `linear-gradient(to ${gradient[direction]}, \
rgba(23, 24, 28, 1), \
rgba(23, 24, 28, .85), \
rgba(23, 24, 28, .65), \
rgba(23, 24, 28, 0) \
)`,
  borderRadius: "0",
  height: "100%",
  left: direction === "left" && 0,
  opacity: disabled && ".5",
  position: "absolute",
  right: direction === "right" && 0,
  top: 0,
  width: "7rem",
});

const ArrowButtonWhite = (props) => (
  <FlatButton id={props.id} onClick={props.onClick} style={arrowButtonStyle(props)}>
    <Arrow color="white" direction={props.direction} style={{ height: "1.5rem", width: "1.5rem" }} />
  </FlatButton>
);

class LayerGallery extends React.Component {
  constructor(props) {
    super(props);
    this.contentRef = React.createRef();
    this.selectedRef = React.createRef();

    this.state = {
      disableRight: true,
      disableLeft: true,
      page: 0,
      pageSize: 0,
      selectedYear: null,
    };

    autoBind(this);
  }

  componentDidMount() {
    document.addEventListener("keyup", this.handleKeyUp);
    if (this.props.type === "imagery") {
      return this.handleImageryLayers();
    }

    return this.handlePage();
  }

  componentDidUpdate({ layers, value }) {
    if (this.props.value !== value || this.props.layers !== layers) {
      if (this.props.type === "imagery" && this.props.layers !== layers) {
        return this.handleImageryLayers();
      }

      return this.handlePage();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.handleKeyUp);
  }

  handleImageryLayers() {
    this.content = {};
    this.props.layers.forEach((l) => {
      const year = moment(l.date).year();

      if (!this.content[year]) {
        this.content[year] = [l];
      } else {
        this.content[year].push(l);
      }
    });
    this.availableYears = Object.keys(this.content);

    const selectedYear = moment(this.props.value.date).year() || max(this.availableYears);
    return this.setState(() => ({ selectedYear }), this.handlePage);
  }

  handleKeyUp({ keyCode }) {
    const offset = KEY_OFFSET[keyCode];
    if (!offset) {
      return;
    }

    const current = this.props.layers.indexOf(this.props.value);
    const next = this.props.layers[current + offset];

    if (next) {
      this.props.onChange(next);
    }
  }

  setPage(newPage) {
    const { offsetWidth, scrollWidth } = this.contentRef.current;
    const totalPages = scrollWidth / offsetWidth - 1;
    const page = Math.max(Math.min(newPage, totalPages), 0);

    return this.setState({
      page,
      pageSize: offsetWidth,
      disableLeft: !page,
      disableRight: page === totalPages,
    });
  }

  handlePageLeft() {
    EXPLORER_GALLERY_SCROLL.track({ direction: "older" });
    return this.setPage(this.state.page - 1);
  }

  handlePageRight() {
    EXPLORER_GALLERY_SCROLL.track({ direction: "newer" });
    return this.setPage(this.state.page + 1);
  }

  handlePage() {
    const target = this.selectedRef.current;
    if (!target) {
      return;
    }

    const { offsetWidth, scrollWidth } = this.contentRef.current;
    const { right, width } = target.getBoundingClientRect();
    const { left } = this.contentRef.current.getBoundingClientRect();
    const middle = right - width / 2 - left;
    const totalPages = scrollWidth / offsetWidth;
    const page = (middle / scrollWidth) * totalPages - 0.5;

    return this.setPage(page);
  }

  handleClick(e, value) {
    this.handlePage(e);
    return this.props.onChange(value);
  }

  render() {
    const { selectedYear } = this.state;
    const { imageryType, type, value } = this.props;
    const isImageryLayer = type === "imagery" && this.content;
    const layers = isImageryLayer ? this.content[selectedYear] : this.props.layers;

    return (
      <div className={`col xs-12 ${css(styles.galleryContainer)}`}>
        {isImageryLayer && (
          <div style={{ margin: "0.25em" }}>
            <Popover
              content={
                <div className={css(styles.popoverContent)}>
                  {this.availableYears.map((year) => (
                    <Button
                      className={css(styles.popoverSelection)}
                      key={year}
                      link
                      onClick={() => {
                        this.setState({ selectedYear: year });
                        this.props.onChange(last(this.content[year]));
                      }}
                    >
                      {year}
                    </Button>
                  ))}
                </div>
              }
              placement="top"
            >
              <Button className={css(styles.popoverTarget)} color="white" link>
                <Icon className={css(styles.popoverTargetIcon)} icon="calendar" />
                {this.state.selectedYear}
                <Icon className={css(styles.popoverTargetIcon)} icon="chevronDown" />
              </Button>
            </Popover>
          </div>
        )}

        <div className={`row ${css(styles.galleryStyle, !isImageryLayer && styles.noYearPicker)}`}>
          <div className="col xs-12 np">
            <div
              ref={this.contentRef}
              style={{
                position: "relative",
                right: this.state.pageSize * this.state.page + "px",
                whiteSpace: "nowrap",
                overflow: "show",
                margin: "0 6rem",
                transition: "1s",
              }}
            >
              {layers.map((layer) => (
                <span key={layer.id || layer.date || layer.details.date}>
                  <LayerButton
                    alert={layer.anomaly}
                    imageryType={imageryType}
                    onClick={this.handleClick}
                    ref={layer.id === value.id && this.selectedRef}
                    selected={layer.id === value.id}
                    value={layer}
                  >
                    {type === "yield.512" ? layer.crop?.cropYear : moment(layer.date).format("MMM DD")}
                  </LayerButton>
                </span>
              ))}
            </div>
            <ArrowButtonWhite
              direction="left"
              disabled={this.state.disableLeft}
              id="layer-gallery-left-button"
              onClick={this.handlePageLeft}
            />
            <ArrowButtonWhite
              direction="right"
              disabled={this.state.disableRight}
              id="layer-gallery-right-button"
              onClick={this.handlePageRight}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default LayerGallery;
