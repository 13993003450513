import router from "router";

import SubRoute from "lib/SubRoute";

/*
 * CORE-2276 renames the Notes feature to Scouting. This route redirects any remaining /notes routes
 * to their /scouting counterparts. It can be removed once we're sure all of these routes have been
 * correctly renamed.
 */
const NotesRouter = SubRoute.extend({
  routes: {
    "": "index",
    add: "addingNote",
    ":id": "note",
  },

  index: () => router.navigate("scouting", { replace: true, trigger: true }),
  note: (id) => router.navigate(`scouting/${id}`, { replace: true, trigger: true }),
  addingNote: (params = {}) => {
    const query = params.field_id ? `?field_id=${params.field_id}` : "";
    return router.navigate(`scouting/add${query}`, { replace: true, trigger: true });
  },
});

export default () => new NotesRouter("notes", { createTrailingSlashRoutes: true });
