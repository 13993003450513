import { gql } from "@apollo/client";

/**
 * @typedef {object} UpdatePasswordInput
 * @property {string} currentPassword
 * @property {string} newPassword
 */
export default gql`
  mutation updatePassword($input: UpdatePasswordInput!) {
    updatePassword(input: $input) @rest(path: "/users/password/update", method: "post") {
      ok
    }
  }
`;
