import { gql } from "@apollo/client";

export default gql`
  fragment ScaleTicket on ScaleTicket {
    contractId
    date
    dockAmount
    grossAmount
    id
    moisturePercentage
    netAmount
    netWeight
    ticketId
  }
`;
