import eventFactory from "lib/metrics/event_factory";

export const CLU_SELECT_FIELD_DRAW_PRESS = eventFactory("Field Draw Press", {
  context: "CLU Select",
});

export const FIELD_DRAW_POINT_DELETE = eventFactory("Point Delete", {
  context: "Field Draw",
  dynamic: ["latitude", "longitude"],
});

export const MAP_FARM_BOUNDARIES_PRESS = eventFactory("Farm Boundaries Press", {
  context: "Map",
});

export const MAP_LOCATION_SEARCH = eventFactory("Location Search", {
  context: "Map",
  dynamic: ["query"],
});

export const MAP_SELECTION = eventFactory("Map: Selection", {
  context: "Map",
  entity: "Field",
  dynamic: ["entity_id", "name"],
});

export const MAP_USER_LOCATION_PRESS = eventFactory("User Location Press", {
  context: "Map",
});

export const EXPLORER_VIEW_TYPE_CHANGE = eventFactory("View Type Change", {
  context: "Explorer",
  dynamic: ["current_layer_type", "previous_layer_type"],
});

export const EXPLORER_SELECTION_FIELD = eventFactory("Map: Selection Explorer", {
  context: "Explorer",
  entity: "Field",
  dynamic: ["entity_id"],
});

export const EXPLORER_FIELD_SEARCH = eventFactory("Field Search", {
  context: "Explorer",
});

export const EXPLORER_VIEW_FILTER_CHANGE = eventFactory("Map: View Filter Change", {
  context: "Explorer",
  current_mode: ["single", "double"],
  previous_mode: ["single", "double"],
});

export const EXPLORER_GALLERY_SCROLL = eventFactory("Gallery Scroll", {
  context: "Explorer",
  direction: ["newer", "older"],
});

export const EXPLORER_BLANK_SLATE_SHOW = eventFactory("Blank Slate Show", {
  context: "Explorer",
  type: ["RX Promo", "RX Prompt", "Yield Prompt"],
});

export const EXPLORER_SELECTION_FIELD_LAYER = eventFactory("Map: Selection Field Layer", {
  context: "Explorer",
  entity: "Field Layer",
  dynamic: ["entity_id", "field_id", "layer_type"],
});

export const EXPLORER_LAYER_INTERACTION = eventFactory("Layer Interaction", {
  context: "Explorer",
  entity: "Field Layer",
  dynamic: ["category", "entity_id", "field_id", "layer_type"],
});
