import { gql } from "@apollo/client";

export default gql`
  fragment DetailedProductPurchaseSummary on ProductPurchaseSummary {
    amountAppliedTotal
    amountAppliedUnit
    amountPurchasedTotal
    amountPurchasedUnit
    amountRemainingTotal @client
    amountRemainingUnit @client
    costTotal
    costPerUnit
    lastPurchaseDate
    activityInputs {
      ...ActivityInputDetail
      activity {
        acreage
        date
        id
        field {
          id
          name
        }
        type {
          name
        }
      }
    }
    product {
      ...DetailedProduct
    }
    purchases {
      ...InputPurchase
    }
    __typename
  }
`;
