import { css } from "aphrodite";
import { isNil, keyBy } from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Col, Row } from "react-styled-flexboxgrid";

import useUnits from "hooks/useUnits";
import styles from "modules/activity/common/styles";
import ActivityQuantityInput from "modules/activity/components/ActivityQuantityInput";

import { Button } from "components/fl-ui";
import { FormGroup } from "components/fl-ui/Form";
import Input from "components/fl-ui/Form/Input";
import Popover from "components/fl-ui/Popover/Popover";

const PER_SECOND = "PER_SECOND";
const PER_MINUTE = "PER_MINUTE";
const PER_HOUR = "PER_HOUR";
const PER_DAY = "PER_DAY";
const TOTAL = "TOTAL";

const rateLookup = {
  PER_SECOND: { abbr: "/ sec", id: PER_SECOND },
  PER_MINUTE: { abbr: "/ min", id: PER_MINUTE },
  PER_HOUR: { abbr: "/ hr", id: PER_HOUR },
  PER_DAY: { abbr: "/ day", id: PER_DAY },
  TOTAL: { abbr: " ", id: TOTAL },
};

const WATER_PRODUCT = {
  amount: "",
  amountUnit: "GALLON",
  amountRate: "PER_SECOND",
  duration: "",
  durationUnit: "SECOND",
};

const WaterInput = (props) => {
  const { onChange } = props;
  const { data: unitData, findEnumByName, loading } = useUnits();
  const amountRates = findEnumByName("ActivityIrrigationAmountRate") ?? [];
  const amountUnits = findEnumByName("ActivityIrrigationAmountUnit") ?? [];
  const durationUnits = findEnumByName("ActivityIrrigationDurationUnit") ?? [];
  const [amount, setAmount] = useState(props.amount || WATER_PRODUCT.amount);
  const [amountRate, setAmountRate] = useState(props.amountRate || WATER_PRODUCT.amountRate);
  const [amountUnit, setAmountUnit] = useState(props.amountUnit || WATER_PRODUCT.amountUnit);
  const [duration, setDuration] = useState(props.duration || WATER_PRODUCT.duration);
  const [durationUnit, setDurationUnit] = useState(props.durationUnit || WATER_PRODUCT.durationUnit);
  const rates = amountRates.map(({ display, value }) => ({ label: display, name: display, ...rateLookup[value] }));
  const volumeUnitsById = keyBy(unitData?.volumeUnits, "data");
  const units = amountUnits.map(({ value }) => {
    const unit = volumeUnitsById[value];
    return { ...unit, id: value, label: unit.display, name: unit.display };
  });

  const renderInputSuffix = () => {
    return (
      <Popover
        content={durationUnits.map(({ display, value }) => (
          <Button
            className={css(styles.durationDropdownButton, durationUnit === value && styles.blueColor)}
            disabled={amountRate === TOTAL}
            key={value}
            link
            onClick={() => {
              setDurationUnit(value);

              if (!isNaN(parseFloat(duration)) && !isNaN(parseFloat(amount))) {
                onChange({ durationUnit: value });
              }
            }}
          >
            {display}
          </Button>
        ))}
      >
        <Button className={css(styles.dropDownButton)} disabled={loading} icon="buttonChevronDown" iconRight>
          {durationUnits.find(({ value }) => value === durationUnit)?.display}
        </Button>
      </Popover>
    );
  };

  return (
    <Row>
      <Col xs md={6}>
        <FormGroup label="Water amount (optional)">
          <ActivityQuantityInput
            amount={isNaN(amount) ? "" : amount}
            disabled={loading}
            onChange={(data) => {
              const payload = {};
              const { amount: newAmount, selectedRate, selectedUnit } = data;
              const amountData = isNil(newAmount) ? amount : newAmount;
              const amountRateData = selectedRate ? selectedRate.id : amountRate;
              const amountUnitData = selectedUnit ? selectedUnit.id : amountUnit;

              setAmount(amountData);
              setAmountRate(amountRateData);
              setAmountUnit(amountUnitData);

              if (isNaN(parseFloat(amountData))) {
                onChange({ amount: null, amountRate: null, amountUnit: null, duration: null, durationUnit: null });
              } else {
                payload.amount = parseFloat(amountData);
                payload.amountRate = amountRateData;
                payload.amountUnit = amountUnitData;
                payload.duration = isNaN(parseFloat(duration)) ? null : duration;
                payload.durationUnit = payload.duration ? durationUnit : null;

                if (payload.amountRate === TOTAL) {
                  setDuration("");
                  payload.duration = null;
                  payload.durationUnit = null;
                }
                onChange(payload);
              }
            }}
            rates={rates}
            selectedRate={rates.find(({ id }) => id === amountRate)}
            selectedUnit={units.find(({ id }) => id === amountUnit)}
            units={units}
          />
        </FormGroup>
      </Col>

      <Col xs md={6}>
        <FormGroup label="Duration (optional)">
          <Input
            className={css(styles.bottomMarginSmall)}
            disabled={amountRate === TOTAL}
            display="block"
            name="duration"
            onChange={({ target }) => {
              const value = parseFloat(target.value);
              setDuration(target.value);

              if (!isNaN(value) && !isNaN(parseFloat(amount))) {
                onChange({ duration: value, durationUnit });
              } else {
                onChange({ duration: null, durationUnit: null });
              }
            }}
            size="large"
            suffix={renderInputSuffix()}
            type="number"
            value={isNaN(duration) ? "" : duration}
          />
        </FormGroup>
      </Col>
    </Row>
  );
};

WaterInput.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  amountRate: PropTypes.string,
  amountUnit: PropTypes.string,
  duration: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  durationUnit: PropTypes.string,
  onChange: PropTypes.func,
};

export default WaterInput;
