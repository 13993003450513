export const BASE_RENT = "BASE_RENT";
export const CROP_SHARE = "CROP_SHARE";
export const EXPENSE_SHARE = "EXPENSE_SHARE";
export const LEASE = "LEASE";
export const LOAN = "LOAN";
export const PER_ACRE = "PER_ACRE";
export const PERCENTAGE = "PERCENTAGE";
export const REVENUE = "REVENUE";
export const TOTAL = "TOTAL";
export const UPDATE_FIELD_INPUT = [
  "acreage",
  "fieldGroupId",
  "fsaFarm",
  "fsaTract",
  "geometry",
  "id",
  "isIrrigated",
  "isRented",
  "isTiled",
  "landValue",
  "landValueRate",
  "name",
  "notes",
  "productionHistory",
];
export const YIELD_ADJUSTMENT = "YIELD_ADJUSTMENT";
