import { css } from "aphrodite/no-important";
import NewLocalPrices from "prices/containers/NewLocalPrices";
import { localBidsStyles as styles } from "prices/styles";
import React from "react";

import useCurrentEnterprise from "hooks/useCurrentEnterprise";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";

import LocalPricesBlankStatePage from "components/advertisements/pages/LocalPricesBlankStatePage";
import ZipCodeInput from "components/fl-ui/ZipCodeInput";

const LocalBids = ({ buyerId = null }) => {
  const { currentEnterprise } = useCurrentEnterprise();
  const hasLocalPricesFeature = useEnterpriseFeature("local_prices");
  const zipCode = currentEnterprise?.postalCode;

  if (!hasLocalPricesFeature) {
    return <LocalPricesBlankStatePage />;
  } else if (!zipCode) {
    return (
      <div className={css(styles.blankslateContainer)}>
        <h2>Where is your farm located?</h2>
        <p className={css(styles.blankslateText)}>
          {
            "Bushel Farm uses your postal code to calculate average basis and county yield averages for your crops. You can always change this later in your "
          }
          <a className={css(styles.blankslateLink)} href="/#settings/farm">
            Farm settings
          </a>
          .
        </p>
        <ZipCodeInput defaultValue={zipCode} icon="marketingPrices" />
      </div>
    );
  }

  return <NewLocalPrices buyerId={buyerId} zipCode={zipCode} />;
};

export default LocalBids;
