import { fileClient } from "@farmlogs/fl-ui";
import * as turf from "@turf/turf";
import Leaflet from "leaflet";
//leaflet.pattern is outdated and the cause of the final outstanding console error
// unfortunately there are no newer versions of leaflet.pattern
// it was last updated six years ago, I'm not sure how we want to handle this
// https://github.com/teastman/Leaflet.pattern
// https://www.npmjs.com/package/leaflet.pattern
import "leaflet.pattern";
import { sortBy } from "lodash";
import moment from "moment";

import GeoJsonLayer from "lib/map/layers/GeoJsonLayer";
import MapUtils from "lib/map/utils";

import { style } from "fields/utils";

const { calculateAcreage } = MapUtils;

export const getImageUrl = (filepickerId, height) => {
  const options = { quality: 75 };
  if (height) {
    options.height = height;
  }

  return fileClient.getImagePreviewUrl(filepickerId, options);
};

export const getSrcURL = (filepickerId) => getImageUrl(filepickerId, 64);

export const hasOverlap = (overlapAcreage, geometry1, geometry2, threshold = 0.001) => {
  const isAboveThreshold = (acreage, geometry) => acreage / calculateAcreage(geometry) > threshold;
  return isAboveThreshold(overlapAcreage, geometry1) || isAboveThreshold(overlapAcreage, geometry2);
};

export const TOO_BIG_ACREAGE = 1000;

export const getCropGeoJSON = ({ color, crop, field }) => {
  const { centroid, geometry, geometricCircumference } = crop || field;

  return {
    type: "Feature",
    geometry,
    properties: {
      centroid,
      color: color || crop?.commodity?.color || "gray",
      field: {
        id: crop?.field?.id || field.id,
      },
      id: crop?.id,
      ...(geometricCircumference?.properties ?? {}),
    },
  };
};

export const getFeature = ({ geometry, geometricCircumference, id }) => {
  if (geometricCircumference) {
    return { ...geometricCircumference, properties: { ...geometricCircumference.properties, id } };
  }

  return turf.feature(geometry, { id });
};

export const getIconPath = (field, options) => {
  const { geometry } = field;
  const isValidGeometry = ["Point", "Polygon", "MultiPolygon"].includes(geometry.type);

  return !isValidGeometry ? "/images/fields/unknown.png" : MapUtils.getThumbnail(geometry, options);
};

export const getLayerCollection = (collection, features, map, options) => {
  collection?.remove();
  const featureCollection = collection || new GeoJsonLayer();
  features.forEach((feature) => featureCollection.addFeature(feature, options));
  featureCollection.addTo(map);
  featureCollection.layers.pm.setOptions({
    allowCutting: false,
    allowEditing: false,
    allowRemoval: false,
    allowRotation: false,
    draggable: false,
  });
  return featureCollection;
};

export const getLayerFillPattern = (mainColor, spaceColor) =>
  new Leaflet.StripePattern({ angle: 120, color: mainColor, opacity: 0.7, spaceColor, spaceOpacity: 0.7 });

export const getLargestIntersection = (cropList, threshold = 0.05) => {
  const result = { acreage: 0 };

  for (let i = 0; i < cropList.length; i++) {
    for (let j = i + 1; j < cropList.length; j++) {
      const intersection = turf.intersect(cropList[i].geometry, cropList[j].geometry);
      const acreage = intersection ? calculateAcreage(intersection) : 0;

      if (acreage > result.acreage) {
        if (hasOverlap(acreage, cropList[i].geometry, cropList[j].geometry, threshold)) {
          result.acreage = acreage;
          result.intersection = intersection;
          result.crops = [cropList[i], cropList[j]];
        }
      }
    }
  }

  return result.acreage ? result : null;
};

export const getSnappedLayerData = (id, layer, message, parentGeometry, fillPattern) => {
  const feature = layer.toGeoJSON();
  const data = { geometry: feature.geometry, layer };
  const diff = turf.difference(feature, parentGeometry);
  const intersection = turf.intersect(feature, parentGeometry);

  if (diff && hasOverlap(MapUtils.calculateAcreage(diff), feature, parentGeometry)) {
    data.geometry = intersection ? intersection.geometry ?? null : null;
    data.layer = data.geometry
      ? new GeoJsonLayer({
          feature: turf.feature(data.geometry, { id, style: { fillPattern } }),
          style,
        })
      : null;
    data.message = message;
    data.temporaryFeatures = [{ ...diff, properties: { id: "_tempLayerId" } }];
  }

  return data;
};

export const getSortedAttachments = (attachments, sortCriteria) => {
  const nameSortedAttachments = sortBy(attachments, ({ name }) => name.toLowerCase());

  switch (sortCriteria) {
    case "name":
      return nameSortedAttachments;
    case "user":
      return sortBy(nameSortedAttachments, ({ uploadedBy }) => uploadedBy.toLowerCase());
    case "date":
    default:
      return sortBy(nameSortedAttachments, ({ created }) => -moment(created).unix());
  }
};
