import _ from "lodash";
import numeral from "numeral";
import PropTypes from "prop-types";
import React from "react";

import { Spacing } from "components/fl-ui/constants";
import ContractUnit from "components/units/ContractUnit";

const ContractQuantity = ({ contract, quantity }) => {
  const isPopulated = !_.isNil(quantity);
  const formattedQty = numeral(quantity).format("0,0[.]00");

  return (
    <div>
      <span className="quantity-text">{isPopulated ? formattedQty : "--"}</span>
      {isPopulated ? <ContractUnit contract={contract} pl={Spacing.xxsmall} /> : null}
    </div>
  );
};

ContractQuantity.propTypes = {
  contract: ContractUnit.propTypes.contract.isRequired,
  quantity: PropTypes.string,
};

export default ContractQuantity;
