import { gql } from "@apollo/client";

export default gql`
  fragment Commodity on Commodity {
    abbr
    bushelSize
    color
    defaultSeedApplyUnit
    defaultSeedPurchaseUnit
    defaultYieldUnit
    dryMoisture
    gddTbase
    gddTceil
    id
    isExchangeTraded
    isUsed
    marketSymbol
    name
    offersEnabled
    seedUnitType
  }
`;
