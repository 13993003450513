const Spacing = {
  xxxxsmall: "0.063rem", // 1px
  xxxsmall: "0.125rem", // 2px
  xxsmall: "0.25rem", // 4px
  xsmall: "0.5rem", // 8px
  small: "0.75rem", // 12px
  minor: "0.875rem", // 14px
  regular: "1rem", // 16px
  medium: "1.25rem", // 20px
  large: "1.5rem", // 24px
  xlarge: "1.75rem", // 28px
  xxlarge: "2rem", // 32px
  xxxlarge: "3rem", // 48px
};

export default Spacing;
