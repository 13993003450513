import { gql } from "@apollo/client";

import { ActivityDetail } from "collection/graphql/activities/fragments";

export default gql`
  query getActivityById($id: Int!) {
    activity: getActivity(id: $id) {
      ...ActivityDetail
    }
  }

  ${ActivityDetail}
`;
