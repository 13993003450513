/* eslint-disable react/display-name */
import { useViewportSpy } from "beautiful-react-hooks";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { memo, useContext, useEffect, useMemo, useRef, useState } from "react";

import usePermissions from "hooks/usePermissions";

import { Button } from "components/fl-ui";
import FieldIndexContext from "fields/components/context/FieldIndexContext";

const FieldCropButton = memo((props) => {
  const { crops: fieldCrops, loading, fieldId } = props;
  const { onAddCrop, year } = useContext(FieldIndexContext);
  const handleClick = (event) => {
    event.preventDefault();
    onAddCrop(fieldId);
  };

  const hasWritePermissions = usePermissions().hasPermission("fields", ["write"]);

  const ref = useRef();
  const [renderFully, setRenderFully] = useState();
  const isVisible = useViewportSpy(ref, { margin: "10px" });
  useEffect(() => {
    if (isVisible && !renderFully) {
      setRenderFully(true);
    }
  }, [isVisible]);

  const cropText = useMemo(() => {
    return _.map(fieldCrops, (crop) => crop.commodity.name)
      .sort()
      .join(", ");
  }, [fieldCrops, year]);

  const showLoadingState = !fieldCrops && loading;
  const showButton = !showLoadingState && !cropText;
  const showCropText = !!cropText;

  return (
    <span ref={ref}>
      {showLoadingState && "Loading crops\u2026"}
      {showCropText && cropText}
      {showButton && hasWritePermissions && (
        <Button color="primary" link onClick={handleClick} size="rg">
          Add crops
        </Button>
      )}
    </span>
  );
});

FieldCropButton.propTypes = {
  fieldId: PropTypes.number.isRequired,
};

export default FieldCropButton;
