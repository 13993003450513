import { css, StyleSheet } from "aphrodite/no-important";
import React from "react";
import { Col } from "react-styled-flexboxgrid";

const styles = StyleSheet.create({
  contentMain: {
    "@media only screen and (max-width: 57.142em)": {
      border: "none",
    },
    "@media print": {
      width: "100% !important",
    },
  },
});

const ContentMain = ({ children, fullWidth }) =>
  fullWidth ? (
    <Col xs={12} className={css(styles.contentMain)}>
      {children}
    </Col>
  ) : (
    <Col xs={12} md={9} lg={9} className={css(styles.contentMain)}>
      {children}
    </Col>
  );

export default ContentMain;
