import AdvisorSponsorImage from "marketing/components/AdvisorSponsorImage";
import React from "react";
import styled from "styled-components";

import { useFarmAdvisorConfig } from "collection/graphql/advisor";

import { ColorPalette, GreyColors } from "components/fl-ui/colors";
import { BorderRadius, Spacing } from "components/fl-ui/constants";

const UserSettingsCargillElevateBanner = styled(({ className }) => {
  const {
    data: { isEnabled, name, programName },
  } = useFarmAdvisorConfig();

  return !isEnabled ? null : (
    <div className={className}>
      <AdvisorSponsorImage />

      <p>
        While enrolled in the {programName} program, {name} administrators will have advisor level access to your Bushel
        Farm account including full access to Contracts and Marketing, and view only access to Fields and Market Prices.
      </p>
    </div>
  );
})`
  align-items: flex-start;
  background-color: ${ColorPalette["almost-white"]};
  border-radius: ${BorderRadius.large};
  display: flex;
  flex-direction: column;
  gap: ${Spacing.small};
  padding: ${Spacing.regular};

  > p {
    color: ${GreyColors.smoke40};
    margin-bottom: 0;
  }
`;

export default UserSettingsCargillElevateBanner;
