import getBarStyles from "marketing/components/ui-elements/progress-bar/getBarStyles";
import React from "react";

import { ColorPalette } from "components/fl-ui/colors";

const BaseProgressBar = ({ baseColor = ColorPalette["default-bar-background"], children, type }) => {
  const { height, borderRadius } = getBarStyles(type);
  return (
    <svg height={height} width="100%" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="99%" height="100%" rx={borderRadius} ry={borderRadius} fill={baseColor} />
      {children}
    </svg>
  );
};

export { BaseProgressBar };
