import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";

import { Button } from "components/fl-ui";
import { Input } from "components/fl-ui/Form";
import { CloseX } from "components/fl-ui/Icons";
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "components/fl-ui/Modal/Modal";
import { Typography } from "components/fl-ui/constants";

const AdminCopy = () => (
  <P>
    Your account and all associated user accounts will be permanently deleted. You will not be able to recover deleted
    accounts or any associated data. To save your data, you may keep your account without a paid subscription or you
    should export your data before proceeding.
  </P>
);

const DefaultCopy = () => (
  <P>
    Your account and any associated user data will be permanently deleted. You will not be able to recover deleted
    accounts or any associated data. To save your data, you may keep your account without a paid subscription or you
    should export your data before proceeding.
  </P>
);

const P = styled.p`
  font-size: ${({ small }) => (small ? Typography.sizes.mn : Typography.sizes.lg)};
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  line-height: 1.5em;
`;

const SubscriptionWarning = styled(({ className, platform }) => (
  <div className={className}>
    {platform.isWebPaymentProvider
      ? `Your subscription will be canceled as part of this process.`
      : `After completing user deletion, you will need to cancel your subscription through ${platform.storeName}.`}
  </div>
))`
  background: #cde8f6;
  border: 1px solid #0099e0;
  border-radius: 4px;
  margin-bottom: 15px;
  line-height: 1.5em;
  padding: 15px;
`;

const AccountDeactivationModal = ({ onClose, onConfirm, platform, role }) => {
  const [inputValue, setInputValue] = useState("");
  const isDisabled = inputValue !== "DELETE";
  const isAdmin = role === "admin";
  let confirmButtonCopy = "Delete account";
  if (isAdmin) {
    confirmButtonCopy = platform?.isWebPaymentProvider ? "Deactivate subscription and accounts" : "Delete all accounts";
  }

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal onClose={handleClose} width={625}>
      <ModalHeader>
        <ModalTitle>Are you sure you want to delete your account?</ModalTitle>
        <CloseX onClick={handleClose} />
      </ModalHeader>

      <ModalBody>
        {isAdmin && platform && <SubscriptionWarning platform={platform} />}
        {isAdmin ? <AdminCopy /> : <DefaultCopy />}

        <P bold>
          By clicking Delete Account you agree to permanent deletion of all data entered into the Bushel Farm system.
        </P>

        <P bold small>
          Please enter the word DELETE to confirm.
        </P>

        <Input
          name="deleteConfirmation"
          onChange={(event, { deleteConfirmation }) => setInputValue(deleteConfirmation)}
          style={{ width: "100%" }}
          type="text"
          value={inputValue}
        />
      </ModalBody>

      <ModalFooter>
        <Button onClick={handleClose}>Cancel</Button>{" "}
        <Button color="danger" disabled={isDisabled} onClick={onConfirm}>
          {confirmButtonCopy}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

AccountDeactivationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  platform: PropTypes.shape({
    isMobilePaymentProvider: PropTypes.bool.isRequired,
    isWebPaymentProvider: PropTypes.bool.isRequired,
    storeName: PropTypes.string.isRequired,
    storeUrl: PropTypes.string,
  }),
  role: PropTypes.oneOf(["admin", "manager", "contributor"]).isRequired,
};

export default AccountDeactivationModal;
