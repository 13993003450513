import { StyleSheet, css } from "aphrodite/no-important";
import classNames from "classnames";
import React from "react";

import { UIColors } from "components/fl-ui/colors";
import { Typography } from "components/fl-ui/constants";

const styles = StyleSheet.create({
  row: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "baseline",
    justifyContent: "space-between",
    marginBottom: 0,
  },
  column: {
    flexDirection: "column",
  },
  label: {
    fontWeight: Typography.weights.medium,
    fontSize: Typography.sizes.rg,
    lineHeight: 1.57,
    textAlign: "left",
    whiteSpace: "nowrap",
  },
  label_column: {
    width: "100%",
  },
  label_valueIsBold: {
    fontWeight: 400,
  },
  value: {
    textAlign: "right",
    padding: "2px 0",
    color: UIColors.light,
    whiteSpace: "nowrap",
  },
  value_column: {
    width: "100%",
  },
  valueIsBold: {
    fontWeight: 500,
    color: UIColors.regular,
  },
  green: {
    color: UIColors.green,
  },
  red: {
    color: UIColors.red,
  },
});

const DetailsItem = (props) => {
  const { label, value, children, className, valueBold, valueColor, column } = props;
  return (
    <div>
      {children ? (
        children(props)
      ) : (
        <p className={classNames(css(styles.row, column && styles.column), className)}>
          <span className={css(styles.label, column && styles.label_column, valueBold && styles.label_valueIsBold)}>
            {label}
          </span>
          <span
            className={css(
              styles.value,
              column && styles.value_column,
              valueBold && styles.valueIsBold,
              valueColor && styles[valueColor]
            )}
          >
            {value}
          </span>
        </p>
      )}
    </div>
  );
};

export default DetailsItem;
