import { groupBy, sortBy } from "lodash";

const sortByName = (items) => sortBy(items, ({ name }) => name.toLowerCase());

const organizeIntoGroups = (fields) => {
  const fieldsByGroup = groupBy(fields, "group.id");
  const groups = fields.reduce((allGroups, field) => {
    allGroups.add(field.group);

    return allGroups;
  }, new Set());

  groups.delete(null);

  const sortedGroups = sortByName([...groups]);
  const groupedFields = sortedGroups.reduce((groupedFields, group) => {
    if (group.id in fieldsByGroup) {
      groupedFields.push({
        group,
        fields: sortByName(fieldsByGroup[group.id]),
      });
    }

    return groupedFields;
  }, []);

  // append ungrouped fields
  if (fieldsByGroup[undefined]) {
    groupedFields.push({
      group: null,
      fields: sortByName(fieldsByGroup[undefined]),
    });
  }

  return groupedFields;
};

export default organizeIntoGroups;
