import { useQuery } from "@apollo/client";
import chooserStyles from "activity/components/choosers/FieldCropChooser/chooserStyles";
import _ from "lodash";
import { isNative } from "mobile/mobileManager";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import ReactSelect from "react-select";

import { getAllEquipment } from "collection/graphql/equipment/queries";

import { modalContext } from "components/fl-ui/Modal/Modal";

const useEquipmentData = () => {
  const allEquipment = _.sortBy(_.values(useQuery(getAllEquipment).data).flat(Infinity), "name");
  const { container } = useContext(modalContext);

  return {
    allEquipment,
    clean: (data) => _.pick(data, ["id", "name", "__typename"]),
    isSearchable: !isNative(),
    onFocus: ({ target }) => {
      if (isNative()) {
        container.scrollTop = target.getBoundingClientRect().y;
      }
    },
  };
};

const EquipmentChooser = ({ onChange, multiple, value }) => {
  const { allEquipment, isSearchable, onFocus } = useEquipmentData();
  return (
    <ReactSelect
      getOptionLabel={_.property("name")}
      getOptionValue={({ id }) => +id}
      isSearchable={isSearchable}
      onFocus={onFocus}
      options={allEquipment}
      onChange={onChange}
      styles={chooserStyles}
      value={value}
      isMulti={multiple}
    />
  );
};

EquipmentChooser.propTypes = {
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  value: PropTypes.any,
};

EquipmentChooser.defaultProps = {
  multiple: false,
};

export default EquipmentChooser;
