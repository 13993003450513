import { useQuery } from "@apollo/client";

import { restClient } from "collection/graphql/client";

/**
 * Use `useQuery` instead.
 * @deprecated
 */
const useRestQuery = (query, options = {}) => useQuery(query, { ...options, client: restClient });

export default useRestQuery;
