import { gql } from "@apollo/client";

import BasicNoteFragment from "collection/graphql/notes/fragments/BasicNote";

export default gql`
  query getNoteById($id: Int!) {
    note: getNoteById(id: $id) @rest(path: "/api/notes/{args.id}", type: "Note") {
      ...BasicNote
    }
  }

  ${BasicNoteFragment}
`;
