import { isEmpty } from "lodash";
import ItemizedProductionCost from "marketing/forms/MarketedCropAddEdit/ItemizedProductionCost";
import SingleProductionCost from "marketing/forms/MarketedCropAddEdit/SingleProductionCost";
import PropTypes from "prop-types";
import React from "react";

import { withProductionCostEnums } from "collection/graphql/marketing";

import { Button } from "components/fl-ui";
import { RadioGroup, Radio } from "components/fl-ui/Form";

const ProductionCosts = ({
  data: { productionCostUnits, productionCostCategory },
  error,
  itemizedProductionCosts,
  onChange,
  productionCost,
  productionCostUnit,
  useItemizedProductionCosts,
}) => {
  const getEmptyItem = () => ({ category: "SELECT", cost: 0, unit: "PER_ACRE" });
  const itemizedCosts = isEmpty(itemizedProductionCosts) ? [getEmptyItem()] : itemizedProductionCosts;
  const addNewItem = () => onChange("itemizedProductionCosts", [...itemizedCosts, getEmptyItem()]);
  const editItem = (item, idx) => {
    const items = [...itemizedCosts];
    items[idx] = item;
    onChange("itemizedProductionCosts", items);
  };

  const removeItem = (idx) => {
    const items = [...itemizedCosts];
    items.splice(idx, 1);
    onChange("itemizedProductionCosts", items);
  };

  return (
    <div>
      <RadioGroup
        label="Production costs"
        name="useItemizedProductionCosts"
        onChange={({ name, value }) => onChange(name, !value || value === "false" ? false : true)}
      >
        <Radio checked={useItemizedProductionCosts} label="Itemize costs" value />
        <Radio checked={!useItemizedProductionCosts} label="Enter a single cost" value={false} />
      </RadioGroup>

      {useItemizedProductionCosts ? (
        <>
          {itemizedCosts.map((item, i) => (
            <ItemizedProductionCost
              category={item.category}
              cost={item.cost}
              editItem={editItem}
              error={error}
              idx={i}
              key={i}
              productionCostCategory={productionCostCategory}
              productionCostUnits={productionCostUnits}
              removeItem={removeItem}
              unit={item.unit}
            />
          ))}

          <Button color="primary" hollow onClick={() => addNewItem()}>
            Add itemized cost
          </Button>
        </>
      ) : (
        <SingleProductionCost
          error={error}
          productionCost={productionCost || 0}
          productionCostUnit={productionCostUnit || "PER_ACRE"}
          productionCostUnits={productionCostUnits}
          onChange={onChange}
        />
      )}
    </div>
  );
};

ProductionCosts.propTypes = {
  data: PropTypes.object,
  error: PropTypes.object,
};

export default withProductionCostEnums(ProductionCosts);
