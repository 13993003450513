import _ from "lodash";
import moment from "moment";
import { Table } from "profit_and_loss/styles";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { Button } from "components/fl-ui";
import BlankSlate from "components/fl-ui/BlankSlate";

const MaintenanceRecordTable = styled(Table)`
  line-height: 1.2em;

  th,
  td {
    padding: 8px;
  }
  th,
  td:not(.notes) {
    white-space: nowrap;
  }
  tr > .notes {
    width: 100%;
  }
`;

const MaintenanceRecords = (props) => {
  const { onAddRecord, onEditRecord, records, showNameColumn, type } = props;
  const showBlankSlate = _.size(records) === 0;

  if (showBlankSlate) {
    return (
      <div>
        <BlankSlate>
          <p>
            {type === "implement" ? "No maintenance records for this implement." : ""}
            {type === "tractor" ? "No maintenance records for this tractor." : ""}
            {!type ? "No maintenance records" : ""}
          </p>

          <Button onClick={onAddRecord}>Log maintenance</Button>
        </BlankSlate>
      </div>
    );
  }

  return (
    <MaintenanceRecordTable>
      <thead>
        <tr>
          <th className="date">Date</th>
          {showNameColumn && <th className="name">Equipment</th>}
          <th className="people">Person</th>
          <th className="laborHours">Time Spent</th>
          <th className="notes">Notes</th>
          <th className="action"> </th>
        </tr>
      </thead>

      <tbody>
        {records.map((record) => {
          const { date, equipment, id, laborHours, notes, people } = record;
          const namesOfPeople = _.map(people, "name");

          return (
            <tr key={`record${id}`}>
              <td className="date">{moment(date).format("M/D/YYYY")}</td>
              {showNameColumn && <td className="name">{equipment.name}</td>}
              <td className="people">{namesOfPeople.join(", ")}</td>
              <td className="laborHours">{laborHours > 0 ? `${laborHours} hours` : ""}</td>
              <td className="notes">{notes}</td>
              <td className="action">
                <Button color="primary" link onClick={() => onEditRecord(record)}>
                  Edit
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </MaintenanceRecordTable>
  );
};

MaintenanceRecords.propTypes = {
  onAddRecord: PropTypes.func.isRequired,
  onEditRecord: PropTypes.func.isRequired,
  records: PropTypes.array.isRequired,
  type: PropTypes.oneOf(["implement", "tractor"]),
};

export default MaintenanceRecords;
