import EditableBaseContract from "./EditableBaseContract";
import { gql } from "@apollo/client";

/**
 * Represents the fields necessary to edit as OptionsContract.
 * @type {DocumentNode}
 */
export default gql`
  fragment EditableOptionsContract on OptionsContract {
    ...EditableBaseContract
    contractSubtypeOptions: contractType
    currentMarketPremium
    expirationDate
    premium
    quantity
    revenueImpact
    strikePrice
  }

  ${EditableBaseContract}
`;
