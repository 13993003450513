import HeatSummary from "./HeatSummary";
import RainfallSummary from "./RainfallSummary";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const CropStatusSummary = styled((props) => {
  const { className, cropYear, fieldId } = props;

  return (
    <div className={className}>
      <div className="row-fluid">
        <div className="col md-6 xs-12">
          <RainfallSummary cropYear={cropYear} fieldId={fieldId} />
        </div>

        <div className="col md-6 xs-12">
          <HeatSummary cropYear={cropYear} fieldId={fieldId} />
        </div>
      </div>
    </div>
  );
})`
  a:hover {
    text-decoration: none;
  }
`;

CropStatusSummary.propTypes = {
  cropYear: PropTypes.number.isRequired,
  fieldId: PropTypes.number.isRequired,
};

export default CropStatusSummary;
