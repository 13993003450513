import PropTypes from "prop-types";
import React from "react";
import _ from "underscore";

import { Button } from "components/fl-ui";

const InputGroup = ({ addUrl, children, description, onAdd, title }) => {
  const addText = `+ Add ${_.titleize(_.singularize(title))}`;

  return (
    <div className="settings-input-group">
      <h2 className="heading-underline">{title}</h2>
      <p>{description}</p>
      <div className="settings-item-wrapper">{children || null}</div>

      {addUrl && (
        <a className="btn btn-white" href={addUrl}>
          {addText}
        </a>
      )}

      {onAdd && (
        <Button className="btn btn-white" color="secondary" onClick={onAdd}>
          {addText}
        </Button>
      )}
    </div>
  );
};

InputGroup.propTypes = {
  addUrl: PropTypes.string,
  children: PropTypes.node,
  description: PropTypes.string,
  onAdd: PropTypes.func,
  title: PropTypes.string.isRequired,
};

InputGroup.defaultProps = {
  addUrl: null,
  description: "",
};

export default InputGroup;
