import { UNKNOWN } from "lib/constants";

/*
 * Per CORE-4013, all archived activity types should be filtered out unless
 * the activity currently being edited has an archived type.
 * UNKNOWN category cannot be selected
 */
const getCategoryOptions = (activityTypes, activityTypeId) =>
  activityTypes?.filter(
    ({ category, id, isArchived }) => category !== UNKNOWN && (!isArchived || id === activityTypeId)
  );

export default getCategoryOptions;
