import { useQuery } from "@apollo/client";
import _ from "lodash";
import LocalPricesFilterBar from "prices/components/LocalPricesFilterBar";
import AreaBids from "prices/containers/AreaBids";
import BuyerDetails from "prices/containers/BuyerDetails";
import useLocalPricesFilters from "prices/containers/hooks/useLocalPricesFilters";
import React, { useMemo } from "react";

import { GET_BIDS_BY_COUNTY } from "collection/graphql/prices";

import LoadingWrapper from "components/fl-ui/LoadingWrapper";

const NewLocalPrices = ({ buyerId }) => {
  const { loaded: filtersLoaded, ...filters } = useLocalPricesFilters();
  const { data, loading } = useQuery(GET_BIDS_BY_COUNTY, {
    skip: !!buyerId || !filtersLoaded,
    pollInterval: 5 * 1000 * 60, // every 5 minutes
    variables: {
      bidType: filters.bidType,
      commodity: filters.commodity?.id,
      counties: _.map(filters.counties, "fips"),
    },
  });
  const bids = data?.bidsByCounty;
  const totalBuyers = useMemo(() => _.uniqBy(bids, "buyer.id").length, [bids]);

  return buyerId ? (
    <BuyerDetails id={buyerId} />
  ) : (
    <LoadingWrapper isLoading={!filtersLoaded}>
      <div>
        <LocalPricesFilterBar filters={filters} totalBuyers={totalBuyers} />
        <LoadingWrapper isLoading={loading}>
          <AreaBids bids={bids} filters={filters} />
        </LoadingWrapper>
      </div>
    </LoadingWrapper>
  );
};

export default NewLocalPrices;
