import { SectionHeader } from "activity/common/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import Cluster from "components/fl-ui/Layout/Cluster";
import { UIColors } from "components/fl-ui/colors";
import { Spacing, Typography } from "components/fl-ui/constants";

const CHEMICAL = "CHEMICAL";

const Info = styled.div`
  dl {
    font-size: ${Typography.sizes.mn};

    dt {
      color: ${UIColors.light};
      text-transform: uppercase;
    }
    dd {
      font-weight: ${Typography.weights.regular};
    }

    > * + * {
      margin-top: ${Spacing.regular};
    }
  }
`;

const Notes = styled.div`
  ${({ isPopulated }) =>
    isPopulated &&
    `
    dd {
      line-height: 1.5em;
      margin-left: 5px;
      margin-top: 5px;
    }
  `}

  ${({ isPopulated }) =>
    !isPopulated &&
    `
    display: flex;
    justify-content: space-between;
  `}
`;

const ProductInfo = (props) => {
  const { brand, chemicalType, epaNumber, notes, restrictedUse, type } = props.product;

  const aspects = [
    { label: "Type", value: _.capitalize(type) },
    { label: "Brand", value: brand },
    { label: "Restricted Use", value: restrictedUse ? "Yes" : "No" },
  ];
  if (type === CHEMICAL) {
    aspects.push(
      { label: "Chemical Type", value: _.capitalize(chemicalType) },
      { label: "EPA Number", value: epaNumber }
    );
  }

  return (
    <Info>
      <SectionHeader label="Product Information" borderless />
      <dl>
        {aspects.map(({ label, value }, i) => (
          <Cluster key={i} between>
            <dt>{label}</dt>
            <dd>{value ?? "--"}</dd>
          </Cluster>
        ))}

        <Notes isPopulated={!!notes}>
          <dt>Notes</dt>
          <dd>{notes ?? "--"}</dd>
        </Notes>
      </dl>
    </Info>
  );
};

ProductInfo.propTypes = {
  product: PropTypes.shape({
    brand: PropTypes.string,
    chemicalType: PropTypes.string,
    epaNumber: PropTypes.string,
    notes: PropTypes.string,
    restrictedUse: PropTypes.any,
    type: PropTypes.string.isRequired,
  }),
};

export default ProductInfo;
