import { useQuery } from "@apollo/client";
import { StyleSheet, css } from "aphrodite/no-important";
import _ from "lodash";
import React, { useMemo, useState } from "react";

import { GET_COMMODITIES } from "collection/graphql/commodities/queries";
import ConfigSelectionModal from "modules/prices/components/ConfigSelectionModal";

import { Select, FormGroup } from "components/fl-ui/Form";
import Icon from "components/fl-ui/Icons/Icon";
import { UIColors } from "components/fl-ui/colors";
import { Spacing, Mixins, Borders } from "components/fl-ui/constants";

const styles = StyleSheet.create({
  container: {
    justifyContent: "space-between",
    flexWrap: "wrap",
    borderBottom: Borders.regular,
    paddingBottom: Spacing.regular,
    marginBottom: Spacing.regular,
  },
  flexRow: {
    display: "flex",
    alignItems: "center",
  },
  left: {
    justifyContent: "flex-start",
  },
  right: {
    justifyContent: "flex-end",
  },
  icon: {
    width: Spacing.large,
    height: Spacing.large,
  },
  link: {
    color: UIColors.primary,
    fontWeight: 500,
    textDecoration: "none",
  },
  marginRight: {
    marginRight: `${Spacing.xsmall} !important`,
  },
  noMargin: {
    margin: 0,
  },
  zipInput: {
    width: Mixins.toRem(100),
  },
});

const LocalPricesFilterBar = ({ filters, totalBuyers }) => {
  const [showConfigModal, setShowConfigModal] = useState(false);
  const { commodity, counties, updateCommodity } = filters;
  const usedCommodities = _.filter(useQuery(GET_COMMODITIES).data?.commodities, { isExchangeTraded: true });
  const commodityOptions = useMemo(
    () =>
      usedCommodities.map(({ id, name }) => ({
        key: id,
        label: name,
        value: id,
      })),
    []
  );

  const handleCommodityChange = (e, { commodity }) => {
    updateCommodity(_.find(usedCommodities, { id: +commodity }));
  };

  return (
    <div className={css(styles.flexRow, styles.container)}>
      <div className={css(styles.flexRow, styles.left)}>
        <Icon icon="mapPin" className={css(styles.icon, styles.marginRight)} />
        <p className={css(styles.noMargin, styles.marginRight)}>
          {`Showing data for `}
          <b>{`${totalBuyers} ${totalBuyers === 1 ? "buyer" : "buyers"}`}</b>
          {` across `}
          <b>{`${counties.length} ${counties.length > 1 ? "counties" : "county"} `}</b>(
          <a className={css(styles.link)} onClick={() => setShowConfigModal((value) => !value)}>
            Edit
          </a>
          )
        </p>
      </div>
      <div className={css(styles.flexRow, styles.right)}>
        <FormGroup horizontal label="Commodity" inputId="commodity">
          <Select
            id="commodity"
            name="commodity"
            options={commodityOptions}
            onChange={handleCommodityChange}
            value={commodity?.id}
          />
        </FormGroup>
      </div>

      {showConfigModal && <ConfigSelectionModal filters={filters} onClose={() => setShowConfigModal(false)} />}
    </div>
  );
};

export default LocalPricesFilterBar;
