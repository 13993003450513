import { last } from "lodash";

const appendToHistory = Symbol("appendToHistory");
const history = Symbol("history");
const listeners = Symbol("listeners");
const name = Symbol("name");

const channels = {};

export default class EventChannel {
  /**
   * @returns {EventChannel[]}
   */
  static getAllChannels() {
    return Object.values(channels);
  }

  /**
   * @param {String} channelName
   * @returns {EventChannel}
   */
  static getChannel(channelName) {
    channels[channelName] = channels[channelName] || new EventChannel(channelName);
    return channels[channelName];
  }

  /**
   * @param {String} channelName
   */
  constructor(channelName) {
    this[name] = channelName;
    this[listeners] = {};

    this[history] = {};
    /**
     * Records the firing of an event and its associated payload.
     * @private
     * @param {String} eventName
     * @param {Object} [payload]
     */
    this[appendToHistory] = (eventName, payload) => {
      if (eventName in this[history]) {
        this[history][eventName].push(payload);
      } else {
        this[history][eventName] = [payload];
      }
    };
  }

  /**
   * @property {String}
   */
  get name() {
    return this[name];
  }

  /**
   * Listens for an event by name.
   * @param {String} eventName
   * @param {Function} callback
   * @returns {Function} a function which unsubscribes the listener
   */
  on(eventName, callback) {
    this[listeners][eventName] = this[listeners][eventName] || new Set();
    this[listeners][eventName].add(callback);

    return () => this.off(eventName, callback);
  }

  /**
   * Listens for an event by name one time and then immediately unsubscribes.
   * @param {String} eventName
   * @param {Function} callback
   * @returns {Function} a function which unsubscribes the listener
   */
  once(eventName, callback) {
    const unsubscribeCallback = this.on(eventName, callback);
    const unsubscribeOnceListener = this.on(eventName, () => unsubscribeCallback());

    return () => {
      unsubscribeCallback();
      unsubscribeOnceListener();
    };
  }

  /**
   * Removes a listener callback from a given event by name.
   * @param {String} eventName
   * @param {Function} callback
   */
  off(eventName, callback) {
    this[listeners][eventName]?.delete(callback);
  }

  /**
   * Removes all listeners and history in the channel.
   */
  clear() {
    this[history] = {};
    this[listeners] = {};
  }

  /**
   * Fires an event with optional payload asynchronously. If any of the callbacks
   * return a promise, the results will be collected and returned once settled.
   * @param {String} eventName
   * @param {Object} [payload]
   * @returns {Promise<PromiseSettledResult>}
   */
  async fire(eventName, payload = undefined) {
    if (this[listeners][eventName]) {
      const callbackArgs = [payload].filter((x) => x);
      const results = Array.from(this[listeners][eventName]).map((callback) => {
        const asyncCallback = async () => callback(...callbackArgs);
        return asyncCallback();
      });

      this[appendToHistory](eventName, payload);
      return Promise.allSettled(results);
    }

    this[appendToHistory](eventName, payload);
  }

  /**
   * Executes a callback after a particular event has been fired at least once. Passes
   * to the callback the payload of the last completed event.
   * @param {String} eventName
   * @param {Function} callback
   */
  after(eventName, callback) {
    if (eventName in this[history]) {
      callback(last(this[history][eventName]));
    } else {
      this.once(eventName, callback);
    }
  }
}
