import React from "react";

import MarketingBlankSlateBanner from "components/advertisements/banners/MarketingBlankSlateBanner";
import PayGateCommonBlankStatePage from "components/advertisements/pages/PayGateCommonBlankStatePage";

const MarketingBlankStatePage = () => {
  const steps = [
    "Calculate breakeven for your farm",
    "Track sales and hedges as you make them",
    "Monitor profitability and position",
  ];

  return (
    <div>
      <PayGateCommonBlankStatePage banner={MarketingBlankSlateBanner} steps={steps} />
    </div>
  );
};

export default MarketingBlankStatePage;
