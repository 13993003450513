import { css } from "aphrodite";
import styles from "map/layers/styles";
import React from "react";

import Icon from "components/fl-ui/Icons/Icon";

const LockIcon = () => (
  <div className={css(styles.lockIconStyle)}>
    <img src="/images/map/thumb-lock-12x14.svg" height="16" />
  </div>
);

const TypeOption = (props) => {
  const { description, disabled, icon, onClick, selected, text, value } = props;

  return (
    <div
      className={css(styles.optionsContainer, selected === value && styles.selected)}
      data-value={value}
      onClick={onClick}
    >
      <div className={css(styles.optionStyle)} key={value}>
        <div className={css(disabled && styles.iconContainer)}>
          {icon && <Icon className={css(styles.iconStyle)} icon={icon} />}
          {disabled && icon && <LockIcon />}
        </div>
        <span style={{ paddingLeft: ".5em" }}>
          <div className={css(styles.optionText, disabled && styles.disabled)}>{text}</div>
          {!disabled && <div className="font-size-s">{description}</div>}
        </span>
      </div>
      {selected === value && <Icon className={css(styles.iconStyle, styles.iconTickStyle)} icon="check" />}
    </div>
  );
};

export default TypeOption;
