import { css } from "aphrodite/no-important";
import { isEmpty } from "lodash";
import RedGreenText from "marketing/components/format/RedGreenText";
import Cell, { CellRow } from "marketing/components/ui-elements/Cell";
import moment from "moment";
import styles from "prices/charts/styles";
import PropTypes from "prop-types";
import React from "react";

import dollarFormat from "lib/utils/dollarFormat";

const TooltipContent = (props) => {
  const { close, contentType, high, low, netChange, open, priceTimestamp } = props;

  return !priceTimestamp ? null : (
    <div className={css(styles.tooltipContainer)}>
      <h5 className={css(styles.tooltipTitle)}>{moment(priceTimestamp).format("MMMM DD, YYYY")}</h5>
      <hr />

      <CellRow>
        <Cell className={css(styles.tooltipLastPrice)}>
          <div className={css(styles.tooltipLabel)}>{contentType === "candlestick" ? "Last price" : "Close"}</div>
          <div className={css(styles.tooltipValue)}>{dollarFormat(close)}</div>
        </Cell>

        <Cell right>
          <div className={css(styles.tooltipLabel)}>Change</div>
          <div className={css(styles.tooltipValue)}>
            <RedGreenText value={netChange}>{dollarFormat(netChange)}</RedGreenText>
          </div>
        </Cell>
      </CellRow>

      {high && (
        <CellRow>
          <Cell className={css(styles.tooltipLastPrice)}>
            <div className={css(styles.tooltipLabel)}>Open</div>
            <div className={css(styles.tooltipValue)}>{dollarFormat(open)}</div>
          </Cell>

          <Cell right>
            <div className={css(styles.tooltipLabel)}>High</div>
            <div className={css(styles.tooltipValue)}>{dollarFormat(high)}</div>
          </Cell>
        </CellRow>
      )}

      {low && (
        <CellRow>
          <Cell className={css(styles.tooltipLastPrice)}>
            <div className={css(styles.tooltipLabel)}>Close</div>
            <div className={css(styles.tooltipValue)}>{dollarFormat(close)}</div>
          </Cell>

          <Cell right>
            <div className={css(styles.tooltipLabel)}>Low</div>
            <div className={css(styles.tooltipValue)}>{dollarFormat(low)}</div>
          </Cell>
        </CellRow>
      )}
    </div>
  );
};

const FuturesContractHistoryTooltipContent = ({ contentType, ...props }) => {
  const { payload } = isEmpty(props.payload) ? {} : props.payload[0];

  if (contentType === "line") {
    const { close, netChange, priceTimestamp } = payload || {};

    return <TooltipContent close={close} netChange={netChange} priceTimestamp={priceTimestamp} />;
  }

  if (contentType === "candlestick" && payload) {
    const { lowHigh, netChange, openClose, priceTimestamp } = payload;

    return (
      <TooltipContent
        close={openClose[1]}
        contentType={contentType}
        high={lowHigh[1]}
        low={lowHigh[0]}
        netChange={netChange}
        open={openClose[0]}
        priceTimestamp={priceTimestamp}
      />
    );
  }

  return null;
};

FuturesContractHistoryTooltipContent.propTypes = {
  contentType: PropTypes.string.isRequired,
  payload: PropTypes.array,
};

export default FuturesContractHistoryTooltipContent;
