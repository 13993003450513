import Big from "big.js";
import _ from "lodash";
import numeral from "numeral";
import PropTypes from "prop-types";
import React, { Component } from "react";

const calculateAcreage = (fields, commodityId = null, cropYear = null) => {
  //if no commodityId, sum the total acreage of fields
  if (!fields) {
    return 0;
  }
  // Adding in year check is a temp fix until https://bushel.atlassian.net/browse/FARM-9160 is completed
  // Once FARM-9160 is completed, year check can be removed
  // if no commodity or year, just sum the acreage
  if (!commodityId || !cropYear) {
    return fields.reduce((total, { acreage }) => new Big(total).add(+acreage).toNumber(0), 0);
  }
  // If a commodityId is passed in
  // generate an array of all fields crops & sum the acreage
  let crops = _.flatMap(fields, "crops");
  crops = crops.filter((crop) => crop.commodity.id === commodityId && crop.cropYear === cropYear);
  return crops.reduce((total, { acreage }) => new Big(total).add(+acreage).toNumber(), 0);
};

class FieldInfo extends Component {
  static propTypes = {
    commodity: PropTypes.object,
    cropYear: PropTypes.number,
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        acreage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      })
    ),
    // TODO the comma style could be used in multiple spots on the fields page
    delimiter: PropTypes.oneOf(["comma", "dot"]),
  };

  static defaultProps = {
    delimiter: "dot",
  };

  render() {
    const { commodity, cropYear, delimiter, fields, ...props } = this.props;
    const separator = delimiter === "dot" ? "  \u00b7 " : ", ";
    const count = fields ? fields.length : 0;
    const acres = numeral(calculateAcreage(fields)).format("0.0");
    const text = [
      count === 1 ? `${count} field` : `${count} fields`,
      acres === 1 ? `${acres} acre total` : `${acres} acres total`,
    ];

    if (commodity) {
      const cropAcres = numeral(calculateAcreage(fields, commodity?.id, cropYear)).format("0.0");
      text.splice(1, 0, `${cropAcres} ${cropAcres === 1 ? "acre" : "acres"} ${commodity?.name}`);
    }

    return fields && fields.length > 0 ? <span {...props}> {text.join(separator)}</span> : null;
  }
}

export { calculateAcreage };
export default FieldInfo;
