import React from "react";
import ReactToggle from "react-toggle";

const Toggle = (props) => {
  return (
    <span onClick={(e) => e.stopPropagation()}>
      <ReactToggle {...props} />
    </span>
  );
};

export default Toggle;
