import { toOption, CapsHeader } from "../Helpers";
import PropTypes from "prop-types";
import React, { useState } from "react";

import useCommodities from "hooks/useCommodities";

import Chooser from "components/fl-ui/Chooser";
import { CropColors } from "components/fl-ui/colors";

export const cropChooserStyles = {
  multiValue: (base, { data }) => ({
    ...base,
    ...CropColors.styles[data.color],
  }),
  multiValueLabel: (base, { data }) => ({
    ...base,
    ...CropColors.styles[data.color],
  }),
  multiValueRemove: (base, { data }) => ({
    ...base,
    ...CropColors.styles[data.color],
  }),
};

/**
 * Commodity filter parameter, allows one or more commodities to be selected.
 */

const CommodityFilter = ({ onParamChanged, param }) => {
  const { commodities } = useCommodities();
  const [selection, setSelection] = useState([]);
  const handleCommoditiesChange = (commodities) => {
    const commodityIds = commodities?.map(({ value }) => value).join() ?? "";
    setSelection(commodities);
    onParamChanged({ id: param.id, value: commodityIds });
  };

  return (
    <div key={param.id}>
      <CapsHeader>{param.title}</CapsHeader>
      <Chooser
        hideIndicator
        id={`report-${param.id}`}
        isClearable={false}
        isMulti
        name={param.id}
        onChange={handleCommoditiesChange}
        options={commodities.map(toOption)}
        styles={cropChooserStyles}
        value={selection}
      />
    </div>
  );
};

CommodityFilter.propTypes = {
  param: PropTypes.object.isRequired,
  onParamChanged: PropTypes.func.isRequired,
};

export default CommodityFilter;
