import SentryInfoError from "lib/errors/SentryInfoError";

/**
 * An error thrown during the subscriptions save workflow.
 */
class SubscriptionSaveError extends SentryInfoError {
  name = "SubscriptionSaveError";
}

export default SubscriptionSaveError;
