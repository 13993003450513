import { gql } from "@apollo/client";

import BasicNoteFragment from "collection/graphql/notes/fragments/BasicNote";

export default gql`
  mutation createNote($input: CreateNoteInput!) {
    note: createNote(input: $input) @rest(type: "Note", path: "/api/notes", method: "POST") {
      ...BasicNote
    }
  }

  ${BasicNoteFragment}
`;
