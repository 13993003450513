import { StyleSheet } from "aphrodite/no-important";

import styleVars from "components/fl-ui/Layout/Navigation/styleVars";
import { UIColors } from "components/fl-ui/colors";
import { BorderRadius, Mixins, Spacing, Typography } from "components/fl-ui/constants";

const ICON_SIZE = Mixins.toRem(40);
const ICON_SIZE_MOBILE = Mixins.toRem(34);

const styles = StyleSheet.create({
  greenText: {
    color: styleVars.brandLogo_color,
  },
  redText: {
    color: UIColors.danger,
  },
  navToggle: {
    width: ICON_SIZE,
    height: ICON_SIZE,
    minWidth: ICON_SIZE,
    minHeight: ICON_SIZE,
    backgroundColor: "transparent",
    cursor: "pointer",
    "@media only screen and (max-width: 62.5em)": {
      width: ICON_SIZE_MOBILE,
      height: ICON_SIZE_MOBILE,
      minWidth: ICON_SIZE_MOBILE,
      minHeight: ICON_SIZE_MOBILE,
    },
  },
  userIcon: {
    overflow: "hidden",
    width: "100%",
    height: "100%",
    minWidth: "100%",
    minHeight: "100%",
    borderRadius: "100%",
    backgroundColor: styleVars.header_color,
    fontSize: Typography.sizes.sm,
    fontWeight: Typography.weights.medium,
    lineHeight: 3,
    color: styleVars.header_backgroundColor,
    textAlign: "center",
    border: `3px solid ${styleVars.header_color}`,
    textTransform: "uppercase",
  },
  userID: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    width: "100%",
    height: "auto",
    padding: `${Spacing.regular} 0`,
  },
  userCopy: {
    marginLeft: Spacing.regular,
    flexGrow: 2,
  },
  userName: {
    fontSize: Typography.sizes.rg,
    fontWeight: Typography.weights.medium,
    lineHeight: 1.375,
    textDecoration: "none",
    margin: 0,
  },
  userEmail: {
    fontSize: Typography.sizes.rg,
    fontWeight: Typography.weights.regular,
    lineHeight: 1.357,
    color: Typography.colors.secondary,
    margin: 0,
  },

  menuContainer: {
    minWidth: Mixins.toRem(320),
    "@media only screen and (max-width: 62.5em)": {
      borderRadius: BorderRadius.medium,
    },
    ":before": {
      display: "none",
    },
    ":after": {
      display: "none",
    },
  },
  menuHeader: {
    alignItems: "baseline",
    borderBottom: `1px solid ${styleVars.userMenu_dividerColor}`,
    display: "flex",
    padding: `0 ${Spacing.regular}`,
  },
  menuSection: {
    borderBottom: `1px solid ${styleVars.userMenu_dividerColor}`,
    flexGrow: 2,
    padding: `${Spacing.regular} ${Spacing.large}`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  menuLink: {
    display: "block",
    width: "100%",
    color: styleVars.userMenu_linkColor,
    fontWeight: Typography.weights.regular,
    fontSize: Typography.sizes.rg,
    lineHeight: 1.357,
    marginBottom: Spacing.minor,
  },
  menuFooter: {
    fontSize: Typography.sizes.rg,
    lineHeight: 1.5,
    textAlign: "center",
  },
  noMargin: {
    margin: 0,
  },
  promo: {
    fontSize: Typography.sizes.lg,
    lineHeight: 1.5,
    width: "100%",
    height: "100%",
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
  },
  promoText: {
    fontSize: Typography.sizes.rg,
    fontWeight: Typography.weights.medium,
    color: Typography.colors.primary,
    marginBottom: 0,
    lineHeight: 1.5,
  },
  signOutButton: {
    margin: `${Spacing.xsmall} 0 ${Spacing.large}`,
  },
  lineBreak: {
    display: "block",
    fontWeight: Typography.weights.regular,
    color: Typography.colors.secondary,
  },
});

export default styles;
