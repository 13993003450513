import { css } from "aphrodite/no-important";
import _ from "lodash";
import moment from "moment";
import FuturesContractHistoryChart from "prices/charts/FuturesContractHistoryChart";
import styles from "prices/charts/styles";
import CommodityCarousel from "prices/components/CommodityCarousel";
import CommodityContractSummary from "prices/components/CommodityContractSummary";
import ContractsByCommodity from "prices/components/ContractsByCommodity";
import React, { useEffect, useState } from "react";

import { withContractHistory } from "collection/graphql/marketing";
import useCommodityQuotes from "collection/graphql/prices/hooks/useCommodityQuotes";
import { CORN } from "lib/constants";
import { MARKETING_COMMODITY_PRICES_VIEW } from "lib/metrics/events";

import LoadingWrapper from "components/fl-ui/LoadingWrapper";

const Header = ({ onCommodityChange, commodityId, ...props }) => {
  const { commodityQuotes } = useCommodityQuotes(props).data ?? {};
  return _.size(commodityQuotes) === 0 ? null : (
    <CommodityCarousel
      selectedCommodityId={commodityId}
      commodityQuotes={commodityQuotes}
      onCommodityChange={onCommodityChange}
    />
  );
};

const Contracts = ({ commodityId, commodityQuotes }) => {
  if (commodityQuotes) {
    const { commodity, contracts } = _.find(commodityQuotes, (quote) => quote.commodity.id === commodityId);
    return <ContractsByCommodity commodity={commodity} contracts={contracts} />;
  }

  return null;
};

const CurrentPrice = ({ commodityId, commodityQuotes, lastUpdated, ...props }) => {
  if (!commodityQuotes) {
    return null;
  }

  const { commodity, priceSnapshot } = _.find(commodityQuotes, (quote) => quote.commodity.id === commodityId);

  return (
    <div>
      <CommodityContractSummary commodityName={commodity.name} priceSnapshot={priceSnapshot} />
      <p className={css(styles.movingAverage_timestamp)}>
        {lastUpdated ? `Last updated at ${moment(lastUpdated).format("h:mma")}` : " "}
      </p>
    </div>
  );
};

const Chart = withContractHistory(({ data: { contract, loading } }) => {
  return (
    <LoadingWrapper isLoading={loading}>
      {contract && <FuturesContractHistoryChart history={contract.continuousHistory} />}
    </LoadingWrapper>
  );
});

/* <FuturesPricesContainer /> (default export) */
const FuturesPricesContainer = (props) => {
  const [selectedCommodityId, setSelectedCommodityId] = useState(CORN);
  const onCommodityChange = ({ id }) => setSelectedCommodityId(id);
  const quoteData = useCommodityQuotes(_.pick(props, ["endDate", "startDate"]));

  useEffect(() => {
    MARKETING_COMMODITY_PRICES_VIEW.track();
  }, []);

  return (
    <div>
      <Header commodityId={selectedCommodityId} onCommodityChange={onCommodityChange} skipContracts />
      <CurrentPrice
        commodityId={selectedCommodityId}
        commodityQuotes={quoteData.data?.commodityQuotes}
        lastUpdated={quoteData.lastUpdated}
      />
      <Chart commodityId={selectedCommodityId} />
      <Contracts commodityId={selectedCommodityId} commodityQuotes={quoteData.data?.commodityQuotes} />
    </div>
  );
};

export default FuturesPricesContainer;
