import _ from "lodash";
import moment from "moment";

import utils from "lib/validation/utils";
import * as constants from "modules/agreements/constants";

const formatText = (text) => {
  if (text.includes("_")) {
    return _.upperFirst(_.lowerCase(text));
  }

  return _.upperFirst(text);
};

export const getFilteredAgreements = (agreementList, selectedFilters) => {
  const matchesField = ({ fields }) =>
    _.isEmpty(selectedFilters.fields) ||
    fields.some((field) => selectedFilters.fields.some((filter) => filter.id === field.id));

  const matchesMaturityDate = ({ maturityDate }) =>
    !selectedFilters.maturityDate || moment(selectedFilters.maturityDate).isSameOrAfter(moment(maturityDate), "day");

  const matchesStartDate = ({ startDate }) =>
    !selectedFilters.startDate || moment(selectedFilters.startDate).isSameOrBefore(moment(startDate), "day");

  const matchesTerms = ({ agreementType, terms }) => {
    if (_.isEmpty(selectedFilters.terms) || agreementType !== constants.LEASE) {
      return true;
    }

    return terms.some(({ termType }) => selectedFilters.terms.some((term) => term === termType));
  };

  const matchesType = ({ agreementType }) => {
    return _.isEmpty(selectedFilters.type) || selectedFilters.type.some((type) => type === agreementType);
  };

  return agreementList.filter(
    (agreement) =>
      matchesField(agreement) &&
      matchesMaturityDate(agreement) &&
      matchesStartDate(agreement) &&
      matchesType(agreement) &&
      matchesTerms(agreement)
  );
};

export const getFormattedList = (list, attribute) => {
  const listLength = _.size(list);

  if (listLength === 0) {
    return "--";
  }

  if (listLength < 3) {
    return list.map((field) => formatText(field[attribute])).join(", ");
  }

  return `${formatText(list[0][attribute])}, + ${listLength - 1} more`;
};

export const getSearchAgreements = (getAgreements, search) => {
  search = _.lowerCase(search);
  const matchesField = ({ fields }) => fields?.some((field) => _.lowerCase(field.name).includes(search));
  const matchesId = ({ id }) => id && _.lowerCase(`${id}`).includes(search);
  const matchesIssuer = ({ agreementType, lenderName, landlord, revenueSource }) => {
    switch (agreementType) {
      case constants.LEASE:
        const { firstName, lastName, organization } = landlord;
        return _.lowerCase(organization).includes(search) || _.lowerCase(`${firstName} ${lastName}`).includes(search);
      case constants.LOAN:
        return _.lowerCase(lenderName).includes(search);
      case constants.REVENUE:
        return _.lowerCase(revenueSource).includes(search);
      default:
        return false;
    }
  };

  const matchesSubtype = ({ agreementType, subType, terms }) => {
    if (!subType) {
      return agreementType === constants.LEASE && terms.some((term) => _.lowerCase(term.termType).includes(search));
    }
    return _.lowerCase(subType).includes(search);
  };

  const matchesDateRange = ({ maturityDate, startDate }) =>
    _.lowerCase(`${moment(startDate).format("MM/DD/YYYY")} - ${moment(maturityDate).format("MM/DD/YYYY")}`).includes(
      search
    );

  return getAgreements().filter(
    (agreement) =>
      matchesId(agreement) ||
      matchesSubtype(agreement) ||
      matchesDateRange(agreement) ||
      matchesField(agreement) ||
      matchesIssuer(agreement)
  );
};

export const getSortedAgreements = (agreements, sort) => {
  const data = { agreements: _.sortBy(agreements, [sort, "id"]), groupedAgreements: null, sort };

  if (sort === "agreementType") {
    data.groupedAgreements = _.groupBy(agreements, ({ agreementType }) => agreementType.toLowerCase());
  }

  return data;
};

export const isPlural = (value) => !!value && value.length !== 1;

export const prepareAgreementForSave = (agreement, type) => {
  let sanitizedAgreement;

  switch (type) {
    case "LEASE":
      sanitizedAgreement = _.pick(agreement, ["attachments", "endYear", "id", "notes", "startYear"]);

      sanitizedAgreement = {
        ...sanitizedAgreement,
        ..._.pick(agreement.landlord, [
          "addressLine1",
          "addressLine2",
          "city",
          "email",
          "firstName",
          "lastName",
          "organization",
          "phone",
          "postalCode",
          "state",
        ]),
      };

      sanitizedAgreement.terms = _.map(agreement.terms, (term) => {
        switch (term.termType) {
          case constants.BASE_RENT:
            return _.pick(term, ["id", "termType", "amount", "baseRentRate"]);

          case constants.CROP_SHARE:
            return _.pick(term, ["id", "termType", "tenantRevenueAllocation"]);

          case constants.EXPENSE_SHARE:
            return _.pick(term, ["id", "termType", "tenantCostAllocation", "expenseCategory"]);

          case constants.YIELD_ADJUSTMENT:
            return {
              ..._.pick(term, ["id", "termType", "amount", "yieldTrigger"]),
              commodity: term.commodity.id,
            };
        }
      });
      break;

    case "LOAN":
      sanitizedAgreement = _.pick(agreement, [
        "attachments",
        "id",
        "interestRate",
        "lenderName",
        "loanType",
        "monthlyPayment",
        "notes",
        "originalBalance",
        "startDate",
        "termLength",
        "termUnit",
      ]);
      break;

    case "REVENUE":
      sanitizedAgreement = _.pick(agreement, [
        "attachments",
        "id",
        "notes",
        "revenueSource",
        "startDate",
        "termLength",
        "termUnit",
      ]);
      break;

    default:
      throw new Error(`Unrecognized agreement type ${type}`);
  }

  sanitizedAgreement.fields = _.map(agreement.fields, "id");
  sanitizedAgreement.attachments = _.map(agreement.attachments, (file) =>
    _.pick(file, ["id", "name", "url", "filepickerId"])
  );
  sanitizedAgreement.paymentSchedules = _.map(agreement.paymentSchedules, (schedule) => {
    const whitelist = [
      "id",
      "amount",
      "amountRate",
      "endDate",
      "paymentDay",
      "paymentInterval",
      "paymentPeriod",
      "paymentMonth",
      "startDate",
      "termLength",
      "termUnit",
    ];
    return _.pick(schedule, whitelist);
  });

  return sanitizedAgreement;
};

export const calculateDateRange = (startDate, termLength, termUnit) => {
  startDate = moment(startDate);
  if (!startDate.isValid()) {
    throw new Error("Invalid start date");
  }

  if (!utils.isInt(termLength, { min: 1 })) {
    throw new Error("Invalid term length");
  }

  if (!["MONTH", "YEAR"].includes(termUnit)) {
    throw new Error(`Invalid term unit ${termUnit}`);
  }

  return {
    endDate: moment(startDate).add(termLength, termUnit.toLowerCase()),
    startDate,
  };
};

export const calculateDateRangeForYears = (startYear, endYear) => ({
  endDate: moment().year(endYear).endOf("year"),
  startDate: moment().year(startYear).startOf("year"),
});
