import FuturesContracts from "marketing/tables/FuturesContracts";
import OptionsContracts from "marketing/tables/OptionsContracts";
import PropTypes from "prop-types";
import React from "react";
import { Row, Col } from "react-styled-flexboxgrid";

const MarketedCropFuturesAndOptions = ({ commodity, marketedCropId, productionStats, year }) => (
  <div>
    {commodity && commodity.marketSymbol && (
      <>
        <Row>
          <Col xs>
            <FuturesContracts
              commodity={commodity}
              marketedCropId={marketedCropId}
              productionStats={productionStats.futures}
              year={year}
            />
          </Col>
        </Row>

        <Row>
          <Col xs>
            <OptionsContracts
              commodity={commodity}
              marketedCropId={marketedCropId}
              productionStats={productionStats.options}
              year={year}
            />
          </Col>
        </Row>
      </>
    )}
  </div>
);

MarketedCropFuturesAndOptions.propTypes = {
  commodity: PropTypes.shape({ marketSymbol: PropTypes.string }),
  marketedCropId: PropTypes.number,
  productionStats: PropTypes.object.isRequired,
  year: PropTypes.number,
};

export default MarketedCropFuturesAndOptions;
