import { useMutation, useQuery } from "@apollo/client";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import CreatableReactSelect from "react-select/creatable";

import createPricelineLocation from "collection/graphql/contracts/mutations/createPricelineLocation";
import getPricelineLocations from "collection/graphql/contracts/queries/getPricelineLocations";
import App from "layout/app";

import { chooserStyles } from "components/fl-ui/Chooser";

const PricelineLocationsDropdown = ({ disabled = false, id, onBlur, onChange, placeholder = "", value }) => {
  const [newlyCreatedLocation, setNewlyCreatedLocation] = useState({});
  const { data, loading } = useQuery(getPricelineLocations, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const baseOptions =
    data?.getPricelineLocations?.map((option) => ({
      label: option.publicName,
      value: option.id,
    })) ?? [];

  const options = !!newlyCreatedLocation ? baseOptions.concat(newlyCreatedLocation) : baseOptions;

  let selectedOption = _.find(options, { value }) ?? null;
  if (!selectedOption && value) {
    selectedOption = { label: value, value };
    options.push(selectedOption);
  }

  const [create] = useMutation(createPricelineLocation);

  const createNewLocation = async (newLocationName) => {
    try {
      await create({
        variables: {
          input: {
            publicName: newLocationName.trim(),
          },
        },
        onCompleted: (data) => {
          const location = data.createPricelineLocation.pricelineLocation;
          setNewlyCreatedLocation({
            label: location.publicName,
            value: location.id,
          });
          onChange(location.id ?? null);
        },
      });
    } catch (error) {
      App.notify("An error with creating the location occurred.");
      throw error;
    }
  };

  return (
    <CreatableReactSelect
      getNewOptionData={(value) => ({ label: `Create ${value.trim()}`, value: value.trim() })}
      inputId={id}
      isClearable
      isDisabled={disabled || loading}
      isValidNewOption={(value) => !!value.trim() && !options.some((option) => option.label === value.trim())}
      onBlur={onBlur}
      onChange={(option) => onChange(option?.value ?? null)}
      onCreateOption={createNewLocation}
      options={options}
      placeholder={placeholder}
      styles={chooserStyles}
      value={selectedOption}
    />
  );
};

PricelineLocationsDropdown.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

export default PricelineLocationsDropdown;
