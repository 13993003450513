import { useQuery } from "@apollo/client";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import ReactSelect from "react-select";

import { GET_COMMODITIES } from "collection/graphql/commodities/queries";

import { chooserStyles } from "components/fl-ui/Chooser";

const ContractCommodityDropdown = ({ disabled = false, onBlur, onChange, placeholder, value }) => {
  const { data } = useQuery(GET_COMMODITIES);
  const options = useMemo(() => {
    return _.sortBy(data?.commodities, ({ isUsed, name }) => !isUsed + name).map((commodity) => ({
      label: commodity.name,
      value: commodity.id,
    }));
  }, [data?.commodities]);
  const selectedOption = options.find((option) => option.value === value);

  return (
    <ReactSelect
      isClearable
      isDisabled={disabled}
      onBlur={onBlur}
      onChange={(option) => onChange(option?.value ?? null)}
      options={options}
      placeholder={placeholder}
      styles={chooserStyles}
      value={selectedOption}
    />
  );
};

ContractCommodityDropdown.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

export default ContractCommodityDropdown;
