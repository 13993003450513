import asAgreementForm from "agreements/forms/asAgreementForm";
import CommandButtonBar from "agreements/forms/components/CommandButtonBar";
import {
  AttachmentsFieldset,
  FieldsFieldset,
  LoanDetailsFieldset,
  NotesFieldset,
  PaymentScheduleFieldset,
  StartDateAndTermFieldset,
} from "agreements/forms/fieldsets";
import { prepareAgreementForSave } from "agreements/utils";
import loanAgreementValidator from "agreements/validators/AgreementsForm/loanAgreementValidator";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";

import { withCreateLoanAgreement, withUpdateLoanAgreement } from "collection/graphql/agreements";

import { Stack } from "components/fl-ui/Layout";

const LoanAgreementForm = ({
  createLoanAgreement,
  updateLoanAgreement,
  fields,
  isValid,
  onChange,
  onCancel,
  onSaveSuccess,
  performDelete,
}) => {
  const handleScheduleChange = ({ paymentSchedules }) => onChange({ paymentSchedules });

  const onSave = async () => {
    const agreement = prepareAgreementForSave(fields, "LOAN");
    if (agreement.id > 0) {
      await updateLoanAgreement(agreement);
    } else {
      await createLoanAgreement(agreement);
    }

    onSaveSuccess();
  };

  return (
    <Stack>
      <StartDateAndTermFieldset
        onChange={onChange}
        startDate={fields.startDate}
        termLength={fields.termLength}
        termUnit={fields.termUnit}
      />
      <FieldsFieldset onChange={onChange} fields={fields.fields} />
      <LoanDetailsFieldset
        interestRate={fields.interestRate}
        lenderName={fields.lenderName}
        loanType={fields.loanType}
        onChange={onChange}
        originalBalance={fields.originalBalance}
      />
      <PaymentScheduleFieldset agreementType="LOAN" fields={fields} onChange={handleScheduleChange} />
      <NotesFieldset notes={fields.notes} onChange={onChange} />
      <AttachmentsFieldset attachments={fields.attachments} onChange={onChange} />
      <CommandButtonBar
        onSaveClick={onSave}
        disableSave={!isValid}
        onCancelClick={onCancel}
        onDeleteClick={fields.id ? () => performDelete(fields.id) : null}
      />
    </Stack>
  );
};

LoanAgreementForm.propTypes = {
  agreement: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onSaveSuccess: PropTypes.func.isRequired,
};

LoanAgreementForm.defaultProps = {
  agreement: {},
};

const getLoanAgreementDefaults = () => ({
  fields: [],
  lenderName: "",
  loanType: "FIXED_RATE_MORTGAGE",
  originalBalance: null,
  interestRate: null,
  paymentSchedules: [],
  startDate: moment().format("YYYY-MM-DD"),
  termLength: 30,
  termUnit: "YEAR",
});
export { getLoanAgreementDefaults };

export default asAgreementForm(withCreateLoanAgreement(withUpdateLoanAgreement(LoanAgreementForm)), {
  validator: loanAgreementValidator,
});
