import { BaseElement } from "@farmlogs/fl-ui";
import MenuListFooter from "activity/components/choosers/MenuListFooter";
import CheckedOption from "activity/components/choosers/options/CheckedOption";
import React from "react";
import { components } from "react-select";
import styled from "styled-components";

import { Button } from "components/fl-ui";
import { CloseX } from "components/fl-ui/Icons";
import Tabs, { Tab } from "components/fl-ui/Tabs";
import { GreyColors, UIColors } from "components/fl-ui/colors";
import { Borders, Spacing, Typography } from "components/fl-ui/constants";
import wrapOccurrences from "components/fl-ui/util/wrapOccurrences";

export const defaultStyles = {
  control: (base, { selectProps }) => ({
    ...base,
    borderColor: selectProps.hasError ? UIColors.danger : base.borderColor,
    borderWidth: selectProps.hasError ? 2 : base.borderWidth,
    minHeight: 42,
  }),
  groupHeading: (base) => ({
    ...base,
    background: GreyColors.smoke92,
    color: UIColors.regular,
    paddingBottom: Spacing.xsmall,
    paddingTop: Spacing.xsmall,
  }),
  indicatorSeparator: () => ({ display: "none" }),
  option: (base) => ({ ...base, borderBottom: Borders.regular, cursor: "pointer", whiteSpace: "normal" }),
};

export const searchStyles = {
  control: (base) => {
    return {
      ...base,
      borderRadius: 20,
      padding: "0 8px",
    };
  },
  indicatorsContainer: () => ({ display: "none" }),
};

export const Mark = styled.mark`
  background: #f5993d;
`;

const TabsContainer = styled.div`
  align-items: center;
  border-bottom: ${Borders.regular};
  display: flex;
  justify-content: space-between;
  padding: ${Spacing.xsmall};
`;

export const WrappedText = styled.span`
  font-size: ${Typography.sizes.rg};
  font-weight: ${Typography.weights.medium};
  ${({ noMargin }) => !noMargin && `margin-bottom: ${Spacing.xxsmall};`}
`;

export const wrapper = (val) => <Mark>{val}</Mark>;

export const InputOption = (props) => {
  const { search } = props.selectProps;
  const name = wrapOccurrences(props.data.name, search ?? "", { wrapper });
  const shortDescription = wrapOccurrences(props.data.shortDescription, search ?? "", { wrapper });
  const commodityName = props.data?.commodity?.name ?? "";

  return (
    <CheckedOption {...props}>
      <div>
        <BaseElement display="flex" gridGap="3px" mb={Spacing.xxsmall}>
          <WrappedText noMargin>{name}</WrappedText> {commodityName && <small> - {commodityName}</small>}
        </BaseElement>
        <small>{shortDescription}</small>
      </div>
    </CheckedOption>
  );
};

export const TabbedMenuList = (props) => {
  const { activeTab, createButtonText, onClose, onCreateStart, onTabChange, tabs } = props.selectProps;

  return (
    <>
      <TabsContainer>
        <Tabs onChange={onTabChange} size="medium" value={activeTab}>
          {tabs.map(({ label, value }) => (
            <Tab key={value} value={value}>
              {label}
            </Tab>
          ))}
        </Tabs>

        {onClose && <CloseX onClick={onClose} />}
      </TabsContainer>

      <components.MenuList {...props} />

      <MenuListFooter>
        <Button color="primary" link onClick={onCreateStart} onTouchStart={onCreateStart}>
          {createButtonText}
        </Button>
      </MenuListFooter>
    </>
  );
};
