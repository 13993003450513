/**
 * @typedef {Object} SystemUnitOfMeasure
 * @property {String} abbr
 * @property {String} data
 * @property {String} display
 * @property {String} type
 */

/**
 * Converts system `Unit` enums to something more digestible by the client.
 * @param {SystemUnitOfMeasure} unitOfMeasure
 * @return {UnitOfMeasure}
 * @private
 */
const systemUnitToClientUnit = ({ abbr, data, display, type }) => ({
  abbr,
  name: display,
  type,
  value: data,
});

export default systemUnitToClientUnit;
