import { gql } from "@apollo/client";

export const getHistoricalRainfall = `
  query getHistoricalRainfall {
    historicalRainfall: getFieldRain {
      fieldId: id
      recent {
        amount
        endTime
        startTime
        state
      }
    }
  }
`;

export default gql(getHistoricalRainfall);
