import { css } from "aphrodite";
import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";

import useViewType from "hooks/useViewType";

import CommodityChooser from "components/commodity/CommodityChooser";

/* Shared Components */
import { Button } from "components/fl-ui";
import { LeftButton } from "components/fl-ui/Buttons";
import DropdownButton from "components/fl-ui/Buttons/DropdownButton";
import { Form, FormGroup, Input } from "components/fl-ui/Form";
import BottomBar from "components/ui/bottom_bar";
import Card from "components/ui/card";
import { HeadingText, MapControlButton, MapControlLabel } from "fields/add/styledComponents";
import styles from "fields/onboarding/onboardingStyles";

const CURRENT_YEAR = new Date().getFullYear();
const getHint = (points) => {
  if (!points) {
    return "Click a corner of your field to start drawing";
  }

  if (points < 3) {
    return "Good work! Click to add another point";
  }

  return "Keep it up! Click your original point to finish";
};

export const ControlButton = ({ color, icon, id, label, onClick }) => {
  const isMobile = useViewType() === "mobile";
  const showLabel = !!label && (!isMobile || !icon);

  return (
    <MapControlButton icon={icon} id={id} onClick={onClick}>
      {showLabel && (
        <MapControlLabel color={color} icon={icon}>
          {label}
        </MapControlLabel>
      )}
    </MapControlButton>
  );
};

ControlButton.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
};

export const HelpBar = ({ onDraw, zoom }) => {
  const isMobile = useViewType() === "mobile";
  const options = [
    { icon: "drawFreeform", text: "Free form", value: "Polygon" },
    { icon: "drawSquare", text: "Draw square", value: "Rectangle" },
    { icon: "drawCircle", text: "Draw circle", value: "Circle" },
  ];

  return (
    <BottomBar style={{ textAlign: "center" }}>
      {zoom < 14 ? (
        <Row middle="xs" center="xs">
          <Col key="zoom">
            <HeadingText>Zoom in to locate your field</HeadingText>
          </Col>
        </Row>
      ) : (
        <Row key="select" middle="xs">
          <Col xs={6} className="text-center">
            <HeadingText>Select a shape or draw your own</HeadingText>
          </Col>
          <Col xs={6} className="text-center">
            {isMobile && (
              <DropdownButton
                buttonText="Free form"
                data-cy="drawFreeform"
                onClick={() => onDraw()}
                onSelect={({ value }) => onDraw(value)}
                options={options}
              />
            )}

            {!isMobile &&
              options.map(({ icon, text, value }) => (
                <LeftButton
                  color="primary"
                  data-cy={icon}
                  icon={icon}
                  key={value}
                  onClick={() => onDraw(value)}
                  size="rg"
                >
                  {text}
                </LeftButton>
              ))}
          </Col>
        </Row>
      )}
    </BottomBar>
  );
};

HelpBar.propTypes = {
  onDraw: PropTypes.func,
  zoom: PropTypes.number,
};

export const DrawHints = ({ onClick, points }) => (
  <span style={{ pointerEvents: "all" }}>
    <Card>
      <button className="close-x" id="field-add-close-hints" onClick={onClick} style={{ right: 0, top: "-1rem" }}>
        ×
      </button>
      <div style={{ paddingBottom: "1em", textAlign: "center" }}>
        <HeadingText style={{ padding: "0.25rem" }}>{getHint(points)}</HeadingText>
        <div className="xs-hide" style={{ paddingTop: "65%" }}>
          <img src="/images/map/how-to-draw.gif" style={{ marginTop: "-65%" }} />
        </div>
      </div>
    </Card>
  </span>
);

DrawHints.propTypes = {
  onClick: PropTypes.func,
  points: PropTypes.number,
};

export const FieldForm = ({ data, isSaving, onCancel, onChange, onSave }) => {
  const disabled = !data.name || isSaving;

  return (
    <span style={{ pointerEvents: "all" }}>
      <Card className={css(styles.fieldAddCard)}>
        <Form>
          <FormGroup label={<span className={css(styles.fieldAddLabel)}>What do you call this field?</span>}>
            <Input
              className="input-l"
              defaultValue={data.name}
              id="field-add-name"
              name="name"
              onChange={onChange}
              placeholder="Enter field name"
              type="text"
            />
          </FormGroup>

          {!data.id && (
            <FormGroup
              label={
                <span className={css(styles.fieldAddLabel)}>
                  What will you harvest on this field in {CURRENT_YEAR}?
                </span>
              }
            >
              <CommodityChooser
                commodityId={data.commodityId}
                onChange={({ id }) => onChange({ target: { name: "commodityId", value: id } })}
                placeholder="Choose commodity"
              />
            </FormGroup>
          )}

          <Button
            color={disabled ? "default" : "primary"}
            data-cy="save-field-button"
            disabled={disabled}
            display="block"
            loading={isSaving}
            onClick={onSave}
          >
            Save My Field
          </Button>

          <Button display="block" link onClick={onCancel}>
            Cancel
          </Button>
        </Form>
      </Card>
    </span>
  );
};

FieldForm.propTypes = {
  data: PropTypes.object,
  isSaving: PropTypes.bool,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
};
