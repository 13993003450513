import Content from "prices/charts/components/FuturesContractHistoryTooltipContent";
import PropTypes from "prop-types";
import React from "react";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { GreyColors, UIColors } from "components/fl-ui/colors";

const FuturesContractHistoryLineChart = ({ data, minHeight = 250, xAxisProps, yAxisProps }) => (
  <ResponsiveContainer minHeight={minHeight}>
    <LineChart data={data} margin={{ top: 5, bottom: 5 }}>
      <CartesianGrid isAnimationActive={false} stroke={GreyColors.smoke91} strokeDasharray="3 3" vertical={false} />
      <Tooltip content={<Content contentType="line" />} formatter={(value) => value} />
      <Line dataKey="close" dot={false} stroke={UIColors.green} strokeWidth={2} />
      <XAxis {...xAxisProps} />
      <YAxis {...yAxisProps} />
    </LineChart>
  </ResponsiveContainer>
);

FuturesContractHistoryLineChart.propTypes = {
  data: PropTypes.array.isRequired,
  minHeight: PropTypes.number,
  xAxisProps: PropTypes.object.isRequired,
  yAxisProps: PropTypes.object.isRequired,
};

export default FuturesContractHistoryLineChart;
