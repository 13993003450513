import { StyleSheet } from "aphrodite/no-important";

import { UIColors } from "components/fl-ui/colors";
import { Borders, Spacing, Typography } from "components/fl-ui/constants";

const styles = StyleSheet.create({
  buttonIconStyle: {
    height: Spacing.xlarge,
    width: Spacing.xlarge,
  },
  buttonContent: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    marginLeft: Spacing.xsmall,
    width: "100%",
  },
  buttonText: {
    "@media only screen and (max-width: 62.5rem)": {
      display: "none",
    },
  },
  cardStyle: {
    cursor: "pointer",
    left: 0,
    padding: "0",
    position: "relative",
    top: Spacing.xsmall,
    zIndex: "10",
  },
  containerStyle: {
    height: 0,
    overflow: "visible",
  },
  disabled: {
    color: UIColors.light,
  },
  flatButton: {
    margin: `${Spacing.xsmall} 0`,
    "@media only screen and (max-width: 62.5rem)": {
      margin: 0,
      ":nth-of-type(2)": {
        position: "absolute",
        right: Spacing.regular,
        width: "min-content",
      },
    },
  },
  galleryContainer: {
    background: "rgba(23, 24, 28)",
  },
  galleryStyle: {
    overflow: "hidden",
    color: "white",
    marginBottom: "3em",
    pointerEvents: "all",
  },
  iconContainer: {
    alignItems: "flex-end",
    background: "rgba(0, 0, 0, 0.3)",
    display: "flex",
    justifyContent: "center",
    padding: "4px 0",
  },
  iconStyle: {
    height: Spacing.xlarge,
    margin: `0 ${Spacing.xsmall}`,
    width: Spacing.xlarge,

    "@media only screen and (max-width: 62.5rem)": {
      height: Spacing.large,
      margin: `0 ${Spacing.xxsmall}`,
      width: Spacing.large,
    },
  },
  iconTickStyle: {
    height: 22,
    width: 22,
  },
  legendContainer: {
    borderTop: Borders.regular,
    fontSize: Typography.sizes.sm,
    marginTop: Spacing.large,
    paddingTop: Spacing.large,
  },
  lockIconStyle: {
    height: 14,
    position: "absolute",
    textAlign: "center",
    width: 12,
  },
  mapControlButton: {
    display: "flex",
    height: Spacing.xxxlarge,
    justifyContent: "flex-start !important",
    width: "100%",
  },
  mapControlMargin: {
    marginTop: `${Spacing.regular} !important`,
  },
  optionsContainer: {
    alignItems: "center",
    borderBottom: "solid 1px #e3e5e8",
    display: "flex",
    justifyContent: "space-between",
    minHeight: "3em",
    padding: ".5em",
  },
  optionStyle: {
    alignItems: "center",
    display: "flex",
  },
  optionText: {
    fontSize: Typography.sizes.lg,
    fontWeight: Typography.weights.medium,
    "@media only screen and (max-width: 62.5rem)": {
      fontSize: Typography.sizes.sm,
    },
  },
  popoverButton: {
    "pointer-events": "all",
    width: "100%",
  },
  mapControlButtonContainer: {
    marginTop: Spacing.regular,
    pointerEvents: "all",
    "@media only screen and (max-width: 62.5rem)": {
      marginTop: 0,
    },
  },
  mapMultiControl: {
    "@media only screen and (max-width: 62.5rem)": {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  mapControlButtonContent: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    marginLeft: Spacing.xsmall,
    width: "100%",
  },
  mapControlOptionsContainer: {
    display: "flex !important",
    justifyContent: "space-between",
    marginLeft: `${Spacing.xsmall} !important`,
    minHeight: `${Spacing.xxxlarge} !important`,
  },
  noYearPicker: {
    marginTop: "1.75em",
  },
  popoverContainer: {
    marginLeft: Spacing.medium,
    marginRight: Spacing.medium,
    "@media only screen and (max-width: 30em)": {
      marginLeft: Spacing.xsmall,
      marginRight: Spacing.xsmall,
    },
  },
  popoverContent: {
    display: "flex",
    flexFlow: "column-reverse",
  },
  popoverSelection: {
    padding: `0 ${Spacing.xxsmall} ${Spacing.small} !important`,
    textDecoration: "underline !important",
  },
  popoverTarget: {
    "pointer-events": "all",
    display: "block !important",
    width: "100%",
  },
  popoverTargetIcon: {
    display: "inline-block",
    width: Spacing.regular,
    height: Spacing.regular,
    verticalAlign: "text-bottom",
    marginLeft: Spacing.xsmall,
    marginRight: Spacing.xsmall,
  },
  selected: {
    color: `${UIColors.blue} !important`,
  },
  unsetWidth: {
    "@media only screen and (max-width: 62.5rem)": {
      width: "unset",
    },
  },
});

export default styles;
