import { useCallback } from "react";

import BasicNoteFragment from "collection/graphql/notes/fragments/BasicNote";
import createNote from "collection/graphql/notes/mutations/createNote";
import deleteNote from "collection/graphql/notes/mutations/deleteNote";
import updateNote from "collection/graphql/notes/mutations/updateNote";
import NoteSchema from "collection/graphql/notes/schemas/NoteSchema";
import useRestMutation from "hooks/useRestMutation";

const useNoteMutations = () => {
  const [update] = useRestMutation(updateNote, {
    optimisticResponse: ({ input }) => ({ note: input }),
  });

  const [destroy] = useRestMutation(deleteNote, {
    onQueryUpdated: ({ queryName }) => {
      return queryName !== "getNoteById";
    },

    update: (cache, { data: { note } }) => {
      cache.evict({ id: cache.identify(note) });
      cache.gc();
    },
  });

  const [create] = useRestMutation(createNote, {
    update: (cache, { data: { note } }) => {
      cache.modify({
        fields: {
          getAllNotes: (existingNotes) =>
            existingNotes.concat(
              cache.writeFragment({
                data: note,
                fragment: BasicNoteFragment,
              })
            ),
        },
      });
    },
  });

  const save = useCallback(
    (note, options = {}) => {
      const input = NoteSchema.validateSync(note, {
        abortEarly: false,
        strict: true,
        stripUnknown: true,
      });

      const queryOptions = {
        ...options,
        variables: {
          input,
        },
      };

      return input.id ? update(queryOptions) : create(queryOptions);
    },
    [create, update]
  );

  return [save, destroy];
};

export default useNoteMutations;
