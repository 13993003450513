import _ from "lodash";
import qs from "qs";

export const parse = (queryString = "") => qs.parse(queryString);

export const stringify = (rawParams) => {
  const params = _.transform(
    rawParams,
    (result, value, key) => {
      if (value && (!_.isArray(value) || _.size(value) > 0)) {
        result[key] = value;
      }

      return result;
    },
    {}
  );

  return qs.stringify(params, { indicies: false });
};

export default { parse, stringify };
