import paymentScheduleValidator from "agreements/validators/AgreementsForm/paymentScheduleValidator";
import _ from "lodash";
import moment from "moment";

import utils from "lib/validation/utils";

const isValid = (fields, errors = {}) => _.isEmpty(validate(fields, { errors }));

const validate = (formFields, options = {}) => {
  const errors = options.errors || {};
  const { fields, paymentSchedules, revenueSource, startDate, termLength, termUnit } = formFields;

  // startDate
  if (!/^\d{4}-\d{2}-\d{2}$/.test(startDate) || !moment(startDate).isValid()) {
    errors.startDate = { value: startDate };
  }

  // fields
  if (_.size(fields) === 0 || !_.every(fields, "id")) {
    errors.fields = { value: fields };
  }

  // termLength
  if (!utils.isInt(termLength, { gt: 0 })) {
    errors.termLength = { value: termLength };
  }

  // termUnit
  if (!["MONTH", "YEAR"].includes(termUnit)) {
    errors.termUnit = { value: termUnit };
  }

  // revenueSource
  const validTypes = ["HUNTING", "MINING", "OIL", "WIND", "EASEMENT", "CONSERVATION", "OTHER"];
  if (!validTypes.includes(revenueSource)) {
    errors.revenueSource = { value: revenueSource };
  }

  // paymentSchedules
  const allSchedulesValid = _.every(paymentSchedules, (schedule) =>
    paymentScheduleValidator.isValid(schedule, { context: formFields })
  );
  if (!allSchedulesValid) {
    errors.paymentSchedules = { value: paymentSchedules };
  }

  return errors;
};

export default {
  isValid,
  validate,
};
