import React from "react";

import states from "lib/states";

import { Select } from "components/fl-ui/Form";

const USStatesSelect = (props) => {
  const options = states.US.map(({ code, name }) => ({
    key: code,
    label: name,
    value: code,
  }));

  return <Select {...props} options={options} minimal />;
};

export default USStatesSelect;
