import { gql } from "@apollo/client";

export default gql`
  fragment SubscriptionProductFragment on SubscriptionProduct {
    acreLimit
    display
    id
    includedBy
    includes
    isPurchasable @client
    mobileIds
    mobilePlanId @client
    name
    plans {
      interval
      price
    }
    promos
    requires
    selfService
    trial
    userLimits
  }
`;
