import PropTypes from "prop-types";
import React from "react";

import NotificationIcon from "modules/notifications/components/NotificationIcon";

import Icon from "components/fl-ui/Icons/Icon";

const NotificationItemIcon = ({ count, notification, ...props }) => {
  const icon = {
    imagery: "imagery",
    "market-prices": "marketing",
    news: "news",
    rainfall: "rainfall",
    "work-orders": "activities",
  }[notification.type];

  return icon ? <Icon icon={icon} {...props} /> : <NotificationIcon count={count} />;
};

NotificationItemIcon.propTypes = {
  count: PropTypes.number,
  notification: PropTypes.object.isRequired,
};

NotificationItemIcon.defaultProps = {
  count: -1,
};

export default NotificationItemIcon;
