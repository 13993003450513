import { isNil } from "lodash";

import UserConfigSchema from "collection/graphql/config/schemas/UserConfigSchema";

const handler = (value, { fieldName }) => {
  if (isNil(value) && fieldName in UserConfigSchema.fields) {
    return UserConfigSchema.fields[fieldName].cast();
  }

  return value;
};

const UserConfigTypePolicy = {
  keyFields: ["__typename"],

  fields: {
    activitySortCriteria: handler,
    fieldSortCriteria: handler,
    fieldViewType: handler,
    didOnboarding: handler,
    mapBounds: handler,
  },
};

export default UserConfigTypePolicy;
