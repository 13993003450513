import styles from "activity/common/styles";
import { CardItems, ToggleButton, ToggleContainer } from "activity/components/styledComponents";
import { css } from "aphrodite";
import Big from "big.js";
import _ from "lodash";
import numeral from "numeral";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import ActivityAreaCard from "modules/activity/components/ActivityAddEditModal/ActivityAreaCard";

const FieldCropAcreageList = ({ bulkAttributes, onChange }) => {
  const [expanded, setExpanded] = useState(false);

  const hasCrops = _.size(bulkAttributes) > 0;
  const totals = bulkAttributes.reduce(
    ({ totalArea, totalCropAcreage }, { acreage, crop }) => {
      return {
        totalArea: new Big(totalArea).add(+acreage).toNumber(),
        totalCropAcreage: new Big(totalCropAcreage).add(+crop.acreage).toNumber(),
      };
    },
    { totalArea: new Big(0), totalCropAcreage: new Big(0) }
  );

  const handleChange = (updatedItem) => {
    onChange(bulkAttributes.map((item) => (item.crop.id === updatedItem.crop.id ? updatedItem : item)));
  };

  useEffect(() => {
    if (!hasCrops && expanded) {
      setExpanded(false);
    }
  }, [hasCrops]);

  return (
    <>
      <ToggleContainer>
        <CardItems className={css(styles.optionHeader)}>
          <div>
            Area: {numeral(totals.totalArea).format("0,0.0")} ac / {numeral(totals.totalCropAcreage).format("0,0.0")} ac
          </div>

          <ToggleButton
            color="primary"
            disabled={!hasCrops}
            icon={expanded ? "collapse" : "expand"}
            iconRight
            link
            onClick={() => setExpanded((expanded) => !expanded)}
          >
            {expanded ? "Close" : "Edit area"}
          </ToggleButton>
        </CardItems>
      </ToggleContainer>

      {expanded &&
        bulkAttributes.map((attribute) => (
          <ActivityAreaCard {...attribute} key={attribute.crop.id} onChange={handleChange} />
        ))}
    </>
  );
};

FieldCropAcreageList.propTypes = {
  bulkAttributes: PropTypes.arrayOf(
    PropTypes.shape({
      acreage: PropTypes.number,
      crop: PropTypes.object,
      geometry: PropTypes.object,
      geometricCircumference: PropTypes.object,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FieldCropAcreageList;
