import { gql } from "@apollo/client";

export default gql`
  query getRssFeedEntry($date: String) {
    rssEntry: getRssFeedEntry(date: $date) {
      title
      links {
        href
        type
        rel
      }
      summary
      published
      authors {
        name
      }
    }
  }
`;
