import ActivityCard from "activity/components/ActivityCard";
import ActivityListing from "activity/containers/ActivityListing";
import useActivityFilters from "activity/hooks/useActivityFilters";
import { perAcreActivityCost, sumActivityAcreageBy } from "activity/utils";
import PropTypes from "prop-types";
import React, { useState } from "react";

import usePermissions from "hooks/usePermissions";
import useViewType from "hooks/useViewType";

import { Button } from "components/fl-ui";
import Pane from "components/fl-ui/Layout/Pane";
import { Typography } from "components/fl-ui/constants";

const GroupedActivities = (props) => {
  const { activities, groupName } = props;
  const { sortCriteria } = useActivityFilters().filters;
  const [showAllActivities, setShowAllActivities] = useState(false);
  const { canRead } = usePermissions();
  const isDesktop = useViewType() === "desktop";
  const activitiesCount = activities.length;
  const displayedActivities = !showAllActivities && activitiesCount > 5 ? activities.slice(0, 5) : activities;
  const remainingActivities = activitiesCount - 5;

  const getGroupTitle = () => {
    return sortCriteria === "fieldName" ? groupName : _.capitalize(groupName);
  };

  const getSubtitle = () => {
    const { activities } = props;
    const activitiesCount = activities.length;
    const subtitle = [`${activitiesCount} ${activitiesCount > 1 ? "activities" : "activity"}`];
    if (canRead("activity_type_costs")) {
      let totalAcreage;
      switch (sortCriteria) {
        case "category":
          totalAcreage = sumActivityAcreageBy(activities, "field");
          break;

        case "cropName":
          totalAcreage = sumActivityAcreageBy(activities, "crop");
          break;

        case "fieldName":
          totalAcreage = activities[0].field.acreage;
          break;
      }

      const activityCost = perAcreActivityCost(activities, totalAcreage);
      subtitle.push(`$${Math.round(activityCost)} / ac`);
    }

    return subtitle.join(" \u00b7 ");
  };

  return (
    <Pane collapsible subtitle={getSubtitle()} title={getGroupTitle()}>
      {isDesktop && <ActivityListing activities={displayedActivities} />}

      {!isDesktop &&
        displayedActivities.map((activity, index) => (
          <ActivityCard activity={activity} key={`${activity.id}-${index}`} />
        ))}

      {remainingActivities > 0 && (
        <Button
          color="primary"
          link
          onClick={() => setShowAllActivities(!showAllActivities)}
          style={{ fontWeight: Typography.weights.regular }}
        >
          {`${showAllActivities ? "Hide" : "Show"} ${remainingActivities} more...`}
        </Button>
      )}
    </Pane>
  );
};

GroupedActivities.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.object).isRequired,
  groupName: PropTypes.string.isRequired,
};

export default GroupedActivities;
