import { gql } from "@apollo/client";

export default gql`
  query getUserConfig {
    userConfig: getUserConfig @rest(path: "/config", type: "UserConfig") {
      activitySortCriteria
      didOnboarding
      fieldSortCriteria
      fieldViewType
      mapBounds {
        center
        zoom
      }
    }
  }
`;
