import useContractsIndexPage from "contracts/hooks/useContractsIndexPage";
import React from "react";

import { Button } from "components/fl-ui";
import BlankSlate from "components/fl-ui/BlankSlate";

const NoContractsResults = () => {
  const { clearFilters, clearSearch, currentFilterCount, currentSearchString, totalContracts, reloadAll } =
    useContractsIndexPage();
  const noContractsExist = totalContracts === 0;

  const resolveContractsContent = () => {
    if (noContractsExist) {
      return {
        title: "You haven’t added any contracts yet",
        message: "Start logging your contracts to update your marketing plan and track your Profit & Loss.",
        action: null,
        actionText: null,
      };
    }

    if (currentSearchString?.length > 0) {
      return {
        title: "No results found",
        message: "Try a different search to find what you are looking for.",
        action: clearSearch,
        actionText: "Clear search",
      };
    }

    if (currentFilterCount > 0) {
      return {
        title: "No results found",
        message: "Try a different set of filters to find what you are looking for.",
        action: clearFilters,
        actionText: "Clear filters",
      };
    }

    return {
      title: "No results found",
      message: "Try adding some contracts to update your marketing plan and track your Profit & Loss.",
      action: reloadAll,
      actionText: "Clear all",
    };
  };

  return totalContracts === null ? null : (
    <BlankSlate>
      <h3>{resolveContractsContent().title}</h3>
      <p>{resolveContractsContent().message}</p>
      <br />
      {resolveContractsContent().action && (
        <Button color="primary" onClick={resolveContractsContent().action}>
          {resolveContractsContent().actionText}
        </Button>
      )}
    </BlankSlate>
  );
};

export default NoContractsResults;
