import BasicFieldFilters from "imagery/components/BasicFieldFilters";
import { imageTypes, sortOptions } from "imagery/utils";
import PropTypes from "prop-types";
import React, { useState } from "react";

import FilterActionButtonGroup from "components/fl-ui/Filters/FilterActionButtonGroup";
import CloseX from "components/fl-ui/Icons/CloseX";
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "components/fl-ui/Modal/Modal";

const FilterModal = (props) => {
  const { fields, filters, imageType, onClose, setFilters, setImageType, setSortCriteria, sortCriteria, years } = props;
  const [selection, setSelection] = useState(filters);

  const applyFilters = () => {
    const { imageType, sortCriteria, ...regularFilters } = selection;
    setFilters(regularFilters);
    sortCriteria && setSortCriteria(sortCriteria);
    imageType && setImageType(imageType);
    onClose();
  };

  const resetFilters = () => {
    setFilters({ commodities: [], groups: [], irrigation: [] });
    onClose();
  };

  const mobileOnlyDropdowns = [
    { defaultValue: imageType, id: "imageType", label: "Type", options: imageTypes },
    { defaultValue: sortCriteria, id: "sortCriteria", label: "Sort by", options: sortOptions },
  ];

  return (
    <Modal>
      <ModalHeader>
        <ModalTitle>Filter imagery</ModalTitle>
        <CloseX onClick={onClose} />
      </ModalHeader>

      <ModalBody>
        <BasicFieldFilters
          fields={fields}
          mobileOnlyDropdowns={mobileOnlyDropdowns}
          selection={selection}
          setSelection={setSelection}
          years={years}
        />
      </ModalBody>

      <ModalFooter>
        <FilterActionButtonGroup
          apply={{ action: applyFilters }}
          cancel={{ action: onClose }}
          reset={{ action: resetFilters }}
        />
      </ModalFooter>
    </Modal>
  );
};

FilterModal.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  filters: PropTypes.shape({
    commodities: PropTypes.array,
    groups: PropTypes.array,
    irrigation: PropTypes.array,
  }).isRequired,
  imageType: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  setImageType: PropTypes.func.isRequired,
  setSortCriteria: PropTypes.func.isRequired,
  sortCriteria: PropTypes.string.isRequired,
  years: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default FilterModal;
