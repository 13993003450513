import { useQuery } from "@apollo/client";
import { useEffect } from "react";

import { getFieldCrop } from "collection/graphql/fields/queries";
import withCropMutation from "hoc/withCropMutation";

const CropView = (props) => {
  const { createCrop, data: defaults, editCrop, id, onFinish } = props;
  const { data, loading } = useQuery(getFieldCrop, { variables: { cropId: id } });
  const crop = data?.fieldCrop ?? { ...defaults };
  const fieldId = crop.field?.id ?? crop.fieldId ?? null;

  useEffect(() => {
    const additionalProps = { onClose: onFinish };
    if (!loading) {
      if (crop.id) {
        editCrop(crop, additionalProps);
      } else {
        createCrop(fieldId, crop, additionalProps);
      }
    }
  }, [crop.id, loading]);

  return null;
};

export default withCropMutation(CropView);
