import * as yup from "yup";

import ProductPurchaseInputSchema from "collection/graphql/products/schemas/ProductPurchaseInputSchema";

import { PurchaseInputSchema } from "components/product/schemas";

const ProductPurchaseFormSchema = yup.object().shape({
  date: ProductPurchaseInputSchema.fields.date.clone(),
  id: ProductPurchaseInputSchema.fields.id.clone(),
  vendor: ProductPurchaseInputSchema.fields.vendor.clone(),
  inputs: yup.array().label("Product inputs").required().of(PurchaseInputSchema).min(1),
});

export default ProductPurchaseFormSchema;
