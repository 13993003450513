import themeGet from "@styled-system/theme-get";
import React from "react";
import styled from "styled-components";

import { Spacing } from "components/fl-ui/constants";

const NavigationHeader = styled.div`
  align-items: center;
  background-color: ${themeGet("colors.white")};
  border-bottom: ${themeGet("borders.basic")};
  display: flex;
  justify-content: space-between;
  padding: ${Spacing.minor} ${Spacing.regular} ${Spacing.xsmall};
  width: 100%;
`;

NavigationHeader.displayName = "NavigationHeader";

export default NavigationHeader;
