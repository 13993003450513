import numeral from "numeral";
import PropTypes from "prop-types";
import React from "react";

import PageSummary, { ResponsiveFigure } from "components/fl-ui/PageSummary";
import ProductAmountRemainingUnit from "components/product/ProductAmountRemainingUnit";
import ProductApplyUnit from "components/product/ProductApplyUnit";
import ProductPurchaseUnit from "components/product/ProductPurchaseUnit";

const NullSummary = () => (
  <PageSummary>
    <ResponsiveFigure value="--" label="Amount purchased" />
    <ResponsiveFigure value="--" label="Average unit price" />
    <ResponsiveFigure value="--" label="Total cost" />
  </PageSummary>
);

const Unit = ({ unit }) => <small>{unit}</small>;

const ProductDetailSummary = (props) => {
  const { purchaseSummary } = props;
  const { amountAppliedTotal, amountPurchasedTotal, amountRemainingTotal, costPerUnit, costTotal } = purchaseSummary;
  if (!purchaseSummary) {
    return <NullSummary />;
  }

  const formatNumber = (number) => numeral(number).format("0,0[.]0");
  const purchased = formatNumber(amountPurchasedTotal);
  const applied = formatNumber(amountAppliedTotal);
  const remaining = amountRemainingTotal !== null ? formatNumber(amountRemainingTotal) : null;

  const perUnitCost = numeral(costPerUnit).format("$0,0.00");
  const totalCost = numeral(costTotal).format("$0,0");

  const unitProps = { alignItems: "center", source: purchaseSummary, components: { Unit } };

  return (
    <PageSummary>
      <ResponsiveFigure value={<ProductPurchaseUnit {...unitProps} amount={purchased} />} label="Amount purchased" />
      <ResponsiveFigure value={<ProductApplyUnit {...unitProps} amount={applied} />} label="Amount used" />
      <ResponsiveFigure
        value={remaining ? <ProductAmountRemainingUnit {...unitProps} amount={remaining} /> : "--"}
        label="Amount remaining"
      />
      <ResponsiveFigure
        value={<ProductPurchaseUnit {...unitProps} amount={perUnitCost} per="unit" />}
        label="Average unit price"
      />
      <ResponsiveFigure value={totalCost} label="Total cost" />
    </PageSummary>
  );
};

ProductDetailSummary.propTypes = {
  purchaseSummary: PropTypes.shape({
    amountPurchasedTotal: PropTypes.number.isRequired,
    amountPurchasedUnit: PropTypes.string.isRequired,
    costPerUnit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    costTotal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }),
};

export default ProductDetailSummary;
