import ProductActivitiesDetailTable from "products/components/ProductActivitiesDetailTable";
import ProductDetailSummary from "products/components/ProductDetailSummary";
import ProductDetailsBlankSlate from "products/components/ProductDetailsBlankSlate";
import ProductInfo from "products/components/ProductInfo";
import ProductPageHeader from "products/components/ProductPageHeader";
import ProductPurchasesDetailTable from "products/components/ProductPurchasesDetailTable";
import PurchaseFormModal from "products/containers/PurchaseFormModal";
import useProductDetailData from "products/hooks/useProductDetailData";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import router from "router";

import { useFeatureAndPermissionAccess } from "hooks/useFeatureAndPermissionAccess";
import useViewType from "hooks/useViewType";
import { PRODUCT_DETAIL_PAGE_VIEW } from "lib/metrics/events";

import PayGateAdBanner from "components/advertisements/banners/PayGateAdBanner";
import { Container, Content, ContentMain, ContentSidebar, Stack } from "components/fl-ui/Layout";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import RestrictedAccessBlankslate from "components/fl-ui/RestrictedAccessBlankslate";
import { Spacing } from "components/fl-ui/constants";

const ProductDetailsPage = (props) => {
  const { productId } = props;
  const { cropYear, loading, refreshQuery, summary } = useProductDetailData(productId);
  const [purchaseToEdit, setPurchaseToEdit] = useState(null);
  const showBlankSlate = !loading && !summary;
  const isMobile = useViewType() === "mobile";

  const { loadingAccess, showPaygate, showRestricted, showContent } = useFeatureAndPermissionAccess({
    featureName: "products",
    permissionName: "inputs",
  });

  const handleEditClick = (purchaseToEdit) => setPurchaseToEdit(purchaseToEdit);

  const handleActivityClick = (activity) => {
    router.navigate(`activity/${activity.id}`, { trigger: true });
  };

  const handleCreate = () => {
    setPurchaseToEdit({});
  };

  useEffect(() => {
    PRODUCT_DETAIL_PAGE_VIEW.track({ productId });
  }, []);

  return (
    <Container>
      <LoadingWrapper isLoading={loadingAccess}>
        <Stack>
          {showPaygate && (
            <>
              <ProductPageHeader onCreatePurchase={handleCreate} />
              <PayGateAdBanner onClick={handlePaygate} />
            </>
          )}
          {showRestricted && <RestrictedAccessBlankslate />}
          {showContent && (
            <>
              <ProductPageHeader
                forceIndexLink={showBlankSlate}
                onCreatePurchase={handleCreate}
                product={summary?.product}
              />

              <LoadingWrapper isLoading={loading}>
                <Content>
                  {!showBlankSlate && !isMobile && (
                    <ContentSidebar>
                      <ProductInfo product={summary?.product} />
                    </ContentSidebar>
                  )}

                  <ContentMain>
                    {showBlankSlate && <ProductDetailsBlankSlate cropYear={cropYear} />}

                    {!showBlankSlate && (
                      <Stack space={Spacing.xxlarge}>
                        <ProductDetailSummary purchaseSummary={summary} />
                        <ProductPurchasesDetailTable purchaseSummary={summary} onEditClick={handleEditClick} />
                        <ProductActivitiesDetailTable purchaseSummary={summary} onActivityClick={handleActivityClick} />
                        {!showBlankSlate && isMobile && <ProductInfo product={summary?.product} />}
                      </Stack>
                    )}
                  </ContentMain>
                </Content>
              </LoadingWrapper>
            </>
          )}
        </Stack>
      </LoadingWrapper>

      {purchaseToEdit && (
        <PurchaseFormModal
          cropYear={cropYear}
          onClose={() => setPurchaseToEdit(null)}
          onDeleteSuccess={() => {
            setPurchaseToEdit(null);
            refreshQuery();
          }}
          onSaveSuccess={() => {
            setPurchaseToEdit(null);
            refreshQuery();
          }}
          purchase={purchaseToEdit}
        />
      )}
    </Container>
  );
};

ProductDetailsPage.propTypes = {
  productId: PropTypes.number.isRequired,
};

export default ProductDetailsPage;
