import { RestLink } from "apollo-link-rest";
import _ from "lodash";

const restLinkOptions = {
  credentials: "same-origin",
  fieldNameDenormalizer: (fieldName) => _.snakeCase(fieldName),
  fieldNameNormalizer: (fieldName) => _.camelCase(fieldName),
  uri: "/v2.0",

  typePatcher: {
    Subscription: (data) => ({
      ...data,
      products: data.products.map((product) => ({
        ...product,
        __typename: "SubscriptionPackage",
      })),
    }),

    SubscriptionProduct: (data) => ({
      ...data,
      plans: data.plans.map((plan) => ({
        ...plan,
        __typename: "SubscriptionProductBillingInterval",
      })),
    }),
  },
};

export const restLink = new RestLink(restLinkOptions);
