import { gql } from "@apollo/client";

export default gql`
  query getAdvisorCommentaries {
    commentary: getAdvisorCommentaries {
      ... on ActiveRecommendationCommentary {
        ...ActiveRecommendationCommentaryFragment
      }
      ... on DailyFlashCommentary {
        ...DailyFlashCommentaryFragment
      }
      ... on OtherCommentary {
        ...OtherCommentaryFragment
      }
      ... on USDAMarketUpdateCommentary {
        ...USDAMarketUpdateCommentaryFragment
      }
      ... on WeeklyReportCommentary {
        ...WeeklyReportCommentaryFragment
      }
    }
  }

  fragment ActiveRecommendationCommentaryFragment on ActiveRecommendationCommentary {
    __typename
    id
    content
    publishDate
    commodity {
      id
      name
      ordinal
    }
  }

  fragment DailyFlashCommentaryFragment on DailyFlashCommentary {
    __typename
    id
    content
    publishDate
  }

  fragment OtherCommentaryFragment on OtherCommentary {
    __typename
    id
    content
    publishDate
    title
    assets {
      type
      filepickerId
      url
    }
  }

  fragment USDAMarketUpdateCommentaryFragment on USDAMarketUpdateCommentary {
    __typename
    id
    content
    publishDate
  }

  fragment WeeklyReportCommentaryFragment on WeeklyReportCommentary {
    __typename
    id
    content
    publishDate
    details {
      detail {
        ... on WeeklyReportCommodityDetail {
          assets {
            type
            filepickerId
            url
          }
          commodity {
            id
            name
            ordinal
          }
        }
        ... on WeeklyReportOtherDetail {
          assets {
            type
            filepickerId
            url
          }
        }
      }
    }
  }
`;
