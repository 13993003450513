import d3 from "d3";

const dollarFormat = (amount, options = {}) => {
  // If our amount is 999 or greater, ditch the ultra-fine precision
  const defaultPrecision = Math.abs(amount) > 999 ? 0 : 2;

  const { precision = defaultPrecision, returnZero = false } = options;

  const format = d3.format(`$,.${precision}f`);

  if (!(Number(amount) || returnZero)) return "$0.00";

  return format(amount);
};

export default dollarFormat;
