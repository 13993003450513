import { css } from "aphrodite/no-important";
import React from "react";

import PayGateCommonBlankSlateBanner from "components/advertisements/banners/PayGateCommonBlankSlateBanner";
import styles from "components/advertisements/styles";

const ContractsBlankSlateBanner = (props) => (
  <PayGateCommonBlankSlateBanner
    {...{
      ...props,
      actionButtonText: "Try Contracts for Free",
      imagePath: "images/pay_gates/banners/contract.svg",
      title: "With Contracts you can:",
    }}
  >
    <ul>
      <li className={css(styles.valueProp)}>
        Automate the entry of grain contract data from grain buyers into the Bushel Network.
      </li>
      <li className={css(styles.valueProp)}>
        Organize, view, and easily access all of your grain contracts from all of your buyers.
      </li>
      <li className={css(styles.valueProp)}>
        Quickly access real-time marketing and financial insights generated using your contract and cost of production
        details in the Marketing and Profit & Loss features.
      </li>
    </ul>
  </PayGateCommonBlankSlateBanner>
);

export default ContractsBlankSlateBanner;
