import { DatePicker } from "@farmlogs/fl-ui";
import moment from "moment";
import React from "react";

import { FormGroup, NumericInput, Select } from "components/fl-ui/Form";
import { Cluster } from "components/fl-ui/Layout";

const StartDateAndTermFieldset = ({ onChange, startDate, termLength, termUnit }) => {
  const termUnits = [
    { key: "YEAR", label: "years", value: "YEAR" },
    { key: "MONTH", label: "months", value: "MONTH" },
  ];

  const startDateValue = moment(startDate).isValid() ? moment(startDate).format("YYYY-MM-DD") : "";

  return (
    <Cluster space="2rem" style={{ flexWrap: "wrap", justifyItems: "start" }}>
      <FormGroup label="Start date" style={{ marginRight: "0.5rem" }}>
        <DatePicker onChange={(startDate) => onChange({ startDate })} value={startDateValue} />
      </FormGroup>

      <FormGroup label="Term">
        <Cluster>
          <NumericInput
            name="termLength"
            onChange={({ target: { value } }) => onChange({ termLength: value })}
            style={{ marginRight: "0.5rem" }}
            type="integer"
            value={termLength}
          />
          <Select
            name="termUnit"
            onChange={(event, payload) => onChange(payload)}
            options={termUnits}
            value={termUnit}
          />
        </Cluster>
      </FormGroup>
    </Cluster>
  );
};

export default StartDateAndTermFieldset;
