import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";

const CropHeadingIcon = (props) => {
  const HEIGHT = 12;
  const MARGIN = 3;
  const RADIUS = 5;
  const XY_CENTER = 6;

  /*
   * If multiple colors are provided, they must be separated by white circles. Fold in
   * the values for these circles
   */
  const separator = { cy: XY_CENTER, fill: "#fff", r: RADIUS };
  let data = props.fillColors.reduce(
    (result, fill) =>
      result.concat(separator, {
        cy: XY_CENTER,
        fill,
        r: RADIUS,
        stroke: props.borderColor,
        strokeWidth: 1,
      }),
    []
  );
  // git rid of the initial separator and reverse the array
  data.shift();
  data = _.reverse(data);

  const WIDTH = MARGIN * (data.length - 1) + HEIGHT;
  const containerProps = Object.assign(_.omit(props, ["borderColor", "fillColors"]), {
    height: HEIGHT,
    width: WIDTH,
    xmlns: "http://www.w3.org/2000/svg",
  });

  return (
    <svg {...containerProps}>
      {data.map((props, i) => (
        <circle key={`circle-${i}`} cx={WIDTH - i * MARGIN - XY_CENTER} {...props} />
      ))}
    </svg>
  );
};

CropHeadingIcon.propTypes = {
  borderColor: PropTypes.string.isRequired,
  fillColors: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default CropHeadingIcon;
