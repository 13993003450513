import EquipmentIconPicker from "equipment/components/EquipmentIconPicker";
import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";

import { GroupedButtons } from "components/fl-ui/Buttons";
import { FormGroup, Input, NumericInput, TextArea } from "components/fl-ui/Form";

const TractorForm = (props) => {
  const { formId, onChange, onSubmit } = props;
  const { fuelCapacity, horsepower, icon, isRented, make, model, name, notes, year } = props.formFields;
  const handleChange = (event, payload) => onChange(payload);
  const handleNumericChange = (event) => {
    const { name, value } = event.target;
    onChange({ [name]: value });
  };
  const selectedOwnership = isRented !== null ? { id: isRented ? "isRented" : "isOwned" } : null;

  return (
    <form id={formId} onSubmit={onSubmit}>
      <Row>
        <Col md={12} xs={12}>
          <FormGroup inputId="name" label="Name">
            <Input id="name" maxLength={45} name="name" onChange={handleChange} type="text" value={name} />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6} sm xs={12}>
          <FormGroup inputId="make" label="Make (optional)">
            <Input id="make" name="make" onChange={handleChange} type="text" value={make} />
          </FormGroup>
        </Col>

        <Col md={6} sm xs={12}>
          <FormGroup inputId="model" label="Model (optional)">
            <Input id="model" name="model" onChange={handleChange} type="text" value={model} />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6} sm xs={12}>
          <FormGroup inputId="horsepower" label="Horsepower (optional)">
            <NumericInput
              id="horsepower"
              min={0}
              name="horsepower"
              onChange={handleNumericChange}
              suffix="hp"
              type="integer"
              value={horsepower}
            />
          </FormGroup>
        </Col>

        <Col md={6} sm xs={12}>
          <FormGroup inputId="fuelCapacity" label="Fuel capacity (optional)">
            <NumericInput
              id="fuelCapacity"
              min={0}
              name="fuelCapacity"
              onChange={handleNumericChange}
              suffix="gal"
              type="integer"
              value={fuelCapacity}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6} sm xs={12}>
          <FormGroup inputId="year" label="Year (optional)">
            <NumericInput
              id="year"
              max={new Date().getFullYear() + 2}
              min={1900}
              name="year"
              onChange={handleNumericChange}
              step={1}
              type="integer"
              value={year}
            />
          </FormGroup>
        </Col>

        <Col md={6} sm xs={12}>
          <FormGroup label="Ownership (optional)">
            <GroupedButtons
              onClick={({ id }) => onChange({ isRented: id === "isRented" })}
              options={[
                { id: "isOwned", label: "Owned" },
                { id: "isRented", label: "Rented" },
              ]}
              selected={selectedOwnership}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={12} xs={12}>
          <FormGroup inputId="notes" label="Notes (optional)">
            <TextArea id="notes" name="notes" onChange={handleChange} style={{ minHeight: "200px" }} value={notes} />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={12} xs={12}>
          <FormGroup label="Icon (optional)">
            <EquipmentIconPicker iconGroup="tractor" onChange={(icon) => onChange({ icon })} value={icon} />
          </FormGroup>
        </Col>
      </Row>
    </form>
  );
};

TractorForm.propTypes = {
  formId: PropTypes.string.isRequired,
};

TractorForm.defaultProps = {};

export default TractorForm;
