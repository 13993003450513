import { css } from "aphrodite/no-important";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Row, Col } from "react-styled-flexboxgrid";

import useCommodities from "hooks/useCommodities";
import { API_VERSION } from "lib/ajax";

import { Card, CardHeader } from "components/fl-ui/Card";
import CropBadge from "components/fl-ui/CropBadge";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import styles from "fields/onboarding/onboardingStyles";

const BadgeContainer = ({ data }) => {
  const { getCommodityById } = useCommodities();
  const commodity = getCommodityById(data?.commodity_id[0]);

  return (
    <div className={css(styles.cropDataContainer)}>
      <CropBadge
        abbr={commodity?.abbr || data.name[0]}
        color={commodity?.color || "light-green"}
        marginRight="regular"
        size={40}
      />
      <div>
        <p className={css(styles.cropDataText, styles.cropDataName)}>{commodity?.name || data.name}</p>
        <p className={css(styles.cropDataText)}>{data.year}</p>
      </div>
    </div>
  );
};

const CropHistoryCard = ({ geometry }) => {
  const [cropHistoryData, setCropHistoryData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    fetch(`${API_VERSION}/cdl?${new URLSearchParams({ geometry: JSON.stringify(geometry) })}`, {
      method: "GET",
      signal,
    })
      .then((response) =>
        response.json().then((history) => {
          const data = history.filter(({ ignore }) => !ignore);
          setCropHistoryData(data);
          setLoading(false);
        })
      )
      .catch((error) => {
        if (error.name !== "AbortError") {
          throw error;
        }
      });

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <Card className={css(styles.card)}>
      <CardHeader className={css(styles.bottomBorder, styles.cardHeader)}>
        <h2>Crop history</h2>
      </CardHeader>

      <LoadingWrapper isLoading={loading && !cropHistoryData?.length}>
        <Row>
          <Col xs md={6}>
            {cropHistoryData.slice(0, 3).map((data) => (
              <BadgeContainer data={data} key={`${data.name}-${data.year}`} />
            ))}
          </Col>

          <Col xs md={6}>
            {cropHistoryData.slice(3, 6).map((data) => (
              <BadgeContainer data={data} key={`${data.name}-${data.year}`} />
            ))}
          </Col>
        </Row>
      </LoadingWrapper>
    </Card>
  );
};

CropHistoryCard.propTypes = {
  geometry: PropTypes.shape({
    coordinates: PropTypes.array,
    type: PropTypes.string,
  }).isRequired,
};

export default CropHistoryCard;
