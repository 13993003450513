import Crud from "crud";
import $ from "jquery";
import React from "react";
import { createRoot } from "react-dom/client";
import render from "resources/templates";
import _ from "underscore";

import bins from "collection/bins";
import App from "layout/app";
import {
  BIN_DETAIL_DELETE,
  BIN_DETAIL_MODAL_CANCEL,
  BIN_DETAIL_MODAL_OPEN_BIN_EDIT,
  BIN_DETAIL_UPDATE_SAVE,
} from "lib/metrics/events";

import Autocomplete from "components/ui/autocomplete";

export default Crud.extend(
  {
    events: _.extend({}, Crud.prototype.events, {
      "click .js-cancel": "close",
      "click .js-close": "close",
      "click .js-delete"() {
        BIN_DETAIL_DELETE.track();
        return this.delete.apply(this, arguments);
      },
      remove: "remove",
      submit: "save",
    }),

    initialize() {
      BIN_DETAIL_MODAL_OPEN_BIN_EDIT.track();
      if (!this.model) {
        this.model = new bins.model();
      }
      return Crud.prototype.initialize.call(this);
    },

    render() {
      const data = this.model.toJSON();
      data.is_new = this.model.isNew();

      this.setElement(render("bin", data));

      return App.dialog(this);
    },

    selectGroup(name) {
      return $("input[name=group]").val(name).change();
    },

    setUI() {
      const groups = this.collection.getGroups().map((group) => ({
        id: group,
        name: group,
      }));

      // Render the filter picker. When a group is selected or added, set the
      // group ID to be the value of the existing hidden input so no other code
      // needs to be changed.

      const container = this.$(".js-group-picker")[0];
      const root = createRoot(container);

      root.render(
        <Autocomplete
          options={groups}
          value={$("input[name=group]").val()}
          onSelect={(group) => {
            this.selectGroup(group.name);
            return this.setUI();
          }}
          onCreate={(name) => {
            this.selectGroup(name);
            return this.setUI();
          }}
        />
      );
    },

    getData() {
      return {
        name: this.$('[name="name"]').val(),
        capacity: +this.$('[name="capacity"]').val() || this.$('[name="capacity"]').val(),
        notes: this.$('[name="notes"]').val(),
        group: this.$('[name="group"]').val(),
        rented: this.$('[name="rented"]:checked').val().length,
      };
    },

    close() {
      BIN_DETAIL_MODAL_CANCEL.track();
      this.cancel();
      return this.$el.remove();
    },

    save(event) {
      event.preventDefault();
      BIN_DETAIL_UPDATE_SAVE.track();
      return Crud.prototype.save.call(this);
    },
  },
  { collection: bins }
);
