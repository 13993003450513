import { GET_CURRENT_USER } from "collection/graphql/auth/queries";
import cache from "collection/graphql/cache";

/**
 * Fails the query if the user is not currently authenticated.
 */
export const authLink = (operation, forward) => {
  const isAuthenticated = !!cache.readQuery({ query: GET_CURRENT_USER })?.currentUser;
  if (!isAuthenticated) {
    throw new Error("Unable to execute query if not authenticated");
  }

  return forward(operation);
};
