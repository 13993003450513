import _ from "lodash";
import numeral from "numeral";

/**
 * Takes a value and formats it as a currency.
 * If the value is null, undefined, Infinity, NaN, or a non-numeric string the function will return "--".
 * If the value is less than zero, the negative sign will be prepended to the formatted string.
 *
 * @param {number} amount The amount that will be null checked and formatted
 * @param {number} [decimalPlaces=2]; The amount of decimal places to display, default of 2
 * @return {string}
 */

const formatCurrency = (amount, decimalPlaces = 2) => {
  if (_.isNaN(amount) || _.isNil(amount) || !_.isFinite(parseInt(amount, 10))) {
    return "--";
  }

  const baseFormattingString = "$0,0.";
  const formattingStringWithDecimalPlaces = baseFormattingString.padEnd(
    decimalPlaces + baseFormattingString.length,
    "0"
  );
  const formattedString = numeral(Math.abs(amount)).format(formattingStringWithDecimalPlaces);
  return amount < 0 ? "-" + formattedString : formattedString;
};
export default formatCurrency;
