import _ from "lodash";

const QueryTypePolicy = {
  fields: {
    getActivity: (_, { args, toReference }) => toReference({ id: args.id, __typename: "Activity" }),
    getCashContracts: {
      keyArgs: false,
      merge(existing, incoming) {
        return {
          contracts: _.uniqBy((existing?.contracts ?? []).concat(incoming.contracts), "__ref"),
          totalContractsFiltered: incoming.totalContractsFiltered,
          totalContracts: incoming.totalContracts,
          __typename: "CashContractCollection",
        };
      },
    },
    getCommodity: (_, { args, toReference }) => toReference({ id: args.id, __typename: "Commodity" }),
    getField: (_, { args, toReference }) => toReference({ id: args.fieldId, __typename: "Field" }),
    getMachineDataProductMatch: (_, { args, toReference }) =>
      toReference({ id: args.id, __typename: "MachineDataProductMatch" }),
    getNoteById: (_, { args: { id }, toReference }) => toReference({ id, __typename: "Note" }),
  },
};

export default QueryTypePolicy;
