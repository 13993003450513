import _ from "lodash";

const transformHistoricalWeatherReadings = (readings, precision) => {
  let total = 0;
  const multiplier = precision * 10;
  const transformed = _.map(readings, ({ amount, day, month }) => {
    total += amount * multiplier;
    return {
      amount,
      day,
      month,
      total: +(total / multiplier).toFixed(precision),
    };
  });

  return _.sortBy(transformed, "date");
};

export default transformHistoricalWeatherReadings;
