/**
 * Normalizes an ingredient's rate.
 */
function normalizeRecipeRate(rateToConvert) {
  const legacyRateMap = {
    "per-acre": "PER_ACRE",
    total: "TOTAL",
  };

  if (rateToConvert in legacyRateMap) {
    return legacyRateMap[rateToConvert];
  } else if (Object.values(legacyRateMap).includes(rateToConvert)) {
    return rateToConvert;
  }

  throw new Error(`Unexpected product recipe rate "${rateToConvert}"`);
}

export default normalizeRecipeRate;
