import { StyleSheet, css } from "aphrodite/no-important";
import PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";
import { Manager, Reference, Popper } from "react-popper";

import { popoverStyles, ARROW_SIZE, BORDER_COLOR, BACKGROUND_COLOR } from "components/fl-ui/Popover/popoverStyles";
import { Mixins } from "components/fl-ui/constants";

const containerStyle = StyleSheet.create({
  top: {
    marginBottom: Mixins.toRem(ARROW_SIZE),
  },
  bottom: {
    marginTop: Mixins.toRem(ARROW_SIZE),
  },
  left: {
    marginRight: Mixins.toRem(ARROW_SIZE),
  },
  right: {
    marginLeft: Mixins.toRem(ARROW_SIZE),
  },
});
const arrowStyle = StyleSheet.create({
  base: {
    position: "absolute",
    width: 0,
    height: 0,
  },
  top: {
    bottom: `-${ARROW_SIZE - 2}px`,
    borderTop: `${ARROW_SIZE}px solid ${BACKGROUND_COLOR}`,
    borderBottom: `0 solid transparent`,
    borderLeft: `${ARROW_SIZE}px solid transparent`,
    borderRight: `${ARROW_SIZE}px solid transparent`,
  },
  bottom: {
    top: `-${ARROW_SIZE - 2}px`,
    borderTop: `0 solid transparent`,
    borderBottom: `${ARROW_SIZE}px solid ${BACKGROUND_COLOR}`,
    borderLeft: `${ARROW_SIZE}px solid transparent`,
    borderRight: `${ARROW_SIZE}px solid transparent`,
  },
  left: {
    right: `-${ARROW_SIZE - 1}px`,
    borderTop: `${ARROW_SIZE}px solid transparent`,
    borderBottom: `${ARROW_SIZE}px solid transparent`,
    borderLeft: `${ARROW_SIZE}px solid ${BACKGROUND_COLOR}`,
    borderRight: `0 solid transparent`,
  },
  right: {
    left: `-${ARROW_SIZE - 1}px`,
    borderTop: `${ARROW_SIZE}px solid transparent`,
    borderBottom: `${ARROW_SIZE}px solid transparent`,
    borderLeft: `0 solid transparent`,
    borderRight: `${ARROW_SIZE}px solid ${BACKGROUND_COLOR}`,
  },
});
const outlineStyle = StyleSheet.create({
  top: {
    bottom: `-${ARROW_SIZE}px`,
    borderTop: `${ARROW_SIZE}px solid ${BORDER_COLOR}`,
  },
  bottom: {
    top: `-${ARROW_SIZE}px`,
    borderBottom: `${ARROW_SIZE}px solid ${BORDER_COLOR}`,
  },
  left: {
    right: `-${ARROW_SIZE}px`,
    borderLeft: `${ARROW_SIZE}px solid ${BORDER_COLOR}`,
  },
  right: {
    left: `-${ARROW_SIZE}px`,
    borderRight: `${ARROW_SIZE}px solid ${BORDER_COLOR}`,
  },
});

class Popover extends React.Component {
  state = { show: false };

  componentWillUnmount() {
    document.removeEventListener("click", this.hide);
    document.removeEventListener("click", this.hideConditionally);
  }

  show = () => {
    if (!this.state.show) {
      const once = true;
      document.addEventListener(
        "click",
        () => {
          if (this.props.persist) {
            document.addEventListener("click", this.hideConditionally);
          } else {
            document.addEventListener("click", this.hide);
          }
          this.setState({ show: true });
        },
        { once }
      );
    }
  };

  hideConditionally = (e) => {
    const content = document.getElementById("popover-content");
    if (!content.contains(e.target)) {
      document.removeEventListener("click", this.hideConditionally);
      this.setState({ show: false });
      if (typeof this.props.onClose === "function") {
        this.props.onClose();
      }
    }
  };

  hide = () => {
    document.removeEventListener("click", this.hide);
    this.setState({ show: false });
    if (typeof this.props.onClose === "function") {
      this.props.onClose();
    }
  };

  render() {
    const { children, content, persist, placement, target, ...props } = this.props;
    const simplify = (p) => {
      if (typeof p === "string") {
        if (p.indexOf("-") >= 0) {
          return p.substring(0, p.indexOf("-"));
        }
        return p;
      }
    };
    return (
      <Manager>
        <Reference>
          {({ ref }) => (
            <span role="button" ref={ref} onClick={this.show} onKeyDown={() => {}} {...props}>
              {children || target}
            </span>
          )}
        </Reference>
        {this.state.show &&
          ReactDOM.createPortal(
            <Popper placement={placement}>
              {({ ref, style, arrowProps, placement }) => (
                <div
                  className={css([popoverStyles.popover, containerStyle[simplify(placement)]])}
                  ref={ref}
                  role="button"
                  style={style}
                >
                  <div id="popover-content">{content}</div>
                  <div
                    className={css(arrowStyle.base, arrowStyle[simplify(placement)], outlineStyle[simplify(placement)])}
                    ref={arrowProps.ref}
                    style={arrowProps.style}
                  />
                  <div
                    className={css(arrowStyle.base, arrowStyle[simplify(placement)])}
                    ref={arrowProps.ref}
                    style={arrowProps.style}
                  />
                </div>
              )}
            </Popper>,
            document.querySelector("#dialog")
          )}
      </Manager>
    );
  }
}

Popover.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  persist: PropTypes.bool,
  placement: PropTypes.oneOf([
    "top",
    "top-start",
    "top-end",
    "bottom",
    "bottom-start",
    "bottom-end",
    "left",
    "left-start",
    "left-end",
    "right",
    "right-start",
    "right-end",
  ]),
  target: PropTypes.any,
};

Popover.defaultProps = {
  placement: "bottom-end",
};

export default Popover;
