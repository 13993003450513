/* eslint-disable react/display-name */
import { BasicInput } from "agreements/forms/components";
import MicroLabel from "agreements/forms/components/MicroLabel";
import { css } from "aphrodite";
import _ from "lodash";
import { LeftButton } from "profit_and_loss/styles";
import React, { Component, Fragment } from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import router from "router";
import styled from "styled-components";

import { withField, withUpdateField } from "collection/graphql/agreements";
import { wrapWithProvider, marketingClient } from "collection/graphql/client";
import useCommodities from "hooks/useCommodities";
import { withPermissions } from "hooks/usePermissions";
import App from "layout/app";
import { PER_ACRE, TOTAL, UPDATE_FIELD_INPUT } from "modules/agreements/constants";

import CommodityChooser from "components/commodity/CommodityChooser";
import { Button } from "components/fl-ui";
import { GroupedButtons } from "components/fl-ui/Buttons";
import Dialog from "components/fl-ui/Dialog";
import { FormGroup, Input, RateSelector } from "components/fl-ui/Form";
import { CloseX } from "components/fl-ui/Icons";
import { Modal, ModalHeader, ModalTitle, ModalBody, ModalFooter } from "components/fl-ui/Modal/Modal";
import ModalActionButtonGroup from "components/fl-ui/Modal/ModalActionButtonGroup";
import { UIColors } from "components/fl-ui/colors";
import { Spacing } from "components/fl-ui/constants";
import CommodityYieldUnit from "components/units/CommodityYieldUnit";
import { styles } from "fields/common";
import FieldGroupChooser from "fields/components/FieldGroupChooser";
import useFieldData from "fields/hooks/useFieldData";
import { validate } from "fields/validators/fieldEditFormValidator";

const CommodityContainer = styled.div`
  @media only screen and (max-width: 62.5rem) {
    margin-bottom: ${Spacing.regular};
  }
`;

const withCommodities = (Component) => (props) => {
  const { commodities } = useCommodities();
  return <Component {...props} commodities={commodities} />;
};

const withFieldDelete = (Component) => (props) => {
  const { executeFieldDelete } = useFieldData();
  return <Component {...props} onDelete={executeFieldDelete} />;
};

const withFieldOperations = (Component) => withUpdateField(withField(withFieldDelete(Component)));

class FieldEditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDelete: false,
      isSaving: false,
      loaded: false,
      errors: {},
      landValue: "",
      landValueRate: PER_ACRE,
      productionHistory: [],
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {
      data: { field, loading },
    } = props;
    if (!_.isEmpty(field) && !loading && !state.loaded) {
      state = {
        ...state,
        ...field,
        loaded: true,
      };

      state.landValue = field.landValueRate === TOTAL ? field.landValueTotal : field.landValuePerAcre;
      state.landValueRate = state.landValueRate || PER_ACRE;
      state.productionHistory = _.map(field.productionHistory, ({ commodity, yieldPerAcre }) => ({
        commodityId: commodity.id,
        yieldPerAcre,
      }));
    }

    return state;
  }

  getBooleanOptions = (trueLabel, falseLabel) =>
    [true, false].map((option) => ({
      id: `${trueLabel}-${option}`,
      label: _.upperFirst(option ? trueLabel : falseLabel),
      value: option,
    }));

  handleProductionHistoryChange = (value, index) => {
    if (_.isNil(index)) {
      this.setState({ inputs: [...this.state.inputs, input], isAddingInput: false });
      this.setState(({ productionHistory }) => ({ productionHistory: [...productionHistory, value] }));
    } else {
      const productionHistory = [...this.state.productionHistory];
      productionHistory[index] = { ...productionHistory[index], ...value };
      this.setState({ productionHistory });
    }
  };

  handleProductionHistoryRemove = (index) => {
    this.setState(({ productionHistory }) => ({
      productionHistory: productionHistory.filter((history, idx) => idx !== index),
    }));
  };

  onDeleteField = async () => {
    const { fieldId, onClose, onDelete } = this.props;

    try {
      await onDelete([fieldId]);
      this.setState({ isSaving: false });

      if (location.hash !== "#fields") {
        router.navigate("fields", { replace: true, trigger: true });
        return null;
      }

      onClose();
    } catch (error) {
      this.setState({ isSaving: false });
      App.notify("An unexpected error occurred");
      throw error;
    }
  };

  onSave = async () => {
    const { createGroup, onClose, updateField } = this.props;
    const { group, ...data } = this.state;

    if (!_.isEmpty(group)) {
      if (group.id) {
        data.fieldGroupId = group.id;
      } else {
        const result = await createGroup(group.name);
        data.fieldGroupId = result.id;
      }
    } else if (_.isNull(group)) {
      data.fieldGroupId = null;
    }

    const fieldData = _.pick(data, UPDATE_FIELD_INPUT);
    fieldData.landValue = fieldData.landValue || null;
    fieldData.landValueRate = _.isNull(fieldData.landValue) ? null : fieldData.landValueRate;

    const errors = validate(fieldData);

    if (_.isEmpty(errors)) {
      await updateField(fieldData);
      onClose();
      return;
    }

    this.setState({ errors, isSaving: false });
  };

  handleDelete = () => this.setState({ isSaving: true }, this.onDeleteField);
  handleSave = () => this.setState({ isSaving: true }, this.onSave);

  render() {
    const { canRead, commodities, onClose } = this.props;
    const {
      confirmDelete,
      acreage,
      errors,
      fsaFarm,
      fsaTract,
      group,
      isSaving,
      landValue,
      landValueRate,
      loaded,
      name,
      productionHistory,
    } = this.state;

    const getPrimaryButtonText = () => {
      if (!loaded) return "Loading...";
      if (isSaving) return "Saving...";
      return "Save field details";
    };

    const irrigatedOptions = this.getBooleanOptions("irrigated", "not irrigated");
    const rentedOptions = this.getBooleanOptions("rented", "owned");
    const tileOptions = this.getBooleanOptions("drainage tile", "no drainage tile");

    const BooleanButtons = ({ attribute, footer, options, title }) => (
      <Col xs={12} md={6}>
        <GroupedButtons
          onClick={({ value }) => this.setState({ [attribute]: value })}
          options={options}
          selected={options.find((option) => option.value === !!this.state[attribute])}
          style={{ borderBottom: "none", marginBottom: Spacing.xxsmall }}
          title={title}
          titleStyle={{ marginBottom: ".4rem" }}
        />

        {footer}
      </Col>
    );

    return confirmDelete ? (
      <Dialog
        dialogBody={`By deleting the field "${name}" you will delete all data associated with the field including crops and activities`}
        dialogControls={
          <>
            <LeftButton color="primary" link onClick={onClose}>
              Cancel
            </LeftButton>
            <Button color="danger" disabled={isSaving} onClick={this.handleDelete}>
              Confirm
            </Button>
          </>
        }
        dialogHeading="Are you sure?"
        onClose={onClose}
      />
    ) : (
      <Modal width={640}>
        <ModalHeader>
          <ModalTitle>Edit field details</ModalTitle>
          <CloseX onClick={onClose} />
        </ModalHeader>

        <ModalBody>
          <Row>
            <Col xs={12} md={6}>
              <FormGroup label="Name">
                <Input
                  defaultValue={name || ""}
                  hasError={!!errors.name}
                  name="name"
                  onChange={_.debounce((e, data) => this.setState(data), 500)}
                  size="large"
                />
              </FormGroup>
            </Col>

            <Col xs={12} md={6}>
              <FormGroup label="Group (optional)">
                <FieldGroupChooser onChange={(group) => this.setState({ group })} value={group && group.id} />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6}>
              <FormGroup label="Acreage">
                <Input
                  defaultValue={_.isNil(acreage) ? "" : (+acreage).toFixed(1)}
                  hasError={!!errors.acreage}
                  name="acreage"
                  onChange={_.debounce((e, data) => this.setState(data), 500)}
                  size="large"
                  suffix="ac"
                  type="number"
                />
              </FormGroup>
            </Col>

            <BooleanButtons attribute="isIrrigated" options={irrigatedOptions} title="Is this field irrigated?" />
          </Row>

          <Row>
            <BooleanButtons attribute="isTiled" options={tileOptions} title="Does this field have drainage tile?" />
            <Col xs={12} md={6}>
              <FormGroup label="FSA tract (optional)">
                <Input
                  defaultValue={fsaTract || ""}
                  name="fsaTract"
                  onChange={_.debounce((e, data) => this.setState(data), 500)}
                  size="large"
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6}>
              <FormGroup label="FSA farm (optional)">
                <Input
                  defaultValue={fsaFarm || ""}
                  name="fsaFarm"
                  onChange={_.debounce((e, data) => this.setState(data), 500)}
                  size="large"
                />
              </FormGroup>
            </Col>

            <BooleanButtons
              attribute="isRented"
              footer={
                <a className={css(styles.linkText)} href="#land_costs" rel="noopener noreferrer" target="_blank">
                  Add more details with land costs
                </a>
              }
              options={rentedOptions}
              title="Ownership"
            />
          </Row>

          {canRead("agreements") && (
            <Fragment>
              <Row>
                <Col xs md={6}>
                  <FormGroup label="Estimated land value (optional)">
                    <Input
                      display="block"
                      hasError={!!errors.landValue}
                      name="landValue"
                      onChange={({ target }) => this.setState({ landValue: parseFloat(target.value) || null })}
                      prefix="$"
                      size="large"
                      suffix={
                        <RateSelector
                          onClick={(landValueRate) => this.setState({ landValueRate })}
                          selected={landValueRate}
                        />
                      }
                      type="number"
                      value={!landValue ? "" : landValue}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <FormGroup label="Actual production history (optional)">
                {productionHistory.map((history, index) => {
                  const selected = _.find(commodities, ({ id }) => +id === +history.commodityId);

                  return (
                    <Row className={css(styles.aphRow)} key={`${index}-${history.commodityId}`}>
                      <Col xs={10} md={11}>
                        <Row>
                          <Col xs={12} md={8}>
                            <CommodityContainer>
                              <CommodityChooser
                                commodityId={+history.commodityId}
                                onChange={({ id }) => this.handleProductionHistoryChange({ commodityId: id }, index)}
                                placeholder="Select commodity"
                                sanitizer={(item) => ({
                                  ...item,
                                  isDisabled: productionHistory.some(({ commodityId }) => +commodityId === +item.id),
                                  value: item.id,
                                })}
                              />
                            </CommodityContainer>
                          </Col>

                          <Col xs={12} md={4}>
                            <MicroLabel
                              className={css(
                                styles.blank_content,
                                (errors.productionHistory || []).includes(index) && styles.blank_contentError
                              )}
                              label="APH"
                            >
                              <BasicInput
                                name="yieldPerAcre"
                                onChange={(event, value) => this.handleProductionHistoryChange(value, index)}
                                suffix={<CommodityYieldUnit commodity={selected} per="acre" />}
                                type="integer"
                                value={history.yieldPerAcre}
                              />
                            </MicroLabel>
                          </Col>
                        </Row>
                      </Col>

                      <Col xs={2} md={1} className={css(styles.centerVertically)}>
                        <CloseX color={UIColors.red} onClick={() => this.handleProductionHistoryRemove(index)} />
                      </Col>
                    </Row>
                  );
                })}

                <Button
                  className={css(styles.aphButton)}
                  color="secondary"
                  disabled={commodities?.length <= productionHistory.length}
                  onClick={() =>
                    this.setState(({ productionHistory }) => ({
                      productionHistory: [...productionHistory, { commodityId: "", yieldPerAcre: "" }],
                    }))
                  }
                >
                  {`Add APH ${_.isEmpty(productionHistory) ? "" : "for another commodity"}`}
                </Button>
              </FormGroup>
            </Fragment>
          )}
        </ModalBody>

        <ModalFooter>
          <ModalActionButtonGroup
            apply={{ action: this.handleSave, buttonText: getPrimaryButtonText(), disabled: !loaded || isSaving }}
            cancel={{ action: onClose, buttonText: "Cancel" }}
            danger={{
              action: () => this.setState({ confirmDelete: true }),
              buttonText: "Delete",
              disabled: !loaded || isSaving,
            }}
          />
        </ModalFooter>
      </Modal>
    );
  }
}

export default wrapWithProvider(withPermissions(withFieldOperations(withCommodities(FieldEditModal))), marketingClient);
