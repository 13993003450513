import { gql } from "@apollo/client";

const FieldStatsFragment = gql`
  fragment FieldStatsFragment on Field {
    acreage
    id
  }
`;

export default FieldStatsFragment;
