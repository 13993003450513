import { subscriptionFragment } from "./fragments";
import { gql } from "@apollo/client";

export default gql`
  query getCurrentSubscription {
    subscription: getCurrentSubscription @rest(type: "Subscription", path: "/subscriptions/subscription") {
      ${subscriptionFragment}
    }
  }
`;
