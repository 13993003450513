import { gql } from "@apollo/client";

import { COMMODITY } from "collection/graphql/commodities/fragments";

export default gql`
  fragment BasicUnderlying on Underlying {
    commodity {
      ...Commodity
    }
    currentMarketPrice
    expirationDate
    fullName
    futuresExpirationDate
    optionsExpirationDate
    shortName
    symbol
  }

  ${COMMODITY}
`;
