import { gql } from "@apollo/client";

/**
 * @type {MinimalListedActivity}
 */
export default gql`
  fragment MinimalListedActivity on Activity {
    id
    acreage
    date
    geometry
    geometricCircumference
    isAAR
    isMachineData
    isWorkOrder
    machineDataProviders
    status
    totalCostPerAcre
    totalCostTotal
    crop {
      id
      acreage
      commodity {
        id
        name
      }
      cropYear
    }
    field {
      id
      acreage
      name
    }
    # TODO adding implements significantly slows down this query. explore options.
    implements {
      id
      name
    }
    inputs {
      ...ActivityInputDetail
    }
    people {
      id
      name
    }
    # TODO adding tractors significantly slows down this query. explore options.
    tractors {
      id
      name
    }
    type {
      id
      category
      name
    }
  }
`;

/**
 * An Activity object with the minimal fields needed for the activity index page.
 *
 * @typedef {Object} MinimalListedActivity
 * @property {Number} acreage
 * @property {MinimalActivityCrop} crop
 * @property {String} date
 * @property {MinimalActivityField} field
 * @property {Number} id
 * @property {MinimalActivityInput[]} inputs
 * @property {Boolean} isAAR
 * @property {Boolean} isCancelled
 * @property {Boolean} isDone
 * @property {Boolean} isMachineData
 * @property {Boolean} isOverdue
 * @property {Boolean} isPlanned
 * @property {Boolean} isWorkOrder
 * @property {"CANCELLED"|"DONE"|"PLANNED"} status
 * @property {"Cancelled"|"Done"|"Overdue"|"Planned"} statusText
 * @property {Number} totalCostPerAcre
 * @property {Number} totalCostTotal
 * @property {MinimalActivityType} type
 */

/**
 * @typedef {Object} MinimalActivityCommodity
 * @property {Number} id
 * @property {String} name
 */

/**
 * @typedef {Object} MinimalActivityCrop
 * @property {Number} acreage
 * @property {MinimalActivityCommodity} commodity
 * @property {Number} id
 */

/**
 * @typedef {Object} MinimalActivityField
 * @property {Number} acreage
 * @property {Number} id
 * @property {String} name
 */

/**
 * @typedef {Object} MinimalActivityInput
 * @property {Number} id
 * @property {MinimalActivityProduct} product
 */

/**
 * @typedef {Object} MinimalActivityProduct
 * @property {Number} id
 * @property {String} name
 */

/**
 * @typedef {Object} MinimalActivityType
 * @property {String} category
 * @property {Number} id
 * @property {String} name
 */
