import CropForm from "marketing/forms/MarketedCropAddEdit/CropForm";
import contractEnums from "marketing/utils/contractEnums";
import PropTypes from "prop-types";
import React from "react";

import { useMarketingPageContext } from "context/MarketingPageContext";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";

import MarketingBlankStateModal from "components/advertisements/modals/MarketingBlankStateModal";
import CloseX from "components/fl-ui/Icons/CloseX";
import ErrorBoundary, { ErrorComponent } from "components/fl-ui/Layout/ErrorBoundary";
import { Modal, ModalBody, ModalHeader, ModalTitle } from "components/fl-ui/Modal/Modal";

const CropEditModal = ({ marketedCrops, onClose, onSaveSuccess }) => {
  const { closeCropEditModal, marketedCropModalData } = useMarketingPageContext();

  const modalClose = !closeCropEditModal ? onClose : closeCropEditModal;
  const modalData = !marketedCropModalData && marketedCrops ? marketedCrops[0] : marketedCropModalData;

  const action = marketedCropModalData?.id ? contractEnums.EDIT_MARKETED_CROP : contractEnums.ADD_MARKETED_CROP;

  if (!useEnterpriseFeature("grain_marketing")) {
    return <MarketingBlankStateModal onClose={closeCropEditModal} />;
  }

  return (
    <Modal width={560}>
      <ModalHeader>
        <ModalTitle>
          {action === contractEnums.ADD_MARKETED_CROP
            ? `Add ${modalData?.year || ""} crop`
            : `Edit ${marketedCropModalData?.year} ${marketedCropModalData?.name} details`}
        </ModalTitle>

        <CloseX onClick={modalClose} />
      </ModalHeader>

      <ModalBody>
        <ErrorBoundary fallback={() => <ErrorComponent compact />}>
          <CropForm
            marketedCrop={modalData}
            marketedCrops={marketedCrops}
            onCancel={modalClose}
            onSaveSuccess={onSaveSuccess || modalClose}
          />
        </ErrorBoundary>
      </ModalBody>
    </Modal>
  );
};

CropEditModal.propTypes = {
  marketedCrops: PropTypes.arrayOf(PropTypes.object),
  onSaveSuccess: PropTypes.func,
  onClose: PropTypes.func,
};

export default CropEditModal;
