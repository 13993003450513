import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const Stack = styled.div`
  > * {
    margin: 0;
  }
  > * + * {
    margin-top: ${(props) => props.space};
  }
`;

Stack.propTypes = {
  space: PropTypes.any,
};

Stack.defaultProps = {
  space: "1rem",
};

export default Stack;
