import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

const EDIT_CROP_YIELD = gql`
  mutation EditCropYield($crop: UpdateFieldCropInput!) {
    updateFieldCrop(crop: $crop) {
      ok
      crop {
        id
        field {
          id
        }
        cropYear
        yieldTotal
        yieldPerAcre
        yieldUnit
        yieldStoredRate
      }
    }
  }
`;

export default graphql(EDIT_CROP_YIELD, {
  props: ({ mutate }) => ({
    editCropYield: (variables) => mutate({ variables }),
  }),
  options: { refetchQueries: ["GetProfitAndLossForYear"] },
});
