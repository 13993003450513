import PropTypes from "prop-types";
import React from "react";

import { TagSelectGroup } from "components/fl-ui/Form";

const ContractStatusFilter = ({ onChange, value }) => {
  return (
    <TagSelectGroup
      id="contractStatusFilter"
      label="Status"
      onChange={(contractStatus) => onChange(contractStatus)}
      options={[
        {
          label: "Open",
          value: "OPEN",
        },
        {
          label: "Closed",
          value: "CLOSED",
        },
      ]}
      showBulkSelect
      value={value}
    />
  );
};

ContractStatusFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired,
};

export default ContractStatusFilter;
