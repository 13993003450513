import { css, StyleSheet } from "aphrodite/no-important";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import Icon from "components/fl-ui/Icons/Icon";
import { UIColors } from "components/fl-ui/colors/index";

const linkStyles = {
  color: UIColors.regular,
  display: "inline",
  paddingBottom: 0,
  textDecoration: "underline",
};

const styles = StyleSheet.create({
  wrapper: {
    display: "flex",
    marginBottom: 0,
  },
  rightArrow: {
    display: "inline-block",
    color: UIColors.medium,
    height: ".8em",
    width: ".8em",
    margin: "0 .15em",
  },
  link: {
    color: UIColors.regular,
    textDecoration: "none",
    "&": {
      ...linkStyles,
    },
    ":hover": {
      ...linkStyles,
    },
  },
  pageName: {
    color: UIColors.dark,
    display: "inline",
    paddingBottom: 0,
  },
});

const BreadCrumbs = ({ links, className, linkClassName, pageNameClassName }) => (
  <div className={classNames(css(styles.wrapper), className)}>
    {links.map(({ enabled, label, link, onClick = null }, i) => {
      return (
        <div key={`${label}_${i}`}>
          {i !== 0 ? (
            <span className={css(styles.rightArrow)}>
              <Icon icon="chevronRight" />
            </span>
          ) : (
            ""
          )}
          {enabled ? (
            <a href={link} className={classNames(css(styles.link), linkClassName)} onClick={onClick}>
              {label}
            </a>
          ) : (
            <span className={classNames(css(styles.pageName), pageNameClassName)}>{label}</span>
          )}
        </div>
      );
    })}
  </div>
);

export const BreadCrumbLinkShape = PropTypes.shape({
  enabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  link: PropTypes.string,
  onClick: PropTypes.func,
});

BreadCrumbs.propTypes = {
  className: PropTypes.string,
  linkClassName: PropTypes.string,
  pageNameClassName: PropTypes.string,
  links: PropTypes.arrayOf(BreadCrumbLinkShape),
};

export default BreadCrumbs;
