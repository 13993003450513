import "segment";

if (process.env.SEGMENT_KEY) {
  window.analytics.load(process.env.SEGMENT_KEY);
}

export const isReady = new Promise((resolve) => {
  window.analytics.ready(() => {
    window.analytics.debug(false);
    resolve({
      segment: window.analytics,
    });
  });
});

/**
 * Logs a page view event by name
 * @param {String} name
 * @return {*}
 */
export const page = (name) => {
  return window.analytics.page(name, {
    path: window.location.hash,
    screenSize: {
      width: screen.width,
      height: screen.height,
      availWidth: screen.availWidth,
      availHeight: screen.availHeight,
    },
  });
};

/**
 * Logs a tracking event
 * @param {String} eventName
 * @return {*}
 */
export const track = (...args) => {
  window.analytics.track(...args);
};
