import { css } from "aphrodite";
import { round, sumBy } from "lodash";
import React, { Component, Fragment } from "react";
import { Grid, Row } from "react-styled-flexboxgrid";
import { ThemeProvider } from "styled-components";

import withCropMutation from "hoc/withCropMutation";

import { Button } from "components/fl-ui";
import Dialog from "components/fl-ui/Dialog";
import { Input } from "components/fl-ui/Form";
import Pane, { PaneContents } from "components/fl-ui/Layout/Pane";
import { styles, fieldCardGrid } from "fields/common";

const ChildContainer = ({ children }) => (
  <ThemeProvider theme={fieldCardGrid}>
    <Grid className={css(styles.fieldGroup_grid)} fluid>
      <Row>{children}</Row>
    </Grid>
  </ThemeProvider>
);

const DialogControls = ({ disabled, onCancel, onConfirm }) => (
  <>
    <Button color="primary" link onClick={onCancel}>
      Cancel
    </Button>
    <Button
      className={css(styles.fieldGroup_dialogPrimaryButton)}
      color="primary"
      disabled={disabled}
      onClick={onConfirm}
    >
      Confirm
    </Button>
  </>
);

class EditForm extends Component {
  state = {
    name: this.props.name,
  };

  handleChange = (e, obj) => {
    this.setState({
      name: obj.name,
    });
  };

  handleSave = () => {
    this.props.onSave(this.state.name);
  };

  render() {
    const { name } = this.state;

    return (
      <div className={css(styles.fieldGroup_edit)}>
        <Input id="name" name="name" onChange={this.handleChange} type="text" value={name} />
        <Button
          className={css(styles.fieldGroup_editButton)}
          color="primary"
          disabled={this.props.disabled || !name?.trim()}
          onClick={this.handleSave}
        >
          Save
        </Button>
        <Button className={css(styles.fieldGroup_editButton)} onClick={() => this.props.onCancel()}>
          Cancel
        </Button>
      </div>
    );
  }
}

class FieldGroup extends Component {
  state = {
    isCollapsed: false,
    isEditMode: false,
    isUpdating: false,
    showDeleteAlert: false,
    showDeleteWithFieldsAlert: false,
  };

  onPaneToggle = (isCollapsed) => {
    this.setState({ isCollapsed });
  };

  edit = () => {
    this.setState({
      isEditMode: true,
    });
  };

  editCancel = () => {
    this.setState({
      isEditMode: false,
    });
  };

  editSave = async (name) => {
    this.setState({ isUpdating: true });
    await this.props.onGroupUpdate(this.props.group.id, { name });
    this.setState({ isEditMode: false, isUpdating: false });
  };

  deleteClick = (withFieldDeletion) => {
    const key = withFieldDeletion ? "showDeleteWithFieldsAlert" : "showDeleteAlert";
    this.setState({ [key]: true });
  };

  hideDeleteAlert = () => {
    this.setState({
      showDeleteAlert: false,
    });
  };

  hideDeleteWithFieldsAlert = () => {
    this.setState({ showDeleteWithFieldsAlert: false });
  };

  processDelete = async (withFieldDeletion) => {
    this.setState({ isUpdating: true });
    await this.props.onDelete(this.props.group.id, withFieldDeletion);
    this.setState({ isUpdating: false, showDeleteAlert: false, showDeleteWithFieldsAlert: false });
  };

  render() {
    const { canEditGroup, children, createCrop, fields } = this.props;
    const group = this.props.group || { id: -1, name: "Ungrouped Fields" };

    if (fields.length > 0) {
      const { isCollapsed, isEditMode, isUpdating, showDeleteAlert, showDeleteWithFieldsAlert } = this.state;
      const isUngrouped = group.id === -1;
      const tooltip = `${isCollapsed ? "Expand" : "Collapse"} "${group.name}"`;
      const subtitle = !isEditMode && `${fields.length} fields · ${round(sumBy(fields, "acreage"))} acres`;
      const title = !isEditMode ? (
        group.name
      ) : (
        <EditForm disabled={isUpdating} name={group.name} onCancel={this.editCancel} onSave={this.editSave} />
      );

      const menuOptions = [
        {
          label: "Add crop to group",
          onSelect: () => createCrop(fields?.map(({ id }) => id)),
        },
        {
          label: "Rename group",
          onSelect: () => this.edit(),
        },
        {
          label: "Delete group",
          color: "danger",
          onSelect: () => this.deleteClick(false),
        },
        {
          label: `Delete group & ${fields.length} ${fields.length === 1 ? "field" : "fields"}`,
          color: "danger",
          onSelect: () => this.deleteClick(true),
        },
      ];

      const paneProps = {
        collapsible: true,
        menuOptions: isUngrouped || !canEditGroup ? null : menuOptions,
        onToggle: this.onPaneToggle,
        subtitle,
        title,
        tooltip,
      };

      return (
        <Fragment>
          <Pane {...paneProps}>
            <ChildContainer>{children}</ChildContainer>
          </Pane>

          {showDeleteAlert && (
            <Dialog
              dialogBody={`Delete the group \"${group.name}\", leaving all fields intact?`}
              dialogControls={
                <DialogControls
                  disabled={isUpdating}
                  onCancel={this.hideDeleteAlert}
                  onConfirm={() => this.processDelete(false)}
                />
              }
              dialogHeading="Are you sure?"
              onClose={this.hideDeleteAlert}
            />
          )}

          {showDeleteWithFieldsAlert && (
            <Dialog
              dialogBody={`Delete the group \"${group.name}\" and all its fields?`}
              dialogControls={
                <DialogControls
                  disabled={isUpdating}
                  onCancel={this.hideDeleteWithFieldsAlert}
                  onConfirm={() => this.processDelete(true)}
                />
              }
              dialogHeading="Are you sure?"
              onClose={this.hideDeleteWithFieldsAlert}
            />
          )}
        </Fragment>
      );
    }

    return (
      <PaneContents noIndent>
        <ChildContainer>{children}</ChildContainer>
      </PaneContents>
    );
  }
}

export default withCropMutation(FieldGroup);
