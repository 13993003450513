import Content from "prices/charts/components/FuturesContractHistoryTooltipContent";
import PropTypes from "prop-types";
import React from "react";
import { Bar, BarChart, CartesianGrid, Cell as ChartCell, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { GreyColors, UIColors } from "components/fl-ui/colors";

const FuturesContractHistoryCandlestickChart = ({ data, minHeight = 250, xAxisProps, yAxisProps }) => (
  <ResponsiveContainer minHeight={minHeight}>
    <BarChart data={data} margin={{ top: 5, bottom: 5 }}>
      <CartesianGrid isAnimationActive={false} stroke={GreyColors.smoke91} strokeDasharray="3 3" vertical={false} />
      <Bar dataKey="lowHigh" fill={UIColors.light} maxBarSize={1} xAxisId={1} />
      <Bar dataKey="openClose" maxBarSize={11} minPointSize={1} radius={[2.5, 2.5, 2.5, 2.5]} xAxisId={0}>
        {data.map(({ openClose, priceTimestamp }) => (
          <ChartCell key={priceTimestamp} fill={openClose[0] > openClose[1] ? "#8B0000" : UIColors.green} />
        ))}
      </Bar>
      <Tooltip content={<Content contentType="candlestick" />} cursor={false} formatter={(value) => value} />
      <XAxis {...xAxisProps} xAxisId={0} />
      <XAxis dataKey="priceTimestamp" hide xAxisId={1} />
      <YAxis {...yAxisProps} />
    </BarChart>
  </ResponsiveContainer>
);

FuturesContractHistoryCandlestickChart.propTypes = {
  data: PropTypes.array.isRequired,
  minHeight: PropTypes.number,
  xAxisProps: PropTypes.object.isRequired,
  yAxisProps: PropTypes.object.isRequired,
};

export default FuturesContractHistoryCandlestickChart;
