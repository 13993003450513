import { DatePicker } from "@farmlogs/fl-ui";
import _ from "lodash";
import ProductFilterChooser from "products/components/ProductFilterChooser";
import ProductVendorChooser from "products/components/ProductVendorChooser";
import sortOptions from "products/sortOptions";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import styled from "styled-components";

import useViewType from "hooks/useViewType";

import { Button } from "components/fl-ui";
import { ClearAllFormGroup, FormGroup, Select, TagSelectGroup } from "components/fl-ui/Form";
import { CloseX } from "components/fl-ui/Icons";
import { Cluster } from "components/fl-ui/Layout";
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "components/fl-ui/Modal/Modal";
import { Spacing, Typography } from "components/fl-ui/constants";

const RestrictedLabel = styled.span`
  font-size: ${Typography.sizes.rg};
  font-weight: ${Typography.weights.regular};
  left: ${Spacing.small};
  position: relative;
  top: -2px;
`;

const ProductFilterModal = (props) => {
  const { commodities, onChange, onClear, onClose, purchaseSummaries } = props;
  const [filters, setFilters] = useState(_.cloneDeep(props.filters));
  const showSortFilter = useViewType() === "mobile";

  const update = (newFilters) =>
    setFilters((filters) => ({
      ...filters,
      ...newFilters,
    }));

  const commodityOptions = _.map(commodities, ({ id, name }) => ({
    label: name,
    value: id,
  }));

  const productTypeOptions = useMemo(
    () => [
      { label: "Chemical", value: "CHEMICAL" },
      { label: "Fertilizer", value: "FERTILIZER" },
      { label: "Seed", value: "SEED" },
      { label: "Water", value: "WATER" },
    ],
    []
  );

  const handleApply = () => onChange(filters);

  return (
    <Modal>
      <ModalHeader>
        <ModalTitle>Filters</ModalTitle>
        <CloseX onClick={onClose} />
      </ModalHeader>

      <ModalBody>
        {showSortFilter && (
          <Row>
            <Col xs>
              <FormGroup label="Sort by">
                <Select
                  name="sortCriteria"
                  onChange={(event, { sortCriteria }) => update({ sortCriteria })}
                  options={sortOptions}
                  value={filters.sortCriteria}
                />
              </FormGroup>
            </Col>
          </Row>
        )}

        <Row>
          <Col xs>
            <TagSelectGroup
              id="productTypes"
              isMulti
              label="Product type"
              onChange={(productTypes) => update({ productTypes })}
              options={productTypeOptions}
              showBulkSelect
              value={filters.productTypes}
            />
          </Col>
        </Row>

        <Row>
          <Col xs>
            <ClearAllFormGroup label="Product name" onClear={() => update({ product: null })}>
              <ProductFilterChooser
                onChange={(product) => update({ product })}
                purchaseSummaries={purchaseSummaries}
                value={filters.product}
              />
            </ClearAllFormGroup>
          </Col>
        </Row>

        <Row>
          <Col xs>
            <ClearAllFormGroup label="Vendor" onClear={() => update({ vendor: null })}>
              <ProductVendorChooser onChange={(vendor) => update({ vendor })} value={filters.vendor} />
            </ClearAllFormGroup>
          </Col>
        </Row>

        <Row>
          <Col xs>
            <TagSelectGroup
              id="commodities"
              isMulti
              label="Crops"
              onChange={(commodities) => update({ commodities })}
              options={commodityOptions}
              showBulkSelect
              value={filters.commodities}
            />
          </Col>
        </Row>

        <Row>
          <Col xs md={6}>
            <ClearAllFormGroup label="Start date" onClear={() => update({ startDate: null })}>
              <DatePicker onChange={(startDate) => update({ startDate })} value={filters.startDate} />
            </ClearAllFormGroup>
          </Col>

          <Col xs md={6}>
            <ClearAllFormGroup label="End date" onClear={() => update({ endDate: null })}>
              <DatePicker onChange={(endDate) => update({ endDate })} value={filters.endDate} />
            </ClearAllFormGroup>
          </Col>
        </Row>

        <Row>
          <Col xs>
            <label>
              <input
                checked={filters.restrictedUse}
                name="restrictedUse"
                onChange={({ target }) => update({ restrictedUse: target.checked })}
                type="checkbox"
                value={filters.restrictedUse}
              />

              <RestrictedLabel>Show only restricted use</RestrictedLabel>
            </label>
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter>
        <Cluster between>
          <Button color="danger" link onClick={onClear} type="button">
            Clear filters
          </Button>

          <span>
            <Button color="default" onClick={onClose} type="button">
              Cancel
            </Button>{" "}
            <Button color="primary" onClick={handleApply} type="button">
              Apply
            </Button>
          </span>
        </Cluster>
      </ModalFooter>
    </Modal>
  );
};

ProductFilterModal.propTypes = {
  filters: PropTypes.shape({
    endDate: PropTypes.string,
    product: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
    productTypes: PropTypes.array.isRequired,
    restrictedUse: PropTypes.bool.isRequired,
    search: PropTypes.string,
    sortCriteria: PropTypes.string.isRequired,
    startDate: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  purchaseSummaries: PropTypes.arrayOf(
    PropTypes.shape({
      product: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        type: PropTypes.oneOf(["CHEMICAL", "FERTILIZER", "SEED", "WATER"]).isRequired,
      }).isRequired,
    })
  ).isRequired,
};

export default ProductFilterModal;
