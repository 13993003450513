import { SupportEmailLink } from "billing/lib";
import React from "react";
import styled from "styled-components";

import useWindow from "hooks/useWindow";

import { Button } from "components/fl-ui";
import Icon from "components/fl-ui/Icons/Icon";
import { Modal, ModalBody } from "components/fl-ui/Modal/Modal";
import { ColorPalette } from "components/fl-ui/colors";

const LoggedOutModal = styled(({ className }) => {
  const win = useWindow();
  const reload = () => win.location.reload();

  return (
    <Modal className={className} width={520}>
      <ModalBody className="modal-body">
        <Icon className="error-icon" icon="error" />
        <h2>Oops! Something went wrong.</h2>
        <p>
          Please{" "}
          <Button className="blue-link" link onClick={reload}>
            refresh
          </Button>{" "}
          to log in again.
        </p>
        <p>
          If this error persists, please let us know by contacting our <SupportEmailLink>support team</SupportEmailLink>
          .
        </p>
      </ModalBody>
    </Modal>
  );
})`
  .blue-link {
    color: ${ColorPalette["blue"]} !important;
    text-decoration: underline;
  }
  .error-icon {
    height: 54px;
  }
  .modal-body {
    text-align: center !important;
  }
`;

export default LoggedOutModal;
