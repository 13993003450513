import moment from "moment";
import PropTypes from "prop-types";
import React from "react";

import rainfallEvents from "lib/rainfall_events";

import Tooltip from "components/fl-ui/Tooltip";

const { bool, shape, number, string } = PropTypes;

const RainfallBadge = function (...args) {
  const obj = args[0],
    { event } = obj,
    val = obj.disableTip,
    disableTip = val != null ? val : false;

  if (event == null) {
    return <noscript />;
  }

  // Build the class names for the badge and the wrapper.
  let badgeClass = "js-amount-container badge recent-rainfall-badge";
  if (event.state === "recent" || event.state === "completed") {
    badgeClass += " recent-rain";
  } else if (event.state === "past") {
    badgeClass += " no-rain";
  }

  let wrapperClass = "rainfall-indicator-wrapper";

  // Calulate the number of days between today and the end of the event.
  const daysAgoEnd = moment().startOf("day").diff(moment(event.endTime).startOf("day"), "days");

  const Badge = () => {
    return (
      <div className={badgeClass}>
        <span className="rainfall-amount-text">{`${event.amount.toFixed(2)}"`}</span>
      </div>
    );
  };
  return (
    <div
      className={wrapperClass}
      style={{ position: "relative" }}
      data-height="0px"
      data-tooltip-text={event.state ? rainfallEvents.tooltip(event) : null}
    >
      {event.state !== "none" && daysAgoEnd < 365 && !disableTip && (
        <Tooltip placement="bottom" message={event.state && rainfallEvents.tooltip(event)}>
          <Badge />
        </Tooltip>
      )}
      {event.state !== "none" && daysAgoEnd < 365 && disableTip && <Badge />}
      <div className="recent-rainfall-text">{rainfallEvents.badge(event)}</div>
    </div>
  );
};

RainfallBadge.displayName = "RainfallBadge";
RainfallBadge.propTypes = {
  disableTip: bool,
  event: shape({
    amount: number,
    endTime: string.isRequired,
    startTime: string,
    state: string.isRequired,
  }),
};

export default RainfallBadge;
