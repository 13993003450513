import PropTypes from "prop-types";
import React from "react";

import AuthForm from "layout/components/AuthForm";
import FooterMessage from "layout/components/FooterMessage";

import { FormGroup, Input } from "components/fl-ui/Form";

const PasswordResetTokenForm = ({ formErrors, formValues, handleChange, handleReset }) => {
  const { error, loading, password, password_confirm } = formValues;

  return (
    <AuthForm
      action="Reset"
      disabled={loading || !!error}
      error={error}
      footer={<FooterMessage />}
      label="Reset Password"
      onChange={handleChange}
      onSubmit={handleReset}
    >
      <FormGroup label="New Password">
        <Input
          autoFocus
          disabled={loading}
          hasError={!!formErrors.password}
          id="password"
          name="password"
          type="password"
          value={password}
        />

        <span className="help-inline">{formErrors.password}</span>
      </FormGroup>

      <FormGroup label="Confirm Password">
        <Input
          disabled={loading}
          hasError={!!formErrors.password_confirm}
          id="password_confirm"
          name="password_confirm"
          type="password"
          value={password_confirm}
        />

        <span className="help-inline">{formErrors.password_confirm}</span>
      </FormGroup>
    </AuthForm>
  );
};

PasswordResetTokenForm.propTypes = {
  formErrors: PropTypes.object.isRequired,
  formValues: PropTypes.shape({
    error: PropTypes.string,
    loading: PropTypes.bool,
    password: PropTypes.string,
    password_confirm: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
};

export default PasswordResetTokenForm;
