import { css } from "aphrodite/no-important";
import { styles } from "billing/lib";
import PropTypes from "prop-types";
import React, { useEffect } from "react";

import {
  TIER_SELECTION_DOWNGRADE_MODAL_OPEN,
  TIER_SELECTION_DOWNGRADE_MODAL_CANCEL,
  TIER_SELECTION_CONFIRM_DOWNGRADE,
} from "lib/metrics/events";

import { Button } from "components/fl-ui";
import CloseX from "components/fl-ui/Icons/CloseX";
import { Modal, ModalHeader, ModalTitle, ModalFooter, ModalBody } from "components/fl-ui/Modal/Modal";

const DownGradeModal = (props) => {
  const { onClose, onConfirm } = props;
  const handleOnClose = () => {
    TIER_SELECTION_DOWNGRADE_MODAL_CANCEL.track();
    onClose();
  };

  const handleClick = () => {
    TIER_SELECTION_CONFIRM_DOWNGRADE.track();
    onConfirm();
  };

  useEffect(() => {
    TIER_SELECTION_DOWNGRADE_MODAL_OPEN.track();
  }, []);

  return (
    <Modal onClose={handleOnClose} width={625}>
      <ModalHeader>
        <ModalTitle>Are you sure you want to cancel your subscription?</ModalTitle>
        <CloseX onClick={handleOnClose} />
      </ModalHeader>
      <ModalBody>
        <p className={css(styles.cancel_modalText)}>
          You will continue to have access to the products you have already paid for until the end of your billing cycle
          and will not be issued a refund.
        </p>

        <p className={css(styles.cancel_modalText)}>
          After your subscription ends, you will continue to have access to any data previously entered, but will no
          longer be able to create new data in features that required a paid subscription.
        </p>
      </ModalBody>
      <ModalFooter>
        <Button className={css(styles.right_margin)} color="primary" link onClick={handleOnClose}>
          Close
        </Button>
        <Button color="danger" hollow onClick={handleClick}>
          Cancel subscription
        </Button>
      </ModalFooter>
    </Modal>
  );
};

DownGradeModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default DownGradeModal;
