/*global URL*/
import { isNative, browserOpenUrl } from "mobile/mobileManager";
import { useMemo } from "react";
import router from "router";

import useWindow from "hooks/useWindow";

const useLinkClick = ({ href, onClick = () => {}, replace = false, route, target = null, trigger = true }) => {
  const { document } = useWindow();
  const useBrowserPlugin = useMemo(() => {
    if (isNative() && href) {
      const isLocal = new URL(href, document.baseURI).origin === new URL(document.baseURI).origin;
      return (!isLocal && !route) || !!target;
    }

    return false;
  }, [href, route, target]);

  const handleClick = (event) => {
    if (route) {
      event.preventDefault();
      router.navigate(route, { replace, trigger });
      onClick(event);
    } else if (useBrowserPlugin) {
      event.preventDefault();
      browserOpenUrl(href);
    } else {
      onClick(event);
    }
  };

  return {
    onClick: handleClick,
  };
};

export default useLinkClick;
