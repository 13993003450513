import { useContext } from "react";

import { authContext } from "collection/graphql/auth/hooks/AuthContextProvider";

const useAuth = () => {
  const auth = useContext(authContext);
  if (!auth) {
    throw new Error("Authorization context required");
  }

  return auth;
};

export default useAuth;
