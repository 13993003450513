import { gql } from "@apollo/client";

import { COMMODITY } from "collection/graphql/commodities/fragments";

const readCommodity = (commodityRef, cache) => {
  const commodityId = commodityRef.__ref.split(":").pop();
  const result = cache.readQuery({
    query: gql`
      query getCommodity($id: Int!) {
        commodity: getCommodity(id: $id) {
          ...Commodity
        }
      }
      ${COMMODITY}
    `,
    variables: {
      id: commodityId,
    },
  });

  return result?.commodity ?? null;
};

const MarketedCropTypePolicy = {
  fields: {
    allowsSpeculativeContracts: {
      read(__, { cache, readField }) {
        const { isExchangeTraded } = readCommodity(readField("commodity"), cache) ?? {};
        const harvestPrice = +readField("harvestPrice");
        return isExchangeTraded && (isNaN(harvestPrice) || harvestPrice <= 0);
      },
    },
  },
};

export default MarketedCropTypePolicy;
