import { gql } from "@apollo/client";

export default gql`
  fragment ActivityInputDetail on ActivityInput {
    id
    acreage
    area
    areaUnit
    amount
    amountRate
    amountUnit
    amountPerAcre
    amountTotal
    inputCostPerAcre
    inputCostPerUnit
    inputCostTotal
    product {
      ...DetailedProduct
    }
  }
`;
