import DetailPage from "equipment/pages/DetailPage";
import IndexPage from "equipment/pages/IndexPage";

import App from "layout/app";
import SubRoute from "lib/SubRoute";

const EquipmentRouter = SubRoute.extend({
  routes: {
    "": () => App.display(IndexPage),
    "implement/:id": (id) => App.display(DetailPage, { id: +id, type: "implement" }),
    "tractor/:id": (id) => App.display(DetailPage, { id: +id, type: "tractor" }),
  },
});

export default () => new EquipmentRouter("equipment", { createTrailingSlashRoutes: true });
