import { css } from "aphrodite";
import moment from "moment";
import React from "react";

import useActivityRecording from "hooks/useActivityRecording";
import styles, { Definition, SectionHeader, StyledGrid } from "modules/activity/common/styles";

import { Icon } from "components/fl-ui/Icons";

const AARListing = ({ recording: rawRecording }) => {
  const { recording } = useActivityRecording(rawRecording.id, rawRecording);
  const { averageSpeed, duration, last, start } = recording || {};

  let formattedDuration = "";
  if (duration) {
    formattedDuration = moment.utc(moment.duration(duration, "s")._milliseconds).format("H:mm:ss");
  }

  return !recording?.frames.length ? null : (
    <section style={{ margin: "2em auto" }}>
      <SectionHeader
        label={
          <span>
            <Icon className={css(styles.aarIcon)} icon="aar" /> Automatic Activity Recording
          </span>
        }
      />
      <StyledGrid>
        <Definition inline term="Duration">
          {moment(start).format("h:mm A")}
          {" - "}
          {moment(last).format("h:mm A")} ({formattedDuration})
        </Definition>
        <Definition inline term="Average speed">
          {Math.round(averageSpeed)} MPH
        </Definition>
      </StyledGrid>
    </section>
  );
};

export default AARListing;
