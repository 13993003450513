import { css, StyleSheet } from "aphrodite/no-important";
import classNames from "classnames";
import { showAppDeprecated } from "mobile/mobileAppUpdate";
import useMobileForcedAppUpdate from "mobile/useMobileForcedAppUpdate";
import React, { Suspense, useEffect } from "react";

import ErrorBoundary from "components/fl-ui/Layout/ErrorBoundary";
import styleVars from "components/fl-ui/Layout/Navigation/styleVars";
import LoadingSpinner from "components/ui/loading";

const styles = StyleSheet.create({
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: `0 0 0 ${styleVars.mainMenuWidth_desktop} !important`,
    "@media only screen and (max-width: 62.5em)": {
      padding: `0 0 ${styleVars.header_height} 0 !important`,
    },
    "@media print": {
      padding: "0 !important",
    },
  },
  padding: {
    width: "100%",
    height: "100%",
    padding: 0,
  },
});

const Loading = () => (
  <div className="loading-placeholder">
    <h2>Loading...</h2>
    <LoadingSpinner />
  </div>
);

let deprecationAlertShown = false;

const ContentContainer = ({ children }) => {
  const { isLoading, state } = useMobileForcedAppUpdate();

  useEffect(() => {
    if (state?.appIsDeprecated && !deprecationAlertShown) {
      deprecationAlertShown = true;
      showAppDeprecated();
    }
  }, [state?.appIsDeprecated]);

  return (
    <ErrorBoundary>
      <div id="main" className={classNames(css(styles.content), "new-navigation")}>
        <div className={css(styles.padding)}>
          <Suspense fallback={<Loading />} isLoading={isLoading}>
            {children || <Loading />}
          </Suspense>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default ContentContainer;
