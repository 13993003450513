import InventoryTransaction from "loads/components/InventoryTransaction";
import { isEmpty, orderBy } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import Transactions from "collection/inventory_transactions";
import useFieldCrops from "modules/fields/hooks/useFieldCrops";

import CropLoads from "components/crop/CropLoads";
import { Button } from "components/fl-ui";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import LoadRow from "components/load/LoadRow";
import BlankDetail from "fields/components/BlankDetail";
import ListWrapper from "fields/components/ListWrapper";

const getLoads = (fieldId, getCropByInventoryNode, year) => {
  const loads = Transactions.filter((transaction) => {
    const source = getCropByInventoryNode(transaction.get("source_id"));
    return source?.field?.id === fieldId && source?.cropYear === year;
  });

  return [...loads];
};

const getLatestCropData = (crops, year) => {
  const crop = crops.find(({ cropYear }) => cropYear === (year || new Date().getFullYear()));
  return crop ? { commodity_id: crop.commodity.id, source_id: crop.inventoryNodeId } : {};
};

const Loads = ({ fieldId, showAll, year }) => {
  const { getCropByInventoryNode, getCropsForField, loading } = useFieldCrops();
  const [showModal, setShowModal] = useState(false);
  const [loads, setLoads] = useState(() => getLoads(fieldId, getCropByInventoryNode, year));
  const sortedCrops = orderBy(getCropsForField(fieldId), ["cropYear", "commodity.name"], ["desc", "asc"]);
  const newLoadData = getLatestCropData(sortedCrops, year);

  const updateLoads = () => {
    setLoads(getLoads(fieldId, getCropByInventoryNode, year));
  };

  useEffect(() => {
    const eventContext = {};
    Transactions.on("add change remove sync destroy update", updateLoads, eventContext);

    return () => {
      Transactions.off("add change remove sync destroy update", updateLoads, eventContext);
    };
  }, []);

  useEffect(() => updateLoads(), [loading, year]);

  return (
    <LoadingWrapper isLoading={loading && isEmpty(sortedCrops)}>
      {showAll ? (
        sortedCrops.map((crop) => <CropLoads crop={crop} key={crop.id} />)
      ) : (
        <div className="load-row-list">
          <ListWrapper buttonAction={() => setShowModal(true)} buttonLabel="Log new load" list={loads}>
            {({ displayed }) => displayed.map((load) => <LoadRow key={load.id} model={load} />)}
          </ListWrapper>

          {!loads.length && (
            <BlankDetail onClick={() => setShowModal(true)}>
              <p>No loads this year</p>
            </BlankDetail>
          )}
        </div>
      )}

      {(showAll || !loads.length) && (
        <Button hollow onClick={() => setShowModal(true)}>
          Log new load
        </Button>
      )}

      {showModal && <InventoryTransaction load={newLoadData} onClose={() => setShowModal(false)} />}
    </LoadingWrapper>
  );
};

Loads.propTypes = {
  fieldId: PropTypes.number.isRequired,
  showAll: PropTypes.bool,
  year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Loads;
