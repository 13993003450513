import { gql } from "@apollo/client";

import { COMMODITY } from "collection/graphql/commodities/fragments";

export default gql`
  query getCommodities {
    commodities: getCommodities(all: true) {
      ...Commodity
    }
  }
  ${COMMODITY}
`;
