import { gql } from "@apollo/client";

export default gql`
  fragment AARRecording on AARRecording {
    id
    start
    last
    duration
    inProgress
    averageSpeed
    frames {
      lat
      lng
      time
      speed
      ha
    }
  }
`;
