import { directoryCache, share, fileDelete, fileStat, fileWrite, isNative } from "mobile/mobileManager";
import moment from "moment";
import { useEffect, useState } from "react";

import useWindow from "hooks/useWindow";

const useMobileInvoiceDownload = (invoice) => {
  const { id, hostedInvoiceUrl, periodEnd } = invoice;
  const dataDirectory = directoryCache();
  const fileName = `BushelFarm-invoice-${moment(periodEnd).format("YYYY-MM-DD")}-${id}.pdf`;
  const path = `com.farmlogs.app/invoices/${fileName}`;
  const [fileUri, setFileUri] = useState();
  const [loading, setLoading] = useState(false);
  const { FileReader, URL } = useWindow();

  useEffect(() => {
    if (isNative() && !fileUri) {
      fileStat({
        directory: dataDirectory,
        path,
      }).then(
        ({ uri }) => setFileUri(uri),
        () => {}
      );
    }

    return () => {
      if (fileUri) {
        fileDelete({
          directory: dataDirectory,
          path,
        });
      }
    };
  }, [fileUri]);

  if (!isNative()) {
    return {};
  }

  const downloadFile = async () => {
    return new Promise(async (resolve) => {
      if (loading) {
        resolve();
        return;
      }
      setLoading(true);

      const reader = new FileReader();
      reader.addEventListener("load", async () => {
        const fileContents = reader.result.replace(/^data:[^;]+/, "data:application/pdf");

        try {
          const { uri } = await fileWrite({
            data: fileContents,
            directory: dataDirectory,
            path,
            recursive: true,
          });
          setFileUri(uri);
          setLoading(false);
          resolve(uri);
        } catch (e) {
          console.error("Unable to write file", e);
        }
      });

      const response = await fetch(new URL(hostedInvoiceUrl).pathname, {
        headers: {
          Accept: "application/pdf",
        },
      });

      reader.readAsDataURL(await response.blob());
    });
  };

  const shareFile = async () => {
    if (!fileUri) return;

    try {
      setLoading(true);

      await share({
        dialogTitle: "Share invoice",
        title: fileName,
        url: fileUri,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    downloadFile,
    hasDownloaded: !!fileUri,
    loading,
    shareFile,
  };
};

export default useMobileInvoiceDownload;
