import { StyleSheet, css } from "aphrodite/no-important";
import React from "react";

import { UIColors } from "components/fl-ui/colors/";
import { Mixins } from "components/fl-ui/constants/";

// for use when there is an asyc delay before a form input loads

const loadKeyframes = {
  "0%": {
    boxShadow: "0 1.5em 0 -1em",
  },
  "80%": {
    boxShadow: "0 1.5em 0 -1em",
  },
  "100%": {
    boxShadow: "0 1.5em 0 -1em",
  },
  "40%": {
    boxShadow: "0 1.5em 0 0",
  },
};

const sharedStyles = {
  base: {
    borderRadius: "50%",
    width: "1.5em",
    height: "1.5em",
    animationFillMode: "both",
    animationName: loadKeyframes,
    animationDuration: "1.8s",
    animationIterationCount: "infinite",
    animationTimingFunction: "ease-in-out",
    animationDelay: "initial",
    animationDirection: "initial",
    animationPlayState: "initial",
  },
  beforeAfter: {
    content: "''",
    position: "absolute",
    top: 0,
  },
};

const styles = StyleSheet.create({
  wrapper: {
    width: "100%",
    height: Mixins.toRem(40),
    display: "flex",
    alignItems: "center",
  },
  loader: {
    ...sharedStyles.base,
    color: UIColors.regular,
    fontSize: "10px",
    margin: "0 auto",
    position: "relative",
    textIndent: "-9999em",
    transform: "translateZ(0) translateY(-100%)",
    animationDelay: "-0.16s",
    ":before": {
      ...sharedStyles.base,
      ...sharedStyles.beforeAfter,
      left: "-2em",
      animationDelay: "-0.32s",
    },
    ":after": {
      ...sharedStyles.base,
      ...sharedStyles.beforeAfter,
      left: "2em",
    },
  },
});

const InputLoader = () => {
  return (
    <div className={css(styles.wrapper)}>
      <div className={css(styles.loader)} />
    </div>
  );
};

export default InputLoader;
