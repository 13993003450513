import { useQuery } from "@apollo/client";
import { HideOnDesktop } from "imagery/styledComponents";
import { getCommodityOptions, getGroupOptions } from "imagery/utils";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

import { marketingClient } from "collection/graphql/client";
import { GET_FIELD_CROPS } from "collection/graphql/fields/queries";

import { FormGroup, Select, TagSelectGroup } from "components/fl-ui/Form";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";

const irrigationOptions = [
  { label: "Irrigated", value: "irrigation_true" },
  { label: "Not irrigated", value: "irrigation_false" },
];

const BasicFieldFilters = ({ fields, mobileOnlyDropdowns, selection, setSelection, years }) => {
  const variables = fields ? { fields: fields.map(({ id }) => id), years } : { years };
  const { data, loading } = useQuery(GET_FIELD_CROPS, { client: marketingClient, variables });
  const commodityOptions = useMemo(() => getCommodityOptions(data?.fieldCrops), [data?.fieldCrops]);
  const groupOptions = getGroupOptions(fields);

  const update = (updates) => {
    setSelection({
      ...selection,
      ...updates,
    });
  };

  return (
    <>
      {mobileOnlyDropdowns && (
        <HideOnDesktop>
          {mobileOnlyDropdowns.map(({ defaultValue, id, label, options }) => (
            <FormGroup key={id} label={label}>
              <Select
                block
                defaultValue={defaultValue}
                onChange={({ target }) => update({ [id]: target.value })}
                options={options}
              />
            </FormGroup>
          ))}
        </HideOnDesktop>
      )}

      <LoadingWrapper isLoading={loading && !data?.fieldCrops}>
        {groupOptions?.length > 0 && (
          <TagSelectGroup
            id="groups"
            isMulti
            label="Field groups"
            onChange={(groups) => update({ groups })}
            options={groupOptions}
            showBulkSelect
            value={selection.groups}
          />
        )}

        {commodityOptions.length > 0 && (
          <TagSelectGroup
            id="commodities"
            isMulti
            label="Commodities"
            onChange={(commodities) => update({ commodities })}
            options={commodityOptions}
            preserveOptions
            showBulkSelect
            value={selection.commodities}
          />
        )}
      </LoadingWrapper>

      <TagSelectGroup
        id="irrigation"
        isMulti
        label="Irrigation"
        onChange={(irrigation) => update({ irrigation })}
        options={irrigationOptions}
        showBulkSelect
        value={selection.irrigation}
      />
    </>
  );
};

BasicFieldFilters.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object),
  mobileOnlyDropdowns: PropTypes.arrayOf(PropTypes.object),
  selection: PropTypes.shape({
    commodities: PropTypes.array,
    groups: PropTypes.array,
    irrigation: PropTypes.array,
  }).isRequired,
  setSelection: PropTypes.func.isRequired,
  years: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default BasicFieldFilters;
