import _ from "lodash";
import { useMemo } from "react";

import useQueryParams from "hooks/useQueryParams";

const useContractsIndexQueryParams = () => {
  const [queryParams, setQueryParams] = useQueryParams({ convertTypes: false });
  const normalizedQueryParams = useMemo(() => {
    return {
      buyers: _.uniq(_.map(queryParams.buyers, _.trim).filter((x) => x)).sort(),
      commodities: _.sortBy(queryParams.commodities),
      contractNumber: queryParams.contractNumber,
      contractStatus: queryParams.contractStatus,
      cropYears: _.sortBy(queryParams.cropYears),
      deliveryEndDate: queryParams.deliveryEndDate,
      deliveryStartDate: queryParams.deliveryStartDate,
      marketedCrops: _.sortBy(queryParams.marketedCrops),
      orderBy: queryParams.orderBy,
      sortDirection: queryParams.sortDirection,
    };
  }, [queryParams]);

  return {
    contractsQueryParams: normalizedQueryParams,
    resetContractsQueryParams: () => setQueryParams({}),
    setContractsQueryParams: (newFilters) => {
      const updatedFilters = {
        ...normalizedQueryParams,
        ...newFilters,
      };
      setQueryParams(
        _.pick(updatedFilters, [
          "buyers",
          "commodities",
          "contractNumber",
          "contractStatus",
          "cropYears",
          "deliveryEndDate",
          "deliveryStartDate",
          "marketedCrops",
          "orderBy",
          "sortDirection",
        ]),
        {
          replace: true,
          trigger: true,
        }
      );
    },
  };
};

export default useContractsIndexQueryParams;
