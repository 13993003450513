import PropTypes from "prop-types";
import React from "react";

import { Button } from "components/fl-ui";

const NewPurchaseButton = (props) => {
  const { large, onSuccessfulClick, primary, text } = props;

  return (
    <Button color={primary ? "primary" : "default"} onClick={onSuccessfulClick} size={large ? "lg" : "rg"}>
      {text}
    </Button>
  );
};

NewPurchaseButton.propTypes = {
  large: PropTypes.bool,
  primary: PropTypes.bool,
  onSuccessfulClick: PropTypes.func.isRequired,
  text: PropTypes.string,
};

NewPurchaseButton.defaultProps = {
  text: "Log new purchase",
};

export default NewPurchaseButton;
