import { BaseElement } from "@farmlogs/fl-ui";
import { css } from "aphrodite";
import { styles } from "billing/lib";
import PropTypes from "prop-types";
import React from "react";
import { Row } from "react-styled-flexboxgrid";

import { Button } from "components/fl-ui";
import Fullscreen from "components/fl-ui/util/Fullscreen";

const FullPageOverlay = ({ children, onCancel, ...props }) => (
  <Fullscreen>
    <BaseElement {...props}>
      {onCancel && (
        <Row reverse>
          <Button className={css(styles.cancelButton)} color="primary" icon="close" link onClick={onCancel}>
            Close
          </Button>
        </Row>
      )}

      <>{children}</>
    </BaseElement>
  </Fullscreen>
);

FullPageOverlay.propTypes = {
  children: PropTypes.node.isRequired,
  onCancel: PropTypes.func,
};

export default FullPageOverlay;
