import _ from "lodash";
import moment from "moment";

const transformDailyWeatherReadings = (readings, precision) => {
  let total = 0;
  const multiplier = precision * 10;
  const transformed = _.map(readings, ({ amount, date }) => {
    total += amount * multiplier;
    date = moment(date);
    return {
      amount,
      date: date.format("YYYY-MM-DD"),
      day: +date.format("D"),
      month: +date.format("M"),
      total: +(total / multiplier).toFixed(precision),
    };
  });

  return _.sortBy(transformed, "date");
};

export default transformDailyWeatherReadings;
