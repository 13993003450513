import numeral from "numeral";
import React from "react";

import withCropMutation from "hoc/withCropMutation";

import { Button } from "components/fl-ui";
import CropBadge from "components/fl-ui/CropBadge";

const CropRow = ({ crops, createCrop, cropYear, editCrop, fieldId, showAll }) => (
  <div className="row-fluid crop-history-row">
    {showAll && <h3 className="col xs-2 crop-history-year">{cropYear}</h3>}

    <div className={`col ${showAll ? "xs-9" : "xs-12"}`}>
      {crops.map((crop, index) => (
        <div className="row-fluid crop-history-crop" key={crop.id}>
          <div className="col xs-12 margin-top-10" style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex" }}>
              <CropBadge abbr={crop.commodity.abbr} color={crop.commodity.color} />

              <div className="display-inline-block vertical-align-middle spacing-left">
                <div className="title spacing-bottom">{crop.commodity.name}</div>

                {crop.status?.toLowerCase() === "historical" ? (
                  <div className="sub">
                    {`auto-detected \u00b7 `}
                    <Button color="primary" lightWeight link onClick={() => editCrop(crop)} size="mn">
                      Change
                    </Button>
                  </div>
                ) : (
                  <div className="sub">
                    {`${numeral(crop.acreage).format("0,0[.]00")} ac \u00b7 `}
                    <Button color="primary" lightWeight link onClick={() => editCrop(crop)} size="mn">
                      Edit
                    </Button>
                  </div>
                )}
              </div>
            </div>

            {!showAll && index === crops?.length - 1 && (
              <Button color="primary" hollow onClick={() => createCrop(fieldId)}>
                + Add crop
              </Button>
            )}
          </div>
        </div>
      ))}

      {!crops.length && showAll && (
        <div className="row-fluid crop-history-crop">
          <div className="col xs-2">
            <div className="map-badge no-crop" />
          </div>
          <div className="col xs-10">
            <Button color="primary" hollow lightWeight onClick={() => createCrop(fieldId, { cropYear })}>
              Add crop
            </Button>
          </div>
        </div>
      )}
    </div>
  </div>
);

export default withCropMutation(CropRow);
