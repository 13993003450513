import { Purchases as CatPurchases, PURCHASES_ERROR_CODE } from "@revenuecat/purchases-capacitor";
import MobilePaymentError from "billing/exceptions/MobilePaymentError";
import _ from "lodash";
import { getPlatform, isFullWeb, isNative } from "mobile/mobileManager";
import { AppUserIdSchema } from "mobile/purchases/schemas";

function getPlatformApiKey() {
  if (getPlatform() === "ios") {
    return process.env.REVENUECAT_PUBLIC_API_KEY_IOS;
  } else if (getPlatform() === "android") {
    return process.env.REVENUECAT_PUBLIC_API_KEY_ANDROID;
  } else {
    throw new Error("Unable to get platform api key for web");
  }
}

/**
 * @type PurchasesPlugin
 */
export const Purchases = new Proxy(CatPurchases, {
  /**
   * Configures the RevenueCat SDK for a given userId.
   * If RevenueCat is already configured then logs in instead
   *
   * @override
   * @param {number|string} appUserId
   * @return {Promise<void|LogInResult>}
   */
  async configure(userId, enterpriseId) {
    // capital ID required for appUserID param key in RevenueCat plugin
    const appUserID = (await AppUserIdSchema.validate(userId)) + "";

    const { isConfigured } = await CatPurchases.isConfigured();
    if (isConfigured) {
      await CatPurchases.logIn({ appUserID });
    } else {
      await CatPurchases.configure({
        apiKey: getPlatformApiKey(),
        appUserID,
      });
    }

    await CatPurchases.setAttributes({ enterprise_id: enterpriseId + "" });
  },

  /**
   * Throws errors if current mobile setup is not valid
   * Must be iOS/Android and using the RevenueCat plugin
   */
  async ensureSupported() {
    if (!isNative()) {
      throw new Error("Purchases not supported on web platform");
    }
    if (!(await isFullWeb())) {
      throw new Error("Purchases not supported, upgrade required");
    }
  },

  /**
   * Returns all active entitlements for the currently configured user.
   * @return {Promise<PurchasesEntitlementInfo.active>}
   */
  async getActiveEntitlements() {
    const { customerInfo } = await CatPurchases.getCustomerInfo();
    return customerInfo.entitlements.active;
  },

  /**
   * @return {Promise<null|string>}
   */
  async getAppUserID() {
    const { appUserID } = await CatPurchases.getAppUserID();
    return appUserID;
  },

  /**
   * Returns all packages available for purchase by the current user from one of the app stores.
   * This may include addons which are not actually for sale.
   * @return {Promise<PurchasePackage[]>}
   */
  async getAvailablePackages() {
    const {
      current: { availablePackages },
    } = await CatPurchases.syncAttributesAndOfferingsIfNeeded();
    return availablePackages;
  },

  /**
   * Do not use this method directly.
   * Calling configure will fallback to logIn if already configured.
   * @deprecated
   * @param {number|string} appUserId
   * @return {Promise<LogInResult>}
   */
  async logIn(appUserId) {
    const appUserID = AppUserIdSchema.validateSync(appUserId) + "";
    return CatPurchases.logIn({ appUserID });
  },

  /**
   * Do not use this method. It will create undesirable user aliases.
   * @deprecated
   * @return {Promise<void>}
   * @throws {Error}
   */
  async logOut() {
    throw new Error("Method not allowed (logOut)");
  },

  /**
   * Given a particular mobilePlanId, find its corresponding package
   * in RevenueCat and attempt to purchase that package through RevenueCat
   *
   * @param {String} mobilePlanId
   * @return {Promise}
   * @throws {MobilePaymentError}
   */
  async payForMobilePlanId(mobilePlanId) {
    const availablePackages = await this.getAvailablePackages();
    const mobilePackage = _.find(availablePackages, (availablePackage) => availablePackage.identifier === mobilePlanId);
    if (!mobilePackage) {
      throw new MobilePaymentError("Failed to find matching mobile package", {
        mobilePlanId,
      });
    }

    try {
      await CatPurchases.purchasePackage({ aPackage: mobilePackage });
    } catch (error) {
      throw new MobilePaymentError("Purchase Package Failure: " + error.message, {
        mobilePlanId,
        mobilePackage,
        error,
        userCancelled: error.code === PURCHASES_ERROR_CODE.PURCHASE_CANCELLED_ERROR,
      });
    }
  },

  get(target, property) {
    if (property in this) {
      return async (...args) => this[property](...args);
    }

    return async (...args) => target[property](...args);
  },
});
