import { gql } from "@apollo/client";

import { subscriptionFragment } from "collection/graphql/subscription/fragments";

export default gql`
  mutation updateWebSubscription($input: SubscriptionInput!) {
    subscription: updateWebSubscription(input: $input) @rest(type: "Subscription", path: "/subscriptions/subscription", method: "POST") {
      ${subscriptionFragment}
    }
  }
`;
