import Backbone from "backbone";

export default Backbone.Model.extend({
  urlRoot: "/api/buyers",

  validation: {
    name: { required: true, maxLength: 45 },
    city: { required: false, maxLength: 100 },
    state: { required: false, maxLength: 40 },
    county: { required: false, maxLength: 40 },
  },

  defaults() {
    return { public: false };
  },

  getIconPath() {
    return "/images/inventory/buyer.png";
  },
});
