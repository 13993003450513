import React, { Fragment } from "react";

const wrapWith = (substring, wrapper, length) => (
  <Fragment>
    {wrapper(substring.substring(0, length))}
    {substring.substring(length)}
  </Fragment>
);

/**
 * Searches for a string within a string and wraps all occurrences in a DOM node.
 *
 * @param {String} target the string in which to search
 * @param {String} search the substring to search for in target
 * @param {Object} [options={}]
 * @param {RegExp} [options.pattern] the regex used to search the target string. may speed up performance on larger
 *   data sets to pass this in explicitly.
 * @param {Function} [options.wrapper] a function returning a DOM node that will wrap any search matches
 * @returns {String|Node} returns a DOM node
 */
const wrapOccurrences = (target, search, options = {}) => {
  let parts = [target];
  const searchLength = search.length;

  if (searchLength > 0) {
    // CORE-3352 the following regular expression was taken from https://bit.ly/2QIcA7g
    const escapedSearch = search.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
    const pattern = options.pattern || new RegExp("(?=" + escapedSearch + ")", "gi");
    const wrapper = options.wrapper ? options.wrapper : (value) => <strong>{value}</strong>;
    parts = target.split(pattern).map((part) => {
      if (pattern.test(part)) {
        pattern.lastIndex = 0;
        part = wrapWith(part, wrapper, searchLength);
      }

      return part;
    });
  }

  return (
    <span>
      {parts.map((part, i) => (
        <span key={i}>{part}</span>
      ))}
    </span>
  );
};

export default wrapOccurrences;
