/**
 * Filters an array of activities by type id.
 *
 * @param {Activity[]} activities
 * @param {Object} filters
 * @param {Number[]} filters.type
 * @param {Function} markAsApplied
 * @return {Activity[]}
 */
const activityTypeFilter = (activities, { type }, markAsApplied) => {
  const typeFilter = type;
  if (typeFilter.length > 0) {
    activities = activities.filter(({ type }) => type?.id && typeFilter.includes(type.id));
    markAsApplied();
  }

  return activities;
};

export default activityTypeFilter;
