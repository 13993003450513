import React from "react";
import styled from "styled-components";

import { GreyColors } from "components/fl-ui/colors";

const StyledLabel = styled.h4`
  color: ${GreyColors.smoke30};
  margin-top: unset;
`;

export default StyledLabel;
