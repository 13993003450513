import WriteActivityWorkflow from "activity/components/WriteActivityWorkflow";
import { activityDeletionPrompt } from "activity/utils";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";

import useActivityDelete from "collection/graphql/activities/hooks/useActivityDelete";
import useBulkActivityStatusUpdate from "collection/graphql/activities/hooks/useBulkActivityStatusUpdate";
import useConnectivityChange from "hooks/useConnectivityChange";
import { DONE, PLANNED } from "lib/constants";

import PopoverButton from "components/fl-ui/Buttons/PopoverButton";

const stateDefaults = {
  action: null,
  showModal: false,
};

const ActivityPopoverButton = (props) => {
  const deleteActivity = useActivityDelete();
  const { activity } = props;
  const [state, setState] = React.useState(stateDefaults);
  const isOnline = useConnectivityChange();
  const updateActivityStatus = useBulkActivityStatusUpdate();

  /* event handlers */
  const markAsDone = () => updateActivityStatus([activity.id], DONE);
  const markAsPlanned = () => updateActivityStatus([activity.id], PLANNED);

  const handleCopy = () =>
    setState({
      action: "duplicate",
      showModal: true,
    });

  const handleDelete = () => {
    activityDeletionPrompt().then(
      () => deleteActivity(activity.id),
      () => {}
    );
  };

  const handleEdit = () =>
    setState({
      action: "edit",
      showModal: true,
    });

  /* menu options */
  const options = [
    isOnline && { label: "Edit", onSelect: handleEdit },
    { label: "Copy", onSelect: handleCopy },
    { label: "Delete", onSelect: handleDelete, color: "danger" },
  ];

  if (activity.isPlanned) {
    options.splice(1, 0, { label: "Mark as done", onSelect: markAsDone });
  } else {
    options.splice(1, 0, {
      label: "Mark as planned",
      onSelect: markAsPlanned,
    });
  }

  return (
    <>
      <PopoverButton options={_.compact(options)} />
      {state.showModal && (
        <WriteActivityWorkflow
          activity={state.action === "duplicate" ? _.omit(activity, "id") : activity}
          onAbort={() => setState(stateDefaults)}
          onCompletion={() => setState(stateDefaults)}
          onDelete={() => {
            setState(stateDefaults);
            handleDelete();
          }}
        />
      )}
    </>
  );
};

ActivityPopoverButton.propTypes = {
  activity: PropTypes.object.isRequired,
};

export default ActivityPopoverButton;
