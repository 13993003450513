import React from "react";

import getHeatHistory from "collection/graphql/weather/getHeatHistory";
import getRainfallHistory from "collection/graphql/weather/getRainfallHistory";
import useRestQuery from "hooks/useRestQuery";

/**
 * @param {WeatherSummary} weatherSummary
 * @param {'gdd'|'rainfall'} aspect
 * @param {Boolean} [startAtPlantingDate=false]
 * @param {Object} [extraQueryVars={}]
 */
const useWeatherHistory = (weatherSummary, aspect, startAtPlantingDate = false, extraQueryVars = {}) => {
  const { centroid, cropYear, endOfCropYear, fieldId, plantingDate, startOfCropYear } = weatherSummary;

  const isGDD = aspect === "gdd";
  const weatherQuery = isGDD ? getHeatHistory : getRainfallHistory;
  const endDate = endOfCropYear;
  const startDate = startAtPlantingDate && plantingDate ? plantingDate : startOfCropYear;

  const weatherQueryParams = {
    skip: !centroid,
    variables: {
      cropYear,
      geometry: JSON.stringify(centroid || {}),
      endDate,
      fieldId,
      startDate,
      ...extraQueryVars,
    },
  };

  return useRestQuery(weatherQuery, weatherQueryParams);
};

export default useWeatherHistory;
