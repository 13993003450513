import PropTypes from "prop-types";
import React, { useState } from "react";

import { Button } from "components/fl-ui";
import { FooterButtons } from "fields/components/styledComponents";

const ListWrapper = ({
  buttonAction,
  buttonLabel,
  children: WrappedComponent,
  hideMoreAction,
  limit = 3,
  list,
  ...props
}) => {
  const [showAll, setShowAll] = useState(false);
  const countTotal = list?.length;
  const displayed = !showAll && countTotal > limit ? list.slice(0, limit) : list;
  const remaining = countTotal - limit;

  return (
    <>
      <WrappedComponent {...props} displayed={displayed} />

      <FooterButtons>
        {!!displayed.length && (
          <Button color="primary" lightWeight link onClick={buttonAction}>
            {buttonLabel}
          </Button>
        )}

        {remaining > 0 && !hideMoreAction && (
          <Button color="primary" lightWeight link onClick={() => setShowAll(!showAll)}>
            {`${showAll ? "Hide" : "Show"} ${remaining} more...`}
          </Button>
        )}
      </FooterButtons>
    </>
  );
};

ListWrapper.propTypes = {
  buttonAction: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  hideMoreAction: PropTypes.bool,
  limit: PropTypes.number,
  list: PropTypes.array,
};

export default ListWrapper;
