import { useQuery } from "@apollo/client";
import { css } from "aphrodite/no-important";
import { isEmpty } from "lodash";
import dollarFormat from "marketing/utils/dollarFormat";
import ApprovalStatus from "prices/components/ApprovalStatus";
import ElevatorMap from "prices/components/ElevatorMap";
import MarketedCropListingHeader from "prices/containers/MarketedCropPrices/MarketedCropListingHeader";
import { futuresTabStyles, localBidsStyles as styles } from "prices/styles";
import React, { Fragment } from "react";

import { GET_BIDS_BY_BUYER } from "collection/graphql/prices";

import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import { Col, Body, Header, HeaderCol, Row, Table } from "components/fl-ui/Table";
import CommodityYieldUnit from "components/units/CommodityYieldUnit";

const BuyerDetail = (props) => {
  const { id } = props;
  const { data, loading: bidsLoading } = useQuery(GET_BIDS_BY_BUYER, {
    variables: {
      buyers: [id],
    },
  });
  const { bids } = data ?? {};

  const getAggregatedBids = () => {
    const bidsObject = {};

    bids.forEach((bid) => {
      const {
        underlying: { commodity },
        underlying,
      } = bid;

      if (!bidsObject[commodity.name]) {
        bidsObject[commodity.name] = {
          underlying,
          values: [],
        };
      }

      bidsObject[commodity.name].values.push(bid);
    });

    return Object.values(bidsObject);
  };

  if (bidsLoading) {
    return <LoadingWrapper />;
  }

  if (isEmpty(bids)) {
    window.location.href = "/#prices/local";
    return null;
  }

  const { buyer } = bids[0];
  const { address, city, distance, name, phone, state, zip, acceptsOfferDelivery } = buyer;

  return (
    <div>
      <ElevatorMap buyer={buyer} />
      <h2 className={css(styles.detail_buyerName, styles.inlineBlock)}>
        {name}
        <ApprovalStatus status={acceptsOfferDelivery} wText />
      </h2>

      <p className={css(styles.detail_buyerInfo)}>
        {address}, {city}, {state}, {zip} • {distance} miles away
      </p>
      <p className={css(styles.detail_buyerInfo)}>{phone}</p>
      <hr className={css(styles.detail_HR)} />
      {getAggregatedBids().map((bid, i) => {
        const {
          underlying: { commodity },
          underlying,
        } = bid;

        return (
          <Fragment key={`${commodity.name}-${commodity.id}`}>
            <MarketedCropListingHeader crop={underlying} />
            <Table className={css(futuresTabStyles.table)}>
              <Header>
                <Row>
                  <HeaderCol className={css(futuresTabStyles.th)} column="delivery">
                    Delivery
                  </HeaderCol>

                  <HeaderCol className={css(futuresTabStyles.th)} column="price" type="number">
                    Current bid
                  </HeaderCol>

                  <HeaderCol className={css(futuresTabStyles.th)} column="basis" type="number">
                    Basis
                  </HeaderCol>
                </Row>
              </Header>

              <Body>
                {bid.values.map((v, i) => {
                  return (
                    <Row key={`buyerBidsRow-${i}`}>
                      <Col className={css(futuresTabStyles.td)}>{v.delivery}</Col>

                      <Col className={css(futuresTabStyles.td)} type="number">
                        <CommodityYieldUnit commodity={commodity} amount={dollarFormat(v.price)} per="unit" />
                      </Col>

                      <Col className={css(futuresTabStyles.td)} type="number">
                        {dollarFormat(v.basis)}
                      </Col>
                    </Row>
                  );
                })}
              </Body>
            </Table>
          </Fragment>
        );
      })}
    </div>
  );
};

export default BuyerDetail;
