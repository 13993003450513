import { useLocalStorage } from "beautiful-react-hooks";
import { useDemoContext } from "demo/demos/MobileSubscriptionsDemo/MobileSubscriptionsDemoContext";
import React from "react";

import { useAuth } from "collection/graphql/auth";

import { Button } from "components/fl-ui";
import DropdownButton from "components/fl-ui/Buttons/DropdownButton";

const AppUserButton = () => {
  const { currentUser } = useAuth();
  const [, setMobileTestUserIds] = useLocalStorage("mobileTestUserIds");
  const { appUserId, isConfigured, purchases, refresh, updateState, users } = useDemoContext();
  const addTestUser = () => {
    const userId = prompt("Enter a user id for testing");
    if (+userId) {
      updateState({ action: "addUser", userId });
    }
  };

  const options = Array.from(users).reduce((options, userId) => {
    if (userId !== +appUserId) {
      options.push({ label: `Login as user id: ${userId}`, value: userId });
    }

    return options;
  }, []);
  options.push({ label: "Add user id...", value: "add" });
  options.push({ label: "Clear", value: "clear" });

  const configure = async () => {
    await purchases.configure(currentUser.id);
    refresh();
  };

  const onSelectUserId = async ({ value }) => {
    switch (value) {
      case "add": {
        addTestUser();
        break;
      }

      case "clear": {
        await purchases.logIn(currentUser.id);
        const users = [currentUser.id];
        setMobileTestUserIds(users);
        updateState({ users: new Set(users) });
        refresh();
        break;
      }

      default: {
        await purchases.logIn(value);
        refresh();
      }
    }
  };

  return (
    <>
      {isConfigured ? (
        <DropdownButton
          button={
            <Button color="primary" size="lg">
              Configured appUserId: <strong>{appUserId}</strong> {currentUser.id === +appUserId ? "(current)" : null}
            </Button>
          }
          onSelect={onSelectUserId}
          options={options}
        />
      ) : (
        <Button color="primary" onClick={configure}>
          Configure user id: {currentUser.id}
        </Button>
      )}
    </>
  );
};

export default AppUserButton;
