import "../styles/app.less";
import "leaflet/dist/leaflet.css";
import ActivityRouter from "activity/init";
import AgreementsRouter from "agreements/init";
import Backbone from "backbone";
import BillingRouter from "billing/init";
import CarbonEnrollmentRouter from "carbon_enrollment/init";
import ContractsRouter from "contracts/init";
import DemoRouter from "demo/init";
import ElevatePlusRouter from "elevate_plus/init";
import EquipmentRouter from "equipment/init";
import ImageryRouter from "imagery/init";
import InventoryRouter from "inventory/init";
import LoadsRouter from "loads/init";
import _ from "lodash";
import "main";
import MapRouter from "map/init";
import MarketingRouter from "marketing/init";
import ScoutingRouter from "notes/init";
import NotesRouter from "notes/notesRouter";
import NotificationsRouter from "notifications/init";
import OtherExpensesRouter from "other_expenses/init";
import OtherRevenueRouter from "other_revenue/init";
import PricesRouter from "prices/init";
import ProductsRouter from "products/init";
import PandLRouter from "profit_and_loss/init";
import ProgramEnrollmentRouter from "program_enrollment/init";
import ReportsRouter from "reports/init";
import ResourcesRouter from "resources/init";
import SettingsRouter from "settings/init";

import { authentication } from "collection/graphql/auth";
import prefetch from "collection/graphql/prefetch";
import InviteLayout from "layout/InviteLayout";
import Login from "layout/Login";
import PasswordReset from "layout/PasswordReset";
import PasswordResetToken from "layout/PasswordResetToken";
import SignUp from "layout/SignUp";
import AppLayout from "layout/app";
import History from "lib/history";
import optionallyReplaceState from "lib/optionallyReplaceState";
import translateUrl from "lib/translateUrl";

import FieldsRouter from "fields/init";

const ROUTES = {
  activity: _.memoize(ActivityRouter),
  alerts: _.memoize(NotificationsRouter),
  billing: _.memoize(BillingRouter),
  contracts: _.memoize(ContractsRouter),
  carbon_enrollment: _.memoize(CarbonEnrollmentRouter),
  demo: _.memoize(DemoRouter),
  elevate_plus: _.memoize(ElevatePlusRouter),
  equipment: _.memoize(EquipmentRouter),
  fields: _.memoize(FieldsRouter),
  imagery: _.memoize(ImageryRouter),
  inventory: _.memoize(InventoryRouter),
  land_costs: _.memoize(AgreementsRouter),
  loads: _.memoize(LoadsRouter),
  map: _.memoize(MapRouter),
  marketing: _.memoize(MarketingRouter),
  notes: _.memoize(NotesRouter),
  other_expenses: _.memoize(OtherExpensesRouter),
  other_revenue: _.memoize(OtherRevenueRouter),
  prices: _.memoize(PricesRouter),
  products: _.memoize(ProductsRouter),
  profit_and_loss: _.memoize(PandLRouter),
  program_enrollment: _.memoize(ProgramEnrollmentRouter),
  reports: _.memoize(ReportsRouter),
  resources: _.memoize(ResourcesRouter), // modal
  scouting: _.memoize(ScoutingRouter),
  settings: _.memoize(SettingsRouter),
};

if (module.hot) {
  module.hot.accept();
}

const isLoggedIn = async () => {
  const currentUser = await getCurrentUser();
  return !!currentUser;
};

const getCurrentUser = async () => {
  const { data } = await authentication({ fetchPolicy: "cache-only" });
  return data?.currentUser;
};

const triggerRoute = () => {
  startHistory();
  History.loadUrl(History.fragment);
};

const showSignup = async function () {
  if (await isLoggedIn()) {
    // Handles navigation to signup page with valid session
    History.navigate("fields/survey", { replace: true });
    location.reload();
    return null;
  }

  return AppLayout.display(SignUp, { screenName: "Sign up" });
};

const handleUnauthorized = () => {
  startHistory();
  const isProtected = currentRouteIsProtected();
  if (isProtected) {
    showLogin();
  }
};

const currentRouteIsProtected = () => {
  const { hash } = window.location;
  const unprotectedHashes = ["#login", "#signup", "#invite", "#password/reset"];

  return !unprotectedHashes.some((routeHash) => hash.startsWith(routeHash));
};

const showLogin = async () => {
  startHistory();

  if (await isLoggedIn()) {
    return goToLandingPage();
  }
  return AppLayout.display(Login, { screenName: "Login" });
};

const startHistory = _.once(() => {
  // update /app/ urls prior to router starting
  optionallyReplaceState(new URL(window.location), window.history.replaceState.bind(window.history));

  return History.initialize();
});

const goToLandingPage = async () => {
  const currentUser = await getCurrentUser();
  return History.navigate(determineLandingPage(currentUser), {
    trigger: true,
    replace: true,
  });
};

const determineLandingPage = (currentUser) => {
  if (!currentUser) {
    return "login";
  }

  if (!currentUser.landingPage) {
    return "fields";
  }

  return currentUser.landingPage;
};

const router = new (Backbone.Router.extend({
  routes: {
    "": "index",
    login: "login",
    signup: "signup",
    invite: "invite",
    "password/reset": "passwordReset",
    "password/reset/:token": "passwordResetToken",
    ":page(/*subroute)": "load",
  },

  async initialize() {
    return authentication().then(triggerRoute, handleUnauthorized);
  },

  navigate(fragment, options = {}) {
    History.navigate(fragment, options);
    return this;
  },

  index() {
    return goToLandingPage();
  },

  login() {
    return showLogin();
  },

  /**
   * Redirects from a full url to a path handled by the router.
   * @param {URL} originalUrl
   */
  reroute(originalUrl) {
    const url = translateUrl(originalUrl);
    this.navigate(url.hash || url.pathname, { replace: true, trigger: true });
  },

  signup() {
    return showSignup();
  },

  passwordReset() {
    return AppLayout.display(PasswordReset, { screenName: "Reset" });
  },

  passwordResetToken(token) {
    return AppLayout.display(PasswordResetToken, { screenName: "Reset", token });
  },

  invite(params) {
    // incase backbone queryparams is removed again
    const token = params && (params["token"] || params.split("token=")[1]);
    return AppLayout.display(InviteLayout, { screenName: "Invite", token });
  },

  async load(page) {
    if (await isLoggedIn()) {
      if (!ROUTES[page]) {
        AppLayout.notify(`\"${window.location.hash}\" doesn't exist.`);
        History.back();
        return;
      }

      // don't show loading for modals
      if (!page.match(/(log|resources)/)) {
        AppLayout.render();
      }

      prefetch();
      return ROUTES[page]();
    }
  },
}))();

export default router;
