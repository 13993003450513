import _ from "lodash";
import React, { useMemo } from "react";
import ProductRecipeList from "settings/components/ProductRecipeList";
import InputGroup from "settings/components/input_group";
import InputItem from "settings/components/input_item";

import { useProductsById } from "collection/graphql/products/hooks";
import Products from "collection/products";
import useBackboneCollection from "hooks/useBackboneCollection";

const Inputs = () => {
  const allProducts = useBackboneCollection(Products);
  const productIds = _.uniq(_.map(allProducts, "id"));
  const { products } = useProductsById(productIds);

  const {
    ChemicalProduct: chemicals,
    FertilizerProduct: fertilizers,
    SeedProduct: seeds,
  } = useMemo(() => {
    const editableProducts = _.reject(products, "public"); // public products are editable by the current enterprise
    const sorted = _.sortBy(editableProducts, "name");
    return _.groupBy(sorted, "__typename");
  }, [products]);

  return (
    <div className="settings-inputs-wrapper margin-top-10">
      <InputGroup title="Custom Fertilizers" addUrl="#resources/products?type=fertilizer">
        {_.map(fertilizers, (fertilizer) => (
          <InputItem
            key={fertilizer.id}
            title={fertilizer.name}
            sub={fertilizer.shortDescription}
            href={`#resources/products/${fertilizer.id}`}
          />
        ))}
      </InputGroup>

      <InputGroup title="Custom Chemicals" addUrl="#resources/products?type=chemical">
        {_.map(chemicals, (chemical) => (
          <InputItem
            key={chemical.id}
            title={chemical.name}
            sub={chemical.shortDescription}
            href={`#resources/products/${chemical.id}`}
          />
        ))}
      </InputGroup>

      <InputGroup title="Custom Seeds" addUrl="#resources/products?type=seed">
        {_.map(seeds, (seed) => (
          <InputItem
            key={seed.id}
            title={seed.name}
            sub={seed.shortDescription}
            href={`#resources/products/${seed.id}`}
          />
        ))}
      </InputGroup>

      <ProductRecipeList />
    </div>
  );
};

Inputs.tabId = "inputs";
Inputs.title = "Input Settings";

export default Inputs;
