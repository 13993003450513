import { useQuery } from "@apollo/client";
import styles from "activity/common/styles";
import AcreageMapControl from "activity/components/ActivityAddEditModal/AcreageMapControl";
import ActivityAreaDrawHandler from "activity/components/ActivityAddEditModal/ActivityAreaDrawHandler";
import ActivityMapImage from "activity/components/ActivityAddEditModal/ActivityMapImage";
import { CardItems, StyledCard } from "activity/components/styledComponents";
import { css } from "aphrodite";
import _ from "lodash";
import numeral from "numeral";
import PropTypes from "prop-types";
import React, { useMemo, useRef, useState } from "react";

import { getFieldCrop } from "collection/graphql/fields/queries";
import useBoolean from "hooks/useBoolean";
import MapUtils from "lib/map/utils";

import { getFeature, getLayerFillPattern } from "components/field/utils";

const ActivityAreaCard = (props) => {
  const { onChange, ...defaults } = props;
  const [isMapShown, { off, toggle }] = useBoolean(false);
  const [attributes, setAttributes] = useState(defaults);
  const { acreage, crop: cropData } = attributes;
  const acreageInputId = useMemo(() => _.uniqueId("acreage-"), []);

  // Fetch more data only if data from props does not contain geometry
  const { data } = useQuery(getFieldCrop, {
    skip: !!(cropData.geometry && cropData.field?.geometry),
    variables: { cropId: cropData.id },
  });

  const crop = data?.fieldCrop ?? cropData ?? {};
  const { commodity, cropYear, field, id } = crop;
  const activityAreaPercent = numeral((100 * acreage) / field.acreage).format("0,0");
  const fillPattern = useMemo(() => getLayerFillPattern(commodity.color, "white"), [commodity.color]);
  const feature = getFeature({
    geometry: attributes.geometry,
    geometricCircumference: attributes.geometricCircumference,
    id,
  });

  /*
   * The `defaults` props change on ever render. This variable exists to temporarily save some of the default values when
   * the map opens, so they can be restored when the cancel button is pressed.
   */
  const ephemeralDefaults = useRef({});
  const handleMapButtonClick = () => {
    if (!isMapShown) {
      ephemeralDefaults.current = {
        acreage: defaults.acreage,
        geometry: defaults.geometry,
        geometricCircumference: defaults.geometricCircumference,
      };
    } else {
      ephemeralDefaults.current = {};
    }
    toggle();
  };

  const onCancel = () => {
    const newAttributes = {
      ...defaults,
      ...ephemeralDefaults.current,
    };

    setAttributes(newAttributes);
    onChange(newAttributes);
    off();
  };

  const onAcreageBlur = (event) => {
    if (event.target.value === "") {
      const defaultAcreage = attributes.geometry ? MapUtils.calculateAcreage(attributes.geometry) : crop.acreage;
      onAcreageChange(defaultAcreage);
    }
  };

  const onAcreageChange = (acreage) => {
    if (/\d?$/.test(acreage + "")) {
      const newAttributes = {
        acreage,
        crop,
        geometry: attributes.geometry,
        geometricCircumference: attributes.geometricCircumference,
      };

      setAttributes(newAttributes);
      onChange(newAttributes);
    }
  };

  const onGeometryChange = (geometry, geometricCircumference = null) => {
    const newAttributes = {
      acreage: geometry ? MapUtils.calculateAcreage(geometry) : crop.acreage,
      crop,
      geometry,
      geometricCircumference,
    };
    setAttributes(newAttributes);
    onChange(newAttributes);
  };

  const onUpdate = () => {
    onChange(attributes);
    off();
  };

  return (
    <>
      <StyledCard>
        <CardItems>
          <div>
            <ActivityMapImage
              className={css(styles.mapImage)}
              commodity={commodity}
              crop={crop}
              feature={feature}
              field={field}
              fillPattern={fillPattern}
              year={cropYear}
            />

            <div>
              {!!acreage && (
                <strong className={css(styles.optionHeader)}>
                  {numeral(acreage).format("0,0[.]00")} {acreage === 1 ? "acre" : "acres"}
                </strong>
              )}
              {!!field.acreage && (
                <p className={css(styles.optionHeader)}>
                  {activityAreaPercent}% of field area {`\u00b7 ${numeral(field.acreage).format("0,0[.]00")}`} ac total
                </p>
              )}
              <p className={css(styles.optionSubText)}>
                {field.name} {"\u00b7"} {cropYear} {commodity.name}
              </p>
            </div>
          </div>

          <AcreageMapControl
            id={acreageInputId}
            onInputBlur={onAcreageBlur}
            onInputFocus={off}
            onChange={onAcreageChange}
            onMapButtonClick={handleMapButtonClick}
            value={acreage}
          />
        </CardItems>

        {!!field && isMapShown && (
          <ActivityAreaDrawHandler
            crop={crop}
            feature={feature}
            field={field}
            fillPattern={fillPattern}
            onCancel={onCancel}
            onChange={onGeometryChange}
            onUpdate={onUpdate}
          />
        )}
      </StyledCard>
    </>
  );
};

ActivityAreaCard.propTypes = {
  acreage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  crop: PropTypes.object,
  geometry: PropTypes.object,
  geometricCircumference: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default ActivityAreaCard;
