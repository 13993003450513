import searchControl from "map/control/search";
import toolbarControl from "map/control/toolbar";
import yearControl from "map/control/year";
import notes from "map/layer/notes";

import getLocation from "lib/getLocation";
import Map from "lib/map/map";
import MapUtils from "lib/map/utils";

export default Map.extend({
  initialize(element, options) {
    const { bounds } = options;
    const center = ((bounds && bounds.center) || getLocation(options)).split(",");
    const zoom = Math.min((bounds && bounds.zoom) || 14, 19);

    Map.prototype.initialize.call(this, element, {
      zoom,
      center,
      attributionControl: false,
    });

    this.fields = options.fields;
    this.fieldLayers = options.fieldLayers;
    this.on("map:view", this.showFields);
    this.on("map:view", this.updateToolbar);
    this.on("map:view", this.showNotes);
    this.on("map:cropChange", () => {
      notes.updateLayers();
    });

    this.on("dragend", this.onDragEnd);
    this.on("zoomend", this.onZoomEnd);

    toolbarControl.fields = options.fields;
    toolbarControl.addTo(this);
    yearControl.addTo(this);
    searchControl.addTo(this);

    return this;
  },

  updateBounds() {
    const { lat, lng } = this.getCenter();
    this.fire("bounds:change", {
      center: `${lat},${lng}`,
      zoom: this.getZoom(),
    });
  },

  updateToolbar() {
    return toolbarControl.render();
  },

  showNotes() {
    this.addLayer(notes);
    return this;
  },

  hideNotes() {
    this.removeLayer(notes);
    return this;
  },

  viewField(id) {
    const fieldGeometry = this.fields.find((field) => field.id === +id)?.geometry;

    if (fieldGeometry) {
      if (fieldGeometry.type === "Point") {
        this.panTo(fieldGeometry.coordinates.slice().reverse());
        this.setZoom(14);
      } else {
        this.fitBounds(MapUtils.getBoundsFromGeometry(fieldGeometry));
      }
    }

    return this;
  },

  showFields() {
    if (this.fieldLayers && !this.hasLayer(this.fieldLayers)) {
      this.addLayer(this.fieldLayers);
    }

    return this;
  },

  hideFields() {
    if (this.fieldLayers && this.hasLayer(this.fieldLayers)) {
      this.removeLayer(this.fieldLayers);
    }

    return this;
  },

  onDragEnd() {
    this.updateBounds();
  },

  onZoomEnd() {
    this.updateBounds();
  },

  removeLayers() {
    this.hideFields();
    this.hideNotes();
  },

  remove() {
    this.removeControl(searchControl);
    this.removeControl(toolbarControl);
    this.removeControl(yearControl);

    return Map.prototype.remove.call(this);
  },
});
