import { DatePicker } from "@farmlogs/fl-ui";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import styled from "styled-components";

import { ClearAllFormGroup, TagSelectGroup } from "components/fl-ui/Form";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  > * {
    /* this is needed because ClearAllFormGroup uses FormGroup which enforces a margin-bottom. lame. */
    margin-bottom: 0;
  }
`;

const ContractDeliveryDateFilter = ({ deliveryEndDate, deliveryStartDate, onChange }) => {
  const applyDatePreset = (selectedOption) => onChange(selectedOption.apply());

  const datePresets = useMemo(() => {
    const format = (date) => moment(date).format("YYYY-MM-DD");

    return [
      {
        label: "Today",
        value: "today",
        apply: () => ({
          deliveryEndDate: format(Date.now()),
          deliveryStartDate: format(Date.now()),
        }),
      },

      {
        label: "Last 7 days",
        value: "last7",
        apply: () => ({
          deliveryEndDate: format(Date.now()),
          deliveryStartDate: format(moment().subtract(7, "days")),
        }),
      },

      {
        label: "Last 30 days",
        value: "last30",
        apply: () => ({
          deliveryEndDate: format(Date.now()),
          deliveryStartDate: format(moment().subtract(30, "days")),
        }),
      },

      {
        label: "Last 6 months",
        value: "last6months",
        apply: () => ({
          deliveryEndDate: format(Date.now()),
          deliveryStartDate: format(moment().subtract(6, "months")),
        }),
      },

      {
        label: "Year to date",
        value: "ytd",
        apply: () => ({
          deliveryEndDate: format(Date.now()),
          deliveryStartDate: format(moment().startOf("year")),
        }),
      },
    ];
  }, []);

  return (
    <div>
      <Container>
        <ClearAllFormGroup
          onClear={() => onChange({ deliveryEndDate, deliveryStartDate: null })}
          label="Delivery start date"
        >
          <DatePicker
            onChange={(deliveryStartDate) => onChange({ deliveryEndDate, deliveryStartDate })}
            value={deliveryStartDate}
          />
        </ClearAllFormGroup>

        <ClearAllFormGroup
          onClear={() => onChange({ deliveryEndDate: null, deliveryStartDate })}
          label="Delivery end date"
        >
          <DatePicker
            onChange={(deliveryEndDate) => onChange({ deliveryEndDate, deliveryStartDate })}
            value={deliveryEndDate}
          />
        </ClearAllFormGroup>
      </Container>

      <TagSelectGroup id="datePresets" onChange={applyDatePreset} options={datePresets} preserveOptions />
    </div>
  );
};

ContractDeliveryDateFilter.propTypes = {
  deliveryEndDate: PropTypes.string,
  deliveryStartDate: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default ContractDeliveryDateFilter;
