/* eslint-disable react/display-name */
import React from "react";
import { Elements, injectStripe, StripeProvider } from "react-stripe-elements";

import useCurrentEnterprise from "hooks/useCurrentEnterprise";

import LoadingWrapper from "components/fl-ui/LoadingWrapper";

const PointOfSale = (WrappedComponent) => (props) => {
  const StripeComponent = injectStripe(WrappedComponent);
  const { currentEnterprise, loading } = useCurrentEnterprise();

  return (
    <StripeProvider apiKey={process.env.STRIPE_PUBLIC_KEY}>
      <Elements>
        <LoadingWrapper isLoading={loading}>
          <StripeComponent {...props} defaultZipCode={currentEnterprise.postalCode ?? ""} />
        </LoadingWrapper>
      </Elements>
    </StripeProvider>
  );
};

export default PointOfSale;
