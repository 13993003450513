import { css, StyleSheet } from "aphrodite/no-important";
import classNames from "classnames";
import omit from "lodash/omit";
import PropTypes from "prop-types";
import React from "react";

import { UIColors } from "components/fl-ui/colors/index";
import { BorderRadius, Borders, Mixins, Typography } from "components/fl-ui/constants";

const TYPE = {
  "": "",
  text: "select-text",
};

const COLOR = {
  primary: "select-blue",
};

const styles = StyleSheet.create({
  wrapper: {
    position: "relative",
    display: "inline-block",
    maxWidth: "100%",
    margin: 0,
    color: UIColors.medium,
    "@-moz-document url-prefix()": {
      "&:after": {
        display: "none",
      },
    },
  },
  select: {
    "-webkit-appearance": "none",
    display: "inline-block",
    maxWidth: "100%",
    width: "100% !important",
    ...Mixins.uiControl,
    position: "relative",
    padding: ".5em .8em", // match form input
    paddingRight: "2em", // room for the little arrow
    margin: 0,
    border: Borders.regular,
    borderRadius: BorderRadius.small,
    color: UIColors.regular,
    backgroundColor: UIColors.white,
    font: "inherit",
    fontWeight: Typography.weights.medium,
    fontSize: Typography.sizes.rg,
    lineHeight: 1.2,
    textAlign: "left",
    textDecoration: "none",
    "::-ms-expand": {
      display: "none",
    },
    "@-moz-document url-prefix()": {
      paddingRight: "0 !important",
    },
  },
  select_disabled: {
    opacity: 0.5,
    cursor: "not-allowed",
  },
  xxl: {
    fontSize: Typography.sizes.xxl,
  },
  xl: {
    fontSize: Typography.sizes.xl,
  },
  l: {
    fontSize: Typography.sizes.l,
  },
  m: {
    fontSize: Typography.sizes.md,
  },
  s: {
    fontSize: Typography.sizes.sm,
  },
  xs: {
    fontSize: Typography.sizes.xs,
  },
  primary: {
    color: UIColors.primary,
    borderBottom: `1px solid ${UIColors.primary}`,
    borderRadius: 0,
  },
  minimalWrapper: {
    "&:after": {
      right: "5px",
    },
  },
  minimalSelect: {
    border: "none",
    background: "transparent",
    boxShadow: "none",
    borderRadius: 0,
    paddingLeft: 0,
    paddingRight: "1.75em",
    height: "unset",
  },
  selectNext: {
    height: Mixins.toRem(40),
  },
  caret: {
    ...Mixins.beforeAfter,
    top: "50%",
    right: "10px",
    margin: "-2px 0 0",
    borderWidth: "4px 4px 0",
    borderStyle: "solid",
    borderColor: UIColors.medium + " transparent",
    pointerEvents: "none",
    "@-moz-document url-prefix()": {
      display: "none",
    },
  },
  caret_disabled: {
    display: "none",
  },
  block: {
    display: "block",
    width: "100%",
  },
  hasError: {
    border: `2px solid ${UIColors.danger}`,
    backgroundColor: UIColors.white,
  },
  legacy: { width: "auto" },
});

const Option = ({ disabled, id, label, value }) => (
  <option disabled={disabled} id={id} label={label} value={value}>
    {label}
  </option>
);

class Select extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(e) {
    if (this.props.onChange) {
      const data = { [e.target.name]: e.target.value };
      this.props.onChange(e, data);
    }
  }

  render() {
    const { className, color, displayType, style, size, minimal, legacy, placeholder, block, hasError, disabled } =
      this.props;

    const defaultOption = placeholder ? [{ label: placeholder }] : [];
    const options = defaultOption
      .concat(this.props.options)
      .map((option, i) => <Option key={`option-${i}`} {...option} />);

    const classList = classNames(
      legacy && "select-wrapper", // allows legacy classnames to be used
      legacy && css(styles.legacy),
      className,
      TYPE[displayType],
      COLOR[color],
      css([styles.wrapper, minimal && styles.minimalWrapper, block && styles.block])
    );

    const nativeProps = omit(this.props, ["block", "displayType", "hasError", "legacy", "minimal"]);

    return (
      <div className={classList} style={style}>
        <select
          {...nativeProps}
          className={css([
            styles.select,
            styles[size],
            styles[color],
            !legacy && styles.selectNext,
            minimal && styles.minimalSelect,
            block && styles.block,
            hasError && styles.hasError,
            disabled && styles.select_disabled,
          ])}
          onChange={this.handleSelect}
        >
          {options}
        </select>
        <div className={css(styles.caret, disabled && styles.caret_disabled)} />
      </div>
    );
  }
}

Select.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  displayType: PropTypes.string,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  size: PropTypes.string,
  minimal: PropTypes.bool,
  legacy: PropTypes.bool,
  value: PropTypes.node || PropTypes.string || PropTypes.number,
  block: PropTypes.bool,
  hasError: PropTypes.bool,
};

Select.defaultProps = {
  className: null,
  color: COLOR.primary,
  disabled: false,
  displayType: TYPE.text,
  options: [],
  placeholder: null,
  style: {},
  block: false,
  hasError: false,
};

export default Select;
