import { StartNavigation } from "@proteansoftware/capacitor-start-navigation";
import { css } from "aphrodite";
import { useViewportSpy } from "beautiful-react-hooks";
import { compact } from "lodash";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";
import PopoverButton from "components/fl-ui/Buttons/PopoverButton";
import styles from "fields/common/styles";
import FieldIndexContext from "fields/components/context/FieldIndexContext";
import { isNative } from "mobile/mobileManager";

const FieldCardMenu = ({ field }) => {
  const { centroid, id, name } = field;
  const { onAddCrop, onEditField, onDeleteField, onEditBoundaries } = useContext(FieldIndexContext);
  const ref = useRef();
  const isVisible = useViewportSpy(ref);
  const [renderPopover, setRenderPopover] = useState(isVisible);
  const [longitude, latitude] = centroid?.coordinates ?? [];
  const showNavigateHereAction = isNative() && !!centroid?.coordinates;

  useEffect(() => {
    if (isVisible && !renderPopover) {
      setRenderPopover(true);
    }
  }, [isVisible]);

  const menuItems = compact([
    {
      label: "Edit field attributes",
      onSelect: () => onEditField(id),
    },
    {
      label: "Edit field boundaries",
      onSelect: () => onEditBoundaries(id),
    },
    {
      label: "Add crop",
      onSelect: () => onAddCrop(id),
    },
    showNavigateHereAction && {
      label: "Navigate here",
      onSelect: () =>
        StartNavigation.launchMapsApp({
          latitude,
          longitude,
          name: name,
        }),
    },
    {
      label: "Delete field",
      color: "danger",
      onSelect: () => onDeleteField(id),
    },
  ]);

  return (
    <div ref={ref} className={css(styles.fieldGroup_popover)}>
      {renderPopover && <PopoverButton options={menuItems} />}
    </div>
  );
};

FieldCardMenu.propTypes = {
  field: PropTypes.object.isRequired,
};

export default FieldCardMenu;
