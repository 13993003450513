import * as turf from "@turf/turf";
import { partition } from "lodash";
import { useMemo } from "react";

import GeoJsonLayer from "lib/map/layers/GeoJsonLayer";

import { getCropGeoJSON, getLayerFillPattern, getLargestIntersection } from "components/field/utils";

export const addCropGeometryDiffToCollection = (crop, intersectingCrop, collection) => {
  const feature = turf.difference(crop.geometry, intersectingCrop.geometry);

  if (feature) {
    feature.properties.id = crop.id;
    feature.properties.color = crop.commodity.color;
    collection.addFeature(feature);
  }
};

export const getCropLayer = ({ color, field, fieldCrops, highlightCrop, isLayerOnly }) => {
  const data = { layer: null };

  if (field) {
    const featureCollection = new GeoJsonLayer({
      feature: getCropGeoJSON({ color, field }),
      hasOutline: true,
      isLayerOnly,
      style: { color: "black", opacity: 0.7, weight: 1.5 },
    });

    if (highlightCrop) {
      // paint a single crop layer on the map
      const cropFeature = getCropGeoJSON({ crop: highlightCrop });
      featureCollection.addFeature(cropFeature);
    } else if (!color && fieldCrops) {
      // paint all available crop layers onto the map
      const { crops, intersection } = getLargestIntersection(fieldCrops) ?? {};
      const [intersecting, nonIntersecting] = partition(fieldCrops, ({ id }) => crops?.some((crop) => crop.id === id));
      nonIntersecting.forEach((crop) => featureCollection.addFeature(getCropGeoJSON({ crop })));

      if (intersecting.length) {
        const [firstCrop, secondCrop] = intersecting;
        const fillPattern = getLayerFillPattern(firstCrop.commodity.color, secondCrop.commodity.color);
        intersection.properties.id = "intersectionLayer";
        intersection.properties.style = { fillPattern };
        addCropGeometryDiffToCollection(firstCrop, secondCrop, featureCollection);
        addCropGeometryDiffToCollection(secondCrop, firstCrop, featureCollection);
        featureCollection.addFeature(intersection);
        data.pattern = fillPattern;
      }
    }

    data.layer = featureCollection;
  }

  return data;
};

const useCropLayer = ({ commodity, crops: fieldCrops, field, isLayerOnly }) => {
  const highlightCrop = commodity && fieldCrops?.find((crop) => crop.commodity.id === commodity.id);
  // paint the crop on a white background if it needs to be highlighted otherwise paint the map with the commodity color
  const color = highlightCrop ? "white" : commodity?.color;

  return useMemo(() => {
    return getCropLayer({ color, field, fieldCrops, highlightCrop, isLayerOnly });
  }, [color, field, fieldCrops]);
};

export default useCropLayer;
