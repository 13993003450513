import { StyleSheet } from "aphrodite/no-important";
import _ from "lodash";
import moment from "moment";

import { GreyColors } from "components/fl-ui/colors";
import { UIColors } from "components/fl-ui/colors";
import { Spacing, Typography } from "components/fl-ui/constants";

const chartStyles = StyleSheet.create({
  chartContainer: {
    marginBottom: Spacing.xxlarge,
  },
  blue: {
    color: UIColors.blue,
  },
  red: {
    color: UIColors.red,
  },
  refAreaLabel: {
    fontWeight: Typography.weights.medium,
  },
  noSelect: {
    userSelect: "none",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    "@media only screen and (max-width: 30em)": {
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
  },
  controlContainer: {
    marginLeft: Spacing.small,
    "@media only screen and (max-width: 30em)": {
      marginLeft: 0,
      marginTop: Spacing.small,
      marginBottom: Spacing.small,
    },
  },
});

const inlineStyles = {
  refLineLabel: {
    textTransform: "uppercase",
    color: `${UIColors.light} !important`,
    fontWeight: Typography.weights.medium,
    fontSize: Typography.sizes.sm,
    userSelect: "none",
  },
  refAreaLabel: {
    userSelect: "none",
  },
  legend: {
    paddingTop: Spacing.xxlarge,
  },
};

const xAxisProps = {
  dataKey: "date",
  domain: ["dataMin", "dataMax"],
  stroke: GreyColors.smoke91,
  tick: {
    fill: GreyColors.smoke50,
    fontSize: Typography.sizes.rg,
    lineHeight: Typography.sizes.xl,
    userSelect: "none",
  },
  tickFormatter: (d) => moment(d).format("MMM"),
  tickMargin: 10,
};

const yAxisProps = {
  yAxisId: 1,
  axisLine: false,
  orientation: "right",
  tick: { fill: GreyColors.smoke50, fontSize: Typography.sizes.rg, userSelect: "none" },
  tickLine: false,
  scale: "linear",
  type: "number",
};

const getTicks = (data) => {
  return _.reduce(
    data,
    (result, { date }) => {
      if (+moment(date).format("D") === 15) {
        result.push(date);
      }
      return result;
    },
    []
  );
};

const chartMargins = { top: 5, right: 0, left: 0, bottom: 5 };

const getColor = (aspect) => {
  return aspect === "rainfall" ? `${UIColors.blue}` : `${UIColors.red}`;
};

const getColorClass = (aspect) => {
  return aspect === "rainfall" ? chartStyles.blue : chartStyles.red;
};

const getUnit = (aspect) => {
  return aspect === "rainfall" ? '"' : " GDD";
};

export { chartStyles, inlineStyles, xAxisProps, yAxisProps, chartMargins, getColor, getColorClass, getUnit, getTicks };
