import { gql } from "@apollo/client";

const getPlantedCropsForField = gql`
  query getPlantedCropsForField($fieldId: Int!, $cropYear: Int!) {
    plantedCrops: getFieldCrops(fields: [$fieldId], activityTypeCategories: [PLANTING], years: [$cropYear]) {
      id
      commodity {
        id
        gddTbase
        gddTceil
      }
      cropYear
      plantingDate
    }
  }
`;

export default getPlantedCropsForField;
