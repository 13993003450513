import PropTypes from "prop-types";
import React from "react";

import useKeyedList from "hooks/useKeyedList";

import { Button } from "components/fl-ui";
import { Stack } from "components/fl-ui/Layout";
import ProductChooser from "components/product/ProductChooser";

const RecipeIngredientsList = ({ ingredients, onChange }) => {
  const ingredientList = useKeyedList(ingredients, onChange);
  const addIngredient = () =>
    ingredientList.append({
      amount: undefined,
      amountUnit: "",
      product: undefined,
      rateUnit: "",
    });

  return (
    <Stack>
      {ingredientList.map((ingredient, key) => (
        <ProductChooser
          input={ingredient}
          inputType="INGREDIENT"
          key={`ingredient${key}`}
          onChange={(values) => ingredientList.update(key, values)}
          onRemove={ingredients.length > 1 ? () => ingredientList.remove(key) : undefined}
        />
      ))}

      <Button color="primary" hollow onClick={addIngredient}>
        Add Ingredient
      </Button>
    </Stack>
  );
};

RecipeIngredientsList.propTypes = {
  ingredients: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number,
      amountUnit: PropTypes.string,
      product: PropTypes.number,
      rate: PropTypes.string,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RecipeIngredientsList;
