import _ from "lodash";

import { DONE, OVERDUE, PLANNED } from "lib/constants";

/**
 * Filters an array of activities by their status.
 *
 * @param {Activity[]} activities
 * @param {Object} filters
 * @param {String[]} filters.status
 * @param {Function} markAsApplied
 * @return {Activity[]}
 */
const statusFilter = (activities, { status }, markAsApplied) => {
  if (status.length > 0) {
    status = status.map(_.toUpper);
    const includePlanned = status.includes(PLANNED);
    const includeOverdue = status.includes(OVERDUE);

    const conditions = [];
    if (status.includes(DONE)) {
      conditions.push(_.property("isDone"));
    }

    if (includePlanned && includeOverdue) {
      conditions.push(_.property("isPlanned"));
    } else if (includePlanned) {
      conditions.push(({ isOverdue, isPlanned }) => isPlanned && !isOverdue);
    } else if (includeOverdue) {
      conditions.push(({ isOverdue, isPlanned }) => isPlanned && isOverdue);
    }

    activities = _.filter(activities, (activity) => conditions.some((condition) => condition(activity)));
    markAsApplied();
  }

  return activities;
};

export default statusFilter;
