import d3 from "d3";
import leaflet from "leaflet";
import moment from "moment";
import _ from "underscore";

// Layer Interfaces
import LeafImageryLayer from "lib/map/imagery/leaf/LeafImageryLayer";
import SoilLayer from "lib/map/layers/soil";
import WdrviLayer from "lib/map/layers/wdrvi";
import YieldLayer from "lib/map/layers/yield";
import MapUtils from "lib/map/utils";
import naturalSort from "lib/naturalSort";

const formatDate = d3.time.format.utc("%b %-e, %Y");
const formatDateString = (dateString) => formatDate(new Date(dateString));
const commas = d3.format(",");
const single = d3.format(".1f");
const formatNumber = (n) => commas(single(n));
const formatPercent = (n) => formatNumber(n * 100) + "%";
const formatSoilDescription = (description) => description.split(", ")[0].replace(" percent", "%").replace(" to ", "-");

export const getCloudCoverage = (details) => {
  if (details.hasOwnProperty("clouds")) {
    return details.clouds;
  }

  const cloudCoverage = +(100 - details.usable_data_coverage).toFixed();
  return cloudCoverage >= 10 ? cloudCoverage : null;
};

export const getFieldLayers = (field, fieldCrops) => {
  const layer = {
    acreage: field?.acreage,
    field_id: field?.id,
    geometry: field?.geometry,
    type: "crops",
  };

  const { acreage, geometry } = layer;
  const Layer = leaflet.GeoJSON.bind(null, geometry, {
    style: {
      color: "#fff",
      opacity: 1,
      fillOpacity: 0,
      weight: 2,
    },
  });

  const details = {
    description: fieldCrops.map(({ commodity }) => commodity.name).join(", ") || "No Crop",
    title: `${formatNumber(acreage)} ac`,
  };

  return [
    {
      ...layer,
      layer: new Layer(),
      Layer,
      details,
    },
  ];
};

export const imageryTypeMap = {
  NDVI: "NDVI",
  "True Color": "RGB",
  NDVI_relative: "NDVI_relative",
};

export const getTypeUrl = (layer, type) => layer?.details?.images[imageryTypeMap[type]]?.url || "";

// This process is expensive. Use only if absolutely needed to mount the layer on the map.
export const sanitizeImagery = (layer, imageryType = "True Color") => {
  const field_id = layer?.field?.id;
  const { bounds } = MapUtils.fitBounds(layer?.field?.geometry, 256, 256);
  const date = layer.date || layer.details.date;
  const isLeafImagery = layer.type === "satellite.leaf";
  const LayerClass = isLeafImagery ? LeafImageryLayer : WdrviLayer;

  const newLayer = {
    ...layer,
    date: moment(date),
    details: {
      ...layer.details,
      data: {
        alert: layer.anomaly,
        cloudCoverage: getCloudCoverage(layer.details),
        learnMore: true,
      },
      description: moment(date).format("MMM DD, YYYY"),
      title: "Sentinel-2 Imagery",
      url: isLeafImagery && getTypeUrl(layer, imageryType),
    },
    field_id,
    id: layer.id || layer.details.id,
    source: isLeafImagery ? "leaf" : "planet",
    type: "imagery",
  };

  const Layer = LayerClass.bind(null, { bounds, layer: { ...newLayer } });
  newLayer.layer = new Layer();
  newLayer.details.data.legend = new Layer().getLegend();

  if (layer.truecolor) {
    const truecolorLayer = { ...newLayer, type: "truecolor" };
    newLayer.truecolor = LayerClass.bind(null, { bounds, truecolorLayer });
  }

  return newLayer;
};

export const sanitizeSoilLayers = (layers) =>
  layers.map((layer) => {
    const Layer = SoilLayer.bind(null, { geometry: layer.geometry });
    const soilLayer = new Layer();
    const info = soilLayer
      .getProperties()
      .sort((a, b) => -naturalSort(a.acreage, b.acreage))
      .map((soil) => ({
        id: soil.id,
        color: { code: soil.color },
        title: _.titleize(soil.name),
        description: [
          `${formatNumber(soil.acreage)} ac ${soil.description ? `• ${formatSoilDescription(soil.description)}` : ""}`,
        ],
      }));

    return {
      ...layer,
      Layer,
      layer: soilLayer,
      details: { data: { info }, description: "SSURGO", title: "Soil Types" },
    };
  });

export const sanitizeYieldLayers = (layers) =>
  layers.map((layer) => {
    const { details, updated } = layer;
    const Layer = YieldLayer.bind(null, layer);

    return {
      ...layer,
      layer: new Layer(),
      Layer,
      details: {
        data: {
          highlights: [
            ["Avg Yield", formatNumber(details.avg_interpolated_yield) + " bu/ac"],
            ["Avg Moisture", formatPercent(details.avg_moisture / 100)],
          ],
          legend: new Layer().getLegend(),
        },
        description: formatDateString(updated),
        title: "Yield",
      },
    };
  });
