import PropTypes from "prop-types";
import React from "react";

import useFieldAttachments from "collection/graphql/fields/hooks/useFieldAttachments";

import { Button } from "components/fl-ui";
import PayGatedFileAttachments from "components/fl-ui/Attachments/PayGatedFileAttachments";

const FieldAttachmentButton = ({ fieldId }) => {
  const { updateAttachments } = useFieldAttachments(fieldId);

  return (
    <PayGatedFileAttachments onChange={updateAttachments}>
      {({ onClick }) => (
        <Button color="primary" hollow onClick={onClick}>
          Upload Attachments
        </Button>
      )}
    </PayGatedFileAttachments>
  );
};

FieldAttachmentButton.propTypes = {
  fieldId: PropTypes.number.isRequired,
};

export default FieldAttachmentButton;
