import React from "react";

import ActivitiesBlankSlateBanner from "components/advertisements/banners/ActivitiesBlankSlateBanner";
import PayGateCommonBlankStateModal from "components/advertisements/modals/PayGateCommonBlankStateModal";

const ActivitiesBlankStateModal = (props) => (
  <PayGateCommonBlankStateModal {...props} banner={ActivitiesBlankSlateBanner} />
);

export default ActivitiesBlankStateModal;
