import PropTypes from "prop-types";
import React from "react";

const { bool } = PropTypes;

const LoadingSpinner = ({ loading = true, simple = false, children }) => {
  if (!loading) {
    return <span>{children}</span> || <noscript />;
  }

  return <div className={`${simple ? "loading-spinner-only" : "loading-spinner"}`} />;
};

LoadingSpinner.displayName = "LoadingSpinner";

LoadingSpinner.propTypes = {
  loading: bool,
  simple: bool,
};

export default LoadingSpinner;
