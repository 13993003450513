import useCurrentEnterprise from "hooks/useCurrentEnterprise";

/**
 * A hook that returns a boolean value indicating whether the current user
 * has a specific enterprise flag enabled on their account.
 *
 * @param {EnterpriseFeature} featureId
 * @return {boolean|undefined}
 */
const useEnterpriseFeature = (featureId) => {
  return useCurrentEnterprise().currentEnterprise?.features?.includes(featureId) ?? undefined;
};

export default useEnterpriseFeature;
