// implement types
export const FERTILIZER = 4;
export const HARVESTER = 2;
export const OTHER = 8;
export const PLANTER = 1;
export const SPRAYER = 3;
export const TILLER = 7;
export const WAGON = 6;

export const TRACTOR = "tractor";
