import { StyleSheet, css } from "aphrodite/no-important";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

const styles = StyleSheet.create({
  row: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    lineHeight: "2em",
  },
  cell: {
    flexGrow: 1,
    textAlign: "left",
  },
  right: {
    textAlign: "right",
  },
  center: {
    textAlign: "center",
  },
});

const CellRow = (props) => <div className={classNames(css(styles.row), props.className)}>{props.children}</div>;

const Cell = (props) => (
  <div
    className={classNames(
      css(styles.cell, props.right && styles.right, props.center && styles.center),
      props.className
    )}
    onClick={props.onClick}
  >
    {props.children}
  </div>
);

Cell.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  right: PropTypes.bool,
  center: PropTypes.bool,
};

Cell.defaultProps = {
  className: "",
  onClick: () => null,
  right: false,
  center: false,
};

export { CellRow };
export default Cell;
