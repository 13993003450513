import { ValidationError } from "yup";

/**
 * Validates a specific path within the given schema. If validation errors occur an array of errors is returned
 *
 * @callback ErrorsAtPathFunction
 * @param {String} path a string path (field name) to validate within the schema
 * @returns {void|String[]} if validation errors occur an array of errors is returned
 */

/**
 * Validates all values against the given schema.
 *
 * @callback IsValidFunction
 * @return {Boolean}
 */

/**
 * A generic hook for validating a schema against values. If this proves to have utility we
 * should migrate it to the top-level `hooks` directory.
 *
 * @param {Object} schema a validation schema
 * @param {Object} values values to validate
 * @param {Object} [options={}] a context object passed into the schema's validation methods
 * @param {Boolean} [options.abortEarly=false]
 * @param {Object} [options.context={}]
 * @return {{ errorsAtPath: ErrorsAtPathFunction, isValid: IsValidFunction }}
 */
const useSchemaValidation = (schema, values, options = {}) => {
  return {
    /**
     * @type {ErrorsAtPathFunction}
     */
    errorsAtPath: (path) => {
      try {
        schema.validateSyncAt(path, values, { abortEarly: false, ...options });
      } catch (error) {
        if (error instanceof ValidationError) {
          return error.errors;
        }
        throw error;
      }
    },

    /**
     * @type {IsValidFunction}
     */
    isValid: () => schema.isValidSync(values, { ...options, abortEarly: false }),
  };
};

export default useSchemaValidation;
