// TODO: move ExpansionCell import out of contracts to shared area
import { css } from "aphrodite/no-important";
import ExpansionCell from "contracts/components/ExpansionCell";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkDirective from "remark-directive";
import remarkDirectiveRehype from "remark-directive-rehype";
import styled from "styled-components";

import cardStyles from "modules/marketing/cards/cardStyles";
import { CommentaryBodyArea } from "modules/marketing/cards/components";
import { CommentaryExpansionHeight } from "modules/marketing/cards/constants/commentaryExpansionHeight";
import CommentaryCommodityReport from "modules/marketing/components/CommentaryCommodityReport";

import { getImageUrl } from "components/field/utils";
import { Button } from "components/fl-ui";
import { Card } from "components/fl-ui/Card";
import { GreyColors, ColorPalette } from "components/fl-ui/colors";
import { BorderRadius, Borders, Spacing } from "components/fl-ui/constants";

//TODO: If we continue to add complexity this needs to be three separate components rather than all in one
const CommentaryCard = styled(
  ({
    assets,
    audioSrc,
    audioTitle,
    body,
    className,
    date,
    expandButtonText,
    headerToggle,
    isNew,
    title,
    weeklyReportDetails,
  }) => {
    const [expanded, setExpanded] = useState(false);
    const [commentaryContentHeight, setCommentaryContentHeight] = useState(0);
    const elementRef = useRef(null);

    useEffect(() => {
      setCommentaryContentHeight(elementRef?.current?.clientHeight || 0);
    }, [expanded]);

    const expandable = commentaryContentHeight > CommentaryExpansionHeight - 1;
    const imageAssets = _.filter(assets, { type: "IMAGE" });

    const urlTransform = (url) => {
      const protocolPattern = /^https?:\/\/.+/;
      if (!protocolPattern.test(url)) {
        return "https://" + url;
      }

      return url;
    };

    return (
      <div className={className}>
        <Card className={css(cardStyles.cropCard)}>
          <div className="commentary-header">
            <div className="commentary-title">
              {isNew && <div className="commentary-new">NEW</div>} {title}
            </div>
            <div className="commentary-date">{date}</div>
            {headerToggle && (
              <ExpansionCell
                className="commentary-toggle"
                expanded={expanded}
                toggleRow={() => setExpanded((prevState) => !prevState)}
              />
            )}
          </div>

          {(!headerToggle || (headerToggle && expanded)) && (
            <div ref={elementRef}>
              <div className="commentary-body">
                {audioSrc && (
                  <>
                    <h3>{`Tune into today's market update!`}</h3>
                    <div className="commentary-audio">
                      <img
                        className="cargill-elevate-logo"
                        height="80"
                        src="/images/marketing/cargill-elevate-podcast.jpg"
                      />
                      <div className="commentary-audio-player">
                        <div className="commentary-audio-title">{audioTitle}</div>
                        <audio id="player" controls>
                          <source src={audioSrc} type="audio/mpeg" />
                          Your browser does not support the audio element.
                        </audio>
                      </div>
                    </div>
                  </>
                )}

                <CommentaryBodyArea expandable={expandable} expanded={expanded}>
                  {imageAssets.map((asset) => (
                    <img key={asset.filepickerId} src={getImageUrl(asset.filepickerId)} />
                  ))}

                  <ReactMarkdown
                    allowedElements={["a", "b", "br", "em", "i", "li", "ol", "p", "strong", "ul"]}
                    components={{
                      b: "strong",
                      i: "em",
                    }}
                    rehypePlugins={[rehypeRaw]}
                    remarkPlugins={[remarkDirective, remarkDirectiveRehype]}
                    urlTransform={urlTransform}
                  >
                    {body}
                  </ReactMarkdown>

                  {weeklyReportDetails?.map((detailsObject) => (
                    <CommentaryCommodityReport
                      key={"commentary-commodity-report" + detailsObject.detail?.commodity?.id}
                      detail={detailsObject.detail}
                    />
                  ))}
                </CommentaryBodyArea>
              </div>

              {expandable && !headerToggle && (
                <Button
                  className="commentary-view-more"
                  color="primary"
                  link
                  onClick={() => setExpanded((prevState) => !prevState)}
                >
                  <span>{expanded ? "View Less" : expandButtonText}</span>
                </Button>
              )}
            </div>
          )}
        </Card>
      </div>
    );
  }
)`
  margin: ${Spacing.medium} 0;

  audio::-webkit-media-controls-enclosure {
    background: none;
  }

  .commentary-audio {
    border: ${Borders.regular};
    border-radius: ${BorderRadius.small};
    padding: ${Spacing.medium};
    margin-top: ${Spacing.medium};
    display: flex;
    flex-flow: row wrap;
  }
  .commentary-audio-player {
    flex-grow: 1;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
  }
  .commentary-audio-title {
    margin-left: 20px;
  }
  .commentary-audio audio {
    width: 100%;
  }

  .commentary-header {
    padding-right: ${({ headerToggle }) => (headerToggle ? "30px" : 0)};
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    color: ${GreyColors.smoke50};
    align-items: flex-start;
    justify-content: space-between;
  }
  .commentary-new {
    background-color: ${ColorPalette["seafoam-green"]};
    color: ${ColorPalette["dark-green"]};
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    margin: 0 8px 0 0;
    padding: 0 2px;
    display: inline-block;
  }
  .commentary-body {
    a {
      color: ${ColorPalette["blue"]};
    }
    ul {
      padding-left: ${Spacing.large};
      li {
        list-style-position: outside;
        list-style-type: disc;
        padding: ${Spacing.xxsmall};
      }
    }

    ol {
      padding-left: ${Spacing.large};
      li {
        list-style-position: outside;
        list-style-type: decimal;
        padding: ${Spacing.xxsmall};
      }
    }

    border-top: ${Borders.regular};
    margin-top: ${Spacing.medium};
    padding-top: ${Spacing.medium};
  }
  .commentary-toggle {
    position: absolute;
    right: 0;
  }

  .commentary-view-more {
    width: 100%;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid ${GreyColors.smoke90};
    display: block;
  }
`;

CommentaryCard.propTypes = {
  audioSrc: PropTypes.string,
  audioTitle: PropTypes.string,
  body: PropTypes.string,
  className: PropTypes.string,
  date: PropTypes.string.isRequired,
  details: PropTypes.arrayOf(PropTypes.object),
  expandable: PropTypes.bool,
  expandButtonText: PropTypes.string,
  expandedBody: PropTypes.string,
  headerToggle: PropTypes.bool,
  isNew: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

export { CommentaryCard };
