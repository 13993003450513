import { css } from "aphrodite/no-important";
import React from "react";

import PayGateCommonBlankSlateBanner from "components/advertisements/banners/PayGateCommonBlankSlateBanner";
import styles from "components/advertisements/styles";

const ActivitiesBlankSlateBanner = (props) => {
  props = {
    ...props,
    actionButtonText: "Try Activities for free",
    secondaryText: "Activities is included in the Essentials plan, and you can try it for free.",
    imagePath: "images/pay_gates/banners/activities.svg",
    title: "With Activities you can:",
  };

  return (
    <PayGateCommonBlankSlateBanner {...props}>
      <ul>
        <li className={css(styles.valueProp)}>
          Easily log and review all your field work in one place to keep your records organized, secure, and accessible
          from any desktop or mobile device.
        </li>
        <li className={css(styles.valueProp)}>
          Log standard activities like planting, spraying, and harvesting to multiple fields at once or track custom
          activity types to get more specific about your operations.
        </li>
        <li className={css(styles.valueProp)}>
          Automatically analyze your cost of production by field, activity type, commodity, or across the entire farm
          without any additional effort.
        </li>
      </ul>
    </PayGateCommonBlankSlateBanner>
  );
};

export default ActivitiesBlankSlateBanner;
