/* eslint-disable react/display-name */
import React from "react";

import { BasicInput } from "components/fl-ui/Form/Input";
import { Prefix, Suffix } from "components/fl-ui/Form/Input/ui";
import NumericInput from "components/fl-ui/Form/NumericInput";

export default (props) => {
  switch (props.type) {
    case "currency":
      return (
        <NumericInput
          {...props}
          prefix={<Prefix style={{ fontSize: "12px", paddingLeft: "0" }}>$</Prefix>}
          render={BasicInput}
          type="currency"
        />
      );

    case "number":
      return <NumericInput {...props} render={BasicInput} />;

    case "percentage":
      return (
        <NumericInput
          {...props}
          render={BasicInput}
          suffix={<Suffix style={{ fontSize: "12px", paddingRight: "0" }}>%</Suffix>}
          type="float"
        />
      );

    default:
      return <BasicInput {...props} />;
  }
};
