/* eslint-disable react/display-name */
import { PER_ACRE } from "agreements/constants";
import { currency } from "marketing/utils/format";
import PropTypes from "prop-types";
import React from "react";

import usePermissions from "hooks/usePermissions";

import FieldDetailsTableRow from "components/field/FieldDetailsTableRow";
import { Button } from "components/fl-ui";

const EstimatedLandValue = ({ field, onAddLandValue }) => {
  const landValueMap = {
    PER_ACRE: field.landValuePerAcre,
    TOTAL: field.landValueTotal,
  };
  const value =
    field.landValueRate && +field.landValuePerAcre ? (
      `${currency(landValueMap[field.landValueRate])} ${field.landValueRate === PER_ACRE ? "/ ac" : ""}`
    ) : (
      <Button color="primary" lightWeight link onClick={() => onAddLandValue()}>
        Set
      </Button>
    );

  return <FieldDetailsTableRow label="Estimated land value" value={value} />;
};

EstimatedLandValue.propTypes = {
  field: PropTypes.shape({
    landValuePerAcre: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.string]),
    landValueRate: PropTypes.string,
    landValueTotal: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.string]),
  }).isRequired,
  onAddLandValue: PropTypes.func.isRequired,
};

const withPermissionProtection = (WrappedComponent) => (props) => {
  const { canRead } = usePermissions();
  return canRead("agreements") ? <WrappedComponent {...props} /> : null;
};

export default withPermissionProtection(EstimatedLandValue);
