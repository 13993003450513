import React from "react";
import { components } from "react-select";
import styled from "styled-components";

import { GreyColors } from "components/fl-ui/colors";

const ColorSample = styled.div`
  background-color: ${({ color }) => color};
  border: 1px solid ${GreyColors.smoke60};
  border-radius: 2px;
  display: inline-block;
  height: 15px;
  width: 15px;
`;

/**
 * A SingleValue component usable within a ReactSelect component.
 * @private
 * @return {React.FC}
 */
const CommodityColorPickerSingleValue = (props) => (
  <components.SingleValue {...props}>
    <ColorSample color={props.data.color} /> {props.data.label}
  </components.SingleValue>
);

export default CommodityColorPickerSingleValue;
