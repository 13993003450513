import { gql } from "@apollo/client";

import { Priceline } from "collection/graphql/contracts/fragments";

export default gql`
  query getPriceline($id: Int!) {
    getPricelines(contractId: $id) {
      ...Priceline
    }
  }
  ${Priceline}
`;
