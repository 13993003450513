import React from "react";
import styled from "styled-components";

import { Icon } from "components/fl-ui/Icons";
import Tooltip from "components/fl-ui/Tooltip";
import { ColorPalette, GreyColors } from "components/fl-ui/colors";
import { isLight } from "components/fl-ui/colors/crops";

/**
 * An Option component usable withing a ReactSelect component.  The data property
 * corresponds to a {@link CommodityColorOption} instance.
 * @private
 * @return {React.FC}
 */
const CommodityColorPickerOption = styled(({ className, data, isSelected, selectOption }) => (
  <Tooltip message={data.label}>
    <button type="button" className={className} onClick={() => selectOption(data)} role="menuitem">
      {isSelected ? <Icon icon="check" /> : ""}
    </button>
  </Tooltip>
))`
  background-color: ${({ data }) => data.color};
  border: 1px solid ${GreyColors.smoke60};
  border-radius: 100px;
  color: ${({ data }) => (isLight(data.color) ? GreyColors.smoke30 : ColorPalette.white)};
  cursor: pointer;
  height: 35px;
  width: 35px;
`;

export default CommodityColorPickerOption;
