import * as yup from "yup";

import ProductPurchaseInputSchema from "collection/graphql/products/schemas/ProductPurchaseInputSchema";

const PurchaseInputSchema = yup.object().shape({
  amountUnit: ProductPurchaseInputSchema.fields.amountUnit.clone(),
  id: ProductPurchaseInputSchema.fields.id.clone(),
  product: ProductPurchaseInputSchema.fields.product.clone(),
  cost: ProductPurchaseInputSchema.fields.cost.clone(),
  costRate: ProductPurchaseInputSchema.fields.costRate.clone(),

  amount: yup
    .number()
    .label("Amount")
    .required()
    .lessThan(10000000, "${path} is too large")
    .test("nonZero", "${path} of 0 is not allowed", (value) => value !== 0),
});

export default PurchaseInputSchema;
