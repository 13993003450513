import moment from "moment";
import PropTypes from "prop-types";
import React from "react";

import getRainfallHistory from "collection/graphql/weather/getRainfallHistory";
import useRestQuery from "hooks/useRestQuery";

import AspectSummary, { RainfallStat } from "components/field/AspectSummary";
import useWeatherQueryOptions from "components/field/weather/hooks/useWeatherQueryOptions";
import { calculateHeatAndRainfallTotals } from "components/field/weather/lib";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";

const RainfallSummary = (props) => {
  const { cropYear, fieldId } = props;
  const { endDate, geometry, lastPlantedCrop, loading, startDate } = useWeatherQueryOptions(fieldId, cropYear);
  const hasPlanted = !!lastPlantedCrop;
  const dateFormat = moment(startDate).year() === cropYear ? "MMM D" : "MMM D, YYYY";

  const { data, loading: rainfallLoading } = useRestQuery(getRainfallHistory, {
    skip: loading,
    variables: { endDate, geometry, startDate },
  });

  const { dailyTotal, percentChange } = calculateHeatAndRainfallTotals(data?.daily, data?.averages);
  const showSecondaryStat = !isNaN(percentChange);
  const isLoading = loading || rainfallLoading;

  return isLoading ? (
    <LoadingWrapper isLoading />
  ) : (
    <AspectSummary linkHref={`#fields/${fieldId}/rainfall`} linkText="View rainfall from all years" title="Rainfall">
      <RainfallStat label={`since ${moment(startDate).format(dateFormat)}`} highlight={hasPlanted}>
        <big>{dailyTotal.toFixed(2)}</big> inches
      </RainfallStat>

      {showSecondaryStat && (
        <RainfallStat label="than avg" highlight={hasPlanted}>
          {Math.abs(percentChange * 100).toFixed(1)}% {percentChange > 0 ? "wetter" : "drier"}
        </RainfallStat>
      )}
    </AspectSummary>
  );
};

RainfallSummary.propTypes = {
  cropYear: PropTypes.number.isRequired,
  fieldId: PropTypes.number.isRequired,
};

export default RainfallSummary;
