import { useCallback, useEffect, useRef, useState } from "react";

/*
 * Taken from https://github.com/streamich/react-use/blob/master/src/useMountedState.ts
 */
const useMountedState = () => {
  const mountedRef = useRef(false);
  const callback = useCallback(() => mountedRef.current, []);

  useEffect(() => {
    mountedRef.current = true;
    return () => (mountedRef.current = false);
  }, []);

  return callback;
};

/*
 * Taken from https://github.com/streamich/react-use/blob/master/src/useAsyncFn.ts
 */
const useAsyncFn = (fn, deps = [], initialState = { loading: false }) => {
  const lastCallId = useRef(0);
  const isMounted = useMountedState();
  const [state, setState] = useState(initialState);

  const callback = useCallback((...args) => {
    const callId = ++lastCallId.current;
    if (!state.loading) {
      setState((prevState) => ({ ...prevState, loading: true }));
    }

    return fn(...args).then(
      (value) => {
        if (isMounted() && callId === lastCallId.current) {
          setState((prevState) => ({ ...prevState, loading: false, value }));
        }
        return value;
      },
      (error) => {
        if (isMounted()) {
          setState((prevState) => ({ ...prevState, error, loading: false }));
        }
        return error;
      }
    );
  }, deps);

  return [state, callback];
};

export default useAsyncFn;
