import { gql } from "@apollo/client";

const deleteActivityMutation = gql`
  mutation deleteActivityById($id: Int!) {
    deleteActivity(id: $id) {
      ok
    }
  }
`;

export default deleteActivityMutation;
