import _ from "lodash";
import { useReducer } from "react";

import { useAuth } from "collection/graphql/auth";
import { GET_CURRENT_USER } from "collection/graphql/auth/queries";
import { createPerson, updatePerson } from "collection/graphql/people/mutations";
import { getAllPeople } from "collection/graphql/people/queries";
import { LegacyPersonSchema } from "collection/graphql/people/schemas/LegacyPersonSchema";
import useRestMutation from "hooks/useRestMutation";
import useRestSuspenseQuery from "hooks/useRestSuspenseQuery";
import App from "layout/app";

const defaultState = {
  newlyAddedUserEmail: "",
  showUserModal: false,
  userData: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case "hideUserModal":
      return {
        ...state,
        showUserModal: false,
        userData: {},
      };

    case "onAddUser":
      return {
        ...state,
        newlyAddedUserEmail: "",
        showUserModal: true,
        userData: { role: action.role },
      };

    case "onEditUser":
      return {
        ...state,
        showUserModal: true,
        userData: action.userData,
      };

    case "setNewlyAddedUserEmail":
      return {
        ...state,
        newlyAddedUserEmail: action.email,
      };
  }
};

export const SAVE_SUCCESS_MESSAGE = "Person added/updated.";
export const SAVE_FAILURE_MESSAGE = "Unable to add person";

const useUserSettingsPage = () => {
  const [state, dispatch] = useReducer(reducer, defaultState);
  const { currentUser } = useAuth();
  const {
    data: { people },
  } = useRestSuspenseQuery(getAllPeople);
  const createUser = useRestMutation(createPerson)[0];
  const updateUser = useRestMutation(updatePerson)[0];

  const roles = ["admin", "contributor", "manager", "no_access"];

  const userGroups = roles.reduce((userGroups, role) => {
    userGroups[role] = _.filter(people, { role });
    return userGroups;
  }, {});

  const addAnotherUser = () => {
    const lastAddedUser = _.find(people, { email: state.newlyAddedUserEmail });
    dispatch({
      role: lastAddedUser.role,
      type: "onAddUser",
    });
  };

  const onSave = async (newData) => {
    const { email, id, role } = newData;
    const isNew = !id;
    const save = isNew ? createUser : updateUser;

    const additionalFields = {
      isAdmin: role === "admin" || role === "manager",
    };

    const input = LegacyPersonSchema.cast(
      { ...newData, ...additionalFields },
      {
        assert: false,
        stripUnknown: true,
      }
    );

    dispatch({ type: "hideUserModal" });
    const refetchQueries = [getAllPeople];
    if (id === currentUser.id) {
      refetchQueries.push(GET_CURRENT_USER);
    }

    try {
      await save({
        refetchQueries,
        variables: { input },
      });

      App.notify(SAVE_SUCCESS_MESSAGE);

      const shouldShowInviteModal = email && isNew && ["admin", "manager", "contributor"].includes(role);
      if (shouldShowInviteModal) {
        dispatch({ type: "setNewlyAddedUserEmail", email });
      }
    } catch (error) {
      App.notify(SAVE_FAILURE_MESSAGE);
      throw error;
    }
  };

  return {
    ...state,

    addAnotherUser,
    isEditingSelf: state.userData.id === currentUser.id,
    hideSuccessModal: () => dispatch({ type: "setNewlyAddedUserEmail", email: "" }),
    isSuccessModalShown: !!state.newlyAddedUserEmail,
    onAdd: (role) => dispatch({ type: "onAddUser", role }),
    onCancel: () => dispatch({ type: "hideUserModal" }),
    onEdit: (userId) => dispatch({ type: "onEditUser", userData: _.find(people, { id: userId }) }),
    onSave,
    userGroups,
  };
};

export default useUserSettingsPage;
