import { gql } from "@apollo/client";

import { ENTERPRISE_FRAGMENT } from "collection/graphql/enterprise/fragments";

export default gql`
  query getCurrentEnterprise {
    currentEnterprise {
      ...Enterprise
    }
  }
  ${ENTERPRISE_FRAGMENT}
`;
