import { gql } from "@apollo/client";

export const GET_SBB_ADVISORS = gql`
  query getAllSbbAdvisors {
    getAllSbbAdvisors {
      name
      email
      phone
    }
  }
`;
