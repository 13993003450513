import { useSuspenseQuery } from "@apollo/client";

import { restClient } from "collection/graphql/client";

/**
 * Use `useSuspenseQuery` instead.
 * @deprecated
 */
const useRestSuspenseQuery = (query, options = {}) => {
  return useSuspenseQuery(query, {
    ...options,
    client: restClient,
  });
};

export default useRestSuspenseQuery;
