import EditableBaseContract from "./EditableBaseContract";
import { gql } from "@apollo/client";

/**
 * Represents the fields necessary to edit a CashContract.
 * @deprecated
 * @type {DocumentNode}
 */

export default gql`
  fragment LegacyEditableCashContract on CashContract {
    ...EditableBaseContract
    basis
    buyer
    contractNumber
    cashContractPrice: contractPrice
    contractSubtypeCash: contractType
    dataSource
    deliveryEndDate
    deliveryStartDate
    maxPrice
    minPrice
    optionalNetPrice: netPrice
    cashPremium: premium
    pricingWindowEnd
    pricingWindowStart
    quantity
    quantityCanceled
    quantityDelivered
    quantityPriced
  }

  ${EditableBaseContract}
`;
