import Backbone from "backbone";
import SubRoute from "backbone.subroute";

import EventChannel from "lib/events";

Backbone.SubRoute = SubRoute;
const channel = EventChannel.getChannel("app");

export default Backbone.SubRoute.extend({
  execute(callback, args) {
    channel.after("ready", () => callback.apply(this, args));
  },
});
