import Collection from "lib/collection";
import Model from "model/product";

const ProductsCollection = Collection.extend({
  name: "products",
  model: Model,
  url: "/api/products",

  comparator(model) {
    if (model.get("type") == "water") {
      return "water";
    }
    return model.get("name").toLowerCase();
  },
});
export default new ProductsCollection();
