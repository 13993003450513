import _ from "lodash";

export const DEFAULT_COORDINATES = "41.588822,-93.620309"; // Des Moines, IA

const findFieldsCenter = function (cords) {
  // For bounding box of what can fit on the screen simutaneously
  // numerator is max lat or lng differential allowable between two field centers
  const distBound = 0.06 / Math.sqrt(2);

  // Create the distance matrix
  const dMatrix = new Array(cords.length);
  let x = 0;
  while (x < cords.length) {
    dMatrix[x] = new Array(cords.length);
    x++;
  }

  // Fill the matrix with distance values
  let i = 0;
  while (i < cords.length) {
    const compareLng = cords[i][0];
    const compareLat = cords[i][1];
    let j = 0;
    while (j < cords.length) {
      dMatrix[i][j] = Math.sqrt(Math.pow(compareLng - cords[j][0], 2) + Math.pow(compareLat - cords[j][1], 2));
      j++;
    }
    i++;
  }

  // Find the field that has the most fields with in distBound range
  const max = _.max(dMatrix, (c) => c.filter((d) => d < distBound).length);

  // Find which cord it corresponds to
  const index = dMatrix.indexOf(max);
  return cords[index];
};

// returns a string of "lng,lat" to serve as a map center. Center decided
// in the following priority, 1.Street Address 2.Fields Center 3.City/Postal
// 4.Des Moines, IA / Default
const getLocation = ({ enterprise, fields: fieldData = [] }) => {
  if (enterprise.address) {
    return enterprise.latlng;
  }

  // Plucking all centroids, filtering out nulls
  const coords = _.map(fieldData, "centroid.coordinates").filter((c) => c);

  // If the user has fields and there is at least one not null centroid
  if (_.size(fieldData) > 0 && coords.length) {
    const [lng, lat] = Array.from(findFieldsCenter(coords));

    // return if the computed coordinates do not end up as 0,0
    if (parseInt(lat, 10) || parseInt(lng, 10)) {
      return `${lat},${lng}`;
    }
  }

  if (enterprise.city || enterprise.postalCode) {
    return enterprise.latlng;
  }

  return DEFAULT_COORDINATES;
};

export default getLocation;
