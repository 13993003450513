import ActivityPopoverButton from "activity/components/ActivityPopoverButton";
import useActivityListing from "activity/containers/ActivityListing/useActivityListing";
import useActivityBulkSelection from "activity/hooks/useActivityBulkSelection";
import { css } from "aphrodite";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { Checkbox } from "components/fl-ui/Form";
import { Icon, IconContainer } from "components/fl-ui/Icons";
import Tooltip from "components/fl-ui/Tooltip";
import styles from "fields/common/styles";

const Table = styled.table`
  border-spacing: 0 0.5rem;

  td,
  th {
    color: #5c6370;
    border: 1px solid #e3e5e8;
    border-width: 1px 0;
    font-size: 0.875rem;
    padding: 0;
    text-align: left;
    @media print {
      border: 0 none;
      border-bottom: 1px solid #e3e5e8;
    }

    a {
      display: block;
      padding: 1rem;
      text-decoration: none;
      @media print {
        padding: 1rem 0;
      }
      span {
        color: #5c6370;
      }
    }

    strong {
      color: #5c6370;
      margin: 0;
    }
  }
  th {
    border: 0 none;
  }
  th:first-child,
  td:first-child {
    border-left-width: 1px;
    border-radius: 4px 0 0 4px;
    padding-left: 10px;
    @media print {
      display: none;
    }
  }
  th:last-child,
  td:last-child {
    border-radius: 0 4px 4px 0;
    border-right-width: 1px;
    padding-right: 10px;
    @media print {
      display: none;
    }
  }
`;

const LinkedCell = ({ breakSpaces, children, id, nowrap }) => {
  const style = {};
  if (nowrap) {
    style.whiteSpace = "nowrap";
  } else if (breakSpaces) {
    style.whiteSpace = "break-spaces";
    style.maxWidth = "200px";
  }

  return (
    <td style={style}>
      <a href={`/#activity/${id}`}>{children}</a>
    </td>
  );
};

const StatusCell = ({ children, id }) => (
  <td style={{ whiteSpace: "nowrap" }}>
    <a href={`/#activity/${id}`} style={{ alignItems: "center", display: "flex", justifyContent: "flex-end" }}>
      {children}
    </a>
  </td>
);

const Row = styled.tr`
  > td {
    ${(props) =>
      props.isSelected &&
      `
        background: rgb(249, 252, 255);
        border-color: rgb(123, 178, 234);
    `}
  }
`;

const ActivityListing = (props) => {
  const { activities } = props;
  const bulkSelection = useActivityBulkSelection();

  const { getProductNamesCopy, hasIconColumn, isSelectedActivity, renderStatusText, showCostPerAcre } =
    useActivityListing(activities);

  return (
    <div style={{ overflowX: "scroll" }}>
      <Table>
        <tbody>
          {activities.map((activity, i) => {
            const {
              acreage,
              crop,
              date,
              field,
              id,
              inputs,
              isAAR,
              isMachineData,
              isWorkOrder,
              totalCostPerAcre,
              type,
            } = activity;
            const isSelected = isSelectedActivity(id);

            return (
              <Row key={i} isSelected={isSelected}>
                <td>
                  <Checkbox checked={isSelected} onChange={() => bulkSelection.toggle(id)} />
                </td>

                <LinkedCell id={id} nowrap>
                  <strong>{moment(date).format("MMM D")}</strong>
                </LinkedCell>

                {hasIconColumn && (
                  <LinkedCell id={id}>
                    {isAAR && (
                      <Tooltip message="Logged with Automatic Activity Recording">
                        <Icon icon="aar" className={css(styles.aarIcon, styles.aarIcon_md)} />
                      </Tooltip>
                    )}

                    {isMachineData && (
                      <Tooltip message="Logged from machine data">
                        <Icon icon="integrations" className={css(styles.aarIcon, styles.aarIcon_md)} />
                      </Tooltip>
                    )}
                  </LinkedCell>
                )}

                <LinkedCell id={id} nowrap>
                  {type ? type.name : "Unknown"}
                </LinkedCell>

                <LinkedCell id={id} nowrap>
                  {field.name}
                </LinkedCell>

                <LinkedCell id={id} nowrap>
                  {crop.commodity.name}
                </LinkedCell>

                <LinkedCell id={id} breakSpaces>
                  <div className={css(styles.ellipsify)}>{getProductNamesCopy(inputs)}</div>
                </LinkedCell>

                <LinkedCell id={id} nowrap>
                  {_.isNil(acreage) ? "--" : `${parseInt(acreage, 10)} ac`}
                </LinkedCell>

                {showCostPerAcre && (
                  <LinkedCell id={id} nowrap>
                    ${parseInt(totalCostPerAcre, 10)} / ac
                  </LinkedCell>
                )}

                <StatusCell id={id}>
                  {isWorkOrder && (
                    <IconContainer>
                      <Icon icon="assignment" />
                    </IconContainer>
                  )}
                  {renderStatusText(activity)}
                </StatusCell>

                <td>{!isSelected && <ActivityPopoverButton activity={activity} />}</td>
              </Row>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

ActivityListing.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ActivityListing;
