import chooserStyles from "activity/components/choosers/FieldCropChooser/chooserStyles";
import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";
import ReactSelect from "react-select";
import router from "router";
import { DropdownContainer } from "settings/styledComponents";

import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import usePermissions from "hooks/usePermissions";
import useViewType from "hooks/useViewType";

const Tab = ({ activeTab, label, route, thisTab }) => {
  return (
    <li className={cx("tab js_settings_tab", activeTab === thisTab && "active")}>
      <a href={`/#${route}`}>
        <div className="indicator" />
        {label}
      </a>
    </li>
  );
};

const SettingsNav = (props) => {
  const isMobile = useViewType() === "mobile";
  const { canRead } = usePermissions();
  const hasActivitiesFeature = useEnterpriseFeature("activities");
  const hasProductsFeature = useEnterpriseFeature("products");
  const hasInputsFeature = hasActivitiesFeature && hasProductsFeature;
  const hasProfitAndLoss = useEnterpriseFeature("profit_and_loss") && canRead("profit_and_loss");

  const tabs = [];
  tabs.push({ label: "Account", route: "settings/personal", thisTab: "personal" });
  tabs.push({ label: "Alerts", route: "settings/alerts", thisTab: "alerts" });
  tabs.push({ label: "Farm", route: "settings/farm", thisTab: "farm" });
  tabs.push({ label: "Plan & Billing", route: "settings/billing", thisTab: "billing" });
  tabs.push({ label: "People", route: "settings/users", thisTab: "users" });
  tabs.push({ label: "Activities", route: "settings/activities", thisTab: "activities" });

  if (hasProfitAndLoss) {
    tabs.push({
      label: "Expense & Revenue Accounts",
      route: "settings/expense_and_revenue",
      thisTab: "expense_and_revenue",
    });
  }

  if (hasInputsFeature) {
    tabs.push({ label: "Inputs", route: "settings/inputs", thisTab: "inputs" });
  }

  tabs.push({ label: "Data", route: "settings/data", thisTab: "data" });
  tabs.push({ label: "Connections", route: "settings/connections", thisTab: "connections" });
  tabs.push({ label: "Commodities", route: "settings/commodities", thisTab: "commodities" });

  return isMobile ? (
    <DropdownContainer>
      <ReactSelect
        getOptionLabel={({ label }) => label}
        getOptionValue={({ thisTab }) => thisTab}
        isSearchable={false}
        onChange={({ route }) => router.navigate(route, { replace: true, trigger: true })}
        options={tabs}
        styles={chooserStyles}
        value={tabs.find(({ thisTab }) => thisTab === props.activeTab)}
      />
    </DropdownContainer>
  ) : (
    <div className="settings-tabs-container">
      <ul className="tabs settings-tabs horizontal-tabs">
        {tabs.map((tab, i) => (
          <Tab {...props} {...tab} key={`tab${i}`} />
        ))}
      </ul>
    </div>
  );
};

SettingsNav.propTypes = {
  activeTab: PropTypes.oneOf([
    "activities",
    "alerts",
    "billing",
    "commodities",
    "data",
    "expense_and_revenue",
    "farm",
    "inputs",
    "personal",
    "sharing",
    "users",
    "connections",
  ]).isRequired,
};

export default SettingsNav;
