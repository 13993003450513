import { DatePicker } from "@farmlogs/fl-ui";
import { BasicInput } from "agreements/forms/components";
import BasicSelect from "agreements/forms/components/BasicSelect";
import CompositeDate from "agreements/forms/components/CompositeDate";
import LineItemFieldset from "agreements/forms/components/LineItemFieldset";
import MicroLabel from "agreements/forms/components/MicroLabel";
import { calculateDateRange, calculateDateRangeForYears } from "agreements/utils";
import paymentScheduleValidator from "agreements/validators/AgreementsForm/paymentScheduleValidator";
import _ from "lodash";
import moment from "moment";
import React, { useState } from "react";

const Schedule = ({ agreementType, fields, ordinal, onChange, onRemove, schedule, total }) => {
  const [formFields, setFormFields] = useState(schedule);

  const dayOptions = _.range(1, 32).map((key) => ({ key, label: key, value: key }));
  const intervalOptions = _.range(1, 21).map((key) => ({ key, label: key, value: key }));
  const amountRateOptions = [
    { key: "TOTAL", value: "TOTAL", label: "total" },
    { key: "PER_ACRE", value: "PER_ACRE", label: "per ac" },
  ];
  const paymentPeriodOptions = [
    { key: "MONTH", value: "MONTH", label: "months" },
    { key: "YEAR", value: "YEAR", label: "years" },
  ];

  const handleChange = (changes) => {
    const updatedFormFields = {
      ...formFields,
      ...changes,
    };
    setFormFields(updatedFormFields);
    onChange(updatedFormFields);
  };

  const handleCompositeDateChange = ({ month, day }) =>
    handleChange({
      paymentDay: day,
      paymentMonth: month + 1,
    });
  const compositeDateValues = {
    month: formFields.paymentMonth - 1,
    day: formFields.paymentDay,
  };

  let dateRange,
    errorMessage = "";
  if (moment(fields.startDate).isValid()) {
    if (agreementType === "LEASE") {
      dateRange = calculateDateRangeForYears(fields.startYear, fields.endYear);
    } else if (fields.termLength > 0 && fields.termUnit) {
      dateRange = calculateDateRange(fields.startDate, fields.termLength, fields.termUnit);
    }
    const validationResult = paymentScheduleValidator.validate(formFields, {
      context: {
        context: fields,
        agreementType,
      },
    });
    if (dateRange && ("startDate" in validationResult || "endDate" in validationResult)) {
      errorMessage = `Choose dates between ${dateRange.startDate.format("MM/DD/YYYY")} and ${dateRange.endDate.format(
        "MM/DD/YYYY"
      )}.`;
    }
  }

  return (
    <LineItemFieldset errorMessage={errorMessage} label={`Schedule ${ordinal} of ${total}`} onRemove={onRemove}>
      <MicroLabel label="Make a payment every">
        <BasicSelect
          name="paymentInterval"
          onChange={({ paymentInterval }) => handleChange({ paymentInterval: +paymentInterval })}
          options={intervalOptions}
          value={formFields.paymentInterval}
        />

        <BasicSelect
          name="paymentPeriod"
          onChange={({ paymentPeriod }) => {
            const data = { paymentPeriod, paymentMonth: paymentPeriod === "MONTH" ? null : 1 };
            handleChange(data);
          }}
          options={paymentPeriodOptions}
          value={formFields.paymentPeriod}
        />
      </MicroLabel>

      <MicroLabel label="On day">
        {formFields.paymentPeriod === "MONTH" && (
          <BasicSelect
            name="paymentDay"
            onChange={({ paymentDay }) => handleChange({ paymentDay: +paymentDay })}
            options={dayOptions}
            value={formFields.paymentDay}
          />
        )}
        {formFields.paymentPeriod === "YEAR" && (
          <CompositeDate {...compositeDateValues} onChange={handleCompositeDateChange} />
        )}
      </MicroLabel>

      <MicroLabel label="For the amount">
        <BasicInput
          name="amount"
          onChange={({ target: { value } }) => handleChange({ amount: +value })}
          type="currency"
          value={formFields.amount}
        />
        <BasicSelect
          name="amountRate"
          onChange={handleChange}
          options={amountRateOptions}
          value={formFields.amountRate}
        />
      </MicroLabel>

      <MicroLabel label="Starting on">
        <DatePicker onChange={(startDate) => handleChange({ startDate })} value={formFields.startDate} />
      </MicroLabel>

      <MicroLabel label="Until the date">
        <DatePicker onChange={(endDate) => handleChange({ endDate })} value={formFields.endDate} />
      </MicroLabel>
    </LineItemFieldset>
  );
};

export default Schedule;
