import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

const UPDATE_ACTIVITY_TYPE = gql`
  mutation updateActivityType($activityType: UpdateActivityTypeInput!) {
    updateActivityType(activityType: $activityType) {
      ok
      activityType {
        id
      }
    }
  }
`;

export default graphql(UPDATE_ACTIVITY_TYPE, {
  props: ({ mutate }) => ({
    updateActivityType: (activityType) => {
      return mutate({
        variables: { activityType },
      });
    },
  }),
  options: { refetchQueries: ["getActivityTypes"] },
});
