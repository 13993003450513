import { DatePicker } from "@farmlogs/fl-ui";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";

import * as constants from "modules/agreements/constants";

import Chooser from "components/fl-ui/Chooser";
import FilterActionButtonGroup from "components/fl-ui/Filters/FilterActionButtonGroup";
import { Checkbox, ClearAllFormGroup, TagSelectGroup } from "components/fl-ui/Form";
import { CloseX } from "components/fl-ui/Icons";
import { Modal, ModalHeader, ModalTitle, ModalBody, ModalFooter } from "components/fl-ui/Modal/Modal";
import { css } from "components/fl-ui/aphrodite";
import styles from "fields/common/styles";

const AgreementFilterModal = (props) => {
  const { applyFilters, fields, hasLeases, onClose, onFilterChange, resetFilters, selectedFilters } = props;
  const typeOptions = [
    { label: "Loans", value: constants.LOAN },
    { label: "Leases", value: constants.LEASE },
    { label: "Non-crop revenue", value: constants.REVENUE },
  ];

  const termOptions = [
    { id: constants.BASE_RENT, label: "Base rent", value: constants.BASE_RENT },
    { id: constants.CROP_SHARE, label: "Crop share", value: constants.CROP_SHARE },
    { id: constants.YIELD_ADJUSTMENT, label: "Yield adjustment", value: constants.YIELD_ADJUSTMENT },
    { id: constants.EXPENSE_SHARE, label: "Expense share", value: constants.EXPENSE_SHARE },
  ];

  const componentProps = {
    formatOptionLabel: (option, { context }) => {
      return context === "menu" ? option.name : `${option.name} · ${(+option.acreage).toFixed(2)} ac`;
    },
    getOptionLabel: ({ name }) => name,
    getOptionValue: _.property("id"),
    isClearable: false,
    isMulti: true,
    onChange: (selected) => {
      onFilterChange({ fields: selected });
    },
    options: fields,
    placeholder: "Select field",
    value: selectedFilters.fields,
  };

  const showLeases =
    hasLeases && (_.isEmpty(selectedFilters.type) || selectedFilters.type.some((type) => type === constants.LEASE));

  return (
    <Modal width={500}>
      <ModalHeader>
        <ModalTitle>Filter agreements</ModalTitle>
        <CloseX onClick={onClose} />
      </ModalHeader>

      <ModalBody>
        <TagSelectGroup
          id="type"
          isMulti
          label="Type"
          multiSelect
          onChange={(type) => onFilterChange({ type })}
          options={typeOptions}
          showBulkSelect
          value={selectedFilters.type}
        />

        <Row>
          <Col xs>
            <ClearAllFormGroup label="Fields" onClear={() => onFilterChange({ fields: [] })}>
              <Chooser {...componentProps} />
            </ClearAllFormGroup>
          </Col>
        </Row>

        <Row>
          <Col xs md={6} className={css(styles.bottom_margin)}>
            <ClearAllFormGroup label="Start date" onClear={() => onFilterChange({ startDate: null })}>
              <DatePicker onChange={(startDate) => onFilterChange({ startDate })} value={selectedFilters.startDate} />
            </ClearAllFormGroup>
          </Col>

          <Col xs md={6} className={css(styles.bottom_margin)}>
            <ClearAllFormGroup label="End date" onClear={() => onFilterChange({ endDate: null })}>
              <DatePicker onChange={(endDate) => onFilterChange({ endDate })} value={selectedFilters.endDate} />
            </ClearAllFormGroup>
          </Col>
        </Row>

        {showLeases && (
          <TagSelectGroup
            id="terms"
            isMulti
            label="Terms"
            onChange={(terms) => {
              const updates = { terms };
              if (_.isEmpty(selectedFilters.type)) {
                updates.type = [constants.LEASE];
              }
              onFilterChange(updates);
            }}
            options={termOptions}
            showBulkSelect
            value={selectedFilters.terms}
          />
        )}

        <Row>
          <Col xs className={css(styles.bottom_margin)}>
            <label htmlFor="includeExpired" className={css(styles.centerVertically)}>
              <Checkbox
                checked={!!selectedFilters.includeExpired}
                id="includeExpired"
                name="includeExpired"
                onChange={({ target: { checked } }) => onFilterChange({ includeExpired: !!checked })}
              />
              <span>Include expired agreements</span>
            </label>
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter>
        <FilterActionButtonGroup
          apply={{ action: applyFilters }}
          cancel={{ action: onClose }}
          reset={{ action: resetFilters }}
        />
      </ModalFooter>
    </Modal>
  );
};

AgreementFilterModal.propTypes = {
  applyFilters: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(PropTypes.object),
  hasLeases: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  selectedFilters: PropTypes.shape({
    endDate: PropTypes.string,
    fields: PropTypes.arrayOf(PropTypes.object),
    includeExpired: PropTypes.bool,
    startDate: PropTypes.string,
    terms: PropTypes.arrayOf(PropTypes.object),
    type: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default AgreementFilterModal;
