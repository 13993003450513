import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";

import useCurrentEnterprise from "hooks/useCurrentEnterprise.js";
import Map from "lib/map/map";

const ElevatorMap = ({ buyer }) => {
  const { currentEnterprise } = useCurrentEnterprise();
  const mapElementRef = useRef();
  const mapRef = useRef();

  useEffect(() => {
    const center = buyer ? [buyer.latitude, buyer.longitude] : currentEnterprise.latlng;
    mapRef.current = new Map(mapElementRef.current, {
      center,
      zoom: 16,
    });
    if (buyer) {
      mapRef.current.removeMarker();
      mapRef.current.panTo([buyer.latitude, buyer.longitude]);
      mapRef.current.addMarker(center);
    }

    return () => {
      mapRef.current.remove();
    };
  }, [buyer]);

  return <div id="map" ref={mapElementRef} style={{ height: "300px" }} />;
};

ElevatorMap.propTypes = {
  buyer: PropTypes.shape({
    latitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    longitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
};

ElevatorMap.defaultProps = {
  buyer: null,
};

export default ElevatorMap;
