import { object, string } from "yup";

/**
 * @typedef {Object} MobileForcedAppUpdatePlatformDetails
 * @property {string} deprecatedRange version with operand prefix stating which version are no longer fully supported (ex. '<=1.2.3')
 */
const MobileForcedAppUpdatePlatformDetails = object({
  deprecatedRange: string().required(),
});

/**
 * @typedef {Object} MobileForcedAppUpdateConfigSchema
 * @property {MobileForcedAppUpdatePlatformDetails} android
 * @property {MobileForcedAppUpdatePlatformDetails} ios
 */
const MobileForcedAppUpdateConfigSchema = object({
  android: MobileForcedAppUpdatePlatformDetails.required(),
  ios: MobileForcedAppUpdatePlatformDetails.required(),
});

export default MobileForcedAppUpdateConfigSchema;
