import React from "react";

import MarketingBlankSlateBanner from "components/advertisements/banners/MarketingBlankSlateBanner";
import PayGateCommonBlankStateModal from "components/advertisements/modals/PayGateCommonBlankStateModal";

const MarketingBlankStateModal = (props) => (
  <PayGateCommonBlankStateModal {...props} banner={MarketingBlankSlateBanner} />
);

export default MarketingBlankStateModal;
