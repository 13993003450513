import { Section } from "demo/components";
import InputChooserDemo from "demo/demos/InputChooserDemo";
import RateChooserDemo from "demo/demos/RateChooserDemo";
import UnitChooserDemo from "demo/demos/UnitChooserDemo";
import React, { useState } from "react";

import { Stack } from "components/fl-ui/Layout";
import ProductChooser from "components/product/ProductChooser";
import { ACTIVITY_INPUT } from "components/product/constants";

const defaultActivityInputInput = () => ({
  amount: null,
  amountRate: null,
  amountUnit: null,
  product: null,
});

const defaultProductPurchaseInput = () => ({
  amount: null,
  amountUnit: null,
  costRate: null,
  product: null,
  rateUnit: null,
});

const EditProductDemo = () => {
  const inputType = ACTIVITY_INPUT;
  const defaults = inputType === ACTIVITY_INPUT ? defaultActivityInputInput() : defaultProductPurchaseInput();
  const [input, setInput] = useState(defaults);
  const handleChange = (input) => {
    console.info(input);
    setInput(input);
  };

  return (
    <Stack space="2rem">
      <Section>
        <h3>ProductChooser Demo</h3>
        <ProductChooser input={input} inputType={inputType} onChange={handleChange} />
      </Section>
      <hr />

      <Section>
        <h3>Individual Demos</h3>

        <InputChooserDemo />
        <hr />
        <UnitChooserDemo />
        <hr />
        <RateChooserDemo />
      </Section>
    </Stack>
  );
};

export default EditProductDemo;
