import themeGet from "@styled-system/theme-get";
import styled from "styled-components";

const DesktopMenuItemHeader = styled.li`
  color: ${themeGet("colors.darkBlue")};
  display: flex;
  font-size: ${themeGet("fontSizes.md")};
  font-weight: ${themeGet("fontWeights.bold")};
  letter-spacing: 0.5px;
  line-height: 1em;
  padding: 14px;
  text-transform: uppercase;
`;

export default DesktopMenuItemHeader;
