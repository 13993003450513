import { gql } from "@apollo/client";

import { FieldGroup } from "collection/graphql/fieldGroups/fragments";

export default gql`
  mutation createFieldGroup($group: CreateFieldGroupInput!) {
    createFieldGroup(group: $group) {
      ok
      group {
        ...FieldGroup
      }
    }
  }
  ${FieldGroup}
`;
