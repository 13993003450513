import { StyleSheet } from "aphrodite";

import { Spacing } from "components/fl-ui/constants";

const styles = StyleSheet.create({
  alertIcon: {
    cursor: "pointer",
    height: Spacing.xxlarge,
    margin: `${Spacing.xsmall} 0 0 ${Spacing.xsmall}`,
    position: "relative",
    width: Spacing.xxlarge,
  },
  alertIconActive: {
    height: Spacing.xxxlarge,
    margin: 0,
    width: Spacing.xxxlarge,
  },
});

export default styles;
