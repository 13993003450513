import { gql } from "@apollo/client";

const getAllMaintenanceRecords = gql`
  query getAllMaintenanceRecords {
    maintenanceRecords: getAllMaintenanceRecords @rest(type: "MaintenanceRecord", path: "/api/maintenance_records") {
      ...MaintenanceRecord
    }
  }
`;

export default getAllMaintenanceRecords;
