import useActivityFilters from "activity/hooks/useActivityFilters";
import useAugmentedActivities from "activity/hooks/useAugmentedActivities";
import useFilteredActivities from "activity/hooks/useFilteredActivities";
import useGroupedActivities from "activity/hooks/useGroupedActivities";
import React, { createContext, useContext, useMemo } from "react";

/**
 * @typedef {Object} ActivitySummaryContext
 * @property {Activity[]} allActivities
 * @property {Object} filteredActivities
 * @property {Object} groupedActivities
 * @property {Boolean} loading
 * @property {Number} totalActivities
 * @property {Number} totalAppliedFilters
 * @property {Number} totalFilteredActivities
 */

/**
 * Provides filter summary info and activities.
 *
 * @private
 * @return {ActivitySummaryContext}
 */
const useActivitiesProvider = () => {
  const { filters } = useActivityFilters();
  const augmentedActivitiesPayload = useAugmentedActivities();
  const { activities: filteredActivities, appliedFilterCount } = useFilteredActivities(
    augmentedActivitiesPayload.allActivities ?? [],
    filters
  );
  const groupedActivities = useGroupedActivities(filteredActivities, filters);

  return useMemo(
    () => ({
      ...augmentedActivitiesPayload,
      filteredActivities,
      groupedActivities,
      totalAppliedFilters: appliedFilterCount,
      totalFilteredActivities: filteredActivities.length,
    }),
    [augmentedActivitiesPayload, filteredActivities, filters]
  );
};

/**
 * @type {React.Context<ActivitySummaryContext>}
 */
const context = createContext(undefined);

/**
 * @typedef {React.FC} ActivitySummaryContextProvider
 */
/**
 * Context provider for {@link ActivitySummaryContext}.
 *
 * @param {React.Props} props
 * @param {React.Children} props.children
 * @requires {ActivityFiltersContext}
 * @return {JSX.Element}
 * @type {ActivitySummaryContextProvider}
 */
export const Provider = (props) => (
  <context.Provider value={useActivitiesProvider()}>{props.children}</context.Provider>
);

/**
 * Provides activities. Requires a {@link ActivitySummaryContextProvider} context provider.
 * @return {ActivitySummaryContext}
 */
export default () => {
  const contextValue = useContext(context);
  if (!contextValue) {
    throw new Error("Invalid context: ActivitySummaryContext expected");
  }

  return contextValue;
};
