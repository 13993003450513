import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const StyledCluster = styled.div`
  align-items: ${(props) => props.align};
  display: flex;
  justify-content: ${(props) => props.justify};
  & > * {
    margin: 0 calc(${(props) => props.space} / 2);
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;

StyledCluster.propTypes = {
  align: PropTypes.oneOf(["baseline", "center", "end", "start", "stretch"]),
  children: PropTypes.any,
  justify: PropTypes.oneOf(["center", "flex-end", "flex-start", "space-around", "space-between", "space-evenly"])
    .isRequired,
  padding: PropTypes.any,
};

const alignment = (key) => {
  const mappings = {
    baseline: "baseline",
    center: "center",
    end: "flex-end",
    start: "flex-start",
    stretch: "stretch",
  };

  return mappings[key];
};

const justification = (props) => {
  const mappings = {
    around: "space-around",
    between: "space-between",
    center: "center",
    end: "flex-end",
    even: "space-evenly",
    start: "flex-start",
  };

  const selected = _.filter(_.keys(mappings), (key) => !!props[key]);
  if (selected.length > 1) {
    throw new Error(`Cluster expects only one layout flag to be provided at a time`);
  }

  return mappings[selected] || mappings.start;
};

const Cluster = ({ align, around, between, center, end, even, start, ...props }) => {
  const justify = justification({ around, between, center, end, even, start });
  return <StyledCluster {...props} align={alignment(align)} justify={justify} />;
};

Cluster.propTypes = {
  align: PropTypes.oneOf(["baseline", "center", "end", "start", "stretch"]),
  around: PropTypes.bool,
  between: PropTypes.bool,
  center: PropTypes.bool,
  end: PropTypes.bool,
  even: PropTypes.bool,
  start: PropTypes.bool,
  space: PropTypes.any,
};

Cluster.defaultProps = {
  align: "center",
  around: false,
  between: false,
  center: false,
  end: false,
  even: false,
  start: false,
  space: "1rem",
};

export default Cluster;
